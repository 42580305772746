
<div class="layout-topbar">
    <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
            <a
                tabindex="0"
                class="menu-button"
                (click)="appMain.onMenuButtonClick($event)"
            >
                <i class="pi pi-bars"></i>
            </a>
        </div>

        <app-menu
            [sidebarStatic]="sidebarStatic"
            [sidebarMobileActive]="sidebarMobileActive"
        >
        </app-menu>

        <div class="layout-topbar-right">
            <ul class="layout-topbar-actions">
                <app-search [class.sidebar-static]="sidebarStatic"></app-search>
                <!-- <li #searchItem class="topbar-item search-item" [ngClass]="{'active-topmenuitem': appMain.search}">
                    <a tabindex="0" (click)="appMain.onTopbarItemClick($event,searchItem)">
                        <i class="topbar-icon pi pi-search"></i>
                    </a>

                    <div class="search-input-wrapper">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" pInputText placeholder="Search..." (click)="appMain.searchClick = true;"/>
                        </span>
                    </div>

                    <ul class="fadeInDown">
                        <div class="search-input-wrapper p-fluid" style="width: 100%">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Search..." (click)="appMain.searchClick = true;"/>
                            </span>
                        </div>
                    </ul>
                </li> -->
                <li
                    class="p-mr-2 p-mr-lg-3"
                    #helpPage
                >
                    <button 
                        pButton 
                        type="button" 
                        icon="pi pi-question" 
                        data-cy="page-help-button" 
                        class="p-button-rounded p-button-secondary p-button-outlined"
                        (click)="helpDialog.toggleShowDialog()"
                    ></button>
                </li>

                <li
                    #profile
                    class="topbar-item user-profile"
                    [ngClass]="{'active-topmenuitem fadeInDown': appMain.activeTopbarItem === profile}"
                >
                    <button
                        pButton
                        data-cy="profile-submit-button"
                        icon="pi pi-user"
                        (click)="appMain.onTopbarItemClick($event,profile)"
                        [pTooltip]="user.givenName + ' ' + user.familyName + ', ' + zone.name"
                    >
                        <!-- <p-avatar icon="pi pi-user"></p-avatar> -->
                    </button>
                    <ul class="fadeInDown">
                        <!-- <li>
                            <a href="#">
                                <i class="pi pi-user"></i>
                                <span>Profile</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="pi pi-cog"></i>
                                <span>Settings</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="pi pi-inbox"></i>
                                <span>Messages</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="pi pi-bell"></i>
                                <span>Notifications</span>
                            </a>
                        </li> -->
                        <li
                            class="logout-item"
                            [routerLink]="[ '/account' ]"
                        >
                            <i class="pi pi-user"></i>
                            <span>
                                {{ user.givenName}} {{ user.familyName}}
                            </span>
                        </li>
                        <li
                            class="logout-item"
                            (click)="$event.stopPropagation()"
                        >
                            <i class="pi pi-palette"></i>
                            <span>Dark</span>
                            <p-inputSwitch
                                class="p-ml-3 p-mt-1"
                                [ngModel]="colorSchemeService.colorScheme.value === 'light'"
                                (onChange)="colorSchemeService.setColorScheme()"
                            ></p-inputSwitch>
                            <span>Light</span>
                        </li>
                        <li
                            class="logout-item"
                            (click)="openFeedback()"
                        >
                            <i class="pi pi-send"></i>
                            <span>Send Feedback / Report Bug</span>
                        </li>
                        <li
                            class="logout-item"
                            (click)="logout()"
                        >
                            <i class="pi pi-sign-out"></i>
                            <span>Logout</span>
                        </li>
                    </ul>
                </li>
                <li
                    #notifications
                    class="topbar-item p-ml-2"
                    *hasPermission="['users.viewNotifications']"
                    [ngClass]="{'active-topmenuitem fadeInDown': appMain.activeTopbarItem === notifications}"
                >
                    <p-button
                        class="notifications-btn"
                        styleClass="p-button-warning"
                        icon="pi pi-bell"
                        [badge]="notificationsBadge"
                        badgeClass="p-badge-danger"
                        (click)="appMain.onTopbarItemClick($event,notifications)"
                    ></p-button>
                    <ul class="fadeInDown">
                        <li *ngIf="!notificationService.latest?.length" class="no-notifications">You have no notifications.</li>
                        <li
                            class="topbar-notification"
                            *ngFor="let notification of notificationService.latest"
							[routerLink]="[ '/notifications', notification.id ]"
                            [class.unread]="!(notification | notificationRead)"
                        >
                            <span class="notification-title-label">
                                {{notification.title}}
                            </span>
                            <span class="notification-time-label">{{ notification | notificationDate }}</span>
                        </li>
                        <li
                            class="logout-item"
							[routerLink]="[ '/notifications' ]"
                        >
                            <i class="pi pi-list"></i>
                            <span>All Notifications</span>
                        </li>
                    </ul>
                </li>

                <!-- <li class="p-ml-3 p-ml-lg-5">
                    <button
                        label="Est"
                        pButton
                        *hasPermission="['frontend.estimating']"
                        class="p-button-secondary"
                        pTooltip="Open Estimating Notes"
                        (click)="appMain.setEstimatingPanel(!appMain.estimatingPanelActive)"
                    ></button>
                </li> -->

            </ul>

            <a
                href=""
                tabindex="0"
                class="layout-rightpanel-button"
                (click)="appMain.onRightPanelClick($event)"
            >
                <i
                    *ngIf="!appMain.rightPanelActive"
                    class="pi pi-arrow-left"
                ></i>
                <i
                    *ngIf="appMain.rightPanelActive"
                    class="pi pi-arrow-right"
                ></i>
            </a>
        </div>
    </div>
    <app-help-dialog #helpDialog></app-help-dialog>
</div>
