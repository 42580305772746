import { gql } from 'graphql-tag';

import { RawUser } from './auth';

export const createUsersMutation = gql`
  mutation createUser(
    $roles: [ String! ]
    $zones: [ String! ]
    $inheritRoleZones: Boolean
    $sendInvite: Boolean
    $users: [ CreateUserInput! ]
  ) {
    createUsers(
      roles: $roles
      zones: $zones
      inheritRoleZones: $inheritRoleZones
      sendInvite: $sendInvite
      users: $users
    ) {
      total
      failedUsers {
        user { email }
        error
      }
      users {
        id
        email
        phone
        givenName
        familyName
        roles {
          id
          name
        }
        zones {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

export interface CreateUserInput {
  email?: string;
  password?: string;
  givenName?: string;
  familyName?: string;
  phone?: string;
}

export interface CreateUsersInput {
	users: CreateUserInput[];
	roles?: string[];
	zones?: string[];
	inheritRoleZones?: boolean;
	sendInvite?: boolean;
}

export interface CreateUsersOutput {
  createUsers: {
    total: number;
    failedUsers: {
      user: { email: string };
      error: string;
    }[];
    users: RawUser[];
  };
}
