<div [formGroup]="shareEventForm">
	<p>Sharing events allows them to be viewed in other zones.</p>

	<div class="p-m-1">
		<p-multiSelect
			[options]="availableZones"
			class="full-width-multiselect"
			formControlName="zones"
			[showToggleAll]="false"
			optionLabel="name"
			appendTo="body"
			dataKey="id"
			placeholder="Select Zone"
			[maxSelectedLabels]="1"
			[selectedItemsLabel]="'{0} Zones Selected'"
		>
			<ng-template
				let-zone
				pTemplate="item"
			>
				<div class="country-item">
					<div>{{zone.name}}</div>
				</div>
			</ng-template>
		</p-multiSelect>
	</div>
	<br>
	<div class="p-text-right">
		<button
			pButton
			label="Cancel"
			icon="pi pi-times"
			(click)="closeDialog()"
			class="p-m-1"
		></button>
		<button
			pButton
			label="Share"
			icon="pi pi-arrow-up-right"
			(click)="shareEvent()"
			class="p-m-1"
		></button>
	</div>
</div>