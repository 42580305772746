import { Injectable } from '@angular/core';
import {ApolloError} from '@apollo/client/core';

import { Message } from 'primeng/api';
import { Subject } from 'rxjs';

import { getApolloErrorMessage } from '../utilities/errors.util';

export interface ButtonData {
  label?: string;
  icon?: string;
  styleClass?: string;
  onClick?: () => void;
  routerLink?: string | string[];
}

@Injectable({
  providedIn: 'root'
})
export class FreyaNotificationsService {
  // Allows all components to show toasts without having to setup, also persists across route changes.
  // Can call local toasts aswell by using the 'key' property
  // https://primefaces.org/primeng/showcase/#/toast

  addToast = new Subject<Message>();

  clearToasts = new Subject();

  constructor() { }

  success(summary: string, detail: string = undefined, data?: any ){
    this.addToast.next({severity: 'success', summary, detail, data, icon: 'pi-check'});
  }

  warning(summary: string, detail: string = undefined){
    this.addToast.next({severity: 'warn', summary, detail, icon: 'pi-exclamation-triangle'});
  }

  error(summary: string, detail: string = undefined, life = 5000, data?: any){
    this.addToast.next({severity: 'error', summary, detail, life, icon: 'pi-times-circle', data });
  }

  info(summary: string, detail: string = undefined){
    this.addToast.next({severity:'info', summary, detail, life: 2500, icon: 'pi-info-circle'});
  }

  apolloError(summary: string, error: ApolloError) {
    this.error(summary, getApolloErrorMessage(error), undefined, error.graphQLErrors?.[0]?.extensions);
  }
}
