import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { parseInt } from 'lodash';
import { SubSink } from 'subsink';

import { GetConfigValuesWithOverridingConfigsGQL, GetConfigValuesWithOverridingConfigsQuery, GetConfigValuesWithOverridingConfigsQueryVariables, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { getConfigValueByKey } from '../../utilities/configs.util';

interface RollingLockDateTableRow {
    name: string;
    value: string | number;
    zone: string;
    key: string;
    dataType: 'date' | 'cron';
}

@Component({
    selector: 'app-accounting',
    templateUrl: './accounting.component.html',
    styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit, OnDestroy {

    configValuesQueryRef: QueryRef<GetConfigValuesWithOverridingConfigsQuery, GetConfigValuesWithOverridingConfigsQueryVariables>;

    subs = new SubSink();

    rows: RollingLockDateTableRow[] = [];

    hasPermissionToModifyLockDate = [{
        permission: 'config.set',
        restriction: { canModifyLockDate: true },
    }];

    currentDate: number;

    supportEmail: string;

    contactAccountingButtonText: string;

    emailSubject: string;

    emailBodyPrefix: string;

    emailBodySuffix: string;

    eventLockedWarning: string;

    loading = false;

    dummyJobCode = 'MOVE-8N5-PAA';

    sampleInfo = `
Job: ${this.dummyJobCode}
Customer: Smith, John
Event: Moving (Booked), June 27
Link: [link to job]
User making the request: Doe, John
    `;

    templatePreview = '';

    constructor(
        private getConfigValuesGQL: GetConfigValuesWithOverridingConfigsGQL,
        private detailsHelper: DetailsHelperService,
        private setConfigGQL: SetConfigValuesGQL,
        private localNotify: FreyaNotificationsService,
    ) {}

    ngOnInit(): void {
        this.fetchConfigValues();

        this.detailsHelper.getObjectUpdates('Configs').subscribe((update) => {
            if (update.action === 'update') {
                this.fetchConfigValues();
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    fetchConfigValues() {

        const keys = [
            'rolling-lock-date.currentDate',
            'rolling-lock-date.supportEmail',
            'rolling-lock-date.emailSubject',
            'rolling-lock-date.contactAccountingButtonText',
            'rolling-lock-date.emailBodyPrefix',
            'rolling-lock-date.emailBodySuffix',
            'rolling-lock-date.eventLockedWarning',
        ];

        if (this.configValuesQueryRef) {
            this.configValuesQueryRef.refetch();
            return;
        }

        this.configValuesQueryRef = this.getConfigValuesGQL.watch({ keys }, { notifyOnNetworkStatusChange: true });

        this.subs.sink = this.configValuesQueryRef.valueChanges.subscribe((res) => {
            this.loading = res.loading;
            if (res?.loading) { return; }

            this.currentDate = parseInt(getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.currentDate'));

            this.supportEmail = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.supportEmail');

            this.contactAccountingButtonText = getConfigValueByKey(
              res.data.getConfigValues,
              'rolling-lock-date.contactAccountingButtonText',
            );

            this.emailSubject = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailSubject');

            this.emailBodyPrefix = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailBodyPrefix');

            this.emailBodySuffix = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.emailBodySuffix');

            this.eventLockedWarning = getConfigValueByKey(res.data.getConfigValues, 'rolling-lock-date.eventLockedWarning');

            this.setTemplatePreview();
        });
    }

    /**
     * Updates a config value, then deactivates the "edit in place" component
     * by calling the component's deactivate() method.
     */
    updateConfig(
      subKey: string,
      value: string | number,
      deactivate: () => void,
    ) {

        const key = `rolling-lock-date.${subKey}`;
        const uncamelizedKey = subKey.split(/(?=[A-Z])/).join(' ').toLowerCase();
        const successMsg = `Successfully updated ${uncamelizedKey}`;
        const failMsg = `Failed to update ${uncamelizedKey}`;

        this.setConfigGQL.mutate({ configs: [ {
          key,
          value: typeof value === 'number' ? value.toString() : value,
        } ] })
            .subscribe(() => {
                this.localNotify.success(successMsg);
                deactivate();
                this[subKey] = value;
                this.setTemplatePreview();
            }, (err) => {
                this.localNotify.apolloError(failMsg, err);
                deactivate();
            });
    }

    setTemplatePreview() {

        const lineBreak = '\n';
        const doubleLineBreak = '\n\n';

        let preview = `to: ${this.supportEmail || 'No email address configured'}`;

        const subject = this.emailSubject || 'No subject configured';

        preview += lineBreak + 'Subject: ' + `${this.dummyJobCode}: ${ subject }`;

        if (this.emailBodyPrefix) {
            preview += doubleLineBreak + this.emailBodyPrefix;
        }

        preview += doubleLineBreak + this.sampleInfo;

        if (this.emailBodySuffix) {
            preview += doubleLineBreak + this.emailBodySuffix;
        }

        this.templatePreview = preview;
    }
}
