import { FIELD_TYPE } from '@karve.it/interfaces/fields';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BaseZoneWithParentFragment, Location, LocationCreateBase, ServiceAreaQueryResult } from 'graphql.generated';
export const JobCreateLocationsActions = createActionGroup({
	source: 'Job Create Locations',
	events: {
        'Location Set Autocomplete': props<{
			locationType: string,
			location: LocationCreateBase,
		 }>(),
        'Location Set Manually': props<{
			locationType: string,
			location: LocationCreateBase,
		 }>(),

		'Set Location Fields': props<{
			fieldName: string,
			fieldValue: any,
			fieldType: FIELD_TYPE,
		}>(),

		'Location Resolve Service Area': props<{ query: string, areaCode: string }>(),
		'Location Resolve Service Area Success': props<{
			resolveServiceArea: ServiceAreaQueryResult,
		  }>(),
		'Location Resolve Service Area Error': props<{ error: Error  }>(),

		'Location Select Area Manually': props<{
			zone: BaseZoneWithParentFragment,
		 }>(),

		'Update Zone In Context': props<{ zoneId: string | null | undefined }>(),

		'Locations Create Success': props<{
			locationType: string,
			location: Partial<Location>,
		}>(),
		'Locations Create Error': props<{
			error: Error,
			locationType: string,
		 }>(),

		'Load Dock Location': emptyProps(),
		'Load Dock Location Success': props<{
			locationType: string,
			location: Partial<Location>,
		 }>(),
		'Load Dock Location Error': props<{ error: Error, locationType: string }>(),

		'Additional Location Selected': props<{
			locationType: string,
		}>(),
		'Additional Location Changed Type': props<{
			initialLocationType: string,
			updatedLocationType: string,
			location: LocationCreateBase | object,
		}>(),
		'Additional Location Removed': props<{
			locationType: string,
		}>(),

		'Location Changed For Job With Charges': emptyProps(),
		'Restore Server Copy Location And Area': emptyProps(),
	},
});
