
<div class="auth-container">
    <div class="auth-header"></div>
    <div
        class="auth-body"
    >
        <img
            class="app-logo"
            [src]="brandingService.watchIcon(256, 'light') | async"
            alt="Application Logo"
            width="256px"
            height="256px"
        />

        <app-auth></app-auth>
    </div>
    <app-auth-footer
        supportMessage="I'm having trouble on the login page"
    ></app-auth-footer>
</div>