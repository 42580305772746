import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AvailabilityV2Service } from '@karve.it/features';
import {QueryRef} from 'apollo-angular';

import { clone, cloneDeep } from 'lodash';
import { ValidationItem } from 'src/app/app.component';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { DeleteObjectComponent } from 'src/app/shared/delete-object/delete-object.component';
import { MutateAvailabilityTemplateComponent } from 'src/app/shared/mutate-availability-template/mutate-availability-template.component';
import { SubSink } from 'subsink';

import { AvailabilityTemplate, AvailabilityTemplatesGQL, AvailabilityTemplatesQuery, AvailabilityTemplatesQueryVariables, FullAvailabilityTemplateFragment } from '../../../generated/graphql.generated';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { PermissionService } from '../../services/permission.service';
import { ApplyTemplateComponent } from '../../shared/apply-template/apply-template.component';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

import { AssetWithConfig } from '../availability.interfaces';

export interface EditTemplateValidation {
  name: ValidationItem;
  timezone: ValidationItem;
}

export interface AvailabilityTemplateWithAsset extends AvailabilityTemplate {
  assets?: AssetWithConfig[];
}

@Component({
  selector: 'app-availability-details',
  templateUrl: './availability-details.component.html',
  styleUrls: ['./availability-details.component.scss']
})
export class AvailabilityDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('delObj', { static: false }) deleteComponent: DeleteObjectComponent;

  @ViewChild('applyTemplate', { static: false}) applyTemplateComponent: ApplyTemplateComponent;

  @Input() template: FullAvailabilityTemplateFragment;

  editTemplateAction = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      this.mutateService.openMutateObject({
        mutateType: 'update',
        objectType: 'availabilityTemplate',
        object: this.template,
      });
    },
    visible: false,
  };

  deleteTemplateAction = {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => {
      this.mutateService.openDeleteObject({
        objectId: this.template.id,
        objectName: this.template.name,
        objectType: 'availabilityTemplate',
      });
    },
    visible: false,
  };

  applyTemplateAction = {
    label: 'Apply Template',
    icon: 'pi pi-arrow-right',
    command: () => {
      this.applyTemplateComponent.preselectedTemplate = this.template;
      this.applyTemplateComponent.openDialog();
    },
    disabled: true,
    visible: false,
  };

  templateActions = [{
    label: 'Template Actions',
    items: [
      this.editTemplateAction,
      this.deleteTemplateAction,
      this.applyTemplateAction,
    ],
    visible: false,
  }];

  subs = new SubSink();

  start: Date = cloneDeep(this.timezoneHelper.defaultDate);
  end: Date = cloneDeep(this.timezoneHelper.defaultDate);

  blockInvalid = false;

  templateQueryRef: QueryRef<AvailabilityTemplatesQuery>;
  templateQH: WatchQueryHelper = {
    loading: false,
  };

  constructor(
    // Helpers
    private detailsHelper: DetailsHelperService,
    private timezoneHelper: TimezoneHelperService,
    private mutateService: FreyaMutateService,
    private permissionHandler: PermissionService,
    private freyaHelper: FreyaHelperService,
    // GQL
    private templatesGQL: AvailabilityTemplatesGQL,
  ) { }

  ngOnInit(): void {
    if (!this.template.name){
      this.retrieveTemplate();
    }

    this.subs.sink = this.detailsHelper.getObjectUpdates('AvailabilityTemplate').subscribe((res) => {
      this.retrieveTemplate();
    });

    this.initializePermissions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      [
        'availabilityV2.updateAvailabilityTemplate',
        'availabilityV2.deleteAvailabilityTemplates',
      ]
    ).subscribe((res) => {
      this.editTemplateAction.visible = res[0];
      this.applyTemplateAction.visible = res[0];
      this.deleteTemplateAction.visible = res[1];

      this.applyTemplateAction.disabled = this.freyaHelper.inRootOrCorporateZone;

      const [ templateActions ] = this.templateActions;

      templateActions.visible = templateActions.items.some((a) => a.visible);

      // Clone template actions so angular change detection propogates this var
      this.templateActions = clone(this.templateActions);
    });
  }

  /**
   * Retrieve or refetch the template being shown
   */
  retrieveTemplate(){
    const input: AvailabilityTemplatesQueryVariables = {
      input: {
        ids: [this.template.id],
      }
    };

    if (this.templateQueryRef){
      this.templateQueryRef.refetch(input);
      return;
    }

    this.templateQueryRef = this.templatesGQL.watch(input);

    this.subs.sink = this.templateQueryRef.valueChanges.subscribe((res) => {
      this.templateQH.loading = res.loading;
      if (!res.data.availabilityTemplates?.length){
        return;
      }
      this.template = res.data.availabilityTemplates[0];
    });
  }
}
