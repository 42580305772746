
<ng-container
    *ngIf="value && value.change !== undefined"
>
    <small
        *ngIf="!isNaN()"
        [class.success-text]="value.change > 0"
        [class.error-text]="value.change < 0"
    >({{ value.change < 0 ? '' : '+' }}{{ value.change * 100 | number : '1.2-2' }}%)</small>
    <small
        *ngIf="isNaN()"
    >
        (0%)
    </small>

</ng-container>

