import { Injectable, OnDestroy } from '@angular/core';
import { dayjs } from '@karve.it/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { getCurrentDateStringFromDayJs } from './time';

@Injectable({
  providedIn: 'root'
})
export class CalendarHelperService implements OnDestroy {

  public refreshScheduleData = new Subject<void>();

  subs = new SubSink();

  // Subject that contains a date string
  private dateChanged = new BehaviorSubject<string>(getCurrentDateStringFromDayJs());

  constructor() {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Called when the date of a calendar changes
   *
   * @param dateString The date string of the new date on the calendar
   */
  displayedDateChanged(dateString: string, viewType?: string){
    // If the calendar is not on a day view then default it to today
    if (viewType && viewType !== 'resourceTimelineDay'){
      dateString = getCurrentDateStringFromDayJs();
    } else {
      // Set the time to prevent defaulting to UTC
      dateString = dayjs.utc(dateString).format(`YYYY-MM-DDTHH:mm:ss`);
    }
    this.dateChanged.next(dateString);
  }

  /**
   * Get the current/last date displayed on a calendar
   *
   * @returns Date
   */
  public get displayedDate(): string {
    return this.dateChanged.value;
  }

}
