import { Component, OnDestroy, OnInit } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import { FullAvailabilityTemplateFragment, AvailabilityTemplatesQuery, AvailabilityTemplatesGQL } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

@Component({
  selector: 'app-availability-templates',
  templateUrl: './availability-templates.component.html',
  styleUrls: ['./availability-templates.component.scss']
})
export class AvailabilityTemplatesComponent implements OnInit, OnDestroy {

  // SubSink
  subs = new SubSink();

  // Query
  templates: FullAvailabilityTemplateFragment[];
  templatesQueryRef: QueryRef<AvailabilityTemplatesQuery>;
  templatesQH: WatchQueryHelper = {
    loading: true
  };

  // Table
  templateColumns = [
    {
      header: 'Name',
      field: 'name'
    },
    {
      header: 'Start Date',
      field: 'startDate'
    },
    {
      header: 'End Date',
      field: 'endDate'
    },
    {
      header: 'Areas',
      field: 'zones',
    }
  ];

  constructor(
    // Helpers
    private detailsHelper: DetailsHelperService,
    // GQL
    private templatesGQL: AvailabilityTemplatesGQL,
  ) { }

  ngOnInit(): void {
    this.retrieveTemplates();

    this.subs.sink = this.detailsHelper.getObjectUpdates('AvailabilityTemplate').subscribe((res) => {
      this.retrieveTemplates();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Retrieve the availability templates
   */
  retrieveTemplates() {
    if (this.templatesQueryRef) {
      this.templatesQueryRef.refetch();
    } else {
      this.templatesQueryRef = this.templatesGQL.watch({}, { fetchPolicy: 'cache-and-network' });

      this.subs.sink = this.templatesQueryRef.valueChanges.subscribe((res) => {
        this.templatesQH.loading = res.loading;
        if (!res.data) { return; }
        this.templates = res.data.availabilityTemplates;
      });
    }
  }

  /**
   * View the availability template in the details section
   *
   * @param template Template to view details for
   */
  viewTemplateDetails(template: FullAvailabilityTemplateFragment){
    this.detailsHelper.open('availability-template', template);
  }
}
