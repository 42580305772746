<p-dialog [modal]="true" class="wide-dialog" [(visible)]="showDialog">
    <ng-template pTemplate="header">
        <h4>{{ deleteLabel }} {{ type }}</h4>
    </ng-template>
    <p style="max-width: 40ch;">Are you sure you want to delete <strong>{{ name }}</strong>?</p>
    <br>
    <p class="error-text"><strong>{{warning}}</strong></p>
    <p-footer>
        <button pButton (click)="cancel()"
            icon="pi pi-times"
            label="Cancel"
            data-cy="cancel-button"
        ></button>
        <button pButton (click)="delete()"
            icon="pi pi-trash"
            label="Delete"
            class="p-button-danger"
            data-cy="delete-button"
        ></button>
    </p-footer>
</p-dialog>