import { on } from '@ngrx/store';

import { JobToolState } from '../../job-tool.reducer';

import { JobEditActions } from './jobv2-edit.actions';
import { defaultJobState, generateStateForUpdateJob } from '../../jobsv2-helpers';
import { LoadingState } from 'src/app/utilities/state.util';
import { cloneDeep } from 'lodash';

export const jobEditReducers = [
    on(JobEditActions.cleanPreviousJobData, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
	        ...defaultJobState as Partial<JobToolState>,
            ...(res.serverCopy ? { job: res.serverCopy } : {}),
        }
	}),
    on(JobEditActions.transferJobStateForEdit, (state: JobToolState, res): JobToolState => {
        const {
            jobInput,
            customerInput,
            fieldsInput,
            inventoryInput,
            locationsInputs,
            resolvedServiceArea,
            selectedTimeSlot,
        } = generateStateForUpdateJob(res.jobInfo) as any;
        return {
            ...state,
            jobInput: cloneDeep(jobInput),
            customerInput: cloneDeep(customerInput),
            fieldsInput: cloneDeep(fieldsInput),
            inventoryInput: inventoryInput && JSON.parse(inventoryInput),
            locationsInputs: cloneDeep(locationsInputs),
            resolvedServiceArea,
            selectedTimeSlot,
        }
	}),
    on(JobEditActions.removeJobLocationsFromAddableList, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            addableAdditionalLocations: res.addableAdditionalLocations,
        }
	}),
    on(JobEditActions.updateJob, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: LoadingState.LOADING,
            }
        }
	}),
    on(JobEditActions.updateJobSuccess, (state: JobToolState): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: LoadingState.LOADED,
            }
        }
	}),
    on(JobEditActions.updateJobError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: {
                    error: 'An error occurred during job updating. Changes are not saved' + res.error.message,
                }
            }
        }
	}),
    on(JobEditActions.discardChanges, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            changes: [],
        }
	}),
] as const;
