import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AttentionItemsFilter } from '../../generated/graphql.generated';

import { AttentionConfig, AttentionItemsService } from '../attention-items/attention-items.service';

@Component({
  selector: 'app-needs-attention',
  templateUrl: './needs-attention.component.html',
  styleUrls: ['./needs-attention.component.scss']
})
export class NeedsAttentionComponent implements OnInit {

  filter: AttentionItemsFilter = {};

  selectedReasons: AttentionConfig[] = [];

  constructor(
    public attentionItemsService: AttentionItemsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.preselectReasons();
  }

  setFilter(selectedConfigs: AttentionConfig[]) {
    this.filter = {
      ...this.filter,
      attentionExplanation: selectedConfigs.map((c) => c.attentionExplanation),
    };
  }

  setParams(configs: AttentionConfig[]) {
    const queryParams = {
      attentionExplanation: configs.map((c) => c.attentionExplanation).join(','),
    };
    this.router.navigate([], { queryParams });
  }

  preselectReasons() {
    const params$ = this.route.queryParams
      .pipe(
        filter(params => 'zone' in params),
        take(1),
      );

    combineLatest(params$, this.attentionItemsService.configs$.pipe(take(1)))
      .subscribe(([params, configs]) => {
        if (!params.attentionExplanation) { return; }

        const attentionParams = params.attentionExplanation.split(',');

        if (!attentionParams.length) { return; }

        const preselectedReasons = configs.filter((c) => attentionParams.includes(c.attentionExplanation));

        this.selectedReasons = preselectedReasons;

        this.setFilter(preselectedReasons);
      });
  }
}
