import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash';
import { InputText } from 'primeng/inputtext';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SetConfigValuesGQL, ConfigValue, ConfigValueInput, ClearConfigValuesGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { EditableListComponent, EditData } from '../../shared/editable-list/editable-list.component';

@Component({
  selector: 'app-single-config-settings-card',
  templateUrl: './single-config-settings-card.component.html',
  styleUrls: ['./single-config-settings-card.component.scss']
})
export class SingleConfigSettingsCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() currentZoneId?: string;

  @Input() config: ConfigValue;

  @ViewChildren(InputText) inputs: QueryList<InputText>;

  @ViewChild(EditableListComponent) editableListRef: EditableListComponent;

  subs = new SubSink();

  parsedValue: any;

  fallback: string;

  isArray: boolean;

  defaultParsedValue: any;

  editMode = false;

  modified = false;

  constructor(
    private setConfigGQL: SetConfigValuesGQL,
    private notify: FreyaNotificationsService,
    private clearConfigValuesGQL: ClearConfigValuesGQL,
  ) {}

  ngOnInit(): void {
    this.setParsedConfig();
  }

  setParsedConfig() {
    try {
      this.parsedValue = JSON.parse(this.config.value);

      this.fallback = this.config.fallback.value || 'None';

      this.isArray = Array.isArray(this.parsedValue);

      if (this.isArray) {
        this.parsedValue = this.parsedValue.map((item: any) => typeof item === 'object' ? JSON.stringify(item) : item);
      }

      this.defaultParsedValue = cloneDeep(this.parsedValue);

    } catch {
    }

  };

  ngAfterViewInit(): void {

    // Whenever new inputs are loaded (i.e. when entering edit mode),
    // set focus on first input
    this.subs.sink = this.inputs.changes
    .subscribe(() => {
      if (this.inputs.first) {
        this.inputs.first.el.nativeElement.focus();
      };
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  enterEditMode() {
    this.editMode = true;
  }

  discardChanges() {
    if (this.modified) {
      this.parsedValue = cloneDeep(this.defaultParsedValue);
      this.modified = false;
    };
    this.editMode = false;
  }

  save(editData?: EditData) {

    const value = editData?.items || this.parsedValue;

    const configInput: ConfigValueInput = {
      key: this.config.key,
      value: JSON.stringify(value),
    };

    this.subs.sink = this.setConfigGQL
    .mutate({ configs: [configInput] })
    .subscribe(() => {

      if (editData) {
        switch (editData.editType) {
          case 'delete':
            this.editableListRef.closeDeleteDialog();
            this.notify.success('Item(s) deleted');
            break;
          case 'add':
            this.editableListRef.closeAddDialog();
            this.notify.success('Item added');
            break;
          case 'edit':
            this.editableListRef.closeEditDialog();
            this.notify.success('Item edited');
            break;
        }

        this.parsedValue = editData.items;

      } else {
        this.notify.success('Changes saved.');
      }

      this.editMode = false;
      this.modified = false;
      this.defaultParsedValue = cloneDeep(this.parsedValue);
      this.config.zone = this.currentZoneId;
    });
  }

  markAsModified(): void {
    this.modified = true;
  }

  identify(index, item) {
    return item.key;
  }

  reset() {
    this.clearConfigValuesGQL.mutate({ keys: [ this.config.key ]})
      .subscribe(() => {
        this.config = this.config.fallback;
        this.setParsedConfig();
        this.notify.success(`Reset config ${this.config.key}`);
      }, (err) => {
        this.notify.apolloError(`Failed to reset config ${ this.config.key }`, err);
      });
  }
}
