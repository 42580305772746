<div class="card">
	<h4>Nice Jobs Integration</h4>
</div>

<div class="card">
	<div *ngIf="!loading; else loadingDiv">
		<a
			*ngIf="!refreshToken && clientId"
			[href]="niceJobsSignInLink"
		>Sign in to Nice Jobs</a>
		<div *ngIf="refreshToken">
			<p>Nice Jobs Integration is Active</p>
			<button
				pButton
				class="p-button-danger"
				label="Deactivate Integration"
				(click)="deactivateNiceJobs()"
			></button>
		</div>
		<p *ngIf="!clientId"><p-message
				severity="error"
				text="Client ID/Secret have not been configured for this application"
			></p-message></p>
	</div>
</div>

<ng-template #loadingDiv>
	<p-skeleton></p-skeleton>
</ng-template>