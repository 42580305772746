import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { PlusAuthenticationService } from '../../core/public-api';

import { AvailableZonesService } from '../../services/available-zones.service';

@Component({
  selector: 'app-no-available-zones',
  templateUrl: './no-available-zones.component.html',
  styleUrls: ['./no-available-zones.component.scss']
})
export class NoAvailableZonesComponent implements OnInit {

  redirectToLink: string;
  redirectToName: string;
  copyright = environment.copyright;

  constructor(
    private availableZones: AvailableZonesService,
    private router: Router,
    private auth: PlusAuthenticationService,
  ) { }

  ngOnInit(): void {
    const domainRedirect = this.availableZones.noAvailableZonesDomainRedirect.value;
    if (!domainRedirect) {
      this.router.navigate([ '/' ]);
      return;
    }

    const url = new URL(location.protocol + '//' + domainRedirect.domain);
    url.port = location.port;
    url.pathname = '/auth/login';

    this.redirectToLink = url.toString();
    this.redirectToName = domainRedirect.name;
  }

  logout() {
    return this.auth.logout();
  }

}
