import { Pipe, PipeTransform } from '@angular/core';
import {dayjs} from '@karve.it/core';

import { TimezoneHelperService } from '../services/timezone-helper.service';

/**
 * Turns unix seconds into a dayjs formatted date
 */
@Pipe({
  name: 'tzDate'
})
export class TzDatePipe implements PipeTransform {

  constructor(
    private timeZoneHelper: TimezoneHelperService,
  ){}

  transform(
    value: number,
    outputFormat: string = 'MMM d, y',
    inputFormat: 'unixSeconds' | 'unixMilliseconds' = 'unixSeconds',
    timezone?: string,
  ): string {
    let dayJsObject: dayjs.Dayjs;

    if (!timezone) {
      timezone = this.timeZoneHelper.getCurrentTimezone();
    }

    if (inputFormat === 'unixSeconds') {
      dayJsObject = dayjs.tz(value * 1000, timezone);
    } else if (inputFormat === 'unixMilliseconds') {
      dayJsObject = dayjs.tz(value, timezone);
    }

    if (!dayJsObject.isValid()){
      return 'Invalid Date';
    }

    return dayJsObject.format(outputFormat);
  }
}
