import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { CreateTaxInput, createTaxMutation, CreateTaxOutput, DeleteTaxesInput, deleteTaxesMutation, DeleteTaxesOutput, ListTaxesFunctionOutput, TaxesInput, taxesQuery, UpdateTaxInput, updateTaxMutation, UpdateTaxOutput } from '@karve.it/interfaces/taxes';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
    providedIn: 'root'
})
export class TaxService {

    constructor(public apollo: PlusApollo,) { }

    listtaxes(input: TaxesInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListTaxesFunctionOutput>({
            query: taxesQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchtaxes(input: TaxesInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListTaxesFunctionOutput>({
            query: taxesQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createTax(input: CreateTaxInput){
        return this.apollo.mutate<CreateTaxOutput>({
            mutation: createTaxMutation,
            variables: {
                ...input
            }
        });
    }

    updateTax(input: UpdateTaxInput){
        return this.apollo.mutate<UpdateTaxOutput>({
            mutation: updateTaxMutation,
            variables: {
                ...input
            }
        });
    }

    deleteTaxes(input: DeleteTaxesInput){
        return this.apollo.mutate<DeleteTaxesOutput>({
            mutation: deleteTaxesMutation,
            variables: {
                ...input
            }
        });
    }


}
