import { gql } from 'graphql-tag';

import { UserRole } from './auth';
import { ZoneDirection } from './common.gql';

// @deprecated
export type RoleInfo = UserRole;
export interface RoleSearch extends ZoneDirection {
  limit?: number;
  skip?: number;
  ids?: string[];
  names?: string[];
  deleted?: boolean;
  directZone?: string;
}

export interface ListRolesInput {
  search: RoleSearch;
}
export interface ListRolesOutput {
  roles: UserRole[];
}

export const roleBaseGQL = `
  id
  name
  color
  description
`;

export const listRolesQuery = gql`
query roles ($search: RoleSearch){
  roles(search: $search) {
    id
    name
    color
    description
    attributes
    zones {
      nodes {
        id
        name
        type
        color
        attributes
        avatar
      }
    }
  }
}
`;

export const createRole = gql`
  mutation createRole($input: [RoleInput!]!, $zone: String){
  roles{
    create(input: $input, zone: $zone){
      id
      name
      color
      description
      attributes
      zones {
        nodes {
          id
          name
          type
          color
          attributes
          avatar
        }
      }
    }
  }
}
`;

// @deprecated
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ListRolesQuery = listRolesQuery;


export interface EditRoleInput {
  ids: string[];
  input: RoleInput;
}

export interface EditRoleOutput {
  roles: {
    edit: UserRole[];
  };
}

export const editRole = gql`
  mutation editRole($ids: [String!], $input: RoleInput!){
  	roles(search:{ids:$ids}){
      	edit(input: $input){
          id
          name
          color
          description
          attributes
          zones {
            nodes {
              id
              name
              type
              color
              attributes
              avatar
            }
          }
        }
    }
}
`;

export interface DeleteRolesOutput {
  roles: {
    delete: boolean;
  };
}

export const removeRole = gql`
  mutation delete($roleIds: String!) {
    roles(search:{ids: [$roleIds]}){
      delete
    }
  }
`;


export const addRoleToUsersMutation = gql`
mutation addRole($users: [String!]!, $role: String!, $addToRoleZones: Boolean) {
  users( filter: { userIds: $users } ) {
    addRole(id: $role, addToRoleZones: $addToRoleZones)
  }
}
`;

export interface AddRoleToUsersInput {
    users: string[];
    role: string;
    addToRoleZones?: boolean;
}

export interface AddRoleToUsersOutput {
  users: { addRole: boolean };
}

export interface CreateRoleInput {
    input: RoleInput[];
    zone: string;
}

export interface CreateRoleOutput {
  createRoles: UserRole[];
}

export interface RoleInput{
  name: string;
  color: string;
  description: string;
  attributes?: string;
}

export interface ListRolesOutput {
    roles: UserRole[];
}

export interface RemoveRoleFromUsersInput {
    users: string[];
    role: string;
}

export interface RemoveRoleFromUsersOutput {
  users: { removeRole: boolean };
}

export interface AddPermissionToRoleInput {
  namespace: string;
  permission: string;
  type?: string;
  ids: string[];
}

export const addPermissionToRoleMutation = gql`
  mutation addPermissionToRole($ids: [String], $input: AddPermission!){
    roles(search:{ids: $ids}){
      addPermission(input: $input)
    }
  }
`;
