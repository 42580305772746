import { Pipe, PipeTransform } from '@angular/core';

import { BaseInvoiceFragment } from '../../generated/graphql.generated';

@Pipe({
  name: 'invoiceName'
})
export class InvoiceNamePipe implements PipeTransform {

  transform(value: BaseInvoiceFragment, customer: string, code: string, includeCustomerName = true): string {
		if (!code || !value) {
			return 'None';
		}

		const fragments = [code];

		if (includeCustomerName) {
			fragments.push(customer);
		}

		fragments.push(`#${value.invoiceNumber}`);

		return fragments.join(' ');
	}


}
