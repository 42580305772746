<div [formGroup]="bookOffForm">
  <ng-template #duration>
    <div formGroupName="duration" class="p-grid">

      <div class="p-col">
        <label>Start</label>
        <br>
        <p-calendar
          formControlName="start"
          placeholder="Enter Start Date"
          showTime="true"
          appendTo="body"
          class="full-width"
          dataType="string"
          [dateFormat]="dateFormat"
          [hourFormat]="timeFormat"
          [stepMinute]="15"
          ></p-calendar>
        <app-validation-messages
          [control]="bookOffForm.get('duration.start')"
          [requireTouched]="true"
          ></app-validation-messages>
        <br>
      </div>

      <div class="p-col">
        <label>End</label>
        <br>
        <p-calendar
          formControlName="end"
          placeholder="Enter End Date"
          showTime="true"
          appendTo="body"
          class="full-width"
          dataType="string"
          [dateFormat]="dateFormat"
          [hourFormat]="timeFormat"
          [stepMinute]="15"
          ></p-calendar>
        <app-validation-messages
          [control]="bookOffForm.get('duration.end')"
          [requireTouched]="true"
          ></app-validation-messages>
        <br>
      </div>
      <div class="p-col-12 p-text-center">
        <app-validation-messages
          [control]="bookOffForm.get('duration')"
          [requireTouched]="true"
          ></app-validation-messages>
      </div>
    </div>

  </ng-template>

  <ng-template #description>
    <label>Description</label>
    <br>
    <textarea
      pInputTextarea
      formControlName="description"
      placeholder="Optional: description for why this asset is booked off"
      class="full-width"
      ></textarea>
  </ng-template>

</div>

<ng-template #durationReview>
    <span>{{bookOffForm.value.duration.start | date : 'h:mm a, MMM, d, y'}} - {{bookOffForm.value.duration.end | date : 'h:mm a, MMM, d, y'}}</span>
</ng-template>

<app-mutate-object
    [fg]="bookOffForm"
    objectType="Book Off"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
    [titleText]="mutateType === 'create' ? 'Book Off Asset' : 'Edit Book Off'"
></app-mutate-object>
