import { createFeatureSelector, createSelector } from "@ngrx/store";
import { JobToolState } from "../../job-tool.reducer";
import { LoadingState } from "src/app/utilities/state.util";
import { EstimatesJobFragment } from "graphql.generated";

export const selectJobToolState = createFeatureSelector<JobToolState>('jobTool');

export const selectJobUpdatingErrors = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;

    const errorKeys = ['updateCustomer', 'updateJob', 'setFields'];
    const errors: string[] = [];

    errorKeys.forEach(key => {
      if (callState && callState[key]?.error) {
        errors.push(callState[key].error);
      }
    });

    return errors;
  }
);

export const selectJobUpdatingInProgress = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;
    const loadingKeys = ['updateCustomer', 'updateJob', 'setFields'];

    return loadingKeys.some(key => callState && callState[key] === LoadingState.LOADING);
  }
);

export const selectCanChangeArea = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const job = state?.job;
    const hasTransactions = job?.transactions?.length;
    const hasCharges = job?.charges?.length;
    const hasDiscounts = (job as unknown as EstimatesJobFragment)?.discounts?.length;
    return !(hasTransactions || hasCharges || hasDiscounts);
  }
);

export const selectServerCopyAreaId = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const zone = state?.job?.zone;
    if (zone && zone?.type === 'area') {
      return zone?.id;
    } else return undefined;
  }
);

export const jobUpdateSelectors = {
  selectJobUpdatingErrors,
  selectJobUpdatingInProgress,
  selectCanChangeArea,
  selectServerCopyAreaId,
};