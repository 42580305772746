import { generateUUID } from '../../utilities/state.util';

const sessionId = generateUUID();

export function getUniqueSessionID() {
	return sessionId;
}

export interface MutationOptions {
	zone?: string;
	actionId?: string;
}

export function generateMutationHeaders(
	opts?: MutationOptions,
) {
	const headers = {
		'x-session': getUniqueSessionID(),
		'x-actionid': opts?.actionId || generateUUID(),
	};

	if (opts?.zone) {
		headers['x-zone'] = opts?.zone;
	}

	return headers;

}

export function generateMutationContext(
	opts?: MutationOptions,
) {
	return {
		headers: generateMutationHeaders(opts),
	};
}
