import { UntypedFormControl, ValidationErrors } from '@angular/forms';

import { BaseRuleFragment } from '../../../generated/graphql.generated';

export const propTypeOptions = [ 'number', 'string', 'boolean', 'array-str', 'array-num', 'array-bool' ] as const;
export type PropTypeOptions = typeof propTypeOptions[number];

export enum CONDITIONS {
	OR = 'OR',
	XOR = 'XOR',

	AND = 'AND',
	NOT = 'NOT',

	/**
	 * If truthy, whether this property exists on the provided input.
	 * If falsy, whether this property does not exist on the provided input.
	 */
	EXISTS = '_EXISTS',
	EQUALS = '_EQUALS',
	RAWEQUALS = '_RAWEQUALS',
	// whether an item is in an array
	IN = '_IN',
	// whether an array has an item
	INCLUDES = '_INCLUDES',
	LT = '_LT',
	LTE = '_LTE',
	GT = '_GT',
	GTE = '_GTE',
	TYPEOF = '_TYPEOF',
}

export const describeCondition: {
	[ c in CONDITIONS ]: (property, value) => string;
} = {
	[CONDITIONS.XOR]: (property, value) => ``,
	[CONDITIONS.OR]: (property, value) => ``,
	[CONDITIONS.AND]: (property, value) => ``,
	[CONDITIONS.NOT]: (property, value) => ``,
	[CONDITIONS.EQUALS]: (property, value) => `${ property } equals [${ typeof value }:${ value }]`,
	[CONDITIONS.RAWEQUALS]: (property, value) => `${ property } raw equals ${ value }`,
	[CONDITIONS.EXISTS]: (property, value) => `${ property } ${ value ? 'exists' : 'does not exist' }`,
	[CONDITIONS.GT]: (property, value) => `${ property } is greater than ${ +value }`,
	[CONDITIONS.GTE]: (property, value) => `${ property } is greater than or equal to ${ +value }`,
	[CONDITIONS.LT]: (property, value) => `${ property } is lesser than ${ +value }`,
	[CONDITIONS.LTE]: (property, value) => `${ property } is lesser than or equal to ${ +value }`,
	[CONDITIONS.IN]: (property, value) =>
        `${ property } is ${ Array.isArray(value) ? `in the set [ ${ value.join(', ') } ]` : `in an empty set (this will never happen)` }`,
	[CONDITIONS.INCLUDES]: (property, value) => `${ property } is an array and includes [${ typeof value }:${ value }]`,
	[CONDITIONS.TYPEOF]: (property, value) => `${ property } is of type ${ value }`,
};

export const conditionalInfo: {
    [ key: string ]: {
        alias: string;
        block?: boolean;
        typeOptions?: PropTypeOptions[];
        enum?: string[];
    };
} = {
    XOR: {
        alias: 'XOR (either but not both)',
        block: true,
    },
    OR: {
        alias: 'OR',
        block: true,
    },
    AND: {
        alias: 'AND',
        block: true,
    },
    NOT: {
        alias: 'NOT',
        block: true,
    },
    _EQUALS: {
        alias: 'equals',
    },
    _RAWEQUALS: {
        alias: 'raw equals',
    },
    _EXISTS: {
        alias: 'exists',
        typeOptions: [ 'boolean' ],
    },
    _GT: {
        alias: 'is greater than',
        typeOptions: [ 'number' ],
    },
    _GTE: {
        alias: 'is greater than or equal to',
        typeOptions: [ 'number' ],
    },
    _LT: {
        alias: 'is lesser than',
        typeOptions: [ 'number' ],
    },
    _LTE: {
        alias: 'is lesser than or equal to',
        typeOptions: [ 'number' ],
    },
    _IN: {
        alias: 'is in',
        typeOptions: [ 'array-str', 'array-num', 'array-bool' ],
    },
    _INCLUDES: {
        alias: 'includes',
    },
    _TYPEOF: {
        alias: 'is of type',
        typeOptions: [ 'string' ],
        enum: [ 'string', 'number', 'boolean', 'null', 'undefined', 'array' ],
    },

};

export const advancedConditions = [ 'XOR', 'OR', 'AND', 'NOT' ];

export const conditionalTypeOptions: {
    label: string;
    value: PropTypeOptions;
    allowedConditions?: string[];
    disabledConditions?: string[];
}[] = [
    {
        label: 'Number',
        value: 'number',
        disabledConditions: [ '_INCLUDES' ],
    },
    {
        label: 'Text',
        value: 'string',
        disabledConditions: [ '_INCLUDES', '_LT', '_LTE', '_GT', '_GTE' ],
    },
    {
        label: 'Boolean',
        value: 'boolean',
        allowedConditions: [ '_TYPEOF', '_IN', '_EXISTS', '_EQUALS', '_RAWEQUALS' ],
    },
    {
        label: 'Array (text)',
        value: 'array-str',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
    {
        label: 'Array (number)',
        value: 'array-num',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
    {
        label: 'Array (boolean)',
        value: 'array-bool',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
];

function getConditionTypeOption(valueType: string) {
    return conditionalTypeOptions.find((cto) => cto.value === valueType);
}

export function conditionArrayValidator(fc: UntypedFormControl): ValidationErrors | null {
    const arr: ConditionArray = fc.value;
    if (!Array.isArray(arr)) {
        return {
            conditions: 'must be an array'
        };
    }

    let errs: ValidationErrors | null = null;
    for (const c of arr) {
        const res = validateCondition(c);
        if (res) {
            errs = {
                ...errs,
                ...res,
            };
        }
    }

    return errs;

    function validateCondition(
        c: ConditionInfo,
        parent?: string,
    ) {
        const key = `${ parent ? parent + '.' : '' }${ c.property || '' }${ c.condition || '' }`;
        if (!c) {
            return {
                [ key ]: 'must be an object',
            };
        }

        if (advancedConditions.includes(c.condition)) {
            if (!Array.isArray(c.value)) {
                return {
                    [ key ]: 'value must be an array',
                };
            }

            let valErrs;
            for (const val of c.value) {
                const res = validateCondition(val, key);
                if (res) {
                    valErrs = {
                        ...valErrs,
                        ...res,
                    };
                }
            }

            return valErrs;
        }

        if (!c.condition) {
            return {
                [ key ]: 'must have a condition',
            };
        }

        if (!c.property) {
            return {
                [ key ]: 'must have a property',
            };
        }

        if (!c.type) {
            return {
                [ key ]: 'must have a type',
            };
        }

        return null;
    }

}

export function determineValueTypeOption(value: any) {
    const valueType = typeof value;
    const typeOption = getConditionTypeOption(valueType);
    if (typeOption) {
        return typeOption;
    }

    if (Array.isArray(value) && typeof value[0] === 'string') {
        return getConditionTypeOption('array-str');
    }

    if (Array.isArray(value) && typeof value[0] === 'number') {
        return getConditionTypeOption('array-num');
    }

    if (Array.isArray(value) && typeof value[0] === 'boolean') {
        return getConditionTypeOption('array-bool');
    }

    return undefined;
}

export const conditionalConditionOptions = Object.entries(CONDITIONS)
    .filter(([ label, value ]) => !advancedConditions.includes(label))
    .map(([ label, value ]) => {
        const info = conditionalInfo[value];

        let newLabel = label;
        if (info && info.alias) {
            newLabel = info.alias;
        }

        return {
            label: newLabel,
            value,
        };
    });


export interface ConditionInfo {
    property?: string;
    condition?: string;
    type?: PropTypeOptions;
    value?: any;
    }

export type ConditionArray = Array<ConditionInfo>;


export const subconditionalKeys = [ 'AND', 'OR', 'XOR', 'NOT' ];

export function describeConditionObject(
	obj: any,
	// TODO: start/end block characters
) {
	const keys = Object.keys(obj);
	const basicKeys = keys.filter((k) => !subconditionalKeys.includes(k));

	const basicKeyResults: string[] = [];

	for (const key of basicKeys) {
		if (!key) { continue; }

		const conditionType = Object.values(CONDITIONS).find((c) => key.endsWith(c));
		// Skip if this is not a supported condition.
		if (!conditionType) { continue; }
		if (subconditionalKeys.includes(conditionType)) { continue; }


		const func = describeCondition[conditionType];
		// Not a supported function
		if (!func) { continue; }

		const propertyKey = key.slice(0, key.length - conditionType.length);
		if (propertyKey.length === 0) { continue; }
		const dataVal = obj[key];

		basicKeyResults.push(func(propertyKey, dataVal));
	}

	let str = basicKeyResults.join(' and ');

	if (obj.AND) {
		const andRes = describeConditionObject(obj.AND);
		str = [ str, andRes ].join(' and ');
	}

	if (obj.NOT) {
		const notRes = describeConditionObject(obj.NOT);
		// TODO: what if not res is empty?
		str = `${ str } and not (${ notRes })`;
	}

	if (obj.OR) {
		const orRes = describeConditionObject(obj.OR);
		str = `(${ str }) or (${ orRes })`;
	}

	if (obj.XOR) {
		const xorRes = describeConditionObject(obj.XOR);
		str = `(${ str }) exclusively or (${ xorRes })`;
	}

	if (str === '') {
		str = 'no condition';
	}

	return str;
}
