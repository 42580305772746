<div
    class="create-discount-input"
    [formGroup]="discountForm"
>
    <ng-template #name>
        <div *appVar="discountForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Name"
                formControlName="name"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="name-text-field"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
            <br>
            <small *ngIf="isSingleUse">Names for single use discounts cannot be reused within the same zone.</small>
        </div>
    </ng-template>
    <ng-template #code>
        <div *appVar="discountForm.get('code') as control">
            <label>Code</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Promo Code"
                formControlName="code"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="code-text-field"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #discountType>
        <div *appVar="discountForm.get('discountType') as control">
            <label>Discount Type</label>
            <br />
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #amount>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-d-flex">
                    <div *appVar="discountForm.get('discountType') as parentControl" [style]="{ width: '12rem' }" class="p-fluid p-pr-3">
                        <label>Discount Type</label>
                        <br />
                        <p-dropdown
                            class="full-width"
                            [options]="discountTypes"
                            formControlName="discountType"
                            appendTo="body"
                            data-cy="type-drop-down-field"
                            >
                        </p-dropdown>
                    </div>
                    <div *appVar="discountForm.get('amount') as control" style="flex: 1" [class.p-fluid]="responsiveHelper.isSmallScreen">
                        <label>Amount</label>
                        <br />
                        <p-inputNumber
                            formControlName="amount"
                            [showButtons]="true"
                            [step]="isPercentage ? 1 : 0.25"
                            [mode]="isPercentage ? 'decimal' : 'currency'"
                            [suffix]="isPercentage ? '%' : undefined"
                            [currency]="currency"
                            [minFractionDigits]="isPercentage ? 0 : 2"
                            [maxFractionDigits]="isPercentage ? 0 : 2"
                            placeholder="Amount"
                            [max]="isPercentage ? 100 : undefined"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            data-cy="amount-number-field"
                            ></p-inputNumber>
                        <br>
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                            ></app-validation-messages>
                    </div>
                </div>
            </div>
            <!-- <div class="p-col-12 p-md-8"> -->
            <!--     <br> -->
            <!--     <small *ngIf="!isSingleUse && !isCustomRange"> -->
            <!--         This discount will have a fixed amount of --> 
            <!--         {{ discountForm.value.amount | formatAmount : discountForm.value.discountType : currency : '___' }} -->
            <!--         whenever it is applied. -->
            <!--     </small> -->
            <!--     <small *ngIf="!isSingleUse && isCustomRange"> -->
            <!--         This discount will have a variable amount between --> 
            <!--         {{ customAmountRangeSubForm.value.min | formatAmount : discountForm.value.discountType : currency : '___' }} -->
            <!--         and -->
            <!--         {{ customAmountRangeSubForm.value.max | formatAmount : discountForm.value.discountType : currency : '___' }}. -->
            <!--         If the user specifies no amount when applying the discount, it will be applied with a default amount of -->
            <!--         {{ discountForm.value.amount | formatAmount : discountForm.value.discountType : currency : '___' }}. -->
            <!--     </small> -->
            <!-- </div> -->
            <ng-container *ngIf="!isSingleUse">

                <div class="p-col-12 p-mt-2" *appVar="discountForm.get('customAmountRange') as parentControl">
                    <h5>Range <i class="pi pi-info-circle help-link" (click)="openCreateDiscountHelp()"></i></h5>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div formGroupName="customAmountRange" class="p-d-flex p-ai-center">
                                <div class="p-pr-4">
                                    <label>Enable</label>
                                    <br>
                                    <p-inputSwitch
                                        [(ngModel)]="isCustomRange"
                                        [ngModelOptions]="{standalone: true}"
                                        (onChange)="onCustomRangeSwitch($event.checked)"
                                        ></p-inputSwitch>
                                </div>
                                <div *appVar="customAmountRangeSubForm.get('min') as minControl" class="p-fluid floating-val-msg">
                                    <label>Min</label>
                                    <br />
                                    <p-inputNumber
                                        formControlName="min"
                                        [showButtons]="true"
                                        [step]="isPercentage ? 1 : 0.25"
                                        [mode]="isPercentage ? 'decimal' : 'currency'"
                                        [suffix]="isPercentage ? '%' : undefined"
                                        [currency]="currency"
                                        [minFractionDigits]="isPercentage ? 0 : 2"
                                        [maxFractionDigits]="isPercentage ? 0 : 2"
                                        [min]="0"
                                        placeholder="Min"
                                        [class.p-invalid-input]="(parentControl.errors && parentControl.touched) || (!minControl.valid && minControl.touched)"
                                        [style]="{ width: '9rem' }"
                                        ></p-inputNumber>
                                    <app-validation-messages [control]="minControl">
                                    </app-validation-messages>
                                </div>
                                <div class="p-px-3" style="flex: 1">
                                    <br>
                                    <p-slider
                                        *ngIf="!responsiveHelper.isSmallScreen"
                                        [(ngModel)]="sliderVal"
                                        [ngModelOptions]="{standalone: true}"
                                        [range]="true"
                                        (onChange)="updateCustomAmountSilently($event.values)"
                                        [disabled]="!isCustomRange"
                                        [min]="0"
                                        [max]="sliderMax"
                                        ></p-slider>
                                </div>
                                <div *appVar="customAmountRangeSubForm.get('max') as maxControl" class="p-fluid floating-val-msg">
                                    <label>Max</label>
                                    <br />
                                    <p-inputNumber
                                        formControlName="max"
                                        [showButtons]="true"
                                        [step]="isPercentage ? 1 : 0.25"
                                        [mode]="isPercentage ? 'decimal' : 'currency'"
                                        [suffix]="isPercentage ? '%' : undefined"
                                        [currency]="currency"
                                        [minFractionDigits]="isPercentage ? 0 : 2"
                                        [maxFractionDigits]="isPercentage ? 0 : 2"
                                        placeholder="Max"
                                        [max]="isPercentage ? 100 : undefined"
                                        [class.p-invalid-input]="(parentControl.errors && parentControl.touched) || (!maxControl.valid && maxControl.touched)"
                                        [style]="{ width: '9rem' }"
                                        ></p-inputNumber>
                                    <app-validation-messages [control]="maxControl">
                                    </app-validation-messages>
                                </div>
                            </div>
                            <app-validation-messages [control]="parentControl" class="p-text-center">
                            </app-validation-messages>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
    <ng-template #active>
        <p-inputSwitch
            formControlName="active"
            name="activeToggle"
            data-cy="active-switch-field"
        ></p-inputSwitch>
        <p for="activeToggle">Click to toggle if discount is active.</p>
    </ng-template>
    <ng-template #startsAt>
        <div *appVar="discountForm.get('startsAt') as control">
            <label>Discount Start Date</label>
            <br />
            <app-masked-calendar
                formControlName="startsAt"
                [showOnFocus]="false"
                data-cy="start-at-calendar-field"
            ></app-masked-calendar>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #expiresAt>
        <div *appVar="discountForm.get('expiresAt') as control">
            <label>Discount Expiration Date</label>
            <br />
            <app-masked-calendar
                formControlName="expiresAt"
                [showOnFocus]="false"
                data-cy="expires-at-calendar-field"
            ></app-masked-calendar>

            <button
                pButton
                class="p-ml-2"
                icon="pi pi-times"
                label="Clear"
                [disabled]="!discountForm.value.expiresAt"
                (click)="clearExpiresAtInput()"
            ></button>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>

    <ng-template #maxRedemptions>
        <div *appVar="discountForm.get('maxRedemptions') as control">
            <label>Max Redemptions</label>
            <br />
            <p-inputNumber
                formControlName="maxRedemptions"
                placeholder="Max Redemptions"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="max-redemptions-text-field"
            ></p-inputNumber>
            <br>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <ng-template #events>
        <div *appVar="discountForm.get('events') as control">
            <app-event-select
                formControlName="events"
                [filter]="{jobId: job?.id}"
                [preselectEventId]="preselectEventId"
            ></app-event-select>
        </div>
    </ng-template>
</div>

<ng-template #amountReview>
    <span *ngIf="mutateType === 'create'">
        Amount: {{ discountForm.value.amount | formatAmount : selectedDiscountType : currency }} | 
    </span>
    <span *ngIf="mutateType === 'edit'">
        Amount: {{ (discount?.amount / 100) | formatAmount : selectedDiscountType : currency }}
    </span>
    Range: {{ customAmountRangeSubForm.value | discountCustomAmountRange : selectedDiscountType : currency }}
</ng-template>

<ng-template #startsReview>
    <span>
        {{discountForm.value.startsAt ? (discountForm.value.startsAt): 'Today'}}
    </span>
</ng-template>

<ng-template #expiresReview>
    <span>
        {{discountForm.value.expiresAt ? (discountForm.value.expiresAt) : 'No Expiration Date'}}
    </span>
</ng-template>

<ng-template #maxRedemptionsReview>
    <span>
        {{discountForm.value.maxRedemptions ? discountForm.value.maxRedemptions : 'Unlimited'}}
    </span>
</ng-template>

<ng-template #timesRedeemedReview>
    <span>
        {{discount.timesRedeemed}}
    </span>
</ng-template>

<app-mutate-object
    #mutate
    [fg]="discountForm"
    objectType="Discount"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>

<app-delete-object
    #delete
    [name]="discount?.name"
    type="Discount"
    (deleted)="deleteDiscount()"
></app-delete-object>
