import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DiscountDetails, DiscountTypes } from '../../generated/graphql.generated';
import { convertCentsToDollars } from '../lib.ts/currency.util';

@Pipe({
  name: 'discountCustomAmount'
})
export class DiscountCustomAmountPipe implements PipeTransform {

  constructor(private currency: CurrencyPipe) {}

  transform(
    discountDetails: DiscountDetails,
    currency: string = 'CAD'
  ): string {

    const hasAppliedCustomAmount = discountDetails?.appliedCustomAmount !== null && discountDetails?.appliedCustomAmount !== undefined;
    const hasAmount = discountDetails?.discount.amount !== null && discountDetails?.discount.amount !== undefined;

    if (!hasAppliedCustomAmount && !hasAmount) {
      console.warn(`Could not resolve amount for applied discount: ${discountDetails.appliedId}`);
      console.warn('To use this pipe, make sure to fetch DiscountDetails.Discount.amount');
      return;
    }

    const amount = hasAppliedCustomAmount ? discountDetails.appliedCustomAmount : discountDetails.discount.amount;

    if (!discountDetails.discount.discountType) {
      console.warn(`Cannot resolve discount type for applied discount: ${discountDetails.appliedId}`);
      console.warn('To use this pipe, make sure to fetch DiscountDetails.Discount.discountType');
      return;
    }
    if (discountDetails.discount.discountType === DiscountTypes.Amount) {
      return this.currency.transform(convertCentsToDollars(amount), currency);
    }

    return `${amount}%`;
  }

}
