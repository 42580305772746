import { Injectable, Input } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { ProductsInput, ListProductsOutput, generateProductsQuery, CreateProductsInput, CreateProductsOutput, createProductsMutation, UpdateProductsInput, UpdateProductsOutput, updateProductsMutation, DeleteProductsInput, DeleteProductsOutput, deleteProductsMutation, GenerateProductsQueryInput, CreateProductInput,  } from '@karve.it/interfaces/products';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

    constructor(private apollo: PlusApollo){}

    /**
     * List Products
     *
     * @param input Criteria to filter by
     * @param queryInput what to resolve in the query
     * @param opts Options for the query
     * @returns Query Ref
     */
    listProducts(input: ProductsInput, queryInput: GenerateProductsQueryInput, opts?: KarveRequestOptions){
      return this.apollo.query<ListProductsOutput>({
        query: generateProductsQuery(queryInput),
        variables: {
          ...input,
        },
        ...parseRequestOptions(opts),
      });
    }

    /**
     * List Products
     *
     * @param input Criteria to filter by
     * @param queryInput what to resolve in the query
     * @param opts Options for the query
     * @returns Query Ref
     */
     watchProducts(input: ProductsInput, queryInput: GenerateProductsQueryInput, opts?: KarveWatchRequestOptions){
      return this.apollo.watchQuery<ListProductsOutput>({
        query: generateProductsQuery(queryInput),
        variables: {
          ...input,
        },
        ...parseRequestOptions(opts),
      });
    }

    /**
     * Create new products.
     *
     * @param input Values for the new products.
     * @returns QueryRef, The created products.
     */
    createProducts(input: CreateProductsInput){
      return this.apollo.mutate<CreateProductsOutput>({
        mutation: createProductsMutation,
        variables: {
          ...input,
        }
      });
    }


    /**
     * Edit Product.
     *
     * @param input New values for the product.
     * @returns QueryRef, True if operation is successful.
     */
    editProduct(input: UpdateProductsInput){
      return this.apollo.mutate<UpdateProductsOutput>({
        mutation: updateProductsMutation,
        variables: {
          ...input,
        }
      });
    }

    /**
     * Delete a product from the system.
     *
     * @returns QueryRef, True if the delete is successful.
     */
    deleteProduct(ids: string[]){
      return this.apollo.mutate<DeleteProductsOutput>({
        mutation: deleteProductsMutation,
        variables: {
          ids
        }
      });
    }

}
