import { ValidationItem } from '../../lib.ts/validation';

export const assetTypes = [
    'Truck',
    'Estimator',
    'Overnight Storage',
    'Delivery'
];

export const assetTypesDropdown = assetTypes.map((t) => ({ label: t, value: t }));


export interface CreateAssetFormValidation{
    name: ValidationItem;
}


export const assetValidation: CreateAssetFormValidation = {
    name: {
        message: 'Name is Required',
    },
};
