
<iframe *ngIf="!iFrameRefreshing && selectedView"
	width="100%"
	height="100%"
	[ngClass]="{'invisible': mapLoading}"
	allowfullscreen
	allow="accelerometer; gyroscope"
	referrerpolicy="no-referrer-when-downgrade"
	[src]="selectedView.url | trustUrl"
	(load)="mapLoadingOff()"
	data-cy="iframe-map"
>
</iframe>
<p-skeleton
	*ngIf="mapLoading || !selectedView"
	width="100%"
	height="100%"
	styleClass="p-skeleton-override"
></p-skeleton>
<!-- <small class="p-mt-2">Route for the map is calculated using current traffic conditions, times may vary.</small> -->
