import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { kebabCase } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { ParsedTransactionType } from 'src/app/settings/settings.component';
import { SetConfigValuesGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-transaction-type',
  templateUrl: './mutate-transaction-type.component.html',
  styleUrls: ['./mutate-transaction-type.component.scss']
})
export class MutateTransactionTypeComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  @ViewChild('key') keyRef: TemplateRef<any>;
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('provider') providerRef: TemplateRef<any>;
  @ViewChild('enabled') enabledRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() transactionType: ParsedTransactionType;

  steps: MutateObjectElement[];

  subs = new SubSink();

  transactionTypeForm = new UntypedFormGroup({
    key: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    nameSameAsKey: new UntypedFormControl(false),
    provider: new UntypedFormControl(''),
    enabled: new UntypedFormControl(false)
  });

  defaultValues = this.transactionTypeForm.value;

  constructor(
    private setConfigGQL: SetConfigValuesGQL,
    private detailsHelper: DetailsHelperService,
    private notify: FreyaNotificationsService
  ) { }

  ngOnInit(): void {

    // Fill out and enable/disable name control depending on whether
    // 'same as key' option is selected
    this.subs.sink = this.transactionTypeForm.controls.nameSameAsKey.valueChanges
    .subscribe((val) => {
      if (val) {
        const key = this.transactionTypeForm.controls.key.value;
        this.transactionTypeForm.controls.name.setValue(key);
        this.transactionTypeForm.controls.name.disable();
        return;
      };
      this.transactionTypeForm.controls.name.reset();
      this.transactionTypeForm.controls.name.enable();
    });

    // If 'same as key' option is selected,
    // reset name and nameSameAsKey controls if user goes back and modifies key
    this.subs.sink = this.transactionTypeForm.controls.key.valueChanges
    .subscribe(() => {
      if (this.transactionTypeForm.controls.nameSameAsKey.value) {
        this.transactionTypeForm.controls.name.enable();
        this.transactionTypeForm.controls.name.reset();
        this.transactionTypeForm.controls.nameSameAsKey.reset();
      };
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openDialog() {
    this.steps = [
      { name: 'Key', ref: this.keyRef, control: 'key', type: 'text'},
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
      { name: 'Provider', ref: this.providerRef, control: 'provider', type: 'text'},
      { name: 'Enabled', ref: this.enabledRef, control: 'enabled', type: 'boolean'}
    ];

    this.transactionTypeForm.reset(this.defaultValues);
    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.openDialog();
  }

  mutateTransactionType() {
    if (this.mutateType === 'create') {
      this.createTransactionType();
    }
  }

  createTransactionType() {
    const { key, name, provider, enabled } = this.transactionTypeForm.controls;

    const value = {
      name: name.value,
      provider: provider.value,
      enabled: enabled.value
    };

    const transactionType = {
      key: ['payment-type', kebabCase(key.value)].join('.'),
      value: JSON.stringify(value)
    };

    this.subs.sink = this.setConfigGQL
    .mutate({ configs: [transactionType]})
    .subscribe((res) => {
      this.mutateRef.closeDialog();
      this.notify.addToast.next({ severity: 'success', summary: 'Transaction type created.' });
      this.detailsHelper.pushUpdate({
        id:res.data.setConfigValues[0].key,
        type:'Configs',
        action:'create'
      });
    });
  }
}
