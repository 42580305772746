<section class="workorders">
    <div>
        <p-button
            label="Open in legacy processor"
            outlined="true"
            (click)="openLegacyClick()"
            [style]="{
                'padding': '15px 15px',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                'font-weight': 'bold',
                'line-height': '1.5rem'
            }"
        ></p-button>
    </div>
</section>
