import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  bytesInGB = 1000000000;
  bytesInMB = 1000000;
  bytesInKB = 1000;

  constructor(){}

  transform(size: number): unknown {
    if ((size / this.bytesInGB) > 1) {
      return `${(size / this.bytesInGB).toFixed(2)} GB`;
    } else if ((size / this.bytesInMB) > 1) {
      return `${(size / this.bytesInMB).toFixed(2)} MB`;
    } else {
      return `${(size / this.bytesInKB).toFixed(2)} KB`;
    }
  }

}
