//#region types

import { gql } from 'graphql-tag';

export interface FormResponse{
    id: string;
    dateStarted?: number;
    dateSubmitted?: number;
    dateEdited: number;
    deletedAt?: number;
    responder?: string;
    fieldResponses?: FieldResponse[];
    sectionResponses?: FormResponse[];
    unAnsweredFields?: string[];
    confirmationPage?: string;
    validated?: boolean;
    formSecret?: string;
    formTypeId?: string;
}

export interface FormType{
    id: string;
    createdOn: number;
    updatedOn: number;
    deletedAt?: number;
    limitToOneResponse?: boolean;
    allowAnonymousEntries?: boolean;
    permissionsRequired: string[];
    editAfterSubmit?: boolean;
    confirmationPage?: string;
    confirmationMessage?: string;
    title?: string;
    description?: string;
    customAttributes?: string[];
    sections?: FormType[];
    fields?: FormField[];
}

export interface FormField {
    id: string;
    createdOn: number;
    updatedOn: number;
    deletedAt?: number;
    order?: number;
    customAttributes?: string[];
    fieldName: string;
    typeName: string;
    questionText?: string;
    isFieldRequired?: boolean;
    options?: Option[];
    minChecked?: number;
    maxChecked?: number;
    format?: string;
    pattern?: string;
    minLength?: number;
    maxLength?: number;
}

export interface FieldResponse {
    id: string;
    fieldId: string;
    dateSubmitted: number;
    datedEdited: number;
    deletedAt?: number;
    value: string;
    validated?: boolean;
    savedOn?: number;
}

export interface Option {
    optionText: string;
    optionValue: string;
}

//#endregion types

export interface ListFormResponseInput {
    filter: FormResonseFilter;
    limit: number;
    skip: number;
}

export interface ListFormResponseOutput {
    formResponses: FormResponsePagination;
}

export interface FormResponsePagination {
    total: number;
    skipped?: number;
    limit?: number;
    responses: FormResponse[];

}

export interface FormResonseFilter{
    ids?: string[];
    userIds?: string[];
    formTypeIds?: string[];
    getUnansweredFields?: boolean;
    getDeleted?: boolean;
    getValid?: boolean;
}

export interface GenerateFormResponseQueryInput{
    fieldResponses: boolean;
    sectionResponses: boolean;
}

export function generateFormResponseQuery(input: GenerateFormResponseQueryInput){
    const listFormResponsesQuery = gql`
        query formresponses($filter: FormResponseFilter, $skip: Int, $limit: Int){
        formResponses(filter: $filter, skip: $skip, limit: $limit){
            total
            skipped
            limit
            responses{
            id
            dateStarted
            dateSubmitted
            dateEdited
            deletedAt
            responder
            ${input.fieldResponses ? fieldResponseGQL : ``}
            ${input.sectionResponses ? sectionResponseGQL : ``}
            unAnsweredFields
            confirmationPage
            validated
            formSecret
            formTypeId
            }
        }
    }
    `;

    return listFormResponsesQuery;
}

export interface FormResponseInput {
    dateStarted?: number;
    confirmationPage?: string;
    formTypeId: string;
    formSecret?: string;
    fieldResponses?: FieldResponseInput[];
    sectionResponses?: FormResponseInput[];
}

export interface FieldResponseInput {
    value: string;
    fieldId: string;
    validated?: boolean;
    savedOn?: boolean;
}

export interface NewFormResponseOutput {
    newFormResponse: FormResponse;
}

export const newFormResponseMutation = gql`
mutation newFormResponse($input: FormResponseInput){
  newFormResponse(input: $input){
    id
    dateStarted
    dateSubmitted
    dateEdited
    deletedAt
    responder
    fieldResponses{
        id
    }
    sectionResponses{
        id
    }
    unAnsweredFields
    confirmationPage
    validated
    formSecret
    formTypeId
  }
}

`;

export const fieldResponseGQL = `
    fieldResponses{
        id
        fieldId
        dateSubmitted
        datedEdited
        datedEdited
        deletedAt
        value
        validated
        savedOn
    }
`;

export const sectionResponseGQL = `
    sectionResponses{
        id
        dateStarted
        dateSubmitted
        dateEdited
        deletedAt
        responder
        unAnsweredFields
        confirmationPage
        validated
        formSecret
        formTypeId
    }
`;



export interface FormResponseEdit{
    id: string;
    formResponseEdit?: ResponseEditBase;
    validateResponse?: boolean;
    submit?: boolean;
}

export interface FieldResponseEdit{
    id: string;
    value: string;
}

export interface ResponseEditBase {
    fieldEdits?: FieldResponseEdit[];
    sectionFieldResponseEdits?: FormResponseEdit[];
    newFieldResponses?: FieldResponseInput[];
    newSectionResponses?: FormResponseInput[];
}

export interface FormResponseEditOutput{
    isEditSuccessful?: boolean;
    isSubmitSuccessful?: boolean;
    validationResult?: ValidateFormResponseOutput;
}

export interface ValidateFormResponseOutput {
    isFormValid: boolean;
    unansweredRequiredFields?: string[];
    invalidFormFieldResponses?: string[];
    invalidSectionResponses?: string[];
}

// For the Mutation
export interface EditFormResponseOutput{
    editFormResponse: FormResponseEditOutput;
}

export const editFormResponseMutation = gql`
mutation editFormResponse($input: FormResponseEdit!) {
  editFormResponse(input: $input){
      isEditSuccessful
      isSubmitSuccessful
      validationResult{
        isFormValid
        unansweredRequiredFields
        invalidFormFieldResponses
        invalidSectionResponses
      }
    }
}
`;

export interface RemoveFormResponsesOutput {
    removeFormResponses: boolean;
}

export const removeFormResponseMutation = gql`
mutation removeFormResponse($ids: [String]!, $delete: Boolean) {
  removeFormResponses(ids: $ids, delete: $delete)
}
`;

export interface GenerateListFormTypesQueryInput{
    sections?: boolean;
    fields?: boolean;
}

export interface ListFormTypesInput {
    filter?: FormTypeFilter;
    skip?: number;
    limit?: number;
    isDeleted?: boolean;
}

export interface FormTypeFilter {
    ids?: string[];
    title?: string;
}

export interface ListFormTypeOutput {
    formTypes: FormTypePagination;
}

export function generateListFormTypesQuery(input: GenerateListFormTypesQueryInput) {
    const listFormTypesQuery = gql`
        query formTypes($filter: FormTypeFilter, $skip: Int, $limit: Int, $isDeleted: Boolean){
            formTypes(filter: $filter, skip: $skip, limit: $limit, isDeleted: $isDeleted){
                total
                skipped
                filtered
                limit
                forms {
                    id
                    createdOn
                    updatedOn
                    deletedAt
                    limitToOneResponse
                    allowAnonymousEntries
                    permissionsRequired
                    editAfterSubmit
                    confirmationPage
                    confirmationMessage
                    title
                    description
                    customAttributes
                    ${input.sections ? sectionsTypeGQL : ``}
                    ${input.fields ? fieldResponseGQL : ``}
                }
            }
        }
    `;

    return listFormTypesQuery;
}

export const sectionsTypeGQL = `
    sections{
        id
        createdOn
        updatedOn
        deletedAt
        limitToOneResponse
        allowAnonymousEntries
        permissionsRequired
        editAfterSubmit
        confirmationPage
        confirmationMessage
        title
        description
        customAttributes
    }
`;

export const formFieldsGQL = `
    fields{
        id
        createdOn
        updatedOn
        deletedAt
        order
        customAttributes
        fieldName
        typeName
        maxLength
    }
`;

export interface CreateFormTypeInput {
    forms: FormTypeInput[];
}

export interface FormTypeInput{
    limitToOneResponse?: boolean;
    permissionsRequired: string[];
    allowAnonymousEntries?: boolean;
    editAfterSubmit?: boolean;
    confirmationPage?: string;
    confirmationMessage?: string;
    title: string;
    description?: string;
    customAttributes?: string[];
    sections?: FormTypeInput[];
    fields?: FormFieldInput[];
}

export interface FormFieldInput {
    order?: number;
    customAttributes?: string[];
    fieldName: string;
    fieldType: FieldTypeInput;
}

export interface FieldTypeInput {
    typeName: string;
    questionText?: string;
    isFieldRequired?: boolean;
    options?: OptionInput[];
    minChecked?: number;
    maxChecked?: number;
    format?: string;
    pattern?: string;
    minLength?: number;
    maxLength?: number;
}

export interface OptionInput {
    optionText: string;
    optionValue: string;
}

export interface FormTypePagination {
    total: number;
    skipped?: number;
    filtered?: number;
    limit?: number;
    forms?: FormType[];
}

export interface CreateFormTypeOutput {
    createFormTypes: FormTypePagination;
}

export const createFormTypesMutation = gql`
    mutation createFormType($forms: [FormTypeInput]){
        createFormTypes(forms: $forms){
            total
            skipped
            forms{
                id
                createdOn
                updatedOn
                deletedAt
                limitToOneResponse
                allowAnonymousEntries
                permissionsRequired
                editAfterSubmit
                confirmationPage
                confirmationMessage
                title
                description
                customAttributes
                sections{
                    id
                }
                fields{
                    id
                }
            }
        }
    }
`;


export interface EditFormTypesInput{
    editFormType?: EditFormTypes;
    editFormField?: EditFields;
}

export interface EditFormTypes{
    ids?: string[];
    formTypeEdit?: EditFormTypeBase;
}

export interface EditFields{
    ids?: string[];
    fieldEdit?: EditFormFieldBase;
}

export interface EditFormFieldBase {
    order?: number;
    customAttributes?: string[];
    fieldName?: string;
    fieldType?: FieldTypeInput;
}

export interface EditFormTypeBase {
    limitToOneResponse?: boolean;
    permissionsRequired: string[];
    allowAnonymousEntries?: boolean;
    editAfterSubmit?: boolean;
    confirmationPage?: string;
    confirmationMessage?: string;
    title?: string;
    description?: string;
    customAttributes?: string[];
    newSections?: FormTypeInput[];
    newFields?: FormFieldInput[];
    removeComponents: string[];
}

export interface EditFormTypesOutput{
    editFormTypes: EditFormTypeOutput;
}

export interface EditFormTypeOutput{
    totalFormsUpdated: number;
    totalFieldsUpdated: number;
}

export const editFormTypesMutation = gql`
    mutation editFormTypes($editFormType: EditFormTypes, $editFormField: EditFields){
        editFormTypes(editFormType: $editFormType){
            totalFormsUpdated
            totalFieldsUpdated
        }
    }
`;


export interface RemoveFormTypesOutput {
    removeFormTypes: boolean;
}

export const removeFormTypesMutation = gql`
    mutation removeFormTypes($ids: [String]){
        removeFormTypes(ids: $ids)
    }

`;
