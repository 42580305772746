

<div class="header">
    <h4 class="text">
        <i class="pi pi-chart-line large-icon"></i>
        Report
    </h4>
    <div class="actions">
        <app-report-actions
            [report]="report"
        ></app-report-actions>
    </div>
</div>

<h5 *ngIf="!loading; else loadingBar">
    <a routerLink="/reports/static/{{report?.id}}">
        {{report?.name || "Report Type"}}
    </a>
</h5>

<!-- Recently run reports will go here eventually... -->

<!-- <div class="tag-container" *ngIf="!loading; else loadingBar">
    <p-tag
        *ngFor="let tag of job.tags"
        [style]="{'background-color': tag.color}"
        [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
    >
        {{tag | formatTag}}
    </p-tag>
</div> -->

<h5>Overview</h5>
<div class="card">
    <p *ngIf="!loading; else loadingBar">
        <app-report-status-icon
            [status]="report?.status"
            [showStatusText]="true"
        ></app-report-status-icon>
    </p>
    <p *ngIf="!loading; else loadingBar">
        Created: {{ report?.createdAt | freyaDate: 'MMM dd, YYYY HH:mm' }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        CSV:
            <ng-container *ngIf="report?.saveData && !reportService.isGenerating(report)">
                <a
                    class="clickable"
                    (click)="reportService.downloadCSV(report, undefined, { stripSummary: true })"
                >Download</a>
                /
                <a
                    class="clickable"
                    (click)="reportService.downloadCSV(report, undefined, { stripSummary: false })"
                >Download With Summary</a>
            </ng-container>
            <ng-container *ngIf="!report?.saveData">
                Not Available - this report is not saved to the cloud.
            </ng-container>
            <ng-container *ngIf="report?.saveData && reportService.isGenerating(report)">
                Generating...
            </ng-container>
    </p>
    <p *ngIf="report?.error">
        <strong>Error: {{ report.error }}</strong>
    </p>
    <p *ngIf="reportService.isFullyLoaded(report) && report.zones; else loadingBar">
        <strong>Zones:
            <app-zone-chip
                *ngFor="let zone of report.zones.nodes"
                [zone]="zone"
            ></app-zone-chip>

        </strong>
    </p>
    <p *ngIf="!loading; else loadingBar">
        Created By:
        <a *ngIf="report?.createdBy"
            [routerLink]="[ '/user', report.createdBy.id ]"
        >
            {{ report.createdBy.givenName }} {{ report.createdBy.familyName }}

        </a>
        <strong *ngIf="report && !report.createdBy && !report.scheduledReport">System</strong>
        <strong *ngIf="report && !report.createdBy && report.scheduledReport">Scheduled Report</strong>
    </p>
    <!-- <p *ngIf="!loading; else loadingBar">
        Last Run: {{ rt?.createdAt | freyaDate }}
    </p> -->
</div>

<h5>Report Type</h5>
<div class="card">
    <a
        *ngIf="!loading; else loadingBar"
        (click)="selectReportType()"
        [routerLink]="[ '/reports/type', report?.reportType?.id ]"
        pTooltip="Version: {{ report?.version }}"
        tooltipPosition="left"
    >
        {{ report?.reportType?.name }}
    </a>
    <hr *ngIf="report?.scheduledReport" />
    <p *ngIf="report?.scheduledReport">
        <a
            *ngIf="!loading; else loadingBar"
            (click)="selectScheduledReport()"
            [routerLink]="[ '/reports/type', report?.reportType?.id ]"
        >
            {{ report?.scheduledReport?.name }}
        </a>
    </p>
    <!-- <p *ngIf="!loading; else loadingBar">
        Last Run: {{ rt?.createdAt | freyaDate }}
    </p> -->
</div>

<h5>Period & Variables</h5>
<div class="card">

    <div class="p-p-2" *ngIf="loading">
        <p-skeleton width="100%" height="8rem"></p-skeleton>
    </div>
    <div class="p-p-2" *ngIf="!loading && vars && !vars.length">
        No variables
    </div>

    <p
        *ngFor="let reportVar of vars"
    >
        <strong>{{ reportVar.key }}</strong>:
        <ng-container [ngSwitch]="reportVar.format">
            <span *ngSwitchCase="'datetime'">{{ reportVar.value | freyaDate: 'MMM dd, YYYY HH:mm' }}</span>
            <span *ngSwitchCase="'object'">{{ reportVar.value | json }}</span>
            <span *ngSwitchDefault>{{ reportVar.value }}</span>
        </ng-container>
    </p>
    <!-- <p *ngIf="!loading; else loadingBar">
        Last Run: {{ rt?.createdAt | freyaDate }}
    </p> -->
</div>

<h5>Summary / Aggregations</h5>
<div class="card"  *ngIf="reportService.isFullyLoaded(report) && report.aggregations; else loadingCard">

    <div class="p-p-2" *ngIf="!loading && !report.aggregations.length">
        No Aggregations
    </div>

    <p
        *ngFor="let ag of report.aggregations"
    >
        <strong>{{ ag.title }}</strong>:
        <app-report-aggregation-value
            [ag]="ag"
        ></app-report-aggregation-value>
    </p>
    <!-- <p *ngIf="!loading; else loadingBar">
        Last Run: {{ rt?.createdAt | freyaDate }}
    </p> -->
</div>

<!-- <app-mutate-job
    [job]="job"
    #mutate
>
</app-mutate-job> -->

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton width="100%" height="16px"></p-skeleton>
    </div>
</ng-template>

<ng-template #loadingCard>
    <div class="p-p-2">
        <p-skeleton width="100%" height="8rem"></p-skeleton>
    </div>
</ng-template>
