import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Message } from 'primeng/api';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { AuthenticateOutput, AuthMethod, AuthMethodType, ListAuthMethodsGQL, ListAuthMethodsQueryVariables } from '../../../generated/graphql.generated';
import { ColorSchemeService } from '../../color-scheme.service';
import { PlusAuthenticationService } from '../../core/auth/plus-auth.service';
import { FREYA_ROUTES } from '../../global.constants';
import { BrandingService } from '../../services/branding.service';
import { EMAIL_VALIDATION_PATTERN } from '../pattern.validator';

export interface AuthMethodWithClientData extends AuthMethod {
  passwordForm?: UntypedFormGroup;
}

export interface AuthInitialState {
  email?: string;
  password?: string;
  submit?: boolean;
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss', '../../authentication/auth.styles.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {

  @Input() loginText = `Login`;
  @Input() showHeader = true;
  @Input() showResetPassword = true;
  @Input() showLoginForever = false;
  @Input() showKeepMeLoggedIn = true;
  @Input() doNavigate = true;
  @Input() tooltips = true;
  /**
   * Set to false to avoid overriding the auth status
   * after authentication failure
   */
  @Input() updateAuthStatus = true;
  /**
   * Set to true to show optional 2fa input box below password
   */
  @Input() show2faBox = false;
  @Input() forCurrentUser = false;
  @Input() resetPasswordPath = FREYA_ROUTES.resetPassword;
  @Input() append2faDialogTo = undefined;
  @Output() afterSuccessfulLogin = new EventEmitter<AuthMethodWithClientData>();

  subs = new SubSink();

  msgs: Message[] = [];

  /**
   * Used when resolving 2fa code
   */
  currentAuthMethod?: AuthMethodWithClientData;

  twoFactorInvalid = false;

  show2FADialog = false;

  authenticating = false;

  loadingAuthMethods = true;
  authMethods: AuthMethodWithClientData[];
  authMethodLoadError?: string;

  keepMeLoggedIn = false;

  twoFactorForm = new UntypedFormGroup({
    code: new UntypedFormControl('', [
      Validators.minLength(6), Validators.required
    ])
  });

  initialState: AuthInitialState;

  public get twoFactorToken() {
    return this.twoFactorForm.get('code')?.value || undefined;
  }

  constructor(
    private plusAuth: PlusAuthenticationService,
    public router: Router,
    public brandingService: BrandingService,
    public listAuthMethods: ListAuthMethodsGQL,
    public colorSchemeSvc: ColorSchemeService,
  ) {
    // can only get current navigation in constructor
    const navigation = this.router.getCurrentNavigation();
    this.initialState = navigation?.extras?.state?.auth || undefined;
  }

  ngOnInit(): void {
    this.loadingAuthMethods = true;
    this.authMethodLoadError = undefined;

    const vars: ListAuthMethodsQueryVariables = {};
    if (this.forCurrentUser && !this.plusAuth.user) {
      this.setAuthMethodLoadError(new Error(`Must be logged in`), []);
      return;
    } else if (this.forCurrentUser && this.plusAuth.user) {
      vars.userId = this.plusAuth.user.id;
    }

    this.subs.sink = this.listAuthMethods.fetch(vars, {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    }).subscribe(async (res) => {
      this.authMethodLoadError = undefined;
      this.loadingAuthMethods = res.loading;
      if (res.data?.authMethods) {
        // check to make sure result is truthy with .filter(Boolean)
        this.setAuthMethods(res.data.authMethods.filter(Boolean));
      } else if (res.errors?.length) {
        const [err] = res.errors;
        this.setAuthMethodLoadError(err, res.errors);
      }
    }, (err) => {
      this.setAuthMethodLoadError(err);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setAuthMethodLoadError(err: Error, errs?: readonly any[]) {
    console.error(`Could not load auth methods`, errs || err);

    let message = err.message;
    if (message.toLowerCase().includes('400 bad request')) {
      message = 'Mismatched application versions';
    }
    if (message.toLowerCase().includes('0 unknown error')) {
      message = 'You are offline. You must go online to sign in.';
    }

    this.authMethods = undefined;
    this.authMethodLoadError = message;
    this.loadingAuthMethods = false;
  }

  /**
   * Sort auth methods and set them
   */
  setAuthMethods(authMethods: AuthMethodWithClientData[]) {

    // Do not show api key authentication
    authMethods = cloneDeep(authMethods.filter((a) => a.authMethodType !== AuthMethodType.Apikey));

    // Show password based auth then show OIDC, sort the rest however
    authMethods = authMethods.sort((a, b) => {
      // password always first
      if (a.authMethodType === AuthMethodType.Password) {
        return -1;
      }

      // Sort OIDC providers alphabetically by name
      if (a.authMethodType === AuthMethodType.Oidc && b.authMethodType === AuthMethodType.Oidc) {
        return b.oidc_name > a.oidc_name ? 1 : -1;
      }

      // show oidc below password but above others
      if (a.authMethodType === AuthMethodType.Oidc) {
        return -1;
      }

      // if nothing else here matches return zero
      return 0;
    });

    // add client data
    for (const method of authMethods) {
      if (method.authMethodType === AuthMethodType.Password) {
        const email = (this.forCurrentUser && this.plusAuth.user?.email) ? this.plusAuth.user.email : (this.initialState?.email || '');
        const minLength = method.password_minLength ? method.password_minLength : environment.passwordMinLength;

        method.passwordForm = new UntypedFormGroup({
          email: new UntypedFormControl(email, [Validators.required, Validators.pattern(EMAIL_VALIDATION_PATTERN)]),
          password: new UntypedFormControl('', [Validators.required, Validators.minLength(minLength)]),
          twoFactorCode: new UntypedFormControl('', []),
        });
      }
    }

    this.authMethods = authMethods;

    if (this.initialState?.submit) {
      this.login(
        authMethods.find((a) => a.authMethodType === 'password'),
        false,
      );
    }
  }

  loginWithPassword(
    method: AuthMethodWithClientData,
    forever = false,
  ) {
    const loginForm = method.passwordForm;
    if (!loginForm) {
      throw new Error(`Login form not provided`);
    }

    if (!loginForm.valid) {
      throw new Error('Email/Password are missing or invalid');
    }

    this.msgs = [];

    const rememberLength = forever ? environment.rememberLength : undefined;

    let twoFactorCode = this.twoFactorToken || loginForm.value.twoFactorCode;
    // remove all non numeric characters
    twoFactorCode = twoFactorCode.replace(/[^0-9]/g, '');

    this.subs.sink = this.plusAuth.authenticate({
      email: loginForm.value.email,
      password: loginForm.value.password,
      rememberLength,
      twoFactorAuthenticationToken: twoFactorCode,
    }).subscribe(async (authRes) => {
      if (authRes.jwt) {
        this.onSuccessfulAuth(method);
      } else {
        // this.loading = false;
        this.onAuthFailure(undefined, authRes);
      }
    }, (err) => {
      this.authenticating = false;
      this.msgs = [{
        severity: 'error',
        closable: false,
        summary: 'Authentication Error: Try again',
        detail: err.message,
      }];
    });
  }

  /**
   * Login to the application
   *
   * @param method auth method to login with
   * @param forever if true, will log in for two years
   * instead of the default three hours. Only set this to true
   * if the user explicitly logs in "forever"
   */
  login(
    method: AuthMethodWithClientData,
    forever?: boolean,
  ) {
    // login forever if we've already pressed the login forever button
    // mainly used for authenticating against 2fa
    // localStorage is cleared after logout so it won't persist across
    // different sessions
    if (forever === undefined) {
      forever = localStorage.getItem('login-forever') === 'true';
    }

    // login forever if we've toggled the keep me signed in button
    forever = this.keepMeLoggedIn || forever;

    this.authenticating = true;
    this.currentAuthMethod = method;
    // reset initial state after first login attempt
    this.initialState = {};

    if (forever) {
      localStorage.setItem('login-forever', 'true');
    }

    try {

      if (!method) {
        throw new Error(`No auth method provided.`);
      }

      if (method.authMethodType === AuthMethodType.Password) {
        return this.loginWithPassword(method, forever);
      }

      throw new Error(`Cannot authenticate with this auth method: unsupported client.`);
    } catch (err) {
      this.onAuthFailure(err);
    }
  }

  async onSuccessfulAuth(authMethod: AuthMethodWithClientData) {
    await this.plusAuth.resetCache();
    this.afterSuccessfulLogin.next(authMethod);
    if (this.doNavigate) {
      this.plusAuth.navigateAfterLogin();
    }
    this.twoFactorForm.reset();
    this.setAuthMethods(this.authMethods);
    this.msgs = [];
    this.twoFactorInvalid = false;
    this.show2FADialog = false;
    this.authenticating = false;
  }

  onAuthFailure(
    err?: Error,
    res?: AuthenticateOutput
  ) {
    console.error(`Error authenticating`, err, res);

    if (res?.invalidCode === 'NO_2FA_TOKEN') {
      this.show2FADialog = true;
    } else if (res?.invalidCode === 'INVALID_2FA_TOKEN') {
      this.twoFactorForm.reset();
      this.show2FADialog = true;
      this.twoFactorInvalid = true;
    } else if (res) {
      this.show2FADialog = false;
      const error: Message = {
        severity: 'error',
        summary: res.invalidReason || 'Authentication Error.',
        closable: false,
      };
      if (res.lockedOutAttemptsLeft < 5 && res.lockedOutAttemptsLeft > 0) {
        error.detail = `${res.lockedOutAttemptsLeft} attempt(s) left.`;
      }

      this.msgs = [error];
    } else if (err) {
      this.show2FADialog = false;
      const error: Message = {
        severity: 'error',
        summary: err.message || 'Client Authentication Error.',
        closable: false,
      };
      this.msgs = [error];
    }

    this.authenticating = false;
  }

  submit2fa() {
    if (this.authenticating) { return; }
    const code = this.twoFactorForm.get('code').value?.replace(/[^0-9]/g, '');
    const codeWithSpaces = this.twoFactorForm.get('code').value?.replace(/[^0-9 ]/g, '');
    this.twoFactorForm.get('code').setValue(codeWithSpaces);
    if (!this.twoFactorForm.valid) {
      return;
    }
    if (code.length < 6) { return; }
    if (!this.currentAuthMethod) { return; }
    this.twoFactorForm.get('code').setValue(code);

    this.login(this.currentAuthMethod);
  }

}
