import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { EstimatesJobFragment } from '../../../generated/graphql.generated';
import { hasUncompletedEvents } from '../../jobs/jobs.util';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { PermissionService } from '../../services/permission.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { DisableRestrictionsComponent } from '../disable-restrictions/disable-restrictions.component';
import { MenuService } from 'src/app/base/menu/app.menu.service';

@Component({
  selector: 'app-estimate-actions',
  templateUrl: './estimate-actions.component.html',
  styleUrls: ['./estimate-actions.component.scss']
})
export class EstimateActionsComponent implements OnInit, OnChanges {

  @Input() job: EstimatesJobFragment;

  @Input() disabled: boolean;

  @Output() jobClosed = new EventEmitter<boolean>();

  @Output() newJobStarted = new EventEmitter();

  subs = new SubSink();

  // ACTIONS
  newJobAction: MenuItem = {
    id: 'new',
    label: 'New Job',
    icon: 'pi pi-replay',
    command: () => this.openClearDialog()
  };

  toggleOpenJobAction: MenuItem = {
    id: 'toggle-open',
    label: 'Close Job',
    icon: 'pi pi-circle-fill',
    command: () => {
      if (this.job?.closedAt) {
        this.freyaHelper.openClosedJob(this.job.id);
      } else {
        this.openCloseDialog();
      }
    },
  };

  toggleRestrictionsAction: MenuItem = {
    id: 'toggle-restrictions',
    command: () => this.openRestrictionsDialog()
  };

  archiveAction: MenuItem = {
    id: 'archive',
    label: 'Archive Job',
    icon: 'pi pi-folder',
    disabled: false,
    command: () => this.freyaHelper.toggleJobArchived(this.job)
  };

  deleteAction: MenuItem = {
    id: 'delete',
    label: 'Delete Job',
    icon: 'pi pi-trash',
    disabled: false,
    command: () => this.freyaHelper.openDeleteJobDialog(this.job),
    visible: false,
  };

  openV2: MenuItem = {
    id: 'open-v2',
    visible: false,
    disabled: false,
    label: 'Open V2',
    icon: 'pi pi-star',
    command: () => this.router.navigate([
      '/jobs',
      this.job.id,
      'overview',
    ]),
  };

  estimateActions: MenuItem[];

  actions = [{
    id: 'actions',
    visible: true,
    label: 'Actions',
    items: [
      this.newJobAction,
      this.toggleOpenJobAction,
      this.archiveAction,
      this.deleteAction,
      this.toggleRestrictionsAction,
      this.openV2,
    ]
  }] as MenuItem[];

  disableIf = {
    noJob: [ 'toggle-open', 'delete' ],
    hasPendingEvents: [ 'archive', 'toggle-open' ],
    hasPendingTransactions: [ 'toggle-open' ],
  };

  isJobV2Enabled = false;

  constructor(
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private estimateHelper: EstimateHelperService,
    private router: Router,
    private confirm: ConfirmationService,
    private permissionHandler: PermissionService,
    private menuService: MenuService,
  ) { }

  ngOnInit(): void {
    this.isJobV2Enabled = this.menuService.isJobV2Enabled;

    this.openV2.visible = this.isJobV2Enabled;
    this.openV2.disabled = !this.isJobV2Enabled;

    this.watchRestrictions();
    this.checkDeletePermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('job')) { return; }
    this.setDisabled();
    this.setToggleOpenAndArchiveActions();
  }

  checkDeletePermissions() {
    this.subs.sink = this.permissionHandler
      .watchPermissions([ 'jobs.delete' ])
      .subscribe((res) => {
        if (!Array.isArray(res) || !res.length) { return; }
        this.deleteAction.visible = res[0];
        this.setDisabled();
        this.setToggleOpenAndArchiveActions();
      });
  }

  watchRestrictions() {
    this.subs.sink = this.estimateHelper.restrictionsEnabled
      .subscribe((enabled) => {
        this.setToggleRestrictionsAction(enabled);
      });
  }

  setDisabled() {
    if(!this.actions.length) {return; }

    const [ actionsMenu ] = this.actions;
    const { items: actions } = actionsMenu;

    for (const action of actions) {
      const { disabled, tooltipLabel } = this.getActionState(action);
      action.disabled = disabled;
      action.tooltipOptions = disabled ? { tooltipLabel, tooltipPosition: 'left' } : undefined;
    };
  }

  getActionState(action: MenuItem): { disabled: boolean; tooltipLabel: string | undefined } {

    const hasPendingTransactions = Boolean(this.job?.transactions?.filter((t) => t.stage === 'pending')?.length);

    if (!this.job && this.disableIf.noJob.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: 'The current job has not been saved yet',
      };
    }

    if (hasUncompletedEvents(this.job) && this.disableIf.hasPendingEvents.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: 'This job has uncompleted events',
      };
    }

    if (hasPendingTransactions && this.disableIf.hasPendingTransactions.includes(action.id)) {
      return {
        disabled: true,
        tooltipLabel: 'This job has pending transactions',
      };
    }

    return {
      disabled: false,
      tooltipLabel: undefined,
    };
  }

  setToggleRestrictionsAction(restrictionsEnabled: boolean) {
    this.toggleRestrictionsAction.label = restrictionsEnabled ? 'Disable Restrictions' : 'Re-Enable Restrictions';
    this.toggleRestrictionsAction.icon = restrictionsEnabled ? 'pi pi-ban' : 'pi pi-check';
  }

  setToggleOpenAndArchiveActions() {
    const isJobClosed = Boolean(this.job?.closedAt);

    this.toggleOpenJobAction.label = isJobClosed ? 'Open Job' : 'Close Job';
    this.toggleOpenJobAction.icon = isJobClosed ? `pi pi-circle` : 'pi pi-circle-fill';

    this.archiveAction.visible = isJobClosed;
  }

  openClearDialog() {
    this.confirm.confirm({
      header: 'Start New Job?',
      message: 'Starting a new job will reset the data locally for the entire estimate process. This will not affect any saved properties.',
      accept: () => this.newJobStarted.emit(),
      acceptIcon: 'pi pi-replay',
      acceptButtonStyleClass: 'p-button-danger',
      acceptLabel: 'Start New Job',
    });
  }

  openCloseDialog() {
    const ref = this.freyaHelper.openCloseJobDialog(this.job);

    this.subs.sink = ref.onClose.subscribe((closed: boolean) => {
      this.jobClosed.next(closed);
    });
  }

  /**
   * Open the dialog to enable/disable booking restrictions
   */
  openRestrictionsDialog() {
    this.dialogService.open(DisableRestrictionsComponent, {
      width: this.responsiveHelper.dialogWidth,
      header: `${this.estimateHelper.restrictionsEnabled.value ? 'Disable' : 'Re-enable'} Booking Restrictions`,
      contentStyle: this.freyaHelper.getDialogContentStyle('0.5rem'),
    });
  }

}
