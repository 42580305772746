import { JOB_EVENT_TYPES } from '../global.constants';

export interface AggregatedEventSummary {
	loaded: boolean;
	currency: string;
	labels: string[];
	values: { subtotal: number; avg: number }[];
	sum: number;
}

export function aggregateEventSummary(
	key: string,
	currency: string,
) {

	return ({ agMap, lastAgMap }) => {
		const count = agMap.count;
		const subtotal = agMap.discountedSubTotalWithoutDamages;
		if (!count || !subtotal) { return; }

		const labels = [ ...JOB_EVENT_TYPES, 'generic' ];
		const values = labels.map(() => ({
			subtotal: 0,
			count: 0,
			avg: 0,
			change: 0,
		}));

		count.labels.forEach((label, labelIndex) => {
			const i = labels.indexOf(label);
			if (i < 0) { return; }
			values[i].count = count.values[labelIndex];
		});

		const sum = subtotal.values.reduce((p, c) => p + c, 0) / 100;
		const eventCount = count.values.reduce((p, c) => p + c, 0);

		subtotal.labels.forEach((label, labelIndex) => {
			const i = labels.indexOf(label);
			if (i < 0) { return; }
			values[i].subtotal = subtotal.values[labelIndex] / 100;
			values[i].avg = values[i].subtotal / values[i].count;
		});

		return {
			[key]: {
				loaded: true,
				currency,
				labels,
				values,
				sum,
				count: eventCount,
			} as AggregatedEventSummary,
		};
	};
}
