import { CdkDrag, CdkDragDrop, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { dayjs } from '@karve.it/core';
import { Store } from '@ngrx/store';


import { PrimeIcons } from 'primeng/api';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { debounceTime, skip } from 'rxjs';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';

import { EventAttendeeRoles } from '../../global.constants';

import { BrandingService } from '../../services/branding.service';

import { DetailsHelperService } from '../../services/details-helper.service';

import { DispatchCardComponent, AttendeeDragData, AssetDragData } from './components/dispatch-card/dispatch-card.component';
import { DispatchCrewComponent } from "./components/dispatch-crew/dispatch-crew.component";
import { DispatchEditEventComponent } from './components/dispatch-edit-event/dispatch-edit-event.component';
import { DispatchActions } from './store/dispatch.actions';
import { DispatchFeature, DispatchFilterOption, EVENT_STATUS_ICONS, DispatchSortOptions, DispatchEvent, EventStatusOptions } from './store/dispatch.reducer';

const SORT_BY_OPTIONS: DispatchFilterOption<DispatchSortOptions>[] = [
  {
    label: DispatchSortOptions.EventStartAsc,
    icon: PrimeIcons.SORT_AMOUNT_DOWN
  },
  {
    label: DispatchSortOptions.EventStartDesc,
    icon: PrimeIcons.SORT_AMOUNT_UP
  },
  {
    label: DispatchSortOptions.TruckNameAsc,
    icon: PrimeIcons.SORT_ALPHA_DOWN
  },
  {
    label: DispatchSortOptions.TruckNameDesc,
    icon: PrimeIcons.SORT_ALPHA_UP
  }
] as const;


const EVENT_STATUS_OPTIONS: DispatchFilterOption<EventStatusOptions>[] = Object.entries(EVENT_STATUS_ICONS).map(([label, icon]) => ({
  label: label as EventStatusOptions,
  icon: icon as PrimeIcons
}));

@Component({
  standalone: true,
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrl: './dispatch.component.scss',
  imports: [
    FreyaCommonModule,
    CommonModule,
    DispatchCardComponent,
    ReactiveFormsModule,
    DispatchEditEventComponent,
    DispatchCrewComponent,
    CdkDropListGroup,
    CdkDropList,
    ButtonGroupModule,
    DatePipe,
  ],
})
export class DispatchComponent implements OnInit, OnDestroy {
  private events$ = this.store.select(DispatchFeature.selectSortedEvents);
  private eventsLoaded$ = this.store.select(DispatchFeature.eventsLoaded);
  conflictingEvents$ = this.store.select(DispatchFeature.selectConflictingEvents);

  public events: DispatchEvent[] = [];
  public eventsLoaded: boolean = false;
  readonly sortByOptions = SORT_BY_OPTIONS;
  readonly eventStatusOptions = EVENT_STATUS_OPTIONS;

  editEventDialogRef: DynamicDialogRef | undefined;
  visible: boolean = false;
  eventUnderEdittingId: number = null;

  public filterEventsForm = new FormGroup({
    date: new FormControl<Date>(new Date()),
    sortBy: new FormControl<DispatchFilterOption<DispatchSortOptions>>(this.sortByOptions[0]),
    eventStatus: new FormControl<DispatchFilterOption<EventStatusOptions>>(this.eventStatusOptions[0]),
  });

  private subs = new SubSink();

  constructor(
    public store: Store,
    public dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private brandingService: BrandingService,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    this.watchEventSelectors();
    this.loadEvents();
    this.watchZoneChange();
    this.watchDateChange();
    this.watchSortByChanges();
    this.watchEventStatusChange();
    this.watchEventUpdates();
  }

  createJob() {
    this.router.navigate(['/estimating'], { queryParams: { step: 0, zone: this.route.snapshot.queryParamMap.get('zone') } });
  }

  openEditDispatchEventDialog(index: number) {
    this.eventUnderEdittingId = index;
    this.visible = true;
  }

  closeDialog() {
    this.visible = false;
    this.eventUnderEdittingId = null;
  }

  private watchEventSelectors() {
    this.subs.sink = this.events$.subscribe((events) => {
      this.events = events
    })
    this.subs.sink = this.eventsLoaded$.subscribe((events) => {
      this.eventsLoaded = events
    })
  }
  private loadEvents(): void {
    const date = this.route.snapshot.queryParamMap.get('date') || sessionStorage.getItem(environment.lskeys.scheduleDate);
    const parsedDate = date ? dayjs(date).toDate() : new Date();
    this.store.dispatch(DispatchActions.hardRefreshEvents({ date: parsedDate }));
    this.filterEventsForm.controls.date.setValue(parsedDate);
  }

  private watchZoneChange(): void {
    this.subs.sink = this.brandingService.currentZone().pipe(skip(1)).subscribe((zone) => {
      this.hardRefreshComponent();
    });
  }

  private watchEventUpdates() {
    this.subs.sink = this.detailsHelper.getObjectUpdates('Events').subscribe((update) => {
      if (update.action === 'update') {
        this.store.dispatch(DispatchActions.hardRefreshEvents({ date: null }));
      }
    });
  }

  private dispatchEventsForDate(date: Date): void {
    this.store.dispatch(DispatchActions.setDispatchDate({ date }));
  }

  private watchDateChange(): void {
    this.subs.sink = this.filterEventsForm.controls.date.valueChanges.pipe(debounceTime(300)).subscribe((date: Date) => {
      this.dispatchEventsForDate(date);
    });
  }

  private watchSortByChanges(): void {
    this.subs.sink = this.filterEventsForm.controls.sortBy.valueChanges.subscribe(({ label }) => {
      this.store.dispatch(DispatchActions.setSortBy({ sortBy: label }));
    });
  }

  private watchEventStatusChange(): void {
    this.subs.sink = this.filterEventsForm.controls.eventStatus.valueChanges.subscribe(({ label }) => {
      this.store.dispatch(DispatchActions.setEventStatus({ eventStatus: label }));
    });
  }

  public isResourceFromDispatchEvent(item: CdkDrag<AttendeeDragData | AssetDragData>) {
    return 'event' in item.data;
  }

  public removeResource($event: CdkDragDrop<any, any, AttendeeDragData | AssetDragData>) {
    const { item } = $event;
    const { data } = item || {};

    if (!data?.event?.event?.id) return;

    const { attendee } = data as AttendeeDragData;
    const { asset } = data as AssetDragData;

    if (!attendee && !asset) return;

    const { event: { id: eventId }, customer: { name: eventName = '' }, crew = [], trucks = [] } = data?.event || {};
    const edit = {
      eventId,
      eventName,
      addAttendees: [],
      removeAttendees: attendee ? [{ user: attendee.user, role: attendee.role, name: attendee.name }] : [],
      removeAssets: asset ? [{ id: asset.id, name: asset.name }] : [],
    }

    if (attendee) {
      const isCrewLead = attendee.role === EventAttendeeRoles.crewLead;
      // If we are removing a crew Leader, we need to assign the next available user as crew lead
      const hasAnotherCrewLead = crew.some(({ role, user }) => role === EventAttendeeRoles.crewLead && user.id !== attendee.user.id);

      if (isCrewLead && !hasAnotherCrewLead && crew.length > 1) {
        // Pick next crew lead as any member other than the one we are removing
        const nextCrewLead = crew.find(({ role, user }) => role === EventAttendeeRoles.crewMember && user.id !== attendee.user.id);
        edit.addAttendees.push({ user: nextCrewLead.user, role: EventAttendeeRoles.crewLead, name: nextCrewLead.name });
        edit.removeAttendees.push({ user: nextCrewLead.user, role: nextCrewLead.role, name: nextCrewLead.name });
      }

    }

    this.store.dispatch(DispatchActions.updateCrew({ edits: [edit] }));
  }

  public changeDate(dateType: 'prev' | 'next' | 'today') {
    const date = this.filterEventsForm.controls.date.value;
    const dayJsObj = dayjs(date);
    let newDate: Date = new Date();

    switch (dateType) {
      case 'prev':
        newDate = dayJsObj.subtract(1, 'day').toDate();
        break;
      case 'next':
        newDate = dayJsObj.add(1, 'day').toDate();
        break;
      case 'today':
      default:
        break;
    }

    this.filterEventsForm.controls.date.setValue(newDate);
  }

  public hardRefreshComponent() {
    this.store.dispatch(DispatchActions.componentHardRefreshed());
  }

  public scrollToEvent(index: string) {
    const eventCard = document.getElementById(`event-${index}`);
    if (eventCard) {
      eventCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
      eventCard.classList.add('highlight-card');
      setTimeout(() => {
        eventCard.classList.remove('highlight-card');
      }, 2000); // Remove highlight after 2 seconds
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}