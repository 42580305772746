<div
  [formGroup]="invoiceForm"
  >
  <ng-template #events>
    <app-event-select
      formControlName="events"
      [filter]="{ jobId: jobId }"
      [disabledEvents]="{
        hasInvoice: true,
        incomplete: true
      }"
      [showInvoiceInfo]="true"
      [selectAllByDefault]="allowMultipleEvents"
      [limit]="allowMultipleEvents ? null : 1"
      ></app-event-select>
    <small>You can only create an invoice for completed events, and cannot include the same event in more than one invoice</small>
  </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="invoiceForm"
    objectType="Invoice"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateInvoice()"
></app-mutate-object>
