<div 
    class="event-container" 
    [style.background-color]="colors?.backgroundColor"
    [style.color]="colors?.textColor"
    (click)="openEvent()"
    pTooltip="{{event.start | freyaDate: 'h:mm a, EEEE MMMM, d'}}"
    tooltipPosition="left"
>
    <div class="bars">
        <div class="vertical-bar" [style.border-color]="colors?.travelTimeColor" [style.background-color]="colors?.leftDivColor">&nbsp;</div>
        <i class="pi event-icon" [ngClass]="statusIconClass"></i>
    </div>
    <div class="main">
        <span class="customer-text"> {{ event?.job?.users | jobCustomer }}</span>
        <br>
        <small>{{areaCode}} <span *ngIf="endAreaCode">- {{endAreaCode}}</span></small>
    </div>
</div>
<div class="area-bar" [style.background-color]="colors?.zoneBackgroundColor" #areaBar>
    
</div>