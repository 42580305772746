import { Pipe, PipeTransform } from '@angular/core';
import { Charge } from '@karve.it/interfaces/charges';

import { ChargeHelperService } from '../services/charge-helper.service';

@Pipe({
  name: 'chargeSubtotal'
})
export class ChargeSubtotalPipe implements PipeTransform {

  constructor(
    private chargeHelper: ChargeHelperService,
  ){}

  transform(charge, quantity = charge.quantity, product = charge.product): number {
    return this.chargeHelper.getChargeSubtotal(charge, quantity, product) / 100;
  }
}
