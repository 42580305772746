
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { SubmitFeedbackGQL, SubmitFeedbackMutationVariables } from '../../../generated/graphql.generated';

import { FeedbackDialogComponent, OpenFeedbackDialogData } from '../../base/feedback-dialog/feedback-dialog.component';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(
        private dialogService: DialogService,
        private responsiveHelper: ResponsiveHelperService,
        private submitFeedbackGQL: SubmitFeedbackGQL,
        private freyaHelper: FreyaHelperService,
    ) { }

    /**
     * Submit user feedback to the backend
     *
     * @param input The type of feedback, RUM info and details given by the user
     * @returns QueryRef
     */
    submitFeedback(variables: SubmitFeedbackMutationVariables) {
        return this.submitFeedbackGQL.mutate(variables, {});
    }

    openFeedbackDialog(
        data: OpenFeedbackDialogData = {},
    ) {
        this.dialogService.open(FeedbackDialogComponent, {
            header: 'Submit Feedback',
            width: this.responsiveHelper.dialogWidth,
            closeOnEscape: true,
            dismissableMask: true,
            data,
            contentStyle: this.freyaHelper.getDialogContentStyle('0.5rem'),
        });
    }


}

