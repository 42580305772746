<div *ngIf="discount">
    <div class="header-with-button" [class.p-ai-start]="discount?.attributes?.includes('single-use')">
        <h4><i class="pi pi-credit-card large-icon"></i> Discount
            <p-chip
                *ngIf="discount?.attributes?.includes('single-use')"
                label="Single Use"
                styleClass="p-mr-2"
                ></p-chip>
            <app-object-state
                [object]="discount"
                objectType="discount"
                [colorful]="true"
            ></app-object-state>
        </h4>
        <div>
            <button
                *ngIf="discountActions[0].visible"
                pButton
                pRipple
                label="Actions"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="discountActions"
            ></p-menu>
        </div>
    </div>
    <br>
    <div class="card unpadded-card">
        <div class="p-grid p-ai-center">
            <div class="p-col-2">
                <span class="indicator" [class.active-indicator]="discount.active"></span>
            </div>
            <div class="p-col-10">
                <strong>{{discount | discountStatus}}</strong>
            </div>
        </div>
    </div>
    <div class="card">
        <h5>{{discount.name}}</h5>
        <p>Code: {{discount.code}}</p>
        <p>Zone: TODO</p>
        <p>Max Redemptions: {{discount.maxRedemptions ? discount.maxRedemptions : 'Unlimited'}}</p>
        <p>Redeemptions: {{discount.timesRedeemed}}</p>
        <p>Amount: {{discount | discountAmount}}</p>
        <p>Custom Range: {{ discount?.customAmountRange | discountCustomAmountRange : discount?.discountType }}</p>
        <p>Starts: {{discount.startsAt | freyaDate }}</p>
        <p>Expires: {{discount.expiresAt ? (discount.expiresAt | freyaDate) : 'Never'}}</p>
    </div>

    <h5>Areas</h5>
    <div class="card">
        <p>Coming Soon</p>
        <!-- <p *ngFor="let area of franchise.children.nodes">{{area.name}}</p> -->
    </div>
</div>

<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="24px"
    ></p-skeleton>
</ng-template>

<app-mutate-discount #mutate mutateType='update' [discount]="discount" (updatedValue)="discount = $event"></app-mutate-discount>
