import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRole } from '@karve.it/interfaces/auth';
import { cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OBJECT_ICON_MAP, passwords } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { MutateUserComponent } from 'src/app/shared/mutate-user/mutate-user.component';
import { SubSink } from 'subsink';

import { FullUserFragment, ListUsersGQL, RoleWithZonesFragment } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss']
})
export class UsersDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateUserComponent;

  @Input() user: FullUserFragment;
  @Input() basic: boolean;

  userIcon = `${OBJECT_ICON_MAP.users} large-icon`;

  passwordForm = new UntypedFormGroup({
    password1: new UntypedFormControl('', [Validators.required, Validators.pattern(passwords.regex), Validators.minLength(10)]),
    password2: new UntypedFormControl('', [Validators.required, Validators.pattern(passwords.regex), Validators.minLength(10)])
  },);

  subs = new SubSink();
  loadingUser = false;

  // Edit Roles Variables
  showEditRoles = false;
  roles: RoleWithZonesFragment[] = [];
  editRoleInput: UserRole[] = [];

  // Delete User Variables
  showDeleteUser = false;

  constructor(
    public detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private listUsersGQL: ListUsersGQL,
  ) { }

  ngOnInit(): void {
    // Reload user if roles are updated
    this.subs.sink = this.detailsHelper.getObjectUpdates('User')
      .pipe(
        filter((objUpdate) => objUpdate.update === 'update' || objUpdate.action === 'update')
      )
      .subscribe(() => {
        this.retrieveUser(false);
      });
  }

  ngOnChanges() {
    if (this.user.id && !this.user.email) {
      this.retrieveUser();
      return;
    };
    this.updateRoles();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async retrieveUser(cache = true) {
    this.loadingUser = true;
    this.user = await lastValueFrom(this.listUsersGQL.fetch({
      filter: { userIds: [ this.user.id ] },
    }, {
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    })
      .pipe(map((res) => cloneDeep(res.data.usersv2.users[0]))));
    this.updateRoles();
    this.loadingUser = false;
  }

  openEditRoles() {
    this.mutateRef.user = this.user;
    this.mutateRef.mutateType = 'update';
    this.mutateRef.openDialog();
    this.mutateRef.mutateRef.viewSection('Roles');
  }

  updateRoles() {
    if (!this.user) { return; };
    this.roles = this.user.roles;
  }

}
