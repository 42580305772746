import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-empty-state',
  templateUrl: './dashboard-empty-state.component.html',
  styleUrl: './dashboard-empty-state.component.scss'
})
export class DashboardEmptyStateComponent {

  @Input() text = 'No data found for the selected period and currency';

}
