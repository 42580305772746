import { NgModule } from '@angular/core';

import { SharedModule } from 'primeng/api';

import { FreyaCommonModule } from '../freya-common/freya-common.module';

import { AssetSelectComponent } from './asset-select/asset-select.component';
import { AssignZonesComponent } from './assign-zones/assign-zones.component';
import { CronTimeFormComponent } from './cron-time-form/cron-time-form.component';
import { EventSelectComponent } from './event-select/event-select.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { ProductSelectComponent } from './product-select/product-select.component';
import { SelectRolesComponent } from './select-roles/select-roles.component';
import { TransactionSelectComponent } from './transaction-select/transaction-select.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { UserSearchMultipleComponent } from './user-search-multiple/user-search-multiple.component';



@NgModule({
  declarations: [
    AssignZonesComponent,
    EventSelectComponent,
    SelectRolesComponent,
    TransactionSelectComponent,
    UserSearchComponent,
    ProductSelectComponent,
    AssetSelectComponent,
    FilterSelectComponent,
    UserSearchMultipleComponent,
    CronTimeFormComponent,
  ],
  imports: [
    SharedModule,
    FreyaCommonModule,
  ],
  exports: [
    AssignZonesComponent,
    EventSelectComponent,
    SelectRolesComponent,
    TransactionSelectComponent,
    UserSearchComponent,
    ProductSelectComponent,
    AssetSelectComponent,
    FilterSelectComponent,
    UserSearchMultipleComponent,
    CronTimeFormComponent,
  ]
})
export class CustomInputsModule { }
