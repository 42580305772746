<!-- <div class="avatar" [class.message]="true" [class.system]="false">
    <p-avatar
        *ngIf="true"
        class="avatar-hover"
        [label]="comment.author | userInitials"
        (click)="viewAuthorInSidePanel(comment.author)"
    ></p-avatar>

    <span class="pin" *ngIf="false"></span>
</div> -->

<div
    class="content-container"
    [class.message]="true"
    [class.system]="false"
>
    <div class="content-header">
        <div>
            <a
                class="name"
                *ngIf="comment.author"
                (click)="viewAuthorInSidePanel(comment.author)"
            >
                {{ comment.author | userName }}
            </a>
            ·
            <app-time-since [unixTime]="comment.createdAt"></app-time-since>
            <i
                class="pi pi-spin pi-spinner"
                *ngIf="comment.callState === 'MUTATING'"
            ></i>
            <i
                class="pi pi-exclamation-triangle"
                *ngIf="isErrorState(comment.callState)"
            ></i>

        </div>

        <div *ngIf="mode === 'view'">
            <span
                *ngFor="let jobRole of authorJobRoles"
                class="jobRole"
            >{{ jobRole.role }}</span>

            <button
                class="pi pi-reply action"
                *ngIf="replyEnabled"
                [disabled]="isErrorState(comment.callState)"
                (click)="openReply()"
            ></button>
            <button
                class="pi pi-pen-to-square action"
                *ngIf="canEdit"
                [disabled]="isErrorState(comment.callState)"
                (click)="setMode('edit')"
            ></button>

            <button
                class="action pi pi-ellipsis-v"
                *ngIf="showMenu"
                (click)="commentMenu.toggle($event)"
            ></button>
            <p-menu
                #commentMenu
                [model]="commentMenuItems"
                [popup]="true"
            />
        </div>
    </div>

    <p-message
        *ngIf="isErrorState(comment.callState)"
        severity="error"
        [text]="comment.callState?.error"
    ></p-message>

    <!--
	We need to create two instances with different modules
	because quill doesnt know to reload modules
	-->
    <quill-editor
        #editor
        *ngIf="mode === 'view'"
        class="content viewMode"
        [readOnly]="true"
        [(ngModel)]="contents"
        [format]="format"
        [modules]="viewModules"
    ></quill-editor>

    <quill-editor
        #editor
        *ngIf="mode === 'edit'"
        class="content editMode"
        [readOnly]="false"
        [(ngModel)]="contents"
        [format]="format"
        [modules]="editModules"
    ></quill-editor>

    <div
        *ngIf="mode === 'edit'"
        class="commentButtons"
    >
        <p-button
            label="Save Comment"
            severity="primary"
            (click)="save()"
        />
        <p-button
            label="Cancel"
            severity="secondary"
            (click)="setMode('view')"
        />
    </div>

    <!-- <span
		class="preserve-whitespace"
	>{{ contents }}</span> -->
    <p-button
        (click)="repliesExpanded = !repliesExpanded"
        class="replyExpander"
        [link]="true"
        *ngIf="showReplies && (replies.length || replyEnabled)"
    >
        {{ repliesExpanded ? "Collapse" : "Expand" }} Replies
    </p-button>

    <div
        class="replies"
        *ngIf="showReplies && repliesExpanded && replies"
    >
        <!--
			Show replies here but only if we are the parent
		-->
        <!-- <app-job-comment
            *ngFor="let reply of replies"
            class="comment"
            [class.internal]="!comment.author"
            [class.topLevel]="false"
            [replyEnabled]="false"
            [showReplies]="false"
            [comment]="reply"
            [job]="job"
        ></app-job-comment> -->
        <p-timeline
            [value]="replies"
            class="comments"
        >
            <ng-template
                pTemplate="content"
                let-reply
            >
                <app-job-comment
                    class="comment"
                    [class.internal]="!comment.author"
                    [class.topLevel]="false"
                    [replyEnabled]="false"
                    [showReplies]="false"
                    [comment]="reply"
                    [job]="job"
                ></app-job-comment>
            </ng-template>
        </p-timeline>
    </div>

    <div
        class="content-reply"
        *ngIf="replyEnabled && repliesExpanded &&  !isErrorState(comment.callState)"
    >
        <input
            class="replyInput"
            pInput
            *ngIf="!replyOpened"
            placeholder="Reply..."
            (focus)="openReply()"
        />
        <quill-editor
            #replyEditor
            [class.hidden]="!replyOpened"
            class="content editMode"
            [readOnly]="(mutating$ | async) || isErrorState(comment.callState)"
            [(ngModel)]="replyContents"
            format="object"
            [modules]="editModules"
        ></quill-editor>

        <div
            class="commentButtons"
            *ngIf="replyOpened"
        >
            <p-button
                class="replyButton"
                [disabled]="(mutating$ | async) || addReplyIsEmpty() || isErrorState(comment.callState)"
                label="Add Comment"
                severity="primary"
                (click)="addReply()"
            />
            <p-button
                [disabled]="(mutating$ | async) || isErrorState(comment.callState)"
                label="Cancel"
                severity="secondary"
                (click)="closeReply()"
            />
        </div>
    </div>
</div>