import { Pipe, PipeTransform } from '@angular/core';

import { BaseLocationFragment, LocationCreateBase } from '../../generated/graphql.generated';

import { getLocationAreaCode } from '../utilities/locations.util';

@Pipe({
  name: 'areaCode'
})
export class AreaCodePipe implements PipeTransform {

  transform(location: BaseLocationFragment | LocationCreateBase): string {
    return getLocationAreaCode(location);
  }

}
