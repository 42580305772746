<p-button
  icon="pi pi-plus"
  label="Add Filter"
  class="full-width"
  (click)="overlayOpened.next(); op.toggle($event)"
  [outlined]="true"
  [rounded]="true"
  ></p-button>

<p-overlayPanel
  #op
  [style]="{ width: panelWidth + 'px' }"
  (onHide)="mode = 'menu'"
  >
    <ng-template pTemplate>
      <div [hidden]="mode === 'input'">
        <p-tieredMenu
          [model]="filters"
          [menuWidth]="panelWidth - 30"
          [style]="{ width: '100%' }"
          ></p-tieredMenu>
      </div>
      <div *ngIf="mode === 'input'">
        <ng-container *ngTemplateOutlet="currentInput"></ng-container>
        <button
          pButton
          label="Back"
          class="p-button-secondary p-button-text p-mt-2"
          (click)="mode = 'menu'"
          icon="pi pi-caret-left"
          ></button>
      </div>
    </ng-template>
</p-overlayPanel>
