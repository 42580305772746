<p-messages
    [(value)]="msgs"
    [enableService]="false"
    data-cy="missing-or-invalid-message"
    close
></p-messages>

<ng-container *ngIf="!loadingAuthMethods && authMethods?.length">
    <!-- List auth methods -->

    <div *ngFor="let authMethod of authMethods"
        [class.darkMode]="colorSchemeSvc.darkMode"
    >
        <form
            *ngIf="authMethod.authMethodType === 'password'"
            class="password-form auth-form"
            [formGroup]="authMethod.passwordForm"
            (submit)="login(authMethod)"
        >
            <h2 *ngIf="showHeader">Login</h2>
            <ng-container *appVar="authMethod.passwordForm.get('email') as control">
                <input
                    autofocus
                    type="email"
                    pInputText
                    [readonly]="authenticating || forCurrentUser"
                    placeholder="Email"
                    formControlName="email"
                    [hidden]="control.value && forCurrentUser"
                    [class.p-invalid-input]="!control.valid && control.touched"
                    data-cy="login-email-text-field"
                >
                <app-validation-messages
                    [control]="control"
                    fieldName="Email"
                    data-cy="email-valid-error-msg"
                >
                </app-validation-messages>
            </ng-container>
            <ng-container *appVar="authMethod.passwordForm.get('password') as control">
                <input
                    pPassword
                    type="password"
                    id="password"
                    [readonly]="authenticating"
                    [feedback]="false"
                    formControlName="password"
                    placeholder="Password"
                    data-cy="login-password-text-field"
                    [class.p-invalid-input]="!control.valid && control.touched"
                />
                <app-validation-messages
                    [control]="control"
                    fieldName="Password"
                    data-cy="password-valid-error-msg"

                >
                </app-validation-messages>
            </ng-container>
            <ng-container *ngIf="show2faBox">
                <ng-container *appVar="authMethod.passwordForm.get('twoFactorCode') as control">
                    <input
                        pInputText
                        type="text"
                        pattern="[0-9 ]*"
                        inputmode="numeric"
                        [feedback]="false"
                        [readonly]="authenticating"
                        formControlName="twoFactorCode"
                        placeholder="000 000"
                        class="full-width"
                    >
                    <app-validation-messages
                        [control]="control"
                        fieldName="Two Factor Code"
                    >
                    </app-validation-messages>
                </ng-container>
            </ng-container>

            <div
                *ngIf="showKeepMeLoggedIn"
                class="keep-me-logged-in"
            >
                <p-checkbox
                    [(ngModel)]="keepMeLoggedIn"
                    [binary]="true"
                    inputId="keepMeLoggedIn"
                    [ngModelOptions]="{standalone: true}"
                    data-cy="keep-me-logged-in-check-box"
                ></p-checkbox>
                <label for="keepMeLoggedIn">Keep me logged in</label>
            </div>

            <div class="password-login-buttons auth-btn-set">
                <button
                    pButton
                    pRipple
                    id="login-submit-button"
                    class="auth-btn p-button-lg p-button-primary login-submit-button"
                    [disabled]="authenticating || !authMethod.passwordForm.valid"
                    [pTooltip]="tooltips ? keepMeLoggedIn ? 'Login forever' : 'Login temporarily' : undefined"
                    [label]="loginText"
                    data-cy="login-submit-button"
                    type="submit"
                ></button>
                <button
                    pButton
                    pRipple
                    *ngIf="showLoginForever"
                    id="login-forever-button"
                    class="auth-btn p-button-outlined p-button-primary login-forever-button"
                    [disabled]="authenticating || !authMethod.passwordForm.valid"
                    label="Login Forever"
                    type="button"
                    (click)="login(authMethod, true)"
                    [pTooltip]="tooltips ? 'Login forever' : undefined"
                ></button>
                <a
                    pButton
                    pRipple
                    *ngIf="showResetPassword"
                    id="reset-password-button"
                    label="Forgot your password?"
                    class="auth-btn p-button-outlined p-button-primary reset-password-button"
                    [routerLink]="resetPasswordPath"
                    [queryParams]="{ email: authMethod.passwordForm.get('email').value }"
                    [pTooltip]="tooltips ? 'Reset Password' : undefined"
                    data-cy="reset-password-button"
                ></a>
            </div>
        </form>
        <form *ngIf="authMethod.authMethodType === 'oidc'">

            Show OIDC form here
        </form>
        <form *ngIf="authMethod.authMethodType === 'ethereum'">

            Show ethereum form here
        </form>

    </div>

</ng-container>

<h2 *ngIf="!loadingAuthMethods && authMethods && !authMethods.length">
    This server does not have authentication configured.
</h2>

<div
    class="centered"
    *ngIf="!loadingAuthMethods && !authMethods && authMethodLoadError"
>
    <h2>Authentication systems are currently unavailable.</h2>
    <p-message
        severity="error"
        [text]="authMethodLoadError"
    ></p-message>
</div>

<div
    *ngIf="loadingAuthMethods"
    class="loading-auth-methods"
>
    <p-skeleton
        width="14ch"
        height="2em"
        class="header"
    ></p-skeleton>
    <p-skeleton
        width="100%"
        height="40px"
        class="input"
    ></p-skeleton>
    <p-skeleton
        width="100%"
        height="40px"
        class="input"
    ></p-skeleton>

    <p-skeleton
        width="100%"
        height="25px"
        class="button"
    ></p-skeleton>
    <p-skeleton
        width="100%"
        height="20px"
        class="button"
    ></p-skeleton>
    <p-skeleton
        width="100%"
        height="20px"
        class="button"
    ></p-skeleton>
</div>

<p-dialog
    header="Enter Two Factor Authentication (2FA) Code"
    [(visible)]="show2FADialog"
    [dismissableMask]="true"
    [appendTo]="append2faDialogTo"
    styleClass="two-factor-dialog"
    [modal]="true"
>
    <form
        [formGroup]="twoFactorForm"
        (submit)="submit2fa()"
    >
        <small
            *ngIf="twoFactorInvalid"
            class="error-text"
            data-cy="error-text"
        >Code was not valid, Please Try Again</small>
        <input
            pInputText
            type="text"
            pattern="[0-9 ]*"
            inputmode="numeric"
            placeholder="000 000"
            class="full-width"
            formControlName="code"
            (keyup)="submit2fa()"
            [readonly]="authenticating"
            autofocus
        >
        <button
            type="submit"
            class="full-width auth-btn p-button-lg p-button-primary two-factor-btn"
            pButton
            [label]="loginText"
            [disabled]="authenticating"
        ></button>
    </form>
</p-dialog>