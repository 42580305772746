import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';

import { DashboardService, DashboardVersion } from '../dashboard/dashboard.service';

export const dashboardVersionLsKey = 'dashboard_version';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService
  ) {}

  dashboardVersion$ = this.dashboardService.dashboardVersion$;

  ngOnInit(): void {
    this.dashboardVersion$.pipe(first()).subscribe((version) => {

      const storedVersion = localStorage.getItem(dashboardVersionLsKey);

      if (!this.isValidVersion(storedVersion)) {
        localStorage.removeItem(dashboardVersionLsKey);
        return;
      }

      if (storedVersion !== version) {
        this.dashboardService.setDashboardVersion(storedVersion);
      }
    });
  }

  isValidVersion(version: string): version is DashboardVersion {
    return version === 'v1' || version === 'v2';
  }

}
