<div class="p-px-3 p-pb-3 p-d-flex p-jc-between">
  <div>
    <span class="p-input-icon-right">
      <i class="pi pi-times clear-search"
        *ngIf="!dynamicReportsQH.loading && dynamicReportsQH.search"
        pTooltip="Clear Search"
        (click)="clearSearch()"
        ></i>
      <input
      type="text"
      #searchInput
      pInputText
      [(ngModel)]="dynamicReportsQH.search"
      (ngModelChange)="filterUpdated.next()"
      placeholder="Search..."
      >
    </span>
  </div>
  <button
    pButton
    label="Build New Report"
    routerLink="/reports/dynamic/new"
    ></button>
</div>
<p-table
  [value]="dynamicReports"
  [paginator]="true"
  (onLazyLoad)="retrieveMoreReports($event)"
  [lazy]="true"
  [totalRecords]="dynamicReportsQH.total"
  [(rows)]="dynamicReportsQH.limit"
  [loading]="dynamicReportsQH.loading"
  [showCurrentPageReport]="!dynamicReportsQH.loading"
  breakpoint="620px"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Description</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-report
    >
    <tr
      class="selectable-row"
      [routerLink]="['/reports', 'dynamic', report.id ]"
      >
      <td>{{ report.name }}</td>
      <td>{{ report.description }}</td>
    </tr>
  </ng-template>
</p-table>
