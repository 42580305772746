import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';

@Component({
  selector: 'app-estimating-panel',
  templateUrl: './estimating-panel.component.html',
  styleUrls: ['./estimating-panel.component.scss']
})
export class EstimatingPanelComponent implements OnInit {

  constructor(
    public appMain: AppMainComponent,
    private detailHelperService: DetailsHelperService,
    private estimatingHelper: EstimateHelperService
  ) { }

  ngOnInit(): void {
  }

}
