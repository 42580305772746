import { Component, Input, OnInit } from '@angular/core';

import { DashboardModuleData } from '../dashboard.constants';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent implements OnInit {

  @Input() value: DashboardModuleData;

  constructor() { }

  ngOnInit(): void {
  }

  isFinite() {
    return this.value?.change && Number.isFinite(this.value?.change);
  }

  isInfinite() {
    return this.value?.change && !Number.isFinite(this.value?.change);
  }

  isNaN() {
    return Number.isNaN(this.value?.change);
  }

}
