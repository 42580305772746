import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreateUsersInput, createUsersMutation, CreateUsersOutput } from '@karve.it/interfaces/registration';
import {
  deactivateMutation, DeactivateUsersInput, DeleteUsersInput, deleteUsersMutation,
  DeleteUsersOutput, EditProfileInput, editProfileMutation, EditProfileOutput, generateUsersQuery,
  GenerateUsersQueryInput, ListUsersInput, ListUsersV2Output
} from '@karve.it/interfaces/users';
import { map } from 'rxjs/operators';

import { PlusApollo } from '../auth/graphql.module';

export class EmailControl extends UntypedFormControl {
  ignoreAddress?: string;
  debounceTime?: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: PlusApollo) { }

  /**
   * Lists Users based on input
   * queryInput - Sets which items should be queried. The Base properties for the type will always be queried.
   */
   listUsersV2(filter: ListUsersInput, queryInput: GenerateUsersQueryInput, cache = true, overrideZone = '') {
    return this.apollo.query<ListUsersV2Output>({
      query: generateUsersQuery(queryInput),
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
      context: {
        headers: {
          'x-zone': overrideZone,
        },
      },
      variables: {
        filter,
        skip: filter.skip,
        limit: filter.limit,
        sort: filter.sort,
      }
    });
  }

  /**
   * Lists Users based on input, watches for changes.
   */
   watchUsersV2(filter: ListUsersInput, queryInput: GenerateUsersQueryInput, cache = true, overrideZone = '') {
    return this.apollo.watchQuery<ListUsersV2Output>({
      query: generateUsersQuery(queryInput),
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
      context: {
        headers: {
          'x-zone': overrideZone,
        },
      },
      variables: {
        filter,
        skip: filter.skip,
        limit: filter.limit,
        sort: filter.sort,
      }
    });
  }


  // mapUsers(users: RawUser[]) {
  //   return users.map((raw) => {
  //     const val = rawUserToUser(raw) as UserWithName;
  //     const hasSpace = val.givenName && val.familyName && val.givenName !== '' && val.familyName !== '';
  //     val.name = (val.givenName || '') + (hasSpace ? ' ' : '') + (val.familyName || '');
  //     return val;
  //   });
  // }

  /**
   * Create New Users.
   *
   * @param input Create users from this.
   * @returns Total number of users, any failed users and the reason they failed to create.
   */
  createUsers(input: CreateUsersInput, overrideZone = '') {

    return this.apollo.mutate<CreateUsersOutput>({
      mutation: createUsersMutation,
      variables: input,
      context: {
        headers: {
          'x-zone': overrideZone,
        },
      },
    }).pipe(map((res) => res.data.createUsers));
  }


  /**
   * Set the value of user information fields.
   *
   * @param input Input for setting the field value.
   * @returns Number of users modified.
   */
  setProfile(input: EditProfileInput) {
    return this.apollo.mutate<EditProfileOutput>({
      mutation: editProfileMutation,
      variables: input,
    }).pipe(map((res) => res.data.users));
  }

  /**
   * Delete one or many users from the system.
   *
   * @param input Ids of the users to delete.
   * @returns Boolean true if delete is successful, false if delete fails.
   */
  deleteUsers(input: DeleteUsersInput) {
    return this.apollo.mutate<DeleteUsersOutput>({
      mutation: deleteUsersMutation,
      variables: input,
    }).pipe(map((res) => res.data.users));
  }

  /**
   * Deactivate/Soft Delete Users or Reactivate them
   *
   * @param input Filter for users and boolean that should be set to true if we want to reactivate the user
   * @returns User
   */
  deactivateUsers(input: DeactivateUsersInput){
    return this.apollo.mutate<boolean>({
      mutation: deactivateMutation,
      variables: {
        filter: input.filter,
        reactivate: input.reactivate || false
      }
    });
  }
}
