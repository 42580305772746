import { Component, OnDestroy, OnInit } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { ChartData } from 'chart.js';
import { SubSink } from 'subsink';

import { environment } from '../../environments/environment';
import { advancedPeriodOptions, isAdvancedPeriod, Period } from '../reports/reports.constants';
import { BrandingService } from '../services/branding.service';

import { FreyaHelperService } from '../services/freya-helper.service';

import { TimezoneHelperService } from '../services/timezone-helper.service';

import { DashboardData, transactionVolumeChartOptions } from './dashboard.constants';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    subs = new SubSink();

    graphData: ChartData;
    transactionVolumeChartOptions = transactionVolumeChartOptions;

    data: DashboardData = {};
    allCurrencies: {
        label: string;
        value: string;
    }[] = [];

    // Values to display in the inputs
    visualPeriodStart: Date;
    visualPeriodEnd: Date;

    // populate list of periods to select from
    periodOptions = advancedPeriodOptions.filter((p) => p !== 'Since Last Run');

    get period() {
        return this.dashboardService.period;
    }

    set period(_period: Period) {
        this.dashboardService.period = _period;
    }

    dashboardV2Enabled$ = this.branding.watchFeature('dashboard-v2');

    constructor(
        public dashboardService: DashboardService,
        private branding: BrandingService,
        private freyaHelper: FreyaHelperService,
        private timezoneHelper: TimezoneHelperService,
    ) {

    }

    ngOnInit() {
        this.graphData = undefined;
        const storedPeriod = localStorage.getItem(environment.lsDashboardPeriod);
        if (storedPeriod && isAdvancedPeriod(storedPeriod)) {
            this.dashboardService.periodOption = storedPeriod;

            if (storedPeriod === 'Custom' && localStorage.getItem('dashboard-custom-range')) {
                this.period = JSON.parse(localStorage.getItem('dashboard-custom-range'));

                if (!this.period){
                    this.initCustomPeriod();
                }

                // Need to convert to string to prevent timezone issues
                this.visualPeriodStart = new Date(dayjs.unix(this.period.start).format('YYYY-MM-DD') + 'T00:00:00');
                this.visualPeriodEnd = new Date(dayjs.unix(this.period.end).format('YYYY-MM-DD') + 'T00:00:00');
            }
        }


        this.subs.sink = this.branding.currentBranding.subscribe(() => {
            if (this.branding.loading.value === true) { return; }

            // console.log(`Reloading dashboard because of zone change`);
            this.dashboardService.load(true);
        });

        this.subs.sink = this.dashboardService.onDataUpdated.subscribe(([ key, data ]) => {
            // console.log(key, data.avgActiveJobSize);
            this.data = data;
            this.updateCurrencies();
            this.updateGraph();

        });

        this.period = this.dashboardService.getPeriod(this.period);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.dashboardService.reset();
    }

    updateCurrencies() {

        if (!this?.data?.currencies?.allCurrencies?.length) {
            this.allCurrencies = [{
                label: this.dashboardService.currency,
                value: this.dashboardService.currency,
            }];
            return;
        }

        this.allCurrencies = this.data.currencies.allCurrencies.map((c) => ({
            value: c.currency,
            label: `${ c.currency } - ${ c.count } job${ c.count === 1 ? '' : 's' }`,
        }));


    }

    updateGraph() {
        if (!this.data.txVolume) {
            this.graphData = undefined;
            return;
        }

        // create correct labels from period
        const { start, end } = this.dashboardService.getPeriod();
        let current = start;
        const aggregateInterval = this.dashboardService.aggregateInterval;
        let formatMode = 'YYYY-MM-DD';
        if (aggregateInterval === 'hour') {
            formatMode = 'HH:mm';
        } else if (aggregateInterval === 'day') {
            formatMode = 'YYYY-MM-DD';
        } else if (aggregateInterval === 'month') {
            formatMode = 'YYYY-MM';
        } else if (aggregateInterval === 'year') {
            formatMode = 'YYYY';
        }

        const data: {
            label: string;
            txVolume: number;
        }[] = [];

        let i = 0;
        do {
            const label = dayjs(current * 1000).format(formatMode);
            current = dayjs(current * 1000).add(1, aggregateInterval).unix();

            data.push({
                label,
                txVolume: 0,
            });
        } while (current <= end && i++ < 100);

        // used to calculate range of y axis
        const allValues: number[] = [];

        this.data.txVolume.forEach((str) => {
            const [ label, strTxVolume ] = str.split(':');
            const txVolume = parseInt(strTxVolume, 10) / 100;
            allValues.push(txVolume);
            // const label = moment(parseInt(unix) * 1000).format(formatMode);
            const dataObj = data.find((d) => d.label === label);
            if (dataObj) {
                dataObj.txVolume = txVolume;
            } else {
                console.warn('Could not place datapoint: ', label, data);
            }
        });

        const labels: string[] = data.map((d) => d.label);
        const txVolumeData: number[] = data.map((d) => d.txVolume);

        const ticks = this.transactionVolumeChartOptions.scales.y.ticks;
        if (ticks) {
            const max = isFinite(Math.max(...allValues)) ? Math.max(...allValues) : 1;
            const min = isFinite(Math.min(...allValues)) ? Math.min(...allValues) : 1;
            const nSteps = 4;
            // @ts-ignore, Doesn't match the updated type for v3 but seems to be backwards compatible
            ticks.min = min;
            // @ts-ignore, Doesn't match the updated type for v3 but seems to be backwards compatible
            ticks.stepSize = ((max - min) || 1) / nSteps;
        }

        this.graphData = {
            labels,
            datasets: [
                {
                    label: 'Transaction Volume',
                    data: txVolumeData,
                    borderColor: [
                        getComputedStyle(document.body).getPropertyValue('--primary-color') || '#2c84d8',
                    ],
                    borderWidth: 4,
                    fill: true,
                    backgroundColor: [
                        getComputedStyle(document.body).getPropertyValue('--primary-lighter-color') || '#2c84d8',
                    ],
                }
            ]
        };

        console.log(this.graphData);
    }


    onDateRangeSelected(type: 'start' | 'end', value: Date){
        this.data = {};
        this.graphData = undefined;

        // These dates need to be converted to strings to ensure that the date and not the 'DateTime' is preserved.
        // ie. local to bay area results in the day being behind because it shifts the DateTime into the timezone which is on another day
        if (type === 'start') {
            this.period[type] = dayjs(this.timezoneHelper.convertDateToString(value)).startOf('day').unix();
        }

        if (type === 'end') {
            this.period[type] = dayjs(this.timezoneHelper.convertDateToString(value)).endOf('day').unix();
        }

        localStorage.setItem(environment.lsDashboardPeriod, this.dashboardService.periodOption);
        // Store the custom period in local storage for reuse
        localStorage.setItem('dashboard-custom-range', JSON.stringify(this.period));

        this.dashboardService.load(false);
    }

    onPeriodUpdated() {
        this.data = {};
        this.graphData = undefined;
        localStorage.setItem(environment.lsDashboardPeriod, this.dashboardService.periodOption);

        if (this.dashboardService.periodOption === 'Custom') {
            this.period = JSON.parse(localStorage.getItem('dashboard-custom-range'));

            if (!this.period){
                this.initCustomPeriod();
            }

            this.dashboardService.load(false);
            return;
        }

        this.dashboardService.load();
    }

    onCurrencyUpdated() {
        this.dashboardService.load();
    }

    initCustomPeriod(){
        this.period = {
            start: dayjs().subtract(1, 'month').startOf('day').unix(),
            end: dayjs().endOf('day').unix(),
        };
        localStorage.setItem('dashboard-custom-range', JSON.stringify(this.period));

        // Need to convert to string to prevent timezone issues
        this.visualPeriodStart = new Date(dayjs.unix(this.period.start).format('YYYY-MM-DD') + 'T00:00:00');
        this.visualPeriodEnd = new Date(dayjs.unix(this.period.end).format('YYYY-MM-DD') + 'T00:00:00');
    }

    tryDashboardV2() {
        this.dashboardService.setDashboardVersion('v2');
    }
}
