import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChargeService, JobService } from '@karve.it/features';
import { Charge, ListChargesOutput } from '@karve.it/interfaces/charges';
import { Job, UpdateJobsInput } from '@karve.it/interfaces/jobs';
import { Tag } from '@karve.it/interfaces/tag';
import {QueryRef} from 'apollo-angular';
import { LazyLoadEvent } from 'primeng/api';
import { SubSink } from 'subsink';

import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaMutateService } from '../services/freya-mutate.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { PermissionService } from '../services/permission.service';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss']
})
export class ChargesComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() charges: Charge[] = [];
  @Input() job: Job;
  @Input() isDamages = false; // If true we show damages if false we show charges
  @Input() readonly = false; // If true all create/update functionality is disabled
  @Input() readonlyTooltip: string; // What to show if create/update functionality is disabled

  // Modify Component Layout
  @Input() tableTitle: string = undefined;
  @Input() showHeaderCard = true;
  @Input() showEditButton = true;
  @Input() showRemoveButton = true;
  @Input() activeData = {
    product: true,
    amount: true,
    quantity: true,
    subtotal: true
  };

  subs = new SubSink();

  // QUERY VARIABLES
  chargesQueryRef!: QueryRef<ListChargesOutput>;
  chargesQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: false,
    search: ''
  };

  loadedParams = false;

  pagination = pagination;

  // Charges associated with an event ending before the lock date and which therefore should not be modified
  lockedCharges: { [ chargeId: string ]: boolean } = {};

  formattedLockDate?: string;

  constructor(
    private detailsHelper: DetailsHelperService,
    private chargesService: ChargeService,
    private freyaMutate: FreyaMutateService,
    private jobService: JobService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    this.chargesQH.total = this.charges?.length;

    this.subs.sink = this.detailsHelper.getObjectUpdates('User').subscribe((res) => {
      if (!this.chargesQueryRef) { return; }
      this.chargesQueryRef.refetch();
    });

    this.subs.sink = this.permissionHandler.watchPermissions([
      'charges.update',
      'charges.delete',
      'calendarEvents.edit',
    ]).subscribe((res) => {
      this.showEditButton = res[0] && res[2];
      this.showRemoveButton = res[0] && res[1];
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.charges) {
      this.setLockedCharges();
    }
  }

  removeCharge(charge: Charge){
    this.jobService.updateJobs({updateJobs: [{jobId: this.job.id, removeCharges: [charge.id]}]} as UpdateJobsInput).subscribe((res) => {
      this.localNotify.success('Charge removed');
      this.detailsHelper.pushUpdate({
        id:this.job.id,
        type:'Jobs',
        action:'update'
      });

    }, (err) => {
      console.error(err);
      this.localNotify.apolloError(`Failed to remove charge`, err);
    });
  }

  // Pagination
  // retrieveMoreCharges(event: LazyLoadEvent) {
  //   this.chargesQH.limit = event.rows;
  //   this.chargesQH.skip = event.first;

  //   this.searchForCharges();
  // }

  // Search For Charges based on filter values
  // async searchForCharges() {
  //   if (!this.loadedParams) { return; }

  //   if (this.chargesQueryRef) {
  //     this.chargesQH.loading = true;

  //     this.chargesQueryRef.resetLastResults();
  //     this.chargesQueryRef.setVariables(
  //       {
  //         limit: this.chargesQH.limit,
  //         skip: this.chargesQH.skip,
  //         filter: {

  //         },
  //       }, true, true);
  //   } else {
  //     this.retrieveCharges();
  //   }
  // }

  // retrieveCharges() {
  //   const watchChargesInput = {
  //     filter: {},
  //     limit: this.chargesQH.limit,
  //     skip: this.chargesQH.skip,
  //   } as ChargesInput;

  //   this.chargesQueryRef = this.chargesService.watchCharges(watchChargesInput, {fetchPolicy: 'no-cache'});

  //   this.subs.sink = this.chargesQueryRef.valueChanges.subscribe((res) => {
  //     if (res.networkStatus !== 7) { return; }
  //     // this.charges = res.data.charges.charges.filter(()) || [];
  //     this.chargesQH.total = res.data.charges.total;
  //     this.chargesQH.loading = false;
  //   });
  // }

  // selectJob(job: Job) {
  //   this.detailsHelper.detailsItem.next({ type: 'job', item: job });
  // }


  createCharge(){
    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'charge',
      additionalValues: [{
        property: 'job',
        value: this.job
      },
      {
        property: 'isDamage',
        value: this.isDamages,
      }]
    });
  }

  editCharge(charge: Charge){
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'charge',
      object: charge,
      additionalValues: [{
        property: 'job',
        value: this.job
      },
      {
        property: 'isDamage',
        value: this.isDamages,
      }]
    });
  }

  setLockedCharges() {

    if (!this.charges) { return; }

    for (const charge of this.charges) {

      if (!charge.calendarEvent?.end) { continue; }

      this.lockedCharges[charge.id] = this.freyaHelper.lockDate > charge.calendarEvent.end;

    }

    this.formattedLockDate = this.freyaHelper.getFormattedLockDate();
  }

  openCharge(charge: Charge) {
    this.detailsHelper.open('charge', charge, true);
  }
}
