import { Pipe, PipeTransform } from '@angular/core';

import { BaseLocationFragment } from '../../generated/graphql.generated';
import { getLocationAreaCode } from '../utilities/locations.util';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {

  transform(value: BaseLocationFragment): unknown {

    if (!value) { return; }

    const text = `${value.addressLineOne} ${value.city} ${getLocationAreaCode(value)}`;

    const url = `https://www.google.com/maps/place/${encodeURIComponent(text)}`;

    return {
      text,
      url
    };
  }

}
