<p>{{ prompt }}</p>
<ul>
  <li *ngFor="let event of events">
    {{ event.title | rename : 'osestimate' : 'estimate' : false |  titlecase }}
    -
    {{ event?.start ? (event.start | freyaDate : 'MMM dd, YYYY hh:mm') : 'Unscheduled' }}
  </li>
</ul>
<p>Do you want to mark them as complete?</p>
<div class="footer">
  <button
    pButton
    label="No"
    icon="pi pi-times"
    (click)="ref.close(false)"
    ></button>
  <button
    class="p-button-success"
    pButton
    label="Yes, Mark As Complete"
    (click)="ref.close(true)"
    ></button>
</div>
