
<ng-container [ngSwitch]="agFormat">

    <app-report-aggregation-value-value
        *ngSwitchCase="'value'"
        class="value"
        [ag]="ag"
        [report]="report"
        [value]="ag.values"
    >
    </app-report-aggregation-value-value>
    <div class="fulltable" *ngSwitchCase="'fulltable'">
        <table>
            <tr
                *ngFor="let label of ag.labels; let i = index" 
            >
                <td>
                    <app-report-aggregation-value-label
                        [ag]="ag"
                        [report]="report"
                        [label]="label"
                    >
                    </app-report-aggregation-value-label>
                </td>
                <td>
                    <app-report-aggregation-value-value
                        class="value"
                        [ag]="ag"
                        [report]="report"
                        [value]="ag.values[i]"
                    >
                    </app-report-aggregation-value-value>
                </td>
            </tr>

        </table>
    </div>
    <div class="values" *ngSwitchCase="'values'">
        <table>
            <tr
                *ngFor="let value of ag.values; let i = index" 
            >
                <td><ng-container 
                    *ngTemplateOutlet="valueOut; context:{ output: value }">
                </ng-container></td>
            </tr>

        </table>
    </div>
    <div class="labels" *ngSwitchCase="'labels'">
        <table>
            <tr
                *ngFor="let label of ag.labels; let i = index" 
            >
                <td>
                    <app-report-aggregation-value-label
                        [ag]="ag"
                        [report]="report"
                        [label]="label"
                    >
                    </app-report-aggregation-value-label>
                </td>
            </tr>
        </table>
    </div>
    <div class="object" *ngSwitchDefault>{{ ag | json }}</div>

</ng-container>
