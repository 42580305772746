import {
  AfterViewInit, ChangeDetectorRef, Component, Input,
  OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { RawUser } from '@karve.it/interfaces/auth';

import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Tag } from 'primeng/tag';
import { SubSink } from 'subsink';

import { EstimatesJobFragment, FullTransactionFragment, TransactionsGQL, TransactionsQueryVariables } from '../../generated/graphql.generated';

import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { EstimateHelperService } from '../services/estimate-helper.service';
import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { MutateTransactionComponent } from '../shared/mutate-transaction/mutate-transaction.component';
import { SendDocumentComponentInput, SendDocumentsComponent } from '../shared/send-documents/send-documents.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('mutate') mutate: MutateTransactionComponent;
  //ViewChild('transactionSearchInput', { static: true }) searchInput!: ElementRef;

  @Input() user: RawUser;
  @Input() job: EstimatesJobFragment;
  @Input() retrieveOnLoad = true;

  // FILTER VARIABLES
  @Input() filter: TransactionsQueryVariables['filter'] = {};
  @Input() pagination = pagination;

  // MODIFY COMPONENT LAYOUT
  // Show seperate header card
  @Input() showHeaderCard = true;
  // Show title in the card with the table
  @Input() smallTitle = false;
  // If true all create/update is disabled
  @Input() readonly = false;
  // What to show if create/update is disabled
  @Input() readonlyTooltip: string;
  // Show the button to open send receipt
  @Input() showReceiptButton = false;

  @Input() emptyMessage = pagination.emptyMessage;

  subs = new SubSink();

  tags: Tag[] = [];

  // Component Variables
  transactions: FullTransactionFragment[] = [];

  // Transaction Query Variables
  transactionsQueryRef!: ReturnType<typeof this.transactionGQL.watch>;
  transactionsQH: WatchQueryHelper = {
    limit: 5,
    skip: 0,
    loading: true,
    search: ''
  };

  constructor(
    private detailsHelper: DetailsHelperService,
    private estimateHelper: EstimateHelperService,
    private ref: ChangeDetectorRef,
    private dialogService: DialogService,
    private transactionGQL: TransactionsGQL,
    private responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.transactions = [];

    this.subs.sink = this.detailsHelper.getObjectUpdates([
      'Transactions',
      'Invoice',
    ]).subscribe(() => {
      this.retrieveTransactions(true);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    //to avoid second call when job is created and there are no transactions to retrieve
    if (!this.estimateHelper.newJobCreated
      && 'job' in changes
      && changes?.job?.currentValue?.id !== changes?.job?.previousValue?.id) {
      this.retrieveTransactions();
    }
    // this.transactionsQH.limit = this.pagination.defaultNumberOfItems;
  }

  retrieveTransactions(refetch = false) {
    const transactionInput = {
      filter: this.filter,
      limit: this.transactionsQH.limit,
    } as TransactionsQueryVariables;

    if (!this.transactionsQueryRef) {

      this.transactionsQueryRef = this.transactionGQL.watch(transactionInput);

      this.subs.sink = this.transactionsQueryRef.valueChanges.subscribe((res) => {
        this.transactionsQH.loading = res.loading;
        if(res.loading) return;

        if (res.networkStatus === 7) {
          this.transactions = res.data.transactions.transactions;
          this.transactionsQH.total = res.data.transactions.total;
        }
      });
    } else if (refetch) {
      this.transactionsQueryRef.refetch(transactionInput);
    } else {
      this.transactionsQueryRef.setVariables(transactionInput);
    }
  }

  // Pagination
  retrieveMoreTransactions(event: LazyLoadEvent) {

    this.transactionsQH.limit = event.rows;
    this.transactionsQH.skip = event.first;

    this.retrieveTransactions();
  }

  selectTransaction(transaction: FullTransactionFragment) {
    this.detailsHelper.detailsItem.next({ type: 'transaction', item: transaction });
  }

  openMutateDialog() {
    this.mutate.mutateType = 'create';
    this.mutate.openDialog();
  }

  openSendReceipt(){
    this.dialogService.open(SendDocumentsComponent, {
      header: 'Send Documents',
      data: {
        input: {
          jobId: this.job.id,
          preselectTemplateKey: 'standard-documents.receipt'
        } as SendDocumentComponentInput,
      },
      width: this.responsiveHelper.dialogWidth
    });
  }

  openTransactionInvoice(transaction: FullTransactionFragment) {

    if (!transaction.invoice) { return; }

    this.detailsHelper.detailsItem.next({ type: 'invoice', item: { id: transaction.invoice.id } });

  }
}
