<p-dialog 
    [(visible)]="showHelpDialog"
    [closable]="true"
    [style]="{width: '40vw'}"
    [resizable]="true"
    [closeOnEscape]="false"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    appendTo="body"
>
    <ng-template pTemplate="header">
        <div class="header">
            <div class="p-d-flex p-ai-lg-center">
                <i class="pi pi-question-circle" style="min-width: 50px; font-size: 2rem;"></i>
                <span style="font-size:1.5rem;">
                    <b>What are you looking for?</b>
                </span>
            </div>
            <div style="display:flex; text-align:left; margin-top:20px;">
                <p-autoComplete
                    [style]="{ flex: 2 }"
                    placeholder="Search"
                    [dropdown]="true"
                    [(ngModel)]="pageSearchInput"
                    [suggestions]="filteredPages"
                    (completeMethod)="filterPages($event)"
                    (onSelect)="resetCurrentPage($event)"
                    field="title"
                    appendTo="body"
                >
                    <ng-template let-page pTemplate="item">
                        <div style="text-align: left;" class="ml-2">
                            <i [class]="page.icon"></i>
                            {{page.title}}
                        </div>
                    </ng-template>
                </p-autoComplete>
                <div style="flex:1; display:flex; justify-content: end;">
                    <button
                        pButton
                        [disabled]="!this.historyIndex"
                        pTooltip="Back to previous page"
                        tooltipPosition="bottom"
                        class="p-button-secondary left-button"
                        icon="pi pi-arrow-left"
                        (click)="goPreviousPage()"
                    ></button>
                    <button
                        pButton
                        [disabled]="this.historyIndex===this.historyHelpId.length-1"
                        pTooltip="Foward to next page"
                        tooltipPosition="bottom"
                        class="p-button-secondary right-button p-ml-2"
                        icon="pi pi-arrow-right"
                        (click)="goNextPage()"
                    ></button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="body">
        <div class="iframeStyle">
            <div class="embed-container" *ngIf="currentPageInfo?.videoLink">
                <iframe
                    [src]="safeVideoSrc"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>
            <markdown
                class="resize-image"
                [data]="markdownContent"
                (click)="linkMarkdown($event)"
                (ready)="dataParsed.next()"
            ></markdown>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <!-- <div class="footer">
            <span style="font-size: 1rem" ><b>Is this information helpful to you? </b></span>
            <div>
                <button 
                    pButton
                    [disabled]="disabledFeedbackBtn"
                    icon="pi pi-times" 
                    (click)="submitHelpfulFeedback(false)" 
                    class="p-button-text p-button-rounded p-button-plain"
                    label="No"
                ></button>
                <button 
                    pButton
                    [disabled]="disabledFeedbackBtn"
                    icon="pi pi-check" 
                    (click)="submitHelpfulFeedback(true)"
                    label="Yes" 
                    class="p-button-text p-button-rounded p-button-plain"
                ></button>
            </div>
        </div> -->
    </ng-template>
</p-dialog>

<p-dialog
    [(visible)]="showImagePopupDialog"
    [dismissableMask]="true"
    [modal]="true"
    appendTo="body"
>
    <h3>{{this.imgAlt}}</h3>
    <img 
        *ngIf="showImagePopupDialog"
        [modal]="true"
        [src]="this.imgSrc"
        style="width: 100%; height: 100%;" 
    />
</p-dialog>
