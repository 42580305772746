import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GraphQLModule, PlusAuthenticationService } from '@karve.it/core';
import { TreeNode } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { ZONE_TYPES } from 'src/app/global.constants';
import { ZoneTreeBranch, generateZoneTree } from 'src/app/utilities/zones.util';
import { SubSink } from 'subsink';

import { AvailableZoneFragment, BaseRoleFragment } from '../../../generated/graphql.generated';
import { AppMainComponent } from '../../app.main.component';
import { AvailableZonesService } from '../../services/available-zones.service';
import { BrandingService } from '../../services/branding.service';
import { DocumentHelperService } from '../../services/document-helper.service';

interface TreeNodeData {
  zone: AvailableZoneFragment;
  role: BaseRoleFragment;
  search: string;
}

@Component({
  selector: 'app-zone-select',
  templateUrl: './zone-select.component.html',
  styleUrls: ['./zone-select.component.scss']
})
export class ZoneSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  subs = new SubSink();
  zoneTree: TreeNode<TreeNodeData>[];
  selectedZone: TreeNode<TreeNodeData>;
  showFilter = false;

  @ViewChild('treeSelect', { static: true }) treeSelect: TreeSelect;

  get currentZone() {
    return this.branding.currentZone().value;
  }

  get currentRoute() {
    return this.router.url;
  }

  constructor(
    public availableZonesService: AvailableZonesService,
    private plusAuth: PlusAuthenticationService,
    public branding: BrandingService,
    private router: Router,
    public graphqlModule: GraphQLModule,
    public documentHelper: DocumentHelperService,
    private appMain: AppMainComponent,
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subs.sink = this.availableZonesService.availableZones
    .subscribe(async () => {
      const zoneTreeSet = Boolean(this.zoneTree);

      this.zoneTree = this.getAvailableZoneTree();


      this.selectCurrentZoneNode(this.zoneTree);
      this.determineIfFilterIsRequired();

      // Call detect changes on appMain to prevent an `ExpressionChangedAfterItHasBeenCheckedError`
      this.appMain.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onZoneSelected(evnt: { node: any }) {
    const { zone, role } = evnt.node.data;
    // console.log('Change Active Zone', evnt);
    this.graphqlModule.newZoneLoading = true;
    this.treeSelect.hide(evnt);
    // this.selectedNode = evnt.node;

    this.plusAuth.setContext(zone.id, role.id)
    .then((context) => {
      this.graphqlModule.newZoneLoading = false;
    }).catch((err) => {
      this.graphqlModule.newZoneLoading = false;
      console.error('Error setting zone', err);
    });
  }

  /**
   * From the provided zoneTree, sets the currently contexted zone as the
   * `selectedZone` and expands it and its children
   *
   * @param zoneTree zoneTree calcualted from convertZoneTreeBranchToTreeNode
   * @returns true if this or a child branch was selected
   */
  selectCurrentZoneNode(zoneTree: TreeNode<TreeNodeData>[]) {
    if (!zoneTree) { return; }

    for (const branch of zoneTree) {
      const isZone = branch.data?.zone?.id === this.graphqlModule.zone.value;
      const isRole = branch.data?.role?.id === this.graphqlModule.role.value;
      if (isZone && isRole) {
        branch.expanded = true;
        this.selectedZone = branch;
        return true;
      } else if (branch.children) {
        const selected = this.selectCurrentZoneNode(branch.children);
        if (selected) {
          branch.expanded = true;
          return true;
        }
      }
    }

    return false;
  }

  /**
   * converts  zoneTreeBranch array structure from generateZoneTree
   * into primeng TreeNode objects.
   */
  convertZoneTreeBranchToTreeNode(availableZoneTree: ZoneTreeBranch<AvailableZoneFragment>[]) {
    const _zoneTree: TreeNode<TreeNodeData>[] = [];

    availableZoneTree = availableZoneTree.sort((a, b) => {

      if (a.children.length && !b.children.length) {
        return -1;
      }

      return a.zone.name.localeCompare(b.zone.name);
    });

    for (const branch of availableZoneTree) {
      const zone = branch.zone;

      for (const role of zone.contextableRoles) {

        let label = `${ zone.name }`;
        if (zone.contextableRoles.length > 1) {
          /**
           * This is the best way to show all available roles.
           * Currently, you can only context into one zone:role combo at a time.
           * Resolving what role you want to use may lead to bugs
           * eg how do I know I want to be in the role of Corporate Admin versus Franchisee?
           * If you have multiple roles you can context into for a zone then there would
           * be no other way to do this.
           * This does make sense because as an admin you may want to be able to see the views of
           * a franchisee, customer, admin, etc.
           */
          label += `(${ role.name })`;
        }

        const treeNode: TreeNode<TreeNodeData> = {
          key: `${ zone.id }:${ role.id }`,

          label,
          data: {
            zone: branch.zone,
            role,
            search: `${ zone.name }\t${ role.name }\t${ zone.type }`,
          },
          children: this.convertZoneTreeBranchToTreeNode(branch.children),
        };

        _zoneTree.push(treeNode);
      }
    }

    return _zoneTree;
  }

  /**
   * Count the available zones in the zone tree.
   * Used to determine if we want to show a searchbox or not
   *
   * Right now the searchbox is not enabled so this is not really used.
   *
   * @param zoneTree
   * @returns
   */
  countAvailableZones(zoneTree: TreeNode[]) {
    if (!zoneTree) { return 0; }
    let count = zoneTree.length;
    for (const branch of zoneTree) {
      if (branch.children) {
        count += this.countAvailableZones(branch.children);
      }
    }

    return count;
  }

  /**
   * Set showFilter to true if the tree node is complex enough
   */
  determineIfFilterIsRequired() {
    const count = this.countAvailableZones(this.zoneTree);
    this.showFilter = count > 999;
  }

  /**
   * Converts an array of zones into the tree structure expected by the TreeSelect component.
   * Along the way, fills out `this.availableZoneMap` to make it easier to set `this.currentZone` on zone change.
   *
   * @param zones An array of zones.
   * @returns A tree structure where each node holds a zone.
   */
  getAvailableZoneTree(): TreeNode<TreeNodeData>[] {
    let availableZones = this.availableZonesService.availableZones.value;
    if (!availableZones) {
      // available zones should be set
      return;
    }

    availableZones = availableZones
      // filter out area zones
      .filter((z) => z.type !== ZONE_TYPES.area);

    const availableZoneTree = generateZoneTree(availableZones);
    const zoneTree = this.convertZoneTreeBranchToTreeNode(availableZoneTree);
    // console.log({availableZoneTree, zoneTree});

    // expand top nodes
    for (const branch of zoneTree) {
      branch.expanded = true;
    }

    return zoneTree;
  }

}
