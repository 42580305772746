<button
    pButton
    pRipple
    label="Actions"
    (click)="menu.toggle($event)"
    icon="pi pi-angle-down"
></button>

<p-menu
    #menu
    [popup]="true"
    appendTo="body"
    [model]="jobActions"
></p-menu>

<app-download-job-invoice
    #downloadInvoice
    [showHtml]="false"
    [jobId]="job?.id"
></app-download-job-invoice>