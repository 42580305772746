export interface AttributeFilter {
  includesAny: string[];
  includesAll: string[];
  includesNone: string[];
}

/**
 * Returns all values that contains all of the proided attributes
 *
 * @param values Values to Filter
 * @param attributes Attributes to Filter by
 */
export function includesAll(values: any[], attributes: string[]): any[] {
  const valuesWithAll = [];
  for (const value of values) {
    if (!value?.attributes) { continue; }
    let hasAllAttriutes = true;
    for (const attribute of attributes) {
      const hasAttribute = value.attributes.find((att) => att === attribute);
      if (!hasAttribute) {
        hasAllAttriutes = false;
        break;
      }
    }

    if (hasAllAttriutes) {
      valuesWithAll.push(value);
    }
  }

  return valuesWithAll;
}

/**
 * Returns all values that contain at least one of the provided attributes
 *
 * @param values Values to filter
 * @param attributes Attributes to Filter by
 */
export function includesAny(values: any[], attributes: string[]): any[] {
  const valuesWithAny = [];
  for (const value of values) {
    if (!value?.attributes) { continue; }
    for (const attribute of attributes) {
      if (value.attributes.find((att) => att === attribute)) {
        valuesWithAny.push(value);
        break;
      }
    }
  }

  return valuesWithAny;
}

/**
 * Returns all values that havve none of the provided attributes
 *
 * @param values Values to filter
 * @param attributes Attributes to filter by
 */
export function includesNone(values: any[], attributes: string[]): any[] {
  const valuesWithNone = [];

  for (const value of values) {
    if (!value?.attributes) { continue; }
    let hasNone = true;
    for (const attribute of attributes) {
      if (value.attributes.find((att) => att === attribute)) {
        hasNone = false;
        break;
      }
    }

    if (hasNone) {
      valuesWithNone.push(value);
    }
  }

  return valuesWithNone;
}


export interface AttributeObject {
	title: string;
	attribute: string;
};

export function getAttributesObjectArray(
  attributeTitles?: AttributeObject[],
	attributes?: string[],
) {
	attributes = attributes || [];
	return attributeTitles.map((attr) => ({
		...attr,
		value: attributes.includes(attr.attribute),
	})).concat(attributes
		// add existing attributes here, even though they may not be in jobAttributes
		.filter((attr) => !attributeTitles.find((ja) => ja.attribute === attr))
		.map((attr) => ({
			attribute: attr,
			title: attr,
			value: true,
		})),
	);
}

/**
 * Add or remove an attribute from an array of
 * attributes based on a boolean flag.
 * Does not mutate attrs.
 *
 * @param attribute attribute to modify
 * @param attrs array of existing arributes
 * @param enabled whether to enable, set to undefined to toggle
 * @returns modified attrs with attribute toggled
 */
export function attributeToggle(
  attribute: string,
  attrs: string[] = [],
  enabled?: boolean,
) {
  attrs = [...attrs] || [];

  const index = attrs.indexOf(attribute);
  const includes = index >= 0;
  if (enabled === undefined) {
    enabled = !includes;
  }

  if (enabled && !includes) {
    attrs.push(attribute);
  }

  if (!enabled && includes) {
    attrs.splice(index, 1);
  }

  return attrs;
}
