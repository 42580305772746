import { Pipe, PipeTransform } from '@angular/core';

import { Price } from '../../generated/graphql.generated';

import { ProductHelperService } from '../services/product-helper.service';

@Pipe({
  name: 'activePrice'
})
export class ActivePricePipe implements PipeTransform {

  constructor(
    private productHelper: ProductHelperService
  ){}

  transform(prices: Price[], zoneId: string = undefined): Price {
    return this.productHelper.getActivePrice(prices, zoneId);
  }

}
