<p-inplace
    [disabled]="loading || saving || !hasPermission"
    >
    <ng-template pTemplate="display">
        <ng-container *ngIf="loading">
            <i class="pi pi-spin pi-spinner p-mr-2"></i>
            <span>Loading...</span>
        </ng-container>
        <ng-container *ngIf="!loading">
            <i class="pi pi-pencil p-mr-2"></i>
            <ng-container [ngSwitch]="contentType">
                <span *ngSwitchCase="'text'">{{label ? label + ':' : ''}} {{ value? (value | sliceText : 25) : 'Not Set' }}</span>
                <span *ngSwitchCase="'date'">{{label ? label + ':' : ''}} {{ value? (value | freyaDate : 'h:mm a, MMM, d, y') : 'Not Set' }}</span>
            </ng-container>
        </ng-container>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container [ngSwitch]="formInput">
            <p-inputGroup *ngSwitchCase="'text'" class="p-element p-inputgroup">
                <input
                    #inputText
                    pInputText
                    type="text"
                    [(ngModel)]="editableValue"
                    [placeholder]="label"
                    [disabled]="saving"
                    (keydown)="handleKeydown($event)"
                >
                <button
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger"
                    [disabled]="saving"
                    (click)="discardChanges()"
                    ></button>
                <button
                    pButton
                    type="button"
                    [icon]="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                    class="p-button-outlined"
                    [disabled]="saving"
                    (click)="save()"
                    ></button>
            </p-inputGroup>
            <ng-container *ngSwitchCase="'textarea'">
                <textarea
                    #textArea
                    pInputTextarea
                    rows="10"
                    [(ngModel)]="editableValue"
                    class="full-width"
                    [placeholder]="label"
                    (keydown)="handleKeydown($event, false)"
                    ></textarea>
                <ng-container [ngTemplateOutlet]="textButtons"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'calendar'">
                <p-calendar
                    [(ngModel)]="editableValue"
                    showTime="true"
                    dataType="string"
                    [dateFormat]="dateFormat"
                    [hourFormat]="timeFormat"
                    [inline]="true"
                >
                </p-calendar>
                <ng-container [ngTemplateOutlet]="textButtons"></ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</p-inplace>

<ng-template #textButtons>
    <div class="p-text-right">
        <p-buttonGroup class="p-element p-button-group">
            <button
                pButton
                type="button"
                icon="pi pi-times"
                class="p-button-outlined p-button-danger"
                [disabled]="saving"
                label="Discard"
                (click)="discardChanges()"
                ></button>
            <button
                pButton
                type="button"
                [icon]="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
                class="p-button-outlined"
                [disabled]="saving"
                label="Save"
                (click)="save()"
                ></button>
        </p-buttonGroup>
    </div>
</ng-template>
