import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { PagesInput, ListPagesOutput, pagesQuery, BlocksInput, ListBlocksOutput, blocksQuery, CreatePageInput, CreatePageOutput, createPageMutation, EditPageInput, EditPageOutput, editPageMutation, RemovePagesInput, RemovePagesOutput, removePagesMutation, CreateBlockInput, CreateBlockOutput, createBlockMutation, EditBlockInput, EditBlockOutput, editBlockMutation, DeleteBlocksInput, DeleteBlocksOutput, deleteBlocksMutation,  } from '@karve.it/interfaces/pages';

@Injectable({
  providedIn: 'root'
})
export class PageService {

    constructor(private apollo: PlusApollo){}

    listPages(input: PagesInput){
      return this.apollo.query<ListPagesOutput>({
        query: pagesQuery,
        variables: {
          ...input
        }
      });
    }

    watchPages(input: PagesInput){
      return this.apollo.watchQuery<ListPagesOutput>({
        query: pagesQuery,
        variables: {
          ...input
        }
      });
    }

    listBlocks(input: BlocksInput){
      return this.apollo.query<ListBlocksOutput>({
        query: blocksQuery,
        variables: {
          ...input
        }
      });
    }

    watchBlocks(input: BlocksInput){
      return this.apollo.watchQuery<ListBlocksOutput>({
        query: blocksQuery,
        variables: {
          ...input
        }
      });
    }

    createPage(input: CreatePageInput){
      return this.apollo.mutate<CreatePageOutput>({
        mutation: createPageMutation,
        variables: {
          ...input
        }
      });
    }

    editPage(input: EditPageInput){
      return this.apollo.mutate<EditPageOutput>({
        mutation: editPageMutation,
        variables: {
          ...input
        }
      });
    }

    removePages(input: RemovePagesInput){
      return this.apollo.mutate<RemovePagesOutput>({
        mutation: removePagesMutation,
        variables: {
          ...input
        }
      });
    }

    createBlock(input: CreateBlockInput){
      return this.apollo.mutate<CreateBlockOutput>({
        mutation: createBlockMutation,
        variables: {
          ...input
        }
      });
    }

    editBlock(input: EditBlockInput){
      return this.apollo.mutate<EditBlockOutput>({
        mutation: editBlockMutation,
        variables: {
          ...input
        }
      });
    }

    deleteBlocks(input: DeleteBlocksInput){
      return this.apollo.mutate<DeleteBlocksOutput>({
        mutation: deleteBlocksMutation,
        variables: {
          ...input
        }
      });
    }



}
