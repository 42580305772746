import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-travel-time',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule
  ],
  templateUrl: './travel-time.component.html',
  styleUrl: './travel-time.component.scss'
})
export class TravelTimeComponent implements OnInit, OnDestroy {

  jobLocations$ = this.store.select(jobToolFeature.selectJobLocations);
  jobTravelDistances$ = this.store.select(jobToolFeature.selectJobTravelDistances);
  collapsed: boolean = true;

  subs = new SubSink();

  constructor(
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.watchJobLocations();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private watchJobLocations(): void {
    this.subs.sink = this.jobLocations$.subscribe(() => {
      this.store.dispatch(JobToolActions.calculateDistance());
    });
  }

}
