export const PRODUCT_LABOR_CATEGORY = 'Labor' as const;

export const PRODUCT_CATEGORIES = [
    'General',
    PRODUCT_LABOR_CATEGORY,
    'Supplies',
    'Truck & Travel',
    'Protection Plans',
    'Specialty Items',
    '3rd Party Services',
    'None',
] as const;

export const CREW_IDENTIFIER = 'Labor';

export const productCategoriesDropdown = PRODUCT_CATEGORIES
    .map((t) => ({ label: t, value: t }));

