import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { safeParseJSON } from '../../js';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

export interface ObjectEditColumn{
  property: string;
  type: 'string' | 'boolean' | 'number' | 'array';
  header: string;
  width?: string;
}

@Component({
  selector: 'app-object-edit-table',
  templateUrl: './object-edit-table.component.html',
  styleUrls: ['./object-edit-table.component.scss']
})
export class ObjectEditTableComponent implements OnInit {

  @Output() save = new EventEmitter<any>();

  @Input() data: any[];

  @Input() columns: ObjectEditColumn[];

  @Input() readonly = false;

  showDialog = false;

  dialogText = '';

  constructor(
    // HELPERS
    private clipboard: Clipboard,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
  }

  addRow(){}

  saveObject(){
    this.save.emit(this.data);
  }

  openImportObject(){
    this.showDialog = true;
  }

  importObject(){

    // Attempt to import this data
    const jsonValue = safeParseJSON(this.dialogText, null);

    // If it fails give a warning messgage
    if (jsonValue === null || jsonValue === undefined){
      this.localNotify.error('Import Failed', 'The JSON you entered is invalid.');
      return;
    }

    // If it succeeds, emit the data
    this.saveObject();
  }

  exportObject(){
    this.clipboard.copy(JSON.stringify(this.data));
    this.localNotify.success('Copied to clipboard');
  }

}
