<div>
  <div class="label-edit-container">
    <div>
      <label for="customer">{{ this.label }}</label>
    </div>
  </div>
  <p-autoComplete
    *ngIf="!isCustomerEditMode"
    [tabindex]="tabIndex"
    [(ngModel)]="value"
    (completeMethod)="search($event)"
    (onClear)="onClearField()"
    (onSelect)="onSelect($event)"
    (onFocus)="onFocus()"
    (onBlur)="onBlur()"
    [optionLabel]="field"
    [disabled]="!isCustomerEditMode && isExistingCustomer"
    [pTooltip]="isExistingCustomer
      ? 'Existing customer is selected. To proceed with editing click Edit Customer'
      : null"
    [suggestions]="suggestedCustomers"
    [placeholder]="placeholder"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%', 'margin-top': '0.5rem' }"
    [ngClass]="{
      'ng-invalid': validationErrors[field],
      'readonly-cursor': !isCustomerEditMode && isExistingCustomer
    }"
  >
    <ng-template
      let-customer
      pTemplate="item"
    >
      <div class="customer-item">
        <div class="customer-field">
          {{ customer.givenName }} {{ customer.familyName }}
        </div>
        <div class="customer-field">{{ customer.email }}</div>
        <div class="customer-field">{{ customer.phone }}</div>
        <div class="customer-field">{{ customer.company }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
  <input
    *ngIf="isCustomerEditMode"
    type="text"
    pInputText
    [tabindex]="tabIndex"
    [(ngModel)]="value[field]"
    (ngModelChange)="edit($event)"
    [placeholder]="placeholder"
    [style]="{ width: '100%', 'margin-top': '0.5rem' }"
    [ngClass]="{
      'ng-invalid': validationErrors[field],
      'readonly-cursor': !isCustomerEditMode && isExistingCustomer
    }"
  >
  <small>{{ validationErrors[field] }}</small>
</div>