<p-dialog
    [(visible)]="syncDialogOpen"
    [modal]="true"
    appendTo="body"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
>
    <ng-template pTemplate="header">
        <h5>Sync Data?</h5>
    </ng-template>
    <p>Sycing your data will quickly transfer as much information as possible to the estimating stepper. This works in two ways.</p>
    <p>1) Selecting 'Sync Data' from a different page will create a new job using the information in the estimating notes.</p>
    <p>2) Selecting 'Sync Data' from the estimating page will overwrite the details of an existing job. Or create a new job if one does not exist.</p>
    <b class="error-text">This will result in the loss of any conflicting data in the estimating stepper.</b>
    <br>
    <br>
    <b class="error-text">THIS CANNOT BE UNDONE</b>
    <br>
    <br>
    <p-footer>
        <button
            pButton
            label="Cancel"
            (click)="syncDialogOpen = false"
        ></button>
        <button
            pButton
            class="p-button-danger"
            label="Sync Data"
            (click)="sync()"
        ></button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="closeDialogOpen">
    <ng-template pTemplate="header">
        Header content here
    </ng-template>
    Content
    <p-footer>
        //buttons
    </p-footer>
</p-dialog>

<div>
    <div class="p-grid sticky-title">
        <div class="p-col">
            <h3>Estimating Notes</h3>
        </div>
        <div class="p-col-3 p-text-right">
            <button
                class="p-button-secondary"
                pButton
                icon="pi pi-arrow-right"
                pTooltip="Close estimating notes panel"
                (click)="appMain.setEstimatingPanel(false)"
            ></button>
        </div>
    </div>

    <small style="font-style: italic;">This form is used to collect information quickly about estimates. Syncing this
        form to the estimate more than once may cause loss of data - caution is advised.</small>
    <br>
    <div class="estimate-note-buttons sticky-buttons">
        <!-- <a
            pButton
            label="View Estimate"
            (click)="goToEstimate()"
        ></a> -->
        <button
            pButton
            class="p-button-danger"
            label="Clear Notes"
            (click)="clear()"
        ></button>
        <span [pTooltip]="!notesForm.valid && 'Form has invalid fields'">
            <button
                pButton
                class="p-button-success"
                label="Sync Data"
                (click)="openSyncDialog()"
                [disabled]="!notesForm.valid"
                ></button>
        </span>
        <button
            pButton
            class="p-button-secondary"
            icon="pi pi-angle-double-up"
            pTooltip="Collapse all cards"
            (click)="closeAll()"
        ></button>
    </div>

    <hr>

    <div
        class="note-fields"
        [formGroup]="notesForm"
        *appVar="notesForm as fg"
    >
        <p-fieldset
            legend="Basic Info"
            [toggleable]="true"
            [(collapsed)]="closedPanels[0]"
        >
            <div #basicInfo>
                <!-- <h5>Basic Info</h5> -->
                <div class="p-d-flex">
                    <div *appVar="fg.get('firstName') as control" class="p-pr-2">
                        <label>First Name</label>
                        <br />
                        <input
                            type="text"
                            pInputText
                            placeholder="First name"
                            formControlName="firstName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        >

                    </div>
                    <div *appVar="fg.get('lastName') as control">
                        <label>Last Name</label>
                        <br />
                        <input
                            type="text"
                            pInputText
                            placeholder="Last Name"
                            formControlName="lastName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        >

                    </div>
                </div>
                <div *appVar="fg.get('phone') as control">
                    <label>Phone</label>
                    <br />
                    <div class="p-d-flex">
                        <p-dropdown
                            [options]="[ '+1' ]"
                            appendTo="body"
                            [style]="{ width: '6rem', 'padding-right': '0.5rem' }"
                            styleClass="full-width"
                            ></p-dropdown>
                        <p-inputMask
                            type="tel"
                            formControlName="phone"
                            [unmask]="true"
                            [autoClear]="false"
                            placeholder="Phone"
                            mask="(999) 999-9999"
                            [class.p-invalid-input]="!control.valid"
                            [style]="{ width: '100%' }"
                            styleClass="full-width"
                            ></p-inputMask>
                    </div>
                </div>
                <div *appVar="fg.get('email') as control">
                    <label>Email</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        placeholder="Email"
                        formControlName="email"
                        [class.p-invalid-input]="!control.valid"
                    >

                </div>
                <div *appVar="fg.get('startingAreaCode') as control">
                    <label>Area Code</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        formControlName="startingAreaCode"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                </div>
                <div *appVar="fg.get('moveDate') as control">
                    <label>Move Date</label>
                    <br />
                    <p-calendar formControlName="moveDate"></p-calendar>
                </div>
                <div *appVar="fg.get('time') as control">
                    <label>Time</label>
                    <br />
                    <p-calendar
                        formControlName="time"
                        [timeOnly]="true"
                        hourFormat="12"
                        inputId="timeonly"
                    ></p-calendar>

                </div>
                <div *appVar="fg.get('flexibility') as control">
                    <label>Flexibility</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        maxlength="40"
                        placeholder="Flexibility"
                        formControlName="flexibility"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>

                <div *appVar="fg.get('needFinance') as control">
                    <label>Do they need financing?</label>
                    <br />
                    <p-inputSwitch formControlName="needFinance"></p-inputSwitch>
                </div>
                <div class="p-grid">
                    <div class="p-col">
                        <label>Partial Move</label>
                        <br />
                        <p-inputSwitch formControlName="partialMove"></p-inputSwitch>
                    </div>
                    <div class="p-col">
                        <label>More than 10 items</label>
                        <br />
                        <p-inputSwitch [controlDisabled]="!fg.value.partialMove === true" formControlName="moreThan10Items"></p-inputSwitch>
                    </div>
                </div>
                <small>More than 10 items only applies to partial moves</small>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset
            legend="Marketing"
            [toggleable]="true"
            [(collapsed)]="closedPanels[1]"
        >
            <div #marketing>
                <div *appVar="fg.get('usedMoverBefore') as control">
                    <label>Used Mover Before</label>
                    <br />
                    <p-inputSwitch formControlName="usedMoverBefore"></p-inputSwitch>
                </div>
                <div *appVar="fg.get('usedYMMBefore') as control">
                    <label>Used YMM Before</label>
                    <br />
                    <p-inputSwitch formControlName="usedYMMBefore"></p-inputSwitch>
                </div>
                <div *appVar="fg.get('howHeard') as control">
                    <label>How did they hear about us</label>
                    <br />
                    <p-dropdown
                        placeholder="How Heard"
                        formControlName="howHeard"
                        [style]="{width: '100%', maxWidth: '372px'}"
                        [class.p-invalid-input]="!control.valid && control.touched"
                        [options]="howHearAboutUs"
                    ></p-dropdown>
                </div>
            </div>
        </p-fieldset>

        <br>
        <p-fieldset
            legend="Required Events"
            [toggleable]="true"
            [(collapsed)]="closedPanels[2]"
        >
            <div #requiredEvents>
                <div class="p-grid">
                    <div
                        *appVar="fg.get('estimate') as control"
                        class="p-col"
                    >
                        <label>Estimate</label>
                        <br />
                        <p-inputSwitch formControlName="estimate"></p-inputSwitch>
                    </div>
                    <div
                        *appVar="fg.get('delivery') as control"
                        class="p-col"
                    >
                        <label>Delivery</label>
                        <br />
                        <p-inputSwitch formControlName="delivery"></p-inputSwitch>
                    </div>
                    <div
                        *appVar="fg.get('packing') as control"
                        class="p-col"
                    >
                        <label>Packing</label>
                        <br />
                        <p-inputSwitch formControlName="packing"></p-inputSwitch>
                    </div>
                    <div
                        *appVar="fg.get('moving') as control"
                        class="p-col"
                    >
                        <label>Moving</label>
                        <br />
                        <p-inputSwitch formControlName="moving"></p-inputSwitch>
                    </div>
                    <div
                        *appVar="fg.get('unpacking') as control"
                        class="p-col"
                    >
                        <label>Unpacking</label>
                        <br />
                        <p-inputSwitch formControlName="unpacking"></p-inputSwitch>
                    </div>
                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset
            legend="Start Location Info"
            [toggleable]="true"
            [(collapsed)]="closedPanels[3]"
        >
            <div #startingGroup>
                <h5>Start Info</h5>
                <!-- <div *appVar="fg.get('startingAreaCode') as control">
                    <label>Area Code</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        formControlName="startingAreaCode"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                </div> -->
                <div *appVar="fg.get('startingAddress') as control">
                    <label>Address</label>
                    <br />
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="startingAddress"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <input
                        *ngIf="googleHelper.loaded | async"
                        class="location-input"
                        autocomplete="off"
                        [options]="options"
                        formControlName="startingAddress"
                        pInputText
                        ngx-google-places-autocomplete
                        (onAddressChange)="handleAddressChange($event, 'start')"
                    />
                </div>
                <div *appVar="fg.get('startingUnit') as control">
                    <label>Unit #</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        formControlName="startingUnit"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>
                <div *appVar="fg.get('startingDwellingType') as control">
                    <label>Dwelling Type</label>
                    <br />
                    <!-- <input
                    type="text"
                    pInputText
                    placeholder="StartingDwellingType"
                    formControlName="startingDwellingType"
                    [class.p-invalid-input]="!control.valid && control.touched"
                > -->
                    <p-dropdown
                        placeholder="Dwelling Type"
                        [options]="dwellingTypes"
                        formControlName="startingDwellingType"
                    ></p-dropdown>
                </div>
                <div *appVar="fg.get('startingSqft') as control">
                    <label>Sqft</label>
                    <br />
                    <input
                        pInputText
                        type="number"
                        formControlName="startingSqft"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >
                </div>
                <div>
                    <label>Bedrooms</label>
                    <br />
                    <p-dropdown
                        placeholder="Number of Bedrooms"
                        [options]="bedroomDropDownOptions"
                        optionLabel="name"
                        optionValue="value"
                        formControlName="bedrooms"
                    ></p-dropdown>
                </div>
                <div *appVar="fg.get('startingStairs') as control">
                    <label>Stairs</label>
                    <br />
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="startingStairs"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <p-dropdown
                        [options]="stairsDropDownOptions"
                        optionLabel="name"
                        optionValue="value"
                        formControlName="startingStairs"
                    ></p-dropdown>
                </div>
                <div *appVar="fg.get('startingElevators') as control">
                    <label>Elevators</label>
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="startingElevators"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <br>
                    <p-dropdown
                        [options]="elevatorDropDownOptions"
                        formControlName="startingElevators"
                    ></p-dropdown>
                </div>
                <div>
                    <label>Can elevators be booked</label>
                    <br />
                    <p-inputSwitch formControlName="startingElevatorsBookable"></p-inputSwitch>
                </div>
                <div *appVar="fg.get('startingParking') as control">
                    <label>Parking</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="startingParking"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset
            legend="End Location Info"
            [toggleable]="true"
            [(collapsed)]="closedPanels[4]"
        >
            <div #destinationGroup>
                <!-- <div *appVar="fg.get('destinationAreaCode') as control">
                    <label>Area Code</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationAreaCode"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div> -->
                <div *appVar="fg.get('destinationAddress') as control">
                    <label>Address</label>
                    <br />
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationAddress"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <input
                        class="location-input"
                        [options]="options"
                        formControlName="destinationAddress"
                        pInputText
                        *ngIf="googleHelper.loaded | async"
                        ngx-google-places-autocomplete
                        (onAddressChange)="handleAddressChange($event, 'end')"
                    />
                </div>
                <div *appVar="fg.get('destinationUnit') as control">
                    <label>Unit #</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationUnit"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>
                <div *appVar="fg.get('destinationDwellingType') as control">
                    <label>Dwelling Type</label>
                    <br />
                    <!-- <input
                    type="text"
                    pInputText
                    placeholder="DestinationDwellingType"
                    formControlName="destinationDwellingType"
                    [class.p-invalid-input]="!control.valid && control.touched"
                > -->
                    <p-dropdown
                        placeholer="Dwelling Type"
                        [options]="dwellingTypes"
                        formControlName="destinationDwellingType"
                    ></p-dropdown>
                </div>
                <div *appVar="fg.get('destinationSqft') as control">
                    <label>Sqft</label>
                    <br />
                    <input
                        type="number"
                        pInputText
                        placeholder=""
                        formControlName="destinationSqft"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>
                <div *appVar="fg.get('destinationStairs') as control">
                    <label>Stairs</label>
                    <br />
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationStairs"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <p-dropdown
                        [options]="stairsDropDownOptions"
                        optionLabel="name"
                        optionValue="value"
                        formControlName="destinationStairs"
                    ></p-dropdown>
                </div>
                <div *appVar="fg.get('destinationElevators') as control">
                    <label>Elevators</label>
                    <br />
                    <!-- <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationElevators"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    > -->
                    <p-dropdown
                        [options]="elevatorDropDownOptions"
                        formControlName="destinationElevators"
                    ></p-dropdown>
                </div>
                <div>
                    <label>Can elevators be booked</label>
                    <br />
                    <p-inputSwitch formControlName="destinationElevatorsBookable"></p-inputSwitch>
                </div>
                <div *appVar="fg.get('destinationParking') as control">
                    <label>Parking</label>
                    <br />
                    <input
                        type="text"
                        pInputText
                        placeholder=""
                        formControlName="destinationParking"
                        [class.p-invalid-input]="!control.valid && control.touched"
                    >

                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset
            legend="Job Details"
            [toggleable]="true"
            [(collapsed)]="closedPanels[5]"
        >
            <div #jobDetails>
                <h5>Job Details</h5>
                <div *appVar="fg.get('inventory') as control">
                    <label>Inventory <button
                            pButton
                            label="Use Template"
                            (click)="setInventoryTemplate()"
                        ></button></label>
                    <br>
                    <br>
                    <textarea
                        style="white-space: pre-line;"
                        pInputTextarea
                        class="full-width"
                        rows="20"
                        placeholder="Enter Inventory Items"
                        formControlName="inventory"
                    ></textarea>
                </div>
            </div>
        </p-fieldset>

    </div>
</div>
<app-add-location
    #addLocation
    (didHide)="attemptedAutocomplete = false"
    (locationInputBlur)="onLocationInputBlur($event)"
    (locationAdded)="setValueEqualToManual($event)"
></app-add-location>

