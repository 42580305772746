<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="viewModel.enabled">
    <ng-container *ngIf="!delta.loading">
      <span [class]="'dashboard-delta' + ' ' + delta.sign" *ngIf="delta.amount">
        <i
          *ngIf="delta.sign === 'negative'"
          class="pi pi-arrow-down"
          ></i>
        <i
          *ngIf="delta.sign === 'positive'"
          class="pi pi-arrow-up"
          ></i>
        <span *ngIf="mode === 'currency'">{{ delta.amount | abbreviateCurrency : delta.currency : true }}</span>
        <span *ngIf="mode === 'percent'">{{ delta.amount | number : '1.2-2' }}%</span>
        <span *ngIf="mode === 'distance'">{{ delta.amount | distance : units }}</span>
        <span *ngIf="mode === 'duration'">{{ delta.amount | secondsToDuration }}</span>
      </span>
      <span *ngIf="!delta.amount">No difference</span>
      {{ viewModel.comparedToText }}
    </ng-container>
    <span *ngIf="delta.loading">Calculating...</span>
  </ng-container>
</ng-container>
