import { Injectable } from '@angular/core';
import { FullProductFragment, Price, Product } from 'src/generated/graphql.generated';

import { BrandingService } from './branding.service';

@Injectable({
  providedIn: 'root'
})
export class ProductHelperService {

  constructor(
    private brandingSvc: BrandingService,
  ) {}

  getActivePriceFromProduct(product: Product, zoneId: string = undefined){
    if (!product?.prices) {
      return null;
    }
    const activePrice = this.getActivePrice(product.prices, zoneId);
    return activePrice;
  }

  getActivePrice(prices: Price[], zoneId: string = undefined): Price{

    const existingPrices = prices.filter((p) => !p.deletedAt); // Filter out deleted prices

    const activePrices = existingPrices.filter((p) => p.active); // Filter out inactive prices

    if (activePrices.length === 1) { return activePrices[0]; } // If only one active price then return it

    let zonePrice;
    if (zoneId) {
     zonePrice = activePrices.find((ap) => ap.zone?.id === zoneId); // Find exact zone price if it exists
    }

    if (!zoneId || !zonePrice){
      zoneId = this.brandingSvc.currentZone().value.id; // Default to contexted zone
      zonePrice = activePrices.find((ap) => ap.zone?.id === zoneId); // Check for price in contexted zone
    }

    if (zonePrice) { return zonePrice; } // If we have an exact zone match then return it

    return activePrices[0];
  }

  filterOutProductsWithInactivePrices(products: FullProductFragment[]): FullProductFragment[] {
    return products.filter((p) => p.prices?.length && p.prices.find((price) => price.active));
  }
}
