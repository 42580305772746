import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { PricesInput, ListPricesOutput, CreatePricesInput, CreatePricesOutput, createPricesMutation, UpdatePricesInput, UpdatePricesOutput, updatePricesMutation, DeletePricesInput, DeletePricesOutput, deletePricesMutation, generatePricesQuery, GeneratePricesQueryInput,  } from '@karve.it/interfaces/prices';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
    providedIn: 'root'
})
export class PriceService {

    constructor(private apollo: PlusApollo){}

    listPrices(input: PricesInput, queryInput: GeneratePricesQueryInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListPricesOutput>({
            query: generatePricesQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchPrices(input: PricesInput, queryInput: GeneratePricesQueryInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListPricesOutput>({
            query: generatePricesQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createPrices(input: CreatePricesInput){
        return this.apollo.mutate<CreatePricesOutput>({
            mutation: createPricesMutation,
            variables: {
                ...input
            }
        });
    }

    updatePrices(input: UpdatePricesInput){
        return this.apollo.mutate<UpdatePricesOutput>({
            mutation: updatePricesMutation,
            variables: {
                ...input
            }
        });
    }

    deletePrices(input: DeletePricesInput){
        return this.apollo.mutate<DeletePricesOutput>({
            mutation: deletePricesMutation,
            variables: {
                ...input
            }
        });
    }

}
