/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { Coordinates } from '@karve.it/interfaces/locations';
import { BehaviorSubject } from 'rxjs';

import { BaseLocationFragment, LocationCreateBase } from '../../generated/graphql.generated';



export interface GeocodeAddressInput {
  placeId?: string;
  formattedAddress?: string;
}

export interface GetGoogleAddressInput {
  placeId?: string;
  formattedAddress?: string;
  // https://developers.google.com/maps/documentation/javascript/places#place_details_requests
  fields?: string[];
}

export interface FindAddressComponentOptions {
    trimWhitespace: boolean;
}

export type AddressComponent = google.maps.GeocoderAddressComponent;
export type Address = google.maps.places.PlaceResult;

@Injectable({
  providedIn: 'root'
})
export class GoogleHelperService {

  placesService: google.maps.places.PlacesService;
  streetViewService: google.maps.StreetViewService;
  loaded = new BehaviorSubject(false);


  constructor(
    private loader: Loader,
  ) {}

  init() {
    // this.loader = new Loader({
    //   apiKey: environment.googlePlacesApiKey,
    //   libraries: ['places'],
    //   language: 'en',
    // });

    // Initialize the Google Services
    this.loader.importLibrary('places').then(() => {
      this.loaded.next(true);
      (globalThis as any).cmd.resolveAddress = (query: string) => this.resolveAddress(query);
    });
  }

  getPlacesService() {
    if (this.placesService) { return this.placesService; }

    // The places service needs an element for the 'map' so we just create one but don't initialize the map
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));

    return this.placesService;
  }

  getStreetViewService() {
    if (this.streetViewService) { return this.streetViewService; }

    this.streetViewService = new google.maps.StreetViewService();

    return this.streetViewService;
  }

  convertGoogleLocationToCreateLocationInput(address: google.maps.places.PlaceResult) {
    const streetAddress = address.formatted_address;
    const postalCode = this.findAddressComponent(address, 'postal_code', {trimWhitespace: true});
    let country = this.findAddressComponent(address, 'country');
    const jurisdiction = this.findAddressComponent(address, 'administrative_area_level_1');

    // Get the locality if it's too small for locality get the administrative region for the city/town.
    let city = this.findAddressComponent(address, 'locality');
    if (!city){
      city = this.findAddressComponent(address, 'administrative_area_level_2');
    }
    // const streetNumber = this.findAddressComponent(address, 'street_number');
    // const streetName = this.findAddressComponent(address, 'route');

    if (country === 'United States') {
      country = 'USA';
    }

    let coordinates: Coordinates;

    if (address?.geometry?.location) {
      coordinates = this.getCoordinatesFromGeometry(address.geometry);
    }

    const result: LocationCreateBase = {
      addressLineOne: streetAddress,
      areaCode: postalCode,
      country,
      city,
      countryJurisdiction: jurisdiction,
      // addressLineTwo
      coordinates,
      name: address.name || streetAddress,
    };

    return result;
  }

  findAddressComponent(address: Address | google.maps.places.PlaceResult, type: string, options?: FindAddressComponentOptions){
    let component = address?.address_components?.find((c) => c.types.find((t) => t === type))?.long_name;
    if (!component) {
      return undefined;
    }

    if (options?.trimWhitespace && component){
      component = component.replace(/\s/g, '');
    }

    return component;
  }

  isValidGooglePlacesAddress = (addr): addr is Address => {
    if (!('address_components' in addr)) {
      return false;
    }

    // const street_number = this.findAddressComponent(addr, 'street_number');
    // const postal_code = this.findAddressComponent(addr, 'postal_code');
    // we need to have a postal code / street number to assign this location
    // if (!street_number || !postal_code) {
      // return false;
    // }

    return true;
  };

  /**
   * Get the Google Address value for a Location
   *
   * @param formattedAddress Is efffectively a search, Ideal use is to take an already formatted google address
   * @param fieldsOverride Provide different fields for the api instead of the default
   * @returns Google Address with the specified fields
   */
  getGoogleAddress(input: GetGoogleAddressInput): Promise<google.maps.places.PlaceResult> {
    return new Promise(async (resolve, reject) => {
      // If we did not pass in a required field
      if (!input.placeId && !input.formattedAddress) {
        console.warn('Google Address Query Aborted, Please Provide placeId or formattedAddress');
        return resolve(null);
      }

      // If we don't have a place Id then retrieve it as it is needed for the details request
      if (!input.placeId) {
        input.placeId = await this.getPlaceIdForAddress(input.formattedAddress);

        // Not a valid place
        if (!input.placeId) {
          return resolve(null);
        }
      }

      const detailsRequest: google.maps.places.PlaceDetailsRequest = {
        placeId: input.placeId,
        fields: input.fields || ['address_component', 'formatted_address', 'geometry', 'name'],
      };

      this.getPlacesService().getDetails(detailsRequest, (detailsResult) => {
        resolve(detailsResult);
      });
    });
  }

  async resolveAddress(query: string) {
    const placeDetails = await this.getGoogleAddress({
      formattedAddress: query,
    });

    const locationBase = this.convertGoogleLocationToCreateLocationInput(placeDetails);

    return {
      placeDetails,
      locationBase,
    };
  }

  /**
   * Get the placeId for a location so it can be used in a details query
   *
   * @param address The Address for a location
   * @returns Google Place Id for that address
   */
  getPlaceIdForAddress(address: string): Promise<string> {
    const request = {
      query: address,
      fields: ['place_id'],
    };

    return new Promise((resolve, reject) => {
      this.getPlacesService().findPlaceFromQuery(request, (results) => {
        // If it's not a valid place return null
        if (!results) { return resolve(null); }
        resolve(results[0].place_id);
      });
    });
  }

  /**
   * Get the Geocoordinates (Lat, Long) for a Google Location
   *
   * @param param The Place ID or formattedAddress based on the type
   * @param type The type of parameter you are providing
   * @returns The Geometry Result for a location
   */
  async geocodeAddress(input: GeocodeAddressInput) {
    return this.getGoogleAddress({
      ...input,
      fields: ['geometry']
    });
  }

  /**
   * Converts the geometry object from google into a our systems Coordinate type.
   *
   * @param result The Result from a geocodeAddress() call
   * @returns Coordinates formatted for our system
   */
  getCoordinatesFromGeometry(geometry: google.maps.places.PlaceGeometry): Coordinates {
    const cords = {
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
    };

    return cords;
  }

  convertCoordinatesToLatLng(coords: Coordinates) {
    return {
      lat: coords.latitude,
      lng: coords.longitude,
    } as google.maps.LatLngLiteral;
  }

  /**
   * @returns Gooogle Maps API key
   */
  getKey(): string {
    return this.loader.apiKey;
  }

  convertLocationToGoogleAddress(location: BaseLocationFragment) {

    const addressComponets: AddressComponent[] = [];

    this.insertComponent(addressComponets, 'postal_code', location.areaCode);
    this.insertComponent(addressComponets, 'country', location.country);
    this.insertComponent(addressComponets, 'administrative_area_level_1', location.countryJurisdiction);
    this.insertComponent(addressComponets, 'locality', location.city);

    return {
      formatted_address: location.addressLineOne,
      address_components: addressComponets,
    } as Partial<Address>;
  }

  insertComponent(components: AddressComponent[], componentType: string, val?: string) {
    if (!val) { return; }
    components.push({
      types: [ componentType ],
      long_name: val,
      short_name: val,
    });
  }
}
