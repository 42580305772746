<p-breadcrumb
  [home]="home"
  [model]="breadcrumb"
  styleClass="p-mb-3"
  >
</p-breadcrumb>

<div
    #header
    class="header card"
>
    <div class="titlebar">
        <h3 *ngIf="rt; else loadingBarBig"
            (click)="select()"
        >
            {{ rt.name }}
        </h3>
        <div class="actions">

            <button
                *hasPermission="['reports.export']"
                pButton
                class="p-button-secondary"
                [disabled]="!rt"
                label="Semi-live Export to Google Sheets"
                pTooltip="Create a formula to automatically export this report to Google Sheets"
                tooltipPosition="left"
                (click)="exportToSheets()"
            ></button>
            <button
                *hasPermission="['scheduledReports.create']"
                pButton
                class="p-button-secondary"
                [disabled]="!rt"
                label="Schedule Report"
                pTooltip="Run this report at a set interval"
                tooltipPosition="left"
                (click)="createScheduledReport()"
            ></button>
            <button
                *hasPermission="['reports.create']"
                pButton
                [disabled]="!rt || generating"
                [label]="generating ? 'Running.....' : 'Run Report'"
                pTooltip="Run report"
                tooltipPosition="left"
                (click)="runReport()"
            ></button>
        </div>
    </div>
    <div *ngIf="rt; else loadingBar">
        <app-zone-chip
            *ngFor="let zone of rt.zones?.nodes"
            [zone]="zone"
        >
        </app-zone-chip>


    </div>
    <div *ngIf="rt?.description"
        [innerHTML]="rt.description | markdown | async"
        (click)="markdownHelper.onMarkdownClick($event)"
    >
    </div>
</div>

<div #scheduledReports
    class="scheduled-report-list card"
>
    <h4>Scheduled</h4>
    <app-scheduled-reports-list
        [load]="true"
        [reportTypeId]="rt?.id"
    >
    </app-scheduled-reports-list>
</div>

<div
    class="report-list card"
>
    <h4>Recently Generated</h4>

    <p-table
        [value]="reports"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreReports($event)"
        [lazy]="true"
        [totalRecords]="reportsQueryHelper.total"
        [(rows)]="reportsQueryHelper.limit"
        [loading]="reportsQueryHelper.loading"
        [showCurrentPageReport]="!reportsQueryHelper.loading"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Zone</th>
                <th>Created</th>
                <!-- <th>Last Run</th> -->
                <th></th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-report
        >
            <tr
                class="selectable-row"
                (click)="select(report)"
            >
                <td>{{report.name}}</td>
                <td>
                    
                    <app-zone-chip
                        *ngFor="let zone of report.zones.nodes"
                        [zone]="zone"
                    ></app-zone-chip>
                </td>
                <td>
                    {{report.createdAt | freyaDate: 'MMM dd, YYYY HH:mm'}}
                    <br />
                    {{ report.createdAt * 1000 | appropriateTimeMeasurement }}

                </td>
                <!-- <td>{{rt.id}}</td> -->
                <td>
                    <div class="icons">
                        <app-report-status-icon
                            [status]="report.status"
                            [showStatusText]="false"
                            fontSize="1.5rem"
                            tooltipPosition="bottom"
                        ></app-report-status-icon>
                        <button
                            pButton
                            type="button"
                            icon="pi pi-cloud-download"
                            [pTooltip]="report?.saveData ? 'Download CSV' : 'Report not saved - cannot download'"
                            tooltipPosition="bottom"
                            [disabled]="!report?.saveData"
                            (click)="downloadCSV(report)"
                        ></button>
                        <app-open-link
                            [link]="[ '/reports', 'static', report.id ]"
                            tooltip="View Report"
                            tooltipPosition="bottom"
                        ></app-open-link>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'6'">
                    No Reports Found
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>

    <ng-template #loadingBarMassive>
        <div class="p-p-2">
            <p-skeleton width="100%" height="50vh"></p-skeleton>
        </div>
    </ng-template>

</div>


<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton width="100%" height="1rem"></p-skeleton>
    </div>
</ng-template>

<ng-template #loadingBarBig>
    <div class="p-p-2">
        <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
</ng-template>
<ng-template #loadingBarMassive>
    <div class="p-p-2">
        <p-skeleton width="100%" height="50vh"></p-skeleton>
    </div>
</ng-template>
