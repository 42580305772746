<div
    class="create-report-input"
    [formGroup]="form"
>
    <ng-template #name>
        <div *appVar="form.get('name') as control">
            <div *ngIf="reportType?.description"
                [innerHTML]="reportType.description | markdown | async"
                (click)="markdownHelper.onMarkdownClick($event)"
            ></div>
            <input
                type="text"
                pInputText
                formControlName="name"
                [placeholder]="getNamePlaceholder()"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <br />
            <small>Leave blank to use autogenerate name</small>
            <br />
            <small>Generating report in <a>{{ currentZone?.name }}</a></small>

            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #period>
        <div *ngIf="reportType?.description"
            [innerHTML]="parseReportTypePeriod(reportType) | markdown | async"
        ></div>
        <div *appVar="form.get('period') as control">
            <p-dropdown
                formControlName="period"
                [options]="periods"
                (onChange)="updatePeriodLocalStorage()"
                (ngModelChange)="updatePeriodVariables({ updateCustomPeriod: true })"
                [filter]="true"
                [showClear]="true"
                optionLabel="name"
                optionValue="value"
                filterBy="name"
                appendTo="body"
                autoWidth
            ></p-dropdown>

            <ng-container>
                <div *appVar="form.get('customPeriod') as customPeriodControl">
                    <p-calendar
                        appendTo="body"
                        formControlName="customPeriod"
                        selectionMode="range"
                        [readonlyInput]="true"
                        inputId="range"
                        (onSelect)="updatePeriodVariables({updateCustomPeriod: false})"
                    >
                    </p-calendar>
                    <small>Timezone: {{ timeZone.getCurrentTimezone() }}</small>
                </div>
            </ng-container>

            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

</div>


<app-mutate-object
    #mutate
    [fg]="form"
    objectType="Report"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
    (changesDiscarded)="reset()"
></app-mutate-object>
