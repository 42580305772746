import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { InplaceModule } from 'primeng/inplace';

import { TagModule } from 'primeng/tag';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-job-tags',
  standalone: true,
  imports: [
    InplaceModule,
    AsyncPipe,
    CommonModule,
    TagModule,
    FreyaCommonModule,
    SharedModule,
  ],
  templateUrl: './job-tags.component.html',
  styleUrl: './job-tags.component.scss',
})
export class JobTagsComponent implements OnInit, OnDestroy {
  public tags$ = this.store.select(jobToolFeature.selectJobTags);
  public filteredTags$ = this.store.select(jobToolFeature.selectFilteredTags);

  public tags: any[] = [];
  public selectedTags = [];
  private selectedCategories: Record<string, number> = {};

  private subs = new SubSink();

  constructor(private store: Store) { }

  ngOnInit() {
    this.subs.sink = this.tags$.subscribe((tags) => {
      this.tags = tags;
      this.selectedTags = [...tags];  // Spread to create a shallow copy as we will be modifying the array
      this.updateSelectedCategories();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchTags(event: AutoCompleteCompleteEvent) {
    this.store.dispatch(JobToolActions.tagSearched({ tagSearch: event.query }));
  }

  onTagSelect(event: AutoCompleteSelectEvent) {
    // At this point, the tag has been selected from the dropdown and added to the selectedTags array
    const tag = event.value;
    const category = tag.category;

    if (!category) return;

    if (this.selectedCategories[category] >= 1) {
      this.removeOldestTagFromCategory(category);
    }

    this.selectedCategories[category] = (this.selectedCategories[category] || 0) + 1;
  }

  removeOldestTagFromCategory(category: string) {
    const index = this.selectedTags.findIndex(tag => tag.category === category);
    if (index !== -1) {
      this.selectedTags.splice(index, 1);
      this.selectedCategories[category]--;
    }
  }

  updateSelectedCategories() {
    this.selectedCategories = this.selectedTags.reduce((acc, tag) => {
      const category = tag.category;
      if (category) {
        acc[category] = (acc[category] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);
  }


  onDeactivate() {
    if (isEqual(this.selectedTags, this.tags)) {
      return;
    }
    this.store.dispatch(
      JobToolActions.tagAssignedToJob({ tags: this.selectedTags, originalTags: this.tags }),
    );
  }
}
