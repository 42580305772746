import { Component } from '@angular/core';

import { AppComponent } from '../app.component';
import { PlusAuthenticationService } from '../core/public-api';
import { text } from '../global.constants';

@Component({
  selector: 'app-error',
  templateUrl: './app.error.component.html',
  styleUrls: ['./app.error.component.scss']
})
export class AppErrorComponent {
  company = text.companyName;
  project = text.projectName;
  constructor(
    public app: AppComponent,
    public authService: PlusAuthenticationService,
  ) {}

  logout() {
    return this.authService.logout(true)
    .then(() => {
      location.reload();
    })
    .catch(() => {
      location.reload();
    });
  }
}
