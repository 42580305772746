import { createActionGroup, props } from '@ngrx/store';
import { Delta } from 'quill/core';
export const JobSummaryActions = createActionGroup({
	source: 'Job Summary',
	events: {
        'Update Summary': props<{
			text: string,
			contents: Delta,
			key: string,
		 }>(),
	},
});
