import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface HasId{
  id: string;
}

@Pipe({
  name: 'getObjectById'
})
export class GetObjectByIdPipe implements PipeTransform {

  transform(objects: HasId[], id: string, index = false): any {

    if (index) {
      const objectIndex = objects.findIndex((obj) => obj.id === id);

      if (objectIndex < 0){
        return 0;
      }
      return objectIndex;
    }

    const object = objects.find((obj) => obj.id === id);
    return object;
  }

}
