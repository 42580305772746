import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';

import { FullCalendarEventFragment } from '../../../generated/graphql.generated';
import { EventLocationTypes } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { EventColorInfo, FullCalendarHelperService } from '../../services/full-calendar-helper.service';
import { getFirstLocation, getLocationAreaCode } from '../../utilities/locations.util';

@Component({
  selector: 'app-display-event',
  templateUrl: './display-event.component.html',
  styleUrls: ['./display-event.component.scss']
})
export class DisplayEventComponent implements OnInit {

  @Input() event: FullCalendarEventFragment;

  // DISPLAY VARIABLES
  colors: EventColorInfo;
  statusIconClass: string;
  areaCode: string;
  endAreaCode: string;

  areaGradient;

  constructor(
    private fcHelper: FullCalendarHelperService,
    private detailsHelper: DetailsHelperService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // Get Event Colors
    this.colors = this.fcHelper.determineColorsFromEvent(this.event as unknown as FullCalendarEventFragment);

    // Get Status Icon
    this.statusIconClass = this.fcHelper.determineIconFromEvent(this.event);

    if (this.event.locations?.length) {
      // Get Areacode
      const startLocation = getFirstLocation(this.event);
      this.areaCode = getLocationAreaCode(startLocation.location);
    } else {
      this.areaCode = '';
    }

    // Get end areacode
    const endLocation = getFirstLocation(this.event, EventLocationTypes.end, true);
    if (endLocation) {
      this.endAreaCode = getLocationAreaCode(endLocation.location);
    }
  }

  openEvent() {
    this.detailsHelper.open('calendar-event', { id: this.event.id });
  }

}
