/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { Zone } from './zones';

export interface PromotionCode {
    id?: string;
    active?: boolean;
    code?: string;
    coupon?: Coupon;
    created?: number;
    customer?: string;
    expires_at?: number;
    livemode?: boolean;
    max_redemptions?: number;
    restrictions?: PromotionCodeRestrictions;
    times_redeemed?: number;
    zones?: Zone[];
}

export interface Coupon {
    id?: string;
    amount_off?: number;
    applies_to?: AppliesTo;
    created?: number;
    currency?: string;
    deleted?: boolean;
    duration?: string;
    duration_in_months?: number;
    livemode?: boolean;
    max_redemptions?: number;
    name?: string;
    percent_off?: number;
    redeem_by?: number;
    times_redeemed?: number;
    valid?: boolean;
}

export interface PromotionCodeRestrictions {
    first_time_transaction?: boolean;
    minimum_amount?: number;
    minimum_amount_currency?: string;
}

export interface AppliesTo{
    products?: string[];
}

export interface GetPromoCodesInput {
    active?: boolean;
    code?: string;
    created?: RangeQueryParam;
    ending_before?: string;
    limit?: number;
    starting_after?: string;
    customer?: string;
    zones?: string[];
}

export interface RangeQueryParam {
    gt?: number;
    gte?: number;
    lt?: number;
    lte?: number;
}

export interface GetPromoCodesOutput {
    promotionCodes: PromotionCode[];
}

const couponProperties = `
    id
    amount_off
    applies_to {
        products 
    }
    created
    currency
    deleted
    duration
    duration_in_months
    livemode
    max_redemptions
    name
    percent_off
    redeem_by
    times_redeemed
    valid
`;

const promotionCodeProperties = `
    id
    name
    active
    code
    coupon {
        ${couponProperties}
    }
    created
    customer
    expires_at
    livemode
    max_redemptions
    restrictions {
        first_time_transaction
        minimum_amount
        minimum_amount_currency
    }
    times_redeemed
    zones{
        id
        name
    }
`;

export const promotionCodesQuery = gql`
    query promoCodes(   $active: Boolean,
                        $code: String,
                        $created: RangeQueryParam,
                        $ending_before: String,
                        $limit: Int,
                        $starting_after: String,
                        $customer: String,
                        $zones: [String!]){
        promotionCodes(   active: $active,
                            code: $code,
                            created: $created,
                            ending_before: $ending_before,
                            limit: $limit,
                            starting_after: $starting_after,
                            customer: $customer,
                            zones: $zones){
                ${promotionCodeProperties}
            }
        }
`;

export interface CreatePromotionCodeInput {
    name: string;
    coupon: string;
    active?: boolean;
    code?: string;
    customer?: string;
    expires_at?: number;
    max_redemptions?: number;
    restrictions?: PromotionCodeRestrictions;
    zones: string[];
}

export interface CreatePromotionCodeOutput {
    createPromotionCodes: PromotionCode[];
}

export const createPromotionCodeMutation = gql`
    mutation createPromoCodes($promoCodes: [CreatePromotionCodeInput]){
        createPromotionCodes(promoCodes: $promoCodes){
            ${promotionCodeProperties}
        }
    }
`;

export interface UpdatePromotionCodeInput {
    stripeId: string;
    active?: boolean;
    addToZones?: string[];
    removeFromZones?: string[];
}

export interface UpdatePromotionCodeOutput {
    updatePromotionCodes: boolean;
}

export const updatePromotionCodeMutation = gql`
    mutation updatePromoCodes($promotionCodes: [UpdatePromotionCodeInput]!){
    updatePromotionCodes(promotionCodes: $promotionCodes)
    }
`;

export interface RetrievePromotionCodeInput{
    stripeId: string;
}

export interface RetrievePromotionCodeOutput{
    retrievePromotionCode: PromotionCode;
}

export const retrievePromotionCodeQuery = gql`
    query retrievePromotionCode($stripeId: String!){
        retrievePromotionCode(stripeId: $stripeId){
            id
            name
            active
            code
            coupon {
            id
            }
            created
            livemode
            expires_at
            customer
            max_redemptions
            restrictions {
            first_time_transaction
            minimum_amount
            minimum_amount_currency
            }
            times_redeemed
            zones{
            id
            }
        }
    }
`;

export interface ListPromotionCodesInput {
	active?: boolean;
    code?: string;
    created?: RangeQueryParam;
    ending_before?: string;
    limit?: number;
    starting_after?: string;
    customer?: string;
    zones?: string[];
}

export interface ListPromotionCodesOutput {
	promotionCodes: PromotionCode[];
}
