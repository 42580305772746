<div class="header-with-button">
    <h4><i class="pi pi-calendar large-icon"></i> Template</h4>
    <div>
        <button data-cy='actions-button'
            pButton
            pRipple
            label="Actions" 
            [disabled]="false"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
            *ngIf="templateActions[0].visible"
            ></button>
        <p-menu #menu [popup]="true" appendTo="body" [model]="templateActions"></p-menu>
    </div>
</div>

<div class="card">
    <h5 *ngIf="!templateQH.loading; else loading" data-cy="template-name">{{template.name}}</h5>

    <h6>Start Date</h6>
    <p *ngIf="!templateQH.loading; else loading">{{template.startDate}}</p>

    <h6>End Date</h6>
    <p *ngIf="!templateQH.loading; else loading">{{template.endDate || 'Never'}}</p>
</div>

<div class="card">
    <h6>Areas</h6>
    <div *ngIf="!templateQH.loading; else loading">
        <div *ngFor="let zone of template.zones">
            {{zone.name}} 
            <!-- <div class="color-square" [style.background-color]="zone.color"></div> -->
        </div>
    </div>
</div>

<div class="card">
    <h6>Availability</h6>
    <div *ngIf="!templateQH.loading; else loading">
        <p>Monday</p>
        <span *ngFor="let time of template.availability.monday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.monday[i] | secondsToTime}} - {{template.availability.monday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.monday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Tuesday</p>
        <span *ngFor="let time of template.availability.tuesday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.tuesday[i] | secondsToTime}} - {{template.availability.tuesday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.tuesday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Wednesday</p>
        <span *ngFor="let time of template.availability.wednesday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.wednesday[i] | secondsToTime}} - {{template.availability.wednesday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.wednesday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Thursday</p>
        <span *ngFor="let time of template.availability.thursday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.thursday[i] | secondsToTime}} - {{template.availability.thursday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.thursday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Friday</p>
        <span *ngFor="let time of template.availability.friday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.friday[i] | secondsToTime}} - {{template.availability.friday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.friday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Saturday</p>
        <span *ngFor="let time of template.availability.saturday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.saturday[i] | secondsToTime}} - {{template.availability.saturday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.saturday?.length">
            <span>No Availability</span>
        </span>
    </div>
    <div *ngIf="!templateQH.loading; else loading">
        <hr>
        <p>Sunday</p>
        <span *ngFor="let time of template.availability.sunday; let even = even; let i = index">
            <p *ngIf="even">{{template.availability.sunday[i] | secondsToTime}} - {{template.availability.sunday[i + 1] | secondsToTime}}</p>
        </span>
        <span *ngIf="!template.availability.sunday?.length">
            <span>No Availability</span>
        </span>
    </div>
</div>

<div class="scroll-padding"></div>

<ng-template #loading>
    <p-skeleton width="100%" height="16px"></p-skeleton>
</ng-template>

<app-apply-template
    #applyTemplate
    reviewButtonText="Apply Template"
></app-apply-template>
