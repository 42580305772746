import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {dayjs} from '@karve.it/core';

import { cloneDeep } from 'lodash';

import { ConfigValueInput, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';

import { MutateType } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

export interface RollingLockDate {
    currentDate: number;
    nextDate: number;
    updateRule: string;
}

@Component({
    selector: 'app-mutate-rolling-lock-date',
    templateUrl: './mutate-rolling-lock-date.component.html',
    styleUrls: ['./mutate-rolling-lock-date.component.scss']
})
export class MutateRollingLockDateComponent implements OnInit {

    @ViewChild('mutate') mutateRef: MutateObjectComponent;

    @Input() mutateType: MutateType;
    @Input() rollingLockDate: RollingLockDate;

    // Template Refs
    @ViewChild('currentDate') currentDateRef: TemplateRef<any>;
    @ViewChild('nextDate') nextDateRef: TemplateRef<any>;
    @ViewChild('updateRule') updateRuleRef: TemplateRef<any>;
    @ViewChild('currentDateReview') currentDateReviewRef: TemplateRef<any>;
    @ViewChild('nextDateReview') nextDateReviewRef: TemplateRef<any>;

    steps: MutateObjectElement[];

    rollingLockDateForm = new UntypedFormGroup({
        currentDate: new UntypedFormControl(null, Validators.required),
        // nextDate: new FormControl(null, Validators.required),
        // updateRule: new FormControl(null, Validators.required),
    });

    defaultFormValues = cloneDeep(this.rollingLockDateForm.value);

    parsedCronTime = 'Unknown';

    dateFormat = 'mm/dd/yy';

    dayJsFormat = 'MM/DD/YYYY hh:mm A';

    timeFormat = 12;

    constructor(
        private setConfigValuesGQL: SetConfigValuesGQL,
        private localNotify: FreyaNotificationsService,
        private detailsHelper: DetailsHelperService,
        private timeZoneHelper: TimezoneHelperService,
    ) { }

    ngOnInit(): void {
    }

    openDialog() {

        if (this.mutateType === 'create') {
            throw new Error('Not implemented');
        }

        this.steps = [
            {
                name: 'Current Date',
                ref: this.currentDateRef,
                control: 'currentDate',
                type: 'complex',
                reviewRef: this.currentDateReviewRef,
            },
            // {
            //     name: 'Next Date',
            //     ref: this.nextDateRef,
            //     control: 'nextDate',
            //     type: 'complex',
            //     reviewRef: this.nextDateReviewRef,
            // },
            // {
            //     name: 'Rolling',
            //     ref: this.updateRuleRef,
            //     control: 'updateRule',
            //     type: 'func',
            //     reviewFunc: () => this.freyaHelper.parseCrontime(this.rollingLockDateForm.value.updateRule),
            // },
        ];

        this.mutateRef.steps = this.steps;

        this.rollingLockDateForm.patchValue({
            currentDate: this.parseUnixTimestamp(this.rollingLockDate.currentDate),
            nextDate: this.parseUnixTimestamp(this.rollingLockDate.nextDate),
            updateRule: this.rollingLockDate.updateRule,
        });

        this.mutateRef.openDialog();
    }

    parseUnixTimestamp(unixTimestamp: number) {
        return dayjs.tz(unixTimestamp * 1000, this.timeZoneHelper.getCurrentTimezone()).format(this.dayJsFormat);
    }

    getUnixTimestamp(timeString: string) {
        return dayjs(timeString, this.dayJsFormat).tz(this.timeZoneHelper.getCurrentTimezone(), true).unix();
    }

    mutateObject() {
        if (this.mutateType === 'update') {
            this.setRollingLockDate();
        }
    }

    setRollingLockDate() {

        const configs: ConfigValueInput[] = [
            {
                key: 'rolling-lock-date.currentDate',
                value: this.rollingLockDate.currentDate.toString(),
            },
            // {
            //     key: 'rolling-lock-date.nextDate',
            //     value: this.rollingLockDate.nextDate.toString(),
            // },
            // {
            //     key: 'rolling-lock-date.updateRule',
            //     value: this.rollingLockDateForm.value.updateRule,
            // },
        ];

        this.setConfigValuesGQL.mutate({ configs })
            .subscribe(() => {
                this.localNotify.success('Rolling lock date updated');
                this.detailsHelper.pushUpdate({
                    id: 'N/A',
                    type: 'Configs',
                    action: 'update',
                });
                this.mutateRef.closeDialog();
            }, (err) => {
                this.mutateRef.loading = false;
                this.localNotify.apolloError('Failed to update rolling lock date', err);
            });

    }

    changeRollingLockDate(dateType: 'currentDate' | 'nextDate') {
        this.rollingLockDate[dateType] = this.getUnixTimestamp(this.rollingLockDateForm.value[dateType]);
    }

}
