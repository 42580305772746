<div
  class="travelInfo"
  *ngIf="jobTravelDistances$ | async as jobTravelDistances"
>
  <span class="time">
    Total Travel:
    {{
      jobTravelDistances?.dock_start_end_dock?.estimatedTime | secondsToTimeUnit
    }}

    (
    {{
      jobTravelDistances?.dock_start_end_dock?.totalDistance
        | distance: jobTravelDistances?.dock_start_end_dock?.distanceUnits
    }})
    <a (click)="collapsed = !collapsed"
      >{{ collapsed ? 'More' : 'Less' }} Info</a
    >
  </span>
  <div *ngIf="!collapsed" class="distanceNote">
    Distances are calculated using an average of traffic conditions, times may
    vary.
  </div>
  <div class="travelBreakdown" *ngIf="!collapsed">
    <span class="stop">
      <i class="pi pi-warehouse"></i>
      <span class="name">Dock</span>
    </span>
    <span class="travelTime">
      <span class="downArrow">&#8595;</span>

      <span class="time">
        {{ jobTravelDistances?.dock_start?.estimatedTime | secondsToTimeUnit }}

        (
        {{
          jobTravelDistances?.dock_start?.totalDistance
            | distance: jobTravelDistances?.dock_start?.distanceUnits
        }})
      </span>
    </span>
    <span class="stop">
      <i class="pi pi-map-marker"></i>
      <span class="name">Start</span>
    </span>
    <span class="travelTime">
      <span class="downArrow">&#8595;</span>

      <span class="time">
        {{ jobTravelDistances?.start_end?.estimatedTime | secondsToTimeUnit }}

        (
        {{
          jobTravelDistances?.start_end?.totalDistance
            | distance: jobTravelDistances?.start_end?.distanceUnits
        }})
      </span>
    </span>
    <span class="stop">
      <i class="pi pi-map-marker"></i>
      <span class="name">End</span>
    </span>
    <span class="travelTime">
      <span class="downArrow">&#8595;</span>

      <span class="time">
        {{ jobTravelDistances?.end_dock?.estimatedTime | secondsToTimeUnit }}

        (
        {{
          jobTravelDistances?.end_dock?.totalDistance
            | distance: jobTravelDistances?.end_dock?.distanceUnits
        }})
      </span>
    </span>
    <span class="stop">
      <i class="pi pi-warehouse"></i>
      <span class="name">Dock</span>
    </span>
  </div>
</div>
