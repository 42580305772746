import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import {CommentsInput, ListCommentsOutput, commentsQuery, AddCommentInput, AddCommentOutput, addCommentMutation, UpdateCommentsInput, UpdateCommentsOutput, updateCommentsMutation, DeleteCommentsInput, DeleteCommentsOutput, deleteCommentsMutation, AddCommentFunctionInput,  } from '@karve.it/interfaces/comments';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

    constructor(private apollo: PlusApollo){}

    listComments(input: CommentsInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListCommentsOutput>({
            query: commentsQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchComments(input: CommentsInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListCommentsOutput>({
            query: commentsQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    addComment(input: AddCommentFunctionInput){
        return this.apollo.mutate<AddCommentOutput>({
            mutation: addCommentMutation,
            variables: {
                ...input
            }
        });
    }

    updateComments(input: UpdateCommentsInput){
        return this.apollo.mutate<UpdateCommentsOutput>({
            mutation: updateCommentsMutation,
            variables: {
                ...input
            }
        });
    }

    deleteComments(input: DeleteCommentsInput){
        return this.apollo.mutate<DeleteCommentsOutput>({
            mutation: deleteCommentsMutation,
            variables: {
                ...input
            }
        });
    }
}
