<div class="discard-job-dialog">
    <span><strong class="warning">All job information will be lost</strong> including</span>
    <ul style="list-style-type: none;">
        <li>Customer details</li>
        <li>Location details</li>
        <li>Inventory</li>
        <li>Timeline & Availability</li>
        <li>Estimates</li>
        <li>Summary</li>
    </ul>
    <span>You can keep this information for your records by creating this job instead.</span>
    <div class="footer">
        <button
            class="p-button-outlined back-btn"
            pButton
            label="Back"
            icon="pi pi-arrow-left"
            (click)="closeDiscardDialog(false)"
        ></button>
        <button
            class="p-button-outlined discard-btn"
            pButton
            label="Discard Opportunity"
            (click)="discardOpportunity()"
        ></button>
        <app-form-action-button
            action="create-close"
            (buttonClicked)="closeDiscardDialog(true)"
        ></app-form-action-button>
        <app-form-action-button
            action="create"
            (buttonClicked)="closeDiscardDialog(true)"
        ></app-form-action-button>
    </div>
</div>
