import { Pipe, PipeTransform } from '@angular/core';

import { Metadata } from '../utilities/metadata.util';

@Pipe({
  name: 'metadata'
})
export class MetadataPipe implements PipeTransform {
 transform(metadata: Metadata, key: string): any {
    return metadata?.[key];
  }
}
