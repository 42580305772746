import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { EventLocationInfo } from '../../estimates/estimates.component';

@Component({
  selector: 'app-update-event-locations-dialog',
  templateUrl: './update-event-locations-dialog.component.html',
  styleUrls: ['./update-event-locations-dialog.component.scss', '../../dynamic-dialog-styling.scss']
})
export class UpdateEventLocationsDialogComponent implements OnInit {

  eventLocationInfo: EventLocationInfo[] = [];

  currentLocation: string;

  newLocation: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.eventLocationInfo = this.config?.data?.eventLocations as EventLocationInfo[];

    const [ firstEvent ] = this.eventLocationInfo;

    const [ firstLocation ] = firstEvent.locations;

    this.currentLocation = firstLocation.currentLocation?.location?.addressLineOne;
    this.newLocation = firstLocation.newLocation?.address;
  }

}
