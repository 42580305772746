import { createActionGroup, props } from '@ngrx/store';

import { UserProfile } from '../../interfaces/auth';

export const AuthActions = createActionGroup({
	source: 'Auth',
	events: {
		'Auth Updated': props<{
			user: UserProfile;
		}>(),
	},
});
