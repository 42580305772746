import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { SubSink } from 'subsink';

import { FullReportFragment, ReportPageListGQL, ReportPageListQueryVariables, ZoneDir } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { MarkdownHelperService } from '../../services/markdown.service';
import { ReportService } from '../../services/report.service';
import { ReportDataStructure } from '../reports.constants';

export interface ReportColumn {
  header: string;
  index: number;
}

export interface ReportFooter {
  label: string;
  data: string[];
}

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent implements OnInit, OnDestroy {

  @Input() reportId: string;
  report: FullReportFragment;
  subs = new SubSink();

  watchQuery: ReturnType<ReportPageListGQL['watch']>;
  loading = true;
  found = false;

  loadingData = false;
  dataHeaders?: ReportColumn[];
  dataRows?: string[][];
  dataFooters?: string[][];

  footerLabelColspan?: number;
  showFooter = true;

  selectedColumns: ReportColumn[];
  selectedFooters: ReportFooter[];

  reportData?: ReportDataStructure;

  tabs = ['data', 'summary'];
  activeTabIndex: number;

  breadcrumb: MenuItem[] = [
    { label: 'Reports', routerLink: '/reports', queryParams: { reportType: 'static' } },
    { label: 'Loading...' },
  ];

  home = { icon: 'pi pi-home', routerLink: '/' };

  constructor(
    private reportTypePageList: ReportPageListGQL,
    private route: ActivatedRoute,
    private detailsHelper: DetailsHelperService,
    public reportService: ReportService,
    private http: HttpClient,
    private angularLocation: Location,
    private freyaMutate: FreyaMutateService,
    public markdownHelper: MarkdownHelperService,
  ) { }

  ngOnInit(): void {
    this.found = false;
    this.subs.sink = this.route.params.subscribe((params) => {
      this.setActiveTabIndex(params.tab);
      this.reportId = params.reportId;
      this.fetch();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.watchQuery;
  }

  getVariables() {
    return {
      filter: {
        ids: [ this.reportId ],
        getDeleted: true,
        zoneDir: ZoneDir.Any,
      },
      getData: [ 'json' ],
    } as ReportPageListQueryVariables;
  }

  fetch() {
    this.reportData = undefined;
    if (!this.reportId) { return; }

    if (this.watchQuery) {
      this.watchQuery.refetch(this.getVariables());
      return;
    }

    this.watchQuery = this.reportTypePageList.watch(this.getVariables());

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      const [report] = res.data?.reports?.reports || [];
      this.report = report;
      this.found = Boolean(report);
      this.setBreadcrumb();
      // console.log(this.report);
      // this.parseCSVData();
      this.fetchData();
    });
  }

  fetchData() {
    if (!this.report?.jsonURL) { return; }

    this.loadingData = true;
    this.subs.sink = this.http.get(this.report.jsonURL, {
      reportProgress: true,
    }).subscribe((res) => {
      // console.log(res);
      this.reportData = res as ReportDataStructure;
      this.parseCSVData();
      this.loadingData = false;
    });

  }

  /**
   * Parses the CSV data and uses it to populate the data table.
   */
  parseCSVData() {
    this.dataHeaders = undefined;
    this.dataRows = undefined;
    this.dataFooters = undefined;

    if (!this.reportData?.csvData) { return; }

    const { data, headers, ags } = this.reportService.parseCSV(this.reportData.csvData);

    if (!data || !headers) { return; }

    const allColumns = headers.map((header, index) => ({ header, index }));

    this.dataHeaders = allColumns;
    this.dataRows = data;

    this.selectedColumns = allColumns;

    if (!ags) { return; }

    const footers = [];

    const sums = ags.find((a) => a[0] === 'SUM');
    const means = ags.find((a) => a[0] === 'MEAN');

    if (sums) {
      footers.push(sums.slice(1));
    }

    if (means) {
      footers.push(means.slice(1));
    }

    this.dataFooters = footers;

    this.setFooter();
  }

  /**
   * Updates the table contents to match the columns selected by the user.
   *
   * In more detail, takes a array of column headers (coming from the column multiselect),
   * sorts it by column index (instead of order of selection),
   * sets it as the value of selectedColumns,
   * then updates the footer data to match the selected columns.
   *
   * @param unorderedSelection An array of column headers
   */
  updateTable(unorderedSelection: ReportColumn[]) {
    this.selectedColumns = unorderedSelection.sort((a, b) => a.index - b.index);
    this.setFooter();
  }

  /**
   * Sets the footer data in `this.selectedFooters` based on which columns the user selected to see.
   */
  setFooter() {
    const [ allSums, allMeans ] = this.dataFooters;

    const selectedSums = [];
    const selectedMeans = [];

    for (const col of this.selectedColumns) {
      if (allSums) {
        selectedSums.push(allSums[col.index]);
      }
      if (allMeans) {
        selectedMeans.push(allMeans[col.index]);
      }
    }

    const nonEmptyFooterRows = [];

    if (selectedSums.length) {
      nonEmptyFooterRows.push(selectedSums);
    }

    if (selectedMeans.length) {
      nonEmptyFooterRows.push(selectedMeans);
    }

    const footerColspan = this.getFooterLabelColspan(nonEmptyFooterRows);
    this.footerLabelColspan = footerColspan;

    const footersWithLabels = [];

    // if (selectedSums.length) {
    //   footersWithLabels.push({
    //     label: 'Sum',
    //     data: selectedSums.splice(footerColspan)
    //   });
    // }

    // if (selectedMeans.length) {
    //   footersWithLabels.push({
    //     label: `Mean where the job's subtotal is greater than 0`,
    //     data: selectedMeans.splice(footerColspan)
    //   });
    // }

    this.selectedFooters = footersWithLabels;
  }

  /**
   * Calculates the span of the cells that will display the labels in the footer
   * (i.e. how many columns the labels should take up)
   * by counting the number of cells that do not contain aggregation data,
   * and returning the smallest number.
   *
   * @param footers An array of footer rows.
   * @returns The number of columns that the footer labels should take up.
   */
  getFooterLabelColspan(footers: string[][]) {

    let result;

    for (const footer of footers) {

      let index = 0;

      while (index < footer.length) {
        if (!footer[index].length) {
          index++;
        } else {
          break;
        }
      }

      if (result === undefined) {
        result = index;
      } else {
        result = Math.min(result, index);
      }
    }

    return result;
  }


  select() {
    if (!this.report) { return; }
    this.detailsHelper.detailsItem.next({ type: 'report', item: this.report });
  }

  downloadJSON() {
    if (!this.reportData) { return; }
    const str = JSON.stringify(this.reportData, null, 2);
    this.reportService.downloadFile(`${ this.report.name }.json`, str);
  }

  downloadCSV() {
    if (!this.reportData.csvData) { return; }
    this.reportService.downloadCSV(this.report, this.reportData);
  }

  rerun() {
    if (!this.reportService.isFullyLoaded(this.report)) { return; }

    let variables = {};
    if (this.report.variables) {
      variables = JSON.parse(this.report.variables);
    }

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'report',
      object: this.report.reportType,
      additionalValues: [
        {
          property: 'navigate',
          value: false
        },
        {
          property: 'variables',
          value: variables
        }
      ]
    });
  }

  /**
   * Adds the active tab to the url.
   *
   * @param tabIndex The index of the active tab
   */
  updateUrl(tabIndex: number) {
    // Get the report id from the url as the report may not have loaded yet at this point
    this.angularLocation.replaceState(
      `reports/static/${this.route.snapshot.params.reportId}/${this.tabs[tabIndex]}`,
      location.search,
    );
  }

  /**
   * Takes the name of a tab (drawn from the url)
   * and sets the active tab to whichever tab matches the provided name (if any),
   * defaults to the first tab if no tab matches the provided name.
   *
   * @param tab The name of a tab
   */
  setActiveTabIndex(tab: string) {
    const tabIndex = this.tabs.indexOf(tab);

    // If the url contains an invalid tab,
    // default to the first tab and remove the invalid tab from the url
    this.activeTabIndex = tabIndex < 0 ? 0 : tabIndex;

    this.updateUrl(this.activeTabIndex);
  }

  setBreadcrumb() {
    const { reportType } = this.report;

    this.breadcrumb = [
      { label: 'Reports', routerLink: '/reports', queryParams: { reportType: 'static' } },
      { label: reportType.name, routerLink: `/reports/type/${reportType.id}`},
      { label: this.report.name },
    ];
  }

}
