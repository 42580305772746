import { Pipe, PipeTransform } from '@angular/core';

// This pipe takes seconds from the start of the day and converts it into a time.
@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(seconds: number): string {
    let amOrPm = 'AM';

    let hours = Math.floor(seconds / 3600);
    let minutes = (Math.floor(seconds % 3600) / 60).toFixed();

    if (minutes === '60'){
      hours += 1;
      minutes = '00';
    }

    if(hours >= 12){
      amOrPm = 'PM';
      if (hours > 12){
        hours -= 12;
      }
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')} ${amOrPm}`;
  }

}
