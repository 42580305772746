import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, from, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { GraphQLModule, PlusAuthenticationService } from '../../core/public-api';
import { AppLoadingService } from '../../services/app-loading.service';

@Injectable({
  providedIn: 'root'
})
export class SetUrlZoneGuard  {

  canActivateChild = this.canActivate;

  constructor(
    private plusAuth: PlusAuthenticationService,
    private graphqlModule: GraphQLModule,
    private router: Router,
    private appLoadingSvc: AppLoadingService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    const desiredZone = state.root.queryParamMap.get('zone');
    return this.appLoadingSvc.appLoading
      .pipe(

        // Wait for the application to load
        filter(loading => !loading),
        take(1),

        // Get current zone and role
        switchMap(() => combineLatest([ this.graphqlModule.zone, this.graphqlModule.role ])),

        // Switch to a different observable depending on the current zone
        switchMap(([ currentZone, desiredRole ]) => {

          // If we could not resolve the desired zone from the query params,
          // or we are already in the desired zone,
          // switch to an observable that emits true to activate the route
          if (!desiredZone || desiredZone === currentZone) {
            return of(true);
          }

          // Otherwise, set the context to the desired zone and role,
          // then switch to an observable that emits a URL tree to redirect to that URL

          // `plusAuth.setContext` returns a promise, so we need to use `from` to convert it into an observable...
          return from(this.plusAuth.setContext(desiredZone, desiredRole))
            // ... then map its value (void) to a URL tree so the output observable emits a URL tree
            .pipe(map(() => this.router.parseUrl(state.url)));
        }),
      );
  }


}
