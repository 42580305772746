import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ArtifactService, PlusAuthenticationService } from '@karve.it/core';
import { Artifact, ArtifactInput } from '@karve.it/interfaces/artifacts';
import { ARTIFACT_CONTENT_TYPE, FileRestrictions } from 'src/app/artifacts/artifact.util';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { BrandingService } from '../../services/branding.service';
export interface FreyaFile extends File {
  uploaded?: boolean;
  progress?: number;
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, OnDestroy {

  @Input() relatedObjects = [];
  @Input() author = this.plusAuth.user.id;
  @Input() restrictions: FileRestrictions = {
    minHeight: 0,
    minWidth: 0,
    allowedFileTypes: ARTIFACT_CONTENT_TYPE,
    maxFileSize: environment.maxFileSize,
  };

  subs = new SubSink();

  artifact: Artifact;

  filesToUpload: FreyaFile[] = [];

  constructor(
    private artifactService: ArtifactService,
    private plusAuth: PlusAuthenticationService,
    private localNotify: FreyaNotificationsService,
    private httpClient: HttpClient,
    private detailsHelper: DetailsHelperService,
    private brandingSvc: BrandingService,
  ) { }


  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  validateFileType(file: File) {
    if (!this.restrictions.allowedFileTypes.includes(file.type)) {
      this.localNotify.addToast.next({
        severity: 'error',
        summary: `${file.type} filetype is not allowed`,
      });
      this.removeFile(file);
      return;
    }
  }

  validateFileDimensions(width: number, height: number) {
    if (width < this.restrictions.minWidth || height < this.restrictions.minHeight) {
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'File is too small.',
        detail: `Min ${this.restrictions.minWidth} x ${this.restrictions.minHeight}`
      });
      return;
    }

    if (width > this.restrictions.maxWidth || height > this.restrictions.maxHeight) {
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'File is too large',
        detail: `Max ${this.restrictions.maxWidth} x ${this.restrictions.maxHeight}`
      });
      return;
    }
  }


  async onFileSelected(event) {
    const file = event.files[0] as FreyaFile;

    this.validateFileType(file);

    if (file.size <= 0) {
      this.localNotify.addToast.next({ severity: 'error', summary: 'File is too small.' });
      return;
    }

    const URL = window.URL;
    const img = new Image();

    img.src = URL.createObjectURL(event.files[0]);
    await new Promise<{ height: number; width: number }>((resolve) => {
      if (event.files[0].type.indexOf('image') >= 0) {
        img.onload = (e: any) => {
          if (!e || !e.path || !e.path[0]) {
            return resolve({ height: 0, width: 0 });  // If it fails return 0,0 for width and height
          }
          return resolve({ height: e.path[0].height, width: e.path[0].width });  // Return the Width and Height of the Image
        };
      } else {
        return resolve({ height: 0, width: 0 });
      }
    })
      .then((obj) => {
        this.validateFileDimensions(obj.width, obj.height);

        file.uploaded = false;
        file.progress = 0;
      });
  }

  uploadHandler(event) {
    for (const file of event.files) {
      if (file.progress === 100) {
        this.removeFile(file);
      } else {
        this.createArtifactForFile(file);
      }
    }
  }

  createArtifactForFile(file: FreyaFile) {
    file.progress = 0;
    this.addProgress(file);
    file.uploaded = true;

    const artifactInput = {
      // Convert file extension to lower case to upload files regardless of case.
      // name: file.name.substring(0,file.name.lastIndexOf('.'))+'.'+file.name.split('.').pop().toLowerCase(),
      name:file.name,
      contentType: file.type,
      namespace: this.brandingSvc.currentZone().value.id,
      fileSize: file.size,
      public: false,
      author: this.plusAuth.user.id,
      relatedObjectIds: this.relatedObjects,
    } as ArtifactInput;

    if (this.author) {
      artifactInput.author = this.author;
    }

    this.subs.sink = this.artifactService.createArtifact([artifactInput]).subscribe(res => {
      const artifact = res.data.createArtifacts.artifacts[0];
      const policy = JSON.parse(artifact.signedPolicy);

      this.uploadFile(file, policy);
    }, (err) => {
      this.localNotify.addToast.next({ severity: 'error', summary: 'File Rejected by the Server', detail: err.message });
    });
  }

  uploadFile(file: FreyaFile, policy) {
    const formData = new FormData();

    for (const f of Object.keys(policy.fields)) {
      const value = policy.fields[f];
      formData.append(f, value);
    }

    formData.append('content-type', file.type);
    formData.append('file', file);

    this.subs.sink = this.httpClient.post<any>(policy.url, formData).subscribe(
      (res) => {
        file.progress = 100;
        this.detailsHelper.pushUpdate({
          id:this.plusAuth.user.id,
          type:'Artifacts',
          action:'update'
        });
        this.localNotify.addToast.next({ severity: 'success', summary: 'File Uploaded', detail: file.name });
      },
      (err) => {
        file.progress = -1;
        this.localNotify.addToast.next({ severity: 'error', summary: 'Upload Failed', detail: file.name });
      }
    );
  }

  async addProgress(file: FreyaFile) {
    if (file.progress < 90) {
      file.progress += 2;
      setTimeout(() => {
        this.addProgress(file);
      }, 100);
    }
  }

  removeFile(file: FreyaFile) {
    const index = this.filesToUpload.findIndex((f) => f.name === file.name);
    this.filesToUpload.splice(index, 1);

    this.filesToUpload = [...this.filesToUpload];
  }
}
