/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { QueryRef } from 'apollo-angular';


import { cloneDeep } from 'lodash';
import { map, tap } from 'rxjs/operators';
import { OBJECT_ICON_MAP } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { DownloadJobInvoiceComponent } from 'src/app/shared/download-job-invoice/download-job-invoice.component';
import { MutateJobComponent } from 'src/app/shared/mutate-job/mutate-job.component';
import { SubSink } from 'subsink';

import { FullJobFragment, JobDetailsFragment, JobDetailsGQL, JobDetailsQuery, JobDetailsQueryVariables } from '../../../generated/graphql.generated';

import { CommentsComponent } from '../../comments/comments.component';

import { BrandingService } from '../../services/branding.service';
import { PermissionService } from '../../services/permission.service';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';
import { isJobSharedToZone, getEventCompareFn } from '../jobs.util';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('mutate') mutateRef: MutateJobComponent;
  @ViewChild('comments') commentsRef: CommentsComponent;
  @ViewChild('inventoryComments') inventoryRef: CommentsComponent;

  @ViewChild('downloadInvoice') invoiceRef: DownloadJobInvoiceComponent;

  @Input() jobData: JobDetailsFragment;
  job: JobDetailsFragment;

  subs = new SubSink();

  jobsQueryRef: QueryRef<JobDetailsQuery, JobDetailsQueryVariables>;
  jobsQH: WatchQueryHelper = {
    loading: true,
  };

  jobIcon = `${OBJECT_ICON_MAP.job} large-icon`;

  showCancelDialog = false;

  // If the job is shared from another zone, then the user will not be able to edit it
  sharedFromAnotherZone = false;
  sharedMessage = '';

  permissions$ = this.permissionHandler.watchPermissions([
    'comments.add',
  ]).pipe(
    map((res) => ({
      addComments: res[0],
    })),
    tap(() => this.cd.detectChanges()),
  );

  constructor(
    public detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private jobDetailsGQL: JobDetailsGQL,
    private brandingService: BrandingService,
    private permissionHandler: PermissionService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges){
    if (changes.jobData?.currentValue?.id !== changes.jobData?.previousValue?.id) {
      this.job = this.jobData;
      this.jobsQH.loading = true;
    }

    if (changes.jobData) {
      this.fetchJob();
    }
  }

  ngOnDestroy() {
    delete this.jobsQueryRef;
    this.subs.unsubscribe();
  }

  /**
   * Refetchs the job or initalizes query if it is the first fetch
   */
  fetchJob() {
    if (this.jobsQueryRef) {
      this.jobsQueryRef.setVariables({ jobId: this.job.id });
      return;
    }

    this.initJobQuery();
  }

  openZone() {
    if (!this.job?.zone) { return; }
    if (this.job?.zone.type === 'area') {
      this.detailsHelper.open('area', { id: this.job?.zone?.id });
    }
  }

  openCustomer() {
    const customer = this.job?.users?.find((c) => c.role === 'customer');
    if (customer) {
      this.detailsHelper.open('users', { id: customer.user.id });
    }
  }

  /**
   * Initializes Jobs Query
   */
  private initJobQuery(){
    this.jobsQueryRef = this.jobDetailsGQL.watch({
      jobId: this.job.id,
      getDeleted: true
    }, {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.jobsQueryRef.valueChanges
      .subscribe({
        next:(res) => {
          this.jobsQH.error = undefined;
          this.jobsQH.loading = res.loading;
          if (!res.data?.jobs?.jobs?.length) { return; }
          if (this.jobsQH.loading) { return; }
          this.job = cloneDeep(res.data.jobs.jobs[0]);
          this.job.events.sort(getEventCompareFn('sequentialOrder'));

          this.sharedFromAnotherZone = isJobSharedToZone(this.job, this.brandingService.currentZone().value.id);
          if (this.sharedFromAnotherZone){
            this.sharedMessage = `This job has been shared from ${this.job.zone.name} and therefore can't be edited.`;
          }

          // TODO: fix job details to use full job fragment
          this.mutateRef.job = this.job as unknown as FullJobFragment;
          this.mutateRef.setFormValues();
          
          this.refetchComments();
        },
        error: (err: ApolloError) => {
          this.jobsQH.error = err;
          this.jobsQH.loading = false;

          // Once an Apollo query observable errors out, it will not emit again,
          // which means calling `jobsQueryRef.refetch()` on the current `jobsQueryRef` will have have no effect
          // and the component will be stuck in an error state,
          // therefore we need to clear the current `jobsQueryRef` so that next time we call `fetchJob()` a new query is initialized
          this.jobsQueryRef = undefined;
        },
      });
  }

  /**
   * Refetches the comments and inventory for the job, or initializes the comments if they have not yet been
   */
  refetchComments(){
    // If the comments have not been initialized then the component will fetch the data itself
    if (!this.commentsRef) { return; }
    this.commentsRef.objectId = this.job.id;
    this.commentsRef.fetchComments(false);
  }

  cancelJob() {
    this.showCancelDialog = false;
    this.freyaHelper.cancelJob(this.job.id);
  }

  // sortEvents(events: BaseCalendarEventFragment[]) {
  //   if (!events) { return []; }

  //   return events.sort((a, b) => (a.start - b.start) || (a.end - b.end));
  // }

  openInvoice(invoiceId: string) {
    this.detailsHelper.detailsItem.next({ item: { id: invoiceId }, type: 'invoice' });
  }
}
