import { Injectable } from '@angular/core';

import { ValidateTokenOutput, ValidateTokenInput , validateToken } from '@karve.it/interfaces/tokens';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private apollo: PlusApollo,
  ) {}

  /**
   * Checks if the token is valid and returns associated metadata with that token.
   *
   * @param input
   */
  validateToken(
    input: ValidateTokenInput
  ) {
    return this.apollo.query<ValidateTokenOutput>({
      query: validateToken,
      variables: {...input},
    });
  }
}
