import { gql } from 'graphql-tag';

import { LocationCreateBase } from './locations';

import { Zone, ZonePagination } from './zones';

export interface UserRole {
    id: string;
    name: string;
    color: string;
    description: string;
    zones: ZonePagination;
    attributes?: string[];
}

export interface ZoneRole {
    zone: Zone;
    role: UserRole;
}

export interface RawUser {
    id: string;
    name?: string;
    email?: string;
    phone?: string;
    givenName?: string;
    familyName?: string;
    company?: string;
    billingAddress?: LocationCreateBase;
    roles?: UserRole[];
    twoFactorEnabled?: boolean;

    // converted in User
    deletedOn?: { unix: number };
    updatedAt?: { unix: number };
    createdAt?: { unix: number };
    zones?: ZonePagination;
}


export interface User extends Pick<RawUser, 'id' | 'email' | 'phone' | 'givenName' | 'familyName' | 'roles'> {
    deletedOn?: Date;
    updatedAt?: Date;
    createdAt?: Date;

    /**
     * Current Authenticated Role
     *
     * @deprecated Use PlusAuthenticationService.contextedRoleId,
     * or GraphqlModule.role
     */
    role?: string;

    /**
     * Currently Authenticated Zone
     *
     * @deprecated use branding.currentZone,
     *  PlusAuthenticationService.contextedZoneId,
     * or GraphqlModule.zone
     */
    zone?: string;
    zones?: Zone[];
    // Mapped 1:1 role and zone relationship
    zoneRoles?: ZoneRole[];
}

export interface JWTPayload {
    id: string;
    email: string;
    exp: number;
    zone: string;
    familyName?: string;
    givenName?: string;
    iat: number;
    updatedAt: number;
    createdAt: number;
    deletedOn?: number;
}

export type UserProfile = User;

export interface AuthenticateInput {
    id?: string;
    zone?: string;
    email?: string;
    password?: string;
    rememberLength?: number;
    jwt?: string;
    refreshToken?: string;
    twoFactorAuthenticationToken?: string;
}



export interface GenerateSecretOutput {
	// Link for QR Code with secret encoded.
	qr: string;
	// Secret used for generating codes.
	secret: string;
	// URI to generate a QR Code from.
	uri: string;
}


export const emailExistsQuery = gql`
query emailExists($email: String!) {
  tokens {
    emailExists(email: $email)
  }
}
`;

export interface EmailExistsInput {
    email: string;
}

export interface EmailExistsOutput {
  tokens: {
    emailExists?: string;
  };
}

export function resolveUserZoneRoles(user: User): ZoneRole[] {
    const zoneroles: { zone: Zone; role: UserRole }[] = [];
    if (!user.zones || ! user.roles) { return []; }

    for (const zone of user.zones) {
      for (const role of user.roles) {
        if (!role.zones.nodes.find((z) => z.id === zone.id)) { continue; }

        zoneroles.push({
          zone,
          role,
        });
      }
    }

    return zoneroles;

}
