import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceText'
})
export class SliceTextPipe implements PipeTransform {

  transform(value: string, maxlength: number, useElipsis = true): string {
    return `${value.slice(0, maxlength)}${useElipsis && value.length > maxlength ? '...' : ''}`;
  }

}
