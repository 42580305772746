<section>
  <header>
    <h2><i class="pi pi-user p-mr-1"></i> Customer Details</h2>
    <p-button
      class="editButton"
      *ngIf="!isEditMode; else cancelButton"
      icon="pi pi-pencil"
      [text]="true"
      (onClick)="editCustomer()"
    />
  </header>
  <ng-container *ngIf="jobLoaded$ | async; else loading">
    <main
      class="p-mt-2 p-ml-1"
      *ngIf="!isEditMode; else customerEditForm"
    >
      <div class="customer details">
        <span> {{ customerName$ | async }} </span>

        <div
          class="data"
          *ngIf="customer$ | async as customer"
        >
          <a
            *ngIf="customer.email"
            href="mailto:{{ customer.email }}"
          >
            {{ customer.email }}
          </a>
          <span *ngIf="customer.email && customer.phone"> | </span>
          <a
            *ngIf="customer.phone"
            href="tel:{{ customer.phone }}"
          >
            {{ customer | phone }}
          </a>
        </div>
      </div>

      <div class="details">
        <span class="title">Timeline</span>
        <span class="data"> {{ job$ | async | jobTimeline }}</span>
      </div>

      <ng-container *ngIf="jobMetadata$ | async as jobMetadata">
        <div class="details">
          <span class="title">Customer Type</span>
          <span class="data"> {{ jobMetadata.customerType }}</span>
        </div>

        <div class="details">
          <span class="title">Job origin</span>
          <span class="data"> {{ jobMetadata.jobOrigin }}</span>
        </div>
      </ng-container>

      <div class="details">
        <span class="title">Source</span>
        <span class="data"> {{ jobSource$ | async }} </span>
      </div>

    </main>
  </ng-container>
</section>

<ng-template #customerEditForm>
  <app-jobv2-create-customer
    [renderHeadless]="true"
    *ngIf="isEditMode"
  ></app-jobv2-create-customer>
</ng-template>
<ng-template #cancelButton>
  <div class="flex">
    <p-button
      class="cancelButton p-mr-2"
      [outlined]="true"
      label="Cancel"
      (onClick)="cancelEditCustomer()"
    />
    <p-button
      class="saveButton"
      icon="pi pi-check"
      label="Save"
      (onClick)="updateJob()"
    />
  </div>
</ng-template>

<ng-template #loading>
  <main class="p-mt-2 p-ml-1">
    <div
      *ngFor="let _ of [1, 2, 3]"
      class="details"
    >
      <p-skeleton
        width="10rem"
        height="3rem"
      />
    </div>
  </main>
</ng-template>