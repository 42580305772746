/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { SubSink } from 'subsink';

import { GetConfigValuesGQL, GetNiceJobsRefreshTokenGQL, SetConfigValuesGQL } from '../../generated/graphql.generated';

import { FreyaNotificationsService } from '../services/freya-notifications.service';

import { NICE_JOB_CONFIGS } from './nice-jobs.constants';


@Component({
  selector: 'app-nice-jobs',
  templateUrl: './nice-jobs.component.html',
  styleUrls: ['./nice-jobs.component.scss']
})
export class NiceJobsComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  niceJobsSignInLink = '';

  authorizeEndpoint = 'https://api.nicejob.com/oauth/authorize';
  redirectUrl = `https://${window.location.hostname}/integration/nicejobs`;

  scopes = ['people', 'bookings'];

  clientId = '';
  authorizationCode = '';
  refreshToken = ``;

  loading = true;

  constructor(
    private getConfigsGQL: GetConfigValuesGQL,
    private route: ActivatedRoute,
    private getNiceJobsRefreshTokenGQL: GetNiceJobsRefreshTokenGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.getConfigsGQL.watch({
      keys: [
        NICE_JOB_CONFIGS.refreshToken,
        NICE_JOB_CONFIGS.clientId,
        NICE_JOB_CONFIGS.clientSecret,
      ],
    }).valueChanges.subscribe((res) => {
      this.loading = res.loading;
      if (!res.data) { return; }

      this.refreshToken = res.data.getConfigValues.find((cfg) => cfg.key === NICE_JOB_CONFIGS.refreshToken)?.value;
      this.clientId = res.data.getConfigValues.find((cfg) => cfg.key === NICE_JOB_CONFIGS.clientId)?.value;

      if (!this.refreshToken){
        // eslint-disable-next-line max-len
        this.niceJobsSignInLink = `${this.authorizeEndpoint}?response_type=code&redirect_uri=${this.redirectUrl}&client_id=${this.clientId}&scope=bookings people`;
      }

      this.route.queryParamMap.subscribe((parmas) => {
        this.authorizationCode = parmas.get('code');
        if (!this.authorizationCode || this.refreshToken) { return; }
        this.getRefreshToken();
      });
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getRefreshToken(){
    this.subs.sink = this.getNiceJobsRefreshTokenGQL.fetch({code: this.authorizationCode}).subscribe((res) => {
      this.refreshToken = res.data.getNiceJobsRefreshToken;
    });
  }

  deactivateNiceJobs(){
    this.setConfigValuesGQL.mutate({
      configs: [
        {
          key: NICE_JOB_CONFIGS.refreshToken,
          value: '',
          reset: true,
        },
        {
          key: NICE_JOB_CONFIGS.accessToken,
          value: '',
          reset: true,
        },
        {
          key: NICE_JOB_CONFIGS.tokenValidUntil,
          value: '',
          reset: true,
        }
      ],
    }).subscribe((res) => {
      this.refreshToken = undefined;
      this.localNotify.success(`Nice Jobs integration deactivated`);
    }, (err) => {
      this.localNotify.apolloError(`Failed to deactivate integration`, err);
    });
  }


}
