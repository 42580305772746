<div *ngIf="tax">
    <div class="header-with-button">
        <div>
            <h4><i class="pi pi-dollar large-icon"></i> Tax</h4>
            <app-object-state
                [object]="tax"
                objectType="tax"
                [colorful]="true"
                [loading]="loading"
                ></app-object-state>
        </div>
        <div>
            <button
                pButton
                pRipple
                label="Actions"
                *hasPermission="['taxes.update']"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="taxActions"
            ></p-menu>
        </div>
    </div>

    <div class="card">
        <h4>{{tax.name}}</h4>
        <p>{{tax.description ? tax.description : 'No Description for this tax'}}</p>
        <p>{{tax.active ? 'Active' : 'Inactive'}}</p>
        <p>{{tax.percentage}}%</p>
        <p>{{tax.type}}</p>
        <p>Zone Defaut: {{tax.isZoneDefault ? 'Yes' : 'No'}}</p>
    </div>

    <h5>Zones</h5>
    <div class="card" *ngIf="tax?.zones?.nodes">
        <div *ngFor="let zone of tax.zones.nodes">
            <div class="p-grid p-align-center">
                <div class="p-col-2">
                    <span class="indicator" [style]="{'background-color': zone.color}"></span>
                </div>
                <div class="p-col-10">
                    <span>{{zone.name}} ({{zone.type | titlecase}})</span>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete-object #delete (deleted)="deleteTax()"></app-delete-object>

<app-mutate-tax #mutate mutateType="update"></app-mutate-tax>
