import { Pipe, PipeTransform } from '@angular/core';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';

@Pipe({
  name: 'pendingEvents'
})
export class PendingEventsPipe implements PipeTransform {

  transform(events: CalendarEvent[]): CalendarEvent[] {
    if (!events) { return []; }

    return events.filter((ce) => ce.status === 'pending');
  }

}
