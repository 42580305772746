<div class="create-price-input" [formGroup]="priceForm">
    <ng-template #product>
        <div>
            <p>Product: {{product.name}}</p>
        </div>
    </ng-template>
    <ng-template #name>
        <div *appVar="priceForm.get('name') as control">
            <label>Name</label>
            <br/>
            <input type="text"
                pInputText
                formControlName="name"
                placeholder="Price Name *"
                data-cy="name-text-field"
                [class.p-invalid-input]="!control.valid && control.touched">
                <br>
                <small>Used to reference this price. Eg. 'Summer Price'</small>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #active>
        <div>
            <p-checkbox formControlName="active" binary="true" label="Toggled if price is active."> </p-checkbox>
        </div>
    </ng-template>
    <ng-template #amount>
        <div *appVar="priceForm.get('amount') as control">
            <div class="p-grid">
                <div class="p-col-5">
                    <label>Type</label>
                    <br/>
                    <p-dropdown
                        [options]="priceTypes"
                        formControlName="priceType"
                        optionLabel="display"
                        optionValue="value"
                        appendTo="body"
                        data-cy="type-dropdown-field"
                    ></p-dropdown>
                </div>
                <div class="p-col-7">
                    <label>Amount</label>
                    <br/>
                    <div class="p-inputgroup input-width" >
                        <!-- <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span> -->
                        <input type="number"
                            step="0.01"
                            pInputText
                            formControlName="amount"
                            placeholder="Amount *"
                            data-cy="amount-number-field"
                            [class.p-invalid-input]="!control.valid && control.touched">
                        <!-- <span class="p-inputgroup-addon"><i class="pi pi-percentage"></i></span> -->
                    </div>
                </div>
            </div>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #currency>
        <div>
            <label>Currency</label>
            <br/>
            <p-dropdown
                [options]="currencyCodes"
                formControlName="currency"
                data-cy="currency-dropdown-field"
                appendTo="body"
            ></p-dropdown>
        </div>
    </ng-template>
    <ng-template #effectExistingCharges>
        <div>
            With the right admin restriction, toggling this ON will recalculate all jobs with this price.

            <br />

            <p-checkbox
                formControlName="effectExistingCharges"
                binary="true"
                label="DANGER: Effect Existing Charges"
            ></p-checkbox>
        </div>
    </ng-template>
    <ng-template #zone>
        <div 
        *appVar="priceForm.get('zones') as control"
        class="p-col-12">
            <app-assign-zones #zonesComponent [limit]="1" formControlName="zones"></app-assign-zones>
        </div>
    </ng-template>


    <ng-template #taxes>
        <div
            class="p-grid"
            *appVar="priceForm.get('taxes') as control"
        >
            <div class="p-col">
                <p-multiSelect
                    [options]="taxOptions"
                    class="full-width-multiselect"
                    formControlName="taxes"
                    [showToggleAll]="false"
                    optionLabel="name"
                    dataKey="id"
                    [maxSelectedLabels]="1"
                    [selectedItemsLabel]="'{0} Taxes Selected'"
                    [autofocusFilter]="!responsiveHelper.isSmallScreen"
                    data-cy="tax-multi-select-field"
                    appendTo="body"
                >
                    <ng-template
                        let-tax
                        pTemplate="item"
                    >
                        <div>
                            <div>{{tax.name}}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <div class="p-col">
                <span class="chip-container">
                    <p-chip
                        *ngFor="let tax of control.value"
                        [removable]="true"
                        (onRemove)="removeTax(tax)"
                        [label]="tax.name"
                    >
                    </p-chip>
                </span>
            </div>
        </div>

        <p
            *ngIf="mutateType === 'update'"
        >
            These taxes will only be applied for new charges added to jobs and not existing ones.
            <ng-container *ngIf="hasEffectExistingChargesRestriction">
                Admin only: Unless you toggle on the "Effect Existing Charges" button, then
                any tax <i>changes</i> you make will be applied to all charges that use this price.

                EG, if you add a tax here and enable that button, then that tax will be added to all
                existing charges. If you remove a tax here and enable that button, then that tax will be removed
                from all existing charges and the totals recalculated.
            </ng-container>
    
        </p>
    </ng-template>

    <ng-template #expenses >
       <div formArrayName="expenses" class="p-datatable" >
        <table style="width: 100%;" class="p-datatable-table" >
            <thead class="p-datatable-thead" >
                <tr>
                    <th style="min-width: 14rem;">Name</th>
                    <th>Unit Cost {{singularProductUnit}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="p-datatable-tbody" >
                <tr *ngFor="let expenseControl of expensesForm.controls; index as ri">
                    <td *appVar="expenseControl.get('expenseId') as control">
                        <p-dropdown
                            [options]="expenseOptions"
                            appendTo="body"
                            placeholder="Select Cost"
                            [formControl]="control"
                            optionLabel="name"
                            optionValue="id"
                            [ngClass]="{'ng-invalid': !control.valid, 'ng-dirty': control.touched}"
                        ></p-dropdown>
                    </td>
                    <td *appVar="expenseControl.get('unitCost') as control">
                        <p-inputNumber 
                            [formControl]="control"
                            inputId="currency-us" 
                            mode="currency" 
                            [currency]="priceForm.value.currency" 
                            locale="en-US"
                            [ngClass]="{'ng-invalid': !control.valid, 'ng-dirty': control.touched}"
                        /> 
                    </td>
                    <td>
                        <p-button icon="pi pi-trash" severity="danger" size="small" (click)="onRowDelete(ri)"/>
                    </td>
                </tr>
            </tbody>
            <tfoot class="p-datatable-tfoot">
                <tr>
                    <td colspan="3">
                        <p-button label="Add Cost" icon="pi pi-plus" size="small" [outlined]="true" (click)="onRowAdd()"></p-button>
                    </td>
                </tr>
            </tfoot>
        </table>
        <small class="field-error-message p-mt-2" *ngIf="expensesForm.invalid && expensesForm.hasError('duplicateExpense') && (expensesForm.touched || expensesForm.dirty)">
            Costs must be unique. Please remove duplicate costs.
        </small>
       </div>
    </ng-template>
</div>

<app-mutate-object 
    #mutate
    [fg]="priceForm"
    objectType="Price"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
    scrollHeight="300px"
></app-mutate-object>

<ng-template #amountReview>
    <span *ngIf="priceForm.value.priceType === 'fixed'">{{priceForm.value.amount | currency}}</span>
    <span *ngIf="priceForm.value.priceType === 'percentage'">{{priceForm.value.amount}}%</span>
</ng-template>