import { Injectable } from '@angular/core';
import { Charge, Rule } from 'src/generated/graphql.generated';

import { JobEventType, METERS_PER_KM, METERS_PER_MILE } from '../global.constants';
import { safeParseJSON } from '../js';

import { ChargeHelperService } from './charge-helper.service';

import { EstimateHelperService } from './estimate-helper.service';
import { FreyaHelperService } from './freya-helper.service';


export interface ProductMetadataValue {
  productId: string;
  quantity: number | string;

  // defaults to 0.01
  roundingQuantity?: number;
  // defaults to 'ceil'
  roundingFunction?: 'round' | 'ceil' | 'floor';

  event?: JobEventType;
}

export interface RemovedProductValue {
  ruleId: string;
  // JSON stringified value from ProductMetadataValue, used as an identifier
  autoInfo: string;
}

export interface AddProductsMetadataValue {
  products: ProductMetadataValue[];
}

export interface ChargeUpdateChange {
  remove?: true | string;
  quantity?: number;
  event?: string;
}

export const ATTR_AUTO_PREFIX = 'auto';
export const ATTR_MANUAL_PREFIX = 'manual';
export const METADATA_JOB_REMOVED_CHARGES = 'ruleRemovedCharges';

@Injectable({
  providedIn: 'root'
})
export class ProductRulesService {

  constructor(
    private freyaHelper: FreyaHelperService,
    private estimateHelper: EstimateHelperService,
    private chargeHelperService: ChargeHelperService,
  ) { }


  determineProductRuleActions({
    productsRemoved,
    productRules,
    activeCharges,
    eventTypes,
  } = {
    productsRemoved: [] as RemovedProductValue[],
    productRules: [] as Rule[],
    activeCharges: [] as Charge[],
    eventTypes: [] as JobEventType[],
  }) {

    const productsToAdd: {
      rule: Rule;
      product: ProductMetadataValue;
      quantity: number;
    }[] = [];

    const chargesToUpdate: {
      rule?: Rule;
      charge: Charge;
      change: ChargeUpdateChange;
      productInfo?: ProductMetadataValue;
    }[] = [];

    const satisfiedCharges: Charge[] = [];

    // if a charge is not satisfied by a rule, remove it if it is in auto mode
    for (const charge of activeCharges) {
      const isAuto = this.isAutoCharge(charge);
      if (!isAuto) { continue; }
      const isSatisfied = satisfiedCharges.includes(charge);
      if (isSatisfied) { continue; }
      // we are an auto charge not in the satisfiedCharges array
      chargesToUpdate.push({
        change: { remove: 'rule not triggered anymore', },
        charge,
      });
    }

    return {
      productsToAdd,
      chargesToUpdate,
    };
  }

  determineAutoQuantity(
    productInfo: ProductMetadataValue
  ): number {
    let quantity = productInfo.quantity;

    if (typeof quantity === 'string' && quantity.startsWith('event-') && productInfo.event) {
      const eventInfo = this.estimateHelper.precalculateEventInfo(productInfo.event);
      const strQuantity = quantity;
      if (strQuantity === 'event-total-time') {
        quantity = eventInfo.totalTime / 3600;
      } else if (strQuantity === 'event-total-locationtime') {
        quantity = eventInfo.totalLocationTime / 3600;
      } else if (strQuantity === 'event-total-traveltime') {
        quantity = eventInfo.totalTravelTime / 3600;
      } else if (strQuantity === 'event-total-distance-mile') {
        quantity = eventInfo.totalDistanceMeters / METERS_PER_MILE;
      } else if (strQuantity === 'event-total-distance-km') {
        quantity = eventInfo.totalDistanceMeters / METERS_PER_KM;
      }
      // console.log({productInfo, eventInfo, strQuantity, quantity}, eventInfo.totalTravelTime / 3600);
    }

    // quantity was not resolved, default to 1
    if (Number(quantity)) {
      quantity = Number(quantity);
    }

    if (typeof quantity === 'string') {
      quantity = 0;
    }

    quantity = quantity || 0;

    const rounding = productInfo.roundingQuantity || 0.01;
    if (rounding) {
      const roundingFunctions = {
        ceil: Math.ceil,
        floor: Math.floor,
        round: Math.round,
      };
      const roundingFunctionType = productInfo.roundingFunction || 'ceil';
      const roundingFunction = roundingFunctions[roundingFunctionType];
      quantity = roundingFunction(quantity / rounding) * rounding;
    }

    quantity = Math.round(quantity * 1000) / 1000;

    return quantity;
  }

  isAutoCharge(charge: Charge) {
    return Boolean(charge.attributes?.find((a) => a.startsWith(`${ ATTR_AUTO_PREFIX }::`)));
  }

  isManualCharge(charge: Charge) {
    return Boolean(charge.attributes?.find((a) => a.startsWith(`${ ATTR_MANUAL_PREFIX }::`)));
  }

  isAutoOrManualCharge(charge: Charge) {
    return Boolean(charge.attributes?.find((a) => a.startsWith(`${ ATTR_MANUAL_PREFIX }::`) || a.startsWith(`${ ATTR_AUTO_PREFIX }::`)));
  }

  getRuleIdFromChargeMode(charge: Charge) {
    const attr = charge.attributes?.find((a) => a.startsWith(`${ ATTR_MANUAL_PREFIX }::`) || a.startsWith(`${ ATTR_AUTO_PREFIX }::`));
    if (!attr) { return undefined; }

    const [ mode, ruleId ] = attr.split('::');
    return ruleId;
  }

  /**
   * Sets charge attributes to auto or manual if it is already auto or manual
   * if it is not auto or manual, "ruleId" is required. If it is not provided,
   * it will not be marked.
   *
   * @param charge charge to set
   * @param setAuto true to set charge as auto, false to set as manual
   */
  setChargeMode(charge: Charge, modeSet: 'auto' | 'manual' = 'auto' , ruleId?: string) {
    const existingRuleId = this.getRuleIdFromChargeMode(charge);
    if (!ruleId && existingRuleId) {
      ruleId = existingRuleId;
    }

    if (!ruleId) { return; }
    const modeAuto = `${ ATTR_AUTO_PREFIX }::${ ruleId }`;
    const modeManual = `${ ATTR_MANUAL_PREFIX }::${ ruleId }`;
    const mode = modeSet === 'auto' ? modeAuto : modeManual;

    charge.attributes = this.freyaHelper.replaceAttribute(charge.attributes, mode, [ modeAuto, modeManual ]);
  }

  private determineExistingCharges(
    activeCharges: Charge[],
    products: ProductMetadataValue[],
    ruleId: string,
  ) {
    const autoCharges: [ Charge, ProductMetadataValue ][] = [];
    const manualCharges: [ Charge, ProductMetadataValue ][] = [];
    const missingProducts: ProductMetadataValue[] = [ ...products ];

    for (const charge of activeCharges) {

      const [ mode, modeRuleId ] = this.chargeHelperService.getAutoModeFromCharge(charge);
      // this charge belongs to a different rule
      if (!mode || modeRuleId !== ruleId) { continue; }

      const product = products.find((productInfo) =>
        charge.attributes.includes(`auto-info::${ JSON.stringify(productInfo) }`)
      );

      if (mode === 'manual') {
        // charge is a manual charge so we will do nothing with it.
        manualCharges.push([ charge, product ]);
      } else if (mode === 'auto') {
        // charge is an auto charge so we will update the quantity if it has changed.
        autoCharges.push([ charge, product ]);
      }

      // if charge exists remove it from missing products
      if (product) {
        const missingProductIndex = missingProducts.indexOf(product);
        if (missingProductIndex >= 0) {
          missingProducts.splice(missingProductIndex, 1);
        }
      }
    }

    return {
      autoCharges,
      manualCharges,
      missingProducts,
    };
  }

  private filterRemovedProducts(
    ruleId: string,
    products: ProductMetadataValue[],
    productsRemoved: RemovedProductValue[]
  ) {
    return [ ...products.filter((p) => {
      // console.log(productsRemoved, p);
      const removed = productsRemoved.find((rm) =>
        rm.ruleId === ruleId &&
        rm.autoInfo === JSON.stringify(p),
      );
      return !removed;
    }) ];
  }

}
