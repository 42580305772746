<div *ngIf="!loading; else skeleton" class="dashboard-number" [class.large]="large">
  <a *ngIf="params; else text"
      [routerLink]="['/reports', 'dynamic', 'new']"
      [queryParams]="params"
      target="_blank"
      pTooltip="View Report"
      tooltipPosition="bottom"
      >
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </a>
</div>
<ng-template #skeleton>
  <p-skeleton [width]="skeletonWidth" [height]="large ? '2rem' : '1.6rem'"></p-skeleton>
</ng-template>

<ng-template #text>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'currency'">{{ amount | abbreviateCurrency : currency : abbreviate }}</ng-container>
    <ng-container *ngSwitchCase="'percent'">{{ amount | number : '1.2-2' }}%</ng-container>
    <ng-container *ngSwitchCase="'distance'">{{ amount | distance : units }}</ng-container>
    <ng-container *ngSwitchCase="'duration'">{{ amount | secondsToDuration }}</ng-container>
  </ng-container>
</ng-template>
