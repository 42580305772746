import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { CANADA, countries, regex, USA } from '../../global.constants';
import { Address, GoogleHelperService } from '../../services/google-helper.service';

export type AddLocationFormType = 'start' | 'end' | 'dock' | 'additional';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {

  @Output() didHide = new EventEmitter<boolean>();
  @Output() locationInputBlur = new EventEmitter<AddLocationFormType>();
  @Output() locationAdded = new EventEmitter<UntypedFormGroup['value']>();

  @Input() formType: AddLocationFormType;

  visible = false;
  countries = countries;

  manualLocationForm: UntypedFormGroup = new UntypedFormGroup({
    streetNumber: new UntypedFormControl('', [Validators.required]),
    streetName: new UntypedFormControl('', [Validators.required]),
    areaCode: new UntypedFormControl(undefined, [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl(undefined, [Validators.required]),
    jurisdiction: new UntypedFormControl(undefined, [Validators.required]),
  });

  constructor(
    private googleHelper: GoogleHelperService
  ) { }

  ngOnInit(): void {
  }

  open(address?: Address) {
    this.primeForm(address);
    this.visible = true;
  }

  primeForm(address?: Address) {
    if (!address) {
      this.manualLocationForm.reset();
      return;
    }

    const formattedAddress = this.googleHelper.convertGoogleLocationToCreateLocationInput(address as Address);

    this.manualLocationForm.reset({
      streetName: this.googleHelper.findAddressComponent(address, 'route'),
      streetNumber: this.googleHelper.findAddressComponent(address, 'street_number'),
      areaCode: formattedAddress.areaCode,
      country: formattedAddress.country,
      jurisdiction: formattedAddress.countryJurisdiction,
      city: formattedAddress.city
    });
    this.changeRegexValidator();
    this.manualLocationForm.markAllAsTouched();
  }

  changeRegexValidator() {
    if (this.manualLocationForm.value.country === CANADA) {
      this.manualLocationForm.controls.areaCode.setValidators([Validators.required, Validators.pattern(regex.areaCodeCanada)]);
    } else if (this.manualLocationForm.value.country === USA) {
      this.manualLocationForm.controls.areaCode.setValidators([Validators.required, Validators.pattern(regex.areaCodeUSA)]);
    }

    this.manualLocationForm.controls.areaCode.setValue(this.manualLocationForm.value.areaCode);
  }

  onLocationInputBlur() {
    this.locationInputBlur.next(this.formType);
    this.close();
  }

  addLocation() {
    this.locationAdded.next(this.manualLocationForm.value);
  }

  close() {
    this.visible = false;
  }
}
