<p-breadcrumb
  [home]="home"
  [model]="breadcrumb"
  styleClass="p-mb-3"
  >
</p-breadcrumb>

<div
    #header
    class="header card"
    *ngIf="loading || found"
>
    <div class="titlebar">
        <h4
            *ngIf="report; else loadingBarBig"
            (click)="select()"
        >
            {{ report.name }}
        </h4>
        <app-report-actions
            *ngIf="report"
            [report]="report"
            [disabled]="'view'"
        ></app-report-actions>
    </div>

    <div *ngIf="report; else loadingBarBig">
        <div class="p-grid">
            <div class="p-col-6">
                <label class="p-mr-2">Generated with data from:</label>
                <app-zone-chip
                    *ngFor="let zone of report.zones?.nodes"
                    [zone]="zone"
                ></app-zone-chip>
            </div>
            <div class="p-col-6">
                <p>Report type:
                    <a
                        [routerLink]="[ '/reports/type', report?.reportType?.id ]"
                    >
                        {{ report?.reportType?.name }}
                    </a>
                </p>
            </div>
            <div class="p-col-12" *ngIf="report?.reportType?.description">
                <div
                    [innerHTML]="report.reportType.description | markdown | async"
                    (click)="markdownHelper.onMarkdownClick($event)"
                >
                </div>
            </div>
        </div>
    </div>
    <!-- Remove now that we have an actions button
    as we haven't settled on how we are going to use quick buttons -->
    <!-- <div class="button-container">
        <button
            pButton
            pRipple
            pTooltip="Download CSV"
            icon="pi pi-download"
            (click)="downloadCSV()"
            class="p-button-rounded p-button-secondary"
        ></button>
        <button
            pButton
            pRipple
            pTooltip="Re-run"
            icon="pi pi-refresh"
            (click)="rerun()"
            class="p-button-rounded p-button-secondary"
        ></button>
    </div> -->
</div>

<p-tabView
    [(activeIndex)]="activeTabIndex"
    (onChange)="updateUrl($event.index)"
>
    <p-tabPanel header="Data">

        <div class="card filter-card">
            <div class="p-grid" >
                    <div class="p-col-12 p-md-6">
                        <label class="p-mr-1">Toggle Columns</label>
                        <br />
                        <p-multiSelect
                            *ngIf="report && dataHeaders && dataRows && !loadingData;"
                            [options]="dataHeaders"
                            [ngModel]="selectedColumns"
                            (onChange)="updateTable($event.value)"
                            optionLabel="header"
                            selectedItemsLabel="{0} columns selected"
                            [style]="{minWidth: '200px'}"
                            placeholder="Choose Columns"
                            appendTo="body"
                        ></p-multiSelect>
                        <div
                            class="p-mt-2"
                            *ngIf="dataHeaders?.length && !selectedColumns?.length"
                        >
                            <p-message
                                [style]="{ width: '100%'}"
                                severity="warn"
                                text="Select a column to view report data"
                            ></p-message>  
                        </div>
                        <p-skeleton
                            *ngIf="!report || loadingData"
                            width="15rem"
                            height="2rem"
                        ></p-skeleton>
                    </div>
                    <div class="p-col-12 p-md-6 p-d-flex p-ai-center">
                        <div class="field-checkbox" *ngIf="report && dataHeaders && dataRows && !loadingData;">
                            <p-checkbox [binary]="true" [(ngModel)]="showFooter" inputId="showFooter"></p-checkbox>
                            <label for="showFooter">
                                Show data summary at bottom of table
                            </label>
                        </div>
                        <p-skeleton
                            *ngIf="!report || loadingData"
                            width="20rem"
                            height="2rem"
                        ></p-skeleton>
                    </div>
                </div>
        </div>

        <div
            #data
            class="data-table card"
        >
            <div *ngIf="!report || loadingData">
                <p-skeleton
                    width="100%"
                    height="50vh"
                ></p-skeleton>
            </div>

            <div *ngIf="report && !loadingData && (!report?.saveData || !dataRows || !dataHeaders)">
                <p class="nodata info">
                    No report data - the report data was not saved.
                </p>
            </div>

            <ng-container *ngIf="report && dataHeaders && dataRows && !loadingData;">
                <p-table [value]="dataRows"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th
                                *ngFor="let col of selectedColumns"
                            >
                                {{ col.header }}
                                <!-- <p-sortIcon
                                [pSortableColumn]="col"
                                    [field]="col"
                                    *ngIf="col"
                                ></p-sortIcon> -->
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-row>
                        <tr>
                            <td
                                *ngFor="let col of selectedColumns"
                                [attr.header]="col"
                            >
                                <span class="p-column-title">
                                    {{col.header}}
                                </span>
                                <app-report-data
                                    [content]="row[col.index]"
                                    [column]="col.header"
                                    [csvColumnInfo]="reportData?.csv?.columns[col.index]"
                                    [row]="row"
                                ></app-report-data>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr [class.hide]="!showFooter" *ngFor="let footer of selectedFooters">
                            <td
                                *ngIf="footerLabelColspan > 0"
                                class="footer-label"
                                [attr.colspan]="footerLabelColspan"
                            >
                                {{ footer.label }}
                            </td>
                            <td *ngFor="let col of footer.data">
                                {{ col }}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>

        </div>
    </p-tabPanel>
    <p-tabPanel header="Summary">
        <div
            #aggregationsloading
            class="p-grid"
            *ngIf="loading"
        >
            <div
                class="p-col-6 p-md-4"
                *ngFor="let item of [].constructor(6); let i = index"
            >
                <p-skeleton
                    height="8rem"
                ></p-skeleton>
            </div>
        </div>

        <div
            #aggregations
            class="p-grid"
            *ngIf="!loading"
        >
            <div
                class="p-col-6 p-md-4"
                *ngFor="let ag of report?.aggregations"
            >
                <div class="card">
                    <h5 class="title">{{ ag.title }}</h5>
                    <app-report-aggregation-value
                        [ag]="ag"
                    ></app-report-aggregation-value>
                </div>
            </div>
        </div>
    </p-tabPanel>
</p-tabView>


<ng-template #loadingBar>
    <div class="p-p-2 loading">
        <p-skeleton
            width="100%"
            height="1rem"
        ></p-skeleton>
    </div>
</ng-template>

<ng-template #loadingBarBig>
    <div class="p-p-2 loading">
        <p-skeleton
            width="100%"
            height="2rem"
        ></p-skeleton>
    </div>
</ng-template>
<ng-template #loadingBarMassive>
    <div class="p-p-2 loading">
        <p-skeleton
            width="100%"
            height="50vh"
        ></p-skeleton>
    </div>
</ng-template>


<app-object-not-found
    *ngIf="!loading && !found"
    title="404"
    subtitle="Report not found - Are you in the correct zone?"
    goBackText="Back to reports"
    [goBackLink]="[ '/reports' ]"
></app-object-not-found>
