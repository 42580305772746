import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ListAuthMethodsGQL } from 'graphql.generated';
import { passwords } from 'src/app/global.constants';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

interface PasswordValidation {
  p1Touched?: boolean;
  p1Length?: boolean;

  p2Touched?: boolean;
  p2Length?: boolean;
  p2Match?: boolean;
}

@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss']
})
export class PasswordConfirmComponent implements OnInit, OnDestroy {

  @Output() passwordValid = new EventEmitter();
  // Check passwords are match and valid.
  @Output() isValid = new EventEmitter();

  subs = new SubSink();

  inputValid = false;
  minPasswordLength = environment.passwordMinLength;

  passwordForm = new UntypedFormGroup({
    password1: new UntypedFormControl('', [
      Validators.required, Validators.pattern(passwords.regex),
      Validators.minLength(this.minPasswordLength)]),
    password2: new UntypedFormControl('', [Validators.required, Validators.pattern(passwords.regex), Validators.required]),
  });

  mediumRegex = `^(?=.*[a-zA-Z0-9!@#$%^&*?]).{10,}$`;
  strongRegex = '^(?=.*[a-zA-Z0-9!@#$%^&*?]).{12,}$';

  constructor(
    public listAuthMethods: ListAuthMethodsGQL,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.passwordForm.valueChanges.subscribe((res) => {
      if ((this.passwordForm.controls.password1.value === this.passwordForm.controls.password2.value)
        && (this.passwordForm.controls.password1.valid || this.passwordForm.controls.password2.valid)
      ) {
        this.passwordValid.emit(this.passwordForm.controls.password1.value);
        this.isValid.emit(true);
      }else{
        this.isValid.emit(false);
      }
    });
    this.checkAuthPasswordLength();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  checkAuthPasswordLength() {
    this.listAuthMethods.fetch({}, {
      fetchPolicy: 'cache-first',
    }).subscribe((res) => {
      const minAuthPasswordLength = res.data?.authMethods[0]?.password_minLength;
      if(minAuthPasswordLength && minAuthPasswordLength >this.minPasswordLength){
        this.minPasswordLength = minAuthPasswordLength;
        this.passwordForm.controls.password1.addValidators(Validators.minLength(this.minPasswordLength));
      }
    });
  }

}
