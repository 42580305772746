import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {dayjs} from '@karve.it/core';
import { capitalize } from 'lodash';

type RangeValidatorOptions = {
    optionalMax?: boolean;
    dataType?: 'number' | 'dateString';
};

export function getGreaterThanValidator(
    minCtrlName: string,
    maxCtrlName: string,
    opts?: RangeValidatorOptions,
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        let min: number | string | null = control.get(minCtrlName).value;
        let max: number | string | null = control.get(maxCtrlName).value;

        if (min === null && max === null) {
          return null;
        }

        if (typeof min === 'string' || typeof max === 'string') {

            if (opts?.dataType !== 'dateString') {
                throw new Error('not implemented');
            }

            min = dayjs(min).unix();

            max = dayjs(max).unix();
        }

        if (opts?.optionalMax) {
            if (isGreaterThan(min, max)) { return null; }
            return { greaterThan: getGreaterThanErrorMessage(minCtrlName, maxCtrlName, opts?.dataType) };
        }

        return isGreaterThan(min, max) ? null : { greaterThan: getGreaterThanErrorMessage(minCtrlName, maxCtrlName, opts?.dataType) };
    };
}

function getGreaterThanErrorMessage(minCtrlName: string, maxCtrlName: string, dataType?: RangeValidatorOptions['dataType']) {

    const relation = dataType === 'dateString' ? 'come after' : 'be greater than';

    return `${capitalize(maxCtrlName)} must ${relation} ${minCtrlName}`;
}

/**
 * Checks that if a provided control has a value, then a second control must have a value too.
 *
 * Read as: `requiredCtrlName` is required if `providedCtrlName` has a value.
 *
 */
export function getConditionallyRequiredValidator(
    providedCtrlName: string,
    requiredCtrlName: string,
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const provided: any | null = control.get(providedCtrlName).value;
        const required: any | null = control.get(requiredCtrlName).value;

        if (provided !== null && required === null) {
          return { conditionallyRequired: { providedCtrlName, requiredCtrlName } };
        }

        return null;
    };
}

function isGreaterThan(min: number | undefined, max: number | undefined) {
    min = min || 0;
    max = max || 0;
    return min < max;
}

