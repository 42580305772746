import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { baseEnvironment } from 'src/environments/environment.base';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss', '../payment.styles.scss']
})
export class PaymentRedirectComponent implements OnInit, OnDestroy {

  // Stripe Variables
  stripe: Stripe;
  clientSecret: string;
  transactionId: string;

  // Display Modifiers
  paymentStatus = 'loading';
  paymentMessage: string;

  subs = new SubSink();

  constructor(
    private localNotify: FreyaNotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.route.queryParams.subscribe((map) => {
      this.clientSecret = map.secret;
      this.transactionId = map.tx;
      this.checkStatus();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // Fetches the payment intent status after payment submission
  async checkStatus() {

    if (!this.stripe) {
      this.stripe = await loadStripe(baseEnvironment.stripeKey);
    }

    const response = await this.stripe.retrievePaymentIntent(this.clientSecret);

    this.paymentStatus = response.paymentIntent.status;

    switch (response.paymentIntent.status) {
      case 'succeeded':
        // this.localNotify.addToast.next({ severity: 'success', summary: 'Payment Succeded' });
        this.paymentMessage = 'Thank you for your payment.';
        break;
      case 'processing':
        this.localNotify.addToast.next({ severity: 'warn', summary: 'Payment processing' });
        setTimeout(() => {
          this.checkStatus();
        }, 5000);
        break;
      case 'requires_payment_method':
        this.localNotify.addToast.next({ severity: 'error', summary: 'Payment failed, please try again' });
        break;
      default:
        this.paymentStatus = 'error';
        this.localNotify.addToast.next({ severity: 'error', summary: 'Something went wrong' });
        break;
    }
  }

  viewReciept() { }

  downloadReciept() { }

  returnToPaymentPage() {
    this.router.navigate(['payment', this.transactionId]);
  }
}
