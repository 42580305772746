import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef, Component, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit, Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BulkEditCalendarEventInput } from '@karve.it/interfaces/calendarEvents';
import { QueryRef } from 'apollo-angular';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SlideMenu } from 'primeng/slidemenu';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { EventTypeInfo, eventTypeInfoMap, JOB_STAGES, JobEventStatus, MAX_32_BIT_INT } from 'src/app/global.constants';
import { HistoryService } from 'src/app/history/history.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { EventHelperService } from 'src/app/services/event-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { FreyaDatePipe } from 'src/app/shared/freya-date.pipe';
import { ShareEventZoneComponent } from 'src/app/shared/share-event-zone/share-event-zone.component';
import { setOrderToIndex } from 'src/app/utilities/order.util';
import { BulkEditCalendarEventGQL, Charge, CreateCalendarEventGQL, EstimatesJobFragment, ListProductsForEstimatingGQL, ListProductsForEstimatingQuery, ListProductsForEstimatingQueryVariables, Product, SingleEditInput, UpdateChargesGQL, ZoneDir } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { isFinalizedInvoice } from '../../invoices/invoices.utils';



import { arrRemoveValue, safeParseJSON } from '../../js';
import { ChargeHelperService } from '../../services/charge-helper.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { ProductHelperService } from '../../services/product-helper.service';
import { METADATA_JOB_REMOVED_CHARGES, ProductRulesService, RemovedProductValue } from '../../services/product-rules.service';
import { EventWithCharges } from '../estimate-confirmation/estimate-confirmation.component';

import { EstimateDiscountsComponent } from '../estimate-discounts/estimate-discounts.component';
import { ProductWithQuantity } from '../estimate-products/estimate-products.component';

import { EstimateUpdated, EstimateUpdatedEvent, EstimatingSaveInfo } from '../estimate.interfaces';

export enum EventReordered {
  up = 'up',
  down = 'down',
  to_top = 'to_top',
  to_bottom = 'to_bottom',
}

export interface UpdateAutoParams {
  overrideRequiredEvents?: string[];
  forceUpdateIfDisabled?: boolean;
}

export interface EventOrder {
  id: string;
  sequentialOrder: number;
}

export type ChargeWithKey = Partial<Charge> & { key?: string };

const deletableEventStatuses: JobEventStatus[] = [
  'required',
  'cancelled',
];

@Component({
  selector: 'app-estimate-breakdown',
  templateUrl: './estimate-breakdown.component.html',
  styleUrls: ['./estimate-breakdown.component.scss']
})
export class EstimateBreakdownComponent implements OnInit, OnDestroy, EstimateUpdated, OnChanges {

  @ViewChild('chargeActionsMenu') chargeActionsMenu: SlideMenu;
  @ViewChildren('addDiscountsChild') addDiscountsChildren: QueryList<EstimateDiscountsComponent>;

  @Output() breakdownInfo = new EventEmitter();
  @Output() estimateUpdated = new EventEmitter<EstimateUpdatedEvent>();
  @Output() requiredEventCreated = new EventEmitter<EventTypeInfo>();
  @Output() customChargeCreated = new EventEmitter<void>();
  @Output() chargesUpdated = new EventEmitter<void>();

  @Input() job: EstimatesJobFragment;

  subs = new SubSink();

  activeCharges: Partial<Charge>[] = [];
  eventsWithCharges: EventWithCharges[] = [];
  eventsBeforeReordering: EventOrder[] = [];

  // The events types that have been added already
  existingEventIds: string[] = [];

  // The events that can have products into them (i.e., are not disabled)
  validEventIds: string[] = [];

  // Variables the hold the state of the Estimate Breakdown
  //collapsedEventIds = {};
  lastModifiedEvent: { eventId: string; collapsed: boolean };

  //Variables to keep track of collapsed elements on the page
  isInitialCollapseState = true;
  isAllEventsCollapsed = false;
  collapsedEventFinancialsIds: Set<string> = new Set<string>();
  collapsedJobFinancialsIds: Set<string> = new Set<string>();
  collapsedEventAssetsIds: Set<string> = new Set<string>();
  collapsedEventsIds: Set<string> = new Set<string>();

  // Variables to keep track of changes
  addedCharges: Partial<Charge>[] = [];
  duplicatedCharges: Partial<Charge>[] = [];
  removedCharges: Partial<Charge>[] = [];
  modifiedCharges: Partial<Charge>[] = [];
  isEventsOrderModified = false;
  reorderedEvents: EventWithCharges[] = [];
  isRequiredEventsModified = false;
  /**
   * To keep track of unsaved charges in any event and
   * save job before adding custom charge or discount
   * as these actions trigger page reloading and state loosing
   */
  jobContainsUnsavedCharges = false;

  // Products
  availableProducts: Product[] = [];
  usedProducts: Product[];

  // Product Adding

  jobMetadataUpdated = false;

  $updateAuto = new Subject<UpdateAutoParams>();

  // Drag and drop functionality
  dragDelay = 100;
  draggingOverBreakdown = false;

  eventActions: MenuItem[] = [];
  chargeActions: MenuItem[] = [];

  // Variables to keep track of whether a new job is loading
  jobLoading: boolean;
  fetchingNewJob: boolean;
  loadingNewJob = true;

  // Variables to keep track of whether products are loading
  productsLoading = true;

  lockedEvents: { [eventId: string]: boolean } = {};

  formattedLockDate?: string;

  jobZoneSet$ = new Subject<string>();

  disabled = false;

  /**
   * Charge amounts converted to dollars so they can be bound to the appropriate UI components.
   */
  editableAmounts: Record<string, number> = {};

  //set width of charges menu dynamically to avoid issues with items width on hover
  menuWidth: number = 0;

  //adjust visible length os charge name based on screen width, used in sliceText pipe
  chargeNameLength: number = 10;

  defaultContactAccountingButtonText = 'Contact Accounting';

  contactAccountingButtonText = this.defaultContactAccountingButtonText;

  defaultEventLockedWarning = `This event takes place before the lock date ${this.formattedLockDate} so it cannot be modified.
  Please create a new event if you need to charge the customer additional charges.`;

  eventLockedWarning = this.defaultEventLockedWarning;

  _key = 0;

  constructor(
    private freyaDatePipe: FreyaDatePipe,
    public estimateHelper: EstimateHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private history: HistoryService,
    private dialogService: DialogService,
    private eventHelperSvc: EventHelperService,
    private createEventGQL: CreateCalendarEventGQL,
    public productRuleService: ProductRulesService,
    private detailsHelper: DetailsHelperService,
    private chargeHelperService: ChargeHelperService,
    private confirmationService: ConfirmationService,
    private productHelper: ProductHelperService,
    private documentHelper: DocumentHelperService,
    private listProductsForEstimatingGQL: ListProductsForEstimatingGQL,
    public responsiveHelper: ResponsiveHelperService,
    private updateChargesGQL: UpdateChargesGQL,
    private bulkEditCalendarEventsGQL: BulkEditCalendarEventGQL,
    private freyaMutate: FreyaMutateService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.usedProducts = [];
    this.activeCharges = [];
    this.jobMetadataUpdated = false;
    this.resetLastModifiedEventType();

    this.watchNewJob();

    this.watchProducts();

    this.setScreenBasedDimensions();

    this.subs.sink = this.$updateAuto
      .pipe(debounceTime(100))
      .subscribe((res) => {
        this.updateProductRules(res);
      });

    this.subs.sink = this.estimateHelper.permissionsAndJobLoading
      .pipe(map((res) => res.jobLoading || !res.updateJob))
      .subscribe((disabled) => {
        this.disabled = disabled;
        this.cd.detectChanges();
      });

    this.freyaHelper.lockDateSupportInfo$.subscribe((info) => {
      this.contactAccountingButtonText = info?.contactAccountingButtonText || this.defaultContactAccountingButtonText;
      this.eventLockedWarning = info?.eventLockedWarning || this.defaultEventLockedWarning;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setEventIds();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setScreenBasedDimensions() {
    //set visible length of charge name
    //always keep it short for resolutions where right side panel
    //might cause horizontal scroll
    if(window.innerWidth >= 992 && window.innerWidth <= 1200) {
      this.chargeNameLength = 5;
    } else if (window.innerWidth <= 700) {
      this.chargeNameLength = window.innerWidth / 25;
    } else {
      this.chargeNameLength = window.innerWidth / 50;
    }

    //set width for slideMenu
    if (window.innerWidth >= 992) {
      this.menuWidth = 170;
    } else {
      this.menuWidth = 150;
    }
  }

  openDiscountsModal(addDiscountsChild: EstimateDiscountsComponent) {
    addDiscountsChild.openApplyDiscountModal();
  }

  handleChargesAdded(eventData: any) {
    const { eventId, addedCharges } = eventData;

    if (addedCharges.length) {
      const targetEvent = this.eventsWithCharges.find(event => event.id === eventId);
      const indexOfTargetEvent = this.eventsWithCharges.indexOf(targetEvent);

      //When we add charges from modal we can add it only to the last position
      //in the list. So to determine current index we use length of charges of target event
      const currentIndex = this.eventsWithCharges[indexOfTargetEvent].charges.length;

      const newCharges: ChargeWithKey[] = addedCharges.map((item: ProductWithQuantity) => {
        const newCharge: ChargeWithKey = {
          product: { ...item.product },
          quantity: item.quantity,
          attributes: [],
          calendarEvent: targetEvent.event as any,
          key: this.key,
        };
        return newCharge;
      });

      newCharges.forEach((newCharge) => {
        this.addCharge(newCharge);
        this.setEventChargesOrder(targetEvent, newCharge as Charge, currentIndex);
        this.updateEventPercentBasedCharges(targetEvent, newCharge);
      });
    }

    this.chargesUpdated.emit();
  }

  /**
   * Sets the active charges based on the job charges. Called by parent.
   *
   * @param activeJob The job whose charges we want to use.
   */
  setCharges(activeJob: EstimatesJobFragment) {

    this.job = activeJob;
    if (this.job) {

      // Conversion required because job.charges is typed using interfaces package
      this.activeCharges = this.job.charges as unknown as Charge[]; // this.job.charges.filter((c) => c?.product?.id || c?.amount > 0);
    } else {
      this.activeCharges = [];
    }

    this.setEditableAmounts();

    this.setEventIds();

    this.setEventsWithCharges();

    //If event has charges, show expanded Event Financials panel for it
    //use isInitialCollapseState to track that it's done only once and avoid
    //losing collapsed state each time when we call setCharges
    if (this.isInitialCollapseState) {
      this.eventsWithCharges.forEach((event: EventWithCharges) => {
        if (event?.charges && event?.charges?.length > 0) {
            this.collapsedEventFinancialsIds.add(event.id);
        }
      });
      this.isInitialCollapseState = false;
    }
  }

  fillReorderedEvents() {
    const reorderedEvents = [];
    for (let index = 0; index < this.eventsWithCharges.length; index++) {
        const item = this.eventsWithCharges[index];
        item.event.sequentialOrder = index + 1;
        reorderedEvents.push(item);
    }

    return reorderedEvents;
}

  disableModifying(event: EventWithCharges) {
    let disabledWarning = '';
    let disabledToolTip = '';
    let chargesDisabled = false;
    let discountsDisabled = false;
    let clickable = false;

    if (this.lockedEvents[event?.event.id]) {
      chargesDisabled = true;
      discountsDisabled = true;
      disabledToolTip = this.eventLockedWarning;
      disabledWarning = disabledToolTip;
    }

    if (this. job?.zone?.type !== 'area') {
      chargesDisabled = true;
      discountsDisabled = true;
      disabledToolTip = `Your job needs to be in an area to apply charges.`;
      disabledWarning = disabledToolTip;
    }

    if(event.unsavedChanges) {
      this.jobContainsUnsavedCharges = true;
      discountsDisabled = true;
      clickable = true;
      disabledToolTip = `Save job to add charges`;
      disabledWarning = `Financials will update when the job has been saved. Click here to save job and update financials.`
    }

    if(this.isEventsOrderModified) {
      chargesDisabled = true;
      discountsDisabled = true;
      clickable = true;
      disabledToolTip = `Save updated events order to proceed with adding new charges.`;
      disabledWarning = disabledToolTip;
    }

    if(event?.event?.invoices?.some(isFinalizedInvoice)) {
      chargesDisabled = true;
      discountsDisabled = true;
      disabledToolTip = `This event has been invoiced and cannot be modified.
      Please void the invoice or create a new event if you need to charge
      the customer additional charges.`
      disabledWarning = disabledToolTip;
    }

    return {
      chargesDisabled,
      discountsDisabled,
      disabledToolTip,
      disabledWarning,
      clickable
    };
  }

  handleChargeDuplicated(charge: Charge) {
    this.addChargeWithResolvedPrice(charge);
    this.chargesUpdated.emit();
  }

  //need to pass currentEvent to ensure correct binding
  handleChargeCopied(currentEvent: EventWithCharges, targetEvent: EventWithCharges, charge: Charge) {

    charge.calendarEvent = targetEvent.event as any;

    this.addChargeWithResolvedPrice(charge);
    this.chargesUpdated.emit();
  }

  handleChargeMoved(currentEvent: EventWithCharges, targetEvent: EventWithCharges, charge: Charge) {
    this.setUnsavedChanges(targetEvent.event.id);
    this.setUnsavedChanges(currentEvent.event.id);

    charge.calendarEvent = targetEvent.event as any;

    //Update percent-based charges in both source and target containers
    this.updateEventPercentBasedCharges(currentEvent, undefined, charge);
    this.updateEventPercentBasedCharges(targetEvent, charge);

    this.setEventChargesOrder(targetEvent, charge, targetEvent?.charges?.length);
    this.chargeModified(charge);

    if (this.eventsWithCharges.some(e => e.unsavedChanges)) {
      this.chargesUpdated.emit();
    }
  }

  duplicateEventAsRequired(originalEvent: EventWithCharges) {
    //Pass basic information from frontend to create required event, no matter which status origin had
    this.createEventGQL.mutate({
      calendarEvents: [{
        title: originalEvent?.event?.title,
        type: originalEvent?.event?.type,
        duplicateFromOriginId: originalEvent?.id,
        jobId: this.job.id,
        status: 'required',
      }]
    }).subscribe({
      next: (res) => {
        this.localNotify.success(`${originalEvent?.event?.title} event duplicated`);
        const [event] = res.data.createCalendarEvent.events;
        this.job.events.push(event);
        this.detailsHelper.pushUpdate({
          action: 'create',
          type: 'Events',
          id: this.job.id,
        });
      },
      error: (err) => {
        console.error(err);
        this.localNotify.error(`Failed to duplicate event ${originalEvent?.event?.title}`, err.message);
      },
    });
  }

  setChargeActions(currentEvent: EventWithCharges, charge: Charge) {

    const generateSubmenuItems = (command: (currentEvent: EventWithCharges, event: EventWithCharges, charge: Charge) => void) => {
      return this.eventsWithCharges
          .filter(event => event.event.id !== currentEvent.event.id)
          .map(event => ({
              label: `${event.event.title}
                  ${event.event.start
                    ? this.freyaDatePipe.transform(event.event.start, 'MMM d, h:mm a')
                    : 'Unscheduled'}`,
              disabled: this.disableModifying(event).chargesDisabled,
              command: () => command(currentEvent, event, charge)
          }));
    };

    const moveToEventItems = generateSubmenuItems(this.handleChargeMoved.bind(this));
    const copyToEventItems = generateSubmenuItems(this.handleChargeCopied.bind(this));

    this.chargeActions = [
      {
        label: 'Remove',
        icon: 'pi pi-trash',
        command: () => this.handleRemoveCharge(currentEvent, charge),
      },
      {
        label: 'Duplicate',
        icon: 'pi pi-clone',
        command: () => this.handleChargeDuplicated(charge),
      },
      {
        label: 'Move to event',
        icon: 'pi pi-file-export',
        disabled: this.eventsWithCharges.length === 1,
        items: moveToEventItems,
      },
      {
        label: 'Copy to event',
        icon: 'pi pi-file-export',
        disabled: this.eventsWithCharges.length === 1,
        items: copyToEventItems,
      }
    ];
  }

  setEventActions(event: EventWithCharges, index: number) {

    this.eventActions = [
      {
        label: 'Move To Top',
        icon: 'pi pi-arrow-circle-up',
        visible: index > 1,
        command: () => this.onMove(event, EventReordered.to_top),
      },
      {
        label: 'Move Up',
        icon: 'pi pi-arrow-up',
        visible: event.event.id !==
          this.eventsWithCharges[0]?.id,
        command: () => this.onMove(event, EventReordered.up),
      },
      {
        label: 'Move Down',
        icon: 'pi pi-arrow-down',
        visible: event.event.id !==
          this.eventsWithCharges[this.eventsWithCharges.length - 1]?.id,
        command: () => this.onMove(event, EventReordered.down),
      },
      {
        label: 'Move To Bottom',
        icon: 'pi pi-arrow-circle-down',
        visible: (this.eventsWithCharges.length >= 3
          && index < this.eventsWithCharges.length - 2),
        command: () => this.onMove(event, EventReordered.to_bottom),
      },
      {
        label: 'Duplicate',
        icon: 'pi pi-clone',
        command: () => this.duplicateEventAsRequired(event),
        //handle all cases when charges disabled + when event has unsaved charges
        disabled: this.disableModifying(event).discountsDisabled,
      },
      {
        id: 'edit',
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.freyaMutate.openMutateObject({
            mutateType: 'update',
            objectType: 'calendarEvent',
            object: event?.event,
          });
        },
        //handle all cases when charges disabled + when event has unsaved charges
        disabled: this.disableModifying(event).discountsDisabled,
      },
      {
        id: 'cancel',
        label: 'Cancel',
        icon: 'pi pi-ban',
        command: () => {
          this.eventHelperSvc.unscheduleEvent(event?.event);
        },
        disabled: this.disableModifying(event).chargesDisabled,
        visible: !deletableEventStatuses.includes(event?.event?.status as JobEventStatus),
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.freyaMutate.openDeleteObject({
            objectId: event?.event?.id,
            objectName: event?.event?.title,
            objectType: 'calendarEvent',
          });
        },
        disabled: this.disableModifying(event).chargesDisabled,
        visible: deletableEventStatuses.includes(event?.event?.status as JobEventStatus),
      },
      {
        label: 'View History',
        icon: 'pi pi-book',
        command: () => {
          if (!event?.id) { return; };
          this.history.openHistory('CalendarEvent', [ event?.id ]);
        },
      },
      {
        id: 'share',
        label: 'Share',
        icon: 'pi pi-arrow-up-right',
        command: () => {
          this.dialogService.open(ShareEventZoneComponent, {
            header: 'Share Event',
            data: {
              event: event?.event,
              jobId: this.job?.id,
            },
          });
        },
        disabled: this.disableModifying(event).chargesDisabled,
      }
    ]
  }

  /**
   * Breaks down active charges into event categories,
   * calculates event subtotals,
   * and uses resulting data to populate Estimate Breakdown table,
   * creating one row per selected event type.
   *
   * Note: calling this causes Estimate Breakdown to re-render.
   */
  setEventsWithCharges() {

    if (!this.job) { return; }

    this.eventsWithCharges = this.chargeHelperService.calculateEventsWithCharges(this.job, this.activeCharges as any);

    this.setLockedEvents();

    this.estimateUpdated.emit();
  }

  unsavedWarningClick() {
    this.chargesUpdated.emit();
  }

  handleInputClick(event: any) {
    event.stopPropagation();
    if (typeof event?.target?.select === 'function') {
      event.target.select();
    }
  }

  toggle(collection: Set<string>, id: string) {
    if (collection.has(id)) {
      collection.delete(id);
    } else {
      collection.add(id);
    }
  }

  toggleEvent(id: string) {
    this.toggle(this.collapsedEventsIds, id);
    if (this.collapsedEventsIds.size === this.eventsWithCharges.length) {
      this.isAllEventsCollapsed = true;
    } else {
      this.isAllEventsCollapsed = false;
    }
  }

  toggleAllEvents() {
    if (this.isAllEventsCollapsed) {
      this.collapsedEventsIds.clear();
      this.isAllEventsCollapsed = false;
    } else {
      this.eventsWithCharges.forEach((event: EventWithCharges) => {
        this.collapsedEventsIds.add(event?.id);
      });
      this.isAllEventsCollapsed = true;
    }
  }

  isEventCollapsed(id: string): boolean {
    return this.collapsedEventsIds.has(id);
  }

  isEventFinancialsCollapsed(id: string): boolean {
    return this.collapsedEventFinancialsIds.has(id);
  }

  isJobFinancialsCollapsed(id: string): boolean {
    return this.collapsedJobFinancialsIds.has(id);
  }

  isEventAssetsCollapsed(id: string): boolean {
    return this.collapsedEventAssetsIds.has(id);
  }

  /**
   * Handles the event of dropping an item into the Estimate Breakdown.
   * If the item is a product, adds a corresponding charge to the job.
   * If it is a charge, it modifies the charge's event and eventType property
   * and recalculates subtotals accordingly.
   *
   * @param event The CdkDragDrop event fired when an item is dropped into a CDKDropList.
   */
  drop(event: CdkDragDrop<EventWithCharges>) {
    const {
      container: { data: targetEvent },
      item: { data: item, dropContainer: sourceContainer },
      currentIndex
    } = event;

    if(this.draggingOverBreakdown) {
      this.setUnsavedChanges(targetEvent.event.id);
      this.setUnsavedChanges(sourceContainer.data.event.id);
    }

    this.draggingOverBreakdown = false;
    this.resetLastModifiedEventType();

    // If item comes from the Estimate Breakdown (i.e. item is a charge)
    if (this.existingEventIds.includes(sourceContainer?.id)) {
      const charge: Charge = item;
      const currentEvent: EventWithCharges = sourceContainer.data;

      // If charge comes from the same event
      if (currentEvent.event?.id === targetEvent.event?.id) {

        this.setEventChargesOrder(currentEvent, charge, currentIndex, false);

      } else {
        charge.calendarEvent = targetEvent.event as any;
        // Update percent-based charges in both source and target containers
        this.updateEventPercentBasedCharges(currentEvent, undefined, charge);
        this.updateEventPercentBasedCharges(targetEvent, charge);

        this.setEventChargesOrder(targetEvent, charge, currentIndex);
        this.chargeModified(charge);

      };
    };

    // If target event already had charges in it,
    // mark them as modified as their order may have changed
    if (targetEvent.charges.length) {
      targetEvent.charges
        .forEach((c) => this.chargeModified(c, false, false));
    };

    //As next we call setEventsWithCharges that causes component rerendering,
    //here we need to save job first, to prevent loose of unsaved changes after delete or change charge quantity
    if (this.eventsWithCharges.some(e => e.unsavedChanges)) {
      this.chargesUpdated.emit();
    }

    this.setEventsWithCharges();
  }


  /**
   * Expands and collapses rows of the Estimate Breakdown
   * as the user drags products or charges over it.
   *
   * @param event An event that fires when the user first drags
   * a product or charge into a CDK container.
   */
  handleDragEnter(event) {

    // Update draggingOverBreakdown so CDK knows what placeholder to use
    this.draggingOverBreakdown = event.container !== event.item.dropContainer;

    // Reset last entered row to its previous state before being modified
    if (this.lastModifiedEvent.eventId) {
      const { eventId, collapsed } = this.lastModifiedEvent;
      //this.collapsedEventIds[eventId] = collapsed;
    };

    // Expand entered eventType, keep track of previous state so you can reset
    const enteredEventId = event.container.data?.id;
    this.lastModifiedEvent.eventId = enteredEventId;
    //this.lastModifiedEvent.collapsed = this.collapsedEventIds[enteredEventId];
    //this.collapsedEventIds[enteredEventId] = false;
  }


  /**
   * Updates the subtotal of all charges of percentage price type in a given event,
   * or in the array that results from adding or removing a charge to the event in question
   * if optional parameters are provided. (Does not actually add or remove the charges to the event.)
   *
   * If the provided event has a type (other than 'none'),
   * adds up all charges of fixed price type in that event,
   * and sets the subtotal of each charge of percentage price type
   * to a percentage of the resulting sum.
   *
   * If the provided event is of type 'none',
   * adds up *all active charges* of fixed price type (not just those contained in the event),
   * and sets the subtotal of each charge of percentage price type
   * to a percentage of the resulting sum.
   *
   * @param event The event whose charges you want to update.
   * @param chargeAdded Any charges you want to add to that event.
   * @param chargeRemoved Any charges you want to remove from that event.
   */
  updateEventPercentBasedCharges(
    event: EventWithCharges,
    chargeAdded: Partial<Charge> = undefined,
    chargeRemoved: Partial<Charge> = undefined
  ) {
    const eventCharges = [...event.charges];

    if (chargeAdded) {
      eventCharges.push(chargeAdded as Charge);
    };
    if (chargeRemoved) {
      arrRemoveValue(eventCharges, chargeRemoved);
    };

    // No charges to update...
    if (!eventCharges.length) { return; };

    const fixedTotal = this.getFixedTotal(eventCharges as Charge[]);

    const percentBasedCharges = eventCharges
      .filter((c) => this.chargeHelperService.getPriceType(c) === 'percentage');

    for (const charge of percentBasedCharges) {

      const currentSubtotal = charge.chargeSubTotal;

      const newSubtotal = fixedTotal * ((this.getEditableAmount(charge) * charge.quantity) / 100);

      if (currentSubtotal !== newSubtotal) {
        charge.chargeSubTotal = newSubtotal;
        this.chargeModified(charge as Charge);
      };
    };

    this.estimateUpdated.emit();
  }

  /**
   * Wrapper for `updateEventPercentBasedCharges` that finds
   * the required event by type and then passes it into
   * the method in question.
   *
   * @param eventType The type of the desired event.
   * @param chargeAdded Any charges you want to add to that event.
   * @param chargeRemoved Any charges you want to remove from that event.
   */
  updateEventPercentBasedChargesByType(
    eventId: string,
    chargeAdded: Charge = undefined,
    chargeRemoved: Charge = undefined
  ) {
    if (!eventId) { return; };
    const eventWithCharges = this.eventsWithCharges
      .find((e) => e.id = eventId);
    if (!eventWithCharges) { return; };
    this.updateEventPercentBasedCharges(eventWithCharges, chargeAdded, chargeRemoved);

  }

  // /**
  //  * Updates the subtotal of all charges of percentage price type that do not have an event.
  //  *
  //  */
  // updateOrphanPercentBasedCharges(chargeRemoved: Charge = undefined) {
  //   const orphanCharges = this.eventsWithCharges
  //     .find((e) => e.type === 'none');

  //   if (!orphanCharges) { return; };

  //   this.updateEventPercentBasedCharges(orphanCharges, undefined, chargeRemoved);
  // }

  /**
   * Takes the charges on an eventWithCharges
   * plus a charge that has either been inserted into that eventWithCharges
   * or just moved around within the same eventWithCharges,
   * and sets their order based on the array that results
   * from inserting the new charge into a specified index of the initial array.
   *
   * A charge's order is calculated by adding up the following 4 numbers:
   * 1) An offset of n * 1000, where n is given by the charge's event type.
   * 2) A (possible) offset of m * 100, where m is given by the start or createdAt time
   * of the charge's event (if it has one) relative to all other events of the same type on the job.
   * 3) An additional offset of 50.
   * 4) The charge's index in the array that results from inserting the provided charge into the specified index of the initial array.
   *
   * For instance, if a job has charges A and B corresponding to two different events of type moving,
   * A's event has an earlier start time than B's event, but B is inserted before A,
   * then their respective orders should be:
   * A: 2151
   * B: 2250
   *
   * @param eventWithCharges The eventWithCharges whose charges we want to set their order.
   * @param insertedCharge The charge to insert in the specified index.
   * @param insertedChargeIndex The index for the new charge.
   * @param isInsertedChargeNew Whether the charge comes from the same event or not.
   */
  setEventChargesOrder(
    eventWithCharges: EventWithCharges,
    insertedCharge: Charge,
    insertedChargeIndex: number,
    isInsertedChargeNew = true
  ) {
    // Figure out what charges need to be updated
    const chargesToUpdate = [...eventWithCharges.charges];
    if (!isInsertedChargeNew) {
      arrRemoveValue(chargesToUpdate, insertedCharge);
    };
    chargesToUpdate.splice(insertedChargeIndex, 0, insertedCharge);

    // CALCULATE OFFSET

    let eventTypeOffset: number;

    if (eventTypeInfoMap[eventWithCharges.event.type]) {
      eventTypeOffset = eventTypeInfoMap[eventWithCharges.event.type].order;
    } else {
      eventTypeOffset = (Object.keys(eventTypeInfoMap).length + 1);
    };

    const eventTypeBase = 1000;
    eventTypeOffset *= eventTypeBase;

    let offset = eventTypeOffset;

    if (eventWithCharges.event) {

      // Collect all events of the same type
      // Right now, there can only be one but this will change
      const sameTypeEvents = this.job?.events?.filter((e) => e.type === eventWithCharges.event.type);

      // Sort by start or createdAt
      sameTypeEvents.sort((a, b) => {
        const aValue = a.start || a.createdAt;
        const bValue = b.start || b.createdAt;

        return aValue - bValue;
      });

      const eventBase = 100;
      const event = sameTypeEvents.find((e) => e.id === eventWithCharges.event.id);
      const eventOffset = (sameTypeEvents.indexOf(event) + 1) * eventBase;
      offset += eventOffset;
    };

    const additionalOffset = 50;
    offset += additionalOffset;

    // Set order using offset
    setOrderToIndex(chargesToUpdate, offset);
  }

  /**
   * Uses input data to add a new charge to the active charges
   * as well as to the `addedCharges` property so that
   * the backend creates a new charge on save.
   *
   * @param chargeData The data to use to create a new charge.
   * @param emit Whether to notify the parent component that the estimate has been updated.
   */
  addCharge(chargeData: ChargeWithKey, emit = true) {

    this.setChargePrice(chargeData);

    this.editableAmounts[chargeData.key] = chargeData.price.priceType === 'fixed' ? chargeData.price.amount / 100 : chargeData.price.amount;

    if (!chargeData.price) {
      console.warn(`Could not find price for product`, chargeData);
      return;
    };

    this.addChargeWithResolvedPrice(chargeData);

  }

  //call when active price already was set to charge or does not need to be set
  //e.g. after charge duplicated inside same event or copied to other event
  addChargeWithResolvedPrice(chargeData: ChargeWithKey) {
    this.setChargeSubtotal(chargeData);

    this.addedCharges.push(chargeData);
    this.activeCharges.push(chargeData);
    this.usedProducts.push(chargeData.product);
  }

  /**
   * Sets a charge's price to its first active price.
   *
   * @param charge
   */
  setChargePrice(charge: Partial<Charge>) {
    if (charge.price) { return; };
    charge.price = this.productHelper.getActivePrice(charge.product?.prices, this.job?.zone?.id);
  }

  /**
   * Sets a charge's subtotal depending on its price type.
   * If price type is fixed, subtotal is set to price amount.
   * If price type is percentage and charge has parent event,
   * subtotal is set to percetage of parent event's fixed charges.
   * If price type is percentage but charge has no parent event,
   * subtotal is set to percentage of all fixed active charges.
   *
   * @param charge The charge whose subtotal you want to set.
   */
  setChargeSubtotal(charge: Partial<Charge>) {

    const editableAmount = this.getEditableAmount(charge);

    if (this.chargeHelperService.getPriceType(charge) === 'fixed') {
      charge.chargeSubTotal = editableAmount * charge.quantity;
      return;
    };

    const parentEvent = this.eventsWithCharges
      .find((e) => e.id === charge.calendarEvent.id);

    const chargesUsedForFixedTotal = parentEvent ? parentEvent.charges : this.activeCharges;

    const fixedTotal = this.getFixedTotal(chargesUsedForFixedTotal as Charge[]);

    charge.chargeSubTotal = fixedTotal * editableAmount / 100;

    this.estimateUpdated.emit();
  }

  onMove(event: EventWithCharges, direction: EventReordered) {

    if (!this.eventsWithCharges || !event) {
      this.localNotify.error(`Error ocurred during reordering events`);
      return;
    }

    const indexBeforeUpdate = this.eventsWithCharges.findIndex(item => item.id === event.event.id);

    if (direction === EventReordered.up) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, indexBeforeUpdate - 1);
    }

    if (direction === EventReordered.down) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, indexBeforeUpdate + 1);
    }

    if(direction === EventReordered.to_top) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, 0);
    }

    if(direction === EventReordered.to_bottom) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, this.eventsWithCharges?.length - 1);
    }

    this.isEventsOrderModified = true;
  }

  setUnsavedChanges(id: string) {
    const eventWithUnsavedChanges = this.eventsWithCharges.find(item => item.id === id);
    eventWithUnsavedChanges.unsavedChanges = true;
  }

  /**
   * Updates a charge's subtotal to reflect changes in the charge's quantity.
   * In addition, updates the subtotal of any affected charges of percentage price type
   * to reflect changes in the fixed total.
   *
   * @param event The eventWithCharges that the charge belongs to.
   * @param charge The charge whose quantity has changed.
   */
  handleQuantityChange(event: EventWithCharges, charge: Charge) {
    this.chargeModified(charge as Charge);
    this.safeUpdateFixedChargeSubtotal(charge);
    this.updateEventPercentBasedCharges(event);
    this.updateEventSubtotal(event.event?.id);
    this.setUnsavedChanges(event.id);
  }

  /**
   * Sets a charge's subtotal if the charge is of fixed price type.
   *
   * @param charge The charge whose subtotal you want to set.
   */
  safeUpdateFixedChargeSubtotal(charge: Charge) {
    if (this.chargeHelperService.getPriceType(charge) === 'fixed') {
      charge.chargeSubTotal = this.getEditableAmount(charge) * charge.quantity;
    }
  }

  /**
   * Updates the subtotal of any affected charges of percentage price type
   * to reflect any changes in the fixed total that may result from removing a charge of fixed price type,
   * then removes the charge in question.
   *
   * @param event The event to which the charge to be removed belongs to.
   * @param charge The charge to be removed.
   */
  handleRemoveCharge(event: EventWithCharges, charge: Charge) {

    this.updateEventPercentBasedCharges(event, undefined, charge);
    // if (event.type !== 'none') {
    //   this.updateOrphanPercentBasedCharges(charge);
    // };
    this.removeCharge(charge as Charge);
    this.setUnsavedChanges(event.id);
  }

  /**
   * Removes a charge from active charges and its associated product from the `usedProducts` property.
   * If the charge was stored in the database, makes sure it's deleted,
   * otherwise makes sure it's not saved.
   * In addition, keeps track of whether the charge was removed due to a rule.
   *
   * @param charge The charge to be removed.
   * @param autoTemporary Used to keep track of whether the charge was removed due to a rule.
   * @param emit Whether to notify the parent component that the estimate has been updated.
   */
  removeCharge(charge: Charge, autoTemporary = false) {
    const usedIndex = this.usedProducts.findIndex((p) => p.id === charge?.product?.id);
    const [removedElement] = this.usedProducts.splice(usedIndex, 1);

    // this.availableProducts.push(removedElement);

    const chargeIndex = this.activeCharges.indexOf(charge); // .findIndex((c) => c.product.id === charge.product.id);
    this.activeCharges.splice(chargeIndex, 1);

    // Add rule to job metadata removed charges
    const ruleId = this.productRuleService.getRuleIdFromChargeMode(charge);
    const autoInfo = this.chargeHelperService.getAutoInfoFromCharge(charge);
    if (ruleId && autoInfo && !autoTemporary) {
      const ruleBasedRemoveCharges = this.getRemovedRuleBasedCharges();
      ruleBasedRemoveCharges.push({
        autoInfo,
        ruleId,
      });
      this.updateJobRemovedChargesMetadata(ruleBasedRemoveCharges);
    }

    // If the charge exists mark it to be removed, otherewise remove it from addedCharges
    if (charge.id) {
      this.removedCharges.push(charge);
    } else {
      const index = this.addedCharges.findIndex((ac) => ac.product.id === charge.product.id);
      this.addedCharges.splice(index, 1);
    }

    this.setEventsWithCharges();
  }

  updateEventSubtotal(eventId: string) {
    const eventWithCharges = this.eventsWithCharges
      .find((e) => e.event?.id === eventId);
    if (!eventWithCharges) { return; };
    eventWithCharges.subtotal = this.chargeHelperService
      .addChargeSubtotals(eventWithCharges.charges);

    this.estimateUpdated.emit();
  }

  /**
   * Initializes and refreshes a products watch query any time the job's zone is set or changes.
   */
  watchProducts() {

    const firstLimit = 200;
    const moreLimit = 150;

    const listProductQueryVariables: ListProductsForEstimatingQueryVariables = {
      limit: firstLimit,
      skip: 0,
      filter: {
        hasActivePrice: true,
        zoneDir: ZoneDir.Gte,
      },
      sort: 'name:ASC',
      pricesFilter: {
        isActive: true,
      }
    };

    let fetchingMore = false;

    let activeQuery: QueryRef<ListProductsForEstimatingQuery, ListProductsForEstimatingQueryVariables>;

    this.subs.sink = this.jobZoneSet$.pipe(
      // Do not initialize or refresh query if zoneId failed to be set
      filter((zoneId) => zoneId !== undefined),
      // Do not refresh query if zone didn't really change
      distinctUntilChanged(),
      // Use latest zone id to set fresh watch query options
      map((zoneId) => ({
        fetchPolicy: 'cache-and-network' as const,
        context: {
          headers: {
            'x-zone': zoneId,
          },
        }
      })),
      // Build watch query
      map((options) => this.listProductsForEstimatingGQL.watch(listProductQueryVariables, options)),
      // Store reference to current watch query so we may call `fetchMore` later
      tap((productsQueryRef) => activeQuery = productsQueryRef),
      // Switch to value changes observable
      switchMap((productsQueryRef) => productsQueryRef.valueChanges),
      filter((res) => res.data !== undefined),
    ).subscribe((res) => {
      this.productsLoading = res.loading;
      if(res.loading) { return; }

      fetchingMore = res.loading;
      const products = res.data.products.products;


      const filteredProducts = this.productHelper.filterOutProductsWithInactivePrices(products);
      filteredProducts.sort((prodA, prodB) => {
        if (!prodA.category) { return 1; };
        return prodA.category.localeCompare(prodB?.category);
      });

      // As we put fetch limit for products to 200 this part of code can be omitted
      // as it leads to displaying duplicated products
      // I comment it instead of removing is case that some franchises have more than 200 products and it can affect them

      /*if(this.availableProducts.length){
        this.availableProducts = [...this.availableProducts, ...filteredProducts as unknown as Product[]];
      }else{
        this.availableProducts = filteredProducts as unknown as Product[];
      }*/

      this.availableProducts = filteredProducts as unknown as Product[];

      // TODO: remove this block of code, as we are not using fetchMore anymore and the default limit is 200
      if (products.length < res.data.products.total && res.networkStatus === 7 && !fetchingMore) {
        // console.log(`FETCH MORE ${ products.length } - ${ products.length + moreLimit }`);
        fetchingMore = true;

        if(activeQuery){
          activeQuery.refetch({
            ...listProductQueryVariables, // Shallow copy; if filter or priceFilter changes, cretae a new object
            skip: products.length,
            limit: moreLimit,
          });
          return;
        }
        // activeQuery.fetchMore({
        //   variables: {
        //     skip: products.length,
        //     limit: moreLimit,
        //   },
        //   // eslint-disable-next-line arrow-body-style
        //   // updateQuery: (p, { fetchMoreResult, variables }) => {

        //   //   fetchingMore = false;

        //   //   if (!p) { return; }

        //   //   return ({
        //   //     products: {
        //   //       ...p?.products,
        //   //       total: fetchMoreResult.products.total,
        //   //       limited: p.products.limited + fetchMoreResult.products.limited,
        //   //       products: [
        //   //         ...p.products.products,
        //   //         ...fetchMoreResult.products.products,
        //   //       ],
        //   //     }
        //   //   });
        //   // },
        // }).catch((err) => {
        //   console.log(err);
        // });
      }

    });
  }

  /**
   * Watches for when a new job is loading
   * and updates the `loadingNewJob` variable accordingly.
   */
  watchNewJob() {

    this.subs.sink = this.estimateHelper.jobLoading
      .pipe(distinctUntilChanged())
      .subscribe(loading => {
        this.jobLoading = loading;
        if (loading && this.fetchingNewJob) {
          this.loadingNewJob = true;
        };
        if (!loading) {
          this.fetchingNewJob = false;
          this.loadingNewJob = false;
        };
      });

    this.subs.sink = this.estimateHelper.fetchingNewJob
      .subscribe((fetching) => this.fetchingNewJob = fetching);
  }

  isChargesAddedRemovedDuplicated() {
    return this.addedCharges?.length
      || this.removedCharges?.length
      || this.jobMetadataUpdated;
  }

  isChargesModified() {
    return this.modifiedCharges?.length;
  }

  hasChanges() {
    return this.isChargesAddedRemovedDuplicated()
      || this.isChargesModified()
      || this.isEventsOrderModified
      || this.isRequiredEventsModified;
  }

  /**
   * Adds Added/remove charges to update jobs input and adds the update charge to promises
   */
  async saveCharges() {
    const saveInfo: EstimatingSaveInfo = {
      input: { jobId: undefined },
      promises: [],
      metadata: {},
    };

    return new Promise<EstimatingSaveInfo>(async (resolve, reject) => {
      const customer = this.freyaHelper.getJobCustomerId(this.job as any);
      if (!customer) { resolve(saveInfo); return; }

      this.isRequiredEventsModified = false;

      if (this.isChargesAddedRemovedDuplicated()) {
        saveInfo.input.newCharges = {
          charges: this.addedCharges.map((ac) => {

            const newCharge = {
              amount: this.getEditableAmount(ac),
              quantity: ac.quantity || 0,
              attributes: ac.attributes ? ac.attributes : undefined,
              order: ac.order,
              eventId: ac.calendarEvent.id,
            };

            if (ac.product) {
              return {
                ...newCharge,
                productId: ac.product.id,
              };
            } else {
              return {
                ...newCharge,
                productName: ac.productName,
                currency: ac.currency,
              };
            }
          }),
          userId: customer,
        };
        saveInfo.input.removeCharges = this.removedCharges.map((c) => c.id);

        // reset all should remove all non-rule based charges, reset removed charges
        // we added the charge info to the metadata when we called removeCharges, so it's here already.
        const ruleBasedRemoveCharges = this.getRemovedRuleBasedCharges();
        saveInfo.metadata = saveInfo.metadata || {};
        saveInfo.metadata[METADATA_JOB_REMOVED_CHARGES] = JSON.stringify(ruleBasedRemoveCharges);

        this.addedCharges = [];
        this.duplicatedCharges = [];
        this.removedCharges = [];
        this.jobMetadataUpdated = false;
      }

      if (this.isChargesModified()) {
        saveInfo.promises.push(this.updateCharges());
      }

      resolve(saveInfo);
    });
  }

  /**
   * Gets any information concerning charges removed due to rules
   * that's stored in the active job's metadata.
   *
   * @returns The active job's removed charges parsed metadata.
   */
  getRemovedRuleBasedCharges(): RemovedProductValue[] {
    const meta = this.job?.metadata?.[METADATA_JOB_REMOVED_CHARGES];
    if (!meta) { return []; }

    return safeParseJSON<RemovedProductValue[]>(meta.value, []);
    // console.log(r.length, r);
    // return r;
  }

  /**
   * Updates existing charges
   */
  updateCharges() {
    const updateChargesInput = {
      charges: this.modifiedCharges.map((mc) => ({
        id: mc.id,
        amount: this.getEditableAmount(mc),
        quantity: mc.quantity,
        eventType: mc.eventType,
        eventId: mc.calendarEvent.id,
        attributes: mc.attributes ? mc.attributes : undefined,
        order: mc.order
      })),
    };

    this.modifiedCharges = [];

    // Update Charges
    return new Promise<boolean>((resolve, reject) => {
      this.subs.sink = this.updateChargesGQL.mutate(updateChargesInput).subscribe((res) => {
        resolve(true);
      }, (err) => {
        this.localNotify.apolloError('Failed to update charges', err);
        reject();
      });
    });
  };

  async saveUpdatedEventsOrder() {
    const saveInfo: EstimatingSaveInfo = {
        input: { jobId: undefined },
        promises: [],
    };
    if (!this.isEventsOrderModified) {
        return Promise.resolve(saveInfo);
    }

    this.reorderedEvents = this.fillReorderedEvents();

    const edits: BulkEditCalendarEventInput = { edits: [] };

    const mutationPromises: Promise<boolean>[] = [];

    for (const event of this.reorderedEvents) {
      const input: SingleEditInput = {
        id: event.event.id,
        edit: {
            sequentialOrder: event.event.sequentialOrder,
        },
      };

      edits.edits.push(input);
    }

    const promise = new Promise<boolean>((resolve, reject) => {
      this.bulkEditCalendarEventsGQL.mutate(edits)
          .subscribe(
              (res) => {
                  resolve(true);
              },
              (err) => {
                  console.error(err);
                  reject(err);
              }
          );
    });

    mutationPromises.push(promise);

    this.isEventsOrderModified = false;
    this.reorderedEvents = [];

    saveInfo.promises = mutationPromises;

    return Promise.resolve(saveInfo);
}

  editCharge(charge: Charge) {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'charge',
      object: charge,
      additionalValues: [{
        property: 'job',
        value: this.job
      }]
    });
  }

  /**
   * Calculate the total for all of the active charges
   *
   * @returns Total of all charges
   */
  totalCharges() {
    const total = this.eventsWithCharges.map((event) => event.subtotal).reduce((subtotal, currentValue) => subtotal + currentValue, 0);
    return total;
  }

  /**
   * Updates the active charges to reflect any changes in the provided charge,
   * marks the provided charge as modified, then notifies the parent component
   * that the estimate has been updated.
   *
   * @param charge The charge that was modified.
   * @param auto Whether the charge was auto-added due to a rule.
   * @param emit Whether to notify the parent component that the estimate has been updated.
   */

  chargeModified(charge: Charge, auto = false, emit = true) {
    const chargeIndex = this.activeCharges.indexOf(charge);
    this.activeCharges.splice(chargeIndex, 1, charge);

    this.addToModifiedCharges(charge, auto);

    if (emit) {
      this.estimateUpdated.emit({
        property: 'charge-updated',
        value: charge,
      });
    }
  }

  /**
   * Marks a charge as modified so the component knows to update it on save.
   * If `auto` is on, modifies the attributes of auto charges to mark them as manual.
   *
   * @param charge The charge that was modified.
   * @param auto Whether to modify the attributes of auto charges to mark them as manual.
   */
  addToModifiedCharges(charge: Charge, auto = false) {


    /*const isAutoCharge = this.productRuleService.isAutoCharge(charge);
    if (!auto && isAutoCharge) {
      this.productRuleService.setChargeMode(charge, 'manual');
      console.log(`Auto charge modified, marking as manual`, charge.attributes);
    }*/

    if (!charge.id) { return; }
    const index = this.modifiedCharges.findIndex((mc) => mc.id === charge.id);

    if (index >= 0) {
      this.modifiedCharges.splice(index, 1, charge);
    } else {
      this.modifiedCharges.push(charge);
    }
  }

  /**
   * Adds removed charges to the active job's matadata
   * based on any charges removed due to a rule.
   *
   * @param ruleBasedRemoveCharges An array of RemovedProductValue objects
   * representing the charges that were removed due to a rule.
   */
  updateJobRemovedChargesMetadata(ruleBasedRemoveCharges: RemovedProductValue[]) {
    const meta = this.job?.metadata?.[METADATA_JOB_REMOVED_CHARGES];
    if (meta) {
      meta.value = JSON.stringify(ruleBasedRemoveCharges);
    } else {
      this.job.metadata[METADATA_JOB_REMOVED_CHARGES] = JSON.stringify(ruleBasedRemoveCharges);
    }
    this.jobMetadataUpdated = true;
  }

  /**
   * Checks the rules and if they require any changes,
   * adds or updates the required charges.
   *
   * @param params
   */
  updateProductRules(params?: UpdateAutoParams) {
    params = params || {};


    // const productsRemoved = this.getRemovedRuleBasedCharges();
    // const eventTypes = params.overrideRequiredEvents
    //   || Object.keys(this.estimateHelper.getEventTypeStatusesOnJob(this.job, true));

    // const {
    //   chargesToUpdate,
    //   productsToAdd,
    // } = this.productRuleService.determineProductRuleActions({
    //   productsRemoved,
    //   productRules,
    //   activeCharges: this.activeCharges as Charge[],
    //   eventTypes,
    // });

    // const autoChargesEnabled = this.isAutoChargesEnabled();
    // if (!autoChargesEnabled && !params.forceUpdateIfDisabled) {
    //   console.warn(`Not updating auto charges because auto charges is disabled for this job`);
    //   return;
    // }

    // let changes = 0;
    // for (const { rule, product: productInfo, quantity } of productsToAdd) {

    //   if (!this.isAutoChargesEnabledForEventType(productInfo.event) && !params.forceUpdateIfDisabled) {
    //     console.log(`Not adding product because event type ${productInfo.event} is disabled`);
    //     continue;
    //   }

    //   const product = this.availableProducts.find((p) => productInfo.productId === p.id);
    //   if (!product) {
    //     console.warn(`Could not find product ${productInfo.productId}`, productInfo, rule);
    //     continue;
    //   }

    //   if (!product.active) {
    //     console.warn(`Product ${product.name} is not active for rule ${rule.id}`, product, productInfo, rule);
    //     continue;
    //   }

    //   const attributes = [`auto::${rule.id}`, `auto-info::${JSON.stringify(productInfo)}`];

    //   console.log(`auto charge added`, product, quantity, attributes, productInfo);
    //   changes++;
    //   // Gets all events of the matching type and applies the charge to each
    //   const eventsToApplyChargeTo = this.eventsWithCharges.filter((event) => event.event.type === productInfo.event);

    //   for (const event of eventsToApplyChargeTo) {
    //     this.addCharge({
    //       product,
    //       quantity,
    //       attributes,
    //       eventType: event.event.type,
    //       calendarEvent: event.event as any,
    //       key: this.key,
    //     }, false);
    //   }
    // }

    // for (const { change, charge, rule, productInfo } of chargesToUpdate) {
    //   // const chargeEventType = this.freyaHelper.getAttributeValueByPrefix(charge.attributes, CHARGE_EVENT_PREFIX);
    //   const chargeEventType = change.event;
    //   if (chargeEventType && !this.isAutoChargesEnabledForEventType(chargeEventType)) {
    //     console.warn(`Not applying change to charge because event type ${chargeEventType} is disabled`, {
    //       change,
    //       charge,
    //       rule,
    //       productInfo,
    //     });
    //     continue;
    //   }
    //   // console.log(change, chargeEvent, charge.attributes);

    //   if (change.remove) {
    //     changes++;
    //     this.removeCharge(charge, true);
    //     console.log(`auto charge removed`, change, charge, productInfo);
    //     continue;
    //   }

    //   console.log(`auto charge updated`, change, charge.quantity, charge.attributes);
    //   if (change.quantity !== undefined) {
    //     charge.quantity = change.quantity;
    //   }

    //   // Keep track of initial event type before changing
    //   const initialEventType = charge.eventType;

    //   // Update subtotal before updating percent-based charges
    //   this.setChargeSubtotal(charge);

    //   // Update percentage-based charges based on whether the charge's event was changed
    //   if (change.event) {

    //     // If event changed, update charges in initial and new events
    //     this.updateEventPercentBasedChargesByType(initialEventType, undefined, charge);
    //     this.updateEventPercentBasedChargesByType(change.event, charge);

    //   } else {

    //     // If event didn't change, update charges in currentEventType only
    //     this.updateEventPercentBasedChargesByType(initialEventType);
    //   }

    //   changes++;
    //   this.chargeModified(charge, true, false);
    // }

    // if (changes) {
    //   this.setEventsWithCharges();

    //   // only fire if we actually changed anything otherwise we will
    //   // make ourselves one fine infinite loop
    //   this.estimateUpdated.emit({
    //     property: 'auto-charge',
    //   });
    // }
  }

  getRuleData() {
    return {};
  }

  /**
   * Opens dialog prompting user to confirm that they want to reset charges to calculated defaults.
   */
  confirmResetProductRules() {
    this.confirmationService.confirm({
      header: 'Reset charges to calculated defaults?',
      message: `Are you sure you want to reset charges to their automatically calculated defaults? `
        + `This will remove any manually added charges and reset all quantities.`,
      accept: () => {
        const changes = this.resetProductRules();
        this.localNotify.addToast.next({
          severity: 'success',
          summary: 'Charges reset to calculated defaults',
          detail: `Save job to apply ${changes} change${changes === 1 ? '' : 's'}`,
        });
      },
      acceptLabel: 'Reset Charges',
      acceptIcon: 'pi pi-refresh',
      rejectLabel: 'Cancel',
    });

  }

  isAutoChargesEnabled() {
    if (!this.job?.stage) { return; }

    const stageInfo = JOB_STAGES[this.job.stage];

    // TODO: check if job has auto charges explicitly disabled
    // if (this.job.metadata)

    return stageInfo?.autoChargesEnabled || false;
  }

  isAutoChargesEnabledForEventType(
    eventType: string
  ) {
    const stageInfo = JOB_STAGES[this.job.stage];
    const disableAutoChargesForBookedEvents = stageInfo?.disableAutoChargesForBookedEvents || false;
    if (!disableAutoChargesForBookedEvents) { return true; }

    const event = this.job.events.find((c) => c.type === eventType);
    if (!event) { return true; }

    return false;
  }

  isAutoChargesEnabledForEvent(
    eventId: string
  ) {
    const stageInfo = JOB_STAGES[this.job.stage];
    const disableAutoChargesForBookedEvents = stageInfo?.disableAutoChargesForBookedEvents || false;
    if (!disableAutoChargesForBookedEvents) { return true; }

    const event = this.job.events.find((c) => c.id === eventId);
    if (!event) { return true; }

    // TODO: determine if auto charges are enabled/disabled for this event?
    return false;
  }

  /**
   * Clears the list of removed charges on the job metadata,
   * resets modified auto charges to auto (instead of manual),
   * and removes all charges added manually.
   *
   * @returns The number of changes made to the charges.
   */
  resetProductRules() {
    this.updateJobRemovedChargesMetadata([]);
    let changes = 0;
    // TODO: only remove charges
    this.activeCharges.slice().forEach((charge) => {
      const auto = this.productRuleService.isAutoCharge(charge as Charge);
      const manual = this.productRuleService.isManualCharge(charge as Charge);
      if (manual) {
        changes++;
        this.productRuleService.setChargeMode(charge as Charge, 'auto');
      } else if (!auto) {
        changes++;
        this.removeCharge(charge as Charge, false);
      }
    });
    console.log(`Product rules reset - ${changes} changes.`);
    // this will update quantities, events, and add removed auto charges.
    this.updateAuto(undefined, true);
    return changes;
  }

  updateAuto(overrideRequiredEvents?: string[], forceUpdateIfDisabled = false) {
    this.$updateAuto.next({
      overrideRequiredEvents,
      forceUpdateIfDisabled,
    });
  }

  setChargeToAuto(charge: Charge) {
    console.log(`Charge reset to auto`, charge);
    this.productRuleService.setChargeMode(charge, 'auto');
    this.chargeModified(charge, true);
    this.updateAuto();
  }

  openEvent(eventId?: string) {
    if (!eventId) { return; }

    this.detailsHelper.open('calendar-event', { id: eventId });
  }

  openProduct(productId?: string) {
    if (!productId) { return; };

    this.detailsHelper.open('product', { id: productId });
  }

  openCharge(charge?: Charge) {
    if (!charge) { return; };

    this.detailsHelper.open('charge', charge);
  }

  markRequiredEventsAsModified() {
    this.isRequiredEventsModified = true;
  }

  resetLastModifiedEventType() {
    this.lastModifiedEvent = {
      eventId: undefined,
      collapsed: undefined
    };
  }

  reset() {
    // this.subs.unsubscribe();
    this.job = undefined;

    this.addedCharges = [];
    this.duplicatedCharges = [];
    this.removedCharges = [];
    this.modifiedCharges = [];

    this.isEventsOrderModified = false;

    this.usedProducts = [];
    this.activeCharges = [];
    this.jobMetadataUpdated = false;

    // this.updateProductRules();
  }

  /**
   * Checks the end date of each event with charges against the lock date
   * and marks events as locked accordingly
   */
  setLockedEvents() {
    for (const event of this.eventsWithCharges) {
      this.lockedEvents[event.id] = event.event?.end && (this.freyaHelper.lockDate > event.event?.end);
    }

    this.formattedLockDate = this.freyaHelper.getFormattedLockDate();
  }

  createCustomCharge(productSearch: string, eventId: string) {

    if(this.jobContainsUnsavedCharges) {
      this.chargesUpdated.emit();
    }

    this.customChargeCreated.next();

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'charge',
      additionalValues: [{
        property: 'job',
        value: this.job
      },
      {
        property: 'isDamage',
        value: false,
      },
      {
        property: 'prefilledChargeName',
        value: productSearch,
      },
      {
        property: 'preselectedEventId',
        value: eventId,
      }]
    });
  }

  setEventIds() {
    if (!this.job?.events?.length) {
      this.existingEventIds = [];
      this.validEventIds = [];
      return;
    }

    const events = this.job?.events || [];
    const validEventIds: string[] = [];

    for (const event of events || []) {

      // // Check if event is locked
      if (event.end && this.freyaHelper.lockDate > event.end) {
        continue;
      }

      // Check if event is invoiced
      if (event?.invoices?.some(isFinalizedInvoice)) {
        continue;
      }

      validEventIds.push(event.id);
    }

    this.validEventIds = validEventIds;
    this.existingEventIds = events.map((e) => e.id);
  }

  setEditableAmounts() {

    for (const charge of this.activeCharges) {

      const amount = this.getUneditedAmount(charge);

      const priceType = this.chargeHelperService.getPriceType(charge);

      this.editableAmounts[charge.id] = priceType === 'fixed' ? amount / 100 : amount;
    }
  }

  getUneditedAmount(charge: ChargeWithKey) {
    if (charge.amount !== null && charge.amount !== undefined) {
      return charge.amount;
    }

    if (charge.price) {
      return charge.price.amount;
    }

    if (charge.product) {

      const activePrice = this.productHelper.getActivePrice(charge.product.prices);

      return activePrice?.amount || 0;
    }

    return 0;
  }

  /**
   * Gets the charge amount from the UI (after the user has potentially edited it),
   * and converts it to cents if necessary.
   */
  getEditableAmount(charge: ChargeWithKey) {
    const priceType = this.chargeHelperService.getPriceType(charge);

    const editableAmount = this.editableAmounts[charge.id || charge.key];

    return priceType === 'fixed' ? Math.round(editableAmount * 100) : editableAmount;
  }

  getFixedTotal(charges: ChargeWithKey[]) {

    let total = 0;

    for (const charge of charges) {

      if (this.chargeHelperService.getPriceType(charge) !== 'fixed') { continue; }

      this.safeUpdateFixedChargeSubtotal(charge as Charge);

      total += charge.chargeSubTotal;

    }

    return total;
  }

  openDocuments() {
    this.documentHelper.openDocumentsDialog({
      jobId: this.job.id,
      jobCode: this.job.code,
      preselectTemplateKey: 'standard-documents.estimate'
    });
  }

  closeChargeActionsMenu() {
    this.chargeActionsMenu.hide();
  }

  contactAccounting(event: EventWithCharges) {
    this.freyaHelper.contactAccounting(event.event.id);
  }

  get key() {
    const key = this._key.toString();
    this._key++;
    return key;
  }

  subtotalLimit = MAX_32_BIT_INT;

  eventTypeInfoMap = eventTypeInfoMap;

}

