<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="p-d-flex p-jc-between">
                <h4>Accounting</h4>
            </div>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <div class="card">
            <h5>Rolling Lock Date</h5>
            <app-edit-in-place
                label="Lock date"
                [value]="currentDate"
                contentType="date"
                formInput="calendar"
                [loading]="loading"
                (saveButtonPressed)="updateConfig('currentDate', $event.value, $event.deactivate)"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Contact Accounting Button Text"
                [value]="contactAccountingButtonText"
                (saveButtonPressed)="updateConfig('contactAccountingButtonText', $event.value, $event.deactivate)"
                [loading]="loading"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Support email"
                [value]="supportEmail"
                (saveButtonPressed)="updateConfig('supportEmail', $event.value, $event.deactivate)"
                [loading]="loading"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Email subject"
                [value]="emailSubject"
                (saveButtonPressed)="updateConfig('emailSubject', $event.value, $event.deactivate)"
                [loading]="loading"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Email prefix"
                [value]="emailBodyPrefix"
                (saveButtonPressed)="updateConfig('emailBodyPrefix', $event.value, $event.deactivate)"
                formInput="textarea"
                [loading]="loading"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Email suffix"
                [value]="emailBodySuffix"
                (saveButtonPressed)="updateConfig('emailBodySuffix', $event.value, $event.deactivate)"
                [loading]="loading"
                formInput="textarea"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <br>
            <app-edit-in-place
                label="Event Locked Warning"
                [value]="eventLockedWarning"
                (saveButtonPressed)="updateConfig('eventLockedWarning', $event.value, $event.deactivate)"
                [loading]="loading"
                formInput="textarea"
                [editPermissions]="hasPermissionToModifyLockDate"
                ></app-edit-in-place>
            <h6>Support Email Template Preview</h6>
            <textarea
                pInputTextarea
                readonly
                rows="20"
                class="full-width"
                [value]="templatePreview"
                [disabled]="true"
                ></textarea>
        </div>
    </div>
    <div class="p-md-6 p-col-12">
        <div class="card">
            <div class="p-d-flex p-jc-between">
                <h5>Integrations</h5>
            </div>
            <a [routerLink]="[ '/integration', 'quickbooks' ]">Quickbooks</a>
            <p></p>
        </div>
    </div>
</div>
