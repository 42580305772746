export function formatUserName(user: {
    givenName?: string;
    familyName?: string;
  }) {

    if (!user) { return 'Unknown User'; }

    const names = [ user.givenName, user.familyName ].filter(Boolean);
    return names.join(' ');
}

export function firstInitialLastName(user: {givenName?: string; familyName?: string}){
  if (!user) { return 'Unknown User'; }

  const firstInitial = user.givenName.substring(0, 1).toUpperCase();

  return `${firstInitial}. ${user.familyName}`;
}

export function lastNameFirstName(user: {givenName?: string; familyName?: string}) {
  return `${user.familyName}, ${user.givenName}`;
}
