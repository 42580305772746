import { gql } from 'graphql-tag';

export interface Action {
    name: string;
    type: string;
    formattedName: string;
    value?: string;
    extraInfo?: string;
    extraInfoType?: string;
    extraInfoQuickOptions?: string[];

    onClick?: (n?: ClientNotification, a?: Action, event?: any) => boolean | void;
}

export interface ClientNotification extends Partial<Omit<PlusNotification, 'createdAt' | 'updatedAt'>> {

    type: string;
    title: string;
    duration: number;
    displayedAt?: Date;

    createdAt: Date;
    updatedAt: Date;
    readAt?: Date;


    _clientCreated?: boolean;

    // Used to control the animation state
    _displayState?: 'new' | 'down' | 'up';
    // Used internal to represent dismiss actions
    _onDismiss?: (action?: string) => void;
}

export interface ClientNotificationInput extends Partial<Omit<
    ClientNotification, 'id' | 'createdAt' | 'updatedAt' | '_clientCreated' | '_displayState'>
> {
    type: string;
    title: string;
}

export interface PlusNotification {
    id: string;
    type: string;
    title: string;
    subtitle?: string;
    body?: string;
    url?: string;

    actionsDisabled?: boolean;

    createdAt: { unix: number };
    updatedAt: { unix: number };

    /**
     * The time, in seconds, for the notification to live.
     * If not set will default to one month.
     */
    expiresIn?: number;

    actions?: Action[];

    data?: string | { [key: string]: string | number | boolean };

    recipients: {
        id: string;
        readAt?: number;
    }[];
}


export interface ListMyNotificationsResult {
    me: {
        notifications: PlusNotification[];
    };
}

export interface GetNotificationsResult {
    me: {
        notifications: PlusNotification[];
    };
}

export interface BrowserNotification extends Notification {
    timestamp: number;
    actions?: any[];
}

export interface BrowserNotificationAction {
    action?: string;
    icon?: string;
    title?: string;
}

export function browserNotificationToPlusNotification(n: BrowserNotification) {
    let type = 'notification';
    if (n.tag && n.tag.startsWith('callstatus')) {
        type = 'call';
    }

    return {
        id: (n.data && n.data.localNotificationId) || n.tag,
        url: n.data && n.data.url,
        title: n.title,
        subtitle: n.body,
        type: (n.data && n.data.type) || type,
        createdAt: { unix: n.timestamp },
        expiresIn: 10000,
        actions: n.actions && n.actions.map((a) => ({
            formattedName: a.title,
            type: a.action,
            name: a.title,
        })),
        recipients: [],
        webpush: n,
    } as unknown as PlusNotification & { webpush: Notification };
}

export const notificationsBaseGQL = `
    id
    type
    title
    subtitle
    body
    createdAt {
    unix
    }
    url
    actionsDisabled
    expiresIn
    data
`;


export const getNotifications = gql`
query getNotifications(
    $notificationIds: [ String! ]!
) {
    me {
        notifications(
            notificationIds: $notificationIds
        ) {
            id
            type
            createdAt {  unix  }
            updatedAt {  unix  }
            title
            subtitle
            body
            url
            expiresIn
            data
            actions {
                name
                type
                value
                extraInfo
                extraInfoType
                extraInfoQuickOptions
                formattedName
            }
            recipients {
                id
                readAt
            }
        }
    }
}

`;

export const listMyNotifications = gql`
query listMyNotifications(
    $limit: Int
    $skip: Int
) {
    me {
        notifications (
            skip: $skip
            limit: $limit
        ) {
            id
            type
            createdAt {  unix  }
            updatedAt {  unix  }
            title
            subtitle
            body
            url
            expiresIn
            data
            actions {
                name
                type
                value
                extraInfo
                extraInfoType
                extraInfoQuickOptions
                formattedName
            }
            recipients {
                id
                readAt
            }
        }
    }
}
`;

export const markNotificationsAsRead = gql`
query markNotificationsAsRead(
  $notificationIds: [String!]!
) {
  me {
    notifications(
      notificationIds: $notificationIds,
      limit: 200,
      setRead: true,
    ) {
      id
      recipients { readAt id }
    }
  }
}
`;

export const subscribeToNewNotifications = gql`
subscription subscribeToNewNotifications {
    newNotification {
        id
        type
        createdAt {  unix  }
        updatedAt {  unix  }
        title
        subtitle
        body
        url
        expiresIn
        data
        actions {
            name
            type
            value
            extraInfo
            extraInfoType
            extraInfoQuickOptions
            formattedName
        }
        recipients {
            id
            readAt
        }
    }
}
`;

export const updateNotificationValue = gql`
mutation updateNotificationAction(
    $notification: String!
    $action: String!
    $value: String!
    $extraInfo: String
) {
  updateNotificationAction(
    notification: $notification
    action: $action
    value: $value
    extraInfo: $extraInfo
  ) {
    id
    actionsDisabled
    actions {
        name
        type
        value
        extraInfo
        extraInfoType
        extraInfoQuickOptions
        formattedName
    }
  }
}
`;

export interface SendMessageInput {
    users: string[];
    subject: string;
    body: string;
}

export interface SendMessageOutput {
    messageUsers: {
        usersSent: string[];
    };
}

export const sendMessage = gql`
mutation message(
    $users: [String!]!
    $subject: String!
    $body: String
) {
  messageUsers(
    userIds: $users
    subject: $subject
    body: $body
  ) {
    usersSent
  }
}
`;


export interface NotifyInput{
    type: string;
    users?: NotifyUserInput[];
    roleIds?: string[];
    data?: NotificationDataInput;
    eventId?: string;
    onReply?: string;
}

export interface NotifyUserInput{
    userId: string;
    data: NotificationDataInput;
}

export type NotificationDataInput = any;

export interface NotifyOutput {
    notify: boolean;
}

export const notifyMutation = gql`
    mutation notify(
        $type: String!
        $users: [NotifyUserInput]
        $roleIds: [String]
        $data: NotificationDataInput
        $eventId: String
        $onReply: String
    ) {
        notify(
            type: $type,
            users: $users,
            roleIds: $roleIds,
            data: $data,
            eventId: $eventId,
            onReply: $onReply
        )
    }
`;
