

<div class="header">
    <h4 class="text">
        <i class="pi pi-chart-line large-icon"></i>
        Report
    </h4>
    <div class="actions">
        <button
            pButton
            pRipple
            label="Actions"
            [disabled]="!rt"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
        ></button>

        <p-menu
            #menu
            [popup]="true"
            appendTo="body"
            [model]="actions"
        ></p-menu>
    </div>
</div>

<h5 *ngIf="!loading; else loadingBar">
    <a routerLink="/reports/type/{{rt?.id}}">
        {{rt?.name || "Report Type"}}
    </a>
</h5>

<!-- Recently run reports will go here eventually... -->

<!-- <div class="tag-container" *ngIf="!loading; else loadingBar">
    <p-tag
        *ngFor="let tag of job.tags"
        [style]="{'background-color': tag.color}"
        [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
    >
        {{tag | formatTag}}
    </p-tag>
</div> -->

<h5>Overview</h5>
<div class="card">
    <p *ngIf="!loading; else loadingBar">
        ID: {{ rt?.id }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        Created: {{ rt?.createdAt | freyaDate }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        Updated: {{ rt?.updatedAt | freyaDate }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        Version: {{ rt?.version }}
    </p>
    <!-- <p *ngIf="!loading; else loadingBar">
        Last Run: {{ rt?.createdAt | freyaDate }}
    </p> -->
</div>

<!-- <app-mutate-job
    [job]="job"
    #mutate
>
</app-mutate-job> -->

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton width="100%" height="16px"></p-skeleton>
    </div>
</ng-template>

