import { Component } from '@angular/core';

@Component({
  selector: 'app-job-files',
  standalone: true,
  imports: [],
  templateUrl: './job-files.component.html',
  styleUrl: './job-files.component.scss'
})
export class JobFilesComponent {

}
