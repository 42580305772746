<div class="karve-tabview">
  <div class="p-tabview p-component">
    <div class="p-tabview-nav-container">
      <div class="p-tabview-nav-content">
        <ul role="tablist" class="p-tabview-nav">
          <li
            *ngFor="let tab of tabs"
            role="presentation"
            tabindex="0"
            [routerLink]="[baseUrl, tab.slug]"
            routerLinkActive="p-highlight"
          >
            <a
              role="tab"
              class="p-tabview-nav-link"
              id="overview-tab"
              aria-controls="overview-content"
              aria-selected="true"
            >
              <span class="p-tabview-title"> {{ tab.name }} </span>
            </a>
          </li>
          <li role="presentation" class="p-tabview-ink-bar"></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="job-tabs-dropdown">
    <p-dropdown
      optionLabel="name"
      optionValue="slug"
      [options]="tabs"
      [(ngModel)]="selectedTab"
      (onChange)="goToRoute($event)"
      appendTo="body"
    >
    </p-dropdown>
  </div>
</div>
