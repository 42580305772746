import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { SearchResult } from 'minisearch';
import { PrimeIcons } from 'primeng/api';
import { IconFieldModule } from 'primeng/iconfield';

import { InputIconModule } from 'primeng/inputicon';

import { combineLatest, Observable, startWith } from 'rxjs';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { EventAttendeeRoles } from '../../../../global.constants';
import { BrandingService } from '../../../../services/branding.service';
import { SharedModule } from '../../../../shared/shared.module';
import { scheduleFeature } from '../../../store/schedules.reducer';
import { DispatchAssetSearchService, PotentialAssetType } from '../../dispatch-asset-search.service';
import { DispatchUserSearchService, PotentialCrewType } from '../../dispatch-user-search.service';
import { DispatchActions } from '../../store/dispatch.actions';
import { AdditionalEventAttendeeRoles, CrewRoleOptions, DispatchFeature, DispatchFilterOption } from '../../store/dispatch.reducer';

import { DispatchChipComponent } from '../dispatch-chip/dispatch-chip.component';

const DEFAULT_REPORT_QUERY_PARAMS = {
  zone: null,
  period: 'Custom',
  periodType: 'eventEnd',
  jobState: 'open;closed;archived',
  eventStatuses: 'required;pending;booked;confirmed;completed',
  jobHasCompletedEstimate: 'false',
  groupBy: 'area',
  sort: 'discountedSubTotal DESC',
  dataColumns: 'area;subTotal;discountedSubTotal;taxTotal;total',
  aggregationColumns: 'subTotalSum;discountedSubTotalSum;totalSum;count;subTotalAvg;discountedSubTotalAvg;totalAvg',
  customPeriod: null,
};
const ROLES_OPTIONS: DispatchFilterOption<CrewRoleOptions>[] = [
  {
    label: AdditionalEventAttendeeRoles.All,
    icon: PrimeIcons.USERS
  },
  {
    label: EventAttendeeRoles.crewLead,
    icon: PrimeIcons.STAR_FILL
  },
  {
    label: EventAttendeeRoles.crewMember,
    icon: PrimeIcons.USER
  }
];
@Component({
  selector: 'app-dispatch-crew',
  standalone: true,
  imports: [
    FreyaCommonModule,
    IconFieldModule,
    InputIconModule,
    ReactiveFormsModule,
    CdkDrag,
    CdkDropList,
    CommonModule,
    DispatchChipComponent,
    SharedModule,
  ],
  providers: [DispatchUserSearchService, DispatchAssetSearchService],
  templateUrl: './dispatch-crew.component.html',
  styleUrl: './dispatch-crew.component.scss',
})
export class DispatchCrewComponent implements OnInit, OnDestroy {

  public potentialCrew$ = this.store.select(DispatchFeature.selectPotentialCrew);
  public potentialAssets$ = this.store.select(DispatchFeature.selectPotentialAssets);
  public usersLoaded$ = this.store.select(DispatchFeature.usersLoaded);
  public assetsLoaded$ = this.store.select(DispatchFeature.assetsLoaded);
  public todaysRevenue$ = this.store.select(DispatchFeature.selectTodaysRevenue);
  public filteredPotentialCrew$: Observable<PotentialCrewType | SearchResult[]>;
  public filteredPotentialAssets$: Observable<PotentialAssetType | SearchResult[]>;
  private formattedDate$ = this.store.select(scheduleFeature.selectFormattedDate);

  public readonly rolesOptions = ROLES_OPTIONS;
  public readonly EVENT_ATTENDEE_ROLES = EventAttendeeRoles;

  public filterCrewForm = new FormGroup({
    userSearch: new FormControl<string>(''),
    assetSearch: new FormControl<string>(''),
    role: new FormControl<DispatchFilterOption<CrewRoleOptions>>(this.rolesOptions[0]),
  });

  public revenueReportQueryParams = DEFAULT_REPORT_QUERY_PARAMS;

  private subs = new SubSink();

  constructor(private store: Store,
    private userSearchService: DispatchUserSearchService,
    private assetSearchService: DispatchAssetSearchService,
    private router: Router,
    private brandingService: BrandingService,
  ) { }

  ngOnInit(): void {

    this.store.dispatch(DispatchActions.initCrew());

    this.watchUserSearch();
    this.watchAssetSearch();
    this.watchRolesFilter();
    this.prepareDynamicReportLink();
  }

  private watchUserSearch() {
    this.filteredPotentialCrew$ = this.userSearchService.searchUsers(
      this.filterCrewForm.get('userSearch').valueChanges.pipe(startWith('')),
      this.potentialCrew$,
    );
  }

  private watchAssetSearch() {
    this.filteredPotentialAssets$ = this.assetSearchService.searchAssets(
      this.filterCrewForm.get('assetSearch').valueChanges.pipe(startWith('')),
      this.potentialAssets$,
    );
  }

  private watchRolesFilter() {
    this.subs.sink = this.filterCrewForm.get('role').valueChanges.subscribe(({ label }) => {
      this.store.dispatch(DispatchActions.setUserRole({ role: label }));
    });
  }

  public addAvailableCrew() {
    this.router.navigate(['/users/employees']);
  }

  public prepareDynamicReportLink() {
    this.subs.sink = combineLatest([this.formattedDate$, this.brandingService.currentZone()]).subscribe(([formattedDate, zone]) => {
      this.revenueReportQueryParams = {
        ...DEFAULT_REPORT_QUERY_PARAMS,
        zone: zone?.id,
        customPeriod: `${formattedDate};${formattedDate}`,
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
