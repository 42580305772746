import { Component, Input } from '@angular/core';

import { AttentionItemsService } from '../attention-items.service';

@Component({
  selector: 'app-attention-items-summary',
  templateUrl: './attention-items-summary.component.html',
  styleUrls: [
    '../../dashboardv2/dashboardv2.component.scss',
    './attention-items-summary.component.scss',
  ]
})
export class AttentionItemsSummaryComponent {

  @Input() summaryStyle: 'dashboard' | 'chips' | 'dashboardv2' = 'dashboard';

  constructor(
    public attentionItemsService: AttentionItemsService,
  ) { }

}
