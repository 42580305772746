import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, take, takeLast, takeUntil } from 'rxjs/operators';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

import { Coordinates } from '../../../generated/graphql.generated';

import { DistanceService } from '../distance.service';
import { DistanceLocation } from 'src/app/jobsv2/jobv2-create/jobv2-interfaces';
import { distancesToShow } from 'src/app/global.constants';

interface MapView {
  name: string;
  url: string;
  inactive: boolean;
}

@Component({
  selector: 'app-distances',
  templateUrl: './distances.component.html',
  styleUrls: ['./distances.component.scss']
})
export class DistancesComponent implements OnInit, OnDestroy {
  @Input() showMap = false;
  @Input() mapZoom = 10;
  @HostBinding('style.--map-height') @Input() mapHeight = '55vh';

  subs = new SubSink();

  mapLoading = false;

  iFrameRefreshing = false;

  mapsBaseUrl = 'https://www.google.com/maps/embed/v1/';

  startView: MapView;
  endView: MapView;
  routeView: MapView;
  mapViews: MapView[];
  selectedMapView: MapView;

  distancesToShow = distancesToShow;

  currentRoute$?: Observable<'job' | 'estimating'>;

  constructor(
    public distanceService: DistanceService,
    private googleHelper: GoogleHelperService,
    public estimateHelper: EstimateHelperService,
    private freyaHelper: FreyaHelperService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.showMap) {
      this.initMap();
    };

    this.setCurrentRoute();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  objectIsEmpty(obj: Record<string, unknown>) {
    return !Object.entries(obj).length;
  }

  setCurrentRoute() {
    this.currentRoute$ = this.route.url.pipe(
      filter(url => Boolean(url.length)),
      map(url => url[0].path as 'job' | 'estimating')
    );
  }

  initMap() {
    this.initMapViews();

    this.subs.sink = this.distanceService.distancesUpdated
    .pipe(debounceTime(150))
    .subscribe(async () => {
      await this.updateMapViews();
      this.refreshiFrame();
    });

    this.subs.sink = this.estimateHelper.addressChanged
    .subscribe(() => this.mapLoading = true );
  }

  initMapViews() {
    this.startView = {
      name: 'Start',
      url: undefined,
      inactive: true
    };
    this.endView = {
      name: 'End',
      url: undefined,
      inactive: true
    };
    this.routeView = {
      name: 'Route',
      url: undefined,
      inactive: true
    };
    this.mapViews = [
      this.startView,
      this.endView,
      this.routeView
    ];
    this.selectedMapView = this.routeView;
  }

  /**
   * Tries to populate each mapView's url property based off the latest locations stored in distanceService
   * If successful, sets the mapView's inactive property to false,
   * which enables the corresponding button on the selectButton
   */
  async updateMapViews() {
    const { start, end } = this.distanceService.locations;
    this.setStreetViewUrl(this.startView, start);
    this.setStreetViewUrl(this.endView, end);

    this.routeView.url = await this.getRouteUrl();

    if (this.routeView.url) {
      this.routeView.inactive = false;
      this.mapViews = clone(this.mapViews);
    };
  }

  /**
   * Gets the Google Maps URL for the requested route based on the locations stored in distanceService
   */
  async getRouteUrl(){
    const { locations } = this.distanceService;
    const dock = locations.dock ? this.distanceService.encodeDistanceLocation(locations.dock) : undefined;
    const start = locations.start ? this.distanceService.encodeDistanceLocation(locations.start) : undefined;
    const end = locations.end ? this.distanceService.encodeDistanceLocation(locations.end) : undefined;
    // console.log(dock, start, end, locations);

    // If all locations are available, return route from dock to dock through start and end
    if (dock && start && end) {
      return this.getDirectionsUrl(dock, dock, [start, end]);
    };

    // If only two locations are available, return route from one to the other
    if (dock && start) {
      return this.getDirectionsUrl(dock, start);
    };
    if (dock && end) {
      return this.getDirectionsUrl(end, dock);
    };
    if (start && end) {
      return this.getDirectionsUrl(start, end);
    };

    // If only one location is available, return place URL
    if (start) {
      return this.getPlaceUrl(start);
    };
    if (end) {
      return this.getPlaceUrl(end);
    };

    // If no location is available, return URL for zone HQ if available
    const hqCoordinates = await this.getHQCoordinates();
    if (hqCoordinates){
      return this.getViewUrl(hqCoordinates.latitude, hqCoordinates.longitude);
    };

    // Otherwise (as a last resort), return URL for country
    // const countryCoordinates = await this.getCountryCoordinates();
    // if (countryCoordinates) {
      // return this.getViewUrl(countryCoordinates.latitude, countryCoordinates.longitude);
    // };
    return this.getViewUrl(environment.defaultMapCoordinates[0], environment.defaultMapCoordinates[1], 4);
  }

  getDirectionsUrl(origin: string, destination: string, waypoints?: string[]): string {
    const key = this.googleHelper.getKey();
    let params = `origin=${origin}&destination=${destination}`;
    if (waypoints) {
      const waypointsParam = `&waypoints=${waypoints.join('|')}`;
      params = params + waypointsParam;
    };
    return `${this.mapsBaseUrl}directions?key=${key}&${params}`;
  }

  getPlaceUrl(place: string) {
    const key = this.googleHelper.getKey();
    return `${this.mapsBaseUrl}place?key=${key}&q=${place}`;
  }

  getViewUrl(lat: number, long: number, zoom = this.mapZoom): string {
    const key = this.googleHelper.getKey();
    const params = `center=${lat},${long}&zoom=${ zoom }`;
    return `${this.mapsBaseUrl}view?key=${key}&${params}`;
  }

  async getHQCoordinates(): Promise<Coordinates> {
    const franchiseHQ = await this.freyaHelper.getDockLocation();
    if (!franchiseHQ?.coordinates) { return undefined; }

    return franchiseHQ.coordinates;

    // const hqGeometry = await this.googleHelper.geocodeAddress({formattedAddress: franchiseHQ.addressLineOne});
    // if (!hqGeometry) { return undefined; }
    // return this.googleHelper.getCoordinatesFromGeometry(hqGeometry.geometry);
  }

  async getCountryCoordinates() {
    const country = await this.freyaHelper.getCountry();
    if (!country) { return; }
    const countryPlaceDetails = await this.googleHelper.geocodeAddress({formattedAddress: country.country});
    if (!countryPlaceDetails) { return; }
    return this.googleHelper.getCoordinatesFromGeometry(countryPlaceDetails.geometry);
  }

  /**
   * Sets the url property of a given map view option
   * to the Google Maps Street View URL for a given location,
   * or for the nearest location within a given radius
   * if the given location has no available street view
   *
   * @param mapView the map view option whose url you want to set
   * @param location the location for which you want the street view
   * @param radius the radius within which to look for a street view
   * if the given location has no available street view
   */
  async setStreetViewUrl(mapView: MapView, location: DistanceLocation, radius: number = 50 ) {
    if (!mapView || !location?.coordinates) { return; }
    const key = this.googleHelper.getKey();

    // const locationGeometry = await this.googleHelper.geocodeAddress({formattedAddress: location.address});
    const latlng = this.googleHelper.convertCoordinatesToLatLng(location.coordinates);
    const params = `location=${ latlng.lat },${ latlng.lng }`;
    mapView.url = `${this.mapsBaseUrl}streetview?key=${key}&${params}`;
    mapView.inactive = false;
    this.mapViews = clone(this.mapViews);
  }

  mapLoadingOff() {
    this.mapLoading = false;
  }

  refreshiFrame() {
    this.cd.detectChanges();
    this.iFrameRefreshing = true;
    setTimeout(() => this.iFrameRefreshing = false, 250);
  };

  goToLocationsTab() {

    // Can only go to location tab if we are in estimating page
    this.currentRoute$.pipe(take(1))
      .subscribe((currentRoute) => {
        if (currentRoute !== 'estimating') { return; }
        this.estimateHelper.goToLocationsTab('start');
      });
  }

}
