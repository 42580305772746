import { Pipe, PipeTransform } from '@angular/core';
import { RawUser } from '@karve.it/interfaces/auth';

@Pipe({
  name: 'userFranchise'
})
export class UserFranchisePipe implements PipeTransform {

  transform(user: RawUser): string {
    if (!user || !user.zones.nodes[0]?.parent) { return 'N/A'; }

    return user.zones.nodes[0].parent.name;
  }

}
