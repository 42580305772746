/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { CreateProductInput, Product } from './stripe-products';

export interface ListPricesInput {
    active?: boolean;
    created?: RangeQueryParam;
    currency?: string;
    lookup_keys?: string[];
    product?: string;
}

export interface ListPricesOutput {
    prices: Price[];
}

export interface WatchPricesInput {
    active?: boolean;
    created?: RangeQueryParam;
    currency?: string;
    lookup_keys?: string[];
    product?: string;
}

export interface WatchPricesOutput {
    prices: Price[];
}

export const pricesQuery = gql`
	query prices($active: Boolean, $created: RangeQueryParam, $currency: String, $lookup_keys: [String], $product: String, ){
		prices(active: $active,  created: $created,  currency: $currency,  lookup_keys: $lookup_keys,  product: $product, ){
            id
            active
            created
            currency
            deleted
            livemode
            lookup_key
            nickname
            product{ id }    
            unit_amount		 
        }
    }
`;

export interface CreatePricesInput {
    prices?: CreatePrice[];
}

export interface CreatePricesOutput {
    createPrices: Price[];
}

export const createPricesMutation = gql`
	mutation createPrices($prices: [CreatePrice], ){
		createPrices(prices: $prices, ){
            id
            active
            created
            currency
            deleted
            livemode
            lookup_key
            nickname
            product{
                id
            }
            unit_amount         
        }
    }
`;

export interface UpdatePriceInput {
    stripeId?: string;
    active?: boolean;
    lookup_key?: string;
    nickname?: string;
    transfer_lookup_key?: boolean;
}

export interface UpdatePriceOutput {
    updatePrice: boolean;
}

export const updatePriceMutation = gql`
	mutation updatePrice($stripeId: String!, $active: Boolean, $lookup_key: String, $nickname: String, $transfer_lookup_key: Boolean, ){
		updatePrice(stripeId: $stripeId,  active: $active,  lookup_key: $lookup_key,  nickname: $nickname,  transfer_lookup_key: $transfer_lookup_key, ) 	 
    }
`;
export interface Price {
    id?: string;
    active?: boolean;
    created?: number;
    currency?: string;
    deleted?: boolean;
    livemode?: boolean;
    lookup_key?: string;
    nickname?: string;
    product?: Product;
    unit_amount?: number;
}

export interface RangeQueryParam {
    gt?: number;
    gte?: number;
    lt?: number;
    lte?: number;
}

export interface CreatePrice {
    currency?: string;
    active?: boolean;
    lookup_key?: string;
    nickname?: string;
    product?: string;
    product_data?: CreateProductInput;
    transfer_lookup_key?: boolean;
    unit_amount?: number;
}

