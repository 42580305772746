<div class="p-flex">
    <button
        label="Enable 2FA"
        class="quick-margin"
        pButton
        [disabled]="user?.twoFactorEnabled"
        (click)="startEnable2FA()"
    ></button>
    <button
        label="Disable 2FA"
        class="p-button-danger quick-margin"
        pButton
        [disabled]="!user?.twoFactorEnabled"
        (click)="startDisable2FA()"
    ></button>
</div>

<br>
<small>Two Factor Authentication adds another layer of security to your account.</small>

<div #twoFactorDialog></div>

<p-dialog
    [header]="'Disable 2FA'"
    [(visible)]="showDisable2FADialog"
    [modal]="true"
>
    <app-auth
        [showHeader]="false"
        [showResetPassword]="false"
        [append2faDialogTo]="twoFactorDialog"
        [showLoginForever]="false"
        [showKeepMeLoggedIn]="false"
        [updateAuthStatus]="false"
        [doNavigate]="false"
        [show2faBox]="true"
        [tooltips]="false"
        [forCurrentUser]="true"
        [loginText]="'Disable 2FA'"
        (afterSuccessfulLogin)="disable2FA()"
    ></app-auth>
</p-dialog>

<p-dialog
    [header]="'Setup 2FA'"
    [(visible)]="showEnable2FADialog"
    [modal]="true"
>
    <div *ngIf="!activeTab">
        <p-skeleton
            width="100%"
            height="100%"
        ></p-skeleton>
    </div>
    <div *ngIf="activeTab === 1">
        <div class="p-grid p-text-center">
            <div class="p-col">
                <img
                    [class.hide]="!imageLoaded"
                    [src]="twoFactorSecret.qr"
                    alt="QR code"
                    width="166px"
                    height="166px"
                    (load)="imageLoaded = true"
                >
                <!-- <p-image [src]="twoFactorSecret.qr" width="166px" height="166px"></p-image> -->
                <p-skeleton
                    *ngIf="!imageLoaded"
                    width="166px"
                    height="166px"
                ></p-skeleton>
            </div>
        </div>
        <br>
        <div class="p-inputgroup">
            <input
                class="full-width"
                pInputText
                readonly
                [ngModel]="twoFactorSecret?.secret"
            >
            <button
                pButton
                icon="pi pi-copy"
                (click)="copyCode()"
            ></button>
        </div>
        <small>Scan QR Code or Copy the seed value into one of the following:
            <a
                href="https://authy.com/download/"
                target="_blank"
            >Authy</a>,
            <a
                [href]="googleAuthenticatorLink"
                target="_blank"
            >
                Google Authenticator
            </a>
            , or
            <a
                href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                target="_blank"
            >Microsoft Authenticator</a>
        </small>
        <br>
        <button
            pButton
            label="I've scanned my code"
            class="two-factor-setup-navigate"
            icon="pi pi-arrow-right"
            (click)="activeTab = 2"
        ></button>
    </div>
    <div *ngIf="activeTab === 2">
        <app-auth
            [showHeader]="false"
            [showResetPassword]="false"
            [showLoginForever]="false"
            [showKeepMeLoggedIn]="false"
            [updateAuthStatus]="false"
            [doNavigate]="false"
            [show2faBox]="true"
            [tooltips]="false"
            [forCurrentUser]="true"
            [loginText]="'Enable 2FA'"
            (afterSuccessfulLogin)="confirmSecret($event)"
        ></app-auth>

        <button
            pButton
            label="Back to QR Code"
            (click)="activeTab = 1"
            icon="pi pi-arrow-left"
            class="two-factor-setup-navigate"
        ></button>
    </div>
</p-dialog>