import { AfterContentInit, AfterViewInit, Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { element } from 'protractor';

import { getTextColorForBackground } from '../utilities/color.util';

interface ColorForBackgroundInput{
  elementSelector?: string;
  backgroundColor: string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[colorForBackground]'
})
export class ColorForBackgroundDirective implements AfterContentInit {

  // Can be a class, with a . selector, or and id, or and element name
  @Input('colorForBackground') input: ColorForBackgroundInput;

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngAfterContentInit(): void {
    let elementToStyle;

    if (!this.input.elementSelector){
      elementToStyle = this.hostElement.nativeElement;
    } else {
      elementToStyle = this.hostElement.nativeElement.querySelector(this.input.elementSelector);
    }

    if (!this.input.backgroundColor){
      this.input.backgroundColor = elementToStyle?.style?.backgroundColor;
    }

    if (!this.input.backgroundColor){
      return;
    }

    const textColor = getTextColorForBackground(this.input.backgroundColor);

    this.renderer.setStyle(elementToStyle, 'color', textColor);
  }
}
