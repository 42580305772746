import { on } from '@ngrx/store';

import { cloneDeep } from 'lodash';
import { CONFIGS_KEYS, DEFAULT_INVENTORY, FIELD_CONFIG, JOB_CATEGORIES, JOB_FORM_FIELDS, zoneSensitiveChanges } from 'src/app/global.constants';
import { safeParseJSON } from 'src/app/js';
import { getConfigValueByKey } from 'src/app/utilities/configs.util';
import { LoadingState } from 'src/app/utilities/state.util';

import { JobToolState } from '../../job-tool.reducer';

import { defaultJobState, generateFieldsInputFromLatestChanges, generateInputFromLatestCustomerChanges, generateInventoryInputFromLatestChanges, generateJobInputFromLatestChanges, generateJobInputMetadataFromLatestChanges, generateLocationsInputsFromLatestChanges, generateTimeSlotFromLatestChanges, trackChanges } from '../../jobsv2-helpers';

import { JobCreateActions } from './jobv2-create.actions';


export const jobCreateReducers = [
    on(JobCreateActions.loadJobConfigs, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            jobConfigsToLoadKeys: res.jobConfigsKeys,
            callState: {
                ...state.callState,
                loadJobConfigs: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.loadJobConfigsSuccess, (state: JobToolState, res): JobToolState => {
        let howHeardOptions;
        let howHeardMandatoryBoolean;
        let dwellingTypeMandatoryBoolean;
        let bedroomsMandatoryBoolean;
        let inventoryItems;
        let parsedOptions = [];
        let parsedInventory = [];
        let closedReasons;
        let parsedClosedReasons = [];
        let roleCustomer;
        let yemboEnabled;

        if (res.configValues.length) {
            howHeardOptions = getConfigValueByKey(res.configValues, CONFIGS_KEYS.howHeardOptions);
            inventoryItems = getConfigValueByKey(res.configValues, CONFIGS_KEYS.inventoryItems);
            closedReasons = getConfigValueByKey(res.configValues, CONFIGS_KEYS.closedReasons);
            roleCustomer = getConfigValueByKey(res.configValues, CONFIGS_KEYS.roleCustomer);

            howHeardMandatoryBoolean = safeParseJSON(getConfigValueByKey(res.configValues, CONFIGS_KEYS.howHeardMandatory), false);
            dwellingTypeMandatoryBoolean = safeParseJSON(getConfigValueByKey(res.configValues, CONFIGS_KEYS.dwellingTypeMandatory), false);
            bedroomsMandatoryBoolean = safeParseJSON(getConfigValueByKey(res.configValues, CONFIGS_KEYS.bedroomsMandatory), false);

            parsedOptions = safeParseJSON(howHeardOptions, []);
            parsedInventory = safeParseJSON(inventoryItems, []);
            parsedClosedReasons = safeParseJSON(closedReasons, []);

            yemboEnabled = safeParseJSON(getConfigValueByKey(res.configValues, CONFIGS_KEYS.yemboEnabled), false);
        }

        return {
            ...state,
            jobConfigs: {
                ...state.jobConfigs,
                ...(howHeardMandatoryBoolean !== undefined && { [CONFIGS_KEYS.howHeardMandatory]: howHeardMandatoryBoolean }),
                ...(dwellingTypeMandatoryBoolean !== undefined && { [CONFIGS_KEYS.dwellingTypeMandatory]: dwellingTypeMandatoryBoolean }),
                ...(bedroomsMandatoryBoolean !== undefined && { [CONFIGS_KEYS.bedroomsMandatory]: bedroomsMandatoryBoolean }),
                ...(roleCustomer !== undefined && { [CONFIGS_KEYS.roleCustomer]: roleCustomer }),
                [CONFIGS_KEYS.howHeardOptions]: parsedOptions,
                [CONFIGS_KEYS.inventoryItems]: parsedInventory,
                [CONFIGS_KEYS.closedReasons]: parsedClosedReasons,
                ...(yemboEnabled !== undefined && { [CONFIGS_KEYS.yemboEnabled]: yemboEnabled }),
            },
            callState: {
                ...state.callState,
                loadJobConfigs: LoadingState.LOADED,
            }
        };
    }),
    on(JobCreateActions.loadJobConfigsError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                loadJobConfigs: {
                    error: res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.resetZoneSensitiveInputs, (state: JobToolState): JobToolState => {
        const fieldToExclude = FIELD_CONFIG.customer.howHeard.name;
        const { [fieldToExclude]: _, ...remainingFields } = state.fieldsInput as { [key: string]: any };
        const existingCustomerSelected = state?.customerInput?.id;
        const filteredChanges = state?.changes?.filter(change => !zoneSensitiveChanges.includes(change.fieldName));
        return {
            ...state,
            changes: filteredChanges,
            validationErrors: (!existingCustomerSelected) ? state.validationErrors : {},
            suggestedCustomers: undefined,
            customerSearchInput: undefined,
            resolvedServiceArea: undefined,
            availableTimeSlots: undefined,
            jobConfigs: {},
            zone: undefined,
            locationsInputs: undefined,
            distances: undefined,
            selectedTimeSlot: undefined,
            fieldsInput: {
                ...remainingFields
            },
            jobTiming: {
                moving: {
                    totalLocationTime: 0,
                    totalTime: 0,
                    totalTravelTime: 0,
                    partialTravelTime: 0,
                }
            },

        }
    }),
    on(JobCreateActions.findTimesSuccess, (state: JobToolState, res): JobToolState => {
        const avails = [];

        for (const window of res.result.data.find.windows) {
            avails.push(...window.startTimes);
        }

        return {
            ...state,
            availableTimeSlots: avails,
            callState: {
                ...state.callState,
                findAvailableTimes: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.findTimesError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                findAvailableTimes: {
                    error: res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.selectTimeSlot, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.selectedTimeSlot,
            namespace: 'jobInput',
            value: res.timeSlot,
        });
        return {
            ...state,
            selectedTimeSlot: res.timeSlot,
            changes,
        }
    }),
    on(JobCreateActions.getCurrencySuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            currency: res.currency,
        }
    }),
    on(JobCreateActions.getCurrentUserSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            user: res.user,
        }
    }),
    on(JobCreateActions.selectClosedReason, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            closedReason: res.closedReason,
        }
    }),
    on(JobCreateActions.createCustomer, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createCustomer: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.createCustomerError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createCustomer: {
                    error: 'An error occurred during customer information saving. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.updateCustomer, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateCustomer: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.updateCustomerSuccess, (state: JobToolState): JobToolState => {
        //as update query doesn't return full customer info
        const editInput = { ...state.customerInput };
        return {
            ...state,
            customerInput: {
                ...editInput,
            },
            callState: {
                ...state.callState,
                updateCustomer: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.updateCustomerError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateCustomer: {
                    error: 'An error occurred during customer information saving. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.createJob, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createJob: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.createJobSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            jobInput: {
                ...state.jobInput,
                id: res.jobId,
            },
            callState: {
                ...state.callState,
                createJob: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.createJobError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createJob: {
                    error: 'An error occurred during job creation. Changes are not saved. ' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.setFields, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.setFieldsSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.setFieldsError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: {
                    error: 'An error occurred during saving job information. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.setTags, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.setTagsSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.setTagsError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: {
                    error: 'An error occurred during saving job information. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.discardJob, (state: JobToolState, res): JobToolState => {
        const prevLocationsInputs = state.locationsInputs?.dock;
        return {
            ...state,
            availableTimeSlots: [],
            closedReason: undefined,
            customerSearchInput: undefined,
            distances: {},
            fieldsInput: {},
            customerInput: undefined,
            validationErrors: {},
            inventoryInput: cloneDeep(DEFAULT_INVENTORY),
            jobInput: {
                //as selecting from UI is disabled now, predefine it here
                jobCategory: JOB_CATEGORIES[0],
                metadata: {},
            },
            jobId: undefined,
            jobTiming: {
                moving: {
                    totalLocationTime: 0,
                    totalTime: 0,
                    totalTravelTime: 0,
                    partialTravelTime: 0,
                }
            },
            locationsInputs: { dock: prevLocationsInputs },
            selectedTimeSlot: undefined,
            suggestedCustomers: [],
            resolvedServiceArea: undefined,
            editCustomerWhenCreateJobMode: false,
            jobTagsIds: [],
            changes: [],
        }
    }),
    on(JobCreateActions.retrieveChangesFromStorage, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            ...defaultJobState as Partial<JobToolState>
        }
    }),
    on(JobCreateActions.applyChangesFromStorage, (state: JobToolState, res): JobToolState => {;
        let changes = res?.retrievedState?.changes || [];
        const customerFromChanges = generateInputFromLatestCustomerChanges(changes);
        const jobFromChanges = generateJobInputFromLatestChanges(changes);
        const jobInputMetaFromChanges = generateJobInputMetadataFromLatestChanges(changes);
        const locationsInputsFromChanges = generateLocationsInputsFromLatestChanges(changes);
        const inventoryInputFromChanges = generateInventoryInputFromLatestChanges(changes);
        const fieldsInputFromChanges = generateFieldsInputFromLatestChanges(changes);
        const timeSlotFromChanges = generateTimeSlotFromLatestChanges(changes);

        //used to track if we have pending changes from create mode and display new / continue opportunity btn
        if (res.changes === 'create') {
            changes = [...changes, {
                fieldName: 'changes_from_create',
                namespace: 'changes_from_create',
                value: 'changes_from_create',
            }];
        }

        return {
            ...state,
            customerInput: {
                ...state.customerInput,
                ...customerFromChanges
            },
            jobInput: {
                ...state.jobInput,
                ...jobFromChanges,
                metadata: {
                    ...state.jobInput?.metadata,
                    ...jobInputMetaFromChanges,
                }
            },
            locationsInputs: {
                ...state.locationsInputs,
                ...locationsInputsFromChanges,
            },
            inventoryInput: {
                ...state.inventoryInput,
                ...(inventoryInputFromChanges.inventory ? inventoryInputFromChanges.inventory : {})
            },
            fieldsInput: {
                ...state.fieldsInput,
                ...fieldsInputFromChanges,
            },
            availableTimeSlots: res?.retrievedState?.availableTimeSlots ? [...res?.retrievedState?.availableTimeSlots] : [],
            selectedTimeSlot: timeSlotFromChanges.selectedTimeSlot ?? state.selectedTimeSlot,
            resolvedServiceArea: {
                ...state.resolvedServiceArea || res.retrievedState.resolvedServiceArea,
            },
            changes,
        };
    }),
] as const;
