<div [formGroup]="taxForm">
    <ng-template #name>
        <div *appVar="taxForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Name"
                formControlName="name"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #type>
        <div *appVar="taxForm.get('type') as control">
            <label>Type</label>
            <br />
            <p-dropdown
                [options]="taxTypes"
                appendTo="body"
                [editable]="true"
                placeholder="Select Type"
                formControlName="type"
            ></p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #description>
        <div *appVar="taxForm.get('description') as control">
            <label>Description</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Description"
                formControlName="description"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #active>
        <div *appVar="taxForm.get('active') as control">
            <label>Active</label>
            <br />
            <p-inputSwitch formControlName="active"></p-inputSwitch>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #zoneDefault>
        <div *appVar="taxForm.get('isZoneDefault') as control">
            <label>Zone Default</label>
            <br />
            <p-inputSwitch formControlName="isZoneDefault"></p-inputSwitch>
            <br>
            <small>Zone default taxes are applied by default when creating products, they can be overridden on a product by product basis.</small>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #percentage>
        <div *appVar="taxForm.get('percentage') as control">
            <div class="p-inputgroup">
                <p-inputNumber formControlName="percentage" mode="decimal" [minFractionDigits]="2" [step]="0.25" [max]="100" [min]="0"></p-inputNumber>
                <span class="p-inputgroup-addon">%</span>      
            </div>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #zones>
        <div 
        *appVar="taxForm.get('zones') as control"
        class="p-col-12">
            <app-assign-zones #zonesComponent formControlName="zones"></app-assign-zones>
        </div>
    </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="taxForm"
    objectType="Tax"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>