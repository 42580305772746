import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

import { AvailableZoneFragment, AvailableZonesGQL, CurrentBrandingQuery, CurrentZoneQuery } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';

import { BrandingService } from './branding.service';


@Injectable({
  providedIn: 'root'
})
export class AvailableZonesService {

  watchQuery: ReturnType<typeof this.availableZonesGQL.watch>;

  /**
   * An array of available zones, updated when the application loads
   * and are reauthenticated
   * but not when we change contexts
   */
  availableZones = new BehaviorSubject<AvailableZoneFragment[]>(undefined);

  /**
   * If there are no available zones, but the backend resolves a main zone to be
   * on a different domain, then this subject will  be set to that zone
   * so that NoAvailableZonesComponent can redirect to its domain
   */
  noAvailableZonesDomainRedirect = new BehaviorSubject<CurrentZoneQuery['currentZone']>(undefined);

  subs = new SubSink();

  constructor(
    private availableZonesGQL: AvailableZonesGQL,
    private branding: BrandingService,
    private router: Router,
    private authSvc: PlusAuthenticationService,
  ) { }

  reload() {
    if (this.authSvc.isDeauthed()) { return; }
    if (this.watchQuery) {
      this.watchQuery.setVariables(this.getVariables());
    }

    // TODO: refactor to grab highest levels first
    this.watchQuery = this.availableZonesGQL.watch(this.getVariables(), {
      fetchPolicy: 'cache-first',
    });

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      if (res.data) {
          this.availableZones.next(res.data.availableZones);
          this.checkIfNoAvailableZones();
      }
    });

    this.subs.sink = this.authSvc.authState.subscribe((state) => {
      if (state === 'deauthenticating') {
        delete this.watchQuery;
        this.subs.unsubscribe();
      }
    });
  }

  /**
   * If there are no available zones but
   * if the current zone returned with a domain set that
   * is NOT this domains main zone, then redirect the user to that
   * domain - avoiding a redirect loop
   *
   * See docs on domain zones
   */
  checkIfNoAvailableZones() {
    const availableZones = this.availableZones.value;
    if (!availableZones) { return; }
    if (availableZones.length) {
      return;
    }

    const currentZone = this.branding?.currentZone().value;
    if (!currentZone) { return; }

    if (window.location.hostname === currentZone.domain) {
      return;
    }

    console.log(`Invalid zone for domain:`, currentZone, currentZone.domain);
    this.noAvailableZonesDomainRedirect.next(currentZone);
    this.router.navigate([ '/no-available-zones' ]);
  }

  private getVariables() {
    return {
      skip: 0,
      limit: -1,
      search: '',
    };
  }
}
