import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { PlusAuthenticationService } from '@karve.it/core';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { SubSink } from 'subsink';

import { FullTransactionFragment, TransactionsGQL, TransactionsQuery, TransactionsQueryVariables } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-transaction-select',
  templateUrl: './transaction-select.component.html',
  styleUrls: ['./transaction-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TransactionSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TransactionSelectComponent,
    }
  ]
})
export class TransactionSelectComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {

  @Output() transactionsSelected = new EventEmitter();

  // Pass this to override current zone
  @Input() filter: TransactionsQueryVariables['filter'];

  @Input() removeTransactionsWithReciepts = false;

  subs = new SubSink();

  // Users Variables
  transactionQueryRef: QueryRef<TransactionsQuery>;
  transactionSuggestions: FullTransactionFragment[] = [];

  // FORM CONTROL VARIABLES
  selectedTransactions: FullTransactionFragment[] = [];
  touched = false;
  disabled = false;

  constructor(
    private plusAuth: PlusAuthenticationService,
    private transactionsGQL: TransactionsGQL,
    public responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.searchTransactions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchTransactions(){
    this.transactionQueryRef = this.transactionsGQL.watch(
      {filter: this.filter}
    );

    this.subs.sink = this.transactionQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.transactionSuggestions = res.data.transactions.transactions;
      if (this.removeTransactionsWithReciepts){
        this.filterOutSentArtifacts();
      }
    });
  }

  /**
   * Remove transactions that have a sent artifact
   */
  filterOutSentArtifacts(){
    this.transactionSuggestions = this.transactionSuggestions
    .filter((t) => !t.metadata?.artifactRecipientId);
  }

  emitSelect(){
    this.transactionsSelected.emit(this.selectedTransactions);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (user) => {};

  onTouched = () => {
    this.touched = true;
  };

  writeValue(transactions: FullTransactionFragment[]) {
    this.selectedTransactions = cloneDeep(transactions);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
