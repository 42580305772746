<div class="card" *ngIf="events$ | async as events">
  <div class="dashboard-card-header p-mb-1">
    <div>
      <h4 class="p-mb-1">Event Summary<app-error-icon *ngIf="events.error"></app-error-icon></h4>
      {{ events.period }}
    </div>
    <app-dashboard-info-v2>
      <div class="p-mb-2">
        <b>Events created:</b> Breakdown of all events created in the selected period into their various types.
      </div>
      <div class="p-mb-2">
        <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="events.createdQueryParams" target="_blank">View Report</a>.
      </div>
      <div class="p-mb-2">
        <b>Events scheduled:</b> Breakdown of all events ending in the selected period into their various types.
      </div>
      <div class="p-mb-2">
        <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="events.happeningQueryParams" target="_blank">View Report</a>.
      </div>
    </app-dashboard-info-v2>
  </div>
  <p-tabView>
    <p-tabPanel header="Created">
      <p-blockUI [target]="blockable" [blocked]="events.createdLoading" [autoZIndex]="false">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      </p-blockUI>
      <app-blockable #blockable>
        <ng-container *ngIf="!events.createdEmpty">
          <ng-container *ngTemplateOutlet="eventsTable; context: { events: events.eventsCreated }"></ng-container>
        </ng-container>
        <app-dashboard-empty-state *ngIf="events.createdEmpty" text="No events created during the selected period"></app-dashboard-empty-state>
      </app-blockable>
    </p-tabPanel>
    <p-tabPanel header="Scheduled">
      <p-blockUI [target]="blockable" [blocked]="events.happeningLoading" [autoZIndex]="false">
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      </p-blockUI>
      <app-blockable #blockable>
        <ng-container *ngIf="!events.happeningEmpty">
          <ng-container *ngTemplateOutlet="eventsTable; context: { events: events.eventsHappening }"></ng-container>
        </ng-container>
        <app-dashboard-empty-state *ngIf="events.happeningEmpty" text="No events scheduled during the selected period with the selected currency"></app-dashboard-empty-state>
      </app-blockable>
    </p-tabPanel>
  </p-tabView>
</div>

<ng-template #eventsTable let-events="events">
  <table class="events-table">
    <thead>
      <th *ngFor="let header of events.headers">{{ header }}</th>
    </thead>
    <tr *ngFor="let row of events.rows">
      <td *ngFor="let cell of row">{{ cell }}</td>
    </tr>
  </table>
</ng-template>
