import { Pipe, PipeTransform } from '@angular/core';

import { DiscountType, formatRangeAmount } from '../discounts/discounts.utils';

import { convertCentsToDollars } from '../lib.ts/currency.util';


@Pipe({
  name: 'discountCustomAmountRange'
})
export class DiscountCustomAmountRangePipe implements PipeTransform {

  transform(
    customAmountRange: { min: number | null; max: number | null } | number[],
    discountType: DiscountType,
    currency: string = 'CAD'
  ): string {

    if (!customAmountRange) {
      return 'None';
    }

    let min: number | null | undefined;
    let max: number | null | undefined;

    if (Array.isArray(customAmountRange)) {
      [ min, max ] = customAmountRange;

      if (discountType === 'amount') {
        min = convertCentsToDollars(min, true);
        max = convertCentsToDollars(max, true);
      }

    } else {
      ({ min, max } = customAmountRange);
    }

    if (!min && !max) {
      return 'None';
    }

    const formattedMin = formatRangeAmount(min, 'Min', discountType, currency);
    const formattedMax = formatRangeAmount(max, 'Max', discountType, currency);

    return `${formattedMin} - ${formattedMax}`;
  }
}
