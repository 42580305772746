<div class="exception-body notfound">
    <div class="exception-topbar">
        <!-- <a id="logolink" [routerLink]="['/']" class="layout-topbar-logo">
            <img [src]="'assets/layout/images/logo-' + (app.topbarTheme === 'dark' ? 'freya-white' : 'freya') + '.svg'" alt="freya-layout"/>
        </a> -->
    </div>
    <div class="exception-wrapper">
        <div class="exception-content">
            <span>Error</span>
            <span class="exception-subtitle">Can't log in here, no available zones</span>
            <a
                pButton pRipple
                label="Go to {{ redirectToName }}"
                [href]="redirectToLink"
                pTooltip="Perhaps you mean to login at a different domain?"
            ></a>
            <button pButton pRipple type="button" label="Logout" (click)="logout()"></button>
        </div>
        <div class="exception-footer">
            <!-- <h4>{{project}}</h4> -->
            <h6>Copyright Ⓒ {{copyright}}</h6>
        </div>
    </div>
</div>
