<div *ngIf="!discountsPerEvent" class="p-grid">
	<div class="p-col">
		<h5>Discounts</h5>
	</div>
	<div *hasPermission="['jobs.update']" class="p-col p-text-right">
		<span [pTooltip]="disabledTooltip" tooltipPosition="left">
			<button pButton [disabled]="archived || (!job | jobLocation : 'start')" icon="pi pi-plus"
				(click)="openApplyDiscountModal()"></button>
		</span>
	</div>
</div>
<p-table *ngIf="discountsToDisplay?.length" [value]="discountsToDisplay"
	[responsiveLayout]="!discountsPerEvent ? 'stack' : null"
	[breakpoint]="!discountsPerEvent ? '700px' : null">
	<ng-template pTemplate="header" *ngIf="discountsToDisplay?.length">
		<tr>
			<th *ngIf="discountsPerEvent" class="fixed-width hidden-column"><div></div></th>
			<th>Name / Code</th>
			<th>Discount</th>
			<th *ngIf="!discountsPerEvent">Event</th>
			<th *ngIf="discountsPerEvent" class="hidden-column"></th>
			<th>Value</th>
			<th>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-discount>
		<tr class="item-row">
			<td *ngIf="discountsPerEvent" class="fixed-width hidden-column"></td>
			<td (click)="openDiscount(discount)" >
				<span class="p-column-title">Name / Code</span>
				<div class="discount-name-wrapper">
					<span class="discount-name">{{
						discount.discount.name
						}}</span>
					<br />
					<span class="discount-code hidden-column">{{
						discount.discount.code
						}}</span>
				</div>
			</td>
			<td>
				<span class="p-column-title">Discount</span>
				{{ discount | discountCustomAmount : job.currency }}
			</td>
			<td *ngIf="!discountsPerEvent">
				<span class="p-column-title">Event</span>
				{{
				discount.event?.type
				}}
			</td>
			<td *ngIf="discountsPerEvent" class="hidden-column"></td>
			<td>
				<span class="p-column-title">Value</span>
				{{
				discount.discountedAmount | freyaCurrency : job.currency
				}}
			</td>
			<td style="text-align: right;">
				<span class="p-column-title"></span>
				<span [pTooltip]="
					disabledTooltip ||
					(lockedDiscounts[discount.appliedId] ? 'This event ends before ' + formattedLockDate : undefined) ||
					(discount.event | eventHasInvoice) && 'This discount has been invoiced'
					" tooltipPosition="left">
					<button pButton type="button" icon="pi pi-ellipsis-v" class="p-button-icon-only p-button-text ellipsis-button"
						(click)="setDiscountActions(discount); actionsMenu.toggle($event)"
						[disabled]="lockedDiscounts[discount.appliedId] || (discount.event | eventHasInvoice) || disabled">
					</button>
				</span>
			</td>
		</tr>
	</ng-template>
	<ng-template *ngIf="!discountsPerEvent" pTemplate="footer">
		<tr class="short-row">
			<td colspan="3" class="p-text-right">
				Total Discounts
			</td>
			<td colspan="2">
				{{
				job.discountTotal / -100
				| currency: job.currency
				}}
			</td>
		</tr>
	</ng-template>
	<ng-template *ngIf="!discountsPerEvent" pTemplate="emptymessage" let-car let-columns="columns">
		<tr>
			<td [attr.colspan]="'5'">
				This job has no discounts applied
			</td>
		</tr>
	</ng-template>
</p-table>

<p-menu #actionsMenu appendTo="body" [popup]="true" [model]="discountActions"></p-menu>

<p-dialog
    header="Apply Discount"
    [modal]="true"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [(visible)]="showApplyDiscountDialog"
    (onHide)="applyDiscountForm.reset()"
>
    <div [formGroup]="applyDiscountForm">
			<div class="p-grid p-fluid">
				<div class="p-col-12 p-md-4">
					<label>Discount</label>
					<br />
					<p-dropdown
							placeholder="Selected Discount"
							[options]="discounts"
							appendTo="body"
							formControlName="discount"
					>
						<ng-template pTemplate="item" let-discount>
							{{ discount.name }} ({{ discount.code }})
						</ng-template>
						<ng-template pTemplate="selectedItem" let-discount>
							{{ discount.name }} ({{ discount.code }})
						</ng-template>
					</p-dropdown>
				</div>
				<div class="p-col-12 p-md-4" *appVar="applyDiscountForm.get('amount') as control">
					<label>Amount
					</label>
					<br/>
					<p-inputNumber
						*ngIf="job?.currency || (job && defaultCurrency)"
						formControlName="amount"
						placeholder="Enter amount"
						[showButtons]="true"
						[step]="selectedDiscount?.discountType === 'percentage' ? 1 : 0.25"
						[mode]="selectedDiscount?.discountType === 'percentage' ? 'decimal' : 'currency'"
						[currency]="job?.currency || defaultCurrency"
						[minFractionDigits]="selectedDiscount?.discountType === 'amount' ? 1 : 0"
						[suffix]="selectedDiscount?.discountType === 'percentage' ? '%' : undefined"
						[min]="amountInputAtts.min"
						[max]="amountInputAtts.max"
					></p-inputNumber>
						<small *ngIf="selectedDiscount">
							Range: {{ selectedDiscount?.customAmountRange | discountCustomAmountRange : selectedDiscount?.discountType : (job.currency || defaultCurrency) }}
						</small>
					<app-validation-messages [control]="control"></app-validation-messages>
				</div>
				<div class="p-col-12 p-md-4">
					<label>Select Event</label>
					<br/>
					<app-event-select
							formControlName="events"
							[filter]="{jobId: job?.id}"
							[disabledEvents]="{
								locked: true,
								invoiced: true
							}" [showInvoiceInfo]="true" [preselectEventId]="currentEventId"></app-event-select>
			</div>
		</div>
	</div>
	<ng-template pTemplate="footer">
		<button pButton (click)="showApplyDiscountDialog = false" icon="pi pi-times" label="Cancel"></button>
		<button pButton (click)="openCreateDiscountDialog()" icon="pi pi-plus" label="New Single Use"></button>
		<button pButton [disabled]="!applyDiscountForm.valid" (click)="addDiscountToEvents()" icon="pi pi-check"
			label="Apply Changes" class="p-button-success"></button>
	</ng-template>
</p-dialog>

<!-- TODO: Remove this -->
<app-mutate-discount #mutate mutateType="create" [job]="job"
	(appliedToJob)="uniqueDiscountApplied()" [preselectEventId]="currentEventId"></app-mutate-discount>