<table
    class="financials"
    *ngIf="!loading; else loadingBar"
>
    <tr
        class="subtotal main-charge"
        pTooltip="Subtotal before damages, discounts, and taxes"
        tooltipPosition="left"
    >
        <td>Charges</td>
        <td>{{ (item.discountedSubTotalWithoutDamages + item.discountTotal) | freyaCurrency : item.currency }}</td>
    </tr>


    <!-- Discounts row -->
    <ng-container *ngIf="item?.discountTotal">
        <ng-container *ngTemplateOutlet="discountsRow"></ng-container>
        <tr
            class="subtotal"
            pTooltip="Subtotal before damages and taxes"
            tooltipPosition="left"
        >
            <td></td>
            <td>
                {{
                item.discountedSubTotalWithoutDamages
                | freyaCurrency : item.currency
                }}
            </td>
        </tr>
    </ng-container>

    <!-- Damages row -->
    <ng-container *ngIf="item?.damageTotal">
        <ng-container *ngTemplateOutlet="damagesRow"></ng-container>
        <tr
            class="subtotal"
            pTooltip="Subtotal before taxes"
            tooltipPosition="left"
        >
            <td></td>
            <td>{{ item.discountedSubTotal | freyaCurrency : item.currency }}</td>
        </tr>
    </ng-container>

    <!-- Taxes row -->
    <ng-container *ngIf="item?.taxTotal">
        <ng-container *ngTemplateOutlet="taxesRow"></ng-container>
    </ng-container>
    <tr
        class="total main-charge"
        pTooltip="Final Total"
        tooltipPosition="left"
    >
        <td><strong>Total</strong></td>
        <td>{{ item.total | freyaCurrency : item.currency }}</td>
    </tr>

    <ng-container *ngIf="showPaid">

        <tr
            pTooltip="Paid Total"
            tooltipPosition="left"
        >
            <td>Paid</td>
            <td>{{ -item.paidTotal | freyaCurrency : item.currency }}</td>
        </tr>

        <tr
            class="subtotal"
            pTooltip="Remaining balance to be paid"
            tooltipPosition="left"
        >
            <td>Balance</td>
            <td>
                {{
                item.total - item.paidTotal | freyaCurrency : item.currency
                }}
            </td>
        </tr>

    </ng-container>


    <tr class="confidential-info-row">
        <td
            colspan="2"
            style="text-align: end; text-decoration: underline; cursor: pointer;"
            (click)="showConfidentialInfo = !showConfidentialInfo"
        > {{ showConfidentialInfo ? 'Hide' : 'Show' }} - Office Information
        </td>
        <td></td>
    </tr>

    <ng-container *ngIf="showConfidentialInfo">

        <ng-container *hasPermission="['expenses.view']">
            <ng-container *ngIf="item?.total !== 0 && item?.expenseTotal !== 0">

                <tr
                    pTooltip="The expenses directly associated with this event/job"
                    tooltipPosition="left"
                >
                    <td><strong>Cost</strong></td>
                    <td>{{ item?.expenseTotal | freyaCurrency : item.currency }}</td>
                </tr>
                <tr
                    pTooltip="Gross Margin is your sub total minus your costs of goods sold (expenses)"
                    tooltipPosition="left"
                >
                    <td><strong>Gross Margin</strong></td>
                    <td>{{ (item?.discountedSubTotal - item?.expenseTotal) || 0 | freyaCurrency : item.currency }}</td>
                </tr>
                <tr
                    pTooltip="Gross profit margin is the difference between revenue and cost of goods sold, divided by revenue."
                    tooltipPosition="left"
                >
                    <td><strong>GPM</strong></td>
                    <td>{{ grossProfitMargin | number: '1.2-2' |formatAmount: 'percentage' }}</td>
                </tr>
            
            </ng-container>
        </ng-container>


        <ng-container *ngTemplateOutlet="!item?.discountTotal ? discountsRow : null"></ng-container>
        <ng-container *ngTemplateOutlet="!item?.damageTotal ? damagesRow : null"></ng-container>
        <ng-container *ngTemplateOutlet="!item?.taxTotal ? taxesRow : null"></ng-container>

    </ng-container>

</table>

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton
            width="100%"
            height="16px"
        ></p-skeleton>
    </div>
</ng-template>


<!-- Discounts row template -->
<ng-template #discountsRow>
    <tr>
        <td>Discounts</td>
        <td>{{ -item.discountTotal | freyaCurrency : item.currency }}</td>
    </tr>

</ng-template>

<!-- Damages row template -->
<ng-template #damagesRow>
    <tr>
        <td>Damages</td>
        <td>{{ item.damageTotal | freyaCurrency : item.currency }}</td>
    </tr>
</ng-template>

<!-- Taxes row template -->
<ng-template #taxesRow>
    <tr>
        <td>Taxes</td>
        <td>{{ item.taxTotal | freyaCurrency : item.currency }}</td>
    </tr>
</ng-template>