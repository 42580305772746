/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { PermissionService } from '../services/permission.service';
import { valueAsArray } from '../utilities/arrays.util';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {

  @Input('hasPermission') permissions: string[] | string;

  subs = new SubSink();
  show = false;

  // Variable Naming: https://stackoverflow.com/questions/41789702/how-to-use-angular-structural-directive-with-multiple-inputs
  private showByDefault = false;
  @Input('hasPermissionShowByDefault')
  set hasPermissionShowByDefault(value: boolean) {
    this.showByDefault = value;
  };

  constructor(
    private permissionHandler: PermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    // Show or hide the component based on the passed values
    if (this.showByDefault) {
      this.showComponent();
    } else {
      this.removeComponent();
    }

    // Return and optionally log a warning if we don't have permissions
    if (!this.permissions?.length) {
      if (!this.showByDefault && !environment.production) { // We shouldn't be getting any empty permission arguments
        console.warn('No permissions provided to hasPermission directive, provide permissions or set showByDefault to true');
      }
      return;
    }

    this.subs.sink = this.permissionHandler.watchAllPermissions(this.permissions)
      .subscribe((res) => {
        const isShowing = this.show;
        this.show = Boolean(res);

      // If we want to show the element and it's not already shwoing
      if (this.show && !isShowing) {
        this.showComponent();
      }

      // If we don't want to show the element
      if(!this.show) {
        this.removeComponent();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const viewRef = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    );
    viewRef.markForCheck();
  }

  disableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    viewRootElement.setAttribute('style', 'background-color: grey');
    this.renderer.setProperty(viewRootElement, 'disabled', true);
  }

  enableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement = this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
    this.renderer.setProperty(viewRootElement, 'disabled', false);
  }
}
