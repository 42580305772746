import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { BaseTokenFragment } from '../../../generated/graphql.generated';
import { MutateLeadSourceComponent } from '../../shared/mutate-lead-source/mutate-lead-source.component';

@Component({
  selector: 'app-lead-source-details',
  templateUrl: './lead-source-details.component.html',
  styleUrl: './lead-source-details.component.scss'
})
export class LeadSourceDetailsComponent implements OnInit {

  @ViewChild('mutate') mutateRef: MutateLeadSourceComponent;

  @Input() leadSource: BaseTokenFragment;

  showInstructions = false;

  url: string;

  actions = [{
    label: 'Actions',
    items: [
      {
        label: 'View Instructions',
        icon: 'pi pi-book',
        command: () => {
          // show dialog
          this.showInstructions = true;
        },
      }
    ]
  }];

  ngOnInit(): void {
    this.url = this.generateURL();
  }

  generateURL() {

    const url = new URL(location.href);
    url.search = '';
    url.pathname = '/api/intake/token';
    url.searchParams.set('token', this.leadSource.token);

    return url.toString();
  }

  // TODO: view instructions
}
