<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="taxes-card-header">Taxes</h4>
        </div>
        <div class="p-col">
            <div style="white-space: nowrap;">
                <span class="p-input-icon-left" style="display: inline-block;">
                    <i
                        *ngIf="!taxesQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="taxesQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #taxSearchInput
                        pInputText
                        [(ngModel)]="taxesQH.search"
                        placeholder="Search"
                        data-cy="search-text-field"
                    >
                </span>
                <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="taxesQH.search = ''; searchForTaxes()"
                ></button>
            </div>
        </div>
        <div class="p-col">      
            <button 
                *hasPermission="['taxes.create']" 
                pButton label="Create" 
                icon="pi pi-plus" 
                (click)="openCreateDialog()" 
                data-cy="create-tax-button"
            ></button>
        </div>
    </div>
</div>

<br>

<div class="card">
    <p-table
        [value]="taxes"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreTaxes($event)"
        [lazy]="true"
        [lazyLoadOnInit]="true"
        [(rows)]="taxesQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        breakpoint="620px"
        [loading]="taxesQH.loading"
        [totalRecords]="taxesQH.total"
        [showCurrentPageReport]="!taxesQH.loading"
    > 
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Value</th>
                <th>Zones</th>
                <th>Zone Default</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tax>
            <tr class="selectable-row" (click)="selectTax(tax)">
                <td>
                    <span class="p-column-title">Name:</span>
                    <span class="bold-on-mobile">{{tax.name}}</span>
                </td>
                <td>
                    <span class="p-column-title">Type:</span>
                    {{tax.type}}
                </td>
                <td>
                    <span class="p-column-title">Value:</span>
                    {{tax.percentage}}%
                </td>
                <td>
                    <span class="p-column-title">Zones:</span>
                    {{tax.zones?.nodes?.length}}
                </td>
                <td>
                    <span class="p-column-title">Zone Default:</span>
                    {{tax.isZoneDefault ? 'Yes' : 'No'}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-car let-columns="columns">
            <tr>
                <td [attr.colspan]="'6'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-mutate-tax mutateType="create" #mutate></app-mutate-tax>