import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

import { prettyProperty } from '../utilities/generic.util';

@Pipe({
  name: 'prettyProperty'
})
export class PrettyPropertyPipe implements PipeTransform {

  constructor(){}

  // Convert a propety key into a pretty string.
  // Eg. timeOfDay = "Time Of Day"
  transform(value: string, addSemiColon = false): string {
    return prettyProperty(value, addSemiColon);
  }

}
