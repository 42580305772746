import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { ListIntakeTokensGQL } from '../../../../generated/graphql.generated';

import { LeadSourceActions } from './lead-source.actions';

export const leadSourceLoad = createEffect(
    (
		actions$ = inject(Actions),
		listIntakeTokensGQL$ = inject(ListIntakeTokensGQL),
	) => {
        return actions$.pipe(
            ofType(LeadSourceActions.created, LeadSourceActions.load),
            exhaustMap((action) => {
				const fetchFromNetwork = ['[Lead Source] Created'].includes(action.type);

                return listIntakeTokensGQL$.fetch({}, {
					fetchPolicy: fetchFromNetwork ? 'network-only' :'cache-first',
				}).pipe(
                    map((res) => {
                        if (res.loading || !res?.data) {
                            return LeadSourceActions.loading();
                        }

                        return LeadSourceActions.loaded({
							leadSources: res.data?.tokens?.nodes,
                        });
                    }),
                    catchError((error) => {
                        return of(LeadSourceActions.loadError({ error }));
                    }),
                );
            }),
        );
    },
    { dispatch: true, functional: true },
);
