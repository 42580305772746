import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';

import { AvailabilityTemplatesComponent } from '../availability/availability-templates/availability-templates.component';
import { AvailabilityComponent } from '../availability/availability.component';
import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { LibModule } from '../lib.ts/lib.module';
import { SharedModule } from '../shared/shared.module';

import { AccountingComponent } from './accounting/accounting.component';
import { BusinessComponent } from './business/business.component';
import { BusinessGeneralComponent } from './business-general/business-general.component';
import { DiscountingRulesComponent } from './discounting-rules/discounting-rules.component';
import { EstimatingItemsComponent } from './estimating-items/estimating-items.component';
import { FranchiseInventoryItemsComponent } from './franchise-inventory-items/franchise-inventory-items.component';
import { ItemsNotMovedComponent } from './items-not-moved/items-not-moved.component';
import { RulesComponent } from './rules/rules.component';
import { RulesSettingsComponent } from './rules-settings/rules-settings.component';
import { SystemRulesComponent } from './system-rules/system-rules.component';
import { TaxDetailsComponent } from './taxes/tax-details/tax-details.component';
import { TaxesComponent } from './taxes/taxes.component';
import { AreaDetailsComponent } from './zone-areas/area-details/area-details.component';
import { ZoneAreasComponent } from './zone-areas/zone-areas.component';


// FullCalendarModule.registerPlugins([
    // dayGridPlugin,
    // timeGridPlugin,
    // resourceTimelinePlugin,
    // interactionPlugin,
// ]);

@NgModule({
  declarations: [
    AvailabilityComponent,
    AvailabilityTemplatesComponent,
    DiscountingRulesComponent,
    BusinessComponent,
    ItemsNotMovedComponent,
    ZoneAreasComponent,
    EstimatingItemsComponent,
    AreaDetailsComponent,
    SystemRulesComponent,
    TaxesComponent,
    TaxDetailsComponent,
    RulesComponent,
    RulesSettingsComponent,
    BusinessGeneralComponent,
    FranchiseInventoryItemsComponent,
    AccountingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FullCalendarModule,
    FreyaCommonModule,
    LibModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
    AreaDetailsComponent,
    TaxDetailsComponent
  ]
})
export class FranchiseModule { }
