<p-table #table
	*ngIf="(reportTypeId || dynamicReportId); else loadingBarBig"
    [value]="scheduledReports"
    (onLazyLoad)="onLazyLoad($event)"
    [lazyLoadOnInit]="load"
    [lazy]="load"
    [loading]="loading"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Name</th>
            <th>Zone</th>
            <th>Last Run</th>
            <th>Next Run</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-sr
    >
        <tr
            class="selectable-row"
            (click)="select(sr)"
        >
            <td>
                {{ sr.name }}
			</td>
            <td>
                <app-zone-chip
                    *ngFor="let zone of sr.zones.nodes"
                    [zone]="zone"
                ></app-zone-chip>
            </td>
            <td
                *ngIf="sr.lastRun"
                [pTooltip]="sr.lastRun * 1000 | appropriateTimeMeasurement"
            >
                {{ sr.lastRun | freyaDate : dateFormat }}
			</td>
            <td
                *ngIf="!sr.lastRun"
            >
                Not yet run
			</td>
            <td
                *ngIf="sr.nextRun"
                [pTooltip]="sr.nextRun * 1000 | appropriateTimeMeasurement"
            >
                {{ sr.nextRun | freyaDate : dateFormat }}
			</td>
            <td
                *ngIf="!sr.nextRun"
            >
                Will not run again
			</td>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="emptymessage"
        let-car
        let-columns="columns"
    >
        <tr>
            <td
				*ngIf="loading"
				[attr.colspan]="'6'"
				class="empty"
			>
				<ng-container *ngTemplateOutlet="loadingBarBig"></ng-container>
            </td>
            <td
				*ngIf="!loading"
				[attr.colspan]="'6'"
				class="empty txt"
			>
				
                Nothing scheduled
				<!-- <br /> -->
				<!-- Create scheduled report -->
            </td>
        </tr>
    </ng-template>
</p-table>

<ng-template #loadingBarBig>
    <div class="p-p-2">
        <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
</ng-template>
