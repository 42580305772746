import { gql } from 'graphql-tag';

import { User, UserRole } from './auth';
import { Metadata } from './metadata';
import { Zone } from './zones';

export interface Token {
  id: string;
  createdAt?: number;
  token?: string;
  tokenType?: string;
  expiration?: number;
  emails?: string[];
  title?: string;
  validOn?: number;
  roles?: UserRole[];
  zones?: Zone[];
  user?: User;
  isValid?: boolean;
  maxUses?: number;
  metadata?: string;
}

export interface CreateMagicLinkInput {
    name: string;
    roles: string[];
    title: string;

    // Unix times as integers
    startsOn: number;
    expiresOn: number;
}

export const createMagicLink =  gql`
mutation createMagicLink($name: String!, $roles: [String]!, $startsOn: Int!, $expiresOn: Int!, $title: String!) {
  tokens{
    createMagicLink(name: $name, roles: $roles, startsOn: $startsOn, expiresOn: $expiresOn, title: $title)
  }
}
`;

export interface MagicLink {
    name?: string;
    title?: string;
    expiresOn?: number;
    startOn?: number;
    role: string;
}

export const viewMagicLinks = gql`
query listMagicLinks{
    tokens{
        viewMagicLinks{
          name
          title
          expiresOn
          startsOn
          role
        }
    }
}
`;

export const redeemMagicLink = gql`
  mutation redeemMagicLink($token: String!, $fName: String!, $lName: String!, $email: String!, $password: String!){
  tokens{
    redeemMagicLink(token:$token, fName:$fName, lName:$lName, email:$email, password: $password){
      givenName
    }
  }
}
`;

export const checkMagicLink = gql`
query checkMagicLink($token: String!){
    tokens{
      checkMagicLink(token: $token)
    }
}
`;

export const expireMagicLink = gql`
mutation expireMagicLink($token: String!){
    tokens{
        expireMagicLink(token:$token)
    }
}
`;

export const validateToken = gql`
query validateToken($token: String, $tokenType: String, $tokenId: String) {
  validateToken(
    token: $token,
    tokenType: $tokenType,
    id: $tokenId
  ) {
    isValid
    error
    tokenType
    expiration
    metadata
    userId
  }
}
`;

export interface ValidateTokenInput {
  token?: string;
  tokenType?: string;
  id?: string;
}

export interface ValidateTokenOutput {

  validateToken: {
    isValid: boolean;
    error?: string;
    tokenType: string;
    expiration?: number;
    // if provided, can be parse from json
    metadata?: Metadata;
    // the ID of the user that this token relates to
    userId?: string;
  };
}

export interface CreateInviteTokenInput {
  token?: string;
  maxUses?: number;
  validOn?: number;
  expiration?: number;
  roles?: string[];
  zones?: string[];
  applyRoleZones?: boolean;
  emailsToInvite?: string[];
  userId?: string;
  sendToken?: boolean;
}

export interface CreateInviteTokenOutput {
  createInviteToken: Token;
}

export const refreshTokensBaseGQL = `
  id
  active
  ipAddress
  deviceType
  expiration
  lifetime
  createdAt
  lastUsed
`;


export const tokensbaseGQL = `
  id
  token
  tokenType
  expiration
  title
  validOn
  isValid
  maxUses
`;

export const createInviteTokenMutation = gql`
  mutation createInviteToken( $token: String, 
                              $maxUses: Int,
                              $validOn: Int,
                              $expiration: Int,
                              $roles: [String!],
                              $zones: [String!],
                              $applyToRoleZones: Boolean,
                              $emailsToInvite: [String],
                              $userId: String,
                              $sendToken: Boolean){
    createInviteToken(token: $token, 
                      maxUses: $maxUses,
                      validOn: $validOn,
                      expiration: $expiration,
                      roles: $roles,
                      zones: $zones,
                      applyToRoleZones: $applyToRoleZones,
                      emailsToInvite: $emailsToInvite,
                      userId: $userId,
                      sendToken: $sendToken){
                        id
                        createdAt
                        token
                        tokenType
                        expiration
                        emails
                        title
                        validOn
                        roles{
                          id
                        }
                        zones{
                          id
                        }
                        user{
                          id
                        }
                        isValid
                        maxUses
                        metadata
                      }
                    }
`;


export const redeemInviteToken = gql`
mutation redeemInviteToken(
  $token: String!,
  $email: String,
  $password: String,
  $givenName: String,
  $familyName: String
) {
  redeemInviteToken(
    token: $token,
    email: $email,
    password: $password,
    givenName: $givenName,
    familyName: $familyName,
  ) {
    userId
    emailConfirmationRequired
  }
}
`;

export interface RedeemInviteTokenInput {
  token: string;
  email?: string;
  password?: string;
  givenName?: string;
  familyName?: string;
}

export interface RedeemInviteTokenOutput {
  redeemInviteToken: {
    userId: string;
    emailConfirmationRequired?: boolean;
  };
}

export const retrieveRefreshTokensQuery = gql`
query refreshTokens(
  $userId: String!
) {
  users(
    filter: {
      userIds: [ $userId ]
    }
  ) {
    refreshTokens(
      filter: {},
      sort: "createdAt:DESC"
      limit: 50
    ) {
      total
      skipped
      limited
      refreshTokens {
        id
        active
        ipAddress
        deviceType
        expiration
        lifetime
        createdAt
        lastUsed
      }
    }
  }
}
`;

export interface RefreshToken {
  id: string;
  active: boolean;
  ipAddress: string;
  deviceType: string;
  expiration: number;
  lifetime: number;
  createdAt: number;
  lastUsed: number;
}

export interface RetrieveRefreshTokensResult {
  users: {
    refreshTokens: {
      total: number;
      skipped: number;
      limited: number;
      refreshTokens: RefreshToken[];
    };
  }[];
}
