import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardBorderValue'
})
export class CardBorderValuePipe implements PipeTransform {

  transform(color: string, pixels = 12): unknown {
    if(!color) {
      color = '#425563';
    }
    return `${color} solid ${pixels}px`;
  }

}
