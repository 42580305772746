import { gql } from 'graphql-tag';

export const updateClock = gql`
mutation updateClock(
  $appointmentIds: [String!]!,
  $user: String,
  $start: Int
  $end: Int
  $status: String
  $resetStart: Boolean
  $resetEnd: Boolean
  $resetAll: Boolean
) {
    appointments (search: {
      ids: $appointmentIds
    }) {
    clock(input: {
      userId: $user
      start: $start
      resetStart: $resetStart
      end: $end
      resetEnd: $resetEnd
      status: $status
      reset: $resetAll

    }) {
        start
        end
        clockId
        status
    }
  }
}
`;

export interface UpdateClockInput {
    appointmentIds: string[];
    user?: string;
    start?: number;
    end?: number;
    status?: string;
    resetStart?: true;
    resetEnd?: true;
    resetAll?: true;
}

export interface UpdateClockOutput {
    appointments: {
        clock: {
            start: number;
            end: number;
            clockId: string;
            status: string;
        }[];
    }[];
}


export const fetchClocks = gql`
query fetchClocks(
  $appointments: [String!]
  $users: [String!]
  $status: String
  $min: Int
  $max: Int
  $roles: [String!]
) {

  clocks(input: {
    appointIds: $appointments
    userIds: $users
    status: $status
    min: $min
    max: $max
    roles: $roles

  }) {
    userId
    appointId
    clockId
    role
    clock_start
    clock_end
    status
    paidOn
  }
}

`;

export interface FetchClocksInput {
    appointments?: string[];
    users?: string[];
    status?: string;
    min?: number;
    max?: number;
    roles?: string[];
}


export interface FetchClocksClock {
    userId: string;
    appointId: string;
    clockId?: string;
    role: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clock_start?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clock_end?: string;
    status?: string;
    paidOn?: number;
}

export interface FetchClocksOutput {
    clocks: FetchClocksClock[];
}

export const retrieveAvailableClockStatuses = gql`
query retrieveAvailableClockStatuses {
  availableClockStatuses {
    status
    name
    attributes
  }
}
`;

export interface RetrieveAvailableClockStatusOutput {
  availableClockStatuses: AvailableClockStatus[];
}

export interface AvailableClockStatus {
  status: string;
  name: string;
  attributes: string[];
}
