<div class="p-grid">
    <div class="p-col">
        <p-multiSelect
        [options]="zoneOptions"
        (onSelect)="emitSelect()"
        class="full-width-multiselect"
        (focus)="touched = true"
        [(ngModel)]="selectedZones"
        (ngModelChange)="onChange(selectedZones)"
        [selectionLimit]="limit"
        [showToggleAll]="false"
        optionLabel="name"
        appendTo="body"
        dataKey="id"
        [placeholder]="placeholder"
        [maxSelectedLabels]="1" 
        [selectedItemsLabel]="'{0} Zones Selected'"
            [autofocusFilter]="!responsiveHelper.isSmallScreen"
    >
        <ng-template
            let-zone
            pTemplate="item"
        >
            <div class="country-item">
                <div>{{zone.name}}</div>
            </div>
        </ng-template>
    </p-multiSelect>
    <br>
    <small *ngIf="limit">This input is limited to selecting <b>{{limit}}</b> zones.</small>
    </div>
    <div class="p-col" *ngIf="showChips">
        <span class="chip-container">
            <p-chip
                *ngFor="let zone of selectedZones"
                [style]="{'background-color': zone.color}"
                [removable]="true"
                (onRemove)="unselectZone(zone)"
                [label]="zone.name"
            >
            </p-chip>
        </span>
    </div>
    
</div>