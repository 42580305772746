import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';


import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { LibModule } from '../lib.ts/lib.module';
import { NotAuthenticatedGuard } from '../shared/guards/not-authenticated.guard';

import { SharedModule } from '../shared/shared.module';

import { AppLoginComponent } from './app.login.component';


import { AuthFooterComponent } from './auth-footer/auth-footer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'login',
    component: AppLoginComponent,
    canActivate: [ NotAuthenticatedGuard ],
    data: {
      customTitle: 'Login',
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      customTitle: 'Reset Password',
    },
  },
  {
    path: 'signup',
    component: SignUpComponent,
    data: {
      customTitle: 'Sign up',
    },
  },
];

@NgModule({
  declarations: [
    AppLoginComponent,
    SignUpComponent,
    ResetPasswordComponent,
    AuthFooterComponent,
  ],
  imports: [
    FreyaCommonModule,
    LibModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class AuthenticationModule { }
