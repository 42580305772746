


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { default as urlJoin } from 'url-join';

import { environment } from '../environments/environment';

import { AppTheme, ColorScheme, defaultColorScheme, isColorScheme } from './colors';


@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {

  themesFolder = '/assets/theme';
  layoutFolder = '/assets/layout/css';

  colorScheme = new BehaviorSubject<ColorScheme>('light');
  appTheme = new BehaviorSubject<AppTheme>('ymm');

  constructor() {
    this.setColorScheme(defaultColorScheme);
  }

  get darkMode() {
    return this.colorScheme.value === 'dark';
  }

  get lightMode() {
    return this.colorScheme.value === 'light';
  }

  setColorScheme(
    scheme?: ColorScheme,
  ) {

    if (!isColorScheme(scheme)) {
      scheme = this.darkMode ? 'light' : 'dark';
    }

    const layoutHref = urlJoin(this.layoutFolder, `layout-${ scheme }.css?v=${ environment.version }`);
    const themeHref = urlJoin(this.themesFolder, this.appTheme.value, `theme-${ scheme }.css?v=${ environment.version }`);

    this.setStylesheetHrefByLinkId('layout-css', layoutHref);
    this.setStylesheetHrefByLinkId('theme-css', themeHref);

    localStorage.setItem('color-scheme', scheme);

    this.colorScheme.next(scheme);
  }

  /**
   * Set the stylesheet href by ID, updating a stylesheet and removing old styles
   *
   * @param stylesheetID the html ID of the stylesheet
   * @param href the href to set for the stylesheet
   * must be a valid link or the old stylesheet won't be removed
   */
  setStylesheetHrefByLinkId(stylesheetID: string, href: string) {
    const linkElement = document.getElementById(stylesheetID) as HTMLLinkElement;
    console.log('loaded', stylesheetID, href);

    if (this.isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const cloneLinkElement = linkElement.cloneNode(true) as HTMLLinkElement;

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', stylesheetID + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', stylesheetID);
      });
    }
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

}
