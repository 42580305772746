import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ZoneService } from '@karve.it/core';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { ListUsersV2Output } from '@karve.it/interfaces/users';
import { GenerateZonesQueryInput, Zone, ZonesInput } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';

import * as _ from 'lodash';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-assign-zones',
  templateUrl: './assign-zones.component.html',
  styleUrls: ['./assign-zones.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: AssignZonesComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AssignZonesComponent
    }
  ]
})
// When Implementing with a form use FreyaHelper.saveZoneChanges to set the values
export class AssignZonesComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy  {

  @Output() zoneSelected = new EventEmitter();

  @Input() objectId: string; // REQUIRED
  @Input() limit: number; // The amount of options they can select
  @Input() zoneTypes: string[];
  @Input() placeholder = '';
  @Input() zoneDir: ZoneDir = ZoneDir.lte;

  @Input() showChips = true;

  subs = new SubSink();

  // Zone Variables
  zoneQueryRef: QueryRef<ListUsersV2Output>;
  zoneOptions: Zone[] = [];

  // FORM CONTROL VARIABLES
  selectedZones: Zone[] = [];
  touched = false;
  disabled = false;

  startingZones: Zone[] = [];

  constructor(
    private zoneService: ZoneService,
    public responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.retrieveZone();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  retrieveZone() {
    const listZonesInput = {
      filter: {
        zoneDir: this.zoneDir,
        types: this.zoneTypes || undefined,
      },
      limit: -1,
    } as ZonesInput;

    const zonesQueryInput = {
      attributes: true,
    } as GenerateZonesQueryInput;

    this.subs.sink = this.zoneService.watchZones(listZonesInput, zonesQueryInput).valueChanges.subscribe((res) => {
      if (!res.data) { return; }
      this.zoneOptions = res.data.zones.nodes;

      if (this.selectedZones?.length){
        this.selectedZones = this.zoneOptions.filter((zo) => this.selectedZones.find((sz) => sz.id === zo.id));
      }
    });
  }

  emitSelect(){
    this.zoneSelected.emit(true);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (selectedZones) => {};

  onTouched = () => {};

  writeValue(zones: Zone[]) {
    this.selectedZones = _.cloneDeep(zones);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  unselectZone(zone: Zone){
    const index = this.selectedZones.findIndex((z) => z.id === zone.id);

    this.selectedZones.splice(index, 1);

    this.selectedZones = [...this.selectedZones]; // Force an NgModel Change
    setTimeout(() => {
      this.onChange(this.selectedZones);
    }, 100);
  }
}
