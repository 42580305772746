<div class="display-tags-container" #container>
    <p-inplace
        #tagInplace
        (click)="handleClick($event, beneath)"
        [disabled]="readonly"
    >
        <ng-template pTemplate="display">
            <span class="tag-container" data-cy="tag-container">
                <p-tag
                    *ngFor="let tag of tagsOnObject"
                    [style]="{'background-color': tag.color}"
                    [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
                >
                    <span>{{tag | formatTag}}</span>
                </p-tag>
            </span>
            <span *ngIf="!tagsOnObject?.length && !objectTagQH.loading">
               + Click to add status tags
            </span>
            <span *ngIf="objectTagQH.loading">
                <p-skelton height="24px"></p-skelton>
            </span>
        </ng-template>
        <ng-template pTemplate="content">
                <p-chips
                #chips
                [(ngModel)]="tagsOnObject"
                placeholder="Search for tags"
                (keydown.enter)="handleEnterEvent()"
                (keydown.backspace)="handleBackspaceEvent()"
                (input)="searchUpdated($event); openAutocomplete($event, beneath)"
                (onRemove)="removeTagFromObject($event.originalEvent, $event.value)"
                (onChipClick)="checkLog($event)"
            >
                <ng-template
                    let-tag
                    pTemplate="chip"
                >
                    <span [style]="{'background-color': tag.color}" [colorForBackground]="{backgroundColor: tag.color}" class="tag-in-input" >
                        {{tag | formatTag}}
                    </span>
                </ng-template>
            </p-chips>
        </ng-template>
    </p-inplace>
</div>
<div #beneath></div>

<p-overlayPanel #tagsAutocomplete [dismissable]="false" [showCloseIcon]="true">
    <ng-template pTemplate>
        <div class="tags-autocomplete">
            <span class="tag-container">
                <div *ngIf="!tagSearchQH.loading">
                    <p-tag
                        *ngFor="let tag of tagSuggestions"
                        (click)="addTagToObject(tag);"
                        [style]="{'background-color': tag.color}"
                        [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
                        class="selectable-tag"
                    >
                    <span>{{tag | formatTag}}</span>
                    </p-tag>
                </div>
                <div *ngIf="tagSearchQH.loading">
                    <p-skeleton
                        width="80px"
                        height="20px"
                    ></p-skeleton>
                    <p-skeleton
                        width="90px"
                        height="20px"
                    ></p-skeleton>
                    <p-skeleton
                        width="100px"
                        height="20px"
                    ></p-skeleton>
                    <p-skeleton
                        width="70px"
                        height="20px"
                    ></p-skeleton>
                    <p-skeleton
                        width="100px"
                        height="20px"
                    ></p-skeleton>
                </div>
                <div *ngIf="!tagSuggestions?.length && !tagSearchQH.loading">
                    No tags match your search
                </div>
                <small *ngIf="tagSuggestions?.length">
                    Showing {{ tagSuggestions.length }} of {{ totalTags }}
                </small>
            </span>
            <button
                pTooltip="Show more"
                *ngIf="tagSuggestions?.length && tagSuggestions.length < totalTags"
                (click)="$event.stopPropagation(); loadMore()"
                pButton
                type="button"
                class="p-button-rounded"
                style="position: absolute; bottom: -2rem; right: 50%; transform: translateX(50%)"
                icon="pi pi-caret-down"
                ></button>
        </div>
    </ng-template>
</p-overlayPanel>

<!-- <button type="text" pButton label="Custom Target" (click)="op.show($event, actualTarget)"></button>
<div #actualTarget></div> -->
