
<div class="header-with-button">
    <div>
        <h4><i [class]="assetIcon"></i> Asset</h4>
        <app-object-state
            [object]="asset"
            objectType="asset"
            [colorful]="true"
            [loading]="assetsQH.loading"
            ></app-object-state>
    </div>
    <div>
        <button data-cy="actions-button" pButton pRipple  label="Actions" (click)="menu.toggle($event)" icon="pi pi-angle-down"></button>
        <p-menu #menu [popup]="true" appendTo="body" [model]="assetActions"></p-menu>
    </div>
</div>

<div id="asset-info" class="card">
    <h5>{{asset.name}}</h5>
    <p>Type: <p-tag [value]="asset.type" severity="success"></p-tag> </p>
    <p>Created: {{asset.createdAt | freyaDate}}</p>
    <p>Updated: {{asset.updatedAt | freyaDate}}</p>
    <p *ngIf="asset.deletedAt">Deleted: {{asset.deletedAt | freyaDate}}</p>
    <p *ngIf="asset.attributes && asset.attributes.length > 0">Attributes: {{ asset.attributes.join(', ') }}</p>
    <p *ngIf="asset?.type==='Estimator' && yemboHelper.yemboEnabled">Yembo Email: {{asset?.metadata?.yemboEmail || 'N/A'}}</p>
    <p>Location: <a
        *ngIf="asset.location"
        style="cursor: pointer;"
        (click)="detailsHelper.open('location', asset.location); $event.stopPropagation()"
        >{{ stringifyLocation(asset.location) }}</a>
        <span *ngIf="!asset.location">No Location</span>
        <!-- <a *ngIf="asset.location"
            [routerLink]="[ 'locations', asset.location.id ]"
            target="_blank"
        >{{ stringifyLocation(asset.location) }}</a>
        <ng-container *ngIf="!asset.location">No Location</ng-container> -->
        <!-- TODO:  -->
    </p>
    <p>{{asset.description}}</p>
</div>

<br>

<h5>Zones & Areas</h5>
<div id="zone-info" class="card">
    <ng-container *ngIf="zones">
        <div *ngFor="let zone of zones">
            <p>{{ zone.name }}</p>
            <!-- <p *ngIf="!zones.areas || zones.areas.length === 0">No Areas Found</p> -->
            <!-- <ul>
                <li *ngFor="let area of zones.areas">
                    {{ area.name }}
                </li>
            </ul> -->
        </div>
    </ng-container>

    <div *ngIf="!zones" class="p-grid p-formgrid">
        <div class="p-field p-col-12" *ngFor="let i of [ 1, 2 ]">
            <p-skeleton width="80%" height="1.5rem" styleClass="p-mb-2"></p-skeleton>
            <p-skeleton width="100%" height="1rem" styleClass="p-mb-2"></p-skeleton>
            <p-skeleton width="100%" height="1rem" styleClass="p-mb-2"></p-skeleton>
        </div>
    </div>

</div>

<h5>Upcoming Events</h5>
<div class="card">
    <div *ngIf="!eventsLoading; else elseBlock">
        <div *ngFor="let event of upcomingEvents">
            <app-display-event [event]="event"></app-display-event>
            <br>
        </div>
    </div>
    <div *ngIf="!eventsLoading && !upcomingEvents?.length">
        No Upcoming Events
    </div>
    <br>
    <small>This list is capped at 5 events</small>
</div>

<br>

<app-mutate-asset #mutate [asset]="asset" mutateType="update"></app-mutate-asset>

<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="30px"
    ></p-skeleton>
</ng-template>

<app-apply-template
    #applyTemplate
    reviewButtonText="Apply Template"
></app-apply-template>
