<div [formGroup]="eventForm">
    <ng-template #title>
        <div *appVar="eventForm.get('title') as control">
            <label>Title</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="eg. Dave's Appointment"
                formControlName="title"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #type>
        <div *appVar="eventForm.get('type') as control">
            <label>Type</label>
            <br />
            <p-dropdown
                class="full-width-dropdown"
                [options]="jobEventTypes"
                formControlName="type"
                appendTo="body"
            ></p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #start>
        <div *appVar="eventForm.get('start') as control">
            <p-calendar
                formControlName="start"
                showTime="true"
                dataType="string"
                [dateFormat]="dateFormat"
                [hourFormat]="timeFormat"
                appendTo="body"
                [minDate]="freyaHelperService.lockDateObject$ | async"
            >
            </p-calendar>
            <app-validation-messages [control]="control">
            </app-validation-messages>
            <ng-container *ngIf="freyaHelperService.lockDate$ | async">
                <br>
                <small>You cannot reschedule to a date prior to {{ formattedLockDate }} (Lock Date).</small>
            </ng-container>
            <br>
            <small>This will ignore availability, proceed with caution.</small>
        </div>
    </ng-template>
    <ng-template #duration>
        <div class="p-inputgroup">
            <input
                pInputText
                type="number"
                step="0.25"
                formControlName="duration"
            >
            <span class="p-inputgroup-addon">Hours</span>
        </div>
        <br>
        <span>
            {{ getUnixTimes().unixStart | tzDate : dayJsTimeFormat }}

            <ng-container
                *ngIf="!getUnixTimes().sameDay"
            >
                {{ getUnixTimes().unixStart | tzDate : dayJsTimeFormat : '' }}
            </ng-container>

            ➡

            <strong>{{ getUnixTimes().unixEnd | tzDate : dayJsTimeFormat }}</strong>, {{ getUnixTimes().unixEnd | tzDate : dayJsDateFormat }}
        </span>
    </ng-template>
    <ng-template #status>
        <div *appVar="eventForm.get('status') as control">
            <label>Status</label>
            <br>
            <p-dropdown
                appendTo="body"
                placeholder="Select a Status"
                [options]="statuses"
                formControlName="status"
                optionLabel="label"
                optionValue="value"
                >
            </p-dropdown>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #assets>
        <div class="p-grid">
            <div class="p-col">
                <p-multiSelect
                    [options]="assetOptions"
                    class="full-width-multiselect"
                    formControlName="assets"
                    [showToggleAll]="false"
                    optionLabel="name"
                    dataKey="id"
                    [maxSelectedLabels]="1"
                    [selectedItemsLabel]="'{0} Assets Selected'"
                    [autofocusFilter]="!freyaHelperService.isSmallScreen"
                    appendTo="body"
                >
                    <ng-template
                        let-asset
                        pTemplate="item"
                    >
                        <div class="country-item">
                            <div>{{asset.name}} ({{asset.type}})</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <div class="p-col">
                <span class="chip-container">
                    <p-chip
                        *ngFor="let asset of eventForm.value.assets"
                        [removable]="true"
                        (onRemove)="unselectAsset(asset)"
                        [label]="asset.name"
                    >
                    </p-chip>
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template #locations>
        <p>Drag the locations up and down to reorder them. </p>
        <p><b>Estimated time at location</b> is meant to help you plan, it does NOT affect the event duration.</p>
        <p><b>Type</b> is derived from the job and used by rules. It does NOT correlate to the order.</p>

        <div cdkDropList class="event-location-list" (cdkDropListDropped)="drop($event)">
            <div *ngFor="let location of eventForm.value.locations; let i = index" #existingLocation class="event-location-row" cdkDrag>
                <div
                    #locationHeader
                    class="location-header"
                >
                    <div>
                        <i class="pi pi-arrows-v"></i>

                    </div>
                    <div class="info">
                        <p class="no-margin">{{location.location.addressLineOne}}</p>
                    </div>
                    <div>
                        <button
                            pButton
                            class="p-button-danger"
                            (click)="removeLocation(location)"
                            icon="pi pi-trash"
                        ></button>
                    </div>
                </div>
                <br>
                <div class="flex">
                    <div class="p-mr-1">
                        <label>Estimated Time At Location</label>
                        <app-duration-input
                            [(duration)]="location.estimatedTimeAtLocation"
                        ></app-duration-input>
                    </div>
                    <div>
                        <label>Type</label>
                        <br>
                        <input type="text" pInputText [disabled]="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="location.type" />
                    </div>
                </div>
                <div
                    #locationBody
                    class="p-grid"
                >

                    <!-- <div class="p-col">
                        <label>Location Type</label>
                        <p-dropdown
                            class="full-width-dropdown"
                            [options]="locationTypes"
                            [(ngModel)]="location.type"
                            [ngModelOptions]="{standalone: true}"
                            appendTo="body"
                        ></p-dropdown>
                    </div> -->
                    <!-- <div class="p-col">
                        <label>Travel Time Offset</label>
                        <app-duration-input
                            [(duration)]="location.travelTimeToNextLocationOffset"
                        ></app-duration-input>
                    </div> -->
                </div>
            </div>
        </div>
        
        <hr>
        <p>Add locations to the event (new locations must be added to the job first).</p>
        <div class="flex">
            <div class="p-m-1">
                <app-job-location-select
                    [jobId]="event?.job?.id"
                    [(ngModel)]="locationsToAdd"
                    [ngModelOptions]="{standalone: true}"
                    [removeLocationIds]="unaddableLocationIds"
                ></app-job-location-select>
            </div>
            <button class="p-m-1" pButton label="Add To Event" icon="pi pi-plus" (click)="addLocationsToEvent()"></button>
        </div>

    </ng-template>

    <ng-template #attendees>
        <br>
        <div *appVar="eventForm.get('attendees') as control">
            <div class="p-grid">
                <div
                    *ngFor="let attendee of control.value"
                    class="p-col-12 p-grid p-align-center"
                >
                <div class="p-col-4">
                    {{attendee.role}}
                </div>
                <div class="p-col-6">
                    {{attendee.user.givenName}} {{attendee.user.familyName}}
                </div>
                    <div class="p-col-2">
                        <button
                            pButton
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="removeAttendee(attendee)"
                        ></button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="p-grid">
                <div
                    class="p-col-12 p-grid"
                    [formGroup]="addAttendeeForm"
                >
                    <div class="p-col-4">
                        <p-dropdown
                            placeholder="Select Job Role"
                            [options]="attendeeRolesArray"
                            formControlName="role"
                            appendTo="body"
                            #role
                        ></p-dropdown>
                    </div>
                    <div class="p-col-6">
                        <app-user-search
                            formControlName="user"
                            [searchFilter]='userSearchOptions'
                        ></app-user-search>
                    </div>
                    <div class="p-col-2">
                        <button
                            pButton
                            icon="pi pi-plus"
                            class="p-button-success"
                            [disabled]="!addAttendeeForm.valid"
                            (click)="addAttendee();"
                        ></button>
                    </div>
                </div>
            </div>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>

    <!-- <ng-template #locations>
        <div class="drag-container">
            <p-orderList
                [value]="eventForm.value.locations"
                [dragdrop]="false"
                class="location-orderlist"
            >
                <ng-template
                    let-location
                    pTemplate="item"
                >

                </ng-template>
            </p-orderList>
            <hr>
            <div #addLocation *ngIf="showNewLocation" class="event-location-row" [formGroup]="newLocationForm">
                <div
                    #locationHeader
                    class="p-grid p-align-center"
                >
                    <div class="p-col-8">
                        <input
                        class="location-input full-width"
                        [options]="options"
                        formControlName="address"
                        pInputText
                        ngx-google-places-autocomplete
                        (onAddressChange)="handleAddressChange($event, 'start')"
                        />
                    </div>
                    <div class="p-col-4 p-text-center">
                        <button
                            pButton
                            label="Add to Event"
                            class="p-button-success p-mr-1"
                            [disabled]="!newLocationForm.valid"
                            (click)="addNewLocation()"
                            icon="pi pi-plus"
                        ></button>
                        <button
                            pButton
                            class="p-button-warning"
                            (click)="showNewLocation = false"
                            icon="pi pi-times"
                        ></button>
                    </div>
                </div>
                <div
                    #locationBody
                    class="p-grid"
                >
                    <div class="p-col">
                        <label>Estimated Time At Location</label>
                        <input
                            pInputText
                            class="full-width"
                            type="number"
                            formControlName="estimatedTimeAtLocation"
                        >
                        <br>
                    </div>
                    <div class="p-col">
                        <label>Location Type</label>
                        <p-dropdown
                            appendTo="body"
                            placeholder="Select Type"
                            class="full-width-dropdown"
                            [options]="locationTypes"
                            formControlName="type"
                        ></p-dropdown>
                    </div>
                    <div class="p-col">
                        <label>Offset (Coming Soon)</label>
                        <input
                            pInputText
                            class="full-width"
                            type="number"
                            formControlName="travelTimeToNextLocationOffset"
                        >
                    </div>
                </div>
            </div>
        </div>
    </ng-template> -->
</div>


<ng-template #startReview>
    <span>{{eventForm.value.start | date : 'h:mm a, MMM, d, y'}}</span>
</ng-template>

<ng-template #durationReview>
    <span>{{eventForm.value.duration}} Hours</span>
</ng-template>

<app-mutate-object
    #mutate
    [fg]="eventForm"
    objectType="Event"
    mutateType="update"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>
