import { createFeature, createReducer, on } from '@ngrx/store';

import { UserProfile } from '../../interfaces/auth';

import { AuthActions } from './auth.actions';

export interface AuthState {
	user: UserProfile | undefined;
};

export const authInitialState = {
	user: undefined,
};

export const authFeature = createFeature({
	name: 'auth',
	reducer: createReducer(
		authInitialState,
		on(AuthActions.authUpdated, (state, res): AuthState => {
			console.log(`AUTH FEATURE`, res);

			return {
				...state,
				user: res.user,
			}
		}),
	)
})
