<p-toast class="freya-toast">
    <ng-template let-message pTemplate="message">
        <span
            [class]="'p-toast-message-icon pi ' + message.icon"
        ></span>
        <div class="p-toast-message-text">
            <div class="p-toast-summary">
                {{ message.summary }}
            </div>
            <div class="p-toast-detail">
                {{ message.detail }}
                <div *ngIf="message.data?.buttons" class="button-container">
                    <button
                        *ngFor="let button of message.data.buttons"
                        pButton
                        [label]="button?.label"
                        [icon]="button?.icon"
                        [class]="button?.styleClass"
                        (click)="button?.onClick"
                        [routerLink]="button?.routerLink"
                    ></button>
                </div>
                <div *ngIf="message.severity === 'error' && getSupportType(message) === 'support'" class="button-container p-mt-2">
                    <button
                        *ngIf="message.severity === 'error'"
                        pButton
                        label="Report Bug"
                        icon="pi pi-send"
                        class="p-button-danger"
                        (click)="reportBug(message)"
                    ></button>
                </div>
                <div *ngIf="message.severity === 'error' && getSupportType(message) === 'accounting'" class="button-container p-mt-2">
                    <button
                        *ngIf="message.severity === 'error'"
                        pButton
                        [label]="contactAccountingButtonText"
                        icon="pi pi-send"
                        class="p-button-danger"
                        (click)="contactAccounting(message)"
                    ></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<router-outlet *ngIf="(appLoadingService.appLoading | async) === false"></router-outlet>

<app-error
    *ngIf="(appLoadingService.appLoading | async) === true && appLoadingService.error && isOnline"
>
    {{ appLoadingService.error }}
</app-error>

<app-offline *ngIf="!isOnline">
</app-offline>


<p-dialog header="Update Available" [(visible)]="isNewVersion" [modal]="true">
    <p>A new version of the app is available</p>
    <ng-template pTemplate="footer">
        <button pButton label="Later" icon="pi pi-times" (click)="closeVersion()"></button>
        <button pButton class="p-button-success" label="Update Now" icon="pi pi-refresh" (click)="updateVersion()"></button>
    </ng-template>
</p-dialog>

<app-mutate-container *ngIf="plusAuth.user?.id && mutateContainerEnabled"></app-mutate-container>
<app-artifact-dialog></app-artifact-dialog>

<p-confirmDialog [breakpoints]="{'992px': '62vw', '510px': '93vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
