import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-masked-calendar',
  templateUrl: './masked-calendar.component.html',
  styleUrls: ['./masked-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MaskedCalendarComponent
    }
  ]
})
export class MaskedCalendarComponent implements ControlValueAccessor, OnInit {

  // CalendarConfig
  @Input() showOnFocus = true;
  @Input() placeholder: string;

  @ViewChild('op') op: OverlayPanel;

  // Form control variables
  calendarDate: string;
  inputDate: string;
  touched = false;
  disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Updates calendar value to match maskedInput value,
   * and notifies parent that calendar date has changed
   * in order to update any form controls bound to calendar date.
   *
   * @param inputDate The string emitted by the maskedInput on ngModelChange.
   */
  setCalendarDate(inputDate: string) {
    this.calendarDate = inputDate;
    this.onChange(this.calendarDate);
  }

  /**
   * Updates maskedInput value to match selected calendar date,
   * and notifies parent that calendar date has changed
   * in order to update any form controls bound to calendar date.
   */
  setInputDate() {
    this.inputDate = this.calendarDate;
    this.onChange(this.calendarDate);
    this.closeCalendar();
  }

  openCalendar(event) {
    if (this.op?.overlayVisible) { return; };
    this.op.show(event);
  }

  closeCalendar() {
    this.op.hide();
  }

  // Form control methods
  onChange = (date) => {};

  onTouched = () => {};

  writeValue(date: string) {
    this.inputDate = date;
    this.calendarDate = date;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (this.touched) { return; };
    this.onTouched();
    this.touched = true;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
