import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { clone, parseInt, range } from 'lodash';

import { dayOfWeekOptions } from '../../global.constants';
import { FreyaHelperService } from '../../services/freya-helper.service';

const dayOfMonthOptions = range(1, 32, 1).map((v) => ({
  label: v.toString(),
  value: v.toString(),
})).concat([
  {
    label: 'Last weekday',
    value: 'LW',
  },
  {
    label: 'Third last day',
    value: 'L-2',
  },
  {
    label: 'Second last day',
    value: 'L-1',
  },
  {
    label: 'Last day',
    value: 'L',
  },
]);

const scheduledReportOptions = [
    {
        label: 'Every Day',
        value: 'every-day',
    },
    {
        label: 'Every Week',
        value: 'every-week',
    },
    {
        label: 'Bi-monthly',
        value: 'bi-monthly',
    },
    {
        label: 'Every Month',
        value: 'every-month',
    },
    {
        label: 'Custom Cron Time',
        value: 'custom',
    },
] as const;

export type ScheduledReportTimeValues = typeof scheduledReportOptions[number]['value'];

@Component({
  selector: 'app-cron-time-form',
  templateUrl: './cron-time-form.component.html',
  styleUrls: ['./cron-time-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CronTimeFormComponent,
    },
  ]
})

export class CronTimeFormComponent implements ControlValueAccessor, OnChanges {

  @Input() scheduledReportOptions = scheduledReportOptions;
  @Input() parsedCronTime = 'Unknown';
  @Output() parsedCronTimeChange = new EventEmitter<string>();

  @Input() time: string;
  @Output() timeChange = new EventEmitter();

  initialTimeOfDay = new Date(`01/01/2000 ${ Math.floor(new Date().getHours()) }:00`);

  showTimeSelect = false;
  showWeekdaySelect = false;
  showDayOfMonthSelect = false;
  showCustomSelect = false;

  dayOfWeekOptions = dayOfWeekOptions;
  dayOfMonthOptions = dayOfMonthOptions;
  selectedTimeBaseOption: ScheduledReportTimeValues = 'every-day';
  selectedTimeOfDay = new Date(this.initialTimeOfDay);
  selectedWeekday = new Date().getDay();
  selectedDayOfMonth = '1';

  touched = false;
  disabled = false;

  constructor(
    private freyaHelper: FreyaHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {

    const timeChange = changes?.time;

    const timeSet = timeChange && !timeChange.previousValue && timeChange.currentValue;

    if (timeSet) {
      this.setSelectedBaseOption(this.time);
      this.setParsedCronTime(this.time);
      this.setTimeOptions(this.time);
    }
  }

  updateTime() {
    const timeOfDay = this.selectedTimeOfDay || this.initialTimeOfDay;
    const hour = timeOfDay.getHours();
    const minute = timeOfDay.getMinutes() || 0;

    let time = this.time;

    switch(this.selectedTimeBaseOption) {
      case 'every-day':
        time = `${ minute } ${ hour } * * *`;
        break;
      case 'every-week':
        time = `${ minute } ${ hour } * * ${ this.selectedWeekday }`;
        break;
      case 'bi-monthly':
        let dayOfMonth = +this.selectedDayOfMonth;
        if (!dayOfMonth) {
          dayOfMonth = -1;
        }

        let secondDayOfMonth: number | string = dayOfMonth + 14;
        if (secondDayOfMonth >= 28) {
          secondDayOfMonth = 'L';
        }

        time = `${ minute } ${ hour } ${ dayOfMonth },${ secondDayOfMonth } * *`;
        break;
      case 'every-month':
        time = `${ minute } ${ hour } ${ this.selectedDayOfMonth } * *`;
        break;
    }

    this.time = time;

    this.setParsedCronTime(time);
    this.setTimeOptions(time);

    this.emitChange(this.time);

  }

  setSelectedBaseOption(time?: string) {
    if (time) {
      this.selectedTimeBaseOption = this.determineSelectedBaseOption(time);
    } else {
      this.selectedTimeBaseOption = 'every-day';
    }
  }

  setTimeOptions(time?: string) {
    this.showTimeSelect = false;
    this.showWeekdaySelect = false;
    this.showDayOfMonthSelect = false;
    this.showCustomSelect = false;

    this.dayOfMonthOptions = clone(dayOfMonthOptions);

    switch(this.selectedTimeBaseOption) {
      case 'every-day':
        this.setTimeOfDay(time);
        break;
      case 'every-week':
        this.setTimeOfDay(time);
        this.setWeekday(time);
        break;
      case 'bi-monthly':
        this.setTimeOfDay(time);
        this.setDayOfMonth(time, true);
        break;
      case 'every-month':
        this.setTimeOfDay(time);
        this.setDayOfMonth(time);
        break;
      default:
      case 'custom':
        this.showCustomSelect = true;
        break;
    }
  }

  determineSelectedBaseOption(
    time: string,
  ): ScheduledReportTimeValues {
    if (time.match(/(\d?\d) (\d?\d) (\*) (\*) (\*)/g)) {
      return 'every-day';
    }
    if (time.match(/(\d?\d) (\d?\d) (\*) (\*) (\d?\d)/g)) {
      return 'every-week';
    }
    if (time.match(/(\d?\d) (\d?\d) (\d?\d,\d?\d) (\*) (\*)/g)) {
      return 'bi-monthly';
    }
    if (time.match(/(\d?\d) (\d?\d) (\d?\d) (\*) (\*)/g)) {
      return 'every-month';
    }

    return 'custom';
  }

  setTimeOfDay(time: string) {

    const cronComponents = time.split(' ');
    const minutes = parseInt(cronComponents[0]);
    const hours = parseInt(cronComponents[1]);
    const timeOfDay = new Date();

    timeOfDay.setHours(hours);
    timeOfDay.setMinutes(minutes);

    this.selectedTimeOfDay = timeOfDay;

    this.showTimeSelect = true;
  }

  setWeekday(time: string) {

    const cronComponents = time.split(' ');
    const dayOfWeek = parseInt(cronComponents[4]);

    this.selectedWeekday = dayOfWeek % 7;
    this.showWeekdaySelect = true;
  }

  setDayOfMonth(time: string, bimonthly?: boolean) {

    const cronComponents = time.split(' ');

    // This may be two days e.g, 1,15 if the provided time was biweekly
    let dayOfMonth = cronComponents[2];

    dayOfMonth = dayOfMonth.split(',')[0];

    if (bimonthly) {
        this.dayOfMonthOptions = this.dayOfMonthOptions.filter((f) => +f.value < 18);
    }

    this.selectedDayOfMonth = dayOfMonth;
    this.showDayOfMonthSelect = true;
  }

  setParsedCronTime(time: string) {
    this.parsedCronTime = this.freyaHelper.parseCrontime(time, true);
    this.parsedCronTimeChange.emit(this.parsedCronTime);
  }

  onChange = (time: string) => {};

  onTouched = () => {};

  writeValue(time: string) {
    this.time = time;
    this.setSelectedBaseOption(time);
    this.setParsedCronTime(time);
    this.setTimeOptions(time);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  markAsTouched() {
    if (this.touched) { return; }
    this.onTouched();
    this.touched = true;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitChange(time: string) {
    this.onChange(time);
    this.timeChange.emit(time);
  }

}
