<section class="activity">
	<header>
		<h2><i class="pi pi-bolt p-mr-1"></i> <span class="p-mr-1">Activity</span> </h2> <p-badge
			[value]="totalComments$ | async"
		/>
	</header>

	<main>
		<app-job-add-comment [jobId]="jobId$ | async"></app-job-add-comment>

		<!-- <div class="comments">
			<app-job-comment
				*ngFor="let comment of (comments$ | async); trackBy:identifyComment"
				class="comment"
				[class.internal]="!comment.author"
				[class.topLevel]="true"
				[comment]="comment"
				[job]="job$ | async"
			>

			</app-job-comment>
		</div> -->


		<p-timeline
			[value]="(comments$ | async)"
			class="comments"
		>
			<ng-template
				pTemplate="content"
				let-comment
			>
				<app-job-comment
					class="comment"
					[class.internal]="!comment.author"
					[class.topLevel]="true"
					[comment]="comment"
					[job]="job$ | async"
				>

				</app-job-comment>
			</ng-template>
		</p-timeline>
	</main>
</section>