<p-dropdown
  *ngIf="groupedFilters && !refreshing"
  [options]="groupedFilters"
  optionValue="filter"
  [group]="true"
  [ngModel]="selected"
  (onChange)="filterChange.emit($event.value)"
>
  <ng-template let-group pTemplate="group">
    <ng-container
      [ngTemplateOutlet]="group.label === 'Custom' ? customGroupLabel: presetGroupLabel"
      [ngTemplateOutletContext]="{ group: group }"
    ></ng-container>
  </ng-template>
  <ng-template let-filter pTemplate="selectedItem">
    <div>
      {{ filter?.name }}
      <span *ngIf="filter?.name === 'New Filter' && !filter?.saved">(<i>unsaved</i>)</span>
    </div>
  </ng-template>
  <ng-template let-filter pTemplate="item">
    <div [ngClass]="{ 'filter-option': true, 'custom': filter.saved }">
      {{ filter?.name }}
      <span *ngIf="filter.saved">
        <button
          pButton
          type="button"
          icon="pi pi-times-circle"
          class="p-button-rounded p-button-text p-button-plain"
          (click)="$event.stopPropagation(); confirmDelete(filter)"
        ></button>
      </span>
    </div>
  </ng-template>
</p-dropdown>

<ng-template #presetGroupLabel let-group='group'>
  <div>{{ group.label }}</div>
</ng-template>

<ng-template #customGroupLabel let-group='group'>
  <div *ngIf="group.items?.length">Custom Filters</div>
  <div *ngIf="!group.items?.length" class="no-custom-filters-wrapper">
    <div>Custom Filters</div>
    <div> Here is where your custom filters will appear.</div>
  </div>
</ng-template>
