import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss']
})
export class DurationInputComponent implements OnInit, OnChanges {

  // If you have an input 'x' w/ an ouput named 'xChange' you can two way bind like ngModel
  @Input() duration: number; // Duration in seconds
  @Output() durationChange: EventEmitter<number> = new EventEmitter<number>();

  durationAsDate: Date;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.durationAsDate = this.createEmptyDate();
    this.durationAsDate.setHours(Math.floor(this.duration / 3600));
    this.durationAsDate.setMinutes(Math.floor(this.duration % 3600 / 60));
  }

  createEmptyDate(){
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  setDurationLength(){
    this.duration = this.durationAsDate.getHours() * 3600 + this.durationAsDate.getMinutes() * 60;
    this.durationChange.emit(this.duration);
  }

}
