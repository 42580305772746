<div class="close-job-dialog">
    <p>Select closure reason:</p>
    <label>Reason</label>
    <br>
    <p-dropdown
        appendTo="body"
        placeholder="Select Closure Reason"
        [options]="closureReasons"
        optionLabel="title"
        optionValue="id"
        [(ngModel)]="selectedClosedReason"
        (ngModelChange)="onClosureReasonSelected($event)"
        >
    </p-dropdown>
        <div class="footer">
            <button
                pButton
                label="Exit Dialog"
                icon="pi pi-times"
                (click)="closeDialog(false)"
            ></button>
            <button
                class="p-button-success"
                pButton
                label="Close Job"
                icon="pi pi-circle-fill"
                (click)="closeJob()"
                [disabled]="!selectedClosedReason"
            ></button>
        </div>
</div>
