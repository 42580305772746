import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber'
})
export class AbbreviateNumberPipe implements PipeTransform {

  transform(value: number, fractionDigits = 2): string {
    let abbreviatedValue: string;

    let suffix: string;

    if (value >= 1e9) {
      abbreviatedValue = (value / 1e9).toFixed(fractionDigits);
      suffix = 'B';
    } else if (value >= 1e6) {
      abbreviatedValue = (value / 1e6).toFixed(fractionDigits);
      suffix = 'M';
    } else if (value >= 1e3) {
      abbreviatedValue = (value / 1e3).toFixed(fractionDigits);
      suffix = 'K';
    } else {
      return value.toString();
    }

    return `${abbreviatedValue}${suffix}`;
  }

}
