import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { FreyaNotificationsService } from './services/freya-notifications.service';
import { UploadFileComponent } from './shared/upload-file/upload-file.component';
import { UserPageComponent } from './users/user-page/user-page.component';

@Injectable({
  providedIn: 'root'
})
export class UploadGuard  {

  constructor(private localNotify: FreyaNotificationsService){}

  canDeactivate(
    component: UserPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.artifactsRef && component.artifactsRef.uploadRef.filesToUpload.find((f) => f.progress < 100)){
      this.localNotify.addToast.next({severity: 'warn', summary: 'File upload in progress.', detail: 'Please wait for it to complete'});
      return false;
    } else {
      return true;
    }
  }
}
