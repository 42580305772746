import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-open-link',
  templateUrl: './open-link.component.html',
  styleUrls: ['./open-link.component.scss']
})
export class OpenLinkComponent implements OnInit {

  @Input() link: string | string[];
  @Input() tooltip: string;
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
