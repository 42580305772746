import { Pipe, PipeTransform } from '@angular/core';
import { CalendarEventLocation } from '@karve.it/interfaces/calendarEvents';
import { isNumber } from 'lodash';

import { environment } from '../../environments/environment';
import { DistanceService } from '../estimates/distance.service';
import { DistanceUnit, jurisdictions, METERS_PER_FOOT, METERS_PER_KM, METERS_PER_MILE } from '../global.constants';


@Pipe({
  name: 'distance'
})
export class DistancePipe implements PipeTransform {

  constructor(
    private distanceService: DistanceService,
  ) {}

  transform(
    // distance in meters or calendar event location
    value: number | CalendarEventLocation,
    // units to display
    unitSystem: DistanceUnit = 'metric',
    decimalPlaces = 2,
    // in meters
    smallUnitThreshold = 500,
  ): string {

    if(!value && !isNumber(value)) { return 'N/A'; }

    if (typeof value !== 'number'){ // value is CalendarEventLocation
      const jurisdiction = jurisdictions.find((jur) =>
        jur && jur.country === (value as CalendarEventLocation)?.location?.country);
      unitSystem = jurisdiction?.units || environment.defaultUnits as DistanceUnit;
      value = value.distanceToNextLocation || 0;
    }

    return this.distanceService.transformDistance(value, unitSystem, decimalPlaces, smallUnitThreshold);
  }

}
