import { on } from '@ngrx/store';

import { JobToolState } from '../../job-tool.reducer';
import { JobCreateInventoryActions } from './jobv2-create-inventory-state.actions';
import { Inventory } from 'src/app/estimates/estimate.interfaces';
import { trackChanges } from '../../jobsv2-helpers';
import { FIELD_CONFIG, JOB_FORM_FIELDS } from 'src/app/global.constants';

export const jobCreateInventoryReducers = [
  on(
    JobCreateInventoryActions.inventoryAddRoom,
    (state: JobToolState, res): JobToolState => {
      const existingRooms = state.inventoryInput?.rooms || [];
      const updatedInventory = {
        ...state.inventoryInput,
        rooms: [
            ...existingRooms,
            {
                name: res?.roomName,
                totalVolume: 0,
                totalWeight: 0,
                totalItems: 0,
                inventory: [],
            }
        ]
      };

      const changes = trackChanges(state.changes, {
        fieldName: JOB_FORM_FIELDS.inventory,
        namespace: 'fieldsInput',
        value: updatedInventory,
        key: FIELD_CONFIG.jobs.inventory.name,
      });

      return {
        ...state,
        inventoryInput: updatedInventory,
        changes,
      };
    }
  ),
  on(
    JobCreateInventoryActions.inventoryUpdateRoomName,
    (state: JobToolState, { roomIndex, newRoomName }): JobToolState => {
      const updatedRooms = [...state.inventoryInput.rooms];
      updatedRooms[roomIndex] = { ...updatedRooms[roomIndex], name: newRoomName };

      const updatedInventory = {
        ...state.inventoryInput,
        rooms: updatedRooms,
      };

      const changes = trackChanges(state.changes, {
        fieldName: JOB_FORM_FIELDS.inventory,
        namespace: 'fieldsInput',
        value: updatedInventory,
        key: FIELD_CONFIG.jobs.inventory.name,
      });

      return {
        ...state,
        inventoryInput: updatedInventory,
        changes,
      };
    }
  ),
  on(
    JobCreateInventoryActions.inventoryItemAddedToRoom,
    (state: JobToolState, { roomIndex, addedItem }): JobToolState => {
      const updatedRooms = [...state.inventoryInput.rooms];
      updatedRooms[roomIndex] = {
        ...updatedRooms[roomIndex],
        inventory: [...updatedRooms[roomIndex].inventory, addedItem],
      };

      const updatedInventory = updateTotals({
        ...state.inventoryInput,
        rooms: updatedRooms,
      });

      const changes = trackChanges(state.changes, {
        fieldName: JOB_FORM_FIELDS.inventory,
        namespace: 'fieldsInput',
        value: updatedInventory,
        key: FIELD_CONFIG.jobs.inventory.name,
      });

      return {
        ...state,
        inventoryInput: updatedInventory,
        changes,
      };
    }
  ),
  on(
    JobCreateInventoryActions.inventoryItemQuantityChanged,
    (state: JobToolState, { newQuantity, roomIndex, itemIndex }): JobToolState => {
      const updatedRooms = [...state.inventoryInput.rooms];

      updatedRooms[roomIndex] = {
        ...updatedRooms[roomIndex],
        inventory: updatedRooms[roomIndex].inventory.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        ),
      };

      const updatedInventoryInput = updateTotals({
        ...state.inventoryInput,
        rooms: updatedRooms,
      });

      const changes = trackChanges(state.changes, {
        fieldName: JOB_FORM_FIELDS.inventory,
        namespace: 'fieldsInput',
        value: updatedInventoryInput,
        key: FIELD_CONFIG.jobs.inventory.name,
      });

      return {
        ...state,
        inventoryInput: updatedInventoryInput,
        changes,
      };
    }
  ),
  on(
    JobCreateInventoryActions.inventoryItemRemovedFromRoom,
    (state: JobToolState, { roomIndex, itemIndex }): JobToolState => {
      const updatedRooms = [...state.inventoryInput.rooms];
      const updatedInventory = updatedRooms[roomIndex].inventory.filter((_, index) => index !== itemIndex);

      updatedRooms[roomIndex] = {
        ...updatedRooms[roomIndex],
        inventory: updatedInventory,
      };

      const updatedInventoryInput = updateTotals({
        ...state.inventoryInput,
        rooms: updatedRooms,
      });

      const changes = trackChanges(state.changes, {
        fieldName: JOB_FORM_FIELDS.inventory,
        namespace: 'fieldsInput',
        value: updatedInventoryInput,
        key: FIELD_CONFIG.jobs.inventory.name,
      });

      return {
        ...state,
        inventoryInput: updatedInventoryInput,
        changes,
      };
    }
  ),
  on(JobCreateInventoryActions.inventoryDeleteRoom, (state: JobToolState, { roomIndex }) => {
    const updatedRooms = state.inventoryInput.rooms.filter((_, i) => i !== roomIndex);

    const updatedInventoryInput = updateTotals({
      ...state.inventoryInput,
      rooms: updatedRooms,
    });

    const changes = trackChanges(state.changes, {
      fieldName: JOB_FORM_FIELDS.inventory,
      namespace: 'fieldsInput',
      value: updatedInventoryInput,
      key: FIELD_CONFIG.jobs.inventory.name,
    });

    return {
      ...state,
      inventoryInput: updatedInventoryInput,
      changes,
    }
  }),
  on(
    JobCreateInventoryActions.selectEstimateMethod,
    (state: JobToolState, res): JobToolState => {
      const [key, value] = Object.entries(res.estimateMethod)[0];
      const changes = trackChanges(state.changes, {
        fieldName: key,
        namespace: 'misc',
        value: value,
      });

      return {
        ...state,
        changes,
      };
    }
  ),
] as const;

function updateTotals(inventoryInput: Inventory) {
  let totalVolume = 0;
  let totalWeight = 0;
  let totalItems = 0;

  const updatedRooms = inventoryInput.rooms.map(room => {
    let roomTotalVolume = 0;
    let roomTotalWeight = 0;
    let roomTotalItems = 0;

    room.inventory.forEach(item => {
      roomTotalVolume += item.volume * item.quantity;
      roomTotalWeight += item.weight * item.quantity;
      roomTotalItems += item.quantity;
    });

    totalVolume += roomTotalVolume;
    totalWeight += roomTotalWeight;
    totalItems += roomTotalItems;

    return {
      ...room,
      totalVolume: roomTotalVolume,
      totalWeight: roomTotalWeight,
      totalItems: roomTotalItems,
    };
  });

  return {
    ...inventoryInput,
    totalVolume,
    totalWeight,
    totalItems,
    rooms: updatedRooms,
  };
}