import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { PlusAuthenticationService, UserService } from '@karve.it/core';
import { RawUser } from '@karve.it/interfaces/auth';
import { ListUsersInput, ListUsersV2Output } from '@karve.it/interfaces/users';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { SubSink } from 'subsink';

import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UserSearchComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: UserSearchComponent
    }
  ]
})
export class UserSearchComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {

  @Output() userSelected = new EventEmitter();

  // Pass this to override current zone
  @Input() zoneId: string = this.branding.currentZone().value.id;
  @Input() searchFilter: ListUsersInput = {};

  subs = new SubSink();

  // Users Variables
  userQueryRef: QueryRef<ListUsersV2Output>;
  userSuggestions: RawUser[];

  // FORM CONTROL VARIABLES
  selectedUser: RawUser;
  touched = false;
  disabled = false;

  constructor(
    private userService: UserService,
    private branding: BrandingService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchUsers(event) {
    if (this.userQueryRef) {
      this.userQueryRef.resetLastResults();
      this.userQueryRef.setVariables({ filter: { search: event.query, limit: 10, ...this.searchFilter } });
    } else {
      this.userQueryRef = this.userService.watchUsersV2(
        { search: event.query, limit: 10, ...this.searchFilter },
        {},
        true,
        this.zoneId
        );

      this.subs.sink = this.userQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.userSuggestions = cloneDeep(res.data.usersv2.users);
        } else {
          this.userSuggestions = cloneDeep(this.userSuggestions);
        }
      });
    }
  }

  emitSelect(){
    this.userSelected.emit(this.selectedUser);
  }

  public userFullName(user: RawUser) {
    if (!user) { return ''; }
    return `${user.givenName} ${user.familyName}`;
  }

  // FORM CONTROL FUNCTIONS
  onChange = (user) => {};

  onTouched = () => {
    this.touched = true;
  };

  writeValue(user: RawUser) {
    this.selectedUser = user;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

}
