<div class="instructions">
  <p>Please send the following email to: </p>
  <span class="p-input-icon-left"
    (click)="copyToClipboard(to)"
    >
    <input
    #inputText
    pInputText
    type="text"
    [value]="to"
    [disabled]="true"
    >
    <i class="pi pi-copy"></i>
  </span>
</div>
<span class="p-input-icon-left"
  (click)="copyToClipboard(body)"
  >
  <textarea
    pInputTextarea
    readonly
    rows="20"
    class="full-width"
    [value]="body"
    [disabled]="true"
    ></textarea>
    <i class="pi pi-copy"></i>
</span>

<div class="footer">
  <div class="p-mr-2">
    <a
      pButton
      label="Send *"
      [href]="mailtoLink"
      target="_blank"
      icon="pi pi-send"
      class="p-mb-2"
      ></a>
    <br>
    <small>*Requires email handler. If nothing happens after you click, please send manually.</small>
  </div>
</div>
