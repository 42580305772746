<div class="card">
  <div class="p-grid">
    <div class="p-col-6">
      <h2>Act On Integration</h2>
    </div>
    <div class="p-col-6">
      <label>Enabled</label>
      <br />
      <p-inputSwitch [(ngModel)]="isEnabled" (onChange)="saveIsEnabled()"></p-inputSwitch>
    </div>
  </div>
</div>

<div class="card" *ngIf="!isAuthenticated">
  <form [formGroup]="logInForm">
    <h4 class="p-mb-3">Log In</h4>
    <div *appVar="logInForm.get('email') as control" class="p-mb-2">
      <label>Email</label>
      <br>
      <input
      type="email"
      pInputText
      placeholder="Email"
      formControlName="email"
      [class.p-invalid-input]="!control.valid && control.touched"
      >
      <app-validation-messages
        [control]="control"
        fieldName="Email"
        >
      </app-validation-messages>
    </div>
    <div *appVar="logInForm.get('password') as control" class="p-mb-2">
      <label>Password</label>
      <br>
      <input
      type="password"
      pInputText
      placeholder="Password"
      formControlName="password"
      [class.p-invalid-input]="!control.valid && control.touched"
      >
      <app-validation-messages
        [control]="control"
        fieldName="Password"
        >
      </app-validation-messages>
    </div>
      <button
        (click)="logIn()"
        pButton
        pRipple
        [disabled]="!logInForm.valid"
        class="p-mt-2"
        label="Log In"
        type="submit"
        ></button>
  </form>
</div>

<div class="card" *ngIf="isAuthenticated">
  <app-contact-lists></app-contact-lists>
</div>
