import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { clone } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ValidationItem } from 'src/app/app.component';
import { OBJECT_ICON_MAP } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { DisabledWhen, parseMenuItemCategoriesVisible, setMenuItemDisabled } from 'src/app/utilities/menu-item.util';
import { DeleteProductsGQL, FullPriceFragment, ListProductsGQL, ListProductsQueryVariables, Product, UpdateProductsGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

export interface EditProductValidation {
  name: ValidationItem;
}

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() product: Product;

  subs = new SubSink();
  active = true;

  productIcon = `${OBJECT_ICON_MAP.product} large-icon`;

  editProductItem = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'product',
        object: this.product,
      });
    }
  };

  addPriceItem = {
    label: 'Add Price',
    icon: 'pi pi-plus',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutate.openMutateObject(
        {
          mutateType: 'create',
          objectType: 'price',
          additionalValues: [{ property: 'product', value: this.product }],
        }
      );
    },
  };

  activateProductItem = {
    label: 'Activate',
    icon: 'pi pi-check',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.changeProductStatus(true);
    },
  };

  deactivateProductItem = {
    label: 'Deactivate',
    icon: 'pi pi-ban',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.changeProductStatus(false);
    }
  };

  deleteProductItem = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutate.openDeleteObject({
        objectId: this.product.id,
        objectName: this.product.name,
        objectType: 'product',
      });
    },
  };

  restoreProductItem = {
    label: 'Restore',
    icon: 'pi pi-undo',
    visible: false,
    command: () => {
      this.confirmationService.confirm({
        header: `Restore Product?`,
        message: `Are you sure you want to restore: ${this.product.name}`,
        acceptLabel: 'Restore Product',
        acceptIcon: 'pi pi-undo',
        rejectLabel: `Don't Restore`,
        rejectIcon: 'pi pi-times',
        accept: () => {
          this.deleteProductsGQL.mutate({ids: [this.product.id], restore: true }).subscribe(() => {
            this.localNotify.success(`Product restored`);
            this.detailsHelper.pushUpdate({
              action: 'update',
              id: this.product.id,
              type: 'Products',
            });
          }, (err) => {
            this.localNotify.apolloError(`Failed to restore product`, err);
            console.log(err);
          });
        }
      });
    },
  };

  productActions = [
    {
      label: 'Product Actions',
      visible: false,
      items: [
        this.addPriceItem,
        this.editProductItem,
        this.activateProductItem,
        this.deactivateProductItem,
        this.deleteProductItem,
        this.restoreProductItem,
      ]
    }
  ] as MenuItem[];


  productQueryRef: QueryRef<any>;
  productQH: WatchQueryHelper = {
    loading: false,
  };

  constructor(
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private cd: ChangeDetectorRef,
    private permissionHandler: PermissionService,
    private freyaMutate: FreyaMutateService,
    private updateProductGQL: UpdateProductsGQL,
    private recentItems: RecentItemsService,
    private listProductsGQL: ListProductsGQL,
    private confirmationService: ConfirmationService,
    private deleteProductsGQL: DeleteProductsGQL,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    this.subs.sink = this.detailsHelper.getObjectUpdates('Products').subscribe(()=>{
      this.refetchProduct();
    });
    this.subs.sink = this.detailsHelper.getObjectUpdates('Prices').subscribe(()=>{
      this.refetchProduct();
    });
  }

  ngOnChanges() {
    if (!this.product?.name || !this.product?.prices){
      this.refetchProduct();
      return;
    }

    this.updateProductActionVisibility(this.product.active);
    this.setActionStates();
    this.recentItems.setPinAction(this.productActions, this.product, 'product');
    this.productActions = clone(this.productActions);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['products.update', 'products.delete', 'prices.create', 'prices.update', 'prices.delete'])
      .subscribe((res) => {
        // permission check for product action
        this.editProductItem.visible = res[0];
        this.addPriceItem.visible = res[2] && this.product.active;
        this.activateProductItem.visible = res[0] && !this.product.active;
        this.deactivateProductItem.visible = res[0] && this.product.active;
        this.deleteProductItem.visible = res[1];

        parseMenuItemCategoriesVisible(this.productActions);
        this.updateProductActionVisibility(this.product.active);

        this.productActions = clone(this.productActions);
      });
  }


  /**
   * Update each action's visibility depends on product active condition.
   *
   * @param isActive Current active condition of the product.
   */
  updateProductActionVisibility(isActive) {

    this.restoreProductItem.visible = Boolean(this.product.deletedAt);

    if(!this.activateProductItem.visible && !this.deactivateProductItem) {return;}
    if (isActive) {
      this.activateProductItem.visible = !this.active;
      this.deactivateProductItem.visible = this.active;
      return;
    }
    this.activateProductItem.visible = this.active;
    this.deactivateProductItem.visible = !this.active;
  }

  refetchProduct() {
    if (this.productQueryRef) {
      this.productQueryRef.refetch(this.generateProductsInput());
      return;
    };
    this.initProductsQuery();
  }

  generateProductsInput(): ListProductsQueryVariables {
    return {
      filter: {
        productIds: [this.product.id],
      }
    } as ListProductsQueryVariables;
  }

  initProductsQuery() {

    this.productQueryRef = this.listProductsGQL.watch(this.generateProductsInput(), {fetchPolicy: 'cache-and-network'});

    this.subs.sink = this.productQueryRef.valueChanges.subscribe((res) => {
      this.productQH.loading = res.loading;
      if (this.productQH.loading) { return; };
      if (res.data.products.products?.length) {
        this.product = res.data.products.products[0];
        this.updateProductActionVisibility(this.product.active);
        this.setActionStates();
        this.recentItems.setPinAction(this.productActions, this.product, 'product');
        this.productActions = clone(this.productActions);
      };
    });
  }

  // Toggle the status of the product
  changeProductStatus(status: boolean) {
    this.subs.sink = this.updateProductGQL.mutate({ products: [{ productId: this.product.id, active: status }] }).subscribe((res) => {
      this.product.active = status;
      this.detailsHelper.pushUpdate({
        id:this.product.id,
        type:'Products',
        action:'update',
      });
      this.updateProductActionVisibility(this.product.active);
      this.localNotify.addToast.next({ severity: 'success', summary: `Product ${status ? 'activated' : 'deactivated'}` });
    });
  }

  openPrice(ev) {
    const price: FullPriceFragment = ev.data;
    this.detailsHelper.open('price', price, true, undefined, { product: this.product });
  }


  /**
   * Set the action states based on the product's state
   */
  setActionStates(){
    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.product.deletedAt),
    };

    for (const action of this.productActions[0].items){
      setMenuItemDisabled(action, disabledWhen);
    }
  }
}
