import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';

import { CreateReportDocument, CreateReportMutation, CreateReportMutationVariables, ListReportsDocument, ListReportsQuery, ListReportsQueryVariables, ListReportTypesDocument, ListReportTypesQuery, ListReportTypesQueryVariables, ListScheduledReportsDocument, ListScheduledReportsQuery, ListScheduledReportsQueryVariables } from 'graphql.generated';

import { KarveRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

    constructor(private apollo: PlusApollo){}

    listReports(variables: ListReportsQueryVariables, opts?: KarveRequestOptions) {
        return this.apollo.query<ListReportsQuery, ListReportsQueryVariables>({
            query: ListReportsDocument,
            variables,
            ...parseRequestOptions(opts),
        });
    }
    listReportTypes(variables: ListReportTypesQueryVariables, opts?: KarveRequestOptions) {
        return this.apollo.query<ListReportTypesQuery, ListReportTypesQueryVariables>({
            query: ListReportTypesDocument,
            variables,
            ...parseRequestOptions(opts),
        });
    }
    listScheduledReports(variables: ListScheduledReportsQueryVariables, opts?: KarveRequestOptions) {
        return this.apollo.query<ListScheduledReportsQuery, ListScheduledReportsQueryVariables>({
            query: ListScheduledReportsDocument,
            variables,
            ...parseRequestOptions(opts),
        });
    }
    createReport(variables: CreateReportMutationVariables) {
        return this.apollo.mutate<CreateReportMutation, CreateReportMutationVariables>({
            mutation: CreateReportDocument,
            variables,
        });
    }

}
