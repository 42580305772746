
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';

import { CONFIGS_KEYS, CUSTOMER_TYPES, DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS, JOB_CATEGORIES, JOB_CREATE_INDEXES, JOB_ORIGINS } from 'src/app/global.constants';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { SubSink } from 'subsink';

import { Job } from '../../../../generated/graphql.generated';
import { JobSearchUsersComponent } from '../../job-activity/job-search-users/job-search-users.component';

import { Modes, jobToolFeature } from '../../job-tool.reducer';


import { JobCreateCustomerActions } from '../jobv2-create-customer-state/jobv2-create-customer.actions';
import { selectHowHeardSelectedOption } from '../jobv2-create-state/jobv2-create.selectors';

@Component({
  selector: 'app-jobv2-create-customer',
  standalone: true,
  imports: [
    FreyaCommonModule,
    JobSearchUsersComponent,
  ],
  templateUrl: './jobv2-create-customer.component.html',
  styleUrls: ['./jobv2-create-customer.component.scss']
})
export class Jobv2CreateCustomerComponent implements OnInit, OnDestroy {
  @Input() renderHeadless = false;
  customerTypes = CUSTOMER_TYPES;
  customerTypeSelectedOption: string;
  jobInput: Partial<Job> | undefined;
  jobOrigins = JOB_ORIGINS;
  jobOriginSelectedOption: string;
  jobCategories: string[] | [] = JOB_CATEGORIES;
  jobCategorySelectedOption: string;
  howHeardDropdownOptions = DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS;
  howHeardMandatory = false;
  howHeardSelectedOption: string | undefined;
  movingDate: Date | Date[];

  isCardCollapsed = false;
  jobCreateIndexes = JOB_CREATE_INDEXES;

  constructor(
    private store: Store,
    private estimateHelper: EstimateHelperService,
  ) { }

  private subs = new SubSink();

  mode: Modes;
  //used for create job form when we edit customer
  isCustomerEditMode = false;
  isExistingCustomer = false;

  customerInput$ = this.store.select(jobToolFeature.selectCustomerInput);
  jobInput$ = this.store.select(jobToolFeature.selectJobInput);
  jobConfigs$ = this.store.select(jobToolFeature.selectJobConfigs);
  howHeardSelectedOption$ = this.store.select(selectHowHeardSelectedOption);

  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  editCustomerWhenCreateJobMode$ = this.store.select(jobToolFeature.selectEditCustomerWhenCreateJobMode);

  ngOnInit(): void {
    this.subs.sink = this.jobFormMode$
      .subscribe((jobFormMode) => {
        this.mode = jobFormMode;
      });

    this.subs.sink = this.editCustomerWhenCreateJobMode$
      .subscribe((editCustomerWhenCreateJobMode) => {
        this.isCustomerEditMode = editCustomerWhenCreateJobMode;
      });

    this.subs.sink = this.customerInput$
      .subscribe((customerInput) => {
        this.isExistingCustomer = Boolean(customerInput?.id);
      });

    this.subs.sink = this.jobInput$
      .subscribe((jobInput) => {
        this.jobInput = cloneDeep(jobInput);
        this.customerTypeSelectedOption = this.jobInput?.metadata?.jobType;
        this.jobOriginSelectedOption = this.jobInput?.metadata?.jobOrigin;

        this.setMovingDate(this.jobInput?.timeline);
      });

    this.subs.sink = this.howHeardSelectedOption$.subscribe((howHeardSelectedOption) => {
      this.howHeardSelectedOption = howHeardSelectedOption;
    });
    this.subs.sink = this.jobConfigs$.subscribe((configs) => {
      if (configs[CONFIGS_KEYS.howHeardMandatory]) {
        this.howHeardMandatory = configs[CONFIGS_KEYS.howHeardMandatory];
      }

      if (configs[CONFIGS_KEYS.howHeardOptions]?.length) {
        this.howHeardDropdownOptions = cloneDeep(configs[CONFIGS_KEYS.howHeardOptions]);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onMovingDateSelect(date: Date) {
    const { timeline, timelineDays } = this.estimateHelper.getTimelineV2(date);
    this.store.dispatch(JobCreateCustomerActions.selectMovingDate({
      timeline,
      timelineDays,
    }));
  }

  onHowHeardSelected(option: DropdownChangeEvent) {
    this.store.dispatch(JobCreateCustomerActions.selectHowHeard({
      fieldValue: option.value,
    }));
  }

  // onMetadataSelected(fieldName: string, value: string) {
  //   this.store.dispatch(JobCreateCustomerActions.metadataSelected({
  //     fieldName,
  //     value,
  //     // jobType: option,
  //   }));
  // }

  onCustomerTypeSelected(event: DropdownChangeEvent) {

    // if event.originalEvent does not exist this was probably
    // programatic

    this.store.dispatch(JobCreateCustomerActions.selectCustomerType({
      jobType: event.value,
    }));
  }

  onJobOriginSelected(option: DropdownChangeEvent) {
    this.store.dispatch(JobCreateCustomerActions.selectJobOrigin({
      jobOrigin: option.value,
    }));
  }

  setMovingDate(timeline: string) {
    this.movingDate = this.estimateHelper.convertToDate(timeline);
  }

  collapseCard(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isCardCollapsed = !this.isCardCollapsed;
  }

  handleDoubleClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  openEditCustomerMode() {
    this.store.dispatch(JobCreateCustomerActions.editCustomerWhenCreateJob());
  }

  deselectCustomer() {
    this.store.dispatch(JobCreateCustomerActions.deselectCustomer());
  }

  discardEditCustomerChanges() {
    this.store.dispatch(JobCreateCustomerActions.discardCustomerChanges());
  }

  acceptEditCustomerChanges() {
    this.store.dispatch(JobCreateCustomerActions.acceptCustomerChanges());
  }
}