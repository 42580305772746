import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep, isNil, minBy } from 'lodash';
import { CONFIGS_KEYS, FIELD_CONFIG, JOB_ROLE_MAP, statuses } from 'src/app/global.constants';
import { LoadingState } from 'src/app/utilities/state.util';

import { FullJobFragmentWithFields, JobToolState } from '../../job-tool.reducer';
import { CalendarEvent, FullInvoiceFragment } from 'graphql.generated';

export const selectJobToolState = createFeatureSelector<JobToolState>('jobTool');

export const selectTotalTimeForFind = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobTiming?.moving?.totalTime
);

export const selectTotalTimeForFindWhenEditJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobTiming?.moving?.totalTime
);

export const selectCustomerInput = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.customerInput
);

export const selectCurrency = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.currency
);

export const selectZoneIdForFind = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.resolvedServiceArea?.id
);

export const selectCustomerRole = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.jobConfigs['role.customer'],
);

export const selectAuthorId = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.user?.id,
)

export const selectLocationsForAddToJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const locationsInputs = state?.locationsInputs;

    if (!locationsInputs) {
      return [];
    }

    return Object.keys(locationsInputs)
      .map(key => {
        const location = locationsInputs[key];
        return {
          locationId: location?.id,
          locationType: key
        };
      });
  }
);

export const selectSummariesForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const crewSummary = jobInput?.crewSummary;
    const adminSummary = jobInput?.adminSummary;
    const customerSummary = jobInput?.customerSummary;

    const result: any = {};

    if (crewSummary) {
      result.crewSummary = crewSummary;
    }
    if (adminSummary) {
      result.adminSummary = adminSummary;
    }
    if (customerSummary) {
      result.customerSummary = customerSummary;
    }

    return result;
  }
);

export const selectMetadataForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const metadata = jobInput?.metadata;

    if (!metadata) {
      return null;
    }

    const result: any = {};

    // Iterate over the keys in metadata and include only those with non-undefined values
    Object.keys(metadata).forEach((key) => {
      const value = metadata[key];
      if (value !== undefined) {
        result[key] = value;
      }
    });

    return result;
  }
);

export const selectTimelinesForSaving = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    const timeline = jobInput?.timeline;
    const timelineDays = jobInput?.timelineDays;

    const result: any = {};

    if (timeline) {
      result.timeline = timeline;
    }
    if (timelineDays) {
      result.timelineDays = timelineDays;
    }

    return result;
  }
);

export const selectJobCategory = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const jobInput = state?.jobInput;
    return jobInput?.jobCategory?.toLowerCase();
  }
);

export const selectZoneIdForCreatingJob = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const zoneId = state?.resolvedServiceArea?.id;
    return zoneId;
  }
);

export const selectJobCreationVariables = createSelector(
  selectCustomerInput,
  selectCurrency,
  selectAuthorId,
  selectLocationsForAddToJob,
  selectSummariesForSaving,
  selectMetadataForSaving,
  selectTimelinesForSaving,
  selectJobCategory,
  selectZoneIdForCreatingJob,
  (customer, currency, authorId, locations, summaries, metadata, timelines, jobCategory, selectZoneIdForCreatingJob) => {
    return {
      stage: 'lead',
      currency,
      metadata,
      jobCategory,
      ...summaries,
      users: [
        {
          role: JOB_ROLE_MAP.customerRole,
          userId: customer?.id,
        },
        /*{
          role: JOB_ROLE_MAP.salesAgentRole,
          userId: authorId,
        }*/
      ],
      locations: [...locations],
      zoneId: selectZoneIdForCreatingJob,
      ...timelines
    };
  }
);

export const selectJobSavingErrors = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;

    const errorKeys = ['createCustomer', 'createJob', 'setFields', 'setTags'];
    const errors: string[] = [];

    errorKeys.forEach(key => {
      if (callState && callState[key]?.error) {
        errors.push(callState[key].error);
      }
    });

    return errors;
  }
);

export const selectJobSavingInProgress = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const callState = state?.callState;
    const loadingKeys = ['createCustomer', 'updateCustomer', 'createJob', 'setFields', 'setTags', 'closeJob'];

    return loadingKeys.some(key => callState && callState[key] === LoadingState.LOADING);
  }
);

export const selectHowHeardSelected = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const changes = state?.changes;
    const selected = changes?.find(c => c?.fieldName === 'customer.howDidTheyHearAboutUs')
    return Boolean(selected);
  }
);

export const selectRequiredLocationDetailsSelected = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const changes = state?.changes;
    const jobConfigs = state?.jobConfigs;

    const dwellingMandatory = jobConfigs?.[CONFIGS_KEYS.dwellingTypeMandatory];
    const bedroomsMandatory = jobConfigs?.[CONFIGS_KEYS.dwellingTypeMandatory];

    const startLocationChangedAndInvalid = changes?.some(c => c?.fieldName?.includes('start')) && (
      (dwellingMandatory && !changes?.some(c => c?.fieldName === 'startLocation.dwellingType')) ||
      (bedroomsMandatory && !changes?.some(c => c?.fieldName === 'startLocation.bedrooms'))
    );

    const endLocationChangedAndInvalid = changes?.some(c => c?.fieldName?.includes('end')) && (
      (dwellingMandatory && !changes?.some(c => c?.fieldName === 'endLocation.dwellingType')) ||
      (bedroomsMandatory && !changes?.some(c => c?.fieldName === 'endLocation.bedrooms'))
    );

    const formInvalid = startLocationChangedAndInvalid || endLocationChangedAndInvalid;

    return {
      dwellingMandatory,
      bedroomsMandatory,
      formInvalid,
    }
  }
);

export const selectHowHeardSelectedOption = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const howHeardSelectedOption = FIELD_CONFIG.customer.howHeard.name;
    return state?.fieldsInput?.[howHeardSelectedOption];
  }
);

export const selectFullNameSelected = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const fullNameSelected = Boolean(state?.customerInput?.fullName);
    return fullNameSelected;
  }
);

export const selectDataToSaveInLS = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    return {
      changes: state?.changes,
      validationErrors: state?.validationErrors,
      addableAdditionalLocations: state?.addableAdditionalLocations,
      distances: state?.distances,
      jobTiming: state?.jobTiming,
      selectedTimeSlot: state?.selectedTimeSlot,
      editCustomerWhenCreateJobMode: state?.editCustomerWhenCreateJobMode,
      resolvedServiceArea: state?.resolvedServiceArea,
      availableTimeSlots: state?.availableTimeSlots,
      jobTagsIds: state?.jobTagsIds,
      job: state?.job,
    };
  }
);

export const selectCreateJobCallState = createSelector(
  selectJobToolState,
  (state: JobToolState) => state.callState.createJob
);

export const selectFieldsValues = (locationTypeKey: string) =>
  createSelector(
    selectJobToolState,
    (state: JobToolState) => {
      const fields = cloneDeep(state.fieldsInput);

      if (!locationTypeKey || !fields || !FIELD_CONFIG[locationTypeKey]) {
        return {
          fields: undefined,
          sqftSelectedOption: undefined,
          accessNotes: undefined,
          buildingType: undefined,
          bedroomsSelectedOption: undefined,
          stairsSelectedOption: undefined,
          accessSelectedOptions: undefined,
        };
      }

      return {
        fields,
        sqftSelectedOption: fields[FIELD_CONFIG[locationTypeKey]?.sqft?.name],
        accessNotes: fields[FIELD_CONFIG[locationTypeKey]?.accessNotes?.name],
        buildingType: fields[FIELD_CONFIG[locationTypeKey]?.buildingType?.name],
        bedroomsSelectedOption: fields[FIELD_CONFIG[locationTypeKey]?.bedrooms?.name],
        stairsSelectedOption: fields[FIELD_CONFIG[locationTypeKey]?.stairs?.name],
        accessSelectedOptions: fields[FIELD_CONFIG[locationTypeKey]?.accessOptions?.name],
      };
    }
  );
export const selectYemboStatus = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    let selfSurveyStatus = statuses.notInitiated;
    let smartConsultStatus = statuses.notInitiated;

    const yemboCreated = Boolean(state?.job?.metadata?.yemboMoveCreatorId);
    const selfSurveyTag = state?.job?.tags?.find(t => t.category === 'Self Survey');
    const vose = state?.job?.events?.find(e => e.type === 'virtualEstimate');

    const smartConsultFromChanges = state?.changes?.find(c => c.fieldName === 'smartConsult');
    const selfSurveyFromChanges = state?.changes?.find(c => c.fieldName === 'selfSurvey');

    const selectedYemboOptions: string[] = [];

    const yemboStatusForSaving = {
      selfSurvey: false,
      smartConsult: false,
    };

    switch (true) {
      case vose && isNil(vose?.start):
        smartConsultStatus = statuses.notBooked;
        break;
      case yemboCreated && vose && !isNil(vose?.start):
        smartConsultStatus = statuses.initiated;
        break;
      default:
        smartConsultStatus = statuses.notInitiated;
    }

    if (selfSurveyTag) {
      selfSurveyStatus = statuses.initiated;
    }

    //for component
    if (selfSurveyStatus === statuses.initiated || selfSurveyFromChanges?.value) {
      selectedYemboOptions.push('selfSurvey');
    }
    if (smartConsultStatus === statuses.initiated
      || smartConsultStatus === statuses.notBooked
      || smartConsultFromChanges?.value) {
      selectedYemboOptions.push('smartConsult');
    }

    //save only if option was not selected initially to prevent creation multiple yembo moves
    if (selfSurveyStatus === statuses.notInitiated && selfSurveyFromChanges?.value) {
      yemboStatusForSaving.selfSurvey = true;
    }
    if (smartConsultStatus === statuses.notInitiated && smartConsultFromChanges?.value) {
      yemboStatusForSaving.smartConsult = true;
    }

    return {
      selfSurvey: selfSurveyStatus,
      smartConsult: smartConsultStatus,
      selectedYemboOptions,
      yemboStatusForSaving
    };
  }
);

export const selectYemboCustomerLink = createSelector(
  selectJobToolState,
  (state: JobToolState) => state?.job?.metadata?.yemboCustomerLink
);

export const selectSavingDisabled = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const howHeardMandatory = state?.jobConfigs[CONFIGS_KEYS.howHeardMandatory];
    const fullNameSelected = state?.customerInput?.fullName;
    const howHeardSelectedOption = FIELD_CONFIG.customer.howHeard.name;
    const howHeardSelected = Boolean(state?.fieldsInput?.[howHeardSelectedOption]);

    if (!fullNameSelected) {
      return true;
    } else if (howHeardMandatory && !howHeardSelected) {
      return true;
    } else return false;
  }
);

export const selectStartTimeCanBeEdited = createSelector(
  selectJobToolState,
  (state: JobToolState) => {

    //this is simplified version of timeline section
    //if job already has moving event, we don't allow change it's time
    const movingEvent = state?.job?.events?.filter((event) => event?.type === 'moving');

    return !movingEvent;
  }
);

export const selectContinueEditing = createSelector(
  selectJobToolState,
  (state: JobToolState) => {
    const changesFromCreateMode = state?.changes?.find((c) => c.fieldName === 'changes_from_create');
    const changesWithoutDockAndHelper = state?.changes?.filter(c =>
      c.fieldName !== 'dock' && c.fieldName !== 'changes_from_create');

    return changesWithoutDockAndHelper?.length && changesFromCreateMode ? true : false;
  }
);

export const selectLatestInvoiceId = createSelector(
  selectJobToolState,
  (state: JobToolState): string | undefined => {
    const job: FullJobFragmentWithFields = state?.job;

    const allInvoices = job?.events?.flatMap(event => event?.invoices) ?? [];
    const activeInvoices = allInvoices.filter(
      invoice => isNil(invoice.voidedAt) && isNil(invoice.deletedAt)
    );
    const earliestInvoice = minBy(activeInvoices, 'updatedAt') as FullInvoiceFragment;

    return earliestInvoice?.id ?? undefined;
  }
);

export const jobCreateSelectors = {
  selectTotalTimeForFind,
  selectZoneIdForFind,
  selectCustomerRole,
  selectAuthorId,
  selectSummariesForSaving,
  selectMetadataForSaving,
  selectJobSavingErrors,
  selectJobSavingInProgress,
  selectHowHeardSelected,
  selectHowHeardSelectedOption,
  selectFullNameSelected,
  selectZoneIdForCreatingJob,
  selectCreateJobCallState,
  selectRequiredLocationDetailsSelected,
  selectFieldsValues,
  selectYemboStatus,
  selectYemboCustomerLink,
  selectSavingDisabled,
  selectStartTimeCanBeEdited,
  selectContinueEditing,
  selectLatestInvoiceId,
};