import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge, range } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { HistoryPartsFragment, ListHistoryFilter,  ListHistoryGQL,  ListHistoryInput } from '../../generated/graphql.generated';
import { DetailsHelperService } from '../services/details-helper.service';
import { MarkdownHelperService } from '../services/markdown.service';
import { initQueryHelper, toggleSort, WatchQueryHelper } from '../utilities/watchQueryHelper';

import { determineHistoryName } from './history.util';

export interface OpenHistoryDialogData {
  label: string;
  id: string | string[];
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  @Input() filter: ListHistoryFilter = {};
  @Input() objectId: string | string[];
  @Input() objectLabel: string;

  /**
   * Set to true to use the object ID input which is faster
   * at the expense of getting less related results
   */
  @Input() useObjectId = false;


  queryHelper: WatchQueryHelper;
  watchQuery: ReturnType<ListHistoryGQL['watch']>;
  history: HistoryPartsFragment[];

  /**
   * The history used to determine the item name
   */
  latestHistory?: HistoryPartsFragment;
  name?: string;

  skeletons = range(0, 8).map(() => ({
    secondWidth: `${ Math.floor(Math.random() * 60) + 30 }%`,
    secondHeight: `1em`,
  }));

  private subs = new SubSink();

  constructor(
    private historyGQL: ListHistoryGQL,
    private route: ActivatedRoute,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public markdownHelper: MarkdownHelperService,
    private detailsHelper: DetailsHelperService,
  ) {}

  ngOnInit(): void {
    this.history = undefined;
    this.latestHistory = undefined;
    this.name = undefined;
    this.resetQueryHelper();

    // console.log(this.config?.data);
    if (this.config?.data) {
      const data = this.config.data as OpenHistoryDialogData;
      this.objectId = data.id;
      this.objectLabel = data.label;
    }

    this.subs.sink = this.route.data.subscribe((res) => {
      this.filter = res.defaultFilters || this.filter;
      if (res.objectLabel) {
        this.objectLabel = res.objectLabel;
      }
    });

    this.subs.sink = this.route.params.subscribe((res) => {
      if (res.id) {
        this.objectId = res.id;
      }
      this.fetch();
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getObjectIdsAsArray() {

    if (Array.isArray(this.objectId)) {
      return this.objectId;
    } else {
      return [ this.objectId ];
    }
  }

  getInput() {
    const baseFilter = {
      relatedLabel: this.objectLabel,
    } as ListHistoryFilter;


    if (this.useObjectId) {
      if (Array.isArray(this.objectId)) {
        baseFilter.objectId_IN = this.objectId;
      } else {
        baseFilter.objectId = this.objectId;
      }
    } else {

      if (Array.isArray(this.objectId)) {
        baseFilter.relatedId_INCLUDES_ANY = this.objectId;
      } else {
        baseFilter.relatedId = this.objectId;
      }
    }


    const filter = merge(this.filter, baseFilter);

    return {
      filter,
      generateText: true,
      limit: this.queryHelper.limit,
      skip: this.queryHelper.skip,
      sort: this.queryHelper.sort.join(';'),
    } as ListHistoryInput;
  }

  toggleSort(propertyName: string) {
    toggleSort(this.queryHelper, propertyName);
    this.fetch();
  }

  fetch() {
    if (!this.objectId || !this.objectLabel
      || (this.watchQuery && this.queryHelper?.loading)
    ) { return; }
    const input = this.getInput();

    if (this.watchQuery) {
      // refetch with new variables
      this.watchQuery.refetch({ input });
      return;
    }

    this.watchQuery = this.historyGQL.watch({
      input,
    }, {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.queryHelper.loading = res.loading;
      if (res?.data?.history?.history) {
        if(this.history?.length){
          this.history = [...this.history, ...res.data.history.history];
        }else{
          this.history = res.data.history.history;
        }
        this.queryHelper.total = res.data.history.total;

        this.queryHelper.hasMore = this.history.length < this.queryHelper.total;
        this.determineAndSetName();
      }
    });

  }
  /**
   * Determines name from the latest history and sets it
   */
  determineAndSetName() {
    const sortedHistory = this.history
      .filter((h) =>
        h.vars?.obj?.label === this.objectLabel
        && this.getObjectIdsAsArray().includes(h.vars.obj.id)
      )
      .concat(this.latestHistory ? [ this.latestHistory ] : [])
      .sort((a, b) => b.createdAt - a.createdAt)
    ;
    // console.log(sortedHistory.map((h) => h));

    const latestHistory = sortedHistory[0];
    if (!latestHistory) {
      return this.setName(undefined);
    }

    const name = determineHistoryName(latestHistory);
    return this.setName(name);
  }
  /**
   * Sets the history name and header
   */
  setName(name?: string) {
    this.name = name;

    const prefix = `Viewing History`;
    if (name?.length) {
      this.config.header = `${ prefix }: ${ name }`;
    } else {
      this.config.header = prefix;
    }
  }

  resetQueryHelper() {
    this.queryHelper = initQueryHelper({
      sort: [ 'createdAt:DESC' ],
      limit: 50,
    });

    return this.queryHelper;
  }

  openHistoryDetails(h: HistoryPartsFragment) {

    // console.warn(`Not Implemented`);
    // this.details.open('history', h);
  }

  // determineHistoryDate(h: HistoryPartsFragment, i: number) {
  //   let dateFormat = 'MMM d';
  //   const nextHistory = i < this.history.length - 1 ? this.history[i+1] : undefined;
  //   const isSame = (f: string) =>
  //     nextHistory && this.freyaDate.transform(h.createdAt, f) === this.freyaDate.transform(nextHistory.createdAt, f);

  //   if (!isSame('y') || !nextHistory) {
  //     dateFormat = 'y MMM d';
  //   } else if (!isSame('MMM')) {
  //     // dateFormat = 'MMM d';
  //   } else if (!isSame('d')) {
  //     // dateFormat = 'MMM d';
  //   }

  //   return this.freyaDate.transform(h.createdAt, `${ dateFormat }`);
  // }

  async fetchMore() {
    if (this.queryHelper.loading || !this.queryHelper.hasMore) { return; }
    const input = this.getInput();
    input.skip = this.history.length;
    input.limit = this.queryHelper.increment || this.queryHelper.limit;

    if (this.watchQuery) {
      // refetch with new variables
      this.watchQuery.refetch({ input });
      return;
    }

    // await this.watchQuery.fetchMore({
    //   variables: {
    //     input,
    //   },
    //   // updateQuery: (p, c) => ({
    //   //     ...p,
    //   //     history: {
    //   //       ...p.history,
    //   //       total: c.fetchMoreResult.history.total,
    //   //       history: [ ...p.history.history, ...c.fetchMoreResult.history.history ],
    //   //     },
    //   //   }),
    // });
  }

  selectHitory(historyId: string) {
    this.detailsHelper.detailsItem.next({ type: 'history', item: historyId });
  }

}
