<div [class.hide]="(minimized$ | async) === true">
    <div>
        <div class="header">
            <span class="title">{{ activeStep?.name }}</span>
            <span>
                <button
                    pButton
                    class="p-button-rounded p-button-text p-button-plain"
                    icon="pi pi-window-minimize"
                    pTooltip="Minimize"
                    (click)="minimized$.next(true)"
                    ></button>
                <span [pTooltip]="selectedDocumentsPending ? 'Cannot close dialog while documents are still being created' : 'Close' ">
                    <button
                        pButton
                        class="p-button-rounded p-button-text p-button-plain"
                        icon="pi pi-times"
                        [disabled]="selectedDocumentsPending"
                        (click)="closeDialog()"
                        ></button>
                </span>
            </span>
        </div>
        <div class="container">
            <ng-container *ngTemplateOutlet="activeContent"></ng-container>
        </div>
        <div class="footer">
            <ng-container *ngTemplateOutlet="activeFooter"></ng-container>
        </div>
    </div>
    <div class="indicator-container p-grid">
        <div
            class="p-col p-text-center"
            #paginatorStuff
            >
            <span
                *ngFor="let step of steps; let i = index"
                [pTooltip]="selectedDocumentsPending ? 'Cannot change steps while documents are being processed' : step.name"
                tooltipPosition="bottom"
                (click)="!selectedDocumentsPending && goToStep(i)"
                [ngClass]="{
                'indicator': true,
                'active-indicator': step === activeStep,
                'disabled-indicator': selectedDocumentsPending
                }"
                ></span>
        </div>
    </div>
</div>

<div [class.hide]="(minimized$ | async) === false">
    <div class="p-grid p-py-2 p-px-3">
        <div class="p-col-12 p-d-flex p-ai-center p-jc-between">
            <span class="bold">{{ selectedDocumentsPending ? 'Processing Documents for ' + input.jobCode + '...' : activeStep?.name }}</span>
            <span>
                <button
                    pButton
                    class="p-button-rounded p-button-text p-button-plain"
                    icon="pi pi-window-maximize"
                    pTooltip="Maximize"
                    (click)="minimized$.next(false)"
                    ></button>
                <span [pTooltip]="selectedDocumentsPending ? 'Cannot close dialog while documents are still being created' : 'Close' ">
                    <button
                        pButton
                        class="p-button-rounded p-button-text p-button-plain"
                        icon="pi pi-times"
                        [disabled]="selectedDocumentsPending"
                        (click)="closeDialog()"
                        ></button>
                </span>
            </span>
        </div>
        <div class="p-col-12 p-pt-0 p-pb-3">
            <ng-container *ngTemplateOutlet="selectedDocumentsPending ? documentsPendingMinimized : activeContentMinimized"></ng-container>
        </div>
    </div>
</div>

<ng-template #documentsPendingMinimized>
    <ng-container *ngFor="let document of documentsToCreate">
        <div *ngIf="document.status === 'pending'" class="p-col-12 p-py-0">
            <div class="p-grid p-ai-center">
                <div class="p-col-2">
                    <small>{{ document.name | titlecase }}</small>
                </div>
                <div class="p-col-10">
                    <app-freya-progress-bar [status]="document.status"></app-freya-progress-bar>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #createContent>
    <div class="p-grid">
        <div *ngFor="let document of documentsToCreate" class="p-col-12 p-d-flex p-ai-center">
            <div style="flex: 1;">
                <p-panel [toggleable]="document.status !== 'sent'" [collapsed]="!preSelectEvent">
                    <ng-template pTemplate="header">
                        <div class="full-width">
                            <div class="p-grid p-mt-0">
                                <div class="p-col-4">
                                    <span class="p-mr-2">{{document.name | titlecase}}</span>
                                </div>
                                <div class="p-col-8">
                                    <div *ngIf="document.status === 'pending'">
                                        <app-freya-progress-bar [status]="document.status"></app-freya-progress-bar>
                                    </div>
                                    <div *ngIf="document.status === 'none'">
                                        <b>
                                            <!-- <i class="pi pi-check-circle"></i> -->
                                            <span *ngIf="document.valid" class="success-text ">Ready to Create</span>
                                        </b>
                                        <b>
                                            <!-- <i class="pi pi-exclamation-triangle"></i> -->
                                            <span *ngIf="!document.valid" class="warning-text">Missing Information</span>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="p-grid">
                        <div *ngFor="let property of document.validationSchema.properties | keyvalue:onCompare" 
                                [class.hide]="property.key === 'jobId' || property.key === 'customerUserId'" 
                                [class.p-col-12]="property.key !== 'jobId' && property.key !== 'customerUserId'"
                        >
                            <div *ngIf="property.key === 'transactionIds'">
                                <app-transaction-select
                                    #transactionSelect
                                    [filter]="{jobId: input.jobId}"
                                    [removeTransactionsWithReciepts]="true"
                                    (transactionsSelected)="setTransactionValues(document, $event)"
                                ></app-transaction-select>
                                <small>Receipts can only be sent once, to resend a reciept go to the documents card.</small>
                            </div>
                            <div *ngIf="property.key === 'eventIds'">
                                <app-event-select
                                    #eventSelect
                                    [filter]="{jobId: input.jobId}"
                                    placeholder="All Events"
                                    [preSelectEventType]="preSelectEvent"
                                    [preselectFirstAvailableEvent]="false"
                                    (eventsSelected)="setEventValues(document, $event)"
                                ></app-event-select>
                                <small>Defaults to include all events</small>
                            </div>
                            <div *ngIf="property.key !== 'eventIds' && property.key !== 'transactionIds' && property.key !== 'jobId'">
                                <label>{{property.key | prettyProperty}}</label>
                                <br>
                                <div *ngIf="property.value.type === 'number'">
                                    <p-inputNumber (onInput)="setPropertyValue(document, property.key, $event.value)"></p-inputNumber>
                                    <br>
                                </div>
                                <div *ngIf="property.value.format === 'date'">
                                    <p-calendar (onSelect)="setPropertyValue(document, property.key, $event, 'date')" appendTo="body"></p-calendar>
                                    <br>
                                </div>
                                <div *ngIf="property.value.type === 'string' && property.value.format !== 'date'">
                                    <input type="text" (input)="setPropertyValue(document, property.key, $event.target.value)" pInputText />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!document.validationSchema.required?.length || (document.validationSchema.required?.length === 1 && document.validationSchema.required[0] === 'jobId')">
                        <br>
                        <p *ngIf="(document.validationSchema.properties | keyvalue:onCompare)?.length <= 1">No Details Required</p>
                        <p *ngIf="(document.validationSchema.properties | keyvalue:onCompare)?.length > 1">Details are optional</p>
                    </div>
                </p-panel>

            </div>
            <div class="create-status-col" [ngSwitch]="document.status">
                <div *ngSwitchCase="'draft'">
                    <span class="success-text">
                        <i class="pi pi-check-circle"></i>
                        Created
                    </span>
                </div>
                <div *ngSwitchCase="'failed'">
                    <span class="error-text">
                        <i class="pi pi-times-circle"></i>
                        Failed
                    </span>
                    <span class="p-ml-4">
                        <span class="freya-link-button" (click)="runPreCreateChecks(document)">Retry</span>
                    </span>
                </div>
                <div *ngSwitchDefault>
                    <button
                        pButton
                        class="p-button-success p-mr-2"
                        icon="pi pi-file"
                        label="Create"
                        (click)="runPreCreateChecks(document)"
                        [disabled]="!document.valid || document.status === 'pending'"
                    ></button>
                    <button
                        pButton
                        class="p-button-danger"
                        icon="pi pi-trash"
                        (click)="removeDocument(document)"
                        [disabled]="document.status === 'pending'"
                    ></button>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex">
                <div style="flex: 1; padding: 0.5rem 0;">
                    <p-menu #menu [popup]="true" [model]="documentItems" appendTo="body"></p-menu>
                    <button
                        type="button"
                        pButton
                        icon="pi pi-plus"
                        label="Add Document to Create"
                        (click)="menu.toggle($event)"
                        ></button>
                </div>
                <div class="create-all-col">
                    <span
                        [pTooltip]="
                        !selectedDocumentsCreatable ?
                        'Select a new document to create it'
                        : !selectedDocumentsValid ?
                        'Some documents are missing information'
                        : undefined"
                        >
                        <button
                            type="button"
                            pButton
                            class="p-button-success full-width"
                            icon="pi pi-file"
                            label="Create All"
                            [disabled]="!selectedDocumentsValid || !selectedDocumentsCreatable"
                            (click)="createDrafts()"
                            ></button>
                    </span>

                </div>
            </div>
            <small>Looking to create an invoice? <span class="freya-link-button" (click)="openCreateInvoiceDialog()">Click here</span></small>
        </div>
    </div>
</ng-template>

<ng-template #createContentMinimized>
    <span *ngIf="!documentsToCreate?.length; else count">
        Open dialog to select documents.
    </span>
    <ng-template #count>
        <span *ngIf="documentCount.created">
            {{ documentCount.created | i18nPlural: documentsPluralMapping }} created.
        </span>
        <span *ngIf="documentCount.valid">
            {{ documentCount.valid | i18nPlural: documentsPluralMapping}} ready to create.
        </span>
        <span *ngIf="documentCount.invalid">
            {{ documentCount.invalid | i18nPlural: documentsPluralMapping}} missing information.
        </span>
    </ng-template>
</ng-template>


<ng-template #createFooter>
    <span
        [pTooltip]="selectedDocumentsPending ? 'Cannot proceed to next step while documents are still being created' : undefined "
        class="p-ml-auto"
    >
        <button
            pButton
            (click)="goToStep(1)"
            [disabled]="selectedDocumentsPending"
            label="Review and Send"
            icon="pi pi-arrow-right"
            iconPos="right"
        ></button>
    </span>
</ng-template>

<ng-template #reviewAndSendContent>
    <h6>Ready to Send <span *ngIf="!documentsLoading">({{ documentsToSend?.length }})</span></h6>
    <div class="p-grid">
      <ng-container *ngIf="!documentsLoading; else skeletons">
        <app-sendable-document
          *ngFor="let document of documentsToSend"
          [document]="document"
          class="p-col-12"
          (statusChanged)="handleStatusChange($event)"
          ></app-sendable-document>
      </ng-container>
    </div>
    <h6>Sent <span *ngIf="!documentsLoading">({{ sentDocuments?.length }})</span></h6>
<div class="p-grid">
      <ng-container *ngIf="!documentsLoading; else skeletons">
        <app-sendable-document
          *ngFor="let document of sentDocuments"
          [document]="document"
          class="p-col-12"
          (statusChanged)="handleStatusChange($event)"
          ></app-sendable-document>
      </ng-container>
    </div>
</ng-template>

<ng-template #reviewAndSendContentMinimized>
    <span *ngIf="documentsToSend?.length">
        {{ documentsToSend?.length | i18nPlural: documentsPluralMapping }} ready to send.
    </span>
    <span *ngIf="sentDocuments?.length">
        {{ sentDocuments?.length | i18nPlural: documentsPluralMapping }} sent.
    </span>
    <span *ngIf="!documentsToSend?.length && !sentDocuments?.length">
        Open dialog to select documents.
    </span>
</ng-template>

<ng-template #reviewAndSendFooter>
    <button
        pButton
        (click)="goToStep(0)"
        [disabled]="selectedDocumentsPending"
        label="Back to Create"
        icon="pi pi-arrow-left"
    ></button>
    <button
        pButton
        icon="pi pi-times"
        (click)="closeDialog()"
        label="Close"
        [disabled]="selectedDocumentsPending"
        class="p-button-danger"
    ></button>
</ng-template>

<ng-template #skeletons> 
    <div class="p-col-12">
        <div class="p-d-flex p-ai-center p-mb-2">
            <div style="flex: 1;">
                <p-panel
                    [toggleable]="true"
                    [collapsed]="true"
                >
                    <ng-template pTemplate="header">
                        <div class="full-width">
                            <div class="p-grid p-mt-0">
                                <div class="p-col-4">
                                    <span class="p-mr-2">
                                        <p-skeleton width="4.5rem" height="16px"></p-skeleton>
                                    </span>
                                </div>
                                <div class="p-col-8">
                                    <p-skeleton width="7rem" height="16px"></p-skeleton>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <p-skeleton width="100%" height="16px"></p-skeleton>
                </p-panel>
            </div>
            <div class="p-p-2">
                <p-splitButton
                    label="Send"
                    icon="pi pi-send"
                    styleClass="p-button-success"
                    [disabled]="true"
                ></p-splitButton>
            </div>
        </div>
        <p-skeleton width="7rem" height="12px" class="p-mt-2"></p-skeleton>
    </div>
</ng-template>
