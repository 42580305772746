import {Component, OnDestroy, OnInit} from '@angular/core';
import { DetailsHelperService, DetailsItem } from 'src/app/services/details-helper.service';
import { SubSink } from 'subsink';

import { AvailableZoneFragment } from '../../../generated/graphql.generated';

import {AppMainComponent} from '../../app.main.component';
import { PlusAuthenticationService } from '../../core/public-api';
import { AvailableZonesService } from '../../services/available-zones.service';
import { BrandingService } from '../../services/branding.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { findBranchById, generateZoneTree } from '../../utilities/zones.util';

export type AdditionalValueType = 'product';

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html',
    styleUrls: ['./app.rightpanel.component.scss']
})
export class AppRightpanelComponent implements OnInit, OnDestroy{

    subs = new SubSink();

    detailsItem: DetailsItem = {type: 'none', item: null};

    isItemInCurrentZoneOrBelow = true;

    itemZone: AvailableZoneFragment;

    additionalValues: Record<AdditionalValueType, any>;

    constructor(
        public appMain: AppMainComponent,
        private detailHelperService: DetailsHelperService,
        private branding: BrandingService,
        private availableZonesService: AvailableZonesService,
        private plusAuth: PlusAuthenticationService,
        private notify: FreyaNotificationsService,
    ) {}

    ngOnInit(){
        this.subs.sink = this.detailHelperService.detailsItem.subscribe((item) => {
            this.detailsItem = item;

            this.isItemInCurrentZoneOrBelow = !item?.zoneId || Boolean(this.resolveItemZoneFromCurrentZoneDown(item?.zoneId));
            this.itemZone = this.availableZonesService.availableZones.value?.find((z) => z.id === item?.zoneId);

            if (item && this.isItemInCurrentZoneOrBelow) {
                // console.log('Right Panel Opened', item);
            }

            if (item?.additionalValues) {
                this.additionalValues = item.additionalValues;
            }
        });
    }

    /**
     * Searches for the item's zone from the current zone down through its children.
     *
     * @param itemZoneId The ID of the item's zone.
     * @returns The item's zone, if it can find it.
     */
    resolveItemZoneFromCurrentZoneDown(itemZoneId: string): AvailableZoneFragment | undefined {
        if (!itemZoneId) { return; }
        const availableZoneTree = generateZoneTree(this.availableZonesService.availableZones.value);
        const currentBranch = findBranchById(availableZoneTree, this.branding.currentZone().value.id);
        return findBranchById([ currentBranch ], itemZoneId)?.zone;
    }

    async contextIntoItemZone() {
        const { type, item } = this.detailsItem;
        const itemZone = this.itemZone;
        await this.plusAuth.setContext(this.itemZone?.id);
        this.detailHelperService.open(type, item);
        this.notify.success(`Zone changed to ${itemZone.name}`);
    }

    ngOnDestroy(){
        this.subs.unsubscribe();
    }

}
