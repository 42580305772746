import { Directive, HostBinding, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

import { SubSink } from 'subsink';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ruleProperty]'
})
export class RulePropertyDirective implements OnInit, OnDestroy{

  @HostBinding('class') elementClass = '';

  @Input('ruleProperty') property: string;

  component: string;

  subs = new SubSink();

  directComponents = ['textarea', 'input']; // Native elements that should have the style applied directly to them
  ignoreDefaultIconComponents = ['p-inputSwitch', 'p-dropdown']; // Components whose styling for the icon is unique

  constructor(
    private _view: ViewContainerRef
  ) {
    // @ts-ignore, Not a typed property
    // eslint-disable-next-line no-underscore-dangle
    this.component = this._view._hostTNode.value;
  }

  ngOnInit(): void {
    // this.subs.sink = this.ruleCheckerService.triggersUpdated.subscribe((res) => {
    //   const violatedRule = res.find((rule) => rule.property === this.property);

    //   if (!violatedRule) {
    //     this.elementClass = '';
    //     return;
    //   }

    //   const severity = violatedRule.trigger.value.triggerMsgLevel;
    //   const severityAllowlist = [ 'error', 'warn', 'info' ];

    //   if (!severityAllowlist.includes(severity)) {
    //     return;
    //   }

    //   let classes = '';
    //   if (this.ignoreDefaultIconComponents.includes(this.component)){
    //     classes += `rule-${severity}-no-icon `;
    //   }

    //   if(this.directComponents.includes(this.component)){
    //     classes += `rule-${severity}-direct`;
    //   } else {
    //     classes += `rule-error`;
    //   }

    //   this.elementClass = classes;
    // });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
