<div
    class="create-transaction-input"
    [formGroup]="transactionForm"
>
    <ng-template #amount>
        <div *appVar="transactionForm.get('amount') as control">
            <label>Amount</label>
            <br />
            <p-inputNumber
                formControlName="amount"
                mode="currency"
                [currency]="transactionForm.value.job?.currency ? transactionForm.value.job.currency : 'USD'"
                placeholder="Amount"
                [class.p-invalid-input]="!control.valid && control.touched"
                ></p-inputNumber>
            <button
                pButton
                class="p-ml-2"
                label="Max"
                (click)="setMaxValue()"
                [disabled]="!transactionForm.value?.invoice"
                ></button>
            <br>
            <small *ngIf="transactionForm.value?.invoice">Remaining balance on selected invoice: {{invoices[transactionForm.value?.invoice] | remainingJobBalance | currency : transactionForm.value.job?.currency || 'USD'}}</small>
            <br>
            <small *ngIf="transactionForm.value?.type !== 'deposit' ">Remaining balance on job: {{ transactionForm.value?.job | remainingJobBalance | currency : transactionForm.value.job?.currency || 'USD'}}</small>
            <br>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
                ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #transactionType>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <label>Type</label>
                <br>
                <p-dropdown
                    [options]="transactionTypeOptions"
                    formControlName="type"
                    appendTo="body"
                    >
                </p-dropdown>
                <br>
            </div>
            <div class="p-col-6 p-md-6" *appVar="transactionForm.get('invoice') as control">
                <label>Invoice</label>
                <br>
                <p-dropdown
                    [options]="invoiceOptions"
                    placeholder="Select an invoice"
                    formControlName="invoice"
                    appendTo="body"
                    emptyMessage="This job has no invoices yet"
                    optionLabel="label"
                    optionValue="value"
                    >
                </p-dropdown>
                <br>
                <small>Payments and refunds must be linked to an invoice</small>
                <app-validation-messages
                    [control]="control"
                    [requireTouched]="true"
                    >
                </app-validation-messages>
            </div>
            <div class="p-col-12" *ngIf="invoiceOptions.length === 0">
                Trying to process a payment? <a class="freya-link-button" (click)="openCreateInvoice()">Create an invoice first</a>
            </div>
        </div>
    </ng-template>
    <ng-template #customer>
        <div *appVar="transactionForm.get('customer') as control">
            <label>Select Customer</label>
            <br />
            <app-user-search
                formControlName="customer"
            ></app-user-search>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #job>
        <div *appVar="transactionForm.get('job') as control">
            <label>Select Job</label>
            <br />
            <p-autoComplete
                field="code"
                formControlName="job"
                [forceSelection]="true"
                [suggestions]="jobSuggestions"
                (completeMethod)="searchJobs($event)"
                (onSelect)="jobId = control.value.id"
                [dropdown]="true"
                datakey="id"
                appendTo="body"
            >
                <ng-template
                    let-job
                    pTemplate="item"
                >
                    <span>{{job.code}}</span>
                </ng-template>
            </p-autoComplete>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #paymentType>
        <div *appVar="transactionForm.get('paymentType') as control">
            <label>Payment Type</label>
            <br />
            <p-dropdown
                class="full-width"
                placeholder="Select a Transaction Type"
                [options]="paymentTypes"
                appendTo="body"
                (onChange)="checkType()"
                formControlName="paymentType"
                optionValue="id"
            >
                <ng-template
                    let-type
                    pTemplate="item"
                >
                    <span>{{type.value | titlecase}}</span>
                </ng-template>
                <ng-template
                    let-value
                    pTemplate="selectedItem"
                >
                    <span>{{value.value | titlecase}}</span>
                </ng-template>
            </p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #stage>
        <div *appVar="transactionForm.get('stage') as control">
            <label>Transaction Stage</label>
            <br />
            <p-dropdown
                class="full-width"
                placeholder="Select a Stage"
                appendTo="body"
                [options]="transactionStages"
                formControlName="stage"
            >
                <ng-template
                    let-stage
                    pTemplate="item"
                >
                    <span>{{stage | titlecase}}</span>
                </ng-template>
                <ng-template
                    let-value
                    pTemplate="selectedItem"
                >
                    <span>{{value | titlecase}}</span>
                </ng-template>
            </p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #sendReceipt>
        <div *appVar="transactionForm.get('sendReceipt') as control">
            <p-checkbox
                [formControl]="control"
                label="Send Receipt"
                [binary]="true"
            ></p-checkbox>
        </div>
    </ng-template>

</div>

<ng-template #customerReview>
    <span>{{transactionForm.value.customer.givenName}} {{transactionForm.value.customer.familyName}}</span>
</ng-template>

<ng-template #jobReview>
    <span>{{transactionForm.value.job.stage | titlecase}}</span>
</ng-template>

<app-mutate-object
    #mutate
    [fg]="transactionForm"
    objectType="Transaction"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>
