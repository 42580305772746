<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="franchises-card-header">Needs Attention</h4>
        </div>
        <div class="p-col">
            <label>Reasons for attention</label>
            <p-multiSelect
                appendTo="body"
                placeholder="Select Reason"
                class="full-width-multiselect"
                [options]="attentionItemsService.configs$ | async"
                [(ngModel)]="selectedReasons"
                (onChange)="setParams($event.value); setFilter($event.value)"
                optionLabel="label"
                [maxSelectedLabels]="1"
                [selectedItemsLabel]="'{0} Reasons'"
                data-cy="product-multi-select-field"
            >
            </p-multiSelect>
        </div>
    </div>
</div>
<div class="card">
  <app-attention-items
    [filter]="filter"
    [maxExplanations]="4"
    ></app-attention-items>
</div>
