import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { FeedbackService } from '@karve.it/core';
import { FeedbackType } from 'graphql.generated';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { MarkdownHelperService } from 'src/app/services/markdown.service';
import { SubSink } from 'subsink';

interface HelpDialogPageInfo {
  id: string;
  title: string;
  videoLink: string;
  markdownFile: string;
  associatedIds: number[];
}

export interface OpenToPageInput {
  targetHelpId: string;
  sectionId?: string;
}

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})

export class HelpDialogComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  helpPageInfoList: HelpDialogPageInfo[];
  currentPageInfo: HelpDialogPageInfo;

  // Filtered list of pages from page search input
  filteredPages: HelpDialogPageInfo[];
  pageSearchInput: string;

  // Stores the helpId of the current page and used for matching the id of the HelpDialogPageInfo.
  helpId: string = this.router.routerState.snapshot.root.firstChild.firstChild.data.helpId;
  historyHelpId: string[] = [];
  // Current page index from history list
  historyIndex = -1;

  markdownContent: string;

  imgAlt: string;
  imgSrc: string;
  safeVideoSrc: SafeResourceUrl;

  showHelpDialog = false;
  showImagePopupDialog = false;
  disabledFeedbackBtn = false;

  sectionToScroll: string;

  dataParsed = new Subject();

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private localNotify: FreyaNotificationsService,
    private feedbackService: FeedbackService,
    private http: HttpClient,
    public markdownHelper: MarkdownHelperService,
  ) { }

  // Prevent both the Image popup dialog and Help dialog from closing simultaneously when the escape key is pressed
  @HostListener('document:keydown.escape', ['$event']) onEscapeKeyDown(event: KeyboardEvent) {
    if (this.showHelpDialog && !this.showImagePopupDialog) {
      this.showHelpDialog = false;
    }
  }

  ngOnInit(): void {
    this.helpPageInfoList = require('./help-dialog.json');
    this.subs.sink = this.router.events.subscribe((e) => {
      if (!(e instanceof NavigationEnd)) { return; }

      this.helpId = this.getHelpIdFromRouterState();
    });

    this.subs.sink = this.dataParsed.pipe(debounceTime(20)).subscribe(() => this.scrollToSection());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Traverses router state tree searching for the helpId of the farthest node whose route has a helpId set
   */
  getHelpIdFromRouterState() {
    let currentSnapshot = this.router.routerState.snapshot.root.firstChild.firstChild;

    let helpId: string = currentSnapshot.data?.helpId;

    while (currentSnapshot.firstChild) {
      currentSnapshot = currentSnapshot.firstChild;

      if (currentSnapshot.data?.helpId) {
        helpId = currentSnapshot.data.helpId;
      }
    }

    return helpId;
  }

  toggleShowDialog() {
    this.showHelpDialog = !this.showHelpDialog;
    this.setCurrentPageInfo();
  }

  /**
   * Set relevant page information for the help dialog based on the given helpId or helpID get from the current route.
   * If there is no matching page id then set page info to dashboard.
   */
  setCurrentPageInfo(targetHelpId?: string, fromButton?: boolean) {
    // this.previousHelpId = targetHelpId ? this.helpId : undefined;
    const id = targetHelpId ? targetHelpId : this.helpId;
    if (!fromButton) {
      this.pushHelpIdToHistory(id);
    }

    const foundPage = this.helpPageInfoList.find(page => page.id === id);
    this.currentPageInfo = foundPage || this.helpPageInfoList.find(pageInfo => pageInfo.id === 'dashboard');
    this.safeVideoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentPageInfo.videoLink);
    this.updateMarkdown();
  }

  /**
   * Resets the current page to the selected page and updates.
   *
   * @param selectedItem The selected page from search bar to set as the current page.
   */
  resetCurrentPage(selectedItem: { value: HelpDialogPageInfo }) {
    this.currentPageInfo = this.helpPageInfoList.find(page => page.title === selectedItem.value.title);
    this.pushHelpIdToHistory(this.currentPageInfo.id);
    this.safeVideoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentPageInfo.videoLink);

    this.pageSearchInput = '';
    this.updateMarkdown();
  }

  filterPages(searchEvent) {
    const query = searchEvent.query.toLowerCase();
    this.filteredPages = this.helpPageInfoList.filter(
      data => data.title.toLowerCase().includes(query)
    );
  }

  updateMarkdown() {
    this.helpId = this.currentPageInfo.markdownFile.replace('.md', '');
    const markdownFilePath = `../../../assets/help-content/markdownFiles/${this.currentPageInfo.markdownFile}`;
    this.http.get(markdownFilePath, { responseType: 'text' }).subscribe((data) => {
      this.markdownContent = data;
      this.pageSearchInput = null;
      this.disabledFeedbackBtn = false;
    });
  }

  /**
   * If the user clicks on a link to another page, it will take them to that page,
   * and if the user clicks on an image, it will display a pop-up dialog with the image
   */
  linkMarkdown(ev: PointerEvent) {
    ev.preventDefault();

    const target = ev.target as HTMLElement;
    if ('href' in target) {
      const anchor = target as HTMLAnchorElement;
      const { pathname } = new URL(anchor.href);
      const [_, helpId] = pathname.split('/');
      this.setCurrentPageInfo(helpId);
    }
    if ('src' in target) {
      const anchor = target as HTMLImageElement;
      this.imgAlt = anchor.alt;
      const { pathname } = new URL(anchor.src);
      this.imgSrc = pathname;
      this.showImagePopupDialog = true;
    }
  };

  submitHelpfulFeedback(isHelpful: boolean) {
    const details = isHelpful ? `${this.currentPageInfo.title} information is helpful` :
      `${this.currentPageInfo.title} information is not helpful`;
    this.feedbackService.submitFeedback({
      feedback: {
        details,
        feedbackType: 'feedback' as FeedbackType,
        activeRoute: this.router.url
      }
    }).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Thanks for the Feedback' });
      this.disabledFeedbackBtn = true;
    });
  }

  pushHelpIdToHistory(targetPagehelpId: string) {
    // Prevent adding page history if a user tries to go to a page that is the same as the current page.
    if (targetPagehelpId === this.historyHelpId[this.historyIndex] || targetPagehelpId === this.historyHelpId[this.historyIndex + 1]) {
      return;
    }
    this.historyIndex++;
    this.historyHelpId.splice(this.historyIndex, 0, targetPagehelpId);
  }

  openToPage(targetHelpId: string, sectionId?: string) {
    this.setCurrentPageInfo(targetHelpId);
    this.showHelpDialog = true;
    this.sectionToScroll = sectionId;
  }

  scrollToSection() {
    if (!this.sectionToScroll) { return; }

    const sectionHeading = document.getElementById(this.sectionToScroll);

    sectionHeading.scrollIntoView({ behavior: 'smooth' });

    this.sectionToScroll = undefined;
  }

  goPreviousPage() {
    this.historyIndex--;
    this.setCurrentPageInfo(this.historyHelpId[this.historyIndex], true);
  }

  goNextPage() {
    this.historyIndex++;
    this.setCurrentPageInfo(this.historyHelpId[this.historyIndex], true);
  }

}
