<div class="chip-content" [ngClass]="{ conflict: hasConflict }">
  <div class="chip-details">
    <p-avatar
      *ngIf="hasConflict; else noConflict"
      [icon]="panelType === 'right' ? 'pi pi-exclamation-triangle' : panelType === 'dialog' ? icon :null"
      [image]="panelType === 'dialog' && imageUrl"
      [label]="
        panelType === 'dialog' && !imageUrl && !icon
          ? name.charAt(0).toUpperCase()
          : null
      "
      shape="circle"
      class="transparent-bg"
    ></p-avatar>

    <ng-template #noConflict>
      <p-avatar
        [image]="imageUrl"
        [label]="!imageUrl && !icon ? name.charAt(0).toUpperCase() : null"
        [icon]="icon ? icon : null"
        shape="circle"
        [style]="{
          'background-color': !imageUrl && !icon ? '#c9ffa97d' : 'transparent',
        }"
      ></p-avatar>
    </ng-template>

    <span class="name">
      {{ name }}
    </span>
    <span class="icon" *ngIf="showStar">
      <i class="pi pi-star-fill"></i>
    </span>
  </div>

  <div class="p-d-flex chip-meta-info">
    <ng-container *ngIf="!hasConflict">
      <span class="name" *ngIf="eventsCount !== null || revenue !== null">
        <ng-container *ngIf="eventsCount !== null">
          {{ eventsCount }} {{ panelType === "right" ? "other jobs" : "jobs" }}
          <ng-container *ngIf="revenue !== null"> | </ng-container>
        </ng-container>
        <ng-container *ngIf="revenue !== null">
          {{ revenue | freyaCurrency: "CAD" }}
        </ng-container>
      </span>
    </ng-container>
    <ng-container *ngIf="hasConflict && panelType === 'dialog'">
      <span class="name"> Conflict </span>
    </ng-container>
    <i
      class="remove-icon pi pi-times p-ml-3"
      (click)="onRemove()"
      *ngIf="removable"
    ></i>
  </div>
</div>
