export function formatCurrency(amount: number, currency = 'USD'): string{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
    });

    return formatter.format(amount);
}

/**
 * Converts a value from dollars eg. 2.37 to cents 237
 *
 * @param dollars The value in dollars
 * @param strict Whether to return original value if value passed to `dollars` is null or undefined
 */
export function convertDollarsToCents(dollars: number, strict?: boolean): number{

    if (strict && (dollars === undefined || dollars === null)) {
        return dollars;
    }

    const cents = Math.round(dollars * 100);
    return cents;
}

/**
 * Converts a value from cents to dollars
 *
 * @param cents The value in cents
 * @param strict Whether to return original value if value passed to `cents` is null or undefined
 */
export function convertCentsToDollars(cents: number, strict?: boolean): number{
    if (strict && (cents === undefined || cents === null)) {
        return cents;
    }
    const dollars = (cents / 100);
    return dollars;
}
