/* eslint-disable no-fallthrough */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { JobEventStatus, JobEventType, JOB_EVENT_STATUSES } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { getArrivalTimeList } from 'src/app/shared/event-location/calendarevent.util';
import { getJobCustomer } from 'src/app/utilities/job-customer.util';
import { SubSink } from 'subsink';

import { CalendarEventForEstimateFragment, FullCalendarEventFragment, Job } from '../../../generated/graphql.generated';
import { DocumentHelperService } from '../../services/document-helper.service';

import { EventHelperService } from '../../services/event-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { currentTimeSeconds } from '../../time';



@Component({
  selector: 'app-event-confirmation',
  templateUrl: './event-confirmation.component.html',
  styleUrls: ['./event-confirmation.component.scss']
})
export class EventConfirmationComponent implements OnInit, OnChanges {
  @Input() event: CalendarEventForEstimateFragment;
  @Input() eventType: JobEventType;
  @Input() job: Job;

  @Input() readonly = false; // If true all create/update is disabled
  @Input() readonlyTooltip: string;

  @Output() printButtonClicked = new EventEmitter<null>();

  payableAmount: number;

  // BUTTON VARIABLES
  nextStatus: JobEventStatus;
  arrivalTimeList: number[] = [];
  subs = new SubSink();

  items = [
    {
      id: 'booked',
      label: 'Mark booked',
      icon: 'pi pi-calendar',
      visible: true,
      command: () => {
        this.promoteEvent('booked');
      }
    },
    {
      id: 'confirm',
      label: 'Mark confirmed',
      icon: 'pi pi-thumbs-up',
      visible: true,
      command: () => {
        this.promoteEvent('confirmed');
      }
    },
    {
      id: 'complete',
      label: 'Mark complete',
      icon: 'pi pi-check-circle',
      command: () => {
        this.promoteEvent('completed');
      },
      visible: true,
    },
    {
      label: 'Cancel',
      icon: 'pi pi-trash',
      command: () => {
        // Mark as Cancelled
        this.eventHelper.unscheduleEvent(this.event);
      },
      visible: true,
    },
    { seperator: true },
    {
      label: 'View',
      icon: 'pi pi-eye',
      command: () => this.openEventDetails()
    },
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.openMutateEvent(),
      visible: true,
    },
    {
      label: 'Print',
      icon: 'pi pi-print',
      command: () => this.printButtonClicked.emit(null),
      visible: true,
    },
  ];

  constructor(
    private detailsHelperService: DetailsHelperService,
    private freyaMutate: FreyaMutateService,
    private router: Router,
    public eventHelper: EventHelperService,
    private documentHelper: DocumentHelperService,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.event || !this.event) { return; }
    this.filterPastStatuses();
    this.getNextEventStatus();
    this.setPayableAmount();
    this.checkIfLocked();
    this.arrivalTimeList= getArrivalTimeList(this.event as any);
  }

  filterPastStatuses() {
    switch (this.event.status) {
      case 'completed':
        this.items[2].visible = false;
      case 'confirmed':
        this.items[1].visible = false;
      case 'booked':
        this.items[0].visible = false;
    }
  }

  getNextEventStatus() {
    const currentIndex = JOB_EVENT_STATUSES.findIndex(((s) => s === this.event.status));

    this.nextStatus = JOB_EVENT_STATUSES[currentIndex + 1] as JobEventStatus;

    if (!this.nextStatus) {
      this.nextStatus = 'cancelled';
    }
  }

  promoteToNextStatus() {
    if (this.nextStatus === 'cancelled') {
      this.eventHelper.unscheduleEvent(this.event);
    } else {
      this.promoteEvent(this.nextStatus);
    }
  }

  promoteEvent(status: JobEventStatus) {
    this.eventHelper.updateEventStatus(this.event.id, status);
    this.getNextEventStatus();
    this.checkNeedsInvoice(status);
  }

  deleteEvent() {
    this.freyaMutate.openDeleteObject({
      objectId: this.event.id,
      objectName: this.event.title,
      objectType: 'calendarEvent',
      showInsteadOfCancel: `Don't Cancel this Event`,
      showInsteadOfDelete: 'Cancel this Event',
      customTitle: 'Cancel Event?'
    });
  }

  goToBooking() {
    this.router.navigate(['estimating', this.job.id], { queryParams: { step: 'booking', activeEventId: this.event.id } });
  }

  openEventDetails() {
    this.detailsHelperService.open('calendar-event', { id: this.event.id });
  }

  openMutateTransaction() {

    const [ invoice ] = this.event.invoices.filter(i => !i.deletedAt && !i.voidedAt);

    if (!invoice) {
      console.warn(`No invoice found for event ${this.event.id}`);
      return;
    }

    this.documentHelper.payInvoice(invoice, this.payableAmount);
  }

  openMutateEvent() {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'calendarEvent',
      object: this.event,
    });
  }

  setPayableAmount() {
    if (!this.event || !this.job || this.event.status === 'cancelled') { return; };

    const [ invoice ] = this.event.invoices?.filter(i => !i.deletedAt && !i.voidedAt) || [];

    if (!invoice) { return; }

    if (invoice.total < 0) { return; }

    const remainingBalance = invoice.total - invoice.paidTotal;
    if (this.event.total > remainingBalance) {
      this.payableAmount = remainingBalance;
      return;
    };
    this.payableAmount = Math.floor(this.event.total);
  }

  goToEventOnSchedule(event: FullCalendarEventFragment){
    this.router.navigate(['/schedule'], { queryParams: {date: dayjs(event.start * 1000).format('YYYY-MM-DD')} });
  }

  checkIfLocked() {
    const isLocked = this.event?.end && this.freyaHelper?.lockDate > this.event?.end;

    if (isLocked) {

      this.readonly = true;

      // Do not overwrite as the component may be readonly for another reason (e.g. job is archived)
      // The tooltip might be set from the parent component and we want to give that priority
      this.readonlyTooltip = this.readonlyTooltip || `This event ends before ${ this.freyaHelper.getFormattedLockDate()} (Lock Date)`;
    };

  }

  openCreateInvoiceDialog() {
    if (this.event.status !== 'completed') { return; }
    this.documentHelper.openCreateInvoiceDialog(this.job.id);
  }

  checkNeedsInvoice(nextStatus: JobEventStatus) {

    if (nextStatus !== 'completed') { return; }

    const now = currentTimeSeconds();

    const otherEvents = this.job.events.filter(e => e.id !== this.event.id);

    const eventsThatStarted = otherEvents.filter(e => e.start <= now);

    if (eventsThatStarted.every(e => e.status === 'completed')) {
      this.documentHelper.openCreateInvoiceDialog(this.job.id);
    }
  }

}
