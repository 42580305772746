import { Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommentService } from '@karve.it/features';
import { AddCommentFunctionInput } from '@karve.it/interfaces/comments';
import { SubSink } from 'subsink';

import { ACCESS_TYPE } from '../../interfaces/shareAccess';

import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit, OnDestroy {

  @Input() nameForComment = 'comment'; // The name used for comments eg. 'comment', 'note' etc.
  @Input() objectId: string;
  @Input() objectType: string;
  @Input() requiredAccessLevel?: ACCESS_TYPE;

  // SUBS
  subs = new SubSink();

  // FORM
  commentForm = new UntypedFormGroup({
    text: new UntypedFormControl(undefined, [Validators.required]),
  });

  loading = false;

  hasPermission = false;

  constructor(
    private detailsHelper: DetailsHelperService,
    private commentService: CommentService,
    private localNotify: FreyaNotificationsService,
    private permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.permissionHandler.watchPermissions(['comments.add'])
      .subscribe((res) => {

        this.hasPermission = res[0];

        if (this.hasPermission) {
          this.commentForm.enable();
        } else {
          this.commentForm.disable();
        }
      });
  };

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addComment(){
    const addCommentInput = {
      input: {
        requiredAccessLevel: this.requiredAccessLevel,
        objectId: this.objectId,
        objectType: this.objectType,
        text: this.commentForm.value.text,
        attributes: [this.nameForComment.toLowerCase()],
      }
    } as AddCommentFunctionInput;

    this.loading = true;

    this.subs.sink = this.commentService.addComment(addCommentInput).subscribe((res) => {

      this.loading = false;

      this.detailsHelper.pushUpdate({
        id:this.objectId,
        type:'Comments',
        action:'create',
      });

      this.localNotify.addToast.next({severity: 'success', summary: `${this.nameForComment} added`});
      this.resetForm();
    }, (err) => {

      this.loading = false;

      this.localNotify.apolloError(`Failed to add ${this.nameForComment}`, err);
    });
  }

  resetForm(){
    this.commentForm.reset();
  }

}
