/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import {gql} from 'graphql-tag';

import { User } from './auth';
import { Access } from './shareAccess';

export interface Page {
    id: string;
    createdOn: number;
    updatedOn: number;
    deletedAt?: number;
    title: string;
    // The path to the page, e.g. 'aboutus'
    path: string;
    attributes: string[];
    blocks: Block[];
    accesses?: Access[];
    user?: User;
}


export interface Block {
    id: string;
    createdOn: number;
    updatedOn: number;
    deletedAt?: number;
    name?: string;
    // Specifies how the block should be rendered, handled on the frontend
    blockType: string;
    content?: string;
    /**
     * Display order of a block on a page, resolves null unless the block is subqueried on a Page
     * or if Blocks are filtered by a single page
     */
    order?: number;
    attributes: string[];
    pages?: Page[];
    accesses?: Access[];
    user?: User;
}





export interface ListPagesQueryOutput {
    pages: ListPagesOutput;
}

export interface ListBlocksQueryOutput {
    blocks: ListBlocksOutput;
}

export interface CreatePageOutput {
    createPage: Page;
}

export interface EditPageOutput {
    editPage: EditPageResult;
}

export interface RemovePagesOutput {
    removePages: removePageResult;
}

export interface CreateBlockOutput {
    createBlock: Block;
}

export interface EditBlockOutput {
    editBlock: BlockEditOutput;
}

export interface DeleteBlocksOutput {
    deleteBlocks: BlockDeleteOutput;
}

export interface PageInput {
    title: string;
    path: string;
    attributes: string[];
    blocks: BlockInput[];
}


export interface BlockInput {
    blockType: string;
    name?: string;
    content?: string;
    attributes: string[];
    //    Input for the placement of the block in the page's block order.    Pass in empty value for default order placement
    orderInput: OrderObject;
}


export interface OrderObject {
    //    Valid values: 'first', 'last', 'before', 'after'    default value: 'last'
    placement?: string;
    //    Only required if placement value is either 'before' or    'after', the blockId the placement value is in reference to
    reference?: string;
}


export interface ListPagesOutput {
    total: number;
    pages: Page[];
    skipped?: number;
    limited?: number;
}


export interface PageInputEdit {
    title?: string;
    path?: string;
    attributes: string[];
}


export interface EditBlock {
    blockId: string;
    blockEdit?: BlockEditInput;
}


export interface BlockEditInput {
    content?: string;
    blockType?: string;
    attributes: string[];
    name?: string;
    order?: OrderObject;
}


export interface BlockEditOutput {
    isSuccess: boolean;
}


export interface BlockDeleteOutput {
    blocksDetached?: number;
    blocksDeleted?: number;
}


export interface EditPageResult {
    pageEdited?: boolean;
    blocksCreated?: number;
    blocksAdded?: number;
    blocksEdited?: number;
    blocksRemoved?: number;
}


export interface removePageResult {
    isSuccess?: boolean;
}


export interface ListBlocksOutput {
    total?: number;
    skipped?: number;
    limited?: number;
    blocks: Block[];
}


export interface PageSubscriptionOutput {
    pageId: string;
    pagePath: string;
}


export interface BlockSubscriptionOutput {
    blockId?: string;
    blockPages: string[];
}


export interface PagesInput {
    ids?: string[];
    authors?: string[];
    hasAttributes?: string[];
    hasAnyAttributes?: string[];
    title?: string;
    path?: string;
    hasBlocks?: string[];
    skip?: number;
    limit?: number;
    sortBy?: string;
    deleted?: boolean;
}



export interface BlocksInput {
    ids?: string[];
    authors?: string[];
    inPages?: string[];
    names?: string[];
    blockType?: string;
    hasAttributes?: string[];
    hasAnyAttributes?: string[];
    sortBy?: string;
    skip?: number;
    limit?: number;
}



export interface CreatePageInput {
    page?: PageInput;
    author?: string;
}



export interface EditPageInput {
    pageId?: string;
    pageEdit?: PageInputEdit;
    addBlocks?: string[];
    deleteBlocks?: string[];
    orderBlocks?: string[];
}



export interface RemovePagesInput {
    ids?: string[];
    delete?: boolean;
}



export interface CreateBlockInput {
    pageId?: string;
    block?: BlockInput;
    author?: string;
}



export interface EditBlockInput {
    blockId?: string;
    blockEdit?: BlockEditInput;
}

export interface DeleteBlocksInput {
    blockIds?: string[];
}

export const pagesProperties = `
    id
    createdOn
    updatedOn
    deletedAt
    title
    path
    attributes
    blocks {
    id
    }
    accesses {
    id
    }
    user {
    id
    email
    givenName
    familyName
    }
    history {
    history {
        id
    }
    skipped
    limited
    total
    }
`;

export const blockProperties = `
    id
    createdOn
    updatedOn
    deletedAt
    name
    blockType
    content
    order
    attributes
    pages {
    id
    }
    accesses {
    id
    }
    user {
    id
    }
`;

export const pagesQuery = gql`
	query pages($ids: [String!], $authors: [String!], $hasAttributes: [String!], $hasAnyAttributes: [String!], $title: String, $path: String, $hasBlocks: [String!], $skip: Int, $limit: Int, $sortBy: String, $deleted: Boolean, ){
		pages(ids: $ids, authors: $authors, hasAttributes: $hasAttributes, hasAnyAttributes: $hasAnyAttributes, title: $title, path: $path, hasBlocks: $hasBlocks, skip: $skip, limit: $limit, sortBy: $sortBy, deleted: $deleted, ){
			    total
			    pages{
                    ${pagesProperties}
                }			    
                skipped
			    limited

	}		}
`;


export const blocksQuery = gql`
	query blocks($ids: [ID!], $authors: [String!], $inPages: [String!], $names: [String!], $blockType: String, $hasAttributes: [String!], $hasAnyAttributes: [String!], $sortBy: String, $skip: Int, $limit: Int, ){
		blocks(ids: $ids, authors: $authors, inPages: $inPages, names: $names, blockType: $blockType, hasAttributes: $hasAttributes, hasAnyAttributes: $hasAnyAttributes, sortBy: $sortBy, skip: $skip, limit: $limit, ){
			    total
			    skipped
			    limited
			    blocks{
                    ${blockProperties}
                }
	}		}
`;


export const createPageMutation = gql`
	mutation createPage($page: PageInput!, $author: String, ){
		createPage(page: $page, author: $author, ){
			    id			    
                createdOn
			    updatedOn
			    deletedAt
			    title
			    path
			    attributes
	}		}
`;


export const editPageMutation = gql`
	mutation editPage($pageId: String!, $pageEdit: PageInputEdit, $addBlocks: [String!], $deleteBlocks: [String!], $orderBlocks: [String!], ){
		editPage(pageId: $pageId, pageEdit: $pageEdit, addBlocks: $addBlocks, deleteBlocks: $deleteBlocks, orderBlocks: $orderBlocks, ){
			    pageEdited
			    blocksCreated
			    blocksAdded
			    blocksEdited
			    blocksRemoved
	}		}
`;


export const removePagesMutation = gql`
	mutation removePages($ids: [String!], $delete: Boolean, ){
		removePages(ids: $ids, delete: $delete, ){
			    isSuccess

	}		}
`;


export const createBlockMutation = gql`
	mutation createBlock($pageId: String!, $block: BlockInput!, $author: String, ){
		createBlock(pageId: $pageId, block: $block, author: $author, ){
			    id		    
                createdOn
			    updatedOn
			    deletedAt
			    name
			    blockType
			    content
			    order
			    attributes
	}		}
`;


export const editBlockMutation = gql`
	mutation editBlock($blockId: ID!, $blockEdit: BlockEditInput!, ){
		editBlock(blockId: $blockId, blockEdit: $blockEdit, ){
			    isSuccess

	    }		
    }
`;


export const deleteBlocksMutation = gql`
	mutation deleteBlocks($blockIds: [String!]!, ){
		deleteBlocks(blockIds: $blockIds, ){
			    blocksDetached
				blocksDeleted

	    }		
    }
`;

