import { Component, Input, OnInit } from '@angular/core';

import { AppComponent } from '../../app.component';
import { text } from '../../global.constants';

@Component({
  selector: 'app-object-not-found',
  templateUrl: './object-not-found.component.html',
  styleUrls: ['./object-not-found.component.scss']
})
export class ObjectNotFoundComponent implements OnInit {

  @Input() title = '404';
  @Input() subtitle = 'Looks like you are lost.';

  @Input() goBackText = 'Go back to home';
  @Input() goBackLink = [ '/' ];

  company = text.companyName;
  project = text.projectName;
  constructor(public app: AppComponent) { }

  ngOnInit(): void {
  }

}
