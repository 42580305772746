import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Discount, DiscountTypes } from '@karve.it/interfaces/discounts';

@Pipe({
  name: 'discountAmount'
})
export class DiscountAmountPipe implements PipeTransform {

  constructor(private currency: CurrencyPipe){}

  transform(discount: Discount, currency: string = 'CAD'): string {
    if (discount.discountType === DiscountTypes.amount){
      return this.currency.transform(discount.amount / 100, currency);
    } else if (discount.discountType === DiscountTypes.percentage) {
      return `${discount.amount}%`;
    }
  }

}
