import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rename'
})
export class RenamePipe implements PipeTransform {

  transform(value: string, currentName: string, newName: string, exact = true): string {
    if (exact && value === currentName) {
      return newName;
    }
    if (!exact && value.toLowerCase().startsWith(currentName)) {
      return newName;
    }
    return value;
  }

}
