import { Injectable } from '@angular/core';
import { CalendarEvent, Charge, EstimatesJobFragment, Job, Product } from 'src/generated/graphql.generated';

import { ChargeWithKey } from '../estimates/estimate-breakdown/estimate-breakdown.component';

import { EventWithCharges } from '../estimates/estimate-confirmation/estimate-confirmation.component';
import { JOB_EVENT_TYPES } from '../global.constants';
import { sortByOrder } from '../utilities/order.util';

import { FreyaHelperService } from './freya-helper.service';
import { ProductHelperService } from './product-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ChargeHelperService {

  constructor(
    private freyaHelper: FreyaHelperService,
    private productHelper: ProductHelperService,
  ) { }

  // getEventFromCharge(charge: Charge) {
  //   return this.freyaHelper.getAttributeValueByPrefix(charge.attributes, CHARGE_EVENT_PREFIX);
  // }

  getAutoInfoFromCharge(charge: Charge) {
    return this.freyaHelper.getAttributeValueByPrefix(charge.attributes, 'auto-info');

  }
  getAutoModeFromCharge(charge: Charge): [ 'manual' | 'auto', string ] {
    const auto = this.freyaHelper.getAttributeValueByPrefix(charge.attributes, 'auto');
    const manual = this.freyaHelper.getAttributeValueByPrefix(charge.attributes, 'manual');
    if (auto) { return [ 'auto', auto ]; }
    if (manual) { return [ 'manual', manual ]; }
    return [ undefined, undefined ];

  }

  /**
   * Returns the subtotal of a charge.
   * If the charge already has a set subtotal, it simply returns that.
   * Otherwise, returns the product of the charge's quantity and amount.
   *
   * Note: works correctly on charges of both fixed and percentage price type
   * as long as the provided charge already has a set subtotal.
   * However, if the charge does not have a set subtotal,
   * will miscalculate the subtotal if the charge is of percentage price type.
   *
   * @param charge The charge whose subtotal you want to get.
   * @param quantity The quantity to use to calculate the charge's subtotal if it doesn't already have one.
   * Defaults to the charge's own quantity.
   * @param product  The product to use to calculate the charge's subtotal if it doesn't already have one.
   * Defaults to the charge's own product.
   * @returns The charge's subtotal.
   */
  getChargeSubtotal(
    charge: Charge,
    quantity: number = charge.quantity,
    product: Product = charge.product
  ): number{
    if (charge.chargeSubTotal !== undefined) {
      return charge.chargeSubTotal;
    };
    if (charge.price){
      return (charge.price.amount * quantity);
    } else if(charge.amount) {
      return (charge.amount * quantity);
    };

    if (!quantity || !product || !product?.prices) { return 0; }

    return (product.prices[0].amount * quantity);
  }

  /**
   * Breaks down a job's charges into event categories,
   * calculing each category's subtotal, taxtotal and total based on its charges
   * as well as the job's total and subtotal.
   *
   * @param job The job whose charges you want to break down.
   * @param requiredEventTypes Any event types that must be included in the breakdown,
   * even if the job contains no events or charges associated with that type.
   * @param charges The charges to break down. Defaults to the job's charges.
   * @param events The events to include in the breakdown. Defaults to the job's events.
   * @returns An object containing required events, orphan charges, the job's total and subtotal.
   */
  calculateJobTotals(
    job: Job,
    requiredEventTypes: string[] = [],
    charges = job?.charges || [],
    events = job?.events || []
    ) {
      // TO BE RETURNED

      // Event types such that the job has charges associated with that type
      const eventsWithCharges: EventWithCharges[] = [];

      // Event types such that:
      // (a) the job has an existing event of that type, or
      // (b) the job has charges associated with that type, or
      // (c) the event type was explicitly set as required above
      const requiredEvents: EventWithCharges[] = [];

      // Charges with no associated event type
      let orphanCharges: Charge[];

      // Job totals
      let subtotal =  0;
      let taxTotal = 0;

      for (const eventType of [...JOB_EVENT_TYPES, 'none']) {

        const calendarEvent = (events.find((ev) => ev.type === eventType) as unknown as CalendarEvent);

        let eventCharges: Charge[] = [];

        if (eventType !== 'none') {
          eventCharges = charges.filter((c) => c.calendarEvent.id === calendarEvent?.id);
        }

        //sortByOrder(eventCharges);

        const newEvent: EventWithCharges = {
            // type: eventType,
            id: calendarEvent?.id,
            charges: eventCharges,
            event: calendarEvent,
            subtotal: this.addChargeSubtotals(eventCharges),
            discountTotal: job?.events.find((e) => e.type === eventType)?.discountTotal,
            taxTotal: eventCharges.map((charge) => charge.taxTotal).reduce((sum, current) => sum + current, 0),
            total: eventCharges.map((charge) => charge.total).reduce((sum, current) => sum + current, 0)
        };

        if (newEvent.charges?.length) {
          eventsWithCharges.push(newEvent);
          subtotal += newEvent.subtotal;
          taxTotal += newEvent.taxTotal;
        };

        if (newEvent.event || newEvent.charges?.length || requiredEventTypes.includes(newEvent.event.type)){
          requiredEvents.push(newEvent);
        };
      };

      // TODO: Factor in discounts
      // const total = subtotal + taxTotal;

      return { requiredEvents, orphanCharges, subtotal, taxTotal };
  }

  calculateEventsWithCharges(job: EstimatesJobFragment, charges = job?.charges || [], includeCancelled = false){
    const eventsWithCharges = [];

    for (const event of job.events) {

      if (event.status === 'cancelled' && !includeCancelled) { continue; };

      const eventCharges = charges.filter((charge) => charge.calendarEvent?.id === event.id) as any;

      sortByOrder(eventCharges);

      eventsWithCharges.push({
        charges: eventCharges,
        id: event.id,
        subtotal: this.addChargeSubtotals(eventCharges),
        event: event as any,
        discountTotal: job?.events.find((e) => e.id === event.id)?.discountTotal,
        taxTotal: eventCharges.map((charge) => charge.taxTotal).reduce((sum, current) => sum + current, 0),
        total: eventCharges.map((charge) => charge.total).reduce((sum, current) => sum + current, 0)
      });
    }

    return eventsWithCharges;
  }

  /**
   * Adds up the subtotals of all provided charges.
   *
   * @param charges The charges whose subtotals you want to add up.
   * @returns The sum of their subtotals.
   */
  addChargeSubtotals(charges: Charge[]): number {
    if (!charges || !charges.length) { return 0; };
    let total = 0;
    for (const charge of charges) {
      total += this.getChargeSubtotal(charge);
    };
    return total;
  }

  /**
   * Adds up the subtotal of all charges of fixed price type in a provided array of charges.
   *
   * Note: Charges with no prices are treated as charges of fixed price.
   *
   * @param charges An array of charges containing the charges of fixed price type whose subtotals you want to add up.
   * @returns The sum of their subtotals.
   */
  getFixedTotal(charges: Charge[]) {
    return this.addChargeSubtotals(charges.filter((c) => !c.price || c?.price?.priceType === 'fixed'));
  }

  getChargeAmount(charge: Charge) {

    if(!charge) { return 0; }

    const priceType = this.getPriceType(charge);

    if (charge.amount) {
      return priceType === 'fixed' ? charge.amount / 100 : charge.amount;
    }

    if(charge.price){
      return priceType === 'fixed' ? charge.price.amount / 100 : charge.price.amount;
    }

    if(charge.product?.id){
      const activePrice = this.productHelper.getActivePrice(charge.product.prices || []);
      return priceType === 'fixed' ? activePrice.amount / 100 : activePrice.amount;
    }

    return 0;

  }

  getPriceType(charge: ChargeWithKey) {

    if (charge.price) {
      return charge.price.priceType;
    }

    if (charge.product) {

      const activePrice = this.productHelper.getActivePrice(charge.product.prices);

      if (activePrice) {
        return activePrice.priceType;
      }
    }

    return 'fixed';
  }
}
