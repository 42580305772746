import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';


import { gql } from 'graphql-tag';

import { map } from 'rxjs/operators';

import { PlusAuthenticationService } from './plus-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {


  constructor(
    private apollo: Apollo,
    private router: Router,
    private auth: PlusAuthenticationService,
  ) {

  }

  requestPasswordReset(
      email: string,
  ) {
      return this.apollo.mutate<RequestPasswordResetResult>({
          mutation: requestPasswordResetMutation,
          variables: { email },
      }).pipe(map((val) => {
        if (!val.data || !val.data.createPasswordResetToken) { return false; }
        return true;
      }));
  }

  resetPassword(token: string, newPassword: string) {
    return this.apollo.mutate<ResetPasswordResult>({
        mutation: resetPasswordMutation,
        variables: { token, newPassword },
    }).pipe(map((val) => {
      if (!val.data || !val.data.redeemPasswordResetToken) { return false; }
      return true;
    }));
  }

}

export const requestPasswordResetMutation = gql`
mutation requestPasswordReset(
  $userId: String
  $email: String
) {
  createPasswordResetToken(
    userId: $userId
    email: $email
  )
}
`;

export interface RequestPasswordResetResult {
    createPasswordResetToken: boolean;
}

export const resetPasswordMutation = gql`
mutation resetPassword(
  $token: String!
  $newPassword: String!
) {
  redeemPasswordResetToken(
    token: $token
    newPassword: $newPassword
  )
}
`;

export interface ResetPasswordResult {
    redeemPasswordResetToken: boolean;
}
