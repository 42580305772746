import { gql } from 'graphql-tag';

import { User } from './auth';
import { ZoneDirection } from './common.gql';
import { Metadata, metaDataGQL } from './metadata';
import { Tax, taxBaseGQL } from './taxes';
import { Zone, zonesBaseGql } from './zones';

export interface ListProductsOutput {
    products: ProductsPagination;
}

export interface CreateProductsOutput {
    createProducts: Product[];
}

export interface UpdateProductsOutput {
    updateProducts: boolean;
}

export interface DeleteProductsOutput {
    deleteProducts: boolean;
}

export interface PricesPagination {
    total?: number;
    skipped?: number;
    limited?: number;
    prices: Price[];
}

export interface Price {
    id: string;
    name?: string;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number;
    /**
     * Denotes whether is price is to be used when charging for the corresponding product.
     * Note only one price can be active for a product at a time, unless they are a custom user price.
     */
    active: boolean;
    // In cents
    amount: number;
    // Whether the price amount is a dollar amount or perentage
    priceType: 'percentage' | 'fixed';
    // ISO Currency format
    currency: string;
    product: Product;
    attributes?: string[];
    metadata?: Metadata;
    // The corresponding user, if applicable.
    user?: User;
    zone?: Zone;
}

export interface Product {
    id: string;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number;
    name: string;
    category?: string;
    // Denotes whether the product is available for purchase
    active: boolean;
    description?: string;
    unitLabel?: string;
    // The description used for credit card staements
    statementDescription?: string;
    prices: PricesPagination;
    attributes?: string[];
    metadata?: Metadata;
    zones: Zone[];
    taxes: Tax[]; // The taxes charged on this product
}


export interface ProductsPagination {
    total: number;
    skipped?: number;
    limited?: number;
    products: Product[];
}


export interface ProductsFilter extends ZoneDirection {
    productIds?: string[];
    names?: string[];
    hasAnyAttributes?: string[];
    hasAllAttributes?: string[];
    // Default false
    showDeleted?: boolean;
    isActive?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    taxIds_INCLUDES_ANY?: string[];
}


export interface CreateProductInput {
    name: string;
    category?: string;
    // Denotes whether the product is available for purchase
    active?: boolean;
    description?: string;
    // The description used for credit card staements
    statementDescription?: string;
    attributes?: string[];
    metadata?: Metadata;
    taxIds?: string[];
}


export interface UpdateProductInput {
    productId: string;
    name?: string;
    // Denotes whether the product is available for purchase
    active?: boolean;
    description?: string;
    // The description used for credit card staements
    statementDescription?: string;
    attributes?: string[];
    metadata?: string;
    addTaxes?: string[];
    removeTaxes?: string[];
}

export interface ProductsInput {
    filter?: ProductsFilter;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface CreateProductsInput {
    products?: CreateProductInput[];
}

export interface UpdateProductsInput {
    products?: UpdateProductInput[];
}

export interface DeleteProductsInput {
    ids?: string[];
}

export const productsBaseGQL = `
    id
    createdAt
    updatedAt
    deletedAt
    name
    category
    active
    description
    unitLabel
    statementDescription
`;

export const productsSubGQL = `
    products {
        products{
            ${productsBaseGQL}
        }
    }
`;

export const attributesGQL = `
    attributes
`;

export const productZonesGQL = `
    zones {
        ${zonesBaseGql}
    }
`;

export const pricesSubGQL = `
    prices {
        prices {
            id
            createdAt
            name
            updatedAt
            deletedAt
            active
            priceType
            amount
            currency
            zone {
                ${zonesBaseGql}
            }
        }
    }
`;

export interface GenerateProductsQueryInput {
    prices?: boolean;
    metaData?: boolean;
    attributes?: boolean;
    zone?: boolean;
    taxes?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function generateProductsQuery(input: GenerateProductsQueryInput) {
    const query = gql`
	query products($filter: ProductsFilter, $skip: Int, $limit: Int, $sort: String, ){
		products(filter: $filter, skip: $skip, limit: $limit, sort: $sort, ){
			    total
			    skipped
			    limited
			    products{ 
                    ${productsBaseGQL}
                    ${input.attributes ? attributesGQL : ``}
                    ${input.prices ? pricesSubGQL : ``}
                    ${input.metaData ? metaDataGQL : ``}
                    ${input.zone ? productZonesGQL : ``}
                    ${input.taxes ? `taxes { ${taxBaseGQL} }` : ``}
                }
	    }		
    }
    `;

    return query;
}

export const createProductsMutation = gql`
	mutation createProducts($products: [CreateProductInput!]!, ){
		createProducts(products: $products){
			    ${productsBaseGQL}
	    }		
    }
`;


export const updateProductsMutation = gql`
	mutation updateProducts($products: [UpdateProductInput!]!, ){
		updateProducts(products: $products, ) 

	}
`;


export const deleteProductsMutation = gql`
	mutation deleteProducts($ids: [String!]!, ){
		deleteProducts(ids: $ids, ) 

	}
`;

