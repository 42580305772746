import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { CreateDynamicReportGQL, DynamicReportParametersInput } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { MutateType } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-dynamic-report',
  templateUrl: './mutate-dynamic-report.component.html',
  styleUrl: './mutate-dynamic-report.component.scss'
})
export class MutateDynamicReportComponent implements OnDestroy {
  @ViewChild('mutate') mutateRef: MutateObjectComponent;
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;

  mutateType: MutateType;

  reportParameters: DynamicReportParametersInput;

  steps: MutateObjectElement[];

  subs = new SubSink();

  dynamicReportForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private notify: FreyaNotificationsService,
    private createDynamicReportGQL: CreateDynamicReportGQL,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
    }
  }

  setFormValues() {
  }

  openDialog() {
    this.steps = [
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
      { name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text'},
    ];

    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  createDynamicReport() {
    const { value } = this.dynamicReportForm;
    this.createDynamicReportGQL.mutate({
      name: value.name,
      description: value.description,
      parameters: this.reportParameters,
    }).subscribe({
      next: (res) => {
        this.mutateRef.closeDialog();
        this.dynamicReportForm.reset();
        this.notify.success('Revenue Report Created', 'Revenue Report has been created successfully');
        this.detailsHelper.pushUpdate({
          id: res.data.createDynamicReport,
          action: 'create',
          type: 'DynamicReport',
        });
      },
      error: (err) => {
        this.notify.error('Failed to create dynamic report', err.message);
        this.mutateRef.loading = false;
      },
    });
  }

}
