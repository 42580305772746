<div class="details-container">
    <div class="flex-container header-container">
        <div>
            <h6>{{locationType.label}} Details</h6>
        </div>
        <div>
            <span>{{(buildingType || '') + ' ' + (address || '')}}</span>
        </div>
    </div>
    <div class="flex-container three-columns-container">
        <div class="area-container">
            <label for="generateId('bedrooms')">
                Bedrooms (number) {{ bedroomsMandatory ? '(Required)' : '' }}
            </label>
            <p-inputNumber
                min="0"
                [tabindex]="jobCreateIndexes[locationType?.key]?.['bedrooms'] ?? null"
                [id]="generateId('bedrooms')"
                [(ngModel)]="bedroomsSelectedOption"
                [style]="{ 'width': '100%', 'marginTop': '0.5rem' }"
                (ngModelChange)="onLocationFieldTyped($event, locationType.key, 'bedrooms')"
                [required]="bedroomsMandatory"
            ></p-inputNumber>
        </div>
        <div class="area-container">
            <label for="generateId('area')">Area (Sqft)</label>
            <p-inputNumber
                [tabindex]="jobCreateIndexes[locationType?.key]?.['area'] ?? null"
                [id]="generateId('area')"
                [(ngModel)]="sqftSelectedOption"
                [style]="{ 'width': '100%', 'marginTop': '0.5rem' }"
                (ngModelChange)="onLocationFieldTyped($event, locationType.key, 'sqft')"
                min="0"
            ></p-inputNumber>
        </div>
        <div>
            <label for="generateId('floor')">{{buildingType === 'House' ? 'Storeys' : 'Floor'}}</label>
            <p-dropdown
                [tabindex]="jobCreateIndexes[locationType?.key]?.['floor'] ?? null"
                [id]="generateId('floor')"
                [(ngModel)]="stairsSelectedOption"
                optionLabel="name"
                optionValue="value"
                [options]="STAIRS_DROPDOWN_OPTIONS"
                [style]="{ 'width': '100%', 'marginTop': '0.5rem' }"
                (onChange)="onLocationFieldSelected($event, locationType.key, 'stairs')"
            ></p-dropdown>
        </div>
    </div>
    <div class="flex-container">
        <label for="generateId('notes')">Access Notes</label>
        <textarea
            [attr.tabindex]="jobCreateIndexes[locationType?.key]?.['notes'] ?? null"
            [id]="generateId('notes')"
            [rows]="5" [cols]="50"
            pInputTextarea
            [style]="{width: '100%', 'marginTop': '0.5rem'}"
            [(ngModel)]="accessNotes"
            (ngModelChange)="onLocationFieldTyped($event, locationType.key, 'accessNotes')"
            >
        </textarea>
    </div>
</div>