import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideString'
})
export class HideStringPipe implements PipeTransform {

  transform(value: string, character = '*'): string {
    if (!value) { return; }
    const length = value.length;
    return ''.padStart(value.length, character);
  }

}
