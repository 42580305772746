import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severity'
})
export class SeverityPipe implements PipeTransform {

  transform(value: string, success: string, warning: string, danger: string): string {
    if (value === success) {
      return 'success';
    } else if (value === warning){
      return 'warning';
    } else if (value === danger) {
      return 'danger';
    }
  }

}
