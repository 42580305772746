<div class="header-with-button">
    <h4><i class="pi pi-map-marker large-icon"></i> Location</h4>
    <div>
        <button
            *ngIf="locationActions[0].visible"
            pButton
            pRipple
            label="Actions"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
        ></button>
        <p-menu
            #menu
            [popup]="true"
            appendTo="body"
            [model]="locationActions"
        ></p-menu>
    </div>
</div>

<div class="card">
    <h5>{{ location.name }}</h5>
    <p *ngIf="location.addressLineOne">
        <strong>Address:</strong>
        {{ location.addressLineOne }}
        <br *ngIf="location.addressLineTwo" />
        {{ location.addressLineTwo }}
    </p>
    <p><strong>Area Code:</strong>
        {{ location | areaCode}}
    </p>
    <p><strong>City:</strong>
        {{ location.city }}
    </p>
    <p><strong>Country:</strong>
        {{ location.country }}
    </p>
    <p><strong>Jurisdiction (province, state, territory, etc):</strong>
        {{ location.countryJurisdiction }}
    </p>
</div>


<div *ngIf="location.subLocations">
    <h4>Sub locations:</h4>
    <ul>
        <li *ngFor="let sub of location.subLocations">
            <a (click)="detailsHelper.open('location', sub)">
                {{ stringifyLocation(sub) }}
            </a>

        </li>

    </ul>
</div>

<app-mutate-location
    #mutate
    [location]="location"
    mutateType="update"
></app-mutate-location>
<app-delete-object #delete
                    [name]="location.name"
                    type="Location"
                    (deleted)="deleteLocation()">
</app-delete-object>
