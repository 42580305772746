import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { BaseTokenFragment } from '../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../services/details-helper.service';
import { MutateLeadSourceComponent } from '../../shared/mutate-lead-source/mutate-lead-source.component';
import { SharedModule } from '../../shared/shared.module';


import { LeadSourceActions } from './lead-source-store/lead-source.actions';
import { LeadSourceFeature } from './lead-source-store/lead-source.reducers';

@Component({
  selector: 'app-lead-source-settings',
  standalone: true,
  imports: [ FreyaCommonModule, SharedModule ],
  templateUrl: './lead-source-settings.component.html',
  styleUrl: './lead-source-settings.component.scss'
})
export class LeadSourceSettingsComponent implements OnInit, OnDestroy {
  leadSources$ = this.store.select(LeadSourceFeature.selectLeadSources);
  loading$ = this.store.select(LeadSourceFeature.selectCallState);

  @ViewChild('mutate') mutateRef: MutateLeadSourceComponent;

  subs = new SubSink();

  constructor(
    private store: Store,
    private detailsHelper: DetailsHelperService,
  ) {

  }

  ngOnInit(): void {
    this.store.dispatch(LeadSourceActions.load());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openLeadSource(leadSource: BaseTokenFragment) {
    this.detailsHelper.open('LeadSource', leadSource);
  }

  addLeadSource() {
    this.mutateRef.token = undefined;
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }

}
