import { Pipe, PipeTransform } from '@angular/core';

import { findSort } from './utilities/watchQueryHelper';

@Pipe({
  name: 'sortDirection'
})
export class SortDirectionPipe implements PipeTransform {

  transform(
    sort: string[],
    propertyName: string,
    ascVal = '▼',
    descVal = '▲',
    noVal = '',
  ): string {
    sort = sort || [ 'createdAt:DESC' ];
    const {
      dir,
    } = findSort(sort, propertyName);
    return dir === 'ASC' ? ascVal : dir === 'DESC' ? descVal : noVal;
  }

}
