import { Directive, ElementRef, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective implements OnInit, OnDestroy {

  @Input() delay = 50;

  @Output() appDoubleClick = new EventEmitter<MouseEvent>();

  @Output() singleClick = new EventEmitter<MouseEvent>();

  // TODO: type
  timer;

  stopClick = false;

  subs = new SubSink();

  constructor(
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    // Emit click unless a double click is detected before the delay ends
    this.subs.sink = fromEvent(this.elementRef.nativeElement, 'click')
      .subscribe((event: MouseEvent) => {
        this.timer = 0;
        this.stopClick = false;

        this.timer = setTimeout(() => {
          if (this.stopClick) { return; }
          this.singleClick.emit(event);
        }, this.delay);
      });

    // Emit double click and stop single click from emitting
    this.subs.sink = fromEvent(this.elementRef.nativeElement, 'dblclick')
      .subscribe((event: MouseEvent) => {
        clearTimeout(this.timer);
        this.stopClick = true;
        this.appDoubleClick.emit(event);
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


}
