<div class="full-width">
        <div class="full-width" [formGroup]="commentForm">
            <textarea
                class="full-width"
                pInputTextarea
                [autoResize]="true"
                [rows]="3"
                [class.disabled]="!objectId || loading"
                formControlName="text"
                placeholder="Add {{nameForComment}} ..."
                [style]="{maxWidth: '372px'}"
                data-cy="add-note-textarea-field"
            ></textarea>
        </div>
        <div class="p-text-left" *ngIf="!objectId">
            <small>A job must be created before you can add notes</small>
        </div>
        <div class="p-text-right">
            <button
                pButton
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text p-m-1"
                [disabled]="commentForm.invalid"
                (mousedown)="resetForm()"
            ></button>
            <button
                pButton
                label="Add"
                [icon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"
                class="p-m-1"
                [disabled]="commentForm.invalid || loading"
                (mousedown)="addComment()"
                data-cy="add-note-button"
            ></button>
        </div>
</div>
