import { createActionGroup, props } from '@ngrx/store';
import { LocationWithHowSetIndicator, Modes } from '../../job-tool.reducer';
export const JobTimelineActions = createActionGroup({
	source: 'Job Timeline Availability',
	events: {
		'Calculate Distances': props<{ input: LocationWithHowSetIndicator }>(),
		'Calculate Distances Success': props<{
			key: string,
			response: any,
			mode: Modes,
		 }>(),
		'Calculate Distances Error': props<{ error: Error }>(),

		'Update Job Timing': props<{
			eventType: string,
			totalLocationTime?: number,
			totalTravelTime?: number,
			partialTravelTime?: number,
			mode: Modes,
		}>(),
	},
});
