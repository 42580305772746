import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

interface IndexedItem { item: string; index: number };

export interface EditData {
  items: string[];
  editType: 'add' | 'delete' | 'edit';
}

@Component({
  selector: 'app-editable-list',
  templateUrl: './editable-list.component.html',
  styleUrls: ['./editable-list.component.scss']
})
export class EditableListComponent implements OnInit, OnChanges {

  @Input() items: string[];

  @Input() editable: boolean;

  @Input() editWarning?: string;

  @Input() deleteWarning?: string;

  @Input() height = '40vh';

  @Output() itemsEdited = new EventEmitter<EditData>();

  filterValue = '';

  indexedItems: IndexedItem[];

  selectedItems: IndexedItem[] = [];

  addDialogVisible = false;

  editDialogVisible = false;

  deleteDialogVisible = false;

  actions: MenuItem[] = [];

  activeItem: IndexedItem;

  listboxRefreshing = false;

  constructor() { }

  ngOnInit(): void {
    this.setIndexedItems();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.setIndexedItems();
  }

  setIndexedItems() {
    this.indexedItems = this.items.map((item, index) => ({ item, index }));
  }

  openAddDialog() {
    this.addDialogVisible = true;
  }

  closeAddDialog() {
    this.addDialogVisible = false;
    this.refreshListbox();
  }

  openDeleteDialog() {
    this.deleteDialogVisible = true;
  }

  closeDeleteDialog() {
    this.selectedItems = [];
    this.deleteDialogVisible = false;
  }

  openEditDialogToItem(item: IndexedItem) {
    this.activeItem = { ...item };
    this.editDialogVisible = true;
  }

  closeEditDialog() {
    this.activeItem = undefined;
    this.editDialogVisible = false;
  }

  /**
   * Hacky way to clear the filter as the listbox component does not expose a method to do so programatically
   */
  refreshListbox() {
    this.listboxRefreshing = true;
    setTimeout(() => this.listboxRefreshing = false, 10);
  }

  addItem(newItem: string) {
    this.itemsEdited.next({
      items: [ newItem, ...this.items ],
      editType: 'add',
    });
  }

  editItem() {

    const editedItems: string[] = [];

    for (const item of this.indexedItems) {
      if (item.index === this.activeItem.index) {
        editedItems.push(this.activeItem.item);
      } else {
        editedItems.push(item.item);
      }
    }

    this.itemsEdited.emit({
      items: editedItems,
      editType: 'edit',
    });
  }

  deleteSelectedItems() {
    const filteredItems: string[] = [];

    // Filter out selected items
    for (const item of this.indexedItems) {

      const isSelected = this.selectedItems.some((i) => i.index === item.index);

      if (isSelected) { continue; }

      filteredItems.push(item.item);
    }

    this.itemsEdited.emit({
      items: filteredItems,
      editType: 'delete'
    });
  }

  deleteItem(item: IndexedItem) {
    const filteredItems = this.indexedItems
      .filter((i) => i.index !== item.index)
      .map((fi) => (fi.item));

    this.itemsEdited.emit({
      items: filteredItems,
      editType: 'delete',
    });
  }

  setActions(item: IndexedItem) {
    this.actions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.openEditDialogToItem(item),
      },
    ];
  }
}
