<div
    [formGroup]="rollingLockDateForm"
    >
    <ng-template #currentDate>
        <div *appVar="rollingLockDateForm.get('currentDate') as control">
            <p-calendar
                formControlName="currentDate"
                showTime="true"
                dataType="string"
                [dateFormat]="dateFormat"
                [hourFormat]="timeFormat"
                appendTo="body"
                (onSelect)="changeRollingLockDate('currentDate')"
            >
            </p-calendar>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #nextDate>
        <div *appVar="rollingLockDateForm.get('nextDate') as control">
            <p-calendar
                formControlName="nextDate"
                showTime="true"
                dataType="string"
                [dateFormat]="dateFormat"
                [hourFormat]="timeFormat"
                appendTo="body"
                (onSelect)="changeRollingLockDate('nextDate')"
            >
            </p-calendar>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #updateRule>
        <app-cron-time-form
            formControlName="updateRule"
            [(parsedCronTime)]="parsedCronTime"
            ></app-cron-time-form>
        <div>
            The lock date will change
            {{ parsedCronTime }}

        </div>
    </ng-template>
</div>
<app-mutate-object
    #mutate
    [fg]="rollingLockDateForm"
    objectType="Rolling Lock Date"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>

<ng-template #currentDateReview>
    <span>{{ rollingLockDate.currentDate | freyaDate : 'h:mm a, MMM, d, y' }}</span>
</ng-template>
<ng-template #nextDateReview>
    <span>{{ rollingLockDate.nextDate | freyaDate : 'h:mm a, MMM, d, y' }}</span>
</ng-template>
