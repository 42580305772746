// asset-search.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import MiniSearch, { SearchResult } from 'minisearch';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DispatchFeature } from './store/dispatch.reducer';
import { SubSink } from 'subsink';

export type PotentialAssetType = ReturnType<
	typeof DispatchFeature.selectPotentialAssets
>;
export type AssetType = PotentialAssetType extends (infer U)[] ? U : never;
export type AssetMiniSearchStoreFields = keyof AssetType;

@Injectable({
	providedIn: 'root',
})
export class DispatchAssetSearchService implements OnDestroy {
	private assetMiniSearch = new MiniSearch({
		fields: ['name'] as AssetMiniSearchStoreFields[],
		storeFields: [
			'name',
			'eventsCount',
			'revenue',
			'type'
		] as AssetMiniSearchStoreFields[],
		searchOptions: {
			prefix: true,
			fuzzy: true,
		},
	});

	private subs = new SubSink();

	constructor(private store: Store) {
		this.initializeMiniSearch();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	private initializeMiniSearch() {
		const potentialAssets = this.store.select(
			DispatchFeature.selectPotentialAssets,
		);
		this.subs.sink = potentialAssets.subscribe((assets: PotentialAssetType) => {
			if (assets.length === 0) {
				return;
			}
			this.assetMiniSearch.removeAll();
			this.assetMiniSearch.addAll(assets);
		});
	}

	public searchAssets(
		searchTerm$: Observable<string>,
		potentialAssets$: Observable<PotentialAssetType>,
	): Observable<PotentialAssetType | SearchResult[]> {
		return searchTerm$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((searchTerm) => {
				if (!searchTerm.trim()) {
					return potentialAssets$;
				}
				return of(this.assetMiniSearch.search(searchTerm));
			}),
		);
	}
}
