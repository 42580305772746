import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { FreyaHelperService } from '../services/freya-helper.service';
import { TimezoneHelperService } from '../services/timezone-helper.service';

@Pipe({
  name: 'freyaDate'
})
export class FreyaDatePipe implements PipeTransform {

  constructor(
    private timeZoneHelper: TimezoneHelperService,
    private datePipe: DatePipe,
  ){}

  /**
   * Freya Date Pipe is a wrapper for the angular date pipe to make working with our system easier in the following ways
   * 1) Automatically convert our unix seconds to miliseconds to match the javascript date type
   * 2) Automatically uses the currentTimezone based on the config value of the zone
   * 3) Defaults the format to our most commonly used format */
  transform(value: number | Date, format: string = 'MMM d, y', timezone = undefined): string {

    const dayJsObject = this.timeZoneHelper.getDayJsTimeZoneObject(value, timezone);

    if (!dayJsObject.isValid()) {
      return 'Date Error';
    }

    return this.datePipe.transform(dayJsObject as unknown as Date, format, dayJsObject.format('Z'));
  }

}
