import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { Dayjs } from 'dayjs';

import { ScheduleActions } from './schedule.actions';

const REVERSE_SCHEDULE_VIEW_TYPE_MAP = {
	day: 'resourceTimelineDay',
	week: 'resourceTimelineWeek',
	month: 'dayGridMonth',
} as const;

export type ScheduleView = keyof typeof REVERSE_SCHEDULE_VIEW_TYPE_MAP;
export interface ScheduleState {
	dayJsDateObject: Dayjs;
	view: ScheduleView;
};

export const scheduleInitialState: ScheduleState = {
	dayJsDateObject: undefined,
	view: 'day',
};

export const scheduleFeature = createFeature({
	name: 'schedule',
	extraSelectors({ selectDayJsDateObject }) {

		const selectFormattedDate = createSelector(
			selectDayJsDateObject,
			(dayJsObject) => dayJsObject && dayJsObject.format('YYYY-MM-DD'),
		);
		return {
			selectFormattedDate
		}
	},
	reducer: createReducer(
		scheduleInitialState,
		on(ScheduleActions.setDate, (state, { date }): ScheduleState => {
			return {
				...state,
				dayJsDateObject: date,
			}
		}),
	)
})
