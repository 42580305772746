<ng-container [ngSwitch]="contentType">
    <span *ngSwitchCase="'text'">
        {{ content }}
    </span>
    <a *ngSwitchCase="'url'" [routerLink]="url">{{ content }}</a>
    <a *ngSwitchCase="'link'"
        class="link"
        (click)="openDetails(url)"
        (appDoubleClick)="navigate(url)"
        >{{ name }}</a>
    <span *ngSwitchCase="'urlList'">
        <span *ngFor="let item of urlList; let i = index">
            <a class="link"
                (click)="openDetails(item.url)"
                (appDoubleClick)="navigate(item.url)">{{ item.before }}</a>
            <span *ngIf="item.after"> {{ item.after }}</span>
            <span *ngIf="i < urlList.length - 1">, </span>
        </span>
    </span>
</ng-container>
