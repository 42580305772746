import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { merge } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { InvoiceWithArtifactsFragment, InvoiceFilter, InvoicesGQL, InvoicesQuery, InvoicesQueryVariables } from '../../generated/graphql.generated';
import { pagination } from '../global.constants';
import { strToTitleCase } from '../js';
import { DetailsHelperService } from '../services/details-helper.service';
import { DocumentHelperService } from '../services/document-helper.service';
import { ParamTypes, QueryParamsService } from '../shared/query-params.service';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

import { InvoiceStatus } from './invoices.utils';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() layout: 'full-page' | 'compact' = 'full-page';

  @Input() syncToQueryParams = true;

  @Input() filter: InvoiceFilter = {};

  @Input() emptyMessage = 'No invoices found';

  @Input() activeColumns = {
    name: true,
    invoiceDate: true,
    dueDate: true,
    status: true,
    balance: true,
    job: true,
  };

  statuses: {label: string; option: InvoiceStatus}[] = [
    'void',
    'draft',
    'finalized',
    'deleted',
  ].map((status: InvoiceStatus) => ({ label: strToTitleCase(status), option: status }) );

  balanceStatuses: { label: string; option: 'outstanding' | 'paid' }[] = [
    'outstanding',
    'paid'
  ].map((status: 'outstanding' | 'paid') => ({ label: strToTitleCase(status), option: status }));

  invoiceSearchForm = new UntypedFormGroup({
    search: new UntypedFormControl(undefined),
    status: new UntypedFormControl(undefined),
    balanceStatus: new UntypedFormControl(undefined),
    invoiceDate: new UntypedFormControl(undefined),
    dueDate: new UntypedFormControl(undefined),
  });

  invoiceSearchFormDefaults = this.invoiceSearchForm.value;

  invoices: InvoiceWithArtifactsFragment[];

  invoicesQuery: QueryRef<InvoicesQuery, InvoicesQueryVariables>;

  invoicesQH: WatchQueryHelper = {
    skip: 0,
    limit: 10,
    loading: false,
  };

  subs = new SubSink();

  pagination = pagination;

  constructor(
    private invoicesGQL: InvoicesGQL,
    private documentsHelper: DocumentHelperService,
    private detailsHelper: DetailsHelperService,
    private queryParams: QueryParamsService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.detailsHelper.getObjectUpdates([ 'Invoice', 'Jobs' ]).subscribe((update) => {

      const invoiceJobUpdated = update.type === 'Jobs' ? this.invoices?.some((i) => i.job.id === update.id) : false;

      if (update.type === 'Invoice' || invoiceJobUpdated) {
        this.retrieveInvoices();
      }

    });

    this.watchInvoiceSearchFormValueChanges();

    if (this.syncToQueryParams) {
      this.route.queryParams.pipe(
        filter(params => 'zone' in params),
          debounceTime(10),
          take(1),
      ).subscribe((params) => {

        const { zone, ...filterParams } = params;

        this.queryParams.stripEmptyParams(filterParams);

        // Tell the queryParams service how we want to parse each param
        const paramTypes: ParamTypes = {
          dateArr: { params: [ 'invoiceDate', 'dueDate' ] },
          arr: { params: [ 'status', 'balanceStatus' ] }
        };

        const formValue = {
          ...this.invoiceSearchFormDefaults,
          ...this.queryParams.parseQueryParams(filterParams, paramTypes),
        };

        this.invoiceSearchForm.setValue(formValue, { emitEvent: false });

        this.retrieveInvoices();
      });
    }
  }

  //(onLazyLoad)="retrieveMoreInvoices($event) in p-table is being called on component init
  //as result we fetch invoices when first open page, so here we can refetch only if user changed page
  //and both current and previous filter values presented
  ngOnChanges(changes: SimpleChanges): void {
    if (this.filterValueChanged(this.filter, changes)) {
      this.retrieveInvoices();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openCreateInvoiceDialog() {
    this.documentsHelper.openCreateInvoiceDialog(this.filter?.jobId);
  }

  retrieveInvoices() {

    if (this.invoicesQuery) {
      this.invoicesQuery.refetch(this.generateVariables());
      return;
    }

    this.invoicesQuery = this.invoicesGQL.watch(this.generateVariables(), {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    this.subs.sink = this.invoicesQuery.valueChanges.subscribe((res) => {
      this.invoicesQH.loading = res.loading;

      if (res.loading) { return; }

      this.invoices = res.data.invoices.invoices;
      this.invoicesQH.total = res.data.invoices.total;
    });
  }

  generateVariables(): InvoicesQueryVariables {

    const { value: val } = this.invoiceSearchForm;

    const vars: InvoicesQueryVariables =  {
      filter: {
        ...this.filter,
        showDeleted: true,
        invoiceDate: this.queryParams.convertDatesToNumValInput(val.invoiceDate),
        dueDate: this.queryParams.convertDatesToNumValInput(val.dueDate),
        search: val.search,
      },
      skip: this.invoicesQH.skip,
      limit: this.invoicesQH.limit,
    };

    if (val.balanceStatus?.length) {
      vars.filter.balance = val.balanceStatus;
    }

    if (val.status?.length) {
      vars.filter.getInvoiceOfStatus = val.status;
    }

    return vars;
  }

  retrieveMoreInvoices(event: LazyLoadEvent) {
    this.invoicesQH.limit = event.rows;
    this.invoicesQH.skip = event.first;

    this.retrieveInvoices();
  }

  openInvoiceDetails(invoice: InvoiceWithArtifactsFragment) {
    this.detailsHelper.detailsItem.next({ type: 'invoice', item: { id: invoice.id } });
  }

  watchInvoiceSearchFormValueChanges() {

    const searchValueChanges = this.invoiceSearchForm
      .controls.search.valueChanges
      .pipe(debounceTime(750));

    const otherValueChanges = Object.keys(this.invoiceSearchForm.controls)
      .filter((key) => key !== 'search')
      .map((key) => this.invoiceSearchForm.controls[key].valueChanges.pipe(debounceTime(250)));

    const combinedValueChanges = merge(searchValueChanges, ...otherValueChanges);

    this.subs.sink = combinedValueChanges
      .subscribe(() => {
        this.updateQueryParams();

        // Reset skip and limit
        this.invoicesQH.skip = 0;
        this.invoicesQH.limit = 10;

        this.retrieveInvoices();
      });
  }

  updateQueryParams() {

    if (!this.syncToQueryParams) { return; }

    this.router.navigate([], {
      queryParams: this.formatSearchFormValues(this.invoiceSearchForm.value)
    });

  }

  formatSearchFormValues(formValues: AbstractControl['value']) {

    const customControls = {
      dateRange: { controls: [ 'invoiceDate', 'dueDate' ] },
    };

    return this.queryParams.formatSearchFormValues(formValues, customControls);
  }

  //adjust this check if use app-invoices component in different places with
  //different filters
  filterValueChanged(filter: InvoiceFilter, changes: SimpleChanges) {
    let currentValue: string, previousValue: string;

    if (filter.jobId) {
    currentValue = changes?.filter?.currentValue?.jobId;
    previousValue = changes?.filter?.previousValue?.jobId;
    }

    if (filter.userSearch) {
    currentValue = changes?.filter?.currentValue?.userSearch?.userId;
    previousValue = changes?.filter?.previousValue?.userSearch?.userId;
    }

    return (currentValue && previousValue && (currentValue !== previousValue));
  }

}
