<div class="card">
  <div class="p-grid">
    <h4 class="p-col-12 p-md-12" data-cy="jobs-card-header">History</h4>
    <div class="p-col-12 p-md-12 p-grid" [formGroup]="historyFilterForm">
      <div class="p-col-12 p-md-4">
        <label>Object Label</label>
        <br />
        <p-multiSelect
          class="full-width-multiselect"
          [options]="objectLabels"
          formControlName="objectLabel"
          ></p-multiSelect>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Object IDs</label>
        <br />
        <p-chips
          formControlName="objectIds"
          separator=","
          addOnBlur="true"
          allowDuplicate="true"
          styleClass="full-width"
          class="full-width-chips"
          ></p-chips>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Actions</label>
        <br />
        <p-chips
          formControlName="actions"
          separator=","
          addOnBlur="true"
          allowDuplicate="true"
          styleClass="full-width"
          class="full-width-chips"
          ></p-chips>
      </div>
      <div class="p-col-12 p-md-4">
        <label>User</label>
        <br />
        <app-user-search
          formControlName="user"
          [zoneId]="(brandingService.currentZone() | async)?.id"
        ></app-user-search>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <p-table
    [value]="history"
    [loading]="historyQH.loading"
    [lazy]="true"
    [showLoader]="false"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Created At</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-h>
      <tr class="history-node">
        <td
          class="datetime"
          [pTooltip]="h.createdAt * 1000 | appropriateTimeMeasurement"
          tooltipPosition="left"
        >
          <i class="pi pi-list"></i><span class="date freya-link-button" (click)="selectHitory(h.id)">{{ h.createdAt | freyaDate: 'EEE MMM dd hh:mma' }}</span>
        </td>
        <td
          class="text"
          [innerHTML]="h.text | markdown | async"
          (click)="markdownHelper.onMarkdownClick($event)"
        ></td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
        <ng-container *ngFor="let placeholder of placeholderCount">
            <tr>
                <td>
                    <p-skeleton
                        height="2rem"
                        width="100%"
                        ></p-skeleton>
                </td>
                <td>
                    <p-skeleton
                        height="2rem"
                        width="100%"
                        ></p-skeleton>
                </td>
            </tr>
        </ng-container>
    </ng-template>
  </p-table>

  <div
    *ngIf="!historyQH.loading && historyQH.hasMore"
    class="p-text-center"
    (appLazyLoad)="fetchMore()"
    >
    <span class="freya-link-button" (click)="fetchMore()">Load more</span>
  </div>
</div>

<ng-template>
	<div class="user-item p-d-flex p-jc-between" style="gap: 1rem" >
		<span style="font-weight: bold">{{user.givenName}} {{user.familyName}}</span>
		<span class="p-text-italic">{{user.email}}</span>
	</div>
</ng-template>
