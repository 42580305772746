<div class="create-user-input" [formGroup]="userForm">
  <ng-template #firstName>
    <div *appVar="userForm.get('firstName') as control">
      <input
        type="text"
        pInputText
        formControlName="firstName"
        placeholder="First Name *"
        [class.p-invalid-input]="!control.valid && control.touched"
        data-cy="first-name-text-field"
      />
      <app-validation-messages
        [control]="control"
        [requireTouched]="true"
      ></app-validation-messages>
    </div>
  </ng-template>
  <ng-template #lastName>
    <div *appVar="userForm.get('lastName') as control">
      <input
        type="text"
        pInputText
        formControlName="lastName"
        placeholder="Last Name *"
        [class.p-invalid-input]="!control.valid && control.touched"
        data-cy="last-name-text-field"
      />
      <app-validation-messages
        [control]="control"
        [requireTouched]="true"
      ></app-validation-messages>
    </div>
  </ng-template>

  <ng-template #company>
    <input
      type="text"
      pInputText
      formControlName="company"
      placeholder="Company"
    />
  </ng-template>

  <ng-template #billingAddress>
    <div *appVar="userForm.get('billingAddress') as control">
      <div class="p-inputgroup">
        <input
          *ngIf="googleHelper.loaded | async"
          pInputText
          #googleAutocomplete="ngx-places"
          ngx-gp-autocomplete
          autocomplete="off"
          placeholder=""
          formControlName="billingAddress"
          (onAddressChange)="handleAddressChange($event)"
          (input)="clearBillingAddress()"
        />
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-times"
          pTooltip="Clear"
          tooltipPosition="left"
          [disabled]="!control.value?.length"
          (click)="clearBillingAddressControl()"
        ></button>
      </div>
      <app-validation-messages [control]="control" fieldName="billingAddress">
      </app-validation-messages>
    </div>
  </ng-template>

  <ng-template #email>
    <div *appVar="userForm.get('email') as control">
      <input
        type="text"
        pInputText
        formControlName="email"
        placeholder="Email"
        [class.p-invalid-input]="!control.valid && control.touched"
        data-cy="email-text-field"
      />
      <app-validation-messages
        [control]="control"
        [requireTouched]="true"
        fieldName="Email"
      ></app-validation-messages>
    </div>
  </ng-template>

  <ng-template #phone>
    <div *appVar="userForm.get('phone') as control">
      <p-inputMask
        unmask="true"
        type="tel"
        placeholder="Phone"
        formControlName="phone"
        [class.p-invalid-input]="!control.valid && control.touched"
        mask="(999)-999-9999"
        data-cy="phone-tel-field"
      ></p-inputMask>
      <app-validation-messages
        [control]="control"
        [requireTouched]="true"
      ></app-validation-messages>
    </div>
  </ng-template>
  <ng-template #inviteOrPassword>
    <div>
      <label>Send Invite</label>
      <br />
      <div *ngIf="!(userForm.controls.email.value | trimWhitespace).length">
        <p-message
          severity="warn"
          text="Can't send an invite to a user without an email."
          styleClass="p-my-2"
        ></p-message>
      </div>
      <div class="p-field-checkbox">
        <p-checkbox
          formControlName="sendInvite"
          binary="true"
          inputId="sendInvite"
        >
        </p-checkbox>
        <label for="sendInvite"
          >Should we send an Invite to this user's email?</label
        >
      </div>
      OR
      <br />
      <br />
      <label>Password</label>
      <br />
      <div *appVar="userForm.get('password') as control">
        <input
          type="text"
          autocomplete="new-password"
          pInputText
          [controlDisabled]="userForm.value.sendInvite"
          formControlName="password"
          [class.p-invalid-input]="!control.valid && control.touched"
          data-cy="password-text-field"
        />
        <button
          pButton
          class="p-ml-1"
          (click)="copyPassword()"
          label="Copy"
        ></button>
        <app-validation-messages
          [control]="control"
          [requireTouched]="true"
        ></app-validation-messages>
      </div>
    </div>
  </ng-template>

  <ng-template #roles>
    <div class="p-grid" *appVar="userForm.get('roles') as control">
      <!-- <label>Roles</label>
            <br/> -->
      <div class="p-col">
        <p-multiSelect
          class="full-width multiselect-custom"
          [options]="zoneRoles"
          defaultLabel="Select Roles *"
          optionLabel="name"
          filterBy="search"
          data-cy="zone-role-multiselect"
          formControlName="roles"
          maxSelectedLabels="1"
          selectedItemsLabel="{0} Roles Selected"
          [class.p-invalid-input]="!control.valid && control.touched"
          [autofocusFilter]="!responsiveHelper.isSmallScreen"
          appendTo="body"
        >
          <ng-template let-role pTemplate="item">
            <div class="role-multi-select">
              <div>
                {{ role.name }}
              </div>
              <small *ngIf="role.subName">
                {{ role.subName }}
              </small>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="p-col">
        <span class="chip-container">
          <p-chip
            *ngFor="let role of control.value"
            [style]="{ 'background-color': role.color }"
            [removable]="true"
            (onRemove)="unselectRole(role)"
            [label]="role.name"
          >
          </p-chip>
        </span>
      </div>
      <app-validation-messages
        [control]="control"
        [requireTouched]="true"
      ></app-validation-messages>
    </div>
  </ng-template>
  <ng-template #sendInvite>
    <div class="p-field-checkbox">
      <p-checkbox
        formControlName="sendInvite"
        binary="true"
        inputId="sendInvite"
        data-cy="send-invite-checkbox"
      >
      </p-checkbox>
      <label for="sendInvite"
        >Should we send an Invite to this user's email?</label
      >
    </div>
    <small>Mutually Exclusie with Password</small>
  </ng-template>
  <ng-template #zones>
    <app-assign-zones
      [objectId]="user?.id"
      formControlName="zones"
    ></app-assign-zones>
  </ng-template>
</div>

<app-mutate-object
  #mutate
  [fg]="userForm"
  objectType="User"
  mutateType="update"
  [steps]="steps"
  (objectMutated)="mutateObject()"
></app-mutate-object>
