<div class="card" *ngIf="!inventoryComment">
    <div class="p-grid p-ai-center">
        <div class="p-col">
            <span class="card-title">{{titleNoToolTip || title}} <span [hidden]="!commentsQH.total">({{commentsQH.total}})</span></span>
        </div>
        <div class="p-col p-text-right">
            <span [pTooltip]="readonly && readonlyTooltip" tooltipPosition="left">
                <button
                    *ngIf="showAddButton"
                    pButton
                    icon="pi pi-plus"
                    (click)="openMutateComment('create')"
                    class="p-m-1"
                    [disabled]="readonly || !objectId"
                ></button>
            </span>
            <button
                pButton
                class="p-button-text ellipsis-button"
                [icon]="collapsed ? 'pi pi-angle-down' : 'pi pi-angle-up'"
                (click)="collapsed=!collapsed"
            ></button>
        </div>
    </div>
    <div
        class="comments scrolling collapsable"
        [class.collapsed]="collapsed"
    >
        <hr>
        <!-- COMMENTS -->
        <div
            class="comment"
            *ngFor="let comment of comments"
        >
        <!-- Small Styling, TODO: Figure out a better system for this maybe grids? -->
            <div class="p-grid" *ngIf="smallContainer">
                <div
                    class="p-col-1 p-text-center"
                    style="min-width: 36px;"
                >
                    <p-avatar
                        class="avatar-hover"
                        [label]="comment.author | userInitials"
                        (click)="viewAuthorInSidePanel(comment.author)"
                    ></p-avatar>
                </div>
                <div class="p-col">
                    <div>
                        <span
                            class="p-m-1"
                            style="font-weight: bold;"
                        >{{comment.author | userName}}</span>
                        <br>
                        <app-time-since [unixTime]="comment.createdAt"></app-time-since>
                    </div>
                </div>
                <div
                    class="p-col-1 p-text-left"
                    style="min-width: 60px;"
                    [pTooltip]="readonly && readonlyTooltip"
                    tooltipPosition="left"
                >
                    <button
                        pButton
                        type="button"
                        icon="pi pi-ellipsis-v"
                        class="p-button-icon-only p-button-text ellipsis-button"
                        [disabled]="readonly"
                        (click)="openCommentOptions(comment, editMenu, $event)"
                    ></button>
                    <p-menu
                        #editMenu
                        appendTo="body"
                        [popup]="true"
                        [model]="commentActions"
                    ></p-menu>
                </div>
                <div class="p-col-12 p-pt-0">
                    <div class="comment-text">
                        <span class="preserve-whitespace">{{comment.text}}</span>
                    </div>
                    <div *ngIf="comment.updatedAt !== comment.createdAt">
                        <small style="font-style: italic;">edited</small>
                    </div>
                </div>
            </div>
            <!-- Large Styling -->
            <div class="p-grid" *ngIf="!smallContainer">
                <div
                    class="p-col-1 p-text-center"
                    style="min-width: 30px;"
                >
                    <p-avatar
                        class="avatar-hover"
                        [label]="comment.author | userInitials"
                        (click)="viewAuthorInSidePanel(comment.author)"
                    ></p-avatar>
                </div>
                <div class="p-col-10">
                    <div>
                        <span
                            class="p-m-1"
                            style="font-weight: bold;"
                        >{{comment.author | userName}}</span>
                        <app-time-since [unixTime]="comment.createdAt"></app-time-since>
                    </div>
                    <div class="comment-text">
                        <span class="preserve-whitespace break-word">{{comment.text}}</span>
                    </div>
                    <div *ngIf="comment.updatedAt !== comment.createdAt">
                        <small style="font-style: italic;">edited</small>
                    </div>
                </div>
                <div
                    class="p-col-1 p-text-left"
                    style="min-width: 60px;"
                    [pTooltip]="readonly && readonlyTooltip"
                    tooltipPosition="left"
                >
                    <button
                        pButton
                        type="button"
                        icon="pi pi-ellipsis-v"
                        class="p-button-icon-only p-button-text ellipsis-button"
                        [disabled]="readonly"
                        (click)="openCommentOptions(comment, editMenu, $event)"
                    ></button>
                    <p-menu
                        #editMenu
                        appendTo="body"
                        [popup]="true"
                        [model]="commentActions"
                    ></p-menu>
                </div>
            </div>
        </div>
        <!-- LOADING -->
        <div *ngIf="commentsQH.loading">
            <p-skeleton
                width="100%"
                height="4rem"
            ></p-skeleton>
        </div>
        <!-- LOAD MORE -->
        <div class="p-grid">
            <br>
            <div class="p-col-12 p-text-center">
                <button
                    *ngIf="!commentsQH.loading && commentsQH.total > comments.length"
                    pButton
                    label="Load More"
                    (click)="fetchMore()"
                ></button>
            </div>
        </div>
        <p *ngIf="!commentsQH.loading && comments.length === 0">No {{nameForComment}}</p>
    </div>
</div>

<div *ngIf="inventoryComment">
    <textarea *ngIf="comments?.length"
        inputId="textarea"
        readonly
        [rows]="20"
        [value]="inventoryText"
        class="full-width"
        pInputTextarea
    ></textarea>
    <div *ngIf="!comments?.length">
        No Inventory
    </div>
</div>
