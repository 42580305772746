<ng-container *ngIf="attentionItemsService.summaries$ | async as summaries">
  <div class="p-grid statistics" *ngIf="summaryStyle === 'dashboard'">
    <div
      *ngFor="let summary of summaries.summaries"
      class="p-col-12 p-md-4"
      >
      <div class="statistic-item">
        <h5 class="item-title">
          {{ summary.items }}
        </h5>
      <h6>
        {{ summary.attentionExplanation | titlecase }}
      </h6>
      </div>
    </div>
  </div>
  <div *ngIf="summaryStyle === 'chips'">
    <p-chip
      *ngFor="let summary of summaries.summaries"
      class="selectable"
      styleClass="p-m-1"
      [routerLink]="[ '/needs-attention' ]"
      [queryParams]="{ attentionExplanation: summary.attentionExplanation }"
      >
      <div class="p-chip-text">
        {{ summary.label | titlecase }}:
        {{ summary.items }}
      </div>
    </p-chip>
  </div>
</ng-container>

<div class="card needs-attention" *ngIf="summaryStyle === 'dashboardv2'">
  <div class="dashboard-card-header">
    <h4>Needs Attention</h4>
    <button
      pButton
      type="button"
      icon="pi pi-external-link"
      [routerLink]="[ '/needs-attention' ]"
      ></button>
  </div>
  <ul class="needs-attention-list">
    <ng-container *ngIf="attentionItemsService.summaries$ | async as summaries; else loading">
      <ng-container *ngIf="!summaries.loading; else loading">
        <li *ngFor="let summary of summaries.summaries"
          class="needs-attention-item"
          [routerLink]="[ '/needs-attention' ]"
          [queryParams]="{ attentionExplanation: summary.attentionExplanation }"
          >
          <div class="item-count">
            {{ summary.items | abbreviateNumber : 0 }}
          </div>
          <div class="item-name">
            {{ summary.label | titlecase }}
          </div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #loading>
  <li *ngFor="let width of ['70%', '50%', '60%', '80%', '70%' ] " class="needs-attention-item loading">
    <div class="item-count"></div>
    <div class="item-name">
      <div class="full-width">
        <p-skeleton [width]="width" height="1.25rem"></p-skeleton>
      </div>
    </div>
  </li>
</ng-template>
