import { Pipe, PipeTransform } from '@angular/core';

import { Charge } from '../../generated/graphql.generated';
import { ChargeHelperService } from '../services/charge-helper.service';

@Pipe({
  name: 'chargeAmount'
})
export class ChargeAmountPipe implements PipeTransform {

  constructor(
    private chargeHelper: ChargeHelperService,
  ){}

  transform(charge: Charge): number {
    return this.chargeHelper.getChargeAmount(charge);
  }


}
