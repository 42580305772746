<div
  #schedulesHeaderCard
  class="card no-border-radius"
  *ngIf="showHeader"
  [formGroup]="scheduleFilters"
>
  <div class="p-grid">
    <!-- <div class="p-col-12 schedules-header">
      <h4 data-cy="schedule-card-header">Schedule</h4>
      <div>
        <button
          pButton
          [pTooltip]="
            filtersCollapsed ? 'Show All Filters' : 'Hide Additional Filters'
          "
          class="p-button-secondary p-align-center"
          [icon]="filtersCollapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
          iconPos="right"
          (click)="filtersCollapsed = !filtersCollapsed"
        ></button>
      </div>
    </div> -->

    <div class="p-col-5 p-md-4">
      <label>Date</label>
      <br />
      <p-calendar
        inputId="basic"
        [showIcon]="true"
        placeholder="Select Date"
        (onSelect)="setCalendarDate($event)"
        class="full-width-calendar"
        data-cy="date-calendar-field"
      ></p-calendar>
    </div>

    <div class="p-col-5 p-md-3">
      <app-info-circle tooltip="Shows events matching the selected types.">
        <label>Event Types </label>
      </app-info-circle>
      <br />
      <p-multiSelect
        placeholder="Select Event Types"
        class="full-width-multiselect"
        formControlName="eventTypes"
        [maxSelectedLabels]="1"
        [options]="systemEventTypes"
        [selectedItemsLabel]="'{0} Event Types'"
        (onChange)="renderAfterDelay()"
        [autofocusFilter]="!responsiveHelper.isSmallScreen"
        [appendTo]="schedulesHeaderCard"
        data-cy="event-type-multi-select-field"
      ></p-multiSelect>
    </div>

    <div class="p-col-6 p-md-3">
      <app-info-circle
        tooltip="Shows events with one or more of the selected products."
      >
        <label>Products </label>
      </app-info-circle>
      <br />
      <app-product-select
        class="full-width"
        formControlName="products"
        (productsSelected)="renderAfterDelay()"
      ></app-product-select>
    </div>
    <div
      class="p-md-2 p-text-right p-as-center p-ml-auto"
      *hasPermission="['calendarEvents.edit', 'frontend.editSchedule']"
    >
      <button
        *ngIf="!editModeEnabled"
        pButton
        label="Edit"
        icon="pi pi-pencil"
        (click)="enableEditMode()"
        data-cy="edit-button"
      ></button>
      <button
        *ngIf="editModeEnabled"
        pButton
        label="Discard"
        class="p-button-danger"
        icon="pi pi-trash"
        pTooltip="Discard all edits that have not been saved"
        tooltipPosition="bottom"
        (click)="showDiscardDialog()"
        data-cy="discard-button"
      ></button>
    </div>
  </div>
  <div class="collapsable p-grid">
    <hr />
    <div class="p-col-6 p-md-4">
      <app-info-circle
        tooltip="Show events that have an asset matching the selected types."
      >
        <label>Asset Types </label>
      </app-info-circle>
      <br />
      <p-multiSelect
        placeholder="Select Asset Types"
        class="full-width-multiselect"
        [maxSelectedLabels]="1"
        [selectedItemsLabel]="'{0} Asset Types'"
        [options]="assetTypes"
        formControlName="assetTypes"
        (onChange)="updateAssets(); renderAfterDelay()"
        [autofocusFilter]="!responsiveHelper.isSmallScreen"
        [appendTo]="schedulesHeaderCard"
        data-cy="asset-types-multi-select-field"
      ></p-multiSelect>
    </div>
    <br />
    <!-- <div class="p-col">
                    <label>
                        Supporting Events
                        <i class="pi pi-info-circle" pTooltip="Shows events with one or more of the selected products."></i>
                    </label>
                    <br>
                    <p-multiSelect
                        placeholder="Select Event Types"
                        class="full-width-multiselect"
                        [maxSelectedLabels]="1"
                        optionLabel="name"
                        [selectedItemsLabel]="'{0} Supporting Event Types'"
                        [options]="supportingEventTypes"
                        [(ngModel)]="selectedSupportingEventTypes"
                        (onChange)="renderAfterDelay()"
                    ></p-multiSelect>
                </div> -->
  </div>
</div>

<div class="card calendar-holder no-border-radius">
  <div class="p-grid calendar-header-wrapper">
    <div class="p-md-4 p-pr-0 p-mr-auto">
      <span class="button-group">
        <button
          pButton
          icon="pi pi-chevron-left"
          (click)="changeDate('prev')"
        ></button>
        <button
          pButton
          label="Today"
          (click)="changeDate('today')"
          data-cy="today-button"
        ></button>
        <button
          pButton
          icon="pi pi-chevron-right"
          (click)="changeDate('next')"
        ></button>
      </span>
      <span
        *ngIf="calendar?.view?.type === 'dayGridMonth'"
        class="switch-label-group p-ml-2"
      >
        <p-inputSwitch
          inputId="collapsedEvents"
          [(ngModel)]="showMonthEvents"
          (onChange)="setMaxEvents($event.checked)"
        ></p-inputSwitch>
        <label for="collapsedEvents">Show Events</label>
      </span>
    </div>
    <div class="p-col-12 p-md-4 fc calendar-title-wrapper">
      <H2
        class="fc-toolbar-title"
        *ngIf="calendarTitle$ | async as calendarTitle"
      >
        {{ calendarTitle }}
      </H2>
    </div>
    <div class="p-md-4 p-d-inline-flex p-jc-md-end p-ai-start">
      <button
        class="p-mr-2"
        pButton
        [disabled]="scheduleQH.loading"
        [icon]="scheduleQH.loading ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
        data-cy="reload-calendar-button"
        (click)="reloadClicked = true; refetchData()"
      ></button>
      <div class="button-group">
        <button
          pButton
          label="Day"
          (click)="changeView('resourceTimelineDay')"
          data-by="calendar-day-button"
        ></button>
        <button
          pButton
          label="Week"
          (click)="changeView('resourceTimelineWeek')"
          data-by="calendar-week-button"
        ></button>
        <button
          pButton
          label="Month"
          (click)="changeView('dayGridMonth')"
          data-by="calendar-month-button"
        ></button>
      </div>
    </div>
  </div>

  <div class="timezone-warning-container" *ngIf="jobInDifferentTimezoneWarning">
    <p-message severity="warn" [text]="jobInDifferentTimezoneWarning">
    </p-message>
  </div>

  <p-message
    *ngIf="noAvailableAssetsWarning"
    severity="warn"
    [text]="noAvailableAssetsWarning"
    class="warning"
  ></p-message>

  <div class="calendar-holder" style="position: relative">
    <!-- <span
                    *ngIf="scheduleQH.loading"
                    class="loading-calendar p-text-center"
                >
                    <i
                        class="pi pi-spin pi-spinner"
                        style="font-size: 2rem"
                    ></i>
                </span> -->
    <full-calendar #fc [options]="calendarOptions"></full-calendar>
  </div>

  <div
    class="card event-edit-bar"
    [ngClass]="{
      'event-edit-bar-safari': responsiveHelper.platform.SAFARI,
      'event-edit-bar-oldios': responsiveHelper.iOSversion <= 14,
    }"
    *ngIf="
      editModeEnabled && !discardEditsDialogVisible && !saveEditsDialogVisible
    "
  >
    <div class="p-grid">
      <div class="p-col-12" style="position: relative">
        <p>
          {{ latestEventEdits.length }} / {{ maximumEditableEvents }} Events
          Edited
        </p>
        <button
          pButton
          label="Discard"
          class="p-button-danger discard-btn"
          icon="pi pi-trash"
          [disabled]="!this.eventEdits.length"
          (click)="showDiscardDialog()"
        ></button>
      </div>
      <div class="p-col-12 p-d-flex p-jc-between">
        <button
          pButton
          label="Undo"
          icon="pi pi-undo"
          [disabled]="!this.eventEdits.length"
          (click)="undoLastEdit()"
          data-cy="undo-button"
        ></button>
        <button
          pButton
          label="Redo"
          icon="pi pi-refresh"
          iconPos="right"
          [disabled]="!this.undoneEventEdits.length"
          (click)="redoEdit()"
          data-cy="redu-button"
        ></button>
        <button
          pButton
          label="Save"
          class="p-button-success"
          icon="pi pi-save"
          [disabled]="!this.eventEdits.length"
          (click)="showSaveDialog()"
        ></button>
      </div>
    </div>
  </div>
</div>
<p-dialog
  [(visible)]="maxEventsDialogVisible"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '992px': '62vw', '510px': '93vw' }"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h5>Maximum Events Reached</h5>
  </ng-template>
  <p>
    You have reached the maximum number of editable events for your subscription
    tier.
  </p>
  <p>To edit this event, save or discard your existing changes.</p>
  <p-footer>
    <button
      pButton
      label="Close"
      icon="pi pi-times"
      (click)="maxEventsDialogVisible = false"
    ></button>
    <div class="p-col">
      <button
        pButton
        label="Discard"
        class="p-button-danger"
        icon="pi pi-trash"
        (click)="showDiscardDialog()"
      ></button>
    </div>
    <button
      pButton
      label="Save"
      icon="pi pi-save"
      (click)="showSaveDialog()"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  [(visible)]="saveEditsDialogVisible"
  [style]="{ width: '80vw' }"
  [closable]="false"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h5>Save Edits</h5>
  </ng-template>
  <div>
    <h5>
      Conflicts:
      <span *ngIf="checkingForConflicts"
        >Checking for Conflicts ... <i class="pi pi-spin pi-spinner"></i
      ></span>
      <span *ngIf="!eventsWithConflicts?.length && !checkingForConflicts"
        >None</span
      >
      <span
        class="error-text"
        *ngIf="eventsWithConflicts?.length && !checkingForConflicts"
        >{{ eventsWithConflicts?.length }} conflict(s) found</span
      >
    </h5>

    <p
      *ngIf="eventsWithConflicts?.length && !checkingForConflicts"
      class="error-text"
    >
      The highlighted events below conflict with another event. This may be
      because someone booked an event while you were editing, please review the
      schedule if this wasn't intentional.
    </p>
    <p-table [value]="latestEventEdits">
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-center" style="width: 3rem">
            Conflict
            <br />
            <small class="header-description"></small>
          </th>
          <th>
            Event
            <br />
            <small class="header-description"
              >Times do not include travel</small
            >
          </th>
          <th>
            Notify Attendees
            <br />
            <p-inputSwitch
              class="header-description"
              [(ngModel)]="notifyMasterToggle"
              (onChange)="toggleAllNotifyAttendees($event)"
            ></p-inputSwitch>
          </th>
          <th>
            Actions
            <br />
            <small class="header-description"></small>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-edit>
        <tr
          [class.conflict-row]="edit.hasConflict"
          [class.saved-row]="edit.status === 'Saved'"
          class="compressed-row"
        >
          <td class="p-text-center" style="width: 3rem">
            <i
              *ngIf="!edit.hasConflict"
              class="pi pi-calendar conflict-icon"
              pTooltip="No Conflict"
              tooltipPosition="bottom"
            ></i>
            <i
              *ngIf="edit.hasConflict"
              class="pi pi-calendar-times conflict-icon"
              pTooltip="Has Conflict"
              tooltipPosition="bottom"
            ></i>
          </td>
          <td>
            <h5 class="no-margin">
              {{ edit.event.title | titlecase }}
              <span style="font-weight: normal">for</span>
              {{ edit.event?._def.extendedProps?.event | eventCustomer }}
            </h5>
            <br />
            <div class="p-grid compressed-grid">
              <!-- BEFORE ROW -->
              <div class="p-col-2">
                <b>Before:</b>
              </div>
              <div class="p-col-3">
                <span
                  *ngFor="
                    let asset of edit.event?._def.extendedProps?.event?.assets
                  "
                  >{{ asset.name }}</span
                >
              </div>
              <div class="p-col-3">
                <span>
                  {{
                    edit.event?._def.extendedProps?.event.start
                      | freyaDate: 'E, MMM, d'
                  }}
                </span>
              </div>
              <div class="p-col-4">
                <span>
                  {{ edit.event?._def.extendedProps?.event | eventDuration }}
                </span>
              </div>
              <!-- CHANGES ROW -->
              <div class="p-col-2">
                <i class="pi pi-arrow-down bold"></i>
              </div>
              <div class="p-col-3">
                <i
                  class="pi pi-arrow-down bold"
                  *ngIf="
                    edit.event?._def.extendedProps?.event?.assets[0]?.name !==
                    edit.event.getResources()[0]?.extendedProps?.name
                  "
                ></i>
              </div>
              <div class="p-col-3">
                <i
                  class="pi pi-arrow-down bold"
                  *ngIf="
                    (edit.event?._def.extendedProps?.event.start
                      | freyaDate: 'E, MMM, d') !==
                    (edit.event.start | freyaDate: 'E, MMM, d')
                  "
                >
                </i>
              </div>
              <div class="p-col-4">
                <i
                  class="pi pi-arrow-down bold"
                  *ngIf="
                    (edit.event?._def.extendedProps?.event | eventDuration) !==
                      edit.event?._def.extendedProps?.event
                      | eventDurationFromCalendar
                        : edit.event.start
                        : edit.event.end
                  "
                >
                </i>
              </div>
              <!-- AFTER ROW -->
              <div class="p-col-2">
                <b>After:</b>
              </div>
              <div class="p-col-3">
                <span *ngFor="let asset of edit.event.getResources()"
                  >{{ asset?.extendedProps?.name }}
                </span>
              </div>
              <div class="p-col-3">
                <span>
                  {{ edit.event.start | freyaDate: 'E, MMM, d' }}
                </span>
              </div>
              <div class="p-col-4">
                <span>
                  {{
                    edit.event?._def.extendedProps?.event
                      | eventDurationFromCalendar
                        : edit.event.start
                        : edit.event.end
                  }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <p-inputSwitch
              [(ngModel)]="edit.notifyAttendees"
              (onChange)="checkNotifyAttendees()"
              [disabled]="
                edit.event?._def.extendedProps?.event?.status === 'pending'
              "
              pTooltip="Events of status 'Pending' do not send notifications"
              tooltipDisabled
            ></p-inputSwitch>
          </td>
          <td>
            <div *ngIf="edit.status === 'Pending' || edit.status === 'Failed'">
              <button
                pButton
                class="p-button-success p-m-1"
                icon="pi pi-save"
                pTooltip="Save changes for this event"
                (click)="saveEdit(edit)"
              ></button>
              <button
                pButton
                class="p-button-danger p-m-1"
                icon="pi pi-trash"
                pTooltip="Discard changes for this event"
                (click)="discardEdit(edit)"
              ></button>
            </div>
            <div *ngIf="edit.status !== 'Pending'">
              <p-chip
                [label]="edit.status"
                [styleClass]="
                  edit.status
                    | matchClass
                      : ['Pending', 'Saved', 'Failed']
                      : ['', 'success-bg', 'error-bg']
                "
              ></p-chip>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [attr.colspan]="'3'"></td>
          <td>
            <button
              pButton
              label="Save All"
              [disabled]="
                scheduleSaving || scheduleSaved || !latestEventEdits?.length
              "
              class="p-button-success"
              icon="pi pi-save"
              (click)="tryToSave()"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <br />
    <p class="p-text-left">
      All saved events will be removed from the edit list when this window is
      closed.
    </p>
  </div>
  <p-footer>
    <button
      pButton
      label="Back to Editing"
      icon="pi pi-times"
      (click)="backToEditing()"
    ></button>
    <button
      pButton
      label="Finish Editing"
      icon="pi pi-check"
      (click)="showDiscardDialog(); refetchData()"
    ></button>
  </p-footer>
  <p
    *ngIf="eventsWithConflicts?.length && !checkingForConflicts"
    class="error-text"
  >
    The highlighted events below conflict with another event. This may be
    because someone booked an event while you were editing, please review the
    schedule if this wasn't intentional.
  </p>
  <p-table [value]="latestEventEdits">
    <ng-template pTemplate="header">
      <tr>
        <th class="p-text-center" style="width: 3rem">
          Conflict
          <br />
          <small class="header-description"></small>
        </th>
        <th>
          Event
          <br />
          <small class="header-description">Times do not include travel</small>
        </th>
        <th>
          Notify Attendees
          <br />
          <p-inputSwitch
            class="header-description"
            [(ngModel)]="notifyMasterToggle"
            (onChange)="toggleAllNotifyAttendees($event)"
          ></p-inputSwitch>
        </th>
        <th>
          Actions
          <br />
          <small class="header-description"></small>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-edit>
      <tr
        [class.conflict-row]="edit.hasConflict"
        [class.saved-row]="edit.status === 'Saved'"
        class="compressed-row"
      >
        <td class="p-text-center" style="width: 3rem">
          <i
            *ngIf="!edit.hasConflict"
            class="pi pi-calendar conflict-icon"
            pTooltip="No Conflict"
            tooltipPosition="bottom"
          ></i>
          <i
            *ngIf="edit.hasConflict"
            class="pi pi-calendar-times conflict-icon"
            pTooltip="Has Conflict"
            tooltipPosition="bottom"
          ></i>
        </td>
        <td>
          <h5 class="no-margin">
            {{ edit.event.title | titlecase }}
            <span style="font-weight: normal">for</span>
            {{ edit.event?._def.extendedProps?.event | eventCustomer }}
          </h5>
          <br />
          <div class="p-grid compressed-grid">
            <!-- BEFORE ROW -->
            <div class="p-col-2">
              <b>Before:</b>
            </div>
            <div class="p-col-3">
              <span
                *ngFor="
                  let asset of edit.event?._def.extendedProps?.event?.assets
                "
                >{{ asset.name }}</span
              >
            </div>
            <div class="p-col-3">
              <span>
                {{
                  edit.event?._def.extendedProps?.event.start
                    | freyaDate: 'E, MMM, d' : timezone
                }}
              </span>
            </div>
            <div class="p-col-4">
              <span>
                {{
                  edit.event?._def.extendedProps?.event
                    | eventDuration: false : false : true : timezone
                }}
              </span>
            </div>
            <!-- CHANGES ROW -->
            <div class="p-col-2">
              <i class="pi pi-arrow-down bold"></i>
            </div>
            <div class="p-col-3">
              <i
                class="pi pi-arrow-down bold"
                *ngIf="
                  edit.event?._def.extendedProps?.event?.assets[0]?.name !==
                  edit.event.getResources()[0]?.extendedProps?.name
                "
              ></i>
            </div>
            <div class="p-col-3">
              <i
                class="pi pi-arrow-down bold"
                *ngIf="
                  (edit.event?._def.extendedProps?.event.start
                    | freyaDate: 'E, MMM, d' : timezone) !==
                  (edit.event.start | freyaDate: 'E, MMM, d' : timezone)
                "
              >
              </i>
            </div>
            <div class="p-col-4">
              <i
                class="pi pi-arrow-down bold"
                *ngIf="
                  (edit.event?._def.extendedProps?.event
                    | eventDuration: false : false : true : timezone) !==
                    edit.event?._def.extendedProps?.event
                    | eventDurationFromCalendar
                      : edit.event.start
                      : edit.event.end
                "
              >
              </i>
            </div>
            <!-- AFTER ROW -->
            <div class="p-col-2">
              <b>After:</b>
            </div>
            <div class="p-col-3">
              <span *ngFor="let asset of edit.event.getResources()"
                >{{ asset?.extendedProps?.name }}
              </span>
            </div>
            <div class="p-col-3">
              <span>
                {{ edit.event.start | freyaDate: 'E, MMM, d' : timezone }}
              </span>
            </div>
            <div class="p-col-4">
              <span>
                {{
                  edit.event?._def.extendedProps?.event
                    | eventDurationFromCalendar
                      : edit.event.start
                      : edit.event.end
                }}
              </span>
            </div>
          </div>
        </td>
        <td>
          <p-inputSwitch
            [(ngModel)]="edit.notifyAttendees"
            (onChange)="checkNotifyAttendees()"
            [disabled]="
              edit.event?._def.extendedProps?.event?.status === 'pending'
            "
            pTooltip="Events of status 'Pending' do not send notifications"
            tooltipDisabled
          ></p-inputSwitch>
        </td>
        <td>
          <div *ngIf="edit.status === 'Pending' || edit.status === 'Failed'">
            <button
              pButton
              class="p-button-success p-m-1"
              icon="pi pi-save"
              pTooltip="Save changes for this event"
              (click)="saveEdit(edit)"
            ></button>
            <button
              pButton
              class="p-button-danger p-m-1"
              icon="pi pi-trash"
              pTooltip="Discard changes for this event"
              (click)="discardEdit(edit)"
            ></button>
          </div>
          <div *ngIf="edit.status !== 'Pending'">
            <p-chip
              [label]="edit.status"
              [styleClass]="
                edit.status
                  | matchClass
                    : ['Pending', 'Saved', 'Failed']
                    : ['', 'success-bg', 'error-bg']
              "
            ></p-chip>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td [attr.colspan]="'3'"></td>
        <td>
          <button
            pButton
            label="Save All"
            [disabled]="
              scheduleSaving || scheduleSaved || !latestEventEdits?.length
            "
            class="p-button-success"
            icon="pi pi-save"
            (click)="tryToSave()"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <br />
  <p class="p-text-left">
    All saved events will be removed from the edit list when this window is
    closed.
  </p>
  <p-footer>
    <button
      pButton
      label="Back to Editing"
      icon="pi pi-times"
      (click)="backToEditing()"
    ></button>
    <button
      pButton
      label="Finish Editing"
      icon="pi pi-check"
      (click)="showDiscardDialog(); refetchData()"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  [(visible)]="discardEditsDialogVisible"
  [style]="{ width: '50vw' }"
  [breakpoints]="{ '992px': '62vw', '510px': '93vw' }"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h5>Discard Edits</h5>
  </ng-template>
  <p>Are you sure you want to discard these changes?</p>
  <p-footer>
    <button
      pButton
      label="Cancel"
      class="p-button-text"
      icon="pi pi-times"
      (click)="discardEditsDialogVisible = false"
    ></button>
    <button
      pButton
      label="Discard"
      class="p-button-danger"
      icon="pi pi-save"
      (click)="discardEdits()"
    ></button>
  </p-footer>
</p-dialog>
