/**
 * Converts a camel case property to a nice readable text
 *
 * @param value The property name
 * @param addSemiColon If true adds a semi colon
 * @returns String
 */

import { capitalize } from 'lodash';

interface GenerateMailtoLinkInput {
  to: string;
  subject: string;
  body: string;
}

export function prettyProperty(value: string, addSemiColon = false): string{
	const words = value.split(/([A-Z])/g);
    if (words) {
      let returnValue = '';
      for (const word of words) {
        if (word.length === 1){
          returnValue += `${word}`;
        } else {
          returnValue += `${word} `;
        }
      }
      if (addSemiColon) {
        returnValue = returnValue.trimEnd() + ':';
      };
      return capitalize(returnValue);
    } else {
      if (addSemiColon) {
        value = value.trimEnd() + ':';
      };
      return capitalize(value);
    }
}

/**
 * If a string can be split into a name and a url,
 * e.g. 'Arturo Javier <http://localhost:4200/user/6fbf16b0-e1e4-11ec-9bb6-4d1b94ec5fb8/overview>',
 * this returns an array of the form [ name, url ]
 *
 * @param str A string possibly containing a name and a url
 * @returns An array of the form [ name, url ] if the provided string can be so divided, otherwise an empty array
 *
 */
export function parseEmbeddedUrl(str: string) {

  const parts = extractParts(str);

  if (!parts) { return null; }

  if (!isValidUrl(parts.inside)) { return null; }

  return {
    before: parts.before.trim(),
    url: new URL(parts.inside).pathname,
    after: parts.after.trim(),
  };
}

function extractParts(input) {
    const regex = /^(.*?)<([^>]*)>(.*)$/;
    const match = regex.exec(input);

    if (match) {
        return {
            before: match[1],  // Everything before the first '<'
            inside: match[2],  // The content inside the first '<' and '>'
            after: match[3]    // Everything after the first '>'
        };
    } else {
        return null; // No '<...>' pattern found
    }
}

export function isValidUrl(str: string) {
  let url: URL;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function generateMailtoLink(input: GenerateMailtoLinkInput) {

  const link = new URL('mailto:' + input.to);

  const paramNames: (keyof GenerateMailtoLinkInput)[] = [
    'subject',
    'body',
  ];

  for (const paramName of paramNames) {
    link.searchParams.set(paramName, input[paramName]);
  }

  return link.toString();
}
