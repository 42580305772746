import { Pipe, PipeTransform } from '@angular/core';
import {dayjs} from '@karve.it/core';

@Pipe({
  name: 'secondsToDuration'
})
export class SecondsToDurationPipe implements PipeTransform {

  transform(seconds: number, emptyDuration = '0 seconds'): string {
    if (!seconds) {
      return emptyDuration;
    }

    const val = dayjs.duration(seconds, 'seconds');
    // Hummanize is causing some weird rounding
    return val.humanize();
  }

}
