import { Pipe, PipeTransform } from '@angular/core';

import { Charge } from '../../generated/graphql.generated';
import { ChargeHelperService } from '../services/charge-helper.service';

@Pipe({
  name: 'charge'
})
export class ChargePipe implements PipeTransform {

  constructor(
    private chargeHelper: ChargeHelperService,
  ){}

  transform(charge: Charge): string {
    const name = charge.product?.name || charge.productName;
    return `${charge.quantity} x ${name}: $${this.chargeHelper.getChargeAmount(charge)}`;
  }
}
