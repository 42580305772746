import { Component, Input, OnChanges } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Report, ReportAggregationResult } from '../../../generated/graphql.generated';

import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-aggregation-value',
  templateUrl: './report-aggregation-value.component.html',
  styleUrls: ['./report-aggregation-value.component.scss']
})
export class ReportAggregationValueComponent implements OnChanges {

  @Input() report: Report;
  @Input() ag: ReportAggregationResult;
  agFormat: string;

  currency = environment.defaultCurrency;

  constructor(
    private reportService: ReportService,
  ) { }

  ngOnChanges(): void {
    if (this.ag) {
      this.determineFormat();
    } else {
      this.agFormat = 'value';
    }
  }

  determineFormat() {
    this.agFormat = this.reportService.determineAgFormat(this.ag);
    const variables = JSON.parse(this.report?.variables || '{}');
    // TODO: determine currency properly
    this.currency = variables.currency || 'USD';
  }
}
