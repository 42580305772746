import { Pipe, PipeTransform } from '@angular/core';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';

import { FreyaHelperService } from '../services/freya-helper.service';

import { FreyaDatePipe } from './freya-date.pipe';

@Pipe({
  name: 'eventDuration'
})
export class EventDurationPipe implements PipeTransform {

  constructor(
    private freyaHelper: FreyaHelperService,
    private freyaDate: FreyaDatePipe,
  ){}

  transform(event: CalendarEvent, includeTravelTime = false, includeDate = false, includeTime = true, timezone = undefined): string {
    let start: number;
    let end: number;

    if(includeTravelTime) {
      start = event.start;
      end = event.end;
    } else {
      ({eventStart: start, eventEnd: end} = this.freyaHelper.getCalendarEventStartandEnd(event));
    }

    if (!event?.start){
      return 'Not Scheduled';
    }

    if(includeDate) {
      const startDate = this.freyaDate.transform(start, 'MMM d', timezone);
      const endDate = this.freyaDate.transform(end, 'MMM d', timezone);

      if (includeTime) {
        return (startDate === endDate) ?
          `${startDate}, ${this.freyaDate.transform(start, 'h:mm a', timezone)}
            - ${this.freyaDate.transform(end, 'h:mm a', timezone)}`
          :`${startDate}, ${this.freyaDate.transform(start, 'h:mm a', timezone)}
            - ${endDate}, ${this.freyaDate.transform(end, 'h:mm a', timezone)}`;
      } else {
        return (startDate === endDate) ?
          startDate
          :`${startDate} - ${endDate}`;
      }

    }
    return `${this.freyaDate.transform(start, 'h:mm a', timezone)} - ${this.freyaDate.transform(end, 'h:mm a', timezone)}`;
  }
}
