import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appVar]'
})
export class VarDirective<T> {

  @Input()
  set appVar(context: T) {
      this.context.$implicit = this.context.appVar = context;

      if (!this.hasView) {
          this.vcRef.createEmbeddedView(this.templateRef, this.context);
          this.hasView = true;
      }
  }

  private context: {
      $implicit: T;
      appVar: T;
  } = {
      $implicit: null,
      appVar: null,
  };

  private hasView = false;

  constructor(
      private templateRef: TemplateRef<{ $implicit: T; appVar: T }>,
      private vcRef: ViewContainerRef,
  ) {}
}
