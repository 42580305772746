import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms';


export function uniqueExpenseValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const expensesFormArray = control as UntypedFormArray;
      const expenseIds = new Set<string>();
  
      for (const control of expensesFormArray.controls) {
        const expenseId = control.get('expenseId').value;
        if (expenseIds.has(expenseId)) {
          return { 'duplicateExpense': { value: control.value } }; //TODO: Display which ones are duplicates
        }
        expenseIds.add(expenseId);
      }
  
      return null;
    };
  }