import { Pipe, PipeTransform } from '@angular/core';
import {dayjs} from '@karve.it/core';

import { FullNotificationFragment } from '../../generated/graphql.generated';

import { FreyaDatePipe } from './freya-date.pipe';

@Pipe({
  name: 'notificationDate'
})
export class NotificationDatePipe implements PipeTransform {
  constructor(
    private freyaDate: FreyaDatePipe,
  ) {}

  transform(notification: Pick<FullNotificationFragment, 'createdAt'>): string {

    if (!notification?.createdAt?.unix) {
      console.warn('Could not parse notification date', notification);
      return;
    }

    const unixTimeStamp = +notification.createdAt.unix;

    const isToday = dayjs().isSame(dayjs.unix(unixTimeStamp), 'day');

    const format = isToday ? 'hh:mm' : 'd MMM';

    return this.freyaDate.transform(unixTimeStamp, format);
  }

}
