import { Pipe, PipeTransform } from '@angular/core';

import { AttentionItem } from '../../generated/graphql.generated';
import { ATTENTION_ACTION_NAMES } from '../global.constants';
import { strToTitleCase } from '../js';


@Pipe({
  name: 'attentionItemAction'
})
export class AttentionItemActionPipe implements PipeTransform {

  transform(item: AttentionItem): string {

    if (item.action === 'book-event' && item.__typename === 'Job') {
      const uncompletedEvents = item.events
        .filter((e) => e.status !== 'completed')
        .sort((a, b) => a.start - b.start);

      const [ first ] = uncompletedEvents;

      const eventType = strToTitleCase(first?.type);

      return `Book ${eventType}`;
    }

    if (ATTENTION_ACTION_NAMES[item.action]) {
      return ATTENTION_ACTION_NAMES[item.action];
    } else {
      return item.action;
    }
  }

}
