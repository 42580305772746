import { Pipe, PipeTransform } from '@angular/core';

import { CalendarEventLocation } from '../../generated/graphql.generated';

import { locationNameMap } from '../global.constants';



@Pipe({
  name: 'eventLocationName'
})
//  Return event location name as 'Dock' instread basestart/baseend
export class EventLocationNamePipe implements PipeTransform {
  locationNameMap = locationNameMap;
  transform(calendarEventLocation: CalendarEventLocation): string {
    return locationNameMap[calendarEventLocation.type];
  }

}
