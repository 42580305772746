<p-table
  #rulesTable
  [value]="rules"
  [lazy]="true"
  (onLazyLoad)="loadRules($event)"
  dataKey="id"
  [paginator]="true"
  [(first)]="skip"
  [rows]="limit"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [globalFilterFields]="['form', 'property', 'id']"
  breakpoint="620px"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Given</th>
      <th>When</th>
      <th>Then</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rule>
    <tr class="selectable-row" (click)="selectRule(rule)">
      <td>
        <span class="p-column-title">Given:</span>
        {{ rule.triggerDescription }}
      </td>
      <td class="when-cell">
        <span class="p-column-title">When:</span>
        <span class="value">
          {{ describeCondition(rule.condition) }}
        </span>
      </td>
      <td>
        <span class="p-column-title">Then:</span>
        <span class="value">
          {{ rule.actionDescription }}
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<app-mutate-rule #mutate></app-mutate-rule>
