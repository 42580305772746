import { Platform } from '@angular/cdk/platform';
import { Injectable, OnDestroy } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { MD_BREAKPOINT, SM_BREAKPOINT } from '../global.constants';
import { NotSupportedComponent } from '../shared/not-supported/not-supported.component';

const NOT_SUPPORTED_LOCAL_STORAGE_KEY = 'not-supported-warning';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveHelperService implements OnDestroy {

  subs = new SubSink();

  dialogWidth: string;

  keyboardOpen = false;

  // How long to press on an element before the element becomes draggable
  dragDelay = 100;

  iOSminVersion = 14;
  chromeMinVersion = 98;

  constructor(
    public platform: Platform,
    private dialogService: DialogService
  ) {
    this.checkSupported();
    this.watchKeyboard();
    this.watchWindow();

    window.addEventListener('resize', this.interceptResizeEvents);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    window.removeEventListener('resize', this.interceptResizeEvents);
  }

  get isLargeScreen() {
    return window.innerWidth >= MD_BREAKPOINT;
  }

  get isMediumScreen() {
    return window.innerWidth < MD_BREAKPOINT && window.innerWidth > SM_BREAKPOINT;
  }

  get isSmallScreen() {
    return window.innerWidth <= SM_BREAKPOINT;
  }

  /**
   * Listens for the sort of focus and blur events that trigger the keyboard to open or close
   * and updates `this.keyboardOpen` accordingly.
   */
  watchKeyboard() {
    const inputFocused = fromEvent(document, 'focusin')
      .pipe(filter((event) => (this.elementTriggersKeyboard(event.target as HTMLElement))));

    const inputBlurred = fromEvent(document, 'focusout')
      .pipe(filter((event) => (this.elementTriggersKeyboard(event.target as HTMLElement))));

    this.subs.sink = merge(
      inputFocused.pipe(map(() => this.isSmallScreen)),
      inputBlurred.pipe(map(() => false))
    ).pipe(debounceTime(50), distinctUntilChanged())
    .subscribe((open) => this.keyboardOpen = open);
  }

  /**
   * Checks if an HTML element will trigger the keyboard on focus,
   * e.g. will return `true` for text inputs, false for checkboxes.
   *
   * @param element The element we want to check if it will trigger the keyboard.
   */
  elementTriggersKeyboard(element: HTMLElement) {
    const elementType = element.getAttribute('type');
    const elementRole = element.getAttribute('role');

    return ( element.tagName === 'INPUT' || element.tagName === 'TEXTAREA' )
      && elementType !== 'checkbox'
      && elementRole !== 'combobox';
  }

  /**
   * Watches for changes in the size of the window and updates any relevant properties
   * such as the width of the dialogs;
   */
  watchWindow() {
    this.subs.sink = fromEvent(window, 'resize')
      .pipe(
        map((event: Event) => event.target),
        startWith(window),
        debounceTime(150)
      )
      .subscribe((window: Window & typeof globalThis) => {
        this.dialogWidth = this.getDialogWidth(window.innerWidth);
      });
  }

  getDialogWidth(windowWidth: number) {
    if (windowWidth >= MD_BREAKPOINT) {
        return '50vw';
    }
    if (windowWidth > SM_BREAKPOINT) {
        return '62vw';
    }
    return '93vw';
  }

  checkSupported() {
    if (this.platform.IOS && (this.iOSversion < this.iOSminVersion)) {
      this.safeShowWarning('This application is not optimized for your OS');
    } else if (this.platform.BLINK && (this.chromeVersion < this.chromeMinVersion)) {
      alert('This application is not optimized for your browser. Please update to the latest version');
    }
  }

  safeShowWarning(header: string, message?: string) {
    const warningShown = localStorage.getItem(NOT_SUPPORTED_LOCAL_STORAGE_KEY) === 'shown';

    if (warningShown) { return; }

    const ref = this.dialogService.open(NotSupportedComponent, {
      header,
      width: this.getDialogWidth(window.innerWidth),
      contentStyle: {
        'border-radius': '0 0 6px 6px',
      }
    });

    this.subs.sink = ref.onClose.subscribe(() => {
      localStorage.setItem(NOT_SUPPORTED_LOCAL_STORAGE_KEY, 'shown');
    });
  }

  get iOSversion(): number {
    if (!this.platform.IOS) { return; }
    const [ _blank, version ] = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return Number(version);
  }

  get chromeVersion(): number {
    const userAgent = navigator.userAgent;
    const match = userAgent.match(/Chrom(e|ium)\/(\d+)\./);
    return match ? parseInt(match[2], 10) : 0;
  }

  /**
   * By default, PrimeNG autcomplete dropdown hides on resize events
   * When you set the focus on the autocomplete input on mobile, this opens the virtual keyboard
   * On Android browsers, this triggers a resize event, which causes the dropdown to immediately close
   * This methods disables the default PrimeNG autocomplete behavior by intercepting resize events
   * when the autocomplete input has focus
   */
  interceptResizeEvents(event: UIEvent) {
    if (document.activeElement.classList.contains('p-autocomplete-input') && window.innerWidth <= SM_BREAKPOINT) {
      event.stopImmediatePropagation();
    }
  }
}
