<div
    [formGroup]="assetForm"
    class="edit-asset-input p-grid p-flex"
>
<ng-template #type>
    <div
    *appVar="assetForm.get('type') as control"
    class="p-col-6 p-md-6"
>
    <label>Type</label>
    <br />
    <p-dropdown
        class="full-width-dropdown"
        [options]="assetTypes"
        formControlName="type"
        appendTo="body"
        optionLabel="label"
        optionValue="value"
        data-cy="type-drop-down-field"
    ></p-dropdown>
    <app-validation-messages
        [control]="control"
        [requireTouched]="false"
    ></app-validation-messages>
</div>
<div class="p-col-6" *ngIf="yemboHelper.yemboEnabled && assetForm.value.type === 'Estimator'">
    <label>Yembo Email</label>
    <br />
    <input
    type="text"
    pInputText
    formControlName="yemboEmail"
    style="width: 100%;"
    >
    <small>This email will be used to properly set the employee for a smart consult/virtual estimate.</small>
</div>
</ng-template>
<ng-template #name>
    <div
    *appVar="assetForm.get('name') as control"
    class="p-col-12 p-md-6"
>
    <label>Name</label>
    <br />
    <input
        type="text"
        pInputText
        formControlName="name"
        placeholder="Asset Name *"
        style="width: 100%;"
        [class.p-invalid-input]="!control.valid && control.touched"
        data-cy="name-text-field"
    >
    <app-validation-messages [control]="control"></app-validation-messages>
</div>
</ng-template>
<ng-template #location>
    <div 
    *appVar="assetForm.get('location') as control"
    class="p-col-12 p-md-6">
        <input
            class="location-input"
            *ngIf="googleHelper.loaded | async"
            [options]="options"
            pInputText
            appendTo="body"
            formControlName="location"
            ngx-google-places-autocomplete
            (onAddressChange)="handleAddressChange($event)"
            data-cy="location-text-field"
        />
    </div>
    <small>Must be set with autocomplete</small>
</ng-template>
<ng-template #zones>
    <div 
    *appVar="assetForm.get('zones') as control"
    class="p-col-12">
        <app-assign-zones #zonesComponent formControlName="zones"></app-assign-zones>
    </div>
</ng-template>
<ng-template #attributes>
    <div
    *appVar="assetForm.get('attributes') as control"
    class="p-col-12"
>
    <label>Attributes (comma seperated)</label>
    <br />
    <p-chips
        formControlName="attributes"
        separator=","
        addOnBlur="addOnBlur"
        [allowDuplicate]="false"
    ></p-chips>
    <app-validation-messages [control]="control"></app-validation-messages>
</div>
</ng-template>
<ng-template #description>
    <div
    *appVar="assetForm.get('description') as control"
    class="p-col-12"
>
    <label>Description</label>
    <br />
    <textarea
        pInputTextarea
        formControlName="description"
        placeholder="Asset Description"
        style="width: 100%; resize: vertical;"
        rows="4"
        cols="20"
        data-cy="description-text-field"
    ></textarea>
    <app-validation-messages [control]="control"></app-validation-messages>

</div>
</ng-template>

</div>

<app-mutate-object 
    #mutate
    [fg]="assetForm"
    objectType="Asset"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>