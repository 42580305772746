<div class="p-grid" *ngIf="events?.length || job?.transactions?.length">
    <div class="p-col-12 p-pb-0">
        <span class="error-text">{{ warningText }}</span>
    </div>
    <div *ngIf="events?.length" class="p-col-6">
        <ul>
            <li *ngFor="let event of events">
                {{ event.title | rename : 'osestimate' : 'estimate' : false |  titlecase }}
                -
                {{ event?.start ? (event.start | freyaDate : 'MMM dd, YYYY hh:mm') : 'Unscheduled' }}
            </li>
        </ul>
    </div>
    <div *ngIf="job?.transactions?.length" class="p-col-6">
        <ul>
            <li *ngFor="let transaction of job?.transactions">
                {{transaction.amount / 100 | currency : transaction.currency }}
                -
                {{ transaction.type | titlecase }}
                ({{ transaction.paymentType.name }})
            </li>
        </ul>
    </div>
</div>
<div class="p-pb-2">
    No notification will be sent out for this change.
</div>

<div class="footer">
    <button
        pButton
        label="No, Exit Window"
        icon="pi pi-times"
        (click)="closeDialog(false)"
        data-cy="exit-window-action-button"
    ></button>
    <button
        class="p-button-danger"
        pButton
        label="Yes, Delete Job"
        icon="pi pi-trash"
        (click)="deleteJob()"
    ></button>
</div>
