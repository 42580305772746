import { Injectable } from '@angular/core';

import { urlToUrlRewrite } from '../utilities/markdown';

import { DetailsHelperService } from './details-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MarkdownHelperService {

  constructor(
    private details: DetailsHelperService,
  ) { }

  /**
   * Call when a piece of markdown is clicked.
   * If an anchor tag is clicked, and the tag directs
   * to an element with a side dialog, then this
   * will open the side dialog instead of navigating
   * to that URL. If the user middle clicks (to open in a new tab)
   * then it will go to that corresponding URL.
   *
   * If no link is clicked, nothing gets overriden.
   *
   * EG: if the url is localhost/user/a-b-c-d then, instead of navigating to
   * that URL, the side panel for the user with id a-b-c-d will be opened instead.
   */
    onMarkdownClick(ev: PointerEvent) {
    const target = ev.target || ev.srcElement;
    if ('href' in target) {
      const anchor = target as HTMLAnchorElement;
      const href = anchor.href;
      const urlRewrite = urlToUrlRewrite(href);
      if (urlRewrite && urlRewrite.urlRewrite.openDetails) {
        this.details.open(urlRewrite.urlRewrite.openDetails, {
          id: urlRewrite.id,
        });
        ev.preventDefault();
      }

      if (href.startsWith('unsafe')) {
        ev.preventDefault();
      }

    }

  }

}
