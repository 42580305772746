import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { PlusAuthenticationService, RoleService } from '@karve.it/core';
import { JobService } from '@karve.it/features';
import { RawUser } from '@karve.it/interfaces/auth';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { JobUserInput, UpdateJobsInput } from '@karve.it/interfaces/jobs';
import { FullJobFragment} from 'graphql.generated';
import {  isEqual } from 'lodash';
import { filter, finalize, map } from 'rxjs/operators';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-job-participant',
    templateUrl: './job-participant.component.html',
    styleUrls: ['./job-participant.component.scss'],
})
export class JobParticipantComponent implements OnInit, OnDestroy, OnChanges {
    @Input() job: FullJobFragment;
    @Input() role: string;
    @Input() isLoading: boolean;


    participant: RawUser;
    isEditing = false;
    userSearchOptions = {};
    subs = new SubSink();

    constructor(
        private roleService: RoleService,
        private jobService: JobService,
        private detailsHelper: DetailsHelperService,
        private localNotify: FreyaNotificationsService,
        private plusAuth: PlusAuthenticationService,
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.job && changes.job.isFirstChange() === false) {
            this.findUserByRole(this.role);
        }
    }
    ngOnInit(): void {
        this.findUserByRole(this.role);

        this.roleService
            .listRoles({ zoneDir: ZoneDir.any, limit: -1 })
            .pipe(
                filter((res) => !res.loading),
                map((res) => {
                    const { staffRoleIds, customerRoleIds } =
                        res?.data?.roles?.reduce(
                            (acc, role) => {
                                if (role.name.includes('Customer')) {
                                    acc.customerRoleIds.push(role.id);
                                } else {
                                    acc.staffRoleIds.push(role.id);
                                }
                                return acc;
                            },
                            { staffRoleIds: [], customerRoleIds: [] }
                        );

                    return {
                        staffRoleIds,
                        customerRoleIds,
                    };
                })
            )
            .subscribe(
                (res) => {
                    this.userSearchOptions = {
                        roles: res.staffRoleIds,
                    };
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    toggleEditingState(forceState?: boolean) {
        this.isEditing = forceState !== undefined ? forceState : !this.isEditing;
    }

    removeParticipant() {
        const userToRemove = {
            userId: this.participant.id,
            role: this.role
        };

        this.updateJob(undefined, userToRemove);
        this.toggleEditingState(false);
    }

    assignYourself(){
        const newUser: RawUser = {
            id: this.plusAuth.user.id,
        };

        this.onUserSelected(newUser);
    }

    onUserSelected(newUser: RawUser) {
        const userToAdd = {
                role: this.role,
                userId: newUser.id,
            };

        const userToRemove = this.participant ?
            {
                role: this.role,
                userId: this.participant.id,
            } : undefined;

        this.updateJob(userToAdd, userToRemove);
        this.toggleEditingState(false);
    }

    updateJob(addUser: JobUserInput, removeUser: JobUserInput) {

        if(isEqual(addUser, removeUser)) {
            return;
        }

        // Job Input
        const updateJobInput: UpdateJobsInput = {
            updateJobs: [
                {
                    jobId: this.job.id,
                    addUsers: addUser ? [addUser] : [],
                    removeUsers: removeUser ? [removeUser] : [],
                },
            ],
        };

        this.subs.sink = this.jobService.updateJobs(updateJobInput).subscribe(
            (res) => {
                this.detailsHelper.pushUpdate({
                    id: this.job.id,
                    type: 'Jobs',
                    action: 'update',
                });
                this.localNotify.addToast.next({
                    severity: 'success',
                    summary: 'Job updated',
                });

            },
            (err) => {
                this.localNotify.apolloError(`Failed to update job`, err);
            },

        );
    }

    findUserByRole(role: string) {
       if(this.job?.users){
        const user = this.job.users.find((u) => u.role === role);
        this.participant = user?.user;
       }
    }

    openUserDetails(user: RawUser) {
        this.detailsHelper.open('users', { id: user.id });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
