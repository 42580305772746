import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-info-v2',
  templateUrl: './dashboard-info-v2.component.html',
  styleUrl: './dashboard-info-v2.component.scss'
})
export class DashboardInfoV2Component {

}
