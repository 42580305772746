import { Component, Input, OnInit } from '@angular/core';

import { ReportStatus } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-report-status-icon',
  templateUrl: './report-status-icon.component.html',
  styleUrls: ['./report-status-icon.component.scss']
})
export class ReportStatusIconComponent implements OnInit {

  @Input() status: ReportStatus = ReportStatus.Pending;
  @Input() showStatusText = false;
  @Input() fontSize = '1rem';
  @Input() tooltipPosition: string;

  constructor() { }

  ngOnInit(): void {
  }

}
