<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4>Availability Schedule</h4>
        </div>
        <div class="p-col">
            <label style="position: relative;">
                Area
                <i class="pi pi-info-circle" pTooltip="Filter availability for a specific area"></i>
            </label>
            <br />
            <app-assign-zones
                [(ngModel)]="selectedAreas"
                (ngModelChange)="retrieveAvailabilityConfigurations()" 
                [zoneTypes]="['area']"
                placeholder="All Areas"
                [showChips]="false"
                [limit]="1"
            ></app-assign-zones>
        </div>
        <div class="p-col">
            <label>
                Asset Types
                <i class="pi pi-info-circle" pTooltip="Shows the assets with the selected types"></i>
            </label>
            <br />
            <p-multiSelect
                #assetsMulti
                placeholder="No Types Selected"
                class="full-width-multiselect"
                [(ngModel)]="selectedAssetTypes"
                [ngModelOptions]="{standalone: true}"
                [maxSelectedLabels]="1"
                [options]="assetTypeOptions"
                [selectedItemsLabel]="'{0} Asset Types'"
                (onChange)="setAvailabilityOnCalendar()"
                data-cy="asset-types-multi-select-field"
            >
        </p-multiSelect>
        </div>
        <div class="p-col">
            <label>
                Date
                <i class="pi pi-info-circle" pTooltip="See the availability for a specific date"></i>
            </label>
            <br />
            <p-calendar
                inputId="basic"
                [showIcon]="true"
                placeholder="Select Date"
                (onSelect)="setCalendarDate($event)"
                appendTo="body"
            ></p-calendar>
        </div>
        <div class="p-col p-text-right">
            <ng-container *ngTemplateOutlet="createTemplateBtn"></ng-container>
            <ng-container *ngTemplateOutlet="applyTemplateBtn"></ng-container>
        </div>
    </div>
</div>

<div class="card">
    <div class="p-grid calendar-header-wrapper">
        <div class="p-md-4 select-day-wrapper">
            <span class="button-group">
                <button
                    pButton
                    icon="pi pi-chevron-left"
                    (click)="calendar.prev()"
                ></button>
                <button
                    pButton
                    label="Today"
                    (click)="calendar.today()"
                ></button>
                <button
                    pButton
                    icon="pi pi-chevron-right"
                    (click)="calendar.next()"
                ></button>
            </span>
        </div>
        <div class="p-col-12 p-md-4 fc calendar-title-wrapper">
            <H2
                *ngIf="calendarTitle$ | async as calendarTitle"
                class="fc-toolbar-title"
                >
                {{calendarTitle}}
            </H2>
        </div>
        <div class="p-md-4 p-d-inline-flex p-jc-md-end p-ai-start">
            <div class="button-group">
                <button
                    pButton
                    label="Day"
                    (click)="calendar.changeView('resourceTimelineDay')"
                ></button>
                <button
                    pButton
                    label="Week"
                    (click)="calendar.changeView('resourceTimelineWeek')"
                ></button>
            </div>
        </div>
    </div>
    <div class="calendar-holder" style="position: relative;">
        <span
            *ngIf="configurationQH.loading"
            class="loading-calendar p-text-center"
        >
            <i
                class="pi pi-spin pi-spinner"
                style="font-size: 2rem"
            ></i>
        </span>
        <full-calendar
            #fc
            [options]="calendarOptions"
        ></full-calendar>
    </div>
</div>

<br>

<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h5>Availability Templates</h5>
        </div>
        <div class="p-col p-text-right">
            <ng-container *ngTemplateOutlet="createTemplateBtn"></ng-container>
            <ng-container *ngTemplateOutlet="applyTemplateBtn"></ng-container>
        </div>
    </div>
    <app-availability-templates></app-availability-templates>
</div>

<ng-template #createTemplateBtn>
    <span 
        class="button-parent" 
        [pTooltip]="freyaHelper.inRootOrCorporateZone ? 'You cannot create availability templates in your current zone': 'Create a new availability template'"
        tooltipPosition="left"
    >
        <button
        pButton
        *hasPermission="['availability.createAvailabilityTemplate']"
        label="Create Template"
        (click)="mat.openDialog()"
        icon="pi pi-plus"
        [disabled]="freyaHelper.inRootOrCorporateZone"
        data-cy="create-template-button"
    ></button>
    </span>
</ng-template>

<ng-template #applyTemplateBtn>
    <span 
        class="button-parent" 
        [pTooltip]="freyaHelper.inRootOrCorporateZone ? 'You cannot create apply templates in the root zone': 'Apply a template to your assets'"
        tooltipPosition="left"
    >
        <button
        pButton
        *hasPermission="['availability.createAvailabilityTemplate']"
        label="Apply Template"
        (click)="at.openDialog()"
        icon="pi pi-arrow-right"
        [disabled]="freyaHelper.inRootOrCorporateZone"
        data-cy="apply-template-button"
    ></button>
    </span>
</ng-template>

<app-apply-template
    #at
    reviewButtonText="Apply Template"
></app-apply-template>

<app-mutate-availability-template
    #mat
    mutateType="create"
></app-mutate-availability-template>
