/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

export interface ListPandadocTemplatesOutput {
	pandadocTemplates: PandadocTemplate[];
}

export interface CreateDocumentFunctionOutput {
	createDocument: CreateDocumentOutput[];
}

export interface CreateDocumentOutput{
    dockLink: string;
    artifactId: string;
    recipients: DocumentRecipient[];
}

export interface DocumentRecipient{
    email: string;
    firstName: string;
    lastName: string;
    role: string;
}

export interface SendStandardDocumentsOutput {
	sendStandardDocuments: boolean;
}

export interface VariablesOutput {
	variables: DocumentVariablesInput;
}

export type DocumentVariablesInput = any;

export interface SendDocumentExternallyOutput {
	sendDocumentExternally: boolean;
}

export interface SendDocumentInternallyOutput {
	sendDocumentInternally: boolean;
}

export interface SaveArtifactOutput {
	saveArtifact: boolean;
}

export interface CommentWithArtifactOnOutput {
	commentWithArtifactOn: string[];
}

export interface Pandadoc {
	//    Id generated by Pandadoc
    id?: string;
    name?: string;
    status?: string;
    date_created?: string;
    date_modified?: string;
    expiration_date?: string;
    version?: string;
}


export interface UploadToPandadocOutput {
    pandadocId: string;
    artifactId: string;
}


export interface PandadocFilterInput {
	//    Return results where the `date_completed` field (ISO 8601) is greater than or equal to this value. (optional)
    completedFrom?: string;
	//    Return results where the `date_completed` field (ISO 8601) is less than or equal to this value. (optional)
    completedTo?: string;
	//    string | Returns results where 'contact_id' is present in document as recipient or approver (optional)
    contactId?: string;
	//    number | Specify how many document results to return. Default is 50 documents, maximum is 100 documents. (optional)
    count?: number;
	//    string | Return results where the `date_created` field (ISO 8601) is greater than or equal to this value. (optional)
    createdFrom?: string;
	//    string | Return results where the `date_created` field (ISO 8601) is less than this value. (optional)
    createdTo?: string;
	//    boolean | Returns only the deleted documents. (optional)
    deleted?: boolean;
	//    Pandadoc id of a specific document to retrieve
    id?: string;
	//    The pandadoc UUID of the folder where the documents are stored. (optional)
    folderUuid?: string;
	//    Specify the form used for documents creation. This parameter can't be used with template_id. (optional)
    formId?: string;
	//    Returns results where 'membership_id' is present in document as owner (should be member uuid)
    membershipId?: string;
}


export interface PandadocFilterInput {
    metadata: string;
    modifiedFrom: string;
    modifiedTo: string;
    orderBy: string;
    page: number;
    q: string;
    status: number;
    statusNe: number;
    tag: string;
}


export interface PandadocDocument {
    id?: string;
    name?: string;
    status?: string;
    dateCreated?: string;
    dateModified?: string;
    expirationDate?: string;
    version?: string;
}


export interface PandadocTemplate {
    id?: string;
    name?: string;
    dateCreated?: string;
    dateModified?: string;
    version?: string;
}

export interface PandadocTemplatesInput {
	q?: string;
	tag?: string;
	count?: number;
	page?: number;
	deleted?: boolean;
	id?: string;
	folderUuid?: string;
	shared?: boolean;
}

export interface CreateDocumentInput {
	serviceToUse?: string;
	documentsToSend?: string[];
	templateIds?: string[];
	userId?: string;
    sendStandardDocuments?: boolean;
    variables?: DocumentVariablesInput;
    send?: boolean;
    sendDocumentExternally?: boolean;
    sendDocumentInternally?: boolean;
    saveArtifact?: boolean;
    commentWithArtifactOn?: boolean;
}

export const pandadocTemplatesQuery = gql`
	query pandadocTemplates($q: String, $tag: String, $count: Int, $page: Int, $deleted: Boolean, $id: String, $folderUuid: String, $shared: Boolean, ){
		pandadocTemplates(q: $q, tag: $tag, count: $count, page: $page, deleted: $deleted, id: $id, folderUuid: $folderUuid, shared: $shared, ){
			    id
			    name
			    dateCreated
			    dateModified
			    version
        }		
    }
`;


export const createDocumentMutation = gql`
	mutation createDocument(
        $serviceToUse: String!,
        $documentsToSend: [String!],
        $templateIds: [String!],
        $userId: String, 
        $sendStandardDocuments: Boolean, 
        $variables: DocumentVariablesInput,
        $send: Boolean,
        $sendDocumentExternally: Boolean,
        $sendDocumentInternally: Boolean,
        $saveArtifact: Boolean,
        $commentWithArtifactOn: [String]
        ){
            createDocument(
                serviceToUse: $serviceToUse, 
                documentsToSend: $documentsToSend, 
                templateIds: $templateIds, 
                userId: $userId,
                variables: $variables,
                send: $send,
                sendStandardDocuments: $sendStandardDocuments,
                sendDocumentExternally: $sendDocumentExternally,
                sendDocumentInternally: $sendDocumentInternally,
                saveArtifact: $saveArtifact,
                commentWithArtifactOn: $commentWithArtifactOn
            ){
                docLink
                artifactId
                recipients {
                    email
                    firstName
                    lastName
                    role
                }
            }
    }
`;

export interface ResendDocumentInput{
    artifactId: string;
    message: string;
    subject: string;
}

export interface ResendDocumentOutput{
    resendDocument: boolean;
}

export const resendDocumentMutation = gql`
    mutation resendDocument(
        $artifactId: String!
        $message: String!
        $subject: String!
    ){
        resendDocument(
            artifactId: $artifactId,
            message: $message,
            subject: $subject
        )
    }
`;

export interface GetPandadocDocumentLinkInput{
    artifactId: string;
    role: string;
}

export interface GetPandadocDocumentLinkOutput{
    getPandadocDocumentLink: string;
}

export const getPandadocDocumentLinkQuery = gql`
    query getPandadocDocumentLink(
        $artifactId: String!,
        $role: String!
    ){
        getPandadocDocumentLink(
            artifactId: $artifactId,
            role: $role
        )
    }
`;
