<p-table
    [value]="templates"
    [columns]="templateColumns"
    [loading]="templatesQH.loading"
    breakpoint="620px"
    responsiveLayout="stack"
>
    <ng-template
        pTemplate="header"
        let-columns
    >
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>

    <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
    >
        <tr class="selectable-row" (click)="viewTemplateDetails(rowData)" data-cy="template-items">
            <td *ngFor="let col of columns">
            <span class="p-column-title">{{col.header}}:</span>
                <div [ngSwitch]="col.header">
                    <div *ngSwitchCase="'Areas'">
                        <span>{{rowData[col.field] | join : ', ' : 'name'}}</span>
                    </div>
                    <div *ngSwitchCase="'End Date'">
                        {{rowData[col.field] || 'No End Date'}}
                    </div>
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template
    pTemplate="emptymessage"
    let-car
    let-columns="columns"
>
    <tr>
        <td [attr.colspan]="'6'">
            No Availability Templates
        </td>
    </tr>
</ng-template>
</p-table>