import { NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { filter, skip } from 'rxjs';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { BrandingService } from '../../services/branding.service';
import { SCHEDULE_VIEW_TYPE_MAP } from '../schedules.component';

enum ScheduleViewType {
  DayWeekMonth,
  Dispatch
}

@Component({
  selector: 'app-schedule-page',
  templateUrl: './schedule-page.component.html',
  styleUrl: './schedule-page.component.scss',
  standalone: true,
  imports: [
    TabMenuModule,
    NgClass
  ]
})
export class SchedulePageComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private brandingService: BrandingService) { }
  public items: MenuItem[] = [
    { label: 'Schedule', viewType: 'default', icon: 'pi pi-calendar' }
  ];
  public viewType: string;
  public activeItem: MenuItem | undefined;

  private subs = new SubSink();


  ngOnInit(): void {
    this.watchDispatchFeature();
    this.watchRouteChange();
  }

  public onActiveItemChange(event: MenuItem) {
    if (!event) return;
    let viewType = event.viewType;

    const currentSelectedItem = this.router.url.includes('dispatch') ? 'Dispatch' : 'Schedule';

    if (currentSelectedItem === event.label) return;

    if (event.label === 'Schedule') {
      const scheduleViewType = sessionStorage.getItem(environment.lskeys.scheduleView);
      viewType = SCHEDULE_VIEW_TYPE_MAP[scheduleViewType] || SCHEDULE_VIEW_TYPE_MAP.resourceTimelineDay;
    }

    this.router.navigate(['/schedule/', viewType], {
      queryParamsHandling: 'preserve'
    });
  }

  private setActiveItem() {
    // If in future we need to add more tabs, we need to identify the active tab based on the URL
    if (this.items.length === 1) {
      this.activeItem = this.items[ScheduleViewType.DayWeekMonth];
      return;
    }

    const activeItemIndex = this.router.url.includes('dispatch') ? ScheduleViewType.Dispatch : ScheduleViewType.DayWeekMonth;
    this.activeItem = this.items[activeItemIndex];
  }

  private watchDispatchFeature() {
    this.subs.sink = this.brandingService.watchFeature('dispatch').subscribe((enabled) => {
      if (enabled) {
        this.items.push({ label: 'Dispatch', viewType: 'dispatch', icon: 'pi pi-truck' });
      } else {
        this.items = this.items.filter((item) => item.label !== 'Dispatch');
      }

      this.setActiveItem();
    });
  }

  private watchRouteChange() {
    this.subs.sink = this.router.events.pipe(
      filter(event => event.type === EventType.NavigationEnd),
      // When the page inits, `watchDispatchFeature` will add the dispatch tab and set active item, so we need to skip the first event
      skip(1),
    ).subscribe(() =>
      this.setActiveItem()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
