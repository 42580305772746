import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseZoneWithParentFragment, CreateInvoiceGQL, CreateInvoiceMutationVariables, GetConfigValuesGQL } from '../../../generated/graphql.generated';
import { EventSelectComponent } from '../../custom-inputs/event-select/event-select.component';
import { CalendarEvent } from '../../interfaces/calendarEvents';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { ApplyTransactionComponent } from '../../transactions/apply-transaction/apply-transaction.component';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-invoice',
  templateUrl: './mutate-invoice.component.html',
  styleUrls: ['./mutate-invoice.component.scss']
})
export class MutateInvoiceComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('events') eventsRef: TemplateRef<any>;

  @ViewChild(EventSelectComponent) eventSelectRef: EventSelectComponent;

  @Input() mutateType: 'update' | 'create';

  // ADDITIONAL INPUTS
  @Input() jobId: string;

  steps: MutateObjectElement[];

  allowMultipleEvents = true;

  invoiceForm = new UntypedFormGroup({
    events: new UntypedFormControl([], [ Validators.required ]),
  });

  subs = new SubSink();

  constructor(
    private createInvoiceGQL: CreateInvoiceGQL,
    private localNotify: FreyaNotificationsService,
    private detaislHelper: DetailsHelperService,
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private documentHelper: DocumentHelperService,
    private branding: BrandingService,
    private getConfigGQL: GetConfigValuesGQL,
  ) {}

  ngOnInit(): void {
    this.checkIfMultipleEventsDisabled();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openDialog() {

    this.steps = [
      { name: 'Events', ref: this.eventsRef, control: 'events', type: 'array' },
    ];

    this.invoiceForm.reset({
      events: [],
    });

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();

    if (this.eventSelectRef) {
      this.eventSelectRef.searchEvents();
    }
  }

  mutateInvoice() {
    if (this.mutateType === 'update') {
      throw new Error('Update invoice not implemented');
    } else if (this.mutateType === 'create') {
      this.createInvoice();
    }
  }

  createInvoice() {

    const { value } = this.invoiceForm;

    const input: CreateInvoiceMutationVariables = {
      invoices: [
        {
          eventIds: value.events.map((e: CalendarEvent) => e.id),
        },
      ],
    };

    this.createInvoiceGQL.mutate(input).subscribe(async (res) => {

      const [ invoice ] = res.data.createInvoice.invoices;

      if (invoice) {

        this.mutateRef.closeDialog();

        this.localNotify.success('Generating invoice', 'You can apply payments while it generates');

        this.detaislHelper.pushUpdate({
          id: this.jobId,
          type: 'Invoice',
          action: 'create',
        });

        this.detaislHelper.detailsItem.next({ type: 'invoice', item: { id: invoice.id } });

        const {
          transactionsWithAvailability,
          transactionsAvailable,
        } = await this.documentHelper.getTransactionsAvailableForInvoice(invoice);

        if (transactionsAvailable) {
          this.dialogService.open(ApplyTransactionComponent, {
            header: 'Apply Existing Transaction to Invoice?',
            width: this.responsiveHelper.dialogWidth,
            data: { transactionsWithAvailability, invoice },
            contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
          });
        }
      }
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError('Failed to create invoice', err);
    });
  }

  checkIfMultipleEventsDisabled() {

    const configQueryRef = this.getConfigGQL.watch({ keys: [ 'invoice.disableMultipleEvents'] });

    this.subs.sink = configQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      const [ config ] = res.data.getConfigValues;

      this.allowMultipleEvents = config?.value !== 'true';

    });

    // Refetch on zone changes
    this.subs.sink = this.branding.currentZone()
      .pipe(
        filter((z): z is (BaseZoneWithParentFragment & { domain: string }) => Boolean(z)),
        map((z) => z.id),
        distinctUntilChanged(),
        // We just subscribed to getConfigs, no need to refetch on the first emission
        skip(1),
      )
      .subscribe(() => {
        configQueryRef.refetch();
      });
  }

}
