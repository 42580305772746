import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { PlusAuthenticationService } from '../../core/public-api';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FullJobFragmentWithFields, jobToolFeature } from '../job-tool.reducer';


import { JobStatusDropdownComponent } from "./job-status-dropdown/job-status-dropdown.component";
import { JobTagsComponent } from './job-tags/job-tags.component';
import { ViewableArtifact } from 'src/app/services/freya-helper.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { HistoryService } from 'src/app/history/history.service';
import { CloseJobV2Component } from '../jobv2-create/close-job-componentV2/close-job-componentV2.component';
import { DialogService } from 'primeng/dynamicdialog';
import { JobToolActions } from '../job-tool.actions';


@Component({
  selector: 'app-job-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
    JobStatusDropdownComponent,
    JobTagsComponent
  ],
  templateUrl: './job-header.component.html',
  styleUrl: './job-header.component.scss'
})
export class JobHeaderComponent implements OnInit, OnDestroy {
  loaded$ = this.store.select(jobToolFeature.jobLoaded);
  job$ = this.store.select(jobToolFeature.selectJob);
  zone$ = this.store.select(jobToolFeature.selectZone);
  customerName$ = this.store.select(jobToolFeature.selectCustomerName);
  documentArtifacts$ = this.store.select(jobToolFeature.selectDocumentsArtifacts);

  latestInvoiceArtifact: ViewableArtifact | undefined = undefined;

  job: FullJobFragmentWithFields;

  actionMenuItems: MenuItem[] = [
    {
      id: 'edit',
      label: 'Edit Job',
      icon: 'pi pi-pencil',
      command: () => {
        this.onEditClick(this.job.id);
      }
    },
    {
      id: 'estimate',
      label: 'Open in legacy processor',
      icon: 'pi pi-money-bill',
      command: () => {
        this.onOpenInOldEstimatorClick(this.job.id);
      }
    },
    {
      id: 'download-invoice',
      label: 'Download latest invoice',
      icon: 'pi pi-file-o',
      command: () => {
        this.downloadLatestInvoice();
      },
      visible: false,
    },
    {
      id: 'toggle-open',
      label: undefined,
      icon: undefined,
    },
    {
      id: 'view-history',
      label: 'View History',
      icon: 'pi pi-book',
      command: () => {
        this.viewJobHistory();
      },
    },
    {
      id: 'send-documents',
      label: 'Send Document',
      icon: 'pi pi-send',
      disabled: false,
      command: () => this.documentHelper.openDocumentsDialog({
        jobId: this.job?.id,
        jobCode: this.job?.code
      }),
    }
  ];

  subs = new SubSink();

  constructor(
    public store: Store,
    public detailsHelper: DetailsHelperService,
    public authSvc: PlusAuthenticationService,
    public documentHelper: DocumentHelperService,
    public historyService: HistoryService,
    public dialogService: DialogService,
    private router: Router,
  ) {
    //this.watchJob();
  }

  ngOnInit(): void {
    this.subs.sink = this.job$.subscribe((job) => {
      this.job = job;

      if (this.job) {
        this.updateToggleActions();
      }
    });

    this.subs.sink = this.documentArtifacts$.subscribe((documentArtifacts) => {
      this.latestInvoiceArtifact = documentArtifacts['invoice'];
      const downloadInvoiceItem = this.actionMenuItems.find(item => item.id === 'download-invoice');

      downloadInvoiceItem.visible = Boolean(this.latestInvoiceArtifact);
    });
  }

  closeJob() {
    return this.dialogService.open(CloseJobV2Component, {
        header: 'Close job',
        width: '42rem',
    });
  }

  reopenJob() {
    this.store.dispatch(JobToolActions.reopenExistingJob({ jobId: this.job?.id }));
  }

  onEditClick(jobId: string): void {;
    this.router.navigate([`/jobs/${jobId}/edit`]);
  }

  onOpenInOldEstimatorClick(jobId: string): void {;
    this.router.navigate([`/estimating/${jobId}`]);
  }

  downloadLatestInvoice() {
    if (this.latestInvoiceArtifact) {
      this.documentHelper.downloadDocument(this.latestInvoiceArtifact);
    }
  }

  viewJobHistory() {
    if (!this?.job?.id) { return; }
      this.historyService.openHistory('Job', [ this.job.id ]);
  }

  updateToggleActions() {
    const isJobClosed = Boolean(this.job?.closedAt);

    const menuItem = this.actionMenuItems?.find(item => item?.id === 'toggle-open');

    menuItem.label = isJobClosed ? 'Open' : 'Close';
    menuItem.icon = isJobClosed ? `pi pi-circle` : 'pi pi-circle-fill';

    menuItem.command = () => {
      if (isJobClosed) {
        this.reopenJob();
      } else {
        this.closeJob();
      }
    };
  }

  private watchJob() {
    this.subs.sink = this.job$.subscribe((job) => this.job = job);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
