import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isEqual } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { SubSink } from 'subsink';

import { SetFieldValueInput, SetFieldValuesGQL, SetFieldValuesMutationVariables } from '../../../generated/graphql.generated';

import { PlusAuthenticationService } from '../../core/public-api';
import { getFieldValue } from '../../fields/fields.utils';
import { FilterType } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

import { AnyParams, FilterConfiguration } from '../../shared/query-params.service';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit, OnDestroy {

  filter: FilterConfiguration<AnyParams> = {
    name: 'All',
    filter: {},
  };

  @Input() filterType: FilterType;
  @Input() presets: FilterConfiguration<AnyParams>[];

  @Output() filterChange = new EventEmitter<AnyParams>();

  refreshing = false;

  customFilters: FilterConfiguration<AnyParams>[] = [];

  selected: AnyParams;

  groupedFilters: { label: string; items: FilterConfiguration<AnyParams>[] }[];
  isNewFilterConfig = false;

  subs = new SubSink();

  constructor(
    private auth: PlusAuthenticationService,
    private freyaHelper: FreyaHelperService,
    private detailsHelper: DetailsHelperService,
    private authService: PlusAuthenticationService,
    private confirm: ConfirmationService,
    private notify: FreyaNotificationsService,
    private setFieldValuesGQL: SetFieldValuesGQL,
  ) { }

  ngOnInit(): void {
    this.fetchCustomFilters();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Fields')
      .subscribe(() => {
        this.fetchCustomFilters();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  fetchCustomFilters(): void {
    const userId = this.auth.user.id;

    this.freyaHelper.getFieldValues(
      [`filters.${this.filterType}`],
      [userId],
      [ 'User' ],
      'network-only'
    )
      .then(([ custom ]) => {

        const filterValues: FilterConfiguration<AnyParams>[] = getFieldValue(custom) || [];

        this.customFilters = filterValues
          .map((f) => ({ ...f, saved: true }));

        this.setFilterValue();
      });
  }

  setFilterValue(v?: AnyParams) {
    const configuration = this.findFilterConfigurationFromFilter(v || this.filter.filter);
    if (configuration) {
      this.filter = configuration;
    }

    const presetGroup = {
      label: 'Presets',
      items: [...this.presets]
    };

    const newFilter = {
      name: 'New Filter',
      filter: v || this.filter?.filter
    } as FilterConfiguration<AnyParams>;

    const customGroup = {
      label: 'Custom',
      items: [ ...this.customFilters ],
    };
  
    if (!configuration) {
      // this.selected = newFilter.filter;
      this.filter = newFilter;
      customGroup.items.push(newFilter);
    }

    this.selected = this.filter.filter;
    this.groupedFilters = [ customGroup, presetGroup ];
    this.isNewFilterConfig = !configuration;
  }

  findFilterConfigurationFromFilter(v: AnyParams) {

    const allFilters = [ ...this.presets, ...(this.customFilters || []) ];

    return allFilters.find((f) => isEqual(f.filter, v));
  }

  refreshDropdown() {
    this.refreshing = true;
    setTimeout(() => this.refreshing = false, 50);
  }

  confirmDelete(filter: FilterConfiguration<AnyParams>) {
    this.confirm.confirm({
      header: 'Delete Filter?',
      message: `Are you sure you want to delete ${filter.name}?`,
      accept: () => this.deleteFilter(filter),
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel'
    });
  }

  deleteFilter(filter: FilterConfiguration<AnyParams>) {

    const userId = this.authService.user.id;

    const updatedFilters = this.customFilters
      .filter((f) => !(f.name === filter.name && isEqual(f.filter, filter.filter)));

    const fieldValue: SetFieldValueInput = {
      fieldName: `filters.${this.filterType}`,
      value: updatedFilters,
    };

    const setFieldsInput: SetFieldValuesMutationVariables = {
      fields: [ fieldValue ],
      objects: [ userId ],
      objectLabel: 'User',
    };

    this.setFieldValuesGQL.mutate(setFieldsInput)
      .subscribe({
        next: () => {
          this.notify.success('Filter deleted');
          this.fetchCustomFilters();
        },
        error: (err) => {
          this.notify.error('Could not delete filter');
          console.error(err);
        },
      });
  }

}
