<div
    class="create-location-input"
    [formGroup]="locationForm"
>
    <ng-template #name>
        <div *appVar="locationForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="name"
                placeholder="Name *"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #address>
        <div *appVar="locationForm.get('address') as control">
            <label>Address</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="address"
                placeholder="Address *"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #city>
        <div *appVar="locationForm.get('city') as control">
            <label>City</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="city"
                placeholder="City *"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #areaCode>
        <div *appVar="locationForm.get('areaCode') as control">
            <label>Area Code</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="areaCode"
                placeholder="Area Code *"
                (keydown.space)="$event.preventDefault();"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <br>
            <small>Do not enter any spaces</small>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #country>
        <div *appVar="locationForm.get('country') as control">
            <label>Country</label>
            <br />
            <p-dropdown
                class="full-width"
                (onChange)="changeRegexValidator()"
                placeholder="Select Country"
                [options]="countries"
                formControlName="country"
                appendTo="body"
            ></p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #jurisdiction>
        <div *appVar="locationForm.get('countryJurisdiction') as control">
            <label>Jurisdiction</label>
            <br />
            <p-dropdown
            class="full-width"
                placeholder="Select a Jurisdiction"
                [options]="locationForm.value.country | jurisdiction"
                formControlName="countryJurisdiction"
                appendTo="body"
            ></p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #attributes>
        <div  *appVar="locationForm.get('attributes') as control">
            <label>Attributes (comma seperated)</label>
            <br />
            <p-chips formControlName="attributes"
                separator=","
                addOnBlur="addOnBlur"
                [allowDuplicate]="false"
            ></p-chips>
            <app-validation-messages [control]="control"></app-validation-messages>
        </div>
    </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="locationForm"
    objectType="Location"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>