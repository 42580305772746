import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TaxService } from '@karve.it/features';
import { Tax, ListTaxesFunctionOutput } from '@karve.it/interfaces/taxes';
import {QueryRef} from 'apollo-angular';

import { clone } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DeleteObjectComponent } from 'src/app/shared/delete-object/delete-object.component';
import { MutateTaxComponent } from 'src/app/shared/mutate-tax/mutate-tax.component';
import { objectTypeFilter } from 'src/app/utilities/details-helper.util';
import { DisabledWhen, KarveMenuItem, parseMenuItemCategoriesVisible, setMenuItemDisabled } from 'src/app/utilities/menu-item.util';

import { SubSink } from 'subsink';


@Component({
  selector: 'app-tax-details',
  templateUrl: './tax-details.component.html',
  styleUrls: ['./tax-details.component.scss']
})
export class TaxDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('mutate') mutateRef: MutateTaxComponent;
  @ViewChild('delete') deleteComponent: DeleteObjectComponent;

  @Input() tax: Tax;

  updateTaxAction: KarveMenuItem = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.mutateRef.tax = this.tax;
      this.mutateRef.mutateType = 'update';
      this.mutateRef.openDialog();
    },
    disabledWhen: {
      objectDeleted: true,
    },
  };

  deleteTaxAction: KarveMenuItem = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.deleteComponent.name = this.tax.name;
      this.deleteComponent.type = 'Tax';
      this.deleteComponent.openDialog();
    },
    disabledWhen: {
      objectDeleted: true,
    }
  };

  taxActions = [{
    label: 'Tax Actions',
    items: [
      this.updateTaxAction,
      this.deleteTaxAction
    ]
  }];

  subs = new SubSink();

  taxesQueryRef: QueryRef<ListTaxesFunctionOutput>;

  loading = false;

  constructor(
    private taxService: TaxService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    // this.subs.sink = this.detailsHelper.taxesUpdated.subscribe(() => {
    //   this.retrieveTax();
    // });
    this.initializePermissions();
    this.subs.sink = this.detailsHelper.getObjectUpdates('Taxes').subscribe(() => {
      this.retrieveTax();
    });


  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['taxes.update', 'taxes.delete'])
      .subscribe((res) => {
        this.updateTaxAction.visible = res[0];
        this.deleteTaxAction.visible = res[1];

        parseMenuItemCategoriesVisible(this.taxActions);
        this.taxActions = clone(this.taxActions);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.tax.name || !this.tax.zones) { // If only passed the id then load the tax
      this.retrieveTax();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveTax() {
    if (this.taxesQueryRef) {
      this.taxesQueryRef.refetch({
        filter: {
          taxIds: [this.tax.id],
          showDeleted: true,
        },
      });
      return;
    }

    this.taxesQueryRef = this.taxService.watchtaxes({
      filter: {
          taxIds: [this.tax.id],
          showDeleted: true,
        },
    }, {
      fetchPolicy: 'network-only',
    });

    this.subs.sink = this.taxesQueryRef.valueChanges.subscribe((res) => {

      this.loading = res.loading;

      if (!res.data?.taxes?.taxes.length) { return; }

      this.tax = res.data?.taxes?.taxes?.[0];

      this.setActionsDisabled();
    }, (err) => {
      console.log(err);
    });
  }

  deleteTax() {
    this.subs.sink = this.taxService.deleteTaxes({ ids: [this.tax.id] }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: this.tax.id,
        type: 'Taxes',
        action: 'delete'
      }, true);
      this.localNotify.addToast.next({ severity: 'success', summary: 'Tax deleted' });
    }, (err) => {
      this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to delete tax' });
    });
  }

  setActionsDisabled() {
    const [ actionsMenu ] = this.taxActions;

    const { items: taxActions } = actionsMenu;

    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.tax.deletedAt),
    };

    for (const action of taxActions) {
      setMenuItemDisabled(action, disabledWhen);
    }
  }

}
