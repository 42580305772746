<div *ngIf="event && event?.type !== 'book-off'">
    <div class="header-with-button">
        <h4>
            <i [class]="eventIcon"></i> Event
            <app-object-state
                [object]="event"
                objectType="event"
                [colorful]="true"
            ></app-object-state>
        </h4>
        <div>
            <span [pTooltip]="allActionsDisabledTooltip">
                <button
                    pButton
                    pRipple
                    label="Actions"
                    (click)="menu.toggle($event)"
                    icon="pi pi-angle-down"
                    [disabled]="allActionsDisabled"
                    ></button>
            </span>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="eventActions"
            ></p-menu>
        </div>
    </div>

    <div class="card">
        <h4
            *ngIf="event.title; else loadingBar"
            [pTooltip]="event.type || 'No Type'"
            tooltipPosition="left"
        >
            {{event.title | rename:'estimating':'OSEstimate':false}}
            <div
                class="color-square"
                [style.background-color]="colors?.backgroundColor"
            ></div>
        </h4>
        <p *ngIf="event.status; else loadingBar">
            <b
                pTooltip="Status"
                tooltipPosition="left"
            >
                <i
                    class="pi"
                    [class.pi-minus-circle]="event.status === 'pending'"
                    [class.pi-calendar]="event.status === 'booked'"
                    [class.pi-times-circle]="event.status === 'cancelled'"
                    [class.pi-check-circle]="event.status === 'completed'"
                    [class.pi-thumbs-up]="event.status === 'confirmed'"
                ></i>
                {{ event.status | titlecase }}</b>
        </p>
        <p-message
            *ngIf="sharedFromAnotherZone"
            severity="warn"
            class="message-info"
            [text]="sharedMessage"
            [escape]="false"
        ></p-message>
        <p-message
            *ngIf="event.status === 'pending' && !sharedFromAnotherZone"
            severity="warn"
            class="message-info"
            text="Event pending and will expire {{+(event.metadata?.timeLockExpirMilliSec) | appropriateTimeMeasurement }}. Click here to mark booked."
            [escape]="false"
            pTooltip="Unless booked this pending event will expire to allow another event to be booked in it's place"
            tooltipPosition="left"
            (click)="updateEventStatus('booked')"
        ></p-message>
        <p-message
            *ngIf="event.status === 'booked' && eventEndIsInPast() && !sharedFromAnotherZone"
            severity="warn"
            class="message-info"
            text="This event was supposed to end {{ event.end * 1000 | appropriateTimeMeasurement }}. Click here to mark completed."
            [escape]="false"
            pTooltip="Unless booked this pending event will expire to allow another event to be booked in it's place"
            tooltipPosition="left"
            (click)="updateEventStatus('completed')"
        ></p-message>

        <b *ngIf="event.sharedZones?.length">Shared with Zones</b>
        <p *ngFor="let zone of event.sharedZones">{{zone}}</p>

        <p>
            Type:
            <span
            >{{ event?.type | titlecase }}</span>
        </p>

        <p>
            Job:
            <span
                *ngIf="event.job?.id"
                pButton
                class="freya-link-button"
                (click)="openJob()"
            >{{ event?.job?.code }}</span>
            <span *ngIf="!event.job?.id">N/A</span>
        </p>

        <!-- <p>Start: {{event.start | freyaDate: 'h:mm a, MMM d, y'}}</p>
        <p>End: {{event.end | freyaDate: 'h:mm a, MMM d, y'}}</p> -->
        <b>Start / End</b>
        <p>{{ event | eventDuration: false : true }} </p>

        <b>Start / End (with travel)</b>
        <p>{{ event | eventDuration: true : true }} </p>
    </div>

    <ng-container *ngIf="invoice">
        <h5>Invoice</h5>
        <div class="card">
            <span class="freya-link-button" (click)="openInvoice()">
                {{ invoice | invoiceName : (event?.job?.users | jobCustomer) : event?.job?.code}}
            </span>
        </div>
    </ng-container>

    <h5>Customer</h5>
    <div class="card">
        <div *ngIf="!eventQH.loading; else loadingBar">
            <p
            class="freya-link-button"
            [class.disabled]="sharedFromAnotherZone"
            (click)="openCustomer()"
        ><i class="pi pi-user"></i>  {{ event | eventCustomer }}  </p>
        <br>
        <br>
        <p><i class="pi pi-phone"></i> {{(event | eventCustomer : false) | phone}}</p>
        <p><i class="pi pi-envelope"></i> {{(event | eventCustomer : false)?.email || 'No Email'}}</p>
        </div>
    </div>
    <ng-container *ngIf="yemboHelper.yemboEnabled && event?.type === 'virtualEstimate'">
        <h5>Yembo</h5>
        <div class="card">
            <div *ngIf="event.status !== 'pending' else notBooked">
                <div *ngIf="(event?.metadata?.yemboMoveLink)">
                    <a
                        target="_blank"
                        [href]="(event?.metadata?.yemboMoveLink)"
                    >
                        <i class="pi pi-external-link"></i>
                        View Move In Yembo
                    </a>
                    <hr>
                </div>

                <!-- <a [href]="event?.metadata | metadata : 'yemboConfirmationLink'" target="_blank">Confirmation Link</a> -->
                <b>Customer Confirmation Link</b>
                <app-copy-button
                    *ngIf="event?.metadata | metadata : 'yemboConfirmationLink'; else notAvailable"
                    [content]="event?.metadata | metadata : 'yemboConfirmationLink'"
                ></app-copy-button>
                <br>
                <b>Customer Consult Link</b>
                <app-copy-button
                    *ngIf="event?.metadata | metadata : 'yemboJoiningLink'; else notAvailable"
                    [content]="event?.metadata | metadata : 'yemboJoiningLink'"
                ></app-copy-button>
                <!-- <a [href]="event?.metadata | metadata : 'yemboJoiningLink'" target="_blank">Customer Link</a> -->
            </div>
            <ng-template #notBooked>
                Book this event to create a move in yembo
            </ng-template>
        </div>
    </ng-container>

    <ng-template #notAvailable>
        <br>
        <span>Not Available</span>
        <br>
    </ng-template>

    <div class="p-d-flex p-jc-between p-ai-center title-bar">
        <h5>Crew</h5>
        <p-button
        label="Edit Crew"
        (click)="editCrew()"
        [disabled]="allActionsDisabled" ></p-button>
    </div>
    <div class="card">
        <span *ngFor="let crewCharge of crewCharges">
            {{crewCharge?.product?.name}}
            <div
                class="color-square"
                [style.background-color]="crewCharge.product.metadata | metadata : 'color'"
            ></div>
            <br><br>
        </span>
        <p *ngIf="!crewCharges?.length">No crew charges assigned</p>

        <p *ngIf="!eventAttendees; else displayAttendees">No crew assigned</p>

        <ng-template #displayAttendees>
            <div class="event-attendees">
                <div *ngIf="eventAttendees?.[eventAttendeeRoles.crewLead]?.length">
                    <h6>Crew Lead</h6>
                    <p *ngFor="let attendee of eventAttendees[eventAttendeeRoles.crewLead]">
                    {{attendee?.givenName}} {{attendee?.familyName}}
                    </p>
                </div>

                <div *ngIf="eventAttendees?.[eventAttendeeRoles.assistantCrewLead]?.length">
                    <h6>Assistant Crew Lead</h6>
                    <p *ngFor="let attendee of eventAttendees[eventAttendeeRoles.assistantCrewLead]">
                    {{attendee?.givenName}} {{attendee?.familyName}}
                    </p>
                </div>

                <div *ngIf="eventAttendees?.[eventAttendeeRoles.crewMember]?.length">
                    <h6>Crew Member{{ eventAttendees[eventAttendeeRoles.crewMember].length === 1 ? '' : 's' }}</h6>
                    <p *ngFor="let attendee of eventAttendees[eventAttendeeRoles.crewMember]">
                    {{attendee?.givenName}} {{attendee?.familyName}}
                    </p>
                </div>
            </div>
        </ng-template>
    </div>

    <h5>Assets</h5>
    <div class="card">
        <div *ngFor="let asset of event.assets">
            <p>{{asset.name}} <p-tag [value]="asset.type"></p-tag></p>
        </div>
        <div *ngIf="!event.assets?.length">
            No Assets Assigned
        </div>
    </div>

    <h5>Locations <div
            class="color-square"
            [style.background-color]="colors?.zoneBackgroundColor"
        ></div>
    </h5>
    <div class="card">
        <div *ngFor="let location of event.locations; let i = index">
            <app-event-location
                [readonly]="readonly"
                [calendarEventType]="event.type"
                [eventLocation]="location"
                [event]="event"
                [arrivalTimeList]="this.arrivalTimeList[i]"
            ></app-event-location>
            <br />
        </div>
    </div>

    <h5>Financials</h5>
    <div class="card">
        <app-financials-table
            [loading]="eventQH.loading"
            [item]="event"
            [showPaid]="false"
        >
        </app-financials-table>
    </div>

    <h5>Charges</h5>
    <div class="card">
        <div *ngIf="!eventQH.loading; else loadingBar">
            <p *ngFor="let c of event.charges | chargeDamage : true">
                {{c | charge}}
            </p>
            <p *ngIf="!(event?.charges | chargeDamage : true)?.length">No Charges</p>
        </div>
    </div>

    <h5>Damages</h5>
    <div class="card">
        <div *ngIf="!eventQH.loading; else loadingBar">
            <p *ngFor="let c of event.charges | chargeDamage : false">
                {{c | charge}}
            </p>
            <p *ngIf="!(event?.charges | chargeDamage : false)?.length">No Damages</p>
        </div>
    </div>


    <h5>Area</h5>
    <div class="card">
        <p *ngFor="let zone of zones">{{ zone.name }}</p>
    </div>

    <app-comments
        *ngIf="event?.job && !eventQH.loading; else loadingBar"
        #comments
        [objectId]="event?.job?.id"
        [objectType]="'Job'"
        [smallContainer]="true"
        [collapsed]="true"
        [commentsFilter]="{hasNoAttributes: ['inventory', 'generated-inventory']}"
        [readonly]="readonly"
    ></app-comments>
    <br>
    <app-estimating-inventory
        *ngIf="event.job && !eventQH.loading; else loadingBar"
        [job]="event.job"
        [readonly]="true"
    ></app-estimating-inventory>

    <br />
    <br />
</div>

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton
            width="100%"
            height="16px"
        ></p-skeleton>
    </div>
</ng-template>

<!-- <app-mutate-calendar-event
    #mutate
    mutateType="update"
    [event]="event"
></app-mutate-calendar-event> -->
