import { Pipe, PipeTransform } from '@angular/core';
import { Asset, assetsQuery } from '@karve.it/interfaces/assets';

@Pipe({
  name: 'assetZones'
})
export class AssetZonesPipe implements PipeTransform {
  // Cap amount is how many items you want to appear before dispalying '+X', off by default
  transform(asset: Asset, capAmount): string {
    if (!asset || !asset?.zones) { return 'None'; }

    let returnString = '';

    for(const z of asset.zones){
      const index = asset.zones.indexOf(z);
      if (capAmount && index > capAmount) {
        returnString += `+${asset.zones.length - capAmount}`;
        break;
      }

      returnString += `${z.name}${index + 1 === asset.zones.length ? '': ', ' }`;
    }

    return returnString;
  }

}
