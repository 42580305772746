<p-dialog
    [style]="artifactType === 'Document' ? documentDialogStyle : {}"
    [modal]="true"
    [(visible)]="showDialog"
    appendTo="body"
    [dismissableMask]="true"
    header="document"
>
    <ng-template pTemplate="header">
        <div class="show-document-dialog-header">
            <div>
                <p class="p-dialog-title">
                    <i
                        [class]="
                            (artifactType | artifactTypeIcon) + ' large-icon'
                        "
                    ></i>
                    {{ artifactType }}
                </p>
                {{ artifact?.name }}
            </div>
            <button
                class="p-button-rounded p-button-text p-button-plain p-mr-1"
                icon="pi pi-external-link"
                pButton
                (click)="freyaHelper.openInNewTab(artifactSrc)"
            ></button>
        </div>
    </ng-template>
    <iframe
        *ngIf="artifactType === 'Document'"
        [src]="artifactSrc | trustUrl"
        frameborder="0"
        width="100%"
        height="99%"
    ></iframe>
    <img *ngIf="artifactType === 'Image'" [src]="artifactSrc" />
</p-dialog>
