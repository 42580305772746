<p-multiSelect
    [options]="roles"
    [(ngModel)]="selectedRoles"
    (ngModelChange)="onChange(selectedRoles)"
    class="full-width multiselect-custom"
    optionLabel="name"
    defaultLabel="Select Roles"
    maxSelectedLabels="1"
    selectedItemsLabel="{0} Roles Selected"
    [autofocusFilter]="!responsiveHelper.isSmallScreen"
    data-cy="select-roles-multi-select-field"
    appendTo="body"
></p-multiSelect>