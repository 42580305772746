import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { CalendarEventDetailsGQL, CalendarEventDetailsQueryVariables, CalendarEventForScheduleFragment, CalendarEventDetailsQuery } from '../../../generated/graphql.generated';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-book-off-details',
  templateUrl: './book-off-details.component.html',
  styleUrls: ['./book-off-details.component.scss']
})
export class BookOffDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() bookOffEvent: CalendarEventForScheduleFragment;

  eventQueryRef: QueryRef<CalendarEventDetailsQuery, CalendarEventDetailsQueryVariables>;

  eventActionsVisible = false;

  eventActions: MenuItem[] = [
    {
      id: 'edit',
      label: 'Edit',
      command: () => this.openEdit(),
    },
    {
      id: 'delete',
      label: 'Delete',
      command: () => this.openDelete(),
    },
  ];

  subs = new SubSink();

  constructor(
    private permissionHandler: PermissionService,
    private freyaMutate: FreyaMutateService,
    private calendarEventDetailsGQL: CalendarEventDetailsGQL,
  ) { }

  ngOnInit(): void {
    this.setPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('bookOffEvent' in changes) {
      this.retrieveEvent();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setPermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions([ 'calendarEvents.edit' ]).subscribe((res) => {
      const [ pEditEvents ] = res as boolean[];
      const editAction = this.eventActions.find((a) => a.id === 'edit');
      const deleteAction = this.eventActions.find((a) => a.id === 'delete');

      editAction.visible = Boolean(pEditEvents);
      deleteAction.visible = Boolean(pEditEvents);

      this.eventActions = [ ...this.eventActions ];;

      this.eventActionsVisible = this.eventActions.some((a) => a.visible);
    });
  }

  openDelete(){
    this.freyaMutate.openDeleteObject({
      objectId: this.bookOffEvent.id,
      objectName: this.bookOffEvent.title,
      objectType: 'calendarEvent',
    });
  }

  openEdit() {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'bookOff',
      object: this.bookOffEvent,
    });
  }

  retrieveEvent() {

    if (this.eventQueryRef) {
      this.eventQueryRef.refetch({ calendarEventId: this.bookOffEvent.id });
      return;
    }

    this.eventQueryRef = this.calendarEventDetailsGQL.watch(
      { calendarEventId: this.bookOffEvent.id },
      { fetchPolicy: 'cache-and-network' },
    );

    this.subs.sink = this.eventQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.bookOffEvent = cloneDeep(res.data.calendarEvents.events[0]);
    });

  }
}
