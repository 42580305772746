<div
    class="layout-wrapper"
    [ngClass]="{
        'layout-sidebar': app.menuMode === 'sidebar',
        'layout-static': app.menuMode === 'sidebar' && sidebarStatic,
        'layout-horizontal': app.menuMode === 'horizontal',
        'layout-rightpanel-active': rightPanelActive,
        'layout-slim': app.menuMode === 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-estimatingpanel-active':estimatingPanelActive,
        'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple
    }"
    [attr.colorScheme]="colorSchemeService.colorScheme.value"
    [class]="'layout-menu-' + colorSchemeService.colorScheme.value + ' layout-topbar-' + colorSchemeService.colorScheme.value"
    (click)="onLayoutClick()"
>
    <app-topbar
        [sidebarStatic]="sidebarStatic"
        [sidebarMobileActive]="staticMenuMobileActive"
    ></app-topbar>

    <app-rightpanel></app-rightpanel>

    <app-estimating-panel></app-estimating-panel>

    <div
        #layoutMain
        class="layout-main"
        (scroll)="checkIfScrolled($event)"
        [class.rightPanelOpen]="rightPanelActive"
        [class.estimatingPanelOpen]="estimatingPanelActive"
    >

        <p-scrollTop
            target="parent"
            [threshold]="100"
            [ngClass]="{
                'scroll-top-default': true,
                'scroll-top-main': !rightPanelActive && !estimatingPanelActive,
                'scroll-top-estimatepanel': estimatingPanelActive,
                'scroll-top-estimatepage': freyaHelper.onTheJobPage,
                'scroll-top-editbarvisible': editBarVisible,
                'scroll-top-rightpanel': rightPanelActive,
                'scroll-top-keyboardopen': responsiveHelper.keyboardOpen
            }"
        ></p-scrollTop>

        <div role="alert" class="p-messages p-component notice-message"
            *ngIf="notice"
        >
            <div role="alert" class="p-message p-message-{{ notice.severity }}">
                <div class="p-message-wrapper">
                    <span class="p-message-icon pi {{ notice.icon || 'pi-exclamation-triangle' }}"></span>
                    <div class="p-message-text">
                        <span class="p-message-summary"
                            [innerHTML]="notice.summary | markdown | async"
                        ></span>
                        <span class="message-detail"
                            [innerHTML]="notice.detail | markdown | async"
                        >
                        </span>
                    </div>
                </div>
                <button type="button" pButton class="p-ripple p-element p-message-close p-link"
                    (click)="closeNotice()"
                >
                    <i class="p-message-close-icon pi pi-times"></i>
                    <span class="p-ink"></span>
                </button>
            </div>
        </div>

        <div class="layout-content forced-bottom-padding">
            <router-outlet></router-outlet>
        </div>

        <!-- <app-footer></app-footer> -->
    </div>

    <div class="layout-mask modal-in" (click)="dismissRightPanels()"></div>
</div>