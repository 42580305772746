import { Component, Input, OnInit } from '@angular/core';

import { BaseZoneFragment } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-zone-chip',
  templateUrl: './zone-chip.component.html',
  styleUrls: ['./zone-chip.component.scss']
})
export class ZoneChipComponent implements OnInit {

  @Input() zone: BaseZoneFragment;

  constructor() { }

  ngOnInit(): void {
  }

}
