import { Component, OnInit } from '@angular/core';

import { text } from '../../global.constants';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {

  company = text.companyName;
  project = text.projectName;

  constructor() { }

  ngOnInit(): void {
  }

  reload(){
    document.location.reload();
  }

}
