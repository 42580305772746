import { NgZone } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { buffer, debounceTime } from 'rxjs/operators';

type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;

/**
 * This will capture all responses, and return it in an Array every 2 secs
 *
 * @param debounce Time in miliseconds to wait for updates
 * @returns Array of observable updates
 */
export const bufferDebounce: BufferDebounce = debounce => source =>
  new Observable(observer =>
    source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
      next(x) {
        observer.next(x);
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      },
  })
);

/**
 * Makes sure all lifecycle hooks of an Observable are running in the NgZone.
 * https://www.acagroup.be/en/blog/how-to-use-the-broadcastchannel-api-angular/
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => new Observable( (observer) => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
}
