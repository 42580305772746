
<p *ngIf="data.description" class="description">{{ data.description }}</p>

<form [formGroup]="form" (ngSubmit)="submit()">

	<p-listbox  *ngIf="!loading; else listloading"
		[options]="availableZones"
		formControlName="selectedZone"

		pAutoFocus
		[autofocus]="true" 
		[listStyle]="{'max-height':'250px', width: '300px'}"

		dataKey="id"
		optionLabel="name"
		[filter]="availableZones?.length > 5"
		filterBy="search"
	>

		<ng-template let-item pTemplate="item">
			<div class="area-item">
				<div>{{ item?.name }}</div>
				<div class="space-between">
					<small *ngIf="item?.parent?.name">
						{{ item?.parent?.name }}
					</small>
					<small *ngIf="item.closestArea?.distance">
						{{ item.closestArea.distance | distance : freyaHelper.getUnits() }}
					</small>
				</div>
			</div>
		</ng-template>
	</p-listbox>

	<p-message
		*ngIf="data.showAreaWarning && form.value?.selectedZone && form.value.selectedZone.type !== 'area'"
		[style]="{ 'max-width': '280px', margin: '10px' }"
		severity="warn"
		text="You are currently selecting a {{ form.value.selectedZone.type }} zone. Select an area to make bookings and add charges.">
	</p-message>

	<div class="ui-dialog-footer">

		<span class="must-select" *ngIf="!data.showCancel">You must select an area to continue</span>
		<button
			*ngIf="data.showCancel"
			pButton
			class="p-button-text"
			type="button"
			label="Cancel"
			icon="pi pi-times"
			(click)="close()"
		></button>
		<button
			[disabled]="!form.valid"
			class="p-button-warning"
			type="submit"
			pButton
			icon="pi pi-check"
			label="Set {{ form.get('selectedZone').value?.type || 'area' }}"
			data-cy="disable-restriction-submit-button"
		></button>
	</div>
</form>

<ng-template #listloading>
    <p-skeleton width="100%" height="200px"></p-skeleton>
</ng-template>
