/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

export enum FIELD_TYPE {
    BOOLEAN = 'boolean',
    STRING = 'string',
    NUMBER = 'number',
    INTEGER = 'integer',

    // An object that is typed or in json format. Must match AJV format on field.
    OBJECT = 'object',
  }

export type FieldValue = string | any | number | boolean ;

export interface ProfileField {
  id: string;
  value: FieldValue;
}

export interface ProfileFieldArray {
  id: string;
  value: any[];
}

export interface ListFieldsFilter {
  default?: FieldValue;
  hasAttributes?: string[];
  search?: string;
  ids?: string;
  names?: string[];
  namespaces?: string[];
  key?: string;
  showDeleted?: boolean;
  roles?: string[];
  labels?: string;
  allLabels?: string;
}

export interface ListFieldsInput {
    filter?: ListFieldsFilter;
    users?: string[]; // User Ids
    objects?: string[]; // Objects Ids
    objectLabels?: string[]; // Node type in the database eg. 'Job'
    showAllUsers?: boolean;
    ignoreDefaults?: boolean;
    sort?: string;
    skip?: number;
    limit?: number;
    limitValues?: number;
    skipValues?: number;
}

export interface FieldObjectValue<T = FieldValue> {
  objectId: string;
  value: T;
  isDefault: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface Field<T = FieldValue> {
  id: string;
  name: string;
  type: FIELD_TYPE;
  default: T;
  format: string;
  attributes?: string[];
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  value?: FieldObjectValue<T>;
  values: FieldObjectValue<T>[];
}

export type UserField<T = FieldValue> = Field<T> & { value?: FieldObjectValue<T> };

export interface ListFieldsOutput<T = FieldValue> {
  fields: {
    fields: Field<T>[];
  };
}

export const listFields = gql`
query listFields(
  $filter: FieldFilter
  $users: [ String! ]
  $objects: [ String ]
  $objectLabels: [ String ]
  $showAllUsers: Boolean
  $ignoreDefaults: Boolean
  $sort: String
  $skip: Int
  $limit: Int
  $limitValues: Int
  $skipValues: Int
) {

  fields(
    filter: $filter
    users: $users
    objects: $objects,
    objectLabels: $objectLabels,
    showAllUsers: $showAllUsers
    ignoreDefaults: $ignoreDefaults
    sort: $sort
    skip: $skip
    limit: $limit
    limitValues: $limitValues
    skipValues: $skipValues
  ) {
    fields {
      id
      name
      type
      default
      # format
      attributes
      createdAt
      updatedAt
      deletedAt
      values {
        objectId
        value
        isDefault
        createdAt
        updatedAt
      }
    }
  }
}
`;

export interface SetFieldValuesInput {
  fields: SetFieldValueInput[];
  objects: string[];
}

export interface SetFieldValueResult {
  fields: Field[];
}

export const setFieldValues = gql`
mutation setFieldValues(
  $objects: [ String! ]!
  $fields: [  SetFieldValueInput! ]!
) {

  setFieldsValues(
    fields: $fields
    objects: $objects
  ) {
    fields {
      id
      values {
        objectId
        value
        isDefault
        createdAt
        updatedAt
      }
    }
  }
}
`;


export interface SetFieldValueInput {
  fieldId: string;
  value: FieldValue;
}

export const setUsersFields = gql`
mutation setUsersFields(
  $users: [ String! ]!
  $fields: [  SetFieldValueInput! ]!
) {

  setFieldsValues(
    fields: $fields
    users: $users
  ) {
    fields {
      id
      values {
        objectId
        value
        isDefault
        createdAt
        updatedAt
      }
    }
  }
}
`;
