import { Pipe, PipeTransform } from '@angular/core';

import { MutateObjectElement } from './mutate-object/mutate-object.component';

@Pipe({
  name: 'filterRemovedSteps'
})
export class FilterRemovedStepsPipe implements PipeTransform {

  transform(steps: MutateObjectElement[]): MutateObjectElement[] {
    return steps.filter((s) => !s.removed);
  }

}


