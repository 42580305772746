<div class="card logistics" *ngIf="logistics$ | async as logistics">
  <div class="dashboard-card-header p-mb-4">
    <div>
      <h4 class="p-mb-1">Logistics<app-error-icon *ngIf="logistics.error"></app-error-icon></h4>
      {{ logistics.period }}
    </div>
    <app-dashboard-info-v2>
      <div class="p-mb-2">
        <b>Distance Booked:</b> The sum of distance between locations for all events in "booked" status ending in the selected period.
      </div>
      <div class="p-mb-2">
        <b>Distance Completed:</b> The sum of distance between locations for all events in "completed" status ending in the selected period.
      </div>
      <div class="p-mb-2">
        <b>Travel Time Booked:</b> The sum of travel times between locations for all events in "booked" status ending in the selected period.
      </div>
      <div class="p-mb-2">
        <b>Travel Time Completed:</b> The sum of travel times between locations for all events in "completed" status ending in the selected period.
      </div>
      <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="logistics.queryParams" target="_blank">View Report</a>.
    </app-dashboard-info-v2>
  </div>
  <div class="stats wrap" [class.p-flex-column]="layoutSize === 'small'">
    <div>
      Distance Booked
      <app-dashboard-number
        [loading]="logistics.loading"
        [amount]="logistics.distanceBooked"
        mode="distance"
        [large]="layoutSize === 'small'"
        [units]="logistics.units"
        [params]="logistics.queryParams"
        ></app-dashboard-number>
      <app-delta [delta]="logistics.distanceBookedDelta" mode="distance" [units]="logistics.units"></app-delta>
    </div>
    <div>
      Distance Completed
      <app-dashboard-number
        [loading]="logistics.loading"
        [amount]="logistics.distanceCompleted"
        mode="distance"
        [large]="layoutSize === 'small'"
        [units]="logistics.units"
        [params]="logistics.queryParams"
        ></app-dashboard-number>
      <app-delta [delta]="logistics.distanceCompletedDelta" mode="distance" [units]="logistics.units"></app-delta>
    </div>
    <div>
      Travel Time Booked
      <app-dashboard-number
        [loading]="logistics.loading"
        [amount]="logistics.travelTimeBooked"
        mode="duration"
        [large]="layoutSize === 'small'"
        [params]="logistics.queryParams"
        ></app-dashboard-number>
      <app-delta [delta]="logistics.travelTimeBookedDelta" mode="duration"></app-delta>
    </div>
    <div>
      Travel Time Completed
      <app-dashboard-number
        [loading]="logistics.loading"
        [amount]="logistics.travelTimeCompleted"
        mode="duration"
        [large]="layoutSize === 'small'"
        [params]="logistics.queryParams"
        ></app-dashboard-number>
      <app-delta [delta]="logistics.travelTimeCompletedDelta" mode="duration"></app-delta>
    </div>
  </div>
</div>
