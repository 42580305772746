import { Component, Input } from '@angular/core';

import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';

@Component({
  selector: 'app-overview-chip',
  standalone: true,
  imports: [
    FreyaCommonModule
  ],
  templateUrl: './overview-chip.component.html',
  styleUrl: './overview-chip.component.scss'
})
export class OverviewChipComponent {
  @Input() icon?: string;
  @Input() value?: string;
  @Input() label?: string;
}
