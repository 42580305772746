import { Component, OnDestroy, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { GoogleHelperService } from 'src/app/services/google-helper.service';

import { InputText } from 'primeng/inputtext';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { Jobv2ManuallyAddLocationComponent } from '../jobv2-manually-add-location/jobv2-manually-add-location.component';
import { SubSink } from 'subsink';
import { LocationWithHowSetIndicator, jobToolFeature } from '../../job-tool.reducer';
import { Jobv2LocationsDetailsComponent } from '../jobv2-create-locations-details/jobv2-create-locations-details.component';
import { DialogService } from 'primeng/dynamicdialog';
import { AddableLocation, AddableLocations } from '../jobv2-interfaces';
import { LocationCreateBase } from 'graphql.generated';
import { JOB_CREATE_INDEXES } from 'src/app/global.constants';

@Component({
    selector: 'app-jobv2-additional-locations',
    standalone: true,
    imports: [
        FreyaCommonModule,
        NgxGpAutocompleteModule,
        Jobv2ManuallyAddLocationComponent,
        Jobv2LocationsDetailsComponent,
    ],
    templateUrl: './jobv2-additional-locations.component.html',
    styleUrls: ['./jobv2-additional-locations.component.scss']
})
export class Jobv2AdditionalLocationsComponent implements OnInit, OnDestroy {
    @ViewChild('googleAutocomplete') autocompleteRef: NgxGpAutocompleteDirective;
    @ViewChild('addLocation') addLocationManuallyRef: Jobv2ManuallyAddLocationComponent;
    @ViewChild(InputText) locationInputRef: InputText;
    @Input() locationType: string;
    @Input() locationData: LocationCreateBase;

    constructor(
        private store: Store,
        public googleHelper: GoogleHelperService,
        public dialogService: DialogService,
    ) { }

    private subs = new SubSink();

    locationsInput$ = this.store.select(jobToolFeature.selectLocationsInputs);
    addableAdditionalLocations$ = this.store.select(jobToolFeature.selectAddableAdditionalLocations);

    locationTypeToOpenInManual: string;

    locations: LocationWithHowSetIndicator;
    addableAdditionalLocations: AddableLocations;
    additionalStopsSelectedOption: AddableLocation;
    address: string;

    jobCreateIndexes = JOB_CREATE_INDEXES;

    ngOnInit(): void {
        this.subs.sink = this.locationsInput$.subscribe(locationsInput => {
            this.locations = cloneDeep(locationsInput);
            this.address = this.locations[this.locationType]?.addressLineOne;
        });

        this.subs.sink = this.addableAdditionalLocations$.subscribe(addableAdditionalLocations => {
            this.addableAdditionalLocations = cloneDeep(addableAdditionalLocations);
            this.additionalStopsSelectedOption = {
                key: this.locationType,
                label: this.locationType,
            };
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public handleAddressChange(address: google.maps.places.AutocompletePrediction, locationType: string) {
        if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
            this.addLocationManuallyRef.formType = locationType;
            this.addLocationManuallyRef.open(address);
            return;
        }

        const location = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);
        const areaCode = location.areaCode.replace(' ', '');

        const locationWithFormattedAreaCode = cloneDeep(location);
        locationWithFormattedAreaCode.areaCode = areaCode;

        this.store.dispatch(JobCreateLocationsActions.locationSetAutocomplete({
            locationType: locationType,
            location: {
                ...locationWithFormattedAreaCode,
                public: false,
            },
        }));
    }

    openManualLocation(locationType: string) {
        this.locationTypeToOpenInManual = locationType;
        this.addLocationManuallyRef.open();
        this.addLocationManuallyRef.formType = locationType;
    }

    handleDropdownChange(event: any, previousLocationKey: string) {
        const newLocationKey = event.value.key;

        if (newLocationKey !== previousLocationKey) {
            this.onAdditionalStopTypeSelected(newLocationKey, previousLocationKey);
        }
    }

    onAdditionalStopTypeSelected(newLocationKey: string, previousLocationKey: string) {
        this.store.dispatch(JobCreateLocationsActions.additionalLocationChangedType({
            initialLocationType: previousLocationKey,
            updatedLocationType: newLocationKey,
            location: {},
        }))
    }

    onLocationDelete( locationType: string ) {
        this.store.dispatch(JobCreateLocationsActions.additionalLocationRemoved({
            locationType,
        }));
    }

    getTabIndex(locationType: string, addressKey: string): number | undefined {
        return this.jobCreateIndexes?.[locationType]?.[addressKey];
      }

}
