import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { AddLocationType } from 'src/app/global.constants';

import { CANADA, countries, regex, USA } from 'src/app/global.constants';
import { Address, GoogleHelperService } from 'src/app/services/google-helper.service';
import { Store } from '@ngrx/store';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { SharedModule } from 'src/app/shared/shared.module';
import { jobToolFeature, Modes } from '../../job-tool.reducer';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-jobv2-manually-add-location',
  standalone: true,
    imports: [
        FreyaCommonModule,
        ReactiveFormsModule,
        LibModule,
        SharedModule,
    ],
  templateUrl: './jobv2-manually-add-location.component.html',
  styleUrls: ['./jobv2-manually-add-location.component.scss']
})
export class Jobv2ManuallyAddLocationComponent implements OnInit {

    @Input() formType: AddLocationType | string;

    visible = false;
    countries = countries;

    manualLocationForm: UntypedFormGroup = new UntypedFormGroup({
      streetNumber: new UntypedFormControl('', [Validators.required]),
      streetName: new UntypedFormControl('', [Validators.required]),
      areaCode: new UntypedFormControl(undefined, [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      city: new UntypedFormControl(undefined, [Validators.required]),
      jurisdiction: new UntypedFormControl(undefined, [Validators.required]),
    });

    constructor(
      private store: Store,
      private googleHelper: GoogleHelperService,
    ) { }

    ngOnInit(): void {}

    open(address?: Address) {
      this.primeForm(address);
      this.visible = true;
    }

    primeForm(address?: Address) {
      if (!address) {
        this.manualLocationForm.reset();
        return;
      }

      const formattedAddress = this.googleHelper.convertGoogleLocationToCreateLocationInput(address as Address);

      this.manualLocationForm.reset({
        streetName: this.googleHelper.findAddressComponent(address, 'route'),
        streetNumber: this.googleHelper.findAddressComponent(address, 'street_number'),
        areaCode: formattedAddress.areaCode,
        country: formattedAddress.country,
        jurisdiction: formattedAddress.countryJurisdiction,
        city: formattedAddress.city
      });
      this.changeRegexValidator();
      this.manualLocationForm.markAllAsTouched();
    }

    changeRegexValidator() {
      if (this.manualLocationForm.value.country === CANADA) {
        this.manualLocationForm.controls.areaCode.setValidators([Validators.required, Validators.pattern(regex.areaCodeCanada)]);
      } else if (this.manualLocationForm.value.country === USA) {
        this.manualLocationForm.controls.areaCode.setValidators([Validators.required, Validators.pattern(regex.areaCodeUSA)]);
      }

      this.manualLocationForm.controls.areaCode.setValue(this.manualLocationForm.value.areaCode);
    }

    onLocationInputBlur() {
      this.close();
    }

    addLocation() {
      const manualLocation = this.manualLocationForm.value;
      this.store.dispatch(JobCreateLocationsActions.locationSetManually({
        locationType: this.formType,
        location: {
          addressLineOne: this.getAddressLineOne(manualLocation),
          addressLineTwo: "",
          areaCode: manualLocation.areaCode,
          city: manualLocation.city,
          country: manualLocation.country,
          public: false,
        },
      }));
      this.close();
    }

    getAddressLineOne(locationFields) {
      const formattedAddress = `${locationFields.streetNumber || ''} ${locationFields.streetName || ''}, ${locationFields.city || ''} `
        + `${locationFields.jurisdiction || ''} ${locationFields.areaCode || ''} ${locationFields.country || ''}`;
      return formattedAddress;
    }

    close() {
      this.visible = false;
    }
}
