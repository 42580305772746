import { Component, Input, OnInit } from '@angular/core';
import { RawUser } from '@karve.it/interfaces/auth';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { EditUserPasswordGQL } from 'src/generated/graphql.generated';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss','../../dynamic-dialog-styling.scss']
})
export class ChangeUserPasswordComponent implements OnInit {

  @Input() user: RawUser;

  isLoading = false;
  isValid = false;
  password = '';

  constructor(
    private editUserGQL: EditUserPasswordGQL,
    private localNotify: FreyaNotificationsService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
  }

  ngOnInit(): void {
    this.user = this.config.data?.user;
  }

  closeDialog() {
    this.ref.close();
  }

  editUserPassword() {
    this.isLoading = true;
    this.editUserGQL.mutate({
      id: this.user.id,
      password: this.password,
    }).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'User password updated' });
      this.isLoading = false;
      this.closeDialog();
    }, (err) => {
      this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to update user password', detail: err });
      this.isLoading = false;
    }
    );
  }


}
