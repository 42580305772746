import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-freya-progress-bar',
  templateUrl: './freya-progress-bar.component.html',
  styleUrls: ['./freya-progress-bar.component.scss']
})
export class FreyaProgressBarComponent implements OnInit, OnDestroy {

  @Input() status: 'failed' | 'sent';
  @Input() showValue = false;

  progress = 0;

  progressInterval;

  constructor() { }

  ngOnInit(): void {
    this.start();
  }

  ngOnDestroy(): void {
    clearInterval(this.progressInterval);
  }

  start(){
    this.increment();
  }

  increment(){
    setTimeout(() => {
      if (this.progress >= 90) { return; }
      this.progress += Math.floor(Math.random()) + 1;
      this.increment();
    }, 200);
  }

  complete(){
    this.progress = 100;
  }

  fail(){
    this.progress = -1;
  }

}
