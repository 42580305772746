import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { CreateTransactionInput, createTransactionMutation, CreateTransactionOutput, DeleteTransactionsInput, deleteTransactionsMutation, DeleteTransactionsOutput, GenerateTransactionQuery, GenerateTransactionQueryInput, ListTransactionsFunctionOutput, TransactionsInput, UpdateTransactionInput, updateTransactionMutation, UpdateTransactionOutput } from '@karve.it/interfaces/transactions';

import { KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    constructor(public apollo: PlusApollo,) { }

    listTransactions(input: TransactionsInput, generateInput: GenerateTransactionQueryInput) {
        return this.apollo.query<ListTransactionsFunctionOutput>({
            query: GenerateTransactionQuery(generateInput),
            variables: {
                ...input
            },
        });
    }

    watchTransactions(input: TransactionsInput, generateInput: GenerateTransactionQueryInput, opts?: KarveWatchRequestOptions) {
        return this.apollo.watchQuery<ListTransactionsFunctionOutput>({
            query: GenerateTransactionQuery(generateInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createTransaction(input: CreateTransactionInput) {
        return this.apollo.mutate<CreateTransactionOutput>({
            mutation: createTransactionMutation,
            variables: {
                ...input
            }
        });
    }

    updateTransaction(input: UpdateTransactionInput) {
        return this.apollo.mutate<UpdateTransactionOutput>({
            mutation: updateTransactionMutation,
            variables: {
                ...input
            }
        });
    }

    deleteTransactions(input: DeleteTransactionsInput) {
        return this.apollo.mutate<DeleteTransactionsOutput>({
            mutation: deleteTransactionsMutation,
            variables: {
                ...input
            }
        });
    }



}

