import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { clone } from 'lodash';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { SubSink } from 'subsink';

import { BaseReportFragment, ReportTypePageListGQL, ReportTypePageListQuery, ReportTypePageListQueryVariables, ZoneDir } from '../../../generated/graphql.generated';
import { AvailableZonesService } from '../../services/available-zones.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MarkdownHelperService } from '../../services/markdown.service';
import { ReportService } from '../../services/report.service';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';
import { ExportToSheetsDialogComponent } from '../export-to-sheets-dialog/export-to-sheets-dialog.component';

@Component({
  selector: 'app-report-type-page',
  templateUrl: './report-type-page.component.html',
  styleUrls: ['./report-type-page.component.scss']
})
export class ReportTypePageComponent implements OnInit, OnDestroy {

  @Input() reportTypeId: string;
  rt: ReportTypePageListQuery['reportTypes']['reportTypes'][number];
  subs = new SubSink();

  watchQuery: ReturnType<ReportTypePageListGQL['watch']>;
  reportsQueryHelper: WatchQueryHelper = {
    skip: 0,
    limit: 10,
    loading: true,
  };

  reports: BaseReportFragment[];

  generating = false;

  didRun = false;

  breadcrumb = [
    { label: 'Reports', routerLink: '/reports', queryParams: { reportType: 'static' } },
    { label: 'Loading...' },
  ];

  home = { icon: 'pi pi-home', routerLink: '/' };

  constructor(
    private reportTypePageList: ReportTypePageListGQL,
    private route: ActivatedRoute,
    private detailsHelper: DetailsHelperService,
    public currentZoneService: AvailableZonesService,
    private location: Location,
    public markdownHelper: MarkdownHelperService,
    private freyaMutate: FreyaMutateService,
    private dialogSvc: DialogService,
    private reportService: ReportService,
    private toast: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe((params) => {
      this.reportTypeId = params.reportTypeId;
      this.fetch();
    });
    this.didRun = false;

    this.subs.sink = this.detailsHelper.objectUpdated
      .pipe(filter((v) =>
        v.action === 'create'
        && v.type === 'Report'
        && v.update?.reportType?.id === this.reportTypeId
      ))
      .subscribe((res) => {
        this.fetch();
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.watchQuery;
  }

  exportToSheets() {
    this.dialogSvc.open(ExportToSheetsDialogComponent, {
      data: {
        reportType: this.rt,
      },
      header: 'Semi-live Export to Google Sheets',
    });
  }

  getVariables(): ReportTypePageListQueryVariables {
    return {
      filter: {
        ids: [ this.reportTypeId ],
        getDeleted: true,
        zoneDir: ZoneDir.Any,
      },
      reportsSkip: this.reportsQueryHelper.skip,
      reportsLimit: this.reportsQueryHelper.limit,
    };
  }

  retrieveMoreReports(event: LazyLoadEvent) {
    this.reportsQueryHelper.limit = event.rows;
    this.reportsQueryHelper.skip = event.first;
    this.fetch();
  }

  fetch() {
    if (!this.reportTypeId) { return; }

    if (this.watchQuery) {
      this.watchQuery.refetch(this.getVariables());
      return;
    }

    this.watchQuery = this.reportTypePageList.watch(
      this.getVariables(),
      {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
      }
    );

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.reportsQueryHelper.loading = res.loading;

      if (res.loading) { return; }

      this.reportsQueryHelper.loading = res.loading;
      const [rt] = res.data?.reportTypes?.reportTypes || [];
      this.rt = rt;
      this.reports = rt.reports.reports;
      console.log(this.reports);
      this.reportsQueryHelper.skip = rt.reports.skipped;
      this.reportsQueryHelper.limit = rt.reports.limited;
      this.reportsQueryHelper.total = rt.reports.total;

      this.setBreadcrumb();

      // console.log(rt);
      if (!this.reportsQueryHelper.loading && !rt) {
        console.warn(`Could not load report type, show 404`);
      }

      if (this.rt && this.route.snapshot.queryParams.run && !this.didRun) {

        /**
         * Set didRun to true and remove run from the current location state
         * this ensures we don't run another report after we hit the back button
         */
        this.didRun = true;
        const path = this.route.snapshot.url.map((s) => s.path).join('/');
        const queryParams = clone(this.route.snapshot.queryParams);
        delete queryParams.run;
        const query = Object.entries(queryParams)
          .map(([ key, value ]) => `${ encodeURIComponent(key) }=${ encodeURIComponent(value) }`)
          .join('&');


        this.location.replaceState(path, query);
        this.runReport();
      }
    });
  }

  select() {
    if (!this.rt) { return; }
    this.detailsHelper.detailsItem.next({ type: 'reportType', item: this.rt });
  }

  runReport() {
    if (!this.rt) { return; }

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'report',
      object: this.rt,
      additionalValues: [
        {
          property: 'navigate',
          value: false
        },
      ]
    });

    // this.subs.sink = this.reportService.createReport({
    //   reportType: this.rt,
    //   navigate: true,
    // })
    // .subscribe((res) => {
    //   this.generating = res.generating;
    // });
  }

  createScheduledReport() {

    if (!this.rt) { return; }

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'ScheduledReport',
      additionalValues: [
        {
          property: 'reportType',
          value: this.rt,
        }
      ],
    });

  }

  downloadCSV(report: BaseReportFragment) {
    this.reportService.downloadCSV(report)
    .catch((err) => {
      this.toast.addToast.next({
        severity: 'error',
        life: 6000,
        summary: 'Error downloading CSV',
        detail: err.message,
      });

      console.error(err);
    });

  }

  setBreadcrumb() {
    this.breadcrumb = [
      { label: 'Reports', routerLink: '/reports', queryParams: { reportType: 'static' } },
      { label: this.rt?.name },
    ];
  }

}
