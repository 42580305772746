import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { dayjs } from '@karve.it/core';
import { RawUser } from '@karve.it/interfaces/auth';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { ValidationItem } from 'src/app/app.component';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { SubSink } from 'subsink';

import { AvailabilityTemplate, CreateAvailabilityTemplateGQL, CreateAvailabilityTemplateInput, UpdateAvailabilityTemplateGQL, UpdateAvailabilityTemplateInput } from '../../../generated/graphql.generated';
import { daysOfTheWeek } from '../../global.constants';
import { UpdateAvailabilityBlockFunctionInput } from '../../interfaces/availability.v2';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

export interface CreateTemplateInput {
  name: string;
  blocks: TimeBlock[];
  timezone: string;
}

export interface TimeBlock {
  start: number;
  end: number;
}

export interface CreateTemplateValidation {
  name: ValidationItem;
  blocks: ValidationItem;
  timezone: ValidationItem;
}

@Component({
  selector: 'app-mutate-availability-template',
  templateUrl: './mutate-availability-template.component.html',
  styleUrls: ['./mutate-availability-template.component.scss']
})
export class MutateAvailabilityTemplateComponent implements OnDestroy {

  @ViewChild('co') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('dates') datesRef: TemplateRef<any>;
  @ViewChild('availability') availabilityRef: TemplateRef<any>;
  @ViewChild('zones') zonesRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() template: AvailabilityTemplate;

  subs = new SubSink();

  steps: MutateObjectElement[];

  // AVAILABILITY
  // Predefined list of blocks that can be selected from
  blockList = [
    {
      start: 0,
      end: 0,
      custom: true,
    },
    { // 9-5
      start: 32400,
      end: 61200
    },
    { // 8-5
      start: 28800,
      end: 61200
    },
    { // 8-4
      start: 28800,
      end: 57600
    },
    { // 7 - 7
      start: 25200,
      end: 68400
    },
    { // 6 - 10
      start: 21600,
      end: 79200
    },
  ];

  // Selected Days of the Week
  selectedDays = [];
  selectedBlockOption = undefined;

  // Stores the Start/End for a custom block, if the user seleted custom
  customBlockStart: Date = cloneDeep(this.timezoneHelper.defaultDate);
  customBlockEnd: Date = cloneDeep(this.timezoneHelper.defaultDate);

  // Flag that checks to make sure the custom times entered are valid
  customTimesInvalid = false;

  daysOfTheWeek = daysOfTheWeek;

  // TEMPLATE
  templateForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(80)]),
    startDate: new UntypedFormControl(new Date(), [Validators.required]),
    endDate: new UntypedFormControl(undefined),
    monday: new UntypedFormControl([]),
    tuesday: new UntypedFormControl([]),
    wednesday: new UntypedFormControl([]),
    thursday: new UntypedFormControl([]),
    friday: new UntypedFormControl([]),
    saturday: new UntypedFormControl([]),
    sunday: new UntypedFormControl([]),
    zones: new UntypedFormControl([], [Validators.required])
  });
  templateFormValues = this.templateForm.value;

  constructor(
    // Helpers
    private localNotify: FreyaNotificationsService,
    private timezoneHelper: TimezoneHelperService,
    private detailsHelper: DetailsHelperService,
    // GQL
    private createTemplateGQL: CreateAvailabilityTemplateGQL,
    private updateTemplateGQL: UpdateAvailabilityTemplateGQL,
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createAvailabilityTemplate();
    } else if (this.mutateType === 'update') {
      this.updateAvailabilityTemplate();
    }
  }

  openDialog() {
    this.steps = [
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text' },
      {
        name: 'Dates', ref: this.datesRef, control: 'startDate', type: 'func',
        reviewFunc: (() =>
        (`${dayjs(this.templateForm.value.startDate).format('YYYY-MM-DD')} - 
          ${this.templateForm.value.endDate ? dayjs(this.templateForm.value.endDate).format('YYYY-MM-DD') : 'No End Date'}`)
        )
      },
      {
        name: 'Availability', ref: this.availabilityRef, control: 'monday', type: 'func',
        reviewFunc: (() => {
          const formValue = this.templateForm.value;
            if (this.availabilityInvalid()){
              return 'No Availability Added';
            }

            return (formValue.monday?.length ? 'Mon,' : '')
            + (formValue.tuesday?.length ? 'Tues,' : '') + (formValue.wednesday?.length ? 'Wed,' : '')
            + (formValue.thursday?.length ? 'Thurs,' : '') + (formValue.friday?.length ? 'Fri,' : '')
            + (formValue.saturday?.length ? 'Sat,' : '') + (formValue.sunday?.length ? 'Sun' : '');
        }),
        invalidFunc: () => (this.availabilityInvalid()),
      },
      { name: 'Areas', ref: this.zonesRef, control: 'zones', type: 'array' },
    ];

    if (this.mutateType === 'create') {
      this.templateForm.reset(this.templateFormValues);
      this.resetAddBlockVariables();
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  /**
   * Updates the end to match the start to make it faster to click through (don't have to start from zero)
   */
  setEndAsStart() {
    this.customBlockEnd = cloneDeep(this.customBlockStart);
  }

  setFormValues() {
    if (!this.template) { return; }
    this.templateForm.patchValue({
      name: this.template.name,
      startDate: new Date(`${this.template.startDate}T00:00`),
      endDate: this.template.endDate ? new Date(`${this.template.endDate}T00:00`) : undefined,
      monday: this.formatAvailabilityAsTimeBlocks(this.template.availability.monday),
      tuesday: this.formatAvailabilityAsTimeBlocks(this.template.availability.tuesday),
      wednesday: this.formatAvailabilityAsTimeBlocks(this.template.availability.wednesday),
      thursday: this.formatAvailabilityAsTimeBlocks(this.template.availability.thursday),
      friday: this.formatAvailabilityAsTimeBlocks(this.template.availability.friday),
      saturday: this.formatAvailabilityAsTimeBlocks(this.template.availability.saturday),
      sunday: this.formatAvailabilityAsTimeBlocks(this.template.availability.sunday),
      zones: this.template.zones || [],
    });
  }

  /**
   * Adds the selected timeblock to the template on the selected days of the week
   */
  addBlockToTemplate() {
    const blockToAdd = { start: this.selectedBlockOption.start, end: this.selectedBlockOption.end };

    if (this.selectedBlockOption.custom) {
      blockToAdd.start = this.timezoneHelper.convertDateToTime(this.customBlockStart, 'seconds');
      blockToAdd.end = this.timezoneHelper.convertDateToTime(this.customBlockEnd, 'seconds');

      // Verify this custom block is valid
      if (blockToAdd.start >= blockToAdd.end) {
        this.customTimesInvalid = true;
        return;
      } else {
        this.customTimesInvalid = false;
      }
    }

    // Loop through the days and add the new block
    for (const day of this.selectedDays) {
      const dayFormControl = this.templateForm.get(day.toLowerCase());

      // Block is already present on this day
      if (dayFormControl.value.find((block) => block.start === blockToAdd.start && block.end === blockToAdd.end)) {
        continue;
      }

      const valueToSet = [...dayFormControl.value, blockToAdd];

      dayFormControl.setValue(valueToSet);
    }

    this.resetAddBlockVariables();
  }

  /**
   * Resets the variables for adding blocks to the template
   */
  resetAddBlockVariables(){
    // Reset the add block variables
    this.selectedDays = [];
    this.selectedBlockOption = undefined;
    this.customBlockStart = cloneDeep(this.timezoneHelper.defaultDate);
    this.customBlockEnd = cloneDeep(this.timezoneHelper.defaultDate);
  }

  /**
   * Removes a block from a day
   *
   * @param day The control name for the day eg. 'monday'
   * @param block The block that shoud be removed
   */
  removeBlockFromDay(day: string, block: TimeBlock) {
    const dayFormControl = this.templateForm.get(day.toLowerCase());

    const valueToSet = dayFormControl.value.filter((val) => !(val.start === block.start && val.end === block.end));

    dayFormControl.setValue(valueToSet);
  }

  /**
   * Sets the custom end equal to the custom start so it is quicker to set the end time after
   */
  setEndToStart() {
    this.customBlockEnd = cloneDeep(this.customBlockStart);
  }

  /**
   * Create a new availability template based on the form values
   */
  createAvailabilityTemplate() {
    const val = this.templateForm.value;

    const input = this.getMutateInput();

    this.createTemplateGQL.mutate({ input }).subscribe((res) => {
      this.localNotify.success('Template created');
      this.detailsHelper.pushUpdate({
        id: res.data.createAvailabilityTemplate.id,
        type: 'AvailabilityTemplate',
        action: 'create'
      });
      this.mutateRef.closeDialog();
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create the template`,err);
    });
  }

  /**
   * Updates the template values using data from the form
   */
  updateAvailabilityTemplate() {
    const val = this.templateForm.value;

    const input: UpdateAvailabilityTemplateInput = {
      id: this.template.id,
      ...this.getMutateInput(),
    };

    this.updateTemplateGQL.mutate({ input }).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: this.template.id,
        type: 'AvailabilityTemplate',
        action: 'update'
      });
      this.mutateRef.closeDialog();
      this.localNotify.success('Template updated');
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update template`,err);

    });
  }

  /**
   * Gets the input for Create/Update actions
   */
  getMutateInput(): CreateAvailabilityTemplateInput {
    const formValue = this.templateForm.value;

    const input: CreateAvailabilityTemplateInput = {
      name: formValue.name,
      startDate: dayjs(formValue.startDate).format('YYYY-MM-DD'),
      endDate: formValue.endDate ? dayjs(formValue.endDate).format('YYYY-MM-DD') : undefined,
      availability: {
        monday: this.formatAvailabilityAsArray(formValue.monday),
        tuesday: this.formatAvailabilityAsArray(formValue.tuesday),
        wednesday: this.formatAvailabilityAsArray(formValue.wednesday),
        thursday: this.formatAvailabilityAsArray(formValue.thursday),
        friday: this.formatAvailabilityAsArray(formValue.friday),
        saturday: this.formatAvailabilityAsArray(formValue.saturday),
        sunday: this.formatAvailabilityAsArray(formValue.sunday),
      },
      zones: formValue.zones.map((z) => z.id),
    };

    return input;
  }

  /**
   * Reformats an array of timeblocks as a flat primitive array where every 2 elements is start/end (matches backend format)
   */
  formatAvailabilityAsArray(timeBlocks: TimeBlock[]): number[] {
    const availabilityArray = [];

    for (const block of timeBlocks) {
      availabilityArray.push(block.start);
      availabilityArray.push(block.end);
    }

    return availabilityArray;
  }

  /**
   * Reformats a flat availability array (backend format) into TimeBlock objects used for editing
   */
  formatAvailabilityAsTimeBlocks(availability: number[]): TimeBlock[] {
    const timeBlockArray = [];

    for (let i = 0; i < availability?.length; i += 2) {
      timeBlockArray.push({ start: availability[i], end: availability[i + 1] });
    }

    return timeBlockArray;
  }

  /**
   * Checks to see that there is availability on at least one day
   *
   * @returns True if there is no availability
   */
  availabilityInvalid() {
    const formVal = this.templateForm.value;
    if (
      !formVal.monday?.lenth &&
      !formVal.tuesday?.length &&
      !formVal.wednesday?.length &&
      !formVal.thurday?.length &&
      !formVal.friday?.length &&
      !formVal.saturday?.length &&
      !formVal.sunday?.length
    ) {
      this.templateForm.setErrors({availability: true});
      return true;
    }

    // If we have valid input remove the error
    if (this.templateForm?.errors){
      delete this.templateForm.errors.availability;
    }
    return false;
  }
}
