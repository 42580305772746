<div>

  <div class="header-with-button">
    <h4>
      <i class="pi pi-history"></i> History
    </h4>
  </div>

  <ng-container *ngIf="!loading; else loadingCard">
    <div class="card p-mb-3">
      <div [innerHtml]="history?.text | markdown | async" class="p-mb-2"></div>
      <p>{{ history.createdAt | freyaDate : 'EEE MMM dd hh:mma' }}</p>
      <p *ngIf="history.ip">IP: {{ history.ip }}</p>
    </div>
    <p-tree [value]="variables" emptyMessage="No Variables"></p-tree>
  </ng-container>

</div>

<ng-template #loadingCard>
  <div class="card">
    <div class="p-p-2" *ngFor="let i of [ 0, 1, 2, 3 ]">
      <p-skeleton
        width="100%"
        height="16px"
        ></p-skeleton>
    </div>
  </div>
</ng-template>
