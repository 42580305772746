import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { dayjs } from '@karve.it/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { countries, estimating, DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS } from 'src/app/global.constants';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';

import { AddLocationComponent, AddLocationFormType } from '../../shared/add-location/add-location.component';

import { EMAIL_VALIDATION_PATTERN } from '../../shared/pattern.validator';
import { EstimateInfo, googleLocationOptions } from '../estimate.interfaces';


@Component({
  selector: 'app-estimating-notes',
  templateUrl: './estimating-notes.component.html',
  styleUrls: ['./estimating-notes.component.scss']
})
export class EstimatingNotesComponent implements OnInit, OnDestroy {

  @ViewChild('addLocation') addLocationRef: AddLocationComponent;

  // TODO: Break into forms based on the section they are in
  notesForm: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', []),
    lastName: new UntypedFormControl('', []),
    phone: new UntypedFormControl('', [Validators.minLength(7)]),
    email: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATION_PATTERN)]),
    moveDate: new UntypedFormControl(undefined, []),
    time: new UntypedFormControl(undefined, []),
    flexibility: new UntypedFormControl(undefined, []),
    usedYMMBefore: new UntypedFormControl(undefined, []),
    usedMoverBefore: new UntypedFormControl(undefined, []),
    bedrooms: new UntypedFormControl(undefined, []),
    howHeard: new UntypedFormControl(undefined, []),

    partialMove: new UntypedFormControl(false, []),
    moreThan10Items: new UntypedFormControl({ value: false, disabled: true }, [],),

    // Whether the Job Needs these event types
    estimate: new UntypedFormControl(false, []),
    delivery: new UntypedFormControl(false, []),
    packing: new UntypedFormControl(false, []),
    moving: new UntypedFormControl(false, []),
    unpacking: new UntypedFormControl(false, []),

    startingPlaceId: new UntypedFormControl(undefined, []),
    startingAddress: new UntypedFormControl(undefined, []),
    startingUnit: new UntypedFormControl(undefined, []),
    startingDwellingType: new UntypedFormControl(undefined, []),
    startingStairs: new UntypedFormControl(undefined, []),
    startingElevators: new UntypedFormControl(undefined, []),
    startingElevatorsBookable: new UntypedFormControl(false, []),
    startingParking: new UntypedFormControl(undefined, []),
    startingSqft: new UntypedFormControl(undefined, []),

    // Set By Address
    startingAreaCode: new UntypedFormControl('', []),
    startingCountry: new UntypedFormControl('', []),
    startingJurisdiction: new UntypedFormControl('', []),
    startingCity: new UntypedFormControl('', []),

    destinationPlaceId: new UntypedFormControl(undefined, []),
    destinationAddress: new UntypedFormControl(undefined, []),
    destinationUnit: new UntypedFormControl(undefined, []),
    destinationDwellingType: new UntypedFormControl(undefined, []),
    destinationStairs: new UntypedFormControl(undefined, []),
    destinationElevators: new UntypedFormControl(undefined, []),
    destinationElevatorsBookable: new UntypedFormControl(undefined, []),
    destinationParking: new UntypedFormControl(undefined, []),
    destinationSqft: new UntypedFormControl(undefined, []),

    // Set By Address
    destinationAreaCode: new UntypedFormControl('', []),
    destinationCountry: new UntypedFormControl('', []),
    destinationJurisdiction: new UntypedFormControl('', []),
    destinationCity: new UntypedFormControl('', []),

    // items: new FormControl('', []),
    // bedroomOne: new FormControl('', []),
    // bedroomTwo: new FormControl('', []),
    // kitchen: new FormControl('', []),
    // bathroom: new FormControl('', []),
    // patioOrBalcony: new FormControl('', []),
    // storage: new FormControl('', []),
    // garageOrShed: new FormControl('', []),
    // specilaityItems: new FormControl('', []),
    // disassembly: new FormControl('', []),
    // wardrobeBoxes: new FormControl('', []),
    inventory: new UntypedFormControl('', []),
    needMovingSupplies: new UntypedFormControl('', []),
    needFinance: new UntypedFormControl('', []),
  });

  notesFormValue = this.notesForm.value;

  dwellingTypes = estimating.dwellingTypes;
  stairsDropDownOptions = estimating.stairsDropDownOptions;
  elevatorDropDownOptions = estimating.elevatorDropDownOptions;
  bedroomDropDownOptions = estimating.bedroomDropdownOptions;

  options = googleLocationOptions;

  // Dialog Variables
  syncDialogOpen = false;
  syncFromOtherPage = false;

  closeDialogOpen = false;

  closedPanels = [false, true, true, true, true, true];

  howHearAboutUs = DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS;

  // MANUAL LOCATIONS
  attemptedAutocomplete = false;
  countries = countries;

  constructor(
    private estimateHelper: EstimateHelperService,
    public appMain: AppMainComponent,
    public router: Router,
    public googleHelper: GoogleHelperService,
    private localNotify: FreyaNotificationsService
  ) { }

  ngOnInit(): void {
    const time = new Date();
    time.setHours(6);
    time.setMinutes(0);

    this.notesForm.controls.time.setValue(time);

    this.restore();

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.cache();
  }

  openSyncDialog() {
    if (this.router.url.includes('estimating')) {
      this.syncDialogOpen = true;
      return;
    }

    this.router.navigate(['estimating'], { queryParams: { step: 0 } }).then(() => {
      this.syncDialogOpen = true;
    });
  }

  // Clear the form values and the local storage values.
  clear() {
    sessionStorage.removeItem('estimating-notes');
    this.notesForm.reset(this.notesFormValue);
  }

  // Set the Estimating Notes form based on the values from local storage.
  restore() {
    if (sessionStorage.getItem('estimating-notes')) {
      this.notesForm.patchValue(JSON.parse(sessionStorage.getItem('estimating-notes')));
      if (this.notesForm.value.moveDate) { // Only Set the date if it's been set previously
        this.notesForm.controls.moveDate.setValue(new Date(this.notesForm.value.moveDate));
      }
      if (this.notesForm.value.time) { // Only Set the date if it's been set previously
        this.notesForm.controls.time.setValue(new Date(this.notesForm.value.time));
      }

      // TODO: Revise this logic after the forms are broken out
      const val = this.notesForm.value;

      if (val.startingAddress) {
        this.closedPanels[2] = false;
      }

      if (val.destinationAddress) {
        this.closedPanels[3] = false;
      }

      if (val.estimate || val.delivery || val.packing || val.moving || val.unpacking) {
        this.closedPanels[1] = false;
      }
    }
  }

  // Store Estiamting Notes in Local Storage.
  cache() {
    sessionStorage.setItem('estimating-notes', JSON.stringify(this.notesForm.value));
  }

  // Pass Estiamting Notes to the main estimating form.
  sync() {
    const val = this.notesForm.value;

    const required = [];
    if (val.estimate) { required.push('estimating'); }
    if (val.delivery) { required.push('delivery'); }
    if (val.packing) { required.push('packing'); }
    if (val.moving) { required.push('moving'); }
    if (val.unpacking) { required.push('unpacking'); }

    // Parse the data into EstimateInfo
    const data: EstimateInfo = {
      customerInfo: {
        email: val.email,
        firstName: val.firstName,
        lastName: val.lastName,
        howDidTheyHearAboutUs: val.howHeard,
        phone: val.phone,
        // usedUsBefore: val.usedYMMBefore,
        // usedOtherBefore: val.usedMoverBefore,
        requiredEvents: required,
      },
      jobInfo: {
        // @ts-ignore
        start: {
          //   startingAreaCode,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          place_id: val.startingPlaceId,
          address: val.startingAddress,
          unit: val.startingUnit,
          dwellingType: val.startingDwellingType,
          stairs: val.startingStairs,
          elevators: val.startingElevators,
          elevatorsBookable: val.startingElevatorsBookable,
          parkingInformation: val.startingParking,
          sqft: val.startingSqft,
          bedrooms: val.bedrooms,

          partialMove: val.partialMove,
          moreThan10Items: val.moreThan10Items,

          areaCode: val.startingAreaCode,
          country: val.startingCountry,
          city: val.startingCity,
          jurisdiction: val.startingJurisdiction,
        },
        // @ts-ignore
        end: {
          //   startingAreaCode,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          place_id: val.destinationPlaceId,
          address: val.destinationAddress,
          unit: val.destinationUnit,
          dwellingType: val.destinationDwellingType,
          stairs: val.destinationStairs,
          elevators: val.destinationElevators,
          elevatorsBookable: val.destinationElevatorsBookable,
          parkingInformation: val.destinationParking,
          sqft: val.destinationSqft,

          areaCode: val.destinationAreaCode,
          country: val.destinationCountry,
          city: val.destinationCity,
          jurisdiction: val.destinationJurisdiction,
        }
      },
      inventoryInfo: {
        inventory: val.inventory,
      },
      bookingInfo: {},

      needsFinancing: val.needFinance,
      preferredDate: val.moveDate ? dayjs(val.moveDate).format('YYYY-MM-DD') : undefined,
      preferredTime: val.time ? dayjs(val.time).format('hh:mm a') : undefined,
      flexibility: val.flexibility,
    };

    this.syncDialogOpen = false;
    this.estimateHelper.panelSync.next({ data });
    this.appMain.setEstimatingPanel(false);
  }

  // Gets the Location Info for the Input
  public handleAddressChange(address: any, tab: 'start' | 'end') {
    if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
      this.attemptedAutocomplete = true;
      this.addLocationRef.formType = tab;
      this.addLocationRef.open(address);
      return;
    }
    try {
      const location = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);

      if (tab === 'start') {
        this.notesForm.patchValue({
          startingPlaceId: address.place_id,
          startingAddress: location.addressLineOne,
          startingAreaCode: location.areaCode,
          startingCountry: location.country,
          startingJurisdiction: location.countryJurisdiction,
          startingCity: location.city,
        });
      } else if (tab === 'end') {
        this.notesForm.patchValue({
          destinationPlaceId: address.place_id,
          destinationAddress: location.addressLineOne,
          destinationAreaCode: location.areaCode,
          destinationCountry: location.country,
          destinationJurisdiction: location.countryJurisdiction,
          destinationCity: location.city,
        });
      }
    } catch {
      this.localNotify.error('Not a valid street address');
      return;
    }
  }

  setInventoryTemplate() {
    this.notesForm.controls.inventory.setValue(estimating.inventoryTemplate);
  }

  goToEstimate() {
    this.router.navigate(['/estimating']);
  }

  // Closes all panels
  closeAll() {
    this.closedPanels = [true, true, true, true, true, true];
  }

  onLocationInputBlur(formType: AddLocationFormType) {
    if (formType === 'start') {
      this.notesForm.controls.startingAddress.setValue(undefined);
    };
    if (formType === 'end') {
      this.notesForm.controls.destinationAddress.setValue(undefined);
    }

  }

  setValueEqualToManual(values: AddLocationComponent['manualLocationForm']['value']) {
    const { streetNumber, streetName, city, jurisdiction, areaCode, country } = values;
    const address = `${streetNumber} ${streetName} ${city}, ${jurisdiction} ${areaCode}, ${country}`;
    if (this.addLocationRef.formType === 'start') {
      this.notesForm.patchValue({
        startingPlaceId: false,
        startingAddress: address,
        startingAreaCode: areaCode,
        startingCountry: country,
        startingJurisdiction: jurisdiction,
        startingCity: city
      });
    }

    if (this.addLocationRef.formType === 'end') {
      this.notesForm.patchValue({
        destinationPlaceId: false,
        destinationAddress: address,
        destinationAreaCode: areaCode,
        destinationCountry: country,
        destinationJurisdiction: jurisdiction,
        destinationCity: city
      });
    }

    this.addLocationRef.close();
  }
}
