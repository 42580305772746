import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService, PlusAuthenticationService } from '@karve.it/core';
import { User } from '@karve.it/interfaces/auth';
import { MenuItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { BaseZoneWithParentFragment } from '../../../generated/graphql.generated';
import { ColorSchemeService } from '../../color-scheme.service';
import { UNREAD_NOTIFICATIONS_COUNT_MAX } from '../../global.constants';
import { BrandingService } from '../../services/branding.service';

import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { NotificationsService } from '../../services/notifications.service';
import { HelpDialogComponent } from '../../shared/help-dialog/help-dialog.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent implements OnInit, OnDestroy{

    @Input() sidebarStatic = false;
    @Input() sidebarMobileActive = false;

    @ViewChild(HelpDialogComponent) helpDialogRef: HelpDialogComponent;

    subs = new SubSink();

    items: MenuItem[];

    user: User;
    zone: BaseZoneWithParentFragment;

    inProduction = environment.production;

    constructor(
        private app: AppComponent,
        public appMain: AppMainComponent,
        public detailsHelper: DetailsHelperService,
        private plusAuth: PlusAuthenticationService,
        private brandingService: BrandingService,
        private router: Router,
        private feedbackSvc: FeedbackService,
        public colorSchemeService: ColorSchemeService,
        public notificationService: NotificationsService,
        private freyaHelper: FreyaHelperService,
    ) {}

    ngOnInit() {
        this.user = this.plusAuth.user;

        this.subs.sink = this.brandingService.currentZone().subscribe((z) => {
            this.zone = z;
        });

        this.subs.sink = this.plusAuth.authState.subscribe((state) => {
            if (state === 'deauthenticating') {
                this.app.mutateContainerEnabled = false;
            } else if (state === 'deauthenticated') {
                this.app.mutateContainerEnabled = true;
            }
        });

        this.subs.sink = this.freyaHelper.helpDialogOpened
            .subscribe(({ targetHelpId, sectionId }) => this.helpDialogRef.openToPage(targetHelpId, sectionId));
    }

    ngOnDestroy() {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
        this.subs.unsubscribe();
    }

    routeTo(route){
        this.router.navigate([route]);
    }

    logout(){
        return this.plusAuth.logout();
    }

    openFeedback(){
        this.feedbackSvc.openFeedbackDialog();
    }

    get notificationsBadge() {
        const { unread } = this.notificationService;
        if (!unread) { return; }
        if (unread > UNREAD_NOTIFICATIONS_COUNT_MAX) {
            return `${UNREAD_NOTIFICATIONS_COUNT_MAX}+`;
        };
        return `${unread}`;
    }
}
