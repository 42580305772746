import { CommonModule, } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { jobToolFeature } from '../../job-tool.reducer';

import { JobAgentComponent } from './job-agent/job-agent.component';
import { OverviewChipComponent } from './overview-chip/overview-chip.component';

@Component({
  selector: 'app-overview-header',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    SharedModule,
    JobAgentComponent,
    FreyaCommonModule,
    OverviewChipComponent
  ],
  templateUrl: './overview-header.component.html',
  styleUrl: './overview-header.component.scss'
})
export class OverviewHeaderComponent {

  public jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);
  public job$ = this.store.select(jobToolFeature.selectJob);
  public salesAgent$ = this.store.select(jobToolFeature.selectJobSalesAgent);
  public estimator$ = this.store.select(jobToolFeature.selectJobEstimator);

  constructor(private store: Store) { }
}
