<div *ngIf="role">
    <div class="p-grid">
        <div class="p-col">
            <h4><i class="pi pi-users large-icon"></i> Role</h4>
        </div>
        <!-- <div class="p-col">
            <button
                pButton
                pRipple
                label="Actions"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="roleActions"
            ></p-menu>
        </div> -->
    </div>

    <div class="card">
        <p>Name: {{role.name}}</p>
        <p>{{role.description}}</p>
        <p>
            <p-chip
                *ngFor="let attr of role.attributes"
                class="quick-margin"
            >
                {{attr}}
            </p-chip>
        </p>
        <p *ngIf="role.color">Color: <p-chip
            class="quick-margin"
            [style]="{'background-color': role.color}"
        >
            {{ role.color }}
        </p-chip></p>
        <p *ngIf="role?.createdAt">Created: {{role.createdAt?.unix | freyaDate }}</p>
        <p *ngIf="role?.updatedAt">Updated: {{role.updatedAt?.unix | freyaDate }}</p>
    </div>

    <div *ngIf="!basic">
        <h5>Zones</h5>
        <div class="card">
            <p *ngFor="let zone of role.zones.nodes">
                {{ (zone.type || 'Zone') | titlecase }}:
                {{ zone.name }}
            </p>
        </div>
    </div>

    <!-- <app-mutate-user
    #mutate
    [user]="user"
    mutateType="update"
    >
    </app-mutate-user> -->

    <!-- <p-dialog
        header="Delete Role"
        [modal]="true"
        [(visible)]="showDeleteUser"
        [keepInViewport]="true"
    >
        <p>Are you sure you want to delete {{user.givenName}} {{user.familyName}}?</p>
        <p-footer>
            <button
                pButton
                type="button"
                class="p-button-warning"
                icon="pi pi-times"
                (click)="showDeleteUser = false"
                label="Cancel"
            ></button>
            <button
                pButton
                type="button"
                class="p-button-danger"
                icon="pi pi-trash"
                (click)="deleteUser()"
                label="Delete"
            ></button>
        </p-footer>
    </p-dialog> -->
</div>