<div class="card">
  <div class="event-header">
    <div class="event-details">
      <h5 class="event-customer-name">
        {{ event?.customer?.name }}
      </h5>
      <span class="event-time"
        >{{ event?.startTime }} - {{ event?.endTime }}</span
      >
    </div>
    <div class="event-actions">
      <p-button
        label="Cancel"
        (onClick)="onClose()"
        [outlined]="true"
        severity="secondary"
      />
      <p-button
        label="Save"
        icon="pi pi-check"
        [loading]="updatingCrew$ | async"
        (onClick)="onSave()"
      />
    </div>
  </div>
  <p-divider></p-divider>
  <div class="event-resources">
    <section>
      <h5>Trucks</h5>
      <div class="section-content">
        <app-dispatch-chip
          *ngFor="let truck of eventTrucks"
          icon="pi pi-truck"
          [eventsCount]="truck?.eventsCount"
          [hasConflict]="truck?.hasConflict"
          [name]="truck?.name"
          removable="true"
          (remove)="removeTruck(truck)"
          panelType="dialog"
        ></app-dispatch-chip>

        <p-autoComplete
          [ngStyle]="{ order: 3 }"
          appendTo="body"
          [suggestions]="filteredPotentialAssets$ | async"
          (completeMethod)="searchAsset($event)"
          (onSelect)="addAsset($event)"
          optionLabel="name"
          variant="outlined"
          placeholder="Select truck..."
          [(ngModel)]="selectedAsset"
        >
        </p-autoComplete>
      </div>
    </section>
    <section>
      <h5>Crew</h5>
      <div
        class="section-content"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        <app-dispatch-chip
          cdkDrag
          [cdkDragData]="user"
          [cdkDragDisabled]="eventCrew.length === 1"
          *ngFor="let user of eventCrew"
          [eventsCount]="user?.eventsCount"
          [hasConflict]="user?.hasConflict"
          [name]="user?.name"
          [showStar]="
            user.role === EVENT_ATTENDEE_ROLES.crewLead ||
            user.role === EVENT_ATTENDEE_ROLES.assistantCrewLead
          "
          removable="true"
          (remove)="removeCrewMember(user)"
          panelType="dialog"
          [ngStyle]="{ order: user.role === 'Crew Lead' ? 1 : 2 }"
        ></app-dispatch-chip>

        <p-autoComplete
          [ngStyle]="{ order: 3 }"
          appendTo="body"
          [suggestions]="filteredPotentialCrew$ | async"
          (completeMethod)="searchUser($event)"
          (onSelect)="addCrewMember($event)"
          optionLabel="name"
          variant="outlined"
          placeholder="Select crew..."
          [(ngModel)]="selectedCrewMember"
        >
        </p-autoComplete>
      </div>
    </section>
  </div>
</div>
