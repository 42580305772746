<div class="p-grid">
  <div class="p-col-12">
    <div class="card">
      <h5>Product Settings</h5>
      <small>
        When an invoice is exported to QuickBooks, the application will try to match each product or service in the invoice to an existing product or service on QuickBooks.
        If the application cannot find an existing product or service, it will create a new one based on the product's category and the settings below.
      </small>
      <app-quickbooks-accounts *ngIf="companyInfo" accountType="income"></app-quickbooks-accounts>
    </div>
  </div>
  <div class="p-col-12">
    <div class="card">
      <h5>Company Info</h5>
      <ng-container *ngIf="companyInfo; else companyInfoLoading">
        <p>Company name: {{ companyInfo.CompanyName }}</p>
        <p>Legal name: {{ companyInfo.LegalName }}</p>
        <p>Address: {{ companyInfo.LegalAddr?.Line1 }}</p>
        <p>Country: {{ companyInfo.Country }}</p>
        <p>Email: {{ companyInfo.Email?.Address }}</p>
        <p *ngFor="let pair of companyInfo.NameValue">
        {{ pair.Name }}: {{ pair.Value}}
        </p>
      </ng-container>
      <ng-template #companyInfoLoading>
        <p-skeleton
          *ngFor="let number of [ 0, 1, 2, 3, 4 ]"
            width="100%"
            height="20px"
            styleClass="p-my-2"
        ></p-skeleton>
      </ng-template>
      <button
        (click)="disconnect()"
        pButton
        pRipple
        class="p-mt-2"
        label="Disconnect from QuickBooks"
        icon="pi pi-sign-in"
        type="submit"
        ></button>
    </div>
  </div>
  <div class="p-col-12" *ngIf="companyInfo?.Country === 'CA'">
    <div class="card">
      <h5>Default Tax Code (Non-US Companies only)</h5>
      <div>
        <small>
          When exporting an invoice to QuickBooks, the application will use the tax code linked to each product or service in the invoice,
          provided there is such a tax code. If no tax code was linked to a product, the application will use the tax code below.
        </small>
      </div>
      <br>
      <div>
        <p-dropdown
          [options]="taxCodes"
          [(ngModel)]="defaultTaxCode"
          (onChange)="setDefaultTaxCode()"
          placeholder="Quickbooks Tax Code"
          appendTo="body"
          optionLabel="Name"
          optionValue="Id"
          optionDisabled="disabled"
          [disabled]="!taxCodes"
          ></p-dropdown>
      </div>
    </div>
  </div>
</div>
