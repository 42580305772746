import { Component, ElementRef, Input } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'app-blockable',
  templateUrl: './blockable.component.html',
  styleUrl: './blockable.component.scss'
})
export class BlockableComponent implements BlockableUI {

  @Input() style: any;
  @Input() class: any;

  constructor(private el: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }

}
