<div class="card">
  <div class="p-grid">
    <div class="p-col-6">
      <h2>QuickBooks Integration</h2>
    </div>
    <div class="p-col-6">
      <ng-container *hasPermission="[ 'config.set' ]">
        <label>Enabled</label>
        <br />
        <p-inputSwitch [(ngModel)]="isEnabled" (onChange)="saveIsEnabled()"></p-inputSwitch>
      </ng-container>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
