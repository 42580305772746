import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy,
  OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {dayjs} from '@karve.it/core';
import { DiscountService, JobService } from '@karve.it/features';
import { UpdateJobsInput } from '@karve.it/interfaces/jobs';
import {QueryRef} from 'apollo-angular';

import { CreateDiscountsGQL, CreateDiscountsMutationVariables, Discount, GetFullDiscountByIdGQL, GetFullDiscountByIdQuery, UpdateDiscountsGQL } from 'graphql.generated';
import { clone, cloneDeep } from 'lodash';
import { filter } from 'rxjs/operators';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { SubSink } from 'subsink';

import { BaseJobFragment, FullDiscountFragment, UpdateDiscountsMutationVariables } from '../../../generated/graphql.generated';

import { orUndefined } from '../../js';
import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';

import { DeleteObjectComponent } from '../delete-object/delete-object.component';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { getConditionallyRequiredValidator, getGreaterThanValidator } from '../range-validators';


@Component({
  selector: 'app-mutate-discount',
  templateUrl: './mutate-discount.component.html',
  styleUrls: ['./mutate-discount.component.scss']
})
export class MutateDiscountComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;
  @ViewChild('delete') deleteRef: DeleteObjectComponent;

  // Template Refs
  @ViewChild('amount') amountRef: TemplateRef<any>;
  @ViewChild('code') codeRef: TemplateRef<any>;
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('active') activeRef: TemplateRef<any>;
  @ViewChild('startsAt') startsAtRef: TemplateRef<any>;
  @ViewChild('expiresAt') expiresAtRef: TemplateRef<any>;
  @ViewChild('maxRedemptions') maxRedemptionsRef: TemplateRef<any>;
  @ViewChild('events') eventsRef: TemplateRef<any>;

  // Review Refs
  @ViewChild('amountReview') amountReviewRef: TemplateRef<any>;
  @ViewChild('startsReview') startsReviewRef: TemplateRef<any>;
  @ViewChild('expiresReview') expiresReviewRef: TemplateRef<any>;
  @ViewChild('maxRedemptionsReview') maxReviewRef: TemplateRef<any>;
  @ViewChild('timesRedeemedReview') timesRedeemedRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() discount: (Discount | FullDiscountFragment);
  @Input() job: BaseJobFragment; // Only used for creating one time job specific discounts, Only works with create
  @Input() preselectEventId = undefined;

  @Output() updatedValue = new EventEmitter();
  @Output() appliedToJob = new EventEmitter(); // Only fired if the code was created for a job

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  customAmountRangeSubForm = new UntypedFormGroup({
    min: new UntypedFormControl({ value: null, disabled: true }, Validators.min(0)),
    max: new UntypedFormControl({ value: null, disabled: true }),
  }, [
    getGreaterThanValidator('min', 'max', { optionalMax: true }),
    getConditionallyRequiredValidator('max', 'min'),
  ]);

  discountForm = new UntypedFormGroup({
    name: new UntypedFormControl(undefined, [Validators.required]),
    code: new UntypedFormControl(undefined, [Validators.required]),
    discountType: new UntypedFormControl('amount', [Validators.required]),
    amount: new UntypedFormControl(undefined, [Validators.required, Validators.min(0)]),
    customAmountRange: this.customAmountRangeSubForm,
    active: new UntypedFormControl(true, [Validators.required]),
    startsAt: new UntypedFormControl(undefined, []),
    expiresAt: new UntypedFormControl(null, []),
    maxRedemptions: new UntypedFormControl(undefined, []),
    attributes: new UntypedFormControl([], []),
    events: new UntypedFormControl([], []),
  });

  discountFormValues = cloneDeep(this.discountForm.value);

  discountQueryRef: QueryRef<GetFullDiscountByIdQuery>;

  discountTypes = [
    {
      label: '$ - Fixed',
      value: 'amount',
    },
    {
      label: '% - Percentage',
      value: 'percentage',
    },
  ];

  currency = 'USD';

  isSingleUse = false;

  isCustomRange = false;

  sliderMax = 1000;

  sliderVal: number[] = [ 0, 1000 ];

  // Raw value of discountType control
  // Used to read control value from template when control is disabled
  selectedDiscountType: string;

  // Whether `selectedDiscountType` is 'percentage'
  isPercentage: boolean;

  constructor(
    private discountService: DiscountService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private timezoneHelper: TimezoneHelperService,
    private jobService: JobService,
    private cd: ChangeDetectorRef,
    private freyaHelper: FreyaHelperService,
    private getDiscountByIdGQL: GetFullDiscountByIdGQL,
    private createDiscountsGQL: CreateDiscountsGQL,
    private updateDiscountsGQL: UpdateDiscountsGQL,
    public responsiveHelper: ResponsiveHelperService,
    ) { }

  ngOnInit(): void {
    this.resolveCurrency();
    this.manageValidators();
    this.bindSliderToForm();
  }

  ngAfterViewInit(): void {
  }

  resolveCurrency(){
    const country = this.freyaHelper.getCountry();
    this.currency = country.currency;
  }

  refetchDiscount() {
    if (!this.discountQueryRef) {
      this.discountQueryRef = this.getDiscountByIdGQL.watch({ discountId: this.discount.id }, { fetchPolicy: 'cache-and-network' });

      this.subs.sink = this.discountQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.discount = res.data.discounts.discounts[0] as unknown as Discount;
          this.updatedValue.emit(this.discount);
        }
      });
    } else {
      this.discountQueryRef.refetch();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
      this.createDiscount();
    } else if (this.mutateType === 'update') {
      // Update
      this.updateDiscount();
    }
  }

  openDelete(){
    this.deleteRef.openDialog();
  }

  openDialog() {
    this.resetAdditionalProperties();
    this.customAmountRangeSubForm.disable();

    if (this.mutateType === 'create') {
      // Assign Steps
      this.steps = [
        { name: 'Name', ref: this.nameRef, control: 'name', type: 'text', },
        { name: 'Code', ref: this.codeRef, control: 'code', type: 'text', },
        {
          name: 'Amount',
          ref: this.amountRef,
          control: 'amount',
          type: 'complex',
          reviewRef: this.amountReviewRef,
          invalidFunc: () => {
            const { amount, discountType } = this.discountForm.controls;
            return amount.invalid || discountType.invalid || this.customAmountRangeSubForm.invalid;
          },
        },
        { name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean', },
        { name: 'Starts At', ref: this.startsAtRef, control: 'startsAt', type: 'complex', reviewRef: this.startsReviewRef},
        { name: 'Expires', ref: this.expiresAtRef, control: 'expiresAt', type: 'complex', reviewRef: this.expiresReviewRef},
        { name: 'Max Redemptions', ref: this.maxRedemptionsRef, control: 'maxRedemptions', type: 'complex', reviewRef: this.maxReviewRef },

      ];
      this.discountForm.reset(this.discountFormValues);

      if (this.job) { // If a job has been provided then we are creating a one time use code for that job specifically
        this.steps[1].ref = undefined;
        this.steps[3].ref = undefined;
        this.steps[4].ref = undefined;
        this.steps[5].ref = undefined;
        this.steps[6].ref = undefined;
        this.steps.push(
          { name: 'Select Event', ref: this.eventsRef, control: 'events', type: 'array'}
        );
        this.steps = clone(this.steps);
        this.discountForm.get('events').addValidators(Validators.required);

        const last6 = (Math.floor(new Date().getTime() / 1000)).toString().slice(-6); // Use last 6 digit to prevent duplicate errors

        this.discountForm.reset({
          name: `Discount for ${ this.job.code }`,
          code: `${this.job.code}-${last6}`,
          discountType: 'amount',
          attributes: ['single-use'],
          amount: undefined,
          active: true,
          startsAt: undefined,
          expiresAt: null,
          maxRedemptions: 1
        });

        this.mutateRef.titleText = 'Create Single Use Discount';
        this.mutateRef.disclaimer = 'Disabled fields are auto generated for this one time use code.';
        this.isSingleUse = true;
      }

    } else if (this.mutateType === 'update') {
      this.setFormValues();
      this.steps = [
        {
          name: 'Amount',
          ref: this.amountRef,
          control: 'amount',
          type: 'complex',
          reviewRef: this.amountReviewRef,
          invalidFunc: () => {
            const { amount, discountType } = this.discountForm.controls;
            return amount.invalid || discountType.invalid || this.customAmountRangeSubForm.invalid;
          },
        },
        { name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean', },
        { name: 'Starts At', ref: this.startsAtRef, control: 'startsAt', type: 'complex', reviewRef: this.startsReviewRef},
        { name: 'Expires', ref: this.expiresAtRef, control: 'expiresAt', type: 'complex', reviewRef: this.expiresReviewRef},
        { name: 'Max Redemptions', ref: this.maxRedemptionsRef, control: 'maxRedemptions', type: 'text', reviewRef: this.maxReviewRef},
        { name: 'Times Redeemed', ref: undefined, control: '', type: 'complex', reviewRef: this.timesRedeemedRef}
      ];
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;

    this.mutateRef.openDialog();
  }

  setFormValues() {
    if (this.discount.customAmountRange) {
      let [ min, max ] = this.discount.customAmountRange;

      if (this.discount.discountType === 'amount') {
        min = convertCentsToDollars(min, true);
        max = convertCentsToDollars(max, true);
      }

      this.isCustomRange = true;

      this.customAmountRangeSubForm.enable();

      this.customAmountRangeSubForm.reset({ min, max });
    }

    const amount = this.discount.discountType === 'percentage' ? this.discount.amount : convertCentsToDollars(this.discount.amount);

    this.discountForm.reset({
      name: this.discount.name,
      code: this.discount.code,
      amount: { value: amount, disabled: true },
      customAmountRange: this.customAmountRangeSubForm.value,
      discountType: { value: this.discount.discountType, disabled: true },
      active: this.discount.active,
      startsAt: this.timezoneHelper.unixToDate(this.discount.startsAt),
      expiresAt: this.timezoneHelper.unixToDate(this.discount.expiresAt),
      maxRedemptions: orUndefined(this.discount.maxRedemptions),
      attributes: this.discount.attributes || [],
    });
  }

  createDiscount() {
    const value = this.discountForm.value;

    const createDiscountInput: CreateDiscountsMutationVariables = {
      createDiscounts: [{
        amount: value.discountType === 'amount' ? convertDollarsToCents(value.amount): value.amount,
        code: value.code,
        discountType: value.discountType,
        active: value.active,
        name: value.name,
        expiresAt: this.timezoneHelper.formatUnix(value.expiresAt),
        startsAt: this.timezoneHelper.formatUnix(value.startsAt),
        maxRedemptions: orUndefined(value.maxRedemptions),
        attributes: [...value.attributes],
        customAmountRange: this.getCustomAmountRange(value.customAmountRange),
      }],
    };

    this.createDiscountsGQL.mutate(createDiscountInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:res.data.createDiscounts[0].id,
        type:'Discounts',
        action:'create'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Discount created' });

      // IF we are assigning it to a jobs events right away
      if (this.job && this.discountForm.value.events?.length) {

        const addDiscountsInput = [];

        for (const event of this.discountForm.value.events){
          addDiscountsInput.push({
            discountId: res.data.createDiscounts[0].id,
            eventId: event.id,
          });
        }

        const updateJobsInput = {
          updateJobs: [{
            jobId: this.job.id,
            addDiscounts: addDiscountsInput,
          }]
        } as UpdateJobsInput;

        this.jobService.updateJobs(updateJobsInput).subscribe(() => {
          this.detailsHelper.pushUpdate({
            id:this.job.id,
            type:'Jobs',
            action:'update',
          });
          this.mutateRef.closeDialog();
          this.localNotify.addToast.next({severity: 'success', summary: 'Discount applied to job'});
          this.appliedToJob.emit(true);
        }, (err) => {
          this.mutateRef.loading = false;
          this.localNotify.apolloError('Failed to apply discount to job', err);
        });
      }
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError('Failed to create discount', err);
    });
  }

  /**
   * Converts the value of the `customAmountRangeSubForm` into the value expected by the `createDiscount` mutation
   */
  getCustomAmountRange(formValue: { min: number | null; max: number | null }) {

    if (!formValue) { return; }
    if (formValue.min === null && formValue.max === null) { return; }

    const isAmount = this.discountForm.getRawValue().discountType === 'amount';

    const min = isAmount ? convertDollarsToCents(formValue.min, true) : formValue.min;
    const max = isAmount ? convertDollarsToCents(formValue.max, true) : formValue.max;

    return [ min, max ].filter((amount) => amount !== null && amount !== undefined);
  }

  updateDiscount() {
    const value = this.discountForm.value;

    const updateDiscountInput = {
      updateDiscounts: [{
        discountId: this.discount.id,
        active: value.active,
        maxRedemptions: value.maxRedemptions,
        expiresAt: value.expiresAt ? dayjs(value.expiresAt, 'MM/DD/YYYY').unix() :  null,
        startsAt: value.startsAt ? dayjs(value.startsAt, 'MM/DD/YYYY').unix() : undefined,
        customAmountRange: this.getCustomAmountRange(value.customAmountRange),
      }],
    } as UpdateDiscountsMutationVariables;

    this.subs.sink = this.updateDiscountsGQL.mutate(updateDiscountInput).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id:this.discount.id,
        type:'Discounts',
        action:'update'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Discount updated'});
      this.refetchDiscount();
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError('Failed to update discount', err);
    });
  }

  deleteDiscount(){
    this.discountService.deleteDiscounts({discountIds: [this.discount.id]}).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id:this.discount.id,
        type:'Discounts',
        action:'delete'
      },true);
      this.localNotify.addToast.next({severity: 'success', summary: 'Discount deleted'});
    }, (err) => {
      this.localNotify.error('Failed to delete discount', err);
    });
  }

  clearExpiresAtInput(){
      this.discountForm.controls.expiresAt.setValue(null);
  }

  /**
   * Watches for value changes in some form controls and updates the validators on other controls to match the new values
   */
  manageValidators() {
    this.subs.sink = this.discountForm.controls.discountType.valueChanges
      .pipe(filter(Boolean))
      .subscribe((discountType: string) => {
        this.setAmountValidators(discountType);
        this.adjustAmounts(discountType);
        this.setCustomRangeValidators(this.discountForm.value.amount, discountType);

        this.selectedDiscountType = discountType;
        this.isPercentage = this.selectedDiscountType === 'percentage';
      });

    this.subs.sink = this.discountForm.controls.amount.valueChanges
      .subscribe((amount: number) => {
        this.setCustomRangeValidators(amount, this.discountForm.value.discountType);
      });

    this.subs.sink = this.customAmountRangeSubForm.controls.max.valueChanges
      .subscribe((amount: number) => this.setSliderMax(amount));
  }

  setAmountValidators(discountType: string) {
    const validators = [ Validators.min(0) ];

    if (discountType === 'percentage') {
      validators.push(Validators.max(100));
    }

    this.discountForm.controls.amount.setValidators(validators);
    this.discountForm.controls.amount.updateValueAndValidity();
  }

  /**
   * Sets max and min validators on the custom amount range controls based on the current value of the amount and discount type controls
   *
   * @param amount The value of the amount control which will be set on the discount
   * @param discountType The value of the discountType control which will be set on the discount
   */
  setCustomRangeValidators(amount: number | null, discountType: string) {

    // MAX CONTROL

    const maxCtrlValidators: ValidatorFn[] = [];

    // Must be greater than discount amount
    if (amount !== null && amount !== undefined) {
      maxCtrlValidators.push(Validators.min(amount));
    }

    // Must not exceed 100 if discount type is percentage
    if (discountType === 'percentage') {
      maxCtrlValidators.push(Validators.max(100));
    }

    this.customAmountRangeSubForm.controls.max.setValidators(maxCtrlValidators);
    this.customAmountRangeSubForm.controls.max.updateValueAndValidity();

    // MIN CONTROL

    // Must be a positive number
    const minCtrlValidators: ValidatorFn[] = [ Validators.min(0) ];

    // Must not exceed discount amount
    if (amount !== null && amount !== undefined) {
      minCtrlValidators.push(Validators.max(amount));
    }

    this.customAmountRangeSubForm.controls.min.setValidators(minCtrlValidators);
    this.customAmountRangeSubForm.controls.min.updateValueAndValidity();

    // If min and max controls have values, mark as touched so corresponding inputs are marked red if previous values are now invalid
    if (this.customAmountRangeSubForm.value.min !== null) {
      this.customAmountRangeSubForm.controls.min.markAsTouched();
    }

    if (this.customAmountRangeSubForm.value.max !== null) {
      this.customAmountRangeSubForm.controls.max.markAsTouched();
    }
  }

  resetCustomAmountRangeValidators() {
    this.customAmountRangeSubForm.controls.min.setValidators(Validators.min(0));
    this.customAmountRangeSubForm.controls.min.updateValueAndValidity();
    this.customAmountRangeSubForm.controls.max.clearValidators();
    this.customAmountRangeSubForm.controls.max.updateValueAndValidity();
  }

  bindSliderToForm() {
    this.subs.sink = this.customAmountRangeSubForm.valueChanges.subscribe((val) => {
      const { min, max } = val;
      this.sliderVal = [ min, max ];
    });
  }

  updateCustomAmountSilently(customRange: number[]) {
    const [ min, max ] = customRange;
    this.customAmountRangeSubForm.setValue({ min, max }, { emitEvent: false });
    this.customAmountRangeSubForm.markAllAsTouched();
  }

  onCustomRangeSwitch(isCustomRange: boolean) {
    if (isCustomRange) {
      this.customAmountRangeSubForm.enable();
      this.setDefaultCustomRange();
    } else {
      this.customAmountRangeSubForm.reset();
      this.customAmountRangeSubForm.disable();
    }
  }

  setDefaultCustomRange() {
    if (!this.hasAmount) {

      const sliderMiddlePoint = this.sliderMax / 2;

      this.customAmountRangeSubForm.setValue({
        min: Math.floor(sliderMiddlePoint - (sliderMiddlePoint / 2)),
        max: Math.floor(sliderMiddlePoint + (sliderMiddlePoint / 2)),
      });

      return;
    }

    const step = this.amount * .5;

    const defaultMin = this.amount - step < 0 ? 0 : this.amount - step > this.sliderMax ? this.sliderMax : this.amount - step;
    const defaultMax = this.amount + step > this.sliderMax ? this.sliderMax : this.amount + step;

    this.customAmountRangeSubForm.setValue({
      min: Math.floor(defaultMin),
      max: Math.floor(defaultMax),
    });
  }

  resetAdditionalProperties() {
    this.isCustomRange = false;
    this.resetCustomAmountRangeValidators();
    this.sliderMax = 1000;
    this.sliderVal = [ 0, 1000 ];
  };

  setSliderMax(customRangeMax: number) {
    const isPercentage = this.discountForm.getRawValue().discountType === 'percentage';
    this.sliderMax = isPercentage ? 100 : Math.max(1000, this.amount || 0, customRangeMax || 0);
  }

  adjustAmounts(discountType: string) {
    if (discountType !== 'percentage') { return; }

    if (this.discountForm.value.amount > 100) {
      this.discountForm.controls.amount.setValue(100);
    }

    if (!this.isCustomRange) { return; }

    this.setSliderMax(this.customAmountRangeSubForm.value.max);
    this.setDefaultCustomRange();
  }

  openCreateDiscountHelp() {
    this.freyaHelper.openHelpDialog('create-discount', 'range');
  }

  get hasAmount() {
    return this.amount !== null && this.amount !== undefined;
  }

  get amount() {
    return this.discountForm.getRawValue().amount;
  }

}
