<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 class="p-d-inline">Users 
                <ng-container *ngIf="usersQH.total !== undefined">
                    ({{ usersQH.total }})
                </ng-container>
            </h4>
            <span *ngIf="usersQH.total === undefined && !userCountLoading" class="freya-link-button" (click)="getUserCount()">Show count</span>
            <p-skeleton
                styleClass="p-d-inline-block"
                *ngIf="userCountLoading"
                height="1.5rem"
                width="2.5rem"
                ></p-skeleton>
        </div>
        <div class="p-col">
            <div style="white-space: nowrap;">
                <span
                    class="p-input-icon-left"
                    style="display: inline-block;"
                >
                    <i
                        *ngIf="!usersQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="usersQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #userSearchInput
                        pInputText
                        [(ngModel)]="usersQH.search"
                        placeholder="Search"
                        data-cy="search-text-field"
                    >
                </span>
                <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="clearSearch()"
                ></button>
            </div>
        </div>
        <div class="p-col">
            <app-select-roles
                [(ngModel)]="selectedRoles"
                [roles]="availableRoles"
                (ngModelChange)="searchUsersByRole()"
            ></app-select-roles>
        </div>
        <div class="p-col">
            <button
                *hasPermission="['users.create']"
                pButton
                (click)="openCreateUser()"
                label="Create"
                icon="pi pi-plus"
                data-cy="create-button"
            ></button>
        </div>
    </div>
</div>

<br class="p-d-none p-d-md-inline">

<div class="card">
    <p-table
        [value]="users"
        [loading]="usersQH.loading"
        [showLoader]="!usersQH.mergeNextResults && !usersQH.isFirstLoad"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols">
                    {{col.header}}
                    <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                </th>
                <th style="width: 3rem"></th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-user
        >
            <tr
                class="selectable-row"
                (appDoubleClick)="freyaHelper.openItem('user', user?.id)"
                (singleClick)="selectUser(user)"
                (auxclick)="freyaHelper.openItem('user', user?.id, true)"
            >
                <td *ngFor="let col of cols">
                    <span class="p-column-title">{{col.header}}:</span>
                    <div>
                        <ng-container [ngSwitch]="col.field">
                            <span *ngSwitchCase="'familyName'"
                                class="bold-on-mobile" data-cy="span-given-name"
                                >
                                {{user.givenName}} {{user.familyName}}
                            </span>
                            <span *ngSwitchCase="'phone'">
                                {{user | phone}}
                            </span>
                            <span *ngSwitchDefault>
                                {{user[col.field] ? user[col.field]: 'None'}}
                            </span>
                        </ng-container>
                    </div>
                </td>
                <td ><span></span><app-open-link [link]="'/user/'+user.id"></app-open-link></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
            <ng-container *ngFor="let placeholder of userPlaceholderCount">
                <tr>
                    <td *ngFor="let col of cols">
                        <p-skeleton
                            height="2rem"
                            width="100%"
                            ></p-skeleton>
                    </td>
                    <td ><span></span><app-open-link [disabled]="true"></app-open-link></td>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'6'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
    <div
        >
    </div>
    <div
        *ngIf="!usersQH.loading && usersQH.hasMore"
        class="p-text-center"
        (appLazyLoad)="retrieveMoreUsers()"
        >
        <span class="freya-link-button" (click)="retrieveMoreUsers()">Load more</span>
    </div>
    <div
        *ngIf="!usersQH.loading && !usersQH.hasMore"
        class="p-text-center"
    >
        No more users to show.
    </div>
</div>

<app-mutate-user #mutate></app-mutate-user>
