<span
    [class.hide]="hidden"
    [pTooltip]="tooltip"
    [tooltipEvent]="responsiveHelper.isSmallScreen ? 'focus' : 'hover'"
>
    <ng-content></ng-content>
    <i
    class="pi pi-info-circle"
></i>
</span>
