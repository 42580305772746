<div class="additional-location-container">
    <div class="flex-container header-container">
        <div>
            <h6>{{locationType}} details</h6>
        </div>
        <div>
            <button
                pButton
                type="button"
                icon="pi pi-times"
                class="p-button-icon-only p-button-text"
                (click)="onLocationDelete(locationType)"
            ></button>
        </div>
    </div>
    <div class="flex-container two-columns-container">
        <div class="address-container">
            <label for="['locationType'] + '-additionalLocationAddressInput'">Address</label>
            <div class="p-inputgroup mt-05">
                <input
                    [tabindex]="getTabIndex(locationType, 'address')"
                    [id]="['locationType'] + '-additionalLocationAddressInput'"
                    #additionalLocationAddressInput
                    [(ngModel)]="address"
                    *ngIf="googleHelper.loaded | async"
                    #googleAutocomplete="ngx-places"
                    ngx-gp-autocomplete
                    pInputText
                    autocomplete="off"
                    (onAddressChange)="handleAddressChange($event, locationType)"
                    [inputStyle]="{'width':'100%', 'margin-top':'0.5rem'}"
                />
                <button
                    type="button"
                    pButton
                    pRipple
                    (click)="openManualLocation(locationType)"
                    icon="pi pi-map-marker"
                    class="p-button-secondary p-button-outlined"
                    pTooltip="Add a location without using the autocomplete"
                ></button>
            </div>
        </div>
        <div class="stop-type-container">
            <label for="additionalStopsDropdown">Stop type</label>
            <p-dropdown
                [tabindex]="getTabIndex(locationType, 'type')"
                id="additionalStopsDropdown"
                [(ngModel)]="additionalStopsSelectedOption"
                [placeholder]="additionalStopsSelectedOption.label"
                [options]="addableAdditionalLocations"
                [style]="{ 'width': '100%', 'marginTop': '0.5rem' }"
                (onChange)="handleDropdownChange($event, locationType)"
            ></p-dropdown>
        </div>
    </div>
</div>

<app-jobv2-manually-add-location
    #addLocation
    [formType]="locationTypeToOpenInManual"
></app-jobv2-manually-add-location>