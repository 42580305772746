import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RoleInfo } from '@karve.it/interfaces/roles';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';


@Component({
  selector: 'app-select-roles',
  templateUrl: './select-roles.component.html',
  styleUrls: ['./select-roles.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: SelectRolesComponent
  }]
})
export class SelectRolesComponent implements ControlValueAccessor {

  @Input() roles: RoleInfo[] = [];
  selectedRoles: RoleInfo[] = [];

  constructor(
    public responsiveHelper: ResponsiveHelperService
  ) { }

  // FORM CONTROL FUNCTIONS
  writeValue(roles: RoleInfo[]): void {
    this.selectedRoles = roles;
  }

  onChange = (roles) => {};

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  onTouched = () => {};

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

}
