<ng-template #section>
    <div class="content-area" style="overflow-y: auto;">
        <div class="section-header">
            <div class="p-grid p-ai-center">
                <div class="p-col">
                    <h5 class="no-margin">{{activeStep}}</h5>
                </div>
            </div>
        </div>
        <div>
            <ng-container *ngTemplateOutlet="sectionContent"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #review>
    <div class="content-area">
        <p-listbox
            [options]="onlyShowEditableItems ? (steps | removeDisabledSteps | filterRemovedSteps) : steps | filterRemovedSteps"
            optionLabel="name"
            (onClick)="viewSection($event.option.name)"
            [scrollHeight]="scrollHeight"
        >
            <ng-template
                let-step
                pTemplate="item"
            >
                <div
                    class="full-width custom-listbox-item"
                    [class.disabled-review-step]="!step.ref"
                    [class.invalid-review-step]="step?.invalidFunc ? step.invalidFunc() : fg.controls[step.control]?.invalid"
                >
                    <div class="p-grid p-align-center">
                        <div class="p-col p-text-left counter-grid-spacing">
                            {{step.name}}
                        </div>
                        <div class="p-col p-text-right counter-grid-spacing">
                            <span *ngIf="step.type === 'text'">
                                {{fg.controls[step.control].value}}
                            </span>
                            <span *ngIf="step.type === 'func' && step.reviewFunc">
                                {{ step.reviewFunc(fg.controls[step.control].value, step) }}
                            </span>
                            <span *ngIf="step.type === 'title'">
                                {{fg.controls[step.control].value | titlecase}}
                            </span>
                            <span *ngIf="step.type === 'array'">
                                {{fg.controls[step.control].value.length}}
                            </span>
                            <span *ngIf="step.type === 'color'">
                                {{fg.controls[step.control].value}}
                            </span>
                            <span *ngIf="step.type === 'complex'">
                                <ng-container
                                    *ngIf="step.reviewRef"
                                    [ngTemplateOutlet]="step.reviewRef"
                                ></ng-container>
                                <span
                                    *ngIf="step.reviewProperty && !step.reviewRef">
                                    {{fg.controls[step.control].value ? fg.controls[step.control].value[step.reviewProperty] : 'Not Set'}}
                                </span>
                            </span>
                            <span *ngIf="step.type === 'currency'">
                                {{fg.controls[step.control].value | currency : currency}}
                            </span>
                            <span *ngIf="step.type === 'boolean'">
                                <!-- {{fg.controls[step.control].value ? 'Yes' : 'No'}} -->
                                <p-inputSwitch
                                    (click)="$event.stopPropagation()"
                                    [(ngModel)]="fg.controls[step.control].value"
                                    (onChange)="toggleBooleanField($event, step)"
                                ></p-inputSwitch>
                            </span>
                            <span *ngIf="step.type === 'password'">
                                {{fg.controls[step.control].value | hideString}}
                            </span>
                            <span *ngIf="step.type === 'phone'">
                                {{ {phone: fg.controls[step.control].value} | phone }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-listbox>
        <br>
        <div *ngIf="disclaimer">
            <p>{{disclaimer}}</p>
        </div>
        <div *ngIf="mutateType==='update'">
            <p-checkbox
                [binary]="true"
                label="Only show items that can be edited"
                [(ngModel)]="onlyShowEditableItems"
            ></p-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #navigationButtons>
    <div class="p-grid p-align-center">
        <div class="p-col p-text-left">
            <button
                pButton
                label="Prev"
                class="navigation-button"
                (click)="viewPreviousSection()"
                [disabled]="activeStepIndex === 0 || underReview"
                icon="pi pi-arrow-left"
            ></button>
        </div>
        <div class="p-col p-text-right">
            <button
                pButton
                label="Next"
                class="navigation-button"
                (click)="viewNextSection()"
                [class.hide]="activeStepIndex === ((steps | removeDisabledSteps | filterRemovedSteps).length - 1) || underReview"
                icon="pi pi-arrow-right"
                iconPos="right"
                data-cy="next-button"
            ></button>
            <button
                pButton
                label="Review"
                class="navigation-button"
                (click)="viewReview()"
                [class.hide]="activeStepIndex !== ((steps | removeDisabledSteps | filterRemovedSteps).length - 1)"
                icon="pi pi-bars"
                data-cy="review-button"
            ></button>
        </div>
    </div>
</ng-template>

<ng-template #reviewButtons>
    <div
        class="p-grid"
        *ngIf="underReview"
    >
        <div class="p-col p-text-center">
            <button
                pButton
                label="Review Changes"
                class="navigation-button"
                [disabled]="sectionInvalid"
                (click)="viewReview()"
                [class.hide]="activeStepIndex === -1"
                icon="pi pi-bars"
                data-cy="review-changes-button"
            ></button>
            
            <button
                pButton
                [label]="loading? 'Saving...':reviewButtonText ? reviewButtonText : mutateType | titlecase"
                class="navigation-button p-button-success"
                [disabled]="!fg || !fg.valid || loading"
                (click)="mutateObject()"
                [class.hide]="activeStepIndex !== -1"
                icon="pi pi-plus"
            >
            <i *ngIf="loading" class="pi pi-spin pi-spinner" style="position: absolute; right:35%" ></i>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #defaultTitle>
    <span
        *ngIf="titleText"
        class="mutate-object-header-text"
    >{{titleText}}</span>
    <span
        *ngIf="!titleText"
        class="mutate-object-header-text"
    >{{mutateType | titlecase}} {{objectType}}</span>
</ng-template>

<p-dialog
    [modal]="true"
    [(visible)]="showDialog"
    [closable]="false"
    (onHide)="closeDialog()"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
>
    <ng-template pTemplate="header">
        <div class="p-grid p-align-center mutate-object-header">
            <div class="p-col-2"></div>
            <div class="p-col-8 p-text-center">
                <ng-container *ngTemplateOutlet="titleContent"></ng-container>
            </div>
            <div class="p-col-2 p-text-center">
                <button
                    (click)="confirmClose()"
                    pButton
                    icon="pi pi-times"
                    class="p-button-text p-button-lg"
                    data-cy="close-button"
                ></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer"></ng-template>
    <ng-container *ngTemplateOutlet="activeContent"></ng-container>
    <div #formValidationErrors>
        <small class="field-error-message" *ngIf="fg.errors?.transactionExceedsRemaining">
            {{ fg.errors.transactionExceedsRemaining}}
        </small>
        <small class="field-error-message" *ngIf="fg.errors?.transactionRefundNegative">
            Transaction of type refund must be a negative value
        </small>
    </div>
    <div class="section-footer">
        <ng-container *ngTemplateOutlet="footerContent"></ng-container>
    </div>
    <div class="indicator-container p-grid">
        <div
            class="p-col p-text-center"
            #paginatorStuff
        >
            <span
                *ngFor="let step of steps | removeDisabledSteps | filterRemovedSteps"
                [pTooltip]="step.name"
                tooltipPosition="bottom"
                [class.active-indicator]="step.name === activeStep && activeContent !== reviewRef"
                (click)="viewSection(step.name)"
                class="indicator"
            ></span>
        </div>
    </div>

</p-dialog>
<p-confirmDialog
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
></p-confirmDialog>
