import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { BaseLocationFragment, FullJobFragment, JobLocation } from '../../../generated/graphql.generated';
import { showGoogleMapsRoute, viewLocationOnGoogleMaps } from '../../utilities/locations.util';
import { formatLocationToDisplay, getJobLocation } from '../jobs.util';

@Component({
  selector: 'app-job-locations',
  templateUrl: './job-locations.component.html',
  styleUrls: ['./job-locations.component.scss']
})
export class JobLocationsComponent implements OnInit, OnChanges {

  @Input() job: FullJobFragment;

  // Set to true to see the 'View Route' button
  @Input() showRoute = false;

  // If True will show all locations on the job, even if they are not in showLocationTypes
  @Input() showAll = false;

  // Shows these locations even if they are not set
  @Input() showLocationTypes: string[] = ['dock', 'start', 'end'];

  locationsToShow: JobLocation[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateLocationsToShow();
  }

  calculateLocationsToShow(){
    if (!this.job?.locations) { return; }

    this.locationsToShow = [];

    for (const locationType of this.showLocationTypes){
      const jobLocation = getJobLocation(this.job, locationType);

      const formattedLocation = formatLocationToDisplay(jobLocation);

      // If a location matching this type is on the job add it to the list
      if (jobLocation){
        this.locationsToShow.push(
          {
            locationType,
            locationId: jobLocation.id,
            location: formattedLocation as any,
        });
        continue;
      }

      // If there is no location for a locationType add a placeholder
      this.locationsToShow.push({
        locationType,
        locationId: undefined,
        location: undefined,
      });
    }

    if (this.showAll){
      for (const location of this.job.locations){
        if (this.locationsToShow.find((l) => l.locationId === location.locationId)){
          continue;
        }

        this.locationsToShow.push(location as any);
      }
    }
  }

  viewLocationOnGoogleMaps(location: BaseLocationFragment) {
    return viewLocationOnGoogleMaps(location);
  }

  showGoogleMapsRoute(){
    return showGoogleMapsRoute(this.job);
  }

  //when lead comes from OBE addressLineOne is not always filled correct
  //if it's not filled, display area code instead
  formatLocationToDisplay(location: Partial<BaseLocationFragment>) {
    const addressLineOne = location?.addressLineOne?.trim();
    const addressLineTwo = location?.addressLineTwo?.trim();
    const areaCode = location?.areaCode;
    const city = location?.city;

    if (addressLineOne) {
        return (addressLineTwo ? addressLineTwo + ' - ' : '') + addressLineOne;
    } else {
      return (areaCode || '') + ' ' + (city || '');
    }
  }
}
