import { Injectable } from '@angular/core';
import { JobService } from '@karve.it/features';
import { MenuItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';

import { BaseCalendarEventFragment, BaseJobFragment, ListCalendarEventsGQL } from '../../generated/graphql.generated';
import { JOB_STAGES, JOB_STAGES_BY_ORDER } from '../global.constants';
import { jobId } from '../interfaces/transactions';

import { DetailsHelperService } from './details-helper.service';
import { DocumentHelperService } from './document-helper.service';
import { EventHelperService } from './event-helper.service';


export type Job = BaseJobFragment;

export const MI_JOB_ACTIONS_PROMOTE = 'job-actions-promote';

export type TransitionFunc = (job: BaseJobFragment) => void;

export type PromoteMenuItem = MenuItem & {
  afterPromoteCb?: TransitionFunc;
  prePromoteCb?: (job: BaseJobFragment) => void;
};

@Injectable({
  providedIn: 'root'
})
export class PromoteJobService {

  constructor(
    private jobService: JobService,
    private detailsHelper: DetailsHelperService,
    private documentsHelper: DocumentHelperService,
    private eventsHelper: EventHelperService,
    private listCalendarEventsGQL: ListCalendarEventsGQL,
  ) {}

  isJobPromotable(job: BaseJobFragment) {
    // console.log(job, this.getNextValidJobStage(job), job?.followingStages?.length);
    return job && Boolean(this.getNextValidJobStage(job)) && !job.archivedAt;
  }

  /**
   * @returns the next valid stage if it exists. cancelled is not a valid stage.
   * Otherwise undefined
   */
   getNextValidJobStage(job: BaseJobFragment) {
    if (!job) { return undefined; }

    const currentStage = JOB_STAGES_BY_ORDER.indexOf(job.stage);
    const nextStage = JOB_STAGES_BY_ORDER[currentStage + 1];
    if (!nextStage || nextStage === 'cancelled') { return undefined; }

    return nextStage;
  }

  generatePromoteAction(
    job: BaseJobFragment,
    afterPromoteCb?: TransitionFunc,
    prePromoteCb?: (j: Job) => void,
  ) {
    const nextStage = this.getNextValidJobStage(job);

    const defaultLabel = 'Promote';
    let label = defaultLabel;
    if (nextStage && JOB_STAGES[nextStage]) {
      label = `Mark ${ JOB_STAGES[nextStage].pastTenseVerb }`;
    }
    const action = {
      id: MI_JOB_ACTIONS_PROMOTE,
      label,
      icon: 'pi pi-angle-double-up',
      visible: Boolean(nextStage),
      disabled: !this.isJobPromotable(job),
      afterPromoteCb,
      prePromoteCb,
      command: () => {
        this.promote(job, afterPromoteCb, prePromoteCb);
      },
    } as PromoteMenuItem;

    return action;
  }

  checkPromoteActionMenuItem(job: BaseJobFragment, actions: MenuItem[]) {
    if (!actions) { return; }
    const jobActionsPromoteItem = actions.find((a) => a.id === MI_JOB_ACTIONS_PROMOTE) as PromoteMenuItem;
    if (!jobActionsPromoteItem) { return; }
    Object.assign(jobActionsPromoteItem, this.generatePromoteAction(
      job,
      jobActionsPromoteItem.afterPromoteCb,
      jobActionsPromoteItem.prePromoteCb,
    ));
  }

  promote(
    job: BaseJobFragment,
    afterPromoteCb?: TransitionFunc,
    prePromoteCb?: (j: BaseJobFragment) => void,
    stage?: string,
    preventRefetch = false,
  ) {
    const nextValidStage = this.getNextValidJobStage(job) || stage;
    if (!nextValidStage) {
      throw new Error(`No valid next stage`);
    }

    if (!this.isJobPromotable(job)) {
      throw new Error(`Job is not promotable to ${ nextValidStage }`);
    }

    if (prePromoteCb) {
      prePromoteCb(job);
    }

    return new Promise< { job: BaseJobFragment; promotedToStage: string } >((resolve, reject) => {
      this.jobService.updateJobs({
        updateJobs: [{
          jobId: job.id,
          stage: nextValidStage,
        }],
      }).subscribe(async (res) => {

        const currentItem = this.detailsHelper.detailsItem.value;
        if (currentItem?.item?.id === job.id) {
          this.detailsHelper.open('job', { id: job.id });
        }
        this.detailsHelper.pushUpdate({
          id:job.id,
          type:'Jobs',
          action:'update',
          update:!preventRefetch
        });

        console.log(`Job promoted to ${ nextValidStage }: ${ job.id }`);

        if ( nextValidStage === JOB_STAGES.invoice.name) {

          const jobEvents = await this.listCalendarEventsGQL.fetch({ filter: { jobId: job.id } }).pipe(
            map((response)  => response.data?.calendarEvents?.events),
            filter((events) => Boolean(events)),
          ).toPromise();

          const prompt = 'You marked the job as invoiced. However,the following events have not been marked as complete: ';

          const confirmationOutput = await this.eventsHelper.confirmCompleteEvents(jobEvents, prompt);

          if (confirmationOutput.confirmed) {
            this.eventsHelper.markComplete(confirmationOutput.incompleteEvents);
          }

          this.documentsHelper.openCreateInvoiceDialog(jobId);
        }

        if (afterPromoteCb) {
          afterPromoteCb(job);
        }
        resolve({ job, promotedToStage: nextValidStage });
      }, (err) => {
        console.error(`Error transitioning job ${ job.id } to ${ nextValidStage }`, err);
        reject(err);
      });
    });

  }

}
