import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Dayjs } from 'dayjs';

export const ScheduleActions = createActionGroup({
	source: 'Schedule',
	events: {
		'Init Schedules Component': emptyProps(),
		'Set Date': props<{
			date: Dayjs;
		}>(),
	},
});
