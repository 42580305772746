<div
    class="card"
    [class.hide]="!showHeaderCard"
>
    <div class="p-grid">
        <div class="p-col">
            <h4>Locations</h4>
        </div>
        <div class="p-col">
            <button pButton  *hasPermission ="['locations.create']" label="Create" icon="pi pi-plus" (click)="openMutateDialog()"></button>
        </div>
        <div class="p-col p-ai-center">
            <div style="white-space: nowrap;">
                <span class="p-input-icon-left" style="display: inline-block;">
                    <i
                        *ngIf="!locationsQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="locationsQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #locationSearchInput
                        pInputText
                        [(ngModel)]="locationsQH.search"
                        placeholder="Search"
                    >
                </span>
                    <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="locationsQH.search = ''; searchForLocations()"
                ></button>
            </div>
        </div>
    </div>
</div>

<br>

<div class="card">
    <p-table
        [value]="locations"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreLocations($event)"
        [lazyLoadOnInit]="true"
        [lazy]="true"
        [totalRecords]="locationsQH.total"
        [(rows)]="locationsQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="locationsQH.loading"
        [showCurrentPageReport]="!locationsQH.loading"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Area Code</th>
                <th>Country</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-location
        >
            <tr
                class="selectable-row"
                (click)="selectLocation(location)"
            >
                <td>{{location.name}}</td>
                <td>{{location.addressLineOne | sliceText : 80}}</td>
                <td>{{location.city}}</td>
                <td>{{location | areaCode}}</td>
                <td>{{location.country}}</td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'5'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-mutate-location #mutate mutateType="create"></app-mutate-location>