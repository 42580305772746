import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FetchPolicy } from '@apollo/client/core';
import { GraphQLModule, PlusAuthenticationService } from '@karve.it/core';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';


import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { environment } from '../../environments/environment';
import { CurrentBrandingGQL, CurrentBrandingQuery, CurrentBrandingQueryVariables, CurrentZoneGQL, CurrentZoneQuery, CurrentZoneQueryVariables } from '../../generated/graphql.generated';
import { ColorSchemeService } from '../color-scheme.service';
import { ColorScheme } from '../colors';
import { BrandingIconSize } from '../global.constants';

import { featuresRetrieved, genFeatureSelector } from '../state/featureFlags.store';

import { AppLoadingService } from './app-loading.service';
import { FeatureName, features } from './features';
import { PermissionService } from './permission.service';

/**
 * Handles the current context, including
 * application branding and information about the currently authenticated
 * zones.
 *
 * **currentBranding**: A behaviour subject containing the applications current ZoneBranding
 * Updated any time the zone changes.
 *
 * **currentZone**: A behaviour subject containing the applications current zone. Updated
 * any time the zone changes
 */
@Injectable({
  providedIn: 'root'
})
export class BrandingService implements OnDestroy {

  subs = new SubSink();

  /**
   * The current (or last) branding - always defined, may be inaccurate if we
   * are loading the next zone it will still have the old zone loaded
   */
  public currentBranding = new BehaviorSubject<CurrentBrandingQuery['branding']>(environment.defaultBranding);

  public loading = new BehaviorSubject<boolean>(true);

  private currentZone$ = new BehaviorSubject<CurrentZoneQuery['currentZone']>(undefined);

  private favicon: HTMLLinkElement = document.querySelector('#appIcon');

  private brandingWatchQuery: ReturnType<CurrentBrandingGQL['watch']>;

  private currentZoneQuery: QueryRef<CurrentZoneQuery, CurrentZoneQueryVariables>;

  private fetchPolicy: FetchPolicy = 'cache-first';

  private loadingZone: string;

  private initialized = false;

  constructor(
    private plusAuth: PlusAuthenticationService,
    private brandingGQL: CurrentBrandingGQL,
    private colorSchemeService: ColorSchemeService,
    private graphqlModule: GraphQLModule,
    private appLoadingService: AppLoadingService,
    private currentZoneGQL: CurrentZoneGQL,
    private permissionSvc: PermissionService,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private doc: Document
  ) {}


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Reload or initialize the branding for the current context in an async way
   */
  reloadBranding() {
    if (this.plusAuth.isDeauthenticating()) {
      return;
    }

    if (this.brandingWatchQuery) {
      this.brandingWatchQuery.resetLastResults();
      return this.brandingWatchQuery.setVariables(this.getVariables());
    }

    this.brandingWatchQuery = this.brandingGQL.watch(this.getVariables(), {
      fetchPolicy: this.fetchPolicy,
      useInitialLoading: true,
    });

    this.subs.sink = this.brandingWatchQuery.valueChanges.subscribe({
      next: (res) => {
        this.appLoadingService.error = undefined;
        this.loading.next(res.loading);
  
        if (res.loading){
          // this might be wrong to update too soon
          this.permissionSvc.updatePermissions(undefined);
          return;
        }
  
        if (!res.data?.branding) {
          this.permissionSvc.updatePermissions(undefined);
          return;
        }
  
        const vars = this.getVariables();
        const matchesZone = vars.zoneId === res?.data?.branding?.zoneId;
  
        if (!matchesZone) {
          return;
        }
  
        this.setFavicon(res.data.branding.ico);
        this.currentBranding.next(res.data.branding);
        this.store.dispatch(featuresRetrieved({
          features: res.data.features || [],
        }));

        const permissions = res.data.permissions.permissions;
        this.permissionSvc.updatePermissions(permissions);
      },
      error: (err) => {
        console.error(`Error retrieving branding`, err);
        this.currentBranding.next(environment.defaultBranding);
        this.permissionSvc.updatePermissions(undefined);
        this.loading.next(false);
        this.appLoadingService.error = err;
      }
    });

    // return this.brandingWatchQuery.valueChanges.toPromise();
  }

  reloadCurrentZone() {
    if (!this.currentZoneQuery) { return; }
    this.currentZoneQuery.refetch();
  }

  watchCurrentZone(){
    if (this.currentZone$?.value){
      console.error(`Current Zone already defined`);
    }

    this.currentZoneQuery = this.currentZoneGQL.watch({});

    this.subs.sink = this.currentZoneQuery.valueChanges.subscribe((res) => {
      // Loading prevents recieving duplicates from the cache and stale prevents recieving out of date values of context change
      if (res.loading) {
        return;
      }

      if (res.data?.currentZone) {
        console.log('Current Zone: ', res.data.currentZone.id);
        this.setCurrentZone(res.data.currentZone);
        // Get the branding for the current zone after it is set
        this.reloadBranding();
      }
    });
  }

  /**
   * Do not call .next on this value!!!
   * This will break the update flow
   */
  currentZone(){
    return this.currentZone$;
  }

  setCurrentZone(currentZone: CurrentZoneQuery['currentZone']) {
    if (this.currentZone().value?.id !== currentZone.id) {
      this.currentZone$.next(currentZone);

      // This will check that we are on the right url for this zone and move us if we are not
      // if (this.doc.location.host !== currentZone.domain && currentZone?.domain && isDevMode() === false){
        // this.doc.location.href = `https://${currentZone.domain}/`;
      // }
    }
  }

  setFavicon(str: string) {
    const url = new URL(str, str.startsWith('http') ? '' : location.origin);
    this.favicon.href = url.href;
  }

  /**
   * Put the branding watch query in standby, meaning it won't be reloaded
   * when refetchObservableQueries is called
   *
   * @param standby whether to be in standby or not
   */
  setStandby(standby: boolean) {
    if (standby) {
      this.brandingWatchQuery.setOptions({
        fetchPolicy: 'standby',
      });
    } else {
      this.brandingWatchQuery.setOptions({
        fetchPolicy: this.fetchPolicy,
      });
      // this.brandingWatchQuery.options.fetchPolicy = this.fetchPolicy;
    }
  }

  /**
   * Return an observable which contains the most up to date path to the
   * icon at a specific size for the application.
   *
   * This observable updates when the zone changes and when the branding changes
   * and will return immediately after you subscribe. If the icon does not change
   * following a zone or scheme (dark/light) change then this will not fire a subject.
   * IE only distinct icon paths will be returned.
   *
   * HTML Example:
   *
   * ```
   *  <img
   *    [src]="brandingService.watchIcon(256) | async"
   *    alt="Logo"
   *  />
   * ```
   *
   * @param desiredSize The requested icon size returns an image of that size.
   * Can be 48, 64, 128, 196, 256, 512, 1024
   *
   * @param forceScheme Force light or dark scheme
   * @returns And observable which you can subscribe to to receive the latest icon path
   */
  watchIcon(
    desiredSize: BrandingIconSize,
    forceScheme?: ColorScheme,
  ) {

    return combineLatest([
      this.colorSchemeService.colorScheme,
      this.currentBranding,
    ]).pipe(
      map(([ scheme, branding ]) => {

        // TODO: if we give it a weird size then try to find the next highest size
        if (forceScheme) {
          scheme = forceScheme;
        }

        const key = `icon${ scheme === 'dark' ? 'Dark' : 'Light' }${ desiredSize }`;
        return branding[key];
      }),
      distinctUntilChanged(),
    );
  }

  getVariables(): CurrentBrandingQueryVariables {
    let zoneId = localStorage.getItem(environment.lskeys.lastBrandingZone);
    // if (this.auth.user?.zone) {
    //   zoneId = this.auth.user.zone;
    //   localStorage.setItem(environment.lskeys.lastBrandingZone, zoneId);
    // }

    if (!zoneId) {
      zoneId = undefined;
    }

    if (!zoneId && this.graphqlModule.zone.value) {
      zoneId = this.graphqlModule.zone.value;
    }

    this.loadingZone = zoneId;

    return {
      zoneId,
      features: features as unknown as string[],
    };
  }

  watchFeature(feature: FeatureName) {
    return this.store.select(genFeatureSelector(feature));
  }
}
