<!--<div class="exception-body">
    <div class="exception-topbar">
        <a id="logolink" [routerLink]="['/']" class="layout-topbar-logo">
            <img [src]="'assets/layout/images/logo-' + (app.topbarTheme === 'dark' ? 'freya-white' : 'freya') + '.svg'" alt="freya-layout"/>
        </a>
    </div>
    <div class="exception-wrapper">
        <div class="exception-content">
            <a 
                href="/"
                aria-label="reload"
            >
                <img src="/assets/layout/images/pages/asset-error.svg"
                    alt="Error contacting server"
                />

            </a>

            <h1>Looks like you are offline</h1>
            <p>Changes have not been saved</p>
            <p>Please wait for the reconnection and try to save again when you are back online</p>
			<br>
			<button pButton label="Try Again" icon="pi pi-refresh" (click)="reload()"></button>
            <br>
            <small>If your still having problems connecting</small>
            <a href="mailto:support@karveit.ca">Contact Support by emailing support&#64;karveit.ca</a>
        </div>
        <div class="exception-footer">
            <h4>{{project}}</h4>
            <h6>Copyright Ⓒ {{company}}</h6>
        </div>
    </div>
</div>-->

<div class="offline-ribbon">
    <p-message
        severity="error"
        text="Looks like you are offline!
        Please wait for the reconnection and try to save changes again when you are back online."
    ></p-message>
</div>