<div class="header-with-button">
    <div>
        <h4><i [class]="productIcon"></i> Product</h4>
        <app-object-state
            [object]="product"
            objectType="product"
            [colorful]="true"
            [loading]="productQH.loading"
            ></app-object-state>
    </div>
    <div>
        <button
            pButton
            pRipple
            label="Actions"
            *hasPermission="['products.update']"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
            data-cy="product-actions-button"
        ></button>
        <p-menu
            #menu
            [popup]="true"
            appendTo="body"
            [model]="productActions"
        ></p-menu>
    </div>
</div>

<div id="product-info" class="card">
    <h5 *ngIf="!productQH.loading; else loadingBar">
        {{ product.name }}
        <div
            class="color-square"
            [style.background-color]="product.metadata?.color"
        ></div>
        <i
            *ngIf="product.active"
            class="pi pi-check"
            [class.success-text]="true"
        ></i>
        <i
            *ngIf="!product.active"
            class="pi pi-ban"
            [class.error-text]="true"
        ></i>
    </h5>
    <p *ngIf="!productQH.loading; else loadingBar">{{ product.description }}</p>
    <p *ngIf="!productQH.loading; else loadingBar">
        <span
            >Category: {{ product.category ? product.category : "None" }}</span
        >
    </p>
    <p *ngIf="!productQH.loading; else loadingBar">
        Created: {{ product.createdAt | freyaDate }}
    </p>
    <p *ngIf="!productQH.loading; else loadingBar">
        Unit: {{ product.unitLabel }}
    </p>
</div>

<div *ngIf="!productQH.loading && product?.prices; else loadingBar">
    <h5>Price{{ product.prices.length === 1 ? "" : "s" }}</h5>
    <!-- No price - add one -->
    <ng-container *ngIf="product.prices.length === 0">

    </ng-container>

    <p-table
        *ngIf="product?.prices.length > 0"
        [value]="product.prices"
        [tableStyle]="{'width': '100%'}"
        dataKey="id"
        selectionMode="single"
        (onRowSelect)="openPrice($event)"
    >
        <ng-template pTemplate="body" let-price>
            <tr [pSelectableRow]="price" class="price-item">
                <td>
                    Name:
                    <br />
                    Zone: 
                    <br />
                    Amount: 
                </td>
                <td>
                    
                    <i
                        *ngIf="price.active"
                        class="pi pi-check"
                        [class.success-text]="price.active"
                    ></i>
                    <i
                        *ngIf="!price.active"
                        class="pi pi-ban"
                        [class.error-text]="price.active"
                    ></i>
                    {{ price.name }}
                    <br />
                      {{ price.zone.name }}
                    <br />
                    <ng-container *ngIf="price.priceType !== 'percentage'">
                        {{price.amount / 100 | currency: price.currency}}
                    </ng-container>
                    <ng-container *ngIf="price.priceType === 'percentage'">
                        {{price.amount}}%
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<!-- TODO: show count of jobs that use this product? Show all jobs with this product? -->

<ng-template #loadingBar>
    <p-skeleton width="100%" height="24px"></p-skeleton>
</ng-template>
