import { Injectable } from '@angular/core';
import {FetchResult} from '@apollo/client/core';
import { GetConfigValuesInput, ListGetConfigValuesOutput, getConfigValuesQuery, SetConfigValuesInput, SetConfigValuesOutput, setConfigValuesMutation } from '@karve.it/interfaces/config';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private apollo: PlusApollo,
  ) { }

  getConfigValues(input: GetConfigValuesInput, overrideZone: string = ''){
    return this.apollo.query<ListGetConfigValuesOutput>({
      query: getConfigValuesQuery,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-zone': overrideZone,
        },
      },
      variables: {
        ...input
      },
    });
  }

  watchConfigValues(input: GetConfigValuesInput){
    return this.apollo.watchQuery<ListGetConfigValuesOutput>({
      query: getConfigValuesQuery,
      variables: {
        ...input
      }
    });
  }

  setConfigValues(input: SetConfigValuesInput, overrideZone: string = ''){
    return this.apollo.mutate<SetConfigValuesOutput>({
      mutation: setConfigValuesMutation,
      context: {
        headers: {
          'x-zone': overrideZone,
        },
      },
      variables: {
        ...input
      }
    });
  }


}
