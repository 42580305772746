import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Artifact } from '@karve.it/interfaces/artifacts';
import { determineArtifactType } from 'src/app/artifacts/artifact.util';
import { FreyaHelperService, ViewableArtifact } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { PandadocArtifactFragment } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-artifact-dialog',
  templateUrl: './artifact-dialog.component.html',
  styleUrls: ['./artifact-dialog.component.scss']
})
export class ArtifactDialogComponent implements OnInit, OnDestroy {

  showDialog: boolean;
  artifact: ViewableArtifact;
  artifactSrc: string;
  artifactType: string;

  docHeight = {
    main: '90vh',
    dialogHeader: '70px',
  };

  documentDialogStyle = {
    width: '90vw',
    height: `calc(${Object.values(this.docHeight).join(' + ')})`,
    'max-height': 'unset'
  };

  subs = new SubSink();

  constructor(
    private freyaHelper: FreyaHelperService,
    private localNotify: FreyaNotificationsService,
    private platform: Platform,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.freyaHelper.artifactOpened
    .subscribe(({ artifact, artifactUrl}) => this.viewArtifact(artifact, artifactUrl));
  }

  viewArtifact(artifact: ViewableArtifact, artifactUrl: string) {
    this.artifact = artifact;

    artifactUrl = artifactUrl || artifact.signedUrl || artifact.url;

    // Mobile iOS browsers do not support embedded PDFs, so we need to pass through Goole Docs viewer
    // if (this.platform.IOS) {
    //   artifactUrl = encodeURIComponent(artifactUrl);

    //   artifactUrl = 'https://docs.google.com/viewer?url=' + artifactUrl + '&embedded=true';
    // }

    if (!artifactUrl) {
      this.localNotify.warning('Unable to open artifact', 'No URL, this document may still be generating');
      return;
    };

    this.artifactType = determineArtifactType(artifact.contentType);

    if (this.artifactType === 'Document' || this.artifactType === 'Image') {
      this.openDialog(artifactUrl);
      return;
    }
    this.freyaHelper.openInNewTab(artifactUrl);
  }

  openDialog(url) {
    this.artifactSrc = url;
    this.showDialog = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
