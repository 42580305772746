import { Artifact } from '@karve.it/interfaces/artifacts';

export interface FileRestrictions {
    minHeight: number;
    minWidth: number;

    maxHeight?: number;
    maxWidth?: number;

    allowedFileTypes: any;

    maxFileSize: number;
}

export function getPandaDocId(artifact: Artifact) {
    return artifact.metadata?.pandadocId;
};

/**
 * Return defined artifact type. If not in artifact type map return "Artifact'"
 */
export function determineArtifactType(contentType: string) {
    if (!(contentType in artifactTypeMap)) {
        return 'Artifact';
    }
    return artifactTypeMap[contentType];
}

export const artifactTypes = ['Document', 'Image', 'Video', 'Text', 'HTML', 'Spreadsheet', 'Presentation', 'Audio'] as const;

export type ArtifactType = typeof artifactTypes[number];

export const ARTIFACT_CONTENT_TYPE = [
    'application/x-7z-compressed',
    'application/vnd.americandynamics.acc',
    'application/vnd.adobe.air-application-installer-package+zip',
    'application/x-shockwave-flash',
    'application/vnd.adobe.fxp',
    'application/pdf',
    'audio/x-aac',
    'application/vnd.airzip.filesecure.azf',
    'application/vnd.airzip.filesecure.azs',
    'application/vnd.amazon.ebook',
    'application/vnd.android.package-archive',
    'application/x-apple-diskimage',
    'application/vnd.apple.installer+xml',
    'application/atom+xml',
    'audio/x-aiff',
    'video/x-msvideo',
    'application/octet-stream',
    'image/bmp',
    'application/x-bittorrent',
    'text/x-c',
    'text/css',
    'image/vnd.dvb.subtitle',
    'text/csv',
    'application/x-debian-package',
    'application/ecmascript',
    'application/epub+zip',
    'image/vnd.xiff',
    'video/x-f4v',
    'video/x-flv',
    'application/vnd.gmx',
    'image/gif',
    'video/h261',
    'video/h263',
    'video/h264',
    'application/vnd.hal+xml',
    'text/html',
    'text/calendar',
    'application/vnd.iccprofile',
    'image/x-icon',
    'image/ief',
    'application/vnd.intergeo',
    'application/vnd.cinderella',
    'application/ipfix',
    'application/pkix-cert',
    'application/pkixcmp',
    'application/pkix-crl',
    'application/pkix-pkipath',
    'application/java-archive',
    'application/java-vm',
    'text/x-java-source,java',
    'application/javascript',
    'application/json',
    'video/jpm',
    'image/jpeg',
    'image/jpg',
    'video/jpeg',
    'audio/x-mpegurl',
    'video/x-m4v',
    'model/mesh',
    'application/x-msdownload',
    'application/vnd.ms-fontobject',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.addin.macroenabled.12',
    'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    'application/vnd.ms-excel.template.macroenabled.12',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/vnd.ms-htmlhelp',
    'application/x-mscardfile',
    'application/vnd.ms-lrm',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/x-msbinder',
    'application/onenote',
    'audio/vnd.ms-playready.media.pya',
    'video/vnd.ms-playready.media.pyv',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.addin.macroenabled.12',
    'application/vnd.ms-powerpoint.slide.macroenabled.12',
    'application/vnd.ms-powerpoint.presentation.macroenabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
    'application/vnd.ms-powerpoint.template.macroenabled.12',
    'application/vnd.ms-project',
    'application/x-mspublisher',
    'application/x-msschedule',
    'application/x-silverlight-app',
    'application/vnd.ms-pki.stl',
    'application/vnd.ms-pki.seccat',
    'application/vnd.visio',
    'application/vnd.visio2013',
    'video/x-ms-wm',
    'audio/x-ms-wma',
    'audio/x-ms-wax',
    'video/x-ms-wmx',
    'application/x-ms-wmd',
    'application/vnd.ms-wpl',
    'application/x-ms-wmz',
    'video/x-ms-wmv',
    'video/x-ms-wvx',
    'application/x-msmetafile',
    'application/x-msterminal',
    'application/msword',
    'application/vnd.ms-word.document.macroenabled.12',
    'application/vnd.ms-word.template.macroenabled.12',
    'application/x-mswrite',
    'application/vnd.ms-works',
    'application/x-ms-xbap',
    'application/vnd.ms-xpsdocument',
    'audio/midi',
    'video/mj2',
    'audio/mpeg',
    'video/vnd.mpegurl',
    'video/mpeg',
    'application/mp21',
    'audio/mp4',
    'video/mp4',
    'application/mp4',
    'application/oda',
    'application/ogg',
    'audio/ogg',
    'video/ogg',
    'audio/webm',
    'video/webm',
    'application/vnd.oasis.opendocument.chart',
    'application/vnd.oasis.opendocument.chart-template',
    'application/vnd.oasis.opendocument.database',
    'application/vnd.oasis.opendocument.formula',
    'application/vnd.oasis.opendocument.formula-template',
    'application/vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.graphics-template',
    'application/vnd.oasis.opendocument.image',
    'application/vnd.oasis.opendocument.image-template',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.presentation-template',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.spreadsheet-template',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.text-master',
    'application/vnd.oasis.opendocument.text-template',
    'image/ktx',
    'application/vnd.sun.xml.calc',
    'application/vnd.sun.xml.calc.template',
    'application/vnd.sun.xml.draw',
    'application/vnd.sun.xml.draw.template',
    'application/vnd.sun.xml.impress',
    'application/vnd.sun.xml.impress.template',
    'application/vnd.sun.xml.math',
    'application/vnd.sun.xml.writer',
    'application/vnd.sun.xml.writer.global',
    'application/vnd.sun.xml.writer.template',
    'application/x-font-otf',
    'image/vnd.adobe.photoshop',
    'application/pkcs10',
    'application/x-pkcs12',
    'application/pkcs7-mime',
    'application/pkcs7-signature',
    'application/x-pkcs7-certreqresp',
    'application/x-pkcs7-certificates',
    'application/pkcs8',
    'image/x-portable-bitmap',
    'image/png',
    'image/x-citrix-png',
    'image/x-png',
    'application/postscript',
    'application/x-font-type1',
    'application/vnd.powerbuilder6',
    'application/pgp-encrypted',
    'application/pgp-signature',
    'video/quicktime',
    'application/x-rar-compressed',
    'audio/x-pn-realaudio',
    'audio/x-pn-realaudio-plugin',
    'application/rsd+xml',
    'application/vnd.rn-realmedia',
    'application/vnd.realvnc.bed',
    'application/rdf+xml',
    'application/rtf',
    'text/richtext',
    'application/rss+xml',
    'application/shf+xml',
    'image/svg+xml',
    'application/vnd.sus-calendar',
    'application/sru+xml',
    'application/set-payment-initiation',
    'application/set-registration-initiation',
    'application/scvp-vp-request',
    'application/scvp-vp-response',
    'application/scvp-cv-request',
    'application/scvp-cv-response',
    'application/sdp',
    'video/x-sgi-movie',
    'application/thraud+xml',
    'application/x-shar',
    'image/x-rgb',
    'application/smil+xml',
    'application/sbml+xml',
    'text/tab-separated-values',
    'image/tiff',
    'application/x-tar',
    'application/x-tcl',
    'application/x-tex',
    'application/tei+xml',
    'text/plain',
    'application/timestamped-data',
    'text/troff',
    'application/x-font-ttf',
    'text/turtle',
    'application/vnd.unity',
    'application/vnd.ufdl',
    'text/uri-list',
    'text/x-vcalendar',
    'text/x-vcard',
    'application/x-cdlink',
    'application/vnd.vsf',
    'application/ccxml+xml',
    'application/voicexml+xml',
    'image/vnd.wap.wbmp',
    'audio/x-wav',
    'application/x-font-woff',
    'application/wspolicy+xml',
    'image/webp',
    'application/vnd.wordperfect',
    'application/x-x509-ca-cert',
    'application/xhtml+xml',
    'application/xml',
    'application/xcap-diff+xml',
    'application/xenc+xml',
    'application/patch-ops-error+xml',
    'application/resource-lists+xml',
    'application/rls-services+xml',
    'application/resource-lists-diff+xml',
    'application/xslt+xml',
    'application/xop+xml',
    'application/xspf+xml',
    'application/vnd.mozilla.xul+xml',
    'chemical/x-xyz',
    'text/yaml',
    'application/yang',
    'application/yin+xml',
    'application/zip',

];

export type ArtifactContentType = typeof ARTIFACT_CONTENT_TYPE[number];

type ArtifactContentTypeMap = {
    [key in ArtifactContentType]?: ArtifactType;
};


export const artifactTypeMap: ArtifactContentTypeMap = {
    'application/pdf': 'Document',  //.pdf
    'application/msword': 'Document',//.doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Document', //.docx
    'image/jpeg': 'Image',         //.jpeg
    'audio/mpeg': 'Audio',          //.mp3
    'image/jpg': 'Image',          //.jpg
    'image/png': 'Image',          //.png
    'application/mp4': 'Video',     //.mp4
    'video/mp4': 'Video',          //.mp4
    'video/webm': 'Video',          //.webm
    'video/mpeg': 'Video',           //.mpeg
    'application/vnd.oasis.opendocument.text': 'Text',//.txt
    'text/html': 'HTML',            //.html
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Spreadsheet', //.xlsx
    'application/vnd.ms-powerpoint': 'Presentation', //.ppt
    'application/vnd.ms-excel': 'Presentation', //.xls
    'audio/x-wav': 'Audio',        //.wav
};
