import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Artifact } from '@karve.it/interfaces/artifacts';
import { RawUser } from '@karve.it/interfaces/auth';
import { QueryRef } from 'apollo-angular';

import { MenuItem } from 'primeng/api';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { SubSink } from 'subsink';

import { PandadocArtifactFragment, ResendDocumentGQL, ResendDocumentMutationVariables, ViewArtifactsGQL, ViewArtifactsQuery, ViewArtifactsQueryVariables } from '../../../generated/graphql.generated';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';

import { PermissionService } from '../../services/permission.service';
import { determineArtifactType, getPandaDocId } from '../artifact.util';


@Component({
  selector: 'app-artifact-details',
  templateUrl: './artifact-details.component.html',
  styleUrls: ['./artifact-details.component.scss']
})
export class ArtifactDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() artifact: PandadocArtifactFragment;

  artifactType: string;
  user: RawUser;
  isPandadoc: boolean;

  permissions = {
    delete: false,
    resend: false,
  };

  disableIf = {
    isArchived: [ 'resend', 'delete' ],
    isDeleted: [ 'resend', 'delete' ],
    noUrl: [ 'view', 'download', 'customer', 'employee', 'print' ],
    noCustomerEmail: [ 'resend' ],
  };

  viewArtifactAction: MenuItem = {
    id: 'view',
    label: 'View',
    icon: 'pi pi-eye',
    command: () => {
      this.freyaHelper.openInDialog(this.artifact);
    }
  };

  downloadArtifactAction: MenuItem = {
    id: 'download',
    label: 'Download',
    icon: 'pi pi-download',
    command: () => {
      this.documentHelper.downloadDocument(this.artifact);
    }
  };

  deleteArtifactAction: MenuItem = {
    id: 'delete',
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.freyaMutate.openDeleteObject({
        objectId: this.artifact.id,
        objectName: this.artifact.name,
        objectType: 'artifact',
      });
    }
  };

  viewAsUserInPandadocAction: MenuItem = {
    id: 'employee',
    label: 'View as Employee',
    icon: 'pi pi-external-link',
    command: () => {
      this.documentHelper.openInPandadoc(this.artifact, 'employee');
    }
  };

  viewAsCustomerInPandadocAction: MenuItem = {
    id: 'customer',
    label: 'View as Customer',
    icon: 'pi pi-external-link',
    command: () => {
      this.documentHelper.openInPandadoc(this.artifact, 'customer');
    },
  };

  resendDocumentAction: MenuItem = {
    id: 'resend',
    label: 'Resend Document',
    icon: 'pi pi-send',
    command: () => {
      this.resendDocument();
    },
  };

  printArtifactAction: MenuItem = {
    id: 'print',
    label: 'Print',
    icon: 'pi pi-print',
    command: () => {
      this.documentHelper.printDocument(this.artifact);
    }
  };

  artifactActions = [{
    label: 'Actions',
    items: [
      this.viewArtifactAction,
      this.downloadArtifactAction,
      this.printArtifactAction,
      this.viewAsUserInPandadocAction,
      this.viewAsCustomerInPandadocAction,
      this.resendDocumentAction,
      this.deleteArtifactAction,
    ]
  }];

  subs = new SubSink();

  artifactsQueryRef: QueryRef<ViewArtifactsQuery>;

  constructor(
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private recentItems: RecentItemsService,
    private freyaHelper: FreyaHelperService,
    private permissionsSvc: PermissionService,
    private viewArtifactsGQL: ViewArtifactsGQL,
    private resendDocumentGQL: ResendDocumentGQL,
    private documentHelper: DocumentHelperService,
    private freyaMutate: FreyaMutateService,
  ) { }

  ngOnInit(): void {
    this.watchPermissions();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Artifacts')
      .subscribe(() => {
        this.fetchArtifact();
        this.recentItems.setPinAction(this.artifactActions, this.artifact, 'artifact');
      });

    if (this.artifact && !this.artifact.name) {
      this.fetchArtifact();
      return;
    };

  }

  /**
   * Sets visibility and disabled status of artifact actions
   */
  setActions() {
    this.checkIfDocument();
    this.setDisabledActions();
    this.recentItems.setPinAction(this.artifactActions, this.artifact, 'artifact');
  }

  ngOnChanges() {
    if (this.artifact && !this.artifact.name) {
      this.fetchArtifact();
      return;
    };
    this.setActions();
    this.artifactType = determineArtifactType(this.artifact.contentType);
    this.isPandadoc = this.checkPandadoc();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Check if the document is from pandadocs
   */
  checkPandadoc() {
    if (getPandaDocId(this.artifact as unknown as Artifact)) {
      return true;
    }
    return false;
  }

  checkIfDocument() {
    const pandadocId = getPandaDocId(this.artifact as unknown as Artifact);

    this.viewAsUserInPandadocAction.visible = Boolean(pandadocId);
    this.viewAsCustomerInPandadocAction.visible = Boolean(pandadocId);
    this.resendDocumentAction.visible = Boolean(pandadocId) && this.permissions.resend;
    this.deleteArtifactAction.visible = this.permissions.delete;
    this.printArtifactAction.visible = Boolean(this.documentHelper.getPrintableType(this.artifact));
  }

  watchPermissions() {
    this.subs.sink = this.permissionsSvc.watch().subscribe(() => {
      
      this.permissions.delete = this.permissionsSvc.checkPermission('artifacts.delete');
      this.permissions.resend = this.permissionsSvc.checkPermission('artifacts.send');
      this.setActions();
    })
  }

  fetchArtifact() {
    const input: ViewArtifactsQueryVariables = { filter: { ids: [this.artifact.id] }, getPrivate: true };

    if (this.artifactsQueryRef) {
      this.artifactsQueryRef.refetch(input);
    }

    this.artifactsQueryRef = this.viewArtifactsGQL.watch(input, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.artifactsQueryRef.valueChanges
    .subscribe((res) => {
      if (res.loading) { return; }
      this.artifact = res.data.artifacts.artifacts[0];
      this.artifactType = determineArtifactType(this.artifact.contentType);
      this.isPandadoc = this.checkPandadoc();
      this.setActions();
    });
  }

  /**
   * Resend a document to the user that would have recieved it originally
   */
  resendDocument() {
    const input: ResendDocumentMutationVariables = {
      artifactId: this.artifact.id,
      message: this.artifact?.metadata?.message,
      subject: this.artifact.name,
    };

    this.resendDocumentGQL.mutate(input).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: this.artifact.id,
        type: 'Artifacts',
        action: 'update'
      });

      this.localNotify.success('Document resent to the customer');

    }, (err) => {
      console.error(err);
      this.localNotify.apolloError(`Failed to resend document`,err);
    });
  }

  /**
   * Sets disabled status of artfifact actions.
   */
  setDisabledActions() {
    const [ actionsMenu ] = this.artifactActions;
    const { items: actions } = actionsMenu;

    for (const action of actions) {
      const hasUrl = this.artifact.signedUrl || this.artifact.url;

      if (this.disableIf.noUrl.includes(action.id) && !hasUrl) {
        this.freyaHelper.disableAction(action);
        continue;
      }

      if (this.disableIf.noCustomerEmail.includes(action.id) && this.artifact.metadata?.placeholderEmail) {
        this.freyaHelper.disableAction(action, 'no customer email');
        continue
      }

      if (this.disableIf.isArchived.includes(action.id) && this.artifact.archivedAt) {
        this.freyaHelper.disableAction(action, 'artifact is archived');
        continue;
      }

      if (this.disableIf.isDeleted.includes(action.id) && this.artifact.deletedAt) {
        this.freyaHelper.disableAction(action, 'artifact was deleted');
        continue;
      }

      this.freyaHelper.enableAction(action);
    }
  }
}
