import { Component } from '@angular/core';

export const ERROR_CONTAINER_ID = 'error-container';

@Component({
  selector: 'app-error-icon',
  templateUrl: './error-icon.component.html',
  styleUrl: './error-icon.component.scss'
})
export class ErrorIconComponent {

  scrollToErrorContainer() {

    const errorContainer = document.getElementById(ERROR_CONTAINER_ID);

    const dashboard = document.getElementsByClassName('layout-main')[0];

    if (!errorContainer) { return; }

    const elementPosition = errorContainer.getBoundingClientRect().top;

    dashboard.scrollTo({
      top: elementPosition,
      behavior: 'smooth',
    });
  }
}
