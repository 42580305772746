import { User } from '@karve.it/interfaces/auth';

// Franchise Info
export const defaultFranchiseInfo = {
    owner: undefined,
    email: undefined,
    phone: undefined,
    // address: undefined,

    editMode: false,
};

export const defaultSystemDetails = {
    franchiseNumber: undefined,
    name: undefined,
    status: undefined,
    timezone: undefined,
    language: undefined,
    country: undefined,
    // currency: undefined,
};

export const defaultLegalCard = {
    businessAddress: undefined,
    franchiseOwners: undefined,
    businessNumber: undefined,
    legalCompanyName: undefined,
    operatingAs: undefined,
    operatingFor: undefined,
    policyNumber: undefined,
    taxNumbers: undefined,
};

export const defaultContactDetails = {
    crewManager: undefined,
    officeManager: undefined,
    salesManager: undefined,
};
