<div class="job-participant">
    <header class="flex participant-role">
        <span class="role-name"> {{ role }} </span>
        <button
            *hasPermission="['jobs.update']"
            class="plain-button"
            (click)="toggleEditingState()"
            [disabled]="isLoading"
        >
            {{ isEditing ? "Close" : "Edit" }}
        </button>
    </header>
    <p-skeleton
        *ngIf="isLoading; else mainContent"
        width="100%"
        height="1.5rem"
        styleClass="p-mt-1"
    ></p-skeleton>
    <ng-template #mainContent>
        <div *ngIf="!isEditing; else userSearchTemplate">
            <p
                *ngIf="participant; else assignSelfTemplate"
                (click)="openUserDetails(participant)"
                class="participant-name"
            >
                {{ participant?.givenName + " " + participant?.familyName }}
            </p>
            <ng-template #assignSelfTemplate>
                <p>
                    None -
                    <button *hasPermission="['jobs.update']" class="plain-button" (click)="assignYourself()">
                        assign yourself
                    </button>
                </p>
            </ng-template>
        </div>
        <ng-template #userSearchTemplate>
            <div class="flex add-role">
                <p-button
                    *ngIf="participant"
                    label="Unassign"
                    icon="pi pi-user-minus"
                    iconPos="left"
                    size="small"
                    styleClass="p-button-outlined p-button-danger"
                    (onClick)="removeParticipant()"
                ></p-button>
                <app-user-search
                    [searchFilter]="userSearchOptions"
                    [zoneId]="job?.zone.id"
                    (userSelected)="onUserSelected($event)"
                ></app-user-search>
            </div>
        </ng-template>
    </ng-template>
</div>
