import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notSet'
})
export class NotSetPipe implements PipeTransform {

  transform(value: any, notSetString = 'Not Set'): any {
    if (value !== undefined && value !== null) {
      return value;
    } else {
      return notSetString;
    }
  }

}
