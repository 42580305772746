import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input,
  OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService, TagsService } from '@karve.it/features';
import {
  GenerateLocationQueryInput, ListLocationsInput,
  ListLocationsOutput, Location, LocationsFilter
} from '@karve.it/interfaces/locations';
import { ListTagsOutput, TAG_TYPES } from '@karve.it/interfaces/tag';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { Tag } from 'primeng/tag';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';

import { MutateLocationComponent } from '../shared/mutate-location/mutate-location.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('mutate') mutate: MutateLocationComponent;
  @ViewChild('locationSearchInput', { static: true }) searchInput!: ElementRef;

  // Filter Variables
  @Input() filter: LocationsFilter = {};
  @Input() pagination = pagination;

  // Modify Component Layout
  @Input() showHeaderCard = true;

  subs = new SubSink();

  tags: Tag[] = [];

  // Component Variables
  locations: Location[] = [];

  // Table Variables
  cols = [
    { field: 'stage', header: 'Stage' },
    { field: 'startLocation', header: 'Start' },
    { field: 'endLocation', header: 'End' },
    { field: 'total', header: 'Total' }
  ];

  // Location Query Variables
  locationsQueryRef!: QueryRef<ListLocationsOutput>;
  locationsQH: WatchQueryHelper = {
    limit: this.pagination.defaultNumberOfItems,
    skip: 0,
    loading: true,
    search: ''
  };

  constructor(private detailsHelper: DetailsHelperService,
    private locationsService: LocationService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private tagService: TagsService) { }

  ngOnInit(): void {
    this.locations = [];
    this.subs.sink = this.detailsHelper.getObjectUpdates('Locations').subscribe(() => {
      this.locationsQueryRef.refetch();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.locationsQH.limit = this.pagination.defaultNumberOfItems;
  }

  ngAfterViewInit() {
    this.ref.detectChanges();

    this.subs.sink = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.locationsQH.skip = 0;

      this.searchForLocations();
    });
  }

  retrieveLocations() {
    this.filter.limit = this.locationsQH.limit;

    const watchLocationsInput = {
      filter: this.filter,
    } as ListLocationsInput;

    const locationsQueryInput = {
      attributes: true,
    } as GenerateLocationQueryInput;

    this.locationsQueryRef = this.locationsService.watchLocations(watchLocationsInput, locationsQueryInput);

    this.subs.sink = this.locationsQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7 && res.data.locations?.locations) {
        this.locations = res.data.locations.locations;
        this.locationsQH.total = res.data.locations.total;
        this.locationsQH.loading = false;
      }
    });
  }

  // Pagination
  retrieveMoreLocations(event: LazyLoadEvent) {

    this.locationsQH.limit = event.rows;
    this.locationsQH.skip = event.first;

    this.searchForLocations();
  }

  // Search For Locations based on filter values
  searchForLocations() {
    this.locationsQH.loading = true;

    if (this.locationsQueryRef) {
      this.filter.limit = this.locationsQH.limit;
      this.filter.skip = this.locationsQH.skip;
      this.filter.address = this.locationsQH.search;

      this.locationsQueryRef.resetLastResults();
      this.locationsQueryRef.setVariables(
        {
          filter: this.filter
        });
    } else {
      this.retrieveLocations();
    }
  }

  selectLocation(location: Location) {
    this.detailsHelper.detailsItem.next({ type: 'location', item: location });
  }

  openMutateDialog() {
    this.mutate.mutateType = 'create';
    this.mutate.owner = this.filter.users[0];
    this.mutate.openDialog();
  }

}
