import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any[], property: string, search: string): unknown {
    return array.filter((p) => p[property].toLowerCase().indexOf(search.toLowerCase()) >= 0);
  }


}
