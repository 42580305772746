import { Injectable } from '@angular/core';

import { AssignObjectsToZonesInput, assignObjectsToZonesMutation, AssignObjectsToZonesOutput, availableZonesQuery, AvailableZonesResult, CreateZonesInput, createZonesMutation, CreateZonesOutput, generateZonesQuery, GenerateZonesQueryInput, GetServiceAreaInput, getServiceAreaQuery, ListGetServiceAreaOutput, ListZonesFunctionOutput, RemoveZonesInput, removeZonesMutation, RemoveZonesOutput, UpdateZonesInput, updateZonesMutation, UpdateZonesOutput, ZonesInput } from '@karve.it/interfaces/zones';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private apollo: PlusApollo) { }

  /**
   * Create new zones as children of existing zones including the root zone.
   *
   * @param input Input parameters for the zones.
   * @returns id of the created zone.
   */
  createZones(input: CreateZonesInput) {
    return this.apollo.mutate<CreateZonesOutput>({
      mutation: createZonesMutation,
      variables: {
        ...input
      }
    });
  }

  /**
   * Edit one or multiple zones with the same edit input.
   *
   * @param input Input paramaters for editing
   * @returns Id of the edited zone.
   */
  updateZones(input: UpdateZonesInput) {
    return this.apollo.mutate<UpdateZonesOutput>({
      mutation: updateZonesMutation,
      variables: {
        ...input
      }
    });
  }

  /**
   * Delete one or multiple zones from the database.
   *
   * @param ids The ids of the zone you want to delete.
   * @returns The number of zones deleted
   */
  removeZones(input: RemoveZonesInput) {
    return this.apollo.mutate<RemoveZonesOutput>({
      mutation: removeZonesMutation,
      variables: {
        ...input
      }
    });
  }

  listZones(input: ZonesInput, queryInput: GenerateZonesQueryInput) {
    return this.apollo.query<ListZonesFunctionOutput>({
      query: generateZonesQuery(queryInput),
      variables: {
        ...input
      }
    });
  }

  watchZones(input: ZonesInput, queryInput: GenerateZonesQueryInput) {
    return this.apollo.watchQuery<ListZonesFunctionOutput>({
      query: generateZonesQuery(queryInput),
      variables: {
        ...input
      }
    });
  }

  getServiceArea(input: GetServiceAreaInput) {
    return this.apollo.query<ListGetServiceAreaOutput>({
      query: getServiceAreaQuery,
      variables: {
        ...input
      }
    });
  }

  assignObjectsToZones(input: AssignObjectsToZonesInput) {
    return this.apollo.mutate<AssignObjectsToZonesOutput>({
      mutation: assignObjectsToZonesMutation,
      variables: {
        ...input
      }
    });
  }


}
