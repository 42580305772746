import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';
import { VarDirective } from './var.directive';



@NgModule({
  declarations: [
    VarDirective,
    ValidationMessagesComponent,
  ],
  exports: [
    VarDirective,
    ValidationMessagesComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class LibModule { }
