
/**
 * Determines if a color is light or dark
 *
 * @param color The hex color you want to use
 * @returns True if it's a light color false if it's a dark color
 */
export function isHexColorLight(color) {
    if (!color.includes('#')){
        console.warn(`Color must be in hexcode format`);
        return true;
    }

    const hex = color.replace('#', '');
    const cr = parseInt(hex.substr(0, 2), 16);
    const cg = parseInt(hex.substr(2, 2), 16);
    const cb = parseInt(hex.substr(4, 2), 16);
    const brightness = ((cr * 299) + (cg * 587) + (cb * 114)) / 1000;
    return brightness > 155;
};


/**
 * Returns an appropriate text color based on the provided background color
 *
 * @param backgroundColor The background color for the text
 * @returns Text color in hexcode format
 */
export function getTextColorForBackground(backgroundColor){
    // If the color is light apply a dark text, if the background is dark apply a light text
    if (isHexColorLight(backgroundColor)) {
        return '#3E4754'; // Dark Text
        } else {
        return '#EAEBEC'; // Light Text
    }
};
