<div [formGroup]="jobForm">
    <ng-template #startLocation>
        <div *appVar="jobForm.get('startLocation') as control">
            <label>Start Location</label>
            <br />
            <input
                *ngIf="googleHelper.loaded | async"
                class="location-input"
                formControlName="startLocation"
                [options]="googleOptions"
                pInputText
                ngx-google-places-autocomplete
                (onAddressChange)="handleAddressChange($event, 'startLocation')"
                [class.p-invalid-input]="!control.valid && control.touched"
            />
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #endLocation>
        <div *appVar="jobForm.get('endLocation') as control">
            <label>End Location</label>
            <br />
            <input
                *ngIf="googleHelper.loaded | async"
                class="location-input"
                formControlName="endLocation"
                [options]="googleOptions"
                pInputText
                ngx-google-places-autocomplete
                (onAddressChange)="handleAddressChange($event, 'endLocation')"
                [class.p-invalid-input]="!control.valid && control.touched"
            />
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #stage>
        <div *appVar="jobForm.get('stage') as control">
            <label>Stage</label>
            <br>
            <p-dropdown
                appendTo="body"
                placeholder="Select a Stage"
                [options]="stages"
                formControlName="stage"
                optionLabel="label"
                optionValue="value"
                >
            </p-dropdown>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #charges>
        <div *appVar="jobForm.get('charges') as control">
            <div
                class="p-grid"
                style="max-height: 60vh; overflow-y: auto;"
            >
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-4"><strong>Line Item</strong></div>
                        <div class="p-col-2"><strong>Amount</strong></div>
                        <div class="p-col-2"><strong>Quantity</strong></div>
                        <div class="p-col-2"><strong>Subtotal</strong></div>
                        <div class="p-col-1"><strong></strong></div>
                    </div>
                </div>
                <div
                    class="p-col-12 p-ai-center"
                    *ngFor="let charge of control.value"
                >
                    <div class="p-grid">
                        <div class="p-col-4">
                            <span *ngIf="charge.product?.name">
                                {{charge.product.name}}
                            </span>
                            <span *ngIf="charge.productName && charge.id">
                                {{charge.productName}}
                            </span>
                            <span *ngIf="(!charge.product || !charge.product.name) && !charge.id">
                                <p-autoComplete
                                    #chargeProduct
                                    field="name"
                                    [(ngModel)]="charge.productName"
                                    [ngModelOptions]="{standalone: true}"
                                    [suggestions]="productSuggestions"
                                    (completeMethod)="searchProduct($event)"
                                    (onSelect)="selectProduct(charge, $event)"
                                    [dropdown]="true"
                                    appendTo="body"
                                ></p-autoComplete>
                            </span>
                        </div>
                        <div class="p-col-2">
                            <span *ngIf="charge.price">
                                {{charge?.price?.amount / 100 | currency : job.currency}}
                            </span>
                            <span *ngIf="!charge.price && charge.product?.id">
                                {{(charge?.product?.prices | activePrice).amount / 100 | currency : job.currency}}
                            </span>
                            <span *ngIf="charge.amount && charge.id">
                                {{charge?.amount / 100 | currency : job.currency}}
                            </span>
                            <span *ngIf="!charge.product?.id && !charge.id">
                                <p-inputNumber
                                mode="currency"
                                [currency]="job.currency"
                                [(ngModel)]="charge.amount"
                                [ngModelOptions]="{standalone: true}"
                                (onInput)="markChargeAsModified(charge)"
                                ></p-inputNumber>
                            </span>
                        </div>
                        <div class="p-col-2">
                            <p-inputNumber
                                [suffix]="charge.product?.unitLabel"
                                min="0.01"
                                [minFractionDigits]="1"
                                [maxFractionDigits]="2"
                                [showButtons]="true"
                                [(ngModel)]="charge.quantity"
                                [ngModelOptions]="{standalone: true}"
                                (onInput)="markChargeAsModified(charge)"
                            ></p-inputNumber>
                        </div>
                        <div class="p-col-2">
                            {{(charge | chargeSubtotal : charge?.quantity : charge?.product) | currency :
                            job.currency}}
                            </div>
                        <div class="p-col-1">
                            <button
                                *ngIf="!charge.id"
                                [disabled]="(!charge.product?.id && (!charge.productName && !charge.amount)) || charge.quantity === 0 || (!charge.amount && !charge.product?.id)"
                                pButton
                                #addButton
                                class="p-button-success"
                                icon="pi pi-plus"
                                (click)="updateJob(); setSectionInvalid(false); addButton.disabled = true"
                            ></button>
                            <button
                                *ngIf="charge.id"
                                pButton
                                class="p-button-danger"
                                icon="pi pi-trash"
                                (click)="removeCharge(charge)"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="p-col-12">
                    <div class="p-grid p-text-center">
                        <div class="p-col">
                            <button
                                pButton
                                *ngIf="(job.users | jobCustomer) !== 'None'"
                                label="Add Charge"
                                (click)="addCharge()"
                                icon="pi pi-plus"
                            ></button>
                            <p-message class="p-mb-2" *ngIf="(job.users | jobCustomer) === 'None'" severity="warn" text="Customer required to add charges" styleClass="mr-2"></p-message>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #damages>
        <div *appVar="jobForm.get('damages') as control">
            <div
                class="p-grid"
                style="max-height: 60vh; overflow-y: auto;"
            >
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-4"><strong>Line Item</strong></div>
                        <div class="p-col-3"><strong>Amount</strong></div>
                        <div class="p-col-3"><strong>Quantity</strong></div>
                        <div class="p-col-2"><strong></strong></div>
                    </div>
                </div>
                <div
                    class="p-col-12 p-ai-center"
                    *ngFor="let damage of control.value"
                >
                    <div class="p-grid">
                        <div class="p-col-4">
                            <span *ngIf="damage.productName && damage.id">
                                {{damage.productName}}
                            </span>
                            <span *ngIf="!damage.id">  
                                <input
                                style="max-width: 100%;"
                                pInputText
                                type="text"
                                [(ngModel)]="damage.productName"
                                [ngModelOptions]="{standalone: true}"
                            >
                            </span>

                        </div>
                        <div class="p-col-3">
                            <span *ngIf="damage.id">
                                {{damage.amount * -1 / 100 | currency : job?.currency}}
                            </span>
                            <span *ngIf="!damage.id">
                            <p-inputNumber
                                mode="currency"
                                [currency]="job.currency"
                                [(ngModel)]="damage.amount"
                                [ngModelOptions]="{standalone: true}"
                            ></p-inputNumber>
                            </span>
                        </div>
                        <div class="p-col-3">
                            <p-inputNumber
                                min="1"
                                [suffix]="damage.product?.unitLabel"
                                [(ngModel)]="damage.quantity"
                                [ngModelOptions]="{standalone: true}"
                                (onInput)="markChargeAsModified(damage)"
                            ></p-inputNumber>
                        </div>
                        <div class="p-col-2">
                            <button
                                #addButton
                                *ngIf="!damage.id"
                                [disabled]="!damage.productName && !damage.amount"
                                pButton
                                class="p-button-success"
                                icon="pi pi-plus"
                                (click)="updateJob(); setSectionInvalid(false); addButton.disabled = true"
                            ></button>
                            <button
                                *ngIf="damage.id"
                                pButton
                                class="p-button-danger"
                                icon="pi pi-trash"
                                (click)="removeDamage(damage)"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="p-col-12">
                    <div class="p-grid p-text-center">
                        <div class="p-col">
                            <button
                                pButton
                                *ngIf="(job.users | jobCustomer) !== 'None'"
                                label="Add Damage"
                                (click)="addDamage(); setSectionInvalid(true)"
                                icon="pi pi-plus"
                            ></button>
                            <p-message class="p-mb-2" *ngIf="(job.users | jobCustomer) === 'None'" severity="warn" text="Customer required to add damages" styleClass="mr-2"></p-message>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #attributes>
        <div *appVar="jobForm.get('attributes') as control">
            <div class="attribute" *ngFor="let attr of attributeObjects">
                <p-checkbox
                    [label]="attr.title"
                    [pTooltip]="attr.attribute"
                    [(ngModel)]="attr.value"
                    [binary]="true"
                    [ngModelOptions]="{standalone: true}"
                ></p-checkbox>
            </div>
        </div>
    </ng-template>
    <ng-template #deposit>
        <div *appVar="jobForm.get('deposit') as control">
            <label>Deposit</label>
            <br />
            <p-inputNumber
                formControlName="deposit"
                mode="currency"
                currency="{{job.currency}}"
                placeholder="Deposit"
                [class.p-invalid-input]="!control.valid && control.touched"
            ></p-inputNumber>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #users>
        <br>
        <div *appVar="jobForm.get('users') as control">
            <div class="p-grid">
                <div
                    *ngFor="let user of control.value"
                    class="p-col-12 p-grid p-align-center"
                >
                <div class="p-col-4">
                    {{user.role}}
                </div>
                    <div class="p-col-6">
                        {{user.user.givenName}} {{user.user.familyName}}
                    </div>
                    <div class="p-col-2">
                        <button
                            pButton
                            class="p-button-danger"
                            icon="pi pi-trash"
                            (click)="removeUser(user)"
                        ></button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="p-grid" *ngIf="userJobRolesArray?.length; else noMoreUsersMessage">
                <div class="p-col-12 p-grid" [formGroup]="addUsersForm">
                    <div class="p-col-4"
                    >
                        <p-dropdown
                            placeholder="Select Job Role"
                            [options]="userJobRolesArray"
                            formControlName="role"
                            appendTo="body"
                            (onChange)="setRoleId($event)"
                            #role
                        ></p-dropdown>
                    </div>
                    <div class="p-col-6">
                        <app-user-search
                            *ngIf="addUsersForm.value.role"
                            #userSearch
                            [zoneId]="job.zone.id"
                            formControlName="user"
                            [searchFilter]='userSearchOptions'
                        ></app-user-search>
                    </div>
                    <div class="p-col-2">
                        <button
                            pButton
                            icon="pi pi-plus"
                            class="p-button-success"
                            [disabled]="!addUsersForm.valid"
                            (click)="addUser();"
                        ></button>
                    </div>
                </div>
            </div>
            <ng-template #noMoreUsersMessage>
                <div class="p-col-12 p-text-center">
                    <p>No more vacant roles. To assign a role, please remove a user from an existing role and then add.</p>
                </div>
            </ng-template>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <br>
</div>




<app-mutate-object
    #co
    [fg]="jobForm"
    objectType="Job"
    mutateType="update"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>
