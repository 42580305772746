import { BasePriceFragment } from '../../generated/graphql.generated';
import { convertDollarsToCents } from '../lib.ts/currency.util';

/**
 * Turns cents into dollars
 * and keeps percentages at full values.
 */
export function getPriceReadableAmount(
	price: BasePriceFragment,
) {
	return price.priceType === 'fixed' ? price.amount / 100 : price.amount;
}

/**
 * Turns dollars to cents
 * and keeps percentages at full values.
 */
export function getPriceSystemAmount(
	price: BasePriceFragment,
) {
	return price.priceType === 'fixed' ? convertDollarsToCents(price.amount) : price.amount;
}
