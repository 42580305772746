<div class="card" [class.hide]="!showHeaderCard">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="assets-card-header">Assets</h4>
        </div>
        <div class="p-col">
            <div style="white-space: nowrap;">
                <span class="p-input-icon-left" style="display: inline-block;">
                    <i
                        *ngIf="!assetsQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="assetsQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #assetSearchInput
                        pInputText
                        [(ngModel)]="assetsQH.search"
                        placeholder="Search"
                        data-cy="assets-search-text-field"
                    >
                </span>
                    <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="assetsQH.search = ''; searchForAssets()"
                ></button>
            </div>
        </div>
        <div class="p-col" style="display: flex; align-items: center;">
            <label class="p-mr-1">Show Deleted</label>
            <p-inputSwitch [(ngModel)]="showDeletedAssets" (onChange)="searchForAssets()"></p-inputSwitch>
        </div>
        <div class="p-col">
            <span [pTooltip]="freyaHelper.inRootOrCorporateZone ? 'You cannot create assets in your current zone': undefined">
                <button
                    pButton
                    *hasPermission="['assets.create']"
                    label="Create Asset"
                    (click)="openCreateAsset()"
                    icon="pi pi-plus"
                    [disabled]="freyaHelper.inRootOrCorporateZone"
                    data-cy="create-asset-button"
                ></button>
            </span>
        </div>
    </div>
</div>

<div class="card">
    <div class="p-grid" *ngIf="showSmallHeader">
        <div class="p-col">
            <h5>Assets</h5>
        </div>
        <div class="p-col p-text-right">
            <button pButton icon="pi pi-plus" [disabled]="true"></button>
        </div>
    </div>
    <p-table [value]="assets" dataKey="name"
        [paginator]="true" 
        (onLazyLoad)="retrieveMoreAssets($event)"
        [lazyLoadOnInit]="true"
        [lazy]="true"
        [totalRecords]="assetsQH.total" 
        [(rows)]="assetsQH.limit"
        [rowsPerPageOptions]="[5,10,25,50,100,250]" 
        [loading]="assetsQH.loading"
        [showCurrentPageReport]="!assetsQH.loading"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Zones</th>
                <th>Location</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-asset let-expanded="expanded">
            <tr class="selectable-row" (click)="viewAssetDetails(asset)">
                <td>
                    <span class="p-column-title">Name</span>
                    <span class="bold-on-mobile" data-cy="span-asset-name">{{asset.name}}</span>
                    <span *ngIf="asset.deletedAt"> (Deleted)</span>
                </td>
                <td>
                    <span class="p-column-title">Type</span>
                    {{asset.type}}
                </td>
                <td>
                    <span class="p-column-title">Zones</span>
                    {{asset | assetZones}}
                    <!-- <a
                        *ngIf="asset.owner"
                        (click)="detailsHelper.open('users-basic', asset.owner); $event.stopPropagation()"
                    >{{ asset.owner.givenName }} {{ asset.owner.familyName }}</a>
                    <span *ngIf="!asset.owner">No Owner</span> -->
                </td>
                <td>
                    <span class="p-column-title">Location</span>
                    <a
                        *ngIf="asset.location"
                        class="asset-location"
                        (click)="detailsHelper.open('location', asset.location); $event.stopPropagation()"
                    >{{ stringifyLocation(asset.location) }}</a>
                    <span *ngIf="!asset.location">No Location</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">{{pagination.emptyMessage}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-mutate-asset mutateType="create" #mutateAsset></app-mutate-asset>
