import { Injectable } from '@angular/core';
import {FetchPolicy, WatchQueryFetchPolicy} from '@apollo/client/core';
import { PlusApollo } from '@karve.it/core';
import { AssetsInput, assetsQuery, CreateAssetsInput, createAssetsMutation, CreateAssetsOutput, DeleteAssetsInput, deleteAssetsMutation, DeleteAssetsOutput, generateAssetsQuery, GenerateAssetsQueryInput, ListAssetsOutput, UpdateAssetsInput, updateAssetsMutation, UpdateAssetsOutput } from '@karve.it/interfaces/assets';


import { KarveRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

    constructor(private apollo: PlusApollo){}

    listAssets(input: AssetsInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListAssetsOutput>({
            query: assetsQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchAssets(
    input: AssetsInput,
        generateInput: GenerateAssetsQueryInput,
        fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
    ){
        return this.apollo.watchQuery<ListAssetsOutput>({
            query: generateAssetsQuery(generateInput),
            variables: {
                ...input
            },
            fetchPolicy,
        });
    }

    createAssets(input: CreateAssetsInput){
        return this.apollo.mutate<CreateAssetsOutput>({
            mutation: createAssetsMutation,
            variables: {
                ...input
            }
        });
    }

    updateAssets(input: UpdateAssetsInput){
        return this.apollo.mutate<UpdateAssetsOutput>({
            mutation: updateAssetsMutation,
            variables: {
                ...input
            }
        });
    }

    deleteAssets(input: DeleteAssetsInput){
        return this.apollo.mutate<DeleteAssetsOutput>({
            mutation: deleteAssetsMutation,
            variables: {
                ...input
            }
        });
    }


}
