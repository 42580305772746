<div #jobCreatePageHeader tabindex="-1">
    <app-jobv2-create-header
    ></app-jobv2-create-header>
    <app-jobv2-create-customer></app-jobv2-create-customer>
    <app-jobv2-create-locations #locationsComponent></app-jobv2-create-locations>
    <app-jobv2-create-inventory #inventoryComponent></app-jobv2-create-inventory>
    <app-jobv2-timeline-availability #timelineComponent></app-jobv2-timeline-availability>
    <app-jobv2-summary #summaryComponent></app-jobv2-summary>
    <div
        class="discard-btn-container"
        *hasPermission="['jobs.discardFilledForm']">
        <button
            *ngIf="mode === 'create'"
            pButton
            label="Discard Opportunity"
            icon="pi pi-trash"
            class="p-button-text discard-button"
            (click)="openDiscardDialog()"
        ></button>
    </div>
</div>