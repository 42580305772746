import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { environment } from '../../../../environments/environment';
import { PlusAuthenticationService } from '../../../core/public-api';
import { MS_ONE_DAY } from '../../../time';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {

  // the time that datadog was initialized at
  initialized: number;
  // enabled = true;
  enabled = environment.production;

  private applicationId = '4a0b8aff-c9fd-4d4a-aa91-c1a49ca08973';
  private clientToken = 'pub03b73fda995896ae1fc6fb36dfcecb65';
  private service = 'karve-plus-frontend';
  private site = 'datadoghq.com';


  get env() {
    if (!environment.production) {
      return 'development';
    }

    if (location.hostname.endsWith('.dev')) {
      return 'staging';
    }

    return 'production';
  }

  /**
   * Datadog RUM client
   */
  get datadogRum() {
    return datadogRum;
  }

  constructor(
    private authSvc: PlusAuthenticationService,
  ) { }

  init() {
    if (!this.enabled) {
      return;
    }
    if (this.initialized) {
      return;
    }

    datadogRum.init({
        applicationId: this.applicationId,
        clientToken: this.clientToken,
        site: this.site,
        service: this.service,

        env: this.env,
        version: environment.version,

        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,

        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,

        // track across all domains
        // useCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: environment.production,

        defaultPrivacyLevel: 'allow',

        beforeSend(event, context) {
          // TODO: rename resource event types so that they have the operation on them
        },

        /**
         * Adds datadog headers to all requests going to the same origin.
         * Headers are:
         * x-datadog-origin: rum
         * x-datadog-parent-id: 5206719884996618525
         * x-datadog-sampling-priority: 1
         * x-datadog-trace-id: 8555732677972330366
         */
        allowedTracingUrls: [
          (origin) => {
            // only allow if this is the same origin
            const urlOrigin = new URL(origin);
            return location.hostname === urlOrigin.hostname;
          },
        ],
    });

    globalThis.datadogRum = datadogRum;

    datadogLogs.init({
      clientToken: this.clientToken,
      site: this.site,
      service: this.service,
      env: this.env,
      version: environment.version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sampleRate: 100,
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: environment.production,
    });

    datadogRum.startSessionReplayRecording();
    this.initialized = Date.now();


    // TODO: add sanitizer?

    /**
      LogRocket.init(environment.logrocket, {
        release: environment.version,
        network: {
          requestSanitizer: (request) => {
            try {
              const body = JSON.parse(request.body);
              if (body?.variables?.password) {
                body.variables.password = 'sanitized';
              }
            } catch (err) {}
            return request;
          }
        }
      });
      LogRocket.getSessionURL((url) => {
        this.freyaHelper.logRocketSessionUrl = url;
      });
     */
  }

  getSessionURL() {

    const internalContext = datadogRum.getInternalContext();
    const sessionID = internalContext?.session_id;

    if (!sessionID || !this.initialized) { return undefined; }

    const url = new URL(`https://app.datadoghq.com/rum/explorer`);
    url.searchParams.set(`query`, `@session.id:${ sessionID } @type:session`);
    url.searchParams.set(`from_ts`, this.initialized.toString());
    url.searchParams.set(`to_ts`, (Date.now() + MS_ONE_DAY).toString());
    url.searchParams.set(`live`, 'false');

    return url.toString();
  }

  identify() {
    if (!this.enabled) {
      return;
    }


    const user = this.authSvc.user;
    if (user) {
      datadogRum.setUser({
        email: user.email,
        id: user.id,
        name: `${ user.givenName } ${ user.familyName }`,
      });
    } else {
      datadogRum.clearUser();

    }

    /**
        LogRocket.identify(this.plusAuth.user?.id, this.plusAuth.user ? {
          name: `${ this.plusAuth.user.givenName } ${ this.plusAuth.user.familyName }`,
          email: this.plusAuth.user.email,
          phone: this.plusAuth.user.phone,
          roles: JSON.stringify(this.plusAuth.user.roles),
          zone: this.brandingService.currentZone().value.id,
        } : undefined);
     */
  }
}
