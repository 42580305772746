import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppLoadingService {

  /**
   * This is true from initial load until the first currentZone
   * has been loaded.
   */
  appLoading = new BehaviorSubject(true);

  /**
   * Set if there was an error while loading
   */
  error?: Error;

  /**
   * A promise that resolves when the application has been fully loaded.
   */
  get appFullyLoaded() {

    return new Promise((resolve) => {
      this.appLoading.pipe(
        filter((loading) => !loading)
      ).subscribe(resolve);
    });

  }

  constructor() { }
}
