import { Pipe, PipeTransform } from '@angular/core';
import {dayjs} from '@karve.it/core';


@Pipe({
  name: 'appropriateTimeMeasurement'
})
export class AppropriateTimeMeasurementPipe implements PipeTransform {
  justNowMaxShow = 30;

  secsMaxShow = 60;
  minsMaxShow = 3600;
  hrsMaxShow = 172800;

  transform(value: number, showJustNow = false): string {
    if (Date.now() - value < this.justNowMaxShow && showJustNow) {
      return 'Just Now';
    }

    return dayjs(value).fromNow();
  
    // } else if (value >= this.secsMaxShow && value < this.minsMaxShow) {
    //   return Math.trunc(value / 60) + ' minutes ago';
    // } else if (value >= this.minsMaxShow && value < this.hrsMaxShow){
    //   return Math.trunc(value / 3600) + ' hours ago';
    // } else if (value >= this.hrsMaxShow) {
    //   return Math.trunc(value / 86400) + ' days ago';
    // } else { return ''; }
  }

}
