import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, defer, from, map, merge, of, switchMap, withLatestFrom } from "rxjs";
import { JobTimelineActions } from "./jobv2-timeline-availability-state.actions";
import { convertLocationToDistanceLocationV2, getRoutesToCalculateV2 } from "../../jobsv2-helpers";
import { FreyaHelperService } from "src/app/services/freya-helper.service";
import { DEFAULT_EVENT_TYPE, DistanceUnit, FULL_DISTANCE_FOR_TIMING } from "src/app/global.constants";
import { CalculateDistanceGQL } from "graphql.generated";
import { Store } from "@ngrx/store";
import { jobToolFeature } from "../../job-tool.reducer";

export const distancesCalculated = createEffect(() => {
    const actions$ = inject(Actions);
    const freyaHelper = inject(FreyaHelperService);
    const calculateDistancesGQL = inject(CalculateDistanceGQL);
    const store = inject(Store);

    return actions$.pipe(
      ofType(JobTimelineActions.calculateDistances),
      withLatestFrom(
        store.select(jobToolFeature.selectJobFormMode)
      ),
      switchMap(([action, mode]) =>
        defer(async () => {
          const units: DistanceUnit = await freyaHelper.getUnits();
          return { units, action };
        }).pipe(
          switchMap(({ units, action }) => {
            const calculateDistanceInput = {
              dock: convertLocationToDistanceLocationV2(action?.input?.dock as any),
              start: convertLocationToDistanceLocationV2(action?.input?.start as any),
              end: convertLocationToDistanceLocationV2(action?.input?.end as any),
            };

            const routesToCalculate = getRoutesToCalculateV2(calculateDistanceInput);

            const validRoutes = Object.entries(routesToCalculate).filter(([key, route]) => {
              let valid = route.length > 1;
              for (const location of route) {
                if (!location.id) {
                  valid = false;
                  break;
                }
              }
              return valid;
            });

            const fetchObservables = validRoutes.map(([key, route]) =>
              from(calculateDistancesGQL.fetch({
                units,
                locationIds: route.map((l) => l.id),
              })).pipe(
                map(response => JobTimelineActions.calculateDistancesSuccess({ key, response, mode })),
                catchError((error) => of(JobTimelineActions.calculateDistancesError({ error })))
              )
            );

            return merge(...fetchObservables);
          }),
          catchError((error) => of(JobTimelineActions.calculateDistancesError({ error })))
        )
      )
    );
  }, { functional: true, dispatch: true });

  export const updateJobTimingEffect = createEffect(() => {
    const actions$ = inject(Actions);
    const store = inject(Store);

    return actions$.pipe(
      ofType(JobTimelineActions.calculateDistancesSuccess),
      withLatestFrom(
        store.select(jobToolFeature.selectJobFormMode)
      ),
      switchMap(([action, mode]) => {
        if (action.key === FULL_DISTANCE_FOR_TIMING) {
          const totalTravelTime = action?.response?.data?.calculateDistance?.estimatedTime as number || 0;
          return of(JobTimelineActions.updateJobTiming({
            eventType: DEFAULT_EVENT_TYPE,
            totalTravelTime,
            mode,
           }));
        } else if (action.key !== FULL_DISTANCE_FOR_TIMING) {
          const travelTime = action?.response?.data?.calculateDistance?.estimatedTime as number || 0;
          return of(JobTimelineActions.updateJobTiming({
            eventType: DEFAULT_EVENT_TYPE,
            partialTravelTime: travelTime,
            mode,
           }));
        }
        return of();
      })
    );
  }, { functional: true, dispatch: true });