import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Report, ReportAggregationResult } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-report-aggregation-value-value',
  templateUrl: './report-aggregation-value-value.component.html',
  styleUrls: ['./report-aggregation-value-value.component.scss']
})
export class ReportAggregationValueValueComponent implements OnInit {

  @Input() report: Report;
  @Input() ag: ReportAggregationResult;
  @Input() value: any;

  currency = environment.defaultCurrency;

  constructor() { }

  ngOnInit(): void {
    const variables = JSON.parse(this.report?.variables || '{}');
    // TODO: determine currency properly
    this.currency = variables.currency || 'USD';
  }

}
