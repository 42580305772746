import { Component, Input, OnInit } from '@angular/core';
import { DeleteJobGQL, FullJobFragment } from 'graphql.generated';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';

import { SubSink } from 'subsink';

import { MenuService } from '../../base/menu/app.menu.service';

@Component({
  selector: 'app-delete-job',
  templateUrl: './delete-job.component.html',
  styleUrls: ['./delete-job.component.scss', '../../dynamic-dialog-styling.scss']
})
export class DeleteJobComponent implements OnInit {

  @Input() job: FullJobFragment;

  subs = new SubSink();

  events: FullJobFragment['events'] = [];

  transactions: FullJobFragment['transactions'] = [];

  warningText: string;

  constructor(
    private menuService: MenuService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private deleteJobsGQL: DeleteJobGQL,
    private notify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    this.job = this.config.data?.job;

    this.events = this.job.events
      ?.filter((e) => e.status !== 'completed')
      ?.sort((a, b) => a.start - b.start);

    this.setWarningText();
  }

  deleteJob() {
    this.menuService.pushJob(this.job, 0, true);

    this.deleteJobsGQL.mutate({ jobIds: [ this.job.id ]})
      .subscribe(() => {
        this.detailsHelper.pushUpdate({
          id: this.job.id,
          type: 'Jobs',
          action: 'delete',
        });

        this.notify.success('Job deleted');
        this.closeDialog(true);
      }, (err) => {
        this.notify.apolloError('Failed to delete job', err);
        this.closeDialog(true);
      });
  }

  closeDialog(deleted: boolean){
    this.ref.close(deleted);
  }

  setWarningText() {
    if (!this.events?.length && !this.job?.transactions?.length) { return; }

    const initialSegment = 'Deleting this job will also delete the following';

    const itemsDescription: string[] = [];

    if (this.events?.length) {
      const eventsDescription = this.events.length > 0 ? 'events' : 'event';
      itemsDescription.push(eventsDescription);
    }

    if (this.job?.transactions?.length) {
      const transactionsDescription = this.job?.transactions.length > 0 ? 'transactions' : 'transaction';
      itemsDescription.push(transactionsDescription);
    }

    this.warningText = `${initialSegment} ${itemsDescription.join(' and ')}:`;
  }

}
