<a
    class="link-look"
    pTooltip="{{unixTime | freyaDate : 'medium'}}"
    tooltipStyleClass="tooltip-smalltext"
    tooltipPosition="bottom"
    (click)="copyTimeToClipboard()"
    [tooltipEvent]="responsiveHelper.isSmallScreen ? 'focus' : 'hover'"
>
{{(unixTime * 1000) | appropriateTimeMeasurement : true}}
</a>
