import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent implements OnInit {
  @Input() content: string;
  @Input() disabled = false;

  maxLengthBeforeTruncating = 200;

  constructor(
    private clipboard: Clipboard,
    private notify: FreyaNotificationsService
  ) { }

  ngOnInit(): void {
  }

  copyToClipboard() {
    this.clipboard.copy(this.content);
    this.notify.success('Copied to clipboard.');
  }
}
