import { formatCurrency } from '../lib.ts/currency.util';

export type DiscountType = 'percentage' | 'amount';
export type AmountType = 'Min' | 'Max';

export function formatRangeAmount(
  amount: number | null | undefined,
  amountType: AmountType,
  discountType: DiscountType,
  currency?: string,
) {
  if (amount === null || amount === undefined) {
    return `No ${amountType}`;
  }

  if (discountType === 'percentage') {
    return `${amount}%`;
  }

  return formatCurrency(amount, currency);
}
