
export interface ColorInfo {
    name: ColorName;
    color: string;
    // The color of text to show above that color
    textColor: string;
    // The lighter version of the color, used as an accent
    lightColor: string;
}

export const COLOR_NAMES = [
    'maroon',
    'brown',
    'red',
    'orange',
    'yellow',
    'pink',
    'beige',
    'green',
    'teal',
    'cyan',
    'navy',
    'blue',
    'lavender',
    'magenta',
    'grey',
    'white',
    'black',
    'orange-yellow'
] as const;

export type ColorName = typeof COLOR_NAMES[number];

export const COLORS: ColorInfo[] = COLOR_NAMES.map((name) => ({
    name,
    color: `var(--${name})`,
    textColor: `var(--${name}-color)`,
    lightColor: `var(--${name}-light)`,
}));

export const COLOR_MAP = COLORS.reduce((p, c) => {
    p[c.name] = c;
    return p;
}, {} as {
    [name in ColorName]: ColorInfo;
});

export function isColorName(str: string): str is ColorName {
    return COLOR_NAMES.includes(str as any);
}

export function isColorScheme(str: string): str is ColorScheme {
    return colorSchemes.includes(str as any);
}

export const appThemes = [
    'ymm',
] as const;

export type AppTheme = typeof appThemes[number];

export const colorSchemes = ['dark', 'light'] as const;
export type ColorScheme = typeof colorSchemes[number];
export let defaultColorScheme: ColorScheme = 'light';
if (isColorScheme(localStorage.getItem('color-scheme'))) {
    defaultColorScheme = localStorage.getItem('color-scheme') as ColorScheme;
}
