import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationService } from '@karve.it/features';
import { CreateLocationsFunctionInput, EditLocationsInput, ListLocationsOutput } from '@karve.it/interfaces/locations';
import { ListRolesOutput, RoleInfo } from '@karve.it/interfaces/roles';
import { Zone } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';

import { CANADA, countries, regex, USA } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseLocationFragment } from '../../../generated/graphql.generated';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-location',
  templateUrl: './mutate-location.component.html',
  styleUrls: ['./mutate-location.component.scss']
})
export class MutateLocationComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('address') addressRef: TemplateRef<any>;
  @ViewChild('city') cityRef: TemplateRef<any>;
  @ViewChild('areaCode') areaCodeRef: TemplateRef<any>;
  @ViewChild('country') countryRef: TemplateRef<any>;
  @ViewChild('jurisdiction') jurisdictionRef: TemplateRef<any>;
  @ViewChild('attributes') attributesRef: TemplateRef<any>;


  @Input() mutateType: 'update' | 'create';
  @Input() location: BaseLocationFragment;
  @Input() owner; // The User the location should belong to

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  rolesQueryRef!: QueryRef<ListRolesOutput>;
  locationRoles: RoleInfo[] = [];
  zones: Zone[] = [];

  locationForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [ Validators.required, Validators.minLength(1), Validators.maxLength(40) ]),
    address: new UntypedFormControl('', [ Validators.required, Validators.minLength(1) ]),
    city: new UntypedFormControl('', [ Validators.required, Validators.minLength(1)]),
    areaCode: new UntypedFormControl('', [ Validators.required, Validators.pattern(regex.areaCodeCanada)]),
    country: new UntypedFormControl([], [ Validators.required, Validators.minLength(1) ]),
    countryJurisdiction: new UntypedFormControl('', []),
    attributes: new UntypedFormControl([], []),
  });

  locationFormValues = this.locationForm.value;

  locationQueryRef: QueryRef<ListLocationsOutput>;

  countries = countries;

  constructor(
    private locationService: LocationService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  refetchLocation(){
    if (!this.locationQueryRef) {
      this.locationQueryRef = this.locationService.watchLocations({filter: {ids: [this.location.id]}}, {});

      this.subs.sink = this.locationQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.location = res.data.locations.locations[0];
        }
      });
    } else {
      this.locationQueryRef.refetch();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
      this.createLocation();
    } else if (this.mutateType === 'update') {
      // Update
      this.updateLocation();
    }
  }

  openDialog() {
    this.steps = [
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text' },
      { name: 'Address', ref: this.addressRef, control: 'address', type: 'text' },
      { name: 'City', ref: this.cityRef, control: 'city', type: 'text' },
      { name: 'Country', ref: this.countryRef, control: 'country', type: 'text' },
      { name: 'Area Code', ref: this.areaCodeRef, control: 'areaCode', type: 'text' },
      { name: 'Jurisdiction', ref: this.jurisdictionRef, control: 'countryJurisdiction', type: 'text' },
      { name: 'Attributes', ref: this.attributesRef, control: 'attributes', type: 'array' },
    ];

    if (this.mutateType === 'create') {
      // Assign Steps
      this.locationForm.reset(this.locationFormValues);
      this.locationForm.reset(this.locationFormValues);
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }
    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.locationForm.reset({
      name: this.location.name,
      address: this.location.addressLineOne,
      city: this.location.city,
      areaCode: this.location.areaCode,
      country: this.location.country,
      countryJurisdiction: this.location.countryJurisdiction,
      attributes: this.location.attributes ? this.location.attributes : [],
    });

    this.changeRegexValidator();
  }

  createLocation(){

    const value = this.locationForm.value;

    const createLocationInput = {
      input: {
        locations: [{
          addressLineOne: value.address,
          city: value.city,
          areaCode: value.areaCode,
          country: value.country,
          name: value.name,
          countryJurisdiction: value.countryJurisdiction,
          attributes: value.attributes ? value.attributes : [],
        }],
        owner: this.owner,
      }
    } as CreateLocationsFunctionInput;

    this.subs.sink = this.locationService.CreateLocations(createLocationInput, '').subscribe((res) => {
      console.log(res.data.createLocations.locations[0].id);
      this.detailsHelper.pushUpdate({
        id:res.data.createLocations.locations[0].id,
        type:'Locations',
        action:'create'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Location created'});
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create location`,err);
    });
  }

  updateLocation(){
    const value = this.locationForm.value;

    const locationEditInput = {
      ids: [this.location.id],
      edit: {
        addressLineOne: value.address,
        city: value.city,
        areaCode: value.areaCode.toUpperCase(),
        country: value.country,
        name: value.name,
        countryJurisdiction: value.countryJurisdiction,
        attributes: value.attributes
      },
    } as EditLocationsInput;


    if (this.locationForm.dirty) {
        this.subs.sink = this.locationService.EditLocations(locationEditInput).subscribe((res) => {
          this.detailsHelper.pushUpdate({
            id:this.location.id,
            type:'Locations',
            action:'update'
          });
          this.mutateRef.closeDialog();
          this.localNotify.addToast.next({severity: 'success', summary: 'Location updated'});
          this.refetchLocation();
        }, (err) => {
          this.mutateRef.loading = false;
          if (err.message.includes('immutable')){
            this.localNotify.apolloError(`Cannot update locations that are used on a job`, err);
          } else {
            this.localNotify.apolloError(`Failed to update location`, err);
            console.error(err);
          }
        });
    }
  }

  changeRegexValidator(){
    if (this.locationForm.value.country === CANADA) {
      this.locationForm.controls.areaCode.setValidators([ Validators.required, Validators.pattern(regex.areaCodeCanada)]);
    } else if(this.locationForm.value.country === USA){
      this.locationForm.controls.areaCode.setValidators([ Validators.required, Validators.pattern(regex.areaCodeUSA)]);
    }

    this.locationForm.controls.areaCode.setValue(this.locationForm.value.areaCode);
  }


}
