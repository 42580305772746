import { Pipe, PipeTransform } from '@angular/core';

import { jurisdictions } from '../global.constants';

@Pipe({
  name: 'jurisdiction'
})
export class JurisdictionPipe implements PipeTransform {

  transform(country: string): string[] {
    if (!country) { return [];}
      const values = jurisdictions.find((j) => j.country === country);
      if(!values) { return []; }
      return values.jurisdictions;
  }

}
