<p>Change <strong>{{ currentLocation }}</strong> to <strong>{{ newLocation }}</strong> on the following events:</p>
<div *ngFor="let event of eventLocationInfo" class="p-mb-2">
  <strong>{{event.event.type | titlecase}} ({{event.event.status | titlecase}})</strong>
  <br>
  <small *ngIf="event.event?.start">{{(event.event.start * 1000) | date}}</small>
  <small *ngIf="!event.event?.start">Unscheduled</small>
  <!-- <ng-container *ngFor="let location of event.locations"> -->
  <!--   <div class="p-col-12 p-py-0"> -->
  <!--     <h6 class="p-m-0"> -->
  <!--       {{ location?.currentLocation?.type | titlecase }} -->
  <!--     </h6> -->
  <!--   </div> -->
  <!--   <div class="p-col-5"> -->
  <!--     {{ location?.currentLocation?.location?.addressLineOne }} -->
  <!--   </div> -->
  <!--   <div class="p-col-2 p-d-flex p-jc-center p-ai-center"> -->
  <!--     <i class="pi pi-arrow-right"></i> -->
  <!--   </div> -->
  <!--   <div class="p-col-5"> -->
  <!--     {{ location?.newLocation?.address }} -->
  <!--   </div> -->
  <!-- </ng-container> -->
</div>
<div class="footer">
  <button
    pButton
    label="No"
    icon="pi pi-times"
    (click)="ref.close(false)"
    ></button>
  <button
    class="p-button-success"
    pButton
    label="Yes, Update Event Locations"
    (click)="ref.close(true)"
    ></button>
</div>
