/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from '@karve.it/features';
import { Transaction } from '@karve.it/interfaces/transactions';
import { Appearance, PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'src/environments/environment';
import { baseEnvironment } from 'src/environments/environment.base';
import { SubSink } from 'subsink';

import { FreyaNotificationsService } from '../services/freya-notifications.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss', './payment.styles.scss']
})
export class PaymentComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('paymentForm') paymentContent: TemplateRef<any>;
    @ViewChild('loading') loadingContent: TemplateRef<any>;

    activeContent: TemplateRef<any>;

    subs = new SubSink();

    // The Stipe Object.
    stripe: Stripe;

    elements: StripeElements;

    transaction: Transaction;

    clientSecret: string;

    paymentIntent: PaymentIntentResult;
    paymentStatus: string;


    // Display Variables
    formBuilt = false;
    routeInvalid = false;
    invalidMessage: string;

  constructor(private localNotify: FreyaNotificationsService,
              private route: ActivatedRoute,
              private transactionsService: TransactionService) { }

  ngOnInit(): void {

    this.setLoading(false);

    this.route.params.subscribe(params => {
      this.initializeComponent();
      if (params.transaction) {
        this.getClientSecret(params.transaction);
      } else {
        this.routeInvalid = true;
        this.invalidMessage = 'Incorrect Route: No Paramater was passed for the transaction';
      }
    });
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  ngAfterViewInit(){
    this.activeContent = this.loadingContent;
  }

  initializeComponent(){
    // Regular
    this.formBuilt = false;
    this.transaction = undefined;
    this.clientSecret = undefined;

    // Invalid
    this.invalidMessage = '';
    this.routeInvalid = false;
  }

  async getClientSecret(id: string){
    this.stripe = await loadStripe(baseEnvironment.stripeKey,
    {
      apiVersion: undefined,
    });

    this.subs.sink = this.transactionsService.listTransactions({filter: {transactionIds: [id]}}, {metadata: true}).subscribe((res) => {
      if (res.networkStatus === 7) {
        try {
          this.transaction = res.data.transactions.transactions[0];
          this.clientSecret = this.transaction.metadata?.clientSecret;
          this.getTransactionDetails();
        } catch {
          // this.routeInvalid = true;
          // this.invalidMessage = 'Invalid Route: Data for this parameter cannot be found. Please check the route or contact support.';
        }
      }
    }, (err) => {
      this.routeInvalid = true;
      this.invalidMessage = 'Invalid Route: Parameter is not in the proper format, please check the route or contact support.';
    });
  }

  async getTransactionDetails(){
    this.paymentIntent = await this.stripe.retrievePaymentIntent(this.clientSecret);

    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#75BF43',
      }
    } as Appearance;

    this.elements = this.stripe.elements({appearance, clientSecret: this.clientSecret});

    const paymentElement = this.elements.create('payment');
    paymentElement.mount('#payment-element');

    setTimeout(() => {
      this.formBuilt = true;
    }, 450);
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setLoading(true);

    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // eslint-disable-next-line max-len
        return_url: `http${environment.production ? 's' : ''}://${ window.location.host }/payment/redirect?secret=${this.clientSecret}&tx=${this.transaction.id}`,
      }
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      this.localNotify.addToast.next({severity: 'error', detail: error.message});
      this.setLoading(false);
    } else {
      this.localNotify.addToast.next({severity: 'error', detail: 'An unexpected error ocurred'});
      this.setLoading(false);
    }
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    // @ts-ignore
    document.querySelector('#submit').disabled = true;
    document.querySelector('#spinner').classList.remove('hidden');
    document.querySelector('#button-text').classList.add('hidden');
  } else {
    // @ts-ignore
    document.querySelector('#submit').disabled = false;
    document.querySelector('#spinner').classList.add('hidden');
    document.querySelector('#button-text').classList.remove('hidden');
  }
}
}

