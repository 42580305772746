import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { Subject, switchMap, tap } from 'rxjs';
import { SchedulesComponent } from 'src/app/schedules/schedules.component';
import { FullCalendarHelperService } from 'src/app/services/full-calendar-helper.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { SubSink } from 'subsink';

import { EstimatesJobFragment } from '../../../generated/graphql.generated';

import { eventTypeInfoMap, JOB_EVENT_TYPES } from '../../global.constants';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';

@Component({
  selector: 'app-job-booking',
  templateUrl: './job-booking.component.html',
  styleUrls: ['./job-booking.component.scss']
})
export class JobBookingComponent implements OnInit, OnDestroy {

  @ViewChild('schedule') scheduleRef: SchedulesComponent;

  @Input() job: EstimatesJobFragment;
  @Input() jobSaving: number;

  subs = new SubSink();

  preferencesForm: UntypedFormGroup = new UntypedFormGroup({
    preferredDate: new UntypedFormControl(),
    preferredTime: new UntypedFormControl('Not Specified'),
    flexibility: new UntypedFormControl('Not Specified')
  });

  // Global Booking Variables
  jobLength = 3600;
  selectedDateAvailabilities: number[];

  // Event Booking Variables
  // eventTypes = ['estimating', 'packing', 'delivery', 'moving', 'unpacking'];
  eventTypes = JOB_EVENT_TYPES;
  activeEventId: string;
  // activeEventIndex = 0;

  eventTypeInfoMap = eventTypeInfoMap;

  updateScheduleOnLoad = false;

  timezone: string;
  jobInDifferentTimezoneWarning: string;
  previousJobArea: string;

  jobAreaChanges$ = new Subject<string>();

  constructor(
    // ANGULAR
    private route: ActivatedRoute,
    private router: Router,
    // HELPERS
    private freyaHelper: FreyaHelperService,
    private fcHelper: FullCalendarHelperService,
    public estimateHelper: EstimateHelperService,
    public timeZoneHelper: TimezoneHelperService,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.route.queryParamMap.subscribe(paramMap => {
      if (paramMap.get('activeEventId')) {
        this.activeEventId = paramMap.get('activeEventId');
      }
    });

    this.subs.sink = this.jobAreaChanges$.pipe(
      switchMap((id) => {
        const zoneId = id ?? this.job?.zone?.id;
        return this.timeZoneHelper.getTimeZoneWithJobAreaPriority(zoneId);
      }),
      tap(timezone => {
        this.handleJobTimeZone(timezone);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  reset() {
    this.job = undefined;
    // this.activeEventId = undefined;
    this.preferencesForm.reset();
  }

  handleJobTimeZone(timezone: string): void {
    const systemTimeZone = this.timeZoneHelper.getCurrentTimezone();
    this.timezone = systemTimeZone;
    if (timezone !== systemTimeZone) {
      this.timezone = timezone;
      this.jobInDifferentTimezoneWarning = `You are selecting event start time in ${timezone} timezone`
    } else {
      this.jobInDifferentTimezoneWarning = '';
    }
  }

  /**
   * Sets the job and sorts the events by start date
   *
   * @param job The job from the estimates component
   */
  setEventData(job: EstimatesJobFragment) {
    // Only reset active event type if it's a new job
    // if ((!this.job || this.job.id !== job.id) && !this.activeEventIndex) {
    //   this.activeEventType = 'estimating';
    // };
    if (job?.id !== this.job?.id){
      this.reset();
    }

    this.job = job;

    if (this.previousJobArea !== job?.zone?.id) {
      this.jobAreaChanges$.next(this.job?.zone?.id);
    }

    this.previousJobArea = job?.zone?.id;

    if (!this.activeEventId){
      this.setActiveEventId(0);
    } else {
      let jobIndex = this.job?.events.findIndex((event) => this.activeEventId === event.id) || 0;

      if (jobIndex < 0){
        jobIndex = 0;
      }

      this.setActiveEventId(jobIndex);
    }
  }

  // TODO: Maybe Remove this and just have all queries use the Job Zone
  changeResolvedZone(zoneId: string) {
    const options = {
      context: {
        headers: {
          'x-zone': zoneId,
        },
      }
    };

    this.scheduleRef.assetsQueryRef.setOptions(options);
    this.scheduleRef.assetsQueryRef.refetch();

    this.scheduleRef.calendarEventQueryRef.setOptions(options);
    this.scheduleRef.calendarEventQueryRef.refetch();
  }

  clearOverlays() {
    if (!this.scheduleRef) { return; }
    this.scheduleRef.clearOverlays();
  }

  // Rerender the Calendar to prevent weird css glitches with Full Calendar
  rerenderCalendar() {
    if (!this.scheduleRef) { return; } // Prevent an error if you navigate to this tab without a job

    this.scheduleRef.calendar.render();

    setTimeout(() => {
      this.scheduleRef.calendar.render(); // The events don't adjust to the new size until after rendering again
    }, 50);
  }

  // Set the Date the Calendar Loads to
  setPreferences(date: string, preferredTime: string, flexibility: string) {
    this.preferencesForm.controls.preferredTime.patchValue(preferredTime);
    this.preferencesForm.controls.flexibility.patchValue(flexibility);

    if (!date) { return; }
    this.preferencesForm.controls.preferredDate.setValue(dayjs(date, 'YYYY-MM-DD').toDate());

    if (!this.scheduleRef){ return; }
    this.scheduleRef.calendar.gotoDate(date);
  }

  /**
   * Set the active event based on the tab index
   *
   * @param index tab index of the event
   */
  setActiveEventId(index: number){
    if (!this.job?.events?.length) { return; }
    const newEventId = this.job?.events[index]?.id;

    if (this.activeEventId === newEventId) {
      // active event ID has already been set
      return;
    }

    this.activeEventId = newEventId;

    this.updateCalendarForEvent();
    this.router.navigate([], {
      queryParams: {
        activeEventId: this.activeEventId,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Updates the calendar for the specific event
   * filtering out only specified asset types.
   * Note that this reloads assets and the schedule view
   * so it must be called conservatively.
   */
  updateCalendarForEvent(){
    if (!this.job?.events) { return; }

    const activeEvent = this.job?.events.find((ev) => ev.id === this.activeEventId);

    if (!activeEvent) {return;}

    // If the event is scheduled set the calendar to that date
    if (activeEvent?.start){
      const date = new Date(activeEvent.start * 1000);
      this.fcHelper.changeCalendarDate.next(date);
    }

    // If the schedule is intialized, set the displayed assets to the event's asset types
    if (!this.scheduleRef){
      return;
    }

    const assetTypes = eventTypeInfoMap[activeEvent.type]?.assetTypes || [];
    this.scheduleRef.scheduleFilters
      .get('assetTypes')
      .setValue(assetTypes);
    this.scheduleRef.updateAssets();
  }

  getActiveIndex(){
    return this.job.events.findIndex((event) => this.activeEventId === event.id);
  }

}
