import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DiscountDetails, EstimatesJobFragment, UpdateCustomAmountGQL, UpdateCustomAmountMutationVariables } from '../../../generated/graphql.generated';
import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { UpdatedType } from '../../utilities/details-helper.util';

@Component({
  selector: 'app-edit-custom-amount',
  templateUrl: './edit-custom-amount.component.html',
  styleUrls: ['./edit-custom-amount.component.scss', '../../dynamic-dialog-styling.scss']
})
export class EditCustomAmountComponent implements OnInit {

  appliedDiscount: DiscountDetails;

  job: EstimatesJobFragment;

  isPercentage: boolean;

  min: number;

  max: number;

  customAmountForm = new UntypedFormGroup({
    amount: new UntypedFormControl(null),
  });

  constructor(
    private updateCustomAmountGQL: UpdateCustomAmountGQL,
    private detailsHelper: DetailsHelperService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.job = this.config?.data.job;

    this.appliedDiscount = this.config?.data.appliedDiscount;

    this.isPercentage = this.appliedDiscount.discount.discountType === 'percentage';

    this.customAmountForm.controls.amount.setValue(this.getCurrentAmount());

    this.setValidators();
  }

  /**
   * Gets the `appliedCustomAmount` for the current `appliedDiscount` if there is one,
   * otherwise defaults to discount amount,
   * converting cents to dollars depending on the discount type.
   */
  getCurrentAmount() {
    const amount = this.appliedDiscount.appliedCustomAmount || this.appliedDiscount.discount.amount;

    if (!this.isPercentage) {
      return convertCentsToDollars(amount);
    }

    return amount;
  }

  /**
   * Calculates the min and max allowed values for the discount's custom amount
   * based on the discount type and `customAmountRange` for the current `appliedDiscount`,
   * then uses those values to set validators on the relevant form control
   */
  setValidators() {
    const { discount } = this.appliedDiscount;

    if (!discount.customAmountRange) {
      this.localNotify.error('Cannot edit amount: discount wasn\'t given a custom amount range');
      this.closeDialog();
      return;
    }

    let [ min, max ] = discount.customAmountRange;

    if (!this.isPercentage) {
      min = convertCentsToDollars(min, true);
      max = convertCentsToDollars(max, true);
    }

    const newMax = this.isPercentage ? Math.min(max, 100) : max;

    this.min = min;
    this.max = newMax;

    this.customAmountForm.controls.amount.setValidators([ Validators.min(min), Validators.max(this.max), Validators.required ]);
    this.customAmountForm.updateValueAndValidity();
  }

  closeDialog() {
    this.ref.close();
  }

  updateCustomAmount() {
    const mutationVariables: UpdateCustomAmountMutationVariables = {
      appliedId: this.appliedDiscount.appliedId,
      customAmount: this.getCustomAmount(),
    };

    this.updateCustomAmountGQL.mutate(mutationVariables)
    .subscribe(() => {
      this.localNotify.success('Amount updated');

      const updateTypes: UpdatedType[] = [ 'Jobs', 'Charges' ];

      for (const updateType of updateTypes) {
        this.detailsHelper.pushUpdate({
          action: 'update',
          type: updateType,
          id: this.job.id,
        });
      }

      this.closeDialog();
    }, (err) => {
      this.localNotify.apolloError('Failed to update amount', err);
    });
  }

  /**
   * Reads the value off the amount form control converting dollars to cents depending on the discount type
   * so we can pass the right value to the `updateCustomAmountGQL` mutation
   */
  getCustomAmount() {
    const { amount } = this.customAmountForm.value;

    if (this.isPercentage) {
      return amount;
    }
    return convertDollarsToCents(amount);
  }
}
