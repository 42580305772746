<p-overlayPanel #op>
    <ng-template pTemplate>
		<div markdown
			class="help-item"
			[data]="dashboardHelpItem.body"
		>

		</div>
		<button pButton pRipple type="button"
			*ngIf="dashboardHelpItem.reportTypeId"
			label="Inspect"
			class="p-button-text p-button-plain inspect-report-btn"
			[disabled]="inspectLoading"
			(click)="inspectReport()"
			pTooltip="View the underlying data behind this dashboard item as a report."
		></button>

    </ng-template>
</p-overlayPanel>

<button pButton type="button"
	class="pi pi-info-circle help-btn"
	(click)="op.toggle($event)"
></button>
