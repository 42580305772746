import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiresAddress(
  validationMsg = 'You must enter an address'
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const parent = control.parent;

      if (!parent) { return null; }

      const hasValidLocation = parent.value?.place_id;

      if (hasValidLocation) { return null; }

      return {
        locationRequired: validationMsg,
      };
    };
}
