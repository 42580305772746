import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {QueryRef} from 'apollo-angular';

import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { AddJobDiscount, DiscountDetails, DiscountsGQL, DiscountsQuery, DiscountsQueryVariables, EstimatesJobFragment, EstimatingUpdateJobGQL, EstimatingUpdateJobMutationVariables, FullDiscountFragment } from '../../../generated/graphql.generated';
import { JobService } from '../../features/public-api';
import { UpdateJobsInput } from '../../interfaces/jobs';
import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { EditCustomAmountComponent } from '../../shared/edit-custom-amount/edit-custom-amount.component';
import { MutateDiscountComponent } from '../../shared/mutate-discount/mutate-discount.component';

@Component({
  selector: 'app-estimate-discounts',
  templateUrl: './estimate-discounts.component.html',
  styleUrls: ['./estimate-discounts.component.scss']
})
export class EstimateDiscountsComponent implements OnInit, OnChanges {

  @ViewChild('mutate') mutateRef: MutateDiscountComponent;

  @Input() job: EstimatesJobFragment;
  @Input() archived = false;
  @Input() readonly = false;
  //Modify appearance of component for usage on estimate page: display discounts per event, adjust styles
  @Input() discountsPerEvent = false;
  @Input() currentEventId: string | null = null;
  @Input() disabled: boolean;
  @Input() addButtonDisabledTooltip: string;

  discountsToDisplay = [];

  subs = new SubSink();

  discountActions: MenuItem[] = [];

  // DISCOUNTS
  applyDiscountForm: UntypedFormGroup = new UntypedFormGroup({
    discount: new UntypedFormControl(null, [Validators.required]),
    amount: new UntypedFormControl(null),
    events: new UntypedFormControl(null, [Validators.required]),
  });

  showApplyDiscountDialog = false;
  discounts: FullDiscountFragment[] = [];
  discountQueryRef: QueryRef<DiscountsQuery, DiscountsQueryVariables>;

  selectedDiscount: FullDiscountFragment;

  amountInputAtts: {
    min: number | undefined;
    max: number | undefined;
  };

  defaultCurrency: string;

  // Charges associated with an event ending before the lock date and which therefore should not be modified
  lockedDiscounts: { [ discountId: string ]: boolean } = {};

  formattedLockDate?: string;

  constructor(
    private jobService: JobService,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private updateJobGQL: EstimatingUpdateJobGQL,
    private discountsGQL: DiscountsGQL,
    private dialogService: DialogService,
    private freyaHelper: FreyaHelperService,
    private responsiveHelper: ResponsiveHelperService,
  ) { }

  ngOnInit(): void {
    this.setAmountInputAtts();
    this.manageApplyDiscountForm();

    this.defaultCurrency = this.freyaHelper.getCurrency();
    this.setDiscountsToDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.job) {
      this.setLockedDiscounts();
      this.setDiscountsToDisplay();
    }
  }

  openApplyDiscountModal() {
    this.showApplyDiscountDialog = true;
    this.fetchDiscounts();
  }

  setDiscountsToDisplay() {
    if (this.currentEventId) {
      this.discountsToDisplay = this.job?.discounts?.filter(d => d.event?.id === this.currentEventId);
    }
    if (!this.currentEventId) {
      this.discountsToDisplay = this.job?.discounts;
    }
  }

  openDiscount(discount?: DiscountDetails) {
    if (!discount) { return; };

    this.detailsHelper.open('discount', discount?.discount);
  }

  fetchDiscounts() {
    if (this.discountQueryRef) {
      this.discountQueryRef.refetch();
    }

    const watchDiscountsInput: DiscountsQueryVariables = {
      limit: -1,
      filter: {
        singleUse: false,
        applyable: true,
      },
    };

    this.discountQueryRef = this.discountsGQL.watch(watchDiscountsInput, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.discountQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      this.discounts = res.data.discounts.discounts;
    }, (err) => {
      this.localNotify.apolloError('Failed to retrieve discounts', err);
    });
  }

  /**
   * Adds the selected discount to all the selected events
   */
  addDiscountToEvents() {
    const addDiscountInput: AddJobDiscount[] = [];

    for (const event of this.applyDiscountForm.value.events){
      addDiscountInput.push({
        discountId: this.applyDiscountForm.value.discount.id,
        eventId: event.id,
        customAmount: this.getCustomAmount(),
      });
    }

    const updateJobsInput: EstimatingUpdateJobMutationVariables = {
      updateJobs: [{
        jobId: this.job.id,
        addDiscounts: addDiscountInput,
      }]
    };

    this.subs.sink = this.updateJobGQL.mutate(updateJobsInput).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: this.job.id,
        type: 'Jobs',
        action: 'update'
      });

      this.applyDiscountForm.reset();
      this.showApplyDiscountDialog = false;
      this.localNotify.success('Discount applied');
    }, (err) => {
      this.localNotify.apolloError('Failed to apply discount', err);
    });
  }

  /**
   * Remove the discount
   */
  removeDiscount(discount: DiscountDetails) {
    const updateJobsInput = {
      updateJobs: [{
        jobId: this.job.id,
        removeDiscounts: [discount.appliedId]
      }]
    } as UpdateJobsInput;

    this.subs.sink = this.jobService.updateJobs(updateJobsInput).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: this.job.id,
        type: 'Jobs',
        action: 'update'
      });

      this.showApplyDiscountDialog = false;
      this.localNotify.addToast.next({ severity: 'success', summary: 'Discount removed' });
    }, (err) => {
      this.localNotify.apolloError('Failed to remove discount', err);
    });
  }

  getCustomAmount() {
    const { amount } = this.applyDiscountForm.value;

    if (!amount) { return; }

    const customAmount = this.selectedDiscount.discountType === 'amount' ? convertDollarsToCents(amount) : amount;

    return customAmount === this.selectedDiscount.amount ? undefined : customAmount;
  }

  openCreateDiscountDialog() {
    this.showApplyDiscountDialog = false;
    this.mutateRef.job = this.job;
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }

  uniqueDiscountApplied() {
    this.detailsHelper.pushUpdate({
      id: this.job.id,
      type: 'Jobs',
      action: 'update'
    });
  }

  get disabledTooltip() {
    if (this.archived) {
      return 'Cannot modify archived jobs';
    } else if (this.readonly) {
      return 'Cannot modify readonly jobs';
    }

    return '';
  }

  manageApplyDiscountForm() {
    this.subs.sink = this.applyDiscountForm.controls.discount
      .valueChanges.subscribe((selectedDiscount: FullDiscountFragment | undefined) => {

        this.selectedDiscount = selectedDiscount;

        const { amount: amountCtrl } = this.applyDiscountForm.controls;

        if (!selectedDiscount) {
          amountCtrl.reset();
          this.resetAmountValidators();
          amountCtrl.disable();
          this.setAmountInputAtts();
          return;
        }

        const amount = selectedDiscount.discountType === 'percentage' ?
          selectedDiscount.amount :
          convertCentsToDollars(selectedDiscount.amount);

        if (!selectedDiscount.customAmountRange) {
          amountCtrl.setValue(amount);
          this.resetAmountValidators();
          amountCtrl.disable();
          this.setAmountInputAtts();
          return;
        }

        amountCtrl.enable();

        amountCtrl.setValue(amount);

        let [ min, max ] = selectedDiscount.customAmountRange;

        if (selectedDiscount.discountType === 'amount') {
          min = convertCentsToDollars(min, true);
          max = convertCentsToDollars(max, true);
        }

        this.setAmountValidators(min, max);
        this.setAmountInputAtts(min, max);
      });;
  }

  setAmountInputAtts(min?: number, max?: number) {
    const isPercentage = this.selectedDiscount?.discountType === 'percentage';

    const newMax = isPercentage ? Math.min(max, 100) : max;

    this.amountInputAtts = {
      min,
      max: newMax,
    };
  }

  resetAmountValidators() {
    const { amount: amountCtrl } = this.applyDiscountForm.controls;
    amountCtrl.clearValidators();
    amountCtrl.updateValueAndValidity();
  }

  setAmountValidators(min: number | undefined, max: number | undefined) {

    const { amount: amountCtrl } = this.applyDiscountForm.controls;

    amountCtrl.clearValidators();

    if (min !== undefined) {
      amountCtrl.addValidators(Validators.min(min));
    }

    if (max !== undefined) {

      const isPercentage = this.selectedDiscount?.discountType === 'percentage';

      const newMax = isPercentage ? Math.min(max, 100) : max;

      amountCtrl.addValidators(Validators.max(newMax));
    }

    amountCtrl.updateValueAndValidity();
  }

  openEditCustomAmountDialog(appliedDiscount: DiscountDetails) {
    this.dialogService.open(EditCustomAmountComponent, {
      header: `Update Amount for ${appliedDiscount.discount.name} (${appliedDiscount.discount.code})`,
      width: this.responsiveHelper.dialogWidth,
      data: {
        appliedDiscount,
        job: this.job,
      },
      contentStyle: this.freyaHelper.getDialogContentStyle('1rem'),
    });
  }

  setDiscountActions(discount: DiscountDetails) {
    const hasCustomRange = discount.discount.customAmountRange;

    this.discountActions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.openEditCustomAmountDialog(discount),
        disabled: !hasCustomRange,
        tooltipOptions: {
          tooltipLabel: !hasCustomRange && 'Discount wasn\'t given a custom amount range',
          tooltipPosition: 'left',
        }
      },
      {
        label: 'Remove',
        icon: 'pi pi-trash',
        command: () => this.removeDiscount(discount),
      }
    ];

  }

  setLockedDiscounts() {
    if (!this.job || !this.job?.discounts?.length) { return; }

    const { discounts } = this.job;

    for (const discount of discounts) {

      if (!discount?.event?.end) { continue; }

      const isLocked = discount.event.end && this.freyaHelper.lockDate > discount.event.end;

      this.lockedDiscounts[discount.appliedId] = isLocked;
    }

    this.formattedLockDate = this.freyaHelper.getFormattedLockDate('MMM DD');
  }
}
