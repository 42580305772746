import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduled-report-page',
  templateUrl: './scheduled-report-page.component.html',
  styleUrls: ['./scheduled-report-page.component.scss']
})
export class ScheduledReportPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
