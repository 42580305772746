import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { QueryRef } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { FullNotificationFragment, UserFullNotificationsGQL, UserFullNotificationsQuery, UserFullNotificationsQueryVariables } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';
import { pagination } from '../global.constants';
import { NotificationsService } from '../services/notifications.service';
import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { currentTimeSeconds } from '../time';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

export type PERIOD = 'hour' | 'day' | 'week';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: FullNotificationFragment[] = [];

  subs = new SubSink();

  // Whether the user has selected a notification from the menu
  notificationSelected: boolean;

  notificationsQueryRef: QueryRef<UserFullNotificationsQuery, UserFullNotificationsQueryVariables>;

  notificationsQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: true
  };

  pagination = pagination;

  periodOptions: { label: string; option: PERIOD }[] = [
    { label: 'Any Time', option: undefined },
    { label: 'Last Hour', option: 'hour' },
    { label: 'Today', option: 'day' },
    { label: 'Week', option: 'week' }
  ];

  selectedReceived: PERIOD;

  // Styling
  tableMaxWidth = '27rem';
  separatorStyle = '1px solid var(--surface-c)';
  tableSeparator = { 'border-right': this.separatorStyle };
  contentSeparator = { 'border-left': this.separatorStyle };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private plusAuth: PlusAuthenticationService,
    private userNotificationsGQL: UserFullNotificationsGQL,
    public notificationsService: NotificationsService,
    public responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.notificationSelected = Boolean(this.getSelectedNotificationId());

    this.subs.sink = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.notificationSelected = Boolean(this.getSelectedNotificationId());
      });

    this.subs.sink = this.notificationsService.notificationRead
      .subscribe((notificationId) => this.markAsRead(notificationId));

    this.subs.sink = this.notificationsService.newNotifications
      .subscribe(() => this.retrieveNotifications());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * When a notification is selected, this manually sets it as read in the menu.
   *
   * This is needed because while the backend will set the notification's status to read,
   * it is not currently set up to return its most current status.
   */
  markAsRead(notificationId: string) {
    if (!notificationId) { return; }

    const selectedNotification = this.notifications.find((n) => n.id === notificationId);
    if (!selectedNotification) { return; }

    const self = selectedNotification.recipients.find((r) => r.recipientId === this.plusAuth.user.id);
    if (!self) { return; }

    self.readAt = currentTimeSeconds();
  }

  /**
   * Extracts the id of the current notification off the URL.
   */
  getSelectedNotificationId(): string {
    const [ childRoute ] = this.route.children;
    if (!childRoute) { return; }

    const { snapshot: { params: { notificationId } } } = childRoute;
    if (!notificationId) { return; }

    return notificationId;
  }

  retrieveMoreNotifications(event: LazyLoadEvent) {
    this.notificationsQH.limit = event.rows;
    this.notificationsQH.skip = event.first;

    this.retrieveNotifications();
  }

  retrieveNotifications() {
    if (this.notificationsQueryRef) {
      this.notificationsQueryRef.refetch(this.notificationsQueryVariables);
      return;
    }

    this.notificationsQueryRef = this.userNotificationsGQL
      .watch(this.notificationsQueryVariables, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.notificationsQueryRef.valueChanges
      .subscribe((res) => {
        this.notificationsQH.loading = res.loading;
        if (res.loading) { return; }

        const [ user ] = cloneDeep(res.data.usersv2.users);

        this.notifications = user.notifications.notifications;
        this.notificationsQH.total = user.notifications.total;
      });
  }

  get notificationsQueryVariables(): UserFullNotificationsQueryVariables {
    const createdAfter = this.selectedReceived ? dayjs().startOf(this.selectedReceived).unix() : undefined;

    return {
      userId: this.plusAuth.user.id,
      skip: this.notificationsQH.skip,
      limit: this.notificationsQH.limit,
      filter: { createdAfter }
    };
  }

}
