import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { SubSink } from 'subsink';

import { environment } from '../../../../environments/environment';
import { DistanceService } from '../../../estimates/distance.service';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { GoogleHelperService } from '../../../services/google-helper.service';
import { SharedModule } from '../../../shared/shared.module';
import { JobLocations, jobToolFeature } from '../../job-tool.reducer';
import { DistanceLocation } from '../../jobv2-create/jobv2-interfaces';

interface MapView {
  name: string;
  url: string;
  inactive: boolean;
}

const MAPS_BASE = 'https://www.google.com/maps/embed/v1/';

@Component({
  selector: 'app-travel-map',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule
  ],
  templateUrl: './travel-map.component.html',
  styleUrls: ['./travel-map.component.scss']
})
export class TravelMapComponent implements OnInit, OnDestroy {

  @HostBinding('style.--map-height') @Input() mapHeight = '45vh';

  @Input() mapZoom = 10;

  views: MapView[] = [];
  selectedView: MapView;

  mapLoading = true;
  iFrameRefreshing = false;

  jobLocations$ = this.store.select(jobToolFeature.selectJobLocations);
  jobLocations: JobLocations;

  subs = new SubSink();

  constructor(
    private googleHelper: GoogleHelperService,
    private cd: ChangeDetectorRef,
    public store: Store,
  ) { }

  ngOnInit(): void {
    this.watchJobLocations();
  }

  private watchJobLocations() {
    this.subs.sink = this.jobLocations$.subscribe((locations) => {
      this.jobLocations = locations;
      this.updateViews();

    });
  }

  private updateViews() {
    // TODO: need distance locations
    // const start = this.state.locations.find((l) => l.locationType === 'start');
    // const end = this.state.locations.find((l) => l.locationType === 'end');

    // TODO: Use job distances and states

    const startUrl = this.getStreetViewUrl(undefined);
    const endUrl = this.getStreetViewUrl(undefined);
    const routeUrl = this.getRouteUrl();

    this.views = [
      {
        name: 'Route',
        url: routeUrl,
        inactive: !routeUrl
      },
      {
        name: 'Start',
        url: startUrl,
        inactive: !startUrl,
      },
      {
        name: 'End',
        url: endUrl,
        inactive: !endUrl,
      }
    ];

    this.selectedView = this.views[0];
  }

  mapLoadingOff() {
    this.mapLoading = false;
  }

  refreshiFrame() {
    this.cd.detectChanges();
    this.iFrameRefreshing = true;
    setTimeout(() => this.iFrameRefreshing = false, 250);
  };

  getRouteUrl() {

    // TODO: replace distance service with state
    // TODO: Move this logic into effects and just return map url

    const dock = this.jobLocations.dock ? this.encodeDistanceLocation(this.jobLocations.dock) : undefined;
    const start = this.jobLocations.start ? this.encodeDistanceLocation(this.jobLocations.start) : undefined;
    const end = this.jobLocations.end ? this.encodeDistanceLocation(this.jobLocations.end) : undefined;
    // console.log(dock, start, end, locations);

    // If all locations are available, return route from dock to dock through start and end
    if (dock && start && end) {
      return this.getDirectionsUrl(dock, dock, [start, end]);
    };

    // If only two locations are available, return route from one to the other
    if (dock && start) {
      return this.getDirectionsUrl(dock, start);
    };
    if (dock && end) {
      return this.getDirectionsUrl(end, dock);
    };
    if (start && end) {
      return this.getDirectionsUrl(start, end);
    };

    // If only one location is available, return place URL
    if (start) {
      return this.getPlaceUrl(start);
    };
    if (end) {
      return this.getPlaceUrl(end);
    };

    // If no location is available, return URL for zone HQ if available
    // const hqCoordinates = await this.getHQCoordinates();
    // if (hqCoordinates){
    //   return this.getViewUrl(hqCoordinates.latitude, hqCoordinates.longitude);
    // };

    // Otherwise (as a last resort), return URL for country
    // const countryCoordinates = await this.getCountryCoordinates();
    // if (countryCoordinates) {
    // return this.getViewUrl(countryCoordinates.latitude, countryCoordinates.longitude);
    // };
    return this.getViewUrl(environment.defaultMapCoordinates[0], environment.defaultMapCoordinates[1], 4);
  }

  // getDirectionsUrl(origin: string, destination: string, waypoints?: string[]): string {
  //   const key = this.googleHelper.getKey();
  //   const url = new URL(MAPS_BASE);
  //   url.pathname += 'directions';
  //   url.searchParams.set('key', key);
  //   url.searchParams.set('origin', `${origin}`);
  //   url.searchParams.set('destination', `${destination}`);
  //   if (waypoints) {
  //     url.searchParams.set('waypoints', `${waypoints.join('|')}`);
  //   };

  //   return url.toString();
  // }

  private getDirectionsUrl(origin: string, destination: string, waypoints?: string[]): string {
    const key = this.googleHelper.getKey();
    let params = `origin=${origin}&destination=${destination}`;
    if (waypoints) {
      const waypointsParam = `&waypoints=${waypoints.join('|')}`;
      params = params + waypointsParam;
    };
    return `${MAPS_BASE}directions?key=${key}&${params}`;
  }

  private getPlaceUrl(place: string) {
    const key = this.googleHelper.getKey();
    const url = new URL(MAPS_BASE);
    url.pathname += 'place';
    url.searchParams.set('key', key);
    url.searchParams.set('q', `${place}`);

    return url.toString();
  }

  private getViewUrl(lat: number, long: number, zoom = this.mapZoom): string {
    const key = this.googleHelper.getKey();
    const url = new URL(MAPS_BASE);
    url.pathname += 'view';
    url.searchParams.set('key', key);
    url.searchParams.set('center', `${lat},${long}`);
    url.searchParams.set('zoom', `${zoom}`);

    return url.toString();
  }

  private getStreetViewUrl(
    location: DistanceLocation,
  ) {
    if (!location?.coordinates) { return; }
    const key = this.googleHelper.getKey();
    const latlng = this.googleHelper.convertCoordinatesToLatLng(location.coordinates);

    const url = new URL(MAPS_BASE);
    url.pathname += 'streetview';
    url.searchParams.set('key', key);
    url.searchParams.set('location', `${latlng.lat},${latlng.lng}`);

    return url.toString();
  }

  private encodeDistanceLocation(location: any): string {
    if (!location) { return; }
    const address = location.addressLineOne ? encodeURIComponent(location.addressLineOne) : '';
    const city = location.city ? encodeURIComponent(location.city) : '';
    const country = location.country ? encodeURIComponent(location.country) : '';
    return [address, city, country].join(',');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
