<div>
    <p-multiSelect
        appendTo="body"
        placeholder="Select Asset"
        [options]="assetSuggestions"
        [(ngModel)]="selectedAssets"
        (ngModelChange)="onChange(selectedAssets)"
        [disabled]="disabled"
        [style]="{ width: '100%' }"
        (onChange)="emitSelect()"
        (focus)="touched = true"
        optionLabel="key"
        optionValue="value"
    >
    <ng-template pTemplate="selectedItems" let-values>
        <span *ngIf="!values?.length">Select Assets</span>
        <span *ngIf="values?.length === 1">{{values[0]?.key}}</span>
        <span *ngIf="values?.length > 1">{{values?.length}} Assets Selected</span>
    </ng-template>

    <!-- <ng-template pTemplate="item" let-asset>
        <span>{{asset.name}} ({{asset.type}})</span>
    </ng-template> -->
</p-multiSelect>
</div>
