<p-dropdown
    class="time-item"
    [options]="scheduledReportOptions"
    [(ngModel)]="selectedTimeBaseOption"
    appendTo="body"
    optionLabel="label"
    optionValue="value"
    (onChange)="updateTime()"
    ></p-dropdown>

<!-- time selection -->
<span class="p-float-label time-item"
    *ngIf="showTimeSelect"
    >
    <p-calendar
        [(ngModel)]="selectedTimeOfDay"
        [showTime]="true"
        [timeOnly]="true"
        appendTo="body"
        [hourFormat]="12"
        (onSelect)="updateTime()"
        (onBlur)="updateTime()"
        (onClose)="updateTime()"
        (onInput)="updateTime()"
        ></p-calendar>
    <label for="time-input">Time of day</label>
</span>
<!-- day of the week selection -->
<span class="p-float-label time-item"
    *ngIf="showWeekdaySelect"
    >
    <p-dropdown
        [options]="dayOfWeekOptions"
        [(ngModel)]="selectedWeekday"
        appendTo="body"
        optionLabel="label"
        optionValue="value"
        (onChange)="updateTime()"
        ></p-dropdown>
    <label for="time-input">Day of the week</label>
</span>
<!-- day of the month selection -->
<span class="p-float-label time-item p-fluid"
    *ngIf="showDayOfMonthSelect"
    >
    <p-dropdown
        styleClass="w-full"
        [options]="dayOfMonthOptions"
        [(ngModel)]="selectedDayOfMonth"
        appendTo="body"
        optionLabel="label"
        optionValue="value"
        (onChange)="updateTime()"
        ></p-dropdown>
    <label for="time-input">Day of the month</label>
</span>

<span class="p-float-label time-item"
    *ngIf="showCustomSelect"
    >
    <input id="time-input" type="text" pInputText
    [(ngModel)]="time"
    (input)="updateTime()"
    >
    <label for="time-input">Cron Interval</label>
</span>
