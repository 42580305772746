import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';

import { environment } from '../../environments/environment';
import { ColorSchemeService } from '../color-scheme.service';
import { FREYA_ROUTES } from '../global.constants';
import { BrandingService } from '../services/branding.service';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss', './auth.styles.scss']
})
export class AppLoginComponent implements OnInit, OnDestroy {

  subs = new SubSink();


  constructor(
    public brandingService: BrandingService,
    private colorSchemeSvc: ColorSchemeService,
  ) { }

  ngOnInit(): void {
    this.colorSchemeSvc.setColorScheme('light');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
