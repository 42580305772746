<div
    override
    [formGroup]="passwordForm"
    autocomplete="off"
>
    <div *appVar="passwordForm.get('password1') as control">
        <p-password
            formControlName="password1"
            id="password_one"
            [toggleMask]="true"
            autocomplete="new-password"
            placeholder="Enter Password"
            appendTo="body"
            [mediumRegex]="mediumRegex"
            [strongRegex]="strongRegex"
            [promptLabel]="'Enter Password'"
            data-cy="new-password1-field"
            [styleClass]="!control.valid && control.touched ? 'p-invalid-password p-input-icon-right' : 'p-input-icon-right'"
        >
            <ng-template pTemplate="header">
                <h6>Enter Password</h6>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Requirements</p>
                <ul
                    class="p-pl-2 p-ml-2 p-mt-0"
                    style="line-height: 1.5"
                >
                    <li [class.error-text]="!passwordForm.controls.password1.valid">Minimum {{minPasswordLength}} characters</li>
                </ul>
                <p-divider></p-divider>
                <p class="p-mt-2">Suggestions</p>
                <ul
                    class="p-pl-2 p-ml-2 p-mt-0"
                    style="line-height: 1.5"
                >
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum {{minPasswordLength}} characters</li>
                </ul>
            </ng-template>
        </p-password>
    </div>

    <p></p>
    <div *appVar="passwordForm.get('password2') as control">
        <p-password
            formControlName="password2"
            autocomplete="new-password2"
            id="password_two"
            appendTo="body"
            [toggleMask]="true"
            [mediumRegex]="mediumRegex"
            [strongRegex]="strongRegex"
            placeholder="Re-Enter Password"
            data-cy="new-password2-field"
            [styleClass]="!(this.passwordForm.controls.password1.value === this.passwordForm.controls.password2.value)
            && (this.passwordForm.controls.password1.valid || this.passwordForm.controls.password2.valid)&& control.touched? 'p-invalid-password p-input-icon-right' : 'p-input-icon-right'"
            >

            <ng-template pTemplate="header">
                <h6>Enter Password</h6>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-divider></p-divider>
                <p class="p-mt-2">Requirements</p>
                <ul
                    class="p-pl-2 p-ml-2 p-mt-0"
                    style="line-height: 1.5"
                >
                    <li [class.error-text]="!passwordForm.controls.password2.valid">Minimum {{minPasswordLength}} characters</li>
                    <li
                        [class.error-text]="passwordForm.controls.password1.value !== passwordForm.controls.password2.value">
                        Match first password</li>
                </ul>
                <p-divider></p-divider>
                <p class="p-mt-2">Suggestions</p>
                <ul
                    class="p-pl-2 p-ml-2 p-mt-0"
                    style="line-height: 1.5"
                >
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum {{minPasswordLength}} characters</li>
                </ul>
            </ng-template>
        </p-password>
    </div>

</div>