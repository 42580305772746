import { max, min, range } from 'lodash';

export function getServerTimingHelperCommands() {

    return {
        listGraphqlQueries,
        showServerTiming,
    };
}

export function isGraphqlRequest(e: PerformanceEntry | PerformanceResourceTiming): e is PerformanceResourceTiming {
    return 'initiatorType' in e && e.name.includes('/api/graphql') && e.initiatorType ==='xmlhttprequest';

}

export function listGraphqlQueries(
    log = true,
) {
    const entries = performance.getEntriesByType('resource');

    const graphqlEntries = entries.filter(isGraphqlRequest);
    const table = graphqlEntries.map((e, i) => ({
        // TODO: get name from somewhere?
        name: e.name,
        start: Math.round(e.requestStart),
        end: Math.round(e.responseStart),
        serverDuration: Math.round(e.responseStart - e.requestStart),
        object: e,
    }));

    if (log) {
        console.table(table);
    }

    return graphqlEntries;
}

export interface ExtendedServerTiming {
    start?: number;
    end?: number;
    name: string;
    description: string;
    duration: number;
}

export function showServerTiming(
    index: number,
    log = true,
    renderInterval = 1,
) {
    if (typeof index !== 'number') {
        throw new Error(`Index must be provided as a number. eg cmd.showServerTiming(0)`);
    }

    const queries = listGraphqlQueries(false);
    const query = queries[index];
    if (!query) {
        throw new Error(`Cannot find query with index ${ index }`);
    }

    const serverTiming = (query as any).serverTiming as ExtendedServerTiming[];
    if (!serverTiming?.length) {
        throw new Error(`No server timing for query with index ${ index }`);
    }

    const extendingTimings = serverTiming.map((t) => {
        if (!t.description) { return t; }
        const [ fullName, start, description ] = t.description.split(';');
        const parsedStart = parseFloat(start);
        if (isNaN(parsedStart) || parsedStart === undefined) { return t; }

        const parsedTiming: ExtendedServerTiming = {
            name: fullName,
            start: parsedStart,
            end: parsedStart + t.duration,
            duration: t.duration,
            description,
        };
        return parsedTiming;
    })
        .filter((t) => t.end !== undefined)
        .sort((a, b) => a.start - b.start);

    const minStart = min(extendingTimings.map((e) => e.start));
    const maxEnd = max(extendingTimings.map((e) => e.end));
    const duration = maxEnd - minStart;
    const size = Math.ceil(maxEnd / renderInterval);
    const baseRow = range(0, size).map(() => ' ');
    const maxNameCharacters = max(extendingTimings
        .map((e) => e.name)
        .concat('TOTAL')
        .map((e) => e.length)
    );
    const maxDurationCharacters = max(extendingTimings
        .map((e) => e.duration)
        .concat([ duration ])
        .map((eDuration) => eDuration.toFixed(0).length)
    );
    const maxStartCharacters = max(extendingTimings
        .map((e) => e.start)
        .concat([ minStart ])
        .map((eDuration) => eDuration.toFixed(0).length)
    );


    const css = [];
    const table = [];
    for (const timing of extendingTimings) {
        const start = Math.floor(timing.start / renderInterval);
        const data = [ ...baseRow ];
        for (let i = start; i < timing.end / renderInterval; i++) {
            data[i] = '█';
        }

        css.push('font: 1em');
        css.push('font-size: 0.2em;line-height:1em');
        css.push('font-size: 1em');
        const row = [
            '%c' + timing.name.padEnd(maxNameCharacters, ' '),
            ' ║ %c',
            ...data,
            `%c ║ ${ timing.duration.toFixed(0).padStart(maxDurationCharacters, ' ') }ms`,
            ` ║ ${ timing.start.toFixed(0).padStart(maxStartCharacters, ' ') }ms`,
        ];

        if (timing.description) {
            row.push(` ║ ${ timing.description }`);
        }
        // const arr = new Array(size + 1);
        // arr[0] = timing.name;
        table.push(row);
    }

    css.push('font-size: 1em');
    css.push('font-size: 0.2em;line-height:1em');
    css.push('font-size: 1em');
    const totalRow = [
        '%c' + 'TOTAL'.padEnd(maxNameCharacters, ' '),
        ' ║ %c',
        ...baseRow.map((r) => '='),
        `%c ║ ${ duration.toFixed(0).padStart(maxDurationCharacters, ' ') }ms`
    ];
    table.push(totalRow);
    // for (let i = 0; i < max; i += renderInterval) {

    // }

    // for (const timing of serverTiming) {
    //     table.push(timing);
    // }

    if (log) {
        const strTable = table.map((c) => c.join('')).join('\n');
        console.log(strTable, ...css);
    }

    return extendingTimings;

}
