<div [formGroup]="ruleForm">
    <ng-template #given>
        <div>
            <p-dropdown
                appendTo="body"
                placeholder="Select Trigger"
                [options]="triggers"
                optionLabel="name"
                optionValue="id"
                formControlName="trigger"
                (onChange)="triggerChanged()"
            ></p-dropdown>

            <hr />

            <div>
                <label><strong>Delay</strong></label>
                
                <br />
                <small >
                    We'll always check the rule's conditions right away.
                    If you'd like, you can add a delay to wait for a certain
                    amount of time before checking the conditions again,
                    and then carrying out the action. Set to 0 to remove delay.
                </small>

                <br />

                <p-inputNumber
                    formControlName="delay"
                    [min]="0"
                />

                <p-dropdown
                    appendTo="body"
                    [options]="delayUnitOptions"
                    formControlName="delayUnit"
                ></p-dropdown>
            </div>
    
            <div *ngIf="showOffset">
                <hr />

                <label><strong>Offset</strong></label>
                
                <br />
                <small >
                    If you want to trigger this rule after the event starts,
                    set the offset to a positive number (e.g., +1 day).
                    If you want it to trigger before the event starts,
                    set the offset to a negative number (e.g., -1 day).
                    For time-based triggers, this 'offset' adjusts the
                    specific scheduled time you'd like the rule to run.
                    <br /> <br/>

                    <strong>Note:</strong> The difference between 'delay'
                    and 'offset' is that a 'delay' adds time after the
                    conditions are first checked, while an 'offset'
                    changes the time the rule is triggered relative
                    to the event start.
                </small>

                <br />

                <p-inputNumber
                    formControlName="offset"
                />
                <p-dropdown
                    appendTo="body"
                    [options]="delayUnitOptions"
                    formControlName="offsetUnit"
                ></p-dropdown>
            </div>
        </div>
    </ng-template>
    <br>
    <ng-template #when>
        <div *appVar="ruleForm.get('conditions') as control">

            <small *ngIf="!trigger">
                You must set a trigger before you can set conditions.
            </small>
            <app-mutate-rule-condition-block
                *ngIf="trigger"
                [trigger]="trigger"
                [conditions]="control.value"
                (blockUpdated)="onConditionBlockUpdated()"
            ></app-mutate-rule-condition-block>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #then>
        <div *appVar="ruleForm.get('action') as control">
            <p-dropdown
                appendTo="body"
                placeholder="Select Action"
                [options]="actions"
                optionLabel="name"
                optionValue="id"
                formControlName="action"
                (onChange)="actionChanged(true)"
            ></p-dropdown>
            <br>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
        <hr />
        <div *ngIf="properties?.length">

            <div *ngFor="let property of properties" class="property">

                <label><strong>{{ property.label || property.key }}</strong></label>
                
                <br *ngIf="property.description" />
                <small *ngIf="property.description">{{ property.description }}</small>

                <input type="text" pInputText
                    [ngModel]="ruleForm.value.metadata[property.key]"
                    [ngModelOptions]="{standalone: true}"
                    (change)="updateMetadataKey(property.key, $event.target.value)"
                    class="meta-input"
                />
            </div>

        </div>

        
    </ng-template>
    <ng-template #attributes>
        <label>Attributes (comma seperated)</label>
        <div *appVar="ruleForm.get('attributes') as control">
            <p-chips
                formControlName="attributes"
                separator=","
                addOnBlur="addOnBlur"
                [allowDuplicate]="false"
            ></p-chips>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #metadata>
        <div *appVar="ruleForm.get('metadata') as control">
            <table>
                <thead>
                    <th>Key</th>
                    <th>Value</th>
                </thead>
                <tbody>
                    <tr *ngFor="let meta of ruleForm.value.metadata | keyvalue">
                        <td>
                            <input type="text" pInputText
                                [(ngModel)]="meta.key"
                                [disabled]="true"
                                [ngModelOptions]="{standalone: true}"
                                class="meta-input"
                            />
                        </td>
                        <td>
                            <input type="text" pInputText
                                [ngModel]="ruleForm.value.metadata[meta.key]"
                                (blur)="control.value[meta.key] = $event.target.value"
                                [ngModelOptions]="{standalone: true}"
                                (change)="updateMetadataKey(meta.key, $event.target.value)"
                                class="meta-input"
                            />
                        </td>
                    </tr>
                    <td>
                        <input 
                        type="text" 
                        pInputText
                        class="meta-input"
                        placeholder="Property Key"
                        #newKey
                    />
                    </td>
                    <td>
                        <button pButton label="Add Key" (click)="addProperty(newKey.value); newKey.value = ''"></button>
                    </td>
                </tbody>
            </table>

            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="ruleForm"
    objectType="Rule"
    mutateType="create"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>

<app-delete-object #delete
    name="this rule"
    type="Rule"
    (deleted)="deleteRule()"
>
</app-delete-object>