import { maxBy, minBy } from "lodash";

const bigSequentialOrder = 50;

export function setOrderToIndex(array: any[], offset = 0) {
    for (const [index, item] of array.entries()) {
        item.order = (index + offset);
    };
}

export function sortByOrder(array: any[]) {
    array.sort((a, b) => a.order - b.order);
}

export function generateEventMinOrder(array: any[]) {
    const sequentialOrders = array.map(item => item.event?.sequentialOrder);
    const minSequentialOrder = minBy(sequentialOrders);
    return (minSequentialOrder / 2) || -0.1;
}

export function generateEventMaxOrder(array: any[]) {
    const sequentialOrders = array.map(item => item.event?.sequentialOrder);
    const maxSequentialOrder = maxBy(sequentialOrders);
    return maxSequentialOrder + 1 || bigSequentialOrder;
}
