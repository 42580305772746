export const FRONTEND_PERMISSIONS = {
        franchiseManagement: 'frontend.franchise-managment',
        franchise: 'frontend.franchise',
        jobs: 'frontend.jobs',
        availability: 'frontend.availability',
        schedule: 'frontend.schedule',
        users: 'frontend.users',
        products: 'frontend.products',
        assets: 'frontend.assets',
        estimating: 'frontend.estimating',
        marketing: 'frontend.marketing',
        reports: 'frontend.reports',
        settings: 'frontend.settings',
        notifications: 'users.viewNotifications',
        needsAttention: 'frontend.needs-attention',
};
