import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

import { ReportStatus } from '../../../generated/graphql.generated';

import { ReportService } from '../../features/Reports/report.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { ButtonData, FreyaNotificationsService } from '../../services/freya-notifications.service';

import { DashboardService } from '../dashboard.service';

import { DashboardInfoItem, dashboardInfoItems, defaultDashboardInfoItem } from './dashboard-info-content';

/**
 * https://github.com/jfcere/ngx-markdown/issues/277
 *
 * About: WARNING: sanitizing HTML stripped some content, see https://g.co/ng/security#xss
 *
 * This is just a warning that angular removed some properties. It's just a warning, not much
 * we can do about it. It can be safely ignored.
 *
 * See:
 * https://github.com/jfcere/ngx-markdown/issues/277
 * https://github.com/jfcere/ngx-markdown/issues/263
 * https://github.com/angular/angular/issues/19645
 */

@Component({
  selector: 'app-dashboard-info',
  templateUrl: './dashboard-info.component.html',
  styleUrls: ['./dashboard-info.component.scss']
})
export class DashboardInfoComponent implements OnInit {

  @ViewChild('op') op: OverlayPanel;

  @Input() key: string;

  dashboardHelpItem: DashboardInfoItem;
  inspectLoading = false;

  constructor(
    private dashboardSvc: DashboardService,
    private reportService: ReportService,
    private detailsHelperSvc: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.dashboardHelpItem = this.getDashboardHelpItem();
  }

  getDashboardHelpItem() {

    let helpItem = dashboardInfoItems.find((k) => k.key === this.key);

    if (!helpItem) {
      helpItem = {
        ...defaultDashboardInfoItem,
        key: this.key,
      };
    }

    return helpItem;
  }

  inspectReport() {
    if (!this.dashboardHelpItem.reportTypeId) {
      return;
    }

    this.inspectLoading = true;

    // TODO: toast report generating

    return this.reportService.createReport({
      input: {
        reportType: this.dashboardHelpItem.reportTypeId,
        report: {
          saveData: true,
          saveReport: true,
          saveAggregations: true,
          variables: JSON.stringify({
            ...this.dashboardSvc.getPeriod(),
          }),
        }
      }
    }).subscribe((res) => {
      this.inspectLoading = false;
      const report = res.data?.createReport;
      if (!report || report.status === ReportStatus.Error) {
        console.error(res);
        this.localNotify.addToast.next({
          severity: 'error',
          summary: 'Could not generate report',
          detail: report?.error,
          life: 6000,
        });
        return;
      }
      console.log(report);

      this.op.hide();
      this.detailsHelperSvc.open('report', report, true);

      const viewButton: ButtonData = {
        label: 'View Report',
        routerLink: `/reports/static/${report.id}`,
        styleClass: 'p-button-info'
      };

      this.localNotify.addToast.next({
        summary: 'Report Generated',
        severity: 'success',
        data: {
          buttons: report?.saveData ? [ viewButton ] : undefined
        }
      });


    }, (err) => {
      console.error(err);
        this.localNotify.addToast.next({
          severity: 'error',
          summary: 'Could not generate report',
          detail: err.message,
          life: 6000,
        });
    });
  }

}
