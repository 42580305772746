import { Component, OnDestroy, OnInit } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { QueryRef } from 'apollo-angular';
import { SelectItem } from 'primeng/api';
import { SubSink } from 'subsink';

import {
  HistoryPartsFragment,
  ListHistoryDataOnlyGQL,
  ListHistoryDataOnlyQuery,
  ListHistoryDataOnlyQueryVariables,
  ListHistoryFilter,
  ListHistoryQueryVariables,
  ZoneDir,
} from '../../generated/graphql.generated';
import { pagination } from '../global.constants';
import { BrandingService } from '../services/branding.service';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { MarkdownHelperService } from '../services/markdown.service';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-history-search',
  templateUrl: './history-search.component.html',
  styleUrl: './history-search.component.scss'
})
export class HistorySearchComponent implements OnInit, OnDestroy {

  watchQuery: QueryRef<ListHistoryDataOnlyQuery, ListHistoryDataOnlyQueryVariables>;

  historyQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: true,
    hasMore: true,
    mergeNextResults: false,
  };

  placeholderCount = Array.from({ length: this.historyQH.limit });

  history: HistoryPartsFragment[] = [];

  name?: string;

  objectLabels: SelectItem<string>[] = [
    {
      value: 'User',
      label: 'User',
    },
    {
      value: 'CalendarEvent',
      label: 'Calendar Event',
    },
    {
      value: 'Job',
      label: 'Job',
    },
    {
      value: 'Asset',
      label: 'Asset',
    },
    {
      value: 'Zone',
      label: 'Zone',
    },
  ]

  historyFilterForm = new FormGroup({
    actions: new FormControl([]),
    objectLabel: new FormControl([]),
    objectIds: new FormControl([]),
    user: new FormControl(undefined),
  });

  pagination = pagination;

  private subs = new SubSink();

  constructor(
    private historyGQL: ListHistoryDataOnlyGQL,
    public markdownHelper: MarkdownHelperService,
    public brandingService: BrandingService,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.historyFilterForm.valueChanges.subscribe(() => {
      this.historyQH.skip = 0;
      this.historyQH.hasMore = true;
      this.historyQH.mergeNextResults = false;
      this.fetchHistory();
    });

    this.fetchHistory();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  fetchHistory() {
    if (this.watchQuery) {
      this.watchQuery.refetch(this.getQueryVars());
      return;
    }

    this.watchQuery = this.historyGQL.watch(this.getQueryVars(), {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.historyQH.loading = res.loading;
      if (res.loading) { return; }

      if (this.historyQH.mergeNextResults) {
        this.history = [
          ...this.history,
          ...res.data.history.history,
        ];
      } else {
        this.history = res.data.history.history;
      }

      this.historyQH.hasMore = res.data.history.history.length === this.historyQH.limit;
    }, (err) => {
      this.localNotify.apolloError('Failed to fetch history', err);
    });
  }

  getQueryVars(): ListHistoryQueryVariables {

    const { value } = this.historyFilterForm;

    const filter: ListHistoryFilter = {
      userId: value.user?.id,
      zoneId: this.brandingService.currentZone().value?.id,
      objectId: value.objectIds.length === 1 ? value.objectIds[0] : undefined,
      objectId_INCLUDES_ANY: value.objectIds.length > 1 ? value.objectIds : [],
      objectLabel: value.objectLabel.length === 1 ? value.objectLabel[0] : undefined,
      objectLabel_INCLUDES_ANY: value.objectLabel.length > 1 ? value.objectLabel : [],
      type: value.actions.length === 1 ? value.actions[0] : undefined,
      type_INCLUDES_ANY: value.actions.length > 1 ? value.actions : [],
      zoneDir: ZoneDir.Lte,
    };

    // The API doesn't handle empty values very well
    for (const key of Object.keys(filter)) {
      if (Array.isArray(filter[key]) && !filter[key].length) {
        delete filter[key];
      } else if (!filter[key]) {
        delete filter[key];
      }
    }

    return {
      input: {
        filter,
        limit: this.historyQH.limit,
        skip: this.historyQH.skip,
        sort: 'createdAt:DESC',
      },
    };
  }

  fetchMore() {
    if (this.historyQH.loading || !this.historyQH.hasMore) { return; }
    this.historyQH.skip += this.historyQH.limit;
    this.historyQH.mergeNextResults = true;
    this.fetchHistory();
  }

  selectHitory(historyId: string) {
    this.detailsHelper.detailsItem.next({ type: 'history', item: historyId });
  }
}
