import { Pipe, PipeTransform } from '@angular/core';

import { AttentionItem } from '../../generated/graphql.generated';
import { OBJECT_ICON_MAP } from '../global.constants';


@Pipe({
  name: 'attentionItemIcon'
})
export class AttentionItemIconPipe implements PipeTransform {

  transform(item: AttentionItem): unknown {
    switch (item.__typename) {
      case 'Job':
        return OBJECT_ICON_MAP.job;
      case 'CalendarEvent':
        return OBJECT_ICON_MAP['calendar-event'];
      case 'User':
        return OBJECT_ICON_MAP.users;
    }
  }

}
