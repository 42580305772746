import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { SubSink } from 'subsink';

import { FullProductFragment, ListProductsGQL, ListProductsQuery, Product, ProductsFilter } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ProductSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ProductSelectComponent,
    }
  ]
})
export class ProductSelectComponent implements OnInit, OnDestroy {

  @Output() productsSelected = new EventEmitter();

  @Input() filter: ProductsFilter;
  // If true the list of selected events will be defaulted to what is returned from the query
  @Input() selectAllByDefault = false;
  // Allows you to override the placeholder for the component
  @Input() placeholder = 'Select Products';

  subs = new SubSink();

  // Users Variables
  productsQueryRef: QueryRef<ListProductsQuery>;
  productSuggestions: FullProductFragment[] = [];

  // FORM CONTROL VARIABLES
  selectedProducts: FullProductFragment[] = [];
  touched = false;
  disabled = false;

  constructor(
    private listProductsGQL: ListProductsGQL,
    public responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.searchProducts();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchProducts(){
    this.productsQueryRef = this.listProductsGQL.watch({filter: this.filter, limit: -1});

    this.subs.sink = this.productsQueryRef.valueChanges.subscribe((res) => {
      this.productSuggestions = cloneDeep(res.data.products.products);
    });
  }

  emitSelect(){
    this.productsSelected.emit(this.selectedProducts);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (selectedEvents) => {};

  onTouched = () => {
    this.touched = true;
  };

  writeValue(products: Product[]) {
    this.selectedProducts = cloneDeep(products);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

}
