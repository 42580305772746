<div class="card" *ngIf="layout === 'full-page'">
    <div class="p-grid" [formGroup]="invoiceSearchForm">
        <div class="p-col-12">
            <h4>Invoices</h4>
        </div>
        <div class="p-col-6 p-md-3">
          <label>Search</label>
          <br>
          <input
            type="text"
            #searchInput
            pInputText
            formControlName="search"
            placeholder="Search invoices by job code, invoice number or customer"
            class="full-width"
          >
        </div>
        <div class="p-col-6 p-md-3">
          <label>Status</label>
          <br>
          <p-multiSelect
            class="full-width-multiselect"
            [options]="statuses"
            formControlName="status"
            optionlabel="label"
            optionValue="option"
            ></p-multiSelect>
        </div>
        <div class="p-col-6 p-md-3">
          <label>Balance</label>
          <br>
          <p-multiSelect
            class="full-width-multiselect"
            [options]="balanceStatuses"
            formControlName="balanceStatus"
            optionlabel="label"
            optionValue="option"
            ></p-multiSelect>
        </div>
        <div class="p-col-6 p-md-3">
          <label>Invoice Date</label>
          <br>
          <p-calendar
            selectionMode="range"
            formControlName="invoiceDate"
            placeholder="Enter Start and End Dates"
            [showIcon]="true"
            styleClass="full-width"
            ></p-calendar>
        </div>
        <div class="p-col-6 p-md-3">
          <label>Due Date</label>
          <br>
          <p-calendar
            selectionMode="range"
            formControlName="dueDate"
            placeholder="Enter Start and End Dates"
            [showIcon]="true"
            styleClass="full-width"
            ></p-calendar>
        </div>
    </div>
</div>

<br>

<div
  class="card"
  >
  <div class="p-grid" *ngIf="layout === 'compact'">
    <div class="p-col">
      <h5>Invoices</h5>
    </div>
    <ng-container
      ></ng-container>
    <div class="p-col p-text-right" *hasPermission="['invoice.create']">
      <button
        pButton
        icon="pi pi-plus"
        (click)="openCreateInvoiceDialog()"
        ></button>
    </div>
  </div>
  <p-table
    [value]="invoices"
    [paginator]="true"
    (onLazyLoad)="retrieveMoreInvoices($event)"
    [lazy]="true"
    [totalRecords]="invoicesQH.total"
    [(rows)]="invoicesQH.limit"
    [(first)]="invoicesQH.skip"
    [rowsPerPageOptions]="pagination.rowPerPageOptions"
    [loading]="invoicesQH.loading"
    [showCurrentPageReport]="!invoicesQH.loading"
    breakpoint="620px"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="activeColumns.name">Invoice</th>
        <th *ngIf="activeColumns.invoiceDate">Date</th>
        <th *ngIf="activeColumns.dueDate">Due</th>
        <th *ngIf="activeColumns.dueDate">Balance</th>
        <th *ngIf="activeColumns.status">Status</th>
        <th *ngIf="activeColumns.job">Job</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice>
      <tr
        class="selectable-row"
        (click)="openInvoiceDetails(invoice)"
        >
        <td *ngIf="activeColumns.name">
          {{ invoice | invoiceName: (invoice?.job?.users | jobCustomer) : invoice?.job?.code  }}
        </td>
        <td *ngIf="activeColumns.invoiceDate">
          {{ invoice.invoiceDate | freyaDate }}
        </td>
        <td *ngIf="activeColumns.dueDate">
          {{ invoice.dueDate | freyaDate }}
        </td>
        <td *ngIf="activeColumns.dueDate">
          {{ (invoice.total - invoice.paidTotal) / 100 | currency : invoice.currency }}
        </td>
        <td *ngIf="activeColumns.status">
          <app-object-state
            *ngIf="invoice"
            [object]="invoice"
            objectType="invoice"
            [colorful]="true"
            ></app-object-state>
        </td>
        <td *ngIf="activeColumns.job" >
          <app-open-link [link]="['/job/', invoice?.job?.id]"></app-open-link>
      </td>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="emptymessage"
      >
      <tr>
        <td [attr.colspan]="'5'">
          {{ emptyMessage }}
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>
