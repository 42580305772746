import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { cloneDeep } from 'lodash';
import { SubSink } from 'subsink';

import { BaseReportTypeFragment, BaseZoneFragment, CreateReportGQL, ReportType_SchemaFragment } from '../../../generated/graphql.generated';
import { PeriodService } from '../../dashboard/period.service';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MarkdownHelperService } from '../../services/markdown.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';

import { MutateObjectComponent, MutateObjectElement } from '../../shared/mutate-object/mutate-object.component';
import { periodToString } from '../report.utils';
import { isPeriod } from '../reports.constants';

type MutateReportReportType = BaseReportTypeFragment & ReportType_SchemaFragment;


@Component({
  selector: 'app-mutate-report',
  templateUrl: './mutate-report.component.html',
  styleUrls: ['./mutate-report.component.scss']
})
export class MutateReportComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  @Input() mutateType: 'create' = 'create' as const;
  @Input() reportType: MutateReportReportType;
  @Input() navigate = true;

  // Template Refs
  @ViewChild('period') periodRef: TemplateRef<any>;
  @ViewChild('name') nameRef: TemplateRef<any>;

  variables: any = {};
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [ Validators.maxLength(200) ]),

    period: new UntypedFormControl('Today', []),
    customPeriod: new UntypedFormControl([new Date(),new Date()], []),
    variables: new UntypedFormControl({}, []),

    zone: new UntypedFormControl('No Zone', []),
  });

  initialFormState = cloneDeep(this.form.value);

  periods = this.periodService.getPeriodNameValues({
    includeCustom: true,
    defaultPeriod: this.initialFormState.period,
  });

  steps: MutateObjectElement[];
  currentZone: BaseZoneFragment;


  subs = new SubSink();
  constructor(
    private createReportGQL: CreateReportGQL,
    private detailsHelper: DetailsHelperService,
    private router: Router,
    private localNotify: FreyaNotificationsService,
    private periodService: PeriodService,
    public freyaHelper: FreyaHelperService,
    public markdownHelper: MarkdownHelperService,
    private brandingService: BrandingService,
    private timeZone: TimezoneHelperService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.create();
      this.reset();
    }
  }

  reset() {
    this.form.reset(this.initialFormState);
    this.variables = {};
  }

  /**
   * @returns start/end variables as formatted strings
   */
  periodToString() {
    return periodToString(this.form.value.period, this.variables);
  }

 /**
  * @returns the parsed variable schema since it is returned as string
  */
  getVariableSchema() {
    try {
      return JSON.parse(this.reportType.variableSchema || '{}');
    } catch (err) {
      console.error(`Could not parse schema`, err);
      return {};
    }
  }

  /**
   * Set the current zone to the currently contexted zone
   */
  setZone() {
    this.currentZone = this.brandingService.currentZone().value;
    const timezone = this.timeZone.getCurrentTimezone();
    this.form.get('zone').setValue(`${ this.currentZone.name } (${ timezone })`);
  }

  /**
   * Set the placeholder name of the report type to match the name that will be set on
   * the backend
   */
  getNamePlaceholder() {
    const zoneName = this.currentZone?.name || 'Current Zone';
    const time = dayjs().format('YYYY/MM/DD HH:mm');
    const name = `${ this.reportType.name } at ${ time } [${ this.periodToString() }] (${ zoneName })`;
    return name;
  }

  /**
   * @returns true if this report type contains start/end variables
   */
  hasPeriod() {
    const schema = this.getVariableSchema();
    return Boolean(schema.start || schema.end);

  }

  parseReportTypePeriod(reportType: BaseReportTypeFragment) {
    if (!reportType?.description) { return undefined; }

    for (const line of reportType.description.split('\n')) {
      const parsedLine = line.replace(/[*]/g, '').toLowerCase().trim();
      if (parsedLine.startsWith('period:')) {
        return line;
      }
    }

    return undefined;
  }

  /**
   * Set the default period from the report type attribute of "defaultPeriod"
   */
  setDefaultPeriodFromReportType() {
    if (!this.reportType?.attributes) { return; }
    const period = this.freyaHelper.getAttributeValueByPrefix(this.reportType.attributes, 'defaultPeriod');
    if (!period) { return; }
    // custom isn't supported
    if (period === 'Custom') { return; }

    // only set the default period if it exists in the values
    if (this.periods.find((p) => p.value === period)) {
      this.form.patchValue({
        period,
      });
    }
  }

  /**
   * Suffix the default period with (default)
   *
   * The default period is either "Today" or the defaultPeriod:: attribute value
   * as set by setDefaultPeriodFromReportType
   */
  updatePeriods() {
    const currentPeriod = this.form.get('period').value;

    if (currentPeriod === 'Custom') { return; }

    this.periods = this.periodService.getPeriodNameValues({
      defaultPeriod: currentPeriod,
      includeCustom: true,
      sortTop: 8,
    });
  }

  openDialog() {
    this.setZone();
    this.setPeriod();
    this.updatePeriods();

    const steps: MutateObjectElement[] = [
      {
        name: 'Name',
        ref: this.nameRef,
        control: 'name',
        type: 'func',
        reviewFunc: () => this.form.value.name || this.getNamePlaceholder(),
      },
    ];

    if (this.hasPeriod()) {
      steps.push({
        name: 'Period', ref: this.periodRef, control: 'period', type: 'func',
        reviewFunc: () => this.periodToString()
      });
    }

    // const schema = this.getVariableSchema();
    // TODO: resolve other values in schema / resolve report variables to set steps

    steps.push({
      name: 'Zone (Timezone)',
      type: 'text',
      control: 'zone',
    });

    this.steps = steps;

    this.mutateRef.steps = this.steps;
    this.mutateRef.titleText = `Create Report: ${ this.reportType.name }`;

    this.updatePeriodVariables({
      updateCustomPeriod: true,
    });
    this.mutateRef.openDialog();
  }

  /**
   * If we passed variables when opening the dialog (e.g. while re-runing a report),
   * this uses the provided variables to define a custom period for report generation,
   * otherwise uses the report type to set a default period.
   *
   */
  setPeriod() {
    if (!Object.keys(this.variables).length) {
      this.setDefaultPeriodFromReportType();
      return;
    }
    if (this.hasPeriod() && this.variables.start && this.variables.end) {
      this.form.patchValue({
        period: 'Custom',
      });
      const customPeriod = [ new Date(this.variables.start * 1000), new Date(this.variables.end * 1000) ];
      this.form.get('customPeriod').setValue(customPeriod);
      delete this.variables.period;
      // this.variables.period = 'Rerun';
    } else {
      delete this.variables.start;
      delete this.variables.end;
      delete this.variables.period;
    }
  }

  /**
   * @returns true if we are running a custom period
   */
  isCustomPeriod() {
    return this.form.value.period === 'Custom';
  }


  updatePeriodLocalStorage() {
    const period = this.form.value.period || 'Today';
    this.periodService.updatePeriodLocalStorage(period);
  }

  /**
   * Called on init and after the period is updated.
   *
   * Parses the form "period" value and sets the start/end variables
   * from that period. Also parses the custom period type into the current timezone
   *
   * If this report type does not have start/end variables
   * defined in the schema this method does nothing.
   *
   */
  updatePeriodVariables(opts: {
    updateCustomPeriod?: boolean;
  }) {
    // don't set the start/end variables if this schema does not have the report vars
    if (!this.hasPeriod()) { return; }

    const period = this.form.value.period || 'Today';

    let start = dayjs.tz().startOf('day').unix();
    let end = dayjs.tz().endOf('day').unix();
    const customPeriodControl = this.form.get('customPeriod');
    if (isPeriod(period)) {
      const unixPeriod = this.periodService.getUnixPeriod(period);
      start = unixPeriod.start;
      end = unixPeriod.end;
      customPeriodControl.disable();

    } else if (this.isCustomPeriod()) {
      if (customPeriodControl.disabled) {
        customPeriodControl.enable();
      }

      const [ customStart, customEnd ] = this.form.value.customPeriod as (Date | null)[];

      if (customStart) {
        start = dayjs.tz()
          .set('year', customStart.getFullYear())
          .set('month', customStart.getMonth())
          .set('date', customStart.getDate())
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .set('ms', 0)
          .unix();
      }
      
      if (customEnd) {
        end = dayjs.tz()
          .set('year', customEnd.getFullYear())
          .set('month', customEnd.getMonth())
          .set('date', customEnd.getDate())
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .set('ms', 0)
          .unix();
      }

      // console.log('Custom period set', start, end, new Date(start * 1000), new Date(end * 1000));
    }

    this.variables.start = start;
    this.variables.end = end;
    this.variables.period = period;

    if (opts.updateCustomPeriod) {
      const dayJsStart = dayjs.tz(start * 1000);
      const dayJsEnd = dayjs.tz(end * 1000);
      customPeriodControl.setValue([
        new Date(
          dayJsStart.get('year'),
          dayJsStart.get('month'),
          dayJsStart.get('date'),
          0,0,0,0
        ),
        // TODO: set end of day here
        new Date(
          dayJsEnd.get('year'),
          dayJsEnd.get('month'),
          dayJsEnd.get('date'),
          23,
          59,
          59,
          0,
        ),
      ]);
    }

  }

  /**
   * Remove undefined/null values from variables
   */
  parseVariables(vars = {}) {
    // eslint-disable-next-line guard-for-in
    for (const key in vars) {
      if (vars[key] === undefined || vars[key] === null) {
        delete vars[key];
      } else if (typeof vars[key] === 'object') {
        vars[key] = this.parseVariables(vars[key]);
      }
    }

    return vars;

  }

  /**
   * Create the report and immediately open the report in the details sidebar
   *
   * The report will have the generating status, so the details sidebar
   * will poll until the report is complete or failed
   */
  private create() {

    const variables = this.parseVariables(this.variables);
    const strVariables = JSON.stringify(variables);
    // console.log({variables});
    this.localNotify.addToast.next({
      severity: 'info',
      summary: 'Generating Report',
      detail: this.reportType.name,
    });

    const name = this.form.value.name || undefined;

    this.createReportGQL.mutate({
      waitForCompletion: false,
      input: {
        reportType: this.reportType.id,
        report: {
          variables: strVariables,
          saveAggregations: true,
          saveData: true,
          saveReport: true,
          name,
        },
      }
    }).subscribe(async (res) => {
      const report = res.data?.createReport;
      this.mutateRef.closeDialog();
      if (!report) {
        throw new Error(`No report returned`);
      }

      console.log(`Report generated`, res);

      this.detailsHelper.pushUpdate({
        action: 'create',
        id: report.id,
        type: 'Report',
        source: 'local',
        update: {
          report,
          reportType: this.reportType,
        },
      });

      this.detailsHelper.open('report', report);
      if (this.navigate) {
        this.router.navigate([ '/reports', report.id ]);
      }

    }, (error) => {
      console.error(error);
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'Could not generate report',
        detail: error.message,
      });
      this.localNotify.apolloError(`Failed to create report `, error);
      this.mutateRef.loading=false;
      // sub.next({
      //   generating: false,
      //   error,
      // });
    });
  }

}
