import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { AdditionalValueType } from '../base/rightpanel/app.rightpanel.component';
import { objectTypeFilter, ObjectUpdate, UpdatedType } from '../utilities/details-helper.util';

export interface DetailsItem {
  type: string;
  item: any;
  // if this is false then the system needs to load the item from the "id" prop
  loaded?: boolean;
  // if set, app will check that the item is viewable from the current zone before trying to load it
  zoneId?: string;
  additionalValues?: Record<AdditionalValueType, any>;
}

export type UpdateType = 'create' | 'update' | 'delete' | 'added' | 'removed' | boolean;

@Injectable({
  providedIn: 'root'
})
export class DetailsHelperService {

  hasScrolled = false;

  rightPanelOpen = false;

  objectUpdated = new Subject<ObjectUpdate>();

  // Send null to close
  detailsItem = new BehaviorSubject<null | DetailsItem>(null);

  constructor() { }

  /**
   *  Loads an item into the details panel.
   *  If you set `zoneId`, the app will check whether the item is viewable from the user's current zone before trying to load it,
   *  otherwise it will assume it is viewable.
   *  For best performance, leave unset unless there is a chance the item might not be viewable from the current zone.
   */
  open(
    type: string,
    item: any,
    loaded = true,
    zoneId?: string,
    additionalValues?: Record<AdditionalValueType, any>,
  ) {
    this.detailsItem.next({
      type,
      item,
      loaded,
      zoneId,
      additionalValues,
    });
  }


  /**
   *  Wrapper for the  objectUpdated subject and filter it by a given type
   *  it will return subject which is filtered by given type(s).
   */
  getObjectUpdates(types: UpdatedType | UpdatedType[]) {
    return this.objectUpdated.pipe(objectTypeFilter(types));
  }

  /**
   *  Wrapper for the objectUpdated subject
   */
  pushUpdate(update: ObjectUpdate, clearPanel=false) {
    if(clearPanel){
      this.detailsItem.next(null);
    }
    return this.objectUpdated.next(update);
  }

}
