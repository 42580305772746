<div *ngIf="!cardView">
    <div class="card">
        <div class="p-grid">
            <div class="p-col">
                <h4 data-cy="area-card-header">Areas</h4>
                <small>Different Areas of your franchise.</small>
            </div>
            <div class="p-col">
                <button
                    *hasPermission="['zones.create']"
                    type="button"
                    pButton
                    icon="pi pi-plus"
                    label="Add"
                    (click)="openCreate()"
                    data-cy="add-area-button"
                ></button>
            </div>
        </div>
    </div>

    <div *ngIf="areasLoading">
        <p-skeleton height="80px"></p-skeleton>
        <br>
        <br>
        <p-skeleton height="80px"></p-skeleton>
        <br>
        <br>
        <p-skeleton height="80px"></p-skeleton>
    </div>

    <div
        class="card selectable-row"
        (click)="detailHelper.detailsItem.next({item: zone, type: 'area'})"
        *ngFor="let zone of zoneAreas"
        [style]="{'border-left': zone.color | cardBorderValue}"
    >
        <h5>{{zone.name}}</h5>
        <div>
            <p-chip
                *ngFor="let area of zone.areas"
                class="quick-margin"
                [style]="{'background-color': zone.color}"
            >{{area.areaCode}}</p-chip>
        </div>
    </div>
</div>

<div *ngIf="cardView">
    <h5>
        <p-badge [value]="0"></p-badge> Areas
    </h5>
    <a routerLink="/franchise-management/territory">View Areas</a>
</div>

<app-mutate-area #ma></app-mutate-area>
