<ng-container *ngIf="explanationStyle === 'chip'">
  <p-chip
    *ngFor="let explanation of attentionExplanations"
    [label]="explanation.label"
    [pTooltip]="explanation.description"
    styleClass="p-m-1"
    ></p-chip>

  <p-chip
    *ngIf="additionalExplanations && !additionalExplanations.expanded && additionalExplanations.explanations.length > 0"
    [label]="additionalExplanations.label"
    styleClass="p-m-1"
    [pTooltip]="additionalExplanations.tooltip"
    (click)="$event.stopPropagation(); additionalExplanations.expanded = true"
    ></p-chip>

  <ng-container *ngIf="additionalExplanations?.expanded">
    <p-chip
      *ngFor="let explanation of additionalExplanations.explanations"
      [label]="explanation.label"
      [pTooltip]="explanation.description"
      styleClass="p-m-1"
      ></p-chip>
  </ng-container>
</ng-container>

<ng-container *ngIf="explanationStyle === 'text'">
  <span *ngIf="attentionExplanations?.length">{{ attentionExplanations[ 0 ]?.label}}:</span>
</ng-container>
