import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MarkdownModule } from 'ngx-markdown';

import { CustomInputsModule } from '../custom-inputs/custom-inputs.module';

import { DynamicReportsComponent } from '../dynamic-reports/dynamic-reports/dynamic-reports.component';
import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { LibModule } from '../lib.ts/lib.module';
import { ReportActionsComponent } from '../shared/report-actions/report-actions.component';
import { SharedModule } from '../shared/shared.module';

import { ExportToSheetsDialogComponent } from './export-to-sheets-dialog/export-to-sheets-dialog.component';
import { MutateReportComponent } from './mutate-report/mutate-report.component';
import { MutateScheduledReportComponent } from './mutate-scheduled-report/mutate-scheduled-report.component';
import { ReportAggregationValueComponent } from './report-aggregation-value/report-aggregation-value.component';
import { ReportAggregationValueLabelComponent } from './report-aggregation-value-label/report-aggregation-value-label.component';
import { ReportAggregationValueValueComponent } from './report-aggregation-value-value/report-aggregation-value-value.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { ReportPageComponent } from './report-page/report-page.component';
import { ReportStatusIconComponent } from './report-status-icon/report-status-icon.component';
import { ReportTypeDetailsComponent } from './report-type-details/report-type-details.component';
import { ReportTypePageComponent } from './report-type-page/report-type-page.component';
import { ReportTypesComponent } from './report-types/report-types.component';
import { ScheduledReportDetailsComponent } from './scheduled-report-details/scheduled-report-details.component';
import { ScheduledReportPageComponent } from './scheduled-report-page/scheduled-report-page.component';
import { ScheduledReportsListComponent } from './scheduled-reports-list/scheduled-reports-list.component';



@NgModule({
  declarations: [
    ReportActionsComponent,
    ReportTypesComponent,
    ReportTypePageComponent,
    ReportPageComponent,
    ScheduledReportPageComponent,
    ReportTypeDetailsComponent,
    ReportDetailsComponent,
    MutateReportComponent,
    ReportStatusIconComponent,
    ReportAggregationValueComponent,
    ReportAggregationValueValueComponent,
    ReportAggregationValueLabelComponent,
    ScheduledReportsListComponent,
    MutateScheduledReportComponent,
    ScheduledReportDetailsComponent,
    ExportToSheetsDialogComponent,
    DynamicReportsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
    ReactiveFormsModule,
    CustomInputsModule,
    MarkdownModule.forChild(),
    LibModule,
  ],
  exports: [
    ReportTypesComponent,
    ReportTypePageComponent,
    ReportPageComponent,
    ScheduledReportPageComponent,
    ReportTypeDetailsComponent,
    ReportDetailsComponent,
    MutateReportComponent,
    ScheduledReportsListComponent,
    MutateScheduledReportComponent,
    ScheduledReportDetailsComponent,
  ],
})
export class ReportsModule { }
