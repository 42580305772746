import { gql } from 'graphql-tag';

import { User } from './auth';
import { usersBaseGQL } from './common.gql';
import { metaDataGQL } from './metadata';
import { attributesGQL, Price, PricesPagination, productsBaseGQL } from './products';
import { zonesBaseGql } from './zones';


export interface ListPricesOutput {
	prices: PricesPagination;
}

export interface CreatePricesOutput {
	createPrices: Price[];
}

export interface UpdatePricesOutput {
	updatePrices: boolean;
}

export interface DeletePricesOutput {
	deletePrices: boolean;
}


export interface PricesFilter {
    priceIds: string[];
    productIds: string[];
    userIds?: string[];
	// Default true
    isActive?: boolean;
    hasAllAttributes: string[];
    hasAnyAttributes: string[];
	// Default False
    showDeleted?: boolean;
}


export interface CreatePriceInput {
    productId: string;
	// In cents
    amount: number;
	// ISO currency code
    currency: string;
	// Default true
    active?: boolean;
    userId?: string;
    attributes?: string[];
    metadata?: string;
}


export interface UpdatePriceInput {
    priceId: string;
    active?: boolean;
    attributes?: string[];
    metadata?: string;
}

export interface PricesInput {
	filter?: PricesFilter;
	skip?: number;
	limit?: number;
}



export interface CreatePricesInput {
	prices?: CreatePriceInput[];
    // Depending on permissions the current context's zone will be used if none is given.
    zoneId?: string;
}



export interface UpdatePricesInput {
	prices?: UpdatePriceInput[];
}



export interface DeletePricesInput {
	ids?: string[];
}

export const pricesBaseGQL = `
    id
    name
    createdAt
    updatedAt
    deletedAt
    active
    amount
    currency
`;

export const pricesUserGQL = `
    user {
        ${usersBaseGQL}
    }
`;

export const pricesZoneGQL = `
    zone {
        ${zonesBaseGql}
    }
`;

export const pricesProductGQL = `
    product {
        ${productsBaseGQL}
    }
`;

export interface GeneratePricesQueryInput {
    attributes?: boolean;
    metaData?: boolean;
    products?: boolean;
    user?: boolean;
    zone?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function generatePricesQuery(input: GeneratePricesQueryInput){
    const query = gql`
        query prices($filter: PricesFilter, $skip: Int, $limit: Int, ){
            prices(filter: $filter, skip: $skip, limit: $limit, ){
                    total
                    skipped
                    limited
                    prices{
                        ${pricesBaseGQL}
                        ${input.attributes ? attributesGQL : ``}
                        ${input.products ? pricesProductGQL : ``}
                        ${input.metaData ? metaDataGQL : ``}
                        ${input.zone ? pricesZoneGQL : ``}
                        ${input.user ? pricesUserGQL : ``}
                    }
        }		
    }
`;

    return query;
}




export const createPricesMutation = gql`
	mutation createPrices($prices: [CreatePriceInput!]!, $zoneId: String){
		createPrices(prices: $prices, zoneId: $zoneId){
			    ${pricesBaseGQL}
	    }		
    }
`;


export const updatePricesMutation = gql`
	mutation updatePrices($prices: [UpdatePriceInput!]!, ){
		updatePrices(prices: $prices, )
	}
`;


export const deletePricesMutation = gql`
	mutation deletePrices($ids: [String!]!, ){
		deletePrices(ids: $ids, ) 

	}
`;

