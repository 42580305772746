<section class="p-d-flex p-flex-column">
  <div class="revenue">
    <!-- TODO: Fix the currency; make it dynamic -->
    <ng-container *ngIf="todaysRevenue$ | async as todaysRevenue">
      <span class="p-d-flex p-jc-between">
        <span class="amount">
          {{ todaysRevenue.total | freyaCurrency: todaysRevenue.currency }}
        </span>
        <a
          [routerLink]="['/reports', 'dynamic', 'new']"
          [queryParams]="revenueReportQueryParams"
        >
          <i
            class="open-report pi pi-file-check"
            pTooltip="Open revenue report"
          ></i>
        </a>
      </span>
      <span class="day-info">Today's total revenue</span>
    </ng-container>
  </div>
  <header class="p-d-flex p-jc-between p-ai-center">
    <h5>Available Crew</h5>
  </header>

  <div class="search-bar">
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        type="text"
        pInputText
        placeholder="Search crew"
        [formControl]="filterCrewForm.get('userSearch')"
      />
    </p-iconField>
  </div>

  <form
    class="p-d-flex p-jc-start p-ai-center filters"
    [formGroup]="filterCrewForm"
  >
    <p-dropdown formControlName="role" [options]="rolesOptions">
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <ng-container
          *ngTemplateOutlet="
            dropdownItemTemplate;
            context: { $implicit: selectedOption, labelPrefix: 'Role:' }
          "
        ></ng-container>
      </ng-template>
      <ng-template pTemplate="item" let-item>
        <ng-container
          *ngTemplateOutlet="
            dropdownItemTemplate;
            context: { $implicit: item, labelPrefix: '' }
          "
        ></ng-container>
      </ng-template>
    </p-dropdown>
  </form>
  <div class="p-d-flex p-flex-column faded-border todaysCrew">
    <ng-container
      *ngIf="{
        usersLoaded: usersLoaded$ | async,
        filteredPotentialCrew: filteredPotentialCrew$ | async,
      } as state"
    >
      <ng-container *ngIf="state.usersLoaded; else usersLoading">
        <div
          class="p-d-flex p-flex-column crew-list"
          cdkDropList
          *ngIf="state.filteredPotentialCrew?.length; else noUsersFound"
        >
          <app-dispatch-chip
            cdkDrag
            [cdkDragData]="{ attendee: user }"
            *ngFor="let user of state.filteredPotentialCrew"
            [name]="user?.name"
            [eventsCount]="user?.eventsCount"
            [revenue]="user?.revenue"
            [ngStyle]="{
              order: user.role === EVENT_ATTENDEE_ROLES.crewLead ? 1 : 2,
            }"
            [showStar]="
              user.role === EVENT_ATTENDEE_ROLES.crewLead ||
              user.role === EVENT_ATTENDEE_ROLES.assistantCrewLead
            "
            panelType="left"
          ></app-dispatch-chip>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #usersLoading> Loading Users </ng-template>
    <ng-template #noUsersFound>
      <div
        class="p-d-flex p-flex-column p-jc-center p-ai-center no-users-found"
      >
        <p-button
          [outlined]="true"
          severity="secondary"
          label="Add Crew"
          icon="pi pi-user-plus"
          (onClick)="addAvailableCrew()"
        ></p-button>
        <p>No users found</p>
      </div>
    </ng-template>
  </div>

  <h5>Available Trucks</h5>
  <div class="search-bar">
    <p-iconField iconPosition="left">
      <p-inputIcon styleClass="pi pi-search" />
      <input
        type="text"
        pInputText
        placeholder="Search trucks"
        [formControl]="filterCrewForm.get('assetSearch')"
      />
    </p-iconField>
  </div>
  <div class="p-d-flex p-flex-column faded-border todaysTrucks">
    <ng-container
      *ngIf="{
        assetsLoaded: assetsLoaded$ | async,
        filteredPotentialAssets: filteredPotentialAssets$ | async,
      } as state"
    >
      <ng-container *ngIf="state.assetsLoaded; else assetsLoading">
        <div
          class="p-d-flex p-flex-column crew-list"
          cdkDropList
          *ngIf="state.filteredPotentialAssets?.length; else noAssetsFound"
        >
          <app-dispatch-chip
            cdkDrag
            [cdkDragData]="{ asset }"
            *ngFor="let asset of state.filteredPotentialAssets"
            [name]="asset?.name"
            icon="pi pi-truck"
            [eventsCount]="asset?.eventsCount"
            [revenue]="asset?.revenue"
            panelType="left"
          ></app-dispatch-chip>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #assetsLoading> Loading Assets </ng-template>
    <ng-template #noAssetsFound><p>No assets found</p></ng-template>
  </div>
</section>

<!-- Reusable template for dropdown items -->
<ng-template #dropdownItemTemplate let-item let-labelPrefix="labelPrefix">
  <div class="flex align-items-center gap-2">
    <i class="pi p-mr-2" [ngClass]="item.icon"></i>
    <div class="p-text-capitalize">{{ labelPrefix }} {{ item.label }}</div>
  </div>
</ng-template>
