import { Injectable } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { clone } from 'lodash';

import { environment } from '../../environments/environment';
import { BaseZoneFragment } from '../../generated/graphql.generated';

import { AdvancedPeriodOptions, Period, periodOptions, PeriodOptions } from '../reports/reports.constants';
import { BrandingService } from '../services/branding.service';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  zone: BaseZoneFragment;

  constructor(
    private branding: BrandingService,
  ) {
    this.branding.currentZone().subscribe((z) => {
      this.zone = z;
    });
  }

  getUnixPeriod(
    period: AdvancedPeriodOptions,
    periodValue?: Period,
  ): Period {
    const eod = dayjs().endOf('day').unix();

    const beginningOfTime = this.zone?.createdAt || dayjs().subtract(30, 'years').unix();
    switch (period) {
      default:
      case 'Custom':
        if (periodValue?.start){
          return periodValue;
        } else {
          return { start: dayjs().startOf('day').unix(), end: eod};
        }
        return periodValue || { start: dayjs().startOf('day').unix(), end: eod};
      case 'Today': return {
        start: dayjs().startOf('day').unix(),
        end: eod,
      };
      case 'Tomorrow': return {
        start: dayjs().startOf('day').add(1, 'day').unix(),
        end: dayjs().endOf('day').add(1, 'day').unix(),
      };
      case 'Yesterday': return {
        start: dayjs().startOf('day').subtract(1, 'day').unix(),
        end: dayjs().endOf('day').subtract(1, 'day').unix(),
      };
      case 'Next 24 hours': return {
        start: dayjs().unix(),
        end: dayjs().add(1, 'day').unix(),
      };
      case 'Last 24 hours': return {
        start: dayjs().subtract(1, 'day').unix(),
        end: dayjs().unix(),
      };

      case 'Last Week': return {
        start: dayjs().subtract(7, 'days').startOf('week').unix(),
        end: dayjs().subtract(7, 'days').endOf('week').unix(),
      };
      case 'This Week': return {
        start: dayjs().startOf('week').unix(),
        end: dayjs().endOf('week').unix(),
      };
      case 'Next Week': return {
        start: dayjs().add(7, 'days').startOf('week').unix(),
        end: dayjs().add(7, 'days').endOf('week').unix(),
      };
      case 'WTD': return {
        start: dayjs().startOf('week').unix(),
        end: eod,
      };
      case 'Last 7 days': return {
        start: dayjs().subtract(7, 'days').startOf('day').unix(),
        end: eod,
      };
      case 'Last 14 days': return {
        start: dayjs().subtract(14, 'days').startOf('day').unix(),
        end: eod,
      };

      case 'Last Month': return {
        start: dayjs().subtract(1, 'month').startOf('month').unix(),
        end: dayjs().subtract(1, 'month').endOf('month').unix(),
      };
      case 'This Month': return {
        start: dayjs().startOf('month').unix(),
        end: dayjs().endOf('month').unix(),
      };
      case 'Next Month': return {
        start: dayjs().add(1, 'month').startOf('month').unix(),
        end: dayjs().add(1, 'month').endOf('month').unix(),
      };
      case 'Month After Next': return {
        start: dayjs().add(2, 'month').startOf('month').unix(),
        end: dayjs().add(2, 'month').endOf('month').unix(),
      };
      case 'MTD': return {
        start: dayjs().startOf('month').unix(),
        end: eod,
      };
      case 'MTD Last Year': return {
        start: dayjs().subtract(1, 'year').startOf('month').unix(),
        end: dayjs().subtract(1, 'year').endOf('day').unix(),
      };
      case 'Last 30 days': return {
        start: dayjs().subtract(30, 'days').startOf('day').unix(),
        end: eod,
      };

      case 'Last Quarter': return {
        start: dayjs().subtract(1, 'quarter').startOf('quarter').unix(),
        end: dayjs().subtract(1, 'quarter').endOf('quarter').unix(),
      };
      case 'This Quarter': return {
        start: dayjs().startOf('quarter').unix(),
        end: dayjs().endOf('quarter').unix(),
      };
      case 'Next Quarter': return {
        start: dayjs().add(1, 'quarter').startOf('quarter').unix(),
        end: dayjs().add(1, 'quarter').endOf('quarter').unix(),
      };
      case 'QTD': return {
        start: dayjs().startOf('quarter').unix(),
        end: eod,
      };
      case 'Last 90 days': return {
        start: dayjs().subtract(90, 'days').startOf('day').unix(),
        end: eod,
      };

      case 'Last Year': return {
        start: dayjs().subtract(1, 'year').startOf('year').unix(),
        end: dayjs().subtract(1, 'year').endOf('year').unix(),
      };
      case 'This Year': return {
        start: dayjs().startOf('year').unix(),
        end: dayjs().endOf('year').unix(),
      };
      case 'Next Year': return {
        start: dayjs().add(1, 'year').startOf('year').unix(),
        end: dayjs().add(1, 'year').endOf('year').unix(),
      };
      case 'YTD': return {
        start: dayjs().startOf('year').unix(),
        end: eod,
      };
      case 'Last 365 days': return {
        start: dayjs().subtract(365, 'days').startOf('day').unix(),
        end: eod,
      };

      case 'Past':
        return {
          start: beginningOfTime,
          end: dayjs().unix(),
        };
      case 'Future':
        return {
          start: dayjs().unix(),
          end: dayjs().add(30, 'years').unix(),
        };

      case 'All Time':
        return {
          start: beginningOfTime,
          end: dayjs().add(30, 'years').unix(),
        };
    }
  }

  getPeriodAsDateArray(period: AdvancedPeriodOptions) {
    const unixPeriod = this.getUnixPeriod(period);
    const start = new Date(unixPeriod.start * 1000);
    const end = new Date(unixPeriod.end * 1000);
    return [ start, end ];
  }

  offsetUnixPeriod(period: Period, offset = 0) {
    const diff = period.end - period.start;

    return {
      start: period.start + diff * offset,
      end: period.end + diff * offset,
    };

  }

  generateKey(period: Period) {
    return `${ period.start }.${ period.end }`;
  }

  getPeriodDropdownCount(): {
    [ key in string ]: number;
  } {
    const strCounts = localStorage.getItem(environment.lskeys.periodDropdownCount) || '{}';
    try {
      return JSON.parse(strCounts);
    } catch (err) {
      return {};
    }
  }

  /**
   * Update counts in local storage for periods most used
   */
  updatePeriodLocalStorage(period: PeriodOptions) {
    const counts = this.getPeriodDropdownCount();
    counts[period] = counts[period] || 0;
    counts[period]++;
    // console.log(`Updating period counts in localstorage ${ period }`, counts);
    localStorage.setItem(environment.lskeys.periodDropdownCount, JSON.stringify(counts));
  }

  getPeriodNameValues(opts?: {
    defaultPeriod?: AdvancedPeriodOptions;
    includeCustom?: boolean;
    sortTop?: number;
    includeSinceLastRun?: boolean;
  }) {
    let periods = clone(periodOptions) as unknown as AdvancedPeriodOptions[];
    if (opts?.includeCustom) {
      periods.push('Custom');
    }

    if (opts?.includeSinceLastRun) {
      periods.push(`Since Last Run`);
    }

    if (opts.sortTop) {
      // move top periods to top
      const topPeriods = Object.entries(this.getPeriodDropdownCount())
        .filter(([ _, count ]) => count > 0)
        .filter(( [ value ] ) => periods.includes(value as any))
        .sort(([ a, acount ], [ b, bcount ]) => bcount - acount)
        .slice(0, opts.sortTop)
        .map(([ value ]) => value as typeof periods[number])
      ;

      // remove top periods from periods and place them at the front, sorted
      periods = topPeriods.concat(periods.filter((p) => !topPeriods.includes(p)));
    }

    const periodNameValue = periods.map((value) => ({
      name: value as string,
      value,
    }));

    if (opts?.defaultPeriod) {
      for (const period of periodNameValue) {
        if (period.value === opts.defaultPeriod) {
          period.name = `${ period.name } (default)`;
          break;
        }
      }
    }

    return periodNameValue;
  }

}
