import { gql } from 'graphql-tag';

import { User } from './auth';
import { locationBaseGQL, ZoneDirection } from './common.gql';
import { Location } from './locations';
import { Metadata, metaDataGQL } from './metadata';
import { ownerSubGQL } from './shareAccess';
import { Zone, zonesBaseGql } from './zones';

export interface ListAssetsOutput {
	assets: AssetsPagination;
}

export interface CreateAssetsOutput {
	createAssets: Asset[];
}

export interface UpdateAssetsOutput {
	updateAssets: boolean;
}

export interface DeleteAssetsOutput {
	deleteAssets: boolean;
}

export interface Asset {
    id: string;
    name: string;
    owner: User;
    type: string;
    description?: string;
    location?: Location;
    attributes?: string[];
    metadata: Metadata;
    createdAt?: number;
    updatedAt?: number;
    deletedAt?: number;
    zones?: Zone[];
}


export interface AssetsPagination {
    total: number;
    skipped?: number;
    limited?: number;
    assets: Asset[];
}


export interface AssetsFilter extends ZoneDirection{
    search?: string;
    ids?: string[];
    names?: string[];
    types?: string[];
    createdBefore?: number;
    createdAfter?: number;
    owners?: string[];
    hasAnyAttributes?: string[];
    hasAllAttributes?: string[];
    locatedAt?: string[];
    showDeleted?: boolean;
}


export interface CreateAssetInput {
    name: string;
    ownerId: string;
    type: string;
    description?: string;
    locationId?: string;
    attributes?: string[];
    metadata?: Metadata;
}


export interface UpdateAssetInput {
    id: string;
    name?: string;
    ownerId?: string;
    type?: string;
    description?: string;
    locationId?: string;
    attributes?: string[];
    metadata?: string;
}

export interface AssetsInput {
	filter?: AssetsFilter;
	skip?: number;
	limit?: number;
	sort?: string;
}



export interface CreateAssetsInput {
	assets?: CreateAssetInput[];
}



export interface UpdateAssetsInput {
	assets?: UpdateAssetInput[];
}



export interface DeleteAssetsInput {
	ids?: string[];
	hardDelete?: boolean;
}

export interface GenerateAssetsQueryInput {
    location?: boolean;
    owner?: boolean;
    metadata?: boolean;
    zones?: boolean;
}

export function generateAssetsQuery(qi: GenerateAssetsQueryInput) {
    return gql`
    query assets($filter: AssetsFilter, $skip: Int, $limit: Int, $sort: String) {
        assets(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            total
            skipped
            limited
            assets{
                id
                name
                type
                description
                attributes
                createdAt
                updatedAt
                deletedAt
                ${ qi.owner ? ownerSubGQL : '' }
                ${ qi.location ? `
                location {
                    ${ locationBaseGQL }
                }
                ` : '' }
                ${ qi.metadata ? metaDataGQL : '' }
                ${ qi.zones ? `zones { ${zonesBaseGql} }`: ''}
            }
        }
    }
    `;
}


export const assetsQuery = gql`
	query assets($filter: AssetsFilter, $skip: Int, $limit: Int, $sort: String, ){
		assets(filter: $filter, skip: $skip, limit: $limit, sort: $sort, ){
			    total
			    skipped
			    limited
			    assets{
                    id
                    name
                    owner {
                        id
                        email
                        givenName
                        familyName
                        phone
                    }
                    type
                    description
                    location {
                        ${locationBaseGQL}
                    }
                    attributes
                    metadata
                    createdAt
                    updatedAt
                    deletedAt
                    zones { ${zonesBaseGql} }
                }
	}		}
`;


export const createAssetsMutation = gql`
	mutation createAssets($assets: [CreateAssetInput]!, ){
		createAssets(assets: $assets, ){
			    id
			    name
			    owner{ id }			    
                type
			    description
			    location{ id }
    		    attributes
			    createdAt
			    updatedAt
			    deletedAt

	}		}
`;


export const updateAssetsMutation = gql`
	mutation updateAssets($assets: [UpdateAssetInput]!, ){
		updateAssets(assets: $assets, ) 
	}
`;


export const deleteAssetsMutation = gql`
	mutation deleteAssets($ids: [String]!, $hardDelete: Boolean, ){
		deleteAssets(ids: $ids, hardDelete: $hardDelete, ) 
	}
`;

