import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { Loader } from '@googlemaps/js-api-loader';
import { GraphQLModule } from '@karve.it/core';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ApolloModule } from 'apollo-angular';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { NgxPrintModule } from 'ngx-print';
import { QuillModule } from 'ngx-quill';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';

import { initCmd } from '../cmd';

import { AccountPageComponent } from './account-page/account-page.component';
import { TwoFactorSetupComponent } from './account-page/two-factor-setup/two-factor-setup.component';
import { ActOnComponent } from './act-on/act-on.component';
import { ContactListsComponent } from './act-on/contact-lists/contact-lists.component';
import { AppRoutingModule } from './app-routing.module';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { ArtifactDetailsComponent } from './artifacts/artifact-details/artifact-details.component';
import { ArtifactsComponent } from './artifacts/artifacts.component';
import { AssetDetailsComponent } from './assets/asset-details/asset-details.component';
import { AssetsComponent } from './assets/assets.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AvailabilityDetailsComponent } from './availability/availability-details/availability-details.component';
import { RemoveAvailabilityComponent } from './availability/remove-availability/remove-availability.component';
import { EstimatingPanelComponent } from './base/estimating-panel/estimating-panel.component';
import { FeedbackDialogComponent } from './base/feedback-dialog/feedback-dialog.component';
import { AppFooterComponent } from './base/footer/app.footer.component';
import { AppMenuComponent } from './base/menu/app.menu.component';
import { MenuService } from './base/menu/app.menu.service';
import { AppMenuitemComponent } from './base/menu/app.menuitem.component';
import { MutateContainerComponent } from './base/mutate-container/mutate-container.component';
import { AppRightpanelComponent } from './base/rightpanel/app.rightpanel.component';
import { SearchComponent } from './base/search/search.component';
import { AppTopBarComponent } from './base/topbar/app.topbar.component';
import { BusinessUnitsComponent } from './business-units/business-units.component';
import { FranchiseDetailsComponent } from './business-units/franchise-details/franchise-details.component';
import { ChargeDetailsComponent } from './charges/charge-details/charge-details.component';
import { ChargesComponent } from './charges/charges.component';
import { CommentsComponent } from './comments/comments.component';
import { CustomInputsModule } from './custom-inputs/custom-inputs.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CountryService } from './demo/service/countryservice';
import { CustomerService } from './demo/service/customerservice';
import { EventService } from './demo/service/eventservice';
import { IconService } from './demo/service/iconservice';
import { NodeService } from './demo/service/nodeservice';
import { PhotoService } from './demo/service/photoservice';
import { ProductService } from './demo/service/productservice';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { DiscountDetailsComponent } from './discounts/discount-details/discount-details.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { PreviewDynamicReportComponent } from './dynamic-reports/preview-dynamic-report/preview-dynamic-report.component';
import { AddJobLocationComponent } from './estimates/add-job-location/add-job-location.component';
import { CustomerInfoComponent } from './estimates/customer-info/customer-info.component';
import { DisableRestrictionsComponent } from './estimates/disable-restrictions/disable-restrictions.component';
import { DistancesComponent } from './estimates/distances/distances.component';
import { EstimateActionsComponent } from './estimates/estimate-actions/estimate-actions.component';
import { EstimateBreakdownComponent } from './estimates/estimate-breakdown/estimate-breakdown.component';
import { EstimateConfirmationComponent } from './estimates/estimate-confirmation/estimate-confirmation.component';
import { EstimateDiscountsComponent } from './estimates/estimate-discounts/estimate-discounts.component';
import { EstimateProductsComponent } from './estimates/estimate-products/estimate-products.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { EstimatingInventoryComponent } from './estimates/estimating-inventory/estimating-inventory.component';
import { EstimatingNotesComponent } from './estimates/estimating-notes/estimating-notes.component';
import { EventBookingComponent } from './estimates/event-booking/event-booking.component';
import { EventConfirmationComponent } from './estimates/event-confirmation/event-confirmation.component';
import { JobBookingComponent } from './estimates/job-booking/job-booking.component';
import { JobInfoComponent } from './estimates/job-info/job-info.component';
import { RequiredEventsComponent } from './estimates/required-events/required-events.component';
import { SelectAreaDialogComponent } from './estimates/select-area-dialog/select-area-dialog.component';
import { FranchiseModule } from './franchise/franchise.module';
import { FreyaCommonModule } from './freya-common/freya-common.module';
import { HistoryDetailsComponent } from './history/history-details/history-details.component';
import { HistoryComponent } from './history/history.component';
import { HistorySearchComponent } from './history-search/history-search.component';
import { HomeComponent } from './home/home.component';
import { InvoiceDetailsComponent } from './invoices/invoice-details/invoice-details.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { JobDetailsComponent } from './jobs/job-details/job-details.component';
import { JobLocationsComponent } from './jobs/job-locations/job-locations.component';
import { JobPageComponent } from './jobs/job-page/job-page.component';
import { JobParticipantComponent } from './jobs/job-participant/job-participant.component';
import { JobsComponent } from './jobs/jobs.component';
import { LeaveEstimatingGuard } from './leave-estimating.guard';
import { LibModule } from './lib.ts/lib.module';
import { LoadingComponent } from './loading/loading.component';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';
import { LocationsComponent } from './locations/locations.component';
import { HowHeardOptionsComponent } from './marketing/how-heard-options/how-heard-options.component';
import { LeadSourceDetailsComponent } from './marketing/lead-source-details/lead-source-details.component';
import { LeadSourceSettingsComponent } from './marketing/lead-source-settings/lead-source-settings.component';
import { MarketingComponent } from './marketing/marketing.component';
import { CorporateComponent } from './mockups/corporate/corporate.component';
import { FranchisePartnersComponent } from './mockups/franchise-partners/franchise-partners.component';
import { LeadsComponent } from './mockups/leads/leads.component';
import { SettingsComponent } from './mockups/settings/settings.component';
import { NeedsAttentionComponent } from './needs-attention/needs-attention.component';
import { NiceJobsComponent } from './nice-jobs/nice-jobs.component';
import { NotificationViewerComponent } from './notifications/notification-viewer/notification-viewer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppCalendarComponent } from './pages/app.calendar.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { NoAvailableZonesComponent } from './pages/no-available-zones/no-available-zones.component';
import { OfflineComponent } from './pages/offline/offline.component';
import { PaymentRedirectComponent } from './payment/payment-redirect/payment-redirect.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentTypesComponent } from './payment-types/payment-types.component';
import { PriceDetailsComponent } from './products/price-details/price-details.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductsComponent } from './products/products.component';
import { QuickbooksAccountsComponent } from './quickbooks/quickbooks-accounts/quickbooks-accounts.component';
import { QuickbooksDashboardComponent } from './quickbooks/quickbooks-dashboard/quickbooks-dashboard.component';
import { QuickbooksLoginComponent } from './quickbooks/quickbooks-login/quickbooks-login.component';
import { QuickbooksComponent } from './quickbooks/quickbooks.component';
import { ReportsModule } from './reports/reports.module';
import { BookOffDetailsComponent } from './schedules/book-off-details/book-off-details.component';
import { CalendarEventDetailsComponent } from './schedules/calendar-event-details/calendar-event-details.component';
import { SchedulesComponent } from './schedules/schedules.component';

import { GoogleHelperService } from './services/google-helper.service';
import { MultiConfigSettingsCardComponent } from './settings/multi-config-settings-card/multi-config-settings-card.component';
import { SettingsComponent as SettingsPageComponent } from './settings/settings.component';
import { SingleConfigSettingsCardComponent } from './settings/single-config-settings-card/single-config-settings-card.component';
import { RuleDetailsComponent } from './shared/rule-details/rule-details.component';
import { SharedModule } from './shared/shared.module';
import { TemplateTypeDirective } from './shared/template-type.directive';
import { SortDirectionPipe } from './sort-direction.pipe';
import { rootState } from './state/rootState';
import { ApplyTransactionComponent } from './transactions/apply-transaction/apply-transaction.component';
import { TransactionDetailsComponent } from './transactions/transaction-details/transaction-details.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { RoleDetailsComponent } from './users/role-details/role-details.component';
import { UserPageComponent } from './users/user-page/user-page.component';
import { UsersDetailsComponent } from './users/users-details/users-details.component';
import { UsersComponent } from './users/users.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { markedOptionsFactory } from './utilities/markdown';
import { SpacingComponent } from './utilities/spacing.component';
import { TextComponent } from './utilities/text.component';
import { TypographyComponent } from './utilities/typography.component';
import { WidgetsComponent } from './utilities/widgets.component';

    @NgModule({
        imports: [
            BrowserModule,
            FormsModule,
            AppRoutingModule,
            HttpClientModule,
            BrowserAnimationsModule,
            FreyaCommonModule,
            AppCodeModule,
            SharedModule,
            ReactiveFormsModule,
            LibModule,
            CalendarModule,
            FullCalendarModule,
            DashboardModule,
            ReportsModule,
            CustomInputsModule,

            NgxPrintModule,

            // primng dialog
            DynamicDialogModule,

            // NG Neat dialog (more capabilities) - https://www.npmjs.com/package/@ngneat/dialog
            // DialogModule.forRoot(),

            MarkdownModule.forRoot({
                markedOptions: {
                    provide: MARKED_OPTIONS,
                    useFactory: markedOptionsFactory,
                },
                // Disable so that Markdown component may render heading IDs
                // Safe as long as all markdown content comes from us
                sanitize: SecurityContext.NONE,
            }),

            ApolloModule,
            GraphQLModule.forRoot({
                graphql: environment.apiUrl,
                ws: environment.websocket,
                websocketsEnabled: environment.webSocketsEnabled,
            }),

            QuillModule.forRoot({
                // modules: {},
            }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
            // Disable so that Markdown component may render heading IDs
            // Safe as long as all markdown content comes from us
            sanitize: SecurityContext.NONE,
        }),

            // Component Modules
            AuthenticationModule,
            FranchiseModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            }),

        QuillModule.forRoot({
            // modules: {},
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
            // Disable so that Markdown component may render heading IDs
            // Safe as long as all markdown content comes from us
            sanitize: SecurityContext.NONE,
        }),

        // Component Modules
        AuthenticationModule,
        FranchiseModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),

        QuillModule.forRoot({
            // modules: {},
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory,
            },
            // Disable so that Markdown component may render heading IDs
            // Safe as long as all markdown content comes from us
            sanitize: SecurityContext.NONE,
        }),

        // Component Modules
        AuthenticationModule,
        FranchiseModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        StoreModule.forRoot({}, {}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),

        // STANDALONE COMPONENTS
        LeadSourceSettingsComponent,
        // StoreModule.forFeature(authFeature),
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppRightpanelComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardDemoComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InvoicesComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        UsersDetailsComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        FranchisePartnersComponent,
        LeadsComponent,
        SchedulesComponent,
        SettingsPageComponent,
        UsersComponent,
        EstimatesComponent,
        SettingsComponent,
        CustomerInfoComponent,
        JobInfoComponent,
        JobBookingComponent,
        EstimateConfirmationComponent,
        AssetsComponent,
        AssetDetailsComponent,
        LocationDetailsComponent,
        EstimateBreakdownComponent,
        EstimateProductsComponent,
        ProductsComponent,
        ProductDetailsComponent,
        BusinessUnitsComponent,
        CorporateComponent,
        AvailabilityDetailsComponent,
        CalendarEventDetailsComponent,
        JobsComponent,
        JobDetailsComponent,
        FranchiseDetailsComponent,
        JobPageComponent,
        UserPageComponent,
        AccountPageComponent,
        TwoFactorSetupComponent,
        LocationsComponent,
        TransactionsComponent,
        TransactionDetailsComponent,
        ArtifactsComponent,
        ArtifactDetailsComponent,
        CommentsComponent,
        PaymentComponent,
        HistorySearchComponent,
        PaymentRedirectComponent,
        MarketingComponent,
        DiscountsComponent,
        DiscountDetailsComponent,
        EstimatingPanelComponent,
        EstimatingNotesComponent,
        RoleDetailsComponent,
        EventBookingComponent,
        RuleDetailsComponent,
        DistancesComponent,
        MutateContainerComponent,
        EventConfirmationComponent,
        ChargesComponent,
        FeedbackDialogComponent,
        HistoryComponent,
        SortDirectionPipe,
        TemplateTypeDirective,
        MultiConfigSettingsCardComponent,
        SingleConfigSettingsCardComponent,
        DisableRestrictionsComponent,
        NoAvailableZonesComponent,
        RemoveAvailabilityComponent,
        PriceDetailsComponent,
        ChargeDetailsComponent,
        SelectAreaDialogComponent,
        OfflineComponent,
        LoadingComponent,
        JobLocationsComponent,
        AddJobLocationComponent,
        NotificationsComponent,
        NotificationViewerComponent,
        EstimateDiscountsComponent,
        RequiredEventsComponent,
        EstimateActionsComponent,
        HowHeardOptionsComponent,
        NiceJobsComponent,
        PaymentTypesComponent,
        EstimatingInventoryComponent,
        NeedsAttentionComponent,
        ActOnComponent,
        ContactListsComponent,
        InvoiceDetailsComponent,
        ApplyTransactionComponent,
        BookOffDetailsComponent,
        QuickbooksComponent,
        QuickbooksLoginComponent,
        QuickbooksDashboardComponent,
        QuickbooksAccountsComponent,
        SearchComponent,
        JobParticipantComponent,
        PreviewDynamicReportComponent,
        HomeComponent,
        HistoryDetailsComponent,
        LeadSourceDetailsComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        ConfirmationService,
        LeaveEstimatingGuard,
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MenuService,
        AppMainComponent,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        {
            provide: Loader,
            useFactory: () => {
                return new Loader({
                    apiKey: environment.googlePlacesApiKey,
                    libraries: ['places']
                });
            },
        },
        provideStore({}),
        ...rootState,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

        constructor(
            // used in cmd
            private injector: Injector,
            private googleHelper: GoogleHelperService,
        ) {
            initCmd(this.injector);
            this.googleHelper.init();
        }
    }
