<div class="exception-body error">
    <div class="exception-topbar">
        <!-- <a id="logolink" [routerLink]="['/']" class="layout-topbar-logo">
            <img [src]="'assets/layout/images/logo-' + (app.topbarTheme === 'dark' ? 'freya-white' : 'freya') + '.svg'" alt="freya-layout"/>
        </a> -->
    </div>
    <div class="exception-wrapper">
        <div class="exception-content">
            <a 
                href="/"
                aria-label="reload"
            >
                <img src="/assets/layout/images/pages/asset-error.svg"
                    alt="Error contacting server"
                />

            </a>
            
            <h1>Error</h1>
            <button pButton (click)="logout()"
                *ngIf="!authService.polling && authService.user"
            >Logout</button>
            <span *ngIf="authService.polling">
                No connection to server
            </span>
            <a href="mailto:support@karveit.ca">Contact Support by emailing support&#64;karveit.ca</a>
        </div>
        <div class="exception-footer">
            <h4>{{project}}</h4>
            <h6>Copyright Ⓒ {{company}}</h6>
        </div>
    </div>
</div>
