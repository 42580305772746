<ng-container *ngIf="!requireTouched || control.touched">
  <small class="field-error-message" *ngIf="control.errors?.required">
    {{ fieldName }} is required.
  </small>
  <small class="field-error-message" *ngIf="control.errors?.minlength">
    Must be at least {{ control.errors.minlength.requiredLength }} characters.
  </small>
  <small class="field-error-message" *ngIf="control.errors?.maxlength">
    Must be shorter than
    {{ control.errors.maxlength.requiredLength }} characters.
  </small>
  <small class="field-error-message" *ngIf="control.errors?.email">
    Must be a valid email address.
  </small>

  <ng-container *ngIf="control.errors?.pattern">
    <small
      class="field-error-message"
      *ngIf="fieldName === 'Email'; else genericPatternMessage"
    >
      Must be a valid email address.
    </small>

    <ng-template #genericPatternMessage>
      <small class="field-error-message">
        Does not match the required format.
      </small>
    </ng-template>
  </ng-container>

  <small class="field-error-message" *ngIf="control.errors?.min">
    Must not be less than {{ control.errors.min.min }}.
  </small>
  <small class="field-error-message" *ngIf="control.errors?.max">
    Must not be greater than {{ control.errors.max.max }}.
  </small>
  <small class="field-error-message" *ngIf="control.errors?.greaterThan">
    {{ control.errors.greaterThan }}
  </small>
  <small
    class="field-error-message"
    *ngIf="control.errors?.conditionallyRequired"
  >
    Cannot set
    {{ control.errors.conditionallyRequired.providedCtrlName }} without
    {{ control.errors.conditionallyRequired.requiredCtrlName }}.
  </small>
  <small
    class="field-error-message check"
    *ngIf="control.errors?.locationRequired"
  >
    {{ control.errors.locationRequired }}
  </small>
  <small
    class="field-error-message check"
    *ngIf="control.errors?.invalidLocation"
  >
    {{ control.errors.invalidLocation }}
  </small>
</ng-container>
