<p-tabMenu
  [model]="items"
  [activeItem]="activeItem"
  (activeItemChange)="onActiveItemChange($event)"
>
  <ng-template pTemplate="item" let-item>
    <a class="p-menuitem-link">
      <span class="ml-2">
        {{ item.label }}
      </span>
    </a>
  </ng-template>
</p-tabMenu>
<router-outlet></router-outlet>
