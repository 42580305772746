import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { SetFieldValueInput, SetFieldValuesGQL, SetFieldValuesMutationVariables } from '../../../generated/graphql.generated';

import { PlusAuthenticationService } from '../../core/public-api';
import { getFieldValue } from '../../fields/fields.utils';
import { FilterType } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';

import { MutateType } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

import { FreyaMutateComponent } from '../mutate-charges/mutate-charges.component';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { AnyParams, FilterConfiguration } from '../query-params.service';

@Component({
  selector: 'app-mutate-filter',
  templateUrl: './mutate-filter.component.html',
  styleUrls: ['./mutate-filter.component.scss']
})
export class MutateFilterComponent implements OnDestroy, FreyaMutateComponent {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;
  @ViewChild('name') nameRef: TemplateRef<any>;

  mutateType: MutateType;
  // The custom filters the user currently has
  currentFilters: FilterConfiguration<AnyParams>[];
  // The filter we are going to add
  filter: FilterConfiguration<AnyParams>;
  // The type of the filter we are going to add
  filterType: FilterType;

  steps: MutateObjectElement[];

  subs = new SubSink();

  filterForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private authService: PlusAuthenticationService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private notify: FreyaNotificationsService,
    private setFieldValuesGQL: SetFieldValuesGQL,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createFilter();
    }
  }

  setFormValues() {
  }

  openDialog() {
    this.steps = [
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
    ];

    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  fetchCurrentFilters() {
    return new Promise<boolean>((resolve, reject) => {
      const userId = this.authService.user.id;

      this.freyaHelper.getFieldValues(
        [`filters.${this.filterType}`],
        [userId],
        [ 'User' ],
      )
        .then(([ current ]) => {

          this.currentFilters = getFieldValue(current) || [];
          resolve(true);
        })
        .catch(() => reject(false));
    });
  }

  async createFilter(){

    if (!this.currentFilters) {
      await this.fetchCurrentFilters();
    }

    const existingFilters = this.currentFilters || [];

    const newFilter = {
      name: this.filterForm.controls.name.value,
      filter: this.filter.filter
    };

    const fieldName = `filters.${this.filterType}`;

    const fieldValue: SetFieldValueInput = {
      fieldName,
      value: [ ...existingFilters, newFilter ],
    };

    const userId = this.authService.user.id;

    const setFieldsInput: SetFieldValuesMutationVariables = {
      fields: [ fieldValue ],
      objects: [ userId ],
      objectLabel: 'User',
    };

    this.setFieldValuesGQL.mutate(setFieldsInput)
      .subscribe({
        next: () => {
          this.detailsHelper.pushUpdate({
            id: undefined,
            type: 'Fields',
            action: 'create',
          });

          this.mutateRef.closeDialog();
          this.filterForm.reset();
          this.notify.success('Filter saved');

        },
        error: (err) => {
          console.error(err);
        },
      });
  }

}
