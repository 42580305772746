import { Component, OnInit } from '@angular/core';
import { FullJobFragmentWithFields, jobToolFeature } from '../job-tool.reducer';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { CommonModule } from '@angular/common';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';

@Component({
  selector: 'app-job-inventory',
  standalone: true,
  imports: [
    CommonModule,
    FreyaCommonModule,
  ],
  templateUrl: './job-inventory.component.html',
  styleUrl: './job-inventory.component.scss'
})
export class JobInventoryComponent implements OnInit {

  job$ = this.store.select(jobToolFeature.selectJob);

  job: FullJobFragmentWithFields | undefined = undefined;

  constructor(
    public store: Store,
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {
    this.subs.sink = this.job$.subscribe((job) => {
      this.job = job;
    });
  }

  openLegacyClick() {
    const jobId = this.job?.id;
    if (!jobId) return;

    const redirectTo = `/estimating/${jobId}`;
      this.router.navigate([redirectTo], {
        relativeTo: this.route,
        queryParams: {
          step: 'inventory',
        },
      });
  }
}
