import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  public isOnline = new BehaviorSubject<boolean>(false);
  isOnline$ = this.isOnline.asObservable();

  constructor() {
    this.watchOnlineStatus();
  }

  updateOnlineStatus(): void {
    const isOnline = window.navigator.onLine;
    this.isOnline.next(isOnline);
  }

  watchOnlineStatus(): void {
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }
}