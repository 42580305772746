import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(value: number | null | undefined, amountType: 'amount' | 'percentage', currencyCode?: string, defaultString?: string): string {

    if (value === null || value === undefined) {
      return defaultString || 'Not Set';
    }

    if (amountType === 'percentage') {
      return `${value}%`;
    }

    return this.currencyPipe.transform(value, currencyCode);
  }

}
