<h5>Distance / Travel Time</h5>
<div *ngIf="distanceService.distances && !objectIsEmpty(distanceService.distances)">
    <table class="distance-matrix">
        <ng-container
            *ngFor="let show of distancesToShow"
        >
            <tr *ngIf="distanceService.distances[show.key]"  [class.total]="show.total">
                <td class="distance-matrix-label">{{ show.label }}:</td>
                <td *ngIf="distanceService.distances[show.key].calculating" class="value">Calculating...</td>
                <td *ngIf="!distanceService.distances[show.key].calculating" class="value">
                    {{ distanceService.distances[show.key].totalDistance | distance:distanceService.distances[show.key].distanceUnits }}
                        / 
                    {{ distanceService.distances[show.key].estimatedTime | secondsToTimeUnit }}
                </td>
            </tr>
        </ng-container>
    </table>
    <small>Distances are calculated using an average of traffic conditions, times may vary.</small>
</div>
<div *ngIf="!(distanceService.distances && !objectIsEmpty(distanceService.distances))">
    <p-message
        (click)="goToLocationsTab()"
        [class.cursor-pointer]="(currentRoute$ | async) === 'estimating'"
        severity="warn"
        text="Enter a location to see distance and time estimates"
        ></p-message>  
</div>
<div *ngIf="showMap" class="map-wrapper">
    <p-selectButton
        styleClass="p-my-3"
        [options]="mapViews"
        [(ngModel)]="selectedMapView"
        optionLabel="name"
        optionDisabled="inactive"
    >
    </p-selectButton>
    <div *ngIf="selectedMapView && selectedMapView.url" class="iframe-wrapper">
        <iframe *ngIf="!iFrameRefreshing"
            width="100%"
            height="100%"
            [ngClass]="{'invisible': mapLoading || jobLoading}"
            allowfullscreen
            allow="accelerometer; gyroscope"
            referrerpolicy="no-referrer-when-downgrade"
            [src]="selectedMapView.url | trustUrl"
            (load)="mapLoadingOff()"
            data-cy="iframe-map"
        >
        </iframe>
        <p-skeleton
            *ngIf="mapLoading || jobLoading"
            width="100%"
            height="100%"
            styleClass="p-skeleton-override"
        ></p-skeleton>
        <small class="p-mt-2">Route for the map is calculated using current traffic conditions, times may vary.</small>
    </div>
</div>
