import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { parse, unparse } from 'papaparse';
import { of, Subject } from 'rxjs';

import { BaseReportFragment, BaseReportTypeFragment, CreateReportGQL, CreateReportMutation, GetReportDataGQL, ReportAggregationResult, ReportDetailsListQuery, ReportGetDataOptions, ReportStatus, ReportType_SchemaFragment, Report_DataFragment } from '../../generated/graphql.generated';
import { ReportDataStructure } from '../reports/reports.constants';
import { ensureUnixSeconds } from '../time';

import { FreyaNotificationsService } from './freya-notifications.service';

export interface ReportVariable {
  key: string;
  value: any;
  format?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private createReportGQL: CreateReportGQL,
    private getReportDataGQL: GetReportDataGQL,
    private router: Router,
    private message: FreyaNotificationsService,
    private http: HttpClient,
  ) { }


  async downloadCSV(
    report: BaseReportFragment & Partial< Report_DataFragment >,
    reportData?: ReportDataStructure,
    opts?: {
      stripSummary?: boolean;
    },
  ) {
    opts = opts || {};
    opts.stripSummary = opts.stripSummary || false;

    const errorPrefix = `Cannot download CSV`;
    let csv = reportData?.csvData || report.csv;

    // get the JSON url
    if (!csv && report.saveData && !report.jsonURL) {
      // get CSV from system
      console.log(`Downloading csv...`);
      this.message.addToast.next({
        severity: 'info',
        summary: 'Generating CSV',
        detail: 'Please wait...',
        life: 8000,
      });
      const { data: { reports: { reports: [ reportOutput ] } } } = await this.getReportDataGQL.fetch({
        filter: {
          ids: [ report.id ],
          getDeleted: true,
        },
      }).toPromise();

      report = reportOutput;
    } else if (!csv && !report.saveData) {
      throw new Error(`${ errorPrefix }: Data is not saved`);
    }

    // get the CSV from the json url
    if (!csv && report.jsonURL) {

      const reportDataStructure = await this.http.get(report.jsonURL).toPromise() as ReportDataStructure;
      if (!reportDataStructure) {
        throw new Error(`Could not retrieve report data structure, try reloading`);
      }

      csv = reportDataStructure.csvData;
    }


    // if none of that worked (ie saveData was false) then throw an error
    if (!csv) {
      throw new Error(`${ errorPrefix }: CSV not returned`);
    }

    if (opts.stripSummary) {
      csv = this.stripSummaryFromCSV(csv);
    }

    this.downloadFile(`${ report.name }.csv`, csv);
  }

  stripSummaryFromCSV(csv: string) {
    const { headers, data } = this.parseCSV(csv);

    if (!headers || !data) {
      console.warn(`Error parsing CSV`);
      return csv;
    }

    const table = [ headers, ...data ];
    return unparse(table, {
      delimiter: ',',
      skipEmptyLines: false,
    });
  }

  parseCSV(csv: string) {
    const results = parse(csv, {
      // header: true,
      // TODO: only show X rows until showMoreTable() is pressed
      // preview: 1000,
    });

    if (!results?.data) { return {}; }

    const fullHeaders = results.data[0] as string[];
    const fullData = results.data.slice(1) as string[][];

    let data = fullData;
    let headers = fullHeaders;
    let ags: string[][];

    const hasAgs = headers[0] === '';
    const agStartIndex = data.findIndex((r, i) =>
      r[0] === '-----'
      && (i === 0 || data[i-1][0] === '')
      && (data.length > i && data[i+1][0] !== '')
    );

    if (hasAgs && agStartIndex >= 0) {
      headers = headers.slice(1);
      ags = data.slice(agStartIndex + 1);
      data = data.slice(0, agStartIndex).map((row) => row.slice(1));
    }

    return {
      fullData,
      fullHeaders,
      data,
      headers,
      ags,
    };

  }

  downloadFile(
    filename: string,
    data: string,
  ) {

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  // async downloadJSON(report: BaseReportFragment & Partial< Report_DataFragment >) {
  //   const errorPrefix = `Cannot download JSON`;
  //   const jsonURL = report.jsonURL;
  //   if (!jsonURL && report.saveData) {
  //     // get JSON URL from system then open URL in a new tab
  //   } else if (!jsonURL && !report.saveData) {
  //     throw new Error(`${ errorPrefix }: Data is not saved`);
  //   }
  // }

  parseReportVariables(strVariables: string) {
    strVariables = strVariables || '{}';
    let variables: any;
    try {
      variables = JSON.parse(strVariables);
    } catch (err) {
      variables = {};
    }

    const arrVariables = Object.entries(variables).map(([ key, value ]) => {
      let format = 'string';
      if (
        [ 'start', 'end' ].includes(key) && typeof value === 'number'
      ) {
        format = 'datetime';
      }

      if (typeof value === 'object') {
        format = 'object';
      }

      // ensure we are in unix seconds format, not unix milliseconds if datetime format
      if (format === 'datetime' && typeof value === 'number') {
        value = ensureUnixSeconds(value);
      }

      return {
        key,
        value,
        format,
      } as ReportVariable;
    });
    return arrVariables;
  }

  createReport(args: {
    // reportTypeId: string;
    reportType: BaseReportTypeFragment & ReportType_SchemaFragment;
    navigate?: boolean;
  }) {
    if (!args.reportType) {
      return of({
        generating: false,
      });
    }

    // TODO: determine required variables and show popup to user

    const strVariables = JSON.stringify({});
    console.log('Generating report...', args.reportType);

    const sub = new Subject<{
      generating: boolean;
      report?: CreateReportMutation['createReport'];
      error?: Error;
    }>();

    sub.next({
      generating: true,
    });

    this.createReportGQL.mutate({
      input: {
        reportType: args.reportType.id,
        report: {
          variables: strVariables,
          saveAggregations: true,
          saveData: true,
          saveReport: true,
        },
      }
    }).subscribe((res) => {
      const report = res.data?.createReport;
      if (!report) {
        throw new Error(`No report returned`);
      }

      console.log(`Report generated`, res);
      sub.next({
        generating: false,
        report,
      });

      if (args.navigate) {
        this.router.navigate([ '/reports', report.id ]);
      }
    }, (error) => {
      console.error(error);
      sub.next({
        generating: false,
        error,
      });
    });

    return sub;

  }

  determineAgFormat(ag: ReportAggregationResult) {

    if (Array.isArray(ag.values) && Array.isArray(ag.labels)) {
      return 'fulltable';
    } else if (Array.isArray(ag.values)) {
      return 'values';
    } else if (Array.isArray(ag.labels)) {
      return 'labels';
    }

    return 'value';
  }

  isFullyLoaded(
    report: BaseReportFragment | ReportDetailsListQuery['reports']['reports'][number]
  ): report is ReportDetailsListQuery['reports']['reports'][number] {
    const hasAggregations = 'aggregations' in report;
    const hasZones = 'zones' in report;

    return hasAggregations && hasZones;
  }

  isGenerating(
    report: BaseReportFragment | ReportDetailsListQuery['reports']['reports'][number]
  ) {
    return [ ReportStatus.Generating, ReportStatus.Pending ].includes(report.status);
  }
}
