import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { OpenFeedbackDialogData } from '../../base/feedback-dialog/feedback-dialog.component';
import { FeedbackService } from '../../core/feedback/feedback.service';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss']
})
export class AuthFooterComponent implements OnInit {

  @Input() showSupport = true;

  @Input() copyright = environment.copyright;
  @Input() supportMessage = '';

  constructor(
    public feedbackSvc: FeedbackService,
  ) { }

  ngOnInit(): void {
  }

  contactSupport() {
    this.feedbackSvc.openFeedbackDialog({
      feedbackType: 'support',
      message: this.supportMessage,
    });
  }


}
