import { Pipe, PipeTransform } from '@angular/core';

import { BaseCalendarEventFragment, CalendarEvent } from '../../generated/graphql.generated';
import { FreyaHelperService } from '../services/freya-helper.service';
import { ensureUnixSeconds } from '../time';

import { FreyaDatePipe } from './freya-date.pipe';

@Pipe({
  name: 'eventDurationFromCalendar'
})
export class EventDurationFromCalendarPipe implements PipeTransform {

  constructor(
    private freyaDate: FreyaDatePipe,
    private freyaHelper: FreyaHelperService
  ){}

  transform(event: CalendarEvent, eventStart: Date, eventEnd: Date): string {
    const {eventStart: start, eventEnd: end} =
    this.freyaHelper.getCalendarEventStartandEnd(
      event as BaseCalendarEventFragment,
      ensureUnixSeconds(eventStart.getTime()),
      ensureUnixSeconds(eventEnd.getTime())
    );

    return `${this.freyaDate.transform(start, 'h:mm a')} - ${this.freyaDate.transform(end, 'h:mm a')}`;
  }

}
