
<div style="max-width: 40ch;">
	<span>
		Keep an up to date copy of {{ reportType.name }} in a Google Sheet. 
	</span>
	<br /><br />
	<ul>
		<li>
			Copy the formula below by clicking on it and paste it into a Google Sheet cell with
			CTRL + V.
		</li>
		<li>
      Google Sheets may ask you to allow access to third-party links. Click on "Allow access" to enable the formula to work.
		</li>
		<li>
			Google Sheets will run this report given every hour with a period 
			of "{{ period }}".
		</li>
		<li>
			This formula will only be valid for a year then you will have to generate a new one.
		</li>
		<li>
			<strong>
				Keep this formula a secret! It gives
				anyone with this formula access to your report!
			</strong>
		</li>
	</ul>
</div>

<div class="options">

	<div class="period">
		<span class="p-float-label">
			<p-dropdown
				placeholder="Select a period"
				[options]="periodOptions"
				[(ngModel)]="period"
				inputId="period-label"
				appendTo="body"
				(ngModelChange)="updateExportFormula()"
			></p-dropdown>
			<label for="period-label">Period</label>
		</span>
	</div>
	
	<p-button label="{{ showOptions ? 'Hide' : 'Show' }} Options" styleClass="p-button-link" (click)="showOptions = !showOptions"></p-button>
	
</div>

<div class="check-options" *ngIf="showOptions">

	<strong>
		You must modify these options <br /> <i>before</i> copying the formula.
		<br /><br/>
	</strong>

	<p-checkbox
		[(ngModel)]="hideSummary"
		[binary]="true"
		inputId="hideSummary"
		label="Hide Summary"
		(ngModelChange)="updateExportFormula()"
	></p-checkbox>

	<p-checkbox
		[(ngModel)]="hideData"
		[binary]="true"
		inputId="hideData"
		label="Hide Data"
		(ngModelChange)="updateExportFormula()"
	></p-checkbox>

	<p-checkbox
		[(ngModel)]="showSummaryAboveHeaders"
		[binary]="true"
		inputId="showSummaryAboveHeaders"
		label="Show Summary Above Headers"
		(ngModelChange)="updateExportFormula()"
	></p-checkbox>

	<p-checkbox
		[(ngModel)]="showMetadata"
		[binary]="true"
		inputId="showMetadata"
		label="Show Metadata"
		(ngModelChange)="updateExportFormula()"
	></p-checkbox>
</div>

<div class="formula field">

	<label for="formula">Formula</label>

	<span class="p-input-icon-right clickable"
		(click)="copyFormula()"
	>
	
		<i class="pi pi-copy"></i>
		<input type="text" pInputText
			class="clickable"
			id="formula"
			[readonly]="true"
			[ngModel]="exportFormula"
			aria-describedby="formula-help" 
		/>
	</span>
	<small id="formula-help" class="block">Copy this formula into google sheets</small>
</div>

<p-footer>

	<button pButton (click)="ref.close()"
		icon="pi pi-check"
		label="Close"
		class="p-button-primary"
		data-cy="ok-button"
	></button>

	<a pButton
		label="Copy & create a new Sheet"
		href="https://sheets.new"
		target="_blank"
		icon="pi pi-copy"
		(click)="copyFormula()"
	></a>
</p-footer>
