<div
  *ngIf="!isEditMode"
  class="chip"
>
  <div class="mediaContainer">
    <ng-container *ngIf="agent; else addAgentIcon">
      <i class="nameInitials">{{ agent?.name?.[0] }}</i>
    </ng-container>
  </div>
  <ng-container *ngIf="agent; else addAgentContainer">
    <div
      class="content clickable"
      (click)="openAgentDetails()"
    >
      <span class="value">{{ agent?.name }}</span>
      <span class="label">{{ role }}</span>
    </div>
    <p-button
      size="small"
      class="chipActionsButton"
      icon="pi pi-ellipsis-v"
      [text]="true"
      (onClick)="menu.toggle($event)"
    ></p-button>
  </ng-container>
</div>

<div
  class="chip"
  *ngIf="isEditMode"
>
  <div class="content">
    <p-autoComplete
      [(ngModel)]="selectedEmployee"
      [suggestions]="employees"
      (completeMethod)="filterEmployees($event)"
      (onSelect)="selectEmployee($event)"
      field="name"
      placeholder="Select agent..."
      appendTo="body"
    ></p-autoComplete>
  </div>
</div>

<p-menu
  #menu
  [model]="actionMenuItems"
  [popup]="true"
  appendTo="body"
></p-menu>

<ng-template #addAgentIcon>
  <i class="pi pi-user"></i>
</ng-template>
<ng-template #addAgentContainer>
  <div
    class="content clickable"
    (click)="!agent && addAgent()"
  >
    <span class="addAgent">+ {{ role }}</span>
  </div>
</ng-template>