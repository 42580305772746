import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeOf'
})
export class TypeOfPipe implements PipeTransform {

  transform(value: unknown, type: string): boolean {
    if (type === 'object') {
      return value && typeof value == 'object';
    };
    return typeof value === type;
  }

}
