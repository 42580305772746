import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { AttentionItem } from '../../../generated/graphql.generated';
import { AttentionConfig, AttentionItemsService } from '../attention-items.service';

export interface AttentionExplanation {
  label: string;
  description?: string;
}

@Component({
  selector: 'app-attention-items-explanation',
  templateUrl: './attention-items-explanation.component.html',
  styleUrls: ['./attention-items-explanation.component.scss']
})
export class AttentionItemsExplanationComponent implements OnInit, OnDestroy {

  @Input() item: AttentionItem;

  @Input() maxExplanations = 3;

  @Input() explanationStyle: 'chip' | 'text' = 'chip';

  attentionExplanations: AttentionExplanation[];

  subs = new SubSink();

  additionalExplanations: {
    label: string;
    tooltip: string;
    explanations: AttentionExplanation[];
    expanded: boolean;
  };

  constructor(
    public attentionItems: AttentionItemsService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.attentionItems.configs$
      .subscribe((configs) => this.parseExplanations(configs));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  parseExplanations(configs: AttentionConfig[]) {
    if (!configs) { return; }

    const parsedExplanations: AttentionExplanation[] = this.item.attentionExplanation
      .map((e) => configs.find((c) => e === c.attentionExplanation))
      .sort((a, b) => a.score - b.score)
      .map((c) => ({
        label: c?.label,
        description: c?.description,
      }));

    this.attentionExplanations = parsedExplanations.slice(0, this.maxExplanations);

    const remainder = parsedExplanations.slice(this.maxExplanations);

    this.additionalExplanations = {
      label: `+ ${remainder.length}`,
      explanations: remainder,
      tooltip: remainder.join('\n'),
      expanded: false,
    };

  }

}
