import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { Component, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';

import { jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-job-status-dropdown',
  standalone: true,
  imports: [MenuModule, TitleCasePipe, AsyncPipe, CommonModule],
  templateUrl: './job-status-dropdown.component.html',
  styleUrl: './job-status-dropdown.component.scss',
})
export class JobStatusDropdownComponent {
  @ViewChild('menu') jobStageMenu: Menu;

  private wasClickedInside = false;
  @HostListener('click')
  clickInside() {
    this.wasClickedInside = true;
  }

  @HostListener('document:click', ['$event.target'])
  clickedOut(targetElement: HTMLElement) {
    if (this.isJobStatusMenuVisible) {
      // Only run when the menu is visible
      const menuElement = document.querySelector('.p-menu-overlay');

      const isInsideMenu = menuElement?.contains(targetElement);
      if (!this.wasClickedInside && !isInsideMenu) {
        this.isJobStatusMenuVisible = false;
      }
    }
    this.wasClickedInside = false;
  }

  public job$ = this.store.select(jobToolFeature.selectJob);
  public isJobStatusMenuVisible = false;
  public jobStatusItems: MenuItem[] = [
    {
      label: 'Review Lead',
      icon: 'pi pi-eye',
    },
    {
      label: 'Move to Estimate',
      icon: 'pi pi-receipt',
    },
    {
      label: 'Close Lead',
      icon: 'pi pi-folder',
    },
  ];

  constructor(public store: Store) {}

  public toggleMenu($event: Event): void {
    this.isJobStatusMenuVisible = !this.isJobStatusMenuVisible;
    this.jobStageMenu.toggle($event);
  }
}
