<div *ngIf="leadSource">
  <div class="header-with-button">
    <div>
      <h4><i class="pi pi-crown large-icon"></i> Lead Source</h4>
    </div>
    <div>
      <button
        pButton
        pRipple
        label="Actions"
        (click)="menu.toggle($event)"
        icon="pi pi-angle-down"
      ></button>
      <p-menu #menu [popup]="true" appendTo="body" [model]="actions"></p-menu>
    </div>
  </div>

  <div class="card">
    <h4>{{ leadSource.metadata.name }}</h4>
    <p>Active: {{ leadSource.isActive ? 'Active' : 'Inactive' }}</p>
    <p>Origin: {{ leadSource.metadata.origin }}</p>
    <p>Source: {{ leadSource.metadata.source }}</p>
    <p>Customer Type: {{ leadSource.metadata.customerType }}</p>
  </div>

  <h5>Zones</h5>
  <div class="card" *ngIf="leadSource?.zones">
    <div *ngFor="let zone of leadSource.zones">
      <div class="p-grid p-align-center">
        <div class="p-col-2">
          <span class="indicator"></span>
        </div>
        <div class="p-col-10">
          <span>{{ zone.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Instructions"
  [modal]="true"
  [(visible)]="showInstructions"
  [style]="{ width: '25rem' }"
>
  <h4>Instructions for {{ leadSource.metadata.name }}.</h4>

  POST URL:
  <input [value]="url" />
</p-dialog>

<app-mutate-lead-source mutateType="create" #mutate></app-mutate-lead-source>
