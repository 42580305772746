<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="p-d-flex p-jc-between">
                <h4>General</h4>
                <a routerLink="/needs-attention" class="bold">
                    View Items Needing Attention
                </a>
            </div>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="header-with-button">
                <h5 data-cy="office-card-header">Office</h5>
<!-- todo: add restriction checking  -->
                <div *hasPermission="['config.set']">
                    <button *ngIf="!franchiseInfo.editMode" pButton icon="pi pi-pencil" (click)="franchiseInfo.editMode = true"></button>
                    <button *ngIf="franchiseInfo.editMode" class="p-button-success" pButton icon="pi pi-save" (click)="saveInfo('franchise-info', franchiseInfo)"></button>
                </div>
            </div>
            
            <p *ngFor="let item of franchiseInfo | keyvalue; trackBy:identify">
                <span *ngIf="item.key !== 'editMode'">
                    <span *ngIf="configsLoaded; else elseBlock">
                        {{item.key | prettyProperty}}: 
                        <span *ngIf="!franchiseInfo.editMode" [class.placeholder-text]="!item.value">{{item.value | notSet}}</span>
                        <span *ngIf="franchiseInfo.editMode">
                            <br><input type="text" name="item.key" (change)="markAsModified(item.key)" [(ngModel)]="franchiseInfo[item.key]" pInputText /></span>
                    </span>
                </span>
            </p>
            <p>
                <span>
                    <span [formGroup]="locationForm">
                        <span *ngIf="!franchiseInfo.editMode">HQ Location: {{locationForm.value.location || 'Not Set'}}</span>
                        <span [hidden]="!franchiseInfo.editMode">
                            <div>
                                HQ Location: 
                                <input
                                    *ngIf="googleHelper.loaded | async"
                                    class="location-input"
                                    [options]="options"
                                    pInputText
                                    formControlName="location"
                                    ngx-gp-autocomplete
                                    (onAddressChange)="handleAddressChange($event)"
                                    #googleAutocomplete="ngx-places"
                                />
                            </div>
                        </span>
                    </span>
                </span>
            </p>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="header-with-button">
                <h5>System</h5>
                <div *hasPermission="['config.set']">
                    <button *ngIf="!systemDetails.editMode" pButton icon="pi pi-pencil" (click)="systemDetails.editMode = true"></button>
                    <button *ngIf="systemDetails.editMode" class="p-button-success" pButton icon="pi pi-save" (click)="saveInfo('system-details', systemDetails)"></button>
                </div>
            </div>
            <p *ngFor="let item of systemDetails | keyvalue; trackBy:identify">
                <span *ngIf="item.key !== 'editMode'">
                    <span *ngIf="configsLoaded; else elseBlock">
                        {{item.key | prettyProperty}}:
                        <span *ngIf="!systemDetails.editMode" [class.placeholder-text]="!item.value">{{item.value | notSet}}</span>
                        <span *ngIf="systemDetails.editMode">
                            <br><input type="text" name="item.key" (change)="markAsModified(item.key)" [(ngModel)]="systemDetails[item.key]" pInputText /></span>
                    </span>
                </span>
            </p>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="header-with-button">
                <h5>Legal</h5>
                <div *hasPermission="['config.set']">
                    <button *ngIf="!legalCard.editMode" pButton icon="pi pi-pencil" (click)="legalCard.editMode = true"></button>
                    <button *ngIf="legalCard.editMode" class="p-button-success" pButton icon="pi pi-save" (click)="saveInfo('legal', legalCard)"></button>
                </div>
            </div>
            <p *ngFor="let item of legalCard | keyvalue; trackBy:identify">
                <span *ngIf="item.key !== 'editMode'">
                    <span *ngIf="configsLoaded; else elseBlock">
                        {{item.key | prettyProperty}}:  
                        <span *ngIf="!legalCard.editMode" [class.placeholder-text]="!item.value">{{item.value | notSet}}</span>
                        <span *ngIf="legalCard.editMode"><br><input type="text" name="item.key" (change)="markAsModified(item.key)" [(ngModel)]="legalCard[item.key]" pInputText /></span>
                    </span>
                </span>
            </p>
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card">
            <div class="header-with-button">
                <h5>Contacts</h5>
                <div *hasPermission="['config.set']">
                    <button *ngIf="!contactDetails.editMode" pButton icon="pi pi-pencil" (click)="contactDetails.editMode = true; onContactDetailsEditModeChange()"></button>
                    <button *ngIf="contactDetails.editMode" class="p-button-success" pButton icon="pi pi-save" (click)="saveInfo('additional-contact-details', contactDetails)"></button>
                </div>
            </div>
            <p *ngFor="let item of contactDetails | keyvalue; trackBy:identify">
                <span *ngIf="item.key !== 'editMode'">
                    <span *ngIf="configsLoaded; else elseBlock">
                        {{item.key | prettyProperty}}: 
                        <span *ngIf="!contactDetails.editMode" [class.placeholder-text]="!item.value">{{item.value | notSet}}</span>
                        <span *ngIf="contactDetails.editMode"><br><input type="text" name="item.key" (change)="markAsModified(item.key)" [(ngModel)]="contactDetails[item.key]" pInputText /></span>
                    </span>
                </span>
            </p>

            <div [formGroup]="notificationsForm">
                <h6>Lead Notification List</h6>
                <p>Whenever a new lead comes in, an email notification will be sent to the email addresses below.</p>
                <app-user-search-multiple
                    formControlName="leadNotificationRecipients"
                    ></app-user-search-multiple>
                <h6 #jobWatchers>Job Watchers (BETA)</h6>
                <p>
                    Send carbon copies of emails and SMS messages to these addresses.
                    <br />
                    Emails and SMS messages sent to a customer will also be sent to the addresses specified
                    below as a copy. You may specify a phone number or email address below.
                    <br /><br />
                    <strong>Note:</strong> This functionality is in beta and is subject to change.
                    <br />
                    <strong>Note:</strong> Only emails/sms's sent to the customer will have a copy sent to
                    the job watchers. If the customer does not have an email or phone number
                    then they will not be sent out.
                    <br />
                    <strong>Note:</strong> Please ensure phone numbers are in the format: <kbd>11231231234</kbd>
                </p>
                <p-chips
                    formControlName="jobWatchers"
                    placeholder="Enter email or phone number then press enter"
                    [max]="16"
                    [addOnTab]="true"
                    [addOnBlur]="true"
                    (onAdd)="checkJobWatchers(); markAsModified('jobs.watchers')"
                    (onRemove)="markAsModified('jobs.watchers')"
                    (onClear)="markAsModified('jobs.watchers')"
                ></p-chips>
                <div *hasPermission="['config.set']">
                    <br />
                    <label>Notify watchers when document has been signed</label>
                    <br />
                    <p-inputSwitch formControlName="notifyWatchersDocumentCompleted"></p-inputSwitch>
                </div>
            </div>
        </div>
    </div>
</div>
