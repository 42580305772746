/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { RangeQueryParam } from './stripe-promotions';

export interface Product {
    id?: string;
    active?: boolean;
    attributes?: string[];
    // caption?: string;
    created?: number;
    // deactivate_on?: string[];
    deleted?: boolean;
    description?: string;
    images?: string[];
    livemode?: boolean;
    name?: string;
    shippable?: boolean;
    statement_descriptor?: string;
    type?: ProductType;
    unit_label?: string;
    updated?: number;
    prices?: Price[];
    // url?: string;
}

export interface Price{
    id?: string;
    active?: boolean;
    created?: number;
    currency?: string;
    deleted?: boolean;
    livemode?: boolean;
    lookup_key?: string;
    nickname?: string;
    product?: Product;
    unit_amount?: number;
}

export enum ProductType {
    service,
    good
}
export interface ProductOutput {
    products: {
        hasMore: boolean;
        products: Product[];
    };
}

export interface ListProductInput {
    active?: boolean;
    created?: RangeQueryParam;
    ids?: string[];
    type?: string;
    ending_before?: string;
    limit?: number;
    starting_after?: string;
}


export const productsQuery = gql`
query products {
  products{
    products {
        id
        active
        attributes
        created
        # deactivate_on
        deleted
        description
        images
        livemode
        name
        shippable
        statement_descriptor
        type
        unit_label
        updated
        # url
        # caption
        prices{
        id
        active
        currency
        nickname
        unit_amount
            deleted
        created
        lookup_key
        }
    }
  }
}
`;

export interface CreateProductInput {
    name: string;
    active?: boolean;
    attributes?: string[];
    caption?: string;
    deactivate_on?: string[];
    description?: string;
    expand?: string[];
    images?: string[];
    shippable?: boolean;
    statement_descriptor?: string;
    type?: ProductType;
    unit_label?: string;
    url?: string;
}

export interface CreateProductOutput {
    createProducts: Product[];
}

export const createProductMutation = gql`
    mutation createProduct($products: [CreateProduct]) {
        createProducts(products: $products){
            id
            active
            description
            name
            attributes
        }
    }
`;

export interface EditProductInput extends CreateProductInput{
    stripeId: string;
}

export interface EditProductOutput {
    updateProduct: boolean;
}

export const editProductMutation = gql`
    mutation editProduct($stripeId: String!
                        $active: Boolean
                        $attributes: [String]
                        $caption: String
                        $deactivate_on: [String]
                        $description: String
                        $expand: [String]
                        $images: [String]
                        $name: String
                        $shippable: Boolean
                        $url: String
                        $unit_label: String
                        $statement_descriptor: String) {
        updateProduct(stripeId: $stripeId,
                        active: $active,
                        attributes: $attributes,
                        caption: $caption,
                        deactivate_on: $deactivate_on,
                        description: $description,
                        expand: $expand,
                        images: $images,
                        name: $name,
                        shippable: $shippable,
                        url: $url
                        unit_label: $unit_label
                        statement_descriptor: $statement_descriptor)
    }
`;

export interface DeleteProductOutput {
    deleteProduct: boolean;
}

export const deleteProductMutation = gql`
    mutation deleteProduct($stripeId: String!) {
        deleteProduct(stripeId: $stripeId)
    }
`;
