import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { clone } from 'lodash';
import { PermissionService } from 'src/app/services/permission.service';
import { parseMenuItemCategoriesVisible } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { BaseRuleFragment, BaseZoneFragment } from '../../../generated/graphql.generated';

import { describeConditionObject } from '../../franchise/rules/rules.constants';
import { HistoryService } from '../../history/history.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateRuleComponent } from '../mutate-rule/mutate-rule.component';

@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.scss']
})
export class RuleDetailsComponent implements OnInit, OnDestroy {

  @Input() rule: BaseRuleFragment;
  @ViewChild('mutate') mutateRef: MutateRuleComponent;

  totalZones: number;
  zones: BaseZoneFragment[];

  metadata: {
    key: string;
    value: string;
  }[];

  updateRuleAction = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.openUpdate();
    }
  };

  duplicateRuleAction = {
    label: 'Duplicate',
    icon: 'pi pi-clone',
    visible: false,
    command: () => {
      this.openDuplicate();
    }
  };

  copyAction = {
    label: 'Copy to clipboard',
    icon: 'pi pi-copy',
    visible: true,
    command: () => {
      this.copyToClipboard();
    }
  };

  deleteRuleAction = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.openDelete();
    }
  };

  viewHistoryAction = {
    id: 'viewHistory',
    label: 'View History',
    icon: 'pi pi-book',
    visible: true,
    command: () => {
      this.historyService.openHistory('Rule', [ this.rule.id ]);
    }
  };


  ruleActions = [{
    label: 'Actions',
    items: [
      this.updateRuleAction,
      this.duplicateRuleAction,
      this.copyAction,
      this.deleteRuleAction,
      this.viewHistoryAction,
    ],
    visible: false,
  }];

  subs = new SubSink();

  constructor(
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    private clipboard: Clipboard,
    private localNotify: FreyaNotificationsService,
    private historyService: HistoryService,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    // load zones when details is opened
    this.subs.sink = this.detailsHelper.detailsItem.subscribe((item) => {
      if (item?.type !== 'rules') { return; }
      this.zones = undefined;
      this.parseMetadata();
    });

    this.parseMetadata();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['rules.update', 'rules.create', 'rules.delete', 'history.list'])
      .subscribe((res) => {
        this.updateRuleAction.visible = res[0];
        this.duplicateRuleAction.visible = res[1];
        this.deleteRuleAction.visible = res[2];
        this.viewHistoryAction.visible = res[3];

        const [ actions ] = this.ruleActions;

        actions.visible = actions.items.some((a) => a.visible);

        parseMenuItemCategoriesVisible(this.ruleActions);
        this.ruleActions = clone(this.ruleActions);
      });
  }

  openUpdate() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.mutateType = 'update';
    this.mutateRef.openDialog();
  }

  openDelete() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.openDelete();
  }

  openDuplicate() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.mutateType = 'duplicate';
    this.mutateRef.openDialog();
    // this.mutateRef.rule = this.rule;
    // this.mutateRef.openDelete();
  }

  describeCondition(condition: string) {
    if (!condition || typeof condition !== 'string') { return ''; }
    return describeConditionObject(JSON.parse(condition));
  }

  parseMetadata() {
    if (!this.rule) {
      this.metadata = [];
    }

    this.metadata = Object.entries((this.rule.metadata))
      .map(([key, value]) => ({ key, value: value as string }));
  }

  copyToClipboard() {
    const rule = {
      ...this.rule,
      trigger: this.rule.trigger.id,
      action: this.rule.action.id,
      attributes: [
        ...this.rule.attributes,
        `copied-from::${ this.rule.id }`,
      ],
    };

    delete rule.triggerDescription;
    delete rule.describedConditions;
    delete rule.actionDescription;
    delete rule.createdAt;
    delete rule.updatedAt;
    delete rule.deletedAt;

    const obj = [rule];

    this.clipboard.copy(JSON.stringify(obj, null, 2));

    this.localNotify.success('Rule copied to clipboard');

  }

}
