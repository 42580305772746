import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateCurrency',
})
export class AbbreviateCurrencyPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(value: number, currencyCode: string, abbreviate = false) {

    if (!abbreviate) {
      return this.currencyPipe.transform(value, currencyCode);
    }

    let abbreviatedValue: string;

    let suffix: string;

    if (value >= 1e9) {
      abbreviatedValue = (value / 1e9).toFixed(2);
      suffix = 'B';
    } else if (value >= 1e6) {
      abbreviatedValue = (value / 1e6).toFixed(2);
      suffix = 'M';
    } else if (value >= 1e3) {
      abbreviatedValue = (value / 1e3).toFixed(2);
      suffix = 'K';
    } else {
      return this.currencyPipe.transform(value, currencyCode);
    }

    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(parseFloat(abbreviatedValue));

    return `${amount}${suffix}`;
  }

}
