import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * This Directive is for use on Reactive forms where disabled is not allowed
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[controlDisabled]'
})
export class ControlDisabledDirective implements OnInit {

  @Input() set controlDisabled( disable: boolean ) {
    if (!this.ngControl.control) { return; }
    if (disable){
      this.ngControl.control.disable();
    } else {
      this.ngControl.control.enable();
    }
  }

  constructor(private ngControl: NgControl) {
  }

  ngOnInit(): void {
  }

}
