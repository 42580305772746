import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(array: any[], seperator = ', ', property: string = undefined, slice: number = undefined): string {

    // If it's an array of objects get a property
    if (property){
      array = array.map((val) => val[property]);
    }

    // If you want to slice to value eg. 'Monday' -> 'Mon'
    if (slice){
     array = array.map((val) => val.slice(0, slice));
    }

    return array.join(seperator);
  }

}
