<div class="card">
    <div class="p-d-flex p-jc-between p-ai-center p-mb-4">
        <h5>{{ config.key }}
            <app-info-circle
                *ngIf="config.zone && config.zone !== currentZoneId"
                class="info-circle"
                [pTooltip]="'Resolved from zone: ' + config.zone"
                ></app-info-circle>
        </h5>
        <div style="min-width: fit-content;">
            <div *hasPermission="['config.set']">
                <button
                    pButton
                    *ngIf="!editMode && (config.zone === currentZoneId)"
                    icon="pi pi-refresh"
                    class="p-button-outlined p-mr-2"
                    [pTooltip]="'Fallback: ' + fallback"
                    (click)="reset()"
                    ></button>
                <button
                    pButton
                    *ngIf="!editMode"
                    icon="pi pi-pencil"
                    (click)="enterEditMode()"></button>
            </div>
            <button
                pButton
                *ngIf="editMode && parsedValue && isArray"
                icon="pi pi-trash"
                [disabled]="!editableListRef?.selectedItems.length"
                (click)="editableListRef.openDeleteDialog()"
                class="p-button-danger p-mr-2"></button>
            <button
                pButton
                *ngIf="editMode && !isArray"
                icon="pi pi-save"
                (click)="save()"
                class="p-button-success p-mr-2"
                [disabled]="!modified"></button>
            <button
                pButton
                *ngIf="editMode"
                [icon]="modified ? 'pi pi-trash' : 'pi pi-times'"
                (click)="discardChanges()"
                class="p-button-secondary"
                [pTooltip]="modified? 'Discard changes.' : undefined"></button>
        </div>
    </div>
    <div *ngIf="parsedValue && !isArray">
        <div *ngFor="let property of parsedValue | keyvalue; trackBy:identify" class="p-mb-2">
            <div *ngIf="parsedValue[property.key] | typeOf : 'boolean'" class="p-d-flex p-jc-between p-ai-center">
                <span class="p-text-bold">{{ property.key | prettyProperty : true }}</span>
                <p-inputSwitch [(ngModel)]="parsedValue[property.key]" [disabled]="!editMode" (onChange)="markAsModified()"></p-inputSwitch>
            </div>
            <div *ngIf="!(parsedValue[property.key] | typeOf : 'boolean') && !(parsedValue[property.key] | typeOf : 'object')">
                <div class="p-text-bold p-mb-1">{{ property.key | prettyProperty : true }}</div>
                <div *ngIf="!editMode" class="input-size"> {{ property.value | notSet }} </div>
                <input *ngIf="editMode" pInputText type="text" [(ngModel)]="parsedValue[property.key]" (input)="markAsModified()" class="full-width" placeholder="Not Set">
            </div>
        </div>
    </div>
    <app-editable-list
        *ngIf="parsedValue && isArray"
        #editableList
        [items]="parsedValue"
        [editable]="editMode"
        (itemsEdited)="save($event)"
        ></app-editable-list>
    <p-message *ngIf="config && !parsedValue" severity="warn" text="Could not parse JSON value."></p-message>  
</div>
