<p-table
  *ngIf="productSettings"
  [value]="categories"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>Product Category</th>
      <th>Item Type</th>
      <th>Income Account</th>
      <th>Expense Account</th>
      <th>Asset Account</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-category>
    <tr>
      <td>{{ category }}</td>
      <td>
        <p-dropdown
          [options]="itemTypes"
          (onChange)="saveProductSettings()"
          [(ngModel)]="productSettings[category].itemType"
          styleClass="full-width"
          appendTo="body"
          ></p-dropdown>
      </td>
      <td>
        <p-dropdown
          [options]="incomeAccounts || []"
          (onChange)="saveProductSettings()"
          placeholder="Income account"
          [(ngModel)]="productSettings[category].incomeAccountId"
          optionLabel="Name"
          optionValue="Id"
          styleClass="full-width"
          [class.ng-invalid]="!productSettings[category].incomeAccountId"
          appendTo="body"
          ></p-dropdown>
      </td>
      <td>
        <p-dropdown
          [options]="expenseAccounts || []"
          (onChange)="saveProductSettings()"
          placeholder="Expense account"
          [(ngModel)]="productSettings[category].expenseAccountId"
          [disabled]="productSettings[category].itemType !== 'Inventory'"
          optionLabel="Name"
          optionValue="Id"
          styleClass="full-width"
          [class.ng-invalid]="!productSettings[category].incomeAccountId && productSettings[category].itemType === 'Inventory'"
          appendTo="body"
          ></p-dropdown>
      </td>
      <td>
        <p-dropdown
          [options]="assetAccounts || []"
          (onChange)="saveProductSettings()"
          [(ngModel)]="productSettings[category].assetAccountId"
          placeholder="Asset account"
          [disabled]="productSettings[category].itemType !== 'Inventory'"
          optionLabel="Name"
          optionValue="Id"
          styleClass="full-width"
          [class.ng-invalid]="!productSettings[category].incomeAccountId && productSettings[category].itemType === 'Inventory'"
          appendTo="body"
          ></p-dropdown>
      </td>
    </tr>
  </ng-template>
</p-table>
