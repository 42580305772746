import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { CreateJobInput, createJobMutation, CreateJobOutput, DeleteJobsInput, deleteJobsMutation, DeleteJobsOutput, GenerateJobsQuery, GenerateJobsQueryInput, JobsInput, ListJobsFunctionOutput, UpdateJobsFunctionOutput, UpdateJobsInput, updateJobsMutation } from '@karve.it/interfaces/jobs';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';
@Injectable({
  providedIn: 'root'
})
export class JobService {

    constructor(private apollo: PlusApollo){}

    listJobs(input: JobsInput, queryInput: GenerateJobsQueryInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListJobsFunctionOutput>({
            query: GenerateJobsQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchJobs(input: JobsInput, queryInput: GenerateJobsQueryInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListJobsFunctionOutput>({
            query: GenerateJobsQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createJob(input: CreateJobInput, overrideZone: string = ''){
        return this.apollo.mutate<CreateJobOutput>({
            mutation: createJobMutation,
            variables: {
                ...input
            },
            context: {
                headers: {
                  'x-zone': overrideZone,
                },
            },
        });
    }

    updateJobs(input: UpdateJobsInput, overrideZone: string = ''){
        return this.apollo.mutate<UpdateJobsFunctionOutput>({
            mutation: updateJobsMutation,
            context: {
                headers: {
                  'x-zone': overrideZone,
                },
              },
            variables: {
                ...input
            }
        });
    }

    deleteJobs(input: DeleteJobsInput){
        return this.apollo.mutate<DeleteJobsOutput>({
            mutation: deleteJobsMutation,
            variables: {
                ...input
            }
        });
    }



}
