<div class="card">
  <div>
    <div class="header-with-button">
      <h5>"How Did You Hear About Us?" Options</h5>
      <div *ngIf="canEdit">
        <button
          pButton
          icon="pi pi-trash"
          class="p-button-danger"
          (click)="howHeardList.openDeleteDialog()"
          [disabled]="!howHeardList.selectedItems.length"
          ></button>
        <button
          pButton
          icon="pi pi-plus"
          (click)="howHeardList.openAddDialog()"
          class="p-ml-1"
          ></button>
      </div>
    </div>
    <app-editable-list
      #howHeardList
      [items]="howHeardOptions"
      editWarning="This will not affect any jobs with this value"
      deleteWarning="This will not affect any jobs with this value or values"
      [editable]="canEdit"
      (itemsEdited)="mutateConfigs($event, howHeardList, 'jobs.howHeardOptions')"
      ></app-editable-list>
  </div>
  <div>
    <div class="header-with-button">
      <h5>Job Origins</h5>
      <div *ngIf="canEdit">
        <button
          pButton
          icon="pi pi-trash"
          class="p-button-danger"
          (click)="jobOriginsList.openDeleteDialog()"
          [disabled]="!jobOriginsList.selectedItems.length"
          ></button>
        <button
          pButton
          icon="pi pi-plus"
          (click)="jobOriginsList.openAddDialog()"
          class="p-ml-1"
          ></button>
      </div>
    </div>
    <app-editable-list
      #jobOriginsList
      [items]="jobOriginOptions"
      editWarning="This will not affect any jobs with this value"
      deleteWarning="This will not affect any jobs with this value or values"
      [editable]="canEdit"
      (itemsEdited)="mutateConfigs($event, jobOriginsList, 'jobs.origins')"
      ></app-editable-list>
  </div>
  <div>
    <div class="header-with-button">
      <h5>Customer Types</h5>
      <div *ngIf="canEdit">
        <button
          pButton
          icon="pi pi-trash"
          class="p-button-danger"
          (click)="customerTypesList.openDeleteDialog()"
          [disabled]="!customerTypesList.selectedItems.length"
          ></button>
        <button
          pButton
          icon="pi pi-plus"
          (click)="customerTypesList.openAddDialog()"
          class="p-ml-1"
          ></button>
      </div>
    </div>
    <app-editable-list
      #customerTypesList
      [items]="customerTypeOptions"
      editWarning="This will not affect any jobs with this value"
      deleteWarning="This will not affect any jobs with this value or values"
      [editable]="canEdit"
      (itemsEdited)="mutateConfigs($event, customerTypesList, 'customer.types')"
      ></app-editable-list>
  </div>
</div>

