<div
    class="card"
    *ngIf="showHeaderCard"
>
    <div class="p-grid">
        <div class="p-col">
            <h4>Charges</h4>
        </div>
    </div>
</div>

<br *ngIf="showHeaderCard">

<div class="card">
    <div class="p-grid" *ngIf="tableTitle">
        <div class="p-col">
            <h5>{{tableTitle}}</h5>
        </div>
        <div class="p-col p-text-right" *hasPermission="['charges.create']">
            <span [pTooltip]="readonly && readonlyTooltip" tooltipPosition="left">
                <button pButton [disabled]="readonly  || !(job | jobLocation : 'start')" (click)="createCharge()" icon="pi pi-plus"></button>
            </span>
        </div>
    </div>
    <!--         (onLazyLoad)="retrieveMoreCharges($event)"
        [lazyLoadOnInit]="true"
        [lazy]="true" -->
    <p-table
        [value]="charges"
        [paginator]="true"
        [totalRecords]="chargesQH.total"
        [(rows)]="chargesQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="chargesQH.loading"
        [showCurrentPageReport]="!chargesQH.loading"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="activeData.product">Product</th>
                <th *ngIf="activeData.amount">Amount</th>
                <th *ngIf="activeData.quantity">Quantity</th>
                <th *ngIf="activeData.subtotal">Subtotal</th>
                <th *ngIf="showEditButton">Edit</th>
                <th *ngIf="showRemoveButton">Remove</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-charge
        >
            <tr
                class="selectable-row"
                (click)="openCharge(charge)"
            >
                <td *ngIf="activeData.product">
                    <span class="p-column-title">Product</span>
                    {{ charge?.product?.name || charge?.productName }}
                </td>
                <td *ngIf="activeData.amount && charge?.price?.priceType !== 'percentage'">
                    <span class="p-column-title">Amount</span>
                    {{ charge | chargeAmount | currency : job?.currency || 'USD' }}
                </td>
                <td *ngIf="activeData.amount && charge?.price?.priceType === 'percentage'">
                    <span class="p-column-title">Amount</span>
                    {{ charge | chargeAmount }}%
                </td>
                <td *ngIf="activeData.quantity">
                    <span class="p-column-title">Quantity</span>
                    {{ charge.quantity }}
                </td>
                <td *ngIf="activeData.subtotal">
                    <span class="p-column-title">Subtotal</span>
                    {{ charge.chargeSubTotal / 100 | currency : job?.currency || 'USD' }}
                </td>
                <td
                    *ngIf="showEditButton"
                    [pTooltip]="
                    readonly && readonlyTooltip ||
                    (lockedCharges[charge.id] && 'Cannot modify charges on events ending before ' + formattedLockDate) ||
                    (charge?.calendarEvent | eventHasInvoice) && 'This charge has been invoiced'
                    "
                    tooltipPosition="bottom"
                    >
                    <span class="p-column-title">Edit</span>
                    <button
                        pButton
                        icon="pi pi-pencil"
                        [disabled]="readonly || lockedCharges[charge.id] || (charge?.calendarEvent | eventHasInvoice)"
                        (click)="editCharge(charge)"
                        ></button>
                </td>
                <td
                    *ngIf="showRemoveButton"
                    [pTooltip]="
                    readonly && readonlyTooltip ||
                    (lockedCharges[charge.id] && 'Cannot modify charges on events ending before ' + formattedLockDate) ||
                    (charge?.calendarEvent | eventHasInvoice) && 'This charge has been invoiced'
                    "
                    tooltipPosition="bottom"
                    >
                    <span class="p-column-title">Remove</span>
                    <button
                        pButton
                        class="p-button-danger"
                        icon="pi pi-trash"
                        [disabled]="readonly || lockedCharges[charge.id] || (charge?.calendarEvent | eventHasInvoice)"
                        (click)="removeCharge(charge)"
                        ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'6'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>
