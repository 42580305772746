<div
    class="menu-wrapper"
    [ngClass]="{
        'layout-sidebar-active': appMain.sidebarActive,
        'sidebar-static': sidebarStatic,
        'layout-mobile-active': sidebarMobileActive
    }"
    (click)="appMain.onSidebarClick($event)"
    (mouseover)="appMain.onSidebarMouseOver($event)"
    (mouseleave)="appMain.onSidebarMouseLeave($event)"
>
    <div
        class="sidebar-logo"
        [class.hidden]="!isSideBarDisabled"
    >
        <a
            [routerLink]="['/']"
            class="logo-img"
        >
            <p-skeleton
                *ngIf="(branding.loading | async) === true"
                width="90%"
                height="80%"
            ></p-skeleton>
            <img
                *ngIf="(branding.loading | async) === false"
                [class.hidden]="!appMain.sidebarActive"
                [src]="branding.watchIcon('Wide') | async"
                [alt]="(branding.currentBranding | async).appName"
            >
            <img
                *ngIf="(branding.loading | async) === false"
                [class.hidden]="appMain.sidebarActive"
                [src]="branding.watchIcon(256) | async"
                [alt]="(branding.currentBranding | async).appName"
            >
        </a>
        <!-- <p class="sidebar-pin no-margin">BETA</p> -->
        <a
            href="#"
            class="sidebar-pin"
            (click)="appMain.onToggleMenu($event)"
        >
            <span class="pin"></span>
        </a>
    </div>
    <div
        [class.hide]="!(appMain.sidebarActive || appMain.staticMenuMobileActive)"
        class="zone-select-container"
    >
        <app-zone-select></app-zone-select>
    </div>
    <div
        class="p-text-center"
        *ngIf="appMain.sidebarActive || appMain.staticMenuMobileActive"
    >
        <small>{{ (branding.currentBranding | async).timezone }}</small>
    </div>
    <div
        [style]="{ padding: '10px 0'}"
        *hasPermission="['frontend.needs-attention']"
        [class.hidden]="!isSideBarDisabled"
    >
        <a
            *ngIf="(appMain.sidebarActive || appMain.staticMenuMobileActive) && attentionItemsTotal$ | async as total"
            routerLink="/needs-attention"
            [style]="{
                padding: '6px 20px',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
                'font-weight': 'bold'
            }"
        >
            <i class="pi pi-exclamation-circle p-mr-2"></i>
            Needs Attention: {{ total }}
        </a>
        </div>
        <div *ngIf="appMain.sidebarActive || appMain.staticMenuMobileActive" [style]="{ padding: '10px 0'}"
            [class.hidden]="!isSideBarDisabled">
            <a
                [routerLink]="'/jobs/new'"
                *ngIf="menuService.isJobCreationEnabled"
                [style]="{
                width: '82%',
                padding: '15px 15px',
                display: 'flex',
                'margin-left': '20px',
                'align-items': 'center',
                'justify-content': 'center',
                'font-weight': 'bold',
                'line-height': '1.5rem',
                color: 'var(--primary-color-text)',
                background: 'var(--primary-color)',
                'text-decoration': 'none',
                'border-radius': '4px'
            }">
                <i class="pi pi-plus" style="margin-right: 8px;"></i>{{ createJobButtonLabel }}
            </a>
        </div>
    <div
        #menuRef
        class="layout-menu-container"
    >
        <ul
            class="layout-menu"
            data-cy="layout-menu"
        >
            <ng-template
                ngFor
                let-item
                [ngForOf]="menuService.sidebar"
                let-i="index"
                [ngForTrackBy]="trackID"
            >
                <li
                    *hasPermission="item.permissions; showByDefault: !item.hiddenByDefault"
                    app-menuitem
                    [hidden]="item.hidden || (item.hiddenIfZoneType && item.hiddenIfZoneType.includes((branding.currentZone | async).type))"
                    [item]="item"
                    [index]="i"
                    [root]="true"
                >
                </li>
            </ng-template>
        </ul>
    </div>
    <div
        #recentItemsRef
        class="recent-items"
    >
        <p-panel
            [toggleable]="true"
            expandIcon="pi pi-chevron-up"
            collapseIcon="pi pi-chevron-down"
            toggler="header"
            [(collapsed)]="recentItems.pinnedItemsCollapsed"
        >
            <ng-template pTemplate="header">
                <span class="p-panel-header-title">
                    <div class="p-panel-header-icon">
                        <i class="pi mi-pin"></i>
                    </div>
                    <span
                        class="p-panel-header-label"
                        [class.hide]="!(appMain.sidebarActive || appMain.staticMenuMobileActive)"
                    >Pinned Items ({{ recentItems.pinnedItemsInZone.length }}/{{ recentItems.maxPinnedItemsPerZone
                        }})</span>
                </span>
                <app-info-circle
                    class="info-circle"
                    [hidden]="!(appMain.sidebarActive || appMain.staticMenuMobileActive)"
                    pTooltip="To pin an item, click the item's action button and select the pin action."
                ></app-info-circle>
            </ng-template>
            <div
                *ngIf="!recentItems.pinnedItemsInZone.length"
                class="no-items"
            >
                <p><small>Here is where your pinned items will appear. Pinning an item allows for quick access to
                        it.</small></p>
                <p><small>To pin an item, click the item's actions button and select the pin action.</small></p>
                <p><small>Currently not all items can be pinned but we will be expanding this in the future.</small></p>
            </div>
            <div
                [@recentItems]="recentItems.pinnedItemsInZone.length"
                *ngFor="let item of recentItems.pinnedItemsInZone"
                class="pinned-item"
            >
                <div>
                    <button
                        pButton
                        pTooltip="Unpin item"
                        type="button"
                        icon="pi mi-pin-fill"
                        (click)="recentItems.unpinItem(item)"
                    ></button>
                </div>
                <div
                    class="item-link"
                    (click)="recentItems.openItem(item); appMain.onLayoutClick()"
                >
                    <i
                        [class]="item.type === 'artifact' ? (item.displayType | artifactTypeIcon) : entityIcons[item.type]"></i>
                    <span class="item-name">
                        {{ item.displayType }}
                        <small>
                            {{ item.name }}
                        </small>
                    </span>
                </div>
            </div>
        </p-panel>
        <p-panel
            [toggleable]="true"
            expandIcon="pi pi-chevron-up"
            collapseIcon="pi pi-chevron-down"
            toggler="header"
            [(collapsed)]="recentItems.recentItemsCollapsed"
            styleClass="recent-items-panel"
        >
            <ng-template pTemplate="header">
                <span class="p-panel-header-title">
                    <div class="p-panel-header-icon">
                        <i class="pi pi-clock"></i>
                    </div>
                    <span
                        class="p-panel-header-label"
                        [class.hide]="!(appMain.sidebarActive || appMain.staticMenuMobileActive)"
                    >Recent Items</span>
                </span>
                <app-info-circle
                    class="info-circle"
                    [hidden]="!(appMain.sidebarActive || appMain.staticMenuMobileActive)"
                    pTooltip="Whenever you visit an item's page, the item gets added here."
                ></app-info-circle>
            </ng-template>
            <div
                *ngIf="!recentItems.recentItemsInZone.length"
                class="no-items"
            >
                <p><small>Here is where your recent items will appear. Recent items records a list of the pages you have
                        visited recently so you can quickly go back to an item.</small></p>
            </div>
            <div
                [@recentItems]="recentItems.recentItemsInZone.length"
                *ngFor="let item of recentItems.recentItemsInZone"
                class="recent-item"
            >
                <div
                    class="item-link"
                    (click)="recentItems.openItem(item); appMain.onLayoutClick()"
                >
                    <i [class]="entityIcons[item.type]"></i>
                    <span class="item-name">
                        {{ item.displayType }}
                        <small>
                            {{ item.name }}
                        </small>
                    </span>
                </div>
            </div>
        </p-panel>
    </div>
</div>