import { Pipe, PipeTransform } from '@angular/core';
import { Job } from 'graphql.generated';

import { FreyaHelperService } from '../services/freya-helper.service';

// Returns all relavant objects that an artifact should be related to based on a Job
@Pipe({
  name: 'jobArtifactRelatedObjects'
})
export class JobArtifactRelatedObjectsPipe implements PipeTransform {

  constructor(
    private freyaHelper: FreyaHelperService
  ){}

  transform(job: Job): string[] {
    const relatedObjects = [];

    if (!job) { return relatedObjects; };

    relatedObjects.push(job.id);

    const userId = this.freyaHelper.getJobCustomerId(job);

    if (!userId) { return relatedObjects; }

    relatedObjects.push(userId);

    return relatedObjects;
  }

}
