import { Component, Input } from '@angular/core';
import { dayjs } from '@karve.it/core';

import { baseEnvironment } from '../../../environments/environment.base';
import { DistanceUnit } from '../../global.constants';
import { DashboardStore } from '../dashboardv2.store';

export interface AmountDelta {
  amount: number;
  sign: 'positive' | 'negative' | 'neutral';
  loading: boolean;
}

export interface CurrencyDelta extends AmountDelta {
  currency: string;
}

@Component({
  selector: 'app-delta',
  templateUrl: './delta.component.html',
  styleUrl: './delta.component.scss'
})
export class DeltaComponent {

  @Input() delta: CurrencyDelta | AmountDelta;
  @Input() mode: 'currency' | 'percent' | 'distance' | 'duration' = 'currency';
  @Input() units: DistanceUnit = baseEnvironment.defaultUnits;

  viewModel$ = this.dashboardStore.deltaViewModel$;

  constructor(
    private dashboardStore: DashboardStore,
  ) {}

}
