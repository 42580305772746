<div class="header-with-button">
    <h4><i [class]="franchiseIcon"></i> Franchise</h4>
    <div>
        <button
            pButton
            pRipple
            label="Actions"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
            data-cy="actions-button"
        ></button>
        <p-menu
            #menu
            [popup]="true"
            appendTo="body"
            [model]="franchiseActions"
        ></p-menu>
    </div>
</div>

<div class="card">
    <h5>{{franchise?.name}}</h5>
    <p>{{franchise?.description}}</p>
</div>

<h5>Areas</h5>
<div class="card">
    <p *ngFor="let area of franchise?.children?.nodes">{{area.name}}</p>
</div>

<p-dialog header="Change Parent" [(visible)]="showChangeParent" [modal]="true">
    <p>Change the parent of {{ franchise.name }}</p>
    <strong>This is DANGEROUS. Are you sure you want to continue?</strong>
    <br />

    <p-dropdown #changeZoneDropdown
        [options]="availableZones.availableZones | async"
        optionLabel="name"
        optionValue="id"
        appendTo="body"
    >

    </p-dropdown>

    <p-button
        (click)="changeParent(changeZoneDropdown.value)"
        label="Change Parent!"
    ></p-button>
</p-dialog>