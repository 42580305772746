<div class="exception-body notfound">
    <div class="exception-wrapper">
        <div class="exception-content">
            <img src="assets/layout/images/pages/asset-404.svg" alt="freya-layout" />
            <span>{{ title }}</span>
            <span class="exception-subtitle">{{ subtitle }}</span>
            <button pButton pRipple type="button" [label]="goBackText" [routerLink]="goBackLink" ></button>
        </div>
        <div class="exception-footer">
            <!-- <h4>{{project}}</h4> -->
            <h6>Copyright Ⓒ {{company}}</h6>
        </div>
    </div>
</div>
