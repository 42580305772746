import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlusAuthenticationService } from '@karve.it/core';
import { RoleInfo } from '@karve.it/interfaces/roles';
import { Zone } from '@karve.it/interfaces/zones';
import { SubSink } from 'subsink';

import { AppComponent } from '../app.component';
import { text } from '../global.constants';
import { BrandingService } from '../services/branding.service';
import { FreyaHelperService } from '../services/freya-helper.service';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  company = text.companyName;
  project = text.projectName;

  // Template Variables
  userName: string;
  userRole: RoleInfo;
  userZone: Zone;
  deniedUrl: string; // The route that they tried to access

  constructor(
    public app: AppComponent,
    private route: ActivatedRoute,
    private plusAuth: PlusAuthenticationService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParams.subscribe((params) => { // Check for Url
      if (params.url) {
        this.deniedUrl = params.url;
      }
    });

    this.userName = `${this.plusAuth.user.givenName} ${this.plusAuth.user.familyName}`;

    // this.getDetails();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // async getDetails() {
  //   [[this.userRole], [this.userZone]] = await Promise.all(
  //     [
  //       // @ts-ignore
  //       this.freyaHelper.getRoles({ ids: [this.plusAuth.user.role] }),
  //       this.freyaHelper.getZones({ filter: { ids: [ this.branding. ] } })
  //     ],
  //   );
  // }
}
