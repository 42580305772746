
import { AvailableZoneFragment, BaseZoneFragment, BaseZoneWithParentFragment } from '../../generated/graphql.generated';

import { ZONE_ORDER, ZONE_TYPES } from '../global.constants';

export type ZoneType = typeof ZONE_ORDER[number];

export type ZoneFilterDirection = 'gt' | 'lt' | 'gte' | 'lte' | 'eq';

/**
 * Takes an array of zones,
 * and filters out any zones whose zone type does not satisfy
 * the specified comparison to the provided zone type.
 *
 * Example: filterZonesByType(zones, ZONE_TYPES.area, 'gt')
 * will filter out any zones whose zone type is NOT greater than
 * the 'area' type.
 *
 * @param zones The zones to be filtered.
 * @param zoneType The type to filter by.
 * @param zoneDir The comparison to filter by.
 * @returns The filtered zones.
 */
export function filterZonesByType<T extends BaseZoneFragment>(zones: T[], zoneType: ZoneType, zoneDir: ZoneFilterDirection){
    if (!zones || !zoneType) { return; };
    switch(zoneDir){
        case 'eq':
            return zones.filter((z) => getZoneOrder(z) === getZoneOrderByType(zoneType));
        case 'gt':
            return zones.filter((z) => getZoneOrder(z) > getZoneOrderByType(zoneType));
        case 'gte':
            return zones.filter((z) => getZoneOrder(z) >= getZoneOrderByType(zoneType));
        case 'lt':
            return zones.filter((z) => getZoneOrder(z) < getZoneOrderByType(zoneType));
        case 'lte':
            return zones.filter((z) => getZoneOrder(z) <= getZoneOrderByType(zoneType));
    };
}

export function getZoneOrder(zone: BaseZoneFragment): number {
  return ZONE_ORDER.indexOf(ZONE_TYPES[zone.type]);
}

export function getZoneOrderByType(zoneType: ZoneType): number {
  return ZONE_ORDER.indexOf(zoneType);
}

export function orderZonesByType<T extends BaseZoneFragment>(zones: T[]): T[] {
    enum ZoneTypeValues {
        'root' = 0,
        'corporate' = 1,
        'franchise' = 2,
        'area' = 3,
    }
    return zones.sort((a, b) => ZoneTypeValues[a.type] - ZoneTypeValues[b.type] );
}




export interface ZoneTreeBranch<Z extends BaseZoneWithParentFragment> {
	zone: Z;
	children: ZoneTreeBranch<Z>[];
	parent?: ZoneTreeBranch<Z>;
	// not used in generateZoneTree
	depth?: number;
}

/**
 * Given an array of zones, makes a tree from those zones and returns
 * only the orphans. Children of those orphans may then be accessed.
 *
 * Complexity is O(n)
 *
 * @param zones flat array of zones
 * @returns orphan zone tree branches
 */
export function generateZoneTree<Z extends BaseZoneWithParentFragment>(zones: Z[]) {

	const branches: { [ id: string ]: ZoneTreeBranch<Z> } = {};
	// collect all zones into zone branch map O(n)
	for (const zone of zones) {
		branches[zone.id] = {
			zone,
			children: [],
		};
	}


	// set branch parent to object in branches O(n)
	for (const branch of Object.values(branches)) {
		if (!branch?.zone?.parent?.id) { continue; }
		branch.parent = branches[branch.zone.parent.id];
		if (branch.parent) {
			branch.parent.children.push(branch);
		}
	}

	// return orphans only (tree root nodes)
	return Object.values(branches).filter((b) => !b.parent);
}

/**
 * Finds a branch in a given zone tree by ID.
 *
 * @param zoneTree
 * @param zoneId
 * @returns The branch of the provided tree whose zone matches the provided ID.
 */
export function findBranchById<Z extends BaseZoneWithParentFragment>(zoneTree: ZoneTreeBranch<Z>[], zoneId: string): ZoneTreeBranch<Z> {
	for (const branch of zoneTree) {
		if (branch.zone.id === zoneId) {
			return branch;
		} else if (branch.children) {

			const found = findBranchById(branch.children, zoneId);

			if (found) {
				return found;
			}

		}
	}
}

/**
 * Sorts zones by depth, with the deepest zones at the top
 *
 * @param zones
 * @returns Array of ZoneTreeBranch
 */
export function sortZonesByDepth<T extends BaseZoneWithParentFragment>(
	zones: T[],
	orderAscending = false,
) {
	const map: {
		[ id: string ]: ZoneTreeBranch<T>;
	} = {};
	for (const zone of zones) {
		map[zone.id] = {
			zone,
			children: [],
		};
	}

	for (const zone of Object.values(map)) {
		if (zone.depth !== undefined) { continue; }
		let current = zone;
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		for (zone.depth = 1; zone.depth < 16; zone.depth++) {
			current.parent = map[current.zone.parent.id];

			if (!current.parent) {
				current.depth = 1;
				break;
			}

			if (current.parent.depth) {
				zone.depth += current.parent.depth;
				break;
			}

			current = current.parent;
		}
	}

	return Object.values(map).sort((a, b) => {
		if (a.depth < b.depth) { return orderAscending ? -1 : 1; }
		if (a.depth > b.depth) { return orderAscending ? 1 : -1; }
		return 0;
	}).map((z) => z.zone);
}


export function resolveChildrenFromID<Z extends BaseZoneWithParentFragment>(
	zones: Z[],
	currentZoneId: string,
	maxDepth = 16,
) {
	const children: Z[] = [];

	for (const zone of zones) {
		if (zone.parent?.id !== currentZoneId) { continue; }

		children.push(zone);
		if (maxDepth > 0) {
			// Recursion alert: resolve children of children
			children.push(...resolveChildrenFromID(zones, zone.id, maxDepth - 1));
		}
	}

	return children;
}
