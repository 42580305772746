import {FetchPolicy, QueryOptions, WatchQueryFetchPolicy, WatchQueryOptions} from '@apollo/client/core';


export interface KarveRequestOptions {
    zone?: string;
    fetchPolicy?: FetchPolicy;
}

export interface KarveWatchRequestOptions {
    zone?: string;
    fetchPolicy?: WatchQueryFetchPolicy;
}

type OutputType<T> = T extends KarveRequestOptions ? Partial<QueryOptions<any>> : Partial<WatchQueryOptions<any>>;

export function parseRequestOptions<T extends KarveRequestOptions | KarveWatchRequestOptions>(
    opts?: T
): OutputType<T>  {

    const headers = {};
    if (opts?.zone) {
        headers['x-zone'] = opts.zone;
    }

    return {
        context: {
            headers,
        },
        fetchPolicy: opts?.fetchPolicy as any,
    } as any;
}
