/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { AutoComplete, AutoCompleteSelectEvent } from 'primeng/autocomplete';

import { OBJECT_ICON_MAP } from 'src/app/global.constants';
import { SubSink } from 'subsink';

import { GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SearchGQL, SearchNode, SearchQuery } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  @ViewChild(AutoComplete) autoComplete: AutoComplete;

  @Input() disabled = false;

  subs = new SubSink();

  results: SearchNode[] = [];
  query = '';

  icons = OBJECT_ICON_MAP;

  detailTypes = {
    Job: 'job',
    Location: 'location',
    ReportType: 'reportType',
  };

  resultPath = {
    Job: ['job'],
    ReportType: ['reports', 'type'],
  };

  allowCrossZoneQuery = false;

  getConfigValuesQuery: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  private searchWatchQuery: QueryRef<SearchQuery>;

  constructor(
    private searchGQL: SearchGQL,
    private detailsHelper: DetailsHelperService,
    private getConfigValuesGQL: GetConfigValuesGQL,
  ) { }

  @HostListener('document:keydown./', [ '$event' ])
  setFocus() {
    const active = document.activeElement;
    const ignoreNodes = [ 'INPUT', 'TEXTAREA' ];
    if (ignoreNodes.includes(active.nodeName)) {
      return true;
    }

    this.autoComplete.inputEL.nativeElement.focus();
    this.autoComplete.inputEL.nativeElement.select();
    return false;
  }

  @HostListener('document:keydown.escape', [ '$event' ])
  blur() {

    const el = this.autoComplete.el.nativeElement;

    if (!el) { return; }

    const [ input ] = el.getElementsByTagName('input');

    if (!input) { return; }

    if (input === document.activeElement) {
      input.blur();
    }
  }

  ngOnInit(): void {
    this.checkAllowCrossZoneQuery();
    // TODO LIST
    // Open items when selected
    // Search icon / loading
    // enter to open the selected index
    // arrow keys to select item
    // slash key to start searching
    // ESC to clear search
    // Click away to close search
    // Focus to re-open search
    // Show recent items at top of search when no search is being made
    // show no items found
    // Show icons for different items
    // Navigate to clear search
  }

  ngOnDestroy() {
      this.subs.unsubscribe();
      delete this.searchWatchQuery;
  }

  checkAllowCrossZoneQuery() {
    if (this.getConfigValuesQuery) {
      this.getConfigValuesQuery.refetch();
      return;
    }

    this.getConfigValuesQuery = this.getConfigValuesGQL.watch({ keys: [ 'search.allowCrossZoneQuery' ]});

    this.subs.sink = this.getConfigValuesQuery.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      const [ config ] = res.data.getConfigValues;

      this.allowCrossZoneQuery = config?.value === 'true';
    });
  }

  search() {

    if (!this.query) {
      this.results = [];
      return;
    }

    const variables = {
      search: this.query,
    };

    if (this.searchWatchQuery) {
      this.searchWatchQuery.setVariables(variables);
      return;
    }

    this.searchWatchQuery = this.searchGQL.watch(variables, {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.searchWatchQuery.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      this.results = cloneDeep(res.data.search.results);
    });
  }

  select(result: AutoCompleteSelectEvent) {
    const {label, objectID} = result.value;

    if(!label || !objectID) {
      console.error('No label or objectID found in result', result);
      return;
    }

    const detailType = this.detailTypes[label];

    // const zoneId = (this.allowCrossZoneQuery) ? result?.zoneId : undefined;
    const zoneId = undefined;
    this.detailsHelper.open(detailType, {
      id: objectID,
    }, false, zoneId);
  }
}
