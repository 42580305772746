import { gql } from 'graphql-tag';

import { commentsBaseGQL } from './comments';
import { attributesGQL, locationBaseGQL, ZoneDirection } from './common.gql';
import { historyBaseGQL } from './history';
import { accessesBaseGQL } from './shareAccess';

export function stringifyLocation(location: Location): string {
  if (location.name) { return location.name; }

  return [
    location.addressLineOne,
    location.addressLineTwo,
    location.city,
    location.countryJurisdiction,
    location.country,
    location.areaCode,
  ].filter((a) => a).join(', ');

}

export interface CreateLocation {
  userId: string;
  city: string;
  country: string;
  areaCode: string;
  addressLineOne: string;
  addressLineTwo: string;
}

export interface GetLocationsOutput {
  locations: {
    locations: Location[];
  };
}

export const getLocationsQuery = gql`
query locations($users: [String]){
  locations(filter: {users: $users}){
    locations{
      id
      city
      public
      areaCode
      country
      addressLineOne
      addressLineTwo
      attributes
      name
    }
  }
}
`;

// export interface CreateLocationsOutput {
//   createLocations: {
//     locations: Location[];
//   };
// }

export const createLocationMutation = gql`
mutation createLocation($userId: String, $city: String, $areaCode: String, $addressLineOne: String!,
                        $addressLineTwo: String,$country: String){
  createLocations(input: {  owner: $userId,locations:[{city: $city,
                                     areaCode: $areaCode,
                                     addressLineOne: $addressLineOne,
                                     addressLineTwo: $addressLineTwo,
                                     country: $country,
                                     public: false}]}){
    locations{
      id
      city
      public
      areaCode
      country
      addressLineOne
      addressLineTwo
      attributes
      name
    }
  }
}
`;



// NEW VERSION
export interface CreateLocationsBase {
  individualCapacity?: number;
  name?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  areaCode?: string;
  city?: string;
  countryJurisdiction?: string;
  country?: string;
  minTime?: number;
  maxTime?: number;
  assets?: string[];
  costPerHour?: number;
  appointmentCapacity?: number;
  attributes?: string[];
  public?: boolean;
}

export interface CreateLocationsInput {
  owner?: string;
  locations: CreateLocationsBase[];
}

export const deleteLocationsMutation = gql`
mutation deleteLocations($ids: [String]!){
  removeLocations(ids: $ids){
    total
  }
}
`;

export const addSubLocation = gql`
  mutation addLocations($parentId: String!, $subLocations: [String!]!){
    addSubLocations(parentId: $parentId, subLocations: $subLocations){
      total
    }
  }
`;

export const removeSubLocation = gql`
  mutation removeLocations($parentId: String!, $subLocations: [String!]!){
    removeSubLocations(parentId: $parentId, subLocations: $subLocations){
      total
    }
  }
`;


export interface Location {
  id: string;
  individualCapacity?: number;
  addressLineOne: string;
  addressLineTwo?: string;
  name?: string;
  areaCode?: string;
  city?: string;
  countryJurisdiction?: string;
  country?: string;
  minTime?: number;
  maxTime?: number;
  assets?: string[];
  costPerHour?: number;
  appointmentCapacity?: number;
  parentId?: string;
  subLocations: Location[];
  parents?: string[];
  attributes?: string[];
  public?: boolean;
  users?: string[];
  coordinates: Coordinates;
}

export interface LocationsFilter extends ZoneDirection{
  names?: string[];
  minCapacity?: number;
  maxCapacity?: number;
  address?: string;
  areaCode?: string;
  city?: string;
  countryJurisdiction?: string;
  country?: string;
  minTime?: number;
  maxTime?: number;
  public?: boolean;
  assets?: string[];
  minCostPerHour?: number;
  maxCostPerHour?: number;
  minAppointmentCapacity?: number;
  maxAppointmentCapacity?: number;
  parentId?: string;
  /** @deprecated use hasAll/hasAnyAttributes*/
  attributes?: string[];
  hasAllAttributes?: string[];
  hasAnyAttributes?: string[];
  ids?: string[];
  skip?: number;
  limit?: number;
  isSoftDeleted?: boolean;
  scope?: string;
  users?: string[];
  getUsers?: boolean;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
  height?: number;
}

export interface CoordinateInput{
  latitude: number;
  longitude: number;
  height?: number;
}

export interface LocationCreateBase {
  individualCapacity?: number;
  name?: string;
  addressLineOne: string;
  addressLineTwo?: string;
  areaCode?: string;
  city?: string;
  countryJurisdiction?: string;
  country?: string;
  minTime?: number;
  maxTime?: number;
  assets?: string[];
  costPerHour?: number;
  appointmentCapacity?: number;
  attributes?: string[];
  public?: boolean;
  coordinates?: CoordinateInput;
}

export interface LocationsCreateInput {
  locations: LocationCreateBase[];
  owner?: string;
}

export interface LocationsEditInput {
  name?: string;
  individualCapacity?: number;
  addressLineOne?: string;
  addressLineTwo?: string;
  areaCode?: string;
  city?: string;
  countryJurisdiction?: string;
  country?: string;
  minTime?: number;
  maxTime?: number;
  assets?: string[];
  costPerHour?: number;
  appointmentCapacity?: number;
  attributes?: string[];
}

export interface LocationPagination {
  total?: number;
  skipped?: number;
  limited?: number;
  locations: Location[];
}

export interface LocationEditOutput {
  total?: number;
  editedLocations: Location[];
}

export interface LocationDeleteOutput {
  total?: number;
  removedLocations: string[];
}

export interface SubLocationEditOutput {
  total?: number;
  subLocations: string[];
  parent?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface setUserLocationsOutput {
  userId: string;
  locationsAdded?: string[];
  locationsRemoved?: string[];
}


export interface ListLocationsInput {
  filter?: LocationsFilter;
}

export interface ListLocationsOutput {
  locations: LocationPagination;
}

export const locationAppointmentInfoGQL = `
  minTime
  maxTime
  assets
  costPerHour
  appointmentCapacity
`;

export const locationsGQL = `
  locations {
    ${locationBaseGQL}
  }
`;

export const locationSubLocationsGQL = `
  subLocations {
    ${locationBaseGQL}
  }
`;

export const locationsCommentsGQL = `
  comments {
    comments {
      ${commentsBaseGQL}
    }
  }
`;

export const locationAccessesGQL = `
  accesses {
    accesses {
      ${accessesBaseGQL}
    }
  }
`;

export const locationHistoryGQL = `
  history {
    history {
      ${historyBaseGQL}
    }
  }
`;

export const locationCoordinatesGQL = `
  coordinates{
    latitude
    longitude
    height
  }
`;

export interface GenerateLocationQueryInput {
  appointmentInfo?: boolean;
  attributes?: boolean;
  subLocations?: boolean;
  comments?: boolean;
  accesses?: boolean;
  history?: boolean;
  coordinates?: boolean;
}

export function generateLocationQuery(input: GenerateLocationQueryInput) {
  const query = gql`
    query locations($filter: LocationsFilter, ){ 
		locations(filter: $filter, ){ 
      total
      skipped
      limited
      locations {
        ${locationBaseGQL}
        ${input.appointmentInfo ? locationAppointmentInfoGQL : ``}
        ${input.subLocations ? locationSubLocationsGQL : ``}
        ${input.comments ? locationsCommentsGQL : ``}
        ${input.accesses ? locationAccessesGQL : ``}
        ${input.history ? locationHistoryGQL : ``}
        ${input.attributes? attributesGQL : ``}
        ${input.coordinates ? locationCoordinatesGQL : ``}
      }
    }
  }
  `;

  return query;
}

export const locationsQuery = gql`
	query locations($filter: LocationsFilter, ){ 
		locations(filter: $filter, ){ 
      total
      skipped
      limited
      locations{
        id
          individualCapacity
          addressLineOne
          addressLineTwo
          name
          areaCode
          city
          countryJurisdiction
          country
          minTime
          maxTime
          assets
          costPerHour
          parentId
          subLocations {
            id
            addressLineOne
            addressLineTwo
            name
            areaCode
            city
            country
            countryJurisdiction
          }
          parents
          attributes
          public
          attributes
          users
      }			 
    }
  }
`;
export interface CreateLocationsFunctionInput {
  input?: LocationsCreateInput;
}

export interface CreateLocationsOutput {
  createLocations: LocationPagination;
}

export const createLocationsMutation = gql`
	mutation createLocations($input: LocationsCreateInput!, ){ 
		createLocations(input: $input, ){ 
      total
      skipped
      limited
      locations{
        id
        individualCapacity
        addressLineOne
        addressLineTwo
        name
        areaCode
        city
        countryJurisdiction
        country
        minTime
        maxTime
        assets
        costPerHour
        parentId
      }			 
    }
  }
`;
export interface EditLocationsInput {
  ids?: string[];
  edit?: LocationsEditInput;
}

export interface EditLocationsOutput {
  editLocations: LocationEditOutput;
}

export const editLocationsMutation = gql`
mutation editLocations($ids: [String]!, $edit: LocationsEditInput, ){ 
		editLocations(ids: $ids,  edit: $edit, ){ 
      total
      editedLocations{ 
        id
        individualCapacity
        addressLineOne
        addressLineTwo
        name
        areaCode
        city
        countryJurisdiction
        country
        minTime
        maxTime
        assets
        costPerHour
        parentId
      }			 
    }
  }
`;
export interface RemoveLocationsInput {
  ids?: string[];
}

export interface RemoveLocationsOutput {
  removeLocations: LocationDeleteOutput;
}

export const removeLocationsMutation = gql`
	mutation removeLocations($ids: [String]!, ){ 
		removeLocations(ids: $ids, ){ 
      total
      removedLocations
    }
  }
`;
export interface AddSubLocationsInput {
  parentId?: string;
  subLocations?: string[];
}

export interface AddSubLocationsOutput {
  addSubLocations: SubLocationEditOutput;
}

export const addSubLocationsMutation = gql`
	mutation addSubLocations($parentId: String!, $subLocations: [String!]!, ){ 
		addSubLocations(parentId: $parentId,  subLocations: $subLocations, ){ 
      total
      subLocations
      parent
    }
  }
`;
export interface RemoveSubLocationsInput {
  parentId?: string;
  subLocations?: string[];
}

export interface RemoveSubLocationsOutput {
  removeSubLocations: SubLocationEditOutput;
}

export const removeSubLocationsMutation = gql`
	mutation removeSubLocations($parentId: String!, $subLocations: [String!]!, ){ 
		removeSubLocations(parentId: $parentId,  subLocations: $subLocations, ){ 
      total
      subLocations
      parent  
    }
  }
`;
export interface SetUserLocationsInput {
  userId?: string;
  addLocations?: string[];
  removeLocations?: string[];
}

export interface SetUserLocationsOutput {
  setUserLocations: setUserLocationsOutput;
}

export const setUserLocationsMutation = gql`
	mutation setUserLocations($userId: String!, $addLocations: [String], $removeLocations: [String], ){ 
		setUserLocations(userId: $userId,  addLocations: $addLocations,  removeLocations: $removeLocations, ){ 
      userId
      locationsAdded
      locationsRemoved        
    }
  }
`;
