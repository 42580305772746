import { gql } from 'graphql-tag';

import { User } from './auth';
import { Zone } from './zones';

export interface ListZonesOutput{
  limited: number;
  skipped: number;
  total: number;
  nodes: Zone[];
}

export interface SkillPagination {
  total?: number;
  skipped?: number;
  limit?: number;
  skills?: Skill[];
}


export interface SetUserSkillsOutput {
  setUsersSkills: boolean;
}

export interface RetrieveUserSkillsOutput {
  users: [{
    skills: [Skill];
  }];
}

export interface RetrieveAllSkillsOutput {
  skills: {
    skills: [Skill];
  };
}

export interface Skill {
  id: string;
  name: string;
  shortName: string;
  description: string;
  skillType: string;
  ratingType: string;
  attributes: [string];
  createdAt: Date;
  updatedAt: Date;
  deletedOn: Date;
  skillDetails: SkillDetails;
  prerequisites: [Skill];
  postrequisites: [Skill];
  equivalents: [Skill];
  equivalentOf: [Skill];
  users: UserSkillOutput[];
  zones: ListZonesOutput;
}

export interface UserSkillOutput{
    addedOn: number;
    updatedOn: number;
    attributes: string[];
    rating: number;
    user: User;
    userId: string;
    form: string;
}


export interface SkillDetails{
  addedOn: number;
  updatedOn: number;
  attributes: string[];
  rating: number;
  form: string;
}

export interface SkillFilter {
  ids?: [string];
  name?: string;
  search?: string;
  shortName?: string;
  description?: string;
  skillType?: string;
  ratingType?: string;
  containsAttributes?: [string];
  hasAttributes?: [string];
  isDeleted?: boolean;
  sortBy?: string;
  getLinked?: boolean;
  getDeepLinked?: boolean;
  getUsers?: SkillUserFilter;
  heldByUsers?: boolean;
  }

  export interface SkillUserFilter {
    minRating?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MaxRating?: number;
    minExclusiveRating?: number;
    maxExclusiveRating?: number;
    userIds?: string[];
    userIdsIntersectional?: boolean;
    forms?: string[];
    formsIntersectional?: boolean;
    skip?: number;
    limit?: number;
    }

  export interface CreateSkillsInput {
    skills: [{
      name: string;
      shortName?: string;
      description?: string;
      skillType: string;
      ratingType?: string;
      attributes?: string[];
      links: SkillLinkInput;
      }];
  }

  export interface SkillLinkInput {
    prerequisites: string[];
    postrequisites: string[];
    equivalents: string[];
    equivalentOf: string[];
  }

  export interface EditSkillBase{
      name?: string;
      shortName?: string;
      description?: string;
      skillType?: string;
      ratingType?: string;
      attributes?: string[];
    }



export const retrieveUserSkills = gql`
    query userSkills($userIds: [String!]) {
        users(filter: {userIds: $userIds}){
          skills{
            id
            name
            shortName
            skillDetails{
              rating
            }
            skillType
            description
            attributes
          }
        }
      }
`;

export const getAllSkills = gql`
  query Skills($skillFilter: SkillFilter, $skip: Int, $limit: Int) {
    skills(filter: $skillFilter, limit: $limit, skip: $skip){
    skills{
      id
      name
      shortName
      description
      skillType
      ratingType
      attributes
      zones {
      	nodes{
          name
        }
      }
      createdAt {
        date
        time
        fullString
        unix
      }
      updatedAt {
        date
        time
        fullString
        unix
      }
      deletedOn {
        date
        time
        fullString
        unix
      }
      skillDetails {
        addedOn
        updatedOn
        attributes
        rating
        form
      }
      prerequisites {
        id
        name
        shortName
        description
        skillType
      }
      postrequisites {
        id
        name
        shortName
        description
        skillType
      }
      equivalents {
        id
        name
        shortName
        description
        skillType
      }
      equivalentOf {
        id
        name
        shortName
        description
        skillType
      }
      users {
        form
        addedOn
        updatedOn
        attributes
        rating
        userId
        user {
          id
          givenName
          familyName
        }
      }
    }
    }
  }
`;

export const setUsersSkills = gql`
  mutation addSkills($user: String!, $skills: [AddUserSkillsInput!], $remove: [String!]) {
	setUsersSkills(input: { edits:[{userId: $user, addSkills:$skills, removeSkills:$remove}]})
}
`;

export const removeSkills = gql`
  mutation remove($skills: [String!]!) {
  removeSkills(ids: $skills, removeRelations: true){
    total
  }
}
`;

export interface CreateSkillsOutput {
  createSkills: SkillPagination;
}

export const createSkills = gql`
  mutation createSkills($input: CreateSkills!){
    createSkills(input: $input){
      total
      skipped
      limit
      skills {
        id
        name
        shortName
        description
        skillType
      }
    }
  }
`;

export interface DeleteSkillsOutput{
  removeSkills: EditSkillPagination;
}

export const deleteSkills = gql`
  mutation deleteSkills($ids: [String!]!,$removeRelations: Boolean){
  removeSkills(ids: $ids, removeRelations: $removeRelations){
    total
    ids
  }
}
`;

export interface EditSkillPagination {
  total: number;
  ids: string[];
}

export interface EditSkillsOutput {
  editSkills: EditSkillPagination;
}

export interface LinkResult {
  isSuccess: boolean;
}

export const editSkills = gql`
  mutation editSkills($ids: [String!]!, $edit: EditSkillBase!){
    editSkills(ids: $ids, edit: $edit){
      total
      ids
    }
  }
`;

export interface LinkSkillsOutput {
  linkSkills: LinkResult;
}

export const linkSkills = gql`
  mutation skillreq($base: String!, $related: [String!]!, $type: String!){
    linkSkills(baseSkill: $base, relatedSkills: $related, relationshipType: $type){
      isSuccess
    }
  }
`;

export interface UnLinkSkillSOutput {
  unlinkSkills: LinkResult;
}

export const unlinkSkills = gql`
  mutation unskillreq($base: String!, $related: [String!]!){
    unlinkSkills(baseSkill: $base, relatedSkills: $related){
    isSuccess
  }
  }
`;
