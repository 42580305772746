import { BaseFieldFragment, CreateUserMutationVariables, EditProfileMutationVariables, Field, FindQueryVariables, FullJobFragment, FullUserFragment, User } from "graphql.generated";
import { cloneDeep, isEmpty, isEqual, isNull, isUndefined, pickBy } from "lodash";

import { Inventory } from "../estimates/estimate.interfaces";
import { ADDABLE_LOCATIONS_V2, DEFAULT_INVENTORY, JOB_CATEGORIES, JOB_ROLE_MAP, eventTypeInfoMapV2, locationsFieldsArray } from "../global.constants";

import { EditCustomerInputWithFullName, FullJobFragmentWithFields, JobChange, JobInputWithSummaries, JobToolFieldsV2, LocationWithHowSetIndicator, ResolvedServiceArea, Summary } from "./job-tool.reducer";
import { DistanceLocation } from "./jobv2-create/jobv2-interfaces";

export type GetRoutesInput = {
  dock?: DistanceLocation,
  start?: DistanceLocation,
  end?: DistanceLocation,
}

export function getRoutesToCalculateV2(locations: GetRoutesInput) {
  const {
    start,
    end,
    dock,
  } = locations;

  const routes: { [type: string]: DistanceLocation[] } = {
    start_end: [start, end],
    dock_start_end_dock: [dock, start, end, dock],
    dock_start: [dock, start],
    start_dock: [start, dock],
    end_dock: [end, dock],
  };

  // Remove routes where a location is undefined or address is undefined
  for (const key in routes) {
    if (routes[key].some(location => location === undefined || location.address === undefined)) {
      delete routes[key];
    }
  }

  return routes;
};

export function convertLocationToDistanceLocationV2(location: LocationWithHowSetIndicator): DistanceLocation {
  return {
    ...location,
    address: location?.addressLineOne,
  } as DistanceLocation;
};

export function getFindQueryVariablesV2(
  formattedDate: string,
  totalTime: number,
  eventType: string,
  zoneId?: string,
): FindQueryVariables {
  const queryVariables: FindQueryVariables = {
    timeWindow: {
      startDate: formattedDate,
      endDate: formattedDate,
      minWindowLength: totalTime,
    },
    minNumAssets: 1,
    assetsFilter: {
      types: eventTypeInfoMapV2[eventType].assetTypes,
    },
    overrideUnavailabilities: false,
  };

  // Add zoneInput only if zoneId is defined
  if (zoneId !== undefined) {
    queryVariables.zoneInput = zoneId;
  }

  return queryVariables;
}

//generate full name string from givenName and familyName fields
export function generateFullCustomerName(customer: Partial<FullUserFragment>): string {
  let fullUserName = '';

  if (customer.givenName) {
    fullUserName += customer.givenName;
  }

  if (customer.familyName) {
    if (fullUserName) {
      fullUserName += ' ';
    }
    fullUserName += customer.familyName;
  }

  return fullUserName;
}

//parse full name string to givenName and familyName
export function parseFullName(fullName?: string): { givenName: string; familyName?: string } | undefined {
  if (!fullName) {
    return undefined; // Handle the case where fullName is undefined or null
  }

  const names = fullName.trim().split(/\s+/);

  if (names.length > 1) {
    return {
      givenName: names[0],
      familyName: names.slice(1).join(' ') // Combine all parts after the first name
    };
  } else {
    return { givenName: names[0], familyName: '' };
  }
}

export function getValidationPattern(fieldName: string): string | null {
  if (fieldName === 'phone') {
    return '\\d{10}';
  } else if (fieldName === 'email') {
    return '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}';
  }
  return null;
}

export const validateEmail = (email: string, validationErrors: { [key: string]: string }): { [key: string]: string } => {
  const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!EMAIL_PATTERN.test(email)) {
    return { ...validationErrors, email: 'Invalid email format' };
  } else {
    const { email, ...rest } = validationErrors;
    return rest;
  }
};

export const validatePhone = (phone: string, validationErrors: { [key: string]: string }): { [key: string]: string } => {
  const PHONE_PATTERN = /^\d{10}$/;
  if (!PHONE_PATTERN.test(phone)) {
    return { ...validationErrors, phone: 'Invalid phone number format' };
  } else {
    const { phone, ...rest } = validationErrors;
    return rest;
  }
};

export const extractFieldsFromResponse = (fields: Partial<Field>[]): Partial<BaseFieldFragment>[] => {
  const result = fields.map(field => ({
    id: field.id,
    name: field.name,
    type: field.type,
    values: field.values?.map(value => ({
      objectId: value.objectId,
      value: value.value,
      isDefault: value.isDefault,
    }))
  }));
  return result;
};

export function hasValueV2(field: Partial<BaseFieldFragment>): boolean {
  return field?.values[0]?.value !== undefined && field?.values[0]?.value !== null;
}

export const populateFieldsFromResponse = (fields: Partial<BaseFieldFragment>[]): JobToolFieldsV2 => {
  const fieldsResult: JobToolFieldsV2 = {};

  if (!fields?.length) {
    return fieldsResult;
  }

  for (const field of fields) {
    if (hasValueV2(field)) {
      fieldsResult[field?.name] = field?.values[0].value;
    }
  }

  return fieldsResult;
}

export const populateJobInputFromResponse = (job: FullJobFragmentWithFields) => {
  if (!isEmpty(job)) {
    const jobInput: JobInputWithSummaries = {
      id: job?.id,
      jobCategory: job?.jobCategory,
      metadata: job?.metadata,
      code: job?.code,
      currency: job?.currency,
      stage: job?.stage,
      state: job?.state,
      tags: job?.tags as any,
      timeline: job?.timeline,
      timelineDays: job?.timelineDays,
      adminSummary: parseContents(job?.adminSummary),
      crewSummary: parseContents(job?.crewSummary),
      customerSummary: parseContents(job?.customerSummary),
    };
    return jobInput;
  } return {};
}


export const populateCustomerInfoFromResponse = (job: FullJobFragmentWithFields) => {
  const customer = job?.users?.find(user => user?.role === JOB_ROLE_MAP.customerRole)?.user;
  let fullName = '';
  if (customer?.givenName) {
    fullName += customer?.givenName;
  }
  if (customer.familyName) {
    fullName += ` ${customer.familyName}`;
  }
  if (!isEmpty(customer)) {
    const customerInput: EditCustomerInputWithFullName = {
      id: customer?.id,
      email: customer?.email,
      phone: customer?.phone,
      fullName: fullName,
      company: customer?.company,
    }
    return customerInput;
  } return {};
}

export const populateLocationsInputsFromResponse = (job: FullJobFragment) => {
  const locationsInputs: LocationWithHowSetIndicator = {};
  if (job?.locations?.length) {
    for (const location of job?.locations) {
      locationsInputs[location?.locationType] = location?.location;
    }
  }
  return locationsInputs;
}

export const populateStartTimeFromResponse = (job: FullJobFragmentWithFields) => {
  let result = [];

  if (job?.events?.length) {
    result = job?.events
      .filter((event) => event?.type === 'moving')
      .sort((event) => event?.start);
  }

  const startTime = result[0]?.start;
  return {
    startTime,
  };
}

export const populateResolvedServiceAreaFromResponse = (job: FullJobFragmentWithFields) => {
  if (job?.zone?.type === 'area') {
    return {
      name: job?.zone?.name,
      id: job?.zone?.id
    };
  } else return undefined;
}

export const populateCalculateDistancesEditInput = (job: FullJobFragmentWithFields) => {

  const result: { [key: string]: any } = {};

  job.locations.forEach(locationItem => {
    const { locationType, location } = locationItem;

    if (locationType === 'start' || locationType === 'end' || locationType === 'dock') {
      result[locationType] = location;
    }
  });

  return result;
}

export const generateStateForUpdateJob = (
  jobInfo: FullJobFragmentWithFields) => {
  const generatedInput = {};
  const fieldsWithoutInventory = jobInfo?.fields.filter(field => field.name !== 'jobs.inventory');
  const inventoryField = jobInfo?.fields.filter(field => field.name === 'jobs.inventory');
  const fieldsInput = populateFieldsFromResponse(fieldsWithoutInventory);
  const inventoryInput = populateFieldsFromResponse(inventoryField);
  const jobInput = populateJobInputFromResponse(jobInfo);
  const customerInput = populateCustomerInfoFromResponse(jobInfo);
  const locationsInputs = populateLocationsInputsFromResponse(jobInfo);
  const time = populateStartTimeFromResponse(jobInfo)?.startTime;
  const resolvedServiceArea = populateResolvedServiceAreaFromResponse(jobInfo);

  let date;
  let formattedDate;

  if (time) {
    date = new Date(time * 1000);
    formattedDate = date?.toISOString()?.split('T')[0];
  }

  generatedInput['jobInput'] = jobInput;
  generatedInput['customerInput'] = customerInput;
  generatedInput['fieldsInput'] = fieldsInput;
  generatedInput['locationsInputs'] = locationsInputs;
  generatedInput['inventoryInput'] = inventoryInput['jobs.inventory'];
  generatedInput['resolvedServiceArea'] = resolvedServiceArea;

  //if job has moving event, use it to determine moving date and start time in edit form
  //it can be edited only if event in requires status
  if (!isUndefined(time) && !isNull(time)) {
    generatedInput['selectedTimeSlot'] = time;
    generatedInput['jobInput']['timeline'] = formattedDate;
  }

  return generatedInput;
}

export function findUpdatedCustomerFields(
  initial: EditCustomerInputWithFullName | undefined,
  current: EditCustomerInputWithFullName | undefined
): EditCustomerInputWithFullName | undefined {
  if (!initial || !current) {
    return undefined;
  }

  const updatedFields: Partial<EditCustomerInputWithFullName> = { id: current.id };

  Object.keys(current).forEach((key) => {
    if (current[key] !== initial[key]) {
      updatedFields[key] = current[key];
    }
  });

  return Object.keys(updatedFields).length > 1 ? updatedFields as EditCustomerInputWithFullName : undefined;
}

export function findUpdatedJobFields(
  initial: JobInputWithSummaries,
  current: JobInputWithSummaries
) {
  if (!initial || !current) {
    return undefined;
  }

  const updatedFields: JobInputWithSummaries & { jobId: string } = { jobId: current?.id };

  const fieldsToCompare: (keyof JobInputWithSummaries)[] =
    ['adminSummary', 'crewSummary', 'customerSummary', 'jobCategory', 'metadata', 'timeline'];

  fieldsToCompare.forEach((field: any) => {
    if (field === 'metadata') {
      if (!isEqual(initial.metadata, current.metadata)) {
        updatedFields.metadata = current.metadata;
      }
    } else if (current[field] !== initial[field]) {
      updatedFields[field] = current[field];
    }
  });

  return Object.keys(updatedFields).length > 1 ? updatedFields : undefined;
}

export function findUpdatedLocations(
  initialLocations: LocationWithHowSetIndicator,
  currentLocations: LocationWithHowSetIndicator
): { [key: string]: any } | undefined {
  if (!initialLocations && !currentLocations) {
    return undefined;
  }

  const updatedLocations: LocationWithHowSetIndicator = {};

  for (const locationType in currentLocations) {
    const currentLocation = currentLocations[locationType];
    const initialLocation = initialLocations ? initialLocations[locationType] : undefined;

    if (!initialLocation || currentLocation.id !== initialLocation.id) {
      updatedLocations[locationType] = currentLocation;
    }
  }

  return Object.keys(updatedLocations).length > 0 ? updatedLocations : undefined;
}

export function findUpdatedFields(
  initialFields: JobToolFieldsV2 | object,
  currentFields: JobToolFieldsV2 | object
): JobToolFieldsV2 | object {
  if (!initialFields && !currentFields) {
    return undefined;
  }

  const updatedFields: JobToolFieldsV2 = {};

  for (const fieldKey in currentFields) {
    const currentField = currentFields[fieldKey];

    const initialField = initialFields ? initialFields[fieldKey] : undefined;


    if (
      !initialField ||
      !initialField.values ||
      !currentField.values ||
      !isEqual(currentField.values[0]?.value, initialField.values[0]?.value)
    ) {
      updatedFields[fieldKey] = currentField;
    }
  }

  return Object.keys(updatedFields).length > 0 ? updatedFields : undefined;
}

export function findUpdatedInventory(
  initialInventory: Inventory,
  currentInventory: Inventory
) {
  if (!initialInventory && !currentInventory) {
    return undefined;
  }

  if (
    !initialInventory ||
    !isEqual(initialInventory.totalItems, currentInventory?.totalItems) ||
    !isEqual(initialInventory.totalVolume, currentInventory?.totalVolume) ||
    !isEqual(initialInventory.totalWeight, currentInventory?.totalWeight)
  ) {
    return currentInventory;
  }

  return undefined;
}

export function trackChanges(
  changes: JobChange[],
  change: JobChange,
) {
  const updatedChanges = [change, ...changes];
  return updatedChanges;
}

export const defaultJobState = {
  //inputs
  customerInput: undefined,
  jobInput: {
    jobCategory: JOB_CATEGORIES[0],
    metadata: {},
  },
  inventoryInput: cloneDeep(DEFAULT_INVENTORY),
  locationsInputs: undefined,
  fieldsInput: {},

  //used to calculate final copy
  changes: [],

  //validation
  validationErrors: {},

  //calculated data
  addableAdditionalLocations: [...ADDABLE_LOCATIONS_V2],
  distances: {},
  jobTiming: {
    moving: {
      totalLocationTime: 0,
      totalTime: 0,
    }
  },
  selectedTimeSlot: undefined,
  editCustomerWhenCreateJobMode: false,
  closedReason: undefined,

  //loaded data based on user inputs
  resolvedServiceArea: undefined,
  availableTimeSlots: [],
  jobTagsIds: [],
}

function getDefinedValues(obj: Record<string, any>, removeEmptyString?: boolean): Record<string, any> {
  return pickBy(obj, value =>
    value !== undefined &&
    value !== null &&
    (!removeEmptyString || (removeEmptyString && value !== ''))
  );
}

const extractChangesFields = (changes: Array<JobChange>, fields: string[]) => {
  return fields.reduce((acc, field) => {
    const foundChange = changes.find(item => item.fieldName === field);

    if (foundChange) {
      if (foundChange.remove) {
        acc[field] = { ...foundChange.value, remove: true };
      } else {
        acc[field] = foundChange.value || foundChange.value;
      }
    } else {
      acc[field] = undefined;
    }

    return acc;
  }, {} as Record<string, any>);
};

export const generateInputFromLatestCustomerChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['initialCustomerCopy', 'selectedExistingCustomer', 'fullName', 'phone', 'email', 'company'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    initialCustomerCopy,
    selectedExistingCustomer,
    fullName,
    phone,
    email,
    company
  } = extractedValues;

  //existing customer selected, no editing performed
  if (selectedExistingCustomer && !initialCustomerCopy) {
    return getDefinedValues({
      fullName: selectedExistingCustomer?.fullName,
      phone: selectedExistingCustomer?.phone,
      email: selectedExistingCustomer?.email,
      company: selectedExistingCustomer?.company,
      id: selectedExistingCustomer?.id,
    })
    //existing customer selected, editing might be performed, need to find edited inputs
  } else if (selectedExistingCustomer && initialCustomerCopy) {
    return getDefinedValues({
      fullName: fullName || selectedExistingCustomer?.fullName,
      phone: phone || selectedExistingCustomer?.phone,
      email: email || selectedExistingCustomer?.email,
      company: company || selectedExistingCustomer?.company,
      id: selectedExistingCustomer?.id,
    })
    //new customer is being created, need to find edited inputs
  } else if (!selectedExistingCustomer) {
    return getDefinedValues({
      fullName: fullName,
      phone: phone,
      email: email,
      company: company,
    })
  }
};
export const generateJobInputFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['timeline', 'crewSummary', 'adminSummary', 'customerSummary'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    timeline,
    crewSummary,
    adminSummary,
    customerSummary,
  } = extractedValues;
  return getDefinedValues({
    timeline,
    crewSummary,
    adminSummary,
    customerSummary,
    metadata: {},
  })
}

export const getServiceAreaFromChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['resolvedServiceArea'];
  const { resolvedServiceArea } = extractChangesFields(changes, fieldsToExtract);

  return getDefinedValues({ resolvedServiceArea });
}

export const generateJobInputMetadataFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['jobType', 'jobOrigin'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    jobType,
    jobOrigin,
  } = extractedValues;

  return getDefinedValues({
    jobType,
    jobOrigin,
  })
}

export const generateInventoryInputFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['inventory'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    inventory,
  } = extractedValues;

  return getDefinedValues({
    inventory,
  })
}

export const generateLocationsInputsFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['dock', 'start', 'end', ...ADDABLE_LOCATIONS_V2.map(({ key }) => key)];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  // Directly destructure the necessary fields, keeping their original names
  const dock = extractedValues.dock;
  const start = extractedValues.start;
  const end = extractedValues.end;
  const storage = extractedValues.storage;
  const office = extractedValues.office;
  const secondStop = extractedValues['2ndStop'];
  const thirdStop = extractedValues['3rdStop'];
  const other = extractedValues.other;

  const locations = getDefinedValues({
    dock,
    start,
    end,
    storage,
    office,
    other,
    ['2ndStop']: secondStop,
    ['3rdStop']: thirdStop,
  })

  //filter out locations with id undefined, e.g. when dock is not set
  const resolvedLocations = Object.fromEntries(
    Object.entries(locations)
      .filter(([key, location]) => location.id)
  );

  return resolvedLocations;
};

export const generateFieldsInputFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = [...locationsFieldsArray];

  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const valuesObject = fieldsToExtract.reduce((acc, field) => {
    acc[field] = extractedValues[field];
    return acc;
  }, {} as Record<string, any>);

  return getDefinedValues(valuesObject);
};

export const generateTimeSlotFromLatestChanges = (changes: Array<JobChange>) => {
  const fieldsToExtract = ['selectedTimeSlot'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    selectedTimeSlot,
  } = extractedValues;

  return getDefinedValues({
    selectedTimeSlot,
  })
}


export function generateCreateCustomerInput(changes: Array<JobChange>): CreateUserMutationVariables {
  const fieldsToExtract = ['fullName', 'phone', 'email', 'company'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    fullName,
    phone,
    email,
    company
  } = extractedValues;

  const definedValues = getDefinedValues({
    phone: phone,
    email: email,
    company: company,
    ...parseFullName(fullName),
  }, true);

  return definedValues;
}


export function generateUpdateCustomerInput(changes: Array<JobChange>, customerId: string): EditProfileMutationVariables {
  const fieldsToExtract = ['fullName', 'phone', 'email', 'company'];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const {
    fullName,
    phone,
    email,
    company
  } = extractedValues;

  const definedValues = getDefinedValues({
    phone: phone,
    email: email,
    company: company,
    ...parseFullName(fullName),
  });

  const input = {
    id: customerId,
    ...definedValues,
  }

  return input;
}

export function generateJobCreateVariables(changes: Array<JobChange>) {
  const fieldsToExtract = [
    'jobType', 'jobOrigin', 'jobCategory',
    'adminSummary', 'crewSummary', 'customerSummary',
    'timeline', 'resolvedServiceArea'
  ];
  const extractedValues = extractChangesFields(changes, fieldsToExtract);

  const valuesObject = fieldsToExtract.reduce((acc, field) => {
    if (['adminSummary', 'crewSummary', 'customerSummary'].includes(field)) {
      acc[field] = extractedValues[field] ? stringifyContents(extractedValues[field]) : undefined;
    } else {
    acc[field] = extractedValues[field];
    }
    return acc;
  }, {} as Record<string, any>);

  return getDefinedValues(valuesObject);
}


export function stringifyContents(summary: Summary) {
  if (summary) {
    return {
      text: summary?.text,
      contents: JSON.stringify(summary?.contents),
    }
  }
}

export function parseContents(summary: { text: string; contents: string } | undefined) {
  if (summary && !isEmpty(summary.contents)) {
    return {
      text: summary.text,
      contents: JSON.parse(summary.contents),
    };
  }
  return undefined;
}


export const filterLocationsForAddAndRemove = (locations: { location: Partial<Location> & { remove?: boolean } }) => {
  return Object.entries(locations).reduce(
    (acc, [key, location]) => {
      console.log('location', location);
      if (location?.remove) {
        acc.forRemove[key] = location;
      } else {
        acc.forAdd[key] = location;
      }
      return acc;
    },
    {
      forRemove: {} as Record<string, any>,
      forAdd: {} as Record<string, any>,
    }
  );
};

export const addExistingCustomerFieldsIfDefined = (customer: User, ...fields) => {
  const fullName = `${customer.givenName} ${customer.familyName}`;

  const valuesObject = { fullName };

  fields.forEach((field) => {
    if (field.value) {
      valuesObject[field.label] = field.value;
    }
  });

  return valuesObject;
};

export type KeyExtractor<T> = (item: T) => string;

export interface FindResourcesArgs<T> {
  original: T[];
  modified: T[];
  keyExtractor: KeyExtractor<T>;
  compareFn?: (a: T, b: T) => boolean;
  detectEdited?: boolean; // Optional parameter to control detection of edited items
}

/**
 * Determines the items added, removed, and edited between two lists of items based on a key extractor function.
 * The keyExtractor function should be a function that takes an item as input and returns a string key.
 * The key should be unique for each item and should be used to compare the lists.
 * The key should contain items to compare separated by '-'.
 *
 * @param original - The original list of items.
 * @param modified - The modified list of items.
 * @param keyExtractor - A function to extract a unique key from each item to compare the lists.
 * @param compareFn - A function to compare two items and determine if they are equal.
 * @param detectEdited - Optional parameter to control detection of edited items. Default is false.
 * @returns An object containing three arrays: `added`, `removed`, and `edited`.
 *
 * @example
 * // Example keyExtractor function for comparing based on user id and role
 * const keyExtractor = (item: AttendeeWithName) => `${item.user.id}-${item.role}`;
 * const compareFn = (a: AttendeeWithName, b: AttendeeWithName) => a.someProperty === b.someProperty;
 * const { added, removed, edited } = getResourceChanges({ original, modified, keyExtractor, compareFn });
 */
export function getResourceChanges<T>({
  original,
  modified,
  keyExtractor,
  compareFn = () => false, // Default compare function always returns false
  detectEdited = false // Default value is false
}: FindResourcesArgs<T>) {
  // Create maps for quick lookup
  const originalMap = new Map<string, T>();
  const modifiedMap = new Map<string, T>();

  // Populate the maps
  original.forEach((item) => originalMap.set(keyExtractor(item), item));
  modified.forEach((item) => modifiedMap.set(keyExtractor(item), item));

  // Find removed items (items present in originalMap but not in modifiedMap)
  const removed = original.filter((item) => !modifiedMap.has(keyExtractor(item)));

  // Find added items (items present in modifiedMap but not in originalMap)
  const added = modified.filter((item) => !originalMap.has(keyExtractor(item)));

  // Find edited items (items present in both maps but with different values)
  let edited: T[] = [];
  if (detectEdited) {
    edited = modified.filter(
      (item) => originalMap.has(keyExtractor(item)) && !compareFn(item, originalMap.get(keyExtractor(item))!)
    );
  }

  return { added, removed, edited };
}

export function checkAndUpdateEmptyRoomName(inventory: Inventory): Inventory {
  return {
    ...inventory,
    rooms: inventory.rooms.map(room => {
      if (room.name === '') {
        return { ...room, name: 'Inventory List Room' };
      }
      return room;
    })
  };
}

export function unifyNumberFormat(phone: string): string {
  return phone.replace(/\D/g, '');
}
