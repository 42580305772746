import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { Tag } from 'primeng/tag';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { Discount, DiscountFilter, DiscountsGQL, DiscountsQuery, DiscountsQueryVariables, FullDiscountFragment } from '../../generated/graphql.generated';

import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { MutateDiscountComponent } from '../shared/mutate-discount/mutate-discount.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('mutate') mutate: MutateDiscountComponent;
  @ViewChild('search', { static: true }) searchInput!: ElementRef;

  // Filter Variables
  @Input() filter: DiscountFilter = { search: '' };
  @Input() pagination = pagination;
  @Input() showSingleUseDiscounts = false;

  // Modify Component Layout
  @Input() showHeaderCard = true;

  subs = new SubSink();

  tags: Tag[] = [];

  currency: string;

  // Component Variables
  discounts: FullDiscountFragment[] = [];

  // Discount Query Variables
  discountsQueryRef!: QueryRef<DiscountsQuery, DiscountsQueryVariables>;
  discountsQH: WatchQueryHelper = {
    limit: this.pagination.defaultNumberOfItems,
    skip: 0,
    loading: true,
    search: ''
  };

  constructor(private detailsHelper: DetailsHelperService,
    private ref: ChangeDetectorRef,
    private discountsGQL: DiscountsGQL,
    private freyaHelper: FreyaHelperService,
    ) { }

  ngOnInit(): void {

    this.currency = this.freyaHelper.getCurrency();

    this.discounts = [];

    this.retrieveDiscounts();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Discounts').subscribe(() => {
      this.discountsQueryRef.refetch();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
    this.subs.sink = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.filter.search = text;
      this.searchForDiscounts();
    });
  }

  retrieveDiscounts() {
    const discountsInput: DiscountsQueryVariables = {
      filter: this.getFilter(),
      limit: this.discountsQH.limit,
      skip: this.discountsQH.skip,
    };

    this.discountsQueryRef = this.discountsGQL.watch(discountsInput, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.discountsQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.discounts = res.data.discounts.discounts;
      this.discountsQH.total = res.data.discounts.total;
      this.discountsQH.loading = false;
    });
  }

  // Pagination
  retrieveMoreDiscounts(event: LazyLoadEvent) {

    this.discountsQH.limit = event.rows;
    this.discountsQH.skip = event.first;

    this.searchForDiscounts();
  }

  // Search For Discounts based on filter values
  searchForDiscounts() {
    this.discountsQH.loading = true;

    this.discountsQueryRef.resetLastResults();
    this.discountsQueryRef.setVariables(
      {
        filter: this.getFilter(),
        limit: this.discountsQH.limit,
        skip: this.discountsQH.skip,
      });
  }

  getFilter() {
    return { ...this.filter, singleUse: this.showSingleUseDiscounts ? undefined : false };
  }

  selectDiscount(discount: Discount) {
    this.detailsHelper.detailsItem.next({ type: 'discount', item: { id: discount.id } });
  }

  openMutateDialog() {
    this.mutate.mutateType = 'create';
    this.mutate.openDialog();
  }

  handleCheckboxChange() {
    this.discountsQH.skip = 0;
    this.searchForDiscounts();
  }

}

