<div *ngIf="user">


    <div class="header-with-button">
        <div>
            <h4><i [class]="userIcon"></i> User</h4>
            <app-object-state
                [object]="user"
                objectType="user"
                [colorful]="true"
                [loading]="loadingUser"
                ></app-object-state>
        </div>
        <app-user-actions
            [user]="user"
            [disabled]="loadingUser"
        ></app-user-actions>
    </div>
    <div class="card">
        <h5 *ngIf="user.givenName; else loadingBar">
            <a routerLink="/user/{{user.id}}/overview">
                {{user.givenName}} {{user.familyName}}
            </a>
        </h5>
        <p *ngIf="!loadingUser; else loadingBar">{{user.email}}</p>
        <p *ngIf="!loadingUser; else loadingBar">{{user | phone}}</p>
        <p *ngIf="!loadingUser; else loadingBar">Billing Address: <a *ngIf="user.billingAddress; else none" target="_blank" [href]="(user.billingAddress | location).url">{{ (user.billingAddress | location).text }}</a>
        <p *ngIf="!loadingUser; else loadingBar">Company: {{ user.company || 'None' }}</p>
        <p *ngIf="user?.createdAt">Created: {{+user.createdAt?.unix | freyaDate}}</p>
    </div>

    <ng-template #none>
        <span>None</span>
    </ng-template>

    <div *ngIf="!basic">
        <div class="header-with-button">
            <h5>Roles</h5>
            <button
                pButton
                type="button"
                label="Edit Roles"
                *hasPermission="['roles.edit']"
                [disabled]="loadingUser"
                icon="pi pi-pencil"
                (click)="openEditRoles()"
            ></button>
        </div>
        <!-- <div class="roles-header p-grid">
            <div class="p-col">
                <h5>Roles</h5>
            </div>
            <div class="p-col">
                <button
                    pButton
                    type="button"
                    label="Edit Roles"
                    *hasPermission="['roles.edit']"
                    [disabled]="loadingUser"
                    icon="pi pi-pencil"
                    (click)="openEditRoles()"
                    data-cy="edit-button"
                ></button>
            </div>
        </div> -->
        <small>Roles dictate how users can interact wth the system.</small>
        <br>
        <div class="card">
            <div *ngIf="!loadingUser; else loadingBar">
                <p-chip
                    *ngFor="let role of roles"
                    class="quick-margin role-chip"
                    pTooltip="{{role.description}}"
                    [style]="{'background-color': role.color, 'margin':'3px'}"
                    tooltipPosition="left"
                    (click)="detailsHelper.open('role', role)"
                >
                    <span [colorForBackground]="{backgroundColor: role.color}">
                        {{role.name}}
                        <ng-container *ngIf="role.zones.nodes">
                            ({{ role.zones.nodes.length }})
                        </ng-container>
                    </span>
                </p-chip>
            </div>
        </div>

        <h5>Zones</h5>
        <div class="card">
            <div *ngIf="!loadingUser; else loadingBar">
                <p *ngFor="let zone of user?.zones?.nodes">{{zone.name}}</p>
            </div>
        </div>
    </div>

    <app-mutate-user
        #mutate
        [user]="user"
        mutateType="update"
    >
    </app-mutate-user>

    <ng-template #loadingBar>
        <div class="p-p-2">
            <p-skeleton
                width="100%"
                height="16px"
            ></p-skeleton>
        </div>
    </ng-template>
</div>
