import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';

import { AvailableZonesService } from './services/available-zones.service';


@Injectable({
  providedIn: 'root'
})
export class ZoneTypeGuard  {

  constructor(
    private router: Router,
    private availableZonesService: AvailableZonesService,

  ) { }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      return this.availableZonesService.availableZones.pipe(
        filter((availableZones) => {
          return Boolean(availableZones);
        }),
        take(1),
        map((availableZones) => {

          const desiredZoneId = state.root.queryParams.zone;

          const desiredZone = availableZones
            .find((zone) => zone?.id === desiredZoneId);

          if (!desiredZone) {
            return true;
          }

          if (!childRoute?.data?.hiddenIfZoneType || !childRoute.data.hiddenIfZoneType.includes(desiredZone.type)) {
            return true;
          }

          const [ _blank, parent ] = state.url.split('/');

          return this.router.createUrlTree([ parent || '/' ]);
        }),
      );
  }
}
