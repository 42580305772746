import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoleInfo } from '@karve.it/interfaces/roles';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit, OnDestroy {

  // @ViewChild('mutate') mutateRef: Mutate;

  @Input() role: RoleInfo;

  roleActions = [{
    label: 'Role Actions',
    items: [
      // {
      //   label: 'Edit',
      //   icon: 'pi pi-pencil',
      //   command: () => {
      //     this.mutateRef.user = this.user;
      //     this.mutateRef.mutateType = 'update';
      //     this.mutateRef.openDialog();
      //   }
      // },
      // {
      //   label: 'Delete',
      //   icon: 'pi pi-trash',
      //   command: () => {
      //     this.openDeleteUserDialog();
      //   }
      // }
    ]
  }];

  subs = new SubSink();
  showEdit = false;

  // Delete User Variables
  showDelete = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openDeleteRoleDialog(){
    this.showDelete = true;
  }

  // deleteUser() {
  //   this.subs.sink = this.userService.deleteUsers({userIds: [this.user.id]}).subscribe((res) => {
  //     if (res) {
  //       this.localNotify.addToast.next({severity: 'success', summary: 'User deleted'});
  //       this.detailsHelper.userUpdated.next(true);
  //       this.detailsHelper.detailsItem.next(null);
  //       this.showDeleteUser = false;
  //     }
  //   });
  // }

}
