<div>
    <p-multiSelect
        appendTo="body"
        [placeholder]="placeholder"
        [options]="jobLocationSuggestions"
        [(ngModel)]="selectedLocations"
        (ngModelChange)="onChange(selectedLocations)"
        [disabled]="disabled"
        [selectedItemsLabel]="'{0} locations selected'"
        [selectionLimit]="limit"
        [dataKey]="'locationId'"
        (onChange)="emitSelect()"
        (focus)="touched = true"
        [autofocusFilter]="!responsiveHelper.isSmallScreen"
    >
    <ng-template let-jl pTemplate="item">
        <div>
            <div>
                <span>{{jl.location.addressLineOne}}</span>
				<br>
				<span>{{jl.locationType}}</span>
            </div>
        </div>
    </ng-template>
    <ng-template let-value pTemplate="selectedItems">
        <!-- <span *ngFor="let location of value">
            {{location.location.addressLineOne}}
        </span> -->
        <!-- <span *ngIf="!value">No Locations Selected</span> -->
        <span>{{value?.length || 'None'}} Selected</span>
    </ng-template>
</p-multiSelect>
</div>