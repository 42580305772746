<main class="grid jobsv2-{{ colorScheme.colorScheme.value }}">
	<app-job-header></app-job-header>

	<app-karve-tabview
		[tabs]="tabsWithSlug"
		[baseUrl]="baseUrl"
		class="job-tabs"
	></app-karve-tabview>

	<div class="tabView">
		<router-outlet></router-outlet>
	</div>

	<app-job-activity></app-job-activity>
</main>