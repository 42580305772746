
import { Injectable } from '@angular/core';
import {FetchPolicy} from '@apollo/client/core';
import { Field, FieldValue, listFields, ListFieldsInput, ListFieldsOutput, SetFieldValueInput, setUsersFields, UserField, FIELD_TYPE, SetFieldValuesInput, SetFieldValueResult, setFieldValues } from '@karve.it/interfaces/fields';
import {QueryRef} from 'apollo-angular';


import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PlusApollo } from '../auth/graphql.module';
import { PlusAuthenticationService } from '../auth/plus-auth.service';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(
    private apollo: PlusApollo,
    private auth: PlusAuthenticationService,
  ) { }

  /**
   * Retrieve user fields for a user by name. Fields will return in order
   * provided at fieldNames. If no field is found, will return undefined.
   *
   * @param user id of the user
   * @param fieldNames array of field names
   * @param cache true if we should use caching
   */
  getUserFields<T = FieldValue>(
    user: string,
    fieldNames: string[],
    cache = true,
  ) {
    return this.apollo.query<ListFieldsOutput<T>>({
      query: listFields,
      variables: {
          users: [ user ],
          limit: fieldNames.length,
          limitValues: 1,
          filter: {
              names: fieldNames,
          }
      } as ListFieldsInput,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
    }).pipe(map((res) => {
        if (!res.data || !res.data.fields) {
            return [];
        }

        const fields: Field<T>[] = [];
        for (const name of fieldNames) {
            const field = res.data.fields.fields.find((f) => f.name === name);
            this.convertFieldValues(field);
            if (field && field.values) {
                field.value = field.values[0];
            }

            fields.push(field);
        }

        return fields;
    }));
  }

    /**
     * List the fields in the system.
     *
     * @param variables Variables to filter the results by.
     * @param fetchPolicy The apollo fetchPolicy that should be used. Default 'cache-first'.
     * @returns A list of fields and the values that objects have for those fields.
     */
     listFields<T = FieldValue>(
      variables: ListFieldsInput,
      fetchPolicy: FetchPolicy = 'cache-first',
    ) {
      return this.apollo.query<ListFieldsOutput<T>>({
        query: listFields,
        variables,
        fetchPolicy
      });
    }

  /**
   * List the fields in the system.
   *
   * @param variables Variables to filter the results by.
   * @param fetchPolicy The apollo fetchPolicy that should be used. Default 'cache-first'.
   * @returns A list of fields and the values that objects have for those fields.
   */
  watchFields<T = FieldValue>(
    variables: ListFieldsInput,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): QueryRef<ListFieldsOutput<T>> {
    return this.apollo.watchQuery<ListFieldsOutput<T>>({
      query: listFields,
      variables,
      fetchPolicy,
      errorPolicy: 'ignore',
    });
  }

  /**
   * Convert's field query results into an easier to read and manage format. Uses convertFieldValues.
   *
   * @param queryRef The query ref for the fields query that you want to pipe into more readable values.
   * @returns A more readable output than the base fields queries.
   */
  pipeFields<T = FieldValue>(queryRef: QueryRef<ListFieldsOutput<T>>){
    return queryRef.valueChanges.pipe(map((v) => {
      // parse object types
      if (!v.data) { return []; }
      for (const field of v.data.fields.fields) {
        this.convertFieldValues(field);
      }
      return v;
    }));
  }

  /**
   * Converts field from the default return to a more readable/useable format.
   *
   * @param field The field that you want to parse.
   */
  convertFieldValues<T>(field: Field<T>) {
    if (field.values && field.type === FIELD_TYPE.OBJECT) {
        for (const value of field.values) {
          if (value.value && typeof value.value === 'string') {
            try {
              value.value = JSON.parse(value.value as any) as T;
            } catch (e) {
              console.error(`Could not parse field ${ field.name }`, value, e);
            }
          }
        }
    }
  }

  /**
   * Converts a fields query ref output to be specific to a single user.
   *
   * @param queryRef The query reference for the fields
   * @param user The id of the user.
   * @returns
   */
  getUserFieldValues<T = FieldValue>(
    queryRef: QueryRef<any>,
    user: string,
    // fieldNames: string[],
  ) {
    let fieldsToReturn = [];
    this.pipeFields(queryRef).pipe(
      map((result: any) => {
        // add value to type for user
        // tslint:disable-next-line: no-string-literal
        const fields: UserField<FieldValue | T>[] = result.data.fields.fields;

        for (const field of fields) {
          field.value = (field.values).find((v) => v.objectId === user);
        }

        // This Maps all of the properties in the array to
        // fieldsToReturn = Object.assign({}, ...fields.map((x) => ({[x.name]: x})));

        fieldsToReturn = fields;
      }),
    );

    return of(fieldsToReturn);
  }

  /**
   *
   * @param users The ids of the users whose fields you want to set.
   * @param fields The fields you want to set and the values for them.
   * @returns QueryRef, The modified fields.
   */
  setUsersFields(
    users: string[],
    fields: SetFieldValueInput[],
  ) {
    return this.apollo.mutate<any>({
      mutation: setUsersFields,
      variables: {
        users,
        fields,
      },
    });
  }

  /**
   * Method to set field for currently AUTHENTICATED (not in context) user
   * Must be authenticated
   *
   * @param field Field to set
   */
  setOwnFields(
    fields: SetFieldValueInput[],
  ) {
    if (!this.auth.isAuthenticated()) { throw new Error('Cannot set own field if not authenticated'); }

    return this.setUsersFields(
      [ this.auth.user.id ],
      fields,
    );

  }

  /**
   * Method to set field for currently AUTHENTICATED (not in context) user
   * Must be authenticated
   *
   * @param field Field to set
   */
  setOwnField(
    field: SetFieldValueInput,
  ) {
    return this.setOwnFields([ field ]);
  }


  setFieldValues(input: SetFieldValuesInput){
    return this.apollo.mutate<SetFieldValueResult>({
      mutation: setFieldValues,
      variables: {
        ...input
      }
    });
  }

  /**
   * Set the Value of fields by their name instead of id
   *
   * @param names Names of the fields to set
   * @param values Values of the Fields to Set (match index with name)
   * @param objects Objects to set the fields for, Applies values to all objects listed.
   */
  setFieldValuesByName(names: string[], values: any[], objects: string[]): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.listFields({filter: {names}}).subscribe((res) => {
        const setFieldInput = {
          fields: [],
          objects,
        } as SetFieldValuesInput;
        for(const name of names){
          const field = res.data.fields.fields.find((f) => f.name === name);
          if (!field) { throw new Error(`Field: ${name} does not exist.`); }

          const input = {
              fieldId: field.id,
              value: values[names.indexOf(name)]
          } as SetFieldValueInput;

          setFieldInput.fields.push(input);
        }

        this.setFieldValues(setFieldInput).subscribe(() => {
          resolve(true);
        }, (err) => {
          reject(err);
        });
      });
    });

  }
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum SYSTEM_FIELDS {
  NOTIFICATIONS_ENABLE = 'notifications.enable',
  NOTIFICATIONS_REMINDERS_EMAIL = 'notifications.appointmentRemind-email',
  NOTIFICATIONS_REMINDERS_SMS = 'notifications.appointmentRemind-sms',
  NOTIFICATIONS_REMINDERS_PUSH = 'notifications.appointmentRemind-push',
  NOTIFICATIONS_REMINDER_DEFAULT_INTERVAL = 'notifications.reminder-default-interval',

  NOTIFICATIONS_NOTIFY_EMAIL = 'notifications.notify-email',
  NOTIFICATIONS_NOTIFY_SMS = 'notifications.notify-sms',
  NOTIFICATIONS_NOTIFY_PUSH = 'notifications.notify-push',

  NOTIFICATIONS_SMALL_UPDATE_EMAIL = 'notifications.smallUpdate-email',
  NOTIFICATIONS_SMALL_UPDATE_SMS = 'notifications.smallUpdate-sms',
  NOTIFICATIONS_SMALL_UPDATE_PUSH = 'notifications.smallUpdate-push',
  NOTIFICATIONS_MEDIUM_UPDATE_EMAIL = 'notifications.mediumUpdate-email',
  NOTIFICATIONS_MEDIUM_UPDATE_SMS = 'notifications.mediumUpdate-sms',
  NOTIFICATIONS_MEDIUM_UPDATE_PUSH = 'notifications.mediumUpdate-push',
  NOTIFICATIONS_LARGE_UPDATE_EMAIL = 'notifications.largeUpdate-email',
  NOTIFICATIONS_LARGE_UPDATE_SMS = 'notifications.largeUpdate-sms',
  NOTIFICATIONS_LARGE_UPDATE_PUSH = 'notifications.largeUpdate-push',

  NOTIFICATIONS_APPOINTMENT_CREATED_EMAIL = 'notifications.appointmentCreated-email',
  NOTIFICATIONS_APPOINTMENT_CREATED_SMS = 'notifications.appointmentCreated-sms',
  NOTIFICATIONS_APPOINTMENT_CREATED_PUSH = 'notifications.appointmentCreated-push',

  NOTIFICATIONS_APPOINTMENT_STARTED_EMAIL = 'notifications.appointmentStarted-email',
  NOTIFICATIONS_APPOINTMENT_STARTED_SMS = 'notifications.appointmentStarted-sms',
  NOTIFICATIONS_APPOINTMENT_STARTED_PUSH = 'notifications.appointmentStarted-push',
  NOTIFICATIONS_APPOINTMENT_FINISHED_EMAIL = 'notifications.appointmentFinished-email',
  NOTIFICATIONS_APPOINTMENT_FINISHED_SMS = 'notifications.appointmentFinished-sms',
  NOTIFICATIONS_APPOINTMENT_FINISHED_PUSH = 'notifications.appointmentFinished-push',

  NOTIFICATIONS_UNSUBSCRIBE_TOKEN = 'notifications.unsubscribe-token',
  NOTIFICATIONS_PUSH_SUBSCRIPTION = 'notifications.push-subscription',
}
