import { dayjs } from '@karve.it/core';
import {
    createFeature,
    createReducer,
    createSelector,
    MemoizedSelector,
    on,
} from '@ngrx/store';

import { cloneDeep } from 'lodash';
import { PrimeIcons } from 'primeng/api';
import { CallState, LoadingState } from 'src/app/utilities/state.util';

import {
    Asset,
    Attendee,
    CalendarEventForScheduleFragment,
    JobUser,
    Role,
    User,
} from '../../../../generated/graphql.generated';

import { COLOR_MAP } from '../../../colors';
import { CUSTOMER_ROLE_ATTRIBUTE, EventAttendeeRoles, eventTypeInfoMap } from '../../../global.constants';

import { DispatchActions } from './dispatch.actions';

// States that are tracked by the job tool under "callState"
export const DISPATCH_CALL_STATES = [
    'events',
    'users',
    'roles',
    'assets',
    'updateCrew',
] as const;
export type DispatchCallState = (typeof DISPATCH_CALL_STATES)[number];

const BOOK_OFF_EVENT_TYPE = 'book-off';
export const CREW_LEAD_ROLE = 'crew-lead';
export const CREW_MEMBER_ROLE = 'crew-member';

const ASSET_TYPES = ['Truck', 'Delivery'];

export enum AdditionalEventAttendeeRoles {
    All = 'All',
}

export type CrewRoleOptions = AdditionalEventAttendeeRoles | EventAttendeeRoles;

export enum DispatchSortOptions {
    EventStartAsc = 'Event Start Ascending',
    EventStartDesc = 'Event Start Descending',
    TruckNameAsc = 'Truck Name Ascending',
    TruckNameDesc = 'Truck Name Descending',
}

interface EventTimings {
    id: string;
    start: number;
    end: number;
}

interface CrewMetadata {
    events: Map<string, { start: number; end: number }>;
    eventsCount: number;
    conflictingEventIds: string[];
    hasConflict: boolean;
    revenue: number;
}

const DEFAULT_CREW_METADATA: CrewMetadata = {
    events: new Map(),
    eventsCount: 0,
    conflictingEventIds: [],
    hasConflict: false,
    revenue: 0,
};

export enum AdditionalEventStatuses {

    All = 'all',
}
export enum EventStatuses {
    Cancelled = 'cancelled',
    Required = 'required',
    Pending = 'pending',
    Booked = 'booked',
    Confirmed = 'confirmed',
    Completed = 'completed',
}


export type EventStatusOptions = AdditionalEventStatuses | EventStatuses;


export const EVENT_STATUS_ICONS = {
    [AdditionalEventStatuses.All]: PrimeIcons.CHECK,
    [EventStatuses.Cancelled]: PrimeIcons.TIMES_CIRCLE,
    [EventStatuses.Required]: PrimeIcons.EXCLAMATION_TRIANGLE,
    [EventStatuses.Pending]: PrimeIcons.MINUS_CIRCLE,
    [EventStatuses.Booked]: PrimeIcons.CALENDAR,
    [EventStatuses.Confirmed]: PrimeIcons.THUMBS_UP,
    [EventStatuses.Completed]: PrimeIcons.CHECK_CIRCLE,
} as const;

// Define the Edit type
export interface UpdateCrewActionPayload {
    eventId: string;
    eventName: string;
    addAttendees?: Omit<AttendeeWithName, 'eventsCount' | 'hasConflict'>[];
    removeAttendees?: Omit<AttendeeWithName, 'eventsCount' | 'hasConflict'>[];
    addAssets?: { id: string; name: string }[];
    removeAssets?: { id: string; name: string }[];
}
export interface JobUserWithName extends JobUser {
    name: string;
}

export interface AttendeeWithName extends Attendee {
    name: string;
    eventsCount: number;
    hasConflict: boolean;
}

export interface TrucksWithMetadata extends Asset {
    eventsCount: number;
    hasConflict: boolean;
}


export interface DispatchFilterOption<T> {
    label: T;
    icon: PrimeIcons;
}

export interface DispatchEvent {
    event: CalendarEventForScheduleFragment;
    style: {
        backgroundColor: string;
        color: string;
    };

    startTime: string;
    endTime: string;
    eventHasConflict: boolean;

    trucks: TrucksWithMetadata[];

    customer: JobUserWithName;

    crew: AttendeeWithName[];
    crewRequirement: number;
    needsMoreCrew: boolean;

    laborCharge: {
        name: string;
        color: string
    };
}
export interface DispatchState {
    events: CalendarEventForScheduleFragment[];
    users: User[];
    roles: Role[];
    assets: Asset[];

    // Event Filter options
    dispatchDate: Date;
    sortBy: DispatchSortOptions;
    eventStatus: EventStatusOptions;

    // User filter options
    userSearch: string;
    userRole: CrewRoleOptions;

    callState: {
        [name in DispatchCallState]: CallState;
    };
}

type LoadingSelectorType = MemoizedSelector<
    Record<string, any>,
    boolean,
    (s1: DispatchState['callState']) => boolean
>;

type LoadingErrorSelectorType = MemoizedSelector<
    Record<string, any>,
    boolean,
    (s1: DispatchState['callState']) => string | null
>;

export const dispatchInitialState: DispatchState = {
    events: [],
    users: [],
    roles: [],
    assets: [],

    dispatchDate: new Date(), //TODO: Optimize: Store DayJs object instead of Date
    sortBy: DispatchSortOptions.EventStartAsc,
    eventStatus: AdditionalEventStatuses.All,

    userSearch: '',
    userRole: AdditionalEventAttendeeRoles.All,

    callState: DISPATCH_CALL_STATES.reduce(
        (p, c) => ({ ...p, [c]: LoadingState.INIT }),
        {} as DispatchState['callState'],
    ),
};

// Helper function to create AttendeeWithName objects
const createAttendeeWithName = (attendee: Attendee | JobUser) => {
    if (!attendee) {
        return {
            user: null,
            role: null,
            name: 'No customer set',
        };
    }
    const { user, role: attendeeRole } = attendee;
    const { givenName, familyName } = user;
    return {
        user: { ...user },
        role: attendeeRole,
        name: [familyName, givenName].filter(Boolean).join(', '),
    };
};

// Helper function to format time
const formatTime = (timestamp: number) =>
    dayjs.tz(timestamp * 1000).format('hh:mm A');

// Function to check for overlapping events
const findConflictingEvents = (events: EventTimings[]): Set<string> => {
    const conflictingEvents = new Set<string>();

    if (events.length <= 1) return conflictingEvents;

    // Sort events by start time
    events.sort((a, b) => a.start - b.start);

    for (let i = 1; i < events.length; i++) {
        if (events[i].start < events[i - 1].end) {
            conflictingEvents.add(events[i - 1].id);
            conflictingEvents.add(events[i].id);
        }
    }

    return conflictingEvents;
};

const findConflicts = (map: Map<string, CrewMetadata>) => {
    for (const metadata of map.values()) {
        const eventsArray = Array.from(metadata.events.entries()).map(
            ([id, { start, end }]) => ({ id, start, end })
        );
        const conflictingEventIds = Array.from(
            findConflictingEvents(eventsArray)
        );

        metadata.hasConflict = Boolean(conflictingEventIds.length);
        metadata.conflictingEventIds = conflictingEventIds;
    }
};

const updateMetadataMap = (
    map: Map<string, CrewMetadata>,
    id: string,
    event: CalendarEventForScheduleFragment
) => {
    if (!map.has(id)) {
        map.set(id, {
            events: new Map(),
            eventsCount: 0,
            conflictingEventIds: [],
            hasConflict: false,
            revenue: 0,
        });
    }

    const metadata = map.get(id)!;

    metadata.events.set(event.id, {
        start: event.start,
        end: event.end,
    });
    metadata.eventsCount += 1;
    metadata.revenue += event.discountedSubTotal;
};

const parseNumberOfCrewMembers = (productName: string) => {
    const regex = /(\d+)\s*(?:Man|Crew)\s*(?:Hourly|Hourly Based|Truck & Travel|Rate|Special|Fee|(?!\d)|[^\d]*|)$/i;
    const match = productName.match(regex);
    return match ? parseInt(match[1], 10) : 0;
};

const getEventStyles = (eventType: string) => {
    const backgroundColor = eventTypeInfoMap[eventType]?.backgroundColor;

    return backgroundColor ? {
        backgroundColor: COLOR_MAP[backgroundColor].lightColor,
        color: COLOR_MAP[backgroundColor].textColor,
    } : {};
}

export const DispatchFeature = createFeature({
    name: 'dispatch',
    extraSelectors: ({
        selectCallState,
        selectEvents,
        selectSortBy,
        selectRoles,
        selectUsers,
        selectUserRole,
        selectAssets,
    }) => {
        const loadingSelectors = DISPATCH_CALL_STATES.reduce(
            (p, callState) => {
                const selectors = {
                    [`${callState}Loading`]: createSelector(
                        selectCallState,
                        (state) =>
                            state[callState] === LoadingState.PENDING ||
                            state[callState] === LoadingState.LOADING ||
                            state[callState] === LoadingState.MUTATING,
                    ),
                    [`${callState}Loaded`]: createSelector(
                        selectCallState,
                        (state) =>
                            state[callState] === LoadingState.LOADED ||
                            state[callState] === LoadingState.MUTATED,
                    ),
                };

                return {
                    ...p,
                    ...selectors,
                };
            },
            {} as {
                [x in
                | `${DispatchCallState}Loading`
                | `${DispatchCallState}Loaded`]: LoadingSelectorType;
            },
        );

        const errorSelectors = DISPATCH_CALL_STATES.reduce(
            (p, callState) => {
                // eslint-disable-next-line @ngrx/prefix-selectors-with-select
                const selectors = {
                    [`${callState}Error`]: createSelector(
                        selectCallState,
                        (state) => {
                            const cs = state[callState];
                            if (typeof cs === 'object' && 'error' in cs) {
                                return cs.error;
                            }

                            return null;
                        },
                    ),
                };

                return {
                    ...p,
                    ...selectors,
                };
            },
            {} as {
                [x in `${DispatchCallState}Error`]: LoadingErrorSelectorType;
            },
        );

        const isAnyLoading = createSelector(selectCallState, (callState) => {
            for (const key in callState) {
                if (callState[key] === LoadingState.LOADING) {
                    return true;
                }
                if (callState[key] === LoadingState.MUTATING) {
                    return true;
                }
            }

            return false;
        });

        const selectCrewMetadata = createSelector(selectEvents, (events) => {
            const crewMetadataMap = new Map<string, CrewMetadata>();
            const assetMetadataMap = new Map<string, CrewMetadata>();

            for (const event of events) {
                for (const { user, role } of event.attendees) {
                    if (role === EventAttendeeRoles.author) continue;
                    updateMetadataMap(crewMetadataMap, user.id, event);
                }

                for (const { id } of event.assets) {
                    updateMetadataMap(assetMetadataMap, id, event);
                }
            }

            findConflicts(crewMetadataMap);
            findConflicts(assetMetadataMap);

            return { crewMetadataMap, assetMetadataMap };
        });

        const selectDerivedEvents = createSelector(
            selectEvents,
            selectCrewMetadata,
            (events, { crewMetadataMap, assetMetadataMap }) =>
                events.map((event) => {
                    const { attendees = [], assets = [], job: { users = [] }, charges = [] } = event;
                    let eventHasConflict = false;

                    // Extract crew-related attendees
                    const crew = attendees
                        .filter(
                            (attendee: Attendee) =>
                                attendee.role !== EventAttendeeRoles.author,
                        )
                        .map((attendee: Attendee) => {
                            const userId = attendee.user.id;
                            const {
                                eventsCount,
                                conflictingEventIds,
                                hasConflict,
                            } =
                                crewMetadataMap.get(userId) ||
                                DEFAULT_CREW_METADATA;

                            if (hasConflict) {
                                eventHasConflict = true;
                            }

                            return {
                                ...createAttendeeWithName(attendee),
                                eventsCount,
                                hasConflict,
                                conflictingEventIds,
                            };
                        });

                    // Extract truck-related assets
                    const trucks = assets
                        .filter(({ type }) =>
                            ASSET_TYPES.includes(type),
                        )
                        .map((asset: Asset) => {
                            const assetId = asset.id;
                            const {
                                eventsCount,
                                conflictingEventIds,
                                hasConflict,
                            } =
                                assetMetadataMap.get(assetId) ||
                                DEFAULT_CREW_METADATA;

                            if (hasConflict) {
                                eventHasConflict = true;
                            }

                            return {
                                ...asset,
                                eventsCount,
                                hasConflict,
                                conflictingEventIds,
                            };
                        });

                    const customer = users.find(
                        (user) => user.role === CUSTOMER_ROLE_ATTRIBUTE,
                    ) as JobUser;

                    const laborCharge = charges.find(
                        charge => charge?.product?.category === 'Labor',
                    );

                    const crewRequirement = parseNumberOfCrewMembers(laborCharge?.product?.name || '') || crew.length;

                    const style = getEventStyles(event.type);

                    return {
                        event,
                        style,
                        startTime: formatTime(event.start),
                        endTime: formatTime(event.end),
                        crew,
                        customer: createAttendeeWithName(customer),
                        trucks,
                        crewRequirement,
                        needsMoreCrew: crewRequirement > crew.length,
                        laborCharge: {
                            name: laborCharge?.product?.name || '',
                            color: laborCharge?.product?.metadata?.color || '',
                        },
                        eventHasConflict,
                    } as DispatchEvent;
                }),
        );

        const selectTodaysRevenue = createSelector(selectEvents, (events) => {
            const total = events.reduce((acc, event) => {
                return acc + (event.discountedSubTotal || 0);
            }, 0);

            const currency = events[0]?.job?.currency || 'USD';

            return { total, currency };
        });

        const selectConflictingEvents = createSelector(selectDerivedEvents, (events) => {
            return events.filter((event) => event.eventHasConflict);
        });
        const selectSortedEvents = createSelector(
            selectDerivedEvents,
            selectSortBy,
            (events, sortBy) => {
                const sortedEvents = cloneDeep(events);

                // Helper function for event sorting
                const compareEvents = (a: DispatchEvent, b: DispatchEvent) => {
                    const isEventStartSort = sortBy.includes('Event Start');
                    const isTruckNameSort = sortBy.includes('Truck Name');

                    const secondarySort = a.event.createdAt - b.event.createdAt; // ascending

                    if (isEventStartSort) {
                        const startComparison = a.event.start - b.event.start; // ascending

                        if (startComparison !== 0) {
                            return sortBy === DispatchSortOptions.EventStartAsc ? startComparison : -startComparison;
                        }

                        // If start times are equal, sort by truck name ascending
                        return secondarySort;
                    }

                    if (isTruckNameSort) {
                        const nameA = a.trucks[0]?.name?.toLowerCase();
                        const nameB = b.trucks[0]?.name?.toLowerCase();
                        const nameComparison = nameA?.localeCompare(nameB) || 0; // ascending

                        if (nameComparison !== 0) {
                            return sortBy === DispatchSortOptions.TruckNameAsc ? nameComparison : -nameComparison;
                        }

                        // If truck names are equal, sort by event start time ascending
                        return secondarySort;
                    }

                    // Default comparison (fallback if no valid sortBy criteria is present)
                    return 0;
                };

                sortedEvents.sort(compareEvents);

                return sortedEvents;
            },
        );

        const selectCrewRoleIds = createSelector(
            selectRoles,
            selectUserRole,
            (roles, userRole: CrewRoleOptions) => {
                const filterRole = (role: Role) => {
                    switch (userRole) {
                        case AdditionalEventAttendeeRoles.All:
                            return (
                                role.attributes.includes(CREW_LEAD_ROLE) ||
                                role.attributes.includes(CREW_MEMBER_ROLE)
                            );
                        case EventAttendeeRoles.crewLead:
                            return role.attributes.includes(CREW_LEAD_ROLE);
                        case EventAttendeeRoles.crewMember:
                            return role.attributes.includes(CREW_MEMBER_ROLE);
                        default:
                            return false;
                    }
                };

                return roles.filter(filterRole).map((role) => role.id);
            },
        );

        const selectDerivedUsers = createSelector(selectUsers, (users) => {
            return users.map((user) => {
                const fullName = [user.familyName, user.givenName]
                    .filter(Boolean)
                    .join(', ');
                const isCrewLead = user.roles.some((role) =>
                    role.attributes.includes(CREW_LEAD_ROLE),
                );
                const role = isCrewLead
                    ? EventAttendeeRoles.crewLead
                    : EventAttendeeRoles.crewMember;
                return {
                    name: fullName,
                    user,
                    role,
                };
            });
        });

        const selectPotentialCrew = createSelector(
            selectDerivedUsers,
            selectCrewMetadata,
            (users, { crewMetadataMap }) => {
                if (!users.length) {
                    return [];
                }

                // Map users to their metadata
                return users.map((user) => {
                    const { eventsCount, revenue } =
                        crewMetadataMap.get(user.user.id) ||
                        DEFAULT_CREW_METADATA;

                    return {
                        ...user,
                        eventsCount,
                        revenue,
                    };
                });
            },
        );

        const selectPotentialAssets = createSelector(
            selectAssets,
            selectCrewMetadata,
            (assets, { assetMetadataMap }) => {
                if (!assets.length) {
                    return [];
                }

                // Map assets to their metadata
                return assets.map((asset) => {
                    const { eventsCount, revenue } =
                        assetMetadataMap.get(asset.id) || DEFAULT_CREW_METADATA;

                    return {
                        ...asset,
                        eventsCount,
                        revenue,
                    };
                });
            },
        );

        return {
            ...loadingSelectors,
            ...errorSelectors,
            isAnyLoading,
            selectTodaysRevenue,
            selectDerivedEvents,
            selectSortedEvents,
            selectCrewRoleIds,
            selectPotentialCrew,
            selectPotentialAssets,
            selectConflictingEvents,
        };
    },
    reducer: createReducer(
        dispatchInitialState,
        on(
            DispatchActions.eventsLoading,
            (state): DispatchState => ({
                ...state,
                callState: {
                    ...state.callState,
                    events: LoadingState.LOADING,
                },
            }),
        ),
        on(
            DispatchActions.eventsLoaded,
            (state, { events }): DispatchState => ({
                ...state,
                // TODO: We should do this filtering in the backend
                events: events.filter(
                    (event) => event.type !== BOOK_OFF_EVENT_TYPE,
                ),
                callState: {
                    ...state.callState,
                    events: LoadingState.LOADED,
                },
            }),
        ),

        on(
            DispatchActions.setSortBy,
            (state, { sortBy }): DispatchState => ({
                ...state,
                sortBy,
            }),
        ),
        on(
            DispatchActions.hardRefreshEvents,
            (state, { date }): DispatchState => {
                const { dispatchDate } = state;

                return {
                    ...state,
                    dispatchDate: date ?? dispatchDate,
                };
            },
        ),
        on(
            DispatchActions.setDispatchDate,
            (state, { date }): DispatchState => ({
                ...state,
                dispatchDate: date,
            }),
        ),
        on(
            DispatchActions.setEventStatus,
            (state, { eventStatus }): DispatchState => ({
                ...state,
                eventStatus,
            }),
        ),

        on(
            DispatchActions.setUserSearch,
            (state, { search }): DispatchState => ({
                ...state,
                userSearch: search,
            }),
        ),

        on(
            DispatchActions.setUserRole,
            (state, { role }): DispatchState => ({
                ...state,
                userRole: role,
            }),
        ),

        on(
            DispatchActions.rolesLoaded,
            DispatchActions.rolesLoadedOnComponentHardRefresh,
            (state, { roles }): DispatchState => ({
                ...state,
                roles,
                callState: {
                    ...state.callState,
                    roles: LoadingState.LOADED,
                },
            }),
        ),

        on(
            DispatchActions.assetsLoading,
            (state): DispatchState => ({
                ...state,
                callState: {
                    ...state.callState,
                    assets: LoadingState.LOADING,
                },
            }),
        ),

        on(
            DispatchActions.assetsLoaded,
            (state, { assets }): DispatchState => ({
                ...state,
                assets,
                callState: {
                    ...state.callState,
                    assets: LoadingState.LOADED,
                },
            }),
        ),

        on(
            DispatchActions.usersLoading,
            (state): DispatchState => ({
                ...state,
                callState: {
                    ...state.callState,
                    users: LoadingState.LOADING,
                },
            }),
        ),

        on(
            DispatchActions.usersLoaded,
            (state, { users }): DispatchState => ({
                ...state,
                users,
                callState: {
                    ...state.callState,
                    users: LoadingState.LOADED,
                },
            }),
        ),

        on(DispatchActions.updateCrew, (state, { edits }): DispatchState => {
            const events = cloneDeep(state.events);
            // A map of eventId to edits for quick lookup
            const editMap = new Map(edits.map((edit) => [edit.eventId, edit]));

            const updatedEvents = events.map((event) => {
                const edit = editMap.get(event.id);
                if (!edit) {
                    return event;
                }

                const {
                    addAttendees = [],
                    removeAttendees = [],
                    addAssets = [],
                    removeAssets = [],
                } = edit;

                // Set of user ids to remove
                const removeAttendeeIds = new Set(
                    removeAttendees.map((attendee) => attendee.user.id),
                );
                const removeAssetIds = new Set(
                    removeAssets.map((asset) => asset.id),
                );

                const newAttendees = event.attendees
                    .filter(
                        (attendee) => !removeAttendeeIds.has(attendee.user.id),
                    )
                    .concat(
                        addAttendees.map((addAttendee) => ({
                            role: addAttendee.role,
                            user: addAttendee.user,
                        })),
                    );

                const newAssets = event.assets
                    .filter((asset) => !removeAssetIds.has(asset.id))
                    .concat(
                        addAssets.map((addAsset) => ({
                            id: addAsset.id,
                            name: addAsset.name,
                            type: 'Truck',
                        })),
                    );

                return {
                    ...event,
                    attendees: newAttendees,
                    assets: newAssets,
                };
            });

            return {
                ...state,
                events: updatedEvents,
                callState: {
                    ...state.callState,
                    updateCrew: LoadingState.MUTATING,
                },
            };
        }),

        on(
            DispatchActions.updateCrewSuccess,
            (state): DispatchState => ({
                ...state,
                callState: {
                    ...state.callState,
                    updateCrew: LoadingState.MUTATED,
                },
            }),
        ),

        on(
            DispatchActions.updateCrewError,
            (state, { edits, error }): DispatchState => {
                // Revert the changes
                // 1. Remove attendees that were added (from addAttendees)
                // 2. Add back attendees that were removed (from removeAttendees)

                const events = cloneDeep(state.events);
                // A map of eventId to edits for quick lookup
                const editMap = new Map(
                    edits.map((edit) => [edit.eventId, edit]),
                );

                const updatedEvents = events.map((event) => {
                    const edit = editMap.get(event.id);

                    if (!edit) {
                        return event;
                    }

                    const { addAttendees = [], removeAttendees = [], addAssets = [], removeAssets = [] } = edit;

                    const addAttendeeIds = new Set(
                        addAttendees.map((attendee) => attendee.user.id),
                    );

                    const addAssetIds = new Set(
                        addAssets.map((addAsset) => addAsset.id),
                    );

                    const newAttendees = event.attendees
                        .filter(
                            (attendee) => !addAttendeeIds.has(attendee.user.id),
                        )
                        .concat(
                            removeAttendees.map((removedAttendee) => ({
                                role: removedAttendee.role,
                                user: removedAttendee.user,
                            })),
                        );

                    const newAssets = event.assets
                        .filter((asset) => !addAssetIds.has(asset.id))
                        .concat(
                            removeAssets.map((removedAsset) => ({
                                id: removedAsset.id,
                                name: removedAsset.name,
                                type: 'Truck',
                            })),
                        );

                    return {
                        ...event,
                        attendees: newAttendees,
                        assets: newAssets,
                    };
                });

                return {
                    ...state,
                    events: updatedEvents,
                    callState: {
                        ...state.callState,
                        updateCrew: {
                            error: error.message,
                        },
                    },
                };
            },
        ),
    ),
});
