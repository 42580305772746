
<span
    [pTooltip]="showStatusText ? 'Status' : ( status | titlecase )"
    [tooltipPosition]="tooltipPosition"
    [style.fontSize]="fontSize"
>
    <i
        *ngIf="status === 'pending'"
        class="pi pi-clock"
    ></i>
    <i
        *ngIf="status === 'generating'"
        class="pi pi-spinner pi-spin"
    ></i>
    <i
        *ngIf="status === 'error'"
        class="pi pi-exclamation-triangle"
    ></i>
    <i
        *ngIf="status === 'completed'"
        class="pi pi-check-circle"
    ></i>

    <span *ngIf="showStatusText">
        {{ status }}
    </span>
</span>
