import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { GetConfigValuesGQL, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { ObjectEditColumn } from '../../shared/object-edit-table/object-edit-table.component';

@Component({
  selector: 'app-franchise-inventory-items',
  templateUrl: './franchise-inventory-items.component.html',
  styleUrls: ['./franchise-inventory-items.component.scss']
})
export class FranchiseInventoryItemsComponent implements OnInit, OnDestroy {

  private subs = new SubSink();

  inventoryItems: any[];

  inventoryColumns: ObjectEditColumn[] = [
    {
      property: 'reviewerName',
      type: 'string',
      header: 'Name',
      width: '100px',
    },
    {
      property: 'label',
      type: 'string',
      header: 'Label',
    },
    {
      property: 'volume',
      type: 'number',
      header: 'Volume',
    },
    {
      property: 'weight',
      type: 'number',
      header: 'Weight',
    },
    {
      property: 'isoCode1',
      type: 'string',
      header: 'ISO Code 1',
    },
    {
      property: 'isoCode2',
      type: 'string',
      header: 'ISO Code 2',
    },
    {
      property: 'isoCode3',
      type: 'string',
      header: 'ISO Code 3',
    },
    {
      property: 'rooms',
      type: 'array',
      header: 'Rooms',
    },
    {
      property: 'aliases',
      type: 'array',
      header: 'Aliases',
    },
    {
      property: 'isBulky',
      type: 'boolean',
      header: 'Bulky',
    },
    {
      property: 'isValuable',
      type: 'boolean',
      header: 'Valuable',
    }
  ];

  pSetConfig$ = this.permissionHandler.watchPermissions(['config.set'])
    .pipe(
      map((res) => res[0]),
      startWith(false),
    );

  constructor(
    // GQL
    private setConfigGQL: SetConfigValuesGQL,
    private getConfigGQL: GetConfigValuesGQL,
    // HELPERS
    private localNotify: FreyaNotificationsService,
    public permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    //  require('../../estimates/estimating-inventory/inventory-items.json');
    this.getConfigGQL.watch({keys: ['inventory.items']}).valueChanges.subscribe((res) => {
      this.inventoryItems = JSON.parse(res.data.getConfigValues[0].value);
    });
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  saveInventoryItems(inventoryItems: any[]){
    console.log(inventoryItems);
    this.setConfigGQL.mutate({
      configs: [{
        key: 'inventory.items',
        value: JSON.stringify(inventoryItems)
      }],
    }).subscribe((res) => {
      console.log(res);
      this.localNotify.success('Inventory Items Saved');
    });
  }

}
