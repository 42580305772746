<div [formGroup]="dynamicReportForm">
    <ng-template #name>
        <input
            type="text"
            pInputText
            formControlName="name"
            placeholder="Name *"
        />
    </ng-template>
    <ng-template #description>
        <textarea
            placeholder="Description *"
            pInputTextarea
            formControlName="description"
            class="full-width"
        ></textarea>
    </ng-template>
</div>
<app-mutate-object
    #mutate
    [fg]="dynamicReportForm"
    objectType="Revenue Report"
    mutateType="create"
    [steps]="steps"
    (objectMutated)="createDynamicReport()"
></app-mutate-object>
