<!-- <h5>{{eventName}} <i
        *ngIf="loading"
        class="pi pi-spin pi-spinner"
    ></i>
    <span *ngIf="event?.status">({{event.status | titlecase}})</span>
</h5> -->
<!-- <hr> -->
<p-message
    *ngIf="(job?.users | jobCustomer) === 'None'"
    severity="warn"
    text="Customer Required"
    styleClass="mr-2"
></p-message>
<p-message
    *ngIf="job?.zone?.type !== 'area'"
    severity="warn"
    text="Your job needs to be in an area to book events"
    styleClass="mr-2"
></p-message>
<p-message
    *ngIf="missingLocations?.value.length && estimateHelper.restrictionsEnabled.value"
    severity="warn"
    text="Missing Locations: {{missingLocations?.value | join}}"
    styleClass="cursor-pointer mr-2"
    (click)="goToLocationTab()"
>
    <ng-template pTemplate="content">
        Missing Locations:
        <ul>
            <li *ngFor="let location of missingLocations">
                {{ location | titlecase }}
            </li>
        </ul>
    </ng-template>
</p-message>
<!-- <p-message
    *ngIf="!job | jobLocation : 'start'"
    severity="warn"
    text="Start Location Required"
    styleClass="mr-2"
></p-message>
<p-message
    *ngIf="eventType === 'moving' && !(job | jobLocation : 'end') && estimateHelper.restrictionsEnabled.value"
    severity="warn"
    text="End Location Required"
    styleClass="mr-2"
></p-message> -->
<div [formGroup]="eventForm" [hidden]="event" class="event-form-flex">

    <div *ngIf="!event?.start">
        <strong>Date:</strong>
        <!-- This is also disabled/enabled in the "reset" method -->
        <p-calendar
            class="full-width-calendar"
            dataType="string"
            formControlName="date"
            (onSelect)="findTimes(); changeCalendarDate()"
            appendTo="body"
            [minDate]="freyaHelperService.lockDateObject$ | async"
        ></p-calendar>
        <small *ngIf="freyaHelperService.lockDate$ | async">You must schedule events after {{ formattedLockDate.short }} (Lock Date)</small>
    </div>

    <div *ngIf="!event?.start">
        <strong>Start Time: </strong>
        <!-- Restrictions enabled (start time) -->
        <div [hidden]="!estimateHelper.restrictionsEnabled.value">
            <p-dropdown
                class="full-width-dropdown"
                placeholder="Select Time"
                (onChange)="setPossibleAssets()"
                [options]="possibleTimes ? possibleTimes : []"
                formControlName="time"
                appendTo="body"
            >
                <ng-template let-time pTemplate="item">
                    <p *ngIf="time === 'Now'">Now</p>
                    <p *ngIf="time !== 'Now'">{{ time | freyaDate: "h:mm a": timezone }}</p>
                </ng-template>
                <ng-template let-time pTemplate="selectedItem">
                    <p *ngIf="time === 'Now'">Now</p>
                    <p *ngIf="time !== 'Now'">{{ time | freyaDate: "h:mm a": timezone }}</p>
                </ng-template>
            </p-dropdown>
        </div>
        <!-- Restrictions enabled (end time time) -->
        <div *ngIf="!estimateHelper.restrictionsEnabled.value">
            <p-calendar
                showTime="true"
                placeholder="Select Time"
                [hourFormat]="12"
                [timeOnly]="true"
                formControlName="time"
                appendTo="body"
                [minDate]="freyaHelperService.lockDateObject$ | async"
            >
            </p-calendar>
            <strong>⚠️ Restrictions Disabled ⚠️</strong>
        </div>
        <!-- Time estimates: -->
        <div
            *ngIf="totalLocationTime !== undefined"
        >
            Location Time: {{ totalLocationTime | secondsToTimeUnit }}
        </div>
        <div
            *ngIf="totalTravelTime !== undefined"
        >
            Travel Time: {{ totalTravelTime | secondsToTimeUnit }}
        </div>
            <small *ngIf="hasLockedWindows">Some times are not shown because they conflict with the lock date ({{ formattedLockDate.long }})</small>
    </div>

    <div *ngIf="!event?.start">
        <strong>Assets: </strong>
        <br />
        <p-multiSelect
            class="full-width-multiselect"
            placeholder="Select Asset"
            [options]="possibleAssets ? possibleAssets : []"
            formControlName="assets"
            filterBy="name"
            appendTo="body"
            [autofocusFilter]="!responsiveHelper.isSmallScreen"
        >
            <ng-template let-asset pTemplate="item">
                <p>{{ asset.name }}</p>
            </ng-template>
            <ng-template let-asset pTemplate="selectedItems">
                <p *ngFor="let a of eventForm.value.assets">{{ a.name }}</p>
                <p *ngIf="!eventForm.value.assets?.length">Select Assets</p>
            </ng-template>
        </p-multiSelect>
        <small
            >Searching for Types:
            <span *ngFor="let a of assetTypes">{{ a }},</span></small
        >

            <!-- <div class="full-width">
                <br>
                <strong>Crew Size: </strong>
                <br>
                <p-inputNumber
                    class="full-width-number-input"
                    formControlName="crewSize"
                    min="1"
                    max="10"
                    showButtons="true"
                >
                </p-inputNumber>
            </div> -->

    </div>

    <div *ngIf="!event?.start">
        <strong>Include travel time for:</strong>
        <br>
        <p-checkbox
            [binary]="true"
            label="Dock to Start Location"
            formControlName="dockToStart"
        ></p-checkbox>
        <br />
        <br />
        <p-checkbox
            [binary]="true"
            label="End Location to Dock"
            formControlName="endToDock"
        ></p-checkbox>
        <!-- <p>
            <strong>Drive Time:</strong>
            <span *ngIf="driveTime"> {{driveTime | secondsToTime}} </span>s
            <span *ngIf="!driveTime"> Missing Values for Calculation</span>
        </p> -->
        <!-- <br>
        <br> -->
    </div>
    <div *ngIf="!event?.start">
        <button
            *hasPermission="['calendarEvents.create']"
            pButton
            class="full-width quick-margin"
            label="Book"
            icon="pi pi-calendar-plus"
            [disabled]="!eventForm.touched || eventForm.invalid || (loading$ | async) === true"
            (click)="tryBookEvent()"
        ></button>
    </div>
    <!-- AR_TODO: Fix this janky templating logic -->
    <div *ngIf="event?.start">
        <h6>Date</h6>
        <span class="freya-link-button" (click)="goToEventDate(event.start)">{{ event.start | freyaDate: 'MMM d, y': timezone }}</span>
        <div class="p-mt-2">
            <app-object-state
                *ngIf="event?.status === 'cancelled'"
                [object]="event"
                objectType="event"
                [colorful]="true"
            ></app-object-state>
        </div>
    </div>
    <div *ngIf="event?.start">
        <h6>Start / End</h6>
        <p>{{ event | eventDuration: false :false :true :timezone }}</p>
    </div>
    <div *ngIf="event?.start">
        <h6>Start / End (Dock to Dock)</h6>
        <p>{{ event | eventDuration: true :false :true :timezone }}</p>
    </div>
    <div *ngIf="event?.start">
        <h6>Assets</h6>
        <p *ngFor="let a of event.assets">{{ a.name }}</p>
    </div>
    <div *ngIf="event?.start">
        <!-- <h6>Crew Size</h6>
        <p>{{crewSize || 'Not Set'}}</p> -->
        <span
            [pTooltip]="isLocked ? 'This event ends before ' + formattedLockDate.short : undefined"
            tooltipPosition="bottom"
            >
            <button
                *ngIf="event.status === 'pending'"
                [disabled]="!job?.users?.length || !job?.users[0].user || (loading$ | async) === true || isLocked"
                pButton
                class="full-width quick-margin"
                icon="pi pi-calendar-plus"
                label="Book"
                (click)="rebookUncancelledEvent()"
                ></button>
        </span>
        <span
            [pTooltip]="isLocked ? 'This event ends before ' + formattedLockDate.short : undefined"
            tooltipPosition="bottom"
        >
            <button
                *ngIf="event.status === 'cancelled'"
                pButton
                class="full-width quick-margin"
                label="Undo Cancel"
                [disabled]="(loading$ | async) === true || isLocked"
                (click)="eventHelper.uncancelEvent(event)"
                icon="pi pi-undo"
            ></button>
            <button
                *ngIf="event.status !== 'cancelled' && event.status !== 'completed' && event.status !== 'pending'"
                pButton
                class="full-width p-button-danger quick-margin"
                label="Cancel"
                [disabled]="(loading$ | async) === true || isLocked"
                (click)="eventHelper.unscheduleEvent(event)"
                icon="pi pi-ban"
            ></button>
        </span>
        <div
            *ngIf="
                (event.title === 'Time Lock' && !job?.users?.length) ||
                !job?.users?.[0].user
            "
        >
            <p-message
                severity="warn"
                text="Events cannot be booked until a customer has been set"
            ></p-message>
        </div>
    </div>
</div>
<p-message
    *ngIf="jobInDifferentTimezoneWarning"
    severity="warn"
    [text]="jobInDifferentTimezoneWarning"
>
</p-message>

