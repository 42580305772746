import { Pipe, PipeTransform } from '@angular/core';
import {ApolloError} from '@apollo/client/core';


import { getApolloErrorMessage } from '../utilities/errors.util';

@Pipe({
  name: 'apolloErrorMessage'
})
export class ApolloErrorMessagePipe implements PipeTransform {

  transform(err: ApolloError): string {
    return getApolloErrorMessage(err);
  }

}
