import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { Subject, debounceTime } from 'rxjs';
import { SubSink } from 'subsink';

import { BaseDynamicReportFragment, DynamicReportsGQL, DynamicReportsQuery, DynamicReportsQueryVariables, ZoneDir } from '../../../generated/graphql.generated';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

@Component({
  selector: 'app-dynamic-reports',
  templateUrl: './dynamic-reports.component.html',
  styleUrl: './dynamic-reports.component.scss'
})
export class DynamicReportsComponent implements OnInit, OnDestroy {

  dynamicReportsQH: WatchQueryHelper = {
    skip: 0,
    limit: 10,
    loading: true,
    search: '',
  }

  dynamicReportsQueryRef: QueryRef<DynamicReportsQuery, DynamicReportsQueryVariables>;

  dynamicReports: BaseDynamicReportFragment[] = [];

  subs = new SubSink();

  filterUpdated = new Subject<void>();

  constructor(private dynamicReportsGQL: DynamicReportsGQL) {}

  ngOnInit(): void {
    this.subs.sink = this.filterUpdated
      .pipe(debounceTime(800)).subscribe(() => {
        this.dynamicReportsQH.skip = 0;
        this.retrieveDynamicReports();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveMoreReports(event: LazyLoadEvent) {
    this.dynamicReportsQH.skip = event.first;
    this.dynamicReportsQH.limit = event.rows;
    this.retrieveDynamicReports();
  }

  retrieveDynamicReports() {

    if (this.dynamicReportsQueryRef) {
      this.dynamicReportsQueryRef.refetch({
        skip: this.dynamicReportsQH.skip,
        limit: this.dynamicReportsQH.limit,
        filter: {
          zoneDir: ZoneDir.Any,
          search: this.dynamicReportsQH.search,
        },
      });
      return;
    }

    this.dynamicReportsQueryRef = this.dynamicReportsGQL.watch({
      skip: this.dynamicReportsQH.skip,
      limit: this.dynamicReportsQH.limit,
      filter: {
        zoneDir: ZoneDir.Any,
        search: this.dynamicReportsQH.search,
      },
    }, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    });

    this.dynamicReportsQueryRef.valueChanges.subscribe((res) => {
      this.dynamicReportsQH.loading = res.loading;

      if (res.loading) { return; }

      this.dynamicReportsQH.total = res.data.dynamicReports.total;
      this.dynamicReports = res.data.dynamicReports.reports;
    });
  }

  clearSearch() {
    this.dynamicReportsQH.search = '';
    this.retrieveDynamicReports();
  }
}
