import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ZoneService } from '@karve.it/core';
import { TaxService } from '@karve.it/features';
import { ListTaxesFunctionOutput, ListTaxesOutput, Tax } from '@karve.it/interfaces/taxes';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { pagination } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { MutateTaxComponent } from 'src/app/shared/mutate-tax/mutate-tax.component';
import { SubSink } from 'subsink';

import { WatchQueryHelper } from '../../utilities/watchQueryHelper';


@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit, OnDestroy {

  @ViewChild('taxSearchInput', { static: true }) taxSearchInput!: ElementRef;
  @ViewChild('mutate', { static: true }) mutateRef!: MutateTaxComponent;

  subs = new SubSink();

  // Component Variables
  taxes: Tax[] = [];

  // Query Variables
  taxesQueryRef!: QueryRef<ListTaxesFunctionOutput>;
  taxesQH: WatchQueryHelper = {
    limit: 5,
    search: '',
    loading: true,
    total: 0
  };

  pagination = pagination;

  constructor(
    private taxesService: TaxService,
    private zoneService: ZoneService,
    private dhs: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    // this.subs.sink = this.dhs.taxesUpdated.subscribe((res) => {
    //   this.taxesQueryRef.refetch();
    // });
    this.subs.sink = this.dhs.getObjectUpdates('Taxes').subscribe((res) => {
      this.taxesQueryRef.refetch();
    });

    this.subs.sink = fromEvent(this.taxSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.taxesQH.skip = 0;
      this.searchForTaxes();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  retrieveTaxs() {
    this.taxesQueryRef = this.taxesService.watchtaxes({ limit: this.taxesQH.limit });

    this.subs.sink = this.taxesQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.taxes = res.data.taxes.taxes;
        this.taxesQH.total = res.data.taxes.total;
        this.taxesQH.loading = false;
      }
    });
  }

  retrieveMoreTaxes(event: LazyLoadEvent) {
    this.taxesQH.limit = event.rows;
    this.taxesQH.skip = event.first;

    this.searchForTaxes();
  }

  searchForTaxes() {
    this.taxesQH.loading = true;

    if (this.taxesQueryRef) {
      const taxInput = {
        filter: {
          nameSearch: this.taxesQH.search,
        },
        limit: this.taxesQH.limit,
        skip: this.taxesQH.skip,
      };

      this.taxesQueryRef.resetLastResults();
      this.taxesQueryRef.setVariables(taxInput);

    } else {
      this.retrieveTaxs();
    }
  }

  selectTax(tax: Tax) {
    this.dhs.detailsItem.next({ type: 'tax', item: { id: tax.id } });
  }

  openCreateDialog() {
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }

}
