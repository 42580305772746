import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, dayjs } from '@karve.it/core';
import { CalendarEventService } from '@karve.it/features';
import { RawUser } from '@karve.it/interfaces/auth';
import { CalendarEvent, GenerateCalendarEventsQueryInput, ListCalendarEventsOutput } from '@karve.it/interfaces/calendarEvents';
import { Job, ListJobsFunctionOutput } from '@karve.it/interfaces/jobs';
import { ListUsersV2Output } from '@karve.it/interfaces/users';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { ArtifactsComponent } from 'src/app/artifacts/artifacts.component';
import { CommentsComponent } from 'src/app/comments/comments.component';
import { JOB_ROLE_MAP, pagination } from 'src/app/global.constants';
import { JobsComponent } from 'src/app/jobs/jobs.component';
import { strToTitleCase } from 'src/app/js';
import { LocationsComponent } from 'src/app/locations/locations.component';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { PageTitleService } from 'src/app/services/page-title.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { MutateUserComponent } from 'src/app/shared/mutate-user/mutate-user.component';
import { TransactionsComponent } from 'src/app/transactions/transactions.component';
import { SubSink } from 'subsink';

import { ListFieldsGQL, ListFieldsQueryVariables, ListHistoryFilter, SetFieldValuesGQL } from '../../../generated/graphql.generated';
import { FreyaMutateService } from '../../services/freya-mutate.service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateUserComponent;

  // Component Refs
  @ViewChild('jobs', { static: false }) jobsRef: JobsComponent;
  @ViewChild('locations', { static: false }) locationsRef: LocationsComponent;
  @ViewChild('transactions', { static: false }) transactionsRef: TransactionsComponent;
  @ViewChild('comments', { static: false }) commentsRef: CommentsComponent;
  @ViewChild('artifacts', { static: false }) artifactsRef: ArtifactsComponent;

  pageItemPagination = cloneDeep(pagination);

  subs = new SubSink();

  permissions = {
    createUser: false,
    editUser: false,
  };

  // User Variables
  user: RawUser;
  activeTabIndex = 0;

  tabs = ['overview', 'jobs', 'locations', 'payments', 'documents', 'notifications'];
  hasIndexLoaded = [false, false, false, false, false];

  // Job Variables
  jobs: Job[];
  jobTotal = 0;
  jobsLoading = true;

  // Event Variables
  events: CalendarEvent[];
  eventsTotal = 0;
  eventsLoading = true;

  hasScrolled = false;

  jobConstants = JOB_ROLE_MAP;

  // Query Refs
  userQueryRef: QueryRef<ListUsersV2Output>;
  jobsQueryRef: QueryRef<ListJobsFunctionOutput>;
  eventsQueryRef: QueryRef<ListCalendarEventsOutput>;

  // Notifications
  notificationHistoryFilter: ListHistoryFilter = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_IN: ['sent sms', 'sent email', 'sent push notification', 'sent local notification']
  };

  smsOptIn;
  smsOptInWatchQuery: ReturnType<typeof this.listFieldsGQL.watch>;
  smsOptInSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private eventService: CalendarEventService,
    public detailsHelperService: DetailsHelperService,
    private angularLocation: Location,
    private router: Router,
    private permissionHandler: PermissionService,
    private titleService: PageTitleService,
    private recentItems: RecentItemsService,
    private freyaMutate: FreyaMutateService,
    private setFieldValuesGQL: SetFieldValuesGQL,
    private listFieldsGQL: ListFieldsGQL,
  ) {
    this.subs.sink = this.route.params.subscribe(params => {
      this.retrieveUser(params.id);
      this.user = { id: params.id };
      if (params.tab) {
        this.changeTabByIndex(this.tabs.indexOf(params.tab));
      }
    });
  }

  ngOnInit(): void {
    this.pageItemPagination.defaultNumberOfItems = 5;
    this.pageItemPagination = cloneDeep(this.pageItemPagination);

    const pagePermissions = ['users.create', 'users.edit'];

    this.subs.sink = this.permissionHandler.watchPermissions(pagePermissions).subscribe((res) => {
      this.permissions.createUser = res[0];
      this.permissions.editUser = res[1];
    });

    this.subs.sink = this.detailsHelperService.getObjectUpdates('User').subscribe(() => {
      this.userQueryRef.resetLastResults();
      this.userQueryRef.refetch();
    });
  }

  ngOnDestroy() {
    this.watchSMSOptIn(false);
    this.subs.unsubscribe();
  }

  retrieveUser(id: string) {
    const userQueryInput = {
      locations: true,
      roles: true,
      zonesWithParent: true,
    };

    this.userQueryRef = this.userService.watchUsersV2({ userIds: [id] }, userQueryInput);

    this.subs.sink = this.userQueryRef.valueChanges.subscribe((res) => {
      if (res.data.usersv2.users?.length === 0) {
        this.router.navigate(['/notfound']);
        return;
      }

      if (res.networkStatus === 7) {
        this.user = res.data.usersv2.users.find((u) => u.id === id);
        this.recentItems.addToRecentItems(this.user, 'users');
        this.updatePageTitle();
        this.watchSMSOptIn(true);

        if (this.commentsRef) {
          this.initComments();
        } else {
          setTimeout(() => {
            this.initComments();
          }, 1000);
        }
      }
    });
  }

  updatePageTitle() {
    if ( !this.user ) { return; };
    const tabName = strToTitleCase(this.tabs[this.activeTabIndex]);
    const title = `${this.user.givenName} ${this.user.familyName}: ${tabName}`;

    this.titleService.setCustomPageTitle({
      pageTitle: title,
    });
  }

  initComments() {
    this.commentsRef.commentsFilter = { objectIds: [this.user.id] };
    this.commentsRef.objectId = this.user.id;
    this.commentsRef.fetchComments();
  }

  retrieveEvents(userId: string) {
    const eventQueryInput = {} as GenerateCalendarEventsQueryInput;

    this.eventsQueryRef = this.eventService.watchCalendarEvents(
      {
        filter: {
          users: [userId],
          min: Math.floor(dayjs(new Date()).valueOf() / 1000),
        },
        limit: 3,
      }, eventQueryInput);

    this.subs.sink = this.eventsQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.events = res.data.calendarEvents.events;
        this.eventsTotal = res.data.calendarEvents.total;
        this.eventsLoading = false;
      }
    });
  }

  changeTabByIndex(index: number) {
    this.activeTabIndex = index;
    this.angularLocation.go(`user/${this.user.id}/${this.tabs[index]}`);
    this.updatePageTitle();

    this.hasIndexLoaded[index] = true;

    if (index === 0) {
      this.retrieveEvents(this.user.id);
    }
  }

  openEditUser() {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'user',
      object: this.user,
    });
  }

  openEditTabItem(item: string) {
    if (item === 'location') {
      this.changeTabByIndex(2);
      if (this.hasLazyLoaded(this.locationsRef, 'location')) {
        this.locationsRef.openMutateDialog();
      };
    } else if (item === 'transaction') {
      this.changeTabByIndex(3);
      if (this.hasLazyLoaded(this.transactionsRef, 'transaction')) {
        this.transactionsRef.openMutateDialog();
      };
    } else if (item === 'comment') {
      this.commentsRef.openMutateComment('create');
    }
  }

  hasLazyLoaded(ref, item: string) {
    if (!ref) {
      setTimeout(() => {
        this.openEditTabItem(item);
      }, 250);
    }

    return ref as boolean;
  }

  bookJob(){
    this.router.navigate(['estimating'], {queryParams: { step: 'customer', userId: this.user.id}});
  }


  /***
   * Notification Options
   */

  updateSmsOptIn() {
    this.setFieldValuesGQL.mutate({
      objects: [ this.user.id ],
      objectLabel: 'User',
      fields: [{
        fieldName: 'notifications.sms',
        value: this.smsOptIn,
      }]
    }, {
      refetchQueries: [ 'listFields' ],
    }).subscribe((res) => {
      // console.log(res);
    }, (err) => {
      console.error(err);
    });
  }

  setSMSOptInVariables() {
    return this.smsOptInWatchQuery.setVariables(this.getSMSOptInVariables());
  }

  watchSMSOptIn(enable?: boolean) {
    enable = enable === undefined ? !this.smsOptInWatchQuery : enable;

    if (!enable) {
      this.smsOptInSub.unsubscribe();
      delete this.smsOptInWatchQuery;
      this.smsOptIn = undefined;
      return;
    }

    if (enable && this.smsOptInWatchQuery) {
      this.setSMSOptInVariables();
      return;
    }

    this.smsOptInWatchQuery = this.listFieldsGQL.watch(this.getSMSOptInVariables(), {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.smsOptInSub = this.smsOptInWatchQuery.valueChanges.subscribe((res) => {
      // console.log(res);
      const fields = res?.data?.fields?.fields || [];
      const [ field ] = fields;
      const [value] = field?.values || [];
      this.smsOptIn = value?.value;
    });
  }

  getSMSOptInVariables(): ListFieldsQueryVariables {
    return {
      objectLabels: ['User'],
      objects: [ this.user.id ],
      filter: {
        names: [
          'notifications.sms'
        ],
      }
    };
  }
}
