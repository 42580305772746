<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="franchises-card-header">Business Units</h4>
        </div>
        <!-- <div class="p-inputgroup p-col">
            <span class="p-input-icon-left">
                <i *ngIf="!franchisesQueryLoading" class="pi pi-search"></i>
                <i *ngIf="franchisesQueryLoading" class="pi pi-spin pi-spinner"></i>
                <input style="width: 100%;" type="text" #franchiseSearchInput pInputText [(ngModel)]="franchisesQuerySearch" placeholder="Search">
            </span>
            <button type="button" pButton pRipple icon="pi pi-times" styleClass="p-button-danger" (click)="franchisesQuerySearch = ''; searchForFranchises()"></button>
        </div> -->
        <div class="p-col">
            <button
                pButton
                *ngIf="canCreateFranchise"
                icon="pi pi-plus"
                label="Create Unit"
                (click)="openMutateBusinessUnit()"
            ></button>
        </div>
    </div>
</div>

<div class="card">
    <p-table [value]="zones" dataKey="name"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreFranchises($event)"
        [lazy]="true"
        [totalRecords]="total"
        [(rows)]="franchisesQueryLimit"
        [rowsPerPageOptions]="[5,10,25,50,100,250]"
        [loading]="franchisesQueryLoading"
        [showCurrentPageReport]="!franchisesQueryLoading"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Areas</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-franchise let-expanded="expanded">
            <tr class="selectable-row" (click)="viewFranchiseDetails(franchise)">
                <td>
                    <span class="p-column-title">Name:</span>
                    <span class="bold-on-mobile">{{franchise.name}}</span>
                </td>
                <td>
                    <span class="p-column-title">Areas:</span>
                    {{franchise.children.total}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">{{franchisesEmptyMessage}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
