import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { firstValueFrom } from 'rxjs';

import { AvailableZonesAndCurrentZoneGQL, BaseJobFragment, BaseZoneWithParentFragment, Job_ZoneFragment, ServiceAreaQueryMatch } from '../../../generated/graphql.generated';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { resolveChildrenFromID } from '../../utilities/zones.util';

export interface SelectAreaDialogData {
  job: BaseJobFragment & Job_ZoneFragment;
  description?: string;
  onlyShowCurrentSubzones?: boolean;
  onlyShowAreas?: boolean;
  showAreaWarning?: boolean;
  closestAreas?: ServiceAreaQueryMatch[];
  showCancel?: boolean;
}

export interface SelectAreaDialogResult {
  error?: Error;
  zone?: BaseZoneWithParentFragment;
  job: BaseJobFragment;
}

export interface SelectAreaZone extends BaseZoneWithParentFragment {
  closestArea?: ServiceAreaQueryMatch;
  closestAreaIndex?: number;
}

@Component({
  selector: 'app-select-area-dialog',
  templateUrl: './select-area-dialog.component.html',
  styleUrls: ['./select-area-dialog.component.scss']
})
export class SelectAreaDialogComponent implements OnInit {

  availableZones: SelectAreaZone[];
  loading = true;

  form = new UntypedFormGroup({
    selectedZone: new UntypedFormControl(undefined, [ Validators.required ]),
  });

  get data() {
    return (this.dialogConfig?.data || {}) as SelectAreaDialogData;
  }

  constructor(
    private availableZonesAndCurrentZoneGQL: AvailableZonesAndCurrentZoneGQL,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private freyaHelper: FreyaHelperService,
  ) {
  }

  ngOnInit(
  ): void {
    this.form.reset();

    this.resolveAvailableZones()
      .catch((error) => {
        console.error(`Error loading areas to select`, error);
        this.dialogRef.close({ error, });
      });
  }

  close() {
    this.dialogRef.close({});
  }

  submit() {
    if (!this.form.valid) { return; }
    this.dialogRef.close({
      zone: this.form.value.selectedZone,
      job: this.data.job,
    });
  }

  async resolveAvailableZones() {

    this.availableZones = undefined;
    this.loading = true;

    const { data } = await firstValueFrom(this.availableZonesAndCurrentZoneGQL
      .fetch({
        skip: 0,
        limit: 1500,
        search: '',
      }));

    this.loading = false;

    let zones = cloneDeep(data.availableZones) as SelectAreaZone[];
    if (this.data.onlyShowCurrentSubzones) {
      zones = resolveChildrenFromID(data.availableZones, data.currentZone.id);
    }

    if (this.data.onlyShowAreas) {
      zones = zones.filter((zone) => zone.type === 'area');
    }

    let closestAreaIndex = 0;
    for (const closestArea of this.data.closestAreas || []) {
      const zone = cloneDeep(zones.find((z) => z.id === closestArea.zoneId));
      closestAreaIndex++;
      if (!zone) { continue; }
      zone.closestArea = closestArea;
      zone.closestAreaIndex = closestAreaIndex;
    }

    zones = zones.sort((a, b) => {
        const aIsJobZone = a.id === this.data.job?.zone?.id;
        const bIsJobZone = b.id === this.data.job?.zone?.id;
        if (aIsJobZone && !bIsJobZone) { return -1; }
        if (bIsJobZone && !aIsJobZone) { return 1; }
        const aIsJobParentZone = a.parent?.id === this.data.job?.zone?.parent?.id;
        const bIsJobParentZone = b.parent?.id === this.data.job?.zone?.parent?.id;
        if (aIsJobParentZone && !bIsJobParentZone) { return -1; }
        if (bIsJobParentZone && !aIsJobParentZone) { return 1; }

        if (a.closestArea || b.closestArea) {
          // check if either or none are in closest areas then return
          if (a.closestArea && !b.closestArea) { return -1; }
          if (b.closestArea && !a.closestArea) { return 1; }
          // both are in closest areas, so compare them
          return a.closestAreaIndex - b.closestAreaIndex;
        }

        if (this.data.onlyShowAreas) {
          if (a.parent?.name < b.parent?.name) { return -1; }
          if (a.parent?.name > b.parent?.name) { return 1; }
        }

        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    });

    this.availableZones = zones.map((z: any) => {
      const clone = cloneDeep(z);
      clone.search = `${ clone.name } ${ clone.parent?.name || '' }`.toLowerCase();
      return clone;
    });

    if (this.data?.job?.zone) {
      const currentAvailableZone =this.availableZones.find((j) => j.id === this.data.job.zone.id);
      if (currentAvailableZone) {
        this.form.setValue({
          selectedZone: currentAvailableZone,
        });
      }
    }

    return data;
  }

}
