import { Component } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { fromPairs } from 'lodash';

import { transactionVolumeChartOptions } from '../../dashboard/dashboard.constants';
import { DashboardStore } from '../dashboardv2.store';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './activity.component.scss',
  ],
})
export class ActivityComponent {

  activity$ = this.dashboardStore.select(
    this.dashboardStore.activityViewModel$,
    (activity) => {

      const transactionsPaidMap = fromPairs(activity.transactionsHappeningPerDay);

      const eventsCreatedMap = fromPairs(activity.eventsCreatedPerDay);

      const eventsHappeningMap = fromPairs(activity.eventsHappeningPerDay);

      const dates = [];

      const labels = [];

      const transactionsPaidData = [];

      const eventsCreatedData = [];

      const eventsHappeningData = [];

      let current = activity.period.start;

      while (current <= activity.period.end) {
        const day = dayjs(current * 1000);
        dates.push(day.format('YYYY-MM-DD'));
        if (activity.isMonthMode) {
          labels.push(day.format('DD'));
        } else {
          labels.push(day.format('YYYY-MM-DD'));
        }
        current = day.add(1, 'day').unix();
      }

      for (const date of dates) {

        if (transactionsPaidMap[date]) {
          transactionsPaidData.push(transactionsPaidMap[date]);
        } else {
          transactionsPaidData.push(0);
        }

        if (eventsCreatedMap[date]) {
          eventsCreatedData.push(eventsCreatedMap[date]);
        } else {
          eventsCreatedData.push(0);
        }

        if (eventsHappeningMap[date]) {
          eventsHappeningData.push(eventsHappeningMap[date]);
        } else {
          eventsHappeningData.push(0);
        }
      }

      const documentStyle = getComputedStyle(document.documentElement);

      return {
        ...activity,
        options: transactionVolumeChartOptions,
        graphData: {
          labels,
          datasets: [
            {
              label: "Transactions paid",
              data: transactionsPaidData,
              borderColor: [
                documentStyle.getPropertyValue('--primary-color'),
              ],
              borderWidth: 3,
            },
            {
              label: 'Events created',
              data: eventsCreatedData,
              borderColor: [
                documentStyle.getPropertyValue('--orange-500'),
              ],
              borderWidth: 3,
            },
            {
              label: 'Events Happening',
              data: eventsHappeningData,
              borderColor: [
                documentStyle.getPropertyValue('--info'),
              ],
              borderWidth: 3,
            },
          ],
        },
      }
    },
  );

  constructor(
    private readonly dashboardStore: DashboardStore,
  ) {}

}
