import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import {QueryRef} from 'apollo-angular';


import { cloneDeep } from 'lodash';

import { SubSink } from 'subsink';

import { GetJobLocationsGQL, GetJobLocationsQuery, JobPageListJobsGQL, JobPageListJobsQuery } from '../../../generated/graphql.generated';
import { EventSelectComponent } from '../../custom-inputs/event-select/event-select.component';

import { JobLocation } from '../../estimates/job-info/job-info.component';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';

@Component({
  selector: 'app-job-location-select',
  templateUrl: './job-location-select.component.html',
  styleUrls: ['./job-location-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: JobLocationSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: JobLocationSelectComponent,
    }
  ]
})
export class JobLocationSelectComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy, OnChanges {

  @Output() locationsSelected = new EventEmitter();

  @Input() jobId: string;

  // Allows you to override the placeholder for the component
  @Input() placeholder = 'Select Locations';

  @Input() limit = undefined;

  @Input() removeLocationIds: string[] = [];

  subs = new SubSink();

  jobsQueryRef: QueryRef<GetJobLocationsQuery>;
  // The locations on the job
  jobLocations: JobLocation[] = [];

  // What to actually display in the dropdown
  jobLocationSuggestions: JobLocation[] = [];

  // FORM CONTROL VARIABLES
  selectedLocations: JobLocation[] = [];
  touched = false;
  disabled = false;

  constructor(
    private jobsGQL: GetJobLocationsGQL,
    public responsiveHelper: ResponsiveHelperService,
  ) { }

  ngOnInit(): void {
    this.retrieveLocations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setJobLocationSuggestions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveLocations(){
    if (!this.jobId) { return; }

    if (this.jobsQueryRef){
      this.jobsQueryRef.refetch();
      return;
    }

    // Needs to be no-cache to prevent issues with other job queries ie. estimating
    this.jobsQueryRef = this.jobsGQL.watch({filter: {jobIds: [this.jobId]}}, {fetchPolicy: 'no-cache'});

    this.subs.sink = this.jobsQueryRef.valueChanges.subscribe((result) => {
      console.log(result);

      if (!result.data.jobs?.jobs?.length) { return; }
      console.log(this.removeLocationIds);

      this.jobLocations = result.data.jobs.jobs[0].locations;

      this.setJobLocationSuggestions();
    });
  }

  setJobLocationSuggestions(){
    this.jobLocationSuggestions = this.jobLocations.filter((location) => !this.removeLocationIds.includes(location.locationId));
  }

  emitSelect() {
    this.locationsSelected.emit(this.selectedLocations);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (selectedEvents) => { };

  onTouched = () => {
    this.touched = true;
  };

  writeValue(locations: JobLocation[]) {
    this.selectedLocations = cloneDeep(locations);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

}
