import { Attendee, CalendarEvent, JobUser } from '../../../generated/graphql.generated';
import { JOB_ROLE_MAP } from '../../global.constants';

export interface FCEventHolder {
    id: string;
    start: number;
    end: number;
    assetIds: string[];
}

// Get list of each location's arrive unixtime
export function getArrivalTimeList(event: CalendarEvent){
    const arrivalTimeList: number[] = [];
    let initArriveTime: number;
    let travelTime: number;
    let arrivalTime: number;

    if (event?.locations) {
        initArriveTime = event?.start;
        for (const [index, eventLocation] of event.locations.entries()) {
            travelTime = eventLocation.travelTimeToNextLocation + eventLocation.travelTimeToNextLocationOffset;
            if (arrivalTime) {
                arrivalTime = arrivalTime + eventLocation.estimatedTimeAtLocation + travelTime;
            } else {
                // Event first location
                arrivalTimeList[0] = initArriveTime;
                arrivalTime = initArriveTime + eventLocation.estimatedTimeAtLocation + travelTime;
            }
            arrivalTimeList[index + 1] = arrivalTime;
        }
    }

    return arrivalTimeList;
}

export function getEventCustomer(event: CalendarEvent): JobUser{
    if (!event) { return; }
    if (!event?.job?.users) { return; }

    return event.job.users.find((att) => att?.role === JOB_ROLE_MAP.customerRole);
}

export function getEventCustomerName(event: CalendarEvent): string{
    if (!event) { return 'N/A'; }

    const customer = getEventCustomer(event);

    if (!customer) { return 'No Customer';}
    if (!customer?.user) { return 'Deleted user';}
    return `${customer.user.givenName} ${customer.user.familyName}`;
}


