import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { Inplace } from 'primeng/inplace';
import { SubSink } from 'subsink';

import { PermissionService, WatchPermissionsAndRestrictionsInput } from '../../services/permission.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';

@Component({
  selector: 'app-edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrl: './edit-in-place.component.scss'
})
export class EditInPlaceComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(Inplace) inplace: Inplace;

  @Input() hasPermission = false;

  @Input() label?: string;

  @Input() value: string | number;

  @Input() contentType: 'text' | 'date' = 'text';

  @Input() formInput: 'text' | 'textarea' | 'calendar' = 'text';

  @Input() loading = false;

  @Input() editPermissions: string | string[] | WatchPermissionsAndRestrictionsInput[];

  @Output() saveButtonPressed = new EventEmitter<{
    value: string | number;
    deactivate: () => void;
  }>();

  subs = new SubSink();

  editableValue: string;

  saving = false;

  dateFormat = 'mm/dd/yy';

  timeFormat = 12;

  dayJsFormat = 'MM/DD/YYYY hh:mm A';

  constructor(
    private permissionsHandler: PermissionService,
    private timeZoneHelper: TimezoneHelperService,
  ) {}

  ngOnInit(): void {
    this.setEditableValue();
    this.checkPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.setEditableValue();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  discardChanges(){
    this.setEditableValue();
    this.inplace.deactivate();
  }

  save() {
    this.saving = true;

    this.saveButtonPressed.emit({
      value: this.getEditedValue(),
      deactivate: () => {
        this.saving = false;
        setTimeout(() => this.inplace.deactivate(), 0);
      },
    });
  }

  setEditableValue() {
    if (!this.value) return;
    switch (this.contentType) {
      case 'date':
        if (typeof this.value !== 'number') throw new Error('Date content type must be a number');
        this.editableValue = this.parseUnixTimestamp(this.value);
        break;
      case 'text':
        if (typeof this.value !== 'string') throw new Error('Text content type must be a string');
        this.editableValue = this.value;
        break;
    }
  }

  getEditedValue() {
    switch (this.contentType) {
      case 'date':
        return this.getUnixTimestamp(this.editableValue);
      case 'text':
        return this.editableValue;
    }
  }

  parseUnixTimestamp(unixTimestamp: number) {
    return dayjs.tz(unixTimestamp * 1000, this.timeZoneHelper.getCurrentTimezone()).format(this.dayJsFormat);
  }

  getUnixTimestamp(timeString: string) {
    return dayjs(timeString, this.dayJsFormat).tz(this.timeZoneHelper.getCurrentTimezone(), true).unix();
  }

  checkPermissions() {
    // If no permissions are provided
    if (!this.editPermissions || Array.isArray(this.editPermissions) && !this.editPermissions.length) {
      this.hasPermission = true;
      return;
    }

    const isArrayOfPermissionsAndRestrictions = Array.isArray(this.editPermissions) && typeof this.editPermissions[0] !== 'string';

    if (isArrayOfPermissionsAndRestrictions) {
      this.subs.sink = this.permissionsHandler
        .watchPermissionsAndRestrictions(this.editPermissions as WatchPermissionsAndRestrictionsInput[])
        .subscribe((res) => {
          this.hasPermission = res.every(Boolean);
        });
    } else {
      this.subs.sink = this.permissionsHandler.watchAllPermissions(this.editPermissions as string | string[]).subscribe((res) => {
        this.hasPermission = Boolean(res);
      });
    }
  }

  handleKeydown(event: KeyboardEvent, saveOnEnter = true) {
    if (event.key === 'Enter' && saveOnEnter) {
      this.save();
    } else if (event.key === 'Escape') {
      this.discardChanges();
    }
  }

}
