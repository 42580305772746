import packageInfo from '../../package.json';
import { DistanceUnit } from '../app/global.constants';
import { S_TWO_YEARS } from '../app/time';

const defaultTimezone = 'America/Chicago';
const defaultCurrency = 'USD';
const defaultCountry  = 'USA';

function getEnvironmentName() {

    return 'local';
    // const host = window.location.host;

    // if (host.includes('localhost')) {
    //     return 'local';
    // } else if (host.endsWith('dev')) {
    //     return 'dev';
    // } else 

    // return 'prod';

}

export const baseEnvironment = {
    version: packageInfo.version,
    production: false,
    environment: getEnvironmentName(),

    apiUrl: '/api/graphql',
    websocket: `ws://${ window.location.host }/api/subscriptions`,
    rememberLength: S_TWO_YEARS,
    webSocketsEnabled: true,
    fullCalendarKey: '0084305944-fcs-1713458431',
    stripeKey: 'pk_test_51IyeIeEqLnMOxUJVfc77FXL83L8zAmTKp80FjtK01OBo8wwQGYUCmY8Qk41B6miS8Oi6LjRuy80XTlmB3ravS55R0089K9wFR1',
    maxFileSize: 1024000000,
    defaultCountry,
    defaultUnits: 'imperial' as DistanceUnit,
    defaultCurrency,
    defaultTimezone,
    approximateSqftPerCarGarage: 264,
    defaultMapCoordinates: [
        38.46980359832875, -98.64888115721854
    ],
    lsDashboardPeriod: 'dashboard-period',
    overrideCode: '4934',
    copyright: 'Karve IT',
    googlePlacesApiKey: 'AIzaSyCxxKX_SulTK1Dfx_mFnCkB4NYivSdHVFg',

    /**
     * The default password minimum length. This is usually overriden
     * by the password auth method which contains information about
     * password requirements, such as min length.
     */
    passwordMinLength: 7,

    breakAtGraphqlErrors: false,

    featureFlags: {
        // apiURL: 'https://gitlab.com/api/v4/feature_flags/unleash/42476215',
        // instanceId: 'rYMc_dHyfHCeFoxwbHyx',
        clientKey: 'testing',
    },

    lskeys: {
        periodDropdownCount: 'period_dropdown_counts',
        lastBrandingZone: 'last_branding_zone',
        scheduleDate: 'schedule-date',
        scheduleView: 'schedule-view',

        createJobState: 'create_job_state',
        lastEditedJobState: 'last_edited_jobs_state',
    },

    defaultBranding: {
        appName: 'Move Right',
        country: defaultCountry,
        timezone: defaultTimezone,

        companyName: 'Move Right',
        website: 'https://moveright.app',

        email: 'moveright@karveit.ca',

		ico: '/assets/icons/favicon.png',
		iconDark48:   '/assets/icons/icon-dark-48.png',
		iconDark64:   '/assets/icons/icon-dark-64.png',
		iconDark128:  '/assets/icons/icon-dark-128.png',
		iconDark196:  '/assets/icons/icon-dark-196.png',
		iconDark256:  '/assets/icons/icon-dark-256.png',
		iconDark512:  '/assets/icons/icon-dark-512.png',
		iconDark1024: '/assets/icons/icon-dark-1024.png',
		iconDarkWide: '/assets/icons/icon-dark-Wide.png',
		iconLight48:   '/assets/icons/icon-light-48.png',
		iconLight64:   '/assets/icons/icon-light-64.png',
		iconLight128:  '/assets/icons/icon-light-128.png',
		iconLight196:  '/assets/icons/icon-light-196.png',
		iconLight256:  '/assets/icons/icon-light-256.png',
		iconLight512:  '/assets/icons/icon-light-512.png',
		iconLight1024: '/assets/icons/icon-light-1024.png',
		iconLightWide: '/assets/icons/icon-light-Wide.png',

        language: 'English',
        primaryColor: '#023047',
        secondaryColor: '#023047',
        shortName: 'Move Right',
        status: 'Active',
        zoneCode: 'MOVE',
        zoneId: 'moveright',
    },
};
