import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import { FullNotificationFragment, UserNotificationByIdGQL, UserNotificationByIdQuery, UserNotificationByIdQueryVariables } from '../../../generated/graphql.generated';
import { PlusAuthenticationService } from '../../core/public-api';
import { DetailsHelperService } from '../../services/details-helper.service';
import { NotificationsService } from '../../services/notifications.service';

export type ViewDetailsData = {
    label: string;
    command?: () => void;
    url?: string;
    icon?: string;
};

@Component({
  selector: 'app-notification-viewer',
  templateUrl: './notification-viewer.component.html',
  styleUrls: ['./notification-viewer.component.scss']
})
export class NotificationViewerComponent implements OnInit, OnDestroy {

  notification: FullNotificationFragment;
  notificationsQueryRef: QueryRef<UserNotificationByIdQuery, UserNotificationByIdQueryVariables>;
  viewDetailsData: ViewDetailsData;

  validUrl: string;

  loading = true;

  subs = new SubSink();

  constructor(
    private plusAuth: PlusAuthenticationService,
    private userNotificationById: UserNotificationByIdGQL,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe(({ notificationId }) => {
      if (!notificationId) { return; }
      this.retrieveNotification(notificationId);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveNotification(notificationId: string) {

    const vars: UserNotificationByIdQueryVariables = {
      userId: this.plusAuth.user.id,
      notificationId
    };

    if (this.notificationsQueryRef) {
      this.notificationsQueryRef.refetch(vars);
      return;
    }

    this.notificationsQueryRef = this.userNotificationById.watch(vars, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.notificationsQueryRef.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      if (res.loading) { return; }

      const [ user ] = res.data.usersv2.users;
      if (!user.notifications.notifications?.length) { return; }

      this.notification = user.notifications.notifications[0];
      this.validUrl = this.notification.url?.includes('event') ? undefined : this.notification.url;
      this.notificationsService.notificationRead.next(this.notification.id);
      this.viewDetailsData = this.getViewDetailsData();
    });

  }

  getViewDetailsData(): ViewDetailsData {
    const { actions } = this.notification;
    if (!actions?.length) { return; }
    const viewAction = actions.find((a) => a.name === 'view');
    if (!viewAction) { return; }

    const viewDetailsData: ViewDetailsData = {
      label: viewAction.formattedName,
    };

    if (viewAction.value.includes('events')) {

      const eventId = new URL(viewAction.value).pathname.replace('/events/', '');

      const eventZoneId = viewAction.extraInfo;

      viewDetailsData.command = () => this.detailsHelper.open('calendar-event', { id: eventId }, undefined, eventZoneId);

    } else {

      viewDetailsData.url = viewAction.value;
      viewDetailsData.icon = 'pi pi-external';
    }

    return viewDetailsData;
  }

}
