import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { JobService } from '@karve.it/features';
import { User } from '@karve.it/interfaces/auth';
import {QueryRef} from 'apollo-angular';
import { cloneDeep } from 'lodash';
import * as pluralize from 'pluralize';

import { JOB_EVENT_TYPES } from 'src/app/global.constants';
import { ChargeHelperService } from 'src/app/services/charge-helper.service';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { MutateType } from 'src/app/services/freya-mutate.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { ProductHelperService } from 'src/app/services/product-helper.service';
import { BaseTaxFragment, Charge, ChargeUpdate, CreateChargesGQL, Expense, FullProductFragment, Job, ListExpensesGQL, ListProductsGQL, ListProductsQuery, ListProductsQueryVariables, ListTaxesGQL, Product, UpdateChargesGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';

import { FreyaDatePipe } from '../freya-date.pipe';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { uniqueExpenseValidator } from '../unique-expense.validator';

export interface FreyaMutateComponent {
  mutateRef: MutateObjectComponent;
  mutateType: MutateType;
  steps: MutateObjectElement[];
  subs: SubSink;

  // Handles opening the dialog and any logic needed to initialize
  openDialog();

  // Handles the actual creation / updating of the values
  mutateObject();

  // Sets the Form Values equal to the provided input
  setFormValues();
}

@Component({
  selector: 'app-mutate-charges',
  templateUrl: './mutate-charges.component.html',
  styleUrls: ['./mutate-charges.component.scss']
})
export class MutateChargesComponent implements OnInit, FreyaMutateComponent, OnDestroy {

  @ViewChild('mutateRef') mutateRef: MutateObjectComponent;

  @ViewChild('product') productRef: TemplateRef<any>;
  @ViewChild('amount') amountRef: TemplateRef<any>;
  @ViewChild('quantity') quantityRef: TemplateRef<any>;
  @ViewChild('event') eventRef: TemplateRef<any>;
  @ViewChild('taxes') taxesRef: TemplateRef<any>;
  @ViewChild('expenses') expensesRef: TemplateRef<any>;


  @Input() charge: Charge;

  // OPTIONAL INPUTS
  @Input() isDamage = false; // If true we are creating a damage instead of a charge
  @Input() job: Job; // The job the charge is assigned to
  @Input() user: User; // The user the charge should be associated with
  @Input() prefilledChargeName: string;
  @Input() preselectedEventId: string;


  mutateType: MutateType;
  steps: MutateObjectElement[];
  subs = new SubSink();

  // FORM VALUES
  chargeForm = new UntypedFormGroup({
    product: new UntypedFormControl(undefined, [Validators.required]),
    amount: new UntypedFormControl(undefined, [Validators.required]),
    quantity: new UntypedFormControl(1, [Validators.required]),
    event: new UntypedFormControl(undefined, [Validators.required]),
    taxes: new UntypedFormControl([], []),
    subtotal: new UntypedFormControl(undefined, [Validators.required]),
    expenses: new UntypedFormArray([], uniqueExpenseValidator()),
  });
  chargeFormValues = this.chargeForm.value;

  taxOptions: BaseTaxFragment[] = [];

  isPercentCharge = false;

  // PRODUCTS VARIABLES
  productsQueryRef: QueryRef<ListProductsQuery, ListProductsQueryVariables>;
  productSuggestions: FullProductFragment[] = [];

  // EVENT VARIABLES
  eventOptions = [...JOB_EVENT_TYPES,'none'];

  expenseOptions: Expense[] = [];

  constructor(
    private freyaDatePipe: FreyaDatePipe,
    private listProductsGQL: ListProductsGQL,
    private productHelper: ProductHelperService,
    private chargeHelper: ChargeHelperService,
    private freyaHelper: FreyaHelperService,
    private jobService: JobService,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private createChargesGQL: CreateChargesGQL,
    private updateChargesGQL: UpdateChargesGQL,
    private listTaxesGQL: ListTaxesGQL,
    public responsiveHelper: ResponsiveHelperService,
    private listExpensesGQL: ListExpensesGQL,
  ) { }

  ngOnInit(): void {
    // Update subtotal when the amount changes
    this.subs.sink = this.chargeForm.controls.amount.valueChanges.subscribe((change) => {
      this.updateSubtotal();
    });

    // Update subtotal when the qunatity changes
    this.subs.sink = this.chargeForm.controls.quantity.valueChanges.subscribe((change) => {
      this.updateSubtotal();
    });

    // Set taxes when product changes
    this.subs.sink = this.chargeForm.controls.product.valueChanges.subscribe((product) => {
      this.setTaxes(product);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get expensesForm() {
    return this.chargeForm.get('expenses') as UntypedFormArray;
  }

  getPluralizedUnitLabel(inclusive: boolean = false) {
    return pluralize(this.charge?.product?.unitLabel || 'unit', this.chargeForm.value?.quantity, inclusive);
  }

  get productUnitLabel() {
      const unit = this.getPluralizedUnitLabel();
      return ` ${unit}`;
  }

  getQuantity() {
      return this.getPluralizedUnitLabel(true);
  }

  get singularProductUnit(){
    const unitLabel = this.charge?.product?.unitLabel;
    return unitLabel ? `(per ${pluralize.singular(unitLabel)})` : '';
  }

  openDialog() {
    this.getExpenses();

    this.setFormValues(); // Initialize the comonent for what we are doing

    this.setAmountValidators();

    this.steps = [
      {
        name: 'Product', ref: this.mutateType === 'create' ? this.productRef : undefined, control: 'product', type: 'func',
        reviewFunc: () => (this.chargeForm.value.product?.name || this.chargeForm.value.product)
      },
      { name: 'Amount', ref: this.amountRef, control: 'amount', type: 'func', reviewFunc: () => {

       const amount = this.chargeForm.controls.amount.value;
       return amount ? (this.isPercentCharge ? `${amount}%` : `$${amount}`) : null;
      }},
      { name: 'Quantity', ref: this.quantityRef, control: 'quantity', type: 'func',
        reviewFunc: () => this.getQuantity(), },
      { name: 'Event', ref: this.eventRef, control: 'event', type: 'func',
        reviewFunc: () => this.getEventInfo()
      },
      { name: 'Taxes', ref: this.taxesRef, control: 'taxes', type: 'array' },
      { name: 'Subtotal', ref: undefined, control: 'subtotal', type: 'currency', },
      {
        name: 'Costs', ref: this.expensesRef, control: 'expenses', type: 'func',
        reviewFunc: this.getExpenseTotal,
      },
    ];

    this.mutateRef.objectType = this.isDamage ? 'Damage' : 'Charge';

    this.mutateRef.steps = this.steps;
    this.mutateRef.currency = this.job?.currency;

    if (this.mutateType === 'update'){
      if (this.charge?.price?.priceType === 'percentage'){
        this.mutateRef.removeStep('Quantity');
      }
    }

    if (this.isDamage) {
      this.mutateRef.removeStep('Taxes');
    }

    this.mutateRef.openDialog();
    this.retrieveTaxes();
  }

  setFormValues() {
    if (this.mutateType === 'create') {
      this.chargeForm.reset(this.chargeFormValues);
      //to show input instead of autoComplete if user searched for product
      if (this.prefilledChargeName) {
        this.chargeForm.patchValue({
          product: this.prefilledChargeName,
        });
      }

      // Reset expenses form array
      this.expensesForm.clear();

    } else if (this.mutateType === 'update'){

      this.chargeForm.patchValue({
        product: this.charge.product || this.charge.productName,
        amount: this.chargeHelper.getChargeAmount(this.charge),
        quantity: this.charge.quantity,
        event: [this.charge.calendarEvent],
        taxes: this.charge.taxes,
      });

      const priceType = this.chargeHelper.getPriceType(this.charge);

      this.isPercentCharge = priceType === 'percentage';

      // Reset expenses form array
      this.expensesForm.clear();
      (this.charge.expensesV2 || []).map(expense => {
        const expenseFormGroup = this.createExpense({ id: expense.expense.id, unitCost: convertCentsToDollars(expense.unitCost)});
        this.expensesForm.push(expenseFormGroup); 
      })
    }
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createCharge();
    } else if (this.mutateType === 'update'){
      this.updateCharge();
    }
  }

  createCharge(){
    const userIdForCharge = this.user?.id || this.freyaHelper.getJobCustomerId(this.job);

    if (!userIdForCharge){
      this.localNotify.error(`Failed to create charge for job without a customer`);
    }

    const val = this.chargeForm.getRawValue();
    this.createChargesGQL.mutate({
      charges: [{
        quantity: val.quantity,
        productId: val.product?.id,
        productName: val.product?.id ? undefined : val.product,
        attributes: this.isDamage ? [ 'damage' ] : [],
        amount: val.product?.id ? undefined : convertDollarsToCents(val.amount),
        eventId: val.event[0]?.id,
        currency: this.job?.currency,
        taxIds: val.taxes ? val.taxes.map((t) => t.id) : [],
        // eventType: val.event[0],
        // attributes: this.freyaHelper.addAttribute([], `${CHARGE_EVENT_PREFIX}::${val.event}`),
      }],
      userId: userIdForCharge,
    }).subscribe((res) => {
      if (this.job) {
        this.jobService.updateJobs({
          updateJobs: [{
            jobId: this.job.id,
            addExistingCharges: [res.data.createCharges[0].id]
          }]
        }).subscribe((jobRes) => {
          this.mutateRef.closeDialog();
          this.localNotify.success('Charge created for job');
          this.detailsHelper.pushUpdate({
            id:this.job.id,
            type:'Jobs',
            action:'create'
          });
        }, (err) => {
          console.error(err);
          this.mutateRef.loading = false;
          this.localNotify.apolloError(`Error creating charge on job`,err);
        });
      } else {
        this.mutateRef.closeDialog();
        this.localNotify.success('Charge Created');
      }
    }, (err) => {
      console.error(err);
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Error creating charge`,err);
    });
  }

  updateCharge(){
    const val = this.chargeForm.getRawValue();

    const chargeUpdate: ChargeUpdate = {
      id: this.charge.id,
      quantity: val.quantity,
      eventId: val.event[0]?.id,
      productName: val.product?.id ? undefined : val.product,
      amount: this.isPercentCharge ? val.amount : convertDollarsToCents(val.amount),
    };

    chargeUpdate.addTaxIds = val.taxes
      .filter((newTax) => !this.charge.taxes.some((tax) => tax.id === newTax.id))
      .map((t) => t.id);

    chargeUpdate.removeTaxIds = this.charge.taxes
      .filter((oldTax) => !val.taxes.some((tax) => tax.id === oldTax.id))
      .map((t) => t.id);
    
    // expenses
    if (this.expensesForm.dirty) {
      const expenses = this.convertExpensesToCents();
      
      const originalExpenses = this.charge.expensesV2.map(e => ({...e.expense, unitCost: e.unitCost})) || [];
      const { added, removed, updated: updatedExpenses } = this.freyaHelper.getAddedRemovedUpdated(
      originalExpenses, expenses, true, ['unitCost'], 'id', 'expenseId'
      );

      if (added.length || removed.length || updatedExpenses.length) {
        chargeUpdate.addExpenses = added;
        chargeUpdate.removeExpenses = removed;
        chargeUpdate.updateExpenses = updatedExpenses;
      }
    }

    this.updateChargesGQL.mutate({
      charges: [ chargeUpdate ],
    }).subscribe((res) => {
      this.mutateRef.closeDialog();
      this.localNotify.success('Charge updated');
      this.detailsHelper.pushUpdate({
        id:this?.job?.id,
        type:'Jobs',
        action:'update',
        });

      this.detailsHelper.pushUpdate({
        id:this.charge.id,
        type:'Charges',
        action:'update',
        });
    }, (err) => {
      console.error(err);
      this.mutateRef.loading = false;
      this.localNotify.error('Failed to update charge');
    });


      // this.chargeService.updateCharges({
      //   charges: [{
      //     id: this.charge.id,
      //     // productId: val.product?.id,
      //     quantity: val.quantity,
      //     eventType: val.event,
      //     // attributes: this.freyaHelper.replaceAttributeByPrefix(
      //     //   this.charge.attributes,
      //     //   `${CHARGE_EVENT_PREFIX}::${val.event}`,
      //     //   `${CHARGE_EVENT_PREFIX}::`
      //     // ),
      //     productName: val.product?.id ? undefined : val.product,
      //     amount: val.product?.id ? undefined : convertDollarsToCents(val.amount),
      //   }]
      // }).subscribe((res) => {
      //   this.localNotify.success('Charge updated');
      //   this.detailsHelper.pushUpdate({
      //     id:this.job.id,
      //     type:'Jobs',
      //     action:'update',
      //     });

      //   this.detailsHelper.pushUpdate({
      //     id:this.charge.id,
      //     type:'Charges',
      //     action:'update',
      //     });
      // }, (err) => {
      //   console.error(err);
      //   this.localNotify.error('Failed to update charge');
      // });
  }

  searchProduct(event) {
    const context = this?.job?.zone?.id
        ? { headers: { 'x-zone': this.job.zone.id } }
        : {};

    if (this.isDamage) {
      this.productSuggestions = [];
      return;
    }
    if (this.productsQueryRef) {
        this.productsQueryRef.resetLastResults();
        this.productsQueryRef.setOptions({
            variables: { filter: { names: [event.query] } },
            fetchPolicy: 'no-cache',
            context,
        });
    } else {
      this.productsQueryRef = this.listProductsGQL.watch(
        {
          filter: {
            names: [event.query],
          },
        },
        {
          fetchPolicy: 'no-cache',
          context,
        },
      );

      this.subs.sink = this.productsQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          // TODO: Update this to filter by zone and active prices in the api once implemented
          this.productSuggestions = cloneDeep(
            this.productHelper.filterOutProductsWithInactivePrices(res.data.products.products)
          );
        } else {
          this.productSuggestions = cloneDeep(
            this.productHelper.filterOutProductsWithInactivePrices(this.productSuggestions)
          );
        }
      });
    }
  }

  retrieveTaxes() {
    this.subs.sink = this.listTaxesGQL.fetch({}).subscribe((res) => {

      if (res.loading) { return; }

      this.taxOptions = res.data.taxes.taxes;

      this.setTaxes(this.chargeForm.value.product);
    });
  }

  removeTax(tax: BaseTaxFragment) {
    const taxesAfterRemove = this.chargeForm.value.taxes.filter((t) => t.id !== tax.id);
    this.chargeForm.controls.taxes.setValue(taxesAfterRemove);
  }

  /**
   * Set's the amount value equal to the price of the selected product
   */
  setPriceAmount() {
    if (!this.chargeForm.value.product?.id) {
      // If no product was selected, that means it is a custom charge, so let them set a custom amount
      this.chargeForm.controls.amount.setValue(undefined);
      this.chargeForm.controls.amount.enable();
      return;
    }

    const price = this.productHelper.getActivePriceFromProduct(this.chargeForm.value.product);

    let amount: number;

    if (price.priceType === 'percentage'){
      amount = price.amount;
      this.mutateRef.removeStep('Quantity');
      this.isPercentCharge = true;
    } else {
      this.isPercentCharge = false;
      this.mutateRef.addStep('Quantity');
      amount = price.amount / 100;
    }

    this.chargeForm.controls.amount.setValue(amount);
    this.chargeForm.controls.amount.disable();

    if (this.isDamage && amount > 0) {
      this.forceNegativeAmount();
    }
  }

  forceNegativeAmount() {
    if (!this.isDamage) { return; }

    let amount = this.chargeForm.getRawValue().amount;

    if (amount > 0) {
      amount *= -1;
    }

    this.chargeForm.controls.amount.setValue(amount);
  }

  updateSubtotal() {
    const val = this.chargeForm.getRawValue(); // Needed to also include disabled controls
    this.chargeForm.controls.subtotal.setValue(val.amount * val.quantity);
  }

  setAmountValidators() {

    const amountCtrl = this.chargeForm.controls.amount;

    if (this.isDamage) {
      amountCtrl.addValidators(Validators.max(0));
    } else {
      amountCtrl.setValidators(Validators.required);
    }

    amountCtrl.updateValueAndValidity();
  }

  onStepChange(change: { prev: number; curr: number }) {

    if (!this.isDamage) { return; }

    const amountStepIndex = this.steps.findIndex((s) => s.control === 'amount');

    if (change.prev === amountStepIndex) {
      this.forceNegativeAmount();
    }
  }

  setTaxes(product: FullProductFragment | string | null) {

    // No taxes on damages
    if (this.isDamage) { return; }

    // Taxes haven't loaded
    if (!this.taxOptions?.length) { return; }

    // If user selected a product, get taxes from active price
    const productSet = product && typeof product !== 'string';

    const activePrice = productSet ? this.productHelper.getActivePriceFromProduct(product as Product) : undefined;

    if (activePrice) {
      this.chargeForm.controls.taxes.setValue(activePrice.taxes);
      return;
    }

    // Otherwise, use zone defaults
    const defaultTaxes = this.taxOptions.filter((t) => t.isZoneDefault);

    if (!defaultTaxes?.length || this.mutateType === 'update') { return; }

    this.chargeForm.controls.taxes.setValue(defaultTaxes);
  }

  getEventNameStart(title, start): string {
    let result = '';
    if (title) {
      result += `${title}: `;
    }
    if (!start) {
      result += 'Unscheduled';
    }
    if (start) {
      result += this.freyaDatePipe.transform(start, 'h:mm a, MMM d');
    }
    return result;
  };

  getEventInfo() {
    const event = this.chargeForm.value?.event;
    return (event?.length && this.getEventNameStart(event[0]?.title, event[0]?.start));
  }

  // Expenses CRUD
  getExpenses() {
    this.listExpensesGQL.fetch({}).subscribe((res) => {
      if(res.loading) return;
      this.expenseOptions = res.data?.expenses?.expenses || [];
    });
  }

  convertExpensesToCents() {
    return this.expensesForm.value.map((expense: Expense) => ({...expense, unitCost: convertDollarsToCents(expense.unitCost)}));
  }

  // Expenses CRUD UI Helpers

  // Method to create an expense form group
  createExpense(expense: Pick<Expense, 'id' | 'unitCost'>): UntypedFormGroup {
    return new UntypedFormGroup({
        expenseId: new UntypedFormControl(expense?.id, Validators.required),
        unitCost: new UntypedFormControl(expense?.unitCost, [Validators.required, Validators.min(0.01)])
    });
  }

  onRowDelete(index: number) {
    this.expensesForm.removeAt(index);
    this.expensesForm.markAsDirty();
  }

  onRowAdd() {
      this.expensesForm.push(this.createExpense({ id: null, unitCost: 0 }));
  }

  getExpenseTotal(val: Pick<Expense, 'unitCost'>[]) {
    const amount = val.reduce((acc, expense) => acc + expense.unitCost, 0);
    return `${val.length || 0} ($${amount})`;
  }



}
