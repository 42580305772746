<section class="p-mt-4">
	<header>
		<h2>Summary</h2>
		<ng-container *ngIf="!isEditMode; else cancelButton">
			<p-dropdown
				class="summaryDropdown"
				[options]="summaryOptions"
				[(ngModel)]="selectedSummary"
				optionLabel="label"
				optionValue="value"
				size="small"
			></p-dropdown>
			<p-button
				class="editButton"
				icon="pi pi-pencil"
				[text]="true"
				(onClick)="editSummary()"
			/>
		</ng-container>
	</header>
	<ng-container *ngIf="jobLoaded$ | async; else loading">

		<main *ngIf="!isEditMode; else summaryEditForm">
			<quill-editor
				#editor
				class="summary"
				[readOnly]="true"
				[(ngModel)]="jobSummaries[selectedSummary].summary"
				[format]="jobSummaries[selectedSummary].format"
				[modules]="{ toolbar: false }"
				placeholder=""
			></quill-editor>
		</main>
	</ng-container>
</section>

<ng-template #summaryEditForm>
	<app-jobv2-summary
		[renderHeadless]="true"
		[defaultActiveTabIndex]="selectedSummaryIndex"
		*ngIf="isEditMode"
	></app-jobv2-summary>
</ng-template>

<ng-template #cancelButton>
	<div class="flex">
		<p-button
			class="cancelButton p-mr-2"
			[outlined]="true"
			label="Cancel"
			(onClick)="cancelEditSummary()"
		/>
		<p-button
			class="saveButton"
			icon="pi pi-check"
			label="Save"
			(onClick)="updateJob()"
		/>
	</div>
</ng-template>

<ng-template #loading>
	<main class="p-py-3">
		<p-skeleton
			width="80%"
			height="3rem"
		/>
	</main>
</ng-template>