import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { generateMailtoLink } from '../../utilities/generic.util';

@Component({
  selector: 'app-contact-accounting',
  templateUrl: './contact-accounting.component.html',
  styleUrls: [
    '../../dynamic-dialog-styling.scss',
    './contact-accounting.component.scss',
  ],
})
export class ContactAccountingComponent implements OnInit {

  to: string;

  subject: string;

  body: string;

  mailtoLink: string;

  constructor(
    private config: DynamicDialogConfig,
    private clipboard: Clipboard,
    private notify: FreyaNotificationsService
  ) {}

  ngOnInit(): void {
      const { to, subject, body } = this.config.data;

      this.to = to;
      this.subject = subject;
      this.body = body;
      this.mailtoLink = generateMailtoLink({ to, subject, body });
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.notify.success('Copied to clipboard.');
  }
}
