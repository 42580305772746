import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { CreateDiscountsInput, createDiscountsMutation, CreateDiscountsOutput, DeleteDiscountsInput, deleteDiscountsMutation, DeleteDiscountsOutput, DiscountsInput, discountsQuery, GenerateDiscountsQuery, GenerateDiscountsQueryInput, ListDiscountsFunctionOutput, ListDiscountsOutput, UpdateDiscountsInput, updateDiscountsMutation, UpdateDiscountsOutput } from '@karve.it/interfaces/discounts';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

    constructor(private apollo: PlusApollo){}

    listDiscounts(input: DiscountsInput, queryInput: GenerateDiscountsQueryInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListDiscountsFunctionOutput>({
            query: GenerateDiscountsQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchDiscounts(input: DiscountsInput, queryInput: GenerateDiscountsQueryInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListDiscountsFunctionOutput>({
            query: GenerateDiscountsQuery(queryInput),
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createDiscounts(input: CreateDiscountsInput){
        return this.apollo.mutate<CreateDiscountsOutput>({
            mutation: createDiscountsMutation,
            variables: {
                ...input
            }
        });
    }

    updateDiscounts(input: UpdateDiscountsInput){
        return this.apollo.mutate<UpdateDiscountsOutput>({
            mutation: updateDiscountsMutation,
            variables: {
                ...input
            }
        });
    }

    deleteDiscounts(input: DeleteDiscountsInput){
        return this.apollo.mutate<DeleteDiscountsOutput>({
            mutation: deleteDiscountsMutation,
            variables: {
                ...input
            }
        });
    }


}
