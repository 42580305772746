import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';

import { BaseTokenFragment, CreateLeadSourceTokenGQL, LeadSourceTokenInput } from '../../../generated/graphql.generated';
import { LeadSourceActions } from '../../marketing/lead-source-settings/lead-source-store/lead-source.actions';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';


@Component({
  selector: 'app-mutate-lead-source',
  templateUrl: './mutate-lead-source.component.html',
  styleUrl: './mutate-lead-source.component.scss'
})
export class MutateLeadSourceComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('origin') originRef: TemplateRef<any>;
  @ViewChild('source') sourceRef: TemplateRef<any>;
  @ViewChild('customerType') customerTypeRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() token: BaseTokenFragment;

  steps: MutateObjectElement[];

  subs = new SubSink();

  form = new FormGroup({
    source: new FormControl('', [ ]),
    origin: new FormControl('', [ ]),
    customerType: new FormControl('', [ ]),

    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    // metadata: new FormControl(true, [ ]),
  });

  defaultValues = this.form.value;

  constructor(
    private cd: ChangeDetectorRef,
    private createLeadSourceTokenGQL: CreateLeadSourceTokenGQL,
    private store: Store,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked(){
    this.cd.detectChanges();
  }

  openDialog() {
    this.steps = [
      {name: 'Origin', ref: this.originRef, control: 'origin', type: 'text'},
      {name: 'Source', ref: this.sourceRef, control: 'source', type: 'text'},
      {name: 'Customer Type', ref: this.customerTypeRef, control: 'customerType', type: 'text'},
      {name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},

    ];
  
    if (this.mutateType === 'create') {
      this.form.reset();
    } else if (this.mutateType === 'update') {

    }

    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;

    this.mutateRef.openDialog();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.create();
    } else if (this.mutateType === 'update') {
      // if (this.form.dirty) {
      //   this.updateTax();
      // }
    }
  }

  create() {
    const value = this.form.value;
    console.log('create lead source', value);

    this.subs.sink = this.createLeadSourceTokenGQL.mutate({
      input: {
        ...value as LeadSourceTokenInput,
      },
    }).subscribe({
      next: (res) => {
        const token = res.data.createLeadSourceToken;
        if (!token) { return; }

        console.log(res.data);
        this.detailsHelper.open('LeadSource', token);
        this.detailsHelper.pushUpdate({
          id: token.id,
          type: 'LeadSource',
          action: 'create'
        });
        this.mutateRef.closeDialog();
        this.localNotify.addToast.next({
          severity: 'success',
          summary: 'Lead Source created',
          detail: value.name,
        });

        this.store.dispatch(LeadSourceActions.created({ token }));
      },
      error: (error) => {
      this.mutateRef.loading = false;
      console.error(error);
      this.localNotify.apolloError(`Failed to create lead source`, error);
      },
    });


    // after create:
    // open in right panel
    // open instructions
    // emit event?

    // const createInput = {
    //   ...this.form.value
    // } as CreateTaxInput;

    // this.subs.sink = this.taxService.createTax(createInput).subscribe(async (res) => {
    //   await this.freyaHelper.saveZoneChanges([], this.form.value.zones, [res.data.createTax.id]);
    //   this.detailsHelper.pushUpdate({
    //     id: res.data.createTax.id,
    //     type: 'Taxes',
    //     action: 'create'
    //   });
    //   this.mutateRef.closeDialog();
    //   this.localNotify.addToast.next({severity: 'success', summary: 'Tax created'});
    // }, (err) => {
    //   this.mutateRef.loading = false;
    //   this.localNotify.apolloError(`Failed to create Tax`,err);
    // });
  }

  generateNameIfEmpty() {

    let newName = this.form.value.name || [
      this.form.value.origin,
      this.form.value.source,
      this.form.value.customerType,
    ].filter(Boolean).join(' ');

    if (!newName) {
      newName = `Empty rule generated ${ (new Date()).toString() } }`;
    }

    this.form.get('name').setValue(newName);
  }

  // updateTax(){
  //   const val = this.form.value;

  //   const zoneChanges = this.freyaHelper.saveZoneChanges(this.tax.zones.nodes, this.form.value.zones, [this.tax.id]);

  //   const updateInput = {
  //     taxId: this.tax.id,
  //     active: val.active,
  //     description: val.description,
  //     isZoneDefault: val.isZoneDefault,
  //     name: val.name
  //   } as UpdateTaxInput;

  //   this.subs.sink = this.taxService.updateTax(updateInput).subscribe(async (res) => {
  //     await zoneChanges;
  //     this.detailsHelper.pushUpdate({
  //       id:updateInput.taxId,
  //       type: 'Taxes',
  //       action: 'update',
  //     });
  //     this.mutateRef.closeDialog();
  //     this.localNotify.addToast.next({severity: 'success', summary: 'Tax updated'});
  //   }, (err) => {
  //     this.localNotify.apolloError(`Failed to update tax`,err);
  //     this.mutateRef.loading = false;
  //   });
  // }


}
