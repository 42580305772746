import { Component, OnDestroy, OnInit } from '@angular/core';
import { Confirmation } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

export type ConfirmationWithLoadingStateInput<T> = Omit<Confirmation, 'accept'> & {
  acceptObservable: Observable<T>;
  onAcceptSuccess: (res?: any) => void;
  onAcceptErr?: (err?: any) => void;
};

@Component({
  selector: 'app-confirmation-with-loading-state',
  templateUrl: './confirmation-with-loading-state.component.html',
  styleUrls: ['../../dynamic-dialog-styling.scss', './confirmation-with-loading-state.component.scss']
})
export class ConfirmationWithLoadingStateComponent implements OnInit, OnDestroy {

  confirmation: ConfirmationWithLoadingStateInput<any>;

  loading = false;

  subs = new SubSink();

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {

    const confirmationInput = this.config.data || {};

    this.confirmation = {
      acceptIcon: 'pi pi-check',
      acceptLabel: 'Yes',
      acceptVisible: true,
      rejectLabel: 'No',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-confirmationdialog-acceptbutton',
      rejectButtonStyleClass: 'p-confirmationdialog-rejectbutton',
      defaultFocus: 'accept',
      ...confirmationInput,
    };
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  accept() {
    this.loading = true;
    this.subs.sink = this.confirmation.acceptObservable.subscribe((res) => {
      this.confirmation.onAcceptSuccess(res);
      this.loading = false;
      this.ref.close();
    }, (err) => {
      if (this.confirmation.onAcceptErr) {
        this.confirmation.onAcceptErr(err);
      }

      this.loading = false;
    });
  }

  reject() {

    if (this.confirmation.reject) {
      this.confirmation.reject();
    }

    this.ref.close();

  }

}
