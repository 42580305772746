import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../../generated/graphql.generated';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(user: User): unknown {
    if (!user || user.deletedOn){
      return 'Deleted User';
    }

    return `${user.givenName} ${user.familyName}`;
  }

}
