<div class="p-grid">
  <div class="p-col-12">
    <div class="marketing-title">
      <h3>Dropdown Options</h3>
    </div>

    <app-how-heard-options></app-how-heard-options>
  </div>

  <div class="p-col-12">
    <div class="marketing-title">
      <h3>Lead Sources</h3>

      <p-button
        label="Add Lead Source"
        [raised]="true"
        (click)="leadSourceSettings.addLeadSource()"
      />
    </div>

    <app-lead-source-settings #leadSourceSettings></app-lead-source-settings>
  </div>
</div>
