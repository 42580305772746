<button
  class="p-button-secondary"
  pButton
  pRipple
  label="Actions"
  (click)="menu.toggle($event)"
  icon="pi pi-angle-down"
  ></button>
<p-menu
  #menu
  [popup]="true"
  appendTo="body"
  [model]="actions"
  ></p-menu>
