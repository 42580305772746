
export function safeParseJSON<T extends Record<any, any>>(value: string | T, defaultValue?: T): T {

    if (value === null || value === undefined) {
        return defaultValue;
    }

	if (typeof value === 'object') {
		return value;
	}

    if (typeof value === 'string') {
        try {
            return JSON.parse(value);
        } catch {}
    }
	return defaultValue;
}

export function strToTitleCase(str: string, seperator = ' ', exceptions = [
    'and',
    'or',
    'the',
]) {
    let split = str.split(seperator);

    split = split.map((v, i) => {
        if (!v?.length) { return v; }

        if (exceptions.includes(v) && i !== 0) {
            return v;
        }


        return v[0].toUpperCase() + v.slice(1);
    });

    return split.join(seperator);
}

export function arrPushIfNotExists<T>(arr: T[], obj: T) {
    const exists = arr.includes(obj);
    if (!exists) {
        return arr.push(obj);
    }

    return undefined;
}


export function arrRemoveValue<T>(arr: T[], obj: T) {
    const index = arr.indexOf(obj);
    if (index >= 0) {
        return arr.splice(index, 1);
    }

    return undefined;
}

/**
 * Returns the value passed or undefined if it is falsy
 */
export function orUndefined(value: any){
    return value ? value : undefined;
}

/**
 * Add an object to an array if it exists, or init a new array and add the obj
 *
 * @param array Array object that may or may not be initilaized
 * @param obj Object you are trying to add to the array
 * @returns The updated array or a new array with the object as it's only element
 */
export function arrPushOrInit<T>(array: T[], obj: T): T[]{
    if(array){
        array.push(obj);
        return array;
    }

    if (obj){
        return [obj];
    }

    return [];
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable no-bitwise */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function hash_cyrb53(str: string, seed = 0) {
    let h1 = 0xDEADBEEF ^ seed; let h2 = 0x41C6CE57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

/**
 * Removes special characters from any substring that looks like a phone number,
 * e.g "Arturo (123) 456-7890" -> "Arturo 1234567890"
 */
export function sanitizePhoneNumber(text: string): string {
    const phoneRegex = new RegExp(`(\\+?( |-|\\.)?\\d{1,2}( |-|\\.)?)?(\\(?\\d{3}\\)?|\\d{3})( |-|\\.)?\\d{3}( |-|\\.)?\\d{4}`, 'g');
    // Replace special characters
    return text.replace(phoneRegex, (match) => match.replace(/[^\d]/g, ''));
  }
  
