import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { GetPandadocDocumentLinkInput, GetPandadocDocumentLinkOutput, getPandadocDocumentLinkQuery, ListPandadocTemplatesOutput, PandadocTemplatesInput, pandadocTemplatesQuery, ResendDocumentInput, resendDocumentMutation, ResendDocumentOutput, CreateDocumentFunctionOutput, CreateDocumentInput, createDocumentMutation } from '@karve.it/interfaces/pandadocs';

import { KarveWatchRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class PandaDocService {

    constructor(private apollo: PlusApollo){}

    listpandadocTemplates(input: PandadocTemplatesInput){
        return this.apollo.query<ListPandadocTemplatesOutput>({
            query: pandadocTemplatesQuery,
            variables: {
                ...input
            }
        });
    }

    watchpandadocTemplates(input: PandadocTemplatesInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListPandadocTemplatesOutput>({
            query: pandadocTemplatesQuery,
            variables: {
                ...input
            }
        });
    }

    createDocument(input: CreateDocumentInput){
        return this.apollo.mutate<CreateDocumentFunctionOutput>({
            mutation: createDocumentMutation,
            variables: {
                ...input
            }
        });
    }

    resendDocument(input: ResendDocumentInput){
        return this.apollo.mutate<ResendDocumentOutput>({
            mutation: resendDocumentMutation,
            variables: {
                ...input
            }
        });
    }

    getDocumentLink(input: GetPandadocDocumentLinkInput){
        return this.apollo.mutate<GetPandadocDocumentLinkOutput>({
            mutation: getPandadocDocumentLinkQuery,
            variables: {
                ...input
            }
        });
    }
}
