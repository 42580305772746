<div class="p-grid">
    <div class="p-col-12">
        <div class="card device-status">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="card-header">
                        <div class="card-title">
                            <h6>
                                {{ title }}
                            </h6>
                        </div>
                    </div>
                    <div
                        class="p-grid p-nogutter"
                        *ngIf="data"
                    >
                        <table class="p-col-12 events">
                            <thead>
                                <th>Event Type</th>
                                <th
                                    pTooltip="subtotal including discounts but without damages"
                                >
                                    Discounted Subtotal
                                </th>
                                <th>Count</th>
                                <th>Average</th>
                            </thead>
                            <tr
                                class="device"
                                *ngFor="let eventType of data"
                            >
                                <td class="label">
                                    {{
                                        eventType.type
                                            | titlecase
                                            | rename
                                                : "Estimating"
                                                : "OSEstimate"
                                                : true
                                            | rename
                                                : "Virtualestimate"
                                                : "VOSEstimate"
                                                : true
                                    }}
                                </td>
                                <td class="status">
                                    {{
                                        eventType.discountedSubTotalWithoutDamages / 100
                                            | currency : currency
                                    }}
                                </td>
                                <td class="status">
                                    {{ eventType.count }}
                                </td>
                                <td class="status">
                                    {{
                                        eventType.average / 100
                                            | currency : currency
                                    }}
                                </td>
                            </tr>

                            <!--  SUM THESE -->
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>
                                        {{
                                            sumSubTotal() / 100
                                            | currency : currency
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            sumCount() || 0
                                        }}
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
