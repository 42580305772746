<p-tabMenu [model]="tabs" [scrollable]="true"></p-tabMenu>
<div class="p-pt-4">
    <router-outlet></router-outlet>
</div>

<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="18px"
    ></p-skeleton>
</ng-template>
