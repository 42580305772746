import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { JobV2FormActionButtonComponent } from "../jobv2-form-action-button/jobv2-form-action-button";

@Component({
    selector: 'app-discard-job',
    standalone: true,
    imports: [
      FreyaCommonModule,
      JobV2FormActionButtonComponent
    ],
    templateUrl: './discard-job.component.html',
    styleUrls: ['./discard-job.component.scss']
  })
  export class DiscardJobComponent {

    constructor(
      private store: Store,
      public ref: DynamicDialogRef,
    ) { }

    closeDiscardDialog(closed: boolean){
        this.ref.close(closed);
    }

    discardOpportunity() {
      this.store.dispatch(JobCreateActions.discardJob());
      this.closeDiscardDialog(true);
    }
  }