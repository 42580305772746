import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommentService } from '@karve.it/features';
import { AddCommentFunctionInput, Comment, ListCommentsOutput, UpdateCommentInput } from '@karve.it/interfaces/comments';
import {QueryRef} from 'apollo-angular';

import { accessLevels } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-comment',
  templateUrl: './mutate-comment.component.html',
  styleUrls: ['./mutate-comment.component.scss']
})
export class MutateCommentComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('text') textRef: TemplateRef<any>;
  @ViewChild('requiredAccessLevel') requiredAccessLevelRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() comment: Comment;
  @Input() objectId: string;
  @Input() objectType: string; // Type of the Object Comment is being applied to
  @Input() hideAccessLevel: boolean; // Will not show or allow editing of the access level

  // Visual Modifiers
  @Input() commentName: string;
  titleText;

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  commentForm = new UntypedFormGroup({
    text: new UntypedFormControl('', [ Validators.required, Validators.minLength(1)]),
    requiredAccessLevel: new UntypedFormControl('any', [  ]),
  });

  commentFormValues = this.commentForm.value;

  commentQueryRef: QueryRef<ListCommentsOutput>;

  accessLevels = accessLevels;

  constructor(
    private commentService: CommentService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }

  refetchComment(){
    if (!this.commentQueryRef) {
      this.commentQueryRef = this.commentService.watchComments({filter: {ids: [this.comment.id]}});

      this.subs.sink = this.commentQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.comment = res.data.comments.comments[0];
        }
      });
    } else {
      this.commentQueryRef.refetch();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createComment();
    } else if (this.mutateType === 'update') {
      this.updateComment();
    }
  }

  openDialog() {
    this.steps = [
      { name: 'Text', ref: this.textRef, control: 'text', type: 'text', disableEnterKey: true },
      { name: 'Required Access Level', ref: this.requiredAccessLevelRef, control: 'requiredAccessLevel', type: 'text' },
    ];

    if (this.hideAccessLevel){
      this.steps = [this.steps[0]];
    }

    if (this.mutateType === 'create') {
      this.titleText = 'Add Note';
      this.commentForm.reset(this.commentFormValues);
    } else if (this.mutateType === 'update') {
      this.titleText = 'Update Note';
      this.setFormValues();
    }
    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.commentForm.reset({
      text: this.comment.text,
      requiredAccessLevel: this.comment.requiredAccessLevel
    });
  }

  createComment(){
    const addCommentInput = {
      input: {
        requiredAccessLevel: this.commentForm.value.requiredAccessLevel || undefined,
        objectId: this.objectId,
        objectType: this.objectType,
        text: this.commentForm.value.text,
        attributes: this.commentName === 'Inventory Note' ? ['inventory'] : [this.commentName.toLowerCase()],
      }
    } as AddCommentFunctionInput;

    this.subs.sink = this.commentService.addComment(addCommentInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.objectId,
        type:'Comments',
        action:'create',
      });

      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: `${this.commentName} added`});
    }, (err) => {
      if (err?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
        this.mutateRef.loading = false;
        this.localNotify.apolloError(`You cannot add comments to users above your current zone`,err);
        return;
      }
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to add ${this.commentName}`,err);
    });
  }

  updateComment(){
    const updateCommentInput = {
      ids: [this.comment.id],
      update: {
        text: this.commentForm.value.text,
        requiredAccessLevel: this.commentForm.value.requiredAccessLevel || undefined,
      }
    } as UpdateCommentInput;

    this.subs.sink = this.commentService.updateComments({input: updateCommentInput}).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.comment.id,
        type:'Comments',
        action:'update',
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: `${this.commentName} updated`});
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update ${this.commentName}`,err);
    });
  }

}
