import { on } from '@ngrx/store';

import { addCommentToComments, updateCommentInPlace } from '../job-activity/comments.utils';
import { JobToolState } from '../job-tool.reducer';

import { JobToolSubscriptionActions } from './job-tool-subscription.actions';

export const jobToolSubscriptionReducers = [
	on(JobToolSubscriptionActions.remoteCommentAdded, (state: JobToolState, res): JobToolState => {

		const updateRes = updateCommentInPlace(
			state.comments,
			res.output.id,
			res.output,
			true
		);

		let comments = updateRes.comments;
		if (!updateRes.comment) {
			const addCommentRes = addCommentToComments(state.comments, res.output);
			comments = addCommentRes.comments;
		}

		// TODO: we should probably load the full comment here because its missing author

		return {
			...state,
			comments,
		};
	}),
	on(JobToolSubscriptionActions.remoteCommentUpdated, (state: JobToolState, res): JobToolState => {

		const {comments} = updateCommentInPlace(state.comments, res.output.id, res.input, true);

		// TODO: flag as recently updated

		return {
			...state,
			comments,
		};
	}),
] as const;
