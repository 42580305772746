<div class="p-grid redirect-topbar page-width p-ai-center">
    <div class="p-col-1 p-text-center">
        <a href="https://www.youmoveme.com/">
            <img
                src="../../../assets/images/YMM_Logo_Color_Small.png"
                alt="YMM_Logo"
                width="48px"
                height="48px"
                >
        </a>
    </div>
    <div class="p-col-4 p-md-7"></div>
    <div class="p-col-2 p-md-1">
        <p (click)="tips.toggle($event)" class="ymm-menu-link">TIPS</p>
    </div>
    <div class="p-col-2 p-md-1">
        <a href="https://www.youmoveme.com/blog" class="ymm-menu-link">BLOG</a>
    </div>
    <div class="p-col-2 p-md-1">
        <a href="https://www.youmoveme.com/franchise/customer-reviews" class="ymm-menu-link">REVIEWS</a>
    </div>
    <div class="p-col-1">
    </div>
</div>
<div class="payment-container page-width">
    <div class="p-grid">
        <div class="p-col-12 p-text-center">
            <img
                src="../../../assets/images/YMM_Logo_Color_Small.png"
                alt="YMM_Logo"
                width="200px"
                height="200px"
                >
        </div>
        <div class="payment-card p-col-12 p-md-6 p-offset-0 p-md-offset-3 p-text-center">
            <div>
                <i
                    [class]="paymentStatus | resolvePaymentIcon"
                    style="font-size: 10rem"
                ></i>
            </div>
            <br>
            <div>
                <p>{{paymentMessage}}</p>
            </div>
            <br>
            <p>A reciept has been sent to admin&#64;youmoveme.com.</p>
            <!-- <div *ngIf="paymentStatus === 'succeeded'" class="p-grid">
                <div class="p-col">
                    <button pButton label="View Recipet" icon="pi pi-external-link" (click)="viewReciept()"></button>
                </div>
                <div class="p-col">
                    <button pButton label="Download Reciept" icon="pi pi-download" (click)="downloadReciept()"></button>
                </div>
            </div> -->
            <div *ngIf="paymentStatus === 'requires_payment_method' || paymentStatus === 'error'" class="p-grid">
                <div class="p-col">
                    <button pButton label="Retry Payment" icon="pi pi-step-backward" (click)="returnToPaymentPage()"></button>
                </div>
            </div>
        </div>
    </div>
</div>

<p-overlayPanel #tips>
    <ng-template pTemplate>
        <a href="https://www.youmoveme.com/tips" class="ymm-menu-link">Tips</a>
        <br>
        <br>
        <a href="https://www.youmoveme.com/qa" class="ymm-menu-link">Q&A</a>
        <br>
        <br>
        <a href="https://www.youmoveme.com/moving-checklist" class="ymm-menu-link">Moving Checklist</a>
    </ng-template>
</p-overlayPanel>