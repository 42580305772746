<span
	[pTooltip]="job?.closedAt && 'Cannot Add Event to Closed Job'"
	tooltipPosition="bottom"
	>
	<button
		class="p-m-1"
		pButton
		pRipple
		label="Add Event"
		(click)="menu.toggle($event)"
		[disabled]="disabled || job?.closedAt"
		icon="pi pi-plus"
		></button>
</span>
<p-menu
	#menu
	[popup]="true"
	appendTo="body"
	[model]="addableEventTypes"
></p-menu>
