<div [formGroup]="filterForm">
    <ng-template #name>
        <input
            type="text"
            pInputText
            formControlName="name"
            placeholder="Name *"
        />
    </ng-template>
</div>
<app-mutate-object
    #mutate
    [fg]="filterForm"
    objectType="Filter Configuration"
    mutateType="create"
    [steps]="steps"
    (objectMutated)="createFilter()"
></app-mutate-object>
