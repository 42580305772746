import { Injectable } from '@angular/core';
import { ListRolesInput, ListRolesOutput, listRolesQuery, RoleSearch, createRole, CreateRoleInput, CreateRoleOutput, EditRoleInput, editRole, removeRole, EditRoleOutput, DeleteRolesOutput} from '@karve.it/interfaces/roles';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private apollo: PlusApollo,
  ) { }

  /**
   * List the roles in the system that match your criteria.
   *
   * @param input Criteria to filter the roles by.
   * @returns QueryRef, list of roles.
   */
  listRoles(search?: RoleSearch) {
    return this.apollo.query<ListRolesOutput>({
      query: listRolesQuery,
      variables: {
        search,
      } as ListRolesInput,
    });
  }

  /**
   * List the roles in the system that match your criteria and watch for changes.
   *
   * @param input Criteria to filter the roles by.
   * @returns QueryRef, list of roles.
   */
   watchRoles(search?: RoleSearch) {
    return this.apollo.watchQuery<ListRolesOutput>({
      query: listRolesQuery,
      variables: {
        search,
      } as ListRolesInput,
    });
  }

  /**
   * Create new roles in the system.
   *
   * @param input Input and Zone used for creating the roles.
   * @returns QueryRef, List of created Roles.
   */
  createRoles(input: CreateRoleInput){
    return this.apollo.mutate<CreateRoleOutput>({
      mutation: createRole,
      variables: {
        ...input
      }
    });
  }

  /**
   * Edit Role descriptive properties.
   *
   * @param input Ids of the role and new information for the roles.
   * @returns QueryRef, List of the edited roles.
   */
  editRoles(input: EditRoleInput){
    return this.apollo.mutate<EditRoleOutput>({
      mutation: editRole,
      variables: {
        ...input
      },
    });
  }

  /**
   * Delete roles from the system.
   *
   * @param roleIds The ids of the roles to be deleted.
   * @returns Query Ref, Boolean
   */
  deleteRoles(roleIds: string[]){
    return this.apollo.mutate<DeleteRolesOutput>({
      mutation: removeRole,
      variables: {
        roleIds
      },
    });
  }

  // Check Permissions Quick
  // addPermisionToRole(){
  //   return this.apollo.mutate<>({
  //     mutation:,
  //     variables: {

  //     },
  //   });
  // }

}
