import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ChargeService, JobService } from '@karve.it/features';
import { Charge } from '@karve.it/interfaces/charges';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { JobUser, ListJobsFunctionOutput, UpdateJobsInput } from '@karve.it/interfaces/jobs';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { filter, map } from 'rxjs/operators';

import {RoleService} from 'src/app/core/roles/role.service';
import { JOB_ROLE_MAP, JOB_STAGES, userJobRoles } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { FullJobFragment, FullProductFragment, ListProductsGQL, ListProductsQuery } from '../../../generated/graphql.generated';
import { strToTitleCase } from '../../js';
import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { GoogleHelperService } from '../../services/google-helper.service';
import { getAttributesObjectArray } from '../../utilities/attributes.util';

import { JobCustomerPipe } from '../job-customer.pipe';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

import { jobAttributeTitles } from './job-attributes';

@Component({
  selector: 'app-mutate-job',
  templateUrl: './mutate-job.component.html',
  styleUrls: ['./mutate-job.component.scss']
})
export class MutateJobComponent implements OnInit, OnDestroy {

  @ViewChild('co') mutateRef: MutateObjectComponent;

  @ViewChild('stage') stageRef: TemplateRef<any>;
  @ViewChild('charges') chargesRef: TemplateRef<any>;
  @ViewChild('damages') damagesRef: TemplateRef<any>;
  @ViewChild('attributes') attributesRef: TemplateRef<any>;
  @ViewChild('deposit') depositRef: TemplateRef<any>;
  @ViewChild('users') usersRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() job: FullJobFragment;

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  productQueryRef: QueryRef<ListProductsQuery>;
  productSuggestions: FullProductFragment[] = [];

  // List of all charges that have been modified
  modifiedCharges = [];

  // Create User Variables
  showDialog = false;

  stages = Object.keys(JOB_STAGES).map((s) => ({
    label: strToTitleCase(JOB_STAGES[s].name),
    value: s,
  }));

  jobForm = new UntypedFormGroup({
    //TODO: Remove - Not using these fields
    // customer: new FormControl('', []),
    // crew: new FormControl([], []),
    // startLocation: new FormControl('', [Validators.required, Validators.minLength(1)]),
    // endLocation: new FormControl('', [Validators.required, Validators.minLength(1)]),
    stage: new UntypedFormControl('', Validators.required),
    deposit: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
    charges: new UntypedFormControl([], []),
    damages: new UntypedFormControl([], []),
    tags: new UntypedFormControl([], []),
    assets: new UntypedFormControl([], []),
    events: new UntypedFormControl([], []),
    subtotal: new UntypedFormControl(0, []),
    taxtotal: new UntypedFormControl(0, []),
    total: new UntypedFormControl(0, []),
    paid: new UntypedFormControl(0, []),
    attributes: new UntypedFormControl([], []),
    users: new UntypedFormControl([], [])
  });

  addUsersForm = new UntypedFormGroup({
    user: new UntypedFormControl(undefined, [Validators.required]),
    role: new UntypedFormControl(undefined, [Validators.required]),
  });

  attributeObjects = getAttributesObjectArray(jobAttributeTitles, []);

  jobFormValues = this.jobForm.value;

  jobQueryRef: QueryRef<ListJobsFunctionOutput>;

  googleOptions = {
    componentRestrictions: {
      country: ['CA', 'US']
    }
  };

  userJobRoles = new Set(userJobRoles);
  userJobRolesArray = userJobRoles;

  staffRoleIds = [];
  customerRoleIds = [];

  userSearchOptions = {};

  constructor(
    public googleHelper: GoogleHelperService,
    private chargesService: ChargeService,
    private jobService: JobService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private customerPipe: JobCustomerPipe,
    private freyaHelper: FreyaHelperService,
    private cd: ChangeDetectorRef,
    private listProductsGQL: ListProductsGQL,
    private roleService: RoleService
    ) { }

  ngOnInit(): void {

    // this.detailsHelper.jobsUpdated.subscribe((res) => {
    //   if (res && this.mutateType === 'update') {
    //     this.retrieveJob();
    //   }
    // });

    this.roleService
        .listRoles({ zoneDir: ZoneDir.any, limit: -1 })
        .pipe(
            filter((res) => !res.loading),
            map((res) => {
                const { staffRoleIds, customerRoleIds } = res?.data?.roles?.reduce((acc, role) => {
                    if (role.name.includes('Customer')) {
                        acc.customerRoleIds.push(role.id);
                    } else {
                        acc.staffRoleIds.push(role.id);
                    }
                    return acc;
                    }, { staffRoleIds: [], customerRoleIds: [] });

                return {
                    staffRoleIds,
                    customerRoleIds,
                };
            }),
        )
        .subscribe(
            (res) => {
                this.staffRoleIds = res.staffRoleIds;
                this.customerRoleIds = res.customerRoleIds;
            },
            (err) => {
                console.error(err);
            }
        );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
    } else if (this.mutateType === 'update') {
      this.updateJob();
    }
  }

  // retrieveJob() {
  //   if (this.jobQueryRef) {
  //     this.jobQueryRef.setVariables({ filter: { jobIds: [this.job.id] } }, true, true);
  //   } else {
  //     this.jobQueryRef = this.jobService.watchJobs(
  //       {
  //         filter: {
  //           jobIds: [this.job.id]
  //         }
  //       } as JobsInput,
  //       {
  //         users: true,
  //         event: true,
  //         charges: true,
  //         tags: true,
  //       } as GenerateJobsQueryInput);

  //     this.subs.sink = this.jobQueryRef.valueChanges.subscribe((res) => {
  //       if (res.networkStatus === 7 && res.data.jobs.jobs?.length) {
  //         this.job = cloneDeep(res.data.jobs.jobs[0]);
  //         this.setFormValues();
  //       }
  //     });
  //   }
  // }

  openDialog() {
    // This Component Has the Same Steps in Create and Update
    this.steps = [
      { name: 'Deposit', ref: this.depositRef, control: 'deposit', type: 'currency' },
      { name: 'Stage', ref: this.stageRef, control: 'stage', type: 'func',
        reviewFunc: () => strToTitleCase(JOB_STAGES[this.jobForm.value.stage].name) },
      { name: 'Users', ref: this.usersRef, control: 'users', type: 'array' },
      { name: 'Attributes', ref: this.attributesRef, control: 'attributes', type: 'func',
        reviewFunc: () =>  this.attributeObjects.filter((a) => a.value).length,
      },
    ];

    if (this.mutateType === 'create') {
      // Assign Steps
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }
    this.mutateRef.steps = this.steps;
    this.mutateRef.currency = this.job.currency;
    this.attributeObjects = getAttributesObjectArray(jobAttributeTitles, this.job.attributes);
    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.jobForm.patchValue({
      //TODO: Remove - Not using these fields
      // customer: this.job?.users ? this.customerPipe.transform(this.job.users) : undefined,
      // crew: this.job?.users?.filter((u) => u.role === JOB_ROLE_MAP.employeeRole),
      // startLocation: this.job.startLocation,
      // endLocation: this.job.endLocation,
      stage: this.job.stage,
      charges: cloneDeep(this.job.charges.filter((c) => c.amount > 0 || !c.amount)),
      damages: cloneDeep(this.job.charges.filter((c) => c.amount < 0)),
      tags: cloneDeep(this.job.tags),
      deposit: convertCentsToDollars(this.job.depositRequired),
      assets: this.job?.events[0]?.assets ? this.job?.events[0]?.assets : [],
      events: this.job?.events,
      subtotal: convertCentsToDollars(this.job.subTotal),
      taxtotal: convertCentsToDollars(this.job.taxTotal),
      total: convertCentsToDollars(this.job.total),
      paid: convertCentsToDollars(this.job.paidTotal),
      users: this.job?.users,
    });

    this.modifiedCharges = [];
    this.removeAssignedJobRoles(this.job?.users.map((u) => u.role));
  }

  public handleAddressChange(address: any, control) {
    this.jobForm.controls[control] = address.formatted_address;
  }

  searchProduct(event) {
    if (this.productQueryRef) {
      this.productQueryRef.resetLastResults();
      this.productQueryRef.setVariables({ filter: { names: [event.query] } });
    } else {
      this.productQueryRef = this.listProductsGQL.watch(
        {
          filter: {
            names: [event.query],
          },
        },
        {
          context: {
            headers: {
              'x-zone': this.job.zone?.id
            }
          }
        }
      );

      this.subs.sink = this.productQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          // TODO: Update this to filter by zone in the api once implemented
          this.productSuggestions = cloneDeep(res.data.products.products.filter((p) => p?.prices?.length));
        } else {
          this.productSuggestions = cloneDeep(this.productSuggestions.filter((p) => p?.prices?.length));
        }
      });
    }
  }

  updateJob() {
    // Calculate Job Charges
    const { added: chargesAdded, removed: chargesRemoved } = this.freyaHelper.getAddedAndRemoved(
      this.job.charges,
      [...this.jobForm.value.charges, ...this.jobForm.value.damages.map((d) => ({ ...d, amount: this.forceNegativeValue(d.amount) }))]);

    const chargesToAdd = chargesAdded
      .filter((ca) => !this.modifiedCharges.find((mc) => mc.id === ca.id))
      .filter((c) => (c.productName && c.amount) || c.product?.id)
      .map((charge) => (
        {
          productId: charge.product?.id,
          productName: charge.productName,
          amount: charge.amount >= 0 ? convertDollarsToCents(charge.amount) : this.forceNegativeValue(convertDollarsToCents(charge.amount)),
          quantity: charge.quantity,
        }
      ));

    const chargesToRemove = chargesRemoved.filter((cr) => !this.modifiedCharges.find((mc) => mc.id === cr));

    // Calculate Job Users
    let { added: usersToAdd, removed: usersToRemove }
      = this.freyaHelper.getAddedAndRemoved(this.job.users, this.jobForm.value.users, false);

    usersToAdd = usersToAdd.map((u) => ({ role: u.role, userId: u.user.id }));

    usersToRemove = usersToRemove.map((u) => ({ role: u.role, userId: u.user.id }));

    const attributes = this.attributeObjects
      .filter((a) => a.value)
      .map((a) => a.attribute);
    this.job.attributes = attributes;

    // Job Input
    const updateJobInput = {
      updateJobs: [{
        jobId: this.job.id,
        stage: this.jobForm.value.stage,
        depositRequired: convertDollarsToCents(this.jobForm.value.deposit),
        attributes,
        newCharges: {
          charges: chargesToAdd,
          userId: this.job.users.find((u) => u.role === JOB_ROLE_MAP.customerRole)?.user?.id
        },
        addUsers: usersToAdd,
        removeUsers: usersToRemove
      }],
    } as UpdateJobsInput;

    this.subs.sink = this.jobService.updateJobs(updateJobInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:this.job.id,
        type:'Jobs',
        action:'update'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Job updated' });
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update job`,err);
    });

    if (chargesToRemove?.length) {
      this.subs.sink = this.chargesService.deleteCharges({ ids: chargesToRemove }).subscribe((res) => {
        this.detailsHelper.pushUpdate({
          id:this.job.id,
          type:'Jobs',
          action:'update'
        });
        // this.localNotify.addToast.next({severity: 'success', summary: 'Removed charges from job'});
      }, (err) => {
        // this.localNotify.addToast.next({severity: 'error', summary: 'Failed to remove charges from job'});
      });
    }

    if (this.modifiedCharges?.length) {
      const updateChargesInput = this.modifiedCharges
        .filter((c) => c.id)
        .map((c) => (
          { id: c.id, quantity: c.quantity }
        ));

      this.subs.sink = this.chargesService.updateCharges({ charges: updateChargesInput }).subscribe((res) => {
        this.detailsHelper.pushUpdate({
          id:this.job.id,
          type:'Jobs',
          action:'update',
        });

      });
    }
  }

  // Sets that charge to be passed in the modify
  markChargeAsModified(charge: Charge) {
    if (!charge.id) { return; }

    if (!this.modifiedCharges.find((mc) => mc.id === charge.id)) {
      this.modifiedCharges.push(charge);
    }
  }

  // Updates the Form Control Value
  addCharge() {
    const value = this.jobForm.controls.charges.value;
    this.jobForm.controls.charges.setValue([...value, { product: {}, quantity: 1 }]);
    this.setSectionInvalid(true);
  }

  // Updates the Form Control Value
  addDamage() {
    const value = this.jobForm.controls.damages.value;
    this.jobForm.controls.damages.setValue([...value, { product: {}, quantity: 1, }]);
  }

  selectProduct(charge: Charge, productValue) {
    charge.productName = undefined;
    charge.product = productValue;
  }

  // Updates the Form Control Value
  removeCharge(charge: Charge) {
    const value = this.jobForm.controls.charges.value;
    let index;
    if (!charge.id) {
      index = value.findIndex((c) => c.product.name === charge.product.name && !c.id);
    } else {
      index = value.findIndex((c) => c.id === charge.id);
    }
    value.splice(index, 1);
    this.jobForm.controls.charges.setValue(value);
  }

  removeDamage(charge: Charge) {
    const value = this.jobForm.controls.damages.value;
    let index;
    if (!charge.id) {
      index = value.findIndex((c) => c.product.name === charge.product.name && !c.id);
    } else {
      index = value.findIndex((c) => c.id === charge.id);
    }
    value.splice(index, 1);
    this.jobForm.controls.damages.setValue(value);
  }

  addUser() {
    const currentValues = this.jobForm.controls.users.value;
    this.jobForm.controls.users.setValue(
      [
        ...currentValues,
        {
          user: this.addUsersForm.value.user,
          role: this.addUsersForm.value.role,
        }
      ]
    );

    // Update the job roles array to prevent the role from being added again
    this.updateJobRolesOptions(this.addUsersForm.value.role, 'remove');
    this.addUsersForm.reset();
  }

  removeUser(user: JobUser) {
    if(user.role === JOB_ROLE_MAP.authorRole){
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'Unable to remove the author. An author, once assigned, cannot be removed from a job.'
      });
      return;
    }
    // const rolesAssginedToUser = this.jobForm.value.users.filter((u) => (u.user.id === user.user.id)).map((u) => u.role);
    const without = this.jobForm.value.users.filter((u: JobUser) => !(u.user.id === user.user.id && u.role === user.role));
    this.jobForm.controls.users.setValue(without);
    // Update the job roles array to allow the role to be added again
    this.updateJobRolesOptions(user.role, 'add');
  }

  forceNegativeValue(amount): number {
    if (!amount) { return undefined; }
    return (Math.abs(amount) * -1);
  }

  setSectionInvalid(invalid: boolean) {
    this.mutateRef.sectionInvalid = invalid;
  }

  // While pre-loading form values, we need to update the userJobRoles array to allow unique staff roles (crew lead, member, author etc.)
  removeAssignedJobRoles(roles: string[]) {
    this.userJobRoles = new Set(userJobRoles);
    roles.forEach((role) =>
        this.updateJobRolesOptions(role, 'remove')
    );
  }

  updateJobRolesOptions(role: string, operation: 'add' | 'remove') {
    if (!operation || !role) {
        return;
    }


    if (operation === 'add') {
      this.userJobRoles.add(role);
    } else if (operation === 'remove') {
      this.userJobRoles.delete(role);
    }

    this.userJobRolesArray = [...this.userJobRoles];

  }

  setRoleId(event) {
    this.addUsersForm.patchValue({ role: event.value });

    // Set the user search options for user-search component based on the selected role
    if (event.value === JOB_ROLE_MAP.customerRole) {
        this.userSearchOptions = { roles: this.customerRoleIds };
    } else {
        this.userSearchOptions = { roles: this.staffRoleIds };
    }
  }
}
