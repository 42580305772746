import { Pipe, PipeTransform } from '@angular/core';
import { Discount } from '@karve.it/interfaces/discounts';

@Pipe({
  name: 'discountStatus'
})
export class DiscountStatusPipe implements PipeTransform {

  transform(discount: Discount): string {
    const now  = new Date().getTime() / 1000;
    if (now > discount.startsAt && (now < discount.expiresAt || !discount.expiresAt)){
      return 'Running';
    } else  if (now < discount.startsAt) {
      return 'Scheduled';
    } if (now > discount?.expiresAt){
      return 'Expired';
    }
  }

}
