<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="franchises-card-header">System Settings</h4>
        </div>
        <div class="p-col">
            <button
                *hasPermission="['config.set']"
                pButton
                icon="pi pi-refresh"
                class="p-button-warning"
                label="Reset Zone Settings"
                (click)="confirmResetZoneSettings()"
                [disabled]="(branding.currentZone() | async)?.id === 'moveright' "
                ></button>
        </div>
    </div>
</div>
<div *ngIf="!loading; else loadingBlock" class="p-grid">
    <div *ngFor="let card of generalSettings" class="p-col-6 p-md-4">
        <app-multi-config-settings-card
            *ngIf="card.type === 'multi-config'"
            [currentZoneId]="currentZoneId"
            [namespace]="card.namespace"
            [configs]="card.configs"
            ></app-multi-config-settings-card>
        <app-single-config-settings-card
            *ngIf="card.type === 'single-config'"
            [currentZoneId]="currentZoneId"
            [config]="card.config"
            ></app-single-config-settings-card>
    </div>
</div>
<ng-template #loadingBlock>
    <div class="p-grid">
        <div *ngFor="let number of [0, 1, 2]" class="p-col-6 p-md-4">
            <div class="card" style="height: 16rem;">
                <div class="p-d-flex p-jc-between p-ai-center p-mb-4">
                    <p-skeleton width="7rem" height="1.5rem"></p-skeleton>
                    <button pButton icon="pi pi-pencil" disabled></button>
                </div>
                <div class="p-mb-4">
                    <p-skeleton width="5rem" styleClass="p-mb-3"></p-skeleton>
                    <p-skeleton width="15rem" styleClass="p-m-2"></p-skeleton>
                </div>
                <div class="p-mb-4">
                    <p-skeleton width="5rem" styleClass="p-mb-3"></p-skeleton>
                    <p-skeleton width="15rem" styleClass="p-m-2"></p-skeleton>
                </div>
            </div>
        </div>
    </div>
</ng-template>
