<p-table
    [value]="attentionItems"
    [paginator]="true"
    (onLazyLoad)="goToPage($event)"
    [lazyLoadOnInit]="true"
    [lazy]="true"
    [totalRecords]="attentionItemsQH.total"
    [(rows)]="attentionItemsQH.limit"
    [showCurrentPageReport]="!attentionItemsQH.loading"
    breakpoint="620px"
    [(first)]="attentionItemsQH.skip"
    [loading]="attentionItemsQH.loading"
>
    <ng-template pTemplate="header">
        <tr>
          <th>Item</th>
          <th>Needs Attention</th>
          <th class="p-text-center">Action</th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        let-item
    >
        <tr
            class="selectable-row"
            (click)="attentionItemsService.openItem(item)"
        >
            <td>
                <span class="p-column-title">Item</span>
                <span>
                    <span class="p-mr-1">
                        <i [class]="item | attentionItemIcon"></i>
                    </span>
                    {{ item | attentionItemName }}
                </span>
            </td>
            <td>
                <span class="p-column-title">Reason</span>
                <app-attention-items-explanation
                    [item]="item"
                    [attentionConfigs]="attentionConfigs"
                    [maxExplanations]="maxExplanations"
                    ></app-attention-items-explanation>
            </td>
            <td class="p-text-center">
                <span class="p-column-title">Action</span>
                <p-button
                    *ngIf="permissions[item.action]"
                    type="button"
                    [style]="buttonStyle"
                    [label]="item | attentionItemAction"
                    [icon]="processing[item.id] ? 'pi pi-spin pi-spinner' : actionButtonIcons[item.action]"
                    (click)="$event.stopPropagation(); performItemAction(item)"
                    [disabled]="processing[item.id]"
                    ></p-button>
            </td>
        </tr>
    </ng-template>
</p-table>
