import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';

import { dayjs, dayJsFullCalendarTimeZonePlugin } from '@karve.it/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { AssetsForAvailabilityGQL, AssetsForAvailabilityQuery, AssetsForAvailabilityQueryVariables, AvailabilityTemplate, BookOffCalendarEventFragment, BookOffEventsGQL, BookOffEventsQuery, CalendarEventsFilter, ListAvailabilityConfigurationGQL, ListAvailabilityConfigurationQuery, Zone, ZoneDir } from '../../generated/graphql.generated';
import { AppMainComponent } from '../app.main.component';

import { CalendarHelperService } from '../calendar-helper.service';
import { BOOK_OFF_EVENT_TYPE } from '../global.constants';

import { RESOURCE_AREA_HEADER } from '../schedules/schedule.constants';
import { sortAssetsForSchedule } from '../schedules/schedule.util';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { FullCalendarHelperService } from '../services/full-calendar-helper.service';
import { TimezoneHelperService } from '../services/timezone-helper.service';
import { ApplyTemplateComponent } from '../shared/apply-template/apply-template.component';
import { assetTypes } from '../shared/assets/assets';
import { calendarPlugins } from '../utilities/calendar.util';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';



import { AvailabilityTemplateWithAsset } from './availability-details/availability-details.component';
import { AssetWithConfig } from './availability.interfaces';
import { AvailabilityEventTypes, DEFAULT_UNAVAILABLE_EVENT } from './availability.util';

export interface ExpandableResource {
  element: any;
  assetName: string;
  resourceId: string;
  expanded: boolean;
  childCount: number;
}

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss', './calendar.styles.scss']
})
export class AvailabilityComponent implements OnInit, OnDestroy, AfterViewInit {

  // Calendar
  @ViewChild('fc', { static: false }) calendarComponent: FullCalendarComponent;

  // Apply Template
  @ViewChild('at', { static: false }) applyTemplate: ApplyTemplateComponent;

  @Input() showHeader = true;
  @Input() showTemplates = true;

  // Used to prevent unecessary queries if the user is spam clicking next
  availabilityDebounce = new Subject<void>();

  calendar: Calendar;
  calendarTitle$ = new BehaviorSubject('');
  startDate: dayjs.Dayjs = dayjs().startOf('day');
  endDate: dayjs.Dayjs = dayjs().add(1, 'day').endOf('day');

  // Whether we have loaded the first set of events onto the calendar
  calendarInitialized = false;

  subs = new SubSink();

  // Configurations
  configurationsQueryRef: QueryRef<ListAvailabilityConfigurationQuery>;
  configurationQH: WatchQueryHelper = {
    loading: true,
  };

  // Asssets
  assets: AssetWithConfig[];
  assetsQueryRef: QueryRef<AssetsForAvailabilityQuery>;

  parseableDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  // Book-off Events
  bookOffEvents: BookOffCalendarEventFragment[] = [];
  bookOffEventsQueryRef: QueryRef<BookOffEventsQuery>;

  // FILTERS
  selectedAreas: Zone[] = [];
  selectedAssetTypes: string[] = [];

  assetTypeOptions = assetTypes;

  // EXPANSION

  // List of the resources that can be expanded and their current expand state.
  expandedResourceList: ExpandableResource[] = [];


  // CALENDAR
  calendarOptions: CalendarOptions = {
    plugins: [
      ...calendarPlugins,
      // resourceTimelinePlugin,
      interactionPlugin,
      dayJsFullCalendarTimeZonePlugin,
    ],
    timeZone: this.timeZone.getCurrentTimezone(),
    initialView: 'resourceTimelineDay',
    schedulerLicenseKey: environment.fullCalendarKey,
    resourceAreaHeaderContent: RESOURCE_AREA_HEADER,
    resourceAreaWidth: this.fcHelper.getResourceAreaWidth(),
    resourceOrder: 'order, title',
    resourcesInitiallyExpanded: false,
    stickyFooterScrollbar: true,
    views: {
      resourceTimelineDay: {
        titleFormat: { weekday: 'long', day: 'numeric', month: 'long' }
      }
    },
    headerToolbar: false,
    resources: [
      {
        id: 'loading',
        title: 'Loading ...',
      }
    ],
    resourceLabelContent: (info) => (
      this.fcHelper.generateResourceLabel(info, this.assets as any)
    ),
    // Drag
    editable: false,
    // Drop
    droppable: false,
    drop: (info) => {
      if (this.freyaHelper.inRootOrCorporateZone) {
        this.localNotify.warning('You cannot apply availability templates in the root zone.');
        return;
      };
      //  this.openApplyTemplateDialog(info);
    },
    eventDrop: (info) => {
    },
    slotDuration: '04:00:00',
    slotMinTime: '06:00:00',
    slotMaxTime: '22:00:00',
    navLinks: false,
    datesSet: (info) => {
      this.calendarHelper.displayedDateChanged(dayjs(info.start).format('YYYY-MM-DD'), info.view.type);

      this.startDate = dayjs(info.view.currentStart);
      this.endDate = dayjs(info.view.currentEnd);

      if (!this.calendarInitialized) { return; }

      this.setCalendarTitle();

      // Notify the availability subject of the change
      this.availabilityDebounce.next();

      // Retrieve the cached version only
      this.retrieveAvailabilityConfigurations(true);

      // Update URL with the new date
      this.updateUrl(dayjs(this.calendar.getDate()).format('YYYY-MM-DD'), info.view.type);
    },
    navLinkWeekClick: (date, event) => {
      event.preventDefault();
    },
    eventClick: (info) => {
      if (info.event.extendedProps.type === AvailabilityEventTypes.unavailable) {
        return;
      }

      if (info.event.extendedProps.type === BOOK_OFF_EVENT_TYPE) {
        this.detailsHelper.open('book-off-event', { id: info.event.id });
        return;
      }

      this.detailsHelper.open('availability-template', info.event.extendedProps.template);
    },
    eventContent: (event) => {
      const type = event.event.extendedProps.type;
      const overriden: boolean = event.event.extendedProps.overriden;

      if (type === AvailabilityEventTypes.unavailable) {
        const onChildResource = event.event.extendedProps.child;
        return { domNodes: [this.fcHelper.createFreyaAvailability(false, onChildResource)] };
      }

      if (type === BOOK_OFF_EVENT_TYPE) {
        return { domNodes: [this.fcHelper.createBookedOffEvent(event.event.extendedProps.event, this.calendar.view.type)] };
      }

      const template: AvailabilityTemplate = event.event.extendedProps.template;

      const container = this.fcHelper.createAvailabilityTemplateEvent(template, type, overriden);

      return { domNodes: [container] };
    },
    events: []
  };

  constructor(
    // Components
    private appMain: AppMainComponent,
    // Helpers
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    public freyaHelper: FreyaHelperService,
    private fcHelper: FullCalendarHelperService,
    private calendarHelper: CalendarHelperService,
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private timeZone: TimezoneHelperService,
    // GQL
    private assetsGQL: AssetsForAvailabilityGQL,
    private configurationsGQL: ListAvailabilityConfigurationGQL,
    private bookOffEventsGQL: BookOffEventsGQL,
  ) { }

  ngOnInit(): void {
    // This ensures that the calendar is rerendered if the container size changes to match that size.
    this.subs.sink = this.freyaHelper.containerSizeChanged.subscribe(() => {
      this.renderAfterDelay();
    });

    this.subs.sink = this.detailsHelper.getObjectUpdates(['AvailabilityConfig', 'Events', 'AvailabilityTemplate']).subscribe((res) => {
      // Only reload when book off events are created
      if (res?.type === 'Events' && res?.update?.type !== BOOK_OFF_EVENT_TYPE){
        return;
      }
      this.retrieveAvailabilityConfigurations();
    });

    // Refetch availability after 250ms have passed without the user changing day/view
    this.subs.sink = this.availabilityDebounce.pipe(debounceTime(250)).subscribe(() => {
      this.retrieveAvailabilityConfigurations();
    });
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.setCalendarTitle();

    // Try to pull the date out of the query params, if none try to restore last date
    this.subs.sink = this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.date){
        this.calendar.gotoDate(params.date);
      }

      if (params.view){
        this.calendar.changeView(params.view);
      }

      this.setCalendarTitle();
      // We may be setting the title after change detection has completed,
      // call this to prevent an `ExpressionChangesAfterItHasBeenCheckedError`
      this.appMain.cd.detectChanges();

      this.retrieveAssets();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Retrieve and parse booked off events
   */
  retrieveBookOffEvents() {
    const filter: CalendarEventsFilter = {
      type: 'book-off',
      min: dayjs(this.calendar.view.currentStart || new Date()).startOf('day').unix(),
      max: dayjs(this.calendar.view.currentEnd || new Date()).endOf('day').unix(),
    };

    if (this.bookOffEventsQueryRef) {
      this.bookOffEventsQueryRef.refetch({ filter });
      return;
    }

    this.bookOffEventsQueryRef = this.bookOffEventsGQL.watch({ filter }, {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.bookOffEventsQueryRef.valueChanges.subscribe((res) => {
      if (!res?.data?.calendarEvents?.events) { return; }

      this.bookOffEvents = res.data.calendarEvents.events;

      if (res.errors) {
        this.localNotify.error(`Error ocurred when retrieving booked of events`, `List may be incomplete`);
      }

      this.calendar.batchRendering(() => {
        for (const event of this.bookOffEvents) {
          if (this.calendar.getEventById(event.id)) {
            continue;
          }

          this.calendar.addEvent({
            id: event.id,
            title: event.title,
            start: event.start * 1000,
            end: event.end * 1000,
            resourceIds: event.assets.map((a) => `A-${a.id}`),
            extendedProps: {
              type: BOOK_OFF_EVENT_TYPE,
              event
            }
          });
        }
      });
    }, (err) => {
      console.error(err);
      this.localNotify.error(`Booked off events could not be loaded`, err);
    });
  }

  /**
   * Retrieve the assets
   */
  retrieveAssets() {
    const assetsQueryVariables: AssetsForAvailabilityQueryVariables = {
      filter: { zoneDir: ZoneDir.Lte },
      limit: -1
    };

    this.assetsQueryRef = this.assetsGQL.watch(assetsQueryVariables, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.assetsQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.assets = res.data.assets.assets;
        this.retrieveAvailabilityConfigurations();
      }
    });
  }

  /**
   * Retrieve the Availability Configurations for the Assets
   */
  retrieveAvailabilityConfigurations(cacheOnly = false) {
    this.configurationQH.loading = true;

    const selectedArea = this.selectedAreas[0]?.id;

    const listConfigInput = {
      objectIds: this.assets.map((a) => a.id).sort(),
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
      zoneId: selectedArea || undefined,
    };

    if (this.configurationsQueryRef) {
      if (cacheOnly) {
        try {
          const data: ListAvailabilityConfigurationQuery = this.apollo.getClient().cache.readQuery(
            {
              query: this.configurationsGQL.document,
              variables: listConfigInput,
            }
          );

          this.handleConfigurationResult(data);
        } catch(e) {
          this.configurationQH.loading = true;
        }
      } else {
        this.configurationsQueryRef.refetch(listConfigInput);
      }
    } else {
      this.configurationsQueryRef = this.configurationsGQL.watch(listConfigInput, { fetchPolicy: 'cache-and-network' });

      this.subs.sink = this.configurationsQueryRef.valueChanges.subscribe((res) => {
        if (!res.data) {
          this.configurationQH.loading = true;
          return;
        }

        this.handleConfigurationResult({availabilityConfigurations: res.data.availabilityConfigurations});
      });
    }
  }

  /**
   * Handles the result of Configuration Query whether it's coming from network or the cache
   *
   * @param data The data returned from the cache or the network
   */
  handleConfigurationResult(data: ListAvailabilityConfigurationQuery){
    this.configurationQH.loading = false;
    this.assets = this.assets.map((a) => ({ ...a, config: data.availabilityConfigurations.find((c) => c.objectId === a.id) }));

    // Parse the availability onto the calendar
    this.setAvailabilityOnCalendar();
    // Retrieve Book Off Events
    this.retrieveBookOffEvents();

    this.calendarInitialized = true;
  }

  /**
   * Set the availability for each asset on the calendar
   */
  setAvailabilityOnCalendar() {
    for (const resource of this.calendar?.getResources()) {
      resource.remove();
    }
    this.calendar.removeAllEvents();

    this.calendar.batchRendering(() => {
      const assetsToDisplay = this.getDisplayedAssets();

    if (!assetsToDisplay?.length){
      this.calendar.addResource({
        title: 'No Assets Available',
      });
      return;
    }

    // Sort the assets, then we can just assign the order property as we loop throught the array
    sortAssetsForSchedule(assetsToDisplay);

    let order = 0;

    for (const asset of assetsToDisplay) {
      // Add Asset to the calendar
      this.calendar.addResource(
        {
          id: `A-${asset.id}`,
          title: `${asset.name} (${asset.type})`,
          children: [],
          type: asset.type,
          extendedProps: {
            assetId: asset.id
          },
          order,
        },
      );

      let holderDate = this.startDate.clone();
      while (this.endDate.diff(holderDate) >= 0) {

        const dateAsAString = holderDate.format('YYYY-MM-DD');
        const dayOfTheWeek = this.parseableDays[holderDate.get('day')];

        if (asset.config) {
          this.parseDayAvailabilityToEvents(asset, dateAsAString, dayOfTheWeek, holderDate);
        } else {
          this.calendar.addEvent({
            ...DEFAULT_UNAVAILABLE_EVENT,
            start: `${dateAsAString}T00:00:00`,
            end: `${dateAsAString}T00:00:01`,
            resourceId: `A-${asset.id}`,
            groupId: asset.id,
          });
        }
        
        holderDate = holderDate.add(1, 'day');
      }

      order += 1;
    }
      // Watch for parent resources being expanded
      setTimeout(() => {
        this.watchExpanders();
        // Set the height based on the rendered assets
        this.setDynamicHeight();
      }, 25);
    });
  }

  /**
   * Convert the availability for a single day into events for the calendar
   *
   * @param asset The asset the availability applies to
   * @param date The string represenation of the date 'YYYY-MM-DD'
   * @param dayOfTheWeek The day of the week matching the date
   * @param holderDate The date object representing the start of the day
   */
  parseDayAvailabilityToEvents(asset: AssetWithConfig, date: string, dayOfTheWeek: string, holderDate) {
    const overrides = asset.config.overrides.filter((o) => o.dates.includes(date));

    const templates = asset.config.templates.filter((t) => t.startDate <= date && (t.endDate >= date || !t.endDate));

    const eventsToAdd = [];

    const resourcesToAdd = [];

    // If we have a config but no templates applied to the current date, set as unavailable
    if (!overrides?.length && !templates?.length) {
      eventsToAdd.push({
        ...DEFAULT_UNAVAILABLE_EVENT,
        resourceIds: [`A-${asset.id}`],
        start: `${date}T00:00:00`,
        end: `${date}T00:00:01`,
        groupId: asset.id,
      });
    }

    for (const activeOverride of overrides) {
      resolveEventsFromTemplate(activeOverride.template, AvailabilityEventTypes.override);
    }

    for (const activeTemplate of templates) {
      resolveEventsFromTemplate(activeTemplate, AvailabilityEventTypes.normal);
    }

    // Add resources
    for (const resource of resourcesToAdd) {
      this.calendar.addResource(resource);
    }

    for (const e of eventsToAdd) {
      this.calendar.addEvent(e);
    }

    // Remove loading resource
    this.removeLoadingResource();

    /**
     * Resolves the FC events for a template, both the event that shows the template and the ones that show the unavailability
     *
     * @param activeTemplate The template to use for this
     * @param type Type of availability either Normal or Override
     */
    function resolveEventsFromTemplate(activeTemplate, type: AvailabilityEventTypes) {
      const availabilityForDay = activeTemplate.availability[dayOfTheWeek];

      // Unique Id for this specific template application on this asset
      const templateResourceId = `${activeTemplate.id}-${asset.id}-${type}`;
      // Prefixed with A so we can apply styling for parent rows only
      const assetResourceId = `A-${asset.id}`;

      // 1) Add resource for this template
      resourcesToAdd.push({
        id: templateResourceId,
        title: `${activeTemplate.name} ${type === AvailabilityEventTypes.override ? '(Override)' : ''}`,
        parentId: assetResourceId,
        extendedProps: {
          template: activeTemplate,
          type,
          parent: asset,
          date,
        },
        order: type === 'Normal' ? 1 : 0,
      });

      // 2) Add No availability Background (templates will overlap this in white)
      eventsToAdd.push({
        ...DEFAULT_UNAVAILABLE_EVENT,
        resourceIds: [templateResourceId],
        start: `${date}T00:00:00`,
        end: `${date}T00:00:01`,
        groupId: asset.id,
        extendedProps: {
          type: AvailabilityEventTypes.unavailable,
          child: true,
        }
      });

      // 3) Check if this template has any availability for this day
      if (!availabilityForDay?.length) {
        // Add Unavailability Event to asset (inverse of template)
        eventsToAdd.push({
          ...DEFAULT_UNAVAILABLE_EVENT,
          start: `${date}T00:00:00`,
          end: `${date}T00:00:01`,
          // If this template is being overriden then do not apply this to the asset
          resourceIds: [assetResourceId],
          groupId: asset.id,
        });
        return;
      }

      // 4) Loop through the availability blocks and create events
      for (let i = 0; i < availabilityForDay?.length; i += 2) {
        const baseEvent = {
          start: holderDate.valueOf() + (availabilityForDay[i] * 1000),
          end: holderDate.valueOf() + (availabilityForDay[i + 1] * 1000),
        };

        // Add Template event
        eventsToAdd.push({
          title: `Available`,
          resourceId: templateResourceId,
          ...baseEvent,
          extendedProps: {
            type,
            template: activeTemplate,
            templateId: activeTemplate.id,
            overriden: Boolean(overrides?.length),
          }
        });

        if (overrides?.length > 0 && type !== AvailabilityEventTypes.override) {
          continue;
        }

        // Add Unavailability Event to asset (inverse of template)
        eventsToAdd.push({
          ...DEFAULT_UNAVAILABLE_EVENT,
          ...baseEvent,
          // If this template is being overriden then do not apply this to the asset
          resourceIds: [assetResourceId],
          groupId: asset.id,
        });
      }
    }

  }

  /**
   * Remove the default loading resource if it is present
   */
  removeLoadingResource() {
    const loadingResource = this.calendar.getResourceById('loading');
    loadingResource?.remove();
  }

  setCalendarDate(event) {
    const tzDate = dayjs(event).format('YYYY-MM-DD');
    this.calendar.gotoDate(tzDate);
  }

  /**
   * Open the template details in the sidepanel
   *
   * @param template The template to display in the sidepanel
   */
  viewTemplateDetails(template: AvailabilityTemplateWithAsset) {
    this.detailsHelper.detailsItem.next({ type: 'availability-template', item: template });
  }

  // FILTERS
  getDisplayedAssets(): AssetWithConfig[] {
    let assetsToDisplay = [];
    if (this.selectedAssetTypes?.length) {
      assetsToDisplay = this.assets.filter((a) => this.selectedAssetTypes.includes(a.type));
    } else {
      assetsToDisplay = this.assets;
    }

    return assetsToDisplay;
  }

  // RENDERING

  /*
  * Calendar is drawn not dynamic, as such events not watched by FC (eg. container size) we must call this.
  */
  renderAfterDelay(delay = 200) {
    this.calendar.setOption('resourceAreaWidth', this.fcHelper.getResourceAreaWidth());
    setTimeout(() => { // Timeout ensures that the DOM has been updated before we try to redraw
      this.calendar.render();
    }, delay);
  }

  /**
   * Sets the height of the calendar based on the number of resources
   */
  setDynamicHeight() {
    const parentResourceCount = this.calendar.getTopLevelResources().length;

    // Get a count of the number of expanded children of visible assets
    const expandedChildResourceCount = this.expandedResourceList
      // Filter out assets that aren't currently displayed
      .filter((resource) => this.getDisplayedAssets().find((a) => `A-${a.id}` === resource.resourceId))
      .filter((resource) => resource.expanded)
      .reduce((count, obj) => (count + obj.childCount), 0);

    // Set height dynamically
    this.calendar.setOption('contentHeight', `${90 + ((parentResourceCount + expandedChildResourceCount) * 53)}px`);
  }

  setCalendarTitle() {
    this.calendarTitle$.next(this.calendar.getCurrentData().viewTitle);
  }


  // RESIZE HELPERS

  /**
   * Gets the top level resources and adds a listener for when their toggle option is clicked, used for dynamic height of calendar
   */
  watchExpanders() {
    const resourceHeaders = document.querySelectorAll('.fc-datagrid-cell-cushion');
    for (let i = 1; i < resourceHeaders.length; i++) {
      const expander = resourceHeaders[i].children.item(0);
      // No child resources
      if (expander.classList.contains('fc-icon')) {
        continue;
      }

      const resourceLabel = resourceHeaders[i].children.item(1)?.children.item(0);

      const title = resourceLabel?.textContent;
      const assetId = resourceLabel?.getAttribute('assetId');

      const resource = this.calendar.getTopLevelResources().find((r) => r.extendedProps.assetId === assetId);

      const childCount = resource.getChildren().length;

      const existingResource = this.expandedResourceList.find((r) => r.resourceId === resource.id);

      if (existingResource?.element === expander){
        return;
      }

      // If Full calendar added a new selector for the element, unsubscribe from the old one
      if (existingResource){
        existingResource.element.removeAllListeners(['click']);
        existingResource.element = expander;
      }

      expander.addEventListener('click', (ev) => {
        this.updateExpandedResourceList(title);
      });

      // Return if already initialized
      if (existingResource) { continue; }
      this.expandedResourceList.push({
        assetName: title,
        resourceId: resource.id,
        childCount,
        expanded: false,
        element: expander,
      });

    }
  }

  /**
   * Toggles the 'expanded' property for the given resource, which is used to calculate calender height
   *
   * @param resourceName The Resource name to update
   */
  updateExpandedResourceList(resourceName: string) {
    const resourceUpdated = this.expandedResourceList.find((resource) => resource.assetName === resourceName);
    resourceUpdated.expanded = !resourceUpdated.expanded;
    this.setDynamicHeight();
  }

  updateUrl(date: string, view: string){
    this.router.navigate(['/business/availability'], {
      queryParamsHandling: 'merge',
      queryParams: {
        date,
        view,
      },
      replaceUrl: true,
    });
  }
}
