import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

import { HistoryPartsFragment } from '../../generated/graphql.generated';

import { HistoryComponent, OpenHistoryDialogData } from './history.component';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  activeDialog?: DynamicDialogRef;
  sub?: Subscription;

  constructor(
    private dialog: DialogService,
  ) { }

  closeActiveHistory() {
    if (!this.activeDialog) { return; }
    this.activeDialog.close();
    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }
  }

  openHistory(
    label: string,
    id: string | string[],
  ) {
    this.activeDialog = this.dialog.open(HistoryComponent, {
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        label,
        id,
      } as OpenHistoryDialogData,
      // width: '400px',
      // height: '500px',
      closable: true,
      header: 'Viewing History',
      // TODO: After we update primeng, set modal to false and draggable to true
      modal: false,
      // draggable: true,
    });

    this.sub = this.activeDialog?.onDestroy.subscribe((res) => {
      this.activeDialog = undefined;
      this.sub?.unsubscribe();
      delete this.sub;
    });

  }

  determineHistoryName(history: HistoryPartsFragment) {
    return this.determineHistoryName(history);
  }
}
