import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTimeUnit'
})
// Get the seconds and display hours and minutes
export class SecondsToTimeUnitPipe implements PipeTransform {
  transform(seconds: number, showPlusMinus: boolean = false): string {
    const hours = Math.floor((Math.abs(seconds) / 60 / 60));
    const minutes: number = Math.floor((Math.abs(seconds) / 60 % 60));

    return ` ${(showPlusMinus) ? `${(seconds<0) ? '-':'+'}`:''}
             ${hours ? `${(hours)} hr(s)` : ''} 
             ${hours && minutes ? `and` : ''} 
             ${minutes ? `${(minutes)}min` : '0 min'}
           `;
  }

}
