import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { MarkdownModule } from 'ngx-markdown';

import { AttentionItemsSummaryComponent } from '../attention-items/attention-items-summary/attention-items-summary.component';
import { ActivityComponent } from '../dashboardv2/activity/activity.component';
import { ConversionsComponent } from '../dashboardv2/conversions/conversions.component';
import { DashboardEmptyStateComponent } from '../dashboardv2/dashboard-empty-state/dashboard-empty-state.component';
import { DashboardInfoV2Component } from '../dashboardv2/dashboard-info-v2/dashboard-info-v2.component';
import { DashboardNumberComponent } from '../dashboardv2/dashboard-number/dashboard-number.component';
import { Dashboardv2Component } from '../dashboardv2/dashboardv2.component';
import { DeltaComponent } from '../dashboardv2/delta/delta.component';
import { ErrorIconComponent } from '../dashboardv2/error-icon/error-icon.component';
import { EventSummaryComponent } from '../dashboardv2/event-summary/event-summary.component';
import { LogisticsComponent } from '../dashboardv2/logistics/logistics.component';
import { PipelineComponent } from '../dashboardv2/pipeline/pipeline.component';
import { RevenueComponent } from '../dashboardv2/revenue/revenue.component';
import { SourcesComponent } from '../dashboardv2/sources/sources.component';
import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { SharedModule } from '../shared/shared.module';

import { ChangeComponent } from './change/change.component';
import { DashboardEventTypeSummaryComponent } from './dashboard-event-type-summary/dashboard-event-type-summary.component';
import { DashboardInfoComponent } from './dashboard-info/dashboard-info.component';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    Dashboardv2Component,
    DashboardNumberComponent,
    ChangeComponent,
    DashboardInfoComponent,
    DashboardEmptyStateComponent,
    DashboardInfoV2Component,
    DashboardEventTypeSummaryComponent,
    DeltaComponent,
    AttentionItemsSummaryComponent,
    PipelineComponent,
    ActivityComponent,
    SourcesComponent,
    EventSummaryComponent,
    ErrorIconComponent,
    LogisticsComponent,
    RevenueComponent,
    ConversionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
    MarkdownModule.forChild(),
  ],
  exports: [
    DashboardComponent,
    Dashboardv2Component,
  ],
})
export class DashboardModule { }
