import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {  Router } from '@angular/router';
import { ConfigService, PlusAuthenticationService, ZoneService } from '@karve.it/core';
import { ListGetConfigValuesOutput } from '@karve.it/interfaces/config';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import { strToTitleCase } from '../js';

import { BrandingService } from './branding.service';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  subs = new SubSink();

  // Used to build title
  jobCode?: string;
  zoneCode: string;
  pageTitle: string;
  appName: string;

  configValuesQueryRef: QueryRef<ListGetConfigValuesOutput>;

  constructor(
    private title: Title,
    private configService: ConfigService,
    private zoneService: ZoneService,
    private router: Router,
    private plusAuth: PlusAuthenticationService,
    private location: Location,
    private brandingService: BrandingService,
  ) {

    this.subs.sink = this.brandingService.currentBranding
    .subscribe((branding) => {
      this.appName = branding.appName;
      this.zoneCode = branding.zoneCode;
      this.setTitle();
    });

    // Update page title on init and subsquent URL changes
    this.updatePageTitle(this.location.path());

    this.location.onUrlChange((url) => {
      this.updatePageTitle(url);
    });

    // Reset on logout
    // this.subs.sink = this.plusAuth.userDeauthenticated
    // .subscribe(() => {
    //   this.resetService();
    // });

  }

  init() {
    this.updatePageTitle(this.router.url);
  }

  destroy() {

    this.subs.unsubscribe();
  }

  /**
   * Tries to get custom page title from route data.
   * If no such title exists, sets page title to default.
   */
  updatePageTitle(url: string) {
    const customPageTitle = this.getCustomPageTitle();
    // console.log('route changed', customPageTitle);

    // Do not update title if title will be set from component
    if (customPageTitle === 'from-component') {
      return;
    };

    if (customPageTitle) {
      this.pageTitle = customPageTitle;
    } else {
      const defaultTitle = this.getDefaultPageTitle(url);
      this.pageTitle = this.formatTitle(defaultTitle);
    };
    this.setTitle();
  }

  /**
   * Searches for custom page title in activated routes prioritizing child routes,
   * e.g., if both '/franchise' and '/franchise/info' have custom titles, it will return the latter.
   */
  getCustomPageTitle(): string {
    let snapshot = this.router.routerState.snapshot.root;
    let customTitle: string;
    while (snapshot) {
      customTitle = snapshot?.data?.customTitle || customTitle;
      snapshot = snapshot.children[0];
    }

    return customTitle;
  }

  /**
   * Builds a default page title based on provided URL.
   * Excludes query params and IDs, e.g., if provided route URL is '/user/0b385b60-0dc3-11ed-8dfe-93cefc7ed4df/',
   * it will return 'user'.
   */
  getDefaultPageTitle(url: string): string {
    const urlFragments = url.split('/')
    .map((f) => this.filterOutIdsAndParams(f))
    .filter(Boolean);

    return urlFragments.join(': ');
  }

  /**
   * Returns true or false depending on whether the current route
   * was set to have a custom title code in routing.module.ts
   */
  hasCustomTitleCode(): boolean {
    const childPageSnapshot = this.router.routerState.snapshot.root.children[0].children[0].children[0];
    const pageSnapshot = this.router.routerState.snapshot.root.children[0].children[0];

    return childPageSnapshot?.data?.customTitleCode || pageSnapshot?.data?.customTitleCode;
  }


  filterOutIdsAndParams(urlFragment: string): string {
    if (!urlFragment) { return; };

    // Filter out params
    const sansParams = urlFragment.split('?')[0];

    // Ignore IDs based on length
    const idLength = 36;
    if (sansParams.length < idLength) {
      return sansParams;
    }
  }

  formatTitle(title: string) {
    if (!title) { return; };
    return strToTitleCase(title.replace('-', ' '));
  }

  setTitle() {
    const title: string = [ this.jobCode || this.zoneCode, this.pageTitle, this.appName ]
    .filter(Boolean)
    .join(' | ');

    this.title.setTitle(title);
  }

  setCustomPageTitle(
    input: {
      pageTitle?: string;
      jobCode?: string;
    },
  ) {
    if (input.pageTitle) {
      this.pageTitle = input.pageTitle;
    };
    this.jobCode = input.jobCode;

    this.setTitle();
  }

  resetService() {
    this.zoneCode = undefined;
    this.pageTitle = undefined;
    this.appName = undefined;
    this.configValuesQueryRef = undefined;
  }
}
