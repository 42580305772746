import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-object',
  templateUrl: './delete-object.component.html',
  styleUrls: ['./delete-object.component.scss']
})
export class DeleteObjectComponent implements OnInit {

  @Input() name: string;
  @Input() type: string;
  @Input() warning = '';
  // Could Pass Remove or another option
  @Input() deleteLabel = 'Delete';

  @Output() deleted = new EventEmitter<boolean>();
  @Output() cancelled = new EventEmitter<boolean>();

  showDialog = false;

  constructor() { }

  ngOnInit(): void {
  }

  openDialog() {
    this.showDialog = true;
  }

  cancel() {
    this.cancelled.emit(true);
    this.showDialog = false;
  }

  delete(){
    this.deleted.emit(true);
    this.showDialog = false;
  }

}
