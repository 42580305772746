<p-table
  [value]="stages"
  *ngIf="contactLists$ | async as contactLists"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>Job Stage</th>
      <th>Contact List</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-stage
    >
    <tr>
      <td>{{ stage | titlecase }}</td>
      <td>
        <p-dropdown
          [options]="contactLists.concat([{ id: undefined, name: 'None' }])"
          [(ngModel)]="jobStageListMap[stage]"
          (ngModelChange)="saveJobStageListMap()"
          optionLabel="name"
          optionValue="id"
          ></p-dropdown>
      </td>
    </tr>
  </ng-template>
</p-table>
