import {gql} from 'graphql-tag';

import { User } from './auth';
import { discountsBaseGQL, ZoneDirection } from './common.gql';
import { Metadata, metaDataGQL } from './metadata';

export interface ListDiscountsFunctionOutput {
    discounts: ListDiscountsOutput;
}

export interface CreateDiscountsOutput {
    createDiscounts: Discount[];
}

export interface UpdateDiscountsOutput {
    updateDiscounts: boolean;
}

export interface DeleteDiscountsOutput {
    deleteDiscounts: boolean;
}

export interface Discount {
    id: string;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number;
    name: string;
    description?: string;
    code: string;
    amount: number;
    discountType: DiscountTypes;
    active: boolean;
    startsAt?: number;
    expiresAt?: number;
    maxRedemptions?: number;
    timesRedeemed: number;
    attributes?: string[];
    metadata?: Metadata[];
}


export interface DiscountDetails {
    discount: Discount;
    // When the discount was applied to the root object
    appliedAt: number;
    // When the discount was considered 'redeemed' (i.e. When a payment was made towards the root object)
    redeemedAt?: number;
    appliedBy?: User;
}


export interface ObjectDiscountDetails {
    objectId?: string;
    details?: DiscountDetails;
}

export enum DiscountTypes {
    percentage = 'percentage',
    amount = 'amount'
}


export interface DiscountFilter extends ZoneDirection {
    discountIds?: string[];
    search?: string;
    name?: string;
    code?: string;
    discountType?: DiscountTypes;
    isActive?: boolean;
    activeAt?: number;
    timesRedeemedMoreThan?: number;
    timesRedeemedLessThan?: number;
    hasAnyAttributes?: string[];
    hasAllAttributes?: string[];
    singleUse?: boolean;
}


export interface ListDiscountsOutput {
    total: number;
    skipped: number;
    limited: number;
    discounts: Discount[];
}


export interface CreateDiscountInput {
    name: string;
    code: string;
    // If discountType amount value should be in cents e.g. $10 => 1000
    // If discountType percentage value should be out of 100. e.g. 50% => 50
    amount: number;
    discountType: DiscountTypes;
    // Default false
    active?: boolean;
    // If null the discount will be immediately active (assuming the active parameter is set to true)
    startsAt?: number;
    // If null discount will be active until updated
    expiresAt?: number;
    maxRedemptions?: number;
    attributes?: string[];
    metadata?: string;
}


export interface UpdateDiscountsInput {
    discountId: string;
    active?: boolean;
    description?: string;
    startsAt?: number;
    expiresAt?: number;
    maxRedemptions?: number;
    attributes?: string[];
    metadata?: string;
}

export interface DiscountsInput {
    filter?: DiscountFilter;
    skip?: number;
    limit?: number;
}

export interface CreateDiscountsInput {
    createDiscounts?: CreateDiscountInput[];
}



export interface UpdateDiscountsInput {
    updateDiscounts?: UpdateDiscountsInput[];
}



export interface DeleteDiscountsInput {
    discountIds?: string[];
}

export interface GenerateDiscountsQueryInput {
    metadata?: boolean;
    attributes?: boolean;
    maxRedemptions?: boolean;
    timesRedeemed?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GenerateDiscountsQuery(input: GenerateDiscountsQueryInput) {
    return gql`
	query discounts($filter: DiscountFilter, $skip: Int, $limit: Int, ){
		discounts(filter: $filter, skip: $skip, limit: $limit, ){
			   total
			   skipped
			   limited
			   discounts{
                    ${discountsBaseGQL}
                    ${input.maxRedemptions ? `maxRedemptions` : ``}
                    ${input.timesRedeemed ? `timesRedeemed` : ``}
                    ${input.attributes ? `attributes` : ``}
                    ${input.metadata ? metaDataGQL : ``}
               }
	        }
        }
    `;
}


export const discountsQuery = gql`
	query discounts($filter: DiscountFilter, $skip: Int, $limit: Int, ){
		discounts(filter: $filter, skip: $skip, limit: $limit, ){
			   total
			   skipped
			   limited
			   discounts{
                    id
                    createdAt
                    updatedAt
                    deletedAt
                    name
                    description
                    code
                    amount
                    discountType
                    active
                    startsAt
                    expiresAt
                    maxRedemptions
                    timesRedeemed
                    attributes
               }
	}		}
`;


export const createDiscountsMutation = gql`
	mutation createDiscounts($createDiscounts: [CreateDiscountInput!]!, ){
		createDiscounts(createDiscounts: $createDiscounts, ){
			   id
			   createdAt
			   updatedAt
			   deletedAt
			   name
			   description
			   code
			   amount			   
               active
			   startsAt
			   expiresAt
			   maxRedemptions
			   timesRedeemed
			   attributes
	}		}
`;


export const updateDiscountsMutation = gql`
	mutation updateDiscounts($updateDiscounts: [UpdateDiscountsInput!]!, ){
		updateDiscounts(updateDiscounts: $updateDiscounts, ) 
	}
`;


export const deleteDiscountsMutation = gql`
	mutation deleteDiscounts($discountIds: [String!]!, ){
		deleteDiscounts(discountIds: $discountIds, ) 
	}
`;

