<div [formGroup]="applyForm">
    <ng-template #selectTemplate>
        <div *appVar="applyForm.get('template') as control" class="p-grid">
            <div class="p-col-6">
                <h6>Template to Apply</h6>
                <hr>
                <p-dropdown
                    [options]="templateOptions"
                    placeholder="Select Template"
                    formControlName="template"
                    optionLabel="name"
                    appendTo="body"
                    data-cy="template-drop-down-field"
                ></p-dropdown>
            </div>
            <div class="p-col-6">
                <h6>Template Preview</h6>
                <hr>
                <div *ngIf="control.value">
                    <b>{{control.value.name}}</b>
                    <br>
                    <br>
                    <p><b>Start Date:</b> {{control.value.startDate}}</p>
        
                    <p><b>End Date:</b> {{control.value.endDate || 'Never'}}</p>
                    
                    <b>Availability</b>
                    <hr>
                    <p>Monday:
                        <span *ngFor="let time of control.value.availability.monday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.monday[i] | secondsToTime}} - {{control.value.availability.monday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.monday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Tuesday:
                        <span *ngFor="let time of control.value.availability.tuesday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.tuesday[i] | secondsToTime}} - {{control.value.availability.tuesday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.tuesday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Wednesday:
                        <span *ngFor="let time of control.value.availability.wednesday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.wednesday[i] | secondsToTime}} - {{control.value.availability.wednesday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.wednesday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Thursday:
                        <span *ngFor="let time of control.value.availability.thursday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.thursday[i] | secondsToTime}} - {{control.value.availability.thursday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.thursday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Friday:
                        <span *ngFor="let time of control.value.availability.friday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.friday[i] | secondsToTime}} - {{control.value.availability.friday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.friday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Saturday:
                        <span *ngFor="let time of control.value.availability.saturday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.saturday[i] | secondsToTime}} - {{control.value.availability.saturday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.saturday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                    <p>Sunday:
                        <span *ngFor="let time of control.value.availability.sunday; let even = even; let i = index">
                            <span *ngIf="even">{{control.value.availability.sunday[i] | secondsToTime}} - {{control.value.availability.sunday[i + 1] | secondsToTime}}, </span>
                        </span>
                        <span *ngIf="!control.value.availability.sunday?.length">
                            <span>No Availability</span>
                        </span>
                    </p>
                </div>
                <div *ngIf="!control.value">
                    No Template Selected
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #type>
        <div *appVar="applyForm.get('type') as control">
            <p-radioButton
                label="Normal - This will apply the template to the assets normal availability."
                value="Normal"
                formControlName="type"
            ></p-radioButton>
            <br>
            <br>
            <p-radioButton
                label="Override - This will override the assets normal availability for the dates selected."
                value="Override"
                formControlName="type"
            ></p-radioButton>
        </div>
    </ng-template>
    <ng-template #dates>
        <p-calendar selectionMode="multiple" formControlName="dates" appendTo="body"></p-calendar>
    </ng-template>
    <ng-template #assets>
        <app-asset-select formControlName="assets"></app-asset-select>
    </ng-template>
</div>

<app-mutate-object
    #co
    [fg]="applyForm"
    objectType="Availability"
    reviewButtonText="Apply Template"
    [mutateType]="'create'"
    [steps]="steps"
    (objectMutated)="applyTemplate()"
></app-mutate-object>