import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Asset, FullAvailabilityTemplateFragment, UpdateAvailabilityConfigGQL, UpdateAvailabilityConfigMutationVariables } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

export interface RemoveAvailabilityInput{
  asset: Partial<Asset>;
  template: FullAvailabilityTemplateFragment;
  type: 'Normal' | 'Override';
  date?: string;
}

@Component({
  selector: 'app-remove-availability',
  templateUrl: './remove-availability.component.html',
  styleUrls: ['./remove-availability.component.scss']
})
export class RemoveAvailabilityComponent implements OnInit {

  removeInput: RemoveAvailabilityInput;

  submitted = false;

  constructor(
    // Dialog
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    // Helpers
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    // GQL
    private updateConfigGQL: UpdateAvailabilityConfigGQL,
  ) { }

  ngOnInit(): void {
    this.removeInput = this.config.data?.input;
  }

  removeTemplate(){
    this.submitted = true;

    const input: UpdateAvailabilityConfigMutationVariables = {
      input: {
        objectId: this.removeInput.asset.id,
      }
    };

    // Set the parameters based on the availability type
    if (this.removeInput.type === 'Normal'){
      input.input.removeTemplates = [this.removeInput.template.id];
    } else if (this.removeInput.type === 'Override'){
      input.input.removeOverrides = [{
        dates: [this.removeInput.date],
        templateId: this.removeInput.template.id,
      }];
    }

    this.updateConfigGQL.mutate(input).subscribe((res) => {
      this.localNotify.success(`${this.removeInput.template.name} removed from ${this.removeInput.asset.name}`);

      this.detailsHelper.pushUpdate({
        action: 'update',
        id: this.removeInput.asset.id,
        type: 'AvailabilityConfig',
      });

      this.detailsHelper.pushUpdate({
        action: 'update',
        id: this.removeInput.asset.id,
        type: 'Assets',
      });
      this.ref.close();
    }, (err) => {
      this.localNotify.error(`Failed to remove ${this.removeInput.template.name} from ${this.removeInput.asset.name}`, err);
      this.ref.close();
    });
  }

}
