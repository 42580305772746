/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { AddSubLocationsInput, addSubLocationsMutation, AddSubLocationsOutput, CreateLocationsFunctionInput, createLocationsMutation, CreateLocationsOutput, EditLocationsInput, editLocationsMutation, EditLocationsOutput, generateLocationQuery, GenerateLocationQueryInput, ListLocationsInput, ListLocationsOutput, RemoveLocationsInput, removeLocationsMutation, RemoveLocationsOutput, RemoveSubLocationsInput, removeSubLocationsMutation, RemoveSubLocationsOutput, SetUserLocationsInput, setUserLocationsMutation, SetUserLocationsOutput } from '@karve.it/interfaces/locations';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

    constructor(private apollo: PlusApollo){}

    /**
     * List locations.
     *
     * @param input Criteria to filter the locations by.
     * @param queryInput Specifies which GQL properties should be queried.
     * @returns QueryRef, List of locations and query information.
     */
    listLocations(input: ListLocationsInput, queryInput: GenerateLocationQueryInput, opts?: KarveRequestOptions){
      return this.apollo.query<ListLocationsOutput>({
        query: generateLocationQuery(queryInput),
        variables: {
          ...input
        },
        ...parseRequestOptions(opts),
      });
    }

    /**
     * List locations, watches for changes.
     *
     * @param input Criteria to filter the locations by.
     * @returns QueryRef, List of locations and query information.
     */
    watchLocations(input: ListLocationsInput, queryInput: GenerateLocationQueryInput, opts?: KarveWatchRequestOptions){
      return this.apollo.watchQuery<ListLocationsOutput>({
        query: generateLocationQuery(queryInput),
        variables: {
          ...input
        },
        ...parseRequestOptions(opts),
      });
    }

    /**
     * Create new locations.
     *
     * @param input Values for the new locations.
     * @param overrideZone Id of the zone you want the query to take place in
     * @returns QueryRef, List of the created locations.
     */
    CreateLocations(input: CreateLocationsFunctionInput, overrideZone = ''){
      return this.apollo.mutate<CreateLocationsOutput>({
        mutation: createLocationsMutation,
        context: {
          headers: {
            'x-zone': overrideZone,
          },
        },
        variables: {
          ...input
        },
      });
    }

    /**
     * Edit existing locations.
     *
     * @param input New values for the locations.
     * @returns QueryRef, Total number edited and the edited locations.
     */
    EditLocations(input: EditLocationsInput){
      return this.apollo.mutate<EditLocationsOutput>({
        mutation: editLocationsMutation,
        variables: {
          ...input
        }
      });
    }

    /**
     * Remove Locations from the system.
     *
     * @param input Ids of the locations you want to remove
     * @returns QueryRef, Ids of the removed locations and the total number.
     */
    RemoveLocations(input: RemoveLocationsInput){
      return this.apollo.mutate<RemoveLocationsOutput>({
        mutation: removeLocationsMutation,
        variables: {
          ...input
        }
      });
    }

    /**
     * Add Locations as children of another location.
     *
     * @param input Information on the sublocation you want to add.
     * @returns QueryRef, Information on the created locations and parents.
     */
    AddSubLocations(input: AddSubLocationsInput){
      return this.apollo.mutate<AddSubLocationsOutput>({
        mutation: addSubLocationsMutation,
        variables: {
          ...input
        }
      });
    }

    /**
     * Remove a location as a child of another location
     *
     * @param input
     * @returns
     */
    RemoveSubLocations(input: RemoveSubLocationsInput){
      return this.apollo.mutate<RemoveSubLocationsOutput>({
        mutation: removeSubLocationsMutation,
        variables: {
          ...input
        }
      });
    }

    /**
     * Add or Remove locations to/from a user.
     *
     * @param input Locations to modify their relationship with the user.
     * @returns QueryRef, The ids of the locations added, removed and the user.
     */
    SetUserLocations(input: SetUserLocationsInput){
      return this.apollo.mutate<SetUserLocationsOutput>({
        mutation: setUserLocationsMutation,
        variables: {
          ...input
        }
      });
    }

}
