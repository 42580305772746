// Stores GQL Pieces that are used across files this is done to prevent access before initialization errors

export enum ZoneDir{
  eq = 'eq',
  gte = 'gte',
  gt = 'gt',
  lte = 'lte',
  lt = 'lt',
  // eslint-disable-next-line id-blacklist
  any = 'any',
}

export interface ZoneDirection {
  zoneDir?: ZoneDir;
}

// Users
export const usersBaseGQL = `
  id
  email
  phone
  company
  givenName
  familyName
`;

export const locationBaseGQL = `
    id
    individualCapacity
    addressLineOne
    addressLineTwo
    name
    areaCode
    city
    countryJurisdiction
    country
    parentId
`;

export const jobsBaseGQL = `
    id
    collectionId
    attributes
    code
    stage
    currency
    startLocation {
      ${locationBaseGQL}
      attributes
    }
    endLocation {
      ${locationBaseGQL}
      attributes
    }
    depositRequired
    subTotal
    taxTotal
    total
    paidTotal
    discountTotal
    pendingTotal
`;

export const discountsBaseGQL = `
    id
    createdAt
    updatedAt
    deletedAt
    name
    description
    code
    amount
    discountType
    active
    startsAt
    expiresAt
`;


export const attributesGQL = `
  attributes
`;

export const discountDetailsGQL = `
  discount {
    ${discountsBaseGQL}
  }
  appliedAt
  redeemedAt
  appliedBy {
    id
  }
`;

export const fieldsBaseGQL = `
  fields {
    id
    name
    type
    default
    values {
      objectId
      value
      isDefault
      createdAt
      updatedAt
    }
  }
`;
