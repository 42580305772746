import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchValidator(
    source: string,
    target: string,
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const sourceCtrl = control.get(source);
        const targetCtrl = control.get(target);

        return sourceCtrl?.value !== targetCtrl?.value
          ? {
            mismatch: { source, target },
        }
          : null;
      };
}
