import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';

import { SchedulesComponent } from './schedules.component';

@Injectable({
  providedIn: 'root'
})
export class LeaveScheduleGuard  {

  constructor(
    private confirmService: ConfirmationService
  ) { }

  canDeactivate(
    component: SchedulesComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!component.latestEventEdits?.length) {
      return of(true);
    }

    const sub = new Subject<boolean>();

    this.confirmService.confirm({
      message: 'You have unsaved changes. If you continue all unsaved changes will be discarded.',
      // accept: 'Save & Continue',
      acceptLabel: 'Continue and Discard',
      rejectLabel: 'Return To Editing',
      header: 'Discard Changes?',
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: true,
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
      // rejectIcon: ''
      accept: async () => {
        sub.next(true);
      },
      reject: (eventCode) => {
        sub.next(false);
      },
    });

    return sub.asObservable();
  }

}
