<div class="container">
    <app-password-confirm
        (isValid)="isValid = $event"
        (passwordValid)="password = $event"
    ></app-password-confirm>
</div>

<div class="footer">
    <button
        pButton
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="closeDialog()"
    ></button>

    <button
        pButton
        label="Change"
        class="p-button-success"
        [disabled]="!isValid"
        [icon]="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (click)="editUserPassword()"
    ></button>


</div>