import { Component, Input, TemplateRef } from '@angular/core';

import { ResponsiveHelperService } from '../../services/responsive-helper.service';

@Component({
  selector: 'app-info-circle',
  templateUrl: './info-circle.component.html',
  styleUrls: ['./info-circle.component.scss']
})
export class InfoCircleComponent {

  @Input() hidden = false;
  @Input() tooltip: string;

  constructor(
    public responsiveHelper: ResponsiveHelperService
  ) { }

}
