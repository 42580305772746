import { Pipe, PipeTransform } from '@angular/core';

import { FullNotificationFragment } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';

@Pipe({
  name: 'notificationRead',
  pure: false,
})
export class NotificationReadPipe implements PipeTransform {

  constructor(
    private plusAuth: PlusAuthenticationService
  ) {}

  transform(notification: FullNotificationFragment, userId?: string): unknown {
    userId = userId || this.plusAuth.user.id;
    const { recipients } = notification;
    if (!recipients) { return false; }
    const self = recipients.find((r) => r.recipientId === userId);
    if (!self) { return false; }
    return Boolean(self.readAt);
  }

}
