<div class="auth-container" #authContainer>
    <div class="auth-header"></div>
    <div class="auth-body">
        <img
            class="app-logo"
            [src]="brandingService.watchIcon(256, 'light') | async"
            alt="Application Logo"
            width="256px"
            height="256px"
        />

        <h1>Sign up for Move Right</h1>

        <div *ngIf="token && !tokenLoading">
            <p-messages
                [(value)]="signupMessages"
                [enableService]="false"
            ></p-messages>
            <form
                class="auth-form"
                [formGroup]="signUpForm"
                (submit)="signup()"
            >
                <div class="input-container">

                    <div *ngIf="showBusinessInfo">

                        <div *appVar="signUpForm.get('businessName') as control">
                            <label>Business Name</label>
                            <input
                                type="text"
                                class="login-input"
                                id="business_name"
                                placeholder="Business Name"
                                formControlName="businessName"
                                [class.p-invalid-input]="!control.valid && control.touched"
                                pInputText
                            >
                            <app-validation-messages
                                [control]="control"
                                [requireTouched]="true"
                            ></app-validation-messages>
                        </div>
                        <div *appVar="signUpForm.get('businessID') as control">
                            <label>Business ID</label>
                            <small class="description">
                                This is used to identify your business.
                                Must be unique within Move Right and only container
                                lowercase alphanumeric characters or dashes
                            </small>
                            <input
                                type="text"
                                class="login-input"
                                id="business_id"
                                placeholder="Business ID"
                                formControlName="businessID"
                                [class.p-invalid-input]="!control.valid && control.touched"
                                pInputText
                            >
                            <app-validation-messages
                                [control]="control"
                                [requireTouched]="true"
                            ></app-validation-messages>
                        </div>
                        <div *appVar="signUpForm.get('businessCode') as control">
                            <label>Business Code</label>
                            <small class="description">
                                This code prefixes all your jobs, estimates, and invoices. Ideally, keep this between 1-4 characters.
                            </small>
                            <input
                                type="text"
                                class="login-input"
                                id="business_code"
                                placeholder="Business Code"
                                formControlName="businessCode"
                                [class.p-invalid-input]="!control.valid && control.touched"
                                pInputText
                            >
                            <app-validation-messages
                                [control]="control"
                                [requireTouched]="true"
                            ></app-validation-messages>
                        </div>
                        <div *appVar="signUpForm.get('businessAddress') as control">
                            <label>Business Address</label>
                            <small class="description">
                                Where is your "dock" location? IE, where will you be originating your jobs from?
                            </small>

                            <input
                                *ngIf="googleHelper.loaded | async"
                                #googleAutocomplete="ngx-places"
                                #locationInput
                                id="business_address"
                                formControlName="businessAddress"

                                type="text"
                                pInputText
                                ngx-gp-autocomplete

                                class="location-input full-width login-input"
                                [class.p-invalid-input]="!control.valid && control.touched"

                                [options]="googleAutocompleteOptions"
                                placeholder="Business Address"
                                autocomplete="off"

                                (onAddressChange)="handleAddressChange($event, 'start')"
                                (change)="handleAddressTextChange($event)"

                                data-cy="start-location-text-field"
                            />
                            <app-validation-messages
                                [control]="control"
                                [requireTouched]="true"
                            ></app-validation-messages>
                        </div>

                        <hr />
                    </div>

                    <div *appVar="signUpForm.get('givenName') as control">
                        <input
                            type="text"
                            class="login-input"
                            id="auth_first_name"
                            placeholder="First Name"
                            formControlName="givenName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('familyName') as control">
                        <input
                            type="text"
                            class="login-input"
                            id="auth_last_name"
                            placeholder="Last Name"
                            formControlName="familyName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('email') as control">
                        <input
                            type="email"
                            class="login-input"
                            id="auth_email_name"
                            placeholder="Email"
                            formControlName="email"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>

                    <div *appVar="signUpForm.get('password') as control">
                        <input
                            pPassword
                            type="password"
                            id="password"
                            [feedback]="false"
                            formControlName="password"
                            placeholder="Password"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        />
                        <app-validation-messages
                            [control]="control"
                            fieldName="Password"
                        >
                        </app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('passwordConfirm') as control">
                        <input
                            pPassword
                            type="password"
                            id="password-confirm"
                            [feedback]="false"
                            formControlName="passwordConfirm"
                            placeholder="Confirm Password"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        />
                        <app-validation-messages
                            [control]="control"
                            fieldName="Password Confirm"
                        >
                        </app-validation-messages>
                        <small class="field-error-message" *ngIf="!passwordsValid() && control.touched">
                            Passwords must match
                        </small>
                    </div>

                    <div class="auth-btn-set">
                        <button
                            pButton
                            pRipple
                            label="Sign Up"
                            id="auth-signup-button"
                            class="auth-btn p-button-lg p-button-primary signup-submit-button"
                            pRipple
                            type="submit"
                            [disabled]="!signUpForm.valid || !passwordsValid() || formSubmitted || !passwordAuthMethod"
                        >
                        </button>
                        <ng-container *ngIf="formSubmitted">
                            <p-progressBar
                                mode="indeterminate"
                                [style]="{ height: '6px' }"
                            ></p-progressBar>
                            <div style="text-align: center;width: 100%;">
    
                                This may take
                                <span *ngIf="!showBusinessInfo">a second</span>
                                <span *ngIf="showBusinessInfo">up to one minute</span>
                            </div>
                        </ng-container>
                    </div>

                </div>
            </form>
        </div>

        <div [hidden]="token || tokenLoading">
            <div class="sign-up-error">
                <h2>Sign-up link expired</h2>
                <p>It looks like your sign up link has already been used or has expired.</p>
                <p><a [routerLink]="[ '/auth', 'login' ]">Go to login</a></p>
                
            </div>
        </div>

    </div>
    <app-auth-footer supportMessage="I'm having trouble signing up"></app-auth-footer>
</div>

<div
    *ngIf="tokenLoading"
    class="loading-cover"
>
    <i
        class="pi pi-spin pi-spinner"
        style="font-size: 4rem"
    ></i>
</div>