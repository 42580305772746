import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'canChangeZone'
})
export class CanChangeZonePipe implements PipeTransform {
  transform(route: string): unknown {

    const jobToolRegex = /\/jobs\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;
    const isOnJobTool = jobToolRegex.test(route);

    const isEstimating = route.indexOf('estimating') >= 0;

    return !isEstimating && !isOnJobTool;
  }
}
