import { gql } from 'graphql-tag';

import { User } from './auth';
import { CalendarEvent } from './calendarEvents';
import { ZoneDirection } from './common.gql';
import { Product , Price } from './products';
import { Tax } from './taxes';

export interface ListChargesOutput {
	charges: ChargesPagination;
}

export interface CreateChargesOutput {
	createCharges: Charge[];
}

export interface UpdateChargesOutput {
	updateCharges: boolean;
}

export interface DeleteChargesOutput {
	deleteCharges: boolean;
}

export interface Charge {
    id: string;
    product: Product;
    price: Price;
    quantity: number;
    taxes?: Tax[];
    // unitCost?: number;
    currency: string;
    // values: 'pending', 'unpaid', 'paid'""";
    // status: string;
    user: User;
    // The calendarEvent the charge is associated with.
    calendarEvent?: CalendarEvent;
    attributes?: string[];
    metadata?: string;
    createdAt?: number;
    updatedAt?: number;
    deletedAt?: number;

    chargeSubTotal?: number;
    discountTotal?: number;
    discountedSubTotal?: number;
    taxTotal?: number; // calculated from discountedSubTotal
    total?: number;

    productName?: string;
    amount?: number;
}


export interface ChargesPagination {
    total?: number;
    skipped?: number;
    limited?: number;
    charges: Charge[];
}


export interface ChargesFilter extends ZoneDirection {
    ids?: string[];
    quoteIds?: string[];
    taxIds?: string[];
    productId?: string;
    priceId?: string;
    userIds?: string[];
    eventId?: string;
    createdBefore?: number;
    createdAfter?: number;
    attributesInclusive?: string[];
    attributesExclusive?: string[];
    showDeleted?: boolean;
}


export interface CreateChargeInput {
    attributes?: string[];
    metadata?: string;
    productId?: string;
    quantity: number;
    taxIds?: string[];
    eventId?: string;
    discountId?: string;

    // If you are creating without an existing product
    productName?: string;
    amount?: number;
}


export interface ChargeUpdate {
    id: string;
    // productId?: string;
    priceId?: string;
    quantity?: number;
    addTaxIds?: string[];
    removeTaxIds?: string[];
    eventId?: string;
    userId?: string;
    attributes?: string[];

    // If you are updating without an existing product
    productName?: string;
    amount?: number;
}


export interface ChargesInput {
	filter?: ChargesFilter;
	limit?: number;
	skip?: number;
	sort?: string;
}



export interface CreateChargesInput {
	charges?: CreateChargeInput[];
    userId: string;
}



export interface UpdateChargesInput {
	charges?: ChargeUpdate[];
}



export interface DeleteChargesInput {
	ids?: string[];
}



export const chargesQuery = gql`
	query charges($filter: ChargesFilter, $limit: Int, $skip: Int, $sort: String, ){
		charges(filter: $filter, limit: $limit, skip: $skip, sort: $sort, ){
			    total
			    skipped
			    limited
			    charges{
                    id
                    product {
                        id
                    }
                    price {
                        id
                    }

                    chargeSubTotal
                    discountTotal
                    discountedSubTotal
                    taxTotal
                    total

                    quantity
                    taxes { id }
                    amount
                    chargeSubTotal
                    productName
                    user {
                        id
                    }
                    calendarEvent {
                        id
                    }
                    attributes
                    metadata
                    createdAt
                    updatedAt
                    deletedAt
                }
	}		}
`;


export const createChargesMutation = gql`
	mutation createCharges($charges: [CreateChargeInput!]!, $userId: String!){
		createCharges(charges: $charges, userId: $userId){
			    id
			    product{ id }
                productName
                price{ id }
                amount
                currency
                quantity
			    taxes { id }
			    user { id }			    
                calendarEvent{ id }
                chargeSubTotal
        	    attributes
			    metadata
			    createdAt
			    updatedAt
			    deletedAt

	}		}
`;


export const updateChargesMutation = gql`
	mutation updateCharges($charges: [ChargeUpdate!]!, ){
		updateCharges(charges: $charges, )

	}
`;


export const deleteChargesMutation = gql`
	mutation deleteCharges($ids: [String], ){
		deleteCharges(ids: $ids, )

	}
`;

