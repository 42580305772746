import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchClass'
})
export class MatchClassPipe implements PipeTransform {

  transform(value: any, values: any[], classes: string[]): string {
    return classes[values.findIndex((v) =>  v === value)];
  }

}
