import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlusAuthenticationService, UserService } from '@karve.it/core';
import { RawUser } from '@karve.it/interfaces/auth';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  user: RawUser;

  constructor(
    private userService: UserService,
    private plusAuth: PlusAuthenticationService
  ) { }

  ngOnInit(): void {

    const queryInput = {
      twoFactorEnabled: true,
      roles: true,
    };

    this.subs.sink = this.userService.listUsersV2({
      userIds: [this.plusAuth.user.id]
    }, queryInput).subscribe((res) => {
      this.user = res.data.usersv2.users[0];
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
