<p-overlayPanel styleClass="dashboard-info" #op>
    <ng-template pTemplate>
      <ng-content></ng-content>
    </ng-template>
</p-overlayPanel>

<button pButton type="button"
	class="pi pi-info-circle help-btn"
  (click)="op.toggle($event)"
></button>
