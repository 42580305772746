import { FullPermissionFragment } from '../../generated/graphql.generated';
import { safeParseJSON } from '../js';

function regixify(str: string) {
	return str.replace('.', '\\.').replace('*', '.*');
}

export function resolvePermissionFromArray(
	permissions: FullPermissionFragment[],
	permission: string,
) {
	let foundDepth = 0;
	let foundDistance = 0;
	let foundSubDistance = 0;
	let foundPermission: FullPermissionFragment;
	let exactMatch = false;

	// if it is not already in the output
	for (const p of permissions) {
		let matches = false;
		let matchDistance = 0;
		let subDistance = 0;

		if (p.permission === permission) {
			matches = true;
			exactMatch = true;
		} else if (permission.match(`^${regixify(p.permission)}$`)) {
			matches = true;
			for (const char of p.permission) {
				if (char === '*') { matchDistance++; }
			}
			subDistance = p.permission.indexOf('*');
		}

		if (!matches) { continue; }

		if (
			// permission not yet found
			!foundPermission ||
			// permission depth is less than found
			foundDepth < p.zoneDepth ||
			// regex distance is less
			(foundDepth === p.zoneDepth && matchDistance < foundDistance) ||
			// *'s are further away
			(foundDepth === p.zoneDepth && matchDistance === foundDistance && foundSubDistance < subDistance)
		) {
			foundPermission = p;
			foundDepth = p.zoneDepth;
			foundDistance = matchDistance;
			foundSubDistance = subDistance;
		}

		if (exactMatch && foundPermission) {
			break;
		}
	}
	// CONSIDER: somehow combine restrictions if we are an exact match

	// permissionMap[permission] = foundPermission;
	return foundPermission;
}

export function allPermissionsExist(
	permissions: (FullPermissionFragment | undefined | boolean)[],
): boolean {
	for (const p of permissions) {
		if (!p) { return false; }
	}

	return true;
}

export function permissionHasRestrictions(
	permission: FullPermissionFragment,
	restrictionsForPermission: any,
) {
	const permissionRestrictions = safeParseJSON(permission.restrictions, {});
	for (const key of Object.keys(restrictionsForPermission)) {
		// TODO: RESTRICTIONS can be arrays, check for that
		// If the restriction value does not match the value passed to check then evaluate to false
		if (restrictionsForPermission[key] !== permissionRestrictions[key]) {
			return false;
		}
	}

	return true;
}

export function permissionHasRestriction(
	permission: FullPermissionFragment,
	name: string,
	value = true,
) {
	return permissionHasRestrictions(permission, {
		[name]: value,
	});
}
