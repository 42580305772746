import { createActionGroup, props } from '@ngrx/store';

import { AddCommentInput, BaseCommentFragment, JobChangedEvent, UpdateCommentPartial } from '../../../generated/graphql.generated';

export type TypedJobChangedEvent<I, O> = {
	input?: I;
	output?: O;
} & Omit<JobChangedEvent, 'input' | 'output'>;

// These are called dynamically in jobsv2.component.ts
export const JobToolSubscriptionActions = createActionGroup({
	source: 'Job Tool Subscription',
	events: {
		'remote comment added': props<TypedJobChangedEvent<AddCommentInput, BaseCommentFragment>>(),
		'remote comment updated': props<TypedJobChangedEvent<UpdateCommentPartial, BaseCommentFragment>>(),

	},
});
