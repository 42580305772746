
<ng-container [ngSwitch]="ag.format">
    <ng-container *ngSwitchCase="'currency'">
        {{ value / 100 | currency: currency }}
    </ng-container>
    <ng-container *ngSwitchDefault>

        {{ value }}
    </ng-container>

</ng-container>
