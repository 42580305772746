<div class="card conversions" *ngIf="conversions$ | async as conversions">
  <div class="dashboard-card-header p-mb-4">
    <div>
      <h4 class="p-mb-1">Conversions<app-error-icon *ngIf="conversions.error"></app-error-icon></h4>
      {{ conversions.period }}
    </div>
    <app-dashboard-info-v2>
      <div class="p-mb-2">
        <b>CSC:</b>
        Out of all jobs created in the selected period that came in as CSC leads<span *ngIf="!conversions.leadsLoading"> ({{ conversions.cscTotal }})</span>,
        the percentage that are currently in "booking" or "invoiced" stage<span *ngIf="!conversions.leadsLoading"> ({{ conversions.cscConverted }})</span>.
      </div>
      <div class="p-mb-2">
        <b>OBE:</b>
        Out of all jobs created in the selected period that came in as OBE leads<span *ngIf="!conversions.leadsLoading"> ({{ conversions.obeTotal }})</span>,
        the percentage that are currently in "booking" or "invoiced" stage<span *ngIf="!conversions.leadsLoading"> ({{ conversions.obeConverted }})</span>.
      </div>
      <div class="p-mb-2">
        <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="conversions.leadsQueryParams" target="_blank">View Report</a>.
      </div>
      <div class="p-mb-2">
        <b>OSE:</b>
        Out of all jobs created in the selected period that have a <b>completed estimating event</b><span *ngIf="!conversions.oseLoading"> ({{ conversions.oseTotal }})</span>,
        the percentage that are currently in "booking" or "invoiced" stage<span *ngIf="!conversions.oseLoading"> ({{ conversions.oseConverted }})</span>.
      </div>
      <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="conversions.oseQueryParams" target="_blank">View Report</a>.
    </app-dashboard-info-v2>
  </div>
  <div class="stats wrap" [class.p-flex-column]="layoutSize === 'small'">
    <div>
      CSC
      <app-dashboard-number
        [loading]="conversions.leadsLoading"
        skeletonWidth="90%"
        [amount]="conversions.cscPercent"
        [large]="layoutSize === 'small'"
        [params]="conversions.leadsQueryParams"
        mode="percent"
        ></app-dashboard-number>
        <app-delta [delta]="conversions.cscPercentDelta" mode="percent"></app-delta>
    </div>
    <div>
      OBE
      <app-dashboard-number
        [loading]="conversions.leadsLoading"
        skeletonWidth="70%"
        [amount]="conversions.obePercent"
        [params]="conversions.leadsQueryParams"
        [large]="layoutSize === 'small'"
        mode="percent"
        ></app-dashboard-number>
      <app-delta [delta]="conversions.obePercentDelta" mode="percent"></app-delta>
    </div>
    <div>
      OSE
      <app-dashboard-number
        [loading]="conversions.oseLoading"
        skeletonWidth="70%"
        [amount]="conversions.osePercent"
        [params]="conversions.oseQueryParams"
        [large]="layoutSize === 'small'"
        mode="percent"
        ></app-dashboard-number>
      <app-delta [delta]="conversions.osePercentDelta" mode="percent"></app-delta>
    </div>
  </div>
</div>
