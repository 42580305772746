import { stat } from 'fs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resolvePaymentIcon'
})
export class ResolvePaymentIconPipe implements PipeTransform {

  transform(status: string,): string {
    if (status === 'loading') {
      return 'pi pi-spin pi-spinner';
    } else if(status === 'succeeded') {
      return 'pi pi-check-circle success-text';
    } else if (status === 'processing') {
      return 'pi pi-spin pi-spinner warning-text';
    } else  if (status === 'requires_payment_method') {
      return 'pi pi-times-circle error-text';
    } else {
      return 'pi pi-times-circle error-text';
    }
  }

}
