import { ApplicationRef, Injectable, NgZone, OnDestroy } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { Subject, timer } from 'rxjs';
import { debounceTime, first, repeatWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { FullNotificationFragment, LatestAndUnreadNotificationsGQL, LatestAndUnreadNotificationsQuery, LatestAndUnreadNotificationsQueryVariables  } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';
import { UNREAD_NOTIFICATIONS_COUNT_MAX } from '../global.constants';
import { currentTimeSeconds } from '../time';
import { runInZone } from '../utilities/rxjs.util';

import { FreyaHelperService } from './freya-helper.service';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

  subs = new SubSink();

  latest: FullNotificationFragment[] = [];
  unread: number;

  notificationsQueryRef: QueryRef<LatestAndUnreadNotificationsQuery, LatestAndUnreadNotificationsQueryVariables>;

  notificationRead = new Subject<string>();
  newNotifications = new Subject<void>();

  lastRefetch: number;
  pollInterval = 120000;

  // Used to notify other tabs of updates
  broadcastChannel: BroadcastChannel;

  // Used to debounce notifications and make sure emissions run in Angular's zone/trigger change detection
  broadcastEmitted = new Subject<void>();

  constructor(
    private plusAuth: PlusAuthenticationService,
    private latestAndUnreadGQL: LatestAndUnreadNotificationsGQL,
    private appRef: ApplicationRef,
    private freyaHelper: FreyaHelperService,
    private ngZone: NgZone,
    private permissionHandler: PermissionService,
  ) {
    this.fetchLatestNotifications();

    this.startPolling();
    this.watchOtherTabs();

    this.subs.sink = this.notificationRead
      .subscribe((notificationId) => {
        this.markAsRead(notificationId);
        this.fetchLatestNotifications();
      });

    this.subs.sink = this.plusAuth.authState
      .subscribe((state) => {
        if (state === 'authenticated') {
          this.fetchLatestNotifications();
          return;
        }
        if (state === 'deauthenticated') {
          this.clear();
          return;
        }
      });
  }

  ngOnDestroy(): void {
    this.clear();
  }

  clear() {
    this.subs.unsubscribe();
    this.latest = undefined;
    this.unread = undefined;
    this.lastRefetch = undefined;
  }

  fetchLatestNotifications() {
    if (!this.permissionHandler.checkPermissions([ 'users.viewNotifications' ])) { return; }

    if (this.notificationsQueryRef) {
      this.notificationsQueryRef.refetch();
      return;
    };

    const notificationsQueryVars: LatestAndUnreadNotificationsQueryVariables = {
      userId: this.plusAuth.user.id,
      latestLimit: 5,
      unreadLimit: UNREAD_NOTIFICATIONS_COUNT_MAX + 1
    };

    this.notificationsQueryRef = this.latestAndUnreadGQL.watch(notificationsQueryVars, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.notificationsQueryRef.valueChanges
    .subscribe((res) => {
      if (res.loading) { return; }
      const [ user ] = res.data.usersv2.users;
      this.checkForNewNotifications(user.latest.notifications);
      this.latest = user.latest.notifications;
      this.unread = user.unread.total;
      this.lastRefetch = currentTimeSeconds();
    });
  }

  checkForNewNotifications(newLatest: FullNotificationFragment[]) {
    for (const notification of newLatest) {
      const existingNotification = this.latest.find((n) => n.id === notification.id);
      if (existingNotification) { continue; }
      this.newNotifications.next();
      if (!this.broadcastChannel) { return; }
      this.broadcastChannel.postMessage(true);
      return;
    }
  }

  markAsRead(notificationId: string) {
    if (!notificationId || !this.latest) { return; }

    const notification = this.latest.find((n) => n.id === notificationId);
    if (!notification) { return; }

    const self = notification.recipients.find((r) => r.recipientId === this.plusAuth.user.id);

    self.readAt = currentTimeSeconds();
  }

  startPolling() {
    this.subs.sink = this.appRef.isStable.pipe(
      first(stable => stable),
      tap(() => this.checkLastRefetch()),
      switchMap(() => timer(0, this.pollInterval)),
      tap(() => this.fetchLatestNotifications()),
      takeUntil(this.freyaHelper.pageHidden),
      repeatWhen(() => this.freyaHelper.pageVisible),
    ).subscribe();
  }

  checkLastRefetch() {
    if ((currentTimeSeconds() - this.lastRefetch) < this.pollInterval) {
      this.fetchLatestNotifications();
    }
  }

  /**
   * Initializes a broadcast channel to watch for changes in other tabs
   */
  watchOtherTabs() {
    try {
      this.broadcastChannel = new BroadcastChannel('new-notifications');
    } catch (e) {
      console.warn(e);
    }

    if (!this.broadcastChannel) { return; }

    this.broadcastChannel.onmessage = () => this.broadcastEmitted.next();

    this.subs.sink = this.broadcastEmitted
    .pipe(
      debounceTime(100),
      runInZone(this.ngZone)
      )
    .subscribe(() => this.fetchLatestNotifications());
  }

}
