import { gql } from 'graphql-tag';

export interface ListGetConfigValuesOutput {
	getConfigValues: ConfigValue[];
}

export interface SetConfigValuesOutput {
	setConfigValues: ConfigValue[];
}

export interface ConfigValue {
	key: string;
	zone?: string;
	value?: string;
	createdAt?: number;
	updatedAt?: number;
}

export interface ConfigValueInput {
	key: string;
	value?: string;
	//	Removes the config value from the database and uses the value from higher levels instead
	reset?: boolean;
}


export interface GetConfigValuesInput {
	omitZone?: boolean;
	keys?: string[];
}


export interface SetConfigValuesInput {
	omitZone?: boolean;
	configs?: ConfigValueInput[];
}



export const getConfigValuesQuery = gql`
	query getConfigValues($omitZone: Boolean, $keys: [String], ){
		getConfigValues(omitZone: $omitZone, keys: $keys, ){
				key			
        zone
				value
				createdAt
				updatedAt
	  }		
  }
`;


export const setConfigValuesMutation = gql`
	mutation setConfigValues($omitZone: Boolean, $configs: [ConfigValueInput!]!, ){
		setConfigValues(omitZone: $omitZone, configs: $configs, ){
				key			
        zone
				value
				createdAt
				updatedAt
	  }		
  }
`;
