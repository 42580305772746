<p-inputMask
    [(ngModel)]="inputDate"
    mask="99/99/9999"
    slotChar="mm/dd/yyyy"
    placeholder="mm/dd/yyyy"
    (click)="$event.preventDefault(); openCalendar($event)"
    (onInput)="openCalendar($event)"
    (ngModelChange)="setCalendarDate($event)"
></p-inputMask>
<p-overlayPanel
    #op
    styleClass="overlay-panel-date"
    [focusOnShow]="false"
    >
    <ng-template pTemplate>
        <p-calendar
            [(ngModel)]="calendarDate"
            (onSelect)="setInputDate()"
            dataType="string"
            appendTo="body"
            [showOnFocus]="showOnFocus"
            [inline]="true"
        ></p-calendar>
    </ng-template>
</p-overlayPanel>

