<div *ngIf="viewType === 'layout'">
	<div class="doc-row">
		<span>Visual Inventory</span>
		<button
			pButton
			icon="pi pi-eye"
			(click)="viewDocument('inventory')"
			[disabled]="!visualInventory"
		></button>
		<button
			pButton
			icon="pi pi-download"
			(click)="downloadDocument('inventory')"
			[disabled]="!visualInventory"
		></button>
	</div>
	<br>
	<div class="doc-row">
		<span>Cube Sheet</span>
		<button
			pButton
			icon="pi pi-eye"
			(click)="viewDocument('cube-sheet')"
			[disabled]="!cubeSheet"
		></button>
		<button
			pButton
			icon="pi pi-download"
			(click)="downloadDocument('cube-sheet')"
			[disabled]="!cubeSheet"
		></button>
	</div>
</div>

<div class="actions" *ngIf="viewType === 'actions'">
	<button
		pButton
		pRipple
		class="p-m-1"
		label="Actions"
		(click)="menu.toggle($event)"
		icon="pi pi-angle-down"
	></button>

	<p-menu
		#menu
		[popup]="true"
		appendTo="body"
		[model]="inventoryActions"
	></p-menu>
</div>