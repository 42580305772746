import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';

import { SubSink } from 'subsink';

import { HistoryService } from '../../history/history.service';

import { RawUser } from '../../interfaces/auth';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { PermissionService } from '../../services/permission.service';
import { RecentItemsService } from '../../services/recent-items.service';
import { DisabledWhen, KarveMenuItem, setMenuItemDisabled } from '../../utilities/menu-item.util';
import { ChangeUserPasswordComponent } from '../change-user-password/change-user-password.component';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user: RawUser;
  @Input() disabled: boolean;

  subs = new SubSink();

  editUserAction: KarveMenuItem = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'user',
        object: this.user,
      });
    },
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    }
  };

  deleteUserAction: KarveMenuItem = {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => {
      this.freyaMutate.openDeleteObject({
        objectId: this.user.id,
        objectName: this.userName,
        objectType: 'user',
      });
    },
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    }
  };

  bookJobAction: KarveMenuItem = {
    label: 'Book Job',
    icon: 'pi pi-money-bill',
    command: () => {
      this.router.navigate(['estimating'], { queryParams: { step: 'customer', userId: this.user.id } });
    },
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
  };

  changePasswordUserAction: KarveMenuItem = {
    label: 'Change password',
    icon: 'pi pi-key',
    command: () => {
      this.openEditUserPasswordDialog();
    },
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    }
  };

  viewHistoryAction = {
    label: 'View History',
    icon: 'pi pi-book',
    command: () => {
      if (!this?.user?.id) { return; }
      this.historyService.openHistory('User', [this.user.id]);
    }
  };

  userActions = [{
    label: 'User Actions',
    items: [
      this.editUserAction,
      this.deleteUserAction,
      this.bookJobAction,
      this.changePasswordUserAction,
      this.viewHistoryAction,
    ]
  }];

  constructor(
    private freyaMutate: FreyaMutateService,
    private router: Router,
    private dialogService: DialogService,
    private permissionHandler: PermissionService,
    private recentItems: RecentItemsService,
    private freyaHelper: FreyaHelperService,
    private historyService: HistoryService,
  ) { }


  ngOnInit(): void {
    this.initializePermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('user')) { return; }
    this.recentItems.setPinAction(this.userActions, this.user, 'users');
    this.setDisabledActions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions([
      'users.edit',
      'users.delete',
      'users.setPassword',
      'frontend.estimating',
      'jobs.update',
    ])
      .subscribe((res) => {
        this.editUserAction.visible = res[0];
        this.deleteUserAction.visible = res[1];
        this.changePasswordUserAction.visible = res[2];
        this.bookJobAction.visible = res[3] && res[4];
      });
  }

  openEditUserPasswordDialog() {
    this.dialogService.open(ChangeUserPasswordComponent, {
      header: 'Change Password',
      data: {
        user: this.user,
      },
      width: '25vw',
      contentStyle: this.freyaHelper.getDialogContentStyle('0.5rem'),
    });
  }

  setDisabledActions() {

    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean((this.user as any)?.deletedAt),
    };

    const [ actionsMenu ] = this.userActions;

    const { items: userActions } = actionsMenu;

    for (const action of userActions) {
      setMenuItemDisabled(action, disabledWhen);
    }

  }

  get userName() {
    return [ this.user.givenName, this.user.familyName ].filter(Boolean).join(' ');
  }
}
