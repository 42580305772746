import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseReportFragment, ReportDetailsListQuery } from 'graphql.generated';
import { MenuItem } from 'primeng/api';
import { MutateReportComponent } from 'src/app/reports/mutate-report/mutate-report.component';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { PermissionService } from 'src/app/services/permission.service';

import { SubSink } from 'subsink';

import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-actions',
  templateUrl: './report-actions.component.html',
  styleUrls: ['./report-actions.component.scss']
})
export class ReportActionsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('mutateReport') mutateReportRef: MutateReportComponent;

  @Input() report: BaseReportFragment | ReportDetailsListQuery['reports']['reports'][number];
  @Input() disabled: ('view' | 'downloadCvs' | 'downloadCvsSum' | 'rerun')[] = [];

  reportActions: MenuItem[] = [];

  subs = new SubSink();

  reportPermissions = {
    view: false,
    create: false
  };

  constructor(
    private reportService: ReportService,
    private freyaMutate: FreyaMutateService,
    private permissionHandler: PermissionService
  ) { }

  ngOnInit(): void {
    this.setPermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateActions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setPermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(['reports.view', 'reports.create'])
      .subscribe((res) => {
        this.reportPermissions.view = res[0];
        this.reportPermissions.create = res[1];
        this.updateActions();
      });
  }

  updateActions() {
    this.reportActions = [
      {
        label: 'View',
        disabled: this.disabled.includes('view') || !this.isViewable,
        icon: 'pi pi-external-link',
        routerLink: [ '/reports', this.report?.id ],
        visible: this.reportPermissions.view
      },
      {
        label: 'Download CSV',
        disabled: this.disabled.includes('downloadCvs') || !this.isViewable,
        icon: 'pi pi-download',
        visible: this.reportPermissions.view,
        command: (ev) => {
          this.reportService.downloadCSV(this.report, undefined, { stripSummary: true });
        },
      },
      {
        label: 'Download CSV With Summary',
        disabled: this.disabled.includes('downloadCvsSum') || !this.isViewable,
        icon: 'pi pi-download',
        visible: this.reportPermissions.view,
        command: (ev) => {
          this.reportService.downloadCSV(this.report, undefined, { stripSummary: false });
        },
      },
      {
        label: 'Re-run',
        disabled: this.disabled.includes('rerun'),
        icon: 'pi pi-refresh',
        visible: this.reportPermissions.create,
        command: (ev) => {
          if (!this.reportService.isFullyLoaded(this.report)) { return; }

          let variables = {};
          if (this.report.variables) {
            variables = JSON.parse(this.report.variables);
          }

          this.freyaMutate.openMutateObject({
            mutateType: 'create',
            objectType: 'report',
            object: this.report.reportType,
            additionalValues: [
              {
                property: 'navigate',
                value: false
              },
              {
                property: 'variables',
                value: variables
              }
            ]
          });

        },
      },
      // regenerate?
    ];
  }

  get isViewable() {
    return this.report?.saveData && !this.reportService.isGenerating(this.report);
  }
}
