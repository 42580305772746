

/**
 * A period of time expressed as unix timestamps
 */
 export interface Period {
    start: number;
    end: number;
}



export const periodOptions = [
    'Today',
	'Tomorrow',
	'Yesterday',
	'Last 24 hours',
	'Next 24 hours',

	'Last Week',
	'This Week',
	'Next Week',
    'WTD',
    'Last 7 days',
	'Last 14 days',

	'Last Month',
	'This Month',
	'Next Month',
	'Month After Next',
    'MTD',
		'MTD Last Year',
    'Last 30 days',

	'Last Quarter',
	'This Quarter',
	'Next Quarter',
    'QTD',
    'Last 90 days',

	'Last Year',
	'This Year',
	'Next Year',
    'YTD',
    'Last 365 days',

	'Past',
	'Future',
    'All Time',
] as const;

export const advancedPeriodOptions = [
	...periodOptions,
	'Custom',
	'Since Last Run'
] as const;

export type PeriodOptions = typeof periodOptions[number];
export type AdvancedPeriodOptions = typeof advancedPeriodOptions[number];

export function isPeriod(str: string): str is PeriodOptions {
    return periodOptions.includes(str as any);
}

export function isAdvancedPeriod(str: string): str is AdvancedPeriodOptions {
	return advancedPeriodOptions.includes(str as any);
}

export const reportPeriods = periodOptions
    .map(([ value ]) => ({
        name: value,
        value,
    }))
;

export interface ColumnDef {
    key: string;
    disablePrettyKey?: boolean;
    title?: string;
    // set to true to hide configured aggregations on this column
    hideAggregations?: boolean;
    defaultValue?: string | number | undefined;

    // TODO: add decimal, percent, unit formats.
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat


	// If the provided data is an array then join the data with the given deliminator
	joinDeliminator?: string;

	/**
	 * Join data with the specified key. If no key is specified, then the objects will
	 * be stringified
	 */
	joinKey?: string;

	/**
	 * LINK SPECIFIC
	 */

	/**
	 * create a link when this row is clicked to the specified object
	 */
	linkPath?: string;

	/**
	 * CURRENCY SPECIFIC
	 */

	 // specify the currency
	currencyKey?: string;

	// currency to fallback to before the system default currency
	currencyDefault?: string;

	// if format is currency then disable the currency multiplication when formatting
	disableCurrencyMultiple?: boolean;

}

export interface ReportDataStructure {
    variables: any;
    render?: {
        format: 'table';
        columns: (string | ColumnDef)[];
    };
    csv?: {
        columns: (string | ColumnDef)[];
    };
    keys: string[];
    data: any[];
    csvData?: string;
    aggregations?: ReportAggregationResult[];
}


export interface ReportAggregationResult {
	id: string;
	title: string;
	type: AggregationType;

	key: string;
	secondaryType?: SecondaryAggregationType;
	secondaryKey?: string;
	options?: {
		[key: string]: string | boolean | number | undefined;
	};

	otherKeys?: string[];

	filters?: ReportAggregationFilter | ReportAggregationFilter[];

	// Only set if aggregation output defines multiple labels
	labels?: string | string[];
	values?: any | any[];
	error?: string;
}

export type AggregationType = string;
export type SecondaryAggregationType = string;

type ArrayOrType<T> = T | T[];

// Will return true if it matches ANY of these filters
export interface ReportAggregationFilter {
	// Key to filter. If not provided, will use key from aggregation
	key?: string;

	typeIs?: ArrayOrType<'string' | 'number' | 'boolean' | 'undefined'>;
	exactly?: ArrayOrType<string | number | boolean>;
	contains?: ArrayOrType<string>;
	startsWith?: ArrayOrType<string>;
	endsWith?: ArrayOrType<string>;
	// regex match
	matches?: ArrayOrType<string>;

	// TODO: temporal values here?
	gt?: number | string;
	lt?: number | string;
	gte?: number | string;
	lte?: number | string;
}

