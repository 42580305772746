<div>
    <p-multiSelect
        appendTo="body"
        placeholder="Select Transaction"
        [options]="transactionSuggestions"
        [(ngModel)]="selectedTransactions"
        (ngModelChange)="onChange(selectedTransactions)"
        [disabled]="disabled"
        (onChange)="emitSelect()"
        (focus)="touched = true"
        [autofocusFilter]="!responsiveHelper.isSmallScreen"
    >
    <ng-template pTemplate="selectedItems" let-values>
        <span *ngIf="!values?.length">Select Transactions</span>
        <span *ngIf="values?.length">{{values?.length}} Transactions Selected</span>
        <!-- <span *ngFor="let transaction of values">{{transaction.amount / 100 | currency : transaction.currency}} - {{transaction.stage}}</span> -->
    </ng-template>

    <ng-template pTemplate="item" let-transaction>
        <span>{{transaction.amount / 100 | currency : transaction.currency}} - {{transaction.stage}}</span>
    </ng-template>
</p-multiSelect>
<!-- </p-dropdown> -->
</div>