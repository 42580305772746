import { Pipe, PipeTransform } from '@angular/core';

import { CalendarEvent, User } from '../../generated/graphql.generated';

import { getEventCustomer, getEventCustomerName } from './event-location/calendarevent.util';

@Pipe({
  name: 'eventCustomer'
})
export class EventCustomerPipe implements PipeTransform {

  transform(event: CalendarEvent, asString = true): string | User {
    if (asString){
      return getEventCustomerName(event);
    }

    return getEventCustomer(event)?.user;
  }

}
