import { gql } from 'graphql-tag';

import { User } from './auth';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACCESS_TYPE {
    'owner' = 'owner',
    'manager' = 'manager',
    'editor' = 'editor',
    'commentor' = 'commentor',
    'viewer' = 'viewer',
    'any' = 'any',
}

export interface ListAccessesOutput {
	accesses: AccessPagination;
}

export interface GrantAccessOutput {
	grantAccess: Access[];
}

export interface EditAccessesOutput {
	editAccesses: boolean;
}

export interface RevokeAccessesOutput {
	revokeAccesses: boolean;
}

export interface AccessPagination {
    total: number;
    skipped?: number;
    limited?: number;
    accesses?: Access[];
}


export interface Access {
    id: string;
    createdAt: number;
    updatedAt?: number;
    revokedAt?: number;
    objectId: string;
//    The object's label on the database. e.g. "Location"
    objectLabel: string;
    user?: User;
//    The type of access being granted to the user. Values: "owner", "manager", "editor", "commenter", "viewer"
    accessType: string;
//    The user that granted the access if applicable
    grantedBy?: User;
    expiresAt?: number;
}


export interface ListAccessInput {
    filter?: AccessFilter;
    skip?: number;
    limit?: number;
    sortBy?: string;
}


export interface AccessFilter {
    ids: string[];
    objectIds: string[];
    objectLabels: string[];
    userIds: string[];
    grantedBy: string[];
    accessType?: string;
    expiresBefore?: number;
    expiresAfter?: number;
    createdBefore?: number;
    createdAfter?: number;
    updatedBefore?: number;
    updatedAfter?: number;
    showRevoked?: boolean;
}


export interface GrantAccess {
    userId: string;
    objectId: string;
    accessType: string;
    expiresAt?: number;
}


export interface EditAccess {
    accessId: string;
    accessType?: string;
    expiresAt?: number;
}


export interface AccessesInput {
	input?: ListAccessInput;
}



export interface GrantAccessInput {
	grantAccess?: GrantAccess[];
}



export interface EditAccessesInput {
	editAccesses?: EditAccess[];
}



export interface RevokeAccessesInput {
	ids?: string[];
}


export const accessesBaseGQL = `
    id
    createdAt
    updatedAt
    revokedAt
    objectId
    objectLabel
    accessType
    grantedBy {
    id
    }
    expiresAt
`;


export const accessesQuery = gql`
	query accesses($input: ListAccessInput, ){
		accesses(input: $input, ){
			    total
			    skipped
			    limited
			    accesses{
                    id
                    createdAt
                    updatedAt
                    revokedAt
                    objectId
                    objectLabel
                    user {
                        id
                        email
                        givenName
                        familyName
                    }
                    accessType
                    grantedBy {
                        id
                        email
                        givenName
                        familyName
                    }
                    expiresAt
                }
	}		}
`;


export const grantAccessMutation = gql`
	mutation grantAccess($grantAccess: [GrantAccess!]!, ){
		grantAccess(grantAccess: $grantAccess, ){
			    total
			    skipped
			    limited
			    accesses{
                    id
                    createdAt
                    updatedAt
                    revokedAt
                    objectId
                    objectLabel
                    user {
                        id
                        email
                        givenName
                        familyName
                    }
                    accessType
                    grantedBy {
                        id
                        email
                        givenName
                        familyName
                    }
                    expiresAt
                }
	}		}
`;


export const editAccessesMutation = gql`
	mutation editAccesses($editAccesses: [EditAccess!]!, ){
		editAccesses(editAccesses: $editAccesses, ) 

	}
`;


export const revokeAccessesMutation = gql`
	mutation revokeAccesses($ids: [String!]!, ){
		revokeAccesses(ids: $ids, ) 

	}
`;

export const ownerSubGQL = `
owner {
    id
    email
    givenName
    familyName
    phone
    createdAt {
        unix
    }
}
`;
