import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { PlusAuthenticationService } from '../../core/public-api';

/**
 * Require the user to not be authenticated to access this page
 */

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticatedGuard  {

  constructor(
    private auth: PlusAuthenticationService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authenticated = this.auth.isAuthenticated();
    // const isAuthRoute = route.url.find((s) => s.path === 'auth');
    if (authenticated) {
      console.log('Navigating home because already authenticated');
      this.router.navigate([ this.auth.homeURL ]);
    }

    return !authenticated;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
