import { Injectable } from '@angular/core';
import { ConfigService, dayjs } from '@karve.it/core';
import { GetConfigValuesGQL } from 'graphql.generated';
import { Observable, Subject, map, of, switchMap } from 'rxjs';
import { SubSink } from 'subsink';

import { environment } from '../../environments/environment';

import { BrandingService } from './branding.service';

const timezoneConfigKey = 'system-details.timezone';

export interface Timezone {
  name: string;
  offset: number;
}
@Injectable({
  providedIn: 'root'
})
export class TimezoneHelperService {

  // Timezones that are available
  availableTimezones = [
    {
      name: "America/Adak",
      offset: 540,
    },
    {
      name: "America/Anchorage",
      offset: 480,
    },
    {
      name: "America/Boise",
      offset: 360,
    },
    {
      name: "America/Chicago",
      offset: 300,
    },
    {
      name: "America/Denver",
      offset: 360,
    },
    {
      name: "America/Detroit",
      offset: 240,
    },
    {
      name: "America/Indiana/Indianapolis",
      offset: 240,
    },
    {
      name: "America/Indiana/Knox",
      offset: 300,
    },
    {
      name: "America/Indiana/Marengo",
      offset: 240,
    },
    {
      name: "America/Indiana/Petersburg",
      offset: 240,
    },
    {
      name: "America/Indiana/Tell_City",
      offset: 300,
    },
    {
      name: "America/Indiana/Vevay",
      offset: 240,
    },
    {
      name: "America/Indiana/Vincennes",
      offset: 240,
    },
    {
      name: "America/Indiana/Winamac",
      offset: 240,
    },
    {
      name: "America/Juneau",
      offset: 480,
    },
    {
      name: "America/Kentucky/Louisville",
      offset: 240,
    },
    {
      name: "America/Kentucky/Monticello",
      offset: 240,
    },
    {
      name: "America/Los_Angeles",
      offset: 420,
    },
    {
      name: "America/Menominee",
      offset: 300,
    },
    {
      name: "America/Metlakatla",
      offset: 480,
    },
    {
      name: "America/New_York",
      offset: 240,
    },
    {
      name: "America/Nome",
      offset: 480,
    },
    {
      name: "America/North_Dakota/Beulah",
      offset: 300,
    },
    {
      name: "America/North_Dakota/Center",
      offset: 300,
    },
    {
      name: "America/North_Dakota/New_Salem",
      offset: 300,
    },
    {
      name: "America/Phoenix",
      offset: 420,
    },
    {
      name: "America/Sitka",
      offset: 480,
    },
    {
      name: "America/Yakutat",
      offset: 480,
    },
    {
      name: "Pacific/Honolulu",
      offset: 600,
    },
    {
      name: "America/Atikokan",
      offset: 300,
    },
    {
      name: "America/Blanc-Sablon",
      offset: 240,
    },
    {
      name: "America/Cambridge_Bay",
      offset: 360,
    },
    {
      name: "America/Creston",
      offset: 420,
    },
    {
      name: "America/Dawson",
      offset: 420,
    },
    {
      name: "America/Dawson_Creek",
      offset: 420,
    },
    {
      name: "America/Edmonton",
      offset: 360,
    },
    {
      name: "America/Fort_Nelson",
      offset: 420,
    },
    {
      name: "America/Glace_Bay",
      offset: 180,
    },
    {
      name: "America/Goose_Bay",
      offset: 180,
    },
    {
      name: "America/Halifax",
      offset: 180,
    },
    {
      name: "America/Inuvik",
      offset: 360,
    },
    {
      name: "America/Iqaluit",
      offset: 240,
    },
    {
      name: "America/Moncton",
      offset: 180,
    },
    {
      name: "America/Panama",
      offset: 300,
    },
    {
      name: "America/Phoenix",
      offset: 420,
    },
    {
      name: "America/Puerto_Rico",
      offset: 240,
    },
    {
      name: "America/Rankin_Inlet",
      offset: 300,
    },
    {
      name: "America/Regina",
      offset: 360,
    },
    {
      name: "America/Resolute",
      offset: 300,
    },
    {
      name: "America/St_Johns",
      offset: 150,
    },
    {
      name: "America/Swift_Current",
      offset: 360,
    },
    {
      name: "America/Toronto",
      offset: 240,
    },
    {
      name: "America/Vancouver",
      offset: 420,
    },
    {
      name: "America/Whitehorse",
      offset: 420,
    },
    {
      name: "America/Winnipeg",
      offset: 300,
    },
  ];

  // The guessed timezone based on local settings
  guessedTimezone = dayjs.tz.guess();

  // A Date with Hours, minutes and seconds set to zero.
  defaultDate = new Date(2021, 1, 1, 0, 0, 0);

  timezoneChanged = new Subject(); // Triggered when the timezone has been changed

  currentTimezone: string; // Current timezone of the authenticated user

  subs = new SubSink();

  constructor(
    private branding: BrandingService,
    public getConfigValuesGQL: GetConfigValuesGQL,
    public configService: ConfigService,
  ) { }

  getCurrentTimezone() {
    const branding = this.branding.currentBranding.value;
    return branding.timezone || environment.defaultTimezone;
  }

  getTimeZoneWithJobAreaPriority(jobAreaId: string): Observable<string> {
    const systemTimeZone = this.getCurrentTimezone();

    if (!jobAreaId) {
      return of(systemTimeZone);
    } else {
      return this.retrieveAreaTimeZone(jobAreaId).pipe(
        map(jobTimeZoneId => jobTimeZoneId || systemTimeZone)
      );
    }
  }

  retrieveAreaTimeZone(areaId: string): Observable<string | null> {
    if (areaId) {
      return this.configService.getConfigValues(
        {
          keys: [timezoneConfigKey],
        },
        areaId
      ).pipe(
        switchMap(res => {
          const timezone = res.data?.getConfigValues?.[0]?.value || null;
          return of(timezone);
        })
      );
    } else {
      return of(null);
    }
  }

  // Check the current timezone of the authenticated zone and cache it
  async watchTimezone() {
    this.subs.sink = this.branding.currentBranding.subscribe((branding) => {
      this.currentTimezone = branding.timezone || environment.defaultTimezone;
      // This will affect global dayjs timezone. Whenever you use dayjs.tz(), it will use this timezone.
      dayjs.tz.setDefault(this.currentTimezone);
      this.timezoneChanged.next(true);
      // console.log(`Timezone set to ${this.currentTimezone}`);
    });
  }

  // Return timezones that contain the string input.
  checkTimezones(input: string) {
    return this.availableTimezones.filter((t) => t.name.toLowerCase().includes(input.toLowerCase())).map((t) => t.name);
  }

  convertDateToTime(date: Date, option: 'seconds'){
    if (option === 'seconds') {
      return (date.getHours() * 3600) + (date.getMinutes() * 60);
    }
  }

  convertDateToString(date: Date){
   return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`;
  }

  dayjs(date: dayjs.ConfigType) {
    return dayjs().tz(this.currentTimezone);
  }

  formatUnix(value: string){
    return value ? dayjs(value, 'MM/DD/YYYY').unix() : undefined;
  }

  // Take a Unix Number and format it as a string
  formatString(seconds: number){
    return seconds ? dayjs(seconds * 1000).format('MM/DD/YYYY') : undefined;
  }

  // convert unix to Date type.
  unixToDate(seconds: number){
    if(!seconds){
      return;
    }
    return new Date (seconds * 1000);
  }

  /**
   * Takes a date as either unix seconds or Date object and a timezone and returns a new dayJs timezone object.
   * If no time zone is provided, it defaults to the current zone based on the config values for the zone.
   */
  getDayJsTimeZoneObject(date: number | Date, timezone?: string ) {

    if (!timezone) {
      timezone = this.getCurrentTimezone();
    }

    if (typeof date === 'number') {
      // convert unix seconds into miliseconds and create date
      return dayjs(+date * 1000).tz(timezone);
    } else {
      return dayjs(date).tz(timezone);
    }
  }

}
