//export const AVAILABILITY_EVENT_TYPES = ['Normal', 'Override', 'Unavailable'];

export enum AvailabilityEventTypes {
    normal = 'Normal',
    override = 'Override',
    unavailable = 'Unavailable'
}

export const DEFAULT_UNAVAILABLE_EVENT = {
    title: 'Unavailable',
    display: 'inverse-background',
    color: '#666666',
    extendedProps: {
        type: AvailabilityEventTypes.unavailable
    }
};
