import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of, Subject } from 'rxjs';

import { EstimatesComponent } from './estimates/estimates.component';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class LeaveEstimatingGuard  {

  public constructor(
    private confirmService: ConfirmationService,
  ) {}

  canDeactivate(
    component: EstimatesComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hasUnsavedChanges = component.hasUnsavedChanges();

    if (!hasUnsavedChanges || component.jobClosed || component.jobSaving) {
      return of(true);
    }

    const sub = new Subject<boolean>();

    this.confirmService.confirm({
      message: 'You have unsaved changes. Are you sure you want to continue?',
      // accept: 'Save & Continue',
      acceptLabel: 'Save & Continue',
      rejectLabel: 'Discard Changes',
      header: 'Save changes?',
      icon: 'pi pi-exclamation-triangle',
      closeOnEscape: true,
      dismissableMask: true,
      rejectIcon: 'pi pi-trash',
      acceptIcon: 'pi pi-save',
      // rejectIcon: ''
      accept: async () => {
        await component.saveJob(true);
        sub.next(true);
      },
      reject: (eventCode) => {
        if (eventCode === 1){
          sub.next(true);
        } else {
          sub.next(false);
        }
      },
    });

    return sub.asObservable();
      // setTimeout(() => {
      //   component.saveJob();
      // }, 100);

      // return component.jobSaveCompleted;
      // return component.canDeactivate() ?
      // true :
      // // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // // when navigating away from your angular app, the browser will show a generic warning message
      // // see http://stackoverflow.com/a/42207299/7307355
  }
}
