<div class="container">
    <!-- <p>
        Are you sure you want to remove the template <b>{{removeInput.template.name}}</b> from <b>{{removeInput.asset.name}}?</b>
    </p> -->
    <span>Are you sure you want to remove the template </span>
    <b class="big-text">{{removeInput.template.name}}</b>
    <span> from </span>
    <b class="big-text">{{removeInput.asset.name}}?</b>
    <br>
    <p *ngIf="removeInput.type === 'Override'">
        Removing this override will restore the normal availability of this asset on: {{removeInput.date}}.
    </p>
</div>
<div class="footer">
    <button
        pButton
        autofocus
        label="Cancel"
        icon="pi pi-times"
        [disabled]="submitted"
        (click)="ref.close()"
    ></button>
    <button
        pButton
        label="Remove Template"
        [disabled]="submitted"
        class="p-button-danger"
        icon="pi pi-trash"
        (click)="removeTemplate()"
    ></button>
    <i
        *ngIf="submitted"
        class="pi pi-spin pi-spinner"
        style="font-size: 2rem"
    ></i>
</div>