import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseRuleFragment, BaseZoneFragment, CreateRuleInput, CreateRulesGQL, DeleteRulesGQL, ListZonesWithChildrenGQL, RuleAction, RuleOptionsGQL, RuleTrigger, UpdateRuleInput, UpdateRulesGQL } from '../../../generated/graphql.generated';

import { advancedConditions, conditionalInfo, ConditionArray, conditionArrayValidator, describeConditionObject, determineValueTypeOption } from '../../franchise/rules/rules.constants';
import { BrandingService } from '../../services/branding.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-rule',
  templateUrl: './mutate-rule.component.html',
  styleUrls: ['./mutate-rule.component.scss']
})
export class MutateRuleComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;
  @ViewChild('delete') deleteRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('given') given: TemplateRef<any>;
  @ViewChild('when') when: TemplateRef<any>;
  @ViewChild('then') then: TemplateRef<any>;
  @ViewChild('attributes') attributes: TemplateRef<any>;
  @ViewChild('metadata') metadataTemplate: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create' | 'duplicate' = 'create';
  @Input() rule: BaseRuleFragment;

  trigger: RuleTrigger;
  action: RuleAction;

  triggers: RuleTrigger[];
  actions: RuleAction[];

  steps: MutateObjectElement[];

  showOffset = false;

  subs = new SubSink();

  ruleForm = new FormGroup({
    trigger: new FormControl(undefined, [Validators.required]),
    conditions: new FormControl(
      [{ property: '', condition: '', type: 'string' }] as ConditionArray,
      [conditionArrayValidator],
    ),
    action: new FormControl('', [Validators.required]),
    attributes: new FormControl([], []),
    metadata: new FormControl({}),
    delay: new FormControl(0, [Validators.min(0)]),
    delayUnit: new FormControl('hours', []),
    offset: new FormControl(0, []),
    offsetUnit: new FormControl('hours', []),
  });

  delayUnitOptions = [
    'seconds',
    'minutes',
    'hours',
    'days',
    'weeks',
    'months',
    'years',
  ];

  ruleFormValues = cloneDeep(this.ruleForm.value);

  // Zone Variables
  zones: BaseZoneFragment[] = [];
  zoneQueryRef: ReturnType<ListZonesWithChildrenGQL['watch']>;

  showDialog = false;

  properties: {
    label: string;
    key: string;
    description: string;
    value: string;
  }[] = [];

  constructor(
    private localNotify: FreyaNotificationsService,
    private brandingSvc: BrandingService,
    private detailsHelper: DetailsHelperService,
    private listZonesWithChildrenGQL: ListZonesWithChildrenGQL,
    private ruleOptionsGQL: RuleOptionsGQL,
    private createRulesGQL: CreateRulesGQL,
    private updateRulesGQL: UpdateRulesGQL,
    private deleteRulesGQL: DeleteRulesGQL,
  ) { }

  ngOnInit(): void {
    this.retrieveZoneInfo();
    this.getRuleOptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create' || this.mutateType === 'duplicate') {
      this.createRule();
    } else if (this.mutateType === 'update') {
      this.updateRule();
    }
  }

  retrieveZoneInfo() {
    this.zoneQueryRef = this.listZonesWithChildrenGQL.watch({
      filter: {
        ids: [this.brandingSvc.currentZone().value.id,
        ]
      }
    });

    this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        if (res.data.zones.nodes.length < 1) { return; }
        this.zones = [
          ...res.data.zones.nodes,
          ...res.data.zones.nodes[0].children.nodes
        ];
      }
    });
  }

  getRuleOptions() {
    this.subs.sink = this.ruleOptionsGQL.fetch().subscribe((res) => {
      this.triggers = res.data.ruleOptions.triggers;
      this.actions = res.data.ruleOptions.actions;

      this.triggerChanged();
      this.actionChanged();
    });
  }

  formatConditionValue(conditions?: ConditionArray) {
    if (!conditions) {
      conditions = this.ruleForm.get('conditions').value;
    }

    if (!conditions) { return {}; }

    // isValid?

    const obj = {};
    for (const condition of conditions) {
      const isBlock = advancedConditions.includes(condition.condition);
      if (isBlock) {
        obj[condition.condition] = this.formatConditionValue(condition.value);
      } else {
        obj[condition.property + condition.condition] = condition.value;
      }

    }

    return obj;
  }

  conditionObjToArray(obj: any) {
    const arr: ConditionArray = [];
    const conditions = Object.keys(conditionalInfo);

    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      const value = obj[key];
      if (advancedConditions.includes(key)) {
        arr.push({
          condition: key,
          value: this.conditionObjToArray(value),
        });
      } else {
        const typeOption = determineValueTypeOption(value);
        if (!typeOption) { continue; }

        const condition = conditions.find((f) => key.slice(-f.length) === f);
        if (!condition) { continue; }
        const property = key.slice(0, -condition.length);

        arr.push({
          condition,
          property,
          type: typeOption.value,
          value,
        });
      }
    }

    return arr;

  }

  openDialog() {

    const baseSteps: MutateObjectElement[] = [
      {
        name: 'Given', ref: this.given, control: 'trigger', type: 'func', reviewFunc: (val) => {
          if (!val || val === '') { return ''; }
          if (!this.triggers) { return val; }
          const trigger = this.triggers.find((t) => t.id === val);
          if (!trigger) { return val; }

          // TODO: add describe func here somehow?
          return trigger.name;
        }
      },
      {
        name: 'When', ref: this.when, control: 'conditions', type: 'func', reviewFunc: (val) => {

          const obj = this.formatConditionValue();

          return describeConditionObject(obj);
        }
      },
      {
        name: 'Then', ref: this.then, control: 'action', type: 'func', reviewFunc: (val) => {
          if (!val || val === '') { return ''; }
          if (!this.actions) { return val; }
          const action = this.actions.find((t) => t.id === val);
          if (!action) { return val; }

          // TODO: add describe func here somehow?
          return action.name;
        }
      },
      {
        name: 'Attributes',
        ref: this.attributes, control: 'attributes', type: 'array',
        reviewOnly: true,
      },
      {
        name: 'Metadata',
        ref: this.metadataTemplate,
        // control: 'metadata',
        control: 'metadata',
        type: 'func',
        reviewOnly: true,
        reviewFunc: (val) => Object.keys(val).map((v) => `${v}: ${val[v]}`).join(', ')
      },
    ];

    let duplicate = false;
    this.ruleForm.reset(cloneDeep(this.ruleFormValues));
    this.properties = [];
    this.action = undefined;
    this.trigger = undefined;

    if (this.mutateType === 'create') {
      this.steps = baseSteps;
    } else if (this.mutateType === 'duplicate') {
      this.steps = baseSteps;
      this.setFormValues();
      this.rule = undefined;
      this.mutateType = 'create';
      duplicate = true;
    } else {
      this.steps = baseSteps;
      this.setFormValues();
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
    if (duplicate) {
      this.mutateRef.viewReview();
    }
  }

  openDelete() {
    this.deleteRef.openDialog();
  }

  setFormValues() {
    const conditionObj = typeof this.rule.condition === 'string' ? JSON.parse(this.rule.condition) : this.rule.condition;
    const conditions = this.conditionObjToArray(conditionObj);

    this.ruleForm.reset({
      ...this.rule,
      trigger: this.rule?.trigger?.id,
      action: this.rule?.action?.id,
      conditions,
      metadata: this.rule.metadata || {},
      delay: this.rule.delay || 0,
      delayUnit: this.rule.delayUnit || 'hours',
      offset: this.rule.offset,
      offsetUnit: this.rule.offsetUnit,
    });

    this.triggerChanged();
    this.actionChanged(false);
  }

  openRuleDetails(rule: BaseRuleFragment) {
    this.detailsHelper.detailsItem.next({ type: 'rules', item: rule });
  }

  triggerChanged() {
    const id = this.ruleForm.get('trigger').value;
    this.trigger = this.triggers.find((t) => t.id === id);

    this.showOffset = Boolean(
      this.trigger?.properties?.find((p) => p.name === 'data.offset')
    );
  }

  actionChanged(loadProperties = false) {
    const id = this.ruleForm.get('action').value;
    this.action = this.actions.find((t) => t.id === id);
    this.loadProperties();
  }

  loadProperties() {
    if (!this.action?.properties) { return; }

    this.properties = Object.entries(this.action.properties)
      .map(([key, propertySchema]) => {
        return {
          key,
          ...propertySchema as any,
        };
      });

  }

  createRule() {

    const val = this.ruleForm.value;
    const condition = JSON.stringify(this.formatConditionValue());

    const createRuleInput: CreateRuleInput = {
      properties: {
        trigger: val.trigger,
        condition,
        action: val.action,
        attributes: val.attributes,
        metadata: val.metadata,
        delay: val.delay || 0,
        delayUnit: val.delayUnit || 'seconds',
        offset: val.offset,
        offsetUnit: val.offsetUnit || 'seconds',
      },
    };

    this.subs.sink = this.createRulesGQL.mutate({
      rules: [createRuleInput]
    }).subscribe({
      next: (res) => {
        this.mutateRef.closeDialog();
        this.localNotify.addToast.next({ severity: 'success', summary: 'Created rule' });
        this.detailsHelper.pushUpdate({
          id: res.data.createRules[0].id,
          type: 'Rules',
          action: 'create',
        });
        const [rule] = res.data?.createRules;
        if (rule) {
          this.openRuleDetails(rule);
        }
      },
      error: (err) => {
        this.mutateRef.loading = false;
        this.localNotify.apolloError(`Failed to create rule`, err.message);
      }
    });
  }

  updateRule() {
    const val = this.ruleForm.value;
    const condition = JSON.stringify(this.formatConditionValue());

    const updateRuleInput: UpdateRuleInput = {
      id: this.rule.id,
      properties: {
        trigger: val.trigger,
        condition,
        action: val.action,
        attributes: val.attributes,
        metadata: val.metadata,
        delay: val.delay || 0,
        delayUnit: val.delayUnit || 'seconds',
        offset: val.offset,
        offsetUnit: val.offsetUnit || 'seconds',
      },
    };

    this.subs.sink = this.updateRulesGQL.mutate({
      rules: [updateRuleInput]
    }).subscribe({
      next: (res) => {
        this.mutateRef.closeDialog();
        this.localNotify.addToast.next({ severity: 'success', summary: 'Updated rule' });
        this.detailsHelper.pushUpdate({
          id: this.rule.id,
          type: 'Rules',
          action: 'update',
        });
        const [rule] = res.data?.updateRules;
        if (rule) {
          this.openRuleDetails(rule);
        }
      },
      error: (err) => {
        this.mutateRef.loading = false;
        this.localNotify.apolloError(`Failed to update rule`, err.message);
      }
    });
  }

  deleteRule() {


    this.subs.sink = this.deleteRulesGQL.mutate({
      ruleIds: [this.rule.id],
    }).subscribe({
      next: (res) => {
        this.localNotify.addToast.next({ severity: 'success', summary: 'Deleted rule' });
        this.detailsHelper.pushUpdate({
          id: this.rule.id,
          type: 'Rules',
          action: 'delete'
        });
        if (this.detailsHelper.detailsItem.value?.item?.id === this.rule.id) {
          this.detailsHelper.detailsItem.next(null);
        }

      },
      error: (err) => {
        this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to delete rule' });
      }
    });

  }

  onConditionBlockUpdated() {
    this.ruleForm.setValue(this.ruleForm.getRawValue());
  }

  addProperty(newProperty: string) {
    if (!newProperty) { return; }

    this.updateMetadataKey(newProperty, '');
  }

  updateMetadataKey(key: string, value: string) {
    this.ruleForm.get('metadata').setValue({
      ...this.ruleForm.value.metadata,
      [key]: value,
    });
  }

}
