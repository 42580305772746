import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ArtifactService } from '@karve.it/core';
import { Artifact } from '@karve.it/interfaces/artifacts';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-download-job-invoice',
  templateUrl: './download-job-invoice.component.html',
  styleUrls: ['./download-job-invoice.component.scss']
})
export class DownloadJobInvoiceComponent implements OnInit,OnChanges, OnDestroy {

  @Input() jobId: string;
  @Input() showHtml = false;

  @Output() invoiceFound = new EventEmitter();

  invoice: Artifact;

  subs = new SubSink();

  constructor(
    private artifactService: ArtifactService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.getInvoiceForJob(this.jobId);
  }

  getInvoiceForJob(id: string){
    if(!id) { return; }

    this.subs.sink = this.artifactService.listArtifacts({
      filter: {
        relatedObjectIds: [id],
        relatedObjectLabels: [ 'Job' ],
      }, getPrivate: true}).subscribe((res) => {
      if (!res.data?.artifacts?.artifacts?.length) { return; }
      this.invoice = res.data.artifacts.artifacts[0];
      this.invoiceFound.emit(true);
    });
  }

  downloadInvoice() {
    fetch(this.invoice.signedUrl || this.invoice.url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.invoice.name;
      link.click();
    });
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

}
