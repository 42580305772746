import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule
  ],
  selector: 'app-dispatch-chip',
  templateUrl: './dispatch-chip.component.html',
  styleUrl: './dispatch-chip.component.scss'
})
export class DispatchChipComponent {
  @Input() panelType: 'left' | 'right' | 'dialog' = 'right';
  @Input() imageUrl: string = '';
  @Input() name: string = '';
  @Input() icon: string = null;
  @Input() eventsCount: number = null;
  @Input() revenue: number = null;
  @Input() showStar: boolean = false;
  @Input() removable: boolean = false;
  @Input() hasConflict: boolean = false;
  @Output() remove = new EventEmitter<void>();

  public onRemove() {
    this.remove.emit();
  }
}
