<div class="card header" (paste)="appRules.paste($event)">
  <div class="description">
    <h4 data-cy="rules-card-header">System Rules</h4>
    <p>
      Rules allow you to define automations when things happen in move right,
      such as sending an email when a new lead is created.
    </p>

    <h5>Rules follow a simple pattern</h5>

    <p>
      <strong>Given (Trigger): </strong>
      What must first happen for this rule to be executed?
    </p>
    <ul>
      <li>A job is created</li>
      <li>An events status is updated</li>
      <li>A job is marked closed</li>
    </ul>

    <p>
      <strong>When (Condition): </strong>
      What must be true for this rule to be executed?
    </p>
    <ul>
      <li>The job's origin is Online Lead</li>
      <li>The closed reason was "Went With Competitor"</li>
      <li>The status was completed AND the event type was "moving"</li>
    </ul>

    <p>
      <strong>Then (Action): </strong>
      What happens when this rule is executed?
    </p>
    <ul>
      <li>Send an email</li>
      <li>Send a webhook</li>
    </ul>
    <p>
      Pro Tip: you can copy rules from other zones, click this text, then press
      CTRL + V to create those rules.
    </p>
  </div>
  <div class="buttons">
    <span
      [pTooltip]="
        freyaHelper.inRootOrCorporateZone
          ? 'You cannot add rules to your current zone'
          : undefined
      "
    >
      <button
        type="button"
        pButton
        *hasPermission="['rules.create']"
        icon="pi pi-plus"
        label="Add"
        (click)="appRules.openCreate()"
        [disabled]="freyaHelper.inRootOrCorporateZone"
        data-cy="add-rules-button"
      ></button>
    </span>
  </div>
</div>

<div class="card rules" (paste)="appRules.paste($event)">
  <app-rules #appRules></app-rules>
</div>
