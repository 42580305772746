import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BaseTokenFragment } from '../../../../generated/graphql.generated';

export const LeadSourceActions = createActionGroup({
	source: 'Lead Source',
	events: {
		'Created': props<{
			token: BaseTokenFragment;
		}>(),
		'Load': emptyProps(),
		'Loading': emptyProps(),
		'Load Error': props<{ error: Error }>(),
		'Loaded': props<{
			leadSources: BaseTokenFragment[];
		}>(),
	},
});
