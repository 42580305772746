/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TagsService } from '@karve.it/features';
import { SetTagsRelationship, TAG_TYPES } from '@karve.it/interfaces/tag';
import {QueryRef} from 'apollo-angular';

import { BaseTagFragment, TagsGQL, TagsQuery, TagsQueryVariables } from 'graphql.generated';
import { cloneDeep, isEqual } from 'lodash';
import { Chips } from 'primeng/chips';
import { Inplace } from 'primeng/inplace';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

@Component({
  selector: 'app-display-tags',
  templateUrl: './display-tags.component.html',
  styleUrls: ['./display-tags.component.scss']
})
export class DisplayTagsComponent implements OnInit, OnDestroy {

  @ViewChild('tagsAutocomplete') autocompleteOverlay: OverlayPanel;
  @ViewChild('tagInplace') tagInplace: Inplace;
  @ViewChild('beneath') beneathElement: ElementRef;
  @ViewChild(Chips) chipsRef: Chips;

  @HostListener('click')
  clicked() {
    this.clickedInside = true;
  }
  @HostListener('window:click')
  clickedOut() {
    if (!this.clickedInside && this.tagInplace.active) {
      this.autocompleteOverlay.hide();
      this.tagInplace.deactivate();
      this.setObjectTags();
    }
    this.clickedInside = false;
  }
  clickedInside = true;

  // QUERY FILTERS
  @Input() objectId: string;
  @Input() objectType: TAG_TYPES;
  // If true then we will retrieve the objects tags based on the id
  @Input() retrieveTags: boolean;
  // If true it will filter the results by tags that are already present on the object
  @Input() filterOutTagsOnObject = true;

  // INPUT DATA
  // If you already have the tags you can pass them here
  @Input() tagsOnObject: BaseTagFragment[] = [];

  @Input() readonly: boolean;

  // STATE HOLDERS
  // The value of the tags before attempting to add or delete
  tagsBeforeAction: BaseTagFragment[];
  // The last saved version of the tags to be saved/the tags we are given on instantiation
  savedTagValues: BaseTagFragment[];

  // SUBS
  subs = new SubSink();

  // OBJECT TAGS
  objectTagRef: QueryRef<TagsQuery, TagsQueryVariables>;
  objectTagQH: WatchQueryHelper = {
    loading: false,
  };

  // TAG SEARCHING
  tagSearchRef: QueryRef<TagsQuery, TagsQueryVariables>;
  tagSearchQH: WatchQueryHelper = {
    limit: 20,
    loading: false,
  };
  tagSuggestions: BaseTagFragment[];
  totalTags: number;
  searchSubject = new Subject<string>();

  constructor(
    private tagService: TagsService,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private tagsGQL: TagsGQL,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.searchSubject.pipe(debounceTime(350)).subscribe((search) => {
      this.searchForTags(search);
    });

    if (this.retrieveTags) {
      this.fetchObjectTags();
    } else {
      this.resetHoldingValues();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // UPDATE VALUES

  updateTags(tags: BaseTagFragment[]){
    if(!tags) { return; }

    this.tagsOnObject = tags;
    this.resetHoldingValues();
  }

  resetHoldingValues(){
    this.savedTagValues = cloneDeep(this.tagsOnObject);
    this.tagsBeforeAction = cloneDeep(this.tagsOnObject);
  }

  // QUERIES

  fetchObjectTags() {
    if (this.objectTagRef) {
      this.objectTagRef.refetch({ filter: { objectIds: [ ] }});
      return;
    }

    this.initObjectTagsQuery();
  }

  private initObjectTagsQuery() {
    this.objectTagRef = this.tagsGQL.watch(
      {
        filter: {
          objectIds: [this.objectId],
          objectTypes: [this.objectType]
        }
      },
      {
        fetchPolicy: 'cache-and-network'
      }
    );

    this.subs.sink = this.objectTagRef.valueChanges.subscribe((res) => {
      this.tagsOnObject = res.data.tags.tags;
      if (!this.savedTagValues) {
        this.savedTagValues = cloneDeep(this.tagsOnObject);
      }
    });
  }

  searchUpdated(input) {
    this.tagSearchQH.limit = 20;
    this.tagSearchQH.loading = true;
    this.searchSubject.next(input.target.value);
  }

  searchOnOpen(){
    if (!this.tagSearchRef){
      this.searchForTags('');
    }
  }

  searchForTags(search: string) {
    this.tagSearchQH.search = search;

    if (this.tagSearchRef) {
      this.tagSearchRef.refetch(this.getTagSearchInput());
      return;
    }

    this.initSearchTagsQuery();
  }

  private getTagSearchInput(): TagsQueryVariables {
    return {
      filter: {
        limit: this.tagSearchQH.limit,
        search: this.tagSearchQH.search,
        objectTypes: [this.objectType],
      },
      order: 'order:asc'
    };
  }

  private initSearchTagsQuery() {
    this.tagSearchRef = this.tagsGQL.watch(this.getTagSearchInput(), { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.tagSearchRef.valueChanges.subscribe((res) => {
      this.tagSearchQH.loading = res.loading;
      if (this.tagSearchQH.loading) { return; }
      this.setSuggestedTags(res.data.tags.tags);
      this.setTotalTags(res.data.tags.total);
    });
  }

  // MUTATIONS

  setObjectTags() {
    // If we have not made any changes then do not try to save
    if (isEqual(this.tagsOnObject, this.savedTagValues)) {
      return;
    }

    const willUpdateTaxExemptStatus = this.tagsOnObject
      .concat(this.savedTagValues)
      .some((t) => t.attributes?.includes('tax-exempt'));

    const tagRelationships = this.tagsOnObject.map((t) => ({ tagId: t.id, private: false } as SetTagsRelationship));

    this.subs.sink = this.tagService.setObjectTags(tagRelationships, this.objectId, this.objectType).subscribe((res) => {
      this.localNotify.success(`Tags updated`);

      this.detailsHelper.pushUpdate({
        id:this.objectId,
        type:'Tags',
        action:'added',
      });

      if (willUpdateTaxExemptStatus) {
        this.detailsHelper.pushUpdate({
          id: this.objectId,
          type: 'Jobs',
          action: 'update',
        });
      }

      this.resetHoldingValues();
    }, (err) => {
      console.error(err);
      this.localNotify.error(`Failed to update tags`);
    });
  }

  // FUNCTIONS

  setSuggestedTags(tags: BaseTagFragment[]){
    this.tagSuggestions = tags.filter(
      (t) => !this.filterOutTagsOnObject || !this.tagsOnObject.find((ot) => ot.id === t.id)
    );
  }

  setTotalTags(total: number) {
    this.totalTags = this.filterOutTagsOnObject ? total - (this.tagsOnObject.length || 0) : total;
  }

  addTagToObject(tag: BaseTagFragment) {
    const removeIndex = this.tagsOnObject.findIndex((t) => t.category && t.category === tag.category);

    if (removeIndex >= 0){
      const [removedTag] = this.tagsOnObject.splice(removeIndex, 1, tag);
      this.tagSuggestions.push(removedTag);
    } else {
      this.tagsOnObject.push(tag);
    }

    this.tagsOnObject = [...this.tagsOnObject];
    this.tagsBeforeAction = cloneDeep(this.tagsOnObject);
    this.setSuggestedTags(this.tagSuggestions);
  }

  removeTagFromObject(event, tag: BaseTagFragment) {
    // Don't update the values if the tag was removed using backspace
    if (event?.key === 'Backspace') { return; }

    this.tagsOnObject = this.tagsOnObject.filter((t) => t.id !== tag.id);
    this.tagsBeforeAction = cloneDeep(this.tagsOnObject);
  }

  openAutocomplete(event, element) {
    this.autocompleteOverlay.show(event, element);
  }

  handleClick(event, element) {
    if (this.readonly) { return; }
    this.searchOnOpen();
    this.openAutocomplete(event, element);
  }

  handleEnterEvent(event) {
    this.tagsOnObject = this.tagsOnObject.filter((t) => t?.id);
  }

  handleBackspaceEvent(event) {
    this.tagsOnObject = cloneDeep(this.tagsBeforeAction);
  }

  checkLog(event){
    console.log(event);
  }

  loadMore() {
    this.tagSearchQH.limit += 20;
    this.searchForTags(this.chipsRef.inputViewChild.nativeElement.value);
  }
}
