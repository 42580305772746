<div
    class="card"
    [class.hide]="!showHeaderCard"
>
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="discounts-card-header">Discounts</h4>
        </div>
        <div class="p-col p-d-flex p-ai-end">
            <div style="white-space: nowrap;">
                <span class="p-input-icon-left" style="display: inline-block;">
                    <i
                        *ngIf="!discountsQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="discountsQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #search
                        pInputText
                        [(ngModel)]="filter.search"
                        placeholder="Search"
                        data-cy="search-text-field"
                    >
                </span>
                    <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="filter.search = ''; searchForDiscounts()"
                ></button>
            </div>
        </div>
        <div class="p-col p-d-flex p-ai-end p-jc-between">
            <div>
                <label for="single-use">Show single use discounts</label>
                <br>
                <p-inputSwitch
                    [(ngModel)]="showSingleUseDiscounts"
                    inputId="single-use"
                    (onChange)="handleCheckboxChange()"
                    class="p-mr-2"
                ></p-inputSwitch>
            </div>
            <div>
                <button 
                    pButton
                    label="Create"
                    *hasPermission="['discounts.create']"
                    icon="pi pi-plus"
                    (click)="openMutateDialog()"
                    data-cy="create-button"
                    class="p-mr-2"
                ></button>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <p-table
        [value]="discounts"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreDiscounts($event)"
        [lazy]="true"
        [totalRecords]="discountsQH.total"
        [(rows)]="discountsQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="discountsQH.loading"
        [showCurrentPageReport]="!discountsQH.loading"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Active</th>
                <th>Status</th>
                <th>Name</th>
                <th>Code</th>
                <th>Amount</th>
                <th>Custom Range</th>
                <th>Starts</th>
                <th>Ends</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-discount
        >
            <tr
                class="selectable-row"
                (click)="selectDiscount(discount)"
            >
                <td class="show-on-stack">
                    <span class="p-column-title">Name:</span>
                    <span class="bold-on-mobile">{{discount.name}}</span>
                </td>
                <td>
                    <span class="p-column-title">Active:</span>
                    <span class="indicator" [class.active-indicator]="discount.active"></span>
                </td>
                <td>
                    <span class="p-column-title">Status:</span>
                    {{discount | discountStatus}}
                </td>
                <td class="hide-on-stack">
                    {{discount.name}}
                </td>
                <td>
                    <span class="p-column-title">Code:</span>
                    {{discount.code}}
                </td>
                <td>
                    <span class="p-column-title">Amount:</span>
                    {{ discount | discountAmount : currency }}
                </td>
                <td>
                    <span class="p-column-title">Custom Range:</span>
                    {{ discount?.customAmountRange | discountCustomAmountRange : discount?.discountType : currency }}
                </td>
                <td>
                    <span class="p-column-title">Starts:</span>
                    {{discount.startsAt | freyaDate }}
                </td>
                <td>
                    <span class="p-column-title">Ends:</span>
                    {{discount.expiresAt ? (discount.expiresAt | freyaDate) : 'Never'}}
                </td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'8'">
                    {{pagination.emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-mutate-discount #mutate mutateType="create"></app-mutate-discount>
