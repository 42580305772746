import { Pipe, PipeTransform } from '@angular/core';

import { MutateObjectElement } from './mutate-object/mutate-object.component';

@Pipe({
  name: 'removeDisabledSteps'
})
export class RemoveDisabledStepsPipe implements PipeTransform {

  transform(steps: MutateObjectElement[]): MutateObjectElement[] {
    if(!steps) {return [];}
    return steps.filter((s) => s.ref && !s.disabled && !s.reviewOnly);
  }

}
