import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { SubSink } from 'subsink';

import { ListScheduledReportsGQL, ListScheduledReportsQueryVariables, ScheduledReportEssentialsFragment } from '../../../generated/graphql.generated';
import { ReportService } from '../../features/public-api';
import { pagination } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

@Component({
  selector: 'app-scheduled-reports-list',
  templateUrl: './scheduled-reports-list.component.html',
  styleUrls: ['./scheduled-reports-list.component.scss']
})
export class ScheduledReportsListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() scheduledReports: ScheduledReportEssentialsFragment[];

  @Input() load = true;
  @Input() reportTypeId: string;
  @Input() dynamicReportId: string;
  @Input() dateFormat = 'YYYY/MM/dd hh:mm a';

  loading = true;

  pagination = pagination;

  watchQuery: ReturnType<typeof this.listScheduledReportsGQL.watch>;

  subs = new SubSink();

  constructor(
    private detailsHelper: DetailsHelperService,
    private report: ReportService,
    private toast: FreyaNotificationsService,
    private listScheduledReportsGQL: ListScheduledReportsGQL,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.detailsHelper
      .getObjectUpdates('ScheduledReport').subscribe((update) => {
        console.log(update);
        if (update.action === 'delete') {
          // this.watchQuery.updateQuery((p) => {
          //   p.scheduledReports.scheduledReports = p.scheduledReports.scheduledReports
          //     .filter((f) => f.id !== update.id);

          //   return p;
          // });
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.watchQuery;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    // if (this.load && this.reportTypeId && changes.reportTypeId) {

    // }
  }


  onLazyLoad(ev: LazyLoadEvent) {
    if (!this.reportTypeId && !this.dynamicReportId) {
      return;
    }

    const variables: ListScheduledReportsQueryVariables = {
      filter: {
        reportTypeIds: [],
        dynamicReportIds: [],
      },
      skip: ev.first,
      limit: ev.rows,
    };

    if (this.reportTypeId) {
      variables.filter.reportTypeIds.push(this.reportTypeId);
    } else if (this.dynamicReportId) {
      variables.filter.dynamicReportIds.push(this.dynamicReportId);
    }

    if (this.watchQuery) {
      return this.watchQuery.setVariables(variables);
    }

    this.watchQuery = this.listScheduledReportsGQL.watch(variables, {
      fetchPolicy: 'cache-first',
    });

    this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      this.scheduledReports = res.data?.scheduledReports?.scheduledReports;
    });

    return this.watchQuery.result();
  }

  select(sr: ScheduledReportEssentialsFragment) {
    this.detailsHelper.detailsItem.next({ type: 'scheduled-report', item: sr });
  }

}
