import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';

import { baseEnvironment } from '../../../environments/environment.base';
import { DistanceUnit } from '../../global.constants';
import { AnyParams } from '../../shared/query-params.service';

@Component({
  selector: 'app-dashboard-number',
  templateUrl: './dashboard-number.component.html',
  styleUrl: './dashboard-number.component.scss'
})
export class DashboardNumberComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.large') get isLarge() { return this.large; }

  @Input() amount: number;
  @Input() currency?: string;
  @Input() units?: DistanceUnit = baseEnvironment.defaultUnits;
  @Input() mode: 'currency' | 'percent' | 'distance' | 'duration' = 'currency';

  @Input() abbreviationThreshold = 125;

  @Input() large = false;
  @Input() loading = false;
  @Input() skeletonWidth = '100%';

  @Input() params?: AnyParams;

  resizeObserver!: ResizeObserver;

  abbreviate = false;

  widthChanged$ = new Subject<number>();

  subs = new SubSink();

  constructor(
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {

    if (this.mode !== 'currency') { return; }

    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        this.widthChanged$.next(width);
      }
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.subs.sink = this.widthChanged$.subscribe((width) => {
      this.abbreviate = width < this.abbreviationThreshold;
    });
  }

  ngOnDestroy(): void {
    if (this.mode !== 'currency') { return; }
    this.resizeObserver.disconnect();
    this.subs.unsubscribe();
  }
}
