<p-treeSelect #treeSelect
    placeholder="Loading ..."
    [options]="zoneTree"
    [ngModel]="selectedZone"

    (onNodeSelect)="onZoneSelected($event)"

    [disabled]="!(currentRoute | canChangeZone) || (documentHelper.processingDocuments | async) === true"
    [tooltipDisabled]="currentRoute | canChangeZone"
    pTooltip="Estimating page is controlling franchise"

    emptyMessage="No Zones Available"
    inputId="available-zones"
    scrollHeight="75vh"
    [filter]="showFilter"
    filterBy="search"
    [resetFilterOnHide]="true"
    filterPlaceholder="Search AvailableZones"
    data-cy="zone-select-tree"
>
    <ng-template pTemplate="value" let-value>
        <span *ngIf="value?.data?.zone">
            {{ value.data.zone.name }}
            <br />
            <small>{{ value.data.role.name }}</small>
        </span>
        <span *ngIf="!value?.data?.zone">
            <p-skeleton width="9ch" height="1em" [style]="{ margin: '3px' }"></p-skeleton>
            <p-skeleton width="14ch" height="0.8em" [style]="{ margin: '3px' }"></p-skeleton>
        </span>
    </ng-template>
</p-treeSelect>

<div class="loading-cover"
    [class.visible]="graphqlModule.newZoneLoading"
    [class.hidden]="!graphqlModule.newZoneLoading"
>
    <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
</div>
