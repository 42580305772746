import { on } from '@ngrx/store';
import { JobToolState } from '../../job-tool.reducer';
import { LoadingState } from 'src/app/utilities/state.util';
import { JobTimelineActions } from './jobv2-timeline-availability-state.actions';

export const jobTimelineReducers = [
    on(
        JobTimelineActions.calculateDistances,
        (state: JobToolState): JobToolState => {
            return {
                ...state,
                callState: {
					...state.callState,
					calculateDistance: LoadingState.LOADING,
				},
            };
        }
    ),
    on(
        JobTimelineActions.calculateDistancesSuccess,
        (state: JobToolState, res): JobToolState => {
            return {
                ...state,
                distances: {
                    ...state.distances,
                    [res.key]: res.response.data.calculateDistance,
                },
                callState: {
					...state.callState,
					calculateDistance: LoadingState.LOADED,
				},
            };
        }
    ),
    on(
        JobTimelineActions.calculateDistancesError,
        (state: JobToolState, res): JobToolState => {
            return {
                ...state,
                callState: {
					...state.callState,
					calculateDistance: {
                        error: res.error.message,
                    },
				},
            };
        }
    ),
    on(JobTimelineActions.updateJobTiming, (state: JobToolState,
        { eventType, totalLocationTime, totalTravelTime, partialTravelTime }): JobToolState => {

            const updatedTotalLocationTime = totalLocationTime ?? state.jobTiming[eventType].totalLocationTime;
            const updatedTotalTravelTime = totalTravelTime ?? state.jobTiming[eventType].totalTravelTime;
            const updatedPartialTravelTime = partialTravelTime ?? state.jobTiming[eventType].partialTravelTime;
            const updatedTotalTime = updatedTotalTravelTime !== 0
                ? updatedTotalTravelTime
                : updatedTotalLocationTime + updatedPartialTravelTime;

            return {
                ...state,
                jobTiming: {
                    ...state.jobTiming,
                    [eventType]: {
                        totalLocationTime: updatedTotalLocationTime,
                        totalTime: updatedTotalTime,
                        totalTravelTime: updatedTotalTravelTime,
                        partialTravelTime: updatedPartialTravelTime,
                    },
                },
            };
      }),
] as const;