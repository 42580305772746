import { gql } from 'graphql-tag';

import { User } from './auth';
import { PayrollReport } from './reports';
import { Zone } from './zones';


export interface ListDefaultWagesInput {
	appointmentRoles?: string[];
}

export interface ListDefaultWagesOutput {
	defaultWages: Wage[];
}

export interface WatchDefaultWagesInput {
	appointmentRoles?: string[];
}

export interface WatchDefaultWagesOutput {
	defaultWages: Wage[];
}

export const defaultWagesQuery = gql`
	query defaultWages($appointmentRoles: [ String! ], ){
		payroll {
            defaultWages(appointmentRoles: $appointmentRoles, ){
                id
                effective
                default
                role
                wage
                zones{ id }			 
            }
        } 
    }
`;

export interface ListAdjustmentsInput {
	users?: string[];
	 start?: number;
	 end?: number;
	 ids?: string[];
	 showPaid?: boolean;
	 showUnpaid?: boolean;
}

export interface ListAdjustmentsOutput {
	adjustments: Adjustment[];
}

export interface WatchAdjustmentsInput {
	users?: string[];
	 start?: number;
	 end?: number;
	 ids?: string[];
	 showPaid?: boolean;
	 showUnpaid?: boolean;
}

export interface WatchAdjustmentsOutput {
	adjustments: Adjustment[];
}

export const adjustmentsQuery = gql`
	query adjustments($users: [String], $start: Int, $end: Int, $ids: [String], $showPaid: Boolean!, $showUnpaid: Boolean!, ){
		payroll {
            adjustments(users: $users,  start: $start,  end: $end,  ids: $ids,  showPaid: $showPaid,  showUnpaid: $showUnpaid, ){
                amount
                date
                id
                description
                paid
                paidOn
                userIds
                users{ id }			 
            }
        } 
    }
`;

export interface ListAmountOwingInput {
	input?: AmountOwingInput;
}

export interface ListAmountOwingOutput {
	amountOwing: PayrollReport;
}

export interface WatchAmountOwingInput {
	input?: AmountOwingInput;
}

export interface WatchAmountOwingOutput {
	amountOwing: PayrollReport;
}

export const amountOwingQuery = gql`
	query amountOwing($input: AmountOwingInput, ){
		payroll {
            amountOwing(input: $input, ){
                start
                end
                userReports {
                    userId
                }			 
            }
        } 
    }
`;

export interface ListAmountPaidInput {
	input?: AmountOwingInput;
}

export interface ListAmountPaidOutput {
	amountPaid: PayrollReport;
}

export interface WatchAmountPaidInput {
	input?: AmountOwingInput;
}

export interface WatchAmountPaidOutput {
	amountPaid: PayrollReport;
}

export const amountPaidQuery = gql`
	query amountPaid($input: AmountOwingInput, ){
		payroll {
            amountPaid(input: $input, ){
                start
                end
                userReports {
                    userId
                }			 
            }
        } 
    }

`;

export interface ListAmountOwingAndPaidInput {
	input?: AmountOwingInput;
}

export interface ListAmountOwingAndPaidOutput {
	amountOwingAndPaid: PayrollReport;
}

export interface WatchAmountOwingAndPaidInput {
	input?: AmountOwingInput;
}

export interface WatchAmountOwingAndPaidOutput {
	amountOwingAndPaid: PayrollReport;
}

export const amountOwingAndPaidQuery = gql`
	query amountOwingAndPaid($input: AmountOwingInput, ){
		payroll {
            amountOwingAndPaid(input: $input, ){
                start
                end
                userReports {
                    userId
                }			 
            }
        }   
    }
`;

export interface SetDefaultWageInput {
	appointmentRole?: string;
    wage: number;
}

export interface SetDefaultWageOutput {
	setDefaultWage: Wage;
}

export const setDefaultWageMutation = gql`
	mutation setDefaultWage($appointmentRole: String!, $wage: Float, ){
		payroll {
            setDefaultWage(appointmentRole: $appointmentRole, ){
                id
                effective
                default
                role
                wage
                zones{ id }			 
            }
        } 
    }
`;

export interface MarkAsPaidInput {
	match?: AmountOwingInput;
	 minimumPayment?: number;
}

export interface MarkAsPaidOutput {
	markAsPaid: MarkAsPaidOutput;
}

export const markAsPaidMutation = gql`
	mutation markAsPaid($match: AmountOwingInput, $minimumPayment: Int, ){
		payroll {
            markAsPaid(match: $match,  minimumPayment: $minimumPayment, ){
                reportId  
            }
        } 
    }
`;
export interface Wage {
	id: string;
	effective?: number;
	default?: boolean;
	role?: string;
	wage: number;
	zones: Zone[];
}

export interface UserAdjustment {
	amount: number;
	date: number;
	id: string;
	description?: string;
	paid: boolean;
	paidOn?: number;
}

export interface Adjustment {
	amount: number;
	date: number;
	id: string;
	description?: string;
	paid: boolean;
	paidOn?: number;
	userIds: string[];
	users: User[];
}

export interface DefaultWage {
	role: string;
	defaultWage?: number;
}

export interface RoleWage {
	role: string;
	roleWage?: number;
	defaultWage?: number;
	wage: number;
}

export interface UserWage {
	role: string;
	userWage?: number;
	roleWage?: number;
	defaultWage?: number;
	wage: number;
}

export interface AmountOwingInput {
	start?: number;
	end?: number;
	hideAdjustments?: boolean;
	users?: string[];
	userAppointmentsAndAdjustments: UserAppointmentAndAdjustmentCollection[];
}

export interface UserAppointmentAndAdjustmentCollection {
    id: string;
    appointments: string[];
    adjustments: string[];
}


export interface MarkAsPaidOutput {
	reportId: string;
}

