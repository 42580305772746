import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { PlusAuthenticationService } from '@karve.it/core';
import { Artifact } from '@karve.it/interfaces/artifacts';
import { Asset } from '@karve.it/interfaces/assets';
import { RawUser } from '@karve.it/interfaces/auth';
import { Zone } from '@karve.it/interfaces/zones';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { noop, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseJobFragment, BaseZoneFragment, CalendarEvent, CalendarEventForScheduleFragment, FullJobWithStagesFragment, Job_UsersFragment, PandadocArtifactFragment, Product, SetFieldValuesGQL, SetFieldValuesMutationVariables } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { determineArtifactType } from '../artifacts/artifact.util';

import { getFieldValue } from '../fields/fields.utils';
import { arrRemoveValue, safeParseJSON, strToTitleCase } from '../js';
import { getEventCustomerName } from '../shared/event-location/calendarevent.util';
import { formatUserName } from '../users/users.utils';
import { UpdatedType } from '../utilities/details-helper.util';
import { getJobCustomer } from '../utilities/job-customer.util';
import { runInZone } from '../utilities/rxjs.util';

import { BrandingService } from './branding.service';

import { DetailsHelperService } from './details-helper.service';
import { FreyaHelperService } from './freya-helper.service';

export interface RecentItem {
  id: string;
  name: string;
  zoneId: string;
  type: RecentItemType;
  displayType: string;
}

export type RecentItemData = Asset | RawUser | FullJobWithStagesFragment
  | Product | BaseZoneFragment | CalendarEventForScheduleFragment | Artifact | PandadocArtifactFragment;
export type RecentItemType = 'asset' | 'users' | 'job' | 'product' | 'franchise' | 'calendar-event' | 'artifact';

const LOCAL_STORAGE_KEYS = {
  items: 'recent-items',
  pinned: 'pinned-items'
};

const NAMESPACE = 'recent-items';
const FIELDNAMES = {
  items: NAMESPACE + '.items',
  pinned: NAMESPACE + '.pinned'
};

const ACTION_ID = 'pin';
const ACTION_LABELS = {
  pin: 'Pin',
  unpin: 'Unpin'
};

@Injectable({
  providedIn: 'root'
})
export class RecentItemsService implements OnDestroy {

  subs = new SubSink();

  // Used to notify other tabs of updates
  broadcastChannel: BroadcastChannel;

  // Used to debounce notifications and make sure emissions run in Angular's zone/trigger change detection
  broadcastEmitted = new Subject<void>();

  // Used to resize menu as pinned items container grows or shrinks
  containerSizeChanged = new Subject<void>();

  // All items, regardless of which zone they were added in
  allRecentItems: RecentItem[] = [];
  allPinnedItems: RecentItem[] = [];

  // Items added in current zone
  recentItemsInZone: RecentItem[] = [];
  pinnedItemsInZone: RecentItem[] = [];

  maxRecentItemsPerZone = 10;
  maxPinnedItemsPerZone = 5;

  itemNameMaxLength = 20;

  recentItemsCollapsed = true;
  pinnedItemsCollapsed = true;

  constructor(
    private auth: PlusAuthenticationService,
    private detailsHelper: DetailsHelperService,
    private ngZone: NgZone,
    private freyaHelper: FreyaHelperService,
    private setFieldValuesGQL: SetFieldValuesGQL,
    private branding: BrandingService,
    private confirmationService: ConfirmationService
  ) {
    this.retrieveFromLocalStorage();
    this.retrieveFields();
    this.watchZones();
    this.watchOtherTabs();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Watches for zone changes, and retrieves recent and pinned items on zone change,
   * filtering out items added in zones other than current zone.
   */
  watchZones() {
    this.subs.sink = this.branding.currentZone().subscribe((z) => {
      this.filterItemsByZone();
    });
  }

  /**
   * Watches other tabs for updates to recent or pinned items
   * and retrieves the most current items from local storage on update.
   */
  watchOtherTabs() {

    try {
      this.broadcastChannel = new BroadcastChannel('recent-items-updated');
    } catch (e) {
      console.warn(e);
    }

    if (!this.broadcastChannel) { return; }

    this.broadcastChannel.onmessage = () => this.broadcastEmitted.next();

    this.subs.sink = this.broadcastEmitted
    .pipe(
      debounceTime(100),
      runInZone(this.ngZone)
      )
    .subscribe(() => this.retrieveFromLocalStorage());
  }

  /**
   * Retrieves recent and pinned items from local storage.
   */
  retrieveFromLocalStorage() {
    this.allRecentItems = safeParseJSON(localStorage.getItem(LOCAL_STORAGE_KEYS.items), []) || [];
    this.allPinnedItems = safeParseJSON(localStorage.getItem(LOCAL_STORAGE_KEYS.pinned), []) || [];
    this.filterItemsByZone();
  }

  /**
   * Retrieves recent and pined items from user field.
   */
  retrieveFields() {
    this.freyaHelper.getFieldValues(
      [FIELDNAMES.items, FIELDNAMES.pinned],
      [this.auth.user?.id],
      [ 'User' ],
    )
    .then(([allRecentItems, allPinnedItems]) => {
      this.allRecentItems = getFieldValue(allRecentItems) || [];
      this.allPinnedItems = getFieldValue(allPinnedItems) || [];
      this.filterItemsByZone();
    });
  }


  /**
   * Takes all recent and pinned items,
   * and filters out items added in zones other than current zones.
   * Stores the results in `this.recentItemsInZone` and `this.pinnedItemsInZone` respectively.
   */
  filterItemsByZone() {
    this.recentItemsInZone = this.filterOutItemsFromOtherZones(this.allRecentItems) || [];
    this.pinnedItemsInZone = this.filterOutItemsFromOtherZones(this.allPinnedItems) || [];
  }

  /**
   * Filters out items added in zones other than current zone.
   *
   * @param items The items to be filtered.
   * @returns An array of filtered items.
   */
  filterOutItemsFromOtherZones(items: RecentItem[]): RecentItem[] {
    if (!items) { return; }
    return items.filter((i) => i.zoneId === this.freyaHelper.currentZone?.id);
  }

  /**
   * Saves items of selected type to local storage,
   * updates corresponding field,
   * and notifies other tabs of the update.
   */
  saveItems(type: 'items' | 'pinned') {

    // Set items to save
    const items = type === 'items' ? this.allRecentItems : this.allPinnedItems;

    // Save to local storage
    localStorage.setItem(LOCAL_STORAGE_KEYS[type], JSON.stringify(items));

    // Update field
    const setFieldsInput: SetFieldValuesMutationVariables = {
      fields: { fieldName: FIELDNAMES[type], value: items},
      objects: this.auth.user?.id,
      objectLabel: 'User',
    };

    this.setFieldValuesGQL.mutate(setFieldsInput)
    .subscribe(noop, (e) => console.warn('Could not update recent-items field', e));

    // Notify
    this.notifyOtherTabs();
  }

  /**
   * Adds a new item to the top of the recent items list
   * (unless the item is already included there or under pinned items).
   * If necessary, deletes the last item of the list
   * to keep the list from exceeding the specified maximum.
   */
  addToRecentItems(item: RecentItemData, type: RecentItemType) {
    if (this.isItemInZone(item)) { return; }

    if (this.recentItemsInZone.length >= this.maxRecentItemsPerZone) {
      const removedItem = this.recentItemsInZone.pop();
      arrRemoveValue(this.allRecentItems, removedItem);
    } else {
      // If maxRecentItemsPerZone hasn't been yet reached,
      // that means container will grow
      // so notify menu component to resize menu accordingly
      this.containerSizeChanged.next();
    }

    const newRecentItem: RecentItem = {
      id: item.id,
      name: this.truncateName(this.getName(item, type)),
      zoneId: this.freyaHelper.currentZone?.id,
      type,
      displayType: this.getDisplayType(item, type)
    };

    this.recentItemsInZone.unshift(newRecentItem);
    this.allRecentItems.unshift(newRecentItem);

    this.saveItems('items');
  }

  /**
   * Returns the item's name that will be displayed
   * on the recent items and pinned items lists.
   *
   * @param item The item to be shown.
   * @param type The item's type.
   * @returns A string with item's name to be displayed.
   */
  getName(item: RecentItemData, type: RecentItemType): string {
    switch(type) {
      case 'job':
        const job = (item as BaseJobFragment & Job_UsersFragment);
        return getJobCustomer(job.users, true) as string;
      case 'users':
        const user = (item as RawUser);
        return formatUserName(user);
      case 'calendar-event':
        const event = (item as CalendarEvent);
        return getEventCustomerName(event);
      default:
        const rest = (item as Zone | Product | Asset);
        return rest.name;
    }
  }

  truncateName(name: string, maxChars?: number) {
    maxChars = maxChars || this.itemNameMaxLength;
    if (name.length > maxChars) {
      return `${name.slice(0, (maxChars - 1))}...`;
    }
    return name;
  }

  /**
   * Returns the type to be displayed above the item's name
   * on the recent items and pinned items lists.
   *
   * May differ from the item's actual type
   * (e.g. an item of type 'job' may get a display type of 'estimate').
   *
   * @param item The item to be shown.
   * @param type The item's type.
   * @returns A string with the type to be displayed.
   */
  getDisplayType(item: RecentItemData, type: RecentItemType): string {
    switch (type) {
      case 'users':
        return 'User';
      case 'job':
        const job = (item as FullJobWithStagesFragment);
        return [strToTitleCase(job?.stage), job?.code].filter(Boolean).join(' | ');
      case 'calendar-event':
        const event = (item as CalendarEventForScheduleFragment);
        return strToTitleCase(event.type);
      case 'artifact':
        const artifact = (item as Artifact);
        return determineArtifactType(artifact.contentType);
      default:
        return strToTitleCase(type);
    }
  }

  confirmPinItem(item: RecentItemData, type: RecentItemType) {
    if (this.pinnedItemsInZone.length < this.maxPinnedItemsPerZone) {
      this.pinItem(item, type);
      return;
    }
    const message = 'You have reached the maximum number of items you can pin in a zone. '
    + 'If you pin this item, this will unpin the last item in your list. '
    + 'Do you wish to proceed?';

    this.confirmationService.confirm({
      message,
      acceptLabel: 'Yes, unpin the last item to make room for this item',
      rejectLabel: 'No',
      header: 'Unpin last item to make room for new item?',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'pi mi-pin',
      rejectIcon: 'pi pi-times',
      defaultFocus: 'accept',
      acceptButtonStyleClass: 'p-button-warning',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: () => this.pinItem(item, type),
      dismissableMask: true
    });

  }

  pinItem(item: RecentItemData, type: RecentItemType) {
    if (this.pinnedItemsInZone.some((pi) => pi.id === item.id)) { return; }

    if (this.pinnedItemsInZone.length >= this.maxPinnedItemsPerZone) {
      const removedItem = this.pinnedItemsInZone.pop();
      arrRemoveValue(this.allPinnedItems, removedItem);
    }

    const newPinnedItem: RecentItem = {
      id: item.id,
      name: this.truncateName(this.getName(item, type)),
      zoneId: this.freyaHelper.currentZone?.id,
      type,
      displayType: this.getDisplayType(item, type)
    };

    let delay = 0;

    if (this.pinnedItemsCollapsed) {
      this.pinnedItemsCollapsed = false;
      delay = 350;
    }

    setTimeout(() => {
      this.pinnedItemsInZone.unshift(newPinnedItem);
      this.allPinnedItems.unshift(newPinnedItem);

      this.saveItems('pinned');

      // Notify details panel so pin action is updated
      this.pushUpdate(item.id, type);

      // Notify menu component to resize menu
      this.containerSizeChanged.next();
    }, delay);
  }

  unpinItem(item: RecentItem) {
    arrRemoveValue(this.pinnedItemsInZone, item);
    arrRemoveValue(this.allPinnedItems, item);
    this.saveItems('pinned');

    // Notify details panel so pin action is updated
    this.pushUpdate(item.id, item.type);

    // Notify menu component to resize menu
    this.containerSizeChanged.next();
  }

  isItemInZone(item: RecentItemData): boolean {
    const itemsInZone = [...this.recentItemsInZone, ...this.pinnedItemsInZone];
    return itemsInZone.some((i) => i.id === item.id);
  }

  openItem(recentItem: RecentItem) {
    this.detailsHelper.detailsItem.next({ item: { id: recentItem.id }, type: recentItem.type });
  }

  notifyOtherTabs() {
    if (!this.broadcastChannel) { return; }
    this.broadcastChannel.postMessage(true);
  }

  /**
   * Takes an item's actions menu,
   * and makes sure the menu contains the correct pin/unpin action based on whether the item is already pinned.
   *
   * If the provided menu contains no pin actions,
   * it adds a new one, otherwise replaces existing one.
   *
   * @param menu An item's actions menu.
   * @param item The item to pin or unpin.
   * @param type The item's type.
   */
  setPinAction(menu: MenuItem[], item: RecentItemData, type: RecentItemType) {
    if (!menu.length) { return; }

    const menuItems = menu[0].items;

    const existingPinAction = menuItems
    .find((a) => a.id === ACTION_ID);

    if (existingPinAction) {
      arrRemoveValue(menuItems, existingPinAction);
    }

    const newPinAction = this.generatePinAction(item, type);

    menuItems.push(newPinAction);
  }

  /**
   * Generates a 'pin' or 'unpin' action menu item based on whether the provided item is already pinned.
   *
   * @param item The item to pin or unpin.
   * @param type The item's type.
   * @returns A pin/unpin menu item.
   */
   generatePinAction(item: RecentItemData, type: RecentItemType): MenuItem {
    const pinnedItem = this.pinnedItemsInZone
    .find((pi) => pi.id === item.id);

    if (pinnedItem) {
      return {
        id: ACTION_ID,
        label: ACTION_LABELS.unpin,
        icon: 'pi mi-pin-fill',
        command: () => this.unpinItem(pinnedItem)
      } as MenuItem;
    }

    return {
      id: ACTION_ID,
      label: ACTION_LABELS.pin,
      icon: 'pi mi-pin',
      command: () => this.confirmPinItem(item, type)
    } as MenuItem;
  }

  pushUpdate(itemId: string, itemType: RecentItemType) {
    let updatedType: UpdatedType;

    switch (itemType) {
      case 'asset':
        updatedType = 'Assets';
        break;
      case 'franchise':
        updatedType = 'Franchises';
        break;
      case 'job':
        updatedType = 'Jobs';
        break;
      case 'product':
        updatedType = 'Products';
        break;
      case 'users':
        updatedType = 'User';
        break;
      case 'calendar-event':
        updatedType = 'Events';
        break;
      case 'artifact':
        updatedType = 'Artifacts';
    }

    this.detailsHelper.pushUpdate({
      id: itemId,
      type: updatedType,
      action: 'update'
    });
  }

}
