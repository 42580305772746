import { Component, Input, OnInit } from '@angular/core';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';
import { DetailsHelperService } from 'src/app/services/details-helper.service';

import { CalendarEventLocation } from '../../../generated/graphql.generated';

import { locationNameMap } from '../../global.constants';

export interface EventLocationWithArrival extends CalendarEventLocation {
  arrivalTime?: number;
}

@Component({
  selector: 'app-event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss']
})
export class EventLocationComponent implements OnInit {
  @Input() calendarEventType: string;
  @Input() eventLocation: EventLocationWithArrival;
  @Input() event: CalendarEvent;

  @Input() readonly = false; // If true all create/update is disabled
  @Input() arrivalTimeList: number;
  locationNameMap = locationNameMap;
  // calculatedTime;
  departureTime: number;

  constructor(
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    // this.getCalculatedTime();
    this.getEachLocationTime();
  }

  getEachLocationTime() {
    this.eventLocation.arrivalTime = this.arrivalTimeList;
    this.departureTime = this.eventLocation.arrivalTime + this.eventLocation.estimatedTimeAtLocation;
  }

  // getCalculatedTime() {
  //   this.calculatedTime = this.ruleChecker.calcs[`${this.calendarEventType}-${this.eventLocation.type}`];
  // }


}
