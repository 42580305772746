import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { firstValueFrom } from 'rxjs';

import { AvailableZonesAndCurrentZoneGQL, BaseZoneWithParentFragment, ServiceAreaQueryMatch } from 'graphql.generated';
import { resolveChildrenFromID } from 'src/app/utilities/zones.util';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';

export interface SelectAreaManuallyDialogDataComponent {
  description?: string;
  onlyShowCurrentSubzones?: boolean;
  onlyShowAreas?: boolean;
  showAreaWarning?: boolean;
  closestAreas?: ServiceAreaQueryMatch[];
  showCancel?: boolean;
}

export interface SelectAreaManuallyDialogResult {
  error?: Error;
  zone?: BaseZoneWithParentFragment;
}

export interface SelectAreaZone extends BaseZoneWithParentFragment {
  closestArea?: ServiceAreaQueryMatch;
  closestAreaIndex?: number;
}

@Component({
  selector: 'app-jobv2-select-area-manually',
  standalone: true,
  imports: [
    FreyaCommonModule,
    ReactiveFormsModule,
],
  templateUrl: './jobv2-select-area-manually.component.html',
  styleUrls: ['./jobv2-select-area-manually.component.scss']
})
export class Jobv2SelectAreaManuallyComponent implements OnInit {

  availableZones: SelectAreaZone[];
  loading = true;

  form = new UntypedFormGroup({
    selectedZone: new UntypedFormControl(undefined, [ Validators.required ]),
  });

  get data() {
    return (this.dialogConfig?.data || {}) as SelectAreaManuallyDialogDataComponent;
  }

  constructor(
    private availableZonesAndCurrentZoneGQL: AvailableZonesAndCurrentZoneGQL,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
  ) {
  }

  ngOnInit(
  ): void {
    this.form.reset();

    this.resolveAvailableZones()
      .catch((error) => {
        console.error(`Error loading areas to select`, error);
        this.dialogRef.close({ error, });
      });
  }

  close() {
    this.dialogRef.close({});
  }

  submit() {
    if (!this.form.valid) { return; }
    this.dialogRef.close({
      zone: this.form.value.selectedZone,
    });
  }

  async resolveAvailableZones() {

    this.availableZones = undefined;
    this.loading = true;

    const { data } = await firstValueFrom(this.availableZonesAndCurrentZoneGQL
      .fetch({
        skip: 0,
        limit: 1500,
        search: '',
      }));

    this.loading = false;

    let zones = cloneDeep(data.availableZones) as SelectAreaZone[];
    if (this.data.onlyShowCurrentSubzones) {
      zones = resolveChildrenFromID(data.availableZones, data.currentZone.id);
    }

    if (this.data.onlyShowAreas) {
      zones = zones.filter((zone) => zone.type === 'area');
    }

    let closestAreaIndex = 0;
    for (const closestArea of this.data.closestAreas || []) {
      const zone = cloneDeep(zones.find((z) => z.id === closestArea.zoneId));
      closestAreaIndex++;
      if (!zone) { continue; }
      zone.closestArea = closestArea;
      zone.closestAreaIndex = closestAreaIndex;
    }

    this.availableZones = zones.map((z: any) => {
      const clone = cloneDeep(z);
      clone.search = `${ clone.name } ${ clone.parent?.name || '' }`.toLowerCase();
      return clone;
    });

    return data;
  }

}
