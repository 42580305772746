<ng-container *ngIf="pipeline$ | async as pipeline">
  <p-blockUI [target]="blockable" [blocked]="pipeline.loading" [autoZIndex]="false">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
  </p-blockUI>
  <div class="card pipeline">
    <div class="dashboard-card-header">
      <h4 class="p-mb-1">Open Jobs<app-error-icon *ngIf="pipeline.error"></app-error-icon></h4>
      <app-dashboard-info-v2>
        <div class="p-mb-2">
          A breakdown of all open jobs (not just for the selected period) into their various stages.
        </div>
        <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="pipeline.queryParams" target="_blank">View Report</a>.
      </app-dashboard-info-v2>
    </div>
    <app-blockable #blockable>
      <p-chart type="pie"
          [data]="pipeline.chartData"
          [options]="options"
          responsive="true" 
          #chart
          [class.hide]="pipeline.isEmpty"
          (onDataSelect)="goToJobsPage(pipeline.chartData.labels[$event.element.index])"></p-chart>
      <app-dashboard-empty-state *ngIf="pipeline.isEmpty" text="No open jobs with the selected currency"></app-dashboard-empty-state>
    </app-blockable>
  </div>
</ng-container>
