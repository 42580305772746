import { ConfigValue } from '../../generated/graphql.generated';

export function getConfigValueByKey(configs: ConfigValue[], key: string, defaultValue?: any) {
  const config = configs.find((c) => c.key === key);
  return config ? config.value : defaultValue;
}

export function getParsedConfigValueByKey(configs: ConfigValue[], key: string, defaultValue?: any) {
  const config = configs.find((c) => c.key === key);
  return config ? JSON.parse(config.value) : defaultValue;
}
