/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { RawUser } from './auth';
import { usersBaseGQL, jobsBaseGQL, ZoneDirection } from './common.gql';
import { Job } from './jobs';
import { Metadata, metaDataGQL } from './metadata';

export interface ListTransactionsFunctionOutput {
    transactions: ListTransactionsOutput;
}

export interface CreateTransactionOutput {
    createTransaction: CreateTransactionOutput;
}

export interface UpdateTransactionOutput {
    updateTransaction: boolean;
}

export interface DeleteTransactionsOutput {
    deleteTransactions: boolean;
}

export enum TransactionStage {
    pending,
    paid,
    cancelled
}


export interface Transaction {
    id: string;
    createdAt: number;
    deletedAt?: number;
    amount?: number;
    customer: RawUser;
    job: Job;
    currency: string;
    transactionType: string;
    // pending, paid, cancelled
    stage: TransactionStage;
    metadata?: Metadata;
}


export interface TransactionsFilter extends ZoneDirection {
    transactionIds?: string[];
    createdAfter?: number;
    createdBefore?: number;
    customerId?: string;
    amountGreaterThan?: number;
    amountLowerThan?: number;
    transactionType?: string;
    currency?: string;
    jobIds?: string[];
    // pending, paid, cancelled
    stage?: TransactionStage;
    // Default false
    showDeleted?: boolean;
}


export interface CreateTransactionOutput {
    transaction: Transaction;
    // Used to complete a payment with whatever external api is used by the created transaction, if any
    client_secret?: string;
}


export interface ListTransactionsOutput {
    total: number;
    skipped: number;
    limited: number;
    transactions: Transaction[];
}

export interface TransactionsInput {
    filter?: TransactionsFilter;
    skip?: number;
    limit?: number;
    sort?: string;
}

export interface CreateTransactionInput {
    amount?: number;
    customerId?: string;
    jobId?: string;
    transactionType?: string;
    stage?: TransactionStage;
    metadata?: string;
}

export interface UpdateTransactionInput {
    transactionId?: string;
    stage?: TransactionStage;
}

export interface DeleteTransactionsInput {
    ids?: string[];
}

export interface GenerateTransactionQueryInput {
    jobExtra?: boolean;
    customerExtra?: boolean;
    metadata?: boolean;
}

export function GenerateTransactionQuery(input: GenerateTransactionQueryInput) {
    const txQuery = gql`
        query transactions($filter: TransactionsFilter, $skip: Int, $limit: Int, $sort: String, ){
            transactions(filter: $filter, skip: $skip, limit: $limit, sort: $sort, ){
                    total
                    skipped
                    limited
                    transactions{
                        ${transactionsBaseGQL}
                        ${input.jobExtra ? jobExtra : jobId}
                        ${input.customerExtra ? customerExtra : cusomterId}
                        ${input.metadata ? metaDataGQL : ``}
                    }
            }		
        }
    `;

    return txQuery;
}

export const transactionsBaseGQL = `
    id
    createdAt
    deletedAt
    amount
    currency
    transactionType
    stage
`;

export const jobId = `
    job { 
        id
    }
`;

export const jobExtra = `
    job {
        ${jobsBaseGQL}
    }
`;

export const cusomterId = `
    customer {
        id
    }
`;

export const customerExtra = `
    customer {
        ${usersBaseGQL}
    }
`;

export const transactionsQuery = gql`
	query transactions($filter: TransactionsFilter, $skip: Int, $limit: Int, $sort: String, ){
		transactions(filter: $filter, skip: $skip, limit: $limit, sort: $sort, ){
			    total
			    skipped
			    limited
			    transactions{ ${transactionsBaseGQL} }
	}		}
`;


export const createTransactionMutation = gql`
	mutation createTransaction($amount: Int!, $customerId: String!, $jobId: String!, $transactionType: String!, $stage: TransactionStage, $metadata: Metadata, ){
		createTransaction(amount: $amount, customerId: $customerId, jobId: $jobId, transactionType: $transactionType, stage: $stage, metadata: $metadata, ){
			    transaction{
                    ${transactionsBaseGQL}
                }			    
                client_secret

	}		}
`;


export const updateTransactionMutation = gql`
	mutation updateTransaction($transactionId: String!, $stage: TransactionStage!, ){
		updateTransaction(transactionId: $transactionId, stage: $stage, ) 

	}
`;


export const deleteTransactionsMutation = gql`
	mutation deleteTransactions($ids: [String]!, ){
		deleteTransactions(ids: $ids, ) 

	}
`;

