import { Pipe, PipeTransform } from '@angular/core';

import { remainingBalance } from '../jobs/jobs.util';

@Pipe({
  name: 'remainingJobBalance'
})
export class RemainingJobBalancePipe implements PipeTransform {

  transform(balance: Parameters<typeof remainingBalance>[0]): number {
    return remainingBalance(balance);
  }

}
