import { Pipe, PipeTransform } from '@angular/core';

import { TransactionWithAvailability } from '../services/freya-helper.service';

@Pipe({
  name: 'transactionAvailability'
})
export class TransactionAvailabilityPipe implements PipeTransform {

  transform(value: TransactionWithAvailability): string {
    if (!value.unavailable) {
      return 'Available';
    }

    if (value.linkedToInvoice) {
      return 'Already applied';
    }

    if (value.exceedsInvoiceBalance) {
      return 'Exceeds remaining invoice balance';
    }
  }

}
