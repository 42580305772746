

<p-autoComplete
	[disabled]="disabled"
	[(ngModel)]="selectedUsers"
	[suggestions]="users"
	(completeMethod)="search($event)"
	[multiple]="true"
	[completeOnFocus]="true"
	[delay]="300"
	[minLength]="0"
	field="email"
    appendTo="body"
	[unique]="true"
	[showEmptyMessage]="true"
	emptyMessage="No users found"
	placeholder="Search for users..."
	(ngModelChange)="onAutocompleteChange()"
></p-autoComplete>
