/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PlusAuthenticationService } from '@karve.it/core';
import { CommentService } from '@karve.it/features';
import { Comment, CommentsInput, ListCommentFilter, ListCommentsOutput } from '@karve.it/interfaces/comments';
import {QueryRef} from 'apollo-angular';

import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaMutateService } from '../services/freya-mutate.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { PermissionService } from '../services/permission.service';
import { permissionHasRestriction } from '../services/permissions.util';
import { YemboHelperService } from '../services/yembo-helper.service';
import { FreyaDatePipe } from '../shared/freya-date.pipe';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  // Muatate Vairable
  @Input() objectType: string; // Overrides the value given in the comments filter
  @Input() objectId: string;

  // Filter Variables
  @Input() commentsFilter: ListCommentFilter;
  @Input() pagination = pagination;

  // Modify Component Variables
  @Input() callOnLoad = true;
  @Input() nameForComment = 'Note'; // What we call comments eg. 'Note', 'Comment' etc.
  @Input() title; // What shows up at the top of the card, default set in ngOnInit
  @Input() titleNoToolTip: string;
  @Input() showAddButton = true;
  @Input() inventoryComment = false;
  @Input() collapsed = false;
  // Manually format as a small container // TODO: Make this automatic for small screens
  @Input() smallContainer = false;
  @Input() readonly = false;
  @Input() readonlyTooltip: string;

  subs = new SubSink();

  // COMMENTS
  comments: Comment[] = [];
  activeComment: Comment;

  // QUERY
  commentsQueryRef!: QueryRef<ListCommentsOutput>;
  commentsQH: WatchQueryHelper = {
    limit: -1,
    skip: 0,
    loading: true,
    search: '',
    increment: 3,
  };

  inventoryText: string;

  // ACTIONS
  commentActions: MenuItem[] = [
    {label: 'Edit', icon: 'pi pi-pencil', command: (event) => {
      this.openMutateComment('update', this.activeComment);
    }},
    {label: 'Delete', icon: 'pi pi-trash', command: () => {
      this.freyaMutate.openDeleteObject({
        objectId: this.activeComment.id,
        // eslint-disable-next-line max-len
        objectName: `the ${this.nameForComment} made by ${this.activeComment?.author?.givenName || '[Deleted]'} on ${this.freyaDatePipe.transform(this.activeComment.createdAt, 'MMMM d')}`,
        objectType: 'comment',
        showInsteadOfType: `${this.nameForComment}`,
      });
    }},
    // Placeholder so when we have options like pin, reply etc. The functionality doesn't change
    {
      label: `You can't modify other user's ${this.nameForComment}s`,
      disabled: true,
      visible: false,
    },
  ];

  constructor(
    private detailsHelper: DetailsHelperService,
    private commentService: CommentService,
    private ref: ChangeDetectorRef,
    private localNotify: FreyaNotificationsService,
    private freyaMutate: FreyaMutateService,
    private freyaDatePipe: FreyaDatePipe,
    public plusAuth: PlusAuthenticationService,
    private permissionHandler: PermissionService,
    private yemboHelper: YemboHelperService,
    ) { }

  ngOnInit(): void {
    this.title = this.title || `${this.nameForComment}s`;

    // Set the loading state based on our input parameters
    this.commentsQH.loading = this.callOnLoad;

    // Subscribe to comment changes
    this.subs.sink = this.detailsHelper.getObjectUpdates('Comments').subscribe((res) => {
      this.fetchComments(true);
    });

    if (this.callOnLoad && this.objectId) {
      this.fetchComments();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If we want to call on load but the it takes a second to get the object id from the parent
    //if (this.callOnLoad && changes.objectId && (changes.objectId.currentValue !== changes.objectId.previousValue)){
    //  this.fetchComments();
    //}
    //updated during the performance optimization for estimator.
    //here it makes sense to fetchComments when objectId changes each time, not only when
    //comments component is being used with callOnLoad = true
    //as result we can get rid of unnecessary fetchComments call from estimator component
    //where component is being used with callOnLoad = false

    if (changes?.objectId?.currentValue && !changes?.objectId?.isFirstChange()){
      this.fetchComments();
    }
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private initCommentQuery() {
    if (!this.objectId) { return; }

    this.commentsQueryRef = this.commentService.watchComments(
      this.generateQueryInput(),
      {
        fetchPolicy: 'cache-and-network',
      }
    );

    this.subs.sink = this.commentsQueryRef.valueChanges.subscribe((res) => {
      this.commentsQH.loading = res.loading;
      if (!res.data?.comments?.comments){
        return;
      }
      this.comments = res.data.comments.comments;
      this.commentsQH.total = res.data.comments.comments.length;

      if (this.inventoryComment){
        const {manual, generated} = this.yemboHelper.getInventoryComments(this.comments as any[]);
        this.inventoryText = this.yemboHelper.getCombinedInventory(manual, generated);
      }
    });
  }

  generateQueryInput(): CommentsInput {
    return {
      filter: {
        ...this.commentsFilter,
        hasAnyAttributes: this.inventoryComment ? ['inventory', 'generated-inventory'] : this.commentsFilter.hasAnyAttributes,
        objectIds: [this.objectId],
        objectType: this.objectType,
      },
      limit: this.commentsQH.limit,
      skip: this.commentsQH.skip,
    };
  }

  fetchComments(refetch = true) {
    if (!this.commentsQueryRef) {
      this.initCommentQuery();
      return;
    }

    if (refetch) {
      this.commentsQueryRef.refetch(this.generateQueryInput());
    } else {
      this.commentsQueryRef.setVariables(this.generateQueryInput());

    }

  }

  fetchMore() {
    this.commentsQH.limit += this.commentsQH.increment;
    this.commentsQueryRef.fetchMore({
      variables: {
        limit: -1,
        skip: this.comments.length,
      },
      // updateQuery: (p, { fetchMoreResult, variables }) => {

      //   const comments = p.comments.comments.slice(0, variables.skip);
      //   comments.splice(
      //     variables.skip,
      //     variables.limit,
      //     ...fetchMoreResult.comments.comments
      //   );
      //   return {
      //     ...p,
      //     comments: {
      //       ...p.comments,
      //       comments,
      //     },
      //   };
      // },
    });
  }

  openMutateComment(mutateType: 'create' | 'update', comment: Comment = undefined){
    this.freyaMutate.openMutateObject({
      mutateType,
      objectType: 'comment',
      object: comment,
      additionalValues: [{
        property: 'commentName',
        value: this.nameForComment,
      }, {
        property: 'objectId',
        value: this.objectId,
      }, {
        property: 'objectType',
        value: this.objectType,
      }, {
        property: 'hideAccessLevel',
        value: true,
      }],
      openToStep: mutateType === 'update' ? 'Text' : undefined,
    });
  }

  viewAuthorInSidePanel(author){
    this.detailsHelper.open('users', {id: author.id});
  }

  openCommentOptions(comment: Comment, menu, clickEvent){
    this.activeComment = comment;

    const canUpdate = this.canUpdateComment(comment);
    // eslint-disable-next-line max-len
    const canDelete = this.canDeleteComment(comment);

    // Modify the visibility of menu items based on permissions
    this.commentActions[0].visible = canUpdate;
    this.commentActions[1].visible = canDelete;
    this.commentActions[2].visible = !canUpdate && !canDelete;

    menu.toggle(clickEvent);
  }

  canUpdateComment(comment: Comment) {
    const isOwnComment = comment?.author?.id === this.plusAuth.user.id;
    const permission = this.permissionHandler.getPermission('comments.update');
    const hasAdminOverride = permissionHasRestriction(permission, 'overrideOwnership');
    return (isOwnComment && permission) || hasAdminOverride;
  }

  canDeleteComment(comment: Comment) {
    const isOwnComment = comment?.author?.id === this.plusAuth.user.id;
    const permission = this.permissionHandler.getPermission('comments.delete');
    const hasAdminOverride = permissionHasRestriction(permission, 'overrideOwnership');
    return (isOwnComment && permission) || hasAdminOverride;
  }
}
