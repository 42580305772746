<div>
    <p-multiSelect
        appendTo="body"
        [placeholder]="placeholder"
        class="full-width-multiselect"
        [options]="productSuggestions"
        [(ngModel)]="selectedProducts"
        (ngModelChange)="onChange(selectedProducts)"
        optionLabel="name"
        [disabled]="disabled"
        (onChange)="emitSelect()"
        (focus)="touched = true"
        [maxSelectedLabels]="1"
        [selectedItemsLabel]="'{0} Products'"
        [autofocusFilter]="!responsiveHelper.isSmallScreen"
        data-cy="product-multi-select-field"
    >
</p-multiSelect>
</div>