<div [formGroup]="transactionTypeForm">
    <ng-template #key>
        <input
            type="text"
            pInputText
            formControlName="key"
            placeholder="Key *"
        />
    <div class="p-mt-2">
        <small>payment-type.{{transactionTypeForm.controls.key.value | customCase : ['kebab'] }}</small>
    </div>
    </ng-template>
    <ng-template #name>
        <input
            type="text"
            pInputText
            formControlName="name"
            placeholder="Name *"
        />
        <div class="p-field-checkbox p-mt-2">
            <p-checkbox
                formControlName="nameSameAsKey"
                binary="true"
                inputId="nameSameAsKey"
            >
            </p-checkbox>
            <label for="nameSameAsKey"><small>Same as key</small></label>
        </div>
    </ng-template>
    <ng-template #provider>
        <input
            type="text"
            pInputText
            formControlName="provider"
            placeholder="Provider"
        />
    </ng-template>
    <ng-template #enabled>
        <div class="p-field-checkbox">
            <p-checkbox
                formControlName="enabled"
                binary="true"
                inputId="enabled"
            >
            </p-checkbox>
            <label for="enabled">Enabled</label>
        </div>
    </ng-template>
</div>
<app-mutate-object
    #mutate
    [fg]="transactionTypeForm"
    objectType="Payment Type"
    mutateType="update"
    [steps]="steps"
    (objectMutated)="mutateTransactionType()"
></app-mutate-object>