import { Component, OnDestroy, OnInit } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { ContactListsGQL, GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { JOB_STAGES } from '../../global.constants';
import { safeParseJSON, strToTitleCase } from '../../js';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

@Component({
  selector: 'app-contact-lists',
  templateUrl: './contact-lists.component.html',
  styleUrls: ['./contact-lists.component.scss']
})
export class ContactListsComponent implements OnInit, OnDestroy {

  configKey = 'act-on.contact-lists';

  configValuesQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  contactLists$ = this.contactListsGQL.watch()
    .valueChanges.pipe(
      filter((res) => !res.loading),
      map(res => res.data.contactLists.result),
    );

  jobStageListMap: { [jobStatus: string]: string } = {};

  stages = Object.keys(JOB_STAGES);

  subs = new SubSink();

  constructor(
    private contactListsGQL: ContactListsGQL,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private notify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.configValuesQueryRef = this.getConfigValuesGQL.watch({ keys: [ this.configKey ] });

    this.subs.sink = this.configValuesQueryRef.valueChanges
      .subscribe((res) => {
        if (res.loading) { return; }

        if (!res.data.getConfigValues.length) { return; }

        this.jobStageListMap = safeParseJSON(res.data.getConfigValues[0].value, {}) || {};
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  saveJobStageListMap() {
    this.setConfigValuesGQL.mutate({configs: [{
      key: this.configKey,
      value: JSON.stringify(this.jobStageListMap),
    }]}).subscribe(() => {
      this.configValuesQueryRef.refetch();
      this.notify.success('Act-On settings updated');
    });
  }
}
