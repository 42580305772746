import { Pipe, PipeTransform } from '@angular/core';
import { Charge } from '@karve.it/interfaces/charges';

@Pipe({
  name: 'chargeDamage'
})
export class ChargeDamagePipe implements PipeTransform {

  transform(value: Charge[], charges: boolean): Charge[] {
    if (!value?.length) { return; }

    if (charges) {
      return value.filter((c) => !(c.attributes || []).includes('damage')); // Charges
    } else {
      return value.filter((c) => (c.attributes || []).includes('damage')); // Damages
    }
  }

}
