<div *ngIf="rule">
  <div class="header-with-button">
    <div>
      <h4><i class="pi pi-book large-icon"> </i> Rule</h4>
      <app-object-state
        [object]="rule"
        objectType="rule"
        [colorful]="true"
      ></app-object-state>
    </div>
    <div>
      <button
        *ngIf="ruleActions[0].visible"
        pButton
        pRipple
        label="Actions"
        (click)="menu.toggle($event)"
        icon="pi pi-angle-down"
      ></button>
      <p-menu
        #menu
        [popup]="true"
        appendTo="body"
        [model]="ruleActions"
      ></p-menu>
    </div>
  </div>

  <div class="card">
    <p class="givenwhenthen">
      <strong>Given</strong> {{ rule.triggerDescription }}
      <br />
      <strong>When</strong> {{ describeCondition(rule.condition) }}
      <br />
      <strong>Then</strong> {{ rule.action.describedForRule }}
    </p>
    <!-- <p>Name: {{artifact.name}}</p>
        <p>Size: {{artifact.fileSize | fileSize}}</p>
        <p>Public: {{artifact.public ? 'Yes' : 'No'}}</p> -->
    <p>Created: {{ rule.createdAt | freyaDate }}</p>
    <p>Updated: {{ rule.updatedAt | freyaDate }}</p>
    <p *ngIf="rule.deletedAt">Deleted: {{ rule.deletedAt | freyaDate }}</p>
    <p>
      Attributes:
      {{ rule.attributes?.length ? rule.attributes.join(', ') : 'None' }}
    </p>

    <p *ngIf="metadata?.length">
      Metadata:
      <p-table [value]="metadata">
        <ng-template pTemplate="header">
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-meta>
          <tr>
            <td>{{ meta.key }}</td>
            <td>{{ meta.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </p>
  </div>
</div>

<ng-template #elseBlock>
  <p-skeleton width="100%" height="24px"></p-skeleton>
</ng-template>

<app-mutate-rule #mutate></app-mutate-rule>
