import { Injector } from '@angular/core';

import { default as cronstrue } from 'cronstrue';

import { PlusAuthenticationService } from './app/core/public-api';
import { hash_cyrb53 } from './app/js';
import { getServerTimingHelperCommands } from './app/servertiming';

let injector: Injector;

export const cmdFlags = {
    SelectZone_ShowOnlySubZones: true,
    SelectZone_ShowAreas: true,
    EventDetails_ShowAllEventActions: false,
};

export function initCmd(
    _injector: Injector,
) {

    injector = _injector;

    globalThis.cmd = {
        ...getServerTimingHelperCommands(),
        // resolveAddress: // located in google-helper.service.ts
        flag(key: keyof typeof cmdFlags) {
            cmdFlags[key] = !cmdFlags[key];
        },
        mkContext,
        setContext,
        getContext,
        cron: (str) => cronstrue.toString(str)
    };
}

function mkContext(
    zone: string,
    role: string,
    seed = 4152434,
) {
    return hash_cyrb53(`${ zone }:${ role }`, seed);
}

async function setContext(
    zone: string,
    role?: string,
) {

    const authService = injector.get(PlusAuthenticationService);
    return authService.setContext(zone, role);
}

function getContext() {

    const authService = injector.get(PlusAuthenticationService);
    return {
        user: authService.user,

        userId: authService.user?.id,
        zoneId: authService.contextedZoneId,
        roleId: authService.contextedRoleId,
    };
}
