<div class="card">
    <div class="p-d-flex p-jc-between p-ai-center p-mb-4">
        <h5>{{ namespace | customCase : ['uncamel', 'unkebab', 'title'] }}</h5>
        <div>
            <ng-container *hasPermission="['config.set']">
                <button pButton *ngIf="!editMode" icon="pi pi-pencil" (click)="enterEditMode()"></button>
            </ng-container>
            <button pButton *ngIf="editMode" icon="pi pi-save" (click)="save()" class="p-button-success p-mr-2" [disabled]="!modifiedKeys.size"></button>
            <button pButton *ngIf="editMode" [icon]="modifiedKeys.size ? 'pi pi-trash' : 'pi pi-times'" (click)="discardChanges()" class="p-button-secondary" [pTooltip]="modifiedKeys.size? 'Discard changes.' : undefined"></button>
        </div>
    </div>
    <div *ngFor="let config of booleanParsedConfigs" class="p-mb-2">
        <div
            *ngIf="!(config.value | typeOf : 'boolean')"
            class="p-d-flex p-jc-between p-ai-center"
            >
            <div style="overflow-x: hidden;">
                <div class="p-text-bold p-mb-1">{{ config.key }}:
                    <app-info-circle
                        *ngIf="config.zone && config.zone !== currentZoneId"
                        class="info-circle"
                        [pTooltip]="'Resolved from zone: ' + config.zone"
                        ></app-info-circle>
                </div>
                <app-copy-button *ngIf="!editMode" [content]="config.value"></app-copy-button>
                <input *ngIf="editMode" pInputText type="text" [name]="config.key" [(ngModel)]="config.value" (input)="markAsModified(config.key)" class="full-width" placeholder="Not Set">
            </div>
            <div *hasPermission="['config.set']">
                <button
                    pButton
                    *ngIf="!editMode && (config.zone === currentZoneId)"
                    (click)="reset(config.key)"
                    icon="pi pi-refresh"
                    class="p-button-outlined"
                    [pTooltip]="'Fallback: ' + config.formattedFallback"
                    ></button>
            </div>
        </div>
        <div *ngIf="config.value | typeOf : 'boolean'" class="p-d-flex p-jc-between p-ai-center">
            <span class="p-text-bold">{{ config.key }}</span>
            <p-inputSwitch [(ngModel)]="config.value" [disabled]="!editMode" (onChange)="markAsModified(config.key)"></p-inputSwitch>
        </div>
    </div>
</div>
