<p-toolbar styleClass="p-mb-4">
	<div class="p-toolbar-group-left">
		<p-button
			type="button"
			icon="pi pi-arrow-left"
			[routerLink]="[ '/notifications' ]"
			pTooltip="All Notifications"
			[disabled]="!notification"
			></p-button>
		<p-button
			class="p-ml-5"
			type="button"
			*ngIf="viewDetailsData"
			[label]="viewDetailsData.label"
			(click)="viewDetailsData?.command && viewDetailsData.command()"
			[routerLink]="viewDetailsData?.url"
			[icon]="viewDetailsData?.icon"
			></p-button>
	</div>
</p-toolbar>
<div *ngIf="notification"> 
	<h3>
		<a 
			*ngIf="validUrl"
			[href]="validUrl"
		>
			{{notification.title}}
		</a>
		<ng-container *ngIf="!validUrl">
			{{notification.title}}
		</ng-container>
	</h3>
	<h4>{{notification.subtitle}}</h4>
	{{ +notification.createdAt?.unix | freyaDate: 'MMMM d y, hh:mm a' }}
	<br>
	<br>
	<p>{{notification.body}}</p>
</div>
