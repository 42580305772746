import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { DashboardStore, defaultLayoutSize } from '../dashboardv2.store';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './revenue.component.scss',
  ],
})
export class RevenueComponent implements OnInit, OnDestroy {

  revenue$ = this.dashboardStore.revenueViewModel$;

  layoutSize = defaultLayoutSize;

  subs = new SubSink();

  constructor(
    private readonly dashboardStore: DashboardStore,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.dashboardStore.layoutSize$.subscribe((layoutSize) => {
      this.layoutSize = layoutSize;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
