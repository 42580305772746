import { gql } from 'graphql-tag';

export interface ListCalculateDistanceOutput {
	calculateDistance: CalculateDistanceOutput;
}

export interface CalculateDistanceOutput {
    distances?: Distance[];
	averageTime?: number;
	estimatedTime?: number;
	totalDistance?: number;
	distanceUnits?: string;
	flagTrip?: boolean;
}


export interface Distance {
    startLocation?: string;
	endLocation?: string;
	averageTime?: number;
	estimatedTime?: number;
	distance?: number;
	distanceUnits?: string;
}


export interface LocationInput {
// Street Address, eg. '123 Testing Street'
	address: string;
// The city eg. 'Winnipeg'
	city: string;
}

export interface CalculateDistanceInput {
	locations?: LocationInput[];
	startingTime?: number;
	units?: string;
}

export const calculateDistanceQuery = gql`
	query calculateDistance($locations: [LocationInput]!, $startingTime: Int, $units: String, ){
		calculateDistance(locations: $locations, startingTime: $startingTime, units: $units, ){
			    distances{
                    startLocation
                    endLocation
                    averageTime
                    estimatedTime
                    distance
                    distanceUnits
                }	
    			averageTime
				estimatedTime
				totalDistance
				distanceUnits
				flagTrip

	}		}
`;

