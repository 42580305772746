/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FeedbackService, PlusAuthenticationService } from '@karve.it/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { safeParseJSON } from 'src/app/js';
import { environment } from 'src/environments/environment';

import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { DatadogService } from '../../src/app/services/datadog.service';

export interface VersionData {
  version: string;
  status: string;
}

export interface OpenFeedbackDialogData {
  error?: Error;
  message?: string;
  feedbackType?: FeedbackValue;
  subject?: string;
}

export const feedbackValues = ['bug', 'support', 'feedback'] as const;
export type FeedbackValue = typeof feedbackValues[number];

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss', '../../dynamic-dialog-styling.scss'],
})
export class FeedbackDialogComponent implements OnInit {

  scriptURL = `https://script.google.com/macros/s/AKfycbwHoGgk6sAGq9M4ttwL3OdicbHucYAxU2rtKNyVJ8hlvG5mDzoE3G_eFhzZ-lpYknlF/exec`;

  feedbackForm = new UntypedFormGroup({
    feedbackType: new UntypedFormControl(undefined, [Validators.required]),
    subject: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(250),
    ]),
    details: new UntypedFormControl(undefined, [ Validators.maxLength(2000) ]),
  });

  formSubmitted = false;

  showReloadPage = true;

  frontendVersion = environment.version;
  backendVersion = 'unknown';

  feedbackOptions = [
      // Id's retrieved from clickup
    {
      description: `Something in the system isn't working properly`,
      label: 'Bug Report',
      value: 'e70749a5-0d17-4b43-8be2-d5d419952cb8',
      feedbackType: 'bug',
    }, {
      description: `Having trouble using parts of the application`,
      label: 'Help / Support',
      value: '70bd4a49-2bd6-4d52-8019-21d03cf94e98',
      feedbackType: 'support',
    },
    {
      description: `Suggestion for how the app can be improved`,
      label: 'Improvement Request',
      value: 'e76820df-c37d-485d-816a-3da456eb29c5',
    },
    // {
    //   description: `An operations specific request such as a document change `,
    //   label: 'Operations',
    //   value: '62f13f6d-11c2-435c-809b-19e0fbaf2b20',
    // },
    {
      description: `Any request or comment that is not reporting bugs, requesting help, or suggesting improvements.`,
      label: 'Other',
      value: 'dcf99f53-b404-430a-9c10-02fb8c617bfb',
    },
  ];

  get data() {
    return this.config.data as OpenFeedbackDialogData;
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router,
    private localNotify: FreyaNotificationsService,
    private http: HttpClient,
    private datadog: DatadogService,
    private confirm: ConfirmationService,
    private auth: PlusAuthenticationService,
  ) { }

  ngOnInit(): void {
    this.setFeedbackType();
    this.setSubject();
    this.setTemplate();

    this.formSubmitted = false;

    this.http.get('/api/version', {
      headers: {},
      responseType: 'json'
    })
      .toPromise()
      .then((res) => {
        this.backendVersion = (safeParseJSON(res) as VersionData).version;
      })
      .catch((e) => console.error('Unable to set backend version in feedback', e));
  }

  async submitFeedback() {
    this.formSubmitted = true;

    const feedback = {
      subject: this.feedbackForm.value.subject,
      description: this.feedbackForm.value.details,
      enquiry_type: this.feedbackForm.value.feedbackType,
      email: this.auth.user?.email,
      name: [this.auth.user?.givenName, this.auth.user.familyName].join(' '),
      phone: this.auth.user?.phone,
      zone: this.auth.contextedZoneId,
      backend_version: this.backendVersion,
      frontend_version: this.frontendVersion,
      current_url: location.href,
      datadog_url: this.datadog.getSessionURL(),
    };

    await fetch(this.scriptURL, {
      method: 'POST',
      mode: 'no-cors',
      body: JSON.stringify({
        feedback,
      }),
    }).then((res) => {
      // this.localNotify.success('Feedback recieved', 'Thank you for helping to make the app better');
      console.log(res.status);
    }).catch((err) => {
      this.localNotify.apolloError(`Failed to submit feedback`,err);
    });

    this.closeDialog();
    this.pollReload();
  }

  closeDialog() {
    this.ref.close();
  }

  pollReload() {
    this.confirm.confirm({
      header: 'Reload Page?',
      message: 'Sometimes a reload may help clear any errors.',
      accept: () => {
        location.reload();
      }
    });
  }

  setFeedbackType() {
    if (!this.data?.feedbackType) { return; }

    const preselectedOption = this.feedbackOptions.find((o) => o.feedbackType === this.data.feedbackType);

    if (!preselectedOption) { return; }

    this.feedbackForm.get('feedbackType').setValue(preselectedOption.value);
  }

  setTemplate() {
    const template = this.data?.message || '';

    this.feedbackForm.get('details').setValue(template);
  }

  setSubject() {
    const { subject } = this.data;

    if (!subject) { return; }

    this.feedbackForm.get('subject').setValue(subject);
  }

}
