
import { ChartOptions } from 'chart.js';

import { CreateReportMutation, Report, ReportAggregationResult } from '../../generated/graphql.generated';

import { PropType } from '../../typescript';
import { Period } from '../reports/reports.constants';

export interface DashboardData {
    [key: string]: DashboardModuleData;
}

export interface DashboardModuleData {
    [key: string]: any;
    loaded?: boolean;
    error?: string;

    amount?: number;
    lastAmount?: number;
    change?: number;
    currency?: string;
    unit?: string;
}

export type AggregationDataMapFunc = (agData: {
    report: Report;
    lastReport: Report;
    agMap: AggregationMap;
    lastAgMap: AggregationMap;
}) => DashboardData;


export interface CachedReport {
    key: string;
    period: Period;
    periodKey: string;
    // unix second time that this cache was created
    createdAt: number;
    report: PropType<CreateReportMutation, 'createReport'>;
    agMap: AggregationMap;
}


export interface AggregationMap {
    [title: string]: ReportAggregationResult;
}

export const transactionVolumeChartOptions = {
    plugins: {
        legend: {
            display: false,
        }
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'index'
    },
    scales: {
        x: {
            display: true,
            grid: {
                color: 'transparent',
            },
            ticks: {
                fontColor: '#BFC2C6'
            },
            beginAtZero: true,
        },
        y: {
            display: true,
            grid: {
                color:  'rgba(191, 194, 198, .45)',
                borderDash: [5, 10],
            },
            ticks: {
                fontColor:  '#BFC2C6',
                min: 0,
                stepSize: 1,
            },
            beginAtZero: true,
        },
    },

} as ChartOptions;

