import { escape } from 'lodash';
import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// TODO: would be nice to grab list of available right panel
// components instead of declaring them here again
const urlRewrites: {
    [ key: string ]: {
        openDetails?: string;
        prefix?: string;
    };
} = {
    user: {
        openDetails: 'users',
    },
    role: {
        openDetails: 'role',
    },
    product: {
        openDetails: 'product',
    },
    asset: {
        openDetails: 'asset',
    },
    job: {
        openDetails: 'job',
    },
    calendarevent: {
        openDetails: 'calendar-event',
        prefix: 'event',
    },
};

export function urlToUrlRewrite(
    strUrl: string,
) {
    const url = new URL(strUrl);
    const [_, name, id] = url.pathname.split('/');
    if (urlRewrites[name]) {
        return {
            name,
            id,
            urlRewrite: urlRewrites[name],
        };
    }

    return undefined;
}

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();

    const originalLink = renderer.link;

    renderer.image = (href, title, text) => (`<img src="${href}" alt="${escape(text)}" class="clickable" ></img>`);

    // eslint-disable-next-line arrow-body-style
    renderer.link = (href, title, text) => {
        const [protocol, data] = href.split('://');
        const parsedProtocol = protocol.toLowerCase().trim();
        if (parsedProtocol in urlRewrites && data) {
            const dataType = escape(parsedProtocol);
            const dataId = escape(data);
            const urlRewrite = urlRewrites[parsedProtocol];
            // const prefix = urlRewrite.
            href = `/${ dataType }/${ dataId }`;
            text = escape(text);
            // return `<a
            //     href="${ href }"
            // >${ escape(text) }</a>`;
        } else if (parsedProtocol === 'help'){
            return `<a href="${escape(data)}">${escape(text)}</a>`;
        }
        else if (parsedProtocol !== 'https') {
            return `<a>${ escape(text) }</a>`;
        }

        const res = originalLink.call(renderer, href, title, text);

        return res;
    };

    return {
        renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
        // smartLists: true,
        // smartypants: false,
    };
}
