import { Asset, BaseAssetFragment } from '../../generated/graphql.generated';

export const EVENT_STATUS_ICON_MAP = [
    {
        status: 'pending',
        icon: 'pi-minus-circle'
    },
    {
        status: 'booked',
        icon: 'pi-calendar'
    },
    {
        status: 'cancelled',
        icon: 'pi-times-circle'
    },
    {
        status: 'confirmed',
        icon: 'pi-thumbs-up'
    },
    {
        status: 'completed',
        icon: 'pi-check-circle'
    },
];

// Controls the order for the rendering of assets on the schedule
export const RESOURCE_ORDER = ['Unassigned', 'Truck', 'Delivery', 'Overnight Storage', 'Estimator'];

export const TRAVEL_TYPES = {
    dockToStart: 'dock to start',
    endToDock: 'end to dock'
};

/**
 * Sorts an array of assets by resource order and then name.
 */
export function sortAssetsForSchedule(assets: BaseAssetFragment[]) {
    assets.sort((a, b) => {
        // If the resource order matches sort by name
        if (RESOURCE_ORDER.indexOf(a.type) === RESOURCE_ORDER.indexOf(b.type)) {
            return a.name.localeCompare(b.name, 'en', { numeric: true });
        } else {
            return RESOURCE_ORDER.indexOf(a.type) - RESOURCE_ORDER.indexOf(b.type);
        }
    });
}
