<div class="card">
    <p>User Name - Role</p>
</div>

<div class="p-grid">
    <div class="p-col">
        <div class="card">
            Recent Activity
        </div>
        <div class="card">
            Account Settings
        </div>
        <div class="card">
            <p>Two Factor Authentication (2FA)</p>
            <hr>
            <app-two-factor-setup [user]="user"></app-two-factor-setup>
        </div>
    </div>
</div>
