import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { BaseReportFragment, ReportDetailsListGQL, ReportDetailsListQuery, ReportStatus, ReportType_SchemaFragment, Report_AggregationsFragment, Report_ZonesFragment } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { ButtonData, FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ReportService, ReportVariable } from '../../services/report.service';
import { MutateReportComponent } from '../mutate-report/mutate-report.component';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() report: ReportDetailsListQuery['reports']['reports'][number];

  loading = false;

  vars: ReportVariable[] = [];
  pollingTimeout: NodeJS.Timeout;
  pendingPollInterval = 1000;
  pendingPollingIndex = 1;


  private subs = new SubSink();

  constructor(
    private detailsHelper: DetailsHelperService,
    public reportService: ReportService,
    private reportDetailsService: ReportDetailsListGQL,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnDestroy();
    this.pendingPollingIndex = 1;
    this.vars = this.reportService.parseReportVariables(this.report?.variables);
    if (!this.reportService.isFullyLoaded(this.report) || !('scheduledReport' in this.report)) {
      this.fetch();
    } else if (this.reportService.isGenerating(this.report)) {
      // if we are fully loaded but generating, wait the poll interval to call fetch again
      this.pollTimeout();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    clearTimeout(this.pollingTimeout);
    this.pollingTimeout = undefined;
  }

  notifyReportFinished() {
    if (this.report.status === ReportStatus.Completed) {

      const viewButton: ButtonData = {
        label: 'View Report',
        routerLink: `/reports/static/${this.report.id}`,
        styleClass: 'p-button-info'
      };

      this.localNotify.addToast.next({
        summary: 'Report Generated',
        severity: 'success',
        data: {
          buttons: this.report?.saveData ? [ viewButton ] : undefined
        }
      });

    } else if (this.report.status === ReportStatus.Error) {
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'Could not generate report',
        detail: this.report.error,
        life: 6000,
      });
    }
  }

  pollTimeout() {
    // TODO: use subscriptions here instead
    if (this.reportService.isGenerating(this.report) && !this.pollingTimeout) {
      // Linearly ease off of the poll timeout
      const interval = this.pendingPollInterval * this.pendingPollingIndex++;
      // console.log(`Fetching after poll timeout because report was pending`);
      this.pollingTimeout = setTimeout(() => {
        this.pollingTimeout = undefined;
        this.fetch(true);
      }, interval);
    }
  }

  fetch(refetch = false) {
    if (!this.report?.id) { return; }

    const isGenerating = this.reportService.isGenerating(this.report);
    this.subs.sink = this.reportDetailsService.fetch({
      filter: {
        ids: [ this.report.id ],
        getDeleted: true,
      }
    }, { fetchPolicy: refetch ? 'network-only' : 'cache-first' }).subscribe((res) => {
      const [ report ] = res.data?.reports?.reports || [];
      if (report) {
        this.report = report;
        this.vars = this.reportService.parseReportVariables(this.report?.variables);
        console.log(this.report.status, this.pollingTimeout);

        // if we phased from generating to not generating
        if (!this.reportService.isGenerating(this.report) && isGenerating) {
          this.notifyReportFinished();
        }

        this.pollTimeout();
      }
    });
  }

  sortReportAgs() {
    if (!this.reportService.isFullyLoaded(this.report)) { return; }
    this.report.aggregations = this.report.aggregations.sort((a, b) => (a.title || a.id).localeCompare(b.title || b.id));
  }

  selectReportType() {
    const rt = this.report?.reportType;
    if (!rt) { return; }

    this.detailsHelper.detailsItem.next({
      type: 'reportType',
      item: rt,
      loaded: false,
    });

  }
  selectScheduledReport() {
    const sr = this.report?.scheduledReport;
    if (!sr) { return; }

    this.detailsHelper.detailsItem.next({
      type: 'scheduled-report',
      item: sr,
      loaded: false,
    });

  }

}
