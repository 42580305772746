import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { clone } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { BaseArtifactFragment, CreateYemboMoveGQL, ListArtifactsGQL, ListArtifactsQuery, ListArtifactsQueryVariables } from '../../../generated/graphql.generated';
import { Artifact } from '../../interfaces/artifacts';
import { FreyaHelperService } from '../../services/freya-helper.service';

@Component({
  selector: 'app-yembo-documents',
  templateUrl: './yembo-documents.component.html',
  styleUrls: ['./yembo-documents.component.scss']
})
export class YemboDocumentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() jobId: string;
  @Input() viewType: 'layout' | 'actions';
  @Input() getInventoryButtonsDisabled: boolean;

  @Output() selfSurveyTriggered = new EventEmitter<void>();
  @Output() bookSmartConsultTriggered = new EventEmitter<void>();
  @Output() yemboMoveCreatedEmitted = new EventEmitter<void>();

  visualInventory: BaseArtifactFragment = undefined;
  cubeSheet: BaseArtifactFragment = undefined;

  subs = new SubSink();

  startOnsiteAction = {
    label: 'Start Onsite',
    icon: 'pi pi-pencil',
    command: () => {
      this.startOnsite();
    },
  } as MenuItem;

  selfSurveyAction = {
    label: 'Self Survey',
    icon: 'pi pi-pencil',
    command: () => {
      this.selfSurvey();
    },
  } as MenuItem;

  bookSmartConsultAction = {
    label: 'Book Smart Consult',
    icon: 'pi pi-pencil',
    command: () => {
      this.bookSmartConsult();
    },
  } as MenuItem;

  viewVisualInventoryAction = {
    label: 'View Visual Inventory',
    icon: 'pi pi-eye',
    command: () => {
      this.viewDocument('inventory');
    },
    disabled: !Boolean(this.visualInventory),
  } as MenuItem;

  downloadVisualInventoryAction = {
    label: 'Download Visual Inventory',
    icon: 'pi pi-download',
    command: () => {
      this.downloadDocument('inventory');
    },
    disabled: !Boolean(this.visualInventory),
  } as MenuItem;

  viewCubeSheetAction = {
    label: 'View Cube Sheet',
    icon: 'pi pi-eye',
    command: () => {
      this.viewDocument('cube-sheet');
    },
    disabled: !Boolean(this.cubeSheet),
  } as MenuItem;

  downloadCubeSheetAction = {
    label: 'Download Cube Sheet',
    icon: 'pi pi-download',
    command: () => {
      this.downloadDocument('cube-sheet');
    },
    disabled: !Boolean(this.cubeSheet),
  } as MenuItem;

  inventoryActions = [{
    label: 'Inventory Actions',
    visible: true,
    items: [
      this.bookSmartConsultAction,
      this.selfSurveyAction,
      this.startOnsiteAction,
      this.viewVisualInventoryAction,
      this.downloadVisualInventoryAction,
      this.viewCubeSheetAction,
      this.downloadCubeSheetAction,
    ]
  }] as MenuItem[];

  cubeSheetQuery: QueryRef<ListArtifactsQuery>;
  visualInventoryQuery: QueryRef<ListArtifactsQuery>;

  constructor(
    // Helpers
    private freyaHelper: FreyaHelperService,
    // GQL
    private listArtifactsGQL: ListArtifactsGQL,
    private createYemboMoveGQL: CreateYemboMoveGQL,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    if (this.jobId){
      this.retrieveYemboDocuments();
      this.setActions();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentJobId = changes?.jobId?.currentValue;
    const previousJobId = changes?.jobId?.previousValue;

    //as we already call retrieveYemboDocuments onInit here we can call it only
    //when user switches between jobs (previousJobId is not undefined).
    //it will help get rid of extra listArtifacts calls
    //when user just open app and see first job and when create new job
    if (currentJobId && previousJobId && currentJobId !== previousJobId){
      this.retrieveYemboDocuments();
    }

    if (changes.getInventoryButtonsDisabled) {
      this.setActions();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Enable/Disable actions depending on if the document is available
   */
  setActions(){
    this.viewVisualInventoryAction.disabled = !Boolean(this.visualInventory);
    this.downloadVisualInventoryAction.disabled = !Boolean(this.visualInventory);
    this.viewCubeSheetAction.disabled = !Boolean(this.cubeSheet);
    this.downloadCubeSheetAction.disabled = !Boolean(this.cubeSheet);
    this.startOnsiteAction.disabled = this.getInventoryButtonsDisabled;
    this.selfSurveyAction.disabled = this.getInventoryButtonsDisabled;
    this.bookSmartConsultAction.disabled = this.getInventoryButtonsDisabled;

    this.inventoryActions = clone(this.inventoryActions);
  }

  /**
   * Create a move and open it so the estimator can start the onsite
   */
  startOnsite(){
    this.confirmationService.confirm({
      header: `Start OnSite Estimate`,
      message: `You will be redirected to yembo`,
      accept: () => {
        this.yemboMoveCreatedEmitted.emit();
        this.createYemboMoveGQL.mutate({jobId: this.jobId}).subscribe((res) => {
          window.open(res.data.createYemboMove.employeeLink, '_blank').focus();
        });
        this.setActions();
      },
    });
  }

  selfSurvey() {
    this.selfSurveyTriggered.emit();
  }

  bookSmartConsult() {
    this.bookSmartConsultTriggered.emit();
  }

  /**
   * Retrieve the Visual Inventory and Cubesheet
   */
  retrieveYemboDocuments(){
    const visualInventoryInput = {
      filter: {
        relatedObjectIds: [this.jobId],
        relatedObjectLabels: [ 'Job' ],
        hasAnyAttributes: ['inventory-pdf'],
      },
      getPrivate: true,
      sortBy: 'createdAt:DESC'
    } as ListArtifactsQueryVariables;

    const cubeSheetInput = {
      filter: {
        relatedObjectIds: [this.jobId],
        relatedObjectLabels: [ 'Job' ],
        hasAnyAttributes: ['cube-sheet'],
      },
      getPrivate: true,
      sortBy: 'createdAt:DESC'
    } as ListArtifactsQueryVariables;

    if (this.visualInventoryQuery && this.cubeSheetQuery){
      this.visualInventoryQuery.refetch(visualInventoryInput);
      this.cubeSheetQuery.refetch(cubeSheetInput);
      return;
    }

    // Visual Inventory
    this.visualInventoryQuery = this.listArtifactsGQL.watch(visualInventoryInput);
    this.subs.sink = this.visualInventoryQuery.valueChanges.subscribe((res) => {
      if (!res?.data?.artifacts?.artifacts?.length){
        this.setActions();
        return;
      }

      this.visualInventory = res.data.artifacts.artifacts[0];
      this.setActions();
    });

    // CubeSheet
    this.cubeSheetQuery = this.listArtifactsGQL.watch(cubeSheetInput);
    this.subs.sink = this.cubeSheetQuery.valueChanges.subscribe((res) => {
      if (!res?.data?.artifacts?.artifacts?.length){
        this.setActions();
        return;
      }
      this.cubeSheet = res.data.artifacts.artifacts[0];
      this.setActions();
    });
  }

  viewDocument(doc: 'inventory' | 'cube-sheet'){
    const artifact = doc === 'inventory' ? this.visualInventory : this.cubeSheet;

    this.freyaHelper.openInDialog(artifact as unknown as Artifact);
  }

  downloadDocument(doc: 'inventory' | 'cube-sheet'){
    const artifact = doc === 'inventory' ? this.visualInventory : this.cubeSheet;

    fetch(artifact.signedUrl || artifact.url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = artifact.name;
        link.click();
      });
  }

}
