import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, of, switchMap, map, filter, from, catchError, tap } from 'rxjs';
import { JobEditActions } from './jobv2-edit.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import { CreateUserInputWithRole, FindGQL, UpdateJobGQL, UpdateJobInput } from 'graphql.generated';
import { JobCreateCustomerActions } from '../../jobv2-create/jobv2-create-customer-state/jobv2-create-customer.actions';
import { JobCreateLocationsActions } from '../../jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { selectZoneIdForFind, selectTotalTimeForFindWhenEditJob } from '../../jobv2-create/jobv2-create-state/jobv2-create.selectors';
import {
	filterLocationsForAddAndRemove,
	generateCreateCustomerInput,
	generateFieldsInputFromLatestChanges,
	generateInventoryInputFromLatestChanges,
	generateJobCreateVariables,
	generateLocationsInputsFromLatestChanges,
	getFindQueryVariablesV2,
	checkAndUpdateEmptyRoomName
} from '../../jobsv2-helpers';
import { DEFAULT_EVENT_TYPE, JOB_FORM_FIELDS, eventTypeInfoMapV2 } from 'src/app/global.constants';
import { JobCreateActions } from '../../jobv2-create/jobv2-create-state/jobv2-create.actions';
import { isEmpty } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

//clean previous job inputs
//retrieve job from local storage and set to state
//needed to allow user reload page while in edit form
//and repopulate data after page reloaded without be call
export const previousJobDataCleanedAndServerCopyRestoredEffect = createEffect((
    actions$ = inject(Actions)
) => {
    return actions$.pipe(
        ofType(JobEditActions.openEditPageFromJobV2),
        map(({ jobId }) => {
			const storedState = localStorage.getItem('last_edited_jobs_state');
			const retrievedState = storedState ? JSON.parse(storedState) : undefined;
			const storedServerCopy = retrievedState?.[0]?.[jobId]?.job || undefined;
			return JobEditActions.cleanPreviousJobData({ serverCopy: storedServerCopy });
		})
    );
}, { functional: true, dispatch: true });

//transfer server copy data to inputs
export const jobDataTransferredToState = createEffect((
    actions$ = inject(Actions),
    store = inject(Store),
) => {
	return actions$.pipe(
	  ofType(JobEditActions.cleanPreviousJobData),
      withLatestFrom(
		store.select(jobToolFeature.selectJob),
	),
	  switchMap(([action, jobInfo]) => {
		const actions = [
		  JobEditActions.transferJobStateForEdit({ jobInfo }),
		];

		return of(...actions);
	  })
	);
  }, { functional: true, dispatch: true });

//apply unsaved changes from local storage
export const applyUnsavedChangesWhenEditJob = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(JobEditActions.transferJobStateForEdit),
		withLatestFrom(store.select(jobToolFeature.selectJob)),
		switchMap(([action, job]) => {
			const storedState = localStorage.getItem('last_edited_jobs_state');
			const retrievedState = storedState ? JSON.parse(storedState) : undefined;

			const jobState = retrievedState?.find(item => item[job?.id]);
			const changes = jobState ? jobState[job?.id] || [] : [];

			return of(JobCreateActions.applyChangesFromStorage({ retrievedState: changes, changes: 'edit' }));
		})
	);
}, { functional: true, dispatch: true });

export const filterAddableLocationsForEditedJobEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(JobCreateActions.applyChangesFromStorage),
		withLatestFrom(
			store.select(jobToolFeature.selectAddableAdditionalLocations),
			store.select(jobToolFeature.selectLocationsInputs)
		),
		switchMap(([action, addableAdditionalLocations, locationsInputs]) => {
			const filteredLocations = addableAdditionalLocations.filter(location => {
				return !locationsInputs.hasOwnProperty(location.key);
			});
			return of(
				JobEditActions.removeJobLocationsFromAddableList({
					addableAdditionalLocations: filteredLocations
				})
			);
		})
	);
}, { functional: true, dispatch: true });

//find available time slots
export const availableTimesFoundWhenEditJob = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const findGQL = inject(FindGQL);

	return actions$.pipe(
	  ofType(
		JobCreateCustomerActions.selectMovingDate,
		JobCreateLocationsActions.locationResolveServiceAreaSuccess,
		JobCreateLocationsActions.locationSelectAreaManually,
		JobCreateLocationsActions.locationSetAutocomplete, // reretrieve times after locations set as they affect travelTime and totalTime
		JobCreateLocationsActions.locationSetManually,
	  ),
	  withLatestFrom(
		store.select(selectTotalTimeForFindWhenEditJob),
		store.select(selectZoneIdForFind),
		store.select(jobToolFeature.selectJobInput),
		store.select(jobToolFeature.selectJobFormMode),
	  ),
	  filter(([action, _, __, ___, mode]) => mode === 'edit'), // Proceed only if mode is 'edit'
	  filter(([_, __, zoneId, jobInput]) => !!zoneId && !!jobInput?.timeline), // Proceed only if both zoneId and timeline are defined
	  switchMap(([action, totalTime, zoneId, jobInput, mode]) => {
		const timeline = jobInput.timeline;
		const variables = getFindQueryVariablesV2(timeline, totalTime, DEFAULT_EVENT_TYPE, zoneId);

		return from(findGQL.fetch(variables)).pipe(
		  map(response => JobCreateActions.findTimesSuccess({
			result: response})),
		  catchError(error => of(JobCreateActions.findTimesError({ error })))
		);
	  })
	);
  }, { functional: true, dispatch: true });

//update customer
//handled in updateExistingCustomerEffect in jobv2-create.effects.ts

//Update job
export const updateJobEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(JobEditActions.updateFormSaved),
		withLatestFrom(
			store.select(jobToolFeature.selectChanges),
			store.select(jobToolFeature.selectJob),
			store.select(jobToolFeature.selectCustomerRole),
			store.select(jobToolFeature.selectYemboStatus),
		),
		map(([result, changes, serverCopy, customerRoleId, yemboStatus]) => {
			const { jobType, jobOrigin, resolvedServiceArea, ...recentChanges } = generateJobCreateVariables(changes);
			const locations = generateLocationsInputsFromLatestChanges(changes);
			const yemboStatusForSaving = yemboStatus.yemboStatusForSaving;
			const selectedTimeSlot = changes.find(item => item.fieldName === JOB_FORM_FIELDS.selectedTimeSlot);

			const { forRemove, forAdd } = filterLocationsForAddAndRemove(locations as any);

			const addLocationsInput = Object.values(forAdd).map(item => ({
				locationId: item.id,
				locationType: item.locationType
			}));

			const removeLocationsInput = Object.values(forRemove).map(item => item?.id);

			const fieldsChanges = generateFieldsInputFromLatestChanges(changes);
			const { inventory } = generateInventoryInputFromLatestChanges(changes);
			const selectedExistingCustomer = changes.find(
				item => item.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer);
			const createCustomerVariables = generateCreateCustomerInput(changes);
			const initialCustomerCopy = changes.find(
				item => item.fieldName === JOB_FORM_FIELDS.initialCustomerCopy);
			const editJobVariables: UpdateJobInput = {
				jobId: undefined, //is added below,
				metadata: {
					jobType,
					jobOrigin,
				},
				fields: {},
				addLocations: addLocationsInput,
				removeLocations: removeLocationsInput,
				...recentChanges,
			}

			let fieldsForQuery = [];
			if (resolvedServiceArea) {
				editJobVariables.setZone = resolvedServiceArea?.id;
			}

			if (!isEmpty(fieldsChanges)) {
				fieldsForQuery = Object.keys(fieldsChanges).map(key => ({
					fieldName: key,
					value: fieldsChanges[key]
				}));
			}

			if (!isEmpty(inventory)) {
				const withFilledRoomNames = checkAndUpdateEmptyRoomName(inventory);
				const inventoryForQuery = {
					fieldName: "jobs.inventory",
					value: JSON.stringify(withFilledRoomNames)
				}

				fieldsForQuery.push(inventoryForQuery);
			}

			if (fieldsForQuery?.length) {
				editJobVariables.fields = {
					objectLabel: "Job",
					fields: fieldsForQuery,
				};
			}

			if (selectedExistingCustomer) {
				const previousCustomerId = serverCopy?.users?.find(u => u.role === 'customer')?.user?.id;
				const newCustomerId = selectedExistingCustomer?.value?.id;
				editJobVariables.removeUsers = [
					{
						role: 'customer',
						userId: previousCustomerId,
					}
				];
				editJobVariables.addUsers = [
					{
						role: 'customer',
						userId: newCustomerId,
					}
				]
			}

			//original user was deselected and we create new one
			if(!initialCustomerCopy && !isEmpty(createCustomerVariables)) {
				const previousCustomerId = serverCopy?.users?.find(u => u.role === 'customer')?.user?.id;

				const customerWithRole = {
					...createCustomerVariables,
					role: customerRoleId,
				}
				editJobVariables.newCustomers = [customerWithRole as CreateUserInputWithRole];

				editJobVariables.removeUsers = [
					{
						role: 'customer',
						userId: previousCustomerId,
					}
				];
			}

			if (yemboStatusForSaving.smartConsult) {
				const eventVariables = {
					sequentialOrder: 0.5,
					status: 'required',
					type: eventTypeInfoMapV2.virtualEstimate.value,
					title: eventTypeInfoMapV2.virtualEstimate.name,
				};

				editJobVariables.addEvents = Array.isArray(editJobVariables.addEvents)
					? [...editJobVariables.addEvents, eventVariables]
					: [eventVariables];
			}

			if (yemboStatusForSaving.selfSurvey) {
				editJobVariables.sendSelfSurvey = true;
			}

			if (selectedTimeSlot) {
				const eventVariables = {
					sequentialOrder: 1,
					status: 'required',
					type: eventTypeInfoMapV2.moving.value,
					title: eventTypeInfoMapV2.moving.name,
					start: selectedTimeSlot?.value,
				}
				editJobVariables.addEvents = Array.isArray(editJobVariables.addEvents)
					? [...editJobVariables.addEvents, eventVariables]
					: [eventVariables];
			}

			return JobEditActions.updateJob({ updateJobInput: { ...editJobVariables, jobId: result.jobId } });
		}),
	);
}, { functional: true, dispatch: true });

export const jobBeingUpdated = createEffect(() => {
	const actions$ = inject(Actions);
	const updateJobGQL = inject(UpdateJobGQL);

	return actions$.pipe(
	  ofType(JobEditActions.updateJob),
	  switchMap((action) => {
		return from(updateJobGQL.mutate({
			updateJobs: [action.updateJobInput]
		})).pipe(
		  map(response => {
			return JobEditActions.updateJobSuccess();
		  }),
		  catchError(error => of(JobEditActions.updateJobError({ error })))
		);
	  })
	);
  }, { functional: true, dispatch: true });

//redirect to jobv2 page
export const redirectAfterJobCreatedSuccess = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const router = inject(Router);
	const route = inject(ActivatedRoute);

	return actions$.pipe(
		ofType(JobEditActions.updateJobSuccess),
		withLatestFrom(
		  store.select(jobToolFeature.selectJobInput),
			store.select(jobToolFeature.selectZoneToContextInto),
		),
		tap(([ _action, jobInput, zone ]) => {
			const redirectTo = `/jobs/${jobInput.id}`;
			router.navigate([redirectTo], {
			  relativeTo: route,
				queryParams: zone ? { zone } : {},
			});
		})
	);
}, { functional: true, dispatch: false });

