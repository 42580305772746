<div class="header-with-button">
    <div>
        <h4><i [class]="icon"></i> Price</h4>
        <app-object-state
            [object]="price"
            objectType="price"
            [colorful]="true"
            [loading]="loading"
            ></app-object-state>
    </div>
    <div>
        <button
            pButton
            pRipple
            label="Actions"
            *hasPermission="['prices.update']"
            (click)="priceMenu.toggle($event)"
            icon="pi pi-angle-down"
            data-cy="price-actions-button"
        ></button>
        <p-menu
            #priceMenu
            [popup]="true"
            appendTo="body"
            [model]="priceActions"
        ></p-menu>
    </div>
</div>

<div id="price-info" class="card">
    <h5>
        {{ price.name }}
        <i
            *ngIf="price.active"
            class="pi pi-check"
            [class.success-text]="price.active"
        ></i>
        <i
            *ngIf="!price.active"
            class="pi pi-ban"
            [class.error-text]="price.active"
        ></i>
    </h5>
    <ng-container *ngIf="product">
        <p>Product: <a class="clickable" (click)="openProduct()">{{ product.name }}</a></p>
        <p>{{ price.zone?.name }}</p>
        <p *ngIf="price.priceType !== 'percentage'">
        {{ price.amount / 100 | currency : price.currency }}
        </p>
        <p *ngIf="price.priceType === 'percentage'">{{ price.amount }}%</p>
        <p>Created: {{ price.createdAt | freyaDate }}</p>
    </ng-container>
</div>

<h5>Taxes</h5>
<div class="card">
    <div *ngIf="!loading; else loadingBar">
		<div>

			<li *ngFor="let tax of price.taxes">
				<a 
					class="clickable"
					(click)="openTax(tax)"
				>
					{{ tax.name }} ({{ tax.percentage }}%)
				</a>
			</li>
		</div>
        <p *ngIf="!price.taxes?.length">No Taxes Assigned</p>
    </div>
</div>

<h5>Costs</h5>
    <div *ngIf="!loading; else loadingBar">
		<p-accordion *ngIf="price.expensesV2?.length; else noExpensesMessage" [multiple]="true">
            <p-accordionTab *ngFor="let expense of price?.expensesV2" [header]="expense.expense.name + ' (' + (expense.unitCost / 100 | currency: price.currency) + ')'">
                <p>
                    {{ expense.expense.description}}
                </p>
            </p-accordionTab>
        </p-accordion>
        <ng-template #noExpensesMessage>
            <p>No Costs Assigned</p>
        </ng-template>
    </div>

<ng-template #loadingBar>
    <p-skeleton width="100%" height="24px"></p-skeleton>
</ng-template>
