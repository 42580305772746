import { gql } from 'graphql-tag';

import { ZoneDirection } from './common.gql';
import { Zone } from './zones';

export interface ListAvailabilityOutput {
    availability: DayAvailability[];
}

export interface ListAvailabilityTemplatesOutput {
    availabilityTemplates: AvailabilityTemplate[];
}

export interface ListAvailabilityConfigurationOutput {
    availabilityConfigurations: [AvailabilityConfiguration];
}

export interface CreateAvailabilityTemplateOutput {
    createAvailabilityTemplate: AvailabilityTemplate;
}

export interface UpdateAvailabilityTemplateOutput {
    updateAvailabilityTemplate: boolean;
}

export interface DeleteAvailabilityTemplatesOutput {
    deleteAvailabilityTemplates: boolean;
}

export interface CreateAvailabilityBlockOutput {
    createAvailabilityBlock: AvailabilityBlock[];
}

export interface UpdateAvailabilityBlockOutput {
    updateAvailabilityBlock: boolean;
}

export interface DeleteAvailabilityBlocksOutput {
    deleteAvailabilityBlocks: boolean;
}

export interface CreateAvailabilityConfigurationOutput {
    createAvailabilityConfiguration: AvailabilityConfiguration;
}

export interface UpdateAvailabilityConfigurationOutput {
    updateAvailabilityConfiguration: boolean;
}

export interface AvailabilitySearch extends ZoneDirection {
    // Use international date format 'YYYY-MM-DD'
    startDate: string;
    // Use international date format 'YYYY-MM-DD, Set it the same as startDate to only query one day'
    endDate: string;
    objectId: string;
}


export interface AvailabilityTemplateInput extends ZoneDirection {
    ids: string[];
    owner?: string;
}


export interface CreateAvailabilityTemplateInput {
    blocks?: CreateAvailabilityBlockInput;
    // timezone: string;
    name: string;
}


export interface UpdateAvailabilityTemplateInput {
    id: string;
    // timezone?: string;
    name?: string;
}


export interface CreateAvailabilityBlockInput {
    // The ID of the template that this is going on.
    template?: string;
    blocks: AvailabilityBlockInput[];
}


export interface AvailabilityBlockInput {
    start?: number;
    end?: number;
}


export interface UpdateAvailabilityBlockInput {
    id: string;
    start?: number;
    end?: number;
}


export interface UpdateAvailabilityConfigurationInput {
    objectId: string;
    edit?: EditConfiguration;
    setTemplates?: SetTemplateInput[];
    // An array of the realtionship Ids pertaining to the config
    removeTemplates?: string[];
    addOverrides?: OverrideInput[];
    removeOverrides?: OverrideInput[];
}


export interface EditConfiguration {
    // The length in seconds of one availability
    availabilityInterval?: number;
}


export interface SetTemplateInput {
    templateId: string;
    // If you want to edit an existing relationship instead of create a new one
    relationshipId?: string;
    //Valid Values?: 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' """;
    dayOfTheWeek?: string;
    // Format as YYYY-MM-DD
    startsOn?: string;
    // Format as YYYY-MM-DD, leave undefined to make it indefinite
    endsOn?: string;
    // This will automatically end a template that it overlaps
    endOverlappingTemplate?: boolean;
    // The zones that this availability applies to
    allowedZones?: boolean;
}


export interface OverrideInput {
    templateId: string;
    // Universal date formate eg. '2021-12-18'
    date: string;
    // The zones that this availability applies to, defaults to all if not provided
    allowedZones?: boolean;
}


export interface DayAvailability {
    date?: string;
    availability?: Availability[];
}


export interface Availability {
    start: number;
    end: number;
}

export interface AvailabilityOutput {
    objectId: string;
    dayAvailability: DayAvailability[];
}

export interface AvailabilitiesInput {
    objectIds: string[];
    startDate: string;
    endDate: string;
}

export interface AvailabilitiesFunctionOutput {
    availabilities: AvailabilityOutput;
}

export interface AvailabilityConfiguration {
    objectId?: string;
    timezone?: string;
    availabilityInterval?: number;
    sunday?: AppliedAvailabilityTemplate[];
    monday?: AppliedAvailabilityTemplate[];
    tuesday?: AppliedAvailabilityTemplate[];
    wednesday?: AppliedAvailabilityTemplate[];
    thursday?: AppliedAvailabilityTemplate[];
    friday?: AppliedAvailabilityTemplate[];
    saturday?: AppliedAvailabilityTemplate[];
    overrides?: OverrideAvailabilityTemplate[];
}


export interface AppliedAvailabilityTemplate {
    id: string;
    // Nickname for the Template eg. 'The Ol 9-5'
    name?: string;
    // The name of the timezone this template uses eg. 'America/Winnipeg''
    // timezone?: string;
    availabilityBlocks: AvailabilityBlock[];
    // The id of the relationship between the template and the config
    relationshipId?: string;
    // Date the template takes effect, Format?: 'YYYY-MM-DD' """;
    startsOn?: string;
    // Date the template is no longer in effect, Format?: 'YYYY-MM-DD' """;
    endsOn?: string;
    // When this template was applied
    createdAt?: number;
    // Which zones this availability applies to
    allowedZones?: Zone[];
}


export interface AvailabilityTemplate {
    id: string;
    // Nickname for the Template eg. 'The Ol 9-5'
    name?: string;
    // The name of the timezone this template uses eg. 'America/Winnipeg''
    availabilityBlocks: AvailabilityBlock[];
}


export interface OverrideAvailabilityTemplate {
    id: string;
    // Nickname for the Template eg. 'The Ol 9-5'
    name?: string;
    // The name of the timezone this template uses eg. 'America/Winnipeg''
    // timezone?: string;
    availabilityBlocks: AvailabilityBlock[];
    // The Date this override is applied
    date?: string;
}


export interface AvailabilityBlock {
    id: string;
    // The time of the day in seconds to start the block eg. 3600 = 1am
    start?: number;
    // The time of the day in seconds to end the block eg. 3600 = 1am
    end?: number;
}

export interface AvailabilityInput {
    search?: AvailabilitySearch;
}



export interface AvailabilityTemplatesFunctionInput {
    input?: AvailabilityTemplateInput;
}



export interface AvailabilityConfigurationInput extends ZoneDirection {
    objectIds?: string[];
    startDate?: string;
    endDate?: string;
}



export interface CreateAvailabilityTemplateFunctionInput {
    input?: CreateAvailabilityTemplateInput;
}



export interface UpdateAvailabilityTemplateFunctionInput {
    input?: UpdateAvailabilityTemplateInput;
}



export interface DeleteAvailabilityTemplatesInput {
    ids?: string[];
}



export interface CreateAvailabilityBlockFunctionInput {
    input?: CreateAvailabilityBlockInput;
}



export interface UpdateAvailabilityBlockFunctionInput {
    input?: UpdateAvailabilityBlockInput;
}



export interface DeleteAvailabilityBlocksInput {
    ids?: string[];
}



export interface CreateAvailabilityConfigurationInput {
    objectId?: string;
    zone?: string;
    timezone?: string;
}



export interface UpdateAvailabilityConfigurationFunctionInput {
    input?: UpdateAvailabilityConfigurationInput;
}

export const appliedTemplateProperties = `
    id
    name
    availabilityBlocks {
        id
        start
        end
    }
    relationshipId
    startsOn
    endsOn
    createdAt
    allowedZones {
        id
        name
        color
    }
`;

export const availabilityBlockProperties = `
    id
    start
    end
`;

export const availabilityQuery = gql`
	query availability($search: AvailabilitySearch!, ){
		availability(search: $search, ){
			    date
			    availability{ 
                    start
                    end
                }
	}		}
`;

export const availabilitiesQuery = gql`
    query availabilties($objectIds: [String!]!, $startDate: String!, $endDate: String!){
        availabilities(objectIds: $objectIds, startDate: $startDate, endDate: $endDate){
            objectId
            dayAvailability {
            date
                availability{
                    start
                    end
                }
            }
        }
    }
`;

export const availabilityTemplatesQuery = gql`
	query availabilityTemplates($input: AvailabilityTemplateInput, ){
		availabilityTemplates(input: $input, ){
			    id
			    name
			    availabilityBlocks{ ${availabilityBlockProperties} }
	}		}
`;


export const availabilityConfigurationQuery = gql`
	query availabilityConfiguration($objectIds: [String], $startDate: String, $endDate: String){
		availabilityConfigurations(objectIds: $objectIds, startDate: $startDate, endDate: $endDate ){
			    objectId
			    timezone
			    availabilityInterval
			    sunday{ ${appliedTemplateProperties} }			    
                monday{ ${appliedTemplateProperties} }			    
                tuesday{ ${appliedTemplateProperties} }			    
                wednesday{ ${appliedTemplateProperties} }			    
                thursday{${appliedTemplateProperties} }			    
                friday{ ${appliedTemplateProperties} }			    
                saturday{ ${appliedTemplateProperties} }			    
                overrides{
                    id
                    name
                    availabilityBlocks {
                        id
                        start
                        end
                    }
                    date
                }
	}		}
`;


export const createAvailabilityTemplateMutation = gql`
	mutation createAvailabilityTemplate($input: CreateAvailabilityTemplateInput!, ){
		createAvailabilityTemplate(input: $input, ){
			    id
			    name
			    availabilityBlocks{ ${availabilityBlockProperties} }
	}		}
`;


export const updateAvailabilityTemplateMutation = gql`
	mutation updateAvailabilityTemplate($input: UpdateAvailabilityTemplateInput!, ){
		updateAvailabilityTemplate(input: $input, ) 

	}
`;


export const deleteAvailabilityTemplatesMutation = gql`
	mutation deleteAvailabilityTemplates($ids: [String!]!, ){
		deleteAvailabilityTemplates(ids: $ids, ) 

	}
`;


export const createAvailabilityBlockMutation = gql`
	mutation createAvailabilityBlock($input: CreateAvailabilityBlockInput!, ){
		createAvailabilityBlock(input: $input, ){
			    id
			    start
			    end

	}		}
`;


export const updateAvailabilityBlockMutation = gql`
	mutation updateAvailabilityBlock($input: UpdateAvailabilityBlockInput!, ){
		updateAvailabilityBlock(input: $input, ) 

	}
`;


export const deleteAvailabilityBlocksMutation = gql`
	mutation deleteAvailabilityBlocks($ids: [String!]!, ){
		deleteAvailabilityBlocks(ids: $ids, ) 

	}
`;


export const createAvailabilityConfigurationMutation = gql`
	mutation createAvailabilityConfiguration($objectId: String, $zone: String, $timezone: String ){
		createAvailabilityConfiguration(objectId: $objectId, zone: $zone, timezone: $timezone){
			    objectId
			    availabilityInterval
	}		}
`;


export const updateAvailabilityConfigurationMutation = gql`
	mutation updateAvailabilityConfiguration($input: UpdateAvailabilityConfigurationInput!, ){
		updateAvailabilityConfiguration(input: $input, ) 

	}
`;

