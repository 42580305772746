<div *ngIf="artifact">
    <div class="p-d-flex p-jc-between p-py-3">
        <div>
            <h4>
                <i 
                [class]="(artifactType | artifactTypeIcon) + ' large-icon'"
                ></i>
                {{artifactType}}
                <div class="p-d-flex p-ai-center">
                    <p-chip
                        *ngIf="artifact?.attributes?.includes('signable')"
                        label="Signable"
                        styleClass="p-mr-2"
                    ></p-chip>
                    <p-chip
                        *ngIf="isPandadoc"
                        label="Pandadoc"
                        styleClass="p-mr-2"
                    ></p-chip>
                    <app-object-state
                        [object]="artifact"
                        [objectType]="artifactType"
                        [colorful]="true"
                    ></app-object-state>
                </div>
            </h4>
        </div>
        <div>
            <button
                pButton
                pRipple
                label="Actions"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="artifactActions"
            >
        </p-menu>
        </div>
    </div>
    <p-message *ngIf="!artifact?.fileSize" severity="warn" text="Document may still be generating"></p-message>  
    <div class="card">
        <p class="break-text">Name: {{artifact?.name}}</p>
        <p>Size: {{artifact?.fileSize | fileSize}}</p>
        <p>Public: {{artifact?.public ? 'Yes' : 'No'}}</p>
        <p>Created: {{artifact?.createdAt | freyaDate}}</p>
        <p *ngIf="isPandadoc">
            Status:
            {{ ([artifact] | attributeFilter : { includesAny: ['status::draft']})?.length ? 'draft' : 'sent' }}
        </p>
    </div>

</div>
<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="24px"
    ></p-skeleton>
</ng-template>
