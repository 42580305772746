import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsReadyService {

  constructor(
    private HttpClient: HttpClient,
  ) { }

  async isReady() {
    return lastValueFrom(
      this.HttpClient.get('/api/ready', {
        responseType: 'text',
      }).pipe(
        map((res) => {
          return true;
        }),
        catchError((err) => {
          return of(false);
        })
      )
    )
  }
}
