import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { TagsService } from '@karve.it/features';
import { ListTagsOutput, Tag, TAG_TYPES } from '@karve.it/interfaces/tag';
import { QueryRef } from 'apollo-angular';

import { SelectItem } from 'primeng/api';
import { merge } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { GetConfigValuesGQL, Job, JobPageListJobsQueryVariables, JobsFilter, JobsPageCountGQL, JobsPageGQL, JobsPageQuery, JobsPageQueryVariables, JobWithUsersAndTagsFragment, ObjectCalendarEventsFilterInput, ZoneDir } from '../../generated/graphql.generated';

import { MenuService } from '../base/menu/app.menu.service';
import { FilterSelectComponent } from '../custom-inputs/filter-select/filter-select.component';
import { DEFAULT_JOB_CLOSED_REASONS, eventTypeInfoMap, JOB_STAGES, JOB_STATES, pagination, PRESET_FILTERS } from '../global.constants';
import { safeParseJSON, strToTitleCase } from '../js';
import { convertDollarsToCents } from '../lib.ts/currency.util';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaMutateService } from '../services/freya-mutate.service';
import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { JobParams, ParamTypes, QueryParamsService } from '../shared/query-params.service';
import { DataError, parseGraphqlErrors } from '../utilities/errors.util';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

export interface JobWithError extends JobWithUsersAndTagsFragment {
  error: DataError;
}

export interface JobClosureReason {
  id: string;
  title: string;
  stages: string[];
}

type DateRangeSelection = [ Date | undefined, Date | undefined ];

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('searchInput', { static: false }) searchInput!: ElementRef;
  @ViewChild('filterSelect') filterSelect!: FilterSelectComponent;

  // Filter Variables
  @Input() filter: JobsFilter = {};
  @Input() pagination = pagination;

  presetFilters = PRESET_FILTERS.jobs;

  /**
   * When true, makes sure the search form values remain synced to any relevant query params,
   * i.e., when the form values change (e.g., user types 'Toledo' into the search box),
   * it updates the query params ('search=Toledo' is added to the URL), and vice versa
   *
   * Turn it off when embedding this into another component
   */
  @Input() syncToQueryParams = true;

  // Modify Component Layout
  @Input() tableTitle: string = undefined;
  @Input() showHeaderCard = true;
  @Input() activeData = {
    stage: true,
    tags: true,
    state: true,
    status: false,
    code: true,
    customer: true,
    total: true,
    amountDue: false,
    date: true,
    firstBookedRevenueGeneratingEvent: true,
    timeline: true,
    link: true,
  };

  subs = new SubSink();

  // Used to decide if the "save filter" button should be disabled
  isFilterConfigurationNew = false;

  stages = [
    { option: 'any', label: 'Any' },
    ...Object.values(JOB_STAGES).map((s) => ({
      option: s.name,
      label: strToTitleCase(s.name)
    }))
  ];

  states = [
    { option: 'any', label: 'Any' },
    ...JOB_STATES.map((s) => ({
      option: s,
      label: strToTitleCase(s),
    }))
  ];

  closedReasons = [];

  tags: Tag[] = [];
  passedTags: string;

  // Component Variables
  jobs: JobWithError[] = [];

  // Job Query Variables
  jobsQueryRef!: QueryRef<JobsPageQuery, JobPageListJobsQueryVariables>;
  jobsQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: true,
    hasMore: true,
    total: undefined,
    mergeNextResults: false,
    isFirstLoad: true,
  };

  jobCountLoading = false;

  jobPlaceholderCount = Array.from({ length: this.jobsQH.limit });

  jobPlaceholderColumnCount = Array.from({ length: 0 });

  // An array of the indexes which contain errors
  jobDataErrors: DataError[] = [];

  jobSearchForm = new FormGroup({
    search: new FormControl(''),
    stage: new FormControl<string>('any'),
    state: new FormControl<string>('any'),
    tagIds: new FormControl<string[]>([]),
    created: new FormControl<DateRangeSelection>(undefined),
    lastUpdated: new FormControl<DateRangeSelection>(undefined),
    bookingDates: new FormControl<DateRangeSelection>(undefined),
    closedAt: new FormControl<DateRangeSelection>(undefined),
    bookedEvents: new FormControl<any>(undefined),
    transactionMin: new FormControl<number>(undefined),
    transactionMax: new FormControl<number>(undefined),
    closedReason: new FormControl<string>(undefined),
    archivedAt: new FormControl<DateRangeSelection>(undefined),
    sources: new FormControl<string[]>([]),
  });

  jobSearchFormDefaults = this.jobSearchForm.getRawValue();

  // Tag Query Variables
  tagQueryRef: QueryRef<ListTagsOutput>;

  // Filter Variables
  filtersCollapsed = true;

  eventTypes: SelectItem[] = Object.values(eventTypeInfoMap)
    .map((et) => ({
      value: et.value,
      label: et.name
    }));

  currencyFilters = {
    currency: 'USD',
    locale: 'en-US'
  };

  isJobV2Enabled = false;

  constructor(
    private detailsHelper: DetailsHelperService,
    private jobsListGQL: JobsPageGQL,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private tagService: TagsService,
    public responsiveHelper: ResponsiveHelperService,
    private router: Router,
    private queryParams: QueryParamsService,
    private freyaMutate: FreyaMutateService,
    private getConfigGQL: GetConfigValuesGQL,
    public freyaHelper: FreyaHelperService,
    private jobsPageCountGQL: JobsPageCountGQL,
    private menuService: MenuService,
    private elementRef: ElementRef,
    ) { }

  ngOnInit(): void {

    this.isJobV2Enabled = this.menuService.isJobV2Enabled;

    this.primeJobSearchForm();

    this.jobs = [];
    this.closedReasons = [];

    this.watchObjectUpdates();
    this.watchJobSearchFormValueChanges();

    this.getConfigValues();

    this.watchQueryParams();
    this.setJobPlaceholderColumnCount();

    if (!this.syncToQueryParams) {
      this.retrieveJobs();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
    this.elementRef.nativeElement.scrollIntoView(true);
  }

  ngOnChanges() {
    this.jobsQH.limit = this.pagination.defaultNumberOfItems;
  }

  generateLink(jobId: string): any[] {
    return this.isJobV2Enabled ? ['/jobs', jobId, 'overview'] : ['/job', jobId];
  }

  /**
   * Makes sure to refetch jobs when users and jobs are updated
   */
  watchObjectUpdates() {
    this.subs.sink = this.detailsHelper.getObjectUpdates(['User', 'Jobs']).subscribe(() => {
      this.jobsQueryRef.refetch();
    });
  }

  /**
   * Executes a different action every time the form is updated,
   * depending on the value of {@link syncToQueryParams}
   *
   * If {@link syncToQueryParams} is set to true, it will update the query params,
   * which should in turn prompt the component to refetch jobs.
   *
   * If false, it will refetch jobs *directly*,
   * without updating the query params.
   */
  watchJobSearchFormValueChanges() {

    /**
     * generate an array of observables for each
     * form control. We can't use jobSearchForm.valueChanges
     * because we need to modify behaviour for
     * search and state.
     */
    const valueChanges = Object.keys(this.jobSearchForm.controls)
      .map((key) => {

        let time = 250;
        if (key === 'search') {
          time = 750;
        }
        const modifiers = [];
        if (key === 'state') {
          modifiers.push(tap((state: any) => this.toggleStateRelatedControls(state, false)));
        }

        return this.jobSearchForm.controls[key].valueChanges.pipe(
          ...modifiers,
          debounceTime(time)
        )
      });


    this.subs.sink = merge(...valueChanges).pipe(
      debounceTime(10),
    ).subscribe((change) => {
      if (this.syncToQueryParams) {
        this.updateQueryParams();
      } else {
        this.updateFilterSelect();
        this.jobsQH.mergeNextResults = false;
        this.jobsQH.skip = 0;
        this.jobsQH.total = undefined;
        this.retrieveJobs();
      }
    });
  }

  /**
   * Formats the values of an Angular form so they can be embedded in the URL as query params.
   *
   * @param formValues An object containing the values of an Angular formGrop.
   * @returns The *same* object, stripped of any empty values,
   * and with the remaining values converted into a URL-friendly format.
   */
  formatSearchFormValues(formValues: AbstractControl['value']): JobParams {

    // Tell the quer params service how you want each control formatted
    const customControls = {
      dateRange: { controls: [ 'created', 'lastUpdated', 'bookingDates', 'closedAt', 'archivedAt'] },
      tagArray: { controls: ['tagIds'], tags: this.tags }
    };

    return this.queryParams.formatSearchFormValues(formValues, customControls);
  }

  /**
   * Embeds the current values of the search form into the URL as query params
   * 
   * Will then call "watchQueryParams" if "syncToQueryParams" is true
   */
  updateQueryParams() {
    this.router.navigate([], {
      queryParams: this.formatSearchFormValues(this.jobSearchForm.value)
    });
    // After navigate, see "watchQueryParams" below
  }

  /**
   * Updates the search form and executes a new search query every time the
   * query params change.
   */
  watchQueryParams() {
    if (!this.syncToQueryParams) { return; }

    this.subs.sink = this.route.queryParams
    .pipe(
      filter(params => 'zone' in params),
      debounceTime(10),
    )
    .subscribe(async (params) => {

      const { zone, ...filterParams } = params;

      this.queryParams.stripEmptyParams(filterParams);

      this.searchWithFilter(filterParams);
    });
  }
  /**
   * Applies the selected filter configuration.
   *
   * If {@link syncToQueryParams} is true, it adds the selected query params to the current URL,
   * which prompts the component to execute a new search.
   *
   * If false, it simply updates the search form and executes a new search,
   * without modifying the current URL.
   *
   * @param jobFilter The filter configuration selected from the dropdown.
   */
  updateFilter(jobFilter: JobParams | undefined) {
    if (!jobFilter) { return; }
    if (this.syncToQueryParams) {
      this.router.navigate([], { queryParams: jobFilter });
    } else {
      this.searchWithFilter(jobFilter);
    }
  }

  /**
   * Updates the search with the values from a given `jobFilter` and executes a new job search query with said values.
   *
   * @param jobFilter The query params extracted from the current URL.
   */
  searchWithFilter(jobFilter: JobParams) {
    this.jobsQH.mergeNextResults = false;
    this.jobsQH.skip = 0;
    this.jobsQH.total = undefined;
    this.updateForm(jobFilter);
    this.updateFilterSelect()
    this.retrieveJobs();
  }

  updateFilterSelect() {
    this.filterSelect.setFilterValue(
      this.formatSearchFormValues(this.jobSearchForm.value)
    );
  }

  /**
   * Updates the search form *silently*,
   * (i.e., without making the form controls' `valueChanges` observable fire,
   * which is important to avoid an infinite loop).
   *
   * @param jobParams The query params extracted from the current URL
   */
  updateForm(jobParams: JobParams) {
    // Tags param must be handled differently as we need to wait for tags to load before updating the form
    const { tags, ...otherParams } = jobParams;

    // Tell the queryParams service how we want to parse each param
    const paramTypes: ParamTypes = {
      num: { params: ['transactionMin', 'transacionMax'] },
      dateArr: { params: ['created', 'lastUpdated', 'bookingDates', 'closedAt', 'archivedAt'] },
      arr: { params: ['bookedEvents', 'sources' ] },
    };

    const updatedFormValue = {
      ...this.jobSearchFormDefaults,
      ...this.queryParams.parseQueryParams(otherParams, paramTypes),
    };
  
    // this.jobSearchForm.
    this.jobSearchForm.setValue(updatedFormValue, {
      emitEvent: false,
    });

    this.toggleStateRelatedControls(this.jobSearchForm.controls.state.value, false);

    // Handle tags
    if (!this.tagQueryRef) {
      this.passedTags = tags;
    } else {
      this.passedTags =  undefined;
      const tagIds = this.queryParams.parseTagQueryParam(tags, this.tags);
      this.jobSearchForm.controls.tagIds.setValue(tagIds, { emitEvent: false });
    }

    this.setFormStatus();
  }

  toggleStateRelatedControls(state: string, emitEvent: boolean) {
    const stateRelatedControlNames = [ 'closedAt', 'closedReason' ];

    const queryIncludesClosedJobs = !state || state === 'closed';

    for (const controlName of stateRelatedControlNames) {
      const control = this.jobSearchForm.get(controlName);

      if (queryIncludesClosedJobs) {
        control.enable({ emitEvent });
      } else {
        control.setValue(undefined, { emitEvent });
        control.disable({ emitEvent });
      };
    }
  }

  /**
   * Sets the search form status as pristine or dirty depending on its value
   */
  setFormStatus() {
    const formValues = Object.values(this.jobSearchForm.value)
      .filter(Boolean)
      .filter(val => {
        if (!(Array.isArray(val) || typeof val === 'string')) { return true; }
        return val.length;
      });

    if (!formValues.length) {
      this.jobSearchForm.markAsPristine();
    } else {
      this.jobSearchForm.markAsDirty();
    }
  }

  // Search For Jobs based on filter values
  async retrieveJobs() {
    // this.jobsQH.loading = true;
    if (!this.tagQueryRef) {
      await this.retrieveTags();
    }

    if (this.jobsQueryRef) {
      const variables = this.generateWatchJobsInput();
      return this.jobsQueryRef.setVariables(variables);
    };

    this.initJobsListWatchQuery();
  }

  initJobsListWatchQuery() {
    this.jobsQueryRef = this.jobsListGQL.watch(this.generateWatchJobsInput(), {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,

    });

    this.subs.sink = this.jobsQueryRef.valueChanges.subscribe((res) => {
      this.jobsQH.loading = res.loading;
      if (res.loading) { return; };
      if (!res.data?.jobs?.jobs) {
        console.warn('no jobs returned', res);
        return;
      };

      // Get Parse the Errors into DataError format
      this.jobDataErrors = parseGraphqlErrors(res.errors, 'jobs', 2);

      const jobsWithError = res.data.jobs.jobs
        .map((job, index) => (
          {
            ...job,
            error: this.jobDataErrors.find((err) => err.listIndex === index)}
        )
      ) || [];

      if (this.jobsQH.mergeNextResults) {
        this.jobs = [
          ...this.jobs,
          ...jobsWithError,
        ];
      } else {
        this.jobs = jobsWithError;
      }

      this.jobsQH.hasMore = res.data.jobs.jobs.length === this.jobsQH.limit;
      this.jobsQH.isFirstLoad = false;
    });
  }

  generateWatchJobsInput(): JobsPageQueryVariables {
    const jobsFilter = this.generateJobsFilter();

    const { getJobsOfState } = jobsFilter;

    const fetchClosedJobs = getJobsOfState?.includes('closed');

    const watchJobsInput: JobsPageQueryVariables = {
      limit: this.jobsQH.limit,
      skip: this.jobsQH.skip,
      filter: jobsFilter,
      resolve: ['users', 'firstBookedRevenueGenerating'],
      sort: fetchClosedJobs ? 'closedAt:desc' : 'createdAt:desc',
    };

    return watchJobsInput;
  }

  generateJobsFilter(): JobsPageQueryVariables['filter'] {
    const val = this.jobSearchForm.value;

    const transactionMin = val.transactionMin ? convertDollarsToCents(val.transactionMin) : undefined;
    const transactionMax= val.transactionMax ? convertDollarsToCents(val.transactionMax) : undefined;

    const getDeleted = !val.stage || val.stage === 'deleted';

    return {
      ...this.filter,
      tagIds: val.tagIds?.length ? val.tagIds : undefined,
      search: val.search,
      stage: val.stage === 'any' ? undefined : val.stage,
      zoneDir: ZoneDir.Lte,
      created: this.queryParams.convertDatesToNumValInput(val.created),
      lastUpdated: this.queryParams.convertDatesToNumValInput(val.lastUpdated),
      closedAt: this.queryParams.convertDatesToNumValInput(val.closedAt),
      archived: this.queryParams.convertDatesToNumValInput(val.archivedAt),
      eventsFilter: this.generateEventsFilter(val.bookedEvents, val.bookingDates),
      transactionAmount: this.queryParams.getNumValInput(transactionMin, transactionMax),
      closedReason: val.closedReason,
      getJobsOfState: val.state && val.state !== 'any' ? [val.state] : undefined,
      sources: val.sources?.length ? val.sources : undefined,
      getDeleted,
    };
  }

  /**
   * Generates an object of type ObjectCalendarEventsFilterInput
   * used by the backend to filter jobs by their associated events.
   *
   * Requested jobs should have at least one event of any of the provided event types
   * booked within the specified range of dates.
   *
   * @param events An array of event types such that the requested jobs should be related to an event of any such type.
   * @param dates The range of dates where the associated events should have been booked.
   * @returns An object of type ObjectCalendarEventsFilterInput.
   */
  generateEventsFilter(events: string[], dates: Date[]): ObjectCalendarEventsFilterInput {
    if (!events || !dates) { return; };
    const [ startDate, endDate ] = dates;
    const calendarEventsFilter: ObjectCalendarEventsFilterInput = {
      types: events,
      start: this.queryParams.convertDatesToNumValInput([ startDate ]),
      end: this.queryParams.convertDatesToNumValInput([ undefined, endDate])
    };
    return calendarEventsFilter;
  }

  /**
   * Resets the search form and searches for jobs with empty filters.
   *
   * Prevents Angular FormGroup default behavior, which is to emit
   * value changes from each control on reset. Component listens
   * to control value changes and refetches every time they emit,
   * so the default behavior would result in many unnecessary calls.
   */
  resetFilters() {
    this.jobSearchForm.reset(undefined, { emitEvent: false });
    if (this.syncToQueryParams) {
      this.updateQueryParams();
    } else {
      this.updateFilterSelect();
      this.jobsQH.mergeNextResults = false;
      this.jobsQH.skip = 0;
      this.jobsQH.total = undefined;
      this.retrieveJobs();
    }
    this.jobSearchForm.markAsPristine();
  }

  formatTimeline(timeline: string) {
    return dayjs(timeline).toDate();
  }

  formatTimelineTooltip(created: number, strTimeline: string) {
    if (!created) {
      return `Job not created.`;
    }

    if (!strTimeline) {
      return `Timeline not set.`;
    }
    const mCreated = dayjs(created * 1000);
    const mTimeline = dayjs(strTimeline);
    let diffMethod: dayjs.QUnitType | dayjs.OpUnitType = 'days';
    let diff = mTimeline.diff(mCreated, diffMethod);
    if (diff > 21) {
      diffMethod = 'weeks';
      diff = mTimeline.diff(mCreated, diffMethod);
      if (diff > 3) {
        diffMethod = 'months';
        diff = mTimeline.diff(mCreated, diffMethod);
      }
    }

    return `${ diff } ${ diffMethod } out`;
  }

  /**
   * Prefills jobSearchForm with any relevant data
   * passed through the filter input.
   */
  primeJobSearchForm() {
    const { search, stage, tagIds } = this.filter;
    this.jobSearchForm.patchValue({
      search: search || '',
      stage,
      tagIds: tagIds || [],
    });
  }

  // Pagination
  retrieveMoreJobs() {
    if (this.jobsQH.loading || !this.jobsQH.hasMore) { return; }
    this.jobsQH.skip += this.jobsQH.limit;
    this.jobsQH.mergeNextResults = true;
    this.retrieveJobs();
  }

  selectJob(job: Job) {
    this.detailsHelper.detailsItem.next({ type: 'job', item: {id: job.id} });
  }

  async retrieveTags() {
    return new Promise((resolve, reject) => {
      this.tagQueryRef = this.tagService.watchTags(
        {
          filter: {
            objectTypes: [TAG_TYPES.Job]
          }
        },
        {
          fetchPolicy: 'cache-and-network'
        }
      );

      this.subs.sink = this.tagQueryRef.valueChanges.subscribe({
        next: (res) => {
          if (res.loading) { return; }
          this.tags = res.data.tags.tags;
          if (this.passedTags) { // If we have tags passed in to filter by then set the filter
            const tagIds = this.queryParams.parseTagQueryParam(this.passedTags, this.tags);
            if (!tagIds?.length) { return resolve(false); }
            this.jobSearchForm.controls.tagIds.setValue(tagIds, { emitEvent: false });
            this.setFormStatus();
          }
          resolve(true);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }

  /**
   * Opens the mutate filter dialog.
   */
  openSaveFilter() {
    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'filter',
      object: {
        name: '',
        filter: this.formatSearchFormValues(this.jobSearchForm.value)
      },
      // TODO: Fetch custom filters from mutate filter component
      additionalValues: [
        {
          property: 'filterType',
          value: 'job'
        },
      ],
    });
  }

  getConfigValues() {
    this.subs.sink = this.getConfigGQL.fetch({keys: ['jobs.closedReasons']})
    .subscribe((res) => {
      let closedReasonsConfigs: JobClosureReason[];

      if (!res?.data?.getConfigValues?.length) {
        closedReasonsConfigs = DEFAULT_JOB_CLOSED_REASONS;
      } else {
        closedReasonsConfigs = safeParseJSON(res.data.getConfigValues[0].value) as JobClosureReason[];;
      }

      const resolvedClosedReasons = closedReasonsConfigs.map((c) => ({ option: c.id, label: c.title }));

      this.closedReasons = [
        {
          option: undefined,
          label: 'Any',
        },
        ...resolvedClosedReasons,
      ];

    });
  }

  setJobPlaceholderColumnCount() {
    // Link column is a special case
    const length = Object.keys(this.activeData).filter((key) => this.activeData[key] && key !== 'link').length;
    this.jobPlaceholderColumnCount = Array.from({ length }) ;
  }

  getJobCount() {
    this.jobsPageCountGQL.fetch(this.generateWatchJobsInput(), { notifyOnNetworkStatusChange: true })
      .subscribe((res) => {
        this.jobCountLoading = res.loading;
        if (res.loading) { return; }
        this.jobsQH.total = res.data.jobs.total;
      });
  }
}
