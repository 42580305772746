import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash';
import { InputText } from 'primeng/inputtext';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SetConfigValuesGQL, ConfigValue, ConfigValueInput, ClearConfigValuesGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

interface BooleanParsedConfig {
  key: string;
  value: string | boolean;
  zone: string;
  formattedFallback?: string;
}

@Component({
  selector: 'app-multi-config-settings-card',
  templateUrl: './multi-config-settings-card.component.html',
  styleUrls: ['./multi-config-settings-card.component.scss']
})
export class MultiConfigSettingsCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input () namespace: string;

  @Input() configs: ConfigValue[];

  @Input() currentZoneId?: string;

  @ViewChildren(InputText) inputs: QueryList<InputText>;

  booleanParsedConfigs: BooleanParsedConfig[];

  defaultConfigs: BooleanParsedConfig[];

  subs = new SubSink();

  editMode = false;

  modifiedKeys: Set<string> = new Set();

  maxLengthBeforeTruncating = 200;

  constructor(
    private setConfigGQL: SetConfigValuesGQL,
    private notify: FreyaNotificationsService,
    private clearConfigValuesGQL: ClearConfigValuesGQL,
  ) { }

  ngOnInit(): void {
    this.booleanParsedConfigs = this.parseConfigs(this.configs);
    this.defaultConfigs = cloneDeep(this.booleanParsedConfigs);
  }

  ngAfterViewInit(): void {

    // Whenever new inputs are loaded (i.e. when entering edit mode),
    // set focus on first input
    this.subs.sink = this.inputs.changes
    .subscribe(() => {
      if (this.inputs.first){
        this.inputs.first.el.nativeElement.focus();
      };
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  enterEditMode() {
    this.editMode = true;
  }

  discardChanges() {
    if (this.modifiedKeys.size) {
      this.booleanParsedConfigs = cloneDeep(this.defaultConfigs);
      this.modifiedKeys.clear();
    };
    this.editMode = false;
  }

  save() {
    const modifiedConfigs: ConfigValueInput[] = this.booleanParsedConfigs
    .filter((c) => this.modifiedKeys.has(c.key))
    .map((c) => ({
      key: c.key,
      value: c.value.toString()
    }));

    this.subs.sink = this.setConfigGQL
    .mutate({ configs: modifiedConfigs })
    .subscribe(() => {
      this.editMode = false;

      this.booleanParsedConfigs = this.booleanParsedConfigs
        .map((c) => {

          if (!this.modifiedKeys.has(c.key)) {
            return c;
          }

          return {
            ...c,
            zone: this.currentZoneId,
          };

        });

      this.modifiedKeys.clear();
      this.defaultConfigs = cloneDeep(this.booleanParsedConfigs);
      this.notify.success('Changes saved');
    });
  }

  markAsModified(key: string) {
    this.modifiedKeys.add(key);
  }

  parseConfigs(configs: ConfigValue[]): BooleanParsedConfig[] {
    return configs.map((c) => {
      let value;
      if (c.value === 'true') {
        value = true;
      } else if (c.value === 'false') {
        value = false;
      } else {
        value = c.value;
      };

      return {
        key: c.key,
        zone: c.zone,
        value,
        formattedFallback: this.formatFallback(c.fallback),
      } as BooleanParsedConfig;
    });
  }

  formatFallback(fallback: ConfigValue) {

    if (!fallback) {
      return 'none';
    }

    const [ parsed ] = this.parseConfigs([fallback]);

    if (!parsed) {
      return 'none';
    }

    return `${ parsed.value} (${parsed.zone})`;

  }

  reset(configKey: string) {

    const config = this.configs.find((c) => c.key === configKey);

    if (!config) {
      throw new Error(`Could not find config ${configKey}`);
    }

    if (!config.id) {
      throw new Error(`Could not find ID for config ${configKey}`);
    }

    this.clearConfigValuesGQL.mutate({ keys: [ configKey ]}).subscribe(() => {

      this.notify.success(`Config ${configKey} reset`);

      const [ parsedFallback ] = this.parseConfigs([ config.fallback ]);

      const updatedConfigs = [ ...this.booleanParsedConfigs ];

      const parsedConfigIndex = updatedConfigs.findIndex((pc) => pc.key === configKey);

      if (parsedConfigIndex < 0) {
        throw new Error(`Could not find parsed config ${configKey}`);
      }

      updatedConfigs.splice(parsedConfigIndex, 1, parsedFallback);

      this.booleanParsedConfigs = updatedConfigs;

    }, (err) => {
      this.notify.apolloError(`Could not reset config ${configKey}`, err);
    });
  }
}
