import { Pipe, PipeTransform } from '@angular/core';

import { CalendarEventForEstimateFragment } from '../../generated/graphql.generated';
import { isFinalizedInvoice } from '../invoices/invoices.utils';

@Pipe({
  name: 'eventHasInvoice'
})
export class EventHasInvoicePipe implements PipeTransform {

  transform(value: CalendarEventForEstimateFragment, finalized = true): boolean {
    if (finalized) {
      return value?.invoices?.some(isFinalizedInvoice);
    }
    return value?.invoices?.some(i => !i.deletedAt && !i.voidedAt);
  }

}
