import { Component, Input, OnInit } from '@angular/core';

import { BaseJobFragment } from '../../../generated/graphql.generated';

export type SummableItem = Pick<BaseJobFragment,
  'damageTotal' |
  'discountTotal' |
  'discountedSubTotal' |
  'discountedSubTotalWithoutDamages' |
  'paidTotal' |
  'pendingTotal' |
  'total' |
  'taxTotal' |
  'subTotal' |
  'currency' |
  'expenseTotal'
>;

@Component({
  selector: 'app-financials-table',
  templateUrl: './financials-table.component.html',
  styleUrls: ['./financials-table.component.scss']
})
export class FinancialsTableComponent implements OnInit {

  @Input() loading = false;
  @Input() item: SummableItem;
  @Input() showPaid = true;

  showConfidentialInfo = false;
  constructor() { }

  ngOnInit(): void {
  }

  get grossProfitMargin(): number {
    
    // TODO: This is a temporary fix to handle negative revenue. Technically, we should not have negative revenue.
    if (!this.item || !this.item.discountedSubTotal || this.item.discountedSubTotal < 0) {
        return 0;
    }

    const expenseTotal = this.item.expenseTotal ?? 0;
    return ((this.item.discountedSubTotal - expenseTotal) / this.item.discountedSubTotal) * 100;

  }

}