<section class="customer">
  <app-overview-header></app-overview-header>

  <app-overview-summary></app-overview-summary>

  <app-overview-customer class="p-mt-4"></app-overview-customer>

  <p-divider />

  <app-overview-locations></app-overview-locations>

  <p-divider />

  <app-overview-timeline></app-overview-timeline>
</section>