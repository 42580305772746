import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { CalendarEvent } from '../../interfaces/calendarEvents';

@Component({
  selector: 'app-complete-events-confirmation',
  templateUrl: './complete-events-confirmation.component.html',
  styleUrls: [
    '../../dynamic-dialog-styling.scss',
    './complete-events-confirmation.component.scss',
  ]
})
export class CompleteEventsConfirmationComponent implements OnInit {

  events: CalendarEvent[];

  prompt: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.events = this.config?.data?.events as CalendarEvent[];
    this.prompt = this.config?.data?.prompt as string;
  }

}
