import { Component, Input, OnInit } from '@angular/core';


import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard-event-type-summary',
  templateUrl: './dashboard-event-type-summary.component.html',
  styleUrls: ['./dashboard-event-type-summary.component.scss']
})
export class DashboardEventTypeSummaryComponent implements OnInit {
  @Input() title: string;
  @Input() data: {
    type: string;
    count: number;
    discountedSubTotalWithoutDamages: number;
    average: number;
  }[];

  @Input() currency = 'USD';

  constructor(
    public dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
  }

  sumSubTotal() {
    return this.data.reduce((acc, d) => acc + d.discountedSubTotalWithoutDamages, 0);
  }

  sumCount() {
    return this.data.reduce((acc, d) => acc + d.count, 0);
  }

}
