import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

export interface Filter extends MenuItem {
  filter?: TemplateRef<any>;
  items?: Filter[];
}

@Component({
  selector: 'app-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss']
})
export class AddFilterComponent implements OnInit, OnChanges {

  @Input() filters: Filter[];

  @Input() panelWidth = 250;

  @Output() overlayOpened = new EventEmitter<void>();

  @ViewChild(OverlayPanel) overlayPanel: OverlayPanel;

  mode: 'menu' | 'input' = 'menu';

  currentInput: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filters' in changes && this.filters) {
      this.setFilterBehavior(this.filters);
    }
  }

  setFilterBehavior(filters: Filter[]) {
    for (const filter of filters) {
      if (filter.filter) {
        filter.command = () => {
          this.currentInput = filter.filter;
          this.mode = 'input';
        };
      } else if (filter.items && filter.items.length) {
        this.setFilterBehavior(filter.items);
      }
    }
  }

  close() {
    setTimeout(() => {
      this.overlayPanel.hide();
    }, 250);
  }

  setMenuMode() {
    this.mode = 'menu';
  }

}
