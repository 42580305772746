import { gql } from 'graphql-tag';

import { User } from './auth';
import { ACCESS_TYPE } from './shareAccess';

export interface ListCommentsOutput {
	comments: CommentPagination;
}

export interface AddCommentOutput {
	addComment: Comment;
}

export interface UpdateCommentsOutput {
	updateComments: Comment[];
}

export interface DeleteCommentsOutput {
	deleteComments: string[];
}

export interface Comment {
    id: string;
    text: string;
    // The Required access level on the object with this comment for the user to be able to view this comment.
    requiredAccessLevel?: ACCESS_TYPE;
    // The User who added the comment
    author?: User;
    attributes?: string;

    createdAt: number;
    updatedAt?: number;
    deletedAt?: number;
}


export interface ListCommentFilter {
    ids?: string[];
    userIds?: string[];
    objectIds?: string[];
    objectType?: string;
    objectTypes?: string[];
    showDeleted?: boolean;
    hasAllAttributes?: string[];
    hasAnyAttributes?: string[];
    hasNoAttributes?: string[];
}


export interface AddCommentInput {
    // Must be on of the Following 'owner', 'manager', 'editor', 'commenter', 'viewer'm 'any'
    requiredAccessLevel?: ACCESS_TYPE;
    objectId: string;
    // Name of the object type eg. 'calendarEvent' or 'location'. Used to load the object.
    objectType: string;
    text: string;
    attributes: string[];
}


export interface UpdateCommentInput {
    ids: string[];
    update: UpdateCommentPartial;
}

export interface UpdateCommentPartial {
    text?: string;
    attributes?: string[];
    requiredAccessLevel?: ACCESS_TYPE;
}


export interface DeleteCommentInput {
    ids: string[];
    // If true the comment is completely removed from the system.
    hardDelete?: boolean;
}


export interface CommentPagination {
    comments?: Comment[];
    total?: number;
    skipped?: number;
    limited?: number;
}

export interface CommentsInput {
	filter?: ListCommentFilter;
	skip?: number;
	limit?: number;
}

export interface AddCommentFunctionInput {
	input?: AddCommentInput;
}

export interface UpdateCommentsInput {
	input?: UpdateCommentInput;
}

export interface DeleteCommentsInput {
	input?: DeleteCommentInput;
}

export const commentsBaseGQL = `
    id
    text
    requiredAccessLevel
    author {
        id
        email
        phone
        givenName
        familyName
    }
    createdAt
    updatedAt
    deletedAt
`;

export const commentsQuery = gql`
	query comments($filter: ListCommentFilter, $skip: Int, $limit: Int, ){
		comments(filter: $filter, skip: $skip, limit: $limit, ){
			    comments{
                    id
                    text
                    requiredAccessLevel
                    author {
                        id
                        email
                        phone
                        givenName
                        familyName 
                    }
                    attributes
                    createdAt
                    updatedAt
                    deletedAt
                }		
			    skipped
			    limited

	}		}
`;


export const addCommentMutation = gql`
	mutation addComment($input: AddCommentInput, ){
		addComment(input: $input, ){
			    id
			    text
			    requiredAccessLevel
			    author{ id }
    		    attributes
			    createdAt
			    updatedAt
			    deletedAt
        }		
    }
`;


export const updateCommentsMutation = gql`
	mutation updateComments($input: UpdateCommentInput,){
		updateComments(input: $input) {
            ${commentsBaseGQL}
        }
	}
`;


export const deleteCommentsMutation = gql`
	mutation deleteComments($input: DeleteCommentInput,){
		deleteComments(input: $input,){
            commentsDeleted
        }
	}
`;

