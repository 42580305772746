import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { PlusAuthenticationService, RoleService } from '@karve.it/core';
import { User } from '@karve.it/interfaces/auth';
import { ZoneDir as commonZoneDir } from '@karve.it/interfaces/common.gql';
import { ListRolesOutput, RoleInfo } from '@karve.it/interfaces/roles';
import {QueryRef} from 'apollo-angular';

import { intersection } from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { FullUserFragment, UserPageCountGQL, UserPageUsersGQL, UserPageUsersQuery, UserPageUsersQueryVariables, ZoneDir } from '../../generated/graphql.generated';
import { MenuService } from '../base/menu/app.menu.service';
import { pagination, ZONE_TYPES } from '../global.constants';
import { BrandingService } from '../services/branding.service';

import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { MutateUserComponent } from '../shared/mutate-user/mutate-user.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('userSearchInput', { static: true }) userSearchInput!: ElementRef;
  @ViewChild('mutate') mutateRef: MutateUserComponent;

  subs = new SubSink();

  // Component Variables
  users: FullUserFragment[] = [];

  cols = [
    { field: 'familyName', header: 'Name' },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Phone' },
  ];

  // Query Variables
  usersQueryRef!: QueryRef<UserPageUsersQuery, UserPageUsersQueryVariables>;
  usersQH: WatchQueryHelper = {
    limit: pagination.defaultNumberOfItems,
    skip: 0,
    loading: true,
    search: '',
    hasMore: true,
    mergeNextResults: false,
    total: undefined,
  };

  userCountLoading = false;

  userPlaceholderCount = Array.from({ length: this.usersQH.limit });

  rolesQueryRef: QueryRef<ListRolesOutput>;
  availableRoles: RoleInfo[];
  selectedRoles: RoleInfo[];

  pagination = pagination;

  contexting = false;

  constructor(
    private userPageUsersGQL: UserPageUsersGQL,
    private dhs: DetailsHelperService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private router: Router,
    private plusAuth: PlusAuthenticationService,
    private brandingSvc: BrandingService,
    private notify: FreyaNotificationsService,
    public freyaHelper: FreyaHelperService,
    private usersPageCountGQL: UserPageCountGQL,
    private menuService: MenuService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.dhs.getObjectUpdates('User').subscribe((res) => {
      this.usersQueryRef.refetch();
    });

    this.subs.sink = fromEvent(this.userSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.usersQH.mergeNextResults = false;
      this.usersQH.skip = 0;
      this.usersQH.total = undefined;
      this.searchForUsers();
    });
    this.searchForUsers();
    this.watchUrl();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.scrollIntoView(true);
  }

  retrieveUsers() {
    this.usersQueryRef = this.userPageUsersGQL.watch(
      {
        limit: this.usersQH.limit,
        filter: {
          zoneDir: ZoneDir.Lte,
          roles: this.getRoleIds()
        },
      }, { notifyOnNetworkStatusChange: true });

    this.subs.sink = this.usersQueryRef.valueChanges.subscribe((res) => {
      this.usersQH.loading = res.loading;
      if (res.loading) { return; }
      if (this.usersQH.mergeNextResults) {
        this.users = [
          ...this.users,
          ...res.data.usersv2.users,
        ];
      } else {
        this.users = res.data.usersv2.users;
      }

      this.usersQH.hasMore = res.data.usersv2.users.length === this.usersQH.limit;
    });
  }

  retrieveMoreUsers() {
    if (this.usersQH.loading || !this.usersQH.hasMore) { return; }
    this.usersQH.skip = this.usersQH.skip + this.usersQH.limit;
    this.usersQH.mergeNextResults = true;
    this.searchForUsers();
  }

  searchForUsers() {
    this.usersQH.loading = true;

    if (this.usersQueryRef) {
      const userInput = {
        filter: {
          search: this.usersQH.search,
          zoneDir: ZoneDir.Lte,
          roles: this.getRoleIds()
        },
        limit: this.usersQH.limit,
        skip: this.usersQH.skip,
      };
      this.usersQueryRef.refetch(userInput);
    } else {
      this.retrieveUsers();
    }
  }

  selectUser(user: User) {
    this.dhs.detailsItem.next({ type: 'users', item: {id: user.id} });
  }

  openCreateUser() {
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }

  getRoleIds() {
    if (!this.selectedRoles || !this.selectedRoles.length) { return; }
    return this.selectedRoles.map((r) => r.id);
  }

  /**
   * Whenever URL changes, fetch available roles
   * and select roles in accordance with current URL
   */
  watchUrl() {
    this.subs.sink = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd || event instanceof Scroll)
    ).subscribe((event: NavigationEnd | Scroll) => {

      const navigationEvent = event instanceof NavigationEnd ? event : event.routerEvent as NavigationEnd;

      // If changing zones, do nothing
      if (this.contexting) {
        return;
      }

      // Fetch roles if the URL starts with '/users/'
      if (navigationEvent.urlAfterRedirects.startsWith('/users')) {
        this.fetchRoles();
      }
    });
  }

  fetchRoles() {
    if (this.rolesQueryRef) {
      this.rolesQueryRef.resetLastResults();
      this.rolesQueryRef.refetch();
      return;
    };

    this.rolesQueryRef = this.roleService.watchRoles({ zoneDir: commonZoneDir.lte, limit: -1 });

    this.subs.sink = this.rolesQueryRef
      .valueChanges.subscribe(async (res) => {
        if (this.contexting) { return; }

        if (res.networkStatus === 7) {

          this.availableRoles = res.data.roles;

          const prevSelection = this.selectedRoles || [];

          await this.selectRoles();

          const newSelection = this.selectedRoles || [];

          const rolesChanged = JSON.stringify(prevSelection.sort()) !== JSON.stringify(newSelection.sort());

          if (rolesChanged) {
            this.usersQH.mergeNextResults = false;
            this.usersQH.skip = 0;
            this.usersQH.total = undefined;
            this.searchForUsers();
          }
        }
      });
  }

  /**
   * Uses the current route url to find any preselected roles
   */
  async selectRoles() {

    // If we are not in a child route, there should be no preselected roles,
    // therefore clear selection
    if (!this.route.snapshot.firstChild) {
      this.selectedRoles = [];
      return;
    };

    const childRouteUrl = this.route.snapshot.firstChild.url[0].path.toLowerCase();

    switch(childRouteUrl) {
      case 'customers':
        this.selectCustomerRoles();
        break;
      case 'employees':
        this.selectEmployeeRoles();
        break;
      case 'allcustomers':
        this.showAllCustomers();
        break;
    }
  }

  selectCustomerRoles() {
    if (!this.availableRoles) { return; };
    this.selectedRoles = this.availableRoles
    .filter((r) => r.attributes[2] === 'customer');
  }

  selectEmployeeRoles() {
    if (!this.availableRoles) { return; };
    this.selectedRoles = this.availableRoles
    .filter((r) => this.isEmployeeRole(r));
  }

  isEmployeeRole(role: RoleInfo): boolean {
    if (!role) { return; };
    const employeeRoles = [
      'employee',
      'call-center-employee',
      'system-role',
      'agent',
      'estimator',
      'crew-lead',
      'crew-meber',
      'team-member',
    ];
    return intersection(role.attributes, employeeRoles).length > 0;
  }

  async showAllCustomers() {
    const currentZone = this.brandingSvc.currentZone().value;

    if (currentZone.type === ZONE_TYPES.franchise) {

      this.contexting = true;

      const { parent } = currentZone;

      await this.plusAuth.setContext(parent.id);

      this.contexting = false;

      this.notify.success(`Zone changed to ${parent.name}`);
    }

    this.router.navigate(['users', 'customers']);
  }

  clearSearch() {
    this.usersQH.search = '';
    this.usersQH.mergeNextResults = false;
    this.usersQH.skip = 0;
    this.usersQH.total = undefined;
    this.searchForUsers();
  }

  searchUsersByRole() {
    this.usersQH.mergeNextResults = false;
    this.usersQH.skip = 0;
    this.usersQH.total = undefined;
    this.searchForUsers();
  }

  getUserCount() {
    this.usersPageCountGQL.fetch({
      filter: {
        search: this.usersQH.search,
        zoneDir: ZoneDir.Lte,
        roles: this.getRoleIds()
      },
    }, {
      notifyOnNetworkStatusChange: true,
    }).subscribe((res) => {
        this.userCountLoading = res.loading;
        if (res.loading) { return; }
        this.usersQH.total = res.data.usersv2.total;
      });
  }
}
