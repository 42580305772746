<p-table
  [value]="transactions"
  [(selection)]="selectedTransaction"
  [showCurrentPageReport]="false"
  breakpoint="620px"
  >
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th>
        Availability
      </th>
      <th class="p-text-center" style="width: 1rem">Status</th>
      <th>Amount</th>
      <th>Type</th>
      <th>Created <br /><small>(Paid)</small></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-t
    >
    <tr>
      <td>
        <p-tableRadioButton [value]="t" [disabled]="t.unavailable"></p-tableRadioButton>
      </td>
      <td [ngClass]="{
        'success-text': !t.unavailable,
        'error-text': t.unavailable
        }">
        <i *ngIf="t.unavailable" class="pi pi-times"></i>
        <i *ngIf="!t.unavailable" class="pi pi-check"></i>
        {{ t | transactionAvailability }}
      </td>
      <td class="p-text-center">
        <span class="p-column-title">Status</span>
        <app-object-state
          [object]="t"
          objectType="transaction"
          [colorful]="true"
          ></app-object-state>
      </td>
    <td>
      <span class="p-column-title">Amount</span>
      {{t.amount / 100 | currency : t.currency}}</td>
    <td>
      <span class="p-column-title">Type</span>
      <span>{{t.type | titlecase}} </span>
      <small>
        {{ t.paymentType.name }}
      </small>
    </td>
    <td>
      <span class="p-column-title">Created (Paid)</span>
      <span>
        {{
        t.createdAt | freyaDate : 'h:mm a, MMM d, y'
        }}
      </span>
      <small
        *ngIf="t.paidAt"
        >
        ({{ t.paidAt | freyaDate : 'h:mm a, MMM d, y' }})
      </small>
    </td>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="emptymessage"
    let-columns="columns"
    >
    <tr>
      <td [attr.colspan]="'5'">
        {{emptyMessage}}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
    </tr>
  </ng-template>
</p-table>
<div class="footer">
  <button
    pButton
    label="Exit Dialog"
    icon="pi pi-times"
    (click)="closeDialog()"
    [disabled]="saving"
    ></button>
  <button
    pButton
    label="Create New Transaction"
    icon="pi pi-plus"
    (click)="openMutateTransactionDialog()"
    [disabled]="saving"
    ></button>
  <button
    class="p-button-success"
    pButton
    label="Apply Selected Transaction"
    [icon]="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    (click)="applyTransaction()"
    [disabled]="saving || !selectedTransaction"
    ></button>
</div>
