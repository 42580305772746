<p>{{ confirmation.message }}</p>
<div class="footer">
  <button
    type="button"
    pButton
    pRipple
    [autofocus]="confirmation.defaultFocus === 'reject'"
    [label]="confirmation.rejectLabel"
    [class]="confirmation.rejectButtonStyleClass"
    [icon]="confirmation.rejectIcon"
    (click)="reject()"
    [disabled]="loading"
    ></button>
  <button
    type="button"
    pButton
    pRipple
    [autofocus]="confirmation.defaultFocus === 'accept'"
    [label]="confirmation.acceptLabel"
    [class]="confirmation.acceptButtonStyleClass"
    [icon]="loading ? 'pi pi-spin pi-spinner' : confirmation.acceptIcon"
    [disabled]="loading"
    (click)="accept()"
    ></button>
</div>
