<div class="card">
  <button
    (click)="connect()"
    pButton
    pRipple
    class="p-mt-2"
    label="Connect to QuickBooks"
    icon="pi pi-sign-in"
    type="submit"
    ></button>
</div>
