<div [formGroup]="templateForm">
    <ng-template #name>
        <div *appVar="templateForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="name"
                placeholder="Template Name *"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="input-name-field"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #dates>
        <div *appVar="templateForm.get('startDate') as control">
            <label>Start Date</label>
            <br />
            <p-calendar
                formControlName="startDate"
                [class.p-invalid-input]="!control.valid && control.touched"
                appendTo="body"
            ></p-calendar>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
        <br>
        <div *appVar="templateForm.get('endDate') as control">
            <label>End Date (Optional)</label>
            <br />
            <p-calendar
                formControlName="endDate"
                [class.p-invalid-input]="!control.valid && control.touched"
                appendTo="body"
            ></p-calendar>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
            <button
                pButton
                class="p-button-warning p-ml-2"
                icon="pi pi-times"
                label="Clear"
                (click)="control.setValue(null)"
            ></button>
        </div>
    </ng-template>
    <br>
    <ng-template #availability>
        <div class="p-grid">
            <div class="p-col-6">
                <h6>Add Availability</h6>
                <small>Add availability to the template. Select the days and times for the availability.</small>
                <hr>
                <label>Select Days of the Week</label>
                <br>
                <p-multiSelect
                    [options]="daysOfTheWeek"
                    [(ngModel)]="selectedDays"
                    [ngModelOptions]="{standalone: true}"
                    class="full-width-multiselect"
                    placeholder="No Days Selected"
                    [maxSelectedLabel]="2"
                    [selectedItemsLabel]="'{0} days selected'"
                    appendTo="body"
                >
                    <ng-template let-value pTemplate="selectedItems">
                        <span>{{selectedDays | join : ', ' : null : 2}}</span>
                        <div *ngIf="!selectedDays?.length">
                            No Days Selected
                        </div>
                    </ng-template>
                </p-multiSelect>
                <br>
                <br>
                <label>Select Time</label>
                <br>
                <p-dropdown
                    [options]="blockList"
                    placeholder="Select Time"
                    [(ngModel)]="selectedBlockOption"
                    [ngModelOptions]="{standalone: true}"
                    class="full-width-dropdown"
                    appendTo="body"
                    data-cy="select-time-dropdown-field"
                >
                    <ng-template
                        let-block
                        pTemplate="item"
                    >
                        <div>
                            <span *ngIf="block.custom">Custom</span>
                            <span *ngIf="!block.custom">{{block.start | secondsToTime}} - {{block.end |
                                secondsToTime}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="selectedItem">
                        <div>
                            <span *ngIf="selectedBlockOption?.custom">Custom</span>
                            <span *ngIf="!selectedBlockOption?.custom">{{selectedBlockOption?.start | secondsToTime}} -
                                {{selectedBlockOption?.end | secondsToTime}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
                <div *ngIf="selectedBlockOption?.custom">
                    <br>
                    <div class="flex">
                        <div class="select-time">
                            <label>Start</label>
                            <br>
                            <p-calendar
                                [(ngModel)]="customBlockStart"
                                [ngModelOptions]="{standalone: true}"
                                [timeOnly]="true"
                                hourFormat="12"
                                (onSelect)="setEndToStart()"
                                class="timer p-m-2"
                                appendTo="body"
                                data-cy="start-time-field"
                            ></p-calendar>
                        </div>
                        <div class="select-time">
                            <label>End</label>
                            <br>
                            <p-calendar
                                [(ngModel)]="customBlockEnd"
                                [ngModelOptions]="{standalone: true}"
                                [timeOnly]="true"
                                hourFormat="12"
                                class="timer p-m-2"
                                appendTo="body"
                                data-cy="end-time-field"
                            ></p-calendar>
                        </div>
                    </div>
                    <p
                        *ngIf="customTimesInvalid"
                        class="error-text"
                    >Block times invalid, end must be after start.</p>
                </div>
                <br>
                <br>
                <button
                    pButton
                    label="Add to Template"
                    icon="pi pi-plus"
                    [disabled]="!selectedDays?.length || !selectedBlockOption"
                    (click)="addBlockToTemplate()"
                    data-cy="add-to-template-button"
                >
                </button>
            </div>
            <div
                class="p-col-6"
                #availability
            >
                <h6>Template Preview</h6>
                <small>Preview all applied availability. Remove availability from days by clicking the x</small>
                <hr>
                <div class="chip-container">
                    <label>Monday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('monday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('monday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div class="chip-container">
                    <label>Tuesday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('tuesday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('tuesday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div class="chip-container">
                    <label>Wednesday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('wednesday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('wednesday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div>
                    <label>Thursday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('thursday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('thursday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div class="chip-container">
                    <label>Friday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('friday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('friday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div class="chip-container">
                    <label>Saturday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('saturday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('saturday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <div class="chip-container">
                    <label>Sunday: </label>
                    <p-chip
                        *ngFor="let block of templateForm.get('sunday').value"
                        [removable]="true"
                        (onRemove)="removeBlockFromDay('sunday', block)"
                    >
                        <span class="time-block">{{block.start | secondsToTime}} - {{block.end | secondsToTime}}</span>
                    </p-chip>
                </div>
                <br>
                <p-message
                    *ngIf="availabilityInvalid()"
                    severity="warn"
                    text="No Availability Added to Template"
                ></p-message>
            </div>

        </div>
    </ng-template>
    <ng-template #zones>
        <app-assign-zones formControlName="zones" [zoneTypes]="['area']"></app-assign-zones>
    </ng-template>
    <br>
</div>

<app-mutate-object
    #co
    [fg]="templateForm"
    objectType="Availability Template"
    [mutateType]='mutateType'
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>