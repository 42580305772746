<div class="header-with-button">
  <h4><i class="pi pi-calendar-times large-icon"></i> Booked Off</h4>
  <button
    *ngIf="eventActionsVisible"
    pButton
    pRipple
    label="Actions"
    (click)="menu.toggle($event)"
    icon="pi pi-angle-down"
    ></button>
  <p-menu
    #menu
    [popup]="true"
    appendTo="body"
    [model]="eventActions"
    ></p-menu>
</div>
<div class="p-grid">
  <div class="p-col">
  </div>
</div>

<div class="card">
  <p><b>Start:</b> {{bookOffEvent.start | freyaDate : 'E, MMM dd, h:mm a'}}</p>
  <p><b>End:</b> {{bookOffEvent.end | freyaDate : 'E, MMM dd, h:mm a'}}</p>
  <p><b>Description:</b> {{ bookOffEvent.description || 'None' }}</p>
  <br>
</div>

<h5>Assets</h5>
<div class="card">
  <div *ngFor="let asset of bookOffEvent.assets">
    <p>{{asset.name}} <p-tag [value]="asset.type"></p-tag></p>
  </div>
  <div *ngIf="!bookOffEvent.assets?.length">
    No Assets Assigned
  </div>
</div>
