/* eslint-disable max-len */
import { gql } from 'graphql-tag';

import { BaseLocationFragment } from '../../generated/graphql.generated';

import { RawUser } from './auth';
import { baseCalendarEventsGQL, CalendarEvent, calendarEventLocationGQL, calendarEventsAssetsGQL, calendarEventTotals } from './calendarEvents';
import { Charge, CreateChargeInput } from './charges';
import { discountDetailsGQL, fieldsBaseGQL, jobsBaseGQL, usersBaseGQL, ZoneDirection } from './common.gql';
import { DiscountDetails, ObjectDiscountDetails } from './discounts';
import { Location, LocationCreateBase } from './locations';
import { Metadata, metaDataGQL } from './metadata';
import { Access, accessesBaseGQL } from './shareAccess';
import { Tag } from './tag';
import { Tax, taxBaseGQL } from './taxes';
import { Transaction, transactionsBaseGQL } from './transactions';
import { Zone, zonesBaseGql } from './zones';
import { Summary } from '../jobsv2/job-tool.reducer';

export interface ListJobsFunctionOutput {
  jobs: ListJobsOutput;
}

export interface CreateJobOutput {
  createJob: Job;
}

export interface UpdateJobsFunctionOutput {
  updateJobs: UpdateJobsOutput;
}

export interface DeleteJobsOutput {
  deleteJobs: boolean;
}

export interface TaxPaid {
  tax: Tax;
  // Value in cents
  paid: number;
}

export interface Job {
  id: string;
  collectionId: string;
  stage: string;
  code: string; // Code is an identifier for the Job
  users: JobUser[];
  charges: Charge[];
  currency: string;   // ISO Currency format
  transactions?: Transaction[];
  startLocation?: BaseLocationFragment;
  endLocation?: BaseLocationFragment;
  depositRequired?: number;
  subTotal: number; // Value in cents
  taxTotal: number;   // Value in cents
  taxesPaid: TaxPaid[];
  discountTotal: number;   // Value in cents
  total: number;   // Value in cents
  discountedSubTotal: number;
  discountedSubTotalWithoutDamages: number;
  damageTotal: number;
  paidTotal: number;   // Value in cents
  pendingTotal: number;   // Value in cents
  events?: CalendarEvent[];
  tags: Tag[];
  previousStages?: Job[];
  followingStages?: Job[];
  isLatest?: boolean;
  metadata?: Metadata[];
  accesses?: Access[];
  discount?: DiscountDetails;
  allAppliedDiscountDetails?: ObjectDiscountDetails[];
  notes?: string;
  zone?: Zone;
  eventsOrderChangedByUser?: boolean;
}


export interface ListJobsOutput {
  total: number;
  skipped?: number;
  limited?: number;
  jobs: Job[];
}


export interface JobUser {
  user: RawUser;
  role: string;
}


export interface NewChargesInput {
  charges: CreateChargeInput[];
  userId: string;
}


export interface JobUserInput {
  userId: string;
  role: string;
}


export interface UpdateJobsOutput {
  transitionedJobs?: TransitionedJob[];
  isSuccess: boolean;
}


export interface TransitionedJob {
  oldJobId: string;
  newJobId: string;
}


export interface JobsFilter extends ZoneDirection {
  jobIds?: string[];
  jobAncestorIds?: string[];
  stage?: string;
  /**
   * Queries based on any associated user's given name, family name, email,
   * and phone number as well as the job stage and code
   */
  search?: string;
  userSearch?: JobsUserSearchInput;
  chargeIds?: string[];
  transactionIds?: string[];
  appliedTaxes?: string[];
  discountId?: string;
  startLocationSearch?: string;
  endLocationSearch?: string;
  eventIds?: string[];
  tagIds?: string[];
  // Default true
  // onlyGetLatestStage?: boolean;
  // Default false
  getDeleted?: boolean;
}

export interface JobsUserSearchInput {
  userId: string;
  role: string;
}


export interface UpdateJobInput {
  jobId: string;
  /**
   * If set will cause the job to be cloned on the database, preserving the previous state of the job.
   * Possible Values: “estimate”, “quote”, “invoice”
   */
  stage?: string;
  addUsers?: JobUserInput[];
  removeUsers?: JobUserInput[];
  setZone?: string;
  // List of user ids
  newCharges?: NewChargesInput;
  addExistingCharges?: string[];
  removeCharges?: string[];
  attributes?: string[];
  startLocation?: JobLocationInput;
  endLocation?: JobLocationInput;
  depositRequired?: number;
  tagIds?: string[];
  metadata?: Metadata;
  crewSummary?: Summary;
  adminSummary?: Summary;
  customerSummary?: Summary;
  addDiscounts?: {
    discountId: string;
    eventId?: string;
    customAmount?: number;
  }[];
  removeDiscounts?: string[];
  notes?: string;
  eventsOrderChangedByUser?: boolean;
}

export interface JobLocationInput{
  // One
  locationId?: string;
  // or the Other
  newLocationInput?: LocationCreateBase;
  owner?: string;
}


export interface JobsInput {
  filter?: JobsFilter;
  skip?: number;
  limit?: number;
  sort?: string;
  resolve?: string[];
}



export interface CreateJobInput {
  stage?: string;
  users?: JobUserInput[];
  startLocation?: JobLocationInput;
  endLocation?: JobLocationInput;
  // ISO Currency format?: default?: 'CAD'"""
  currency?: string;
  depositRequired?: number;
  metadata?: string;
  newCharges?: NewChargesInput;
  addExistingCharges?: string[];
  timeline?: string;
  timelineDays?: number;
}



export interface UpdateJobsInput {
  updateJobs?: UpdateJobInput[];
}


export interface DeleteJobsInput {
  jobIds?: string[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const JobGQL = {
  zone: `zone { 
    ${zonesBaseGql} 
    parents {
      id
    }
  }`,
  appliedDiscounts: `allAppliedDiscountDetails {
     objectId
     details {
       ${discountDetailsGQL}
     } 
  }`,
  transactions: `transactions {
    ${transactionsBaseGQL}
  }`,
  taxesPaid: `taxesPaid {
    tax {
      ${taxBaseGQL}
    }
    paid
  }`,
  notes: `notes`,
  discount: `discounts{
    ${discountDetailsGQL}
  }`,
};

export const jobsUserGQL = `
    users {
        user { ${usersBaseGQL} }
        role
    }
`;

export const jobChargesGQL = `
    charges {
      id
      product {
        id
        name
        unitLabel
        category
      }
      chargeSubTotal
      discountTotal
      discountedSubTotal
      taxTotal
      total
      amount
      productName
      price {
        id
        amount
        priceType
      }
      user {
        id
      }
      currency
      quantity
      taxes {
        id
      }
      createdAt
      updatedAt
      deletedAt
      attributes
    }
`;

export const jobTransactionGQL = `
    transactions {
      id
    }
`;

export const jobEventGQL = `
    events {
        ${baseCalendarEventsGQL}
        ${calendarEventsAssetsGQL}
        ${calendarEventLocationGQL}
        ${calendarEventTotals}
        topicText
    }
`;

export const jobTagsGQL = `
    tags {
        id
        name
        objectType
        color
        category
        private
        attributes
        order
    }
`;

export const previousStagesGQL = `
    previousStages{
        ${jobsBaseGQL}
    }
`;

export const followingStagesGQL = `
    followingStages{
        ${jobsBaseGQL}
    }
`;

export const jobAccessGQL = `
    accesses {
        ${accessesBaseGQL}
    }
`;

export interface GenerateJobsQueryInput {
  users?: boolean;
  charges?: boolean;
  transactions?: boolean;
  event?: boolean;
  tags?: boolean;
  previousStages?: boolean;
  followingStages?: boolean;
  metadata?: boolean;
  accesses?: boolean;
  zone?: boolean;
  appliedDiscounts?: boolean;
  taxesPaid?: boolean;
  notes?: boolean;
  discount?: boolean;
  fields?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GenerateJobsQuery(input: GenerateJobsQueryInput) {
  const jobsQuery = gql`
        query jobs($filter: JobsFilter, $skip: Int, $limit: Int, $sort: String, $resolve: [String]){
            jobs(filter: $filter, skip: $skip, limit: $limit, sort: $sort, resolve: $resolve){
                total
                skipped
                limited
                jobs{
                    ${jobsBaseGQL}
                    ${input.users ? jobsUserGQL : ``}
                    ${input.charges ? jobChargesGQL : ``}
                    ${input.transactions ? `` : ``}
                    ${input.event ? jobEventGQL : ``}
                    ${input.tags ? jobTagsGQL : ``}
                    ${input.previousStages ? previousStagesGQL : ``}
                    ${input.followingStages ? followingStagesGQL : ``}
                    ${input.metadata ? metaDataGQL : ``}
                    ${input.accesses ? jobAccessGQL : ``}
                    ${input.zone ? JobGQL.zone : ``}
                    ${input.appliedDiscounts? JobGQL.appliedDiscounts : ``}
                    ${input.taxesPaid ? JobGQL.taxesPaid : ``}
                    ${input.notes ? JobGQL.notes : ``}
                    ${input.discount ? JobGQL.discount: ``}
                    ${input.fields ? fieldsBaseGQL : ``}
                }
            }
        }
    `;

  return jobsQuery;
}

export const createJobMutation = gql`
	mutation createJob($stage: String!, $users: [JobUserInput], $startLocation: JobLocationInput, $endLocation: JobLocationInput, $currency: String, $depositRequired: Int, $metadata: Metadata, $newCharges: NewChargesInput, $addExistingCharges: [String], ){
		createJob(stage: $stage, users: $users, startLocation: $startLocation, endLocation: $endLocation, currency: $currency, depositRequired: $depositRequired, metadata: $metadata, newCharges: $newCharges, addExistingCharges: $addExistingCharges, ){
      id
    }		
  }
`;


export const updateJobsMutation = gql`
	mutation updateJobs($updateJobs: [UpdateJobInput], ){
		updateJobs(updateJobs: $updateJobs, ){
			  transitionedJobs {
          oldJobId
          newJobId
        }			  
        isSuccess
	    }		
    }
`;


export const deleteJobsMutation = gql`
	mutation deleteJobs($jobIds: [String]!, ){
		deleteJobs(jobIds: $jobIds, ) 
	}
`;

