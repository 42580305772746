/* eslint-disable max-len */
import { gql } from 'graphql-tag';

import { ZoneDirection } from './common.gql';
import { Metadata } from './metadata';
import { ListZonesOutput, zonesBaseGql, zonesSubGQL } from './zones';

export interface ListTaxesFunctionOutput {
	taxes: ListTaxesOutput;
}

export interface CreateTaxOutput {
	createTax: Tax;
}

export interface UpdateTaxOutput {
	updateTax: boolean;
}

export interface DeleteTaxesOutput {
	deleteTaxes: boolean;
}

export interface Tax {
    id: string;
    createdAt: number;
    updatedAt: number;
    deletedAt: number;
    type: string;
    active: boolean;
    isZoneDefault: boolean;
    percentage: number;
    name: string;
    description?: string;
    attributes: string[];
    metadata?: Metadata[];

	zones: ListZonesOutput;
}

export interface TaxesFilter extends ZoneDirection {
    taxIds?: string[];
    chargeId?: string;
    type?: string;
    isActive?: boolean;
    isZoneDefault?: boolean;
    nameSearch?: string;
    hasAllAttributes?: string[];
    hasAnyAttributes?: string[];
	// Default false
    showDeleted?: boolean;
}

export interface ListTaxesOutput {
    total?: number;
    skipped?: number;
    limited?: number;
    taxes: Tax[];
}

export interface TaxesInput {
	filter?: TaxesFilter;
	skip?: number;
	limit?: number;
}

export interface CreateTaxInput {
	active?: boolean;
	isZoneDefault?: boolean;
	percentage?: number;
	name?: string;
	type?: string;
	description?: string;
	attributes?: string[];
	metadata?: string;
}

export interface UpdateTaxInput {
	taxId?: string;
	active?: boolean;
	isZoneDefault?: boolean;
	name?: string;
	description?: string;
	attributes?: string[];
	metadata?: string;
}

export interface DeleteTaxesInput {
	ids?: string[];
}

export const taxBaseGQL = `
    id
    createdAt
    updatedAt
    deletedAt
    type
    active
    isZoneDefault
    percentage
    name
    description
    attributes
	${zonesSubGQL}
`;

export const taxesQuery = gql`
	query taxes($filter: TaxesFilter, $skip: Int, $limit: Int, ){
		taxes(filter: $filter, skip: $skip, limit: $limit, ){
			    total
			    skipped
			    limited
			    taxes{ 
                    ${taxBaseGQL}
                }
	}		}
`;


export const createTaxMutation = gql`
	mutation createTax($active: Boolean!, $isZoneDefault: Boolean!, $percentage: Float!, $name: String!, $type: String!, $description: String, $attributes: [String], $metadata: Metadata, ){
		createTax(active: $active, isZoneDefault: $isZoneDefault, percentage: $percentage, name: $name, type: $type, description: $description, attributes: $attributes, metadata: $metadata, ){
			    id
			    createdAt
			    updatedAt
			    deletedAt
			    type
			    active
			    isZoneDefault
			    percentage
			    name
			    description
			    attributes
	}		}
`;


export const updateTaxMutation = gql`
	mutation updateTax($taxId: String!, $active: Boolean, $isZoneDefault: Boolean, $name: String, $description: String, $attributes: [String], $metadata: Metadata, ){
		updateTax(taxId: $taxId, active: $active, isZoneDefault: $isZoneDefault, name: $name, description: $description, attributes: $attributes, metadata: $metadata, )
	}
`;


export const deleteTaxesMutation = gql`
	mutation deleteTaxes($ids: [String]!, ){
		deleteTaxes(ids: $ids, ) 
	}
`;

