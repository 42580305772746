import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AutoCompleteCompleteEvent, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { JOB_CREATE_INDEXES } from 'src/app/global.constants';
import { SubSink } from 'subsink';

import { jobToolFeature } from '../../job-tool.reducer';
import { getValidationPattern } from '../../jobsv2-helpers';
import { JobCreateCustomerActions } from '../../jobv2-create/jobv2-create-customer-state/jobv2-create-customer.actions';

@Component({
    selector: 'app-job-search-users',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './job-search-users.component.html',
    styleUrl: './job-search-users.component.scss'
})
export class JobSearchUsersComponent implements OnInit, OnDestroy {

    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() field: string = '';

    value = {};
    isCustomerEditMode: boolean;
    isExistingCustomer = false;

    constructor(
        private store: Store,
    ) { }

    private subs = new SubSink();

    suggestedCustomers = [];
    isFocused: boolean = false;

    validationPattern: string;
    validationErrors: { [key: string]: string };

    tabIndex = 0;

    suggestedCustomers$ = this.store.select(jobToolFeature.selectSuggestedCustomers);
    validationErrors$ = this.store.select(jobToolFeature.selectValidationErrors);
    customerInput$ = this.store.select(jobToolFeature.selectCustomerInput);
    editCustomerWhenCreateJobMode$ = this.store.select(jobToolFeature.selectEditCustomerWhenCreateJobMode);

    ngOnInit(): void {
        this.value = {};

        this.tabIndex = JOB_CREATE_INDEXES[this.field];

        this.subs.sink = this.validationErrors$.subscribe((validationErrors) => {
            this.validationErrors = validationErrors;
        });

        this.subs.sink = this.suggestedCustomers$.subscribe((suggestedCustomers) => {
            if (this.isFocused) {
                this.suggestedCustomers = suggestedCustomers;
            }
        });

        this.subs.sink = this.customerInput$.subscribe((customer) => {
            // this.customer = cloneDeep(customer);
            this.isExistingCustomer = Boolean(customer?.id);
            this.value = { ...customer };
            this.value[this.field] = this.value[this.field] || '';
        });

        this.subs.sink = this.editCustomerWhenCreateJobMode$
            .subscribe((editCustomerWhenCreateJobMode) => {
                this.isCustomerEditMode = editCustomerWhenCreateJobMode;
            });

        this.validationPattern = getValidationPattern(this.field);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    search(event: AutoCompleteCompleteEvent) {
        const value = event?.query || '';

        this.store.dispatch(JobCreateCustomerActions.customerValueInput({
            field: this.field,
            value,
            filter: {
                search: value,
            },
            search: true,
        }));
    }

    edit(event: string) {
        this.store.dispatch(JobCreateCustomerActions.customerValueInput({
            field: this.field,
            value: event,
            search: false,
        }));
    }

    onSelect(event: AutoCompleteSelectEvent) {
        this.store.dispatch(JobCreateCustomerActions.existingCustomerSelected({
            existingCustomer: event.value
        }));
    }

    onClearField() {
        this.store.dispatch(JobCreateCustomerActions.customerValueInput({
            field: this.field,
            value: '',
            search: false,
        }));
    }

    onFocus(): void {
        this.isFocused = true;
    }

    onBlur(): void {
        this.isFocused = false;
    }
}
