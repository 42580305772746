import { Pipe, PipeTransform } from '@angular/core';
import { camelCase, kebabCase } from 'lodash';

import { strToTitleCase } from '../js';

export type CaseOption = 'title' | 'kebab' | 'unkebab' | 'camel' | 'uncamel';

@Pipe({
  name: 'customCase'
})
export class CustomCasePipe implements PipeTransform {

  transform(value: string, options: CaseOption[]): string {
    let result = value;
    for (const option of options) {
      switch(option) {
        case 'title':
          result = strToTitleCase(result);
          break;
        case 'kebab':
          result = kebabCase(result);
          break;
        case 'unkebab':
          result = result.replace(/-/g, ' ');
          break;
        case 'camel':
          result = camelCase(result);
          break;
        case 'uncamel':
          result = result.split(/(?=[A-Z])/).join(' ');
          break;
      };
    };
    return result;
  }

}
