import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../freya-common/freya-common.module';

@Component({
  selector: 'app-karve-tabview',
  standalone: true,
  imports: [
    FreyaCommonModule
  ],
  templateUrl: './karve-tabview.component.html',
  styleUrls: ['./karve-tabview.component.scss',]
})
export class KarveTabviewComponent implements OnInit, OnDestroy {
  @Input() tabs: {
    name: string;
    slug: string;
  }[];
  @Input() baseUrl: string;

  selectedTab: string;


  subs = new SubSink();
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.watchRouteChange();
  }

  private watchRouteChange(): void {

    this.subs.sink = this.route.firstChild?.url.subscribe(urlSegment => {
      const tab = urlSegment[0].path;

      // Get the last part of the URL
      if (!this.selectedTab || tab !== this.selectedTab) {
        this.selectedTab = tab;
      }
    });
  }

  public goToRoute(tab: any): void {
    this.router.navigate([this.baseUrl, tab.value]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
