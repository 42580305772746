<div class="card">
    <div class="p-grid">
        <div class="p-col-12">
            <h4>Notifications</h4>
        </div>
        <div class="p-col-12 p-md-4">
            <label>Received</label>
            <br />
            <p-dropdown
                optionLabel="label"
                optionValue="option"
                [(ngModel)]="selectedReceived"
                styleClass="full-width"
                [options]="periodOptions"
                (onChange)="retrieveNotifications()"
            ></p-dropdown>
        </div>
    </div>
</div>

<div class="card notifications-card" *ngIf="!responsiveHelper.isSmallScreen">
    <div
        class="table-col"
        [ngStyle]="
            notificationsService?.latest?.length ? tableSeparator : undefined
        "
    >
        <ng-container *ngTemplateOutlet="table"></ng-container>
    </div>
    <div
        class="content-col"
        [ngStyle]="
            !notificationsService?.latest?.length ? contentSeparator : undefined
        "
    >
        <ng-container *ngIf="notificationSelected">
            <router-outlet></router-outlet>
        </ng-container>
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    </div>
</div>

<div class="card" *ngIf="responsiveHelper.isSmallScreen">
    <ng-container *ngTemplateOutlet="table"></ng-container>
    <div *ngIf="!notificationsService.latest?.length">
        <ng-container *ngTemplateOutlet="placeholder"></ng-container>
    </div>
    <div [class.hide]="responsiveHelper.isSmallScreen && !notificationSelected">
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #table>
    <p-table
        [value]="notifications"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreNotifications($event)"
        [lazyLoadOnInit]="true"
        [totalRecords]="notificationsQH.total"
        [lazy]="true"
        [(rows)]="notificationsQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="notificationsQH.loading"
        [showCurrentPageReport]="!notificationsQH.loading"
        breakpoint="620px"
        [style]="{ width: '28rem' }"
        [class.hide]="responsiveHelper.isSmallScreen && notificationSelected"
    >
        <ng-template pTemplate="body" let-notification>
            <tr
                class="selectable-row"
                [routerLink]="['/notifications', notification.id]"
                [routerLinkActive]="['active-row']"
            >
                <td
                    class="notification-data"
                    [class.read]="notification | notificationRead"
                >
                    <div>
                        <span class="title-label">
                            {{ notification.title }}
                        </span>
                        <p-chip
                            *ngIf="!(notification | notificationRead)"
                            label="New"
                            styleClass="p-ml-2"
                        ></p-chip>
                    </div>
                    <span class="created-at-label">
                        {{ notification | notificationDate }}
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>

<ng-template #placeholder>
    <div
        *ngIf="!notificationSelected"
        class="notifications-placeholder-wrapper"
    >
        <div class="notifications-placeholder">
            <i class="pi pi-inbox"></i>
            <div class="label-wrapper">
                <div class="label">
                    <p *ngIf="notificationsService.latest?.length">
                        Select a notification to view it here
                    </p>
                    <p *ngIf="!notificationsService.latest?.length">
                        You have no notifications
                    </p>
                    <small>
                        Whenever an event is booked, rescheduled, or cancelled,
                        a notification will be sent to customers and
                        <a
                            [routerLink]="['/franchise', 'info']"
                            fragment="job-watchers"
                            >job watchers</a
                        >.
                    </small>
                </div>
            </div>
        </div>
    </div>
</ng-template>
