import { Component } from '@angular/core';

import { formatCurrency } from '../../lib.ts/currency.util';
import { DashboardStore } from '../dashboardv2.store';

@Component({
  selector: 'app-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './event-summary.component.scss',
  ],
})
export class EventSummaryComponent {

  format = (data) => {

    const { headers, rows } = data;

    const currencyIndex = headers.indexOf('Currency');

    const formattedHeaders = headers.slice(0, currencyIndex);

    if (!data.rows.length) {
      return {
        headers: formattedHeaders,
        rows: [],
      }
    }

    const [ firstRow ] = rows;

    const currency = firstRow[currencyIndex];

    const formattedRows: string[][] = [];

    let subTotalSum = 0;
    let eventCountSum = 0;

    for (const [ eventType, netSubTotalRaw, eventCount, eventTypeAverageRaw ] of rows) {

      const netSubTotal = parseFloat(netSubTotalRaw);
      const eventTypeAverage = parseFloat(eventTypeAverageRaw);

      formattedRows.push([
        eventType,
        formatCurrency(netSubTotal, currency),
        eventCount,
        formatCurrency(eventTypeAverage, currency),
      ]);

      subTotalSum += netSubTotal;
      eventCountSum += parseInt(eventCount);
    }

    const average = eventCountSum ? subTotalSum / eventCountSum : 0;

    formattedRows.push([
      'Total',
      formatCurrency(subTotalSum, currency),
      eventCountSum.toString(),
      formatCurrency(average, currency),
    ]);

    return {
      headers: formattedHeaders,
      rows: formattedRows
    }
  };

  events$ = this.dashboardStore.eventsViewModel$;

  constructor(
    private readonly dashboardStore: DashboardStore,
  ) {}

}
