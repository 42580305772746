import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() requireTouched = true;
  @Input() fieldName = 'This field';

  constructor() { }

  ngOnInit(): void {
  }

}
