import { Injectable, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import printJS from 'print-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseInvoiceFragment, ResendDocumentGQL, ResendDocumentMutationVariables, SendInvoicesGQL, TransactionsGQL } from '../../generated/graphql.generated';

import { determineArtifactType } from '../artifacts/artifact.util';
import { PandaDocService } from '../features/public-api';
import { Artifact } from '../interfaces/artifacts';
import { isFinalizedInvoice } from '../invoices/invoices.utils';
import { remainingBalance } from '../jobs/jobs.util';
import { ApplyTransactionComponent } from '../transactions/apply-transaction/apply-transaction.component';
import { getJobCustomer } from '../utilities/job-customer.util';

import { SendDocumentComponentInput, SendDocumentsComponent } from './../shared/send-documents/send-documents.component';
import { DetailsHelperService } from './details-helper.service';
import { FreyaHelperService, ViewableArtifact } from './freya-helper.service';
import { FreyaMutateService } from './freya-mutate.service';
import { FreyaNotificationsService } from './freya-notifications.service';
import { ResponsiveHelperService } from './responsive-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentHelperService implements OnDestroy {

  sendDocumentsDialogInUseForJob = new BehaviorSubject<string | null>(null);

  processingDocuments = new BehaviorSubject<boolean>(false);

  sendDocumentsdialogMaximized = new Subject<void>();

  subs = new SubSink();

  constructor(
    private localNotify: FreyaNotificationsService,
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private pandadocService: PandaDocService,
    private resendDocumentGQL: ResendDocumentGQL,
    private sendInvoicesGQL: SendInvoicesGQL,
    private detailsHelper: DetailsHelperService,
    private freyaMutate: FreyaMutateService,
    private confirmationService: ConfirmationService,
    private transactionsGQL: TransactionsGQL,
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openDocumentsDialog(input: SendDocumentComponentInput, openToStep?: number) {
    this.subs.sink = this.sendDocumentsDialogInUseForJob.pipe(take(1)).subscribe((jobId) => {

      if (jobId && input.jobId === jobId) {
        this.sendDocumentsdialogMaximized.next();
        return;
      }

      if (jobId && input.jobId !== jobId) {
        const summary = 'The documents dialog is being used for another job';
        const details = 'Please close the other documents dialog before opening a new one.';
        this.localNotify.error(summary, details);
        return;
      }

      const ref = this.dialogService.open(SendDocumentsComponent, {
        showHeader: false,
        data: { input, openToStep },
        width: this.responsiveHelper.dialogWidth,
        styleClass: 'send-document-dialog',
        contentStyle: {
          'padding-bottom': 0,
        },
      });

      this.sendDocumentsDialogInUseForJob.next(input.jobId);

      this.subs.sink = ref.onClose.subscribe(() => this.sendDocumentsDialogInUseForJob.next(null));
    });
  }

  printDocument(artifact: ViewableArtifact) {
    const printableType = this.getPrintableType(artifact);

    if (!printableType) { return; }

    printJS({
      printable: artifact.signedUrl || artifact.url,
      type: printableType,
      onError: (err) => this.localNotify.error(err)
    });
  }

  getPrintableType(artifact: ViewableArtifact) {
    if (!artifact.contentType) {
      return false;
    }

    const [_content, docType] = artifact.contentType.split('/');
    if (docType === 'pdf') {
      return 'pdf';
    }
    if (determineArtifactType(artifact.contentType) === 'Image') {
      return 'image';
    }
    return false;
  }

  downloadDocument(artifact: ViewableArtifact) {
    if (!artifact) { return; }
    const url = artifact.signedUrl || artifact.url;
    this.freyaHelper.downloadFile(url, artifact.name);
  }

  /**
   * Open a document in pandadoc
   */
  openInPandadoc(artifact: ViewableArtifact, role: 'customer' | 'employee') {
    this.subs.sink = this.pandadocService.getDocumentLink({ artifactId: artifact.id, role }).subscribe((res) => {
      this.freyaHelper.openInDialog(artifact, res.data.getPandadocDocumentLink);
    }, (err) => {
      this.localNotify.apolloError('Failed to open document', err);
    });
  }

  resendDocument(artifact: Artifact) {

    const resendDocumentInput: ResendDocumentMutationVariables = {
      artifactId: artifact.id,
      subject: artifact.name,
      message: artifact?.metadata?.message,
    };

    this.resendDocumentGQL.mutate(resendDocumentInput)
      .subscribe(() => {
        this.localNotify.success('Document resent to customer');
      }, (err) => {
        this.localNotify.apolloError(`Could not resend document`, err);
        console.error(err);
      });
  }

  confirmSendInvoice<T extends BaseInvoiceFragment>(invoice: T) {

    if (isFinalizedInvoice(invoice)) {
      this.sendInvoice(invoice.id);
    } else {
      this.showSendInvoiceConfirmation(invoice.id);
    }
  }

  showSendInvoiceConfirmation(invoiceId: string) {

    const message = `Sending an invoice will finalize it. `
      + `You will not be able to make any further charges on any invoiced events. `;


    this.freyaHelper.confirmWithLoading({
      header: 'Send Invoice?',
      message,
      acceptLabel: 'Send and Finalize',
      acceptIcon: 'pi pi-send',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      acceptObservable: this.sendInvoicesGQL.mutate({ invoiceIds: [invoiceId] }),
      onAcceptSuccess: () => {

        this.localNotify.success('Invoice sent');

        this.detailsHelper.pushUpdate({
          id: invoiceId,
          type: 'Invoice',
          action: 'update',
        });

      },
      onAcceptErr: (err) => {
        this.localNotify.apolloError('Failed to send invoice', err);
      }
    });
  }

  sendInvoice(invoiceId: string) {

    this.localNotify.info('Sending...', 'This may take a moment');

    this.sendInvoicesGQL.mutate({ invoiceIds: [invoiceId] }).subscribe((res) => {

      this.localNotify.success('Invoice sent');

      this.detailsHelper.pushUpdate({
        id: invoiceId,
        type: 'Invoice',
        action: 'update',
      });

    }, ((err) => {
      this.localNotify.apolloError('Failed to send invoice', err);
    }));

  }

  openCreateInvoiceDialog(jobId: string) {
    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'invoice',
      additionalValues: [
        {
          property: 'jobId',
          value: jobId,
        }
      ],
    });
  }

  async payInvoice(
    invoice: BaseInvoiceFragment,
    defaultAmount?: number,
  ) {

    const { transactionsWithAvailability, transactionsAvailable } = await this.getTransactionsAvailableForInvoice(invoice);

    if (transactionsAvailable) {
      this.dialogService.open(ApplyTransactionComponent, {
        header: 'Apply Existing Transaction to Invoice?',
        width: this.responsiveHelper.dialogWidth,
        data: { transactionsWithAvailability, invoice },
        contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
      });
    } else {
      this.openCreateTransactionsDialogForInvoice(invoice, defaultAmount);
    }

  }

  openCreateTransactionsDialogForInvoice(
    invoice: BaseInvoiceFragment,
    defaultAmount?: number,
  ) {
    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'transaction',
      additionalValues: [
        {
          property: 'job',
          value: invoice.job,
        },
        {
          property: 'user',
          value: getJobCustomer(invoice.job.users, false)
        },
        {
          property: 'defaultAmount',
          value: defaultAmount || remainingBalance(invoice, false),
        },
        {
          property: 'invoice',
          value: invoice,
        },
      ]
    });

  }

  async getTransactionsAvailableForInvoice(invoice: BaseInvoiceFragment) {

    const jobTransactions$ = this.transactionsGQL.fetch({
      filter: {
        jobId: invoice.job.id,
        showDeleted: false,
      },
      limit: -1,
    }).pipe(
      map(res => res.data.transactions.transactions),
    );

    const transactions = await jobTransactions$.toPromise();

    return this.freyaHelper.getTransactionsAvailableForInvoice(invoice, transactions);
  }
}
