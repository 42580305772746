import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { WatchQueryOptionsAlone } from 'apollo-angular/types';
import { SubSink } from 'subsink';

import { ConfigValueInput, GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { CUSTOMER_TYPES, DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS, JOB_ORIGINS } from '../../global.constants';
import { safeParseJSON } from '../../js';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { EditData, EditableListComponent } from '../../shared/editable-list/editable-list.component';
import { getParsedConfigValueByKey } from '../../utilities/configs.util';

@Component({
  selector: 'app-how-heard-options',
  templateUrl: './how-heard-options.component.html',
  styleUrls: ['./how-heard-options.component.scss']
})
export class HowHeardOptionsComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  getconfigsQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  howHeardOptions: string[] = [];

  jobOriginOptions: string[] = [];

  customerTypeOptions: string[] = [];

  canEdit: boolean;

  constructor(
    private getConfigValues: GetConfigValuesGQL,
    private setConfigValues: SetConfigValuesGQL,
    private localNotify: FreyaNotificationsService,
    private permissions: PermissionService,
  ) { }

  ngOnInit(): void {
    this.retrieveConfigs();
    this.checkPermissions();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async checkPermissions() {
    this.subs.sink = this.permissions.watch().subscribe(() => {
      this.canEdit = this.permissions.checkPermission('configs.set');
    });
  }

  retrieveConfigs() {
    if (this.getconfigsQueryRef) {
      this.getconfigsQueryRef.refetch();
      return;
    }

    const opts: WatchQueryOptionsAlone<GetConfigValuesQueryVariables, any> = { fetchPolicy: 'cache-and-network' };

    this.getconfigsQueryRef = this.getConfigValues.watch({ keys: [
      'jobs.howHeardOptions',
      'jobs.origins',
      'customer.types',
    ] }, opts);

    this.subs.sink = this.getconfigsQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      const values = res.data.getConfigValues;

      this.howHeardOptions = getParsedConfigValueByKey(values, 'jobs.howHeardOptions', DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS);
      this.jobOriginOptions = getParsedConfigValueByKey(values, 'jobs.origins', JOB_ORIGINS);
      this.customerTypeOptions = getParsedConfigValueByKey(values, 'customer.types', CUSTOMER_TYPES);
    });

  }

  mutateConfigs(
    editData: EditData,
    editableList: EditableListComponent,
    key: string,
  ) {

    const { items, editType } = editData;
    const configInput: ConfigValueInput = {
      key,
      value: JSON.stringify(items),
    };

    this.setConfigValues.mutate({ configs: [configInput] })
      .subscribe(() => {

        switch (editType) {
          case 'delete':
            editableList.closeDeleteDialog();
            this.localNotify.success('Item(s) deleted');
            break;
          case 'add':
            editableList.closeAddDialog();
            this.localNotify.success('Item added');
            break;
          case 'edit':
            editableList.closeEditDialog();
            this.localNotify.success('Item edited');
            break;
        }

        this.retrieveConfigs();
      });
  }

}
