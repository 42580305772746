import { AbstractControl } from '@angular/forms';
import {dayjs} from '@karve.it/core';
import { default as cronstrue } from 'cronstrue';

import { AdvancedPeriodOptions, isPeriod } from './reports.constants';

export function periodToString(
	period: AdvancedPeriodOptions,
  variables: any,
) {
    variables = variables || {};
    if (isPeriod(period)) {
      return period;
    }

    if (period === 'Custom') {
      const start = dayjs.tz(variables.start * 1000);
      const end = dayjs.tz(variables.end * 1000);

      const format = `YYYY/MM/DD HH:mm`;
      const strStart = start.format(format);
      const strEnd = end.format(format);
      if (strStart === strEnd) { return strStart; }

      return `${ strStart } - ${ strEnd }`;
    }

    return period;
}

export function cronValidator(control: AbstractControl): { cron: string } | null {

  const time = control.value;
  if (!time) {
    return { cron: 'not set' };
  }

  try {
    cronstrue.toString(time, {
      throwExceptionOnParseError: true,
    });

    return null;
  } catch (e) {
    return {
      cron: e.message,
    };
  }

}
