import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlusAuthenticationService } from '@karve.it/core';
import { GenerateSecretOutput, RawUser } from '@karve.it/interfaces/auth';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { AuthMethod } from '../../../generated/graphql.generated';
import { AuthMethodWithClientData } from '../../shared/auth/auth.component';

@Component({
  selector: 'app-two-factor-setup',
  templateUrl: './two-factor-setup.component.html',
  styleUrls: ['./two-factor-setup.component.scss']
})
export class TwoFactorSetupComponent implements OnInit, OnDestroy {

  @Input() user: RawUser;

  subs = new SubSink();

  twoFactorSecret: GenerateSecretOutput;

  twoFactorToken = '';
  password: string = undefined;

  activeTab: number = undefined;

  showDisable2FADialog = false;
  showEnable2FADialog = false;

  imageLoaded = false;
  queryLoading = false;

  get googleAuthenticatorLink() {
    const os = this.getMobileOperatingSystem();
    if (os === 'Android') {
        return 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US';
    } else if (os === 'iOS') {
        return 'https://apps.apple.com/ca/app/google-authenticator/id388497605';
    } else {
      return 'https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345';
    }
  }

  constructor(
    private plusAuth: PlusAuthenticationService,
    private clipboard: Clipboard,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }

  generate2FASecret(){
    this.subs.sink = this.plusAuth.generateSecret().subscribe((res) => {
      this.twoFactorSecret = res.data.generateSecret;
      this.activeTab = 1;
    });
  }

  confirmSecret(authMethod: AuthMethodWithClientData) {

    const form = authMethod?.passwordForm?.value;
    if (form?.twoFactorCode) {
      this.twoFactorToken = form.twoFactorCode;
    }

    this.subs.sink = this.plusAuth.confirmSecret({
      token: this.twoFactorToken.toString()
    }).subscribe((res) => {
      if (res.data.confirmSecret) {
        this.localNotify.addToast.next({severity: 'success', summary: '2FA has been activated'});
        this.showEnable2FADialog = false;
        this.queryLoading = false;
        this.user.twoFactorEnabled = true;
      } else {
        this.localNotify.addToast.next({severity: 'error', summary: 'Code is not valid please try a newer code'});
        this.twoFactorToken = '';
        this.queryLoading = false;
      }
    }, (err) => {
      console.error(err);
    });

    this.twoFactorToken = '';
  }


  startEnable2FA() {
    this.showEnable2FADialog = true;
    this.generate2FASecret();
  }

  startDisable2FA() {
    this.showDisable2FADialog = true;
  }

  disable2FA() {
    this.subs.sink = this.plusAuth.disable2FA({})
    .subscribe((res) => {
      this.activeTab = undefined;
      this.showDisable2FADialog = false;
      this.showEnable2FADialog = false;
      this.queryLoading = false;
      this.user.twoFactorEnabled = false;
      this.localNotify.addToast.next({severity: 'success', summary: '2FA has been disabled'});
    }, (err) => {
      let message = '';

      if (err.graphQLErrors[0].message.indexOf('Token') >= 0) {
        message = '2FA code is not valid';
      } else if(err.graphQLErrors[0].message.indexOf('Password') >= 0){
        message = 'Password is not valid';
      }

      this.localNotify.addToast.next({severity: 'error', summary: message});
      this.queryLoading = false;
    });

    this.twoFactorToken = '';
  }

  copyCode(){
    this.clipboard.copy(this.twoFactorSecret.secret);
    this.localNotify.addToast.next({severity: 'success', summary: '2FA seed copied'});
  }

  getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
          return 'Android';
      }

      // @ts-ignore
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS';
      }
      return 'unknown';
  }

}
