<p-panel
    [toggleable]="true"
    [collapsed]="true"
>
    <ng-template pTemplate="header">
        <h6 class="no-margin">
            <i class="pi pi-map-marker"></i>

            <span [ngSwitch]="eventLocation.type">
                <!-- EventLocation name display 'Dock' instread basestart/baseend -->
                <span *ngSwitchCase="'basestart'">
                    {{eventLocation|eventLocationName | titlecase}} : {{eventLocation.arrivalTime | freyaDate: "h:mm a "
                    }}
                </span>
                <span *ngSwitchCase="'baseend'">
                    {{eventLocation|eventLocationName | titlecase }} : {{eventLocation.arrivalTime | freyaDate: "h:mm a
                    " }}
                </span>
                <span *ngSwitchDefault>
                    {{ eventLocation.type | titlecase }} : {{eventLocation.arrivalTime| freyaDate: "h:mm a "}}
                </span>
            </span>


            <p
                class="freya-link-button"
                (click)="detailsHelper.open('location',
                eventLocation.location)"
            >
                <br />
                {{eventLocation.location?.addressLineTwo ? eventLocation.location?.addressLineTwo + ' - ' : '' }}{{ eventLocation?.location?.addressLineOne }}
            </p>
        </h6>
    </ng-template>
    <div class="locaiton-card-body">
        <p>
            Arrival time:
            <b>{{eventLocation.arrivalTime | freyaDate: "h:mm a" }}
            </b>
        </p>

        <p>
            Time at location:
            <b>{{
                eventLocation.estimatedTimeAtLocation | secondsToTimeUnit
                }}
            </b>
        </p>
        <p *ngIf="eventLocation?.travelTimeToNextLocation">
            Departure time:
            <b>{{departureTime | freyaDate: "h:mm a "}}
            </b>
        </p>
<!-- 
        <p *ngIf="calculatedTime &&
            calculatedTime !== eventLocation.estimatedTimeAtLocation">
            Calculated Time At Location: {{ calculatedTime }}
        </p> -->

        <p>
            <span *ngIf="eventLocation?.travelTimeToNextLocation">
                <hr>
                <p>
                    Distance to next location:
                    <b>{{ eventLocation | distance }}</b>
                </p>
                <p>
                    Travel time to next location:
                    <b>{{ eventLocation.travelTimeToNextLocation | secondsToTimeUnit
                        }}
                    </b>
                    <span *ngIf="eventLocation?.travelTimeToNextLocationOffset"><b>
                            {{
                            eventLocation?.travelTimeToNextLocationOffset
                            | secondsToTimeUnit : true
                            }}
                            offset</b></span>
                </p>
            </span>
        </p>

        <small >All times are estimates based on event information</small>
    </div>
</p-panel>