import {ApolloError} from '@apollo/client/core';


export interface WatchQueryHelper {
    limit?: number;
    skip?: number;
    total?: number;
    loading?: boolean;
    search?: string;
    error?: ApolloError;
    increment?: number;
    hasMore?: boolean;
    sort?: string[];
    mergeNextResults?: boolean;
    isFirstLoad?: boolean;
}

export function initQueryHelper(base?: Partial<WatchQueryHelper>): WatchQueryHelper {
    return {
        loading: true,
        hasMore: false,
        total: undefined,
        skip: 0,
        limit: 50,
        increment: 50,
        sort: [ 'createdAt:ASC' ],
        ...base,
    };
}

export function findSort(
    sort: string[],
    searchTitle: string,
) {
    const index = sort.findIndex((s) => {
        const [ sTitle ] = s.split(':');
        return sTitle === searchTitle;
    });
    if (index < 0) {
        return {};
    }

    const value = sort[index];
    const [ title, dir ] = value.split(':');

    return {
        value,
        title,
        dir: dir || 'ASC',
        index,
    };
}

/**
 * Mutate query helper and toggle its sorting value for the
 * specified property. IE turn sort: [ 'createdAt:ASC' ]
 * into [ 'createdAt:DESC' ]
 *
 * @param queryHelper query helper to mutate
 * @param propertyName the property name to toggle
 * @returns the new sort value
 */
export function toggleSort(
    queryHelper: WatchQueryHelper,
    propertyName: string,
) {

    let sort = queryHelper?.sort || [ 'createdAt:DESC' ];
    const {
      index,
      title: foundTitle,
      dir,
      value,
    } = findSort(sort, propertyName);

    let newDir = 'ASC';
    if (value) {
      newDir = dir === 'ASC' ? 'DESC' : 'ASC';
      // clone the array to trigger change detection
      sort = [ ...sort ];
      sort[index] = `${ propertyName }:${ newDir }`;
    } else {
      sort = [ `${ propertyName }:${ newDir }`, ...sort ];
    }
    queryHelper.sort = sort;

    return sort;
}


// import { QueryRef } from 'apollo-angular';
// import { ApolloQueryResult } from 'apollo-client';
// import { PropType } from '../../typescript';

// export interface BaseVariables {
//     filter?: any;
//     skip: number;
//     limit: number;
// }

// export interface ListQueryRef<
//     K extends string,
//     Output extends { [ key in K ]: any },
//     Variables extends BaseVariables = any,
// > {
//     limit: number;
//     skip: number;
//     total?: number;
//     loading?: boolean;
//     search?: string;

//     /**
//      * When "loading more" how much more should we load?
//      */
//     increment?: number;

//     /**
//      * Based on the last networked result, is there more
//      * data to be loaded?
//      */
//     hasMore?: boolean;
//     filter?: PropType<Variables, 'filter'>;
//     ref?: QueryRef<Output, Variables>;

//     paginationKey: K;
//     data?: PropType<Output, K>;
// }

// export function genListQueryRef<
//     Output extends { [ key in K ]: any },
//     Variables extends BaseVariables,
//     K extends string,
//     Ref = ListQueryRef<K, Output, Variables>,
// >(
//     paginationKey: K,
//     base: Partial<Ref> = {},
// ) {
//     const finalBase = {
//         limit: 50,
//         skip: 0,
//         paginationKey,
//         loading: true,
//         hasMore: true,
//         total: undefined,
//         ...base,
//     } as unknown as Ref;

//     return finalBase;
// }

// /**
//  * Call this function first thing in your value changes
//  */
// export function processListQueryRef<
//     K extends string,
//     Output extends { [ key in K ]: any },
//     Variables extends BaseVariables = any,
// >(
//     listQueryRef: ListQueryRef<K, Output, Variables>,
//     res: ApolloQueryResult<Output>,
// ) {
//     listQueryRef.loading = res.loading;
//     const data = res.data[listQueryRef.paginationKey];
//     listQueryRef.data = data;
//     listQueryRef.loading = data.loading;


//     // TODO:

// }
