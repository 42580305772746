
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';

export const calendarPlugins = [
    dayGridPlugin,
    timeGridPlugin,
    resourceTimelinePlugin,
    interactionPlugin,
];
