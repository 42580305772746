import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DetailsHelperService } from '../../services/details-helper.service';
import { isValidUrl, parseEmbeddedUrl } from '../../utilities/generic.util';
import { ColumnDef } from '../reports.constants';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss']
})
export class ReportDataComponent implements OnInit {

  @Input() content = '';
  @Input() column = '';
  @Input() csvColumnInfo?: ColumnDef;
  @Input() row?: string[];

  contentType: 'text' | 'url' | 'link' | 'urlList';
  currency?: string;

  name: string;
  url: string;
  urlList: { before: string; url: string, after?: string }[];

  constructor(
    private router: Router,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {

    const urlList = this.getUrlList();

    if (urlList.length) {
      this.urlList = urlList;
      this.contentType = 'urlList';
      return;
    }

    if (isValidUrl(this.content)) {
      this.url = new URL(this.content).pathname;
      this.contentType = 'url';
      return;
    }

    const parsed = parseEmbeddedUrl(this.content);

    if (!parsed) {
      this.contentType = 'text';
      return;
    }

    const { before: embeddedName, url: embeddedUrl } = parsed;
    this.name = embeddedName;
    this.url = embeddedUrl;
    this.contentType = 'link';
  }

  getUrlList() {

    const segments = this.content.split(', ');

    const urlList: typeof this.urlList = [];

    for (const segment of segments) {
      const parsed = parseEmbeddedUrl(segment);
      if (!parsed) { continue; }
      const { before, url, after } = parsed;
      urlList.push({
        before,
        url,
        after,
      });
    }

    return urlList;
  }

  navigate(url) {
    this.router.navigate([ url ]);
  }

  openDetails(url: string) {

    const [ _blank, objectType, objectId ] = url.split('/');

    switch (objectType) {
      case 'user':
        this.detailsHelper.open('users', { id: objectId });
        break;
      case 'job':
        this.detailsHelper.open('job', { id: objectId });
        break;
      default:
        console.warn('Unable to open details for object type: ', objectType);
    }
  }

}
