import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { jobToolFeature } from "../../job-tool.reducer";
import { SubSink } from "subsink";
import { selectFullNameSelected, selectHowHeardSelected, selectJobSavingErrors, selectRequiredLocationDetailsSelected } from "../jobv2-create-state/jobv2-create.selectors";
import { JobEditActions } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions";
import { selectJobUpdatingErrors } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors";
import { Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { JobV2FormActionButtonComponent } from "../jobv2-form-action-button/jobv2-form-action-button";

@Component({
  selector: 'app-jobv2-create-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
    JobV2FormActionButtonComponent,
  ],
  templateUrl: './jobv2-create-header.component.html',
  styleUrls: ['./jobv2-create-header.component.scss']
})
export class Jobv2CreateHeaderComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store,
    public router: Router,
  ) { }

  private subs = new SubSink();

  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  jobInput$ = this.store.select(jobToolFeature.selectJobInput);

  jobCode: string;
  jobId: string;
  editMode: boolean;

  //error handling
  jobSavingErrors$ = this.store.select(selectJobSavingErrors);
  errorMessages = [
    'Errors occurred, job could not be created. Resolve these errors and try again.'
  ];

  jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
  errorUpdateMessages = [
    'Errors occurred, job could not be updated. Resolve these errors and try again.'
  ]

  breadcrumb: MenuItem[];
  home: MenuItem;

  ngOnInit(): void {
    this.subs.sink = this.jobInput$.subscribe((jobInput) => {
      this.jobCode = jobInput?.code;
      this.jobId = jobInput.id;
    });

    this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
      this.editMode = jobFormMode === 'edit';

      this.setBreadcrumb(this.editMode);
    });

    this.subs.sink = this.jobSavingErrors$.subscribe((jobSavingErrors) => {
      const defaultError = 'Errors occurred, job could not be created. Resolve these errors and try again.'
      this.errorMessages = [];

      this.errorMessages.push(defaultError);

      jobSavingErrors.forEach(error => {
        this.errorMessages.push(error);
      });
    });

    this.subs.sink = this.jobUpdatingErrors$.subscribe((jobUpdatingErrors) => {
      const defaultError = 'Errors occurred, job could not be updated. Resolve these errors and try again.'
      this.errorUpdateMessages = [];

      this.errorUpdateMessages.push(defaultError);

      jobUpdatingErrors.forEach(error => {
        this.errorUpdateMessages.push(error);
      });
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setBreadcrumb(editMode: boolean) {

    if (editMode) {
      this.breadcrumb = [
        { label: 'Jobs', routerLink: '/jobs'},
        { label: this.jobCode, routerLink: `/jobs/${this.jobId}`},
      ];
    } else {
      this.breadcrumb = [
        { label: 'Jobs', routerLink: '/jobs'},
        { label: 'New Opportunity', routerLink: `/jobs/new`},
      ];
    }

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  cancelUpdating() {
    this.store.dispatch(JobEditActions.discardChanges());
    this.router.navigate([`/jobs/${this.jobId}/overview`]);
  }
}