import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlusAuthenticationService, TokenService } from '@karve.it/core';
import { Message } from 'primeng/api';
import { FREYA_ROUTES } from 'src/app/global.constants';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

import { ListAuthMethodsGQL, RequestPasswordResetGQL, ResetPasswordGQL } from '../../../generated/graphql.generated';

import { BrandingService } from '../../services/branding.service';
import { AuthInitialState } from '../../shared/auth/auth.component';
import { EMAIL_VALIDATION_PATTERN } from '../../shared/pattern.validator';
import { matchValidator } from '../match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../auth.styles.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  loginPath: string = FREYA_ROUTES.login;

  loginMessages: Message[] = [];
  tokenInvalid = false;

  emailSent = false;

  resetInProgress = false; // True if we are mid update

  /**
   * Auth method ID resolved by auth token
   */
  authMethodId?: string;

  sendRequestForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATION_PATTERN), Validators.required, Validators.minLength(3)]),
  });
  resetForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATION_PATTERN), Validators.required, Validators.minLength(3)]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(environment.passwordMinLength)]),
    passwordConfirm: new UntypedFormControl('', [Validators.required]),
    token: new UntypedFormControl('', Validators.required),
  }, [matchValidator('password', 'passwordConfirm'),]);

  get resetToken() {
    return this.resetForm.get('token').value;
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private notify: FreyaNotificationsService,
    private tokenService: TokenService,
    private plusAuth: PlusAuthenticationService,
    private requestResetGQL: RequestPasswordResetGQL,
    private resetPasswordGQL: ResetPasswordGQL,
    public brandingService: BrandingService,
    public listAuthMethods: ListAuthMethodsGQL,
  ) { }

  ngOnInit(): void {
    this.tokenInvalid = false;

    // Check for Token
    this.subs.sink = this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.sendRequestForm.get('email').setValue(params.email);
      }
      if (!params.token) {
        return;
      }
      const token = params.token;
      this.removeTokenFromURL();

      this.resetForm.get('token').setValue(token);
      this.validateResetToken();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Uses the token from the url query that was
   * placed in the reset form and validates that
   * it is "valid" - ie, exists, is not expired, and hasn't been
   * used by calling the database.
   *
   * The database then responds with some metadata about the token
   * such as the email address used for the account so the user can
   * login automatically after resetting the password.
   */
  validateResetToken() {
    this.subs.sink = this.tokenService.validateToken({ token: this.resetForm.get('token').value })
      .subscribe((res) => {
        if (!res.data.validateToken.isValid) {
          // this.tokenInvalid = true;
          this.resetForm.reset();
          this.tokenInvalid = true;
          this.loginMessages = [{
            severity: 'error',
            summary: 'Invalid token',
            detail: 'Please request a new one'
          }];
          return;
        }
        console.log(res);
        const metadata = res.data.validateToken.metadata;
        const loginMeta: string = metadata?.login;
        this.authMethodId = metadata?.authMethodId;
        if (!this.authMethodId) {
          this.resetForm.reset();
          this.tokenInvalid = true;
          this.loginMessages = [{
            severity: 'error',
            summary: 'Invalid token:',
            detail: 'Please request a new one; no auth method ID returned'
          }];
        }

        this.checkAuthMethods();
        console.log(loginMeta);
        if (loginMeta) {
          this.resetForm.get('email').setValue(loginMeta);
        } else {
          this.loginMessages = [{
            severity: 'warning',
            summary: 'Cannot resolve email from token',
            detail: 'Please contact support'
          }];
        }
      });
  }

  /**
   * Resolve minimum length from auth method
   */
  checkAuthMethods() {
    this.subs.sink = this.listAuthMethods.fetch({}, {
      fetchPolicy: 'cache-first',
    }).subscribe((res) => {
      if (!this.authMethodId) {
        console.warn(`Auth method ID is not set`, res);
        this.loginMessages.push({
          severity: 'warning',
          summary: 'Cannot resolve auth method from token',
          detail: 'Please contact support',
        });
        return;
      }
      const authMethods = res.data?.authMethods;
      if (!authMethods) {
        console.warn(`No auth methods returned`, res);
        this.loginMessages = [{
          severity: 'warning',
          summary: 'Cannot resolve authentication methods',
          detail: 'Please contact support'
        }];
        return;
      }

      const authMethod = authMethods.find((a) => a.id === this.authMethodId);
      if (!authMethod) {
        this.loginMessages.push({
          severity: 'warning',
          summary: 'Cannot resolve auth method',
          detail: 'Please contact support',
        });
        return;
      }

      const minLength = authMethod.password_minLength;
      if (minLength > environment.passwordMinLength) {
        this.resetForm.get('password').addValidators(Validators.minLength(minLength));
      }

    }, (err) => {
      console.error(`Error when checking auth methods`, err);
      this.loginMessages = [{
        severity: 'warning',
        summary: 'Error when resolving authentication methods',
        detail: 'Please contact support'
      }];
    });
  }

  requestResetPassword() {
    this.emailSent = false;
    this.resetInProgress = true;
    const email = this.sendRequestForm.get('email').value;
    this.subs.sink = this.requestResetGQL.mutate({
      email,
    }).subscribe((res) => {
      if (!res) {
        this.loginMessages = [{
          severity: 'error',
          detail: `No account found for ${email}.`
        }];
      } else {
        this.loginMessages = [{
          severity: 'success',
          detail: `If ${email} exists as a valid login then we will send`
            + ` this email instructions to reset your password.`
        }];
        this.emailSent = true;
      }
      this.resetInProgress = false;
    }, (err) => {
      this.resetInProgress = false;
      console.error(`Could not reset password`, err);
      this.loginMessages = [{
        severity: 'error',
        summary: `There was an error when requesting a password reset link`,
        detail: err.message,
      }];
    });
  }

  removeTokenFromURL() {
    // Replace Token in URL to make it cleaner & remove secret from browser history
    const url = new URL(location.toString());
    url.searchParams.delete('token');
    history.replaceState({}, '', url.pathname + url.search);
  }

  resetPassword() {
    if (!this.resetForm.valid) {
      return;
    }

    const {
      token,
      email,
      password,
    } = this.resetForm.value;
    this.resetInProgress = true;
    this.subs.sink = this.resetPasswordGQL.mutate({
      token,
      newPassword: password,
    }).subscribe((res) => {
      if (res) {
        this.notify.addToast.next({ severity: 'success', detail: 'Password reset successful' });

        // If the email wan't in the metadata route to login
        if (!email) {
          this.router.navigate(['/']);
          return;
        }

        this.router.navigate(['/auth/login'], {
          state: {
            auth: {
              email,
              password,
              submit: true,
            } as AuthInitialState,
          }
        });

        // TODO: refactor this to direct you to login with prefilled data
        // this.plusAuth.authenticate({ email, password }).subscribe(() => {
        //   this.router.navigate(['/']);
        // });
      }

      this.resetInProgress = false;
    }, (err) => {
      this.loginMessages = [{
        severity: 'error',
        summary: `There was an error when resetting your password`,
        detail: err.message,
      }];
      this.resetInProgress = false;
    });
  }

}
