import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { capitalize } from 'lodash';
import { MenuItem } from 'primeng/api';

import { CreateCalendarEventGQL, EstimatesJobFragment } from '../../../generated/graphql.generated';
import { EventTypeInfo, eventTypeInfoMap } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

@Component({
  selector: 'app-add-event-button',
  templateUrl: './add-event-button.component.html',
  styleUrls: ['./add-event-button.component.scss']
})
export class AddEventButtonComponent implements OnInit, OnChanges {

  @Input() job: EstimatesJobFragment;
  @Input() disabled: boolean;
  @Input() onEstimator = true;

  addableEventTypes: MenuItem[] = [];

  constructor(
    private estimateHelper: EstimateHelperService,
    private localNotify: FreyaNotificationsService,
    private createEventGQL: CreateCalendarEventGQL,
    private detailsHelper: DetailsHelperService,
  ) { }

  ngOnInit(): void {
    if (this.job) {
      this.setAddableEventTypes();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.job) {
      this.setAddableEventTypes();
    }
  }

  /**
   * Set which events can be added
   */
  setAddableEventTypes() {
    this.addableEventTypes = [];
    for (const [key, value] of Object.entries(eventTypeInfoMap)) {
      const eventWithSequentialOrder = {
        ...value,
        sequentialOrder: (this.job?.events?.length + 1) || 1,
      };
      this.addableEventTypes.push({
        label: capitalize(eventWithSequentialOrder.value),
        command: () => {
          this.localNotify.info(`Creating ${eventWithSequentialOrder.name} event ...`);
          this.estimateHelper.addRequiredEvent.next(eventWithSequentialOrder as EventTypeInfo);
        }
      } as MenuItem);
    }
  }

}
