<div
    #header
    class="p-grid header"
    [class.sticky-header]="detailsHelperService.hasScrolled"
>
    <div class="p-col">
        <div class="card">
            <div class="p-grid">
                <div class="p-col-8">
                    <h3 *ngIf="user; else loading">
                        {{user.givenName}} {{user.familyName}}
                        <p-chip
                            *ngFor="let role of user.roles"
                            class="quick-margin role-chip"
                            [pTooltip]="role.description"
                            [style]="{'background-color': role.color}"
                            (click)="detailsHelperService.open('role', role)"
                        >
                            {{ role.name }} 
                            <ng-container *ngIf="role.zones.nodes">
                                ({{ role.zones.nodes.length }})
                            </ng-container>
                        </p-chip>
                    </h3>
                </div>
                <div class="p-col-4 p-text-right">
                    <app-user-actions
                        [user]="user"
                        [disabled]="!user"
                    ></app-user-actions>
                </div>
                <div class="button-container">
                    <button
                        pButton
                        pRipple
                        type="button"
                        *hasPermission="['users.edit']"
                        pTooltip="Edit User"
                        icon="pi pi-user"
                        (click)="openEditUser()"
                        class="p-button-rounded p-button-secondary"
                    ></button>
                    <button
                        pButton
                        pRipple
                        *hasPermission="['users.view']"
                        type="button"
                        pTooltip="Add Transaction"
                        icon="pi pi-dollar"
                        (click)="openEditTabItem('transaction')"
                        class="p-button-rounded p-button-secondary"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        *hasPermission="['locations.create']"
                        pTooltip="Add Location"
                        icon="pi pi-map-marker"
                        (click)="openEditTabItem('location')"
                        class="p-button-rounded p-button-secondary"
                    ></button>
                    <button
                        pButton
                        pRipple
                        type="button"
                        pTooltip="Add Note"
                        *hasPermission="['comments.add']"
                        icon="pi pi-list"
                        (click)="openEditTabItem('comment')"
                        class="p-button-rounded p-button-secondary"
                    ></button>
                    <!-- <button
                        pButton
                        pRipple
                        [disabled]="true"
                        type="button"
                        pTooltip="Add Note"
                        icon="pi pi-comments"
                        (click)="openEditTabItem('comment')"
                        class="p-button-rounded p-button-secondary"
                    ></button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div
        style="max-width: 100%;"
        #tabs
        class="p-md-12 p-lg-8"
    >
        <p-tabView
            [(activeIndex)]="activeTabIndex"
            (onChange)="changeTabByIndex($event.index)"
        >
            <p-tabPanel data-cy="tab-panel-overview" header="Overview">
                <ng-template pTemplate="content">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="card">
                                <div class="p-grid p-ai-center">
                                    <div class="p-col">
                                        <span class="card-title">User Info</span>
                                    </div>
                                    <div class="p-col p-text-right">
                                        <button *hasPermission="['users.edit']" pButton [disabled]="false" icon="pi pi-pencil" (click)="openEditUser()"></button>
                                    </div>
                                </div>
                                <hr>
                                <p *ngIf="user.givenName; else loading">Email: {{user.email}}</p>
                                <p *ngIf="user.givenName; else loading">Phone: {{user | phone}}</p>
                                <p *ngIf="user.givenName; else loading">Customer Rep: TODO</p>
                                <p *ngIf="user.givenName; else loading">Franchise: {{user | userFranchise }}</p>
                                <!-- <p *ngIf="user.givenName; else loading">Account Type: TODO</p> -->
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel data-cy="tab-panel-jobs" *hasPermission="['jobs.list']" header="Jobs">
                <ng-template pTemplate="content">
                    <app-jobs
                        #jobs
                        [syncToQueryParams]="false"
                        [filter]="{userSearch: { userId: user.id, role: jobConstants.customerRole}}"
                        [showHeaderCard]="true"
                    ></app-jobs>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel data-cy="tab-panel-locations"  header="Locations">
                <ng-template pTemplate="content">
                    <app-locations [pagination]="pageItemPagination" [filter]="{users: [user.id]}" #locations></app-locations>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel data-cy="tab-panel-payments" *hasPermission="['jobs.list', 'transactions.list']" header="Payments">
                <ng-template pTemplate="content">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <app-invoices
                                [filter]="{userSearch: { userId: user.id, role: jobConstants.customerRole} }"
                                [syncToQueryParams]="false"
                                emptyMessage="This user has no invoices"
                                layout="compact"
                                ></app-invoices>
                        </div>
                        <div class="p-col-12">
                            <app-transactions *ngIf="hasIndexLoaded[3]" [pagination]="pageItemPagination" [filter]="{customerId: user.id}" #transactions [showHeaderCard]="false" [user]="user"></app-transactions>
                        </div>
                        <div class="p-col-6">
                            <div class="card">
                                <p>Payment Methods</p>
                                <small>Credit/Debit Cards stored for convient use at a later date.</small>
                            </div>
                        </div>
                        <div class="p-col-6">
                            <div class="card">
                                <h5>Receipts</h5>
                                <app-artifacts
                                    [readonly]="true"
                                    [filter]="{
                                        relatedObjectIds: [user.id],
                                        relatedObjectLabels: ['User'],
                                        hasAnyAttributes: ['Receipt']
                                    }"
                                    [showUpload]="false"
                                    [showHeaderCard]="false"
                                ></app-artifacts>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </p-tabPanel>
            <p-tabPanel data-cy="tab-panel-documents" *hasPermission="['artifacts.list']" header="Documents">
                <ng-template pTemplate="content">
                    <app-artifacts
                        [filter]="{relatedObjectIds: [this.user.id], relatedObjectLabels: ['User']}"
                        #artifacts
                    ></app-artifacts>
                </ng-template>
            </p-tabPanel>
            <!-- <p-tabPanel data-cy="tab-panel-cases" header="Cases">
                <div class="card">
                </div>
            </p-tabPanel> -->
            <p-tabPanel data-cy="tab-panel-notifications" header="Notifications">
                <ng-template pTemplate="content">
                    <div class="card">
                        <h5>Notification Options</h5>
                        <p-toggleButton
                            [(ngModel)]="smsOptIn"
                            (onChange)="updateSmsOptIn()"
                            [onLabel]="'SMS Opted In'"
                            [offLabel]="'SMS Opted Out'"
                            [onIcon]="'pi pi-check'"
                            [offIcon]="'pi pi-times'"
                            [disabled]="smsOptIn === undefined"
                            [style]="{ width: '15em' }"
                        ></p-toggleButton>
                    </div>
                    <div class="card">
                        <h5>Notification History</h5>
                        <app-history
                            [filter]="notificationHistoryFilter"
                            [objectId]="user?.id"
                            [objectLabel]="'User'"
                            [useObjectId]="true"
                        ></app-history>
                    </div>
                </ng-template>
            </p-tabPanel>
            <!-- <p-tabPanel data-cy="tab-panel-communications" header="Communications">
                <div class="card">
                    <p>Coming Soon.</p>
                </div>
            </p-tabPanel>
            <p-tabPanel data-cy="tab-panel-history" header="History">
                <div class="card">
                    <p>Coming Soon.</p>
                </div>
            </p-tabPanel> -->
        </p-tabView>
    </div>
    <div
        #Notes
        class="p-md-12 p-lg-4 notes-section"
        [class.notes-while-sidepanel]="detailsHelperService.rightPanelOpen"
    >

        <app-comments
            #comments
            [callOnLoad]="false"
            [commentsFilter]="{objectids: [user?.id]}"
            [objectId]="user?.id"
            objectType="User"
            nameForComment="Note"
        ></app-comments>
    </div>
</div>

<ng-template #loading>
    <p-skeleton
        width="100%"
        height="20px"
    ></p-skeleton>
</ng-template>

