<div class="card">
  <div class="p-grid">
    <div class="p-col">
      <h4>Payment Types</h4>
    </div>
    <div class="p-col">
      <button
        *hasPermission="['configs.set']"
        type="button"
        pButton
        icon="pi pi-plus"
        label="Add"
        (click)="openCreateTransactionTypeDialog()"
        ></button>
    </div>
  </div>
</div>
<div class="card">
  <p-table
    [value]="paymentTypeRows"
    [paginator]="true"
    [rows]="10"
    dataKey="key"
    editMode="row"
    >
    <ng-template pTemplate="header">
      <tr>
        <th>Key</th>
        <th>Name</th>
        <th>Provider</th>
        <th>Enabled</th>
        <th style="width:8rem" *hasPermission="[ 'configs.set' ]"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-type let-editing="editing">
      <tr [pEditableRow]="type">
        <td>{{ type.key }}</td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="type.name" placeholder="Not Set" (input)="markTransactionTypeAsModified(type)">
            </ng-template>
            <ng-template pTemplate="output">
              {{ type.name | notSet }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="type.provider" placeholder="Not Set" (input)="markTransactionTypeAsModified(type)">
            </ng-template>
            <ng-template pTemplate="output">
              {{ type.provider | notSet }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputSwitch [(ngModel)]="type.enabled" (onChange)="markTransactionTypeAsModified(type)"></p-inputSwitch>
            </ng-template>
            <ng-template pTemplate="output">
              <i
                *ngIf="type.enabled"
                class="pi pi-check p-ml-4"
                [class.success-text]="true"
                ></i>
              <i
                *ngIf="!type.enabled"
                class="pi pi-ban p-ml-4"
                [class.error-text]="true"
                ></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td *hasPermission="[ 'configs.set' ]">
          <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-primary p-mr-3"></button>
          <button *ngIf="editing" pButton type="button" pCancelEditableRow [icon]="type.modified? 'pi pi-trash': 'pi pi-times'" class="p-button-secondary p-mr-3" (click)="discardChangesToTransactionType(type)"></button>
          <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-save" class="p-button-success" [disabled]="!type.modified" (click)="saveTransactionType(type)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<app-mutate-transaction-type #mutateTransactionType></app-mutate-transaction-type>
