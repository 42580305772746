<div class="auth-container">
    <div class="auth-header"></div>
    <div class="auth-body">
        <a
            [routerLink]="['/']"
            class="logo"
        >
            <img
                [src]="brandingService.watchIcon(256, 'light') | async"
                alt="Logo"
            />
        </a>
        <ng-container *ngIf="!resetToken">
            <h2>Request password reset link</h2>
            <p>A link will be sent to your email with instructions on resetting your password</p>
        </ng-container>

        <p-messages
            [(value)]="loginMessages"
            [enableService]="false"
            data-cy="login-error-msg"
        ></p-messages>
        <form *ngIf="!resetToken && !emailSent"
            [formGroup]="sendRequestForm"
            (submit)="requestResetPassword()"
            class="auth-form"
        >
            <input
                class="login-input"
                type="email"
                id="auth_email"
                data-cy="reset-email-text-field"
                formControlName="email"
                placeholder="Email"
                pInputText
            >
            <app-validation-messages [control]="sendRequestForm.get('email')"
                fieldName="Email"
            >
            </app-validation-messages>

            <div class="reset-password-buttons auth-btn-set">
                <button
                    pButton
                    pRipple
                    type="submit"
                    id="reset-button"
                    class="auth-btn p-button-primary  p-button-lg"
                    label="Request Reset Link"
                    [disabled]="resetInProgress || !sendRequestForm.valid"
                    data-cy="reset-email-submit-button"
                ></button>
                <a
                    pButton
                    pRipple
                    id="back-to-login"
                    label="Back to login"
                    class="auth-btn p-button-outlined p-button-primary"
                    routerLink="/auth/login"
                ></a>
            </div>
        </form>
        <form
            *ngIf="resetToken"
            [formGroup]="resetForm"
            (submit)="resetPassword()"
            class="password-reset-container auth-form"
        >
            <h2>Reset Password</h2>
            <input
                pInputText
                type="email"
                id="email"
                [readonly]="true"
                [feedback]="false"
                formControlName="email"
                placeholder="Email"
                pTooltip="You cannot modify your email"
            />
            <ng-container *appVar="resetForm.get('password') as control">
                <input
                    pPassword
                    type="password"
                    id="password"
                    [readonly]="resetInProgress"
                    [feedback]="false"
                    formControlName="password"
                    placeholder="Password"
                    [class.p-invalid-input]="!control.valid && control.touched"
                />
                <app-validation-messages [control]="control"
                    fieldName="Password"
                ></app-validation-messages>
            </ng-container>
            <ng-container *appVar="resetForm.get('passwordConfirm') as control">
                <input
                    pPassword
                    type="password"
                    id="passwordConfirm"
                    [readonly]="resetInProgress"
                    [feedback]="false"
                    formControlName="passwordConfirm"
                    placeholder="Confirm Password"
                    [class.p-invalid-input]="!control.valid && control.touched"
                />
                <app-validation-messages [control]="control"
                    fieldName="Confirm Password"
                ></app-validation-messages>
            </ng-container>
            <small class="field-error-message" *ngIf="resetForm.errors?.mismatch && resetForm.get('passwordConfirm').touched">
                Passwords must match
            </small>
            <div class="password-reset-buttons auth-btn-set">
                <button
                    pButton
                    pRipple
                    id="reset-submit-button"
                    class="auth-btn p-button-lg  p-button-primary login-submit-button"
                    [disabled]="resetInProgress || !resetForm.valid"
                    label="Set Password"
                    type="submit"
                ></button>
                <a
                    pButton
                    pRipple
                    id="back-to-login"
                    label="Back to login"
                    class="auth-btn p-button-outlined p-button-primary"
                    routerLink="/auth/login"
                ></a>
            </div>
        </form>

        <div *ngIf="emailSent">
            <p>Click on the relevant icon to take you to your inbox.</p>
            <div class="link-container">
                <a
                    href="https://mail.google.com/mail/u/0/#inbox"
                    target="_blank"
                >
                    <img
                        src="/assets/images/logos/gmail-icon.png"
                        width="40px"
                        height="40px"
                    >
                </a>
                <a
                    href="https://outlook.live.com/mail/0/inbox"
                    target="_blank"
                >
                    <img
                        src="/assets/images/logos/outlook-icon.png"
                        width="40px"
                        height="40px"
                    >
                </a>
                <a
                    href="https://mail.yahoo.com"
                    target="_blank"
                >
                    <img
                        src="/assets/images/logos/yahoo-icon.png"
                        width="40px"
                        height="40px"
                    >
                </a>
            </div>
        </div>
    </div>
    <app-auth-footer
        supportMessage="I'm having trouble resetting my password"
    ></app-auth-footer>
</div>