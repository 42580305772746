import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements OnInit, OnDestroy {

  @Output() appLazyLoad: EventEmitter<void> = new EventEmitter<void>();
  private observer: IntersectionObserver;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {

    const options = {
      // Relative to the viewport
      root: null,
      rootMargin: '0px',
      // Percentage of the target element which should be visible before it is loaded
      threshold: 0.1,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.appLazyLoad.emit();
        this.observer.unobserve(this.element.nativeElement);
      }
    }, options);

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

}
