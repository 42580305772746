import { Pipe, PipeTransform } from '@angular/core';

import { ArtifactType } from '../artifacts/artifact.util';

@Pipe({
  name: 'artifactTypeIcon'
})
export class ArtifactTypeIconPipe implements PipeTransform {

  transform(artifactType: ArtifactType): string {
    switch(artifactType) {
      case 'Image':
        return 'pi pi-image';
      case 'Video':
        return 'pi pi-video';
      case 'Audio':
        return 'pi pi-volume-up';
      default:
        return 'pi pi-file';
    };
  }

}
