import { Component, OnDestroy, } from '@angular/core';

import { LeadSourceSettingsComponent } from './lead-source-settings/lead-source-settings.component';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss'],
})
export class MarketingComponent implements OnDestroy {

  constructor() {
  }

  ngOnDestroy(){
  }

}
