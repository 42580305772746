import { Injectable } from '@angular/core';
import { retrieveRefreshTokensQuery, RetrieveRefreshTokensResult } from '@karve.it/interfaces/tokens';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  constructor(
    private apollo: PlusApollo,
  ) {}

  /**
   * List refresh tokens, which contain information about the user and their session.
   *
   * @param userId The id of the user whose refresh tokens you want to see.
   * @returns QueryRef, a list of refresh tokens.
   */
  retrieveUserRefreshTokens(
    userId: string,
  ) {
    return this.apollo.query<RetrieveRefreshTokensResult>({
      query: retrieveRefreshTokensQuery,
      variables: { userId },
    });
  }
}
