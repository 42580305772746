import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input,
  OnDestroy, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { UntypedFormControl, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';
import {FetchResult} from '@apollo/client/core';
import {dayjs} from '@karve.it/core';
import { AvailabilityV2Service } from '@karve.it/features';
import { SetTemplateInput, UpdateAvailabilityConfigurationFunctionInput, updateAvailabilityConfigurationMutation, UpdateAvailabilityConfigurationOutput } from '@karve.it/interfaces/availability.v2';

import { cloneDeep } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AssetWithConfig } from 'src/app/availability/availability.interfaces';
import { daysOfTheWeek } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { Asset, AssetsForAvailabilityGQL, AvailabilityTemplatesGQL, BaseAssetFragment, FullAvailabilityTemplateFragment, UpdateAvailabilityConfigGQL, UpdateAvailabilityConfigMutationVariables } from '../../../generated/graphql.generated';
import { AssetKeyValue } from '../../custom-inputs/asset-select/asset-select.component';

import { FreyaHelperService } from '../../services/freya-helper.service';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-apply-template',
  templateUrl: './apply-template.component.html',
  styleUrls: ['./apply-template.component.scss']
})
export class ApplyTemplateComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  @Input() reviewButtonText: string;
  @Input() preselectedTemplate: FullAvailabilityTemplateFragment;
  @Input() preselectedAssets: BaseAssetFragment[];

  @ViewChild('co') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('title') titleRef: TemplateRef<any>;
  @ViewChild('selectTemplate') selectTemplateRef: TemplateRef<any>;
  @ViewChild('type') typeRef: TemplateRef<any>;
  @ViewChild('dates') datesRef: TemplateRef<any>;
  @ViewChild('assets') assetsRef: TemplateRef<any>;

  subs = new SubSink();

  steps: MutateObjectElement[];

  applyForm = new UntypedFormGroup({
    template: new UntypedFormControl(undefined, [Validators.required]),
    type: new UntypedFormControl('Normal', [Validators.required]),
    dates: new UntypedFormControl([]),
    assets: new UntypedFormControl([], [Validators.required]),
  });
  applyFormValues = this.applyForm.value;

  // OPTIONS
  // The templates that can be applied
  templateOptions: FullAvailabilityTemplateFragment[];

  constructor(
    // HELPERS
    private cd: ChangeDetectorRef,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    public freyaHelper: FreyaHelperService,
    // GQL
    private templatesGQL: AvailabilityTemplatesGQL,
    private updateConfigGQL: UpdateAvailabilityConfigGQL,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.templatesGQL.watch({}, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    }).valueChanges.subscribe((res) => {
      if (!res.data?.availabilityTemplates) { return; }
      this.templateOptions = res.data.availabilityTemplates;
    });

    this.subs.sink = this.applyForm.get('type').valueChanges.pipe(skip(1)).subscribe((val) => {
      if (val === 'Normal'){
        this.mutateRef.removeStep('Dates to Override');
      }

      if (val === 'Override'){
        this.mutateRef.addStep('Dates to Override');
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();

  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openDialog() {
    this.applyForm.reset(this.applyFormValues);

    if (this.preselectedTemplate){
      this.applyForm.get('template').setValue(this.preselectedTemplate);
    }

    if (this.preselectedAssets){
      this.applyForm.get('assets').setValue(this.preselectedAssets);
    }

    this.steps = [
       { name: 'Select Template', ref: this.selectTemplateRef, control: 'template', type: 'func',
         reviewFunc: (() => (this.applyForm.value.template.name))
       },
       { name: 'Availability Type', ref: this.typeRef, control: 'type', type: 'text'},
       { name: 'Dates to Override', ref: this.datesRef, control: 'dates', type: 'array'},
       { name: 'Assets', ref: this.assetsRef, control: 'assets', type: 'array'}
    ];

    this.mutateRef.steps = this.steps;
    this.mutateRef.titleText = 'Apply Availability Template';
    this.mutateRef.reviewButtonText = 'Apply Template';
    this.mutateRef.removeStep('Dates to Override');
    this.mutateRef.openDialog();
  }

  /**
   * Handles applying the template to multiple assets (if selected) and notifying the user of the changes
   */
  async applyTemplate() {
    const assets = this.applyForm.get('assets').value;

    const joinObject = {};
    for (const asset of this.applyForm.get('assets').value){
        joinObject[`${asset.name}`] = this.updateConfig(asset);
    }

    forkJoin(joinObject).subscribe((res) => {
      for (const key of Object.keys(res)){
        // @ts-ignore
        if (!res[key]?.data?.updateAvailabilityConfiguration){
          // @ts-ignore
          this.localNotify.apolloError(`Failed to update configuration for ${res[key]}`, res[key]?.error);
          this.mutateRef.loading=false;
        }

        const assetId = assets.find((a) => a.name === key).id;

        this.detailsHelper.pushUpdate({
          action: 'update',
          id: assetId,
          type: 'Assets',
        });
        this.detailsHelper.pushUpdate({
          action: 'update',
          id: assetId,
          type: 'AvailabilityConfig',
        });
      }

      this.mutateRef.closeDialog();
      this.localNotify.success(`Template applied to assets`);
    });
  }

  /**
   * Updated the configuration for the asset
   *
   * @param asset The asset which is having it's config updated
   * @returns Observable of the mutate query
   */
  updateConfig(asset: Asset): Observable<any> {
    const formVal = this.applyForm.value;

    const datesAsStrings = formVal.dates.map((date) => dayjs(date).format('YYYY-MM-DD'));

    const input: UpdateAvailabilityConfigMutationVariables = {
      input : {
        objectId: asset.id,
      }
    };

    if (formVal.type === 'Normal'){
      input.input.addTemplates = [{templateId: formVal.template.id}];
    }

    if (formVal.type === 'Override'){
      input.input.addOverrides = [{
        templateId: formVal.template.id,
        dates: datesAsStrings,
      }];
    }

    return this.updateConfigGQL.mutate(input);
  }
}
