/* eslint-disable max-len */
import { gql } from 'graphql-tag';

import { Asset, AssetsFilter } from './assets';
import { User } from './auth';
import { AddAttendeeInput, baseCalendarEventsGQL, CalendarEvent, calendarEventsAssetsGQL, calendarEventsTopicsGQL } from './calendarEvents';
import { locationBaseGQL , usersBaseGQL } from './common.gql';
import { ListUsersInput } from './users';

export interface ListFindOutput {
	find: FindOutput;
}

export interface LockWindowOutput {
	lockWindow: CalendarEvent;
}

export interface FindOutput {
    windowCount: number;
    userCount: number;
    assetCount: number;
    windows: BookingWindow[];
}


export interface WindowInput {
	// Use international date format 'YYYY-MM-DD'
    startDate: string;
	// Use international date format 'YYYY-MM-DD, Set it the same as startDate to only query one day'
    endDate: string;
	// The minimum size of the booking window, if any.
    minWindowLength?: number;
}


export interface BookingWindow {
    start: number;
    end: number;
    users: User[];
    assets?: Asset[];
    startTimes: number[];
}


export interface BookWindowInput {
    title: string;
    shortTitle?: string;
    description?: string;
    colour?: string;
    type: string;
    locationId?: string;
    topicIds?: string[];
    topicText?: string;
    assetIds?: string[];
    /**
     * Set metadata on the object. Must be provided in a valid JSON format otherwise it will throw an error.
     * If using double quotes, make sure you escape the string.
     */
    metadata?: string;
}


export interface FindInput {
	timeWindow?: WindowInput;
	usersFilter?: ListUsersInput;
	assetsFilter?: AssetsFilter;
	minNumUsers?: number;
	minNumAssets?: number;
  overrideUnavailabilities: boolean;
}



// export interface LockWindowInput {
// 	title?: string;
// 	shortTitle?: string;
// 	description?: string;
// 	start?: number;
// 	end?: number;
	// colour?: string;
	// locationId?: string;
	// attendees?: AddAttendeeInput[];
	// topicIds?: string[];
	// topicText?: string;
	// assetIds?: string[];
  // metadata?: string;
// }

export interface LockWindowInput{
  start: number;
  end: number;
  type: string;
  title?: string;
	colour?: string;
	locationId?: string;
  /** @deprecated */
	attendees?: AddAttendeeInput[];
	topicIds?: string[];
	topicText?: string;
	assetIds?: string[];
  metadata?: string;
  jobId: string;
  attributes?: string[];
  locations?: CalendarEventLocationInput[];
  overrideAvailabilities?: boolean;
  overrideUnavailabilities?: boolean;
  updateEventSequentialOrder?: boolean;
}

export interface CalendarEventLocationInput{
  locationId: string;
  type: string;
  order: number;
  estimatedTimeAtLocation: number;
  travelTimeToNextLocationOffset: number;
  ignoreInDuration?: boolean;
}

export const findQuery = gql`
	query find($timeWindow: WindowInput!, $usersFilter: UserSearch, $assetsFilter: AssetsFilter, $minNumUsers: Int, $minNumAssets: Int, $overrideUnavailabilities: Boolean){
		find(timeWindow: $timeWindow, usersFilter: $usersFilter, assetsFilter: $assetsFilter, minNumUsers: $minNumUsers, minNumAssets: $minNumAssets, overrideUnavailabilities: $overrideUnavailabilities){
			    windowCount
			    userCount
			    assetCount
			    windows{
            start
            end
            startTimes
            users{
              ${usersBaseGQL}
            }
            assets{
              id
              name
              type
              description
              attributes
              location {
                    ${ locationBaseGQL }
              }
            }
          }
	}		}
`;


export const lockWindowMutation = gql`
	mutation lockWindow($start: Int!, $end: Int!, $type: String!, $title: String, $colour: String, $locationId: String, $topicIds: [String], $topicText: String, $assetIds: [String], $metadata: Metadata, $jobId: String!, $attributes: [String], $locations: [CalendarEventLocationInput], $overrideUnavailabilities: Boolean, $overrideAvailabilities: Boolean, $updateEventSequentialOrder: Boolean){
		lockWindow(start: $start, end: $end, type: $type, title: $title, colour: $colour, locationId: $locationId, topicIds: $topicIds, topicText: $topicText, assetIds: $assetIds, metadata: $metadata, jobId: $jobId, attributes: $attributes, locations: $locations, overrideUnavailabilities: $overrideUnavailabilities, overrideAvailabilities: $overrideAvailabilities, updateEventSequentialOrder: $updateEventSequentialOrder){ 
        ${baseCalendarEventsGQL}
        ${calendarEventsAssetsGQL}
        ${calendarEventsTopicsGQL}
	  }		
  }
`;

