import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UpdateJobInput } from 'graphql.generated';
import { FullJobFragmentWithFields } from '../../job-tool.reducer';
import { AddableLocations } from '../../jobv2-create/jobv2-interfaces';
export const JobEditActions = createActionGroup({
	source: 'Job Edit',
	events: {
		'Update Form Saved': props<{ jobId: string }>(),
		'Discard Changes': emptyProps(),

		'Update Job': props<{ updateJobInput: UpdateJobInput }>(),
		'Update Job Success': emptyProps(),
		'Update Job Error': props<{ error: Error }>(),

        'Open Edit Page From Job V2': props<{ jobId?: string }>(),
		'Clean Previous Job Data': props<{ serverCopy?: FullJobFragmentWithFields | undefined }>(),
        'Transfer Job State For Edit': props<{ jobInfo: any }>(),
		'Reset Changes After Transferring Job State': emptyProps(),

		'Remove Job Locations from Addable List': props<{ addableAdditionalLocations: AddableLocations }>(),
	},
});
