<div>
    <button
        pButton
        pRipple
        label="Actions"
        [disabled]="disabled"
        (click)="menu.toggle($event)"
        icon="pi pi-angle-down"
        data-cy="actions-button"
    ></button>
    <p-menu
        #menu
        [popup]="true"
        appendTo="body"
        [model]="userActions"
    ></p-menu>
</div>
