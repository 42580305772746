<div
    class="create-report-input"
    [formGroup]="form"
>
	<ng-template #time>
        <app-cron-time-form
            formControlName="time"
            [(parsedCronTime)]="parsedCronTime"
            ></app-cron-time-form>
    <p class="crontime">
    This report will be run
    {{ parsedCronTime }}
    </p>
	</ng-template>
	<ng-template #period>
		<p>
			This is the period that each scheduled report will be run for.
			For example, a report that has a period "Yesterday"
			and an interval that is run "every day at 2AM" will generate a report
			every day at 2AM for the previous day.
		</p>
		<p>
			If you use "Every Day at 2AM" for "Today" then the report will only return
			results that match the period for the current day, which has only been in progress
			for two hours.
		</p>
		<p *ngIf="reportDescription"
			[innerHTML]="parseReportTypePeriod() | markdown | async"
		></p>
		<div *appVar="form.get('period') as control">
			<p-dropdown
				formControlName="period"
				[options]="periods"
				(onChange)="updatePeriodLocalStorage()"
				[filter]="true"
				[showClear]="true"
				optionLabel="name"
				optionValue="value"
				filterBy="name"
				appendTo="body"
				autoWidth
			></p-dropdown>

			<app-validation-messages
				[control]="control"
				[requireTouched]="true"
			></app-validation-messages>
		</div>
	</ng-template>
    <ng-template #name>
        <div *appVar="form.get('name') as control">
            <input
				class="name"
                type="text"
                pInputText
                formControlName="name"
                [placeholder]="getNamePlaceholder()"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <br />
            <small>Leave blank to auto generate name</small>
            <br />
            <small>Generating report in <a>{{ currentZone?.name }}</a></small>

            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #notifications>
        <div *appVar="form.get('webhooks') as control" class="notifications">

			<h6 for="select-users">Users</h6>
			<app-user-search-multiple
				id="select-users"
				formControlName="notifyUsers"
			></app-user-search-multiple>
			<small>
				These users are sent a copy of the generated report
				following successful report generation.
			</small>

			<h6 for="select-roles">Roles</h6>
			<p-multiSelect
				[options]="roles"
				formControlName="notifyRoles"
				class="full-width multiselect-custom"
				optionLabel="name"
				optionValue="id"
				defaultLabel="Select Roles"
				maxSelectedLabels="2"
				selectedItemsLabel="{0} Roles Selected"
				data-cy="select-roles-multi-select-field"
				appendTo="body"
			></p-multiSelect>
			<small>
				The users of these roles are sent a copy of the generated report
				following successful report generation.
			</small>

			<h6 for="webhook-input">Webhooks (advanced)</h6>
			<p-chips
				formControlName="webhooks"
				[max]="3"
				id="webhook-input"
				[allowDuplicate]="false"
				[addOnBlur]="true"
				[addOnTab]="true"
				(onAdd)="checkWebhooks()"
			></p-chips>
			<small>
				Enter up to three secure URL's that a `POST` request is sent
				to following successful report generation.
				A secure URL starts with https://
			</small>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>

			<hr />

			<div class="field-checkbox">
				<p-checkbox
					name="dontSendIfEmpty"
					formControlName="dontSendIfEmpty"
					binary="true"
					label="Don't Send If Empty"
					inputId="dontSendIfEmpty"
				></p-checkbox>
				<br />
				<small>
					Webhooks and notifications will not be sent if the
					report is empty.
				</small>
			</div>
        </div>
    </ng-template>

</div>


<app-mutate-object
    #mutate
    [fg]="form"
    objectType="ScheduledReport"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
    (changesDiscarded)="reset()"
></app-mutate-object>
