<!-- https://stripe.com/docs/payments/quickstart -->

<div class="payment-container">
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-offset-0 p-md-offset-3 p-text-center">
      <div class="payment-card">
        <h3 *ngIf="transaction" class="no-margin">Total: {{transaction.amount / 100 | currency : transaction.currency}}</h3>
        <h3 *ngIf="routeInvalid" class="no-margin">Payment Setup Failed</h3>
        <p-skeleton *ngIf="!transaction && !routeInvalid" width="100%" height="20px"></p-skeleton>
      </div>
    </div>
    <br>
    <div class="p-col-12 p-md-6 p-offset-0 p-md-offset-3 p-text-center">
      <form [class.hide]="!formBuilt" (submit)="handleSubmit($event)" id="payment-form">
        <div id="payment-element">
        </div>
        <div id="card-element">

        </div>
        <button id="submit">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Pay now</span>
        </button>
        <div id="payment-message" class="hidden"></div>
      </form>
      <p-skeleton *ngIf="!formBuilt && !routeInvalid" width="100%" height="400px"></p-skeleton>
      <div *ngIf="routeInvalid" class="payment-card error-text">
        <p>{{invalidMessage}}</p>
      </div>
    </div>
  </div>
</div>