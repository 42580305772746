<div
    class="card"
    [class.hide]="!showHeaderCard"
>
    <div class="p-grid">
        <div class="p-col">
            <h4>Transactions</h4>
        </div>
        <div class="p-col p-text-right">
            <button
                *ngIf="showReceiptButton"
                pButton
                class="p-mr-2"
                label="Send Receipt"
                icon="pi pi-send"
                (click)="openSendReceipt()"
            ></button>
            <span [pTooltip]="readonly && readonlyTooltip">
                <button
                    pButton
                    (click)="openMutateDialog()"
                    [disabled]="readonly"
                    icon="pi pi-plus"
                ></button>
            </span>
        </div>
        <!-- <div class="p-col">
            <button pButton label="Create" [disabled]="true" icon="pi pi-plus"></button>
        </div>
        <div class="p-col p-ai-center">
            <div style="white-space: nowrap;">
                <span class="p-input-icon-left" style="display: inline-block;">
                    <i
                        *ngIf="!transactionsQH.loading"
                        class="pi pi-search"
                    ></i>
                    <i
                        *ngIf="transactionsQH.loading"
                        class="pi pi-spin pi-spinner"
                    ></i>
                    <input
                        type="text"
                        #transactionSearchInput
                        pInputText
                        [(ngModel)]="transactionsQH.search"
                        placeholder="Search"
                    >
                </span>
                    <button
                    type="button"
                    pButton
                    pRipple
                    class="p-ml-1"
                    label=""
                    icon="pi pi-times"
                    styleClass="p-button-danger"
                    (click)="transactionsQH.search = ''; searchForTransactions()"
                ></button>
            </div>
        </div> -->
    </div>
</div>


<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h5 *ngIf="!smallTitle">Transactions</h5>
            <span
                class="card-title"
                *ngIf="smallTitle"
            >Transactions</span>
        </div>
        <div class="p-col p-text-right">
            <button
                *ngIf="showReceiptButton"
                pButton
                class="p-mr-2"
                label="Send Receipt"
                icon="pi pi-send"
                (click)="openSendReceipt()"
            ></button>
            <span *hasPermission="['transactions.create']" [pTooltip]="readonly && readonlyTooltip" tooltipPosition="left">
                <button
                    pButton
                    (click)="openMutateDialog()"
                    [disabled]="readonly || (!job | jobLocation : 'start')"
                    icon="pi pi-plus"
                ></button>
            </span>
        </div>
    </div>
    <p-table
        [value]="transactions"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreTransactions($event)"
        [lazy]="true"
        [totalRecords]="transactionsQH.total"
        [(rows)]="transactionsQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="transactionsQH.loading"
        [showCurrentPageReport]="!transactionsQH.loading"
        breakpoint="620px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th class="p-text-center" style="width: 1rem">Status</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Created <br /><small>(Paid)</small></th>
                <th>Invoice</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-t
        >
            <tr
                class="selectable-row"
                (click)="selectTransaction(t)"
            >
                <td class="p-text-center">
                    <span class="p-column-title">Status</span>
                    <app-object-state
                        [object]="t"
                        objectType="transaction"
                        [colorful]="true"
                        ></app-object-state>
                </td>
                <td>
                    <span class="p-column-title">Amount</span>
                    {{t.amount / 100 | currency : t.currency}}</td>
                <td>
                    <span class="p-column-title">Type</span>
                    <span>{{t.type | titlecase}} </span>
                    <small>
                        {{ t.paymentType.name }}
                    </small>
                </td>
                <td>
                    <span class="p-column-title">Created (Paid)</span>
                    <span>
                        {{
                            t.createdAt | freyaDate : 'h:mm a, MMM d, y'
                        }}
                    </span>
                    <small
                        *ngIf="t.paidAt"
                    >
                        ({{ t.paidAt | freyaDate : 'h:mm a, MMM d, y' }})
                    </small>
                </td>
                <td (click)="$event.stopPropagation(); openTransactionInvoice(t)">
                    <span class="p-column-title">Invoice</span>
                    <span *ngIf="t.invoice" class="freya-link-button">
                    {{ t.invoice | invoiceName : (t.invoice?.job?.users | jobCustomer) : t?.invoice?.job?.code }}
                    </span>
                    <span *ngIf="!t.invoice">No Active Invoice</span>
                </td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'5'">
                    {{emptyMessage}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-mutate-transaction
    #mutate
    mutateType='create'
    [user]="user"
    [job]="job"
></app-mutate-transaction>
