import { gql } from 'graphql-tag';

export interface ListHistoryOutput {
	history: HistoryPagination;
}

export interface History {
    id: string;
    // Field for extra information
    text?: string;
    // Action type, eg. 'ADDED_COMMENT', 'REMOVED_ATTENDEE'
    type?: string;
    // Ids of the items related to this history.
    relatedItems?: string[];
    // The user that created the history. Eg. If Timmy adds a user he will be the user for a history of type ADDED_USER.
    user?: string;
    attributes?: string[];
    metadata?: string;
    createdAt?: number;
}


export interface HistoryPagination {
    history: History[];
    skipped?: number;
    limited?: number;
    total?: number;
}


export interface ListHistoryInput {
   filter: HistoryFilter;
   skip?: number;
   limit?: number;
}


export interface HistoryFilter {
   objectId?: string;
   historyIds?: string;
    // The users who created the history, Not relatedItems
   userIds?: string[];
    // The ids of Items that are related to the history. Eg. The ID of tag if the type was TAG_ADDED
   relatedItemIds?: string[];
    // Action type, eg. 'ADDED_COMMENT', 'REMOVED_ATTENDEE'
   types?: string[];
}


export interface ListObjectHistoryInput {
   historyIds?: string;
    // The users who created the history, Not relatedItems
   userIds?: string[];
    // The ids of Items that are related to the history. Eg. The ID of tag if the type was TAG_ADDED
   relatedItemIds?: string[];
    // Action type, eg. 'ADDED_COMMENT', 'REMOVED_ATTENDEE'
   types?: string[];
   skip?: number;
   limit?: number;
}

export interface HistoryInput {
	input?: ListHistoryInput;
}

export const historyBaseGQL = `
   id
   text
   type
   relatedItems
   user
   createdAt
`;

export const historyQuery = gql`
	query history($input: ListHistoryInput!, ){
		history(input: $input, ){
			    history{
                    id
                    text
                    type
                    relatedItems
                    user
                    attributes
                    metadata
                    createdAt
                }
			    skipped
			    limited
			    total

	}		}
`;

