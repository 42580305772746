<section *ngIf="{
    job: job$ | async,
    salesAgent: salesAgent$ | async,
    estimator: estimator$ | async,
    jobLoaded: jobLoaded$ | async
  } as state">
  <ng-container *ngIf="state.jobLoaded; else loading">
    <app-overview-chip
      icon="pi-calendar"
      [value]="
    state.job.timeline ? (state.job.timeline | date: 'MMM dd yyyy') : 'N/A'
  "
      label="Timeline"
    ></app-overview-chip>
    <app-overview-chip
      icon="pi-dollar"
      [value]="
    state.job.total
      ? (state.job.total | freyaCurrency: state.job.currency)
      : 'N/A'
  "
      label="Revenue"
    ></app-overview-chip>
    <app-job-agent
      class="agentChip"
      [jobId]="state.job.id"
      [agent]="state.salesAgent"
      role="Sales Agent"
    ></app-job-agent>
    <app-job-agent
      class="agentChip"
      [jobId]="state.job.id"
      [agent]="state.estimator"
      role="Estimator"
    ></app-job-agent>
  </ng-container>
</section>

<ng-template #loading>
  <p-skeleton
    class="flex-1"
    borderRadius="2.1875rem"
    *ngFor="let _ of [1, 2, 3, 4]"
    width="100%"
    height="4rem"
  />
</ng-template>