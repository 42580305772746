import { HistoryPartsFragment } from '../../generated/graphql.generated';

export function determineHistoryName(
    history: HistoryPartsFragment,
) {
    const obj = history?.vars?.obj;
    if (!obj) { return undefined; }

    const possibleObjectIdentifierKeys = [
      'name',
      'title',
      'code',
      'type',
      'label',
      'givenName',
      'familyName',
      'email',
      'key',
    ];

    const matchingIdentifier = possibleObjectIdentifierKeys
      .find((possibleIdentifierKey) => obj[possibleIdentifierKey]);

    if (!matchingIdentifier) { return undefined; }

    return obj[matchingIdentifier];

}
