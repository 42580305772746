<article>
  <div class="p-d-flex p-jc-between">
    <div class="p-d-flex p-flex-column event-title">
      <span
        class="customer-name"
        (click)="openEventDetails()"
        (dblclick)="openJob()"
      >
        <a>{{ event.customer.name }}</a>
        <a class="job-link">({{ event.event.job.code }})</a>
      </span>

      <span>{{ event.startTime }} - {{ event.endTime }}</span>

      <div class="event-metadata">
        <p-chip
          [style]="event.style"
          label="{{ event.event.type | titlecase }}"
        />
        <p-chip
          label="{{ event.event.status | titlecase }}"
          [icon]="EVENT_STATUS_ICONS[event.event.status]"
        />
      </div>
    </div>
    <p-button icon="pi pi-ellipsis-v" text="true" (onClick)="op.toggle($event)">
      <p-overlayPanel #op>
        <div class="dispatch-menu">
          <div
            class="dispatch-menu-section"
            *ngFor="let submenu of items; let i = index"
          >
            <ul>
              <li
                *ngFor="let item of submenu"
                [ngClass]="{ disabled: item?.disabled }"
              >
                <a class="p-d-flex p-ai-center" (click)="item.command()">
                  <span [class]="item.icon"></span>
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </li>
            </ul>
            <p-divider *ngIf="i !== items.length - 1"></p-divider>
          </div>
        </div> </p-overlayPanel
    ></p-button>
  </div>

  <section>
    <div class="section-header">
      <div class="section-title">
        <span>Trucks</span>
        <p-chip
          [icon]="
            event.trucks.length === 0 ? 'pi pi-exclamation-triangle' : null
          "
          [label]="
            event.trucks.length === 0
              ? '0/1'
              : event.trucks.length + '/' + event.trucks.length
          "
          [ngClass]="{
            'conflict-chip': event.trucks.length === 0,
          }"
        />
      </div>
      <p-button
        icon="pi pi-pencil"
        text="true"
        (onClick)="openEditEvent()"
      ></p-button>
    </div>
    <div
      class="section-content"
      cdkDropList
      (cdkDropListDropped)="addAsset($event)"
      [cdkDropListEnterPredicate]="onlyTrucksPredicate"
    >
      <app-dispatch-chip
        *ngFor="let truck of event.trucks"
        cdkDrag
        [cdkDragData]="{
          asset: truck,
          event,
        }"
        [name]="truck?.name"
        [hasConflict]="truck?.hasConflict"
        [tooltipOptions]="tooltipOptions"
        [tooltipDisabled]="!truck?.hasConflict"
        [pTooltip]="
          truck?.name + ' is already assigned to another event at this time'
        "
        icon="pi pi-truck"
      ></app-dispatch-chip>
      <p-chip
        *ngIf="!event.trucks.length"
        label="Truck needed"
        styleClass="crew-needed-chip"
      />
    </div>
  </section>

  <section class="crew-section">
    <div class="section-header">
      <div class="section-title">
        <span>Crew </span>
        <p-chip
          pBadge
          [badgeDisabled]="!event.laborCharge.color"
          [ngClass]="{
            'custom-badge': true,
            'conflict-chip': event.needsMoreCrew,
          }"
          [ngStyle]="{ '--badge-background-color': event.laborCharge.color }"
          [tooltipDisabled]="!event.laborCharge.name"
          [pTooltip]="event.laborCharge.name"
          *ngIf="event.crew.length || event.crewRequirement"
          [icon]="event.needsMoreCrew && 'pi pi-exclamation-triangle'"
          [label]="event.crew.length + '/' + event.crewRequirement"
        />
      </div>

      <p-button
        icon="pi pi-pencil"
        text="true"
        (onClick)="openEditEvent()"
      ></p-button>
    </div>

    <div
      class="section-content"
      cdkDropList
      (cdkDropListDropped)="addCrewMember($event)"
      [cdkDropListEnterPredicate]="onlyAttendeesPredicate"
    >
      <app-dispatch-chip
        *ngFor="let user of event.crew"
        cdkDrag
        [cdkDragData]="{
          attendee: user,
          event,
        }"
        [name]="user?.name"
        [hasConflict]="user?.hasConflict"
        [ngStyle]="{
          order: user.role === EVENT_ATTENDEE_ROLES.crewLead ? 1 : 2,
        }"
        [showStar]="
          user.role === EVENT_ATTENDEE_ROLES.crewLead ||
          user.role === EVENT_ATTENDEE_ROLES.assistantCrewLead
        "
        [hasConflict]="user?.hasConflict"
        [tooltipDisabled]="!user?.hasConflict"
        [tooltipOptions]="tooltipOptions"
        [pTooltip]="
          user?.name + ' is already assigned to another event at this time'
        "
      ></app-dispatch-chip>
      <p-chip
        *ngIf="!event.crew.length"
        label="Crew needed"
        styleClass="crew-needed-chip"
      />
    </div>
  </section>
  <!-- TODO: Optimize this section and use semantic html -->
  <section>
    <div
      class="section-header"
      pTooltip="Subtotal after discounts (and before taxes)"
    >
      <div class="section-title">
        <span>Subtotal</span>
      </div>
      <div class="section-title">
        <!-- TODO: Fix the currency; make it dynamic -->
        <span>
          {{
            event.event?.discountedSubTotal
              | freyaCurrency: event?.event?.job?.currency || 'CAD'
          }}
        </span>
      </div>
    </div>
  </section>
</article>
