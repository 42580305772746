
export interface DashboardInfoItem {
	key: string;
	body: string;
	reportTypeId?: string;
}

export const defaultDashboardInfoItem: DashboardInfoItem = {
	key: 'default',
	body: `
Sorry! There is no help documentation for this dashboard item
just yet.

Contact Support to learn more about what this dashboard item does.
`,
};

export const dashboardInfoItems: DashboardInfoItem[] = [
	{
		key: 'Job Stages',
		body: `
### Job Stages

Shows the number of jobs created in the period that are *open*.
Jobs that had that stage in the past will not be counted in
this dashboard item. 
		`,
		reportTypeId: 'db-current-jobs',
	},
	{
		key: 'Average Invoice Size',
		body: `
### Average Invoice Size

The average discounted sub total amount for jobs in the \`invoice\` stage
that were *created* during the period.

In this case, the \`(+∞%)\` means that the current period's average job
size is infinitely greater than the last periods job size (In this case,
there were no jobs in the previous period and the average job size
was zero)

\`Average Invoice Size\` = \`SUM of discounted subtotals for all jobs in the stage
invoice\` with a total greater than zero / \`COUNT of all jobs in the stage invoice with a total greater than zero\`
		`,
		reportTypeId: 'db-current-jobs',
	},
	{
		key: 'Transaction Volume',
		body: `
### Transactions Paid During Period

A graph of the sum total of transactions that were *paid* within the 
period. The horizontal and vertical scale of this graph will change 
based on the selected period and the largest transaction amount, 
respectively.

		`,
		reportTypeId: 'db-transaction-volume',
	},
	{
		key: 'Revenue',
		body: `
### Revenue

**A note on infinite percentages:** In this case, the \`(+∞%)\` means that 
the current period's revenue is infinitely greater than the last periods 
revenue (In this case, there were no jobs in the previous period, so the 
last periods revenue is zero and, since you can't divide by zero in a 
conventional sense, \`60 / 0 = ∞\`); The alternative here is *not* 
showing this metric but it is more useful to know that the last 
period had no revenue than it is to not show this metric.
		`,
		reportTypeId: '5020.0-expected-revenue',
	},
	{
		key: 'Realized Revenue',
		body: `
#### Realized Revenue
The discounted sub total of all scheduled events within a given period that have been booked and completed,
calculated as the sum of their discounted subtotals without including any damages.
		`,
		reportTypeId: '5020.0-expected-revenue',
	},
	{
		key: 'Scheduled Revenue',
		body: `
#### Scheduled Revenue
The discounted sub total of all scheduled events within a given period that have been booked but not yet completed,
calculated as the sum of their discounted subtotals without including any damages.
		`,
		reportTypeId: '5020.0-expected-revenue',
	},
	{
		key: 'Expected Revenue',
		body: `
#### Expected Revenue
The discounted sub total of all scheduled events within a given period that have been booked or completed,
calculated as the sum of their discounted subtotals without including any damages.
		`,
		reportTypeId: '5020.0-expected-revenue',
	},
	{
		key: 'Logistics',
		body: `
### Logistics
#### Distance
Sum of travel distances between locations for all calendar events for jobs 
created in a period. Travel distance is considered "complete" when the 
event has been completed.

#### Travel Time
Sum of travel times between locations for all calendar events for jobs 
created in a period. Travel time is considered "complete" when the event 
has been completed.

		`,
		reportTypeId: 'db-logistics-distances',
	},
	{
		key: 'CSC conversion',
		body: `
### CSC Conversion

CSC = Corporate Sales Centre

Useful for determining the percentage of CSC leads that book a revenue 
generating event.

The percentage of jobs *created* within the period that have the 
*Job Origin* of \`CSC\` that have gone from the \`lead\` to the 
\`booking\` stage (ie, they have booked a revenue generating event such as a move).

\`CSC Conversion %\` = \`CSC Jobs that have been in the booking stage\`
 / \`Total CSC Jobs\`
		`,
		reportTypeId: 'db-csc-conversion',
	},
	{
		key: 'OBE conversion',
		body: `
### OBE Conversion Percentage

Useful for determining the percentage of OBE leads that book a revenue 
generating event.

The percentage of jobs *created* within the period that have the *Job
Origin* of \`OBE\` that have funnelled into the \`booking\` stage (ie, 
	they have booked a revenue generating event such as a move).

\`OBE Conversion %\` = \`OBE Jobs that have been in the booking stage\`
 / \`Total OBE Jobs\`
		`,
		reportTypeId: 'db-obe-conversion',
	},
	{
		key: 'OSE conversion',
		body: `
### OSE Conversion

Useful for determining the percentage of on-site estimates that book a 
revenue generating event.

The percentage of jobs *created* within the period that have completed 
an \`OSEstimate\` event that have funneled into the \`booking\` stage (ie, they have booked a revenue generating event such as a move).

\`OSE Conversion %\` = \`Jobs with an OSEstimate that have been in the 
booking stage\` / \`Jobs with an OSEstimate\`

		`,
		reportTypeId: 'db-ose-conversion',
	},
	{
		key: 'Events Created',
		body: `
### Events Created
A summary of events that have been *created* during the period. 
This does not include pending events. This includes estimates, 
even though estimates are not supposed to be revenue generating.

		`,
		reportTypeId: 'db-created-events',
	},
	{
		key: 'Events Happening',
		body: `
### Events Happening
A summary of events that have are *happening* during the period. 
This does not include pending events. This includes estimates, 
even though estimates are not supposed to be revenue generating.

Note: This is based on the event *end* time, so if your period is 
for "Today", but you have an event that starts yesterday, this 
event will be included in today's dashboard but not yesterday's.
		`,
		reportTypeId: 'db-happening-events',
	},
];
