import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { clone } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { BaseTaxFragment, DeletePricesGQL, FullPriceFragment, FullProductFragment, ListPricesGQL, ListPricesQuery, ListPricesQueryVariables, UpdatePricesGQL } from '../../../generated/graphql.generated';
import { OBJECT_ICON_MAP } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { DisabledWhen, parseMenuItemCategoriesVisible, setMenuItemDisabled } from '../../utilities/menu-item.util';

@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss']
})
export class PriceDetailsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() price: FullPriceFragment;

  @Input() product: FullProductFragment;

  icon = `${OBJECT_ICON_MAP.price} large-icon`;
  subs = new SubSink();

  loading = false;

  editPriceItem = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutateSvc.openMutateObject({
        mutateType: 'update',
        objectType: 'price',
        object: this.price,
        additionalValues: [{
          property: 'product',
          value: this.product,
        }],
      });
    },
  };

  deletePriceItem = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    disabledWhen: {
      objectDeleted: true,
    },
    command: () => {
      this.freyaMutateSvc.openDeleteObject({
        objectId: this.price.id,
        objectName: this.price.name,
        objectType: 'price',
      });
    }
  };

  restorePriceItem = {
    label: 'Restore',
    icon: 'pi pi-undo',
    visible: false,
    command: () => {
      this.confirmationService.confirm({
        header: `Restore Price?`,
        message: `Are you sure you want to restore: ${this.price.name}`,
        acceptLabel: 'Restore Price',
        acceptIcon: 'pi pi-undo',
        rejectLabel: `Don't Restore`,
        rejectIcon: 'pi pi-times',
        accept: () => {
          this.deletePricesGQL.mutate({ids: [this.price.id], restore: true }).subscribe(() => {
            this.localNotify.success(`Price restored`);
            this.detailsHelperSvc.pushUpdate({
              action: 'update',
              id: this.price.id,
              type: 'Prices',
            });
          }, (err) => {
            this.localNotify.apolloError(`Failed to restore price`, err);
            console.log(err);
          });
        }
      });
    },
  };

  priceActions = [
    {
      label: 'Price Actions',
      visible: false,
      items: [
        this.editPriceItem,
        this.deletePriceItem,
        this.restorePriceItem,
      ]
    }
  ] as MenuItem[];

  get active() {
    return this.price?.active || false;
  }

  queryRef: QueryRef<ListPricesQuery, ListPricesQueryVariables>;

  constructor(
    private updatePricesGQL: UpdatePricesGQL,
    private listPricesGQL: ListPricesGQL,
    private detailsHelperSvc: DetailsHelperService,
    private freyaMutateSvc: FreyaMutateService,
    private permissionHandler: PermissionService,
    private cd: ChangeDetectorRef,
    private localNotify: FreyaNotificationsService,
    private deletePricesGQL: DeletePricesGQL,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    this.subs.sink = this.detailsHelperSvc.getObjectUpdates('Products').subscribe(()=>{
      this.refetch();
    });
    this.subs.sink = this.detailsHelperSvc.getObjectUpdates('Prices').subscribe(()=>{
      this.refetch();
    });
  }

  ngOnChanges() {
    if (!this.price?.product || !this.price.taxes){
      this.refetch();
      return;
    }

    this.setActionStates();
  }

  getFetchVariables() {
    return {
      filter: {
        priceIds: [ this.price.id ],
      },
      limit: 1,
    } as ListPricesQueryVariables;
  }

  refetch() {
    if (this.queryRef) {
      this.queryRef.refetch(this.getFetchVariables());
      return;
    };

    this.initQuery();

  }

  initQuery() {

    this.queryRef = this.listPricesGQL.watch(this.getFetchVariables(), {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.queryRef.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      const [ resPrice ] = res.data?.prices?.prices || [];
      if (!resPrice) { return; }
      this.price = resPrice;

      this.setActionStates();
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions([
      'prices.update',
      'prices.delete',
    ]).subscribe((res) => {

      // Permission check for price action
      this.editPriceItem.visible = res[0];
      this.deletePriceItem.visible = res[1];

      parseMenuItemCategoriesVisible(this.priceActions);

      this.priceActions = clone(this.priceActions);
    });
  }


  // Toggle the status of the price
  changePriceStatus(status: boolean) {
    this.subs.sink = this.updatePricesGQL.mutate(
      {
        prices:
          [{
            priceId: this.price.id,
            active: status
          }]
      }).subscribe((res) => {
        this.price.active = status;
        this.detailsHelperSvc.pushUpdate({
          id:this.price.id,
          type:'Prices',
          action:'update'
        });
        this.localNotify.addToast.next({
          severity: 'success',
          summary: `Price ${status ? 'activated' : 'deactivated'}`
        });
      });
  }

  openProduct() {
    this.detailsHelperSvc.open('product', this.product);
  }

  openTax(tax: BaseTaxFragment) {
    this.detailsHelperSvc.open('tax', tax);

  }

  /**
   * Set the state of the actions based on the current price
   */
  setActionStates() {
    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.price.deletedAt),
    };

    for (const action of this.priceActions[0].items){
      setMenuItemDisabled(action, disabledWhen);
    }

    this.restorePriceItem.visible = disabledWhen.objectDeleted;

    parseMenuItemCategoriesVisible(this.priceActions);

    this.priceActions = clone(this.priceActions);
  }

}
