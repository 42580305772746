export class InvalidTokenError extends Error {
    constructor(msg) {
        super(msg);
    }
}

export function jwtDecode(token: string, options?: { header?: boolean }) {
    if (typeof token !== 'string') {
        throw new InvalidTokenError('Invalid token specified');
    }

    options = options || {};
    const pos = options.header === true ? 0 : 1;
    try {
        return JSON.parse(atob(token.split('.')[pos]));
    } catch (e) {
        throw new InvalidTokenError('Invalid token specified: ' + e.message);
    }
};
