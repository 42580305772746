import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { TreeNode } from 'primeng/api';
import { SubSink } from 'subsink';

import { HistoryPartsFragment, ListHistoryGQL, ListHistoryQuery, ListHistoryQueryVariables } from '../../../generated/graphql.generated';
import { MarkdownHelperService } from '../../services/markdown.service';

@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrl: './history-details.component.scss'
})
export class HistoryDetailsComponent implements OnChanges, OnDestroy {

  historyQueryRef: QueryRef<ListHistoryQuery, ListHistoryQueryVariables>;

  subs = new SubSink();

  loading = true;

  history: HistoryPartsFragment;

  variables: TreeNode[];

  constructor(
    private listHistoryGQL: ListHistoryGQL,
    public markdownHelper: MarkdownHelperService,
  ) {}

  @Input() historyId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('historyId') && this.historyId) {
      this.retrieveHistory();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveHistory() {

    if (this.historyQueryRef) {
      this.historyQueryRef.refetch({ input: { filter: { id: this.historyId } } });
      return;
    }

    this.historyQueryRef = this.listHistoryGQL.watch({ input: { filter: { id: this.historyId }  } }, {
      notifyOnNetworkStatusChange: true,
      useInitialLoading: true,
    });

    this.subs.sink = this.historyQueryRef.valueChanges.subscribe((res) => {

      this.loading = res.loading;

      if (res.loading) { return; }

      this.history = res.data.history.history[0];

      this.variables = this.history.vars ? [ this.getNode('Variables', this.history.vars) ] : [];

    });
  }

  getNode(label: string, object: any) {

    const node = {
      label,
      data: object,
      children: [],
    };

    if (object === null || object === undefined) {
      label = `${label}: null`;
      return node;
    }

    if (Array.isArray(object)) {
      for (let i = 0; i < object.length; i++) {
        node.children.push(this.getNode(`${label} ${i + 1}`, object[i]));
      }
      return node;
    }

    if (typeof object === 'object') {
      for (const key of Object.keys(object)) {
        node.children.push(this.getNode(key, object[key]));
      }
      return node;
    }

    node.label = `${label}: ${object}`;

    return node;
  }

}
