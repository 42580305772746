<form [formGroup]="disableRestrictionsForm"
    (ngSubmit)="validateRestrictionsCode()"
    pFocusTrap
    (keydown.enter)="$event.preventDefault(); validateRestrictionsCode()">
    <div *ngIf="estimateHelper.restrictionsEnabled.value">
        <div *ngIf="requiresCode; else noCodeRequired">
            <p>Enter Code</p>
            <br>
            <div *appVar="disableRestrictionsForm.get('code') as control">
                <input
                    type="password"
                    formControlName="code"
                    autocomplete="off"
                    pAutoFocus
                    [autofocus]="true" 
                    pInputText
                    (enter)="validateRestrictionsCode()"
                    data-cy="code-input-text-field"
                >
                <br>
                <small
                    class="error-text"
                    *ngIf="control.invalid && control.dirty"
                >Provided code was not corrrect</small>
                <br>

            </div>
        </div>
        <div *ngIf="!requiresCode" #noCodeRequired>
            Users with your role are not required to enter the code
            <br>
            <br>
        </div>
        <small>
            Disabling booking restrictions will allow you to ignore the systems
            restrictions foravailability, locations, etc.
        </small>
    </div>
    <br>
    <div *ngIf="!estimateHelper.restrictionsEnabled.value">
        <p>Are you sure you want to re-enable restrictions?</p>
    </div>
    <br>
    <div class="p-grid p-text-right">
        <div class="p-col">
            <button
                pButton
                type="button"
                class="p-button-text"
                label="Cancel"
                icon="pi pi-times"
                (click)="closeDialog()"
            ></button>
            <button
                *ngIf="estimateHelper.restrictionsEnabled.value"
                [disabled]="disableRestrictionsForm.invalid && requiresCode"
                class="p-button-warning"
                type="submit"
                pButton
                pAutoFocus
                [autofocus]="!requiresCode"
                icon="pi pi-ban"
                label="Disable"
                data-cy="disable-restriction-submit-button"
            ></button>
            <button
                *ngIf="!estimateHelper.restrictionsEnabled.value"
                class="p-button-success"
                type="submit"
                [autofocus]="true" 
                pInputText
                pButton
                icon="pi pi-check"
                label="Re-Enable"
                data-cy="enable-restriction-submit-button"
            ></button>
        </div>
    </div>
</form>