<div
    class="create-comment-input"
    [formGroup]="commentForm"
>
    <ng-template #text>
        <div *appVar="commentForm.get('text') as control">
            <label>Text</label>
            <br />
            <textarea
                class="full-width"
                type="text"
                [rows]="10"
                [cols]="40"
                pInputTextarea
                formControlName="text"
                placeholder="Enter Text *"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="update-note-textarea-field"
            ></textarea>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #requiredAccessLevel>
        <div *appVar="commentForm.get('requiredAccessLevel') as control">
            <label>Required Level</label>
            <br />
            <p-dropdown
                class="full-width"
                placeholder="Select an Access Level"
                [options]="accessLevels"
                formControlName="requiredAccessLevel"
                appendTo="body"
            >
            <ng-template let-level pTemplate="item">
                <span>{{level | titlecase}}</span>
            </ng-template>
            <ng-template let-level pTemplate="selectedItem">
                <span>{{level | titlecase}}</span>
            </ng-template>
        </p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <!-- <ng-template #attributes>
        <div  *appVar="commentForm.get('attributes') as control">
            <label>Attributes (comma seperated)</label>
            <br />
            <p-chips formControlName="attributes"
                separator=","
                addOnBlur="addOnBlur"
                [allowDuplicate]="false"
            ></p-chips>
            <app-validation-messages [control]="control"></app-validation-messages>
        </div>
    </ng-template> -->
</div>

<app-mutate-object
    #mutate
    [fg]="commentForm"
    objectType="Comment"
    [mutateType]="mutateType"
    [titleText]="titleText"
    reviewButtonText="{{mutateType === 'create' ? 'Add' : 'Update'}} Note"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>