import { Pipe, PipeTransform } from '@angular/core';

import { strToTitleCase } from '../js';

@Pipe({
  name: 'filterValue'
})
export class FilterValuePipe implements PipeTransform {

  transform(value: string | string []): unknown {
    if (typeof value === 'string') {
      return strToTitleCase(value);
    }

    return value
      .map((i) => strToTitleCase(i))
      .join(', ');
  }

}
