<div class="layout-rightpanel" (click)="appMain.rightPanelClick = true">
  <!-- <button pButton label="Open" (click)="updateObj.openDialog()"></button> -->
  <div
    class="rightpanel-wrapper"
    *ngIf="detailsItem && isItemInCurrentZoneOrBelow"
  >
    <app-users-details
      *ngIf="detailsItem.type === 'users'"
      [user]="detailsItem.item"
    ></app-users-details>
    <app-users-details
      *ngIf="detailsItem.type === 'users-basic'"
      [user]="detailsItem.item"
      [basic]="true"
    ></app-users-details>
    <app-role-details
      *ngIf="detailsItem.type === 'role'"
      [role]="detailsItem.item"
    ></app-role-details>
    <app-availability-details
      *ngIf="detailsItem.type === 'availability-template'"
      [template]="detailsItem.item"
    ></app-availability-details>
    <app-calendar-event-details
      *ngIf="detailsItem.type === 'calendar-event'"
      [event]="detailsItem.item"
    ></app-calendar-event-details>
    <app-product-details
      *ngIf="detailsItem.type === 'product'"
      [product]="detailsItem.item"
    ></app-product-details>
    <app-price-details
      *ngIf="detailsItem.type === 'price'"
      [price]="detailsItem.item"
      [product]="additionalValues?.product"
    ></app-price-details>
    <app-charge-details
      *ngIf="detailsItem.type === 'charge'"
      [charge]="detailsItem.item"
    ></app-charge-details>
    <app-asset-details
      *ngIf="detailsItem.type === 'asset'"
      [asset]="detailsItem.item"
    ></app-asset-details>
    <app-location-details
      *ngIf="detailsItem.type === 'location'"
      [location]="detailsItem.item"
    ></app-location-details>
    <app-job-details
      *ngIf="detailsItem.type === 'job'"
      [jobData]="detailsItem.item"
    ></app-job-details>
    <app-report-type-details
      *ngIf="detailsItem.type === 'reportType'"
      [rt]="detailsItem.item"
    ></app-report-type-details>
    <app-scheduled-report-details
      *ngIf="detailsItem.type === 'scheduled-report'"
      [sr]="detailsItem.item"
    ></app-scheduled-report-details>
    <app-report-details
      *ngIf="detailsItem.type === 'report'"
      [report]="detailsItem.item"
      [loaded]="detailsItem.loaded"
    ></app-report-details>
    <app-franchise-details
      *ngIf="detailsItem.type === 'franchise'"
      [franchise]="detailsItem.item"
    ></app-franchise-details>
    <app-area-details
      *ngIf="detailsItem.type === 'area'"
      [area]="detailsItem.item"
    ></app-area-details>
    <app-transaction-details
      *ngIf="detailsItem.type === 'transaction'"
      [transaction]="detailsItem.item"
    ></app-transaction-details>
    <app-artifact-details
      *ngIf="detailsItem.type === 'artifact'"
      [artifact]="detailsItem.item"
    ></app-artifact-details>
    <app-discount-details
      *ngIf="detailsItem.type === 'discount'"
      [discount]="detailsItem.item"
    ></app-discount-details>
    <app-tax-details
      *ngIf="detailsItem.type === 'tax'"
      [tax]="detailsItem.item"
    ></app-tax-details>
    <app-lead-source-details
      *ngIf="detailsItem.type === 'LeadSource'"
      [leadSource]="detailsItem.item"
    ></app-lead-source-details>
    <app-rule-details
      *ngIf="detailsItem.type === 'rules'"
      [rule]="detailsItem.item"
    ></app-rule-details>
    <app-invoice-details
      *ngIf="detailsItem.type === 'invoice'"
      [invoiceId]="detailsItem.item?.id"
    ></app-invoice-details>
    <app-book-off-details
      *ngIf="detailsItem.type === 'book-off-event'"
      [bookOffEvent]="detailsItem.item"
    ></app-book-off-details>
    <app-history-details
      *ngIf="detailsItem.type === 'history'"
      [historyId]="detailsItem.item"
    ></app-history-details>
  </div>
  <div
    class="rightpanel-wrapper"
    *ngIf="detailsItem && !isItemInCurrentZoneOrBelow"
  >
    <div class="card">
      <p-messages severity="warn">
        <ng-template pTemplate>
          <div>
            <p>
              The item you are trying to view cannot be viewed from your current
              zone.
            </p>
            <p>Do you want to switch to {{ itemZone?.name }}?</p>
          </div>
        </ng-template>
      </p-messages>
      <div class="p-d-flex p-jc-evenly">
        <button pButton label="Switch" (click)="contextIntoItemZone()"></button>
        <button
          pButton
          label="Close"
          class="p-button-secondary"
          icon="pi pi-times"
          (click)="appMain.onRightPanelClick($event)"
        ></button>
      </div>
    </div>
  </div>
  <!-- <div>
        <app-update-object #updateObj objectTitle="Edit Test" [tabs]="[{name: 'Test', ref: test}, {name: 'Test2', ref: test2}]">
            <ng-template #test>
                <p >Test</p>
            </ng-template>
            <ng-template #test2>
                <p >Test 2</p>
            </ng-template>
        </app-update-object>
    </div> -->
</div>
