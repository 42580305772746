import { v4 as uuid } from 'uuid';

export enum LoadingState {
	// for calls that have not yet been initialized
	INIT = 'INIT',

	/**
	 * Use this state for calls that have not been
	 * made yet or need to be made but we are offline
	 * or waiting for another mutation to finish
	 */
	PENDING = 'PENDING',

	LOADING = 'LOADING',
	LOADED = 'LOADED',

	MUTATING = 'MUTATING',
	MUTATED = 'MUTATED',
}

export interface ErrorState {
	error: Error | string;
}

export enum PendingReason {
	OFFLINE = 'OFFLINE',
	WAITING = 'WAITING',
}

export interface PendingState<INPUT = any> {
	pending: PendingReason;
	inputs: INPUT[];
}

export type CallState = LoadingState | ErrorState | PendingState;

export function generateUUID() {
	return uuid({});
}

export function isErrorState(state: CallState): state is ErrorState {
	return (state as ErrorState)?.error !== undefined;
}
