import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dayjs } from '@karve.it/core';
import { SelectItem } from 'primeng/api';
import { filter, first } from 'rxjs';
import { SubSink } from 'subsink';

import { DashboardService } from '../dashboard/dashboard.service';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../global.constants';
import { AdvancedPeriodOptions, advancedPeriodOptions } from '../reports/reports.constants';
import { QueryParamsService } from '../shared/query-params.service';

import { comparedToValues, ComparedTo, DashboardStore, LayoutSize, defaultLayoutSize, comparedToLsKey } from './dashboardv2.store';
import { ERROR_CONTAINER_ID } from './error-icon/error-icon.component';

// Regex to validate the format MMMM-YYYY (case-insensitive)
const MONTH_REGEX = /^(january|february|march|april|may|june|july|august|september|october|november|december)-\d{4}$/i;
@Component({
  selector: 'app-dashboardv2',
  templateUrl: './dashboardv2.component.html',
  styleUrl: './dashboardv2.component.scss',
  providers: [ DashboardStore ],
})
export class Dashboardv2Component implements OnInit, AfterViewInit, OnDestroy {

  layoutObserver!: ResizeObserver;

  layoutSize: LayoutSize = defaultLayoutSize;

  cols = [
    'header',
    'dropdowns',
    'needsAttention',
    'pipeline',
    'smallCards',
    'revenue',
    'conversions',
    'activity',
    'logistics',
    'eventSummary',
    'sources',
  ] as const;

  colClassMap: Record<typeof this.cols[number], Record<typeof this.layoutSize, string>> = {
    header: {
      large: 'p-col-4',
      medium: 'p-col-4',
      small: 'p-col-12',
    },
    dropdowns: {
      large: 'p-col-8 p-jc-end dropdowns',
      medium: 'p-col-8 p-jc-end dropdowns',
      small: 'p-col-12 dropdowns',
    },
    needsAttention: {
      large: 'p-col-4',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
    pipeline: {
      large: 'p-col-5',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
    smallCards: {
      large: 'p-col-3 p-flex-column small-cards',
      medium: 'p-col-12 small-cards',
      small: 'p-col-12 p-flex-column small-cards',
    },
    revenue: {
      large: 'p-col-6',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
    conversions: {
      large: 'p-col-6',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
    activity: {
      large: 'p-col-8',
      medium: 'p-col-12',
      small: 'p-col-12',
    },
    logistics: {
      large: 'p-col-4',
      medium: 'p-col-12',
      small: 'p-col-12',
    },
    eventSummary: {
      large: 'p-col-6',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
    sources: {
      large: 'p-col-6',
      medium: 'p-col-6',
      small: 'p-col-12',
    },
  }

  @ViewChild('layout') layout: ElementRef;

  periodOptions = [
    'Month of Year',
    ...advancedPeriodOptions,
  ];

  currencyOptions = [
    'USD',
    'CAD',
  ];

  comparedToOptions: SelectItem<ComparedTo>[] = comparedToValues
    .map((val) => ({
      label: `Compared to ${val}`,
      value: val,
    }));


  month: string;

  setPeriod = this.dashboardStore.setPeriod;

  setMonth = this.dashboardStore.setMonth;

  setComparedTo = this.dashboardStore.setComparedTo;

  setCurrency = this.dashboardStore.setCurrency;

  header$ = this.dashboardStore.headerViewModel$;

  averageInvoiceSize$ = this.dashboardStore.averageInvoiceSizeViewModel$;

  conversions$ = this.dashboardStore.conversionsViewModel$;

  subs = new SubSink();

  errorContainerId = ERROR_CONTAINER_ID;

  customPeriod: Date[];

  lastValidCustomPeriod: Date[];

  constructor(
    private readonly dashboardStore: DashboardStore,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private queryParamsService: QueryParamsService,
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.header$.subscribe((header) => {
      this.month = header.month;
      this.customPeriod = header.customPeriod;
      this.lastValidCustomPeriod = header.customPeriod;
    });

    this.subs.sink = this.route.queryParams
      .pipe(
        filter(params => 'zone' in params),
        first(),
      )
      .subscribe((params) => {

        const copy = { ...params };

        const validPeriod = this.isValidPeriod(params.period);

        if (!validPeriod) {
          this.clearParams();
          this.checkLocalStorage();
          return;
        }

        this.setPeriod(validPeriod);

        let hasInvalidParams = false;

        if (validPeriod === 'Custom') {

          const validCustomPeriod = this.isValidCustomPeriod(params.customPeriod);

          if (validCustomPeriod) {
            this.dashboardStore.setCustomPeriod(validCustomPeriod);
          } else {
            copy.customPeriod = null;
            hasInvalidParams = true
          }
        } else if (params.customPeriod) {
          copy.customPeriod = null;
          hasInvalidParams = true;
        }

        if (validPeriod === 'Month of Year') {

          const validMonth = this.isValidMonth(params.month);

          if (validMonth) {
            this.setMonth(validMonth);
          } else {
            copy.month = null;
            hasInvalidParams = true;
          }

        } else if (params.month) {
          copy.month = null;
          hasInvalidParams = true;
        }

        if (validPeriod === 'Month of Year' || validPeriod === 'MTD') {

          const validComparedTo = this.isValidComparedTo(params.comparedTo);

          if (validComparedTo) {
            this.setComparedTo(validComparedTo);
          } else {
            copy.comparedTo = null;
            hasInvalidParams = true;
          }
        } else if (params.comparedTo) {
          copy.comparedTo = null;
          hasInvalidParams = true;
        }

        if ((validPeriod === 'Month of Year' || validPeriod === 'MTD') && copy.comparedTo === null) {
          this.checkLocalStorage();
        }

        if (hasInvalidParams) {
          this.router.navigate([], {
            queryParams: copy,
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  clearParams() {
    return this.router.navigate([], {
      queryParams: {
        period: null,
        customPeriod: null,
        month: null,
        comparedTo: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngAfterViewInit(): void {
    this.watchLayout();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.layoutObserver.disconnect();
  }

  isValidPeriod(text: string) {
    if (!text) { return };
    const decoded = text.replaceAll('-', ' ');
    for (const val of [ ...advancedPeriodOptions, 'Month of Year' ]) {
      if (val === decoded) {
        return decoded as (AdvancedPeriodOptions | 'Month of Year');
      }
    }
  }

  isValidCustomPeriod(text: string) {
    return this.queryParamsService.parseStartEndQueryParam(text);
  }

  isValidMonth(text: string): string | null {
    if (!text || typeof text !== 'string') return null;

    // Validate the format
    if (!MONTH_REGEX.test(text)) return null;

    // Replace hyphens with spaces and trim extra whitespace
    const normalizedText = text.replace(/-/g, ' ').trim();
    const parsedDate = dayjs(normalizedText, 'MMMM YYYY', true);

    return parsedDate.isValid() ? parsedDate.format('MMMM YYYY') : null;
  }

  isValidComparedTo(text: string) {
    if (!text) { return };
    const decoded = text.replaceAll('-', ' ');
    for (const val of comparedToValues) {
      if (val === decoded) {
        return decoded;
      }
    }
  }

  watchLayout() {
    this.layoutObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {

        const { width } = entry.contentRect;

        let newSize: LayoutSize;

        if (width > MD_BREAKPOINT) {
          newSize = 'large';
        } else if (width > SM_BREAKPOINT) {
          newSize = 'medium';
        } else {
          newSize = 'small';
        }

        if (newSize !== this.layoutSize) {
          this.layoutSize = newSize;
          this.dashboardStore.setLayoutSize(newSize);
        }
      }
    });

    this.layoutObserver.observe(this.layout.nativeElement);
  }

  validateComparedTo(input: string): ComparedTo {
    for (const val of comparedToValues) {
      if (val === input) {
        return val;
      }
    }
  }

  backToDashboardV1() {
    this.dashboardService.setDashboardVersion('v1');
  }

  validateCustomPeriod() {
    if (this.customPeriod.filter(Boolean).length === 2) {
      this.dashboardStore.setCustomPeriod(this.customPeriod);
    } else {
      this.customPeriod = this.lastValidCustomPeriod;
    }
  }

  checkLocalStorage() {
    const validComparedToFromLocalStorage = this.isValidComparedTo(localStorage.getItem(comparedToLsKey));

    if (validComparedToFromLocalStorage) {
      this.setComparedTo(validComparedToFromLocalStorage);
    }
  }
}
