import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';

import { switchMap, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import {
  BaseCompanyInfoFragment,
  CompanyInfoGQL,
  DisconnectFromQuickbooksGQL,
  GetConfigValuesGQL,
  GetConfigValuesQuery,
  GetConfigValuesQueryVariables,
  QuickbooksTaxCodesGQL,
  QuickbooksTaxCodeWithSalesTaxListFragment,
  SetConfigValuesGQL,
} from '../../../generated/graphql.generated';
import { BrandingService } from '../../services/branding.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';


@Component({
  selector: 'app-quickbooks-dashboard',
  templateUrl: './quickbooks-dashboard.component.html',
  styleUrls: ['./quickbooks-dashboard.component.scss']
})
export class QuickbooksDashboardComponent implements OnInit, OnDestroy {

  companyInfo: BaseCompanyInfoFragment;

  taxCodes: (QuickbooksTaxCodeWithSalesTaxListFragment & { disabled: boolean })[];

  defaultTaxCode: string;

  getConfigValuesQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  subs = new SubSink();

  constructor(
    private route: ActivatedRoute,
    private companyInfoGQL: CompanyInfoGQL,
    private disconnectFromQuickbooksGQL: DisconnectFromQuickbooksGQL,
    private brandingService: BrandingService,
    private localNotify: FreyaNotificationsService,
    private router: Router,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private quickbooksTaxCodesGQL: QuickbooksTaxCodesGQL,
  ) { }

  ngOnInit(): void {

    this.route.queryParams.pipe(take(1)).subscribe(() => {

      /**
       * If window.opener is not undefined, that means
       * (1) we are in the authentication popup,
       * (2) authentication was successful
       * (3) Quickbooks redirected back to current route of app
       */
      if (window.opener) {

        // If that's the case, have the original window redirect to Quickbooks dashboard...
        window.opener.location.href = '/integration/quickbooks';
        // ...then close current popup
        window.close();
      } else {
        this.retrieveCompanyInfo();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveCompanyInfo() {
    this.subs.sink = this.brandingService.currentZone().pipe(
      switchMap(() => this.companyInfoGQL.fetch()),
    ).subscribe((res) => {
      if (res.loading) { return; }
      this.companyInfo = res.data.companyInfo;

      if (this.companyInfo.Country === 'CA') {
        this.getTaxCodes();
        this.getDefaultTaxCode();
      }

    }, (err) => {
      this.localNotify.apolloError('Unable to connect to Quickbooks', err);
      this.router.navigate(['/integration/quickbooks/login']);
    });
  }

  disconnect() {
    this.disconnectFromQuickbooksGQL.mutate().subscribe(() => {
      this.localNotify.info('Successfully disconnected from Quickbooks');
      this.router.navigate(['/integration/quickbooks/login']);
    }, (err) => {
      this.localNotify.apolloError('Unable to disconnect', err);
    });
  }

  getTaxCodes() {
    this.quickbooksTaxCodesGQL.fetch().subscribe((res) => {
      this.taxCodes = res.data.quickbooksTaxCodes.map((tc) => ({
        ...tc,
        disabled: !tc.SalesTaxRateList?.TaxRateDetail?.length,
      }));
    }, (err) => this.localNotify.apolloError('Unable to retrieve Quickbooks tax codes', err));
  }

  getDefaultTaxCode() {

    if (this.getConfigValuesQueryRef) {
      this.getConfigValuesQueryRef.refetch();
      return;
    }

    this.getConfigValuesQueryRef = this.getConfigValuesGQL.watch({
      keys: ['quickbooks.default-tax-code'],
    });

    this.subs.sink = this.getConfigValuesQueryRef.valueChanges.subscribe((res) => {

      if (res.loading) { return; }

      const [config] = res.data.getConfigValues;

      this.defaultTaxCode = config?.value;
    });
  }

  setDefaultTaxCode() {
    this.setConfigValuesGQL.mutate({
      configs: [{
        key: 'quickbooks.default-tax-code',
        value: this.defaultTaxCode,
      }],
    }).subscribe(() => {
      this.localNotify.success('Default tax code saved');
    }, (err) => {
      this.localNotify.apolloError('Failed to save default tax code', err);
    });
  }
}
