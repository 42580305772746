import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubSink } from 'subsink';

import { BaseUserFragment, ListUsersGQL, ListUsersQueryVariables } from '../../../generated/graphql.generated';

@Component({
  selector: 'app-user-search-multiple',
  templateUrl: './user-search-multiple.component.html',
  styleUrls: ['./user-search-multiple.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: UserSearchMultipleComponent
  }],
})
export class UserSearchMultipleComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() disabled = false;

  users: BaseUserFragment[];
  userIds: string[];
  selectedUsers: BaseUserFragment[] = [];
  total: number;
  query = '';

  subs = new SubSink();

  private listUsersWatchQuery: ReturnType<typeof this.listUsersGQL.watch>;

  constructor(
    private listUsersGQL: ListUsersGQL,
  ) { }

  ngOnInit(): void {
    this.query = '';
    this.users = [];
    this.selectedUsers = [];
    this.listUsersWatchQuery = this.listUsersGQL
      .watch(this.getVariables(), { fetchPolicy: 'cache-and-network' });
    this.subs.sink = this.listUsersWatchQuery.valueChanges.subscribe((res) => {
      // console.log(res);
      if (!res?.data?.usersv2) {
        delete this.users;
        delete this.total;
        return;
      }
      this.users = [...res.data.usersv2.users];
      this.total = res.data.usersv2.total;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.listUsersWatchQuery;
  }

  search(ev: { query: string }) {
    this.query = ev.query;
    if (this.listUsersWatchQuery) {
      this.listUsersWatchQuery
        .setVariables(this.getVariables());
    }
  }

  getVariables(): ListUsersQueryVariables {
    return {
      filter: {
        search: this.query || '',
      },
      limit: 10,
    };

  }

  onAutocompleteChange() {
    this.userIds = this.selectedUsers.map((u) => u.id);
    this.onChange(this.userIds);
  }

  // FORM CONTROL FUNCTIONS
  writeValue(userIds: string[]): void {
    this.userIds = userIds;
    this.subs.sink = this.listUsersGQL.fetch({
      limit: userIds.length,
      filter: {
        userIds,
      }
    }).subscribe((res) => {
      this.selectedUsers = res.data?.usersv2?.users || [];
    });
  }

  onChange = (userIds: string[]) => {};

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  onTouched = () => {};

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
