<div *ngIf="area">
    <div class="header-with-button">
        <h4><i class="pi pi-map large-icon"></i> Area</h4>
        <div>
            <button
                *ngIf="areaActions[0].visible"
                pButton
                pRipple
                label="Actions"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="areaActions"
            ></p-menu>
        </div>
    </div>

    <div class="card">
        <h4>{{area.name}}</h4>
        <p>{{area.description ? area.description : 'No Description for this area.'}}</p>
    </div>

    <div>
        <h5>Territory</h5>
    </div>
    <div class="card">
        <div class="chip-container">
            <p-chip
                [removable]="false"
                *ngFor="let territory of area.areas"
                [style]="{'background-color': area.color}"
            >{{territory.areaCode}}</p-chip>
        </div>
    </div>

    <div *ngIf="dockAddressForArea">
        <h5>Dock Address</h5>
    </div>
    <div class="card" *ngIf="dockAddressForArea">
        {{dockAddressForArea}}
    </div>

    <!-- <div>
        <h5>Rules</h5>
    </div>
    <div class="card">
        <a [routerLink]="[ '/franchise', 'rules', area.id ]">View Rules</a>
    </div> -->
</div>

<!-- <p-dialog header="Delete Area" [modal]="true" [(visible)]="showDeleteDialog" [keepInViewport]="true">
    <p>Are you sure you want to delete {{area.name}}?</p>
    <p-footer>
        <button pButton type="button" class="p-button-warning" icon="pi pi-times" (click)="showDeleteDialog = false" label="Cancel"></button>
        <button pButton type="button" class="p-button-danger" icon="pi pi-trash" (click)="deleteArea()" label="Delete"></button>
    </p-footer>
</p-dialog> -->
