<p-fileUpload
    customUpload="true"
    multiple="multiple"
    maxFileSize="restrictions.maxFileSize"
    [files]="filesToUpload"
    (uploadHandler)="uploadHandler($event)"
    (onSelect)="onFileSelected($event)"
    >

    <ng-template pTemplate="toolbar">
    </ng-template>
    <ng-template
        let-file
        pTemplate="file"
    >
        <div class="p-grid">
            <div class="p-col-7">
                {{file.name | sliceText : 60}}
            </div>
            <div class="p-col-3">
                {{file.size | fileSize}}
            </div>
            <div class="p-col-2">
                <span *ngIf="!file.uploaded">
                    <button pButton class="p-button-danger" icon="pi pi-trash" (click)="removeFile()"></button>
                </span>
                <span *ngIf="file.uploaded && file.progress > 0 && file.progress < 100">
                    <p-progressBar [showValue]="false" [value]="file.progress"></p-progressBar>
                </span>
                <span *ngIf="file.uploaded && file.progress < 0">
                    <i class="pi pi-times-circle error-text"></i>
                </span>
                <span *ngIf="file.uploaded && file.progress === 100">
                    <i class="pi pi-check-circle success-text"></i>
                </span>
            </div>
        </div>
    </ng-template>
</p-fileUpload>