import { Component } from '@angular/core';

import { AppComponent } from '../app.component';
import { text } from '../global.constants';

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent {
  company = text.companyName;
  project = text.projectName;
  constructor(public app: AppComponent) { }
}
