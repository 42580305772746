/* eslint-disable max-len */
import { gql } from 'graphql-tag';

import { ZoneDirection } from './common.gql';
import { Location } from './locations';
import { roleBaseGQL, RoleInfo } from './roles';

// export interface ZoneWithBasicParents extends Zone {
//     parents: Pick<Zone, 'id' | 'name'>[];
// }

export interface AvailableZonesResult {
    availableZones: Zone[];
    currentZone: Zone;
}

export const availableZonesQuery = gql`
query availableZones( $skip: Int, $limit: Int, $search: String ) {
  availableZones(
    skip: $skip
    limit: $limit
    search: $search
  ) {
    id
    name
    type
    contextable
	createdAt
    updatedAt
    deletedAt
    attributes
    description
    avatar
    color
	parent {
		id
		name
	}
  }
  currentZone {
    id
    name
    type
    contextable
	createdAt
    updatedAt
    deletedAt
    attributes
    description
    avatar
    color
    parents {
      id
      name
    }
  }
}
`;

export interface ZonePagination<T = Record<string, unknown>> {
    total: number;
    skipped: number;
    limited: number;
    nodes: (Zone & T)[];
}

// export interface CreateZonesInput {
//   parent: string;
//   zones: ZoneInput[];
// }

// export interface ZoneInput {
//   name: string;
//   type: string;
//   description: string;
//   contextable: boolean;
//   avatar: string;
//   color: string;
//   attributes: any[];
//   maxDepth: number;
//   maxSubZones: number;
// }

// export const createZoneMutation = gql`
//   mutation createZones($parent: String!, $zones: [ZoneInput!]!){
//       createZones(parent: $parent, zones: $zones){
//         id
//       }
//   }
// `;

// export interface CreateZonesOutput {
//   createZones: [{
//     id: string;
//   }];
// }

export interface EditZoneInput {
  ids: string[];
  edit: ZoneInput;
}

export interface EditZoneOutput {
  updateZones: [{
    id: string;
  }];
}

export const editZoneMutation = gql`
  mutation editZone($ids: [String!]!, $edit: ZoneInput!){
    updateZones(ids: $ids, edit: $edit){
      id
    }
  }
`;

export interface DeleteZonesOutput {
  removeZones: {
    zonesRemoved: number;
  };
}

export const deleteZoneMutation = gql`
    mutation deleteZone($ids: [String!]!) {
      removeZones(ids: $ids) {
        zonesRemoved
      }
    }
`;


export interface ListZonesFunctionOutput {
	zones: ListZonesOutput;
}

export interface ListAvailableZonesOutput {
	availableZones: Zone[];
}

export interface ListCurrentZoneOutput {
	currentZone: Zone;
}

export interface ListGetServiceAreaOutput {
	getServiceArea: GetServiceAreaOutput;
}

export interface CreateZonesOutput {
	createZones: Zone[];
}

export interface UpdateZonesOutput {
	updateZones: Zone[];
}

export interface RemoveZonesOutput {
	removeZones: RemoveZonesOutput;
}

export interface AssignObjectsToZonesOutput {
	assignObjectsToZones: AssignObjectsToZonesOutput;
}

export interface ZoneInput {
	name?: string;
	type?: string;
	description?: string;
	contextable?: boolean;
	avatar?: string;
	color?: string;
	attributes: string[];
	maxDepth?: number;
	maxSubZones?: number;
}


export interface ListZonesFilter extends ZoneDirection {
	showDeleted?: boolean;
	indirectZone?: string;
	parent?: string;
	parents?: string[];
	ids?: string[];
	names?: string[];
	types?: string[];
	contextable?: boolean;
	search?: string;
	hasAllAttributes?: string[];
	hasAnyAttributes?: string[];
	objects?: string[];
	objectLabel?: string;
	objectsDeep?: string[];
}

export interface ListZonesObjectFilter {
	ids: string[];
	label?: string;
	skip?: number;
	limit?: number;
}


export interface ListZonesOutput {
	limited: number;
	skipped: number;
	total: number;
	nodes: Zone[];
}


export interface Zone {
	id: string;
	createdAt: number;
	updatedAt: number;
	deletedAt?: number;
	name?: string;
	type?: string;
	description?: string;
	contextable?: boolean;
	avatar?: string;
	color?: string;
	attributes: string[];
	maxDepth?: number;
	maxSubZones?: number;
	//	Parents of this zone, from direct parent to greatest parent	Always available, even if the user has no permissions on parent zones.
	parents: Zone[];
	//	The parent of this zone
	parent?: Zone;
	//	DIRECT children of this zone. IF you need to get all children,	use query.zones
	children: ListZonesOutput;
	//	Return a list of roles which you are able to context into for this zone
	availableRoles: RoleInfo[];
	//	Returns a list of the zone's serviceable areas
	areas?: Location[];
}



export interface RemoveZonesOutput {
	zonesRemoved: number;
}


export interface AssignObjectsToZonesOutput {
	objectsAssigned: number;
	objectsRemoved: number;
}


export interface GetServiceAreaOutput {
	zone: Zone;
	area: Location;
	// Denotes whether the returned zone was resolved by an exact area match
	areaMatch: boolean;
	// The distance between the given area and the resolved zone's HQ, only used if areaMatch is false
	hqDistance?: number;
}


export interface ZonesInput {
	filter?: ListZonesFilter;
	sort?: string;
	skip?: number;
	limit?: number;
	resolveObjects?: boolean;
	deepResolveObjects?: boolean;
	objectFilter?: ListZonesObjectFilter;
}



export interface AvailableZonesInput {
	skip?: number;
	limit?: number;
	search?: string;
}



export interface GetServiceAreaInput {
	areaCode?: string;
}



export interface CreateZonesInput {
	parent?: string;
	zones?: ZoneInput[];
}



export interface UpdateZonesInput {
	ids?: string[];
	edit?: ZoneInput;
}



export interface RemoveZonesInput {
	ids?: string[];
	hardDelete?: boolean;
}



export interface AssignObjectsToZonesInput {
	zoneIds?: string[];
	addObjects?: string[];
	removeObjects?: string[];
	label?: string;
}

export const zonesBaseGql = `
  id		
  createdAt
  updatedAt
  deletedAt
  name
  type
  description
  contextable
  avatar
  color
`;

export const zonesSubGQL = `
  zones {
    nodes {
      ${zonesBaseGql}
    }
  }
`;

export const zoneParentsGQL = `
  parents {
    ${zonesBaseGql}
  }
`;

export const zoneParentGQL = `
  parent {
    ${zonesBaseGql}
  }
`;

export const zoneChildrenGQL = `
  children {
    total
    nodes {
      ${zonesBaseGql}
    }
  }
`;

export const zoneAvaialbleRoles = `
  availableRoles {
    ${roleBaseGQL}
  }
`;

export const zoneAreasGQL = `
  areas {
    id
    name
    areaCode
    attributes
    subLocations {
      id
      name
      areaCode
      attributes
    }
  }
`;

export const zoneMaxInfo = `
  maxDepth
  maxSubZones
`;



export interface GenerateZonesQueryInput {
  parents?: boolean;
  parent?: boolean;
  children?: boolean;
  availableRoles?: boolean;
  areas?: boolean;
  attributes?: boolean;
  maxInfo?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function generateZonesQuery(input: GenerateZonesQueryInput) {
  const zonesQuery = gql`
	query zones($filter: ListZonesFilter, $sort: String, $skip: Int, $limit: Int, $resolveObjects: Boolean, $deepResolveObjects: Boolean, $objectFilter: ListZonesObjectFilter, ){
		zones(filter: $filter, sort: $sort, skip: $skip, limit: $limit, resolveObjects: $resolveObjects, deepResolveObjects: $deepResolveObjects, objectFilter: $objectFilter, ){
				limited
				skipped
				total
				nodes {
          ${zonesBaseGql}
          ${input.parent ? zoneParentGQL : ``}
          ${input.parents ? zoneParentsGQL : ``}
          ${input.children ? zoneChildrenGQL : ``}
          ${input.maxInfo ? zoneMaxInfo : ``}
          ${input.attributes ? `attributes` : ``}
          ${input.areas ? zoneAreasGQL : ``}
          ${input.availableRoles ? zoneAvaialbleRoles : ``}
        }
	  }		
  }
  `;

  return zonesQuery;
}


// export const zonesQuery = gql`
// 	query zones($filter: ListZonesFilter, $sort: String, $skip: Int, $limit: Int, $resolveObjects: Boolean, $deepResolveObjects: Boolean, $objectFilter: ListZonesObjectFilter, ){
// 		zones(filter: $filter, sort: $sort, skip: $skip, limit: $limit, resolveObjects: $resolveObjects, deepResolveObjects: $deepResolveObjects, objectFilter: $objectFilter, ){
// 				limited
// 				skipped
// 				total
// 				nodes { MANUAL INPUT REQUIRED }
// 	  }
//   }
// `;



// export const availableZonesQuery = gql`
// 	query availableZones($skip: Int, $limit: Int, $search: String, ){
// 		availableZones(skip: $skip, limit: $limit, search: $search, ){
// 				id{ MANUAL INPUT REQUIRED }
//         createdAt
// 				updatedAt
// 				deletedAt
// 				name
// 				type
// 				description
// 				contextable
// 				avatar
// 				color
// 				attributes
// 				maxDepth
// 				maxSubZones
// 				parents{ MANUAL INPUT REQUIRED }
//         parent{ MANUAL INPUT REQUIRED }
//         children{ MANUAL INPUT REQUIRED }
//         availableRoles{ MANUAL INPUT REQUIRED }
//         areas{ MANUAL INPUT REQUIRED }
// 	}		}
// `;


// export const currentZoneQuery = gql`
// 	query currentZone(){
// 		currentZone(){
// 			I			N			V			A			L			I			D
// 	}		}
// `;


export const getServiceAreaQuery = gql`
	query getServiceArea($areaCode: String!, ){
		getServiceArea(areaCode: $areaCode, ){
      zone {
        id
		parent {
			id
		}
      }
      area {
        id
        name
        areaCode
      }
      areaMatch
      hqDistance
	  }		
  }
`;


export const createZonesMutation = gql`
	mutation createZones($parent: String!, $zones: [ ZoneInput! ]!, ){
		createZones(parent: $parent, zones: $zones, ){
				id		
        createdAt
				updatedAt
				deletedAt
				name
				type
				description
				contextable
				avatar
				color
	}		}
`;


export const updateZonesMutation = gql`
	mutation updateZones($ids: [ String! ]!, $edit: ZoneInput!, ){
		updateZones(ids: $ids, edit: $edit, ){
				id			
        createdAt
				updatedAt
				deletedAt
				name
				type
				description
				contextable
				avatar
				color
	}		}
`;


export const removeZonesMutation = gql`
	mutation removeZones($ids: [ String! ]!, $hardDelete: Boolean, ){
		removeZones(ids: $ids, hardDelete: $hardDelete, ){
				zonesRemoved

	}		}
`;


export const assignObjectsToZonesMutation = gql`
	mutation assignObjectsToZones($zoneIds: [ String! ]!, $addObjects: [ String! ]!, $removeObjects: [ String! ]!, $label: String, ){
		assignObjectsToZones(zoneIds: $zoneIds, addObjects: $addObjects, removeObjects: $removeObjects, label: $label, ){
				objectsAssigned
				objectsRemoved

	}		}
`;


