import { Pipe, PipeTransform } from '@angular/core';
import { Job } from '@karve.it/interfaces/jobs';

import { BaseLocationFragment, FullJobFragment, JobLocation } from '../../generated/graphql.generated';
import { getJobLocation } from '../jobs/jobs.util';

import { AreaCodePipe } from './area-code.pipe';

@Pipe({
  name: 'jobLocation'
})
export class JobLocationPipe implements PipeTransform {

  constructor(private areaCodePipe: AreaCodePipe){}

  transform(job: FullJobFragment, locationType: string, toString = false): string | BaseLocationFragment {
    if (!job?.locations) { return toString ? 'No Location Set' : undefined; };
    const location = getJobLocation(job, locationType);

    if (!location) {
      return toString ? `No ${locationType} location` : undefined;
    }

    if (toString){
      return `${location.addressLineTwo ? location.addressLineTwo + ' - ' : ''}${location.addressLineOne}`;
    }

    return location;
  }

}
