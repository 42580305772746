<div *ngIf="!jobsQH.error; else errorTemp">
    <div class="header-with-button p-ai-start">
        <div class="p-d-flex">
            <div class="p-pr-1">
                <h4><i [class]="jobIcon"></i> Job</h4>
                <h5 class="job-stage">{{ job?.stage | titlecase }}</h5>
            </div>
            <app-object-state
                [object]="job"
                objectType="job"
                [colorful]="true"
                [loading]="jobsQH.loading"
            ></app-object-state>
        </div>
        <app-job-actions
            *ngIf="job && !jobsQH.loading"
            [job]="job"
            data-cy="actions-button"
        ></app-job-actions>
    </div>
    <p-message
        *ngIf="sharedFromAnotherZone"
        severity="warn"
        [text]="sharedMessage"
    ></p-message>

    <div class="tag-container" *ngIf="!jobsQH.loading; else loadingBar">
        <p-tag
            *ngFor="let tag of job?.tags"
            [style]="{ 'background-color': tag.color }"
            [colorForBackground]="{
                elementSelector: 'span',
                backgroundColor: tag.color
            }"
        >
            {{ tag | formatTag }}
        </p-tag>
    </div>

    <div class="card">
        <p *ngIf="job && !jobsQH.loading; else loadingBar">
            Customer:
            <a (click)="openCustomer()" class="clickable">
                {{ job.users | jobCustomer }}
            </a>
        </p>
        <p *ngIf="job && !jobsQH.loading; else loadingBar">
            Job Code:
            <a [routerLink]="['/job', job.id]">
                {{ job.code }}
            </a>
        </p>
        <p *ngIf="job && !jobsQH.loading; else loadingBar">
            Stage:
            <a [routerLink]="['/job', job?.id]">
                {{ job.stage | titlecase }}
            </a>
        </p>
        <p pTooltip="Zone" *ngIf="job?.zone && !jobsQH.loading; else loadingBar">
            <span>{{ job.zone.type | titlecase }} / Zone: </span>
            <a
                *ngIf="job.zone"
                [class.clickable]="job.zone.type === 'area'"
                (click)="openZone()"
            >
                {{ job.zone.name }}
            </a>
        </p>
        <b *ngIf="job?.sharedZones?.length">Shared with Zones</b>
        <p *ngFor="let zone of job?.sharedZones">{{ zone }}</p>
        <div *ngIf="job?.metadata | metadata : 'yemboEmployeeLink'">
            <a
                target="_blank"
                [href]="job?.metadata | metadata : 'yemboEmployeeLink'"
            >
                <i class="pi pi-external-link"></i>
                View Move In Yembo
            </a>
        </div>
    </div>

    <h5>Events</h5>
    <div class="card events" *ngIf="!jobsQH.loading; else loadingBar">
        <ng-container *ngIf="!jobsQH.loading && job?.events?.length">
            <div
                *ngFor="let event of job.events"
                class="ce-event clickable"
                (click)="detailsHelper.open('calendar-event', event)"
            >
                <div>
                    <span class="ce-title">{{ event.title }}</span>
                    <span class="ce-status">{{
                        event.status | titlecase
                    }}</span>
                </div>
                <div
                    pTooltip="With dock travel times: {{
                        event | eventDuration : true : false
                    }}"
                    tooltipPosition="left"
                    class="ce-time"
                >
                    <span *ngIf="event?.start">
                        {{ event | eventDuration : false : true : false }}
                    </span>
                    <span>
                        {{ event | eventDuration : false : false : false }}
                    </span>
                </div>
            </div>
        </ng-container>

        <p *ngIf="!jobsQH.loading && !job?.events?.length" class="no-events">
            No Events
        </p>
    </div>

    <h5>Financials</h5>
    <div class="card">
        <app-financials-table
            [loading]="jobsQH.loading"
            [item]="job"
        ></app-financials-table>
    </div>

    <h5>Assets</h5>
    <div class="card">
        <div *ngIf="!jobsQH.loading; else loadingBar">
            <p *ngIf="!job.events || job.events.length === 0">
                No Assets Assigned
            </p>
            <div *ngIf="job.events && job.events.length > 0">
                <div *ngFor="let asset of job.events[0].assets">
                    <p>{{ asset.name }} <p-tag [value]="asset.type"></p-tag></p>
                </div>
            </div>
        </div>
    </div>

    <h5>Locations</h5>
    <div class="card">
        <app-job-locations [job]="job" [showAll]="true"></app-job-locations>
    </div>

    <!-- <h5>Notes</h5> -->
    <app-comments
        #comments
        [objectId]="this.job?.id"
        [objectType]="'Job'"
        [nameForComment]="'Note'"
        [showAddButton]="(permissions$ | async)?.addComments === true"
        [commentsFilter]="{ hasNoAttributes: ['inventory'] }"
        [smallContainer]="true"
        [collapsed]="true"
        [readonly]="
            job?.archivedAt || (permissions$ | async)?.addComments === false
        "
        [readonlyTooltip]="
            job?.archivedAt
                ? 'Cannot modify comments of an archived job'
                : 'You do not have permission to modify comments'
        "
    ></app-comments>
    <h5>Charges</h5>
    <div class="card">
        <div *ngIf="!jobsQH.loading; else loadingBar">
            <p *ngFor="let c of job.charges | chargeDamage : true">
                {{ c | charge }}
            </p>
            <p *ngIf="!(job?.charges | chargeDamage : true)?.length">
                No Charges
            </p>
        </div>
    </div>

    <h5>Invoices</h5>
    <div class="card">
        <div *ngIf="!jobsQH.loading; else loadingBar">
            <span
                *ngFor="let invoice of job?.invoices"
                class="freya-link-button"
                (click)="openInvoice(invoice.id)"
            >
                {{ invoice | invoiceName: (job?.users | jobCustomer) : job?.code }}
            </span>
            <span *ngIf="!job?.invoices?.length">No invoices</span>
        </div>
    </div>

    <h5>Damages</h5>
    <div class="card">
        <div *ngIf="!jobsQH.loading; else loadingBar">
            <p *ngFor="let c of job.charges | chargeDamage : false">
                {{ c | charge }}
            </p>
            <p *ngIf="!(job?.charges | chargeDamage : false)?.length">
                No Damages
            </p>
        </div>
    </div>

    <h5>Transactions</h5>
    <div class="card">
        <div *ngIf="!jobsQH.loading; else loadingBar">
            <p *ngFor="let t of job?.transactions">
                <p-tag
                    [severity]="
                        t.deletedAt
                            ? 'danger'
                            : (t.stage
                              | severity : 'paid' : 'pending' : 'cancelled')
                    "
                >
                    {{ t.deletedAt ? "Deleted" : (t.stage | titlecase) }}
                </p-tag>
                <a
                    (click)="detailsHelper.open('transaction', { id: t.id })"
                    class="clickable"
                >
                    {{ t.type | titlecase }}
                    {{ t.amount / 100 | currency : t.currency }}
                </a>
            </p>
            <p *ngIf="!job?.transactions?.length">No Transactions</p>
        </div>
    </div>

    <app-estimating-inventory
        *ngIf="job && !jobsQH.loading; else loadingBar"
        [job]="job"
        [readonly]="true"
    ></app-estimating-inventory>

    <br />
    <br />

    <app-mutate-job [job]="job" #mutate> </app-mutate-job>

    <!-- <app-delete-object #delete
            [name]="job.stage"
            type="Job"
            (deleted)="deleteJob()"
            >
            </app-delete-object> -->

    <!-- <app-download-job-invoice
                #downloadInvoice
                (invoiceFound)="enableDownloadInvoice()"
                [jobId]="job?.id"
                ></app-download-job-invoice> -->
</div>

<p-dialog
    [(visible)]="showCancelDialog"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [breakpoints]="{ '992px': '62vw', '510px': '93vw' }"
>
    <ng-template pTemplate="header"> Cancel Job? </ng-template>
    <p class="error-text">
        Cancelling this Job will also cancel any associated calendar events. Are
        you sure you want to do this?
    </p>
    <ng-template pTemplate="footer">
        <button
            pButton
            label="No, Exit Window"
            icon="pi pi-times"
            (click)="showCancelDialog = false"
        ></button>
        <button
            class="p-button-danger"
            pButton
            label="Yes, Cancel Job"
            icon="pi pi-trash"
            (click)="cancelJob()"
        ></button>
    </ng-template>
</p-dialog>

<ng-template #errorTemp>
    <div class="header-with-button">
        <h4>
            <i class="pi pi-times-circle large-icon"></i> Failed to load job
        </h4>
        <span></span>
    </div>
    <div class="card">
        <p-messages severity="error">
            <ng-template pTemplate>
                <div>
                    <p>{{ jobsQH.error | apolloErrorMessage }}</p>
                </div>
            </ng-template>
        </p-messages>
    </div>
</ng-template>

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton width="100%" height="16px"></p-skeleton>
    </div>
</ng-template>
