import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { CalendarEventsInput, ListCalendarEventsOutput, RecurringCalendarEventsInput, ListRecurringCalendarEventsOutput, recurringCalendarEventsQuery, CreateCalendarEventInput, CreateCalendarEventOutput, createCalendarEventMutation, EditCalendarEventInput, EditCalendarEventOutput, editCalendarEventMutation, EditRecurringCalendarEventInput, EditRecurringCalendarEventOutput, editRecurringCalendarEventMutation, RemoveCalendarEventInput, RemoveCalendarEventOutput, removeCalendarEventMutation, RemoveRecurringEventsInput, RemoveRecurringEventsOutput, removeRecurringEventsMutation, GenerateCalendarEventsQueryInput, generateCalendarEventsQuery,  } from '@karve.it/interfaces/calendarEvents';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class CalendarEventService {

    constructor(private apollo: PlusApollo){}

    listCalendarEvents(input: CalendarEventsInput, queryInput: GenerateCalendarEventsQueryInput, opts?: KarveRequestOptions){
      return this.apollo.query<ListCalendarEventsOutput>({
        query: generateCalendarEventsQuery(queryInput),
        variables: {
          ...input
        },
        ...parseRequestOptions(opts),
      });
    }

    watchCalendarEvents(input: CalendarEventsInput, queryInput: GenerateCalendarEventsQueryInput, opts?: KarveWatchRequestOptions){
      return this.apollo.watchQuery<ListCalendarEventsOutput>({
        query: generateCalendarEventsQuery(queryInput),
        variables: {
          ...input
        },
        ...parseRequestOptions(opts),
      });
    }

    listRecurringCalendarEvents(input: RecurringCalendarEventsInput){
      return this.apollo.query<ListRecurringCalendarEventsOutput>({
        query: recurringCalendarEventsQuery,
        variables: {
          ...input
        }
      });
    }

    watchRecurringCalendarEvents(input: RecurringCalendarEventsInput){
      return this.apollo.watchQuery<ListRecurringCalendarEventsOutput>({
        query: recurringCalendarEventsQuery,
        variables: {
          ...input
        }
      });
    }

    createCalendarEvent(input: CreateCalendarEventInput){
      return this.apollo.mutate<CreateCalendarEventOutput>({
        mutation: createCalendarEventMutation,
        variables: {
          ...input
        }
      });
    }

    editCalendarEvent(input: EditCalendarEventInput){
      return this.apollo.mutate<EditCalendarEventOutput>({
        mutation: editCalendarEventMutation,
        variables: {
          ...input
        }
      });
    }

    editRecurringCalendarEvent(input: EditRecurringCalendarEventInput){
      return this.apollo.mutate<EditRecurringCalendarEventOutput>({
        mutation: editRecurringCalendarEventMutation,
        variables: {
          ...input
        }
      });
    }

    removeCalendarEvent(input: RemoveCalendarEventInput){
      return this.apollo.mutate<RemoveCalendarEventOutput>({
        mutation: removeCalendarEventMutation,
        variables: {
          ...input
        }
      });
    }

    removeRecurringEvents(input: RemoveRecurringEventsInput){
      return this.apollo.mutate<RemoveRecurringEventsOutput>({
        mutation: removeRecurringEventsMutation,
        variables: {
          ...input
        }
      });
    }


}
