<p-breadcrumb
  [home]="home"
  [model]="breadcrumb"
  styleClass="p-mb-3"
  >
</p-breadcrumb>

<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel header="Static">
        <div class="p-px-3 p-pb-3">
            <span class="p-input-icon-right">
                <i class="pi pi-spin pi-spinner" *ngIf="reportTypesQH.loading"></i>
                <i class="pi pi-times clear-search" *ngIf="!reportTypesQH.loading && reportTypesQH.search"
                    pTooltip="Clear Search"
                    (click)="clearSearch()"
                    ></i>
                <!-- <label for="float-input">Search</label> -->
                <input
                type="text"
                #searchInput
                pInputText
                [(ngModel)]="reportTypesQH.search"
                (ngModelChange)="filterUpdated.next()"
                placeholder="Search..."
                data-cy="search-text-field"
                >
            </span>
        </div>
        <p-table #table
            [value]="reportTypes"
            [paginator]="true"
            (onLazyLoad)="onLazyLoad($event)"
            [lazyLoadOnInit]="true"
            [lazy]="true"
            [totalRecords]="reportTypesQH.total"
            [(rows)]="reportTypesQH.limit"
            [rowsPerPageOptions]="pagination.rowPerPageOptions"
            [loading]="reportTypesQH.loading"
            [showCurrentPageReport]="!reportTypesQH.loading"
            breakpoint="620px"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Zone</th>
                    <!-- <th>Created</th> -->
                    <!-- <th>Last Run</th> -->
                    <th></th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-rt
                >
                <tr
                    class="selectable-row"
                    (click)="select(rt)"
                    >
                    <td>
                        <span class="p-column-title">Name:</span>
                        {{rt.name}}
                    </td>
                <td>
                    <span class="p-column-title">Zone</span>
                    <app-zone-chip
                        *ngFor="let zone of rt.zones?.nodes"
                        [zone]="zone"
                        >
                    </app-zone-chip>
                </td>
                <!-- <td>{{rt.id}}</td> -->
                <td class="open-external">
                    <span></span>
                    <span>
                        <a
                            *hasPermission="['reports.create']"
                            pButton
                            type="button"
                            routerLink="/reports/type/{{ rt.id }}"
                            [queryParams]="{ run: true }"
                            icon="pi pi-directions"
                            pTooltip="Run {{ rt.name }}"
                            data-cy='run-report-type-button'
                            ></a>
                        <a
                            pButton
                            type="button"
                            routerLink="/reports/type/{{ rt.id }}"
                            icon="pi pi-external-link"
                            pTooltip="View {{ rt.name }}"
                            data-cy="view-report-type-button"
                            ></a>
                    </span>
                </td>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="emptymessage"
                let-car
                let-columns="columns"
                >
                <tr>
                    <td [attr.colspan]="'6'">
                        {{pagination.emptyMessage}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                </tr>
            </ng-template>
        </p-table>
    </p-tabPanel>
    <p-tabPanel header="Dynamic">
        <app-dynamic-reports></app-dynamic-reports>
    </p-tabPanel>
</p-tabView>

<p-dialog #createReportTypeDialog
    header="Report Type Creation Coming Soon"
    [(visible)]="showCreateReportTypeDialog"
    [modal]="true"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
>

    <p>
        Report type creation is coming soon. To request a new report please <a href="mailto:support@karveit.ca">email Karve IT</a>
        or
        <a class="clickable" (click)="openFeedback()">submit a request</a>

    </p>

    <p>To run an existing report, open that report type then click "Run Report" in the top right.</p>
</p-dialog>
