import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { SubSink } from 'subsink';

import { FullInvoiceFragment, FullTransactionFragment, TransactionStage } from '../../../generated/graphql.generated';
import { CalendarEvent } from '../../interfaces/calendarEvents';
import { getInvoiceStatus, InvoiceStatus } from '../../invoices/invoices.utils';
import { FreyaHelperService } from '../../services/freya-helper.service';

export interface StatefulObject {
   deletedAt: number;
   archivedAt: number;
   closedAt: number;
   closedReason: string;
}

export type State =
|  'deleted'
|  'archived'
|  'open'
|  'closed'
|  TransactionStage
|  CalendarEvent['status']
|  InvoiceStatus;

@Component({
  selector: 'app-object-state',
  templateUrl: './object-state.component.html',
  styleUrls: ['./object-state.component.scss']
})
export class ObjectStateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() object: Partial<StatefulObject>;

  @Input() objectType: 'invoice' | 'transaction' | 'event' | 'job';

  @Input() colorful: boolean;

  @Input() loading: boolean;

  @Input() closureReasonPlacement: 'label' | 'tootlip' = 'tootlip';

  subs = new SubSink();

  state: State;

  label: string;

  tooltip: string;

  constructor(
    private titleCase: TitleCasePipe,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.freyaHelper.closureReasons.subscribe((reasons) => {
      if (!reasons?.length) { return; }

      this.setState();
    });
  }

  ngOnChanges(): void {
    this.setState();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  reset() {
    this.state = undefined;
    this.tooltip = undefined;
    this.label = undefined;
  }

  setState() {
    this.reset();

    if (this.object?.deletedAt) {
      this.state = 'deleted';
      this.setStateBasedProps();
      return;
    }

    if (this.object?.archivedAt) {
      this.state = 'archived';
      this.setStateBasedProps();
      return;
    }

    if (this.objectType === 'invoice') {
      this.state = getInvoiceStatus(this.object as FullInvoiceFragment);
      this.setStateBasedProps();
      return;
    }

    if (this.objectType === 'transaction') {
      this.state = (this.object as FullTransactionFragment).stage;
      this.setStateBasedProps();
      return;
    }

    if (this.objectType === 'event') {
      this.state = (this.object as CalendarEvent).status;
      this.setStateBasedProps();
    }

    if (this.object?.closedAt) {
      this.state = 'closed';
      this.setStateBasedProps();
      return;
    }

    if (this.objectType === 'job' && !this.object?.closedAt) {
      this.state = 'open';
      this.setStateBasedProps();
    }
  }

  setStateBasedProps() {
    this.setTooltip();
    this.setLabel();
  }

  setTooltip() {
    if (!this.state) { return; }

    if (this.objectType === 'job' && this.state === 'closed' && this.closureReasonPlacement === 'tootlip') {

      const closedReason = this.getClosedReasonTitle();

      if (closedReason) {
        this.tooltip = `Closure reason: ${closedReason}`;
        return;
      }
    };

    if (this.state === 'archived') {
      this.tooltip = `This ${this.objectType ? this.objectType : 'object' } has been archived and cannot be modified`;
    }

  }

  setLabel() {
    if (this.objectType === 'job' && this.state === 'closed' && this.closureReasonPlacement === 'label') {

      const closedReason = this.getClosedReasonTitle();

      if (closedReason) {
        this.label = `Closed - ${closedReason}`;
        return;
      }
    }

    this.label = this.titleCase.transform(this.state);
  }

  getClosedReasonTitle() {
    if (!this.object?.closedReason || !this.object.closedReason.length) { return; }
    const closedReasonId = this.object.closedReason;
    const closedReason = this.freyaHelper.closureReasons.value.find((c) => c.id === closedReasonId);
    return this.titleCase.transform(closedReason?.title);
  }
}
