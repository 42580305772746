<div class="flex card">
    <h4 class="no-margin" style="width: 30%">Inventory Items</h4>
    <button
        pButton
        label="Save"
        icon="pi pi-save"
        (click)="saveObject()"
        class="p-button-success p-m-1"
        *ngIf="!readonly"
    ></button>
    <button
        pButton
        label="Import"
        icon="pi pi-download"
        (click)="openImportObject()"
        class="p-m-1"
        pTooltip="Import a configuration from another zone"
        *ngIf="!readonly"
    ></button>
    <button
        pButton
        label="Export"
        icon="pi pi-upload"
        (click)="exportObject()"
        class="p-m-1"
        pTooltip="Copy your configuration so it can be imported into another zone"
    ></button>
</div>

<p-table [value]="data" [columns]="columns" [paginator]="true" [rows]="50">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns" [style.width]="col.width || '50px'">
                <ng-container [ngSwitch]="col.type">
					<!--
						There are too many of "pInputText", so we end up
						with a maximum call stack size exceeded error
						because it wants to call detect changes for EVERY input
						not in a form.

						So, we can't use pInputText ):
					-->
                    <input
                        class="full-width text-input"
                        type="text"
                        *ngSwitchCase="'string'"
                        [(ngModel)]="rowData[col.property]"
                        [disabled]="readonly"
                    />
                    <p-inputNumber
                        class="full-width-number-input"
                        *ngSwitchCase="'number'"
                        [(ngModel)]="rowData[col.property]"
                        type="number"
                        [disabled]="readonly"
                    ></p-inputNumber>
                    <input
                        *ngSwitchCase="'date'"
                        [(ngModel)]="rowData[col.property]"
                        type="date"
                        [disabled]="readonly"
                    />
                    <p-checkbox
                        *ngSwitchCase="'boolean'"
                        name="groupname"
                        value="val1"
                        [(ngModel)]="rowData[col.property]"
                        [disabled]="readonly"
                    ></p-checkbox>
                    <p-chips
                        *ngSwitchCase="'array'"
                        [(ngModel)]="rowData[col.property]"
                        [disabled]="readonly"
                    ></p-chips>
                    <!-- Add more cases for other input types as needed -->
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="showDialog">
    <ng-template pTemplate="header"> Import </ng-template>
    <textarea rows="10" cols="50" [(ngModel)]="dialogText"> </textarea>

    <p class="error-text">This will overwrite the existing value.</p>

    <ng-template pTemplate="footer">
        <button
            pButton
            label="Import"
            icon="pi pi-download"
            (click)="importObject()"
        ></button>
    </ng-template>
</p-dialog>
