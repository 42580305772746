// user-search.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import MiniSearch, { SearchResult } from 'minisearch';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { DispatchFeature } from './store/dispatch.reducer';

export type PotentialCrewType = ReturnType<
    typeof DispatchFeature.selectPotentialCrew
>;
export type CrewMemberType = PotentialCrewType extends (infer U)[] ? U : never;
export type UserMiniSearchStoreFields = keyof CrewMemberType;

@Injectable({
    providedIn: 'root',
})
export class DispatchUserSearchService implements OnDestroy {
    private subs = new SubSink();
    private userMiniSearch = new MiniSearch({
        fields: ['name'] as UserMiniSearchStoreFields[],
        storeFields: [
            'name',
            'eventsCount',
            'revenue',
            'role',
            'user',
        ] as UserMiniSearchStoreFields[],
        searchOptions: {
            prefix: true,
            fuzzy: true,
        },
    });

    constructor(private store: Store) {
        this.initializeMiniSearch();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private initializeMiniSearch() {
        const potentialCrew = this.store.select(
            DispatchFeature.selectPotentialCrew,
        );
        this.subs.sink = potentialCrew.subscribe((users: PotentialCrewType) => {
            if (users.length === 0) {
                return;
            }
            this.userMiniSearch.removeAll();
            this.userMiniSearch.addAll(
                users.map((user) => ({ ...user, id: user.user.id })),
            );
        });
    }

    public searchUsers(
        searchTerm$: Observable<string>,
        potentialCrew$: Observable<PotentialCrewType>,
    ): Observable<PotentialCrewType | SearchResult[]> {
        return searchTerm$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((searchTerm) => {
                if (!searchTerm.trim()) {
                    return potentialCrew$;
                }
                return of(this.userMiniSearch.search(searchTerm));
            }),
        );
    }
}
