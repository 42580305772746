import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freyaCurrency'
})
export class FreyaCurrencyPipe implements PipeTransform {


  constructor(
    private currencyPipe: CurrencyPipe
  ) {}

  transform(
    value: number,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): unknown {

    value /= 100;

    const result = this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);

    return result;
  }

}
