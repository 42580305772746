import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfigService } from '@karve.it/core';
import { LocationService } from '@karve.it/features';
import { LocationCreateBase } from '@karve.it/interfaces/locations';

import { concatMap, map } from 'rxjs/operators';
import { googleLocationOptions } from 'src/app/estimates/estimate.interfaces';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { Address, GoogleHelperService } from 'src/app/services/google-helper.service';
import { SubSink } from 'subsink';

import { regex } from '../../global.constants';
import { SetConfigValuesInput } from '../../interfaces/config';
import { safeParseJSON } from '../../js';

import { BrandingService } from '../../services/branding.service';
import { RecentItemsService } from '../../services/recent-items.service';


import { defaultContactDetails, defaultFranchiseInfo, defaultLegalCard, defaultSystemDetails } from '../franchise.interfaces';

@Component({
  selector: 'app-business-general',
  templateUrl: './business-general.component.html',
  styleUrls: ['./business-general.component.scss']
})
export class BusinessGeneralComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('jobWatchers') jobWatchersRef: ElementRef<any>;

  subs = new SubSink();

  locationForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(undefined, {}),
    location: new UntypedFormControl(undefined, {}),
  });
  locationToCreate: LocationCreateBase;

  franchiseInfo: any = { ...defaultFranchiseInfo };

  systemDetails: any = { ...defaultSystemDetails };

  legalCard: any = { ...defaultLegalCard };

  contactDetails: any = { ...defaultContactDetails };

  configsLoaded = false;

  modifiedConfigs: string[] = [];

  options = googleLocationOptions;

  notificationsForm = new UntypedFormGroup({
    leadNotificationRecipients: new UntypedFormControl({ value: [], disabled: true }),
    jobWatchers: new UntypedFormControl({ value: [], disabled: true }),
    notifyWatchersDocumentCompleted: new UntypedFormControl({value: false, disabled: true}),
  });

  constructor(
    private configService: ConfigService,
    private freyaHelper: FreyaHelperService,
    public googleHelper: GoogleHelperService,
    private locationService: LocationService,
    private localNotify: FreyaNotificationsService,
    private brandingSvc: BrandingService,
    private recentItems: RecentItemsService,
    ) { }

  ngOnInit(): void {
    this.retrieveFranchiseConfigs();

    this.subs.sink = this.brandingSvc.currentZone().subscribe((zone) => {
      this.getHQLocation();

      if (zone.id) {
        this.recentItems.addToRecentItems(zone, 'franchise');
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    const { hash } = location;
    if (hash !== '#job-watchers') { return; }
    if (!this.jobWatchersRef?.nativeElement) { return; }
    this.jobWatchersRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  async getHQLocation(networkOnly?: boolean){
    const hq = await this.freyaHelper.getDockLocation(networkOnly);
    if(!hq) { return; }
    this.locationForm.setValue({
      id: hq.id,
      location: hq.addressLineOne
    });
  }

  retrieveFranchiseConfigs() {
    this.subs.sink = this.configService.watchConfigValues(
      {
        keys: [
          'franchise-info.*',
          'system-details.*',
          'legal.*',
          'additional-contact-details.*',
          'jobs.watchers',
          'intake.notificationRecipients',
          'jobs.notifyWatchersDocumentCompleted'
        ]
      }
    ).valueChanges.subscribe((res) => {
      // Reset Values
      this.franchiseInfo = { ...defaultFranchiseInfo };
      this.systemDetails = { ...defaultSystemDetails };
      this.legalCard = { ...defaultLegalCard };
      this.contactDetails = { ...defaultContactDetails};
      this.notificationsForm.controls.jobWatchers.setValue([]);
      this.notificationsForm.controls.notifyWatchersDocumentCompleted.setValue(false);

      for (const config of res.data.getConfigValues) {

        if (config.key === 'jobs.watchers' && config.value) {
          this.notificationsForm.controls.jobWatchers.setValue(config.value.split(';'));
        }

        if (config.key === 'intake.notificationRecipients' && config.value) {
          this.notificationsForm.controls.leadNotificationRecipients.setValue(safeParseJSON(config.value, []));
        }

        if (config.key === 'jobs.notifyWatchersDocumentCompleted' && config.value) {
          this.notificationsForm.controls.notifyWatchersDocumentCompleted.setValue(JSON.parse(config.value));
        }

        const [namespace, key] = config.key.split('.');

        switch (namespace) {
          case 'franchise-info':
            this.franchiseInfo[key] = config.value;
            break;
          case 'system-details':
            this.systemDetails[key] = config.value;
            break;
          case 'legal':
            this.legalCard[key] = config.value;
            break;
          case 'additional-contact-details':
            this.contactDetails[key] = config.value;
            break;
        }

      }
      this.configsLoaded = true;
    });
  }

  saveInfo(namespace: string, data) {
    if (namespace === 'franchise-info'){
      this.saveHQLocation();
    }

    const val = this.notificationsForm.value;

    const values = Object.keys(data)
    .filter((ok) => ok !== 'editMode' && data[ok]) // Only get values from the object being edited
    .filter((ok) => this.modifiedConfigs.find((mc) => mc === ok)) // Only get values that have been edited
    .map((k) => (
      {
        key: `${namespace}.${k}`,
        value: data[k],
      }
    ));

    if (
      namespace === 'additional-contact-details'
      && this.notificationsForm.controls.jobWatchers.dirty
    ) {
      values.push({
        key: 'jobs.watchers',
        value: val.jobWatchers.join(';'),
      });
    }

    if (
      namespace === 'additional-contact-details'
      && this.notificationsForm.controls.leadNotificationRecipients.dirty
    ) {
      values.push({
        key: 'intake.notificationRecipients',
        value: JSON.stringify(val.leadNotificationRecipients),
      });
    }

    if (
      namespace === 'additional-contact-details'
      && this.notificationsForm.controls.notifyWatchersDocumentCompleted.touched
    ) {
      values.push({
        key: 'jobs.notifyWatchersDocumentCompleted',
        value: JSON.stringify(this.notificationsForm.controls.notifyWatchersDocumentCompleted.value),
      });
    }

    data.editMode = false;

    this.onContactDetailsEditModeChange();

    if (!values.length) { return; }

    this.subs.sink = this.configService.setConfigValues({ configs: values }).subscribe((res) => {
      this.modifiedConfigs = [];
    });
  }

  async saveHQLocation(){
    if (this.locationForm.dirty && !this.locationToCreate){
      this.getHQLocation();
      this.localNotify.warning('HQ Location Reset', 'Use the autocomplete to set the location');
    }
    if (!this.locationToCreate) { return; } // Nothing to save

    this.locationToCreate.attributes = ['ZoneHQ'];

    if (this.locationForm.value.id) {
      await this.locationService.EditLocations({ids: [this.locationForm.value.id], edit: {attributes: ['old-zone-hq']}}).toPromise();
    }

    // Get and Set the Coordinates for this location
    // const geocodingResult = await this.googleHelper.geocodeAddress({ formattedAddress: this.locationToCreate.addressLineOne});
    // this.locationToCreate.coordinates = this.googleHelper.getCoordinatesFromGeometry(geocodingResult.geometry);

    this.locationService.CreateLocations({input: {locations: [this.locationToCreate]}})
      .pipe(
        map((res) => res.data.createLocations.locations[0].id as string),
        map((newLocationId) => ({  configs: [{key: 'franchise-info.dockLocationId', value: newLocationId }] } as SetConfigValuesInput)),
        concatMap((input) => this.configService.setConfigValues(input))
      ).subscribe(() => this.getHQLocation(true));
  }

  markAsModified(key: string){
    this.modifiedConfigs.push(key);
  }

  identify(index, item) {
    return item.key;
  }

  checkJobWatchers() {
    const jobWatchers = (this.notificationsForm.value.jobWatchers || []).filter((watcher) => {
      const isEmail = watcher.match(regex.email);
      const isPhone = watcher.match(regex.phone);
      if (isEmail || isPhone) {
        return true;
      }

      this.localNotify.warning(`Could not add job watcher`, `${ watcher } is not a valid email or phone number.`);

      return false;

    });

    this.notificationsForm.controls.jobWatchers.setValue(jobWatchers);
  }

  handleAddressChange(address: Address){
    if(!this.googleHelper.isValidGooglePlacesAddress(address)){
      this.locationForm.controls.location.setErrors({notvalid: true});
    }

    this.locationToCreate = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);

    this.locationForm.controls.location.setErrors(null);
  }

  onContactDetailsEditModeChange() {
    if (this.contactDetails.editMode) {
      this.notificationsForm.enable();
    } else {
      this.notificationsForm.disable();
    }
  }
}
