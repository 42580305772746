import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';

import { BaseZoneFragment } from 'graphql.generated';
import { flatten } from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, filter, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { AppMainComponent } from '../../app.main.component';

import { MenuService, SidebarItem } from '../../base/menu/app.menu.service';
import { ZONE_TYPES } from '../../global.constants';
import { FRONTEND_PERMISSIONS } from '../../permissions.constants';

import { BrandingService } from '../../services/branding.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit, OnDestroy {


  subs = new SubSink();

  subZones: BaseZoneFragment[];

  tabs: SidebarItem[] = [];

  constructor(
    private permissionHandler: PermissionService,
    public brandingSvc: BrandingService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private appMain: AppMainComponent,
  ) { }

  ngOnInit() {
    this.watchPermissions();
    this.watchUrl();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Resolves which tabs to show based on user permissions and current zone type
   */
  watchPermissions() {
    const sidebarMenuItems = this.menuService.sidebar.find((i) => i.label === 'Business')?.items;

    const businessMenuItems: SidebarItem[] = [
      {
        label: 'General',
        icon: 'pi pi-table',
        routerLink: [ '/business/general' ],
        hiddenIfZoneType: [ ZONE_TYPES.corporate, ZONE_TYPES.root ],
        permissions: FRONTEND_PERMISSIONS.franchise,
      },
      ...sidebarMenuItems,
    ];

    const tabPermissions = flatten(businessMenuItems.map((i) => i.permissions));

    const currentZoneType$ = this.brandingSvc.currentZone().pipe(map((z) => z.type));
    const permissions$ = this.permissionHandler.watchPermissions(tabPermissions);

    this.subs.sink = combineLatest([ currentZoneType$, permissions$ ])
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),)
      .subscribe(([ currentZoneType, permissions ]) => {

          const canView: SidebarItem[] = [];

          for (const item of businessMenuItems) {

            if (item?.hiddenIfZoneType?.includes(currentZoneType)) {
              continue;
            }

            const hasPermission = permissions[tabPermissions.indexOf(item.permissions)];

            if (!hasPermission) {
              continue;
            }

            canView.push(item);
          }

          this.tabs = canView;

          // We may be setting the tabs after change detection has completed,
          // call this to prevent an `ExpressionChangesAfterItHasBeenCheckedError`
          this.appMain.cd.detectChanges();
        });
  }

  checkPermissionsForCurrentRoute() {

    // If there are no tabs, that means permissions haven't loaded yet, wait for them to load before checking
    if (!this.tabs.length) { return; }

    const [ url ] = this.router.url.split('?');

    const tabForRoute = this.tabs.find((t) => t.routerLink && t.routerLink[0] === url);

    if (!tabForRoute) {
      this.router.navigate(this.tabs[0].routerLink, { relativeTo: this.route });
    }
  }


  watchUrl() {
    const currentZoneType$ = this.brandingSvc.currentZone()
      .pipe(
        map((z) => z.type),
        distinctUntilChanged(),
      );

    const url$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      distinctUntilChanged(),
    );

    const currentZoneType = this.brandingSvc.currentZone().getValue().type;

    this.subs.sink = combineLatest([ currentZoneType$, url$ ])
      .pipe(startWith([ currentZoneType, this.router.url ]))
      .subscribe(([ zoneType, url ]) => this.handleDefaultSubRoute(zoneType, url));
  }

  /**
   * Resolves the default subroute/tab when the user visits '/business' based on the current zone type.
   * If the current zona type is corporate or root, redirects to Business Units
   * If franchise, redirects to General (formerly Franchise Info)
   */
  handleDefaultSubRoute(zoneType: string, url: string) {

    const [ _blank, _parent, child ] = url.split('/');

    if (child) { return; }

    let redirectTo: string;

    if (zoneType === 'root' || zoneType === 'corporate') {
      redirectTo = 'units';
    } else {
      redirectTo = 'general';
    }

    this.router.navigate([ `/business/${redirectTo}`], { relativeTo: this.route });

  }

}
