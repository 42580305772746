import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dayjs } from '@karve.it/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ScheduleActions } from './schedule.actions';

export const onAppLoad = createEffect((
	actions$ = inject(Actions),
	route = inject(ActivatedRoute),
) => {

	return actions$.pipe(
		ofType(ScheduleActions.initSchedulesComponent),
		map(() => {
			const dateToSet = route.snapshot.queryParamMap.get('date') || sessionStorage.getItem(environment.lskeys.scheduleDate);
			return ScheduleActions.setDate({ date: dateToSet ? dayjs(dateToSet) : dayjs() });
		}),
	);

}, { dispatch: true, functional: true });

