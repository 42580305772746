import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateJobGQL } from 'graphql.generated';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseJobFragment, Job_UsersFragment } from '../../../generated/graphql.generated';

import { MenuService } from '../../base/menu/app.menu.service';
import { FreyaHelperService } from '../../services/freya-helper.service';

@Component({
  selector: 'app-close-job',
  templateUrl: './close-job.component.html',
  styleUrls: ['./close-job.component.scss', '../../dynamic-dialog-styling.scss']
})
export class CloseJobComponent implements OnInit, OnDestroy {

  @Input() job: BaseJobFragment & Job_UsersFragment;

  closeJobForm = new UntypedFormGroup({
    reason: new UntypedFormControl(undefined, [Validators.required]),
    explanation: new UntypedFormControl(undefined, []),
  });

  closureReasons: { option: string; label: string }[] = [];

  subs = new SubSink();

  constructor(
    private menuService: MenuService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private updateJob: UpdateJobGQL,
    private notify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit(): void {
    this.job = this.config.data?.job;
    this.setClosureReasons();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  closeJob() {
    this.menuService.pushJob(this.job, 0, true);

    this.updateJob.mutate({
      updateJobs: [{
        jobId: this.job.id,
        closeJob: true,
        closedReason: this.closeJobForm.value.reason.option
      }],
    }).subscribe(() => {
      this.detailsHelper.pushUpdate({
        id: this.job.id,
        type: 'Jobs',
        action: 'update',
      });

      this.notify.success('Job closed');
    }, (err) => {
      this.notify.apolloError('Failed to close job', err);
    });

    this.closeDialog(true);
  }

  closeDialog(closed: boolean){
    this.ref.close(closed);
  }

  setClosureReasons() {
    this.closureReasons = this.freyaHelper.closureReasons.value
      .filter((cr) => !cr.stages?.length || cr.stages.includes(this.job?.stage))
      .map((cr) => ({
        option: cr.id,
        label: cr.title,
      }));

      if (!this.closureReasons?.length){
        console.warn(`Closure Reasons did not load or are invlaid: ${this.closureReasons}`);
        this.closureReasons = [{option: 'other', label: 'Other'}];
      }
  }

}
