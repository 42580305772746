<ng-container *ngIf="activity$ | async as activity">
  <p-blockUI [target]="blockable" [blocked]="activity.loading" [autoZIndex]="false">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
  </p-blockUI>
  <div class="card activity">
    <div class="dashboard-card-header p-mb-4">
      <div>
        <h4 class="p-mb-1">Activity<app-error-icon *ngIf="activity.error"></app-error-icon></h4>
        {{ activity.displayPeriod }}
      </div>
      <app-dashboard-info-v2>
        <div class="p-mb-2">
          <b>Transactions Paid:</b> For each day of the selected period, the sum of all transactions paid on that day.
        </div>
        <div class="p-mb-2">
          <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="activity.transactionsPaidQueryParams" target="_blank">View Report</a>.
        </div>
        <div class="p-mb-2">
          <b>Events Created:</b> For each day of the selected period, the sum of discounted subtotal of all events created on that day.
        </div>
        <div class="p-mb-2">
          <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="activity.eventsCreatedQueryParams" target="_blank">View Report</a>.
        </div>
        <div class="p-mb-2">
          <b>Events Happening:</b> For each day of the selected period, the sum of discounted subtotal of all events happening on that day.
        </div>
        <div class="p-mb-2">
          <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="activity.eventsHappeningQueryParams" target="_blank">View Report</a>.
        </div>
      </app-dashboard-info-v2>
    </div>
    <app-blockable #blockable>
      <p-chart
        type="line"
        [data]="activity.graphData"
        [options]="activity.options"
        [responsive]="true"
        style="max-height: 150px;"
        ></p-chart>
    </app-blockable>
  </div>
</ng-container>
