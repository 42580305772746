import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-span-seperator',
  templateUrl: './span-seperator.component.html',
  styleUrls: ['./span-seperator.component.scss']
})
export class SpanSeperatorComponent implements OnInit {

  @Input() breakpoint = 800;

  innerWidth: number;

  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

}
