
import { BaseUserFragment, Job_UsersFragment, User } from '../../generated/graphql.generated';

import { JOB_ROLE_MAP } from '../global.constants';

export function getJobCustomer(users: Job_UsersFragment['users'], asString?: boolean): string | BaseUserFragment  {
    if (!users) { return 'None'; }
    const user = users.find((u) => u.role === JOB_ROLE_MAP.customerRole);

    if (asString) {
      return user?.user ? `${user.user.givenName} ${user.user.familyName}` : 'None';
    } else {
      return user?.user as User;
    }
}

export function getJobCustomerUser(users: Job_UsersFragment['users'], asString?: boolean): BaseUserFragment  {
    if (!users) { return undefined; }
    const user = users.find((u) => u.role === JOB_ROLE_MAP.customerRole);

    return user?.user;
}

export function getJobCustomerEmail(users: Job_UsersFragment['users']): string  {
  if (!users) { return 'None'; }
  const user = users.find((u) => u.role === JOB_ROLE_MAP.customerRole);

  return user?.user?.email;
}
