<div
    #header
    class="p-grid"
>
    <div class="p-col left-column right-column">
        <div class="card">
            <p-messages *ngIf="isJobV2PageEnabled" severity="warn">
                <ng-template pTemplate>
                    <div class="jobsv2-enabled-waring">
                        This is the legacy version of the process tool.
                        We are working on transitioning to our new, improved Jobs tool.
                        <button
                            *ngIf="isJobV2PageEnabled"
                            pButton label="Go to Job Tool"
                            class="p-button-warning p-button-outlined"
                            (click)="openJobTool()"
                            style="margin-left : 1rem">
                        </button>
                    </div>
                </ng-template>
            </p-messages>
            <div class="p-grid p-as-center job-header">
                <div class="p-col-8 p-as-center">
                    <h4
                        *ngIf="job && !jobsLoading; else elseBlock"
                        class="no-margin"
                    >
                        {{ job?.users | jobCustomer }} -
                        {{ job?.stage | titlecase }}

                    </h4> 
                    <h5 class="no-margin job-code">{{ job?.code }}</h5>
                    <div class="p-d-flex p-ai-center">
                        <app-object-state
                            [object]="job"
                            objectType="job"
                            [colorful]="true"
                            closureReasonPlacement="label"
                            [loading]="jobsLoading"
                        ></app-object-state>
                    </div>
                </div>
                <div class="p-col-4 p-text-right">
                    <span [pTooltip]="readonlyTooltip">
                        <button
                        class="p-m-1"
                            pButton
                            [disabled]="readonly || job?.followingStages.length > 0"
                            (click)="navigateToEstimatingTool()"
                            label="Process"
                            data-cy="process-button"
                        ></button>
                    </span>
                    <div style="display: inline-block;" class="p-m-1">
                        <app-job-actions
                        *ngIf="job"
                        [job]="job"
                        data-cy="actions-button">
                    </app-job-actions>
                    </div>
                </div>
                <div
                    *ngIf="job?.id"
                    class="p-col-6 p-md-4"
                >
                    <app-display-tags
                    [objectType]="'Job'"
                    [objectId]="job?.id"
                    [tagsOnObject]="job?.tags"
                    [readonly]="readonly"
                ></app-display-tags>
                </div>
                <div
                *ngIf="job?.id"
                class="p-col-6 p-md-6 flex participants"
                >
                    <!-- <app-job-participant role="Author" [job]="job" ></app-job-participant> -->
                    <app-job-participant role="Sales Agent" [job]="job" [isLoading]="jobsLoading"  ></app-job-participant>
                    <app-job-participant role="Estimator" [job]="job" [isLoading]="jobsLoading" ></app-job-participant>
                </div>
            </div>
        </div>
    </div>
</div>

<p-tabView>
    <p-tabPanel header="Overview">
        <app-estimate-confirmation
            #confirmationRef
            [job]="job"
            [showLoading]="true"
            [inventory]="inventoryText"
            [printSectionId]="hostId"
        >
        </app-estimate-confirmation>
        <!-- This will be moved -->


        <app-comments
            *ngIf="job"
            [objectId]="job?.id"
            [objectType]="'Job'"
            [callOnLoad]="true"
            [nameForComment]="'Note'"
            [commentsFilter]="{hasNoAttributes: ['inventory', 'generated-inventory']}"
            titleNoToolTip="Notes - This will be moved to the notes tab in a future release"
            [readonly]="addCommentReadOnly"
            [readonlyTooltip]="readonlyTooltip"
        ></app-comments>
    </p-tabPanel>
    <p-tabPanel
        header="Notes"
        [disabled]="!job"
        *ngIf="false"
    >
        <app-comments
            [objectId]="job?.id"
            [objectType]="'Job'"
            [callOnLoad]="true"
            [nameForComment]="'Note'"
            [commentsFilter]="{hasNoAttributes: ['inventory', 'generated-inventory']}"
        ></app-comments>
    </p-tabPanel>
    <p-tabPanel
        header="History"
        *ngIf="false"
        [disabled]="!job"
        tooltip="View historical events related to the job"
    >
        <!-- <h3>Coming Soon: Snapshots</h3> -->
        <!-- <app-snapshots></app-snapshots> -->
        <app-history
            [objectId]="job?.id"
            objectLabel="Job"
        >
        </app-history>
    </p-tabPanel>
</p-tabView>


<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="24px"
    ></p-skeleton>
</ng-template>

<app-mutate-job #mutate>
</app-mutate-job>

<p-dialog
    [(visible)]="showCancelDialog"
    [modal]="true"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
>
    <ng-template pTemplate="header"> Cancel Job? </ng-template>
    <p class="error-text">
        Cancelling this Job will also cancel any associated calendar events. Are
        you sure you want to do this?
    </p>
    <ng-template pTemplate="footer">
        <button
            pButton
            label="No, Exit Window"
            icon="pi pi-times"
            (click)="showCancelDialog = false"
        ></button>
        <button
            class="p-button-danger"
            pButton
            label="Yes, Cancel Job"
            icon="pi pi-trash"
            (click)="cancelJob()"
        ></button>
    </ng-template>
</p-dialog>

<app-mutate-location
    #mutateLocation
    mutateType="update"
></app-mutate-location>
