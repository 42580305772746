import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { ProductHelperService } from 'src/app/services/product-helper.service';
import { Product, EstimatesJobFragment } from 'src/generated/graphql.generated';

export interface ProductWithQuantity {
  product: Product;
  quantity: number;
}

export interface ChargeWithQuantity extends Product {
  quantity?: number;
}

@Component({
  selector: 'app-estimate-products',
  templateUrl: './estimate-products.component.html',
  styleUrls: ['./estimate-products.component.scss']
})

export class EstimateProductsComponent implements OnChanges {
  @Input() availableProducts: Product[] = [];
  @Input() job: EstimatesJobFragment;
  @Input() eventId: string;
  @Input() eventTitle: string;
  @Input() eventStart: string;
  @Input() productsLoading: boolean;
  @Input() disabled: boolean;
  @Input() disabledToolTip: string;

  @Output() chargesAddedFromModal: EventEmitter<{ eventId: string; addedCharges: ProductWithQuantity[] }> = new EventEmitter();
  @Output() createCustomChargeClicked = new EventEmitter<{ productSearch: string }>();

  mutatedAvailableProducts: ChargeWithQuantity[] = [];

  showAvailableProductsDialog = false;

  productSearch = '';

  addedFixedChargesTotal = 0;
  addedPercentageCharges = false;
  isChargesToAdd = false;

  warningMessage = '';

  constructor(
    private productHelper: ProductHelperService,
    private notify: FreyaNotificationsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.availableProducts) {
      this.mutatedAvailableProducts = cloneDeep(this.availableProducts);
    }
    this.generateWarning();
  }

  handleInputClick(event: any) {
    event.stopPropagation();
    if (typeof event?.target?.select === 'function') {
      event.target.select();
    }
  }

  resetMutatedProducts() {
    this.mutatedAvailableProducts = cloneDeep(this.availableProducts);
  }

  addChargesToCurrentEvent() {
    if (this.mutatedAvailableProducts.some(charge => charge.quantity)) {
      this.chargesAddedFromModal.emit({
        eventId: this.eventId,
        //update structure of charge only on add and keep mutatedAvailableProducts
        //same as availableProducts to not break search
        addedCharges: this.mutatedAvailableProducts
          .filter(charge => charge.quantity)
          .map(charge => ({
            product: cloneDeep(charge),
            quantity: charge.quantity
          }))
      });

      this.showAvailableProductsDialog = false;
      this.resetMutatedProducts();
    }
  }

  cancelCharges() {
    this.resetMutatedProducts();
    this.showAvailableProductsDialog = false;
    this.addedFixedChargesTotal = 0;
    this.addedPercentageCharges = false;
    this.isChargesToAdd = false;
  }

  openAvailableProductsModal() {
    this.showAvailableProductsDialog = true;
  }

  handlePercentageQuantity(input: number | boolean): number {
    if (typeof input === 'number') {
        return input;
    } else if (typeof input === 'boolean') {
      return input ? 1 : 0;
    }
  }

  generateWarning() {
    if (this.addedPercentageCharges) {
      this.warningMessage = 'Percentage based charges will be applied to all event charges';
    } else if (!this.isChargesToAdd) {
      this.warningMessage = 'Select charges above to add charges';
    } else {
      this.warningMessage = '';
    }
  }

  handleQuantityChange(product: Product, quantity: number | boolean) {
    const unifiedQuantity = this.handlePercentageQuantity(quantity);
    if (this.mutatedAvailableProducts.length) {
      const existingChargeIndex = this.mutatedAvailableProducts.findIndex(charge => charge.id === product.id);

      this.mutatedAvailableProducts[existingChargeIndex].quantity = unifiedQuantity;
    }

    if (unifiedQuantity > 0) {
      this.isChargesToAdd = true;
    } else if (unifiedQuantity === 0) {
      this.isChargesToAdd = this.mutatedAvailableProducts.some(item => item.quantity);
    }

    this.calculateAddedTotal();
    this.generateWarning();
  }

  handleClickOnProductRow(product: Product) {
    const activePrice = this.productHelper.getActivePrice(product.prices, this.job?.zone?.id);
    if (activePrice.priceType === 'percentage') {
      return;
    }

    if (this.mutatedAvailableProducts.length) {
      const existingChargeIndex = this.mutatedAvailableProducts.findIndex(charge => charge.id === product.id);

      if (this.mutatedAvailableProducts[existingChargeIndex].quantity) {
        this.mutatedAvailableProducts[existingChargeIndex].quantity +=1;
      } else {
        this.mutatedAvailableProducts[existingChargeIndex].quantity = 1;
      }

      this.isChargesToAdd = true;
    }

    this.calculateAddedTotal();
    this.generateWarning();
  }

  calculateAddedTotal() {
    if (this.mutatedAvailableProducts.length) {
      const activePricesFixed = this.mutatedAvailableProducts
        .filter(charge => (charge.quantity && this.productHelper.getActivePrice(charge.prices, this.job?.zone?.id).priceType === 'fixed'))
        .map(charge => {
          const activePrice = this.productHelper.getActivePrice(charge.prices, this.job?.zone?.id);
          return { amount: activePrice.amount, quantity: charge.quantity };
        });
      this.addedFixedChargesTotal = activePricesFixed.reduce((total, price) => total + (price.amount * price.quantity), 0);

      this.addedPercentageCharges = this.mutatedAvailableProducts
        .some(charge => (charge.quantity
          && this.productHelper.getActivePrice(charge.prices, this.job?.zone?.id).priceType === 'percentage'));
    }
    if (!this.mutatedAvailableProducts.some(charge => charge.quantity)) {
      this.addedFixedChargesTotal = 0;
      this.addedPercentageCharges = false;
    }
  }

  emitCreateCustomCharge(productSearch: string) {

    //save added charges before opening custom charge dialog to prevent losing data
    if (this.isChargesToAdd) {
      this.addChargesToCurrentEvent();

      this.notify.success('Selected Charges were added to the job');
    }

    this.showAvailableProductsDialog = false;
    this.createCustomChargeClicked.emit({ productSearch });
  }
};
