import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { SubSink } from 'subsink';

import { Inventory, InventoryItem } from 'src/app/estimates/estimate.interfaces';
import { CONFIGS_KEYS, DEFAULT_ROOM_OPTIONS, JOB_CREATE_INDEXES, statuses, yemboStatuses } from 'src/app/global.constants';
import { Store } from '@ngrx/store';
import { EstimateMethod, JobToolFieldsV2, jobToolFeature } from '../../job-tool.reducer';
import { JobCreateInventoryActions } from '../jobv2-create-inventory-state/jobv2-create-inventory-state.actions';
import MiniSearch, { SearchResult } from 'minisearch';
import { cloneDeep, debounce, DebouncedFunc, isNil } from 'lodash';
import { debounceTime } from 'rxjs';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';


@Component({
    selector: 'app-jobv2-create-inventory',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './jobv2-create-inventory.component.html',
    styleUrls: ['./jobv2-create-inventory.component.scss']
})
export class Jobv2InventoryComponent implements OnInit, OnDestroy {

    @ViewChild('addRoomBtn', { static: false }) addRoomBtn!: ElementRef<HTMLInputElement>;
    @ViewChildren('roomNameSelect') roomNameSelects!: QueryList<AutoComplete>;
    @ViewChildren('itemSelect') itemSelects!: QueryList<AutoComplete>;
    @ViewChildren('itemChangeQuantity') itemChangeQuantities!: QueryList<ElementRef>;

    subs = new SubSink();

    defaultRoomOptions: string[] = DEFAULT_ROOM_OPTIONS;
    filteredRoomOptions: string[] | [] = [];

    itemMiniSearch = new MiniSearch({
        fields: ['reviewerName', 'label', 'rooms', 'aliases'],
        storeFields: ['reviewerName', 'label', 'rooms', 'volume', 'weight'],
        idField: 'label',
    });

    estimateMethods = [
        { label: 'Self Survey', value: 'selfSurvey', id: 'selfSurvey' },
        { label: 'Book Smart Consult', value: 'smartConsult', id: 'smartConsult' }
      ];

    inventoryInput: Inventory;
    fields: JobToolFieldsV2;
    inventoryItemOptions: InventoryItem[] = [];
    filteredItemOptions: (SearchResult | InventoryItem)[] = [];
    yemboStatusesOptions = yemboStatuses;
    yemboCustomerLink: string | undefined = undefined;
    yemboEnabled: boolean;

    currentYemboStatuses = {
        selfSurvey: yemboStatuses?.selfSurvey?.[statuses.notInitiated],
        smartConsult: yemboStatuses?.smartConsult?.[statuses.notInitiated],
    };

    selectedYemboOptions: string[] = [];

    isCardCollapsed = false;
    jobCreateIndexes = JOB_CREATE_INDEXES;
    newIndexForAddRoomBtn: number | 0 = 0;
    activeTabIndex: number | 0 = 0;

    jobConfigs$ = this.store.select(jobToolFeature.selectJobConfigs);
    inventoryInput$ = this.store.select(jobToolFeature.selectInventoryInput);
    yemboStatus$ = this.store.select(jobToolFeature.selectYemboStatus);
    yemboCustomerLink$ = this.store.select(jobToolFeature.selectYemboCustomerLink);

    debouncedInventorySearch: DebouncedFunc<(event: string) => void>;
    constructor(
        private store: Store,
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.inventoryInput$
        .subscribe((inventoryInput) => {
            this.inventoryInput = cloneDeep(inventoryInput);
        });

        this.subs.sink = this.yemboCustomerLink$
        .subscribe((yemboCustomerLink) => {
            this.yemboCustomerLink = yemboCustomerLink;
        });

        this.subs.sink = this.yemboStatus$
        .subscribe((yemboStatus) => {
            this.currentYemboStatuses = {
                selfSurvey: yemboStatus?.selfSurvey === 'initiated'
                    ? yemboStatuses?.selfSurvey?.[yemboStatus?.selfSurvey] + ' | ' + this.yemboCustomerLink
                    : yemboStatuses?.selfSurvey?.[yemboStatus?.selfSurvey],
                smartConsult: yemboStatuses?.smartConsult?.[yemboStatus?.smartConsult],
            }

            this.selectedYemboOptions = cloneDeep(yemboStatus.selectedYemboOptions);
        });

        this.initInventorySearch();

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    updateCheckboxStatus(method: string): void {

        if (this.selectedYemboOptions?.includes(method)) {
            this.store.dispatch(JobCreateInventoryActions.selectEstimateMethod({
                estimateMethod: {
                    [method]: true,
                }
            }));
        } else {
            this.store.dispatch(JobCreateInventoryActions.selectEstimateMethod({
                estimateMethod: {
                    [method]: false,
                }
            }));
        }
    }

    onRoomAdded() {
        this.store.dispatch(JobCreateInventoryActions.inventoryAddRoom({
            roomName: '',
        }));
    }

    onRoomDeleted(index: number) {
        this.store.dispatch(JobCreateInventoryActions.inventoryDeleteRoom({
            roomIndex: index,
        }));
    }

    onRoomNameInputUpdated(event) {
        this.filteredRoomOptions = this.defaultRoomOptions.filter((ro) => ro.includes(event.query));

        if (!this.filteredRoomOptions?.length){
            this.filteredRoomOptions.push(event.query);
        }
    }

    onRoomNameSelected(event, index: number) {
        this.store.dispatch(JobCreateInventoryActions.inventoryUpdateRoomName({
            roomIndex: index,
            newRoomName: event.value,
        }));
    }

    private initInventorySearch() {
        this.subs.sink = this.jobConfigs$
            .pipe(debounceTime(300))
            .subscribe((configs) => {
                if (configs[CONFIGS_KEYS.inventoryItems]) {
                    this.inventoryItemOptions = cloneDeep(configs[CONFIGS_KEYS.inventoryItems]);
                    this.itemMiniSearch.removeAll();
                    this.itemMiniSearch.addAll(this.inventoryItemOptions);
                }

                this.yemboEnabled = configs[CONFIGS_KEYS.yemboEnabled];
            });

        this.debouncedInventorySearch = debounce(this.performInventorySearch, 300);
    }

    public searchInventoryItems(event: AutoCompleteCompleteEvent) {
        this.debouncedInventorySearch(event.query);
    }

    private performInventorySearch(query: string) {
        const searchResults = this.itemMiniSearch.search(
            `${query}`, {
            prefix: true,
            fuzzy: 0.2,
            boost: {
                reviewerName: 2,
                label: 2,
            }
        });

        if (searchResults.length === 0) {
            this.filteredItemOptions = structuredClone(this.inventoryItemOptions);
        } else {
            this.filteredItemOptions = searchResults;
        }

        if (query) {
            this.filteredItemOptions.unshift({ reviewerName: query, name: query, quantity: 0 } as InventoryItem);
        }
    }

    onItemAddedToRoom(event, roomIndex: number) {
        //We need to replace reviewerName with name
        //In config we have reviewerName, but in setFieldsValue it should be name
        //TO DO: see if we can sync it
        const { reviewerName, ...rest } = event.value;
        const updatedItem = {
            ...rest,
            name: reviewerName,
            quantity: 1,
            isCarrierPack: false,
            volume: Number(event.value.volume) || 0,
            weight: Number(event.value.weight) || 0,
        };

        this.store.dispatch(JobCreateInventoryActions.inventoryItemAddedToRoom({
            roomIndex: roomIndex,
            addedItem: updatedItem,
        }));

        setTimeout(() => {
            const itemSelect = this.itemSelects.toArray()[roomIndex];
            if (itemSelect) {
                itemSelect.inputEL.nativeElement.focus();
            }
        }, 0);
    }

    onItemRemovedFromRoom(roomIndex: number, itemIndex: number,) {
        this.store.dispatch(JobCreateInventoryActions.inventoryItemRemovedFromRoom({
            roomIndex, itemIndex,
        }));
    }

    onItemQuantityChanged(event, roomIndex: number, itemIndex: number) {
        this.store.dispatch(JobCreateInventoryActions.inventoryItemQuantityChanged({
            newQuantity: event,
            roomIndex,
            itemIndex,
        }));

        setTimeout(() => {
            const itemSelect = this.itemChangeQuantities.toArray()[itemIndex];
            if (itemSelect) {
                itemSelect.nativeElement.focus();
            }
        }, 0);
    }

    calculateRoomTabIndex(index: number): string {
        const calculatedIndex = (index + 1) + 'addedRoom';
        this.jobCreateIndexes = {
            ...this.jobCreateIndexes,
            [calculatedIndex]: (index + 1) * 1000,
        };
        return calculatedIndex;
    }

    collapseCard() {
        this.isCardCollapsed = !this.isCardCollapsed
      }
}