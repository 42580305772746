import { Component } from '@angular/core';

import { DashboardStore } from '../dashboardv2.store';

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './sources.component.scss',
  ],
})
export class SourcesComponent {

  sources$ = this.dashboardStore.sourcesViewModel$;

  constructor(
    private readonly dashboardStore: DashboardStore,
  ) {}

}
