import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@karve.it/interfaces/auth';

@Pipe({
  name: 'userInitials'
})
export class UserInitialsPipe implements PipeTransform {

  transform(user: User): string {
    if (!user || !user.givenName || !user.familyName){
      return 'N/A';
    }

    return user.givenName.substring(0, 1) + user.familyName.substring(0, 1);
  }

}
