<p-table
  [value]="leadSources$ | async"
  [tableStyle]="{ 'min-width': '50rem' }"
  [loading]="(loading$ | async) !== 'LOADED'"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Zone</th>
      <th>Valid</th>
      <th>Uses</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-token>
    <tr (click)="openLeadSource(token)" class="lead-source">
      <td>{{ token.metadata.name }}</td>
      <td>{{ token.zones[0].name }}</td>
      <td>{{ token.isValid ? 'VALID' : 'NOT VALID' }}</td>
      <td>{{ token.used }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4" class="no-lead-sources">
        There are no lead sources added.
        <br />
        <p-button
          label="Add a lead source"
          [link]="true"
          (click)="addLeadSource()"
        />
      </td>
    </tr>
  </ng-template>
</p-table>

<app-mutate-lead-source mutateType="create" #mutate></app-mutate-lead-source>
