<div class="exception-body access">
    <div class="exception-topbar">
    </div>
    <div class="exception-wrapper">
        <div class="exception-content">
            <img src="assets/layout/images/pages/asset-access.svg" alt="freya-layout" />
            <span>403: Access Denied</span>
            <b class="p-text-center">Sorry, you don't have permissions to access {{deniedUrl}}.</b>
            <br>
            <a pButton [routerLink]="deniedUrl" label="Try Again"></a>
            <br>
            <a pButton routerLink="" label="Go back to home"></a>
            <br>
            <p-panel header="View Details" collapsed="true" [toggleable]="true">
                <p>User: {{userName}}</p>
                <!-- <p *ngIf="userRole">Role: {{userRole?.name}}</p> -->
                <!-- <p *ngIf="userZone">Zone: {{userZone?.name}}</p> -->
                <p>Page: {{deniedUrl}}</p>
            </p-panel>
        </div>
        <div class="exception-footer">
            <h6>Copyright Ⓒ {{company}}</h6>
        </div>
    </div>
</div>
