import { createFeature, createReducer, on } from '@ngrx/store';

import { BaseTokenFragment } from '../../../../generated/graphql.generated';

import { CallState, LoadingState } from '../../../utilities/state.util';

import { LeadSourceActions } from './lead-source.actions';

export const leadSourceInitialState = {
	callState: 'INIT' as CallState,
	leadSources: [] as BaseTokenFragment[],
};

export type LeadSourceState = typeof leadSourceInitialState;

export const LeadSourceFeature = createFeature({
	name: 'Lead Source',
	reducer: createReducer(
		leadSourceInitialState,
		on(
			LeadSourceActions.load,
            (state, {}): LeadSourceState => ({
                ...state,
				callState: LoadingState.LOADING,
				leadSources: [],
            }),
		),
		on(
			LeadSourceActions.loading,
            (state, {}): LeadSourceState => ({
                ...state,
				callState: LoadingState.LOADING,
            }),
		),
		on(
			LeadSourceActions.loadError,
            (state, { error }): LeadSourceState => ({
                ...state,
				callState: {
					error,
				},
            }),
		),
		on(
			LeadSourceActions.loaded,
            (state, { leadSources }): LeadSourceState => ({
					...state,
					callState: LoadingState.LOADED,
					leadSources,
				}
			),
		),
	),
});
