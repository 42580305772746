import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {QueryRef} from 'apollo-angular';




import { LazyLoadEvent } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { FullProductFragment, ListProductsGQL, ListProductsQuery, ListProductsQueryVariables, Price, Product } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { pagination } from '../global.constants';

import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { MutateProductComponent } from '../shared/mutate-product/mutate-product.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

  @ViewChild('mutateProduct') mutateProductRef: MutateProductComponent;
  @ViewChild('productSearchInput', { static: true }) productSearchInput!: ElementRef;

  subs = new SubSink();

  products: FullProductFragment[];

  showDeleted = false;

  // Lazy Loading Variables
  productsQueryRef: QueryRef<ListProductsQuery, ListProductsQueryVariables>;
  productsQH: WatchQueryHelper = {
    limit: pagination.defaultNumberOfItems,
    search: '',
    loading: true,
    total: 0
  };

  pagination = pagination;

  constructor(
    private detailsHelper: DetailsHelperService,
    public freyaHelper: FreyaHelperService,
    private listProductsGQL: ListProductsGQL,

  ) { }

  ngOnInit() {
    this.subs.sink = this.detailsHelper.getObjectUpdates('Prices').subscribe(() => this.productsQueryRef.refetch());

    this.subs.sink = fromEvent(this.productSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.productsQH.skip = 0;

      this.searchForProducts();
    });

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchForProducts() {
    this.productsQH.loading = true;

    if (this.productsQueryRef) { // If we have already intialized the query
      // this.productsQueryRef.resetLastResults();
      this.productsQueryRef.refetch(this.getProductsInput());
    } else {
      this.initProductQuery();
    }
  }

  initProductQuery() {

    const watchProductsInput = this.getProductsInput();
    this.productsQueryRef = this.listProductsGQL.watch(watchProductsInput);

    this.subs.sink = this.productsQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.products = res.data.products.products;
        this.productsQH.loading = false;
        this.productsQH.total = res.data.products.total;
      }
    });
  }

  retrieveMoreProducts(event: LazyLoadEvent) {
    this.productsQH.limit = event.rows;
    this.productsQH.skip = event.first;

    this.searchForProducts();
  }

  getProductsInput(): ListProductsQueryVariables {
    return {
      filter: {
        names: [this.productsQH.search],
        showDeleted: this.showDeleted,
      },
      limit: this.productsQH.limit,
      skip: this.productsQH.skip,
    };
  }

  // Populate the Product and Price Information in the Side Panel
  viewProductDetails(product: Product, price?: Price) {
    if (price) {
      this.detailsHelper.open('price', price, true, undefined, { product });
      return;
    }
    this.detailsHelper.open('product', product);
  }

  openCreateProduct() {
    this.mutateProductRef.mutateType = 'create';
    this.mutateProductRef.openDialog();
  }
}
