import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { default as cronstrue } from 'cronstrue';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { BaseRoleFragment, BaseUserFragment, CreateExportTokenGQL, CreateReportGQL, DeleteScheduledReportsGQL, ListRolesGQL, ListScheduledReportsGQL, ListScheduledReportsQueryVariables, ListUsersGQL, ScheduledReportEssentialsFragment, TestScheduledDynamicReportGQL, Token } from '../../../generated/graphql.generated';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { ReportService, ReportVariable } from '../../services/report.service';
import { DisabledWhen, KarveMenuItem, setMenuItemDisabled } from '../../utilities/menu-item.util';

@Component({
  selector: 'app-scheduled-report-details',
  templateUrl: './scheduled-report-details.component.html',
  styleUrls: ['./scheduled-report-details.component.scss']
})
export class ScheduledReportDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() sr: ScheduledReportEssentialsFragment;
  @Input() loaded = true;

  vars: ReportVariable[] = [];
  actions: KarveMenuItem[] = [];
  loading = false;
  variables: [];

  subs = new SubSink();

  notifyUsers: BaseUserFragment[];
  notifyRoles: BaseRoleFragment[];

  permissions = {
    pReportsCreate: false,
    pScheduleReportsUpdate: false,
    pScheduleReportsDelete: false,
  };

  private listScheduledReportsWatchQuery: ReturnType<typeof this.listScheduledReportsGQL.watch>;

  constructor(
    private permissionsHanlder: PermissionService,
    private listScheduledReportsGQL: ListScheduledReportsGQL,
    private deleteScheduledReportsGQL: DeleteScheduledReportsGQL,
    private testScheduledDynamicReportGQL: TestScheduledDynamicReportGQL,
    private localNotify: FreyaNotificationsService,
    public detailsHelper: DetailsHelperService,
    private mutateService: FreyaMutateService,
    private createReportGQL: CreateReportGQL,
    private listUsersGQL: ListUsersGQL,
    private listRolesGQL: ListRolesGQL,
    private confirmSvc: ConfirmationService,
    public reportService: ReportService,
  ) { }

  async ngOnInit() {
    this.load();

    this.initActions();
    this.initPermissions();
    this.updateVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.load();
    this.initActions();
    this.setVisibleActions();
    this.updateVars();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  load() {
    if (!this.sr) { return; }
    if (!this.sr?.name) {
      this.loaded = false;
    }

    if (this.listScheduledReportsWatchQuery) {
      this.listScheduledReportsWatchQuery.setVariables(this.getVariables());
      return;
    }

    this.listScheduledReportsWatchQuery = this.listScheduledReportsGQL.watch(
      this.getVariables(),
      { fetchPolicy: 'cache-and-network' }
    );

    this.subs.sink = this.listScheduledReportsWatchQuery.valueChanges.subscribe((res) => {
      const [sr] = res.data?.scheduledReports?.scheduledReports || [];
      this.loading = res.loading;
      if (sr && !res.loading) {
        this.sr = sr;
        this.loaded = true;
        this.updateVars();
        this.updateNotifications();
      }
    });
  }

  updateNotifications() {

    if (this.sr?.notifyRoles?.length) {
      this.subs.sink = this.listRolesGQL.fetch({
        search: {
          ids: this.sr.notifyRoles,
          limit: this.sr.notifyRoles.length,
        }
      }).subscribe((res) => {
        this.notifyRoles = res.data.roles;
      });
    } else {
      this.notifyRoles = [];
    }

    if (this.sr?.notifyUsers?.length) {
      this.subs.sink = this.listUsersGQL.fetch({
        limit: this.sr.notifyUsers.length,
        filter: {
          userIds: this.sr.notifyUsers,
        }
      }).subscribe((res) => {
        this.notifyUsers = res.data.usersv2.users;
      });
    } else {
      this.notifyUsers = [];
    }

  }

  getVariables(): ListScheduledReportsQueryVariables {
    return {
      filter: {
        ids: [ this.sr.id ],
        getDeleted: true,
      },
    };
  }

  initActions() {

    this.actions = [
      {
        id: 'view',
        label: this.sr?.reportType ? 'View Report Type' : 'View Dynamic Report',
        icon: 'pi pi-external-link',
        routerLink: this.sr?.reportType ? [ '/reports/type', this.sr?.reportType?.id ] : [ '/reports/dynamic', this.sr?.dynamicReport?.id ],
      },
      {
        id: 'test',
        label: 'Test Now',
        icon: 'pi pi-step-forward',
        command: () => {
          this.testReport();
        },
        disabledWhen: {
          objectDeleted: true,
        },
      },
      {
        id: 'edit',
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => {
          this.mutateService.openMutateObject({

            mutateType: 'update',
            objectType: 'ScheduledReport',
            object: this.sr,
            additionalValues: [
              {
                property: 'reportType',
                value: this.sr?.reportType,
              },
              {
                property: 'dynamicReport',
                value: this.sr?.dynamicReport,
              },
            ],
          });
        },
        disabledWhen: {
          objectDeleted: true,
        },
      },
      {
        separator: true,
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.confirmSvc.confirm({
            header: 'Are you sure you want to delete this scheduled report?',
            message: `Clicking "Yes, delete" will delete "${ this.sr.name }". It will not delete any reports it generated. Are you sure?`,
            rejectLabel: 'No, go back',
            acceptLabel: 'Yes, delete.',
            acceptIcon: 'pi pi-trash',
            accept: () => {
              this.delete();
            }
          });
        },
        disabledWhen: {
          objectDeleted: true,
        },
      }
    ];

    this.setDisabledActions();
  }

  initPermissions() {
    this.subs.sink = this.permissionsHanlder.watchPermissions([
      'reports.create',
      'scheduleReports.update',
      'scheduleReports.delete',
    ]).subscribe((res) => {
      this.permissions.pReportsCreate = res[0];
      this.permissions.pScheduleReportsUpdate = res[1];
      this.permissions.pScheduleReportsDelete = res[2];

      this.setVisibleActions();
    });
  }

  setVisibleActions() {
    const test = this.actions.find((a) => a.id === 'test');
    const edit = this.actions.find((a) => a.id === 'edit');
    const deleteAction = this.actions.find((a) => a.id === 'delete');

    test.visible = this.permissions.pReportsCreate;
    edit.visible = this.permissions.pScheduleReportsUpdate;
    deleteAction.visible = this.permissions.pScheduleReportsDelete;
  }

  setDisabledActions() {

    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.sr.deletedAt),
    };

    for (const action of this.actions) {
      setMenuItemDisabled(action, disabledWhen);
    }

  }

  testReport() {
    if (this.sr.reportType) {
      this.createTestReport();
    } else if (this.sr.dynamicReport) {
      this.testDynamicReport();
    }
  }

  createTestReport() {
    const strVariables = this.sr.variables;
    // console.log({variables});
    this.localNotify.addToast.next({
      severity: 'info',
      summary: 'Generating Report',
      detail: this.sr.reportType.name,
    });

    this.createReportGQL.mutate({
      waitForCompletion: false,
      input: {
        reportType: this.sr.reportType.id,
        scheduledReport: this.sr.id,
        report: {
          variables: strVariables,
          saveAggregations: true,
          saveData: true,
          saveReport: true,
        },
      }
    }).subscribe(async (res) => {
      const report = res.data?.createReport;
      if (!report) {
        throw new Error(`No report returned`);
      }

      console.log(`Test Report generated`, res);

      this.detailsHelper.pushUpdate({
        action: 'create',
        id: report.id,
        type: 'Report',
        source: 'local',
        update: {
          report,
          reportType: this.sr.reportType,
        },
      });

      this.detailsHelper.open('report', report);

    }, (error) => {
      console.error(error);
      this.localNotify.addToast.next({
        severity: 'error',
        summary: 'Could not generate report',
        detail: error.message,
      });
      // sub.next({
      //   generating: false,
      //   error,
      // });
    });
  }

  testDynamicReport() {
    this.testScheduledDynamicReportGQL.mutate({ reportId: this.sr.id }).subscribe((res) => {

      const success = res.data.testScheduledDynamicReport;

      if (success) {
        this.localNotify.success(`Dynamic report ran`);
      } else {
        this.localNotify.error(`Could not test dynamic report`);
      }

    }, (err) => {
      this.localNotify.error(`Could not test dynamic report`, err.message);
    });
  }

  delete() {
    this.deleteScheduledReportsGQL.mutate({
      input: {
        ids: [ this.sr.id ],
      }
    }, {
      refetchQueries: [ 'ListScheduledReports' ],
    }).subscribe((res) => {
      if (res.data?.deleteScheduledReports?.deleted?.length) {
        this.localNotify.success(`Scheduled report deleted`, this.sr.name);
        this.detailsHelper.pushUpdate({
          action: 'delete',
          id: this.sr.id,
          type: 'ScheduledReport',
        }, true);
        delete this.sr;
      } else {
        this.localNotify.error(
          `Could not delete scheduled report`,
          `Could not confirm that the scheduled report was deleted.`,
        );
      }
    }, (err) => {
      this.localNotify.error(`Could not delete scheduled report`, err.message);
      console.error(err);

    });
  }

  timeToString(
    time: string,
    verbose = true
  ) {
    try {
      const crontime = cronstrue.toString(time, {
        throwExceptionOnParseError: true,
        verbose,
      });

      // change to lowercase
      // crontime = crontime[0].toLowerCase() + crontime.slice(1);

      return crontime;
    } catch (err) {
      return `Invalid Interval: ${ time }`;
    }
  }

  updateVars() {
    this.vars = this.reportService.parseReportVariables(this.sr?.variables);
  }

}
