<p-chip
  *ngIf="!loading && state"
  [label]="label"
  [pTooltip]="tooltip"
  [styleClass]="colorful && state"
></p-chip>

<p-skeleton
  *ngIf="loading"
  width="48px"
  height="28px"
  ></p-skeleton>
