import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Job } from '@karve.it/interfaces/jobs';

@Pipe({
  name: 'jobAmountDue'
})
export class JobAmountDuePipe implements PipeTransform {

  constructor(private currency: CurrencyPipe){}

  transform(job: Job): string {

    return job ? this.currency.transform((job.total - job.paidTotal) / 100) : 'N/A';
  }

}
