<div>
    <p-autoComplete
        appendTo="body"
        [field]="userFullName"
        [disabled]="disabled"
        [(ngModel)]="selectedUser"
        (ngModelChange)="onChange(selectedUser)"
        [forceSelection]="true"
        [suggestions]="userSuggestions"
        (completeMethod)="searchUsers($event)"
        (onSelect)="emitSelect()"
        (focus)="touched = true"
        [dropdown]="true"
    >
        <ng-template
            let-user
            pTemplate="item"
        >
            <span>{{user.givenName}} {{user.familyName}}</span>
        </ng-template>
    </p-autoComplete>
</div>
