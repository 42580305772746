import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { MenuItem } from 'primeng/api';

import { AutoCompleteSelectEvent } from 'primeng/autocomplete';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../../../services/details-helper.service';
import { JobToolActions } from '../../../job-tool.actions';
import {
  JobRole,
  jobToolFeature,
  UpdateJobsV2Input,
  UserWithName,
} from '../../../job-tool.reducer';

@Component({
  selector: 'app-job-agent',
  standalone: true,
  imports: [FreyaCommonModule],
  templateUrl: './job-agent.component.html',
  styleUrl: './job-agent.component.scss',
})
export class JobAgentComponent implements OnInit, OnDestroy {
  @Input() agent: UserWithName;
  @Input() role: JobRole;
  @Input() jobId: string;

  public isEditMode = false;

  public actionMenuItems: MenuItem[] = [];

  public selectedEmployee: UserWithName;
  public employees$ = this.store.select(jobToolFeature.selectEmployeesWithName);
  public employees: UserWithName[] = [];

  private subs = new SubSink();
  private wasClickedInside = false;

  constructor(
    private store: Store,
    private detailsHelper: DetailsHelperService,
  ) { }

  @HostListener('click')
  clickInside() {
    this.wasClickedInside = true;
  }

  @HostListener('document:click', ['$event.target'])
  clickedOut(targetElement: HTMLElement) {
    if (this.isEditMode) {
      // Only run when in edit mode
      const menuElement = document.querySelector('.p-menu-overlay');

      const isInsideMenu = menuElement && menuElement?.contains(targetElement);
      if (!this.wasClickedInside && !isInsideMenu) {
        this.isEditMode = false;
      }
    }
    this.wasClickedInside = false;
  }

  ngOnInit() {
    this.actionMenuItems = [
      {
        label: `Change ${this.role}`,
        icon: 'pi pi-pencil',
        command: () => {
          this.addAgent();
        },
      },
      {
        label: `Unassign ${this.role}`,
        icon: 'pi pi-times',
        command: () => {
          this.unAssignAgent();
        },
      },
    ];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private watchEmployees() {
    this.subs.sink = this.employees$.subscribe((employees) => {
      this.employees = employees;
    });
  }

  public filterEmployees(event) {
    this.store.dispatch(
      JobToolActions.employeeSearched({ employeeSearch: event.query }),
    );
  }

  public addAgent() {
    this.isEditMode = true;
    this.selectedEmployee = this.agent;
    this.watchEmployees();
  }

  private createUserInput(user: UserWithName, role: JobRole) {
    return { ...user, role };
  }

  private unAssignAgent() {
    const removeUsers = this.agent
      ? [this.createUserInput(this.agent, this.role)]
      : [];

    // Job Input
    const updateJobInput: UpdateJobsV2Input = {
      jobId: this.jobId,
      removeUsers,
      addUsers: [],
    };

    this.store.dispatch(JobToolActions.jobAgentChanged({ updateJobInput }));
  }

  public selectEmployee(event: AutoCompleteSelectEvent) {
    const selectedEmployee = event.value as UserWithName;

    if (selectedEmployee.id === this.agent?.id) {
      return;
    }

    const addUsers = [this.createUserInput(selectedEmployee, this.role)];
    const removeUsers = this.agent
      ? [this.createUserInput(this.agent, this.role)]
      : [];
    const updateJobInput: UpdateJobsV2Input = {
      jobId: this.jobId,
      addUsers,
      removeUsers,
    };

    this.store.dispatch(JobToolActions.jobAgentChanged({ updateJobInput }));
    this.isEditMode = false;
  }

  public openAgentDetails() {
    this.detailsHelper.open('users', { id: this.agent.id });
  }
}
