import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { Discount, FullDiscountFragment, GetFullDiscountByIdGQL, GetFullDiscountByIdQuery } from 'graphql.generated';
import { clone } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { PermissionService } from 'src/app/services/permission.service';
import { MutateDiscountComponent } from 'src/app/shared/mutate-discount/mutate-discount.component';
import { DisabledWhen, KarveMenuItem, parseMenuItemCategoriesVisible, setMenuItemDisabled } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { FreyaHelperService } from '../../services/freya-helper.service';

@Component({
  selector: 'app-discount-details',
  templateUrl: './discount-details.component.html',
  styleUrls: ['./discount-details.component.scss']
})
export class DiscountDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateDiscountComponent;

  @Input() discount: FullDiscountFragment;

  discountQueryRef: QueryRef<GetFullDiscountByIdQuery>;

  updateDiscountAction: KarveMenuItem = {
    id: 'edit',
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.mutateRef.discount = this.discount as unknown as Discount;
      this.mutateRef.mutateType = 'update';
      this.mutateRef.openDialog();
    },
    disabledWhen: {
      objectDeleted: true,
      objectArchived: true,
    },
  };

  deleteDiscountAction: KarveMenuItem = {
    id: 'delete',
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.mutateRef.discount = this.discount as unknown as Discount;
      this.mutateRef.openDelete();
    },
    disabledWhen: {
      objectDeleted: true,
      objectArchived: true,
    }
  };

  discountActions = [{
    label: 'Discount Actions',
    items: [
      this.updateDiscountAction,
      this.deleteDiscountAction
    ],
    visible: false,
  }];

  subs = new SubSink();
  constructor(
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    private getDiscountByIdGQL: GetFullDiscountByIdGQL,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit() {
    this.initializePermissions();

    if (this.discount.id && !this.discount.name) {
      this.fetchDiscount();
    }

    this.subs.sink = this.detailsHelper.getObjectUpdates('Discounts').subscribe((update) => {
      this.fetchDiscount(update.id);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('discount')) { return; }
    if (!this.discount.id) { return; }

    if (!this.discount.name) {
      this.fetchDiscount();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.discount = undefined;
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['discounts.update', 'discounts.delete'])
      .subscribe((res) => {
        this.updateDiscountAction.visible = res[0];
        this.deleteDiscountAction.visible = res[1];

        const [ actions ] = this.discountActions;

        actions.visible = actions.items.some((i) => i.visible);

        parseMenuItemCategoriesVisible(this.discountActions);
        this.discountActions = clone(this.discountActions);
      });
  }

  fetchDiscount(discountId?: string) {

    discountId = discountId || this.discount?.id;

    if (this.discountQueryRef) {
      this.discountQueryRef.refetch({ discountId });
      return;
    }

    this.discountQueryRef = this.getDiscountByIdGQL.watch({ discountId }, { fetchPolicy: 'cache-and-network'});

    this.subs.sink = this.discountQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.discount = res.data?.discounts.discounts[0];
      this.setDisabled();
    });
  }

  setDisabled() {
    const [ actionsMenu ] = this.discountActions;
    const { items: actions } = actionsMenu;

    const disabledWhen: DisabledWhen = {
      objectDeleted: Boolean(this.discount.deletedAt),
      objectArchived: Boolean(this.discount.archivedAt),
    };

    for (const action of actions) {
      setMenuItemDisabled(action, disabledWhen);
    }
  }
}
