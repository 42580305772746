import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import {ChargesInput, ListChargesOutput, chargesQuery, CreateChargesInput, CreateChargesOutput, createChargesMutation, UpdateChargesInput, UpdateChargesOutput, updateChargesMutation, DeleteChargesInput, DeleteChargesOutput, deleteChargesMutation, } from '@karve.it/interfaces/charges';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {

    constructor(private apollo: PlusApollo){}

    listCharges(input: ChargesInput, opts?: KarveRequestOptions){
        return this.apollo.query<ListChargesOutput>({
            query: chargesQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    watchCharges(input: ChargesInput, opts?: KarveWatchRequestOptions){
        return this.apollo.watchQuery<ListChargesOutput>({
            query: chargesQuery,
            variables: {
                ...input
            },
            ...parseRequestOptions(opts),
        });
    }

    createCharges(input: CreateChargesInput){
        return this.apollo.mutate<CreateChargesOutput>({
            mutation: createChargesMutation,
            variables: {
                ...input
            }
        });
    }

    updateCharges(input: UpdateChargesInput){
        return this.apollo.mutate<UpdateChargesOutput>({
            mutation: updateChargesMutation,
            variables: {
                ...input
            }
        });
    }

    deleteCharges(input: DeleteChargesInput){
        return this.apollo.mutate<DeleteChargesOutput>({
            mutation: deleteChargesMutation,
            variables: {
                ...input
            }
        });
    }

}
