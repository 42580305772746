import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ChipModule } from 'primeng/chip';
import { OverlayPanel } from 'primeng/overlaypanel';

import { TooltipModule } from 'primeng/tooltip';

import { User } from '../../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { EventAttendeeRoles } from '../../../../global.constants';

import { DetailsHelperService } from '../../../../services/details-helper.service';
import { FreyaMutateService } from '../../../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../../../services/freya-notifications.service';
import { SharedModule } from '../../../../shared/shared.module';
import { convertLocationToFullAddress } from '../../../../utilities/locations.util';

import { DispatchActions } from '../../store/dispatch.actions';
import { AttendeeWithName, DispatchEvent, DispatchFeature, EVENT_STATUS_ICONS, JobUserWithName, UpdateCrewActionPayload } from '../../store/dispatch.reducer';
import { DispatchChipComponent } from '../dispatch-chip/dispatch-chip.component';
import { DispatchEditEventComponent } from '../dispatch-edit-event/dispatch-edit-event.component';

type PotentialAssetType = ReturnType<typeof DispatchFeature.selectPotentialAssets>
export type AssetType = PotentialAssetType extends (infer U)[] ? U : never;

export interface AttendeeDragData {
  event?: DispatchEvent;
  attendee: AttendeeWithName | JobUserWithName;
};

export interface AssetDragData {
  event?: DispatchEvent;
  asset: AssetType;
};
@Component({
  standalone: true,
  imports: [
    FreyaCommonModule,
    DispatchChipComponent,
    DispatchEditEventComponent,
    CdkDropList,
    ChipModule,
    SharedModule,
    TooltipModule,
  ],
  selector: 'app-dispatch-card',
  templateUrl: './dispatch-card.component.html',
  styleUrl: './dispatch-card.component.scss'
})
export class DispatchCardComponent implements OnInit {
  @Input() event: DispatchEvent;
  @Output() editDispatchEvent = new EventEmitter<void>();

  @ViewChild('op') overlayPanel: OverlayPanel;

  public items: MenuItem[] | undefined;
  public readonly EVENT_ATTENDEE_ROLES = EventAttendeeRoles;

  public tooltipOptions = {
    tooltipStyleClass: "conflict-tooltip",
    tooltipPosition: "top",
    positionTop: -8
  };

  public EVENT_STATUS_ICONS = EVENT_STATUS_ICONS

  constructor(
    private detailsHelper: DetailsHelperService,
    private store: Store,
    private router: Router,
    private notify: FreyaNotificationsService,
    private mutateService: FreyaMutateService,
  ) { }

  ngOnInit() {
    this.items = [
      [
        {
          label: 'Edit Job',
          icon: 'pi pi-pencil',
          command: () => {
            if (!this.event.event.job) return;
            this.router.navigate(['/estimating', this.event.event.job.id]);
          },
        },
      ],
      // [
      //   {
      //     label: 'Contact Crew',
      //     icon: 'pi pi-envelope',
      //     disabled: true,
      //   },
      //   {
      //     label: 'Contact Customer',
      //     icon: 'pi pi-envelope',
      //     disabled: true,
      //   },
      // ],
      [
        {
          label: 'Manage Crew and Trucks',
          icon: 'pi pi-users',
          command: () => {
            this.overlayPanel.hide();
            this.openEditEvent();
          },
        },
        {
          label: 'Add Transaction',
          icon: 'pi pi-money-bill',
          command: () => {
            this.mutateService.openMutateObject({
              objectType: 'transaction',
              mutateType: 'create',
              additionalValues: [
                {
                  property: 'job',
                  value: this.event.event.job,
                },
                {
                  property: 'user',
                  value: this.event.customer.user,
                },
              ],
            });
          }
        }
      ],
      [
        {
          label: 'View Route',
          icon: 'pi pi-map',
          command: () => {
            const url = this.getRouteUrl();
            if (url) {
              window.open(url, '_blank');
            } else {
              console.error('URL is undefined');
            }
          }
        }
      ]
    ];
  }

  public openEventDetails() {
    this.detailsHelper.open(
      'calendar-event',
      this.event.event,
    );
  }

  public openJob() {
    this.router.navigate(['/job', this.event.event.job.id]);
  }

  public openEditEvent() {
    this.editDispatchEvent.emit();
  }

  public addCrewMember($event: CdkDragDrop<any, any, AttendeeDragData>) {

    const { previousContainer, container, item } = $event;

    // Return if the item is dropped in the same container
    if (previousContainer === container || !item.data?.attendee) return;

    const { attendee, event } = item.data;

    const eventId = event?.event?.id;
    const crew = event?.crew || [];
    const customerName = event?.customer?.name;
    const eventName = customerName || this.event.customer.name;

    const { user, name, role: initialRole } = attendee;
    const { id: userId } = user;
    let role = initialRole;

    // Check if the user is already in the crew
    const isUserInCrew = this.event.event.attendees.some(({ user }) => user.id === userId);
    if (isUserInCrew) {
      return this.notify.error('Crew member already added');
    }

    const crewLeadExists = this.event.event.attendees.some(({ role }) => role === EventAttendeeRoles.crewLead);

    if (!crewLeadExists && this.event.crew.length === 0) {
      role = EventAttendeeRoles.crewLead; // Add the first user as crew lead if no crew lead exists
    } else if (crewLeadExists && role === EventAttendeeRoles.crewLead) {
      role = EventAttendeeRoles.crewMember; // If crew lead exists, add the user as crew member
    }

    const edits: UpdateCrewActionPayload[] = [
      {
        eventId: this.event.event.id,
        eventName,
        addAttendees: [{ user, role, name, }],
      }
    ];

    // If we receive an event id (i.e. user is dragged from one event to another), we need to remove the user from the previous event
    if (eventId) {
      const removeAttendees = [{ user, role: initialRole, name }];
      const addAttendees = [];

      const isCrewLead = attendee.role === EventAttendeeRoles.crewLead;

      if (isCrewLead && crew.length > 1) {
        // Pick next crew lead as any member other than the one we are removing
        const nextCrewLead = crew.find(({ role, user }) => role === EventAttendeeRoles.crewMember
          && user.id !== attendee.user.id);
        addAttendees.push({ user: nextCrewLead.user, role: EventAttendeeRoles.crewLead, name: nextCrewLead.name });
        removeAttendees.push({ user: nextCrewLead.user, role: nextCrewLead.role, name: nextCrewLead.name });
      }

      edits.push({ eventId, eventName, removeAttendees, addAttendees, });
    }

    this.store.dispatch(DispatchActions.updateCrew({ edits }));

  }

  public addAsset($event: CdkDragDrop<any, any, AssetDragData>) {

    const { previousContainer, container, item } = $event;

    // Return if the item is dropped in the same container
    if (previousContainer === container || !item?.data?.asset) return;

    const { asset, event } = item.data;
    const eventId = event?.event?.id;
    const eventName = event?.customer?.name || this.event.customer.name;
    const trucks = event?.trucks || [];
    const { id: assetId, name } = asset;

    // Check if the asset is already assigned to the event
    const assetAlreadyExists = this.event.event.assets.some(({ id }) => id === assetId);
    if (assetAlreadyExists) {
      return this.notify.error('Asset already assigned to the event');
    }

    const edits: UpdateCrewActionPayload[] = [
      {
        eventId: this.event.event.id,
        eventName,
        addAssets: [{ id: assetId, name }],
      }
    ];

    // If we receive an event id (i.e. user is dragged from one event to another), we need to remove the user from the previous event
    if (eventId) {

      edits.push({
        eventId,
        eventName,
        removeAssets: [{ id: assetId, name }],
      });
    }

    this.store.dispatch(DispatchActions.updateCrew({
      edits
    }));
  }

  public onlyAttendeesPredicate(item: CdkDrag<AttendeeDragData>) {
    return 'attendee' in item.data;
  }

  public onlyTrucksPredicate(item: CdkDrag<AssetDragData>) {
    return 'asset' in item.data;
  }

  private getRouteUrl() {
    let startLocation = null;
    let endLocation = null;

    // Find start and end locations
    for (const location of this.event.event.locations) {
      if (location.type === 'start') {
        startLocation = location.location;
      } else if (location.type === 'end') {
        endLocation = location.location;
      }
      // Break early if both locations are found
      if (startLocation && endLocation) {
        break;
      }
    }


    // Return early if either location is missing
    if (!startLocation || !endLocation) {
      return;
    }

    const start = convertLocationToFullAddress(startLocation);
    const end = convertLocationToFullAddress(endLocation);

    return `https://www.google.ca/maps/dir/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
  }

  // Predicate function to check if a crew member already exists and does not allow users to drop in the list.
  //TODO: Find some another way to do it because predicaate function is fired multiple time ands this can be expensive.
  public crewMemberAlreadyExists = ($event: CdkDrag<User>) => {
    const { data } = $event;
    if (!data) return false;
    console.log('ran')
    return !this.event.event.attendees.some((member) => member.user.id === data.id);
  }
}

