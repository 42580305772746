/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { SubSink } from 'subsink';

import {
  BaseQuickbooksAccountFragment,
  GetConfigValuesGQL,
  GetConfigValuesQuery,
  GetConfigValuesQueryVariables,
  QuickbooksAccountsGQL,
  QuickbooksAccountsQuery,
  QuickbooksAccountsQueryVariables,
  SetConfigValuesGQL,
} from '../../../generated/graphql.generated';
import { safeParseJSON } from '../../js';
import { BrandingService } from '../../services/branding.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PRODUCT_CATEGORIES } from '../../shared/product-categories/product-categories';

export type QuickbooksItemType = 'Service' | 'Inventory' | 'NonInventory';

export interface ProductSetting {
  itemType: QuickbooksItemType;
  incomeAccountId: string;
  expenseAccountId?: string;
  assetAccountId?: string;
}

@Component({
  selector: 'app-quickbooks-accounts',
  templateUrl: './quickbooks-accounts.component.html',
  styleUrls: ['./quickbooks-accounts.component.scss']
})
export class QuickbooksAccountsComponent implements OnInit, OnDestroy {

  configKey = 'quickbooks.product-settings';

  categories = PRODUCT_CATEGORIES;

  itemTypes: QuickbooksItemType[] = ['Service', 'Inventory', 'NonInventory'];

  incomeAccounts: BaseQuickbooksAccountFragment[];

  expenseAccounts: BaseQuickbooksAccountFragment[];

  assetAccounts: BaseQuickbooksAccountFragment[];

  accountsQueryRef: QueryRef<QuickbooksAccountsQuery, QuickbooksAccountsQueryVariables>;

  productSettingsQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  productSettings: typeof this.defaultProductSettings;

  defaultProductSettings: Record<typeof PRODUCT_CATEGORIES[number], Partial<ProductSetting>> = {
    None: {
      itemType: 'Service',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    Labor: {
      itemType: 'Service',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    General: {
      itemType: 'Inventory',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    Supplies: {
      itemType: 'Inventory',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    'Truck & Travel': {
      itemType: 'Service',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    'Protection Plans': {
      itemType: 'NonInventory',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    'Specialty Items': {
      itemType: 'NonInventory',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
    '3rd Party Services': {
      itemType: 'NonInventory',
      incomeAccountId: undefined,
      expenseAccountId: undefined,
      assetAccountId: undefined,
    },
  };

  subs = new SubSink();

  constructor(
    private quickbooksAccountsGQL: QuickbooksAccountsGQL,
    private brandingService: BrandingService,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.retrieveAccounts();
    this.retrieveProductSettings();

    this.subs.sink = this.brandingService.currentZone()
      .subscribe(() => {
        this.accountsQueryRef?.refetch();
        this.productSettingsQueryRef?.refetch();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveProductSettings() {
    this.productSettingsQueryRef = this.getConfigValuesGQL.watch({ keys: [this.configKey] });

    this.subs.sink = this.productSettingsQueryRef.valueChanges.subscribe((res) => {

      if (res.loading) { return; }

      const [config] = res.data.getConfigValues;

      this.productSettings = safeParseJSON(config?.value, this.defaultProductSettings as any);

    });
  }

  retrieveAccounts() {
    this.accountsQueryRef = this.quickbooksAccountsGQL.watch({
      filter: {
        accountTypes: ['Income', 'Other Current Asset', 'Cost of Goods Sold'],
      },
    });

    this.subs.sink = this.accountsQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      this.incomeAccounts = res.data.quickbooksAccounts
        .filter((a) => a.AccountType === 'Income');

      this.assetAccounts = res.data.quickbooksAccounts
        .filter((a) => a.AccountType === 'Cost of Goods Sold');

      this.expenseAccounts = res.data.quickbooksAccounts
        .filter((a) => a.AccountType === 'Other Current Asset' && a.AccountSubType === 'Inventory');

    });
  }

  saveProductSettings() {

    for (const category of Object.keys(this.productSettings)) {
      const categorySettings: ProductSetting = this.productSettings[category];

      if (categorySettings.itemType !== 'Inventory') {
        delete categorySettings.assetAccountId;
        delete categorySettings.expenseAccountId;
      }
    }

    this.setConfigValuesGQL.mutate({
      configs: [{
        key: this.configKey,
        value: JSON.stringify(this.productSettings),
      }]
    }).subscribe(() => {
      this.localNotify.success(`Product settings updated`);
    });
  }

}
