import { Pipe, PipeTransform } from '@angular/core';

import { AttributeFilter, includesAll, includesAny, includesNone } from '../utilities/attributes.util';

@Pipe({
  name: 'attributeFilter'
})
/**
 * Filter an array by attribute values
 */
export class AttributeFilterPipe implements PipeTransform {

  transform(values: any[], filter: AttributeFilter): any[] {
    let filteredValues = values;

    if(filter.includesAll?.length){
      filteredValues = includesAll(filteredValues, filter.includesAll);
    }

    if(filter.includesAny?.length){
      filteredValues = includesAny(filteredValues, filter.includesAny);
    }

    if(filter.includesNone?.length){
      filteredValues = includesNone(filteredValues, filter.includesNone);
    }

    return filteredValues;
  }
}
