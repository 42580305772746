<p-autoComplete
	placeholder="Search (/) for leads, jobs, and reports."
	[(ngModel)]="query"
	(completeMethod)="search()"
	[suggestions]="results"
	field="title"
	styleClass="search"
	(onSelect)="select($event)"
	[showEmptyMessage]="true"
	>
	<ng-template pTemplate="item" let-result>
		<div class="search-result-container">
			<div class="search-result">
				<div>
					<i *ngIf="result.label === 'Job'"
						class="{{ icons.job }} large-icon"
						></i>
					<i *ngIf="result.label === 'Location'"
						class="{{ icons.location }} large-icon"
						></i>
				</div>
				<div class="data">
					<div class="title">{{ result.title }}</div>
					<div class="subtitle">{{ result.subtitle }}</div>
				</div>
			</div>
			<div *ngIf="resultPath[result.label]">
				<app-open-link [link]="resultPath[result.label].concat(result.objectID)" (click)="$event.stopPropagation()"></app-open-link>
			</div>
		</div>
	</ng-template>
</p-autoComplete>
