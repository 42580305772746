import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { stringifyLocation } from '@karve.it/interfaces/locations';

import { cloneDeep } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { AssetsFilter, AssetsGQL, BaseAssetFragment, FullAssetFragment } from '../../generated/graphql.generated';
import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { MutateAssetComponent } from '../shared/mutate-asset/mutate-asset.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mutateAsset') mutateRef: MutateAssetComponent;
  @ViewChild('assetSearchInput', { static: true }) assetSearchInput!: ElementRef;

  @Input() filter: AssetsFilter = {};

  // Layout Variables
  @Input() showHeaderCard = true;
  @Input() showSmallHeader = false;

  subs = new SubSink();

  assets: FullAssetFragment[];

  // Lazy Loading Variables
  assetsQueryRef: ReturnType<typeof this.assetsGQL.watch>;
  assetsQH: WatchQueryHelper = {
    limit: pagination.defaultNumberOfItems,
    search: '',
    loading: true,
    total: 0
  };
  showDeletedAssets = false;

  pagination = pagination;

  stringifyLocation = stringifyLocation;

  constructor(
    private assetsGQL: AssetsGQL,
    public detailsHelper: DetailsHelperService,
    private cd: ChangeDetectorRef,
    public freyaHelper: FreyaHelperService
  ) {
  }

  ngOnInit() {
    this.subs.sink  = this.detailsHelper.getObjectUpdates('Assets').subscribe(()=>{
      this.searchForAssets();
    });

    this.subs.sink = fromEvent(this.assetSearchInput.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(750),
    ).subscribe((text: string) => {
      this.assetsQH.skip = 0;

      this.searchForAssets();
    });

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(){
    this.cd.detectChanges();
  }

  searchForAssets() {
    this.assetsQH.loading = true;

    if (this.assetsQueryRef) {
      const assetsInput = {
        filter: this.getFilter(),
        limit: this.assetsQH.limit,
        skip: this.assetsQH.skip,
      };

      this.assetsQueryRef.refetch(assetsInput);
    } else {
      this.retrieveAssets();
    }
  }

  retrieveAssets(){

    this.assetsQueryRef = this.assetsGQL.watch({
      limit: this.assetsQH.limit,
      filter: this.getFilter(),
    });

    this.subs.sink = this.assetsQueryRef.valueChanges.subscribe((res) => {
      console.log(res.data.assets.assets);
      if (res.networkStatus === 7) {
        this.assets = cloneDeep(res.data.assets.assets);
        console.log(this.assets);
        this.assetsQH.loading = false;
        this.assetsQH.total = res.data.assets.total;
      }
    });
  }

  retrieveMoreAssets(event: LazyLoadEvent) {
    this.assetsQH.skip = event.first;
    this.assetsQH.limit = event.rows;

    this.searchForAssets();
  }

  getFilter() {
    return {
      search: this.assetsQH.search ? this.assetsQH.search : undefined,
      showDeleted: this.showDeletedAssets,
    } as AssetsFilter;
  }

  // Populate information in the Side Panel
  viewAssetDetails(asset: BaseAssetFragment){

    asset = cloneDeep(asset);

    this.detailsHelper.detailsItem.next({type: 'asset', item: {id: asset.id}});
  }

  openCreateAsset(){
    this.mutateRef.mutateType = 'create';
    this.mutateRef.openDialog();
  }


}
