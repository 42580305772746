import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BaseUserFragment, FullUserFragment, UserSearch } from 'graphql.generated';
export const JobCreateCustomerActions = createActionGroup({
	source: 'Job Create',
	events: {
		'Search Users Success': props<{ users: BaseUserFragment[] }>(),
		'Search Users Error': props<{ error: Error }>(),

		'Discard Customer Changes': emptyProps(),
		'Accept Customer Changes': emptyProps(),
		'Deselect Customer': emptyProps(),

		'Create New Customer Based On Existing': emptyProps(),

		'Customer Value Input': props<{
			field: string;
			value: string;
			filter?: UserSearch;
			search?: boolean;
		}
		>(),
		'Existing Customer Selected': props<{
			existingCustomer: Partial<FullUserFragment>
		}>(),

		'Select Moving Date': props<{
			timeline: string,
			timelineDays: number,
		}>(),
		'Select How Heard': props<{ fieldValue: string }>(),
		'Select Customer Type': props<{ jobType: string }>(),
		'Select Job Origin': props<{ jobOrigin: string }>(),
		'Edit Customer When Create Job': emptyProps(),
	},
});
