import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '@karve.it/interfaces/tag';
import { capitalize } from 'lodash';

@Pipe({
  name: 'formatTag'
})
export class FormatTagPipe implements PipeTransform {

  transform(tag: Tag): string {
    return `${tag.category ? `${capitalize(tag.category)}: ` : '' }${tag.name}`;
  }

}
