import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { authFeature } from '../core/auth/auth.reducer';

import * as scheduleEffects from '../schedules/store/schedule.effects';
import { scheduleFeature } from '../schedules/store/schedules.reducer';

import { featureFlagsFeature } from './featureFlags.store';

export const rootState = [
	provideState(authFeature),
	provideState(featureFlagsFeature),
	provideState(scheduleFeature),
	provideEffects(scheduleEffects),
];
