import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { PlusAuthenticationService } from '@karve.it/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FREYA_ROUTES } from './global.constants';

import { FreyaNotificationsService } from './services/freya-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard  {

  public constructor(
    private auth: PlusAuthenticationService,
    private localNotify: FreyaNotificationsService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authenticated = Boolean(this.auth.user);

    if (!authenticated) {
      // Return URL Tree, https://juristr.com/blog/2018/11/better-route-guard-redirects/
      return this.router.parseUrl(FREYA_ROUTES.login);
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
