<div [formGroup]="customAmountForm">

  <div *appVar="customAmountForm.get('amount') as control">
    <p-inputNumber
      placeholder="Enter amount"
      formControlName="amount"
      [showButtons]="true"
      [step]="appliedDiscount?.discount.discountType === 'percentage' ? 1 : 0.25"
      [mode]="appliedDiscount?.discount.discountType === 'percentage' ? 'decimal' : 'currency'"
      [currency]="job.currency"
      [minFractionDigits]="appliedDiscount?.discount.discountType === 'amount' ? 1 : undefined"
      [maxFractionDigits]="appliedDiscount?.discount.discountType === 'amount' ? 2 : 0"
      [suffix]="appliedDiscount?.discount?.discountType === 'percentage' ? '%' : undefined"
      [min]="min"
      [max]="max"
      [class.p-invalid-input]="!control.valid && control.touched"
      ></p-inputNumber>
      <app-validation-messages [control]="control"></app-validation-messages>
    <br>
    <small>
      Range: {{ appliedDiscount?.discount.customAmountRange | discountCustomAmountRange : appliedDiscount?.discount.discountType }}
    </small>
  </div>

  <div class="footer">
    <button
      pButton
      icon="pi pi-times"
      (click)="closeDialog()"
      label="Close"
      ></button>
    <span
      [pTooltip]="customAmountForm.invalid && 'Invalid Amount'"
      tooltipPosition="bottom"
      >
      <button
        pButton
        label="Update"
        icon="pi pi-check"
        class="p-button-success"
        (click)="updateCustomAmount()"
        [disabled]="customAmountForm.invalid"
        ></button>
    </span>
  </div>
</div>

