/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { RangeQueryParam } from './stripe-promotions';

export interface ListCouponsInput {
    created?: RangeQueryParam;
    ending_before?: string;
    limit?: number;
    starting_after?: string;
}

export interface ListCouponsOutput {
    coupons: Coupon[];
}

export interface WatchCouponsInput {
    created?: RangeQueryParam;
    ending_before?: string;
    limit?: number;
    starting_after?: string;
}

export interface WatchCouponsOutput {
    coupons: Coupon[];
}

export const couponsQuery = gql`
	query coupons($created: RangeQueryParam, $ending_before: String, $limit: Int, $starting_after: String, ){ 
		coupons(created: $created,  ending_before: $ending_before,  limit: $limit,  starting_after: $starting_after, ){ 
            id
            amount_off
            applies_to{
                products
            }
            created
            currency
            deleted
            duration
            duration_in_months
            livemode
            max_redemptions
            name
            percent_off
            redeem_by
            times_redeemed
            valid    
        }
    }
`;

export interface RetrieveCouponInput {
    stripeId?: string;
}

export interface RetrieveCouponOutput {
    retrieveCoupon: Coupon;
}

export const retrieveCouponQuery = gql`
	query retrieveCoupon($stripeId: String!, ){ 
		retrieveCoupon(stripeId: $stripeId, ){ 
            id
            amount_off
            applies_to{ products }    
            created
            currency
            deleted
            duration
            duration_in_months
            livemode
            max_redemptions
            name
            percent_off
            redeem_by
            times_redeemed
            valid           
        }
    }
`;

export interface CreateCouponsInput {
    coupons?: CreateCoupon[];
}

export interface CreateCouponsOutput {
    createCoupons: Coupon[];
}

export const createCouponsMutation = gql`
	mutation createCoupons($coupons: [CreateCoupon], ){ 
		createCoupons(coupons: $coupons, ){ 
            id
            amount_off
            applies_to{ products }    
            created
            currency
            deleted
            duration
            duration_in_months
            livemode
            max_redemptions
            name
            percent_off
            redeem_by
            times_redeemed
            valid  
        }
    }
`;

export interface UpdateCouponInput {
    stripeId?: string;
    name?: string;
}

export interface UpdateCouponOutput {
    updateCoupon: boolean;
}

export const updateCouponMutation = gql`
	mutation updateCoupon($stripeId: String!, $name: String, ){ 
		updateCoupon(stripeId: $stripeId,  name: $name, )
    }
`;

export interface DeleteCouponInput {
    stripeId?: string;
}

export interface DeleteCouponOutput {
    deleteCoupon: boolean;
}

export const deleteCouponMutation = gql`
	mutation deleteCoupon($stripeId: String!, ){ 
		deleteCoupon(stripeId: $stripeId, )		
    }
`;
export interface Coupon {
    id?: string;
    amount_off?: number;
    applies_to?: AppliesTo;
    created?: number;
    currency?: string;
    deleted?: boolean;
    duration?: string;
    duration_in_months?: number;
    livemode?: boolean;
    max_redemptions?: number;
    name?: string;
    percent_off?: number;
    redeem_by?: number;
    times_redeemed?: number;
    valid?: boolean;
}

export interface AppliesTo {
    products?: string[];
}

export interface CreateCoupon {
    duration: string;
    amount_off?: number;
    applies_to?: AppliesToInput;
    currency?: string;
    duration_in_months?: number;
    max_redemptions?: number;
    name?: string;
    percent_off?: number;
    redeem_by?: number;
}

export interface AppliesToInput {
    products?: string[];
}
