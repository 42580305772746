<div
    class="create-product-input"
    [formGroup]="productForm"
>
    <ng-template #nameAndCategory>
        <div *appVar="productForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="name"
                placeholder="Product Name *"
                [class.p-invalid-input]="!control.valid && control.touched"
                data-cy="name-text-field"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
            <div class="p-col">
                <span class="chip-container">
                    <!-- <p-chip
                        *ngFor="let tax of control.value"
                        [removable]="true"
                        (onRemove)="removeTax(tax)"
                        [label]="tax.name"
                    >
                    </p-chip> -->
                </span>
            </div>
        </div>

        <div>
            <label>Category</label>
            <br />
            <p-dropdown
                class="half-width-dropdown"
                [options]="productCategories"
                formControlName="category"
                [showToggleAll]="false"
                optionLabel="label"
                optionValue="value"
                appendTo="body"
                data-cy="category-dropdown-field"
            ></p-dropdown>
        </div>
    </ng-template>

    <ng-template #active>
        <div>
            <p-checkbox
                formControlName="active"
                binary="true"
                data-cy="active-check-box"
            > </p-checkbox>
            <span> Product Is Active</span>
        </div>
    </ng-template>

    <ng-template #color>
        <div class="p-grid">
            <div
                *appVar="productForm.get('color') as control"
                class="p-flex p-ai-center p-col"
            >
                <label>Select Color</label>
                <br /><br />
                <p-dropdown
                    [options]="colorList"
                    formControlName="color"
                    [showToggleAll]="false"
                    placeholder="Select a Color"
                    optionLabel="name"
                    optionValue="name"
                    data-cy="select-color-drop-down-field"
                    appendTo="body"
                >
                    <ng-template pTemplate="selectedItem">
                        {{this.productForm.value.color | titlecase}}
                    </ng-template>

                    <ng-template
                        let-item
                        pTemplate="item"
                    >
                        {{item.name | titlecase}}
                    </ng-template>
                </p-dropdown>

                <br /><br />
                <button
                    pButton
                    label="Clear Color"
                    icon="pi pi-refresh"
                    class="p-button-text"
                    (click)="resetSelectedColor()"
                    data-cy="clear-color-button"
                ></button>
            </div>


            <div class="tag-container p-col">
                <label>Examples</label>
                <br /><br />
                <span>Light mode</span>
                <div class="card color-box">
                    <div
                        class="color-square"
                        [style.background-color]="this.productForm.value['color']"
                    >
                    </div>
                    <span [style]="{'color':'var(--black)'}">
                        {{!this.productForm.value['color'] ? 'No color' : this.productForm.value['color'] | titlecase }}
                    </span>
                </div>
                <span>Dark mode</span>
                <div
                    class="card color-box"
                    [style]="{'background-color':'var(--card-background-opposite)'}"
                >
                    <div
                        class="color-square"
                        [style.background-color]="this.productForm.value['color']"
                    > </div>
                    <span [style]="{'color':'var(--black)' }">
                        {{!this.productForm.value['color'] ? 'No color' : this.productForm.value['color'] | titlecase }}

                    </span>
                </div>

            </div>
        </div>
        <br>
    </ng-template>

    <ng-template #description>
        <div>
            <label>Description</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="description"
                placeholder="Product Description"
                data-cy="description-text-field"
            >
        </div>
    </ng-template>

    <ng-template #statementDescription>
        <div>
            <label>Statment Description</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="statementDescription"
                placeholder="Statement Description"
                data-cy="statement-description-text-field"
            >
            <br>
            <small>The description that will appear in invoices.</small>
        </div>
    </ng-template>

    <ng-template #quickbooksProduct>
        <div>
            <label>Quickbooks Product</label>
            <br />
            <p-dropdown
                [options]="quickbooksProducts"
                styleClass="full-width"
                formControlName="quickbooksProduct"
                placeholder="Quickbooks Product"
                appendTo="body"
                optionLabel="Name"
                optionValue="Id"
                ></p-dropdown>
        </div>
    </ng-template>

    <ng-template #quickbooksTaxCode>
        <div>
            <label>Quickbooks Tax Code</label>
            <br />
            <p-dropdown
                [options]="quickbooksTaxCodes"
                styleClass="full-width"
                formControlName="quickbooksTaxCode"
                placeholder="Quickbooks Tax Code"
                appendTo="body"
                optionLabel="Name"
                optionValue="Id"
                optionDisabled="disabled"
                ></p-dropdown>
        </div>
    </ng-template>

    <ng-template #unitLabel>
        <div>
            <label>Unit Label</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="unitLabel"
                placeholder="Product Unit"
                data-cy="description-text-field"
            >
        </div>
    </ng-template>
</div>


<app-mutate-object
    #mutate
    [fg]="productForm"
    objectType="Product"
    [mutateType]="mutateType"
    [steps]="steps"
    scrollHeight="300px"
    (objectMutated)="mutateObject()"
></app-mutate-object>
