import { Component, Input, OnInit } from '@angular/core';
import {dayjs} from '@karve.it/core';

import { Report, ReportAggregationResult } from '../../../generated/graphql.generated';
import { TimezoneHelperService } from '../../services/timezone-helper.service';

@Component({
  selector: 'app-report-aggregation-value-label',
  templateUrl: './report-aggregation-value-label.component.html',
  styleUrls: ['./report-aggregation-value-label.component.scss']
})
export class ReportAggregationValueLabelComponent implements OnInit {

  @Input() report: Report;
  @Input() ag: ReportAggregationResult;
  @Input() label: any;

  constructor(
    private timeZoneHelper: TimezoneHelperService,
  ) { }

  ngOnInit(): void {
    this.formatLabel();
  }

  formatLabel() {
    // console.log(this.label, this.ag);
    const options = this.ag?.options;
    if (this.ag.type === 'PERIODS' && options?.unit) {
      const unit = options.unit;
      const format = options.format || 's';
      const time = format === 's' ? this.label * 1000 : this.label;

      // TOOD: figure out timezone, use report timezone in this format, resolve timezone, etc
      const timezone = options.tz || this.timeZoneHelper.currentTimezone;
      let outputFormat = 'YYYY-MM-DD HH:mm';
      switch(unit) {
        case 'minute':
        case 'hour':
        default:
        break;
        case 'day':
          outputFormat = 'YYYY-MM-DD';
          break;
        case 'week':
          outputFormat = 'YYYY w';
          break;
        case 'month':
          outputFormat = 'YYYY MMM';
          break;
        case 'quarter':
          outputFormat = 'YYYY Qo [quarter]';
          break;
        case 'year':
          outputFormat = 'YYYY';
          break;
      }

      const formatted = dayjs(time).tz(timezone).format(outputFormat);
      if (formatted) {
        this.label = formatted;
      }
    }
  }

}
